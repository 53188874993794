import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    inject,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { IUIDialogConfig, UIDialogDirective, UIModule } from '@bannerflow/ui';
import { ICreative } from '@domain/creativeset/creative';
import { take } from 'rxjs/operators';
import { AnimationControlService } from '../../../shared/animation-control/state/animation-control.service';
import { StudioCreativeComponent } from '../../../shared/components/creative/studio-creative.component';
import { EnvironmentService } from '../../../shared/services/environment.service';
import { VersionsService } from '../../../shared/versions/state/versions.service';

@Component({
    selector: 'creative-list-item-preview',
    standalone: true,
    imports: [CommonModule, StudioCreativeComponent, UIModule],
    templateUrl: './creative-list-item-preview.component.html',
    styleUrls: ['./creative-list-item-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreativeListItemPreviewComponent implements OnInit, OnChanges {
    private animationControlService = inject(AnimationControlService);
    private environmentService = inject(EnvironmentService);
    private versionsService = inject(VersionsService);

    @Input() creative: ICreative | undefined;
    @Input() scale = 1;
    @ViewChild('dialog') dialog?: UIDialogDirective;

    percentageTooltip: string;
    dialogCreativeConfig: IUIDialogConfig;
    isMobileShowcase = this.environmentService.isMobileShowcase;

    ngOnInit(): void {
        this.generateDialogConfig();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.scale) {
            this.updateTooltip();
        }
    }

    updateTooltip(): void {
        if (this.scale < 1) {
            this.percentageTooltip = `Scaled to ${Math.round(this.scale * 100)}%. Click for full size`;
        } else {
            this.percentageTooltip = 'Actual size';
        }
    }

    openCreativeDialog(): void {
        // pause animations when previewing
        this.animationControlService.stopAnimations();
        this.dialog?.open();
    }

    private generateDialogConfig(): void {
        if (!this.creative) {
            return;
        }
        this.versionsService
            .getVersionByID(this.creative.version.id)
            .pipe(take(1))
            .subscribe(version => {
                if (!version || !this.creative) {
                    return;
                }

                const { width, height } = this.creative.size;
                const headerText = `${version.name} - ${width}×${height}`;
                const windowWidth = window.innerWidth;
                const windowHeight = window.innerHeight;
                const headerHeight = 50;
                const scrollbarThickness = 18;
                const dialogHeight =
                    width > windowWidth
                        ? height + headerHeight + scrollbarThickness
                        : height + headerHeight;
                const dialogWidth = height > windowHeight ? width + scrollbarThickness : width;

                this.dialogCreativeConfig = {
                    padding: 0,
                    maxWidth: 'calc(100% - 2.5rem)',
                    maxHeight: 'calc(100% - 2.5rem)',
                    panelClass: 'dialog-panel',
                    width: dialogWidth,
                    height: dialogHeight,
                    headerText
                };
            });
    }
}
