<div class="side-panel ui-scrollbar">
    <size-add-tabs (selectedTabChange)="selectTab($event)">
        <div tab1>
            <ng-container *ngTemplateOutlet="sizesContentTemplate" />
        </div>
        <div tab2>
            <ng-container *ngTemplateOutlet="psdContentTemplate" />
        </div>
    </size-add-tabs>
</div>

<ng-template #sizesContentTemplate>
    <size-add-custom-input (customSizeAdded)="addSizeToCustomSizesCollection($event)" />
    <size-add-collection-select />
    <size-add-collection-overview />
    <size-add-collection-list />
</ng-template>

<ng-template #psdContentTemplate>
    @if (selectedTab === Tabs.PSD_IMPORT) {
        <psd-list />
    }
</ng-template>

@switch (selectedTab) {
    @case (Tabs.SIZE_ADD) {
        <size-add-preview
            [sizes]="addedSizes()"
            (onCancel)="close()" />
    }
    @case (Tabs.PSD_IMPORT) {
        <div class="psd-preview-container">
            <psd-import-preview (onCancel)="close()" />
            <psd-fix-font-panel />
        </div>
    }
}
