import { IAdFile } from '@domain/ad/ad-data-creative';
import { concatUrl, isRelativeUrl } from '../url';

export function isDataJSUrl(url: string): boolean {
    return url.includes('data');
}

export function isAnimatedCreativeUrl(url: string): boolean {
    return url.includes('animated-creative');
}

export function isPreloadAssetUrl(name: string): boolean {
    return name.startsWith('preload');
}

export function remapFileUrl(file: IAdFile, origin?: string): IAdFile {
    /**
     * Campaign: origin + /scripts/filename
     * Latest Preview: origin + /scripts/filename
     * Metadata preview: url based on addata.
     * HTML5 Output: "filename"
     */
    let mappedUrl = file.url;

    if (origin) {
        if (isPreloadAssetUrl(file.id) || isAnimatedCreativeUrl(file.url)) {
            mappedUrl = `scripts/${mappedUrl}`;
        }
        if (isRelativeUrl(file.url)) {
            mappedUrl = concatUrl(origin, mappedUrl);
        }
    }

    return {
        id: file.id,
        url: mappedUrl
    };
}
