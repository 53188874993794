import { StreamingHttpClient } from '../streaming-http-client';
import { parseManifest } from './manifest-parser';
import { MediaType, StreamingManifest, StreamingRepresentation } from './manifest.model';

export class ManifestManager {
    private _manifest: StreamingManifest;
    private _manifestUrl: string;
    private _currentRepresentationIndex = 0;

    constructor(private _httpClient: StreamingHttpClient) {}

    async init(manifestUrl: string): Promise<void> {
        if (!manifestUrl.endsWith('.mpd')) {
            throw new Error(`Unsupported manifest: ${manifestUrl}`);
        }
        this._manifestUrl = manifestUrl;
        try {
            const manifestResponse = await this._httpClient.fetchText(manifestUrl);
            this._manifest = parseManifest(manifestResponse);

            console.debug('[ManifestManager] manifest parsed');
        } catch (error) {
            console.error('Error fetching and parsing manifest', error);
        }
    }

    getCodecs(type: MediaType): string {
        return this._manifest.codecs[type];
    }

    getTotalDuration(): number {
        return this._manifest.totalDuration;
    }

    getTotalChunks(): number {
        return this._manifest.totalChunks;
    }

    /**
     * Returns the base URL inferred from the manifest's location.
     * http://blob.storage.net/123/guid.mpd -> http://blob.storage.net/123
     * @private
     */
    getBaseUrl(): string {
        return this._manifestUrl.substring(0, this._manifestUrl.lastIndexOf('/'));
    }

    getInitUrl(baseUrl: string, type: MediaType): string {
        const { initUrl } = this.getCurrentRepresentation(type);

        return `${baseUrl}/${initUrl}`;
    }

    getChunkUrl(baseUrl: string, chunkIndex: number, type: MediaType): string {
        const chunkUrl = this.getNextChunkUrl(chunkIndex, type);
        return `${baseUrl}/${chunkUrl}`;
    }

    getNextChunkUrl(chunkIndex: number, type: MediaType): string {
        if (chunkIndex >= this.getTotalChunks()) {
            throw new Error('Chunk index cannot be greater or equal than the total number of chunks.');
        }

        const { chunkUrlTemplate, id, startIndex } = this.getCurrentRepresentation(type);
        return chunkUrlTemplate
            .replace('$RepresentationID$', id)
            .replace('$Number$', (chunkIndex + startIndex).toString());
    }

    getManifest(): StreamingManifest {
        return this._manifest;
    }

    getCurrentRepresentation(type: MediaType): StreamingRepresentation {
        const manifest = this.getManifest();
        return manifest.representations[type][this._currentRepresentationIndex];
    }

    verifyCurrentRepresentation(bps: number, type: MediaType): void {
        const previousIndex = this._currentRepresentationIndex;
        this._currentRepresentationIndex = 0; // reset to the lowest bitrate
        // find a representation that matches our current bandwidth, starting at the best one
        const representations = this.getManifest().representations[type];
        for (let i = representations.length - 1; i > 0; i--) {
            if (bps > representations[i].bandwidth) {
                this._currentRepresentationIndex = i;
                break;
            }
        }

        if (previousIndex !== this._currentRepresentationIndex) {
            console.log(`Switching to representation[${this.getCurrentRepresentation(type).name}]`);
        }
    }
}
