// The lowercase version of `navigator.userAgent`.
// The `navigator` can be `undefined` in some cases. To avoid breaking, we should default to an empty string.
// To be able to mock the user agent in tests, it's moved to its own module.
export const userAgent = (window.navigator?.userAgent || '').toLowerCase();

/**
 * @deprecated `window.navigator.vendor` is deprecated.
 * https://developer.mozilla.org/en-US/docs/Web/API/Navigator/vendor
 */
export const vendor = window.navigator?.vendor;
