import { Injectable } from '@angular/core';
import { Logger } from '@bannerflow/sentinel-logger';
import { isElementVisibleAtTime } from '@creative/animation.utils';
import { isLocked } from '@creative/nodes';
import {
    forEachDataElement,
    forEachParentNode,
    isGroupDataNode,
    isHidden,
    isNumberBetween,
    isSelectionVisibleAtTime,
    isTextNode,
    positionIsInBounds
} from '@creative/nodes/helpers';
import { isElementSelection } from '@creative/nodes/selection';
import {
    IBoundingBox,
    IDelta,
    IOffset,
    IPosition,
    IResizeDirection,
    ISize,
    ResizeDirection,
    RotationLocation
} from '@domain/dimension';
import { ElementKind } from '@domain/elements';
import {
    IGroupElementDataNode,
    ITextDataNode,
    ITextViewElement,
    OneOfDataNodes,
    OneOfElementDataNodes,
    OneOfSelectableElements
} from '@domain/nodes';
import {
    GuidelineType,
    ICrossValue,
    IGuideline,
    IPointResult,
    IPositionToCrossValuesMap,
    IQueryPoint,
    ISnapLine,
    ISnapLines,
    ISnapPoint,
    ISnapPoints,
    ITransformDirection,
    LockedDirection,
    TransformMode
} from '@domain/workspace';
import { IGuidelineSelection } from '@studio/domain/workspace/guideline.models';
import { UserSettingsService } from '@studio/stores/user-settings';
import { BTreeIndex } from '@studio/utils/btree';
import { ROTATION_ICON_MULTIPLE, setCursorOnElement } from '@studio/utils/cursor';
import { isElementDescendantOfElement } from '@studio/utils/dom-utils';
import {
    aspectRatioScale,
    diagonal,
    distance,
    getBoundsOfScaledRectangle,
    getCenter,
    getRatio
} from '@studio/utils/geom';
import { IMouseDownMove, IMouseValue } from '@studio/utils/mouse-observable';
import {
    clamp,
    polygonIsIntersecting,
    rotatePosition,
    roundToNearestMultiple
} from '@studio/utils/utils';
import { BehaviorSubject, Subject, tap } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MediaLibraryService } from '../../../shared/media-library/state/media-library.service';
import { BrandLibraryElementEditService } from '../media-library/brandlibrary-element-edit.service';
import { PropertiesService } from '../properties-panel/properties.service';
import { EditorEventService, ElementChangeType } from '../services/editor-event';
import { EditorStateService } from '../services/editor-state.service';
import { ElementHighlightService } from '../services/element-highlight.service';
import { ElementSelectionBoundingBoxService } from '../services/element-selection-bounding-box.service';
import { ElementSelectionService } from '../services/element-selection.service';
import { MutatorService } from '../services/mutator.service';
import { SelectionNetService } from '../services/selection-net.service';
import { KeyframeAction, KeyframeService } from '../timeline';
import {
    IBoundingCornersWithOffsetToTopLeft,
    StudioWorkspaceComponent
} from './studio-workspace.component';

const SNAP_TOLERANCE = 5;

@Injectable()
export class WorkspaceTransformService {
    workspace: StudioWorkspaceComponent;
    private snappingIsEnabled = true;
    private isElementDragging = false;
    private _mode: TransformMode = TransformMode.None;
    get mode(): TransformMode {
        return this._mode;
    }
    private _mode$ = new BehaviorSubject<TransformMode>(TransformMode.None);
    mode$ = this._mode$.asObservable().pipe(tap(mode => (this._mode = mode)));
    private xPoints = new BTreeIndex<ICrossValue>();
    private yPoints = new BTreeIndex<ICrossValue>();
    private elementToXPointsMap = new Map<number | string, number[]>();
    private elementToYPointsMap = new Map<number | string, number[]>();
    private static RESIZE_TOLERANCE = 5;
    private static ROTATE_TOLERANCE = 20;
    private static MIN_SIZE = 25;
    private elementStart: Readonly<IPosition> & Partial<ISize>;
    private resizeDirection: IResizeDirection = {};
    private elementSizeRatio: number;
    private elementResizeRatio: number;
    private shiftKeyIsDown = false;
    private altKeyIsDown = false;
    private ctrlKeyIsDown = false;
    private mouseLeftIsDown = false;
    private mouseStart?: IPosition;
    private rotatedMouseStartPosition?: IPosition;
    private lastRotation?: number; // Can only be from 0 to 2 * Math.PI
    private rotationLocation?: RotationLocation;
    private imagePositionStart?: IPosition;
    private addedElementsToSelectionInMouseDown = false;
    private canvasStartMousePosition: IPosition;
    private savedMousePosition: IPosition | undefined;
    private currentMousePosition: IPosition | undefined;
    private isCloning = false;
    private elementLockStarted = false;
    private initialElementUnderMouse: OneOfElementDataNodes | undefined;
    private onDestroy$ = new Subject<void>();
    public isPositioningImage = false;
    private didMove = false;
    private isMediaLibraryOpen = false;

    /**
     * We need to detect if it is on Safari, due to 'user-select: none' in Safari will return the incorrect value.
     * It's not able to get the position of the text if a parent is not "selectable" in Safari. Firefox has a related
     * bug where it returns the node of the canvas layer if we have 'user-select: all'. So we have to only in Safari,
     * turn on 'user-select: all' when the user is editing. And always default to 'user-select: none' otherwise.
     */
    isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    onGuidelineChange$ = new BehaviorSubject<IGuideline | undefined>(undefined);
    guidelineSelection: IGuidelineSelection = {
        instance: undefined,
        hasMoved: false,
        isAdding: false
    };
    imageSelectionAreas: Map<string, IPosition & ISize & { hover?: boolean }> = new Map();

    private unsubscribe$ = new Subject<void>();
    private logger = new Logger('WorkspaceTransformService');

    constructor(
        private editorStateService: EditorStateService,
        private editorEventService: EditorEventService,
        private mediaLibraryService: MediaLibraryService,
        private propertiesService: PropertiesService,
        private keyframeService: KeyframeService,
        private mutatorService: MutatorService,
        private elementSelectionService: ElementSelectionService,
        private selectionNetService: SelectionNetService,
        private elementSelectionBoundingBoxService: ElementSelectionBoundingBoxService,
        private elementHighlightService: ElementHighlightService,
        private userSettingsService: UserSettingsService,
        private brandLibraryElementEditService: BrandLibraryElementEditService
    ) {}

    nudgeLeft = (): void => this.nudgePosition({ x: -1, y: 0 });
    moveLeft = (): void => this.nudgePosition({ x: -10, y: 0 });

    nudgeRight = (): void => this.nudgePosition({ x: 1, y: 0 });
    moveRight = (): void => this.nudgePosition({ x: 10, y: 0 });

    nudgeUp = (): void => this.nudgePosition({ x: 0, y: -1 });
    moveUp = (): void => this.nudgePosition({ x: 0, y: -10 });

    nudgeDown = (): void => this.nudgePosition({ x: 0, y: 1 });
    moveDown = (): void => this.nudgePosition({ x: 0, y: 10 });

    deleteGuideline = (): void => {
        this.removeActiveGuideline(true);
        this.workspace.gizmoDrawer.draw();
    };

    onInit(): void {
        this.editorEventService.text.textMouseDown$
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(this._setTextElementCursor);

        this.editorEventService.text.textMouseUp$
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(this._unsetTextElementCursor);

        const workspace = this.workspace;

        const mouseObervable = this.workspace.mouseObervable;
        mouseObervable.mouseDown$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(mouseValue => this.onMouseDown(mouseValue));

        mouseObervable.mouseMove$.pipe(takeUntil(this.unsubscribe$)).subscribe(mouseValue => {
            this.onMouseMove(mouseValue);
        });

        mouseObervable.mouseDownMove$.pipe(takeUntil(this.unsubscribe$)).subscribe(mouseValue => {
            this.onMouseDownMove(mouseValue);
        });

        mouseObervable.mouseUp$.pipe(takeUntil(this.unsubscribe$)).subscribe(mouseValue => {
            this.onMouseUp(mouseValue.event);
        });

        mouseObervable.doubleClick$.pipe(takeUntil(this.unsubscribe$)).subscribe(mouseValue => {
            this.onMouseDoubleClick(mouseValue.event);
        });

        window.addEventListener('blur', this.blur);

        this.workspace.betterHotkeyService.keyEvent$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(keyEvent => {
                if (keyEvent.keys.some(key => key === 'Ctrl' || key === 'Cmd')) {
                    this.ctrlKeyIsDown = keyEvent.down ?? false;
                }

                if (keyEvent.keys.some(key => key === 'Alt')) {
                    if (keyEvent.down) {
                        this.onAltKeyDown();
                    } else if (keyEvent.up) {
                        this.onAltKeyUp();
                    }
                }

                if (keyEvent.keys.some(key => key === 'Shift')) {
                    if (keyEvent.down) {
                        this.onShiftKeyDown();
                    } else if (keyEvent.up) {
                        this.onShiftKeyUp();
                    }
                }

                if (keyEvent.keys.some(key => key === 'Spacebar')) {
                    if (keyEvent.up) {
                        this.onSpaceKeyUp();
                    }
                }

                if (keyEvent.keys.some(key => key === 'Escape')) {
                    if (keyEvent.up) {
                        this.onEscapeKeyUp();
                    }
                }
            });

        workspace.betterHotkeyService.on('NudgeLeft', this.nudgeLeft);
        workspace.betterHotkeyService.on('MoveLeft', this.moveLeft);
        workspace.betterHotkeyService.on('NudgeRight', this.nudgeRight);
        workspace.betterHotkeyService.on('MoveRight', this.moveRight);
        workspace.betterHotkeyService.on('NudgeUp', this.nudgeUp);
        workspace.betterHotkeyService.on('MoveUp', this.moveUp);
        workspace.betterHotkeyService.on('NudgeDown', this.nudgeDown);
        workspace.betterHotkeyService.on('MoveDown', this.moveDown);
        workspace.betterHotkeyService.on('DeleteElement', this.deleteGuideline);

        workspace.gradientHelper.on('start', this.onGradientHelperStart);

        this.editorEventService.creative.change$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => this.indexElementPoints());

        this.mediaLibraryService.isOpen$.pipe(takeUntil(this.unsubscribe$)).subscribe(isOpen => {
            this.isMediaLibraryOpen = isOpen;
        });

        this.userSettingsService.snapping$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(snapping => (this.snappingIsEnabled = snapping));

        this.indexElementPoints();
        this.workspace.isElementDragging$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(is => (this.isElementDragging = is));

        this.mode$.pipe(takeUntil(this.unsubscribe$)).subscribe();
    }

    destroy(): void {
        this.unsubscribe$.next();

        window.removeEventListener('blur', this.blur);

        this.workspace.gradientHelper.off('start', () => {
            this.setTransformMode(TransformMode.EditGradient);
        });

        this.workspace.betterHotkeyService.off('NudgeLeft', this.nudgeLeft);
        this.workspace.betterHotkeyService.off('MoveLeft', this.moveLeft);
        this.workspace.betterHotkeyService.off('NudgeRight', this.nudgeRight);
        this.workspace.betterHotkeyService.off('MoveRight', this.moveRight);
        this.workspace.betterHotkeyService.off('NudgeUp', this.nudgeUp);
        this.workspace.betterHotkeyService.off('MoveUp', this.moveUp);
        this.workspace.betterHotkeyService.off('NudgeDown', this.nudgeDown);
        this.workspace.betterHotkeyService.off('MoveDown', this.moveDown);
        this.workspace.betterHotkeyService.off('DeleteElement', this.deleteGuideline);
    }

    setTransformMode(mode: TransformMode): void {
        this._mode$.next(mode);
    }

    cancel(): void {
        const workspace = this.workspace;
        if (this.mode === TransformMode.EditText) {
            this.mutatorService.stopEditText();
            if (this.isSafari) {
                workspace.userSelect = 'none';
            }
        }
        if (this.mode === TransformMode.CreateElement) {
            this.workspace.stopCreateNewElement();
        }

        if (this.workspace.designView.toolbar) {
            this.workspace.designView.toolbar.deselectToolbarItem();
        }

        this.stopImagePositioning();

        this.setTransformMode(TransformMode.None);

        workspace.gizmoDrawer.draw();
    }

    forceStopEditText(): void {
        if (this.mode === TransformMode.EditText) {
            this.cancel();
        }
    }

    private onGradientHelperStart = (): void => {
        this.setTransformMode(TransformMode.EditGradient);
    };

    private blur = (): void => {
        this.altKeyIsDown = false;
        this.shiftKeyIsDown = false;
        this.ctrlKeyIsDown = false;
    };

    private onShiftKeyDown(): void {
        this.shiftKeyIsDown = true;
        if (this.savedMousePosition && !this.elementLockStarted && this.currentMousePosition) {
            this.elementLockStarted = true;
            this.moveByLockedDirection(this.currentMousePosition);
        }
    }

    private onShiftKeyUp(): void {
        this.shiftKeyIsDown = false;
        if (this.mode === TransformMode.Resize) {
            this.mutatorService.resetResize();
        }
        this.elementLockStarted = false;
        if (this.mode === TransformMode.Move && this.currentMousePosition) {
            // Move element to current MousePosition when shiftKey is released
            this.mutatorService.move(this.currentMousePosition.x, this.currentMousePosition.y);
        }
    }

    private onAltKeyDown(): void {
        this.altKeyIsDown = true;
        if (this.workspace.zoomBox) {
            this.workspace.zoomBox = undefined;
            this.workspace.gizmoDrawer.draw();
        }
    }

    private onAltKeyUp(): void {
        this.altKeyIsDown = false;
        if (this.mode === TransformMode.Resize) {
            this.mutatorService.resetResize();
        }
        if (this.mode === TransformMode.Zoom) {
            if (this.workspace.canvasMousePosition) {
                this.setWorkspaceZoomBox(this.workspace.canvasMousePosition);
                this.workspace.gizmoDrawer.draw();
            }
        }
    }

    private onEscapeKeyUp(): void {
        this.mediaLibraryService.closeMediaLibrary(
            this.brandLibraryElementEditService.isEditingName$$.value
        );
        this.cancel();
    }

    private onSpaceKeyUp(): void {
        setTimeout(() => {
            this.workspace.wasPanning = false;
        }, 100);
    }

    private onMouseDown = (mouseValue: IMouseValue): void => {
        const { event, mousePosition } = mouseValue;
        const workspace = this.workspace;
        const selection = this.elementSelectionService.currentSelection;
        const element = selection?.element;

        this.didMove = false;
        this.elementStart = { ...mousePosition };

        workspace.disableGuidelinePreview = true;
        this.addedElementsToSelectionInMouseDown = false;
        this.mutatorService.workspaceFocused = true;
        this.guidelineSelection.instance = undefined;
        this.blurAllInputs();
        workspace.contextMenu.tryCloseMenus();

        // Image positioning areas is visible
        if (this.imageSelectionAreas.size) {
            this.isPositioningImage = [...this.imageSelectionAreas.values()].some(e => e.hover);

            // Start moving image
            if (this.isPositioningImage && element?.kind === ElementKind.Image) {
                const imageSettings = element.imageSettings;
                this.imagePositionStart = { x: imageSettings.x, y: imageSettings.y };
                this.rotatedMouseStartPosition = this.rotateMousePosition(element, mousePosition);
                this.setTransformMode(TransformMode.PositionImage);
                this.logger.debug('this.imageSelectionAreas.size');
                return;
            }
            // Stop moving image
            else {
                this.isPositioningImage = false;
            }
        }

        if (
            this.workspace.designView.versionPickerIsOpen ||
            this.workspace.designView.animator?.isPlaying
        ) {
            return;
        }

        if (event.button === 0) {
            this.mouseLeftIsDown = true;
        }
        // Don't do anything when pressing scroll
        else if (event.button === 1) {
            return;
        } else {
            this.mouseLeftIsDown = false;
        }

        if (workspace.isPanning) {
            return;
        }

        if (this.guidelineSelection.isAdding) {
            return;
        }

        workspace.mouseDownTimestamp = Date.now();
        const canvasMousePosition = (this.canvasStartMousePosition =
            workspace.getMousePositionRelativeToCanvas(event));

        let elementUnderMousePosition = this.workspace.findElementAtPosition(canvasMousePosition);
        const elementBoundingBoxUnderMousePosition =
            this.findSelectedElementAtPosition(canvasMousePosition);
        const boundingBox = this.elementSelectionBoundingBoxService.boundingBoxes?.lockedExcluded;
        const guidelineUnderMousePosition = this.getGuidelineUnderMouse(
            this.workspace,
            canvasMousePosition
        );
        const colorPointUnderMouse = this.isMouseUnderColorPoint(workspace, mousePosition);
        const clickOnGuideLine = guidelineUnderMousePosition && !colorPointUnderMouse;
        const activeGuideline = this.guidelineSelection.instance;

        if (elementUnderMousePosition && selection) {
            this.elementSelectionService.latestSelectionType = 'element';
        }

        if (elementUnderMousePosition && isLocked(elementUnderMousePosition)) {
            const allElementsUnderMousePosition =
                this.workspace.findAllElementAtPosition(canvasMousePosition);
            const nonLockedElement = allElementsUnderMousePosition.find(el => !isLocked(el));
            if (allElementsUnderMousePosition.length > 1 && nonLockedElement) {
                elementUnderMousePosition = nonLockedElement;
            }
        }

        if (clickOnGuideLine) {
            this.setGuidelines(workspace, canvasMousePosition);
            this.guidelineSelection.isAdding = true;
        } else if (!activeGuideline) {
            this.onGuidelineChange$.next(this.guidelineSelection.instance);
        }

        if (
            (!elementUnderMousePosition && this.workspace.contextMenuOpen) ||
            this.workspace.isZoomControlHovered
        ) {
            return;
        }

        this.initialElementUnderMouse =
            elementUnderMousePosition || elementBoundingBoxUnderMousePosition;

        if (!this.mouseLeftIsDown && !elementUnderMousePosition) {
            if (this.mode === TransformMode.EditText) {
                this.cancel();
            }
            return;
        }

        if (this.mode === TransformMode.EditText) {
            if (elementUnderMousePosition === selection?.element) {
                return;
            }
            const selectedElement = selection?.element as ITextDataNode;
            const textEditor = this.editorStateService.renderer.getViewElementById<ITextViewElement>(
                selectedElement.id
            )?.__richTextRenderer?.editor_m;
            if (textEditor && textEditor.inEditMode && !textEditor.inTextBounds(event)) {
                this.cancel();
            }

            return;
        }
        // Create new element mode
        else if (this.mode === TransformMode.CreateElement || this.workspace.createElementKind) {
            this.mouseStart = canvasMousePosition;
            this.setTransformMode(TransformMode.CreateElement);
            this.setCreateElementCursor();
            return;
        } else if (this.mode === TransformMode.EditGradient) {
            const colorPoint = workspace.gradientHelper.pointAt(mousePosition.x, mousePosition.y);
            if (colorPoint) {
                workspace.gradientHelper.selectPoint(colorPoint);
                workspace.gradientHelper.draggingPoint = colorPoint;
            } else {
                workspace.gradientHelper.stop();
            }
            return;
        }

        if (boundingBox && selection) {
            const direction = this.getTransformDirection(canvasMousePosition, boundingBox);

            if (direction.resize && element) {
                const selectionOrElement = selection.length === 1 ? selection.element : selection;
                this.setTransformMode(TransformMode.Resize);
                this.resizeDirection = direction.resize;
                this.setMouseStartPosition();
                this.elementSizeRatio = getRatio(boundingBox.width, boundingBox.height);
                this.unindexSelectableElements(selectionOrElement);
                this.mutatorService.resizeStart(selectionOrElement);
                return;
            }

            if (direction.rotate && element) {
                this.setTransformMode(TransformMode.Rotate);
                this.unindexSelectableElements(element);
                this.mutatorService.rotateStart(element);
                return;
            }

            if (selection.length !== 1) {
                let unindexedElements = false;
                for (const selectionElement of selection.elements) {
                    const box = selectionElement;
                    const rotatedCanvasMousePosition = rotatePosition(
                        canvasMousePosition,
                        { x: box.x + box.width / 2, y: box.y + box.height / 2 },
                        box.rotationZ!
                    );

                    if (positionIsInBounds(rotatedCanvasMousePosition, box)) {
                        if (!unindexedElements) {
                            this.unindexSelectableElements(selection);
                            unindexedElements = true;
                        }

                        this.elementStart = {
                            x: boundingBox.x,
                            y: boundingBox.y
                        };

                        if (event.altKey) {
                            this.setTransformMode(TransformMode.Clone);
                        } else {
                            this.setTransformMode(TransformMode.Move);
                        }

                        this.mutatorService.moveStart(selection);
                        return;
                    }
                }
            }
        }

        const mouseElement = elementUnderMousePosition || elementBoundingBoxUnderMousePosition;
        const isMouseElementUnlocked = mouseElement && !isLocked(mouseElement);
        const nodeToSelect = isMouseElementUnlocked
            ? this.elementSelectionService.getGroupOrNode(mouseElement)
            : undefined;

        if (
            nodeToSelect &&
            !this.workspace.isZooming &&
            !isLocked(nodeToSelect) &&
            !guidelineUnderMousePosition
        ) {
            if (this.shiftKeyIsDown || this.ctrlKeyIsDown) {
                if (!selection?.has(nodeToSelect)) {
                    if (this.workspace.designView.timeline.isRecordingKeyframes) {
                        this.elementSelectionService.setSelection(nodeToSelect);
                    } else {
                        this.elementSelectionService.addSelection(nodeToSelect);
                    }
                    this.addedElementsToSelectionInMouseDown = true;
                }
            } else {
                if (this.workspace.designView.timeline.isRecordingKeyframes) {
                    if (isGroupDataNode(nodeToSelect)) {
                        this.elementSelectionService.clearSelection();
                    } else {
                        this.elementSelectionService.setSelection(nodeToSelect);
                    }
                } else {
                    this.elementSelectionService.setSelection(nodeToSelect);
                }
            }

            if (event.altKey) {
                this.setTransformMode(TransformMode.Clone);
            } else {
                this.setTransformMode(TransformMode.Move);
            }
            this.initializeMove();

            return;
        }

        this.selectionNetService.setSelectionNet(undefined);
        workspace.createElementBox = undefined;

        // Only deselect in normal select mode.
        if (!this.shiftKeyIsDown && !this.ctrlKeyIsDown && !this.workspace.isZooming) {
            this.elementSelectionService.clearSelection();
        }

        if (
            this.mode === TransformMode.None &&
            !this.workspace.isZooming &&
            !this.workspace.designView.timeline.isRecordingKeyframes
        ) {
            this.mouseStart = canvasMousePosition;
            this.setTransformMode(TransformMode.Select);
        }
        if (this.mode === TransformMode.None && this.workspace.isZooming) {
            this.mouseStart = canvasMousePosition;
            this.setTransformMode(TransformMode.Zoom);
        }
    };

    private isMouseUnderColorPoint(
        workspace: StudioWorkspaceComponent,
        mousePosition: IPosition
    ): boolean {
        return (
            this.mode === TransformMode.EditGradient &&
            !!workspace.gradientHelper.pointAt(mousePosition.x, mousePosition.y)
        );
    }

    setGuidelines(workspace: StudioWorkspaceComponent, canvasMousePosition: IPosition): void {
        const guidelines = workspace.design.document.guidelines;
        for (const guideline of guidelines) {
            guideline.type === GuidelineType.Vertical
                ? this.findVerticalGuideline(canvasMousePosition.x, guideline)
                : this.findHorizontalGuideline(canvasMousePosition.y, guideline);

            this.workspace.gizmoDrawer.draw();
        }
    }

    getGuidelineUnderMouse(
        workspace: StudioWorkspaceComponent,
        { x, y }: IPosition
    ): IGuideline | undefined {
        const guidelines = workspace.design.document.guidelines;
        for (const guideline of guidelines) {
            if (
                (guideline.type === GuidelineType.Vertical &&
                    isNumberBetween(x, guideline.position.x)) ||
                (guideline.type === GuidelineType.Horizontal &&
                    isNumberBetween(y, guideline.position.y))
            ) {
                return guideline;
            }
        }
    }

    isMouseUnderGuideline(workspace: StudioWorkspaceComponent, { x, y }: IPosition): boolean {
        const guidelines = workspace.design.document.guidelines;
        for (const guideline of guidelines) {
            if (
                (guideline.type === GuidelineType.Vertical &&
                    isNumberBetween(x, guideline.position.x)) ||
                (guideline.type === GuidelineType.Horizontal &&
                    isNumberBetween(y, guideline.position.y))
            ) {
                return true;
            }
        }
        return false;
    }

    private findHorizontalGuideline(positionY: number, guideline: IGuideline): void {
        isNumberBetween(positionY, guideline.position.y)
            ? this.setActiveGuideline(guideline)
            : this.setGuidelineInactive();
    }

    private findVerticalGuideline(positionX: number, guideline: IGuideline): void {
        isNumberBetween(positionX, guideline.position.x)
            ? this.setActiveGuideline(guideline)
            : this.setGuidelineInactive();
    }

    private setActiveGuideline(guideline: IGuideline): void {
        this.workspace.gizmoDrawer.drawGuidelineTooltip = true;
        this.guidelineSelection.instance = guideline;
        this.setTransformMode(TransformMode.Guidelines);
        this.onGuidelineChange$.next(guideline);
    }

    private setGuidelineInactive(): void {
        this.onGuidelineChange$.next(undefined);
    }

    private setWorkspaceZoomBox(canvasMousePosition: IPosition): void {
        if (this.mouseStart) {
            const x = Math.min(this.mouseStart.x, canvasMousePosition.x);
            const y = Math.min(this.mouseStart.y, canvasMousePosition.y);
            const width = Math.abs(this.mouseStart.x - canvasMousePosition.x);
            const height = Math.abs(this.mouseStart.y - canvasMousePosition.y);
            this.workspace.zoomBox = { x, y, width, height };
        }
    }

    private initializeMove(): void {
        const selection = this.elementSelectionService.currentSelection;

        if (!selection) {
            throw new Error('No selection box found when initializing move');
        }

        this.setMouseStartPosition();

        this.mutatorService.moveStart(selection);
        this.unindexSelectableElements(selection);
        this.elementHighlightService.clearHighlight();
    }

    private setMouseStartPosition(): void {
        const boundingBox = this.elementSelectionBoundingBoxService.boundingBoxes?.lockedExcluded;

        if (!boundingBox) {
            throw new Error('No bounding box found when setting start position');
        }

        const { x, y, width, height } = boundingBox;
        this.elementStart = {
            x: x,
            y: y,
            width: width,
            height: height
        };
    }

    private resizeByMouseDelta(mouseDelta: IPosition): void {
        const selection = this.elementSelectionService.currentSelection;
        const boundingBox = this.elementSelectionBoundingBoxService.boundingBoxes?.lockedExcluded;
        if (!selection || !boundingBox) {
            throw new Error('No selection or bounding box found when trying to resize by mouse delta.');
        }

        const selectedElement = selection.element;
        const rotationZ = boundingBox.rotationZ || 0;

        const ratio = this.propertiesService.inStateView
            ? this.propertiesService.stateData?.ratio
            : selectedElement?.ratio;

        /**
         * Normalise the position of delta based on the current rotation of the element.
         * This should really be done through matrix transformations as this is a naïve
         * approach which does not account for multiple elements. It should, however, work
         * fine for a single element.
         */
        const mouseAngle = Math.atan2(-mouseDelta.y, mouseDelta.x);
        const pointDistance = diagonal(mouseDelta.x, -mouseDelta.y);

        const normalizedAngle = rotationZ + mouseAngle;
        const normalizedDelta = {
            x: Math.cos(normalizedAngle) * pointDistance,
            y: -Math.sin(normalizedAngle) * pointDistance
        };

        const offset = this.getOffsetFromMouseDelta(normalizedDelta, this.resizeDirection);

        const selectionHasElementWithRatio = [...selection.elements].some(e => e.ratio !== undefined);
        const keepElementRatio = this.shiftKeyIsDown || selectionHasElementWithRatio || ratio;

        // In preview mode resize should mimic alt key effect
        if (keepElementRatio && !this.mutatorService.preview) {
            this.modifyOffsetByKeepingElementRatio(offset, normalizedDelta);
        }

        if (this.altKeyIsDown || this.mutatorService.preview) {
            this.modifyOffsetByReflectingSize(offset);
        }

        const normalisedOffset = Object.entries(offset).reduce(
            (memo, [key, value]) =>
                value && !isNaN(value)
                    ? { ...memo, [key]: value / this.editorStateService.zoom }
                    : memo,
            {} as Partial<IOffset>
        );

        const snappedOffset = this.adjustOffsetToSnapPointPosition(normalisedOffset);

        this.mutatorService.resize(snappedOffset, this.mutatorService.preview);
    }

    private adjustOffsetToSnapPointPosition(offset: Partial<IOffset>): Partial<IOffset> {
        if (!this.snappingIsEnabled) {
            return offset;
        }

        const startResize = this.mutatorService.startResize;
        const resizeDirection = structuredClone(this.resizeDirection);

        if (this.altKeyIsDown) {
            resizeDirection.top = true;
            resizeDirection.left = true;
            resizeDirection.bottom = true;
            resizeDirection.right = true;
        }

        const top = offset.top ? -offset.top + startResize.y : startResize.y;
        const bottom = offset.bottom
            ? offset.bottom + startResize.y + startResize.height
            : startResize.y + startResize.height;
        const left = offset.left ? -offset.left + startResize.x : startResize.x;
        const right = offset.right
            ? offset.right + startResize.x + startResize.width
            : startResize.x + startResize.width;

        if (resizeDirection.top || resizeDirection.left) {
            const snapPoints = this.getSnapPoints({
                x: left,
                y: top
            });

            if (resizeDirection.left && snapPoints.x && !snapPoints.x.offset) {
                offset.left = startResize.x - snapPoints.x.position;
            }

            if (resizeDirection.top && snapPoints.y && !snapPoints.y.offset) {
                offset.top = startResize.y - snapPoints.y.position;
            }
        }

        if (resizeDirection.bottom || resizeDirection.right) {
            const snapPoints = this.getSnapPoints({
                x: right,
                y: bottom
            });

            if (resizeDirection.right && snapPoints.x && !snapPoints.x.offset) {
                offset.right = snapPoints.x.position - startResize.x - startResize.width;
            }

            if (resizeDirection.bottom && snapPoints.y && !snapPoints.y.offset) {
                offset.bottom = snapPoints.y.position - startResize.y - startResize.height;
            }
        }

        return offset;
    }

    private getAngleOfRotationCursor(rotation: number): number {
        switch (this.rotationLocation) {
            case 'top-right':
                rotation += Math.PI / 4;
                break;
            case 'top-left':
                rotation += (3 * Math.PI) / 4;
                break;
            case 'bottom-left':
                rotation += (5 * Math.PI) / 4;
                break;
            case 'bottom-right':
                rotation += (7 * Math.PI) / 4;
                break;
            default:
                throw new Error('Invalid rotation direction.');
        }
        rotation = this.normalizeRotation(rotation);
        if (rotation >= 2 * Math.PI) {
            rotation = 0;
        }
        return rotation;
    }

    private getPositionToCrossValuesMap(
        elementCorners: IBoundingCornersWithOffsetToTopLeft,
        topLeft: IPosition
    ): IPositionToCrossValuesMap {
        const xToCrossValuesMap = new Map<number, IQueryPoint>();
        const xPoints: number[] = [];
        for (const name in elementCorners) {
            const corner = elementCorners[name];
            const x = corner.position.x;
            const queryPoint = xToCrossValuesMap.get(x);
            if (!queryPoint) {
                xToCrossValuesMap.set(x, {
                    value: x,
                    offset: -corner.offset.x,
                    crossValues: []
                });
            }
            xPoints.push(x);
        }
        const centerX = (Math.min(...xPoints) + Math.max(...xPoints)) / 2;
        const yToCrossValuesMap = new Map<number, IQueryPoint>();
        const yPoints: number[] = [];
        for (const name in elementCorners) {
            const corner = elementCorners[name];
            const y = corner.position.y;
            const queryPoint = yToCrossValuesMap.get(y);
            if (!queryPoint) {
                yToCrossValuesMap.set(y, {
                    value: y,
                    offset: -corner.offset.y,
                    crossValues: []
                });
            }
            yPoints.push(y);
        }
        const centerY = (Math.min(...yPoints) + Math.max(...yPoints)) / 2;

        const queryPointX = xToCrossValuesMap.get(centerX);
        if (!queryPointX && this._mode !== TransformMode.Resize) {
            xToCrossValuesMap.set(centerX, {
                value: centerX,
                offset: topLeft.x - centerX,
                crossValues: []
            });
        }
        const queryPointY = yToCrossValuesMap.get(centerY);
        if (!queryPointY && this._mode !== TransformMode.Resize) {
            yToCrossValuesMap.set(centerY, {
                value: centerY,
                offset: topLeft.y - centerY,
                crossValues: []
            });
        }

        return {
            xToCrossValuesMap,
            yToCrossValuesMap
        };
    }

    private getSnapLines(): ISnapLines {
        const selection = this.elementSelectionService.currentSelection;
        const boundingBox = this.elementSelectionBoundingBoxService.boundingBoxes?.lockedExcluded;

        if (!selection || !boundingBox) {
            throw new Error('Trying to get snap lines with no selection or bounding box');
        }

        const selectedElementsCorners = this.workspace.getBoundingCorners(
            boundingBox,
            'canvas',
            0,
            true
        );
        const horizontalSnapLines = new Map<number, ISnapLine>();
        const verticalSnapLines = new Map<number, ISnapLine>();

        for (const name in selectedElementsCorners) {
            const corner = selectedElementsCorners[name];
            const cornerX = name === 'center' ? corner.x : Math.round(corner.x);
            const cornerY = name === 'center' ? corner.y : Math.round(corner.y);
            const xKey = this.xPoints.findKey(cornerX);
            if (xKey) {
                const snapLine = horizontalSnapLines.get(cornerX);
                if (snapLine) {
                    if (snapLine.start > cornerY) {
                        snapLine.start = cornerY;
                    }
                    if (snapLine.end < cornerY) {
                        snapLine.end = cornerY;
                    }
                    const points = xKey.values.map(v => v.value.crossValue);
                    for (const point of points) {
                        if (snapLine.start > point) {
                            snapLine.start = point;
                        }
                        if (snapLine.end < point) {
                            snapLine.end = point;
                        }
                        snapLine.circles.add(point);
                    }
                } else {
                    horizontalSnapLines.set(cornerX, {
                        base: cornerX,
                        start: Math.min(cornerY, ...xKey.values.map(v => v.value.crossValue)),
                        end: Math.max(cornerY, ...xKey.values.map(v => v.value.crossValue)),
                        circles: new Set<number>(xKey.values.map(v => v.value.crossValue))
                    });
                }
            }

            const yKey = this.yPoints.findKey(cornerY);
            if (yKey) {
                const snapLine = verticalSnapLines.get(cornerY);
                if (snapLine) {
                    if (snapLine.start > cornerX) {
                        snapLine.start = cornerX;
                    }
                    if (snapLine.end < cornerX) {
                        snapLine.end = cornerX;
                    }
                    const points = yKey.values.map(v => v.value.crossValue);
                    for (const point of points) {
                        if (snapLine.start > point) {
                            snapLine.start = point;
                        }
                        if (snapLine.end < point) {
                            snapLine.end = point;
                        }
                        snapLine.circles.add(point);
                    }
                } else {
                    verticalSnapLines.set(cornerY, {
                        base: cornerY,
                        start: Math.min(cornerX, ...yKey.values.map(v => v.value.crossValue)),
                        end: Math.max(cornerX, ...yKey.values.map(v => v.value.crossValue)),
                        circles: new Set<number>(yKey.values.map(v => v.value.crossValue))
                    });
                }
            }
        }

        return {
            vertical: Array.from(verticalSnapLines.values()),
            horizontal: Array.from(horizontalSnapLines.values())
        };
    }

    private getSnapPoints(
        mousePosition: IPosition,
        width?: number,
        height?: number,
        rotationZ?: number
    ): ISnapPoints {
        const selection = this.elementSelectionService.currentSelection;
        const boundingBox =
            this.elementSelectionBoundingBoxService.boundingBoxes?.lockedAndHiddenExcluded;

        if (!selection || !boundingBox) {
            throw new Error('Trying to get snap points without selection or selection bounding box');
        }

        const corners = this.workspace.getBoundingCornersWithOffsetToTopLeft(
            {
                x: Math.round(mousePosition.x),
                y: Math.round(mousePosition.y),
                width: width ? width : boundingBox.width,
                height: height ? height : boundingBox.height,
                rotationZ: rotationZ ? rotationZ : boundingBox.rotationZ
            },
            'canvas'
        );
        const { xToCrossValuesMap, yToCrossValuesMap } = this.getPositionToCrossValuesMap(corners, {
            x: Math.round(mousePosition.x),
            y: Math.round(mousePosition.y)
        });
        const zoom = this.editorStateService.zoom;
        const tolerance = SNAP_TOLERANCE / zoom;

        return {
            x: this.getXSnapPoint(xToCrossValuesMap.values(), tolerance),
            y: this.getYSnapPoint(yToCrossValuesMap.values(), tolerance)
        };
    }

    private onMouseMove = (mouseValue: IMouseValue): void => {
        const { event, mousePosition } = mouseValue;

        const workspace = this.workspace;
        workspace.mousePosition = mousePosition;

        if (
            this.keyframeService.actionMode !== KeyframeAction.None ||
            this.workspace.designView.timeline?.animator.isPlaying ||
            this.workspace.isPanning
        ) {
            return;
        }

        const canvasMousePosition = workspace.getMousePositionRelativeToCanvas(event);
        const selection = this.elementSelectionService.currentSelection;
        const isGroupSelected = isGroupDataNode(selection.nodes[0]);
        const boundingBoxes = this.elementSelectionBoundingBoxService.boundingBoxes;
        const boundingBox = isGroupSelected
            ? boundingBoxes?.fullSelection
            : boundingBoxes?.lockedExcluded;

        if (selection.length === 1 && selection.element && isHidden(selection.element)) {
            return;
        }

        const isMouseOnWorkspace = isElementDescendantOfElement(
            workspace.host.nativeElement,
            event.target
        );

        workspace.canvasMousePosition = canvasMousePosition;
        const imagePositionHovered = [...this.imageSelectionAreas.values()].some(e => e.hover);

        if (imagePositionHovered && !workspace.isPanning) {
            workspace.designView.setCursor(workspace.host.nativeElement, 'image-position-0');
        }

        if (
            !this.workspace.isPanning &&
            !imagePositionHovered &&
            this.mode !== TransformMode.Rotate &&
            this.mode !== TransformMode.Resize &&
            this.mode !== TransformMode.EditText
        ) {
            const cursor = this.isElementDragging ? 'pointer' : 'selection-0';
            this.workspace.designView.setCursor(this.workspace.host.nativeElement, cursor);
        }

        if (workspace.designView.versionPickerIsOpen || workspace.isResizingCanvas) {
            return;
        }

        if (this.mode === TransformMode.EditText) {
            return;
        }

        if (
            this.mode === TransformMode.Guidelines &&
            this.guidelineSelection.instance &&
            !this.guidelineSelection.instance.social
        ) {
            this.moveGuideline(workspace, canvasMousePosition, this.guidelineSelection.instance);
            this.workspace.gizmoDrawer.draw();
            if (!this.guidelineSelection.hasMoved && this.guidelineSelection.isAdding) {
                this.workspace.isEditingGuideline$.next(true);
                this.guidelineSelection.hasMoved = true;
            }
            return;
        }

        if (this.imageSelectionAreas.size) {
            workspace.gizmoDrawer.draw();
        }

        const guidelineUnderMouse = this.getGuidelineUnderMouse(workspace, canvasMousePosition);
        const isMouseUnderGuideline = this.isMouseUnderGuideline(workspace, canvasMousePosition);
        const colorPointUnderMouse = this.isMouseUnderColorPoint(workspace, mousePosition);

        if (
            isMouseUnderGuideline &&
            guidelineUnderMouse &&
            !colorPointUnderMouse &&
            !guidelineUnderMouse.social
        ) {
            switch (guidelineUnderMouse.type) {
                case GuidelineType.Vertical:
                    this.workspace.designView.setCursor(this.workspace.host.nativeElement, 'resize-0');
                    break;
                case GuidelineType.Horizontal:
                    this.workspace.designView.setCursor(this.workspace.host.nativeElement, 'resize-90');
                    break;
            }
            return;
        }

        // Create new element mode
        if (this.workspace.createElementKind) {
            this.setCreateElementCursor();
        } else {
            if (!workspace.isZooming) {
                if (!this.actionIsAllowedOnSelection()) {
                    return;
                }

                switch (this.mode) {
                    case TransformMode.Resize:
                        this.setResizeCursor(this.elementResizeRatio);
                        return;

                    case TransformMode.Rotate: {
                        if (this.mutatorService.preview) {
                            return;
                        }
                        if (!selection || !boundingBox) {
                            throw new Error(
                                'Cannot rotate as a selection or bounding box does not exist'
                            );
                        }
                        const elementRotation = boundingBox.rotationZ || 0;
                        const cursorRotation = this.getAngleOfRotationCursor(-elementRotation);
                        this.setRotationCursor(cursorRotation);
                        return;
                    }

                    case TransformMode.Move:
                        if (
                            this.mutatorService.preview ||
                            !this.initialElementUnderMouse ||
                            this.guidelineSelection.instance
                        ) {
                            return;
                        }
                        if (!selection) {
                            throw new Error('Cannot move as a selection does not exist');
                        }

                        this.elementHighlightService.clearHighlight();
                        return;

                    case TransformMode.None: {
                        const boundingBoxWithDefault = boundingBox || {
                            x: 0,
                            y: 0,
                            height: 0,
                            width: 0,
                            rotationZ: undefined
                        };
                        const direction = this.getTransformDirection(
                            canvasMousePosition,
                            boundingBoxWithDefault
                        );

                        if (direction.resize) {
                            const resize = direction.resize;
                            let rotation = 0;
                            rotation = -(boundingBoxWithDefault.rotationZ || 0);

                            if ((resize.top && resize.left) || (resize.bottom && resize.right)) {
                                rotation += (3 * Math.PI) / 4;
                            } else if ((resize.top && resize.right) || (resize.bottom && resize.left)) {
                                rotation += Math.PI / 4;
                            } else if (resize.top || resize.bottom) {
                                rotation += Math.PI / 2;
                            } else if (resize.left || resize.right) {
                                rotation += 0;
                            } else {
                                throw new Error('Invalid resize direction.');
                            }

                            rotation = this.normalizeRotation(rotation);

                            if (rotation >= 2 * Math.PI) {
                                rotation = 0;
                            }

                            this.setResizeCursor(rotation);
                            this.elementResizeRatio = rotation;

                            return;
                        }

                        if (direction.rotate) {
                            if (selection.elements.length === 1) {
                                const element = selection.element;
                                this.rotationLocation = direction.rotate;
                                this.lastRotation = this.getMouseElementRotationAngle(
                                    canvasMousePosition,
                                    element!,
                                    false
                                );
                                const rotation = this.getAngleOfRotationCursor(
                                    -(boundingBox?.rotationZ || 0)
                                );
                                this.setRotationCursor(rotation);
                            }
                            return;
                        } else {
                            if (!this.workspace.isPanning && !imagePositionHovered) {
                                const cursor = this.isElementDragging ? 'pointer' : 'selection-0';
                                this.workspace.designView.setCursor(
                                    workspace.host.nativeElement,
                                    cursor
                                );
                            }
                        }
                        break;
                    }
                }
            }
        }

        let nodeUnderMousePosition = workspace.findElementAtPosition(canvasMousePosition);
        if (
            nodeUnderMousePosition &&
            isMouseOnWorkspace &&
            !workspace.createElementKind &&
            !workspace.isZooming
        ) {
            if (isLocked(nodeUnderMousePosition)) {
                const allNodesUnderMousePosition =
                    this.workspace.findAllElementAtPosition(canvasMousePosition);
                const nonLockedNode = allNodesUnderMousePosition.find(el => !isLocked(el));
                if (allNodesUnderMousePosition.length > 1 && nonLockedNode) {
                    nodeUnderMousePosition = nonLockedNode;
                }
            }
            if (selection && selection.length > 0) {
                // Don't highlight elements inside a selection
                if (selection.has(nodeUnderMousePosition)) {
                    return;
                }
            }
            if (!isLocked(nodeUnderMousePosition)) {
                this.elementHighlightService.setHighlight(nodeUnderMousePosition, 'workspace');
            } else {
                if (
                    this.workspace.designView.timeline &&
                    !this.workspace.designView.timeline.animator.isPlaying
                ) {
                    this.workspace.designView.setCursor(
                        this.workspace.host.nativeElement,
                        'selection-locked-0'
                    );
                }
            }
            if (this.isMouseUnderGuideline(workspace, canvasMousePosition)) {
                this.elementHighlightService.clearHighlight();
            }
        } else {
            if (isMouseOnWorkspace) {
                this.elementHighlightService.clearHighlight();
            }
        }
    };

    private async onMouseDownMove(mouseValue: IMouseDownMove): Promise<void> {
        const { event, mousePosition, mouseDownMoved } = mouseValue;
        const mouseDelta = mouseValue.mouseDelta || { x: 0, y: 0 };

        if (
            this.keyframeService.actionMode !== KeyframeAction.None ||
            this.workspace.designView.timeline?.animator.isPlaying
        ) {
            return;
        }

        const workspace = this.workspace;
        const canvasMousePosition = workspace.getMousePositionRelativeToCanvas(event);
        const selection = this.elementSelectionService.currentSelection;
        const notHiddenElements = this.elementSelectionService.currentSelection.elements.filter(
            element => !isHidden(element)
        );
        const boundingBox = this.elementSelectionBoundingBoxService.boundingBoxes?.lockedExcluded;
        const isMouseOnWorkspace = isElementDescendantOfElement(
            workspace.host.nativeElement,
            event.target
        );

        if (
            this.isPositioningImage &&
            mouseDelta &&
            notHiddenElements[0] &&
            this.rotatedMouseStartPosition &&
            !workspace.isPanning
        ) {
            this.positionImage(mousePosition);
            return;
        }

        if (isMouseOnWorkspace) {
            this.elementHighlightService.clearHighlight();
        }

        if (workspace.designView.versionPickerIsOpen || workspace.isResizingCanvas) {
            return;
        }

        if (this.mode === TransformMode.EditText) {
            return;
        }

        if (
            this.mode === TransformMode.Guidelines &&
            this.guidelineSelection.instance &&
            !this.guidelineSelection.instance.social
        ) {
            this.moveGuideline(workspace, canvasMousePosition, this.guidelineSelection.instance);
            this.workspace.gizmoDrawer.draw();
            if (!this.guidelineSelection.hasMoved && this.guidelineSelection.isAdding) {
                this.workspace.isEditingGuideline$.next(true);
                this.guidelineSelection.hasMoved = true;
            }
            return;
        }

        if (this.imageSelectionAreas.size) {
            workspace.gizmoDrawer.draw();
        }

        const x = this.elementStart.x + mouseDelta.x / this.editorStateService.zoom;
        const y = this.elementStart.y + mouseDelta.y / this.editorStateService.zoom;
        this.currentMousePosition = { x, y };

        if (notHiddenElements.length > 0) {
            if (
                notHiddenElements[0] &&
                !isSelectionVisibleAtTime(notHiddenElements[0], workspace.designView.time)
            ) {
                return;
            }

            if (!workspace.isZooming) {
                if (!this.actionIsAllowedOnSelection()) {
                    return;
                }

                switch (this.mode) {
                    case TransformMode.Resize:
                        if (mouseDelta) {
                            this.resizeByMouseDelta(mouseDelta);
                        }
                        return;

                    case TransformMode.Rotate: {
                        let angleDiff = this.getRotationAngleDiff(canvasMousePosition);
                        const elementRotation = boundingBox?.rotationZ || 0;

                        if (this.shiftKeyIsDown) {
                            const normalizedRotation = this.normalizeRotation(-elementRotation);
                            const newRotation = normalizedRotation + angleDiff;
                            const roundedRotation = roundToNearestMultiple(newRotation, Math.PI / 12);
                            angleDiff = roundedRotation - normalizedRotation;
                        }
                        this.mutatorService.rotate(angleDiff);
                        this.lastRotation! = this.normalizeRotation(this.lastRotation! + angleDiff);
                        return;
                    }

                    case TransformMode.Move:
                        if (
                            this.mutatorService.preview ||
                            !this.initialElementUnderMouse ||
                            this.guidelineSelection.instance ||
                            !this.mouseLeftIsDown
                        ) {
                            return;
                        }

                        if (this.savedMousePosition === undefined) {
                            this.savedMousePosition = { x, y };
                        }

                        if (event.shiftKey) {
                            this.moveByLockedDirection({ x, y });
                        } else if (mouseDownMoved) {
                            const { x: xSnapPoint, y: ySnapPoint } = this.getSnapPoints({ x, y });

                            const shouldSnap = this.snappingIsEnabled;
                            const newX =
                                shouldSnap && xSnapPoint ? xSnapPoint.position + xSnapPoint.offset : x;
                            const newY =
                                shouldSnap && ySnapPoint ? ySnapPoint.position + ySnapPoint.offset : y;

                            this.mutatorService.move(newX, newY);

                            this.elementHighlightService.clearHighlight();
                        }

                        this.didMove = true;
                        return;

                    case TransformMode.Clone:
                        if (mouseDownMoved) {
                            if (this.mode === TransformMode.Clone) {
                                await workspace.designView.cloneSelection();
                                this.unindexSelectableElements(selection);
                                this.mutatorService.moveStart(
                                    this.elementSelectionService.currentSelection
                                );

                                this.setTransformMode(TransformMode.Move);
                                this.isCloning = true;
                            } else {
                                this.mutatorService.move(x, y);
                            }
                        }
                        return;
                }
            }
        }

        if (this.mode === TransformMode.CreateElement) {
            const direction: IResizeDirection = {
                left: mouseDelta.x < 0,
                right: mouseDelta.x >= 0,
                top: mouseDelta.y < 0,
                bottom: mouseDelta.y >= 0
            };

            const offset = this.getOffsetFromMouseDelta(mouseDelta, direction);

            offset.top =
                offset.top !== undefined
                    ? Math.round((offset.top || 0) / this.editorStateService.zoom)
                    : undefined;
            offset.right =
                offset.right !== undefined
                    ? Math.round((offset.right || 0) / this.editorStateService.zoom)
                    : undefined;
            offset.bottom =
                offset.bottom !== undefined
                    ? Math.round((offset.bottom || 0) / this.editorStateService.zoom)
                    : undefined;
            offset.left =
                offset.left !== undefined
                    ? Math.round((offset.left || 0) / this.editorStateService.zoom)
                    : undefined;

            if (this.shiftKeyIsDown) {
                this.elementSizeRatio = 1;
                this.modifyOffsetByKeepingElementRatio(offset, mouseDelta);
            }
            if (this.altKeyIsDown) {
                this.modifyOffsetByReflectingSize(offset);
            }
            this.elementHighlightService.clearHighlight();
            const x = this.mouseStart!.x - (offset.left || 0);
            const y = this.mouseStart!.y - (offset.top || 0);
            const width = Math.abs((offset.left || 0) + (offset.right || 0));
            const height = Math.abs((offset.top || 0) + (offset.bottom || 0));
            workspace.createElementBox = { x, y, width, height };
            workspace.gizmoDrawer.draw();
            return;
        }

        if (this.mode === TransformMode.Select && !workspace.isZooming) {
            const x = Math.min(this.mouseStart!.x, canvasMousePosition.x);
            const y = Math.min(this.mouseStart!.y, canvasMousePosition.y);
            const width = Math.abs(this.mouseStart!.x - canvasMousePosition.x);
            const height = Math.abs(this.mouseStart!.y - canvasMousePosition.y);
            const selectionNet = { x, y, width, height };
            this.selectionNetService.setSelectionNet(selectionNet);
            const nodesInSelectionNet: OneOfDataNodes[] = [];
            const elements = this.mutatorService.renderer.creativeDocument.elements;
            elements.forEach(element => {
                if (
                    this.elementInSelectionNet(element, selectionNet) &&
                    !isLocked(element) &&
                    isElementVisibleAtTime(element, this.editorStateService.renderer.time_m)
                ) {
                    let parentNode: IGroupElementDataNode | undefined;
                    forEachParentNode(element, parent => {
                        parentNode = parent;
                    });

                    if (parentNode) {
                        nodesInSelectionNet.push(parentNode);
                    } else {
                        nodesInSelectionNet.push(element);
                    }
                }
            }, workspace.designView.time);

            if (this.shiftKeyIsDown) {
                const inverseElements = nodesInSelectionNet.reduce(
                    (memo, element) => {
                        const elementIndex = memo.findIndex(({ id }) => element.id === id);
                        if (elementIndex !== -1) {
                            return [...memo.slice(0, elementIndex), ...memo.slice(elementIndex + 1)];
                        } else {
                            return [...memo, element];
                        }
                    },
                    [...this.elementSelectionService.currentSelection.nodes]
                );
                this.elementSelectionService.setSelection(...inverseElements);
            } else {
                this.elementSelectionService.setSelection(...nodesInSelectionNet);
            }

            return;
        } else if (this.mode === TransformMode.Zoom && !workspace.isPanning && workspace.isZooming) {
            if (!event.altKey) {
                this.setWorkspaceZoomBox(canvasMousePosition);
                return;
            } else {
                workspace.zoomBox = undefined;
            }
        }

        if (this.mode === TransformMode.EditGradient) {
            if (this.workspace.gradientHelper.draggingPoint) {
                this.workspace.gradientHelper.movePoint(mousePosition.x, mousePosition.y);
            }
        }
    }

    setSnaplines(): void {
        const { horizontal, vertical } = this.getSnapLines();

        if (this.snappingIsEnabled) {
            this.workspace.horizontalSnapLines = Array.from(horizontal.values());
            this.workspace.verticalSnapLines = Array.from(vertical.values());
        } else {
            this.workspace.horizontalSnapLines = [];
            this.workspace.verticalSnapLines = [];
        }
    }

    private positionImage(mousePosition: IPosition): void {
        const element = this.elementSelectionService.currentSelection.element;
        const startPosition = this.imagePositionStart;
        const mouseStart = this.rotatedMouseStartPosition;

        if (element?.kind === ElementKind.Image && mouseStart && startPosition) {
            const { imageAsset } = element;

            const rotatedMousePosition = this.rotateMousePosition(element, mousePosition);
            const moveX = (rotatedMousePosition.x - mouseStart.x) * (element.mirrorX ? -1 : 1);
            const moveY = (rotatedMousePosition.y - mouseStart.y) * (element.mirrorY ? -1 : 1);

            const renderedImageSize = {
                ...aspectRatioScale(imageAsset as ISize, element, 'cover'),
                x: 0,
                y: 0
            };
            const zoom = this.editorStateService.zoom;
            // This is the total length in pixels the image can be moved
            const overflowWidth = (element.width - renderedImageSize.width) * zoom;
            const overflowHeight = (element.height - renderedImageSize.height) * zoom;

            const x = startPosition.x + moveX / overflowWidth;
            const y = startPosition.y + moveY / overflowHeight;

            const valuesToUpdate: Partial<IPosition> = {};

            if (isFinite(x)) {
                valuesToUpdate.x = clamp(x, 0, 1);
            }
            if (isFinite(y)) {
                valuesToUpdate.y = clamp(y, 0, 1);
            }

            if (Object.keys(valuesToUpdate).length) {
                this.workspace.mutatorService.setImageSettings(
                    element,
                    valuesToUpdate,
                    ElementChangeType.Burst
                );
            }
        }
    }

    private rotateMousePosition(element: OneOfElementDataNodes, mousePosition: IPosition): IPosition {
        const elementPosition = this.workspace.getPositionRelativeToWorkspace(element);
        const origin = {
            x: elementPosition.x + (element.width * this.editorStateService.zoom) / 2,
            y: elementPosition.y + (element.height * this.editorStateService.zoom) / 2
        };
        return rotatePosition(mousePosition, origin, element.rotationZ);
    }

    private moveGuideline(
        workspace: StudioWorkspaceComponent,
        canvasMousePosition: IPosition,
        guideline: IGuideline
    ): void {
        const { height, width } = workspace.host.nativeElement.getBoundingClientRect();

        guideline.type === GuidelineType.Vertical
            ? this.moveVerticalGuideline(canvasMousePosition, height, guideline)
            : this.moveHorizontalGuideline(canvasMousePosition, width, guideline);
        this.workspace.gizmoDrawer.drawGuidelineTooltip = true;
    }

    private moveHorizontalGuideline(
        canvasMousePosition: IPosition,
        width: number,
        guideline: IGuideline
    ): void {
        const { y: ySnapPosition } = this.getSnapPoints(canvasMousePosition, width, 1, 0);
        this.workspace.designView.setCursor(this.workspace.host.nativeElement, 'resize-90');
        const newYPosition = ySnapPosition ? ySnapPosition.position : canvasMousePosition.y;

        const roundedNewYPosition = Math.round(newYPosition);

        if (guideline.position.y !== roundedNewYPosition) {
            guideline.position.y = roundedNewYPosition;
            this.onGuidelineChange$.next(guideline);
        }
    }

    private moveVerticalGuideline(
        canvasMousePosition: IPosition,
        height: number,
        guideline: IGuideline
    ): void {
        const { x: xSnapPosition } = this.getSnapPoints(canvasMousePosition, 1, height, 0);
        this.workspace.designView.setCursor(this.workspace.host.nativeElement, 'resize-0');
        const newXPosition = xSnapPosition ? xSnapPosition.position : canvasMousePosition.x;

        const roundedNewXPosition = Math.round(newXPosition);

        if (guideline.position.x !== roundedNewXPosition) {
            guideline.position.x = roundedNewXPosition;
            this.onGuidelineChange$.next(guideline);
        }
    }

    private getXSnapPoint(
        points: IterableIterator<IQueryPoint>,
        tolerance: number
    ): ISnapPoint | undefined {
        return this.getSnapPoint(points, this.xPoints, tolerance);
    }

    private getYSnapPoint(
        points: IterableIterator<IQueryPoint>,
        tolerance: number
    ): ISnapPoint | undefined {
        return this.getSnapPoint(points, this.yPoints, tolerance);
    }

    private getSnapPoint(
        points: IterableIterator<IQueryPoint>,
        bTreeIndex: BTreeIndex<ICrossValue>,
        tolerance: number
    ): ISnapPoint | undefined {
        let closestDistance = Infinity;
        let closestPoint: IPointResult | undefined;
        for (const point of points) {
            const item = bTreeIndex.closest(point.value, tolerance);
            if (item) {
                if (item.distance < closestDistance) {
                    closestDistance = item.distance;
                    closestPoint = {
                        item,
                        point
                    };
                }
            }
        }
        if (closestPoint) {
            const item = closestPoint.item;
            const point = closestPoint.point;

            return {
                position: item.key,
                offset: point.offset
            };
        }

        return undefined;
    }

    private moveByLockedDirection(mousePosition: IPosition): void {
        const direction = this.getLockedDirection(mousePosition);
        const { x: xSnapPoint, y: ySnapPoint } = this.getSnapPoints({
            x: direction === LockedDirection.HORIZONTAL ? this.savedMousePosition!.x : mousePosition.x,
            y: direction === LockedDirection.VERTICAL ? this.savedMousePosition!.y : mousePosition.y
        });

        const shouldSnap = this.snappingIsEnabled;

        let x = mousePosition.x;
        if (direction === LockedDirection.HORIZONTAL) {
            x = this.elementStart.x;
        } else if (shouldSnap && xSnapPoint) {
            x = Math.round(xSnapPoint.position + xSnapPoint.offset);
        }

        let y = mousePosition.y;
        if (direction === LockedDirection.VERTICAL) {
            y = this.elementStart.y;
        } else if (shouldSnap && ySnapPoint) {
            y = Math.round(ySnapPoint.position + ySnapPoint.offset);
        }

        this.mutatorService.move(x, y);
    }

    private getLockedDirection(position: IPosition): LockedDirection {
        const xDiff = Math.abs(this.savedMousePosition!.x - position.x);
        const yDiff = Math.abs(this.savedMousePosition!.y - position.y);

        if (xDiff > yDiff) {
            return LockedDirection.VERTICAL;
        }
        return LockedDirection.HORIZONTAL;
    }

    private elementInSelectionNet(element: IBoundingBox, selectionNet: IBoundingBox): boolean {
        const elementCorners = this.workspace.getBoundingCorners(element, 'workspace');
        const selectionNetCorners = this.workspace.getBoundingCorners(selectionNet, 'workspace');

        return polygonIsIntersecting(
            [
                elementCorners.topLeft,
                elementCorners.topRight,
                elementCorners.bottomRight,
                elementCorners.bottomLeft
            ],
            [
                selectionNetCorners.topLeft,
                selectionNetCorners.topRight,
                selectionNetCorners.bottomRight,
                selectionNetCorners.bottomLeft
            ]
        );
    }

    private normalizeRotation(rotation: number): number {
        let newRotation = rotation;
        const oneRotation = 2 * Math.PI;
        while (newRotation >= oneRotation) {
            newRotation -= oneRotation;
        }
        while (newRotation < 0) {
            newRotation += oneRotation;
        }
        return newRotation;
    }

    private getRotationAngleDiff(canvasMousePosition: IPosition): number {
        const selection = this.elementSelectionService.currentSelection;
        const element = selection?.element;
        if (element) {
            return this.getMouseElementRotationAngle(canvasMousePosition, element, true);
        }
        throw new Error('Should not reach here');
    }

    private getMouseElementRotationAngle(
        canvasMousePosition: IPosition,
        element: OneOfSelectableElements,
        diffFromListRotation: boolean
    ): number {
        const { x, y } = this.getMouseElementPosition(canvasMousePosition, element);
        if (x === 0) {
            if (y >= 0) {
                const angle = Math.PI / 2;
                if (diffFromListRotation) {
                    return angle - this.lastRotation!;
                }
                return angle;
            } else {
                const angle = (3 * Math.PI) / 2;
                if (diffFromListRotation) {
                    return angle - this.lastRotation!;
                }
                return angle;
            }
        }
        let angle = Math.atan(y / Math.abs(x));
        if (x < 0) {
            angle = Math.PI - angle;
        } else if (x >= 0 && y < 0) {
            angle = 2 * Math.PI + angle;
        }
        if (angle >= 2 * Math.PI) {
            angle = 0;
        }
        if (diffFromListRotation) {
            // If mouse is in the first quadrant and last rotation is in the last.
            if (this.lastRotation! - angle > Math.PI / 2) {
                return 2 * Math.PI + angle - this.lastRotation!;
            }

            // The reverse case
            else if (angle - this.lastRotation! > Math.PI / 2) {
                return angle - (2 * Math.PI + this.lastRotation!);
            }
            return angle - this.lastRotation!;
        }
        return angle;
    }

    private getMouseElementPosition(position: IPosition, element: OneOfSelectableElements): IPosition {
        const boundingBox = this.mutatorService.selectionBoundingBox(element);
        return {
            x: position.x - (boundingBox.x + boundingBox.width / 2),
            y: boundingBox.y + boundingBox.height / 2 - position.y
        };
    }

    private resetShiftLockPositions(): void {
        this.savedMousePosition = undefined;
        this.currentMousePosition = undefined;
    }

    private stopImagePositioning(): void {
        this.isPositioningImage = false;
        this.imageSelectionAreas.clear();
    }

    private onMouseUp = async (event: MouseEvent): Promise<void> => {
        this.resetShiftLockPositions();
        if (this.isPositioningImage) {
            this.stopImagePositioning();
        }

        const workspace = this.workspace;

        workspace.zoomBox = undefined;
        workspace.gizmoDrawer.xHelpers = [];
        workspace.gizmoDrawer.yHelpers = [];
        workspace.horizontalSnapLines = [];
        workspace.verticalSnapLines = [];
        workspace.disableGuidelinePreview = false;

        const selection = this.elementSelectionService.currentSelection;
        const boundingBox =
            this.elementSelectionBoundingBoxService.boundingBoxes?.lockedAndHiddenExcluded;
        const canvasMousePosition = workspace.getMousePositionRelativeToCanvas(event);
        let elementUnderMousePosition = workspace.findElementAtPosition(canvasMousePosition);
        const mouseDownTime = Date.now() - (workspace.mouseDownTimestamp || 0);

        if (elementUnderMousePosition && isLocked(elementUnderMousePosition)) {
            const allElementsUnderMousePosition =
                this.workspace.findAllElementAtPosition(canvasMousePosition);
            const nonLockedElement = allElementsUnderMousePosition.find(el => !isLocked(el));
            if (allElementsUnderMousePosition.length > 1 && nonLockedElement) {
                elementUnderMousePosition = nonLockedElement;
            }
        }

        if (event.button === 0) {
            this.mouseLeftIsDown = false;
        }

        if (this.guidelineSelection.instance) {
            this.removeActiveGuideline();
            this.guidelineSelection.hasMoved = false;
            this.workspace.isEditingGuideline$.next(false);
            this.editorEventService.creative.change(
                'guidelines',
                workspace.design.document.guidelines,
                ElementChangeType.Force
            );
        }

        if (boundingBox && this.elementStart) {
            const selectionMoved =
                this.elementStart.x !== boundingBox.x || this.elementStart.y !== boundingBox.y;
            const selectionResized =
                (this.elementStart.width && this.elementStart.width !== boundingBox.width) ||
                (this.elementStart.height && this.elementStart.height !== boundingBox.height);
            const movedOrResized = selectionMoved || selectionResized;
            const hasKeyModifier = this.shiftKeyIsDown || this.ctrlKeyIsDown;

            // Only deselect elements if shift is down and there is no added elements
            // and the selection hasn't changed in position and size.
            if (
                this.workspace.transform.mode !== TransformMode.EditText &&
                elementUnderMousePosition &&
                hasKeyModifier &&
                !movedOrResized &&
                !this.addedElementsToSelectionInMouseDown
            ) {
                const groupOrElement = this.elementSelectionService.getGroupOrNode(
                    elementUnderMousePosition.__parentNode || elementUnderMousePosition
                );
                if (groupOrElement) {
                    this.elementSelectionService.deleteSelection(groupOrElement);
                }
            }
        }

        switch (this.mode) {
            case TransformMode.Resize:
                this.resizeDirection = {};
                this.mutatorService.resizeEnd();
                this.indexSelectableElements(selection);
                this.setTransformMode(TransformMode.None);
                break;
            case TransformMode.Rotate:
                this.mutatorService.rotateEnd();
                this.indexSelectableElements(selection);
                this.setTransformMode(TransformMode.None);
                break;
            case TransformMode.Move:
                if (this.didMove) {
                    this.mutatorService.moveEnd();
                }
                this.indexSelectableElements(selection);
                this.setTransformMode(TransformMode.None);
                this.didMove = false;
                break;
            case TransformMode.Select:
            case TransformMode.PositionImage:
                this.setTransformMode(TransformMode.None);
                break;
            case TransformMode.Zoom:
                this.workspace.zoomBox = undefined;
                break;
            case TransformMode.CreateElement: {
                const box = workspace.createElementBox;

                // Clicks that have changed position between down and up state should get default size
                if (box && mouseDownTime < 500 && (box.width < 10 || box.height < 10)) {
                    box.width = 0;
                    box.height = 0;
                }

                const el = await workspace.createNewElement(box || canvasMousePosition);

                if (isTextNode(el)) {
                    this.setTransformMode(TransformMode.EditText);
                } else {
                    this.setTransformMode(TransformMode.None);
                }

                break;
            }
            case TransformMode.EditGradient:
                workspace.gradientHelper.stopDrag();
                break;
            case TransformMode.Guidelines:
                this.setTransformMode(TransformMode.None);
                this.workspace.isEditingGuideline$.next(false);
                break;
        }

        workspace.stopCreateNewElement();
        this.indexElementPoints();
        this.selectionNetService.setSelectionNet(undefined);
        this.workspace.gizmoDrawer.drawGuidelineTooltip = false;
        this.guidelineSelection.isAdding = false;

        if (this.isCloning) {
            this.isCloning = false;
        }
    };

    private onMouseDoubleClick = (event: MouseEvent): void => {
        const workspace = this.workspace;
        const selection = this.elementSelectionService.currentSelection;
        const canvasMousePosition = workspace.getMousePositionRelativeToCanvas(event);
        const nodeUnderMousePosition = this.workspace.findElementAtPosition(canvasMousePosition);

        if (this.mutatorService.preview || !nodeUnderMousePosition) {
            return;
        }

        if (this.workspace.designView.timeline.isRecordingKeyframes) {
            this.elementSelectionService.setSelection(nodeUnderMousePosition);
            return;
        }

        if (selection.length > 1) {
            if (isGroupDataNode(selection.nodes[0])) {
                const newSelection =
                    this.elementSelectionService.getGroupOrNode(nodeUnderMousePosition);
                this.elementSelectionService.setSelection(newSelection!);
                return;
            }

            if (isTextNode(nodeUnderMousePosition)) {
                this.elementSelectionService.setSelection(nodeUnderMousePosition);
                this.setTransformMode(TransformMode.EditText);
                this.mutatorService.startEditText(nodeUnderMousePosition);
            }
            return;
        }

        const element = selection.element;
        if (isTextNode(element)) {
            if (this.mode !== TransformMode.EditText) {
                this.setTransformMode(TransformMode.EditText);
                this.mutatorService.startEditText(element);
                if (this.isSafari) {
                    workspace.userSelect = 'all';
                }
            }
        } else if (distance(canvasMousePosition, this.canvasStartMousePosition) <= 5) {
            this.mutatorService.fillToCanvas(selection);
            this.elementSelectionService.clearSelection();
            this.elementSelectionService.setSelection(nodeUnderMousePosition!);
        }
    };

    async removeActiveGuideline(removeAllActive?: boolean): Promise<void> {
        const designView = this.workspace.designView;
        const { height, width } = this.workspace.host.nativeElement.getBoundingClientRect();

        const activeGuideline = this.guidelineSelection.instance;

        if (activeGuideline) {
            const guidelineWorkspacePosition = this.workspace.getPositionRelativeToWorkspace(
                activeGuideline.position
            );
            if (activeGuideline.type === GuidelineType.Vertical) {
                if (
                    guidelineWorkspacePosition.x >= width ||
                    guidelineWorkspacePosition.x < 0 ||
                    (this.isMediaLibraryOpen &&
                        guidelineWorkspacePosition.x <= designView.mediaLibraryWidth)
                ) {
                    this.workspace.design.document.guidelines =
                        this.workspace.design.document.guidelines.filter(
                            g => g.id !== activeGuideline.id
                        );
                    this.guidelineSelection.instance = undefined;
                    this.unindexElementPoints();
                }
            } else if (
                guidelineWorkspacePosition.y >=
                    height - this.workspace.designView.timeline.getHostHeight() ||
                guidelineWorkspacePosition.y < 0
            ) {
                this.workspace.design.document.guidelines =
                    this.workspace.design.document.guidelines.filter(g => g.id !== activeGuideline.id);
                this.guidelineSelection.instance = undefined;
                this.unindexElementPoints();
            }

            if (removeAllActive) {
                this.workspace.design.document.guidelines =
                    this.workspace.design.document.guidelines.filter(g => g.id !== activeGuideline.id);
                this.guidelineSelection.instance = undefined;
            }

            this.onGuidelineChange$.next(this.guidelineSelection.instance);
        }
    }

    private setRotationCursor(rotation: number): void {
        let normalizedRotation = Math.round((rotation * 180) / Math.PI);
        normalizedRotation = roundToNearestMultiple(normalizedRotation, ROTATION_ICON_MULTIPLE);
        if (normalizedRotation >= 360) {
            normalizedRotation = 0;
        }
        if (!this.selectionHasLockedElements()) {
            this.workspace.designView.setCursor(
                this.workspace.host.nativeElement,
                `rotation-${normalizedRotation}`
            );
        }
    }

    private setResizeCursor(rotation: number): void {
        let normalizedRotation = Math.round((rotation * 180) / Math.PI);
        normalizedRotation = roundToNearestMultiple(normalizedRotation, ROTATION_ICON_MULTIPLE);
        if (normalizedRotation >= 360) {
            normalizedRotation = 0;
        }
        if (!this.selectionHasLockedElements()) {
            this.workspace.designView.setCursor(
                this.workspace.host.nativeElement,
                `resize-${normalizedRotation}`
            );
        }
    }

    private setCreateElementCursor(): void {
        if (this.workspace.createElementKind) {
            const cursor = this.isElementDragging
                ? 'pointer'
                : `create-element-${this.workspace.createElementKind}-0`;
            this.workspace.designView.setCursor(this.workspace.host.nativeElement, cursor);
        }
    }

    private setCorrectDelta(direction: ResizeDirection, delta: IPosition): number {
        switch (direction) {
            case 'right':
                return delta.x;
            case 'left':
                return -delta.x;
            case 'bottom':
                return delta.y;
            case 'top':
                return -delta.y;
        }
    }

    private getOffsetFromMouseDelta(delta: IPosition, directions: IResizeDirection): Partial<IOffset> {
        const offset: Partial<IOffset> = {};

        (Object.keys(directions) as ResizeDirection[]).forEach(direction => {
            if (directions[direction]) {
                offset[direction] = this.setCorrectDelta(direction, delta);
            }
        });
        return offset;
    }

    private modifyOffsetByReflectingSize(offset: Partial<IOffset>): void {
        if (typeof offset.top !== 'undefined') {
            offset.bottom = offset.top;
        } else if (typeof offset.bottom !== 'undefined') {
            offset.top = offset.bottom;
        }
        if (typeof offset.left !== 'undefined') {
            offset.right = offset.left;
        } else if (typeof offset.right !== 'undefined') {
            offset.left = offset.right;
        }
    }

    private modifyOffsetByKeepingElementRatio(offset: Partial<IOffset>, mouseDelta: IDelta): void {
        if (typeof offset.top !== 'undefined') {
            if (typeof offset.left !== 'undefined') {
                const delta = this.getNorthWestSouthEastDelta(mouseDelta);
                offset.left = delta.x;
                offset.top = delta.y;
            } else if (typeof offset.right !== 'undefined') {
                const delta = this.getNorthEastSouthWestDelta(mouseDelta);
                offset.right = delta.x;
                offset.top = delta.y;
            } else {
                if (this.altKeyIsDown) {
                    offset.left = offset.right = offset.top * this.elementSizeRatio;
                } else {
                    offset.left = offset.right = (offset.top * this.elementSizeRatio) / 2;
                }
            }
        } else if (typeof offset.bottom !== 'undefined') {
            if (typeof offset.left !== 'undefined') {
                const delta = this.getNorthEastSouthWestDelta(mouseDelta);
                offset.left = -delta.x;
                offset.bottom = -delta.y;
            } else if (typeof offset.right !== 'undefined') {
                const delta = this.getNorthWestSouthEastDelta(mouseDelta);
                offset.right = -delta.x;
                offset.bottom = -delta.y;
            } else {
                if (this.altKeyIsDown) {
                    offset.left = offset.right = offset.bottom * this.elementSizeRatio;
                } else {
                    offset.left = offset.right = (offset.bottom * this.elementSizeRatio) / 2;
                }
            }
        } else if (typeof offset.left !== 'undefined') {
            if (this.altKeyIsDown) {
                offset.top = offset.bottom = offset.left / this.elementSizeRatio;
            } else {
                offset.top = offset.bottom = offset.left / this.elementSizeRatio / 2;
            }
        } else if (typeof offset.right !== 'undefined') {
            if (this.altKeyIsDown) {
                offset.top = offset.bottom = offset.right / this.elementSizeRatio;
            } else {
                offset.top = offset.bottom = offset.right / this.elementSizeRatio / 2;
            }
        } else {
            throw new Error('Should not reach here.');
        }
    }

    private getNorthEastSouthWestDelta(mouseDelta: IDelta): IDelta {
        const x = mouseDelta.x;
        const y = -mouseDelta.y;
        const pointDistance = diagonal(x, y);
        const diagonalArc = Math.atan(this.elementSizeRatio);
        let pointArc: number;

        // Handle zeroes
        if (y === 0) {
            if (x >= 0) {
                pointArc = (3 * Math.PI) / 2;
            } else {
                pointArc = Math.PI / 2;
            }
        } else if (x === 0) {
            if (y >= 0) {
                pointArc = 0;
            } else {
                pointArc = Math.PI;
            }
        }

        // Second quadrant
        else if (x < 0 && y > 0) {
            pointArc = Math.atan(-x / y);
        }

        // Third quadrant
        else if (x < 0 && y < 0) {
            pointArc = Math.PI / 2 + Math.atan(-y / -x);
        }

        // Fourth quadrant
        else if (x > 0 && y < 0) {
            pointArc = Math.PI + Math.atan(x / -y);
        }

        // First quadrant
        else {
            pointArc = (3 * Math.PI) / 2 + Math.atan(y / x);
        }
        const diagonalDistance = Math.cos(diagonalArc + pointArc) * pointDistance;

        return {
            x: Math.round(Math.sin(diagonalArc) * diagonalDistance),
            y: Math.round(Math.cos(diagonalArc) * diagonalDistance)
        };
    }

    private getNorthWestSouthEastDelta(mouseDelta: IDelta): IDelta {
        const x = mouseDelta.x;
        const y = -mouseDelta.y;
        const pointDistance = diagonal(x, y);
        const diagonalArc = Math.atan(this.elementSizeRatio);

        let pointArc: number;

        // Handle zeroes
        if (y === 0) {
            if (x >= 0) {
                pointArc = Math.PI / 2;
            } else {
                pointArc = (3 * Math.PI) / 2;
            }
        } else if (x === 0) {
            if (y >= 0) {
                pointArc = 0;
            } else {
                pointArc = Math.PI;
            }
        }

        // First quadrant
        else if (x > 0 && y > 0) {
            pointArc = Math.atan(x / y);
        }

        // Second quadrant
        else if (x < 0 && y > 0) {
            pointArc = (3 * Math.PI) / 2 + Math.atan(y / -x);
        }

        // Third quadrant
        else if (x < 0 && y < 0) {
            pointArc = Math.PI + Math.atan(-x / mouseDelta.y);
        }

        // Fourth quadrant
        else {
            pointArc = Math.PI / 2 + Math.atan(y / -x);
        }
        const diagonalDistance = Math.cos(diagonalArc + pointArc) * pointDistance;

        return {
            x: Math.round(Math.sin(diagonalArc) * diagonalDistance),
            y: Math.round(Math.cos(diagonalArc) * diagonalDistance)
        };
    }

    private getTransformDirection(
        mousePosition: IPosition,
        element: IBoundingBox
    ): ITransformDirection {
        mousePosition = rotatePosition(
            mousePosition,
            { x: element.x + element.width / 2, y: element.y + element.height / 2 },
            element.rotationZ!
        );
        const { width, height, x: left, y: top } = element;
        const resizeTolerance =
            WorkspaceTransformService.RESIZE_TOLERANCE / this.editorStateService.zoom;
        const rotateTolerance =
            WorkspaceTransformService.ROTATE_TOLERANCE / this.editorStateService.zoom;
        const minSize = WorkspaceTransformService.MIN_SIZE;
        const right = left + width;
        const bottom = top + height;
        let resizeDirection: IResizeDirection | undefined = {};
        let rotateDirection: RotationLocation | undefined;
        let hasResizeDirection = false;

        if (
            mousePosition.x >= left - resizeTolerance &&
            mousePosition.x <= left + (width > minSize ? resizeTolerance : 0) &&
            mousePosition.y >= top - resizeTolerance &&
            mousePosition.y <= bottom + resizeTolerance
        ) {
            resizeDirection.left = true;
            hasResizeDirection = true;
        }
        if (
            mousePosition.x >= right - (width > minSize ? resizeTolerance : 0) &&
            mousePosition.x <= right + resizeTolerance &&
            mousePosition.y >= top - resizeTolerance &&
            mousePosition.y <= bottom + resizeTolerance
        ) {
            resizeDirection.right = true;
            hasResizeDirection = true;
        }
        if (
            mousePosition.y >= top - resizeTolerance &&
            mousePosition.y <= top + (height > minSize ? resizeTolerance : 0) &&
            mousePosition.x >= left - resizeTolerance &&
            mousePosition.x <= right + resizeTolerance
        ) {
            resizeDirection.top = true;
            hasResizeDirection = true;
        }
        if (
            mousePosition.y >= bottom - (height > minSize ? resizeTolerance : 0) &&
            mousePosition.y <= bottom + resizeTolerance &&
            mousePosition.x >= left - resizeTolerance &&
            mousePosition.x <= right + resizeTolerance
        ) {
            resizeDirection.bottom = true;
            hasResizeDirection = true;
        }

        if (
            mousePosition.y <= top &&
            mousePosition.y >= top - rotateTolerance &&
            mousePosition.x <= left &&
            mousePosition.x >= left - rotateTolerance
        ) {
            rotateDirection = 'top-left';
        } else if (
            mousePosition.y <= top &&
            mousePosition.y >= top - rotateTolerance &&
            mousePosition.x >= right &&
            mousePosition.x <= right + rotateTolerance
        ) {
            rotateDirection = 'top-right';
        } else if (
            mousePosition.y >= bottom &&
            mousePosition.y <= bottom + rotateTolerance &&
            mousePosition.x <= left &&
            mousePosition.x >= left - rotateTolerance
        ) {
            rotateDirection = 'bottom-left';
        } else if (
            mousePosition.y >= bottom &&
            mousePosition.y <= bottom + rotateTolerance &&
            mousePosition.x >= right &&
            mousePosition.x <= right + rotateTolerance
        ) {
            rotateDirection = 'bottom-right';
        }

        if (!hasResizeDirection) {
            resizeDirection = undefined;
        }

        return {
            resize: resizeDirection,
            rotate: rotateDirection
        };
    }

    indexElementPoints = (): void => {
        const canvasSize = this.workspace.canvasSize;
        this.unindexElementPoints();

        this.xPoints.insert(0, { crossValue: 0 }, -1);
        this.xPoints.insert(0, { crossValue: canvasSize.height }, -1);
        this.xPoints.insert(canvasSize.width, { crossValue: 0 }, -1);
        this.xPoints.insert(canvasSize.width, { crossValue: canvasSize.height }, -1);
        this.xPoints.insert(
            Math.round(canvasSize.width / 2 - 0.5),
            { crossValue: Math.round(canvasSize.height / 2 - 0.5) },
            -1
        );

        this.yPoints.insert(0, { crossValue: 0 }, -1);
        this.yPoints.insert(0, { crossValue: canvasSize.width }, -1);
        this.yPoints.insert(canvasSize.height, { crossValue: 0 }, -1);
        this.yPoints.insert(canvasSize.height, { crossValue: canvasSize.width }, -1);
        this.yPoints.insert(
            Math.round(canvasSize.height / 2 - 0.5),
            { crossValue: Math.round(canvasSize.width / 2 - 0.5) },
            -1
        );

        if (!!this.workspace.design.document.guidelines.length) {
            this.indexGuidelines();
        }

        forEachDataElement(
            this.mutatorService.renderer.creativeDocument,
            this.indexElement,
            this.workspace.designView.time
        );
    };

    private indexGuidelines(): void {
        const guidelines = this.workspace.design.document.guidelines;
        for (const guideline of guidelines) {
            const { height, width } = this.workspace.host.nativeElement.getBoundingClientRect();
            if (guideline.type === GuidelineType.Horizontal) {
                this.yPoints.insert(guideline.position.y, { crossValue: 0 }, -1);
                this.yPoints.insert(
                    guideline.position.y,
                    { crossValue: guideline.position.y + width },
                    -1
                );
            } else {
                this.xPoints.insert(guideline.position.x, { crossValue: 0 }, -1);
                this.xPoints.insert(
                    guideline.position.x,
                    { crossValue: guideline.position.x + height },
                    -1
                );
            }
        }
    }

    private unindexElementPoints(): void {
        this.xPoints.clear();
        this.yPoints.clear();
    }

    private indexSelectableElements(selection: OneOfSelectableElements): void {
        if (isElementSelection(selection)) {
            for (const e of selection.elements) {
                this.indexElement(e);
            }
        } else {
            this.indexElement(selection);
        }
    }

    private unindexSelectableElements(selection: OneOfSelectableElements): void {
        if (isElementSelection(selection)) {
            for (const element of selection.elements) {
                this.unindexElement(element);
            }
        } else {
            this.unindexElement(selection);
        }
    }

    indexElement = (element: OneOfElementDataNodes): void => {
        const xPoints: number[] = [];
        const yPoints: number[] = [];
        const id = element.id;
        const corners = this.workspace.getBoundingCorners(
            element,
            'canvas',
            0,
            /* calculateCenter */ true
        );

        for (const name in corners) {
            const corner = corners[name];
            const x = name === 'center' ? corner.x : Math.round(corner.x);
            const y = name === 'center' ? corner.y : Math.round(corner.y);
            this.xPoints.insert(x, { crossValue: y }, id);
            xPoints.push(x);
            this.yPoints.insert(y, { crossValue: x }, id);
            yPoints.push(y);
        }

        this.elementToXPointsMap.set(id, xPoints);
        this.elementToYPointsMap.set(id, yPoints);
    };

    private unindexElement(element: OneOfElementDataNodes): void {
        const id = element.id;
        const corners = this.workspace.getBoundingCorners(
            element,
            'canvas',
            0,
            /* calculateCenter */ true
        );
        const xPoints: number[] = [];
        const yPoints: number[] = [];

        for (const name in corners) {
            const corner = corners[name];
            const x = name === 'center' ? corner.x : Math.round(corner.x);
            const y = name === 'center' ? corner.y : Math.round(corner.y);
            this.xPoints.delete(x, id);
            this.yPoints.delete(y, id);
            xPoints.push(x);
            yPoints.push(y);
        }

        this.elementToXPointsMap.delete(id);
        this.elementToYPointsMap.delete(id);
    }

    private findSelectedElementAtPosition(position: IPosition): OneOfElementDataNodes | undefined {
        const elements = this.elementSelectionService.currentSelection.elements.filter(
            element => !isHidden(element)
        );
        const renderer = this.editorStateService.renderer;
        const viewElements = elements.map(element => renderer.getViewElementById(element.id));

        for (const viewElement of viewElements) {
            if (!viewElement) {
                continue;
            }
            const dataNode = viewElement.__data;
            const box = getBoundsOfScaledRectangle(viewElement);
            const origin = getCenter(box);
            const rotatedPosition = rotatePosition(position, origin, viewElement.rotationZ!);

            if (positionIsInBounds(rotatedPosition, dataNode) && !isLocked(dataNode)) {
                return viewElement.__data;
            }
        }
    }

    private nudgePosition({ x, y }: IPosition): void {
        const selection = this.elementSelectionService.currentSelection;
        if (!selection) {
            throw new Error('Selection not found when nudging position');
        }

        const elements = selection.elements;

        if (!elements.length) {
            return;
        }

        if (this.elementSelectionService.latestSelectionType === 'keyframe') {
            return;
        }

        this.mutatorService.nudgeMove(elements, x, y);
    }

    private blurAllInputs(): void {
        const inputs = window.document.querySelectorAll('input');
        for (let i = 0; i < inputs.length; ++i) {
            inputs[i].blur();
        }
    }

    private selectionHasLockedElements(): boolean {
        return this.elementSelectionService.currentSelection.nodes.some(node => isLocked(node));
    }

    private _setTextElementCursor = (): void => {
        setCursorOnElement(this.workspace.host.nativeElement, 'text');
    };

    private _unsetTextElementCursor = (): void => {
        setCursorOnElement(this.workspace.host.nativeElement, 'selection-0');
    };

    actionIsAllowedOnSelection(): boolean {
        return (
            this.elementSelectionService.currentSelection.elements.findIndex(
                element =>
                    isLocked(element) &&
                    (this.mode === TransformMode.Resize || this.mode === TransformMode.Rotate)
            ) === -1
        );
    }
}
