import { trigger, transition, style, animate, state } from '@angular/animations';

export const logoAnimation = trigger('logoAnimation', [
    transition(':enter', [
        style({
            opacity: '0',
            transform: 'scale(0) rotate(-180deg)'
        }),
        animate(150)
    ]),
    transition(':leave', [
        animate(
            150,
            style({
                transform: 'scale(-1.5) rotate(180deg)',
                opacity: '0'
            })
        )
    ]),
    state('*', style({ position: 'absolute' }))
]);
