<ui-dropdown
    #menu
    type="menu">
    <ui-dropdown-item
        id="interaction-create-new-campaign-button"
        class="menu-option"
        (click)="openCreateCampaign()"
        [disabled]="!(hasActiveDesigns$ | async)"
        svgIcon="display-campaign">
        {{ createDisplayText$ | async }}
    </ui-dropdown-item>
    <ng-container *permissions="'SocialCampaignManager'">
        <div
            class="social-campaign"
            uiTooltip="You do not have any social sizes in this creative set"
            [uiTooltipDisabled]="!!(isSocialCampaignAvailable$ | async)">
            <ui-dropdown-item
                id="create-new-social-campaign"
                class="menu-option"
                [disabled]="!(isSocialCampaignAvailable$ | async)"
                svgIcon="social-campaign"
                (click)="openSocialCampaigns()">
                {{ createDisplaySocialText$ | async }}
            </ui-dropdown-item>
            @if (!(isSocialCampaignAvailable$ | async)) {
                <a
                    class="link"
                    rel="noopener"
                    target="_blank"
                    href="https://support.bannerflow.com/en/articles/6499649">
                    Read more
                </a>
            }
        </div>
    </ng-container>
    @if ((hasConnectedCampaigns$ | async) || isAddToCampaignVisible) {
        <ui-dropdown-divider></ui-dropdown-divider>
    }
    @if (campaignPublishStatus$ | async; as campaigns) {
        @for (campaign of campaigns; track campaign.id) {
            <ui-dropdown-item
                svgIcon="display-campaign"
                [uiTooltip]="campaign.name"
                [uiTooltipDisabled]="!campaign.name || campaign.name.length < 25"
                (click)="openCampaign(campaign.id)">
                {{ campaign.name | dotdotdot: 25 }}
            </ui-dropdown-item>
        }
    }
    @if ((hasConnectedCampaigns$ | async) || isAddToCampaignVisible) {
        <ui-dropdown-divider></ui-dropdown-divider>
    }
    @if (isAddToCampaignVisible) {
        <ui-dropdown-item
            id="add-to-campaign-context-menu"
            class="custom-dropdown"
            [uiDropdownTarget]="addToCampaignDropdown.dropdown"
            [disabled]="!!(addToCampaignDisabled$ | async)">
            <div class="custom-item">
                <div class="custom-text">
                    {{ addToCampaignText$ | async }}
                </div>
            </div>
        </ui-dropdown-item>
    }

    <add-to-campaign-dropdown #addToCampaignDropdown></add-to-campaign-dropdown>
</ui-dropdown>
