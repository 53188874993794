import { EnvironmentProviders, importProvidersFrom } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FiltersEffects } from './filters.effects';
import { FILTERS_FEATURE_KEY, reducer } from './filters.reducer';

export function provideFiltersStore(): EnvironmentProviders {
    return importProvidersFrom(
        StoreModule.forFeature(FILTERS_FEATURE_KEY, reducer),
        EffectsModule.forFeature(FiltersEffects)
    );
}
