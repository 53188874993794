import { validationMap } from '@domain/api/validations';
import { PropertyInputValidation } from '../property-validation';

type Keys = 'size' | 'position' | 'scale' | 'rotation' | 'radius' | 'opacity';

export const layoutPropertiesInputValidation: PropertyInputValidation<Keys> = {
    size: validationMap.layout.size,
    position: validationMap.layout.position,
    scale: validationMap.layout.scale,
    rotation: validationMap.layout.rotation,
    radius: validationMap.layout.radius,
    opacity: {
        min: 0,
        max: 100
    }
} as const;
