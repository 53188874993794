import { FeedStore } from '@creative/elements/feed/feed-store';
import { ICreativeEnvironment } from '@domain/creative/environment';
import { IRenderer } from '@creative/renderer.header';

export class FeedStoreSingleton {
    private static feedStore: FeedStore;

    static getInstance(): FeedStore {
        if (!FeedStoreSingleton.feedStore) {
            throw new Error('FeedStore not initialized');
        }
        return FeedStoreSingleton.feedStore;
    }

    static initialize(brandId: string, env: ICreativeEnvironment): void {
        if (FeedStoreSingleton.feedStore) {
            return;
        }
        FeedStoreSingleton.feedStore = new FeedStore(
            { loops: 0 } as unknown as IRenderer,
            env,
            brandId
        );
    }
}
