import {
    IPSDConversionError,
    PSDErrorType,
    PSDOriginalFontNotFoundData
} from '@studio/domain/components/psd-import/psd';

export class PSDConversionError extends Error implements IPSDConversionError {
    // these are errors that we can ignore and still import the creative
    // (e.g. if the original font is not found, we can still import the creative with a fallback font)
    private _lowSeverityErrors = [PSDErrorType.OriginalFontNotFound];
    private _type: PSDErrorType;
    private _isCritical: boolean;
    private _data?: PSDOriginalFontNotFoundData;

    isFixed = false;

    get type(): PSDErrorType {
        return this._type;
    }

    get isCritical(): boolean {
        return this._isCritical;
    }

    get data(): PSDOriginalFontNotFoundData | undefined {
        return this._data;
    }

    constructor(
        message: string,
        type: PSDErrorType = PSDErrorType.CreativeConversion,
        data?: PSDOriginalFontNotFoundData
    ) {
        super(message);
        this.name = 'PSDConversionError';
        this._type = type;
        this._isCritical = !this._lowSeverityErrors.includes(type);
        if (type === PSDErrorType.OriginalFontNotFound && data) {
            this._data = data;
        }
    }
}
