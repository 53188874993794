import { Injectable } from '@angular/core';
import { BEGIN_WEIGHT_CALCULATION, GET_CREATIVE_WEIGHTS } from '@data/graphql/weight.queries';
import {
    BeginWeightCalculationDto,
    ZBeginWeightCalculation,
    ICreativeWeight,
    CreativeWeightsResult,
    CreativeWeightsVariables,
    ZCreativeWeightsDto
} from '@domain/creativeset/creative/weight';
import { adjustWeightCalculation } from '@creative/utils';
import { Apollo, QueryRef } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class WeightDataService {
    private queryRef: QueryRef<CreativeWeightsResult, CreativeWeightsVariables>;

    constructor(private apollo: Apollo) {}

    beginWeightCalculation(
        creativesetId: string,
        creativeIds: string[]
    ): Observable<BeginWeightCalculationDto> {
        return this.apollo
            .mutate({
                mutation: BEGIN_WEIGHT_CALCULATION,
                variables: {
                    creativesetId,
                    creativeIds
                }
            })
            .pipe(map(result => ZBeginWeightCalculation.parse(result.data?.beginWeightCalculation)));
    }

    checkForCalculatedWeights(checksums: string[]): Observable<ICreativeWeight[]> {
        if (!this.queryRef) {
            this.queryRef = this.getCreativeWeights(checksums);
        } else {
            this.queryRef.setVariables({ creativeChecksumList: checksums });
        }
        this.queryRef.startPolling(2000);

        return this.queryRef.valueChanges.pipe(
            map(result => ZCreativeWeightsDto.parse(result.data.creativeWeightsByChecksums)),
            switchMap((weights: ICreativeWeight[]) => {
                return new Observable<ICreativeWeight[]>(subscriber => {
                    const completedOrFailed = weights.filter(
                        (creativeWeight: ICreativeWeight) =>
                            creativeWeight.failed || creativeWeight.weights
                    );

                    const pending = weights.filter(
                        (creativeWeight: ICreativeWeight) =>
                            !creativeWeight.failed && !creativeWeight.weights
                    );

                    if (completedOrFailed.length > 0) {
                        subscriber.next(completedOrFailed.map(adjustWeightCalculation));
                    }

                    if (pending.length === 0) {
                        this.queryRef.stopPolling();
                        subscriber.complete();
                    }
                });
            })
        );
    }

    private getCreativeWeights(
        checksums: string[]
    ): QueryRef<CreativeWeightsResult, CreativeWeightsVariables> {
        return this.apollo.watchQuery({
            query: GET_CREATIVE_WEIGHTS,
            variables: {
                creativeChecksumList: checksums
            },
            fetchPolicy: 'network-only'
        });
    }
}
