import { IUrlParameterMap } from '@domain/ad/url-parameters';
import { safeUrlRegex } from '../url';
import { getTopSafeFrame, isIframe } from './dom';

interface IAMPWindow extends Window {
    context: any;
}

/**
 * Send data on page leave. Use this when tracking stuff
 * since it supports being fired in beforeunload.
 * @param url
 * @param data
 */
export function send(url: string, data: any): void {
    // If sendBeacon is allowed (Doesn't work in safari)
    if (navigator?.sendBeacon) {
        navigator.sendBeacon(url, data);
    } else {
        post(url, data);
    }
}

/**
 * Do post request. Note, use send when communication with tracker
 * @param url
 * @param data
 * @param callback
 */
export function post(
    url: string,
    data?: any,
    done?: (data: any, url: string) => void,
    error?: (data: any) => void
): XMLHttpRequest {
    return makeRequest(url, 'POST', data, done, error);
}

/**
 * Do GET Request
 * @param url
 * @param done
 */
export function get(
    url: string,
    done?: (data: any, url: string) => void,
    error?: (data: any) => void
): XMLHttpRequest {
    return makeRequest(url, 'GET', undefined, done, error);
}

export function loadPixel(url?: string): HTMLImageElement {
    const pixel = new Image();
    pixel.src = url || '';
    return pixel;
}

function makeRequest(
    url: string,
    type: 'POST' | 'GET',
    data?: any,
    callback?: (data: any, url: string) => void,
    error?: (data: any) => void
): XMLHttpRequest {
    const request = new XMLHttpRequest();

    if (callback) {
        request.onload = (): void => {
            if (request.status >= 200 && request.status < 300) {
                callback(request.response, url);
            } else if (error) {
                error(request);
            } else {
                throw new Error(`Could not load resource: ${url}`);
            }
        };
    }
    request.onerror = (e): void => {
        if (error) {
            error(e);
        } else {
            throw new Error(`Could not load resource: ${url}`);
        }
    };

    request.open(type, url, true); // `false` makes the request synchronous, needed to not be ignored on pagehide
    request.send(data);
    return request;
}

export function getCurrentDomain(params?: IUrlParameterMap): string {
    const w = getTopSafeFrame() as IAMPWindow;

    // Domain as query params
    let domain =
        params?.domain && params.domain !== '${DetectedDomain}'
            ? decodeURIComponent(params.domain)
            : '';

    // AMP Domain
    if (!domain && w.context) {
        domain = w.context.canonicalUrl;
    }

    // Try to get domain from top parent
    if (!domain) {
        const doc = w.document;
        const location = w.location || (doc ? doc.location : undefined);

        if (location?.ancestorOrigins?.length) {
            domain = location.ancestorOrigins[location.ancestorOrigins.length - 1];
        }

        if ((location && !location.ancestorOrigins) || !domain) {
            // Check if referrer exist (only on iframes)
            if (doc?.referrer && isIframe(w)) {
                domain = doc.referrer;
            } else if (location?.href) {
                domain = location.href;
            }

            if (domain?.match(safeUrlRegex)) {
                domain = '';
            }
        }
    }

    // Return url without querystrings (may be blank)
    return domain.replace(/\?.*/g, '');
}
