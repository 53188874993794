import { Injectable, inject } from '@angular/core';
import { SentinelService } from '@bannerflow/sentinel';
import { IActivityLoggerService } from '@domain/monitoring/activity-logger.interface';

interface IAction {
    title: string;
    data?: any;
}

@Injectable({ providedIn: 'root' })
export class ActivityLoggerService implements IActivityLoggerService {
    private logs: IAction[] = [];
    private debounce = false;
    private debounceTimer: any;
    private sentinelService = inject(SentinelService);
    /** Seems to take approx 500ms for keys to fire 2nd time when pressing down */
    private timeout = 510;
    private recentCommands = new Set<string>();

    log(command: string, data?: any): void {
        if (this.debounce) {
            clearTimeout(this.debounceTimer);
        }
        this.debounceTimer = setTimeout(() => {
            this.debounce = false;
            this.recentCommands.clear();
        }, this.timeout);

        if (this.debounce && this.recentCommands.has(command)) {
            return;
        }

        this.debounce = true;
        this.recentCommands.add(command);

        const action: IAction = {
            title: command,
            data: data
        };
        this.logs.push(action);

        this.sentinelService.trackPageAction(action.title, action.data);

        (window as any).activities = this.logs.slice(this.logs.length - 10, this.logs.length);

        // Uncomment for debugging
        // console.log(command, 'data: ', data);
    }
}
