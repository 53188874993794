import { IBoundingBox } from '@domain/dimension';
import { OneOfDataNodes } from '@domain/nodes';
import { IState } from '@domain/state';
import { FILTER_MAP, IFilterMap } from '@domain/style';
import { getBoundingRectangleOfRotatedBox, getOuterBounds } from '@studio/utils/geom';
import { isGroupDataNode, toFlatElementNodeList } from '../nodes/helpers';
import { getBoundingBoxOfElementWithState } from '../rendering';

export function calculateBoundingBox(nodes: OneOfDataNodes[], state?: IState): IBoundingBox {
    const boxes = nodes
        .map(node => {
            if (isGroupDataNode(node)) {
                return toFlatElementNodeList(node);
            }
            return node;
        })
        .flat()
        .map((node, _, array) => {
            if (array.length === 1) {
                return getBoundingBoxOfElementWithState(node, state, true);
            }
            // Multiple elements selected, include rotations in box size.
            return getBoundingRectangleOfRotatedBox(node);
        });

    return {
        rotationZ: 0,
        ...(boxes.length === 1 ? boxes[0] : getOuterBounds(...boxes))
    };
}

/**
 * Converts the `filter` object to a string.
 * @returns A string that can be used as a CSS value.
 */
export function getFilterString(filters: IFilterMap): string {
    const values = Object.entries(filters)
        .filter(([_, filter]) => filter?.value !== undefined)
        .map(([key, filter]) => {
            const filterUnit = FILTER_MAP[key]?.unit;
            return `${key}(${filter!.value!.toFixed(0)}${filterUnit})`;
        });

    return values.join(' ');
}
