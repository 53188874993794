<ui-loader *ngIf="loading$ | async; else loaded"></ui-loader>

<ng-template #loaded>
    <div class="container">
        <ui-tabs
            #tabs
            [class.hide-tabs]="showcaseList && showcaseList.data.length === 0">
            <ui-tab
                #createLinkTab
                name="Create new link">
                <div class="create-wrapper">
                    <div ui-theme="default">
                        <h3>Share with team members</h3>
                        <div class="invite-wrapper">
                            <ui-select
                                [multiSelect]="true"
                                [searchable]="true"
                                [tokenField]="true"
                                placeholder="Invite people"
                                [(selected)]="selectedRecipients"
                                theme="default">
                                <ui-option
                                    *ngFor="let user of sortedUsers"
                                    [value]="user">
                                    <ng-container *ngIf="user.kind === 'translator'"> </ng-container>
                                    {{ user.name }}
                                    <div class="flags">
                                        <version-flag
                                            *ngFor="let localization of user.localizations"
                                            [localizationId]="localization.id"></version-flag>
                                    </div>
                                </ui-option>
                            </ui-select>
                            <ui-button
                                text="Send link"
                                type="primary"
                                (click)="createLinkWithRecipients()"
                                [disabled]="!(selectedRecipients.length > 0)">
                            </ui-button>
                        </div>
                        <ui-textarea
                            class="note"
                            placeholder="Add a note"
                            [(value)]="invitationMessage">
                        </ui-textarea>
                    </div>
                    <div ui-theme="small">
                        <div class="options">
                            <h3>Sharing options</h3>
                            <ui-select
                                class="option"
                                placeholder="All versions"
                                theme="small"
                                [multiSelect]="true"
                                [searchable]="true"
                                [useTargetWidth]="true"
                                [width]="'100%'"
                                (selectedChange)="sortUsers(); resetLink()"
                                [(selected)]="selectedVersions">
                                <ui-option
                                    *ngFor="let version of versions"
                                    [value]="version">
                                    <version-flag
                                        [title]="version.name"
                                        [showName]="true"
                                        [localizationId]="version.localization.id"></version-flag>
                                    <span>
                                        <span *ngIf="version.id === (defaultVersion$ | async)?.id"
                                            >Default</span
                                        >
                                    </span>
                                </ui-option>
                            </ui-select>
                            <ui-select
                                class="option sizes"
                                placeholder="All sizes"
                                theme="small"
                                [multiSelect]="true"
                                [searchable]="true"
                                [useTargetWidth]="true"
                                [width]="'100%'"
                                (selectedChange)="resetLink()"
                                [(selected)]="selectedSizes">
                                <ui-option
                                    *ngFor="let size of sizes"
                                    [value]="size"
                                    class="size-option">
                                    {{ size.width + ' × ' + size.height }}
                                    <span
                                        class="size-name"
                                        *ngIf="size.name"
                                        truncateSpan
                                        [spanText]="size.name"></span>
                                </ui-option>
                            </ui-select>
                        </div>
                        <div>
                            <h3>Permissions</h3>
                            <div class="permissions">
                                <div [class.toggleOff]="!allowFullAccess">
                                    <div>
                                        <ui-svg-icon
                                            icon="crown"
                                            class="icon"></ui-svg-icon>
                                        <label>Allow full access</label>
                                        <ui-svg-icon
                                            [uiTooltip]="
                                                'Please note that only logged-in users within your organization will get the full access'
                                            "
                                            icon="question-mark"
                                            class="info">
                                        </ui-svg-icon>
                                    </div>
                                    <ui-toggle-switch
                                        [(selected)]="allowFullAccess"
                                        id="interaction-toggle-full-access"
                                        (selectedChange)="sortUsers(); resetLink()">
                                    </ui-toggle-switch>
                                </div>
                                <div
                                    [class.toggleOff]="!allowTranslations"
                                    [class.disabled]="allowFullAccess">
                                    <div>
                                        <ui-svg-icon
                                            icon="edit"
                                            class="icon"></ui-svg-icon>
                                        <label>Allow text editing</label>
                                    </div>
                                    <ui-toggle-switch
                                        [disabled]="allowFullAccess"
                                        [selected]="allowTranslations"
                                        id="interaction-toggle-translations"
                                        (selectedChange)="allowTextEditingChange($event)">
                                    </ui-toggle-switch>
                                </div>
                                <div
                                    [class.toggleOff]="!allowStyling"
                                    [class.disabled]="!allowTranslations || allowFullAccess">
                                    <div>
                                        <ui-svg-icon
                                            icon="text-style-apply-formatting"
                                            class="icon"></ui-svg-icon>
                                        <label>Allow styling</label>
                                    </div>
                                    <ui-toggle-switch
                                        [disabled]="!allowTranslations || allowFullAccess"
                                        [selected]="allowStyling"
                                        id="interaction-toggle-styling"
                                        (selectedChange)="allowStylingChange($event)">
                                    </ui-toggle-switch>
                                </div>
                                <div
                                    [class.toggleOff]="!allowComments"
                                    [class.disabled]="allowFullAccess">
                                    <div>
                                        <ui-svg-icon
                                            icon="comments"
                                            class="icon"></ui-svg-icon>
                                        <label>Allow commenting</label>
                                    </div>
                                    <ui-toggle-switch
                                        [disabled]="allowFullAccess"
                                        [(selected)]="allowComments"
                                        id="interaction-toggle-comments"
                                        (selectedChange)="resetLink()">
                                    </ui-toggle-switch>
                                </div>
                                <div
                                    [class.toggleOff]="!allowStatuses"
                                    [class.disabled]="allowFullAccess">
                                    <div>
                                        <ui-icon
                                            icon="checkmark"
                                            class="icon"></ui-icon>
                                        <label>Allow to change status</label>
                                    </div>
                                    <ui-toggle-switch
                                        [disabled]="allowFullAccess"
                                        [(selected)]="allowStatuses"
                                        id="interaction-toggle-status"
                                        (selectedChange)="resetLink()">
                                    </ui-toggle-switch>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="share-wrapper"
                    ui-theme="default">
                    <ng-container *ngIf="{ createdShowcase: createdShowcase$ | async } as data">
                        <div>
                            <h3>Share a link externally</h3>
                            <span class="share-text"
                                >Share your creative set with external stakeholders, reviewers,
                                copywriters or translators</span
                            >
                        </div>
                        <ui-button
                            id="create-showcase-link"
                            *ngIf="
                                !allowFullAccess &&
                                (!data.createdShowcase || data.createdShowcase.isTemporary)
                            "
                            text="Create link"
                            type="default"
                            [svgIcon]="'decision-tree'"
                            (click)="createLink()">
                        </ui-button>
                        <ui-button
                            *ngIf="
                                !allowFullAccess &&
                                data.createdShowcase &&
                                !data.createdShowcase.isTemporary
                            "
                            text="Copy link"
                            type="default"
                            [svgIcon]="'copy'"
                            (click)="onCopyLink(data.createdShowcase.showcaseLink)">
                        </ui-button>
                        @if (allowFullAccess) {
                            <ui-button
                                text="Manage users"
                                class="manage-users-btn"
                                type="default"
                                [svgIcon]="'users'"
                                (click)="goToManageUsersView()">
                            </ui-button>
                            <ui-button
                                text="Copy link"
                                type="default"
                                [svgIcon]="'copy'"
                                (click)="onCopyMVLink()">
                            </ui-button>
                        }
                    </ng-container>
                </div>
            </ui-tab>
            <ui-tab
                name="Manage access"
                ui-theme="tiny"
                (selectedChange)="selectedTabChanged()">
                <h3 class="table-text">Manage your shared links</h3>
                <ui-list
                    [dataSource]="showcaseList"
                    columnHeight="34px"
                    [expandable]="false"
                    #linkList
                    [multiSelect]="false"
                    *ngIf="showcaseList && showcaseList.data.length > 0">
                    <ui-list-column
                        name="Link"
                        property="disabled"
                        width="30%">
                        <ng-template
                            let-data="data"
                            let-value="value"
                            ui-list-cell-template>
                            <div
                                class="link"
                                ui-theme="tiny">
                                <ui-toggle-switch
                                    [selected]="!data.disabled"
                                    (selectedChange)="toggleEnabled($event, data.showcaseKey)">
                                </ui-toggle-switch>
                                <ui-svg-icon
                                    *ngIf="!data.disabled"
                                    icon="link-s"
                                    (click)="copyLink(data.showcaseLink)"></ui-svg-icon>
                                <ui-svg-icon
                                    *ngIf="data.disabled"
                                    icon="delete"
                                    (click)="deleteShowcase(data.showcaseKey)">
                                </ui-svg-icon>
                                <a
                                    [href]="data.showcaseLink"
                                    class="linkRef"
                                    target="_blank"
                                    (click)="goToLink(data.showcaseLink)"
                                    >{{ data.showcaseLink }}</a
                                >
                            </div>
                        </ng-template>
                    </ui-list-column>

                    <ui-list-column
                        name="Invited people"
                        property="invitations"
                        width="20%">
                        <ng-template
                            let-value="value"
                            let-data="data"
                            ui-list-cell-template>
                            <div class="users">
                                <ng-container *ngIf="value.length === 0">
                                    External access
                                </ng-container>
                                <span
                                    class="invitations"
                                    *ngIf="value.length > 0">
                                    {{ value[0].name }}
                                </span>
                            </div>
                        </ng-template>
                    </ui-list-column>

                    <ui-list-column
                        name="Versions"
                        property="versions"
                        width="15%">
                        <ng-template
                            let-value="value"
                            let-data="data"
                            ui-list-cell-template>
                            <div class="versions">
                                <ng-container
                                    *ngIf="value.length === versions.length || value.length === 0">
                                    All versions
                                </ng-container>
                                <item-list-overflow *ngIf="value.length !== versions.length">
                                    <overflow-item
                                        *ngFor="let version of value"
                                        class="version"
                                        style="white-space: nowrap">
                                        <version-flag
                                            [localizationId]="version.localization.id"
                                            [uiTooltipDelay]="0"
                                            [uiTooltip]="version.name"></version-flag>
                                        &nbsp;<span class="separator">&nbsp;&nbsp;&nbsp;</span>
                                    </overflow-item>
                                </item-list-overflow>
                            </div>
                        </ng-template>
                    </ui-list-column>

                    <ui-list-column
                        name="Sizes"
                        property="sizes"
                        width="25%">
                        <ng-template
                            let-value="value"
                            let-data="data"
                            ui-list-cell-template>
                            <div class="sizes">
                                <ng-container
                                    *ngIf="value.length === sizes.length || value.length === 0">
                                    All sizes
                                </ng-container>
                                <item-list-overflow *ngIf="value.length !== sizes.length">
                                    <overflow-item
                                        *ngFor="let size of value; let i = index"
                                        class="size"
                                        style="white-space: nowrap">
                                        {{ size.name || size.width + ' × ' + size.height
                                        }}<span class="separator">,</span>
                                    </overflow-item>
                                </item-list-overflow>
                            </div>
                        </ng-template>
                    </ui-list-column>

                    <ui-list-column
                        name="Allowed"
                        property="allowedOperations"
                        width="10%">
                        <ng-template
                            let-data="data"
                            let-value="value"
                            ui-list-cell-template>
                            <ng-container
                                *ngIf="!(value.comments || value.statuses || value.translations)">
                                View only
                            </ng-container>
                            <ng-container *ngIf="value.fullAccess">
                                <div class="fullAccess">
                                    <ui-svg-icon icon="crown"></ui-svg-icon>
                                </div>
                            </ng-container>
                            <ng-container
                                *ngIf="
                                    !value.fullAccess &&
                                    (value.comments || value.statuses || value.translations)
                                ">
                                <div class="comments">
                                    <ui-svg-icon
                                        *ngIf="value.comments"
                                        icon="comments"></ui-svg-icon>
                                </div>
                                <div class="statuses">
                                    <ui-svg-icon
                                        *ngIf="value.statuses"
                                        icon="checkbox-checkmark-default"></ui-svg-icon>
                                </div>
                                <div class="translations">
                                    <ui-svg-icon
                                        *ngIf="value.translations"
                                        icon="edit"></ui-svg-icon>
                                </div>
                                <div class="styling">
                                    <ui-svg-icon
                                        *ngIf="value.styling"
                                        icon="text-style-apply-formatting"></ui-svg-icon>
                                </div>
                            </ng-container>
                        </ng-template>
                    </ui-list-column>
                </ui-list>
            </ui-tab>
        </ui-tabs>
    </div>
</ng-template>
