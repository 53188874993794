@if (group) {
    @if (!selectedElement && group.elements.length > 1) {
        <group-input-header
            [ngClass]="{ 'double-padding': isShowingAll$ | async }"
            [groupLength]="group.elements.length"
            (toggleExpand)="toggleExpand($event)"></group-input-header>
    }
    @for (value of values$ | async; track value.renderId; let index = $index) {
        @for (inputData of value.inputs; track inputData.versionPropertyId) {
            @if (selectedElement ?? group.elements[index]; as groupElement) {
                <div
                    class="wrapper"
                    [ngClass]="{ 'double-padding': isShowingAll$ | async }">
                    <div class="header">
                        <ui-svg-icon [icon]="'widget'"></ui-svg-icon>
                        <span>
                            {{ inputData.label }}
                        </span>
                    </div>
                    @for (property of inputData.values; track property.value) {
                        <div class="input">
                            <ng-template #leadingIcon>
                                @if (!selectedElement && group.elements.length > 1 && !expanded) {
                                    <group-option-number
                                        [number]="group.elements.length"></group-option-number>
                                } @else {
                                    <ui-svg-icon
                                        leadingIcon
                                        [icon]="(groupElement | iconForElement) ?? ''"></ui-svg-icon>
                                }
                            </ng-template>
                            <mat-input
                                [value]="property.value"
                                [type]="'textarea'"
                                [label]="
                                    property.version
                                        | versionToLabel: (expanded ? groupElement.name : undefined)
                                "
                                [leadingIcon]="
                                    (isShowingAll$ | async) === false ? leadingIcon : undefined
                                "
                                (inputFocus)="highlightElement(groupElement.id, property.version.id)"
                                (inputBlur)="blurElement()"
                                (inputMouseOver)="
                                    highlightElement(groupElement.id, property.version.id)
                                "
                                (inputMouseOut)="blurElement()"
                                (inputKeyup)="onKeyUp(property.value, $event, index)"></mat-input>
                        </div>
                    }
                </div>
            }
        }
    }
}
