export enum GridSize {
    None = 'none',
    Grid10 = 10,
    Grid20 = 20,
    Grid50 = 50,
    Grid100 = 100,
    GridCustom = 'custom',
    GridFacebook = 'facebook'
}

export enum GridColor {
    BLACK = 'rgba(0, 0, 0, 0.5)',
    WHITE = 'rgba(255, 255, 255, 0.5)',
    GREEN = 'rgba(0, 128, 0, 0.5)',
    PURPLE = 'rgba(128, 0, 128, 0.5)'
}
