<div class="setting">
    <div
        class="action-row"
        [class.collapsed]="actionSettingsOpened === false">
        <div class="action-label action-state">
            <ui-svg-icon [icon]="actionData.disabled ? 'action-disabled' : 'de-action'"></ui-svg-icon>
            <span
                class="action-name"
                (click)="onEditAction()"
                >{{ selectedAction.name }}</span
            >
            <ui-svg-icon
                class="direction-right"
                icon="direction-right"></ui-svg-icon>
            <span
                class="state-name"
                *ngIf="selectedTarget && selectedAction.value !== ActionOperationMethod.OpenUrl">
                {{ selectedTarget.name }}
            </span>
            <span
                class="state-name"
                *ngIf="selectedAction.value === ActionOperationMethod.OpenUrl"
                [uiTooltip]="actionData.operations[0].value"
                [uiTooltipPosition]="'left'"
                [uiTooltipDisabled]="actionSettingsOpened">
                {{ actionData.operations[0].value }}
            </span>
        </div>
        <ui-svg-icon
            class="menu-button"
            icon="kebab"
            [uiDropdownTarget]="actionMenu"></ui-svg-icon>
    </div>
</div>

<section-expand
    *ngIf="actionSettingsOpened"
    #actionExpander
    arrowPosition="7%"
    [expanded]="true"
    (animationStateChanged)="closeAction($event)">
    <div class="action-settings">
        <div class="setting-row alternate">
            <div class="setting-label">Trigger</div>
            <div class="setting-value select">
                <div class="property-input">
                    <ui-select
                        [(selected)]="selectedTrigger"
                        (selectedChange)="onTriggerChange()"
                        [disabled]="disabled"
                        placeholder="Select a trigger"
                        [useTargetWidth]="true">
                        <ui-option
                            *ngFor="let trigger of triggers"
                            [value]="trigger">
                            {{ trigger.name }}
                        </ui-option>
                    </ui-select>
                </div>
            </div>
        </div>
        <div class="setting-row alternate">
            <div class="setting-label">Action</div>
            <div class="setting-value select">
                <div class="property-input">
                    <ui-select
                        data-test-id="action-select-action"
                        [(selected)]="selectedAction"
                        (selectedChange)="onActionMethodChange()"
                        [disabled]="disabled"
                        placeholder="Select an action"
                        [useTargetWidth]="true">
                        <ui-option
                            *ngFor="let action of selectableActions"
                            [selected]="action === selectedAction"
                            [value]="action">
                            {{ action.name }}
                        </ui-option>
                    </ui-select>
                </div>
            </div>
        </div>

        <ng-container *ngIf="isStateMethod">
            <div class="setting-row alternate">
                <div class="setting-label">Target</div>
                <div class="setting-value select">
                    <div class="property-input">
                        <ui-select
                            data-test-id="action-select-target"
                            [(selected)]="selectedTarget"
                            (selectedChange)="onStateTargetChange()"
                            [disabled]="disabled"
                            placeholder="No elements created"
                            [useTargetWidth]="true">
                            <ui-option
                                *ngFor="let target of targets"
                                [selected]="target.id === selectedTarget.id"
                                [value]="target">
                                {{ target.name }}
                            </ui-option>
                        </ui-select>
                    </div>
                </div>
            </div>

            <div
                class="setting-row alternate"
                *ngIf="selectedAction.value !== ActionOperationMethod.ClearStates">
                <div class="setting-label">State</div>
                <div class="setting-value select">
                    <div class="property-input">
                        <ui-select
                            [(selected)]="selectedState"
                            (selectedChange)="onStateValueChange()"
                            [disabled]="disabled"
                            placeholder="No states on target"
                            [useTargetWidth]="true">
                            <ui-option
                                *ngFor="let state of states"
                                [selected]="state.id === selectedState"
                                [value]="state">
                                {{ state.name }}
                            </ui-option>
                        </ui-select>
                    </div>
                </div>
            </div>

            <div
                class="setting-row reverse"
                *ngIf="isClickPreventionEligible(selectedTrigger.value)">
                <div class="setting-label">Trigger target URL on click</div>
                <div class="setting-value">
                    <div class="property-input">
                        <ui-toggle-switch
                            [(selected)]="allowClickThrough"
                            (selectedChange)="onPreventClicksChange()">
                        </ui-toggle-switch>
                    </div>
                </div>
            </div>

            <div class="divider"></div>

            <div class="setting-row">
                <div class="setting-label">Duration</div>
                <div class="setting-value col-2 wide">
                    <div class="property-input slider">
                        <studio-ui-slider
                            [(value)]="duration"
                            [disabled]="disabled"
                            (valueChange)="onDurationChange()"
                            (mouseup)="onDurationChange(true)"
                            [config]="{ min: 0, max: 10, accuracy: 100 }"></studio-ui-slider>
                    </div>
                    <div
                        class="property-input"
                        data-test-id="animation-duration">
                        <ui-number-input
                            [(value)]="duration"
                            data-test-id="animation-duration-input"
                            (valueChange)="onDurationChange(true)"
                            [disabled]="disabled"
                            [keyboardEmit]="true"
                            [min]="0"
                            [max]="10"
                            [step]="0.1"
                            [allowEmpty]="false"
                            [disableUndo]="true"></ui-number-input>
                    </div>
                </div>
            </div>

            <div class="setting-row alternate">
                <div class="setting-label">Easing</div>
                <div class="setting-value select easing-setting col-1 wide">
                    <ui-select
                        [selected]="timingFunction"
                        (selectedChange)="setEasing($event)"
                        placeholder="None"
                        [useTargetWidth]="true">
                        <ui-option
                            *ngFor="let timingFunction of timingFunctions | keyvalue"
                            [value]="timingFunction.key"
                            >{{ timingFunction.value.name }}</ui-option
                        >
                    </ui-select>
                </div>
            </div>
        </ng-container>

        <div
            class="setting-row alternate"
            *ngIf="selectedAction.value === ActionOperationMethod.OpenUrl">
            <div class="setting-label">
                URL
                <ui-svg-icon
                    icon="question-mark-s"
                    [uiTooltip]="urlActionTooltip"
                    [uiTooltipPosition]="'left'"
                    [uiTooltipInteractive]="true"
                    [uiTooltipMaxWidth]="'260px'">
                </ui-svg-icon>
            </div>
            <div class="setting-value">
                <div class="property-input">
                    <ui-input
                        #urlInput
                        [validation]="urlValidation!"
                        [value]="actionData.operations[0].value"
                        (blur)="onUrlInputBlur()"
                        (valueChange)="onUrlChange($event)">
                    </ui-input>
                </div>
            </div>
        </div>
    </div>
</section-expand>

<ui-dropdown
    #actionMenu
    width="150"
    [offset]="{ x: 18, y: 4 }"
    [positions]="[
        { originX: 'start', originY: 'bottom', overlayX: 'end', overlayY: 'top' },
        { originX: 'start', originY: 'top', overlayX: 'end', overlayY: 'bottom' }
    ]"
    type="menu">
    <ui-dropdown-item
        *ngIf="!actionData.disabled"
        (click)="onEditAction()">
        {{ actionSettingsOpened ? 'Close' : 'Edit action' }}
    </ui-dropdown-item>
    <ui-dropdown-item
        [svgIcon]="actionData.disabled ? 'de-action' : 'action-disabled'"
        (click)="onToggleDisableAction()">
        {{ actionData.disabled ? 'Enable action' : 'Disable action' }}
    </ui-dropdown-item>
    <ui-dropdown-item
        class="divider"
        svgIcon="delete"
        (click)="onDeleteAction()">
        Delete action
    </ui-dropdown-item>
</ui-dropdown>
