import { ICreative } from '@domain/creativeset/creative/creative';
import { ICreativeWeight } from '@domain/creativeset/creative/weight';
import { IVersion } from '@domain/creativeset/version';
import { cloneDeep } from '@studio/utils/clone';
import { CRC32checksum } from '@studio/utils/utils';
import { toFlatNodeList } from './nodes/helpers';
import {
    deserializeDesignDocument,
    stringifyDesignDocument
} from './serialization/document-serializer';
import { deserializeVersionProperties } from './serialization/property-value-serializer';

export function getVersionIdsFromCreatives(creatives: ICreative[]): string[] {
    return [...new Set(creatives.map(creative => creative.version.id))];
}

export function getSizeIdsFromCreatives(creatives: ICreative[]): string[] {
    return [...new Set(creatives.map(creative => creative.size.id))];
}

export function adjustWeightCalculation(creativeWeights: ICreativeWeight): ICreativeWeight {
    if (!creativeWeights.weights) {
        return creativeWeights;
    }

    const { initialLoad, subLoad, url, frameworkOverhead } = creativeWeights.weights;
    const subLoadAssets = subLoad?.assets || [];

    const filteredInitialLoadAssets = cloneDeep(
        subLoadAssets.filter(asset => asset.name === 'ad.js' || asset.name === 'preload.jpg')
    );
    const filteredSubLoadAssets = cloneDeep(
        subLoadAssets.filter(asset => asset.name !== 'ad.js' && asset.name !== 'preload.jpg')
    );
    const initialLoadTotalWeight = filteredInitialLoadAssets.reduce(
        (acc, curr) => acc + curr.weight,
        0
    );
    const subLoadTotalWeight = filteredSubLoadAssets.reduce((acc, curr) => acc + curr.weight, 0);

    return {
        ...creativeWeights,
        weights: {
            url,
            frameworkOverhead,
            initialLoad: {
                ...initialLoad,
                assets: filteredInitialLoadAssets,
                totalWeight: initialLoadTotalWeight
            },
            subLoad: {
                ...subLoad,
                assets: filteredSubLoadAssets,
                totalWeight: subLoadTotalWeight
            },
            totalWeight: (initialLoad?.totalWeight || 0) + (subLoad?.totalWeight || 0)
        }
    };
}

export function creativeToChecksum(creative: ICreative, version: IVersion, stateId?: string): string {
    const { id, design, targetUrl, size } = creative;
    const creativeHasDesign = !!design?.elements;

    if (creativeHasDesign && typeof design.document === 'string') {
        design.document = deserializeDesignDocument(design.document);
    }

    const elements = !creativeHasDesign
        ? undefined
        : design.elements.filter(element =>
              toFlatNodeList(design.document).find(e => e.id === element.id)
          );

    const filteredVersionProperties = !elements
        ? []
        : version.properties.filter(
              property =>
                  !!elements.find(
                      element =>
                          !!element.properties.find(elProp => elProp.versionPropertyId === property.id)
                  )
          );

    // Serialize since any 'Map' gets stringified as '{}' regardless its content
    const document = design ? JSON.parse(stringifyDesignDocument(design.document)) : undefined;

    const stringified = JSON.stringify({
        id,
        document,
        elements: elements,
        size,
        version: deserializeVersionProperties(filteredVersionProperties),
        targetUrl,
        versionTargetUrl: version.targetUrl,
        stateId: stateId || undefined
    });

    return CRC32checksum(stringified);
}

export function updateChecksumOfCreatives(
    dirtyCreatives: ICreative[],
    dirtyVersions: IVersion[],
    stateId?: string
): ICreative[] {
    return dirtyCreatives.reduce((results: ICreative[], creative: ICreative) => {
        const version = dirtyVersions.find(v => v.id === creative.version.id)!;
        const newChecksum = creativeToChecksum(creative, version, stateId);
        if (newChecksum !== creative.checksum) {
            creative.checksum = newChecksum;
            results.push(creative);
        }
        return results;
    }, []);
}
