import { Pipe, PipeTransform } from '@angular/core';
import { IVersionedElementPropertyValuesByElement } from '@domain/creativeset/version';

@Pipe({ standalone: true, name: 'headline' })
export class GroupHeadlinePipe implements PipeTransform {
    transform(elements: IVersionedElementPropertyValuesByElement[]): string {
        return elements.reduce(
            (prev, curr, index) => `${prev}${index !== 0 ? `, ${curr.name}` : curr.name}`,
            ''
        );
    }
}
