import { IUrlParameterMap } from '@domain/ad/url-parameters';
import { SimpleCache } from './simple-cache';

export const safeUrlRegex = /(javascript|about|data)(:|%3A)/gi;
const domainRegex = /^(?:https?:\/\/)?(?:[^@/\n]+@)?(?:www\.)?([^:/?\n]+)/gim;
const urlRegex =
    /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/i;

export function getUrlParameters(url: string | undefined = ''): IUrlParameterMap {
    const urlParams: { [key in string]: string } = {};

    const cache = SimpleCache.get<IUrlParameterMap>(url);

    if (cache) {
        return cache;
    }

    if (url && url.indexOf('?') > -1) {
        // For modern browsers
        try {
            new URL(url).searchParams.forEach((value, key) => {
                urlParams[key] = value;
            });
        } catch {
            url = url.substring(url.indexOf('?') + 1);

            let match: RegExpExecArray | null;
            // Regex for replacing addition symbol with a space
            const search = /([^&=]+)=?([^&]*)/g;
            const decode = (s: string): string => {
                try {
                    s = decodeURIComponent(s.replace(/\+/g, ' '));
                    // eslint-disable-next-line no-empty
                } catch {}
                return s;
            };

            const query = url || window.location.search.substring(1);

            while ((match = search.exec(query))) {
                urlParams[decode(match[1])] = decode(match[2]);
            }
        }
    }

    SimpleCache.set(url, urlParams);

    return urlParams;
}

export function isEncodedUrl(url: string): boolean {
    // What to do with app links like blocket://
    return (
        url
            .toLowerCase()
            .replace(/(https?%3A)?(?=%2F%2F)/i, '')
            .indexOf('%2f%2f') === 0
    );
}

export function isRelativeUrl(url: string): boolean {
    return !!url && url.replace(/^https?:/, '').indexOf('//') !== 0;
}

export function isUrl(url: string | undefined): boolean {
    return typeof url === 'string' && !!url.match(urlRegex);
}

export function isBase64Image(str?: string): boolean {
    return !!str && str.startsWith('data:image');
}

export function isBase64Mp4Video(str?: string): boolean {
    return !!str && str.startsWith('data:video/mp4;base64');
}

export function isBase64SVG(str?: string): boolean {
    return !!str && str.startsWith('data:image/svg+xml;base64');
}

export function addProtocol(
    url?: string,
    protocol: 'http' | 'https' | undefined = 'https'
): string | undefined {
    if (!url || url === '#') {
        return url;
    }

    // Links without protocol messes up iOS safari
    if (url.indexOf('//') === 0) {
        url = `${protocol}:${url}`;
    }

    // Make sure url starts with http:// or https://
    else if (url.indexOf('http') !== 0) {
        url = `${protocol}://${url}`;
    }

    return url;
}

/**
 * Merge two parts of a url.
 * Applies a slash between if missing and removes if it has two adjacent.
 * @param domain
 * @param path
 */
export function concatUrl(domain = '', path = ''): string {
    if (domain.length && path.length) {
        if (domain.charAt(domain.length - 1) !== '/') {
            domain += '/';
        }
        if (path.charAt(0) === '/') {
            path = path.substr(1);
        }
    }

    return domain + path;
}

export function getDomain(url?: string, stripProtocol = true): string | undefined {
    if (typeof url === 'string') {
        const match = url.match(domainRegex);
        if (match) {
            return stripProtocol ? removeProtocol(match[0]) : match[0];
        }
    }
}

export function getDomainFromURL(url: string): string {
    return new URL(url).hostname;
}

export function removeProtocol(url: string): string {
    return url.replace(/^((https?:)?\/\/)/gim, '');
}

export function hasParameter(parameters: IUrlParameterMap, key: string): boolean {
    return parameters[key] !== undefined;
}

export function parameterToBool(parameter?: string): boolean | undefined {
    if (!parameter) {
        return undefined;
    }

    switch (parameter.toLowerCase()) {
        case '1':
        case 'on':
        case 'true':
        case 'yes':
            return true;
        case '0':
        case 'off':
        case 'false':
        case 'no':
            return false;
        default:
            return undefined;
    }
}

export function parameterToUrl(parameter?: string): string {
    if (!parameter) {
        return '';
    }
    return isEncodedUrl(parameter) ? decodeURIComponent(parameter) : parameter;
}

export function replaceOrigin(url: string, newOrigin: string): string {
    if (!newOrigin) {
        return url;
    }
    return url.replace(new URL(url).origin, new URL(newOrigin).origin);
}

export function isBannerflow(url: string): boolean {
    if (!url) {
        return false;
    }
    try {
        const hostname = typeof URL === 'function' ? new URL(url).hostname : getDomain(url, true);
        return !!hostname && !!hostname.match(/bannerflow./g);
    } catch {
        return false;
    }
}

export function isSameDomain(a: string, b: string): boolean {
    return getDomain(a) === getDomain(b);
}

export function removeQueryParams(url: string): string {
    return url.replace(/\?.*/g, '');
}

export function getUrlsInString(str: string): string[] {
    return str?.match(/(https?:\/\/[^\s]+)/g) || [];
}

/**
 * Return the first url that's not undefined. '' means that we should prevent click
 */
export function getFirstUrl(...urls: Array<string | undefined> | []): string | undefined {
    // NOSONAR
    for (let i = 0; i < urls.length; i++) {
        if (urls[i] !== undefined && urls[i] !== null) {
            return urls[i];
        }
    }
    return undefined;
}

export function objectToQueryString(params: any, removeEmpty = true): string {
    if (typeof params === 'object') {
        const queries: string[] = [];
        for (const key in params) {
            const value = params[key];
            if ((value !== undefined && value.toString()) || !removeEmpty) {
                queries.push(`${key}=${value}`);
            }
        }

        return queries.length ? `?${queries.join('&')}` : '';
    }
    return '';
}

export function getFileExtension(url?: string): string | undefined {
    if (url) {
        const split = url.split(/[#?]/);
        const fragment = split[0];
        const dot = fragment.split('.');
        if (dot.length === 1) {
            return undefined; // no extension found
        }
        const extension = dot.pop()?.trim().toLowerCase();

        if (extension) {
            return /[a-zA-Z]/gi.test(extension) ? extension : undefined;
        }
    }
    return undefined;
}

export function removeFileExtension(value: string): string {
    return (value || '').replace(/\.(jpg|png|gif|svg|mp4|psd)$/i, '');
}

export function isVideoFileUrl(url: string): boolean {
    const ext = getFileExtension(url);
    if (ext) {
        return /(mp4|mov|webm|h264)/.test(ext);
    }
    return false;
}

export function isSVGFile(url: string): boolean {
    if (isBase64SVG(url)) {
        return true;
    }

    const ext = getFileExtension(url);
    return ext === 'svg';
}

export function isGIFFile(url: string): boolean {
    const ext = getFileExtension(url);
    return ext === 'gif';
}

export function isInLocalEnvironment(): boolean {
    if (!window) {
        return false;
    }
    if (window.location.protocol === 'file:') {
        return true;
    }

    return false;
}

export function getOrigin(str: string): string {
    try {
        const url = new URL(str);
        return url.origin;
    } catch {
        try {
            if (!isUrl(str)) {
                throw new Error('Provided value is not a valid url.');
            }
            const a = document.createElement('a');
            a.href = str;
            return a.origin;
        } catch (e) {
            throw new Error(e as string);
        }
    }
}

export function getFileName(url: string): string {
    const segments = new URL(url).pathname.split('/');
    return segments.pop() || url;
}

export function getFileLocation(url: string): string {
    const segments = url.split('/');
    segments.pop();
    return segments.join('/');
}

export function addMissingProtocol(url: string): string {
    return /https?:\/\//.test(url) ? url : `https://${url}`;
}

export function extractURL(str: string): string | undefined {
    const url = /http[s]?:\/\/[^\s]+/i;
    const match = str.match(url);
    return match ? match[0] : undefined;
}
