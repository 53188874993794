<div
    *ngIf="(sizes$ | async)?.length"
    class="size-list"
    data-test-id="size-list-horizontal">
    <div
        *ngFor="let size of sizes$ | async"
        class="size-list-element-wrapper"
        [id]="'h-size-item-' + size.id"
        (click)="selectSize(size)">
        <div
            class="size-list-element"
            data-test-id="size-list-horizontal-item"
            [ngClass]="{ selected: size.id === selectedSizeId }">
            {{ size.width }} × {{ size.height }}
        </div>
    </div>
</div>
