import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Logger } from '@bannerflow/sentinel-logger';
import { CreativeMode, ICreativeEnvironment } from '@domain/creative/environment';
import { IAppEnvironment, StudioPage } from '@domain/environment';
import { Breakpoint, getQueryFromBreakpoint } from '@studio/utils/breakpoints';
import { BehaviorSubject, Observable, combineLatest, map, of, tap } from 'rxjs';
import { environment } from '../../../environments/environment';

interface BFStudio {
    activePage?: StudioPage;
    inPreviewMode: boolean;
    inShowcaseMode: boolean;
    environment: IAppEnvironment;
}

@Injectable({ providedIn: 'root' })
export class EnvironmentService {
    inShowcaseMode$: Observable<boolean>;
    inShowcaseMode: boolean;

    isMobile: boolean;
    isMobile$: Observable<boolean>;

    isMobileShowcase: boolean;
    isMobileShowcase$: Observable<boolean>;

    private _activePage$ = new BehaviorSubject<StudioPage | undefined>(undefined);
    activePage$ = this._activePage$.asObservable();

    bfstudio: BFStudio = {
        inPreviewMode: false,
        inShowcaseMode: false,
        environment: environment
    };

    env: ICreativeEnvironment;

    private logger = new Logger('EnvironmentService');

    constructor(private breakpointObserver: BreakpointObserver) {
        this.inShowcaseMode = this.isShowcaseUrl(window.location.href);
        this.inShowcaseMode$ = of(this.inShowcaseMode);

        this.isMobile = window.matchMedia(getQueryFromBreakpoint(Breakpoint.DesktopDown)).matches;
        this.isMobile$ = this.breakpointObserver
            .observe(getQueryFromBreakpoint(Breakpoint.DesktopDown))
            .pipe(
                map(matchBreakpoint => matchBreakpoint.matches),
                tap(isMobile => (this.isMobile = isMobile))
            );

        this.isMobileShowcase = this.isMobile && this.inShowcaseMode;
        this.isMobileShowcase$ = combineLatest([this.inShowcaseMode$, this.isMobile$]).pipe(
            map(([showcaseMode, isMobile]) => showcaseMode && isMobile)
        );
        this.logger.verbose({
            inShowcaseMode: this.inShowcaseMode,
            isMobile: this.isMobile,
            isMobileShowcase: this.isMobileShowcase
        });

        this.setEnv();
    }

    setEnv(): void {
        const { fontService, imageOptimizer, feedStorage, fontStorage, videoStorage } =
            environment.origins;

        this.env = {
            STUDIO_JS: true,
            FONTSERVICE_API_ORIGIN: fontService,
            IMAGE_OPTIMIZER_ORIGIN: imageOptimizer,
            FEEDS_STORAGE_ORIGIN: feedStorage,
            FONTS_AZURE_STORAGE_CDN_ORIGIN: fontStorage,
            VIDEOS_STORAGE_ORIGIN: videoStorage,
            MODE: CreativeMode.ManageView // the MODE will be overwritten by each DI container. Use environment.service for env info
        } as ICreativeEnvironment;
    }

    setGlobals(): void {
        (window.process as any) = { env: this.env };

        window.bfstudio = this.bfstudio;

        // in test, we rely on some CSS hax
        if (environment.stage === 'test') {
            document.body.classList.add('in-test');
            window.process.env.IN_TEST = true;
            this.env.IN_TEST = true;
        }
    }

    setPage(name: StudioPage): void {
        this._activePage$.next(name);
        this.bfstudio.activePage = name;

        if (name === 'DV') {
            this.bfstudio.inPreviewMode = false;
            document.body.classList.remove('manage-view');
            document.body.classList.add('design-view');
        } else if (name === 'MV') {
            this.bfstudio.inPreviewMode = true;
            document.body.classList.remove('design-view');
            document.body.classList.add('manage-view');
        }
    }

    isPage(name: StudioPage): boolean {
        return this.bfstudio.activePage === name;
    }

    private isShowcaseUrl(url: string): boolean {
        return !!url.match(/share/);
    }
}
