<div
    class="text"
    *ngIf="indexedText"
    [dir]="getTextDirection()">
    <ng-container *ngFor="let span of indexedText.spans">
        <br *ngIf="isNewline(span)" />
        <span
            *ngIf="!isNewline(span)"
            [uiTooltip]="'Apply character styling'"
            [uiTooltipDisabled]="!span.attributes.styleIndex"
            [uiTooltipPosition]="'top'"
            [uiTooltipHideArrow]="true"
            uiTooltipType="discrete"
            [ngClass]="{
                'character-styling': span.attributes.styleIndex !== undefined,
                space: isSpace(span)
            }"
            (mousedown)="selectStyling(span)"
            >{{ renderSpan(span) }}</span
        >
        <sup *ngIf="shouldShowIndex(span)">{{ span.attributes.styleIndex }}</sup>
    </ng-container>
</div>
