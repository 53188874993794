import { ElementRef, Injectable } from '@angular/core';
import { IUIPopoverConfig, UIPopoverRef, UIPopoverService } from '@bannerflow/ui';
import { IFeedStore } from '@domain/creative/feed/feed-store.header';
import { IFeed, IFeedStep } from '@domain/feed';
import { cloneDeep } from '@studio/utils/clone';
import { Subject } from 'rxjs';
import { FeedPopoverComponent } from './feed-popover.component';
import { FeedService } from './feed.service';

interface OpenOptions {
    disableInputs?: boolean;
    disableEditSource?: boolean;
}

@Injectable({ providedIn: 'root' })
export class FeedSettingService {
    private feedPopoverRef: UIPopoverRef<FeedPopoverComponent>;
    private config: IUIPopoverConfig = {
        position: 'left',
        arrowPosition: 'right',
        panelClass: 'no-padding',
        width: '229px',
        maxWidth: '229px',
        minWidth: '229px',
        theme: 'tiny',
        backgroundColor: 'var(--studio-color-surface-secondary)'
    };

    feed: IFeed;
    visibleFeedStep: IFeedStep | undefined;
    oldFeed: IFeed;
    elementId: string;
    feedStore: IFeedStore;
    totalFeedItems: number;

    openingInProcess = false;

    private _feedValueChanged$ = new Subject<{ newFeed: IFeed; oldFeed: IFeed }>();
    feedValueChanged$ = this._feedValueChanged$.asObservable();

    private _updateFeedPopOver$ = new Subject<void>();
    updateFeedPopOver$ = this._updateFeedPopOver$.asObservable();

    private _close$ = new Subject<void>();
    close$ = this._close$.asObservable();

    lastFeedPillClicked: string | undefined; // ugly hack to avoid global vars, I'm truly sorry

    constructor(
        private feedService: FeedService,
        private uiPopover: UIPopoverService
    ) {}

    async open(
        target: ElementRef,
        elementId: string,
        feed: IFeed,
        visibleFeedStep: IFeedStep | undefined,
        feedStore: IFeedStore,
        { disableInputs = false, disableEditSource = false }: OpenOptions,
        config?: IUIPopoverConfig
    ): Promise<void> {
        if (this.openingInProcess) {
            return;
        } else {
            this.openingInProcess = true;
        }

        this.close();
        await this.feedService.getFeeds();

        this.feed = feed;
        this.visibleFeedStep = visibleFeedStep;
        this.oldFeed = cloneDeep(feed);
        this.elementId = elementId;
        this.feedStore = feedStore;
        const popoverConfig = {
            ...this.config,
            ...config
        };

        this.feedPopoverRef = this.uiPopover.openComponent<FeedPopoverComponent>(
            target,
            FeedPopoverComponent,
            {
                ...popoverConfig,
                data: {
                    ...popoverConfig.data,
                    disableInputs
                }
            }
        );

        this.feedPopoverRef.onClose.subscribe(() => {
            this._close$.next();
        });

        if (disableEditSource) {
            this.feedPopoverRef.afterViewInit.then(() => {
                this.feedPopoverRef?.subComponentRef?.instance?.hideEditSource();
            });
        }
        this.openingInProcess = false;
    }

    changeFeedValue(feed: IFeed): void {
        this._feedValueChanged$.next({ newFeed: feed, oldFeed: this.oldFeed });
    }

    updateFeedPopOverValues(feed: IFeed, feedStore: IFeedStore): void {
        this.feed = feed;
        this.feedStore = feedStore;
        this._updateFeedPopOver$.next();
    }

    close(): void {
        this._close$.next();
        if (this.feedPopoverRef) {
            this.feedPopoverRef.close();
        }
    }
}
