import { Ad } from '@ad';
import { getStudioAdDataCreative, IInputCreative } from '@ad/data/get-ad-data-creative';
import { Location } from '@angular/common';
import {
    AfterViewInit,
    Component,
    DestroyRef,
    ElementRef,
    inject,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Logger } from '@bannerflow/sentinel-logger';
import {
    UIBodyComponent,
    UIConfirmCloseDialogCallback,
    UIConfirmDialogService,
    UIDialogService,
    UINotificationService,
    UITooltipService
} from '@bannerflow/ui';
import {
    getElementAndAnimationOfKeyframe,
    getKeyframeById,
    isTransitionAnimation
} from '@creative/animation.utils';
import { IAnimator } from '@creative/animator.header';
import { ICreativeWrapper } from '@creative/creative-wrapper.header';
import { createCreativeContainer, ICreativeFactory, T } from '@creative/creative.container';
import {
    copyVersionPropertyValue,
    isVariableSpan,
    isVersionedText,
    remapStyles
} from '@creative/elements/rich-text/utils';
import { calculateBoundingBox } from '@creative/elements/utils';
import { applyWidgetCodeOnWidgetNodes } from '@creative/elements/widget/utils';
import {
    mergeFontFamilies,
    tryGetFontByStyleId,
    tryGetFontStyleById
} from '@creative/font-families.utils';
import { getLibraryKindFromElementKind, GroupDataNode, isImageElement } from '@creative/nodes';
import { canCreateGroup } from '@creative/nodes/data-node.utils';
import {
    createVersionedTextFromText,
    forEachDataElement,
    getTextElements,
    initializeElements,
    isGroupDataNode,
    isHidden,
    isImageNode,
    isTextNode,
    isVideoNode,
    isWidgetNode,
    toFlatElementNodeList,
    toFlatNodeList
} from '@creative/nodes/helpers';
import { IRenderer } from '@creative/renderer.header';
import { isAnimationState, setNewPropertyIds } from '@creative/rendering/states.utils';
import { isValidationError } from '@creative/serialization/validator.utils';
import { ActionOperationMethod } from '@domain/action';
import { AnimationType, AnimationTypes, IAnimation, IAnimationKeyframe } from '@domain/animation';
import { IBrandLocalization } from '@domain/brand/brand';
import { IBrandLibraryElement, INewBrandLibraryElement } from '@domain/brand/brand-library';
import { ISerializedCopy } from '@domain/copy-paste';
import { CreativeMode, ICreativeEnvironment } from '@domain/creative/environment';
import { ICreativeset, IDesign } from '@domain/creativeset';
import { IElement, IElementProperty, IElementPropertyValues } from '@domain/creativeset/element';
import { IImageElementAsset, IVideoElementAsset } from '@domain/creativeset/element-asset';
import { CreativeSize } from '@domain/creativeset/size';
import { ITextSpan, IVersion, IVersionProperty } from '@domain/creativeset/version';
import { IBoundingBox, IPosition, ISize } from '@domain/dimension';
import { ElementKind } from '@domain/elements';
import { IFontFamily } from '@domain/font-families';
import { IHotkeyContext } from '@domain/hotkeys/hotkeys.types';
import {
    ICreativeDataNode,
    OneOfDataNodes,
    OneOfDataNodeValues,
    OneOfElementDataNodes,
    OneOfElementPropertyKeys,
    OneOfGroupDataNodes,
    OneOfTextDataNodes,
    OneOfTextViewElements
} from '@domain/nodes';
import { IState } from '@domain/state';
import { IRadius } from '@domain/style';
import { IWordSpan, SpanType } from '@domain/text';
import { IWidgetElementDataNode } from '@domain/widget';
import { TransformMode } from '@domain/workspace';
import { concatLatestFrom } from '@ngrx/operators';
import { isKnownErrorResponse } from '@studio/domain/api/error';
import { ErrorStatus, KnownErrorResponse } from '@studio/domain/api/error.types';
import { IFontValidationState } from '@studio/domain/font-validation';
import { BrowserDefaultHotkeys } from '@studio/hotkeys';
import { ActivityLoggerService } from '@studio/monitoring/activity-logger.service';
import {
    ElementPatchedEvent,
    EventLoggerService,
    HistoryRedoEvent,
    HistoryUndoEvent,
    SaveCreativeEndEvent,
    SaveCreativeEvent,
    SaveCreativeLogEvent
} from '@studio/monitoring/events';
import { BrandService } from '@studio/stores/brand';
import { FontFamiliesService } from '@studio/stores/font-families';
import { UserSettingsService } from '@studio/stores/user-settings';
import { cloneDeep } from '@studio/utils/clone';
import { loadCursors, setCursorOnElement } from '@studio/utils/cursor';
import { Container } from '@studio/utils/di';
import { uuidv4 } from '@studio/utils/id';
import { hasImageReference, isElementMediaAssetLoading, isMediaElementNode } from '@studio/utils/media';
import { SimpleCache } from '@studio/utils/simple-cache';
import { deepEqual, generateUniqueName } from '@studio/utils/utils';
import {
    firstValueFrom,
    interval,
    lastValueFrom,
    merge,
    mergeMap,
    Observable,
    of,
    Subject
} from 'rxjs';
import {
    debounce,
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    skip,
    skipUntil,
    switchMap,
    take,
    tap,
    withLatestFrom
} from 'rxjs/operators';
import { NavigationGuard } from '../../routes/navigation.guard';
import { FontManagerComponent } from '../../shared/components/fonts/font-manager.component';
import { CreativesetDataService } from '../../shared/creativeset/creativeset.data.service';
import { FiltersService } from '../../shared/filters/state/filters.service';
import { BrandLibraryDataService } from '../../shared/media-library/brand-library.data.service';
import { MediaLibraryService } from '../../shared/media-library/state/media-library.service';
import { EnvironmentService } from '../../shared/services/environment.service';
import { ErrorsRedirectionService } from '../../shared/services/errors-redirection.service';
import { FeatureService } from '../../shared/services/feature/feature.service';
import { FontValidationService } from '../../shared/services/font-validation.service';
import { HotkeyBetterService } from '../../shared/services/hotkeys/hotkey.better.service';
import { StudioRoutingService } from '../../shared/services/studio-routing.service';
import { VersionsService } from '../../shared/versions/state/versions.service';
import { createAdDataCreativeVersion } from '../../shared/versions/versions.utils';
import { getDesignViewTitle } from '../page-title-util';
import { SERVICES } from './design-view.services';
import { DraggableContainerDirective } from './draggable-container.directive';
import { EditorTopbarComponent } from './editor-topbar/editor-topbar.component';
import { MediaLibraryComponent } from './media-library/media-library.component';
import { PropertiesPanelComponent } from './properties-panel/properties-panel.component';
import { PropertiesService } from './properties-panel/properties.service';
import {
    ElementSelectionBoundingBoxService,
    ElementSelectionService,
    ValidationService
} from './services';
import { CopyPasteService } from './services/copy-paste.service';
import { EditorEventService, ElementChangeType, isElementChange } from './services/editor-event';
import { EditorSaveStateService, EditorSaveStatus } from './services/editor-save-state.service';
import { EditorStateService } from './services/editor-state.service';
import { ElementCreatorService } from './services/element-creator.service';
import { HistoryService, IEditorSnapshot } from './services/history.service';
import { MutatorService } from './services/mutator.service';
import { SaveErrorHandlerService } from './services/save-error-handler.service';
import { AnimationService, KeyframeService } from './timeline';
import { StudioTimelineComponent } from './timeline/studio-timeline/studio-timeline.component';
import { StudioToolbarComponent } from './toolbar/studio-toolbar.component';
import { StudioWorkspaceComponent } from './workspace/studio-workspace.component';
import { ZoomControlService } from './workspace/zoom-control/zoom-control.service';

function filterChangeEvent<T extends { type: ElementChangeType }>(
    source: Observable<T>
): Observable<T> {
    return source.pipe(
        filter(({ type }) => type !== undefined && type !== ElementChangeType.Skip),
        debounce(({ type }) => (type === ElementChangeType.Burst ? interval(500) : of(true)))
    );
}

@Component({
    templateUrl: './design-view.component.html',
    styleUrls: ['./design-view.component.scss'],
    providers: SERVICES
})
export class DesignViewComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('workspace') workspace: StudioWorkspaceComponent;
    @ViewChild('timeline') timeline: StudioTimelineComponent;
    @ViewChild('topbar') topbar: EditorTopbarComponent;
    @ViewChild('uiBody') uiBody: UIBodyComponent;
    @ViewChild('mediaLibrary') mediaLibrary: MediaLibraryComponent;
    @ViewChild('fontManager') fontManager: FontManagerComponent;
    @ViewChild('toolbar') toolbar: StudioToolbarComponent;
    @ViewChild('propertiesPanel') propertiesPanel: PropertiesPanelComponent;
    @ViewChild(DraggableContainerDirective, { static: true })
    draggableContainer: DraggableContainerDirective;
    public viewContainerRef = inject(ViewContainerRef);
    public editorStateService = inject(EditorStateService);
    public host = inject(ElementRef);
    public propertiesService = inject(PropertiesService);
    private uiNotificationService = inject(UINotificationService);
    private creativesetDataService = inject(CreativesetDataService);
    private keyframeService = inject(KeyframeService);
    private mediaLibraryService = inject(MediaLibraryService);
    private router = inject(Router);
    private activatedRoute = inject(ActivatedRoute);
    private historyService = inject(HistoryService);
    private uiConfirmDialogService = inject(UIConfirmDialogService);
    private navigationGuard = inject(NavigationGuard);
    private hotkeyBetterService = inject(HotkeyBetterService);
    private uiDialogService = inject(UIDialogService);
    private uiTooltipService = inject(UITooltipService);
    private appContainer = inject(Container<T>);
    private location = inject(Location);
    private activityLoggerService = inject(ActivityLoggerService);
    private titleService = inject(Title);
    private saveErrorHandlerService = inject(SaveErrorHandlerService);
    private editorSaveStateService = inject(EditorSaveStateService);
    private copyPasteService = inject(CopyPasteService);
    private animationService = inject(AnimationService);
    private validationService = inject(ValidationService);
    private editorEventService = inject(EditorEventService);
    private zoomControlService = inject(ZoomControlService);
    private mutatorService = inject(MutatorService);
    private eventLoggerService = inject(EventLoggerService);
    private elementSelectionService = inject(ElementSelectionService);
    private fontValidationService = inject(FontValidationService);
    private elementSelectionBoundingBoxService = inject(ElementSelectionBoundingBoxService);
    private brandService = inject(BrandService);
    private fontFamiliesService = inject(FontFamiliesService);
    private versionsService = inject(VersionsService);
    private elementCreatorService = inject(ElementCreatorService);
    private environmentService = inject(EnvironmentService);
    private brandLibraryDataService = inject(BrandLibraryDataService);
    private filtersService = inject(FiltersService);
    private userSettingsService = inject(UserSettingsService);
    private studioRoutingService = inject(StudioRoutingService);
    private errorsRedirectionService = inject(ErrorsRedirectionService);
    private featureService = inject(FeatureService);

    readonly mediaLibraryWidth = 200;
    renderer: IRenderer;
    animator: IAnimator | undefined;
    loading = true;
    notifyIsPlaying$ = new Subject<boolean>();
    workspaceInit$: Observable<boolean>;
    designViewInit$: Observable<boolean>;
    versionPickerIsOpen = false;
    mouseOverTimeline = false;
    container: Container<T>;
    isMediaLibraryOpen?: boolean;

    private currentCursor: string;
    private creativeset: ICreativeset;
    private creative: ICreativeWrapper;
    private selectedVersion: IVersion;
    private versions: IVersion[];
    private defaultVersion: IVersion;
    private fontFamilies: IFontFamily[] = [];
    private localizations: IBrandLocalization[];
    private creativeSetFontFamilies: IFontFamily[] = [];
    private initErrors = false;
    private _editingElement?: IBrandLibraryElement | INewBrandLibraryElement;
    // Keep track of elements that will suspend blur of text element.
    private richTextBlurSuspensionElements = new Set<HTMLElement>();
    private isSaving = false;
    private destroyed = false;
    private logger = new Logger('DesignView');
    private skipPristineStateCheck = false;
    private destroyRef = inject(DestroyRef);

    get time(): number {
        return this.animator ? this.animator.time : 0;
    }

    get editingElement(): IBrandLibraryElement | INewBrandLibraryElement | undefined {
        return this._editingElement;
    }

    get copiedSnapshot(): IEditorSnapshot | undefined {
        return this.copyPasteService.copiedSnapshot;
    }

    set editingElement(element: IBrandLibraryElement | INewBrandLibraryElement | undefined) {
        this.mediaLibraryService.setIsEditingElement(!!element);
        this._editingElement = element;
    }

    constructor() {
        this.environmentService.setPage('DV');
        this.historyService.editor = this;
        this.loading = this.creativesetDataService.creativeset === undefined;

        /* on a fast machine the loader closes so quick it looks like flimmer. */
        if (this.creativesetDataService.creativeset !== undefined) {
            this.loading = false;
        }

        // For debugging purposes
        window.editorPage = this;

        this.creativesetDataService.creativeset$.pipe(takeUntilDestroyed()).subscribe(creativeset => {
            window.creativeset = creativeset;
            this.creativeset = creativeset;
        });

        this.historyService.onChange$
            .pipe(
                skip(1), // Skip first validation. Avoids validating when no chagnes were made
                filter((_value, index) => index % 5 === 0), // every 5th action trigger
                withLatestFrom(this.editorSaveStateService.isUploading$),
                filter(([_, isUploading]) => !isUploading), // ignore validation while uploading / saving
                takeUntilDestroyed()
            )
            .subscribe(() => {
                this.logger.verbose('User action triggered validation!');
                this.validateCreative();
            });

        this.validationService.rollback$.pipe(takeUntilDestroyed()).subscribe(async snapshot => {
            this.logger.log('Rolling back to latest validated snapshot.');
            await this.useSnapshot(snapshot);
            this.historyService.clear(); // clear history of snapshots when reverting to prevent the user to get back to an invalid state.
            this.rerenderCanvas();
        });

        this.workspaceInit$ = this.editorEventService.workspaceInit$;
        this.designViewInit$ = this.editorEventService.designViewInit$;
        this.designViewInit$
            .pipe(takeUntilDestroyed())
            .subscribe(() =>
                this.editorSaveStateService.setDisabled(this.editorStateService.sizeIsActive)
            );

        this.fontValidationService.fontValidationChange$
            .pipe(
                takeUntilDestroyed(),
                map(fontValidationResults => fontValidationResults.filter(result => !result.valid)),
                mergeMap(fontValidationResults =>
                    this.fontValidationService.filterIgnoredResults(fontValidationResults)
                ),
                filter(fontValidationResults => !!fontValidationResults.length)
            )
            .subscribe(fontValidationResults => {
                this.processFontValidationResult(fontValidationResults);
            });

        this.brandService.localizations$.pipe(take(1)).subscribe(localizations => {
            this.localizations = localizations;
        });

        this.fontFamiliesService.fontFamilies$.pipe(takeUntilDestroyed()).subscribe(fontFamilies => {
            this.fontFamilies = fontFamilies;
        });

        this.fontFamiliesService.creativeSetFontFamilies$
            .pipe(takeUntilDestroyed())
            .subscribe(creativeSetFontFamilies => {
                this.creativeSetFontFamilies = creativeSetFontFamilies;
            });

        this.versionsService.versions$.pipe(takeUntilDestroyed()).subscribe(versions => {
            this.versions = versions;
        });

        this.versionsService.defaultVersion$.pipe(takeUntilDestroyed()).subscribe(defaultVersion => {
            this.defaultVersion = defaultVersion;
        });

        this.versionsService.selectedVersion$
            .pipe(
                takeUntilDestroyed(),
                concatLatestFrom(() => this.versionsService.newVersionPropertiesIds$)
            )
            .subscribe(async ([selectedVersion, newVersionPropertiesIds]) => {
                if (!this.editorStateService.document || this.historyService.isApplyingSnapshot) {
                    this.selectedVersion = selectedVersion;
                    return;
                }
                const prevSelected = this.selectedVersion?.id || selectedVersion.id;
                this.selectedVersion = selectedVersion;
                this.mutatorService.resolveAllCharacterStyles(true);
                this.updateElementsVersionedProperties(prevSelected);
                await this.syncVersionsFromStore();
                this.copyVersionPropertiesToOriginal(newVersionPropertiesIds);
                await this.syncVersionsFromStore();
                this.rerenderCanvas();

                if (this.featureService.isDesignApiFeatureEnabled()) {
                    const currentSize = this.editorStateService.size;
                    const creative = this.creativeset.creatives.find(
                        ({ size, version }) =>
                            size.id === currentSize.id && version.id === selectedVersion.id
                    );

                    if (!creative) {
                        throw new Error(
                            `Could not find creative with size id ${currentSize.id} and version id ${selectedVersion.id}.`
                        );
                    }

                    this.editorStateService.initCreative(creative.id);
                }
            });

        merge(
            this.versionsService.defaultVersionProperties$,
            this.versionsService.selectedVersionProperties$
        )
            .pipe(
                concatLatestFrom(() => [
                    this.versionsService.defaultVersion$,
                    this.versionsService.selectedVersion$
                ]),
                takeUntilDestroyed()
            )
            .subscribe(([_, defaultVersion, selectedVersion]) => {
                this.defaultVersion = defaultVersion;
                this.selectedVersion = selectedVersion;
            });

        this.editorSaveStateService.state$
            .pipe(takeUntilDestroyed())
            .subscribe(state => (this.isSaving = state.status === EditorSaveStatus.Saving));

        this.editorSaveStateService.save$
            .pipe(
                withLatestFrom(this.editorSaveStateService.state$),
                map(([saveOptions, state]) => ({ saveOptions, state })),
                filter(({ state }) => state.status === EditorSaveStatus.Idle),
                takeUntilDestroyed()
            )
            .subscribe(({ saveOptions }) => {
                this.editorSaveStateService.setStatus(EditorSaveStatus.Saving);
                this.onSaveCreative(saveOptions.saveAll, saveOptions.saveAndExit);
            });

        this.historyService.onDirtyChange$.pipe(takeUntilDestroyed()).subscribe(isDirty => {
            if (!this.editorStateService.sizeIsActive) {
                this.editorSaveStateService.setDisabled(false);
                return;
            }

            this.editorSaveStateService.setDisabled(!isDirty);
        });

        // Ensures correct data propagation to properties panel by reselecting elements programmatically after a renderer update
        this.editorStateService.renderer$
            .pipe(skipUntil(this.editorEventService.workspaceViewInit$), takeUntilDestroyed())
            .subscribe(renderer => {
                const currentSelection = this.elementSelectionService.currentSelection.elements.flatMap(
                    ({ id }) => id
                );
                const newSelection = toFlatNodeList(renderer.creativeDocument).filter(({ id }) =>
                    currentSelection.some(selectedElementId => selectedElementId === id)
                );
                this.elementSelectionService.clearSelection();
                this.elementSelectionService.setSelection(...newSelection);
            });

        this.filtersService.selectedCreativeSizes$
            .pipe(skip(1), withLatestFrom(this.versionsService.selectedVersion$), takeUntilDestroyed())
            .subscribe(([creativeSizes, version]) => {
                if (this.featureService.isDesignApiFeatureEnabled()) {
                    return;
                }

                const creative = this.creativeset.creatives.find(
                    c =>
                        c.version.id === version.id && creativeSizes.some(size => size.id === c.size.id)
                );

                if (!creative) {
                    throw new Error('Could not get creative');
                }

                this.editorStateService.initCreative(creative.id);
                this.workspace.centerCanvas();
            });

        this.editorStateService.creativeChanged$.pipe(takeUntilDestroyed()).subscribe(() => {
            this.rerenderCanvas();
        });
    }

    ngAfterViewInit(): void {
        // TODO: Refactor this to a NavigationGuard
        const navigationEnded$ = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        );

        this.activatedRoute.queryParams
            .pipe(
                take(1),
                filter(
                    queryParams =>
                        queryParams['version'] === 'all' ||
                        (queryParams['version'] ?? '').split(',').length
                ),
                debounce(() => navigationEnded$),
                switchMap(() => this.versionsService.selectedVersions$.pipe(take(1)))
            )
            .subscribe(selectedVersions => {
                this.filtersService.selectVersionById(selectedVersions[0].id);
            });

        loadCursors();
    }

    updateElementsVersionedPropertiesForSelectedVersion(): void {
        this.updateElementsVersionedProperties(this.selectedVersion.id);
    }

    private updateElementsVersionedProperties(changedVersion: string): void {
        for (const node of this.editorStateService.document.elements) {
            if (!isTextNode(node)) {
                continue;
            }
            const element = this.editorStateService.getElementById(node.id);
            const contentProperty = element.properties.find(({ name }) => name === 'content');

            if (!contentProperty?.versionPropertyId) {
                continue;
            }

            const newValue = createVersionedTextFromText(node.content);
            const updatedVersionProperty: IVersionProperty = {
                id: contentProperty.versionPropertyId,
                name: contentProperty.name,
                value: newValue
            };
            this.versionsService.upsertVersionProperty(changedVersion, updatedVersionProperty);
        }
    }

    private copyVersionPropertiesToOriginal(newVersionPropertiesIds: string[]): void {
        for (const selectedVersionProperty of this.selectedVersion.properties) {
            if (
                newVersionPropertiesIds.includes(selectedVersionProperty.id) ||
                !this.editorStateService.defaultVersionProperties.find(
                    vp => vp.id === selectedVersionProperty.id
                )
            ) {
                const versionProperty = {
                    id: selectedVersionProperty.id,
                    name: selectedVersionProperty.name,
                    value: copyVersionPropertyValue(selectedVersionProperty)
                };
                this.editorStateService.upsertDefaultVersionProperty(versionProperty);
            }
        }
    }

    private updateSnapshotFromStorage(parsedCopy: ISerializedCopy): void {
        const guidelines = this.workspace.design.document.guidelines;
        const activeGuideline = this.workspace.transform.onGuidelineChange$.getValue();
        try {
            this.copyPasteService.updateCopyFromStorage(
                parsedCopy,
                this.time,
                guidelines,
                activeGuideline,
                this.selectedVersion.id
            );
        } catch (error) {
            this.logger.error(error);
        }
    }

    async ngOnInit(): Promise<void> {
        this.mediaLibraryService.isOpen$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(isOpen => {
            this.isMediaLibraryOpen = isOpen;
        });

        window.addEventListener('storage', (e: StorageEvent) => {
            if (e.key === 'copiedSnapshot' && e.newValue !== null) {
                const parsedCopy = JSON.parse(e.newValue);
                if (
                    this.copyPasteService.copyPasteChecker(
                        parsedCopy,
                        this.creativesetDataService.brand.id
                    )
                ) {
                    this.updateSnapshotFromStorage(parsedCopy);
                }
            }
        });

        if (!this.brandLibraryDataService.brandLibrary) {
            this.brandLibraryDataService.brandLibraryLoaded = new Promise(resolve => {
                this.brandLibraryDataService.brandLibrary$.pipe(take(1)).subscribe(() => {
                    resolve();
                    this.logger.verbose('BrandLibrary loaded successfully');
                });
                this.brandLibraryDataService.loadBrandLibrary();
            });
        }
        this.editorStateService.__appComponentViewRef = this.draggableContainer.viewContainerRef;

        this.navigationGuard.addPristineUnloadCheck(this.historyService.isPristine);
        this.navigationGuard.addPristineCheck(this.checkPristineState);
        this.mediaLibraryService.init();
        await this.startInit();
    }

    private setEditorPageTitle({ width, height }: CreativeSize, creativeSetName: string): void {
        this.titleService.setTitle(getDesignViewTitle(width, height, creativeSetName));
    }

    private async startInit(): Promise<void> {
        this.logger.verbose('startInit');
        const params = this.activatedRoute.snapshot.params;

        try {
            await this.editorStateService.initCreative(params.creative);
            await this.init();
        } catch (e) {
            this.logger.error(e);
            this.initErrors = true;
            this.navigationGuard.removePristineCheck(this.checkPristineState);
            await this.router.navigate([
                '/brand',
                this.creativesetDataService.brand.id,
                'creativeset',
                this.creativeset.id
            ]);
        }
    }

    rerenderCanvas(): void {
        this.logger.debug('RerenderCanvas');

        const version = createAdDataCreativeVersion(this.selectedVersion, this.localizations);

        initializeElements(this.editorStateService.document, this.editorStateService.elements, {
            fontFamilies: this.fontFamilies,
            versionProperties: this.selectedVersion.properties,
            defaultVersionProperties: this.defaultVersion.properties
        });

        this.renderer.WidgetRenderer?.creativeApi.updateVersionData_m(version);

        this.renderer.rerender_m(this.editorStateService.document);
        this.editorStateService.setRenderer(this.renderer);

        this.editorEventService.renderedCanvas();
    }

    private async init(): Promise<void> {
        this.setEditorPageTitle(this.editorStateService.size, this.creativeset.name);
        const env = {
            ...this.environmentService.env,
            MODE: CreativeMode.DesignView
        } as ICreativeEnvironment;
        this.container = createCreativeContainer(env);
        this.container.parent = this.appContainer;
        this.container.register_m(T.EDITOR_HISTORY_SERVICE, this.historyService);
        this.container.register_m(T.EDITOR_STATE, this.editorStateService);
        this.container.register_m(T.EDITOR_EVENT, this.editorEventService);
        this.container.register_m(T.ENVIRONMENT, env); // maybe move that into the createCreativeContainer

        if (!this.selectedVersion && this.activatedRoute.snapshot.queryParams.version === 'all') {
            this.filtersService.selectVersion(this.defaultVersion);
        }

        const fontFamilies = await lastValueFrom(this.fontFamiliesService.fontFamilies$.pipe(take(1)));

        const patchedElements = initializeElements(
            this.editorStateService.document,
            this.editorStateService.elements,
            {
                versionProperties: this.editorStateService.versionProperties,
                defaultVersionProperties: this.editorStateService.defaultVersionProperties,
                fontFamilies: fontFamilies
            }
        );

        if (patchedElements.length) {
            this.eventLoggerService.log(new ElementPatchedEvent(patchedElements));
        }

        for (const element of patchedElements) {
            this.editorStateService.addElement(element);
        }

        this.initObservers();

        await this.initCreative();
        this.initMutator();

        this.setupHotkeyListeners();

        window.designClone = this.editorStateService.designFork;
        window.renderer = this.renderer;

        // TODO: move to the viewinit done event once editor event service was merged
        if (this.fontFamilies.length === 0) {
            // no brand font set
            this.uiNotificationService.open(
                "Your brand doesn't have any fonts yet. We recommend that you upload at least one font before you continue.",
                { type: 'warning', placement: 'top' }
            );
        }

        this.editorEventService.designViewInit();

        this.validateFonts();
    }

    private initObservers(): void {
        this.logger.verbose('initObservers');

        this.editorEventService.workspaceViewInit$.pipe(take(1)).subscribe(() => {
            this.onWorkspaceInit();
            this.validationService.init();
        });

        // start listen to zoom after workspace was inited
        this.zoomControlService.changeZoomObservable
            .pipe(
                skipUntil(this.editorEventService.workspaceInit$),
                debounceTime(500),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe(() => {
                this.workspace.transform.setTransformMode(TransformMode.None);
                this.renderer.rerenderText_m();
                this.rerenderCanvas();
            });

        this.historyService.redo$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            this.redo();
        });

        this.historyService.undo$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            this.undo();
        });

        const observeFilter = (isEditingElement: boolean): boolean =>
            !isEditingElement && !!this.workspace;

        this.elementSelectionService.change$
            .pipe(
                concatLatestFrom(() => this.mediaLibraryService.isEditingElement$),
                filter(([_, isEditingElement]) => !isEditingElement && !!this.workspace),
                distinctUntilChanged(),
                map(([selection, _isEditingElement]) =>
                    selection.length === 1 ? selection.elements[0] : undefined
                ),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe(element => {
                if (!element) {
                    // Element may no longer exist when undoing
                    const previousElement = this.propertiesService.selectedElement;
                    this.propertiesService.dataElementChange$.next(undefined);
                    this.propertiesService.selectedStateChange$.next(undefined);
                    this.rerenderNode(previousElement);
                } else if (element === this.propertiesService.selectedElement) {
                    return;
                } else {
                    this.propertiesService.selectedStateChange$.next(undefined);
                    this.propertiesService.dataElementChange$.next(element);
                }
            });

        this.historyService.snapshotApply$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            this.editorStateService.setRenderer(this.renderer);
            this.rerenderNode();
        });

        merge(
            this.elementSelectionBoundingBoxService.boundingBox$,
            this.editorEventService.elements.immediateChange$.pipe(
                concatLatestFrom(() => this.mediaLibraryService.isEditingElement$),
                filter(([_, isEditingElement]) => observeFilter(isEditingElement)),
                map(([change, _isEditingElement]) => change)
            ),
            this.historyService.snapshotApply$
        )
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(elementChange => {
                let element: undefined | OneOfDataNodes;

                if (isElementChange(elementChange)) {
                    element = elementChange.element;
                }

                this.rerenderNode(element);

                const setSnaplines = [TransformMode.Move, TransformMode.Resize].includes(
                    this.workspace.transform.mode
                );

                if (setSnaplines) {
                    this.workspace.transform.setSnaplines();
                }

                this.workspace.gizmoDrawer.draw();
            });

        this.editorEventService.creative.change$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                this.checkElementVisibility();
            });

        this.propertiesService
            .observeDataElementOrStateChange(false)
            .pipe(
                skipUntil(this.editorEventService.workspaceInit$),
                concatLatestFrom(() => this.mediaLibraryService.isEditingElement$),
                filter(([_, isEditingElement]) => observeFilter(isEditingElement)),
                map(([state, _isEditingElement]) => state),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe(({ state }) => {
                this.rerenderNode(undefined, state);
            });

        merge(
            this.workspaceInit$,
            this.editorEventService.text.change$.pipe(debounceTime(500)),
            merge(
                this.editorEventService.creative.change$.pipe(
                    tap(() => {
                        this.workspace.gizmoDrawer.draw();
                        this.renderer.creativeDocument.clearEmptyChildren();
                    })
                ),
                this.editorEventService.elements.changes$.pipe(
                    concatLatestFrom(() => this.mediaLibraryService.isEditingElement$),
                    filter(([_, isEditingElement]) => observeFilter(isEditingElement)),
                    map(([change, _isEditingElement]) => change)
                )
            ).pipe(filterChangeEvent)
        )
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                this.historyService.addSnapshot();
            });

        this.editorEventService.elements.change$
            .pipe(
                concatLatestFrom(() => this.mediaLibraryService.isEditingElement$),
                filter(([_, isEditingElement]) => observeFilter(isEditingElement)),
                map(([change, _isEditingElement]) => change),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe(change => {
                if (change.changes.actions) {
                    this.renderer.creativeDocument.elements.forEach(element => {
                        if (isHidden(element)) {
                            return;
                        }
                        this.renderer.setActions_m(element);
                    });
                }
            });

        this.versionsService.versions$
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                skip(1) // skip first so it doesn't try to call renderer too early
            )
            .subscribe(versions => {
                this.onVersionDataChange(versions);
            });
    }

    private onWorkspaceInit(): void {
        this.historyService.storeCurrentStateAsBackendState();

        if (!this.editorStateService.designFork.id) {
            this.editorStateService.designFork.document.width = this.editorStateService.size.width;
            this.editorStateService.designFork.document.height = this.editorStateService.size.height;
        }

        this.renderOnCanvas();

        this.animator?.seek(
            this.editorStateService.document.elements.length
                ? this.editorStateService.document.getFirstPreloadImageFrame()
                : 0
        );

        window.creativesetDataService = this.creativesetDataService;

        this.activityLoggerService.log(
            `Navigated to '${this.selectedVersion?.name}:${
                this.editorStateService.size.name ||
                `${this.editorStateService.size.width}x${this.editorStateService.size.height}`
            }'`
        );

        const storageCopiedSnapshot = window.localStorage.getItem('copiedSnapshot');
        if (storageCopiedSnapshot) {
            const parsedCopy = JSON.parse(storageCopiedSnapshot);
            if (
                this.copyPasteService.copyPasteChecker(parsedCopy, this.creativesetDataService.brand.id)
            ) {
                this.updateSnapshotFromStorage(parsedCopy);
            }
        }
        this.loading = false;
        this.editorEventService.workspaceInit();
    }

    async initCreative(): Promise<void> {
        this.logger.verbose('initCreative');

        if (this.renderer) {
            this.renderer.destroy();
        }

        this.container.register_m(
            T.TEXT_SELECTION_CHANGE_SUBJECT,
            this.editorEventService.text.textSelectionChange$
        );
        this.container.register_m(T.TEXT_CHANGE_SUBJECT, this.editorEventService.text.change$);

        const newAd = this.createAd();

        const createCreative = this.container.resolve<ICreativeFactory>(T.CREATIVE_FACTORY);
        const creative = (this.creative = createCreative(
            this.editorStateService.document,
            {
                elements: this.editorStateService.elements,
                versions: this.versions,
                currentVersionId: this.selectedVersion.id,
                zoom: this.editorStateService.zoom,
                brandId: this.creativesetDataService.brand.id,
                inDesignView: true
            },
            newAd
        ));

        this.renderer = creative.renderer_m;
        this.animator = creative.animator_m;
        this.animator.on('play', () => {
            this.notifyIsPlaying$.next(true);
            this.setPreviewOverlay(true);
        });

        this.animator.on('pause', () => {
            this.notifyIsPlaying$.next(false);
            this.setPreviewOverlay(false);
        });

        this.animator.on('stop', () => {
            this.notifyIsPlaying$.next(false);
            this.setPreviewOverlay(false);
        });

        this.editorStateService.setRenderer(this.renderer);

        const feeds: string[] = [];
        for (const element of this.renderer.creativeDocument.elements) {
            if (isTextNode(element)) {
                for (const span of element.content.spans) {
                    if (span.type === SpanType.Variable) {
                        feeds.push(span.style.variable!.id);
                    }
                }
            } else if (element.feed && isMediaElementNode(element)) {
                feeds.push(element.feed.id);
            }
        }
        if (feeds) {
            await creative.feedStore_m.preloadFeeds(feeds);
        }
    }

    private createAd(): Ad {
        const { creativeset, brand } = this.creativesetDataService;
        const creativeAdData: IInputCreative = {
            version: this.selectedVersion,
            design: this.editorStateService.designFork,
            size: {
                id: '0',
                width: this.editorStateService.document.width,
                height: this.editorStateService.document.height
            }
        };

        const version = createAdDataCreativeVersion(this.selectedVersion, this.localizations);
        const adCreative = getStudioAdDataCreative(
            creativeset,
            creativeAdData,
            creativeset.brandId,
            version
        );

        const adScript = document.createElement('script');

        return new Ad(
            {
                id: 'studioAd',
                adTagId: '',
                origin: '',
                account: { slug: 'accountSlug' },
                brand: { id: brand.id },
                creatives: [adCreative]
            },
            adScript
        );
    }

    private initMutator(): void {
        this.logger.verbose('initMutator');
        this.editorStateService.mutatorService = this.mutatorService;
    }

    rerenderNode(node?: OneOfDataNodes, stateOverride?: IState): void {
        if (isGroupDataNode(node)) {
            forEachDataElement(node, element => {
                this.rerenderElementWithCurrentState(element, stateOverride);
            });
            this.timeline.updateElements();
            return;
        }

        this.rerenderElementWithCurrentState(node, stateOverride);
    }

    private rerenderElementWithCurrentState(
        dataElement?: OneOfElementDataNodes,
        stateOverride?: IState
    ): void {
        dataElement = dataElement || this.propertiesService.selectedElement;
        const renderer = this.renderer;

        renderer?.clearAdditionalStates_m();

        if (!dataElement || !renderer || renderer.destroyed_m) {
            return;
        }

        // Element may no longer exist when undoing
        const element = renderer.creativeDocument.elements.find(el => el.id === dataElement?.id);
        if (!element) {
            this.elementSelectionService.clearSelection();
            return;
        }

        if (this.propertiesService.inStateView) {
            const stateData = stateOverride || this.propertiesService.stateData;

            // Ignore temp states and states shown by selecting a keyframe
            if (stateData?.id && !isAnimationState(dataElement, stateData)) {
                renderer.addAdditionalState_m(dataElement, stateData, 1);
            }
        }

        renderer.setViewElementValues_m(dataElement, renderer.time_m);
    }

    private setupHotkeyListeners(): void {
        this.logger.verbose('Setting up hotkeys');
        const hotkeyContext: IHotkeyContext = {
            name: 'Editor',
            input: window,
            keyDefaultBehaviourExclusions: Object.values(BrowserDefaultHotkeys)
        };
        this.hotkeyBetterService.pushContext(hotkeyContext);

        this.hotkeyBetterService.on('SaveAndExit', this.saveAndExit);
        this.hotkeyBetterService.on('Save', this.saveCreative);
        this.hotkeyBetterService.on('Deselect', this.deselectShortcut);
        this.hotkeyBetterService.on('SelectAll', this.selectAllElements);

        this.hotkeyBetterService.on('Undo', this.undo);
        this.hotkeyBetterService.on('Redo', this.redo);
        this.hotkeyBetterService.on('Copy', this.copySelection);
        this.hotkeyBetterService.on('Cut', this.cutSelection);
        this.hotkeyBetterService.on('Paste', this.onPaste);
        this.hotkeyBetterService.on('PasteStyle', this.onPasteStyle);
        this.hotkeyBetterService.on('PasteLayout', this.onPasteLayout);
        this.hotkeyBetterService.on('PasteAnimation', this.onPasteAnimations);
        this.hotkeyBetterService.on('NavigateToTP', this.navigateToTPShortcut);
    }

    private removeHotkeyListeners(): void {
        this.logger.verbose('Removing hotkeys');
        this.hotkeyBetterService.off('SaveAndExit', this.saveAndExit);
        this.hotkeyBetterService.off('Save', this.saveCreative);
        this.hotkeyBetterService.off('Deselect', this.deselectShortcut);
        this.hotkeyBetterService.off('SelectAll', this.selectAllElements);

        this.hotkeyBetterService.off('Undo', this.undo);
        this.hotkeyBetterService.off('Redo', this.redo);
        this.hotkeyBetterService.off('Copy', this.copySelection);
        this.hotkeyBetterService.off('Cut', this.cutSelection);
        this.hotkeyBetterService.off('Paste', this.onPaste);
        this.hotkeyBetterService.off('PasteStyle', this.onPasteStyle);
        this.hotkeyBetterService.off('PasteLayout', this.onPasteLayout);
        this.hotkeyBetterService.off('PasteAnimation', this.onPasteAnimations);
        this.hotkeyBetterService.off('NavigateToTP', this.navigateToTPShortcut);

        this.hotkeyBetterService.popContext();
    }

    removeRichTextBlurSuspensionElement(htmlElement: HTMLElement): void {
        this.richTextBlurSuspensionElements.delete(htmlElement);
        this.mutatorService.richTextBlurSuspensionElements = this.richTextBlurSuspensionElements;
    }

    private renderOnCanvas(): void {
        this.workspace.renderCanvas();
    }

    private onVersionDataChange(versions: IVersion[]): void {
        this.renderer.versions_m = versions;
        this.applyImageReference();
    }

    private checkPristineState = async (): Promise<boolean> => {
        if (this.skipPristineStateCheck) {
            return true;
        }

        if (this.historyService.isDirty()) {
            const result = await this.uiConfirmDialogService.confirm({
                headerText: 'Save before exiting',
                confirmText: 'Save',
                discardText: "Don't save",
                text: 'Do you want to save changes before exiting the design?',
                showCancelButton: true,
                onConfirm: async () => {
                    try {
                        // the pristine check triggers during a navigation. Hence, we don't need to set saveAndExit
                        // fixes navigation to different routes than MV (e.g.TP) - COBE-2117
                        await this.onSaveCreative(false, false);
                        return { error: undefined };
                    } catch (e) {
                        return { error: e };
                    }
                },
                onConfirmed: this.onSaveCreativeFinished
            });

            if (result === 'discard' || result === 'confirm') {
                this.versionsService.resetVersions(this.creativeset.versions);
                return true;
            }

            return false;
        }

        return true;
    };

    private applyImageReference(): void {
        const { document } = this.editorStateService;
        const imageElements = this.creativeset.elements.filter(
            el => this.filterByDocument(el, document) && isImageElement(el)
        );

        for (const imageElement of imageElements) {
            const dirtyImageElement = this.editorStateService.getElementById(imageElement.id);
            const imageRef = imageElement.properties.find(hasImageReference);

            if (dirtyImageElement && imageRef?.id !== undefined) {
                const dirtyImageRef = dirtyImageElement.properties.find(hasImageReference);
                if (dirtyImageRef && dirtyImageRef.id === undefined) {
                    dirtyImageRef.id = imageRef.id;
                }
            }
        }
    }

    toolbarIsOpen(toolbar: { isOpen: boolean; width: number }): void {
        this.workspace.toolbar = toolbar;
    }

    ngOnDestroy(): void {
        this.destroyed = true;
        this.userSettingsService.setSharedSetting('lastLocation', 'DesignView');

        // dont destroy things that have not been initialized
        if (!this.initErrors) {
            this.renderer.destroy();
            this.animator?.destroy();

            this.editorStateService.reset();
            this.container.unregister_m(T.EDITOR_STATE);
            this.container.unregister_m(T.EDITOR_EVENT);
            this.container.unregister_m(T.EDITOR_HISTORY_SERVICE);
            this.container.unregister_m(T.TEXT_SELECTION_CHANGE_SUBJECT);
            this.container.unregister_m(T.TEXT_CHANGE_SUBJECT);

            this.removeHotkeyListeners();

            this.creativeset = undefined!;
            this.navigationGuard.removePristineUnloadCheck(this.historyService.isPristine);
            this.navigationGuard.removePristineCheck(this.checkPristineState);

            delete this.animator;
            // @ts-expect-error: Old code but should probably not be needed
            delete this.renderer;

            this.editorEventService.elements.clear();

            SimpleCache.clear();
        }

        window.editorPage = undefined;
        window.renderer = undefined;
    }

    private saveCreative = (saveAll = false): void => {
        this.editorSaveStateService.save({ saveAll, saveAndExit: false });
    };

    private saveAndExit = (): void => {
        this.editorSaveStateService.save({
            saveAll: false,
            saveAndExit: true
        });
    };

    private async getDirtyDesignsAndVersions(): Promise<{
        defaultVersion: IVersion;
        dirtyVersions: IVersion[];
        dirtyDesigns: IDesign[];
    }> {
        const dirtyDesigns: IDesign[] = [];
        for (const design of this.editorStateService.designs) {
            if (this.editorStateService.designFork.id === design.id) {
                continue;
            }
            if (
                !deepEqual(
                    cloneDeep(design),
                    this.historyService.designsBackendState.find(d => d.id === design.id)
                )
            ) {
                dirtyDesigns.push(design);
            }
        }

        const defaultVersionCopy = cloneDeep(this.defaultVersion);

        this.versionsService.cleanStyleIds([
            ...this.editorStateService.designs.map(design => design.document.id),
            this.editorStateService.document.id
        ]);

        this.versions = await firstValueFrom(this.versionsService.versions$);

        for (const version of this.versions) {
            for (const versionProperty of version.properties) {
                const styles: ITextSpan[] = [];

                // If original version does not have a version property copy over it.
                let defaultVersionProperty = defaultVersionCopy.properties.find(
                    p => p.id === versionProperty.id
                );
                if (!defaultVersionProperty) {
                    defaultVersionProperty = {
                        ...versionProperty,
                        value: { ...versionProperty.value, styles }
                    };
                    defaultVersionCopy.properties.push(defaultVersionProperty);
                }
            }
        }

        dirtyDesigns.push(this.editorStateService.designFork);

        const versionProperties = [
            ...defaultVersionCopy.properties,
            ...this.editorStateService.currentVersion.properties
        ];

        dirtyDesigns.forEach(design =>
            this.validationService.validateRelationalData(design, versionProperties)
        );

        const designDocument = this.editorStateService.document;

        const invalidStopTime =
            designDocument.loops && designDocument.stopTime! > designDocument.duration;

        if (invalidStopTime) {
            this.mutatorService.setCreativeStopTime(undefined);
        }

        const hasPreloadFrames = designDocument.preloadImage && designDocument.preloadImage.frames;
        const invalidPreloadFrame =
            hasPreloadFrames && designDocument.getFirstPreloadImageFrame() > designDocument.duration;

        if (invalidPreloadFrame) {
            this.mutatorService.setPreloadImageFrames([], false);
        }

        await this.syncVersionsFromStore(); // Get updated versions
        return { defaultVersion: defaultVersionCopy, dirtyVersions: this.versions, dirtyDesigns };
    }

    private async validateCreative(): Promise<void> {
        const { defaultVersion, dirtyDesigns, dirtyVersions } = await this.getDirtyDesignsAndVersions();
        const designsToSave = dirtyDesigns.map(design => ({
            id: design.id,
            name: design.name,
            hasHeavyVideo: design.hasHeavyVideo,
            document: design.document,
            elements: this.editorStateService.elements.filter(element =>
                this.filterByDocument(element, design.document)
            )
        }));

        try {
            this.validationService.validateDesigns(designsToSave, dirtyVersions, defaultVersion);
        } catch (e) {
            if (isValidationError(e as Error)) {
                this.validationService.setStatusInvalid();
            } else {
                throw e;
            }
        }
    }

    private async onSaveCreative(saveAll: boolean, saveAndExit: boolean): Promise<void> {
        try {
            this.eventLoggerService.log(new SaveCreativeEvent(), this.logger);

            // We have to resolve all character styles, since the changes are just stored locally.
            this.mutatorService.resolveAllCharacterStyles();
            this.updateElementsVersionedPropertiesForSelectedVersion();
            await this.syncVersionsFromStore();

            const { dirtyDesigns, defaultVersion, dirtyVersions } =
                await this.getDirtyDesignsAndVersions();

            await this.brandLibraryDataService.brandLibraryLoaded;

            /**
             * Make sure that we don't attempt any saves if renderer or design-view already has been
             * destroyed in between asynchronous executions. Can otherwise cause potential
             * race-conditions resulting in elements being be deleted.
             */
            if (this.isDestroyed()) {
                this.eventLoggerService.log(new SaveCreativeLogEvent(), this.logger);
                return;
            }

            if (!this.editorStateService.designFork.id) {
                await this.saveNewDesign({
                    dirtyVersions,
                    saveAndExit
                });

                /**
                 * Re-do the saving flow when e.g replacing images
                 * in all designs in a non-active design
                 */
                if (saveAll) {
                    await this.onSaveCreative(saveAll, saveAndExit);
                    return;
                }
            } else {
                await this.saveExistingDesigns({
                    dirtyDesigns,
                    dirtyVersions,
                    defaultVersion,
                    saveAll
                });
            }

            this.historyService.storeCurrentStateAsBackendState();
            this.versionsService.designUpdated();
            this.creativesetDataService.creativesetChanged();
            this.eventLoggerService.log(new SaveCreativeEndEvent(), this.logger);
            this.editorSaveStateService.saveSuccess();

            if (saveAndExit) {
                this.exit();
            }
        } catch (error) {
            this.editorSaveStateService.setStatus(EditorSaveStatus.Idle);
            this.saveErrorHandlerService.handleSaveError(error);
            throw error;
        }
    }

    private async saveNewDesign({ dirtyVersions, saveAndExit }: SaveNewDesignOptions): Promise<void> {
        const { document, designFork, size, elements } = this.editorStateService;
        const filteredElements = elements.filter(element => this.filterByDocument(element, document));

        const designToSave: Omit<IDesign, 'id'> = {
            name: `design-${uuidv4()}`,
            elements: filteredElements,
            document,
            hasHeavyVideo: designFork.hasHeavyVideo
        };

        const response = await firstValueFrom(
            this.creativesetDataService.createDesignsWithExistingSize([
                {
                    size,
                    versions: dirtyVersions,
                    design: designToSave
                }
            ])
        );

        if (isKnownErrorResponse(response)) {
            throw new Error(response.message);
        }

        this.editorStateService.sizeIsActive = true;
        this.editorSaveStateService.setDisabled(true);

        await this.syncVersionsFromStore();

        // Make sure that we are using the saved design
        const newDesign = response?.creatives.find(
            creative => creative.size.id === this.editorStateService.size.id
        )?.design;

        if (!newDesign) {
            throw new Error('Expected to process saved design but could not find it');
        }

        await this.editorStateService.setDesignFork(newDesign);
        this.rerenderCanvas();

        if (!saveAndExit) {
            this.historyService.checkDirtiness();
            this.location.go(
                `/brand/${this.creativesetDataService.brand.id}/creativeset/${this.creativeset.id}/editor/${this.editorStateService.creative.id}/${this.editorStateService.size.id}/${newDesign.id}?version=${this.selectedVersion.id}`
            );
        }
    }

    private async saveExistingDesigns({
        dirtyDesigns,
        dirtyVersions,
        defaultVersion,
        saveAll
    }: Omit<SaveDesignOptions, 'saveAndExit'>): Promise<void> {
        const allElements = [
            ...this.editorStateService.elements,
            ...Array.from(dirtyDesigns).flatMap(design => design.elements)
        ].reduce((acc, curr) => {
            const elementExist = acc.some(element => element.id === curr.id);
            if (!elementExist) {
                acc.push(curr);
            }
            return acc;
        }, [] as IElement[]);
        const dirtyDesignIds: string[] = [];
        const designsToSave: IDesign[] = dirtyDesigns.map(design => {
            dirtyDesignIds.push(design.id);
            const { id, name, hasHeavyVideo, document } = design;

            return {
                id,
                name,
                hasHeavyVideo,
                document,
                elements: allElements.filter(element => this.filterByDocument(element, document))
            };
        });

        if (saveAll) {
            const otherDesigns = this.creativeset.designs.filter(d => !dirtyDesignIds.includes(d.id));
            designsToSave.push(...otherDesigns);
        }

        // cloneDeep since otherwise reference is broken to editorStateService.versionProperties
        const response = await firstValueFrom(
            this.creativesetDataService.updateDesignsInCreativeset(
                this.versions,
                designsToSave,
                cloneDeep(dirtyVersions),
                cloneDeep(defaultVersion)
            )
        );

        if (!response) {
            return;
        }

        if (isKnownErrorResponse(response)) {
            await this.handleErrorResponse(response);
            return;
        }

        const designs = response.designs;

        if (!designs) {
            this.logger.warn(`No designs were saved.`);
            return;
        }

        const updatedCreative = this.creativesetDataService.creativeset.creatives.find(
            creative =>
                designs.find(design => design.id === creative.design?.id) &&
                this.editorStateService.creative.id === creative.id
        );

        if (!updatedCreative?.design) {
            throw new Error(`Creative and/or it's design could not be found after saving.`);
        }

        this.editorStateService.updateElements(updatedCreative.design.elements);
    }

    private async handleErrorResponse(error: KnownErrorResponse): Promise<void> {
        const status = error.status;

        if (status === ErrorStatus.BadRequest) {
            this.validationService.setStatusInvalid();
            throw new Error('Bad request. Could not save creative.');
        }

        if (status === ErrorStatus.Conflict) {
            await this.uiConfirmDialogService.confirm({
                headerText: 'Unable to save creative set',
                confirmText: 'Reload',
                closeButton: false,
                // Cancel text need to coerce to false or else default value is used
                cancelText: '',
                showCancelButton: false,
                escKeyClose: false,
                backdropClickClose: false,
                text: `
                Changes were made to this creative set in another tab or by another user. Reload this page to get the latest version of your creative set.`,
                onConfirm: () => {
                    location.reload();
                    return new Promise(() => undefined);
                }
            });
        }

        if (status === ErrorStatus.NotFound) {
            this.skipPristineStateCheck = true;
            await this.errorsRedirectionService.handleErrorRedirection(error);
        }
    }

    private onSaveCreativeFinished = (
        closeDialog: UIConfirmCloseDialogCallback,
        error?: unknown
    ): void => {
        if (error) {
            return;
        }

        this.renderOnCanvas();
        this.uiNotificationService.open('Creative saved.', {
            type: 'info',
            placement: 'top',
            autoCloseDelay: 5000
        });

        setTimeout(() => {
            closeDialog('confirm');
            this.historyService.storeCurrentStateAsBackendState();
        });
    };

    private setPreviewOverlay(enabled: boolean): void {
        this.workspace.gizmoDrawer.hideOverflow = enabled;
        this.workspace.gizmoDrawer.draw();
    }

    private deselectShortcut = (): void => {
        const overlayContainer = document.querySelector('.cdk-overlay-container');

        if (this.animator?.isPlaying) {
            this.animator.pause();
            return;
        }

        if (this.workspace.createElementKind !== undefined) {
            this.workspace.stopCreateNewElement();
            return;
        }

        if (
            this.animator?.isPlaying ||
            this.workspace.contextMenuOpen ||
            this.workspace.transform.mode !== TransformMode.None ||
            overlayContainer?.childNodes.length
        ) {
            return;
        }

        if (this.elementSelectionService.currentSelection.length) {
            this.workspace.deselectAllElements();
            return;
        }

        this.exit();
    };

    selectAllElements = (): void => {
        if (!this.timeline.isRecordingKeyframes) {
            const nodes: OneOfDataNodes[] = [];
            for (const node of this.renderer.creativeDocument.nodes) {
                if (!node.locked) {
                    nodes.push(node);
                    this.mutatorService.workspaceFocused = true;
                }
            }
            this.elementSelectionService.setSelection(...nodes);
        }
    };

    private selectElements = (ids: string[]): void => {
        const elementsToSelect = toFlatNodeList(this.renderer.creativeDocument).filter(node =>
            ids.includes(node.id)
        );

        if (ids.length) {
            this.elementSelectionService.setSelection(...elementsToSelect);
        } else {
            this.renderer.rerender_m(this.editorStateService.document);
        }
    };

    private removeSelectedKeyframes(): void {
        this.keyframeService.deleteKeyframes$.next();
    }

    editElement(element: IBrandLibraryElement | INewBrandLibraryElement): void {
        this.timeline.animationRecorderService.stopRecording();
        this.workspace.stopCreateNewElement();
        this.editingElement = element;
        this.uiTooltipService.closeAll();
    }

    onCloseEditElement({
        updateLibrary,
        elementType
    }: {
        updateLibrary: boolean;
        elementType: ElementKind;
    }): void {
        if (updateLibrary) {
            const kind = getLibraryKindFromElementKind(elementType);
            this.mediaLibraryService.openMediaLibrary(kind);
        }
        setTimeout(() => {
            this.timeline.gizmoDrawer.canvasSizeSet$.next();
        }, 100);
        this.workspace.centerCanvas();
        this.editingElement = undefined;
    }

    onMouseEnterTimeline(): void {
        this.mouseOverTimeline = true;
    }

    onMouseLeaveTimeline(): void {
        this.mouseOverTimeline = false;
    }

    exit(): void {
        if (this.isSaving) {
            return;
        }

        this.activityLoggerService.log('Exited design view');
        // If version hasn't been saved yet and it doesn't have any edits applied

        if (!this.editorStateService.sizeIsActive && this.historyService.isClean()) {
            this.navigationGuard.removePristineCheck(this.checkPristineState);
        }

        this.mediaLibraryService.closeMediaLibrary(false);

        const exitUrl = this.studioRoutingService.getDVExitUrl();
        this.router.navigate([exitUrl], {
            queryParamsHandling: 'merge'
        });
    }

    undo = async (event?: Event): Promise<void> => {
        this.eventLoggerService.log(new HistoryUndoEvent(), this.logger);
        const undoSnapshot = this.historyService.popUndo();
        if (undoSnapshot) {
            await this.useSnapshot(undoSnapshot);
        }

        // We must prevent default here, because CTRL+Z triggers an undo on the last input element that
        // can override application behaviour. For instance, in the color picker. If you type in one color
        // and then select another with the mouse. CTRL+Z will trigger an application undo that will revert
        // to the typed color. Whereas, the native undo will try to insert initial color, because that is
        // the last value that was recognized. The merged value will be a mixed half of both.
        if (event) {
            event.preventDefault();
        }
    };

    redo = async (): Promise<void> => {
        this.eventLoggerService.log(new HistoryRedoEvent(), this.logger);

        const redoSnapshot = this.historyService.popRedo();
        if (redoSnapshot) {
            await this.useSnapshot(redoSnapshot);
        }
    };

    async cloneSelection(): Promise<void> {
        const copiedSelection = this.historyService.createSnapshot();
        await this.pasteSelection(copiedSelection, undefined, true, ElementChangeType.Skip);
    }

    onPaste = async (position?: IPosition): Promise<void> => {
        if (!this.copiedSnapshot) {
            return;
        }
        const updateTime =
            this.editorStateService.document.elements.length === 0 && this.animator?.time === 0;
        await this.pasteSelection(cloneDeep(this.copiedSnapshot), position);
        if (updateTime) {
            const time = this.getTimeFromElements(this.copiedSnapshot.selection?.elements || []);
            this.animator?.setTime_m(time);
            this.animator?.render_m(time, true);
            this.timeline.setPlayheadPosition();
        }
    };

    onPasteStyle = (): void => {
        if (this.copiedSnapshot?.selection && this.copiedSnapshot.selection.length === 1) {
            this.pasteStyle(this.copiedSnapshot);
        }
    };

    onPasteLayout = (): void => {
        if (this.copiedSnapshot) {
            this.pasteLayout(this.copiedSnapshot);
        }
    };

    onPasteAnimations = (): void => {
        if (this.copiedSnapshot) {
            this.pasteAnimations(this.copiedSnapshot);
        }
    };

    navigateToTPShortcut = (): void => {
        this.studioRoutingService.navigateToTP();
    };

    private pasteLayout(snapshot: IEditorSnapshot): void {
        const { selection } = snapshot;

        if (!selection) {
            throw new Error('No selection found when pasting layout');
        }

        const boundingBox = calculateBoundingBox([...selection.elements]);

        for (const targetElement of this.elementSelectionService.currentSelection.elements) {
            let size: ISize;
            let position: IPosition;

            if (targetElement.ratio) {
                //
                // Position element in the middle of the selection if the element has ratio-lock
                //
                const ratio = Math.min(
                    boundingBox.width / targetElement.width,
                    boundingBox.height / targetElement.height
                );

                size = {
                    width: targetElement.width * ratio,
                    height: targetElement.height * ratio
                };

                const centerX = boundingBox.x + boundingBox.width / 2 - size.width / 2;
                const centerY = boundingBox.y + boundingBox.height / 2 - size.height / 2;

                position = {
                    x: centerX,
                    y: centerY
                };
            } else {
                size = { width: boundingBox.width, height: boundingBox.height };
                position = { x: boundingBox.x, y: boundingBox.y };
            }

            this.mutatorService.setSize(targetElement, size, false);

            if (selection.length === 1 && selection.element && selection.element.rotationZ) {
                this.mutatorService.setRotationZ(targetElement, selection.element.rotationZ);
            }

            this.mutatorService.setPosition(targetElement, position);
        }
    }

    private cleanUpAnimationsStates(states: IState[], animations: IAnimation[]): IState[] {
        const animationTypes = [...Object.keys(AnimationTypes).filter(key => isNaN(Number(key)))];
        // Remove states related to the in/out animations
        const filteredStates = states.filter(
            state =>
                !animations
                    .filter(animation => animationTypes.includes(animation.type || ''))
                    .some(animation =>
                        animation.keyframes.some(keyframe => keyframe.stateId === state.id)
                    )
        );
        return filteredStates;
    }
    private filterOutNonAnimationStatesFromSource(
        states: IState[],
        animations: IAnimation[]
    ): IState[] {
        const animationTypes = [...Object.keys(AnimationTypes).filter(key => isNaN(Number(key)))];
        const filteredStates = states.filter(state =>
            animations
                .filter(animation => animationTypes.includes(animation.type || ''))
                .some(animation => animation.keyframes.some(keyframe => keyframe.stateId === state.id))
        );
        return filteredStates;
    }

    private pasteAnimations(snapshot: IEditorSnapshot): void {
        const mutatorService = this.mutatorService;

        const { selection } = snapshot;
        const copiedElements = snapshot.document.elements;

        if (!selection) {
            throw new Error('No selection found when pasting animations');
        }

        for (const targetElement of this.elementSelectionService.currentSelection.elements) {
            const copiedElement = selection.asSortedArray()[0];

            const dataElement = targetElement;
            const copiedDataElement = copiedElements.find(el => el.id === copiedElement.id);

            if (copiedDataElement) {
                const copiedDataElementAnimationStates = this.filterOutNonAnimationStatesFromSource(
                    copiedDataElement.states,
                    copiedDataElement.animations
                );

                // Remove any old animations from targetElement before pasting the copied animations
                dataElement.states = this.cleanUpAnimationsStates(
                    dataElement.states,
                    dataElement.animations
                );
                Object.keys(AnimationTypes)
                    .filter(key => isNaN(Number(key)))
                    .forEach(key => {
                        mutatorService.removeAnimationTypeOnElement(
                            key as AnimationType,
                            dataElement,
                            true
                        );
                    });

                // The element should retain its start time but get the new duration
                mutatorService.setElementAnimationTimeAndDuration(
                    dataElement.time,
                    copiedDataElement.duration,
                    dataElement,
                    true
                );

                copiedDataElement.animations.forEach(animation => {
                    const copiedAnimation = { ...animation };
                    copiedAnimation.id = uuidv4();
                    const copiedStates: IState[] = [];
                    const copiedKeyFrames = [
                        ...copiedAnimation.keyframes.map(keyFrame => {
                            const copiedKeyFrame = { ...keyFrame };
                            copiedKeyFrame.id = uuidv4();
                            const stateSource = copiedDataElementAnimationStates.find(
                                state => state.id === keyFrame.stateId
                            );
                            if (stateSource) {
                                const copiedState = { ...stateSource };
                                copiedState.id = uuidv4();
                                copiedKeyFrame.stateId = copiedState.id;
                                copiedStates.push(copiedState);
                            }
                            return copiedKeyFrame;
                        })
                    ];
                    copiedAnimation.keyframes = copiedKeyFrames;
                    mutatorService.applyAnimationOnElement(dataElement, copiedAnimation, copiedStates);
                });
                this.editorEventService.elements.change(dataElement, {
                    animations: dataElement.animations,
                    states: dataElement.states
                });
            }
        }
    }

    private copyDefaultStyles(
        targetElement: OneOfElementDataNodes,
        copiedElement: OneOfElementDataNodes
    ): void {
        /* Lookup for max opacity of the element.
           If element is not in view at the moment of copy/paste styles it's opacity will be 0.
           This hides target element on canvas. fixes: STUDIO-2016 */
        const opacity = copiedElement.opacity;

        const props: Array<{ key: OneOfElementPropertyKeys; value: OneOfDataNodeValues }> = [
            { key: 'border', value: copiedElement.border },
            { key: 'shadows', value: copiedElement.shadows },
            { key: 'opacity', value: Math.max(copiedElement.opacity, opacity) },
            { key: 'radius', value: copiedElement.radius },
            { key: 'fill', value: copiedElement.fill },
            { key: 'filters', value: copiedElement.filters }
        ];

        const targetDataElement = targetElement;

        if (
            copiedElement.kind === ElementKind.Image &&
            targetDataElement.kind === ElementKind.Image &&
            copiedElement['imageSettings']
        ) {
            this.mutatorService.setImageSettings(targetDataElement, copiedElement['imageSettings']);
        }

        for (const { key, value } of props) {
            if (key === 'fill' && value === undefined) {
                return;
            }
            if (key === 'radius' && value) {
                let radius = value as IRadius;

                const maxRadius = Math.ceil(Math.min(copiedElement.width, copiedElement.height) / 2);
                const targetMaxRadius = Math.ceil(
                    Math.min(targetElement.width, targetElement.height) / 2
                );
                const getTargetMaxRadiusValue = (radiusValue: number): number =>
                    radiusValue >= maxRadius ? targetMaxRadius : radiusValue;

                radius = {
                    type: radius.type,
                    topLeft: getTargetMaxRadiusValue(radius.topLeft),
                    topRight: getTargetMaxRadiusValue(radius.topRight),
                    bottomRight: getTargetMaxRadiusValue(radius.bottomRight),
                    bottomLeft: getTargetMaxRadiusValue(radius.bottomLeft)
                };
                this.mutatorService.setElementPropertyValue(targetElement, key, radius);
            }
            this.mutatorService.setElementPropertyValue(targetElement, key, value);
        }
    }

    private async copyAllTextStyles(
        targetElement: OneOfTextDataNodes,
        copiedElement: OneOfTextDataNodes
    ): Promise<void> {
        let textProperties;
        const copiedViewElement = this.renderer.getViewElementById<OneOfTextViewElements>(
            copiedElement.id
        );

        if (!copiedViewElement?.__richTextRenderer) {
            const data = { ...copiedElement };

            if (copiedElement.__dirtyContent) {
                textProperties = copiedElement.__dirtyContent.style;
                const span = copiedElement.__dirtyContent.spans[0] as IWordSpan;
                Object.keys(span.style).forEach(async key => {
                    if (key === 'fontSize' && span.style[key]! < 1 && span.style[key]! > 0) {
                        textProperties[key] = copiedElement.fontSize * (span.style[key] ?? 1);
                    } else {
                        textProperties[key] = span.style[key];
                    }

                    if (key === 'font') {
                        await this.renderer.injectFontFace(span.style[key]!);
                    }
                });
                await this.renderer.injectFontFace(textProperties.font!);
            } else {
                await this.renderer.injectFontFace(data.font!);
            }
        } else {
            const richTextEditor = copiedViewElement.__richTextRenderer.editor_m!;
            textProperties = richTextEditor.getTextProperties();
        }

        this.copyDefaultStyles(targetElement, copiedElement);

        const props: Array<{ key: OneOfElementPropertyKeys; value: OneOfDataNodeValues }> = [
            { key: 'textColor', value: textProperties.textColor },
            { key: 'underline', value: textProperties.underline },
            { key: 'strikethrough', value: textProperties.strikethrough },
            { key: 'textShadows', value: textProperties.textShadows },
            { key: 'lineHeight', value: textProperties.lineHeight },
            { key: 'fontSize', value: textProperties.fontSize },
            { key: 'padding', value: textProperties.padding },
            { key: 'horizontalAlignment', value: textProperties.horizontalAlignment },
            { key: 'verticalAlignment', value: textProperties.verticalAlignment },
            { key: 'uppercase', value: textProperties.uppercase },
            { key: 'font', value: textProperties.font },
            { key: 'maxRows', value: textProperties.maxRows },
            { key: 'textOverflow', value: textProperties.textOverflow },
            { key: 'characterSpacing', value: textProperties.characterSpacing }
        ];

        // TODO: Optimize this.
        for (const { key, value } of props) {
            this.mutatorService.setElementPropertyValue(targetElement, key, value);
        }

        this.workspace.contextMenu.tryCloseMenus();
    }
    private stylePolicy: PolicyMap<
        OneOfElementDataNodes | OneOfTextDataNodes,
        OneOfElementDataNodes | OneOfTextDataNodes,
        void
    > = {
        [ElementKind.Rectangle]: (t, c) => this.copyDefaultStyles(t, c),
        [ElementKind.Image]: (t, c) => this.copyDefaultStyles(t, c),
        [ElementKind.Ellipse]: (t, c) => this.copyDefaultStyles(t, c),
        [ElementKind.Widget]: (t, c) => this.copyDefaultStyles(t, c),
        [ElementKind.Video]: (t, c) => this.copyDefaultStyles(t, c),
        [ElementKind.Text]: (t, c) =>
            this.copyTextStyle(t as OneOfTextDataNodes, c as OneOfTextDataNodes),
        [ElementKind.Button]: (t, c) =>
            this.copyTextStyle(t as OneOfTextDataNodes, c as OneOfTextDataNodes)
    };

    private pasteStyle = (snapshot: IEditorSnapshot): void => {
        const { selection } = snapshot;

        if (!selection) {
            throw new Error('No selection found when pasting style');
        }

        const sortedSelection = selection.asSortedArray();
        const selectionElements = this.elementSelectionService.currentSelection.elements;

        for (const copiedElement of sortedSelection) {
            for (const targetElement of selectionElements) {
                const applyStyle = this.stylePolicy[targetElement.kind];

                if (!applyStyle) {
                    throw new Error(`Unknown element type: ${ElementKind[targetElement.kind]}`);
                }

                applyStyle(targetElement, copiedElement);
            }
        }
    };

    private copyTextStyle(targetElement: OneOfTextDataNodes, copiedElement: OneOfTextDataNodes): void {
        this.copyAllTextStyles(targetElement, copiedElement);
    }

    private getTimeFromElements(elements: OneOfElementDataNodes[]): number {
        let time = 0;
        for (const element of elements) {
            if (element) {
                const elementTime = element.animations[0]?.keyframes[1]?.time as number | undefined;

                if (!elementTime) {
                    continue;
                }

                if (time === 0 && elementTime) {
                    time = elementTime;
                    continue;
                }
                time = elementTime < time ? elementTime : time;
            }
        }
        // defaulting to one
        return time !== 0 ? time : 1;
    }

    pasteKeyframes(targetDataElements: ReadonlyArray<OneOfElementDataNodes>): void {
        if (!this.copiedSnapshot?.keyframeSelection) {
            return;
        }

        if (this.copiedSnapshot) {
            const addedKeyframes = this.copyPasteService.pasteKeyframes(
                this.copiedSnapshot,
                targetDataElements,
                this.time
            );

            const selectedElements = this.timeline.nodes.filter(el =>
                targetDataElements.find(tel => tel.id === el.id)
            );

            this.keyframeService.set(...addedKeyframes);

            selectedElements.forEach(el => {
                const expand = this.timeline.timelineElementComponents.find(
                    tel => tel.node.id === el.id
                );

                if (expand) {
                    expand.expanded = true;
                }

                if (!isGroupDataNode(el)) {
                    this.editorEventService.elements.change(el, { animations: el.animations });
                }
            });

            this.elementSelectionService.setSelection(...selectedElements);
        }
    }

    async pasteSelection(
        snapshot: IEditorSnapshot,
        position?: IPosition,
        setViewIndex = false,
        changeType: ElementChangeType = ElementChangeType.Instant
    ): Promise<void> {
        if (this.copiedSnapshot && this.copiedSnapshot.copyType === 'keyframe') {
            const selection = this.elementSelectionService.currentSelection;
            if (selection && selection.length > 0) {
                this.pasteKeyframes(selection.elements);
            }
            return;
        }
        await this.pasteElement(snapshot, position, setViewIndex, changeType);
    }

    private async pasteElement(
        snapshot: IEditorSnapshot,
        position?: IPosition,
        setViewIndex = false,
        changeType: ElementChangeType = ElementChangeType.Instant
    ): Promise<void> {
        const { selection, versions, selectedVersionId, elements, document } = snapshot;

        if (!selection) {
            throw new Error('No selection found when pasting element');
        }

        if (!selection.nodes.length) {
            return;
        }
        const defaultVersionProperties = [...this.defaultVersion.properties];

        if (this.editorStateService.elements.length === 0 && this.animator?.time === 0) {
            this.animator.setTime_m(1);
        }

        const newSelection: OneOfDataNodes[] = [];
        const fontStyleIds: string[] = [];
        const elementsToPatch: OneOfElementDataNodes[] = [];
        const creativesetElements = [...elements, ...this.editorStateService.elements];
        const nodesArray = toFlatNodeList(selection.nodes);
        const groupsArray = nodesArray.filter(node => isGroupDataNode(node)) as GroupDataNode[];
        let idChangeMap = new Map<string, string>();
        const nodeTreeTarget = this.getEligiblePasteTargets(nodesArray.slice().reverse()[0]);
        const nodeIndexList: { [key in string]: number } = {};

        this.elementSelectionService.clearSelection();

        if (groupsArray.length && !canCreateGroup(nodesArray, this.renderer.creativeDocument)) {
            return;
        }

        const clonedGroupRefs: { newGroup: GroupDataNode; oldGroup: GroupDataNode }[] = [];
        // Recreate all groups
        for (const group of groupsArray) {
            const sharedGroup = creativesetElements.find(e => e.id === group.id)!;
            const clonedSharedGroup = cloneDeep(sharedGroup);
            const newGroupName = generateUniqueName(
                sharedGroup.name,
                toFlatNodeList([
                    ...this.editorStateService.document.nodes,
                    ...clonedGroupRefs.map(gr => gr.newGroup)
                ])
            );
            const newGroup = group.copy();
            newGroup.setNodes_m([]);
            newGroup.name = newGroupName;
            newGroup.__parentNode = group.__parentNode;
            clonedSharedGroup.id = newGroup.id;
            this.editorStateService.addElement(clonedSharedGroup);
            clonedGroupRefs.push({ newGroup, oldGroup: group });
        }

        /**
         * Set correct parentNode for nested groups.
         * Set parentNode to undefined if it's the topmost group
         * in a nest group copy. Only the topmost group is added
         * to the node tree that is selected further down.
         */
        for (const groupRef of clonedGroupRefs) {
            const { newGroup, oldGroup } = groupRef;
            const oldParentGroupRef = clonedGroupRefs.find(
                gr => gr.oldGroup.id === oldGroup.__parentNode?.id
            );
            if (oldParentGroupRef) {
                oldParentGroupRef.newGroup.addNode_m(newGroup);
            } else {
                newGroup.__parentNode = undefined;
            }
        }

        for (const element of nodesArray) {
            if (isGroupDataNode(element)) {
                continue;
            }
            const sharedElement = creativesetElements.find(e => e.id === element.id)!;
            const clonedElement = cloneDeep(element);
            const copySharedElement = cloneDeep(sharedElement);
            copySharedElement.id = clonedElement.id = uuidv4();

            if (!sharedElement) {
                throw new Error(`Could not find shared element with id '${element.id}'`);
            }

            const elementValues = this.copyElementValues(
                {
                    elementProperties: sharedElement.properties,
                    copiedVersions: versions,
                    defaultVersionProperties: [...defaultVersionProperties],
                    images: this.creativeset.images,
                    videos: this.creativeset.videos,
                    fontFamilies: this.fontFamilies
                },
                document.id,
                selectedVersionId
            );

            // when we paste an element with a feed, we need to add it to the store
            if (isImageNode(clonedElement) || isVideoNode(clonedElement)) {
                if (clonedElement.feed && !this.renderer.feedStore!.getFeed(clonedElement.feed.id)) {
                    await this.renderer.feedStore!.add(clonedElement.feed.id);
                }
            }

            if (isWidgetNode(clonedElement)) {
                this.cloneWidgetProperties(clonedElement, elementValues);
            }

            /**
             * Update all ids on animations, states, actions etc.
             */
            const newIdMappings = setNewPropertyIds(element, clonedElement);

            idChangeMap = new Map([...idChangeMap, ...newIdMappings]);
            idChangeMap.set(element.id, clonedElement.id);

            let newElement: OneOfElementDataNodes;
            try {
                // Since we're removing the element from the document, duplicated names will appear
                const newName = generateUniqueName(clonedElement.name, [
                    ...toFlatNodeList(this.renderer.creativeDocument.nodes),
                    ...elementsToPatch
                ]);
                newElement = await this.elementCreatorService.createElementCopy(
                    {
                        ...clonedElement,
                        name: newName
                    },
                    {
                        values: elementValues,
                        element: sharedElement
                    },
                    false
                );

                const csetElement = this.editorStateService.getElementById(newElement.id);
                newElement.id = csetElement.id = clonedElement.id;

                if (isTextNode(clonedElement) && isTextNode(element)) {
                    copySharedElement.properties.push(...elementValues.elementProperties);
                    clonedElement.characterStyles = element.characterStyles;
                    clonedElement.content = cloneDeep(element.__dirtyContent || element.content);
                    fontStyleIds.push(clonedElement.content.style.font?.id || '');
                    for (const span of clonedElement.content.spans) {
                        if (!isVariableSpan(span)) {
                            continue;
                        }

                        const spanVariable = span.style.variable;
                        if (spanVariable?.spanId) {
                            spanVariable.spanId = spanVariable.spanId.replace(
                                element.id,
                                clonedElement.id
                            );
                        }

                        if (spanVariable?.id && !this.renderer.feedStore?.getFeed(spanVariable.id)) {
                            await this.renderer.feedStore?.add(spanVariable.id);
                        }
                    }
                    this.workspace.transform.setTransformMode(TransformMode.None);
                }
                // Remove from document. Re-added based on current selection further down
                this.mutatorService.creativeDocument.removeNodeById_m(newElement.id);
                const clonedGroup = clonedGroupRefs.find(
                    clone => clone.oldGroup.id === clonedElement.__parentNode?.id
                );

                if (clonedGroup) {
                    const parent = element.__parentNode ?? element.__rootNode;
                    const elementIndex = parent ? parent.nodes.findIndex(e => e.id === element.id) : 0;
                    clonedGroup.newGroup.addNode_m(newElement, elementIndex);
                }
            } catch (e) {
                this.logger.error(e);
                continue;
            }

            elementsToPatch.push(newElement);

            if (setViewIndex) {
                nodeIndexList[clonedElement.id] =
                    this.mutatorService.getElementViewIndex(clonedElement).index;
            }

            newSelection.push(newElement);
        }

        this.addFontFamiliesIfNeeded(snapshot, fontStyleIds);

        elementsToPatch.forEach(element => this.patchPropertyIds(element, idChangeMap));

        this.filterInvalidActions(elementsToPatch);

        newSelection.push(...clonedGroupRefs.map(c => c.newGroup));

        newSelection.forEach(node => {
            if (!node.__parentNode) {
                nodeTreeTarget.addNode_m(node, setViewIndex ? nodeIndexList[node.id] : undefined);
            }
        });
        this.renderer.updateElementOrder_m();

        if (position) {
            const flatNodeList = toFlatNodeList(newSelection);
            const flatElementList = toFlatElementNodeList(newSelection);
            const newBoundingBox = calculateBoundingBox(flatNodeList);
            this.positionSelection(flatElementList, newBoundingBox, position);
        }

        if (this.editorStateService.document.elements.length === 1) {
            this.timeline.seek(1);
        }

        this.editorEventService.creative.change('elements', undefined, changeType);
        this.elementSelectionService.setSelection(...newSelection);
        this.elementSelectionService.latestSelectionType = 'element';
    }

    private getEligiblePasteTargets(pasteNode: OneOfDataNodes): OneOfGroupDataNodes {
        const selectedNodes = this.elementSelectionService.currentSelection.nodes
            .filter(node => {
                if (isGroupDataNode(node) || node.__parentNode) {
                    return true;
                }
            })
            .map(node => {
                if (!isGroupDataNode(node) && node.__parentNode) {
                    return node.__parentNode;
                }

                return node;
            }) as GroupDataNode[];

        const distinctTarget = [...new Set(selectedNodes)][0];

        const nodeListTarget = distinctTarget ? distinctTarget : this.mutatorService.creativeDocument;

        // Copy pasting on itself should not be allowed
        const targetIsSameGroup = (node: OneOfDataNodes): boolean =>
            isGroupDataNode(node) && node.id === nodeListTarget.id;
        const nodeParentIsSameGroup =
            pasteNode.__parentNode && targetIsSameGroup(pasteNode.__parentNode);
        if ((targetIsSameGroup(pasteNode) || nodeParentIsSameGroup) && isGroupDataNode(pasteNode)) {
            return distinctTarget.__parentNode || this.mutatorService.creativeDocument;
        }

        return nodeListTarget;
    }

    addFontFamiliesIfNeeded(snapshot: IEditorSnapshot, fontStyleIds: string[]): void {
        if (!snapshot.fontFamilies) {
            return;
        }

        let newFontFamilies: IFontFamily[] = [];
        for (const fontStyleId of fontStyleIds) {
            if (tryGetFontStyleById(this.fontFamilies, fontStyleId)) {
                // Font already there
                continue;
            }
            const newFont = tryGetFontByStyleId(snapshot.fontFamilies, fontStyleId);
            if (!newFont) {
                // nothing to do, font doesn't exist
                continue;
            }

            newFontFamilies = mergeFontFamilies(newFontFamilies, [
                {
                    ...newFont,
                    fontStyles: newFont.fontStyles.filter(({ id }) => fontStyleId === id)
                }
            ]);
        }
        this.fontFamiliesService.addFontFamilies(newFontFamilies);
    }

    private patchPropertyIds(element: OneOfElementDataNodes, idMap: Map<string, string>): void {
        const properties: OneOfElementPropertyKeys[] = ['animations', 'actions', 'states'];

        const recursiveUpdateIds = (
            object: OneOfElementDataNodes,
            key: OneOfElementPropertyKeys
        ): void => {
            const value = object[key];
            if (typeof value === 'string') {
                const id = idMap.get(value);
                if (id) {
                    object[key] = id;
                }
                return;
            }

            if (typeof object !== 'object') {
                return;
            }

            if (Array.isArray(value)) {
                for (const arrItem of value) {
                    for (const prop in arrItem) {
                        recursiveUpdateIds(arrItem, prop as OneOfElementPropertyKeys);
                    }
                }
            } else {
                for (const prop in value) {
                    recursiveUpdateIds(value, prop as OneOfElementPropertyKeys);
                }
            }
        };

        for (const key of Object.keys(element) as OneOfElementPropertyKeys[]) {
            if (!properties.includes(key)) {
                continue;
            }
            recursiveUpdateIds(element, key);
        }
    }

    /**
     * Filter out actions that are targeting non-existing elements
     * or that are targeting elements that no longer has that state.
     * Needed for cross-design pasting
     */
    private filterInvalidActions(elementsToPatch: OneOfElementDataNodes[]): void {
        const allElements = [...this.renderer.creativeDocument.elements, ...elementsToPatch];
        elementsToPatch.forEach(element => {
            element.actions = element.actions.filter(action => {
                const ops = action.operations.filter(operation => {
                    if (operation.method === ActionOperationMethod.OpenUrl) {
                        return true;
                    }

                    const elementExists = allElements.some(el => el.id === operation.target);

                    if (
                        operation.method === ActionOperationMethod.ClearStates ||
                        operation.value === undefined
                    ) {
                        return elementExists;
                    }

                    const stateExists =
                        elementExists &&
                        allElements.some(el => el.states.find(state => state.id === operation.value));
                    return stateExists;
                });

                return ops.length > 0;
            });
        });
    }

    private cloneWidgetProperties(
        clonedElement: IWidgetElementDataNode,
        elementValues: IElementPropertyValues
    ): void {
        clonedElement.customProperties = clonedElement.customProperties.map(property => {
            const elementProperty = elementValues.elementProperties.find(
                prop => prop.name === property.name
            );
            if (elementProperty && elementProperty.versionPropertyId) {
                property.versionPropertyId = elementProperty.versionPropertyId;
            }
            return property;
        });
    }

    private positionSelection(
        elements: OneOfElementDataNodes[],
        boundingBox: IBoundingBox,
        position: IPosition
    ): void {
        position.x /= this.editorStateService.zoom;
        position.y /= this.editorStateService.zoom;

        this.mutatorService.setSelectionPosition(elements, boundingBox, position);
    }

    private copyElementValues(
        elementValues: IElementPropertyCopyValues,
        sourceDocumentId: string,
        selectedVersionId: string
    ): IElementPropertyValues {
        const { elementProperties, copiedVersions, defaultVersionProperties, fontFamilies } =
            elementValues;
        const newElementProperties: IElementProperty[] = [];
        let newVersionPropertyId: string | undefined;
        const versionValues = copiedVersions.find(({ id }) => id === selectedVersionId)!.properties;
        const defaultVersionValues = copiedVersions.find(
            ({ id }) => id === this.defaultVersion.id
        )?.properties;
        const currentVersionId = this.editorStateService.currentVersion.id;

        for (const property of elementProperties) {
            const copiedProperty = property;
            copiedProperty.id = '';

            if (!property.versionPropertyId) {
                newElementProperties.push(copiedProperty);
                continue;
            }

            newVersionPropertyId = uuidv4();

            let versionValue = versionValues.find(({ id }) => id === property.versionPropertyId);
            if (!versionValue) {
                versionValue = defaultVersionValues?.find(
                    ({ id }) => id === property.versionPropertyId
                );
            }

            if (!versionValue) {
                throw new Error(
                    'Could not find version value in selected or default version snapshot.'
                );
            }

            versionValue = cloneDeep(versionValue);
            if (isVersionedText(versionValue)) {
                for (const span of versionValue.value.styles) {
                    const style = span.styleIds[sourceDocumentId];
                    span.styleIds = {};
                    if (style) {
                        span.styleIds[sourceDocumentId] = style;
                    }
                }
            }

            const newVersionedProperty: IVersionProperty = {
                id: newVersionPropertyId,
                name: versionValue.name,
                value: versionValue.value
            };

            if (isVersionedText(newVersionedProperty)) {
                remapStyles(
                    newVersionedProperty.value,
                    sourceDocumentId,
                    this.editorStateService.document.id
                );
            }

            // We only need to add on original value, since we don't allow a non original value to be the same as original
            this.versionsService.addVersionProperty(this.defaultVersion.id, newVersionedProperty);

            if (currentVersionId !== this.defaultVersion.id) {
                this.versionsService.addVersionProperty(currentVersionId, newVersionedProperty);
            }

            defaultVersionProperties.push(newVersionedProperty); // awful hack to avoid awaiting for the new defaultVersion.properties

            copiedProperty.versionPropertyId = newVersionPropertyId;
            copiedProperty.clientId = uuidv4();
            newElementProperties.push(copiedProperty);
        }

        return {
            elementProperties: newElementProperties,
            versionProperties: versionValues,
            defaultVersionProperties,
            newDefaultVersionId: newVersionPropertyId ?? '',
            fontFamilies
        };
    }

    private isKeyframeCopyValid(keyframes: IAnimationKeyframe[]): boolean {
        const dataElements = this.elementSelectionService.currentSelection.elements;

        return !keyframes.some(keyframe => {
            const elementAndAnimation = getElementAndAnimationOfKeyframe(dataElements, keyframe);
            return elementAndAnimation && isTransitionAnimation(elementAndAnimation.animation);
        });
    }

    copySelection = (): boolean => {
        if (
            this.keyframeService.hasKeyframes &&
            !this.isKeyframeCopyValid([...this.keyframeService.keyframes])
        ) {
            this.uiNotificationService.open('Cannot copy keyframes from transition animations', {
                type: 'warning',
                autoCloseDelay: 5000,
                placement: 'top'
            });
            return false;
        }

        this.copyPasteService.copiedSnapshot = this.historyService.createSnapshot();

        this.copyPasteService.copyAndSetLocalstorage(
            this.copyPasteService.copiedSnapshot,
            this.creativesetDataService.brand.id,
            this.creativeSetFontFamilies
        );
        this.animationService.change$.next();
        return true;
    };

    cutSelection = (): void => {
        const selection = this.elementSelectionService.currentSelection;
        if (selection && selection.length >= 1) {
            if (this.copySelection()) {
                if (this.keyframeService.hasKeyframes) {
                    this.removeSelectedKeyframes();
                } else {
                    this.workspace.removeSelectedElements();
                }
                this.workspace.deselectAllElements();
            }
        }
    };

    private async useSnapshot(snapshot: IEditorSnapshot): Promise<void> {
        this.logger.verbose('Using snapshot');
        this.historyService.isApplyingSnapshot = true;

        if (!snapshot.defaultVersionId) {
            throw new Error('Snapshot missing original version id!');
        }

        // Update version properties
        for (const version of this.versions) {
            const snapshotVersion = snapshot.versions.find(({ id }) => id === version.id);
            if (!snapshotVersion) {
                continue;
            }

            for (const property of snapshotVersion.properties) {
                this.versionsService.upsertVersionProperty(version.id, property);
            }
        }

        // Populate the rootNode
        forEachDataElement(snapshot.document, element => {
            element.__rootNode = snapshot.document;
        });

        const versionProperties = snapshot.versions.find(
            ({ id }) => id === snapshot.selectedVersionId
        )!.properties;
        initializeElements(snapshot.document, snapshot.elements, {
            versionProperties,
            defaultVersionProperties: this.defaultVersion.properties,
            fontFamilies: this.fontFamilies
        });

        this.editorStateService.document.guidelines = snapshot.guidelines;
        this.renderer.rerender_m(snapshot.document);
        this.animator?.useCreative_m(snapshot.document);

        this.mutatorService.useCreative(snapshot.document);
        this.editorStateService.updateElements(snapshot.elements);

        this.renderer.versions_m = this.versions;
        this.editorStateService.document = snapshot.document;
        this.editorStateService.designFork.document = snapshot.document;

        await applyWidgetCodeOnWidgetNodes(this.editorStateService.designFork);

        this.workspace.transform.onGuidelineChange$.next(snapshot.activeGuideline);

        // Add versions that is not part of the snapshot. Otherwise, we will not be able
        // to change version from a snapshot that didn't include the version.
        for (const version of this.versions) {
            const versionFork = this.versions.find(({ id }) => id === version.id);
            if (versionFork) {
                continue;
            }

            this.versions.push({
                id: version.id,
                name: version.name,
                localization: version.localization,
                properties: version.properties.filter(vp =>
                    this.defaultVersion.properties.find(({ id }) => id === vp.id)
                ),
                targetUrl: version.targetUrl
            });
        }

        if (snapshot.isVersionable) {
            const version = this.versions.find(v => v.id === snapshot.selectedVersionId)!;
            this.filtersService.selectVersion(version);
        }
        const selectedIds = snapshot.selection?.nodes.map(element => element.id) || [];
        if (selectedIds.length > 0) {
            this.propertiesService.dataElementChange$.next(undefined);
            this.propertiesService.selectedStateChange$.next(undefined);
        }
        this.elementSelectionService.clearSelection();
        this.workspace.transform.indexElementPoints();
        this.timeline.timelineElementComponents.forEach(c => {
            if (snapshot.expandedAnimationElements.includes(c.node.id)) {
                c.expanded = true;
            }
        });

        this.selectElements(selectedIds);

        const selectedKeyframes: IAnimationKeyframe[] = [];
        if (snapshot.keyframeSelection?.keyframes.length) {
            for (const element of snapshot.document.elements) {
                for (const keyframe of snapshot.keyframeSelection.keyframes) {
                    const kf = getKeyframeById(element, keyframe.id);
                    if (kf) {
                        selectedKeyframes.push(kf);
                    }
                }
            }
        }
        this.keyframeService.set(...selectedKeyframes);

        const state = this.propertiesService.selectedElement?.states.find(
            ({ id }) => id === snapshot.activeState?.id
        );
        this.propertiesService.selectedStateChange$.next(state);

        const selection = this.elementSelectionService.currentSelection;
        const selectedElement = selection?.element;

        let isInTextEditMode = false;
        if (selectedElement && isTextNode(selectedElement)) {
            const textSelection = snapshot.textSelection;
            this.rerenderNode(selectedElement);
            if (textSelection) {
                await this.mutatorService.startEditText(selectedElement, true);
                const viewElement = this.renderer.getViewElementById<OneOfTextViewElements>(
                    selectedElement.id
                );
                viewElement?.__richTextRenderer?.editor_m!.selection.selectText(
                    textSelection.anchor,
                    textSelection.focus
                );
                isInTextEditMode = true;
            }
        }
        this.workspace.transform.setTransformMode(
            isInTextEditMode ? TransformMode.EditText : TransformMode.None
        );

        if (selection && snapshot.selection) {
            this.elementSelectionService.latestSelectionType = snapshot.latestSelectionType;
        }

        this.historyService.checkDirtiness();
        this.historyService.emitSnapshotChange();

        // Clear EventChanges cache
        this.editorEventService.elements.clear();

        this.historyService.isApplyingSnapshot = false;
    }

    private fontManagerClosed = (): void => {
        this.creativesetDataService.syncFonts();
    };

    openFontManager(): void {
        const dialog = this.uiDialogService.openComponent(FontManagerComponent, {
            padding: 0,
            headerText: 'Manage brand fonts',
            width: '100%',
            maxWidth: '100%',
            height: '100%',
            panelClass: ['inlined-iframe', 'fullscreen'],
            data: {
                brandId: this.creativesetDataService.brand.id
            }
        });
        dialog.beforeClose().subscribe(this.fontManagerClosed);
    }

    setCursor(element: HTMLElement, cursor = 'default'): void {
        if (cursor === '') {
            cursor = 'default';
        }
        if (this.currentCursor !== cursor) {
            this.currentCursor = cursor;
            setCursorOnElement(element, cursor);
        }
    }

    private validateFonts(): void {
        const elements = getTextElements(this.editorStateService.document);
        if (!elements.length) {
            return;
        }

        this.fontValidationService.validateElements(elements, this.creative.feedStore_m);
    }

    private filterByDocument(element: IElement, document: ICreativeDataNode): boolean {
        for (const node of document.nodeIterator_m(true)) {
            if (node.id === element.id) {
                return true;
            }
        }
        return false;
    }

    processFontValidationResult(fontValidationResults: IFontValidationState[]): void {
        const elementsNames = fontValidationResults
            .map(({ elementName }) => `‘${elementName}’`)
            .join(', ');
        const message = `Your font does not support all characters used in ${elementsNames}. We highly recommend changing font (or copy) to avoid a back up font being used. Read more <a target="_blank" href="http://support.bannerflow.com/en/articles/6046094-how-to-solve-issues-with-missing-font-characters">here</a>.`;
        this.uiNotificationService.open(
            message,
            { type: 'warning', placement: 'top' },
            'Don’t show this again',
            true
        );
        this.uiNotificationService.notificationRef.instance.onCloseCheckBox
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(isCheckboxChecked => {
                if (isCheckboxChecked) {
                    this.fontValidationService.updateIgnoredResults(fontValidationResults);
                }
            });
    }

    private checkElementVisibility(): void {
        const elements = this.elementSelectionService.currentSelection.elements;

        for (const element of elements) {
            const viewElement = this.renderer.getViewElementById(element.id);
            const dataNode = this.renderer.creativeDocument.findNodeById_m(element.id, true);

            if (!dataNode) {
                return;
            }

            const hideViewElement = isHidden(element) && viewElement;
            const showViewElement = !isHidden(element) && !viewElement;

            if (hideViewElement) {
                this.renderer.hideElement_m(element);
            } else if (showViewElement) {
                const isLoadingAsset =
                    isMediaElementNode(element) && isElementMediaAssetLoading(element);

                if (!isLoadingAsset) {
                    this.renderer.showElement_m(element);
                }
            }
        }
    }

    async syncVersionsFromStore(): Promise<void> {
        this.versions = await firstValueFrom(this.versionsService.versions$);
        this.selectedVersion = await firstValueFrom(this.versionsService.selectedVersion$);
        this.defaultVersion = await firstValueFrom(this.versionsService.defaultVersion$);
    }

    private isDestroyed(): boolean {
        // this.renderer is deleted (undefined) in ngOnDestroy
        const isRendererDestroyed = !this.renderer ? true : this.renderer.destroyed_m;
        return this.destroyed || isRendererDestroyed;
    }
}

interface IElementPropertyCopyValues {
    copiedVersions: IVersion[];
    elementProperties: IElementProperty[];
    defaultVersionProperties: IVersionProperty[];
    newDefaultVersionId?: string;
    images?: IImageElementAsset[];
    videos?: IVideoElementAsset[];
    fontFamilies: IFontFamily[];
}

interface SaveDesignOptions {
    dirtyVersions: IVersion[];
    dirtyDesigns: IDesign[];
    saveAndExit: boolean;
    defaultVersion: IVersion;
    saveAll: boolean;
}

type SaveNewDesignOptions = Pick<SaveDesignOptions, 'dirtyVersions' | 'saveAndExit'>;

type PolicyMap<T, K, V> = { [key in string]: (t: T, k: K) => V };
