import { ElementDtoV2 } from '@domain/api/generated/sapi';
import { IBrandLibraryElement, INewBrandLibraryElement } from '@domain/brand/brand-library';
import { IElement, IElementProperty, INewElement } from '@domain/creativeset/element';
import { AssetReference } from '@domain/creativeset/element-asset';
import { uuidv4 } from './id';

type CreateElement<Element extends IElement | IBrandLibraryElement> = Omit<
    Partial<Element>,
    'properties'
> & {
    type: Element['type'];
    properties?: Partial<IElementProperty>[];
};

export function createElement(element: CreateElement<IElement> & { id: string }): IElement;
export function createElement(element: CreateElement<IElement> & { id?: string }): INewElement;
export function createElement(element: CreateElement<IElement>): IElement | INewElement {
    let elementProperties: IElementProperty[] = [];
    if (element.properties) {
        elementProperties = element.properties.map(property => createElementProperty(property));
    }

    return {
        id: element.id ?? undefined,
        name: element.name ?? '',
        properties: elementProperties,
        type: element.type
    };
}

export function createBrandlibraryElement(
    element: CreateElement<IBrandLibraryElement> & { id: string }
): IBrandLibraryElement;
export function createBrandlibraryElement(
    element: CreateElement<IBrandLibraryElement> & { id?: string }
): INewBrandLibraryElement;
export function createBrandlibraryElement(
    element: CreateElement<IBrandLibraryElement>
): IBrandLibraryElement | INewBrandLibraryElement {
    return {
        ...createElement(element),
        parentFolderId: element.parentFolderId,
        created: element.created ?? '',
        modified: element.modified ?? ''
    };
}

const NULL_UUID = '00000000-0000-0000-0000-000000000000';

export function createElementProperty(property: Partial<IElementProperty>): IElementProperty {
    /**
     * BE creates a null uuid in certain cases (i.e stock widget). We will generate a proper id
     * if this is the case or if clientId is falsy.
     */
    const clientId = hasValidClientId(property) ? property.clientId : uuidv4();

    return {
        id: property.id ?? '',
        clientId,
        name: property.name ?? '',
        label: property.label,
        unit: property.unit,
        value: property.value,
        versionPropertyId: property.versionPropertyId
    };
}

export function isNewBrandlibraryElement(
    element: IBrandLibraryElement | INewBrandLibraryElement
): element is INewBrandLibraryElement {
    if (!element.id) {
        return true;
    }

    return false;
}

export function getLibraryWidgetReferenceOfElement(
    element: IBrandLibraryElement | INewBrandLibraryElement | IElement
): IElementProperty | undefined {
    return element.properties.find(prop => prop.name === AssetReference.BannerflowLibraryWidget);
}

export function getWidgetContentUrlOfElement(
    element: IBrandLibraryElement | INewBrandLibraryElement | IElement | ElementDtoV2
): string | undefined {
    return element.properties.find(prop => prop.name === AssetReference.WidgetContentUrl)?.value;
}

export function getDynamicPropertyOfElement(element: IElement): IElementProperty | undefined {
    return element.properties.find(prop => prop.name === 'dynamicContent');
}

function hasValidClientId(
    property: Partial<IElementProperty>
): property is Partial<IElementProperty> & { clientId: string } {
    return !!property.clientId && property.clientId !== NULL_UUID;
}
