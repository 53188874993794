<div
    class="text"
    *ngIf="version">
    <!-- If version is default and no other versions exists -->
    <div *ngIf="state === 'forbidden'">
        You cannot remove this version, a creative set requires at least one version.
    </div>
    <!-- If version is default and only one other version exist -->
    <div *ngIf="state === 'auto-default'">
        The '{{ version.name }}' is your default version. Deleting this version will make your other
        version the default version.
    </div>
    <!-- If version is default and more than 2 other versions exist -->
    <div *ngIf="state === 'choose-default'">
        You are trying to delete the default version. Please choose a version to act as the new default
        version:
        <br /><br />
        <ui-select
            id="replace-version-select"
            theme="default"
            [(selected)]="versionToReplaceDefault">
            <div ui-select-label>
                <version-flag
                    [localizationId]="versionToReplaceDefault.localization.id"
                    [showName]="true"
                    [title]="versionToReplaceDefault.name"></version-flag>
            </div>
            <ui-option
                *ngFor="let v of nonDefaultVersions"
                [value]="v">
                <version-flag
                    [localizationId]="v.localization.id"
                    [showName]="true"
                    [title]="v.name"></version-flag>
            </ui-option>
        </ui-select>
    </div>
</div>
<div class="buttons">
    <ui-button
        text="Cancel"
        (click)="cancel()"></ui-button>
    <ui-button
        id="delete-version-button"
        *ngIf="state !== 'forbidden'"
        (click)="delete()"
        type="primary"
        text="Delete">
    </ui-button>
</div>
