<ng-container *ngIf="users$ | async as users">
    <user-icon
        *ngFor="let user of users | slice: 0 : ICON_LIMIT; let i = index"
        (click)="openCreativesetInUseDialog(users)"
        class="user-icon"
        uiTooltipPosition="bottom"
        [class.inactive]="!user.isActive"
        [shortName]="user.shortName"
        [color]="user.color"
        [uiTooltip]="user.name + ' - ' + (user.isActive ? 'Active' : 'Inactive')"></user-icon>

    <user-icon
        *ngIf="users.length > ICON_LIMIT"
        (click)="openCreativesetInUseDialog(users)"
        [shortName]="'+' + (users.length - ICON_LIMIT)"
        color="var(--studio-color-second)"></user-icon>
</ng-container>
