import { IDistance } from '@domain/dimension';
import { ILayoutElement, ILayoutRule } from '../rules';

/**
 * If 90% of width is covered, assume it should be centered
 */
const SNAP_TOLERANCE = 0.8;

/**
 * Center assets aligned to the edge if new creative is way smaller in height or width.
 * This rule should run as one of the last rules
 */
export class CenterRule implements ILayoutRule {
    applyAutoContraintRule(layoutElement: ILayoutElement): void {
        const { constraint, rotatedBoundingBox, newCanvasSize, group, originalCreative } =
            layoutElement;
        const bounds = group?.rotatedBoundingBox || layoutElement.originalCreative;
        // const finalConstaint = getFinalConstraint(layoutElement);
        const left = this.pixelConstraint(constraint.left);
        const right = this.pixelConstraint(constraint.right);
        const top = this.pixelConstraint(constraint.top);
        const bottom = this.pixelConstraint(constraint.bottom);
        const widthPercent = rotatedBoundingBox.width / bounds.width;
        const newW = newCanvasSize.width * widthPercent;
        const heightPercent = rotatedBoundingBox.height / bounds.height;
        const newH = newCanvasSize.height * heightPercent;

        // Either left OR right aligned in pixels
        if (
            this.oneOfPx(left, right) &&
            constraint.width === undefined &&
            newCanvasSize.width < originalCreative.width &&
            widthPercent < 1
        ) {
            const edgeDistance = (left !== undefined ? left : right) || 0; // Always defined

            // If the asset will overlap one the non-constained edge, constrain it (with snap).
            if (newW + edgeDistance * 2 >= newCanvasSize.width * SNAP_TOLERANCE) {
                constraint.left = { unit: 'px', value: edgeDistance };
                constraint.right = { unit: 'px', value: edgeDistance };
            }
        }

        // Either top OR bottom aligned in pixels
        if (
            this.oneOfPx(top, bottom) &&
            constraint.height === undefined &&
            newCanvasSize.height < originalCreative.height &&
            heightPercent < 1
        ) {
            const edgeDistance = (top !== undefined ? top : bottom) || 0; // Always defined

            // If the asset will overlap one the non-constained edge, constrain it (with snap).
            if (newH + edgeDistance * 2 >= newCanvasSize.height * SNAP_TOLERANCE) {
                constraint.top = { unit: 'px', value: edgeDistance };
                constraint.bottom = { unit: 'px', value: edgeDistance };
            }
        }
    }

    private pixelConstraint(distance?: IDistance): number | undefined {
        if (distance && distance.unit === 'px') {
            return distance.value;
        }
    }

    private oneOfPx(d1?: number, d2?: number): boolean {
        return (d1 !== undefined && d2 === undefined) || (d2 !== undefined && d1 === undefined);
    }
}
