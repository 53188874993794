import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DISPLAY_CAMPAIGN_FEATURE_KEY, DisplayCampaignState } from './display-campaign.reducer';

export const getDisplayCampaignState = createFeatureSelector<DisplayCampaignState>(
    DISPLAY_CAMPAIGN_FEATURE_KEY
);

export const getLoaded = createSelector(getDisplayCampaignState, ({ loaded }) => loaded);

export const getError = createSelector(getDisplayCampaignState, ({ error }) => error);

export const getCampaigns = createSelector(getDisplayCampaignState, ({ campaigns }) => campaigns);

export const getPushingChangesOnCampaigns = createSelector(
    getDisplayCampaignState,
    ({ pushingChangesOnCampaignIds }) => pushingChangesOnCampaignIds
);

export const getIsPublishing = createSelector(
    getPushingChangesOnCampaigns,
    pushingChangesOnCampaignIds => !!pushingChangesOnCampaignIds.length
);

export const getHasConnectedCampaigns = createSelector(
    getDisplayCampaignState,
    ({ campaignIds }) => !!campaignIds.length
);

export const getCampaignIds = createSelector(getDisplayCampaignState, ({ campaignIds }) => campaignIds);
