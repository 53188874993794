import { EnvironmentProviders, importProvidersFrom } from '@angular/core';
import { UserEffects } from './user.effects';
import { reducer, USER_FEATURE_KEY } from './user.reducer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

export function provideUserStore(): EnvironmentProviders {
    return importProvidersFrom(
        StoreModule.forFeature(USER_FEATURE_KEY, reducer),
        EffectsModule.forFeature(UserEffects)
    );
}
