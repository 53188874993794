import { EnvironmentProviders, importProvidersFrom } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UserSettingsEffects } from './user-settings.effects';
import { reducer, USER_SETTINGS_FEATURE_KEY } from './user-settings.reducer';

export function provideUserSettingsStore(): EnvironmentProviders {
    return importProvidersFrom(
        StoreModule.forFeature(USER_SETTINGS_FEATURE_KEY, reducer),
        EffectsModule.forFeature(UserSettingsEffects)
    );
}
