<div class="overview">
    <div class="overview__header">
        <div class="overview__header__label">{{ label }}</div>
        ({{ currentSelected }})
    </div>
    <div class="overview__action">
        <div
            class="toggle-action"
            (click)="toggle()">
            <ng-container *ngIf="currentSelected > 0; else selectAllText">Deselect all</ng-container>
            <ng-template #selectAllText>Select all</ng-template>
        </div>
    </div>
</div>
