import { PipeTransform, Pipe } from '@angular/core';
@Pipe({
    standalone: true,
    name: 'dotdotdot'
})
export class DotDotDotPipe implements PipeTransform {
    transform(value: string, limit: number): string {
        return value.length > limit ? `${value.substring(0, limit)}...` : value;
    }
}
