export function capitalize(s: string): string {
    return s.charAt(0).toUpperCase() + s.slice(1);
}

export function getUniqueName(name: string, existingNames?: string[]): string {
    const existing = existingNames?.find(n => n.toLowerCase() === name.toLowerCase());
    if (existing) {
        if (/\(\d+\)$/i.test(name)) {
            const incrementedName = name.replace(
                /\((.+?)\)$/gi,
                (fullMatch, n) => `(${Number(n) + 1})`
            );
            return getUniqueName(incrementedName, existingNames);
        } else {
            return getUniqueName(`${name} (1)`, existingNames);
        }
    }

    return name;
}

/**
 * Compares the similarity between two strings using an n-gram comparison method.
 * The grams default to length 2.
 * @param str1 The first string to compare.
 * @param str2 The second string to compare.
 * @param gramSize The size of the grams. Defaults to length 2.
 */
function stringSimilarity(str1: string, str2: string, gramSize = 2): number {
    if (!str1?.length || !str2?.length) {
        return 0.0;
    }

    // Order the strings by length so the order they're passed in doesn't matter
    // and so the smaller string's ngrams are always the ones in the set
    const s1 = str1.length < str2.length ? str1 : str2;
    const s2 = str1.length < str2.length ? str2 : str1;

    const pairs1 = getNGrams(s1, gramSize);
    const pairs2 = getNGrams(s2, gramSize);
    const set = new Set(pairs1);

    const total = pairs2.length;
    let hits = 0;
    for (const item of pairs2) {
        if (set.delete(item)) {
            hits++;
        }
    }
    return hits / total;
}

// https://en.wikipedia.org/wiki/N-gram
function getNGrams(s: string, len: number): number[] {
    s = ' '.repeat(len - 1) + s.toLowerCase() + ' '.repeat(len - 1);
    const v = new Array(s.length - len + 1);
    for (let i = 0; i < v.length; i++) {
        v[i] = s.slice(i, i + len);
    }
    return v;
}

const SIMILARITY_THRESHOLD = 0.2;

export function isSimilarString(a: string, b: string): boolean {
    // using n-gram comparison method since it has proven better results than Monge-Elkan
    return stringSimilarity(a, b) > SIMILARITY_THRESHOLD;
}

/**
 * Returns the most similar item from the list of items.
 * If nothing is similar enough, returns undefined.
 * @param searchString - The string we want to compare to
 * @param key - The key of the item
 * @param items - The list of items we search in
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getMostSimilarItem<T extends { [key in string]: any }>(
    searchString: string,
    key: keyof T,
    items: T[]
): T | undefined {
    const similarItems = items
        .map(i => ({
            item: i,
            similarity: stringSimilarity(i[key], searchString)
        }))
        .filter(i => i.similarity > SIMILARITY_THRESHOLD)
        .sort((a, b) => b.similarity - a.similarity);

    return similarItems[0]?.item;
}
