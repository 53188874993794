import { Component, Injector } from '@angular/core';
import { MetaPlacement } from '@domain/social';
import { BaseOverlayComponent } from '../base-overlay.component';

@Component({
    selector: 'meta-overlay',
    templateUrl: 'meta-overlay.component.html',
    styleUrls: ['./meta-overlay.component.scss', '../shared.scss']
})
export class MetaOverlayComponent extends BaseOverlayComponent {
    MetaPlacement = MetaPlacement;

    constructor(injector: Injector) {
        super(injector);
    }
}
