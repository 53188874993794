import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Breakpoint, getQueryFromBreakpoint } from '@studio/utils/breakpoints';

/**
 * Used as:
 * *media="Breakpoint.DesktopUp"
 *
 * If the breakpoint matches, the directive will remove / create the component
 *
 * Examples:
 *
 * <div *media="Breakpoint.MobileUp">I am visible on tablet and up</div>
 * <div *media="Breakpoint.MobileDown">I am visible only on mobile</div>
 */
@Directive({
    selector: '[media]',
    standalone: true
})
export class MediaDirective {
    private hasView = false;
    private removeListener: () => void;

    constructor(
        private readonly viewContainer: ViewContainerRef,
        private readonly template: TemplateRef<any>
    ) {}

    @Input('media') set breakpoint(breakpoint: Breakpoint) {
        // cleanup existing listener
        if (this.removeListener) {
            this.removeListener();
        }
        this.setListener(breakpoint);
    }

    private setListener(breakpoint: Breakpoint): void {
        const query = getQueryFromBreakpoint(breakpoint);
        const mediaQueryList = window.matchMedia(query);
        const listener = (event): void => {
            // create view if true and not created already
            if (event.matches && !this.hasView) {
                this.hasView = true;
                this.viewContainer.createEmbeddedView(this.template);
            }
            // destroy view if false and created
            if (!event.matches && this.hasView) {
                this.hasView = false;
                this.viewContainer.clear();
            }
        };
        // run once and then add listener
        listener(mediaQueryList);
        mediaQueryList.addEventListener('change', listener);
        // add cleanup listener
        this.removeListener = (): void => mediaQueryList.removeEventListener('change', listener);
    }
}
