<div
    id="interaction-sizes"
    class="sizes">
    <div
        class="header"
        [class.collapsed]="collapsed">
        <ui-svg-icon
            (click)="toggleAll()"
            id="interaction-sizes-filter"
            data-test-id="size-filter-reset"
            class="filter-icon"
            [uiTooltip]="(toggleAllTooltipText$ | async) || ''"
            [uiTooltipHideArrow]="true"
            [class.all-filtered]="isFilteringAllSizes$ | async"
            [class.some-filtered]="
                (isFilteringAllSizes$ | async) === false && (isFilteringAnySize$ | async)
            "
            icon="visibility-visible" />
        <span class="title">Sizes</span>
        <div class="temporary-container-waiting-for-icon"></div>
        <ui-svg-icon
            (click)="collapse()"
            class="icon collapse"
            [icon]="collapsed ? 'arrow-up' : 'arrow-down'" />
    </div>
    @if (!collapsed) {
        <div class="size-section ui-scrollbar">
            @for (size of sizes | sortByGroupWidth; track size) {
                <div
                    class="size"
                    (click)="toggleFilter($event, size)"
                    [class.unfiltered]="!!(isFilteringAnySize$ | async) && !isFiltered(size)"
                    data-test-id="size-interaction-item">
                    <div class="size-copy">
                        <ui-svg-icon
                            class="size-visibility-icon"
                            icon="visibility-visible"
                            [class.selected]="isFiltered(size)" />
                        <div class="size">
                            <span>{{ size.width }} × {{ size.height }}</span>
                            <div class="space"></div>
                            @if (size.name) {
                                <span
                                    [uiTooltip]="size.name"
                                    [uiTooltipHideArrow]="true"
                                    [uiTooltipDisabled]="isMobileShowcase"
                                    class="size-name">
                                    {{ size.name }}
                                </span>
                            } @else {
                                <span></span>
                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    }
</div>
