import { IPosition, ISize } from '@domain/dimension';
import { ElementKind } from '@domain/elements';

export let cursorsLoaded = false;

export const ROTATION_ICON_MULTIPLE = 3;

export function loadCursors(): void {
    if (!cursorsLoaded) {
        createRotatingCursors('rotation', false);
        createRotatingCursors('resize', true);
        createCursor('image-position', CURSOR_IMAGE_POSITION);
        createCursor(`create-element-${ElementKind.Text}`, CURSOR_TEXT);
        createCursor(`create-element-${ElementKind.Button}`, CURSOR_BUTTON);
        createCursor(`create-element-${ElementKind.Rectangle}`, CURSOR_RECT);
        createCursor(`create-element-${ElementKind.Ellipse}`, CURSOR_ELLIPSE);
        createCursor(`create-element-${ElementKind.Image}`, CURSOR_IMAGE);
        createCursor(`selection-locked`, CURSOR_SELECTION_LOCKED);
        createCursor(`selection`, CURSOR_SELECTION);
    }
    cursorsLoaded = true;
}

export function createRotatingCursors(name: string, resizeCursor: boolean): Promise<void> {
    return new Promise(resolve => {
        const positionAndSize: IPosition & ISize = { x: 16, y: 16, width: 32, height: 32 };
        for (let i = 0; i < 360; i += ROTATION_ICON_MULTIPLE) {
            const styleElement = document.createElement('style');
            let curorsUrl = 'data:image/svg+xml;base64,';
            if (resizeCursor) {
                curorsUrl += btoa(createSvgResizeCursor(i));
            } else {
                curorsUrl += btoa(createSvgRotateCursor(i));
            }

            styleElement.innerHTML = `.${name}-${i} {
                cursor: url("${curorsUrl}") ${positionAndSize.x} ${positionAndSize.y}, auto !important;
            }`;

            document.head.appendChild(styleElement);
        }

        resolve();
    });
}

export function createCursor(name: string, cursor: string): Promise<void> {
    return new Promise(resolve => {
        const positionAndSize: IPosition & ISize = { x: 16, y: 16, width: 32, height: 32 };
        const styleElement = document.createElement('style');
        const curorsUrl = `data:image/svg+xml;base64,${btoa(cursor)}`;

        styleElement.innerHTML = `.${name}-${0} {
                cursor: url("${curorsUrl}") ${positionAndSize.x} ${positionAndSize.y}, auto !important;
            }`;

        document.head.appendChild(styleElement);

        resolve();
    });
}

export const CURSOR_SELECTION_LOCKED = `
<svg width="32px" height="32px" viewBox="16 16 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 55.2 (78181) - https://sketchapp.com -->
    <title>cursor-cursor / Selection locked</title>
    <desc>Created with Sketch.</desc>
    <g id="cursor-/-Selection-locked" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group" transform="translate(31.000000, 30.997995)" fill-rule="nonzero">
            <path d="M1.70710678,14.4370336 C1.07714192,15.0669985 -1.49213975e-13,14.6208317 -1.49213975e-13,13.7299268 L-1.49213975e-13,1.00200475 C-1.49213975e-13,0.111099904 1.07714192,-0.335066888 1.70710678,0.294897973 L10.7071068,9.29489797 C11.3370716,9.92486283 10.8909049,11.0020048 10,11.0020048 L5.14213562,11.0020048 L1.70710678,14.4370336 Z" id="Path" fill-opacity="0.3" fill="#FFFFFF"></path>
            <polygon id="Path" fill="#000000" points="4.72792206 10.0020048 10 10.0020048 1 1.00200475 1 13.7299268"></polygon>
        </g>
        <g id="Group-2" transform="translate(39.000000, 18.000000)" fill-rule="nonzero">
            <path d="M8,5 L8,8 C8,9.1045695 7.1045695,10 6,10 L2,10 C0.8954305,10 0,9.1045695 0,8 L0,5 C0,4.25971764 0.40219863,3.61337381 1,3.26756439 L1,3 C1,1.34314575 2.34314575,0 4,0 C5.65685425,0 7,1.34314575 7,3 L7,3.26756439 C7.59780137,3.61337381 8,4.25971764 8,5 Z" id="Path" fill-opacity="0.3" fill="#FFFFFF"></path>
            <path d="M6,4 C6.55228475,4 7,4.44771525 7,5 L7,8 C7,8.55228475 6.55228475,9 6,9 L2,9 C1.44771525,9 1,8.55228475 1,8 L1,5 C1,4.44771525 1.44771525,4 2,4 L2,3 C2,1.8954305 2.8954305,1 4,1 C5.1045695,1 6,1.8954305 6,3 L6,4 Z M5,4 L5,3 C5,2.44771525 4.55228475,2 4,2 C3.44771525,2 3,2.44771525 3,3 L3,4 L5,4 Z" id="Mask-Copy-3" fill="#000000"></path>
        </g>
    </g>
</svg>
`;

export const CURSOR_SELECTION = `
<svg width="32px" height="32px" viewBox="16 16 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 55.2 (78181) - https://sketchapp.com -->
    <title>cursor-cursor / Selection</title>
    <desc>Created with Sketch.</desc>
    <g id="cursor-/-Selection" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group" transform="translate(31.000000, 30.997995)" fill-rule="nonzero">
            <path d="M1.70710678,14.4370336 C1.07714192,15.0669985 -1.49213975e-13,14.6208317 -1.49213975e-13,13.7299268 L-1.49213975e-13,1.00200475 C-1.49213975e-13,0.111099904 1.07714192,-0.335066888 1.70710678,0.294897973 L10.7071068,9.29489797 C11.3370716,9.92486283 10.8909049,11.0020048 10,11.0020048 L5.14213562,11.0020048 L1.70710678,14.4370336 Z" id="Path" fill-opacity="0.3" fill="#FFFFFF"></path>
            <polygon id="Path" fill="#000000" points="4.72792206 10.0020048 10 10.0020048 1 1.00200475 1 13.7299268"></polygon>
        </g>
    </g>
</svg>
`;

export const CURSOR_BUTTON = `
<svg width="36px" height="32px" viewBox="16 16 36 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 51.3 (57544) - http://www.bohemiancoding.com/sketch -->
    <title>Icon / Cursor / Button</title>
    <desc>Created with Sketch.</desc>
    <defs>
        <path d="M24,9 L24,8 C24.5522847,8 25,7.55228475 25,7 L25,3 C25,2.44771525 24.5522847,2 24,2 L14,2 C13.4477153,2 13,2.44771525 13,3 L13,7 C13,7.55228475 13.4477153,8 14,8 L16,8 L16,9 L14,9 C12.8954305,9 12,8.1045695 12,7 L12,3 C12,1.8954305 12.8954305,1 14,1 L24,1 C25.1045695,1 26,1.8954305 26,3 L26,7 C26,8.1045695 25.1045695,9 24,9 Z M18,5 L23,10 L18,12 L18,5 Z" id="path-1"></path>
    </defs>
    <g id="Cursor-/-Button" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group" transform="translate(26.000000, 17.000000)">
            <g id="Group-2" transform="translate(0.000000, 9.000000)" fill-rule="nonzero">
                <path d="M8,5 L12,5 C12.5522847,5 13,5.44771525 13,6 L13,7 C13,7.55228475 12.5522847,8 12,8 L8,8 L8,12 C8,12.5522847 7.55228475,13 7,13 L6,13 C5.44771525,13 5,12.5522847 5,12 L5,8 L1,8 C0.44771525,8 0,7.55228475 0,7 L0,6 C0,5.44771525 0.44771525,5 1,5 L5,5 L5,1 C5,0.44771525 5.44771525,0 6,0 L7,0 C7.55228475,0 8,0.44771525 8,1 L8,5 Z" id="Path" fill-opacity="0.3" fill="#FFFFFF"></path>
                <polygon id="Path" fill="#000000" points="7 6 7 1 6 1 6 6 1 6 1 7 6 7 6 12 7 12 7 7 12 7 12 6"></polygon>
            </g>
            <path d="M24,10 C24,10.2559223 23.9023689,10.5118446 23.7071068,10.7071068 C23.6113456,10.802868 23.4971313,10.8781804 23.3713907,10.9284767 L18.3713907,12.9284767 C17.8586072,13.1335901 17.2766367,12.8841742 17.0715233,12.3713907 C17.0242752,12.2532704 17,12.1272194 17,12 L17,10 L14,10 C12.3431458,10 11,8.65685425 11,7 L11,3 C11,1.34314575 12.3431458,0 14,0 L24,0 C25.6568542,0 27,1.34314575 27,3 L27,7 C27,8.65685425 25.6568542,10 24,10 Z" id="Combined-Shape" fill="#FFFFFF" fill-rule="nonzero" opacity="0.300000012"></path>
            <mask id="mask-2" fill="white">
                <use xlink:href="#path-1"></use>
            </mask>
            <use id="Combined-Shape" fill="#000000" xlink:href="#path-1"></use>
        </g>
    </g>
</svg>
`;

export const CURSOR_ELLIPSE = `
<svg width="34px" height="32px" viewBox="16 16 34 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 51.3 (57544) - http://www.bohemiancoding.com/sketch -->
    <title>Icon / Cursor / Oval</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Icon-&amp;-Cursor" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Icon-/-Cursor-/-Oval">
            <path d="M43.5,28 C39.9101491,28 37,25.0898509 37,21.5 C37,17.9101491 39.9101491,15 43.5,15 C47.0898509,15 50,17.9101491 50,21.5 C50,25.0898509 47.0898509,28 43.5,28 Z" id="Path" fill-opacity="0.3" fill="#FFFFFF" fill-rule="nonzero"></path>
            <path d="M43.5,26 C45.9852814,26 48,23.9852814 48,21.5 C48,19.0147186 45.9852814,17 43.5,17 C41.0147186,17 39,19.0147186 39,21.5 C39,23.9852814 41.0147186,26 43.5,26 Z M43.5,27 C40.4624339,27 38,24.5375661 38,21.5 C38,18.4624339 40.4624339,16 43.5,16 C46.5375661,16 49,18.4624339 49,21.5 C49,24.5375661 46.5375661,27 43.5,27 Z" id="Oval-3" fill="#000000"></path>
            <g id="Group-2" transform="translate(26.000000, 26.000000)" fill-rule="nonzero">
                <path d="M8,5 L12,5 C12.5522847,5 13,5.44771525 13,6 L13,7 C13,7.55228475 12.5522847,8 12,8 L8,8 L8,12 C8,12.5522847 7.55228475,13 7,13 L6,13 C5.44771525,13 5,12.5522847 5,12 L5,8 L1,8 C0.44771525,8 0,7.55228475 0,7 L0,6 C0,5.44771525 0.44771525,5 1,5 L5,5 L5,1 C5,0.44771525 5.44771525,0 6,0 L7,0 C7.55228475,0 8,0.44771525 8,1 L8,5 Z" id="Path" fill-opacity="0.3" fill="#FFFFFF"></path>
                <polygon id="Path" fill="#000000" points="7 6 7 1 6 1 6 6 1 6 1 7 6 7 6 12 7 12 7 7 12 7 12 6"></polygon>
            </g>
        </g>
    </g>
</svg>
`;

export const CURSOR_RECT = `
<svg width="34px" height="32px" viewBox="16 16 34 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 51.3 (57544) - http://www.bohemiancoding.com/sketch -->
    <title>Icon / Cursor / Rectangle</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Icon-&amp;-Cursor" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Icon-/-Cursor-/-Rectangle">
            <g id="Group-2" transform="translate(26.000000, 26.000000)" fill-rule="nonzero">
                <path d="M8,5 L12,5 C12.5522847,5 13,5.44771525 13,6 L13,7 C13,7.55228475 12.5522847,8 12,8 L8,8 L8,12 C8,12.5522847 7.55228475,13 7,13 L6,13 C5.44771525,13 5,12.5522847 5,12 L5,8 L1,8 C0.44771525,8 0,7.55228475 0,7 L0,6 C0,5.44771525 0.44771525,5 1,5 L5,5 L5,1 C5,0.44771525 5.44771525,0 6,0 L7,0 C7.55228475,0 8,0.44771525 8,1 L8,5 Z" id="Path" fill-opacity="0.3" fill="#FFFFFF"></path>
                <polygon id="Path" fill="#000000" points="7 6 7 1 6 1 6 6 1 6 1 7 6 7 6 12 7 12 7 7 12 7 12 6"></polygon>
            </g>
            <rect id="Rectangle-7" fill-opacity="0.3" fill="#FFFFFF" fill-rule="nonzero" x="37" y="15" width="13" height="13" rx="1"></rect>
            <path d="M38,27 L49,27 L49,16 L38,16 L38,27 Z M39,26 L48,26 L48,17 L39,17 L39,26 Z" id="Icon---Rectangle" fill="#000000"></path>
        </g>
    </g>
</svg>
`;

export const CURSOR_TEXT = `
<svg width="32px" height="32px" viewBox="16 16 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 51.3 (57544) - http://www.bohemiancoding.com/sketch -->
    <title>Icon / Cursor / Text</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Icon-&amp;-Cursor" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Icon-/-Cursor-/-Text" fill-rule="nonzero">
            <path d="M31,34 C30.4477153,34 30,33.5522847 30,33 L30,32 C30,31.4477153 30.4477153,31 31,31 L31,26.7324356 C30.696372,26.5567963 30.4432037,26.303628 30.2675644,26 L29,26 C28.4477153,26 28,25.5522847 28,25 L28,24 C28,23.4477153 28.4477153,23 29,23 L31,23 C31.5973463,23 32.1335278,23.2618774 32.5,23.6770878 C32.8664722,23.2618774 33.4026537,23 34,23 L36,23 C36.5522847,23 37,23.4477153 37,24 L37,25 C37,25.5522847 36.5522847,26 36,26 L34.7324356,26 C34.5567963,26.303628 34.303628,26.5567963 34,26.7324356 L34,31 C34.5522847,31 35,31.4477153 35,32 L35,33 C35,33.5522847 34.5522847,34 34,34 L34,38.2675644 C34.303628,38.4432037 34.5567963,38.696372 34.7324356,39 L36,39 C36.5522847,39 37,39.4477153 37,40 L37,41 C37,41.5522847 36.5522847,42 36,42 L34,42 C33.4026537,42 32.8664722,41.7381226 32.5,41.3229122 C32.1335278,41.7381226 31.5973463,42 31,42 L29,42 C28.4477153,42 28,41.5522847 28,41 L28,40 C28,39.4477153 28.4477153,39 29,39 L30.2675644,39 C30.4432037,38.696372 30.696372,38.4432037 31,38.2675644 L31,34 Z" id="Path" fill="#FFFFFF" opacity="0.300000012"></path>
            <path d="M32,39 C31.4477153,39 31,39.4477153 31,40 L29,40 L29,41 L31,41 C31.5522847,41 32,40.5522847 32,40 L33,40 C33,40.5522847 33.4477153,41 34,41 L36,41 L36,40 L34,40 C34,39.4477153 33.5522847,39 33,39 L33,33 L34,33 L34,32 L33,32 L33,26 C33.5522847,26 34,25.5522847 34,25 L36,25 L36,24 L34,24 C33.4477153,24 33,24.4477153 33,25 L32,25 C32,24.4477153 31.5522847,24 31,24 L29,24 L29,25 L31,25 C31,25.5522847 31.4477153,26 32,26 L32,32 L31,32 L31,33 L32,33 L32,39 Z" id="fill" fill="#000000"></path>
        </g>
    </g>
</svg>`;

export const CURSOR_IMAGE = `
<svg width="36px" height="32px" viewBox="16 16 36 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 51.3 (57544) - http://www.bohemiancoding.com/sketch -->
    <title>cursor-Cursor / Image</title>
    <desc>Created with Sketch.</desc>
    <defs>
        <path d="M38,25.4285714 L38,17 L49,17 L49,27 L38,27 L38,25.4285714 Z M46.1428571,26 L48,26 L48,18 L39,18 L39,23.8571429 L40.5,21.5 L42.7217391,24.9913043 L44,23.5 L46.1428571,26 Z M45,22 C44.4477153,22 44,21.5522847 44,21 C44,20.4477153 44.4477153,20 45,20 C45.5522847,20 46,20.4477153 46,21 C46,21.5522847 45.5522847,22 45,22 Z" id="path-1"></path>
    </defs>
    <g id="Cursor-/-Image" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group-2" transform="translate(26.000000, 26.000000)" fill-rule="nonzero">
            <path d="M8,5 L12,5 C12.5522847,5 13,5.44771525 13,6 L13,7 C13,7.55228475 12.5522847,8 12,8 L8,8 L8,12 C8,12.5522847 7.55228475,13 7,13 L6,13 C5.44771525,13 5,12.5522847 5,12 L5,8 L1,8 C0.44771525,8 0,7.55228475 0,7 L0,6 C0,5.44771525 0.44771525,5 1,5 L5,5 L5,1 C5,0.44771525 5.44771525,0 6,0 L7,0 C7.55228475,0 8,0.44771525 8,1 L8,5 Z" id="Path" fill-opacity="0.3" fill="#FFFFFF"></path>
            <polygon id="Path" fill="#000000" points="7 6 7 1 6 1 6 6 1 6 1 7 6 7 6 12 7 12 7 7 12 7 12 6"></polygon>
        </g>
        <rect id="Rectangle-7" fill-opacity="0.3" fill="#FFFFFF" fill-rule="nonzero" x="37" y="16" width="13" height="12" rx="1"></rect>
        <mask id="mask-2" fill="white">
            <use xlink:href="#path-1"></use>
        </mask>
        <use id="Mask" fill="#000000" fill-rule="nonzero" xlink:href="#path-1"></use>
    </g>
</svg>
`;

export const CURSOR_IMAGE_POSITION = `
<svg width="32px" height="32px" viewBox="16 16 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 58 (84663) - https://sketch.com -->
    <title>cursor-cursor / Move</title>
    <desc>Created with Sketch.</desc>
    <defs>
        <path d="M24.2928932,31.2928932 L31.2928932,24.2928932 C31.6834175,23.9023689 32.3165825,23.9023689 32.7071068,24.2928932 L39.7071068,31.2928932 C40.0976311,31.6834175 40.0976311,32.3165825 39.7071068,32.7071068 L32.7071068,39.7071068 C32.3165825,40.0976311 31.6834175,40.0976311 31.2928932,39.7071068 L24.2928932,32.7071068 C23.9023689,32.3165825 23.9023689,31.6834175 24.2928932,31.2928932 Z" id="path-1"></path>
        <filter x="-37.5%" y="-37.5%" width="175.0%" height="175.0%" filterUnits="objectBoundingBox" id="filter-2">
            <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
    </defs>
    <g id="cursor-/-Move" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group">
            <g id="Path-14" opacity="0.5" fill-rule="nonzero">
                <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"></use>
                <use fill="#FFFFFF" xlink:href="#path-1"></use>
            </g>
            <path d="M32,24.9289322 L35.0709322,27.9999322 L33,28 L33,31 L36,31 L35.9999322,28.9289322 L39.0710678,32 L35.9999322,35.0709322 L36,33 L33,33 L33,36 L35.0709322,35.9999322 L32,39.0710678 L28.9289322,35.9999322 L31,36 L31,33 L28,33 L27.9999322,35.0709322 L24.9289322,32 L27.9999322,28.9289322 L28,31 L31,31 L31,28 L28.9289322,27.9999322 L32,24.9289322 Z" id="Combined-Shape" fill="#000000" fill-rule="nonzero"></path>
            <rect id="Rectangle" x="0" y="0" width="64" height="64"></rect>
        </g>
    </g>
</svg>
`;

let currentCursor: string | undefined = 'default';
/**
 * Sets a mouse cursor on the given element.
 *
 * NOTE: The mouse cursor does not update immeditately while devtools is open.
 */
export function setCursorOnElement(domElement: HTMLElement, cursor?: string): void {
    if (currentCursor === cursor) {
        return;
    }
    const className: string = domElement.className;
    // domElement.style.cursor = 'unset';
    for (const name of className.split(' ')) {
        if (
            name.startsWith('rotation') ||
            name.startsWith('resize') ||
            name.startsWith('create-element') ||
            name.startsWith('selection') ||
            name.startsWith('image-position')
        ) {
            domElement.classList.remove(name);
        }
    }

    if (cursor) {
        if (
            cursor.startsWith('rotation') ||
            cursor.startsWith('resize') ||
            cursor.startsWith('create-element') ||
            cursor.startsWith('selection') ||
            cursor.startsWith('image-position')
        ) {
            domElement.classList.add(cursor);
        } else {
            domElement.style.cursor = cursor;
        }
    }
    currentCursor = cursor;
}

export function createSvgResizeCursor(rotation: number): string {
    return `
        <svg width="32px" height="32px" viewBox="16 16 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <defs>
                <path d="M34.9411663,36.1432478 L27.8667619,29.0688434 L26,30.9356053 L26.0033366,26.0033366 L30.9356053,26 L29.0688434,27.8667619 L36.1432478,34.9411663 L38.0100097,33.0744044 L38.0066731,38.0066731 L33.0744044,38.0100097 L34.9411663,36.1432478 Z" id="path-1"></path>
                <filter x="-58.3%" y="-58.3%" width="216.6%" height="216.6%" filterUnits="objectBoundingBox" id="filter-2">
                    <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"></feMorphology>
                    <feOffset dx="0" dy="0" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
                    <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                    <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"></feComposite>
                    <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
                </filter>
            </defs>
            <g transform="rotate(${-rotation}, 32, 32)">
                <g id="Icon-/-Cursor-/-Resize" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linejoin="round">
                    <g id="Combined-Shape" fill-rule="nonzero" transform="translate(32.005005, 32.005005) rotate(-45.000000) translate(-32.005005, -32.005005) ">
                        <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"></use>
                        <path stroke="#FFFFFF" stroke-width="1" d="M34.2340595,36.1432478 L27.8667619,29.7759502 L25.4991828,32.1435294 L25.5036745,25.5036745 L32.1435294,25.4991828 L29.7759502,27.8667619 L36.1432478,34.2340595 L38.510827,31.8664804 L38.5063352,38.5063352 L31.8664804,38.510827 L34.2340595,36.1432478 Z" fill="#363636" fill-rule="evenodd"></path>
                    </g>
                </g>
            </g>
        </svg>
    `;
}

export function createSvgRotateCursor(rotation: number): string {
    return `
        <svg width="32px" height="32px" viewBox="16 16 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <title>Icon / Cursor / Rotate</title>
            <desc>Created with Sketch.</desc>
            <defs>
                <path d="M15.9895924,8.98959236 L14,7 L19,7 L19,12 L17.0502525,10.0502525 C15.7835017,11.3170034 15,13.0670034 15,15 C15,16.9329966 15.7835017,18.6829966 17.0502525,19.9497475 L19,18 L19,23 L14,23 L15.9895924,21.0104076 C14.4513949,19.4722102 13.5,17.3472102 13.5,15 C13.5,12.6527898 14.4513949,10.5277898 15.9895924,8.98959236 Z" id="path-1"></path>
                <filter x="-127.3%" y="-43.7%" width="354.5%" height="187.5%" filterUnits="objectBoundingBox" id="filter-2">
                    <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"></feMorphology>
                    <feOffset dx="0" dy="0" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
                    <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                    <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"></feComposite>
                    <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
                </filter>
            </defs>
            <g transform="rotate(${-rotation}, 32, 32)">
                <g id="Icon-/-Cursor-/-Rotate" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Group" transform="translate(33.000000, 32.000000) scale(-1, 1) translate(-33.000000, -32.000000) translate(18.000000, 17.000000)" fill-rule="nonzero">
                        <g id="Combined-Shape" stroke-linejoin="round">
                            <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"></use>
                            <path stroke="#FFFFFF" stroke-width="1" d="M15.2922095,8.99931628 L12.7928932,6.5 L19.5,6.5 L19.5,13.2071068 L17.0637144,10.7708212 C16.0636671,11.9362068 15.5,13.4229627 15.5,15 C15.5,16.5770373 16.0636671,18.0637932 17.0637144,19.2291788 L19.5,16.7928932 L19.5,23.5 L12.7928932,23.5 L15.2922095,21.0006837 C13.8289161,19.3667606 13,17.2484638 13,15 C13,12.7515362 13.8289161,10.6332394 15.2922095,8.99931628 Z" fill="#363636" fill-rule="evenodd"></path>
                        </g>
                        <rect id="Rectangle-16" x="0" y="0" width="30" height="30"></rect>
                    </g>
                </g>
            </g>
        </svg>
    `;
}
