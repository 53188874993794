<div class="buttons__wrapper">
    <ui-button
        data-test-id="previous-element-button"
        type="default"
        text="Previous"
        svgIcon="arrow-left"
        [disabled]="(canSelectPrevious$ | async) === false"
        (click)="onSelectPreviousClicked()"></ui-button>
    <ui-button
        data-test-id="next-element-button"
        type="default"
        text="Next"
        trailingIcon="arrow-right"
        [disabled]="(canSelectNext$ | async) === false"
        (click)="onSelectNextClicked()"></ui-button>
</div>
