import { IFeedStore } from '@domain/creative/feed/feed-store.header';
import { Container } from '@studio/utils/di';
import { IAnimator } from './animator.header';
import { ICreativeWrapper } from './creative-wrapper.header';
import { IRendererFactory, T } from './creative.container';
import { IRendererOptions } from './renderer';
import { IRenderer } from './renderer.header';

export class CreativeWrapper implements ICreativeWrapper {
    renderer_m: IRenderer;
    animator_m: IAnimator;
    feedStore_m: IFeedStore;

    constructor(options: IRendererOptions, container: Container<T>) {
        const createRenderer = container.resolve<IRendererFactory>(T.RENDERER_FACTORY);
        this.renderer_m = createRenderer(options);
        this.animator_m = container.resolve(T.ANIMATOR);
        this.feedStore_m = this.renderer_m.feedStore!;
    }
}
