import { IElement } from '@domain/creativeset/element';
import {
    CaptureEventSeverity,
    ConsoleEventLevel,
    StudioActivityEvent,
    StudioCaptureEvent,
    StudioConsoleEvent,
    StudioTrackerEvent,
    StudioUniqueEvent
} from '@studio/domain/monitoring/event-logger.interface';

type ErrorData = {
    originalError: Error;
};

export class SaveCreativeLogEvent implements StudioCaptureEvent, StudioConsoleEvent {
    capture = true;
    message = 'Save Creative Log';
    description = 'Saving changes';
    log = true;
    severity: CaptureEventSeverity.Log;
    level = ConsoleEventLevel.Log;
}

export class SaveCreativeEvent implements StudioTrackerEvent, StudioConsoleEvent {
    message = 'Save Creative';
    description = 'Saving changes';
    track = true;
    start = true;
    log = true;
    level = ConsoleEventLevel.Log;
}

export class SaveCreativeEndEvent implements StudioTrackerEvent {
    message = 'Save Creative';
    description = 'Saved successfully';
    track = true;
    start = false;
}

export class PublishChangesEvent implements StudioTrackerEvent, StudioConsoleEvent {
    message = 'Publish Changes';
    description = 'Publish Changes started';
    track = true;
    start = true;
    log = true;
    level = ConsoleEventLevel.Log;
}

export class PublishChangesEndEvent implements StudioTrackerEvent {
    message = 'Publish Changes';
    description = 'Publish Changes finished';
    track = true;
    start = false;
}

export class WeightCalculationEvent implements StudioTrackerEvent {
    message = 'Weight Calculation';
    description = 'Weight Calculation started';
    track = true;
    start = true;
}

export class WeightCalculationEndEvent implements StudioTrackerEvent {
    message = 'Weight Calculation';
    description = 'Weight Calculation finished';
    track = true;
    start = false;
}

export class AddSizesEvent implements StudioTrackerEvent, StudioConsoleEvent {
    message = 'Add Sizes';
    description: string;
    track = true;
    data: { newSizes: number };
    log = true;
    level = ConsoleEventLevel.Log;

    constructor(sizes: number) {
        this.description = `Adding ${sizes} new sizes`;
        this.data = { newSizes: sizes };
    }
}

export class HistoryUndoEvent implements StudioActivityEvent, StudioConsoleEvent {
    message = 'History Undo';
    description = 'Undoing';
    activity = true;
    log = true;
    level = ConsoleEventLevel.Verbose;
}

export class HistoryRedoEvent implements StudioActivityEvent, StudioConsoleEvent {
    message = 'History Redo';
    description = 'Redoing';
    activity = true;
    log = true;
    level = ConsoleEventLevel.Verbose;
}

export class AssetUploadEvent implements StudioActivityEvent, StudioTrackerEvent, StudioConsoleEvent {
    message = 'Asset Upload';
    description = 'Uploading assets to brand library';
    activity = true;
    log = true;
    level = ConsoleEventLevel.Verbose;
    track = true;
}

export class AssetUploadCompleteEvent implements StudioActivityEvent, StudioConsoleEvent {
    message = 'Asset Uploaded';
    description = 'Asset upload completed';
    activity = true;
    log = true;
    level = ConsoleEventLevel.Verbose;
}

export class ImagePropertyChangeEvent implements StudioActivityEvent, StudioConsoleEvent {
    message = 'Image Property Change';
    description: string;
    activity = true;
    log = true;
    level = ConsoleEventLevel.Verbose;
    data: any;

    constructor(propertyName: string, oldValue: any, newValue: any) {
        this.description = `Image property[${propertyName}] changed`;
        this.data = {
            from: oldValue,
            to: newValue
        };
    }
}

export class VideoPropertyChangeEvent implements StudioActivityEvent, StudioConsoleEvent {
    message = 'Video Property Change';
    description: string;
    activity = true;
    log = true;
    level = ConsoleEventLevel.Verbose;
    data: any;

    constructor(propertyName: string, oldValue: any, newValue: any) {
        this.description = `Video property[${propertyName}] changed`;
        this.data = {
            from: oldValue,
            to: newValue
        };
    }
}

export class TranslationPanelSaveEvent implements StudioTrackerEvent, StudioConsoleEvent {
    message = 'Translation Save';
    description = 'Saving translations';
    track = true;
    data: { showcase: boolean };
    log = true;
    level = ConsoleEventLevel.Verbose;

    constructor(inShowcase = false) {
        this.data = { showcase: inShowcase };
    }
}

export class TranslationPanelDoneEvent implements StudioTrackerEvent, StudioConsoleEvent {
    message = 'Translation Done';
    description = 'Marking the translations as done';
    track = true;
    log = true;
    level = ConsoleEventLevel.Verbose;
}

export class TranslationPanelDoneErrorEvent implements StudioCaptureEvent, StudioConsoleEvent {
    message = 'Failed to mark showcase as done';
    description = 'Failed to mark showcase as done';
    capture = true;
    severity = CaptureEventSeverity.Error;
    data: ErrorData;
    log = true;
    level = ConsoleEventLevel.Error;

    constructor(public exception: Error) {
        this.data = { originalError: exception };
    }
}

export class StoreSnapshotError implements StudioCaptureEvent, StudioConsoleEvent {
    message = 'Could not store copied snapshot';
    description = 'Failed storing the copied snapshot to the localStorage';
    capture = true;
    severity = CaptureEventSeverity.Error;
    data: ErrorData;
    log = true;
    level = ConsoleEventLevel.Error;

    constructor(public exception: Error) {
        this.data = { originalError: exception };
    }
}

export class InvalidDesignEvent implements StudioTrackerEvent, StudioConsoleEvent {
    message = 'Invalid design encountered!';
    description: string;
    track = true;
    data: { validation: any };
    log = true;
    level = ConsoleEventLevel.Error;

    constructor(errorMessage: string, validation: any) {
        this.description = errorMessage;
        this.data = { validation };
    }
}

export class CommentsInitErrorEvent implements StudioCaptureEvent, StudioConsoleEvent {
    message = 'Failed to initialize CommentService';
    description = 'Failed to initialize CommentService';
    capture = true;
    severity = CaptureEventSeverity.Error;
    data: ErrorData;
    log = true;
    level = ConsoleEventLevel.Error;

    constructor(public exception: Error) {
        this.data = { originalError: exception };
    }
}

export class FontValidationFailEvent implements StudioTrackerEvent, StudioConsoleEvent {
    message = 'Font validation failed';
    description: string;
    data: any;
    log = true;
    level = ConsoleEventLevel.Warning;
    track = true;

    constructor(public _data: any) {
        this.data = _data;
        this.description = `Font doesn't support these characters: ${_data.missingCharacters?.join(
            ', '
        )}`;
    }
}

type VersionValidationErrorData = {
    versionId: string;
    versionPropertyId?: string;
    description?: string;
};

export class VersionValidationFailEvent implements StudioTrackerEvent, StudioConsoleEvent {
    message = 'Version validation error';
    description: string;
    data: VersionValidationErrorData;
    log = true;
    level = ConsoleEventLevel.Warning;
    track = true;

    constructor(description: string, data: VersionValidationErrorData) {
        this.data = data;
        this.description = description;
    }
}

export class OrphanElementFoundEvent implements StudioTrackerEvent, StudioUniqueEvent {
    message = 'Orphan element encountered';
    description: string;
    track = true;
    unique = true;
    data: { creativeSetId: string };

    constructor(creativeSetId: string) {
        this.description = `Orphan element encountered on creative set with ID: ${creativeSetId}`;
        this.data = { creativeSetId };
    }

    uniqueIdentifier = (): string => {
        // Since we want to keep track only once for each creativeset
        return this.data.creativeSetId;
    };
}

export class FontNotFoundError implements StudioCaptureEvent, StudioConsoleEvent {
    message = 'Could not find font or font style';
    description: string;
    capture = true;
    severity = CaptureEventSeverity.Error;
    data: any;
    log = true;
    level = ConsoleEventLevel.Error;

    constructor(public _data: { fontFamilyId?: string; fontStyleId?: string }) {
        this.data = _data;
        const id = _data.fontFamilyId || _data.fontStyleId;
        this.description = `Could not find ${_data.fontFamilyId ? 'font' : 'font style'} with ID ${id}`;
    }
}

export class PSDImportError implements StudioCaptureEvent, StudioConsoleEvent {
    message = 'PSD Import Error';
    description: string;
    capture = true;
    severity = CaptureEventSeverity.Warning;
    log = true;
    level = ConsoleEventLevel.Error;
    data: { error: { Type: string; Description: string } };
    constructor(errorType: string, description: string) {
        this.description = `${errorType}: ${description}`;
        this.data = { error: { Type: errorType, Description: description } };
    }
}

export class PSDImportConversionEvent implements StudioTrackerEvent, StudioConsoleEvent {
    message = 'PSD Import Conversion';
    description: string;
    track = true;
    log = true;
    level = ConsoleEventLevel.Verbose;
    data: { fileSize: number; amountOfLayers: number; duration: number };

    constructor(fileSize: number, amountOfLayers: number, duration: number) {
        this.description = `PSD file imported and converted ${amountOfLayers} layers successfully in ${duration}ms`;
        this.data = { fileSize, amountOfLayers, duration };
    }
}

export class RelationValidationEvent implements StudioCaptureEvent, StudioConsoleEvent {
    description: string;
    log = true;
    message = 'Relation Validation Error';
    level = ConsoleEventLevel.Error;
    data: { elementId: string; versionPropertyId?: string };
    capture = true;
    severity = CaptureEventSeverity.Error;

    constructor(description: string, data: RelationValidationEvent['data']) {
        this.description = description;
        this.data = data;
    }
}

export class ElementPatchedEvent implements StudioCaptureEvent, StudioConsoleEvent {
    message = 'Global elements were patched';
    log = true;
    level = ConsoleEventLevel.Warning;
    capture = true;
    severity = CaptureEventSeverity.Error;
    data: { patchedElements: IElement[] };
    description: string;

    constructor(patchedElements: IElement[]) {
        this.data = {
            patchedElements
        };
        this.description = `Global elements were patched: ${patchedElements.length}`;
    }
}
