import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { pipe } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { USER_FEATURE_KEY, UserState } from './user.reducer';

export const getUserState = createFeatureSelector<UserState>(USER_FEATURE_KEY);

export const getUserWhenLoaded = pipe(
    select(getUserState),
    filter(state => state.loaded)
);

export const getUserLoaded = createSelector(getUserState, (state: UserState) => state?.loaded);

export const getUserError = createSelector(getUserState, (state: UserState) => state?.error);

export const getIsEmployee = createSelector(getUserState, (state: UserState) => state?.isEmployee);

export const getIsEmployeeWhenLoaded = pipe(
    getUserWhenLoaded,
    map(state => state.isEmployee)
);

export const getRole = createSelector(getUserState, (state: UserState) => state?.role);

export const getPermissions = createSelector(getUserState, (state: UserState) => state?.permissions);

export const getPermissionsWhenLoaded = pipe(
    getUserWhenLoaded,
    map(state => state.permissions)
);
