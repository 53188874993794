<ng-container *ngIf="showOverlay$ | async">
    <ng-container
        *ngIf="selectedPlacement$ | async as selectedPlacement"
        [ngSwitch]="(selectedNetwork$ | async)?.type">
        <meta-overlay
            *ngSwitchCase="SocialNetworkType.Meta"
            [selectedPlacement]="selectedPlacement">
        </meta-overlay>
        <tiktok-overlay
            *ngSwitchCase="SocialNetworkType.TikTok"
            [selectedPlacement]="selectedPlacement">
        </tiktok-overlay>
        <pinterest-overlay
            *ngSwitchCase="SocialNetworkType.Pinterest"
            [selectedPlacement]="selectedPlacement">
        </pinterest-overlay>
        <snapchat-overlay
            *ngSwitchCase="SocialNetworkType.Snapchat"
            [selectedPlacement]="selectedPlacement">
        </snapchat-overlay>
    </ng-container>
</ng-container>
