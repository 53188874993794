import { IFeed } from '@domain/feed';
import { cloneDeep } from '@studio/utils/clone';

export function validateAndFixVersionedFeed(versionedFeed: IFeed): IFeed {
    let copyOfVersionedText = cloneDeep(versionedFeed);

    const rules: Array<(versionedFeed: IFeed) => IFeed> = [
        fixVersionedFeedShouldHaveFeedTypeFeedRule,
        fixVisibleFeedStepShouldNotBeSetOnVersionPropertyFeedRule
    ];

    for (const rule of rules) {
        copyOfVersionedText = rule(copyOfVersionedText);
    }
    return copyOfVersionedText;
}

export function fixVersionedFeedShouldHaveFeedTypeFeedRule(versionedFeed: IFeed): IFeed {
    if (versionedFeed.type !== 'text') {
        versionedFeed.type = 'text';
    }

    return versionedFeed;
}

export function fixVisibleFeedStepShouldNotBeSetOnVersionPropertyFeedRule(versionedFeed: IFeed): IFeed {
    if ('visibleFeedStep' in versionedFeed) {
        delete versionedFeed['visibleFeedStep'];
    }

    return versionedFeed;
}
