import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { UIDropdownComponent, UIDropdownTargetDirective } from '@bannerflow/ui';
import { getHotkeysAsKeyValueList } from '@studio/hotkeys';
import { ElementAlign } from '../../../properties-panel/element-align.enum';
import { ContextMenuComponent } from '../context-menu.component';
import { ElementMenuComponent } from '../element-menu/element-menu.component';

@Component({
    selector: 'state-menu',
    templateUrl: './state-menu.component.html',
    styleUrls: ['../context-menu.component.scss']
})
export class StateMenuComponent implements AfterViewInit {
    @ViewChild('menu') menu: UIDropdownComponent;
    @ViewChild('menuTrigger') menuTrigger: UIDropdownTargetDirective;

    keyboardShortcuts = getHotkeysAsKeyValueList(['Editor', 'Workspace', 'Timeline']);
    ElementAlign = ElementAlign;
    elementMenu?: ElementMenuComponent;

    constructor(public contextMenu: ContextMenuComponent) {}

    ngAfterViewInit(): void {
        this.elementMenu = this.contextMenu.elementMenuComponent;
    }
}
