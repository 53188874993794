import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SIZE_ADD_FEATURE_KEY, SizeAddState } from './size-add.reducer';

export const getSizeAddState = createFeatureSelector<SizeAddState>(SIZE_ADD_FEATURE_KEY);

export const getLoaded = createSelector(getSizeAddState, ({ loaded }) => loaded);

export const getError = createSelector(getSizeAddState, ({ error }) => error);

export const getSizes = createSelector(getSizeAddState, ({ sizes }) => sizes);

export const getCollections = createSelector(getSizeAddState, ({ collections }) => collections);

export const getSelectedSizes = createSelector(getSizeAddState, ({ selectedSizes }) => selectedSizes);

export const getSelectedCollections = createSelector(
    getSizeAddState,
    ({ collections, selectedCollectionIds }) =>
        collections.filter(collection => selectedCollectionIds.includes(collection.id))
);

export const getSelectedCollectionsIds = createSelector(
    getSizeAddState,
    ({ selectedCollectionIds }) => selectedCollectionIds
);

export const getFilteredSizes = createSelector(
    getSelectedCollections,
    getSizes,
    (selectedCollections, sizes) => {
        if (selectedCollections.length === 0) {
            return sizes;
        }

        return sizes.filter(({ id }) =>
            selectedCollections.some(({ sizeFormatIds }) => sizeFormatIds?.includes(id))
        );
    }
);
