<div class="custom-size">
    <div class="input-wrapper">
        <ui-number-input
            #widthInput
            data-test-id="custom-width-input"
            id="interaction-custom-width"
            placeholder="Width"
            [arrowButtons]="false"
            [keyboardEmit]="true"
            [min]="0"
            [value]="customWidth()"
            (valueChange)="setWidth($event)"
            (submit)="addCustomSize()"></ui-number-input>
    </div>
    <span class="custom-size__cross">×</span>
    <div class="input-wrapper">
        <ui-number-input
            #heightInput
            data-test-id="custom-height-input"
            id="interaction-custom-height"
            placeholder="Height"
            [arrowButtons]="false"
            [keyboardEmit]="true"
            [min]="0"
            [value]="customHeight()"
            (valueChange)="setHeight($event)"
            (submit)="addCustomSize()"></ui-number-input>
    </div>

    <ui-button
        id="add-button"
        type="discrete"
        icon="plus-skinny-minimal"
        text="ADD"
        [border]="false"
        [disabled]="!customWidth() || !customHeight()"
        (click)="addCustomSize()"></ui-button>
</div>
