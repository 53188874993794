import { z } from 'zod';
import { ImageLibraryAsset } from './image-asset';
import { VideoLibraryAsset } from './video-asset';

export interface Asset {
    url: string;
    width: number;
    height: number;
}

/**
 * @deprecated Use Asset interface instead
 */
export const ZAsset = z.object({
    width: z.number(),
    height: z.number(),
    url: z.string().min(1)
});

export type OneOfLibraryAssets<
    LibraryAsset extends ImageLibraryAsset | VideoLibraryAsset = ImageLibraryAsset | VideoLibraryAsset
> = LibraryAsset;
