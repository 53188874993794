import { EnvironmentProviders, importProvidersFrom } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MediaLibraryEffects } from './media-library.effects';
import { MEDIA_LIBRARY_FEATURE_KEY, reducer } from './media-library.reducer';

export function provideMediaLibraryStore(): EnvironmentProviders {
    return importProvidersFrom(
        StoreModule.forFeature(MEDIA_LIBRARY_FEATURE_KEY, reducer),
        EffectsModule.forFeature(MediaLibraryEffects)
    );
}
