import { Component, OnDestroy } from '@angular/core';
import { CreativesetDataService } from '../../../shared/creativeset/creativeset.data.service';
import { FiltersService } from '../../../shared/filters/state/filters.service';
import { VirtualScrollService } from '../../../shared/services/virtual-scroll.service';
import { CreativeSize } from '@domain/creativeset';
import { ICreative } from '@domain/creativeset/creative';
import { getIntersectionArray, mapSizeIdsToSizes, sortByFormatSize } from '@studio/utils/utils';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { TileSelectService } from '../services/tile-select.service';
import { CommonModule } from '@angular/common';

@Component({
    standalone: true,
    imports: [CommonModule],
    selector: 'size-list-horizontal',
    templateUrl: './size-list-horizontal.component.html',
    styleUrls: ['./size-list-horizontal.component.scss']
})
export class SizeListHorizontalComponent implements OnDestroy {
    sizes$: Observable<CreativeSize[]>;

    selectedSizeId: string;

    private unsubscribe$ = new Subject<void>();

    constructor(
        private creativesetDataService: CreativesetDataService,
        private filtersService: FiltersService,
        private tileSelectService: TileSelectService,
        private virtualScrollService: VirtualScrollService
    ) {
        this.tileSelectService.currentlyViewedSize$
            .pipe(debounceTime(100), takeUntil(this.unsubscribe$))
            .subscribe(sizeId => {
                this.virtualScrollService.updateSelectedItemById(sizeId);
            });

        this.tileSelectService.setCurrentlyViewedSize('');
        this.sizes$ = this.filtersService.selectedSizes$.pipe(
            map(allActiveSizes => {
                const creativesIdsGroupedBySize = this.getCreativesIdsGroupedBySize([
                    ...this.creativesetDataService.creativeset.creatives
                ]);
                const sizesIds = getIntersectionArray(creativesIdsGroupedBySize, allActiveSizes);
                return sortByFormatSize(
                    mapSizeIdsToSizes(sizesIds, [...this.creativesetDataService.creativeset.sizes]),
                    'size'
                );
            })
        );

        this.virtualScrollService.selectedItem$.pipe(takeUntil(this.unsubscribe$)).subscribe(item => {
            this.selectedSizeId = item.id;
            const element = document.getElementById(`h-size-item-${item.id}`);
            element?.scrollIntoView({ behavior: 'auto' });
        });
    }

    getCreativesIdsGroupedBySize(creatives: ICreative[]): string[] {
        const sizeIds = creatives.map(creative => creative.size.id);
        const uniqueSizeIds = [...new Set(sizeIds)];
        return uniqueSizeIds;
    }

    selectSize(size: CreativeSize): void {
        this.virtualScrollService.updateSelectedItemById(size.id);
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
