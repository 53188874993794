import { IBounds } from '@domain/dimension';
import { GroupOrLayoutElement, ILayoutRule, isLayoutElement } from '../rules';
import { ScaleDefault } from './scale-element-properties';

export class ScaleStatePropertiesRule implements ILayoutRule {
    applyRenderRule?(layoutElement: GroupOrLayoutElement, calculatedBounds: IBounds): void {
        if (isLayoutElement(layoutElement)) {
            const { element } = layoutElement;
            const xScale = calculatedBounds.width / element.width;
            const yScale = calculatedBounds.height / element.height;
            const minScale = Math.min(xScale, yScale);

            element.states?.forEach(state => {
                // Note that scaleX and scaleY can stay the same since they relate to width and height which already is scaled

                if (typeof state.x === 'number') {
                    state.x *= xScale;
                }
                if (typeof state.y === 'number') {
                    state.y *= yScale;
                }

                if (state.radius) {
                    state.radius = ScaleDefault.scaleRadius(
                        state.radius,
                        element.width,
                        element.height,
                        calculatedBounds
                    );
                }

                state.shadows = ScaleDefault.scaleShadows(state.shadows, minScale);
            });
        }
    }
}
