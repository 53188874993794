import { EventEmitter, Injectable } from '@angular/core';
import { IBoundingBox, IPosition, ISize } from '@domain/dimension';
import { getCenter } from '@studio/utils/geom';
import { getMousewheelSpeed } from '@studio/utils/mouse-observable';
import { Subject } from 'rxjs';

export type Zoom = { zoom: number };

@Injectable()
export class ZoomControlService {
    zoomControlHovered = new EventEmitter<boolean>();

    private changeZoomSource = new Subject<number>();
    public changeZoomObservable = this.changeZoomSource.asObservable();

    shouldSnap = (zoom: number): boolean =>
        (1 - zoom) * (1 - zoom * 2) < 0 || (1 - zoom) * (1 - zoom / 2) < 0;

    calculateNewCanvasPosition = (
        currentPosition: IPosition,
        targetPosition: IPosition,
        ratio: number,
        { width, height }: ISize
    ): IPosition => ({
        x: Math.round(currentPosition.x + (targetPosition.x - width / 2 - currentPosition.x) * ratio),
        y: Math.round(currentPosition.y + (targetPosition.y - height / 2 - currentPosition.y) * ratio)
    });

    changeZoom(zoom: number): void {
        this.changeZoomSource.next(zoom);
    }

    calculateBoundingBox(
        boundingBox: IBoundingBox,
        workspaceSize: ISize,
        canvasSize: ISize,
        boundingBoxWorkspacePosition: IPosition,
        canvasPosition: IPosition,
        currentZoom: number
    ): IPosition & Zoom {
        const padding = 0.9;
        const zoomFactor = Math.max(
            this.getZoomFactor(padding, workspaceSize, boundingBox, currentZoom),
            0.01
        );
        const workspaceCenter: IPosition = {
            x: workspaceSize.width / 2,
            y: workspaceSize.height / 2
        };

        const canvasCenter = getCenter({ ...canvasPosition, ...canvasSize });
        const boundingBoxCenter = getCenter({
            ...boundingBoxWorkspacePosition,
            ...{ width: boundingBox.width * currentZoom, height: boundingBox.height * currentZoom }
        });

        const canvasToWorkspace = this.getDistance(workspaceCenter, canvasCenter, zoomFactor - 1);
        const workspaceToBoundingBox = this.getDistance(boundingBoxCenter, workspaceCenter, zoomFactor);

        const x = Math.round(canvasPosition.x - canvasToWorkspace.x - workspaceToBoundingBox.x);
        const y = Math.round(canvasPosition.y - canvasToWorkspace.y - workspaceToBoundingBox.y);
        const zoom = zoomFactor * currentZoom;
        return { x, y, zoom };
    }

    calculateZoomToMousePosition(
        event: WheelEvent,
        mousePosition: IPosition,
        { x, y, width, height }: IPosition & ISize,
        currentZoom: number
    ): IPosition & Zoom {
        const wheelSpeed = getMousewheelSpeed(event);

        const speed = wheelSpeed.y - wheelSpeed.y * 2;
        const zoomFactor = 0.05;
        let zoom = Math.min(Math.max(currentZoom + speed * zoomFactor, 0.01), 32);

        if (currentZoom / zoom > 2) {
            zoom = currentZoom / 2;
        }

        const ratio = 1 - zoom / currentZoom;
        return {
            ...this.calculateNewCanvasPosition({ x, y }, mousePosition, ratio, { width, height }),
            zoom
        };
    }

    calculateZoomToCenter(
        zoom: number,
        mousePosition: IPosition,
        canvasPositionAndSize: IPosition & ISize,
        currentZoom: number
    ): IPosition & Zoom {
        const newZoom = Math.min(Math.max(currentZoom * zoom, 0.25), 32);
        const ratio = 1 - zoom / currentZoom;
        const canvasPosition = { x: canvasPositionAndSize.x, y: canvasPositionAndSize.y };
        const canvasSize = { width: canvasPositionAndSize.width, height: canvasPositionAndSize.height };
        const newPosition = this.calculateNewCanvasPosition(
            canvasPosition,
            mousePosition,
            ratio,
            canvasSize
        );
        return { ...newPosition, zoom: newZoom };
    }

    private getDistance = (
        positionOne: IPosition,
        positionTwo: IPosition,
        zoom: number
    ): IPosition => ({
        x: (positionOne.x - positionTwo.x) * zoom,
        y: (positionOne.y - positionTwo.y) * zoom
    });

    private getZoomFactor = (
        padding: number,
        workspaceSize: ISize,
        boundingBox: IBoundingBox,
        currentZoom: number
    ): number =>
        padding *
        Math.min(
            workspaceSize.width / (boundingBox.width * currentZoom),
            workspaceSize.height / (boundingBox.height * currentZoom)
        );
}
