import { CreativeDataNode } from '@creative/nodes/base-data-node';
import { Subject } from 'rxjs';
import { ElementChangeType } from './element-change';

/**
 * Accessible through editor-events
 */
type CreativeNodeKeys = keyof CreativeDataNode;

export class CreativeEvents {
    private _change$ = new Subject<{
        property?: CreativeNodeKeys;
        value?: CreativeDataNode[CreativeNodeKeys];
        type: ElementChangeType;
    }>();
    change$ = this._change$.asObservable();

    change<Property extends CreativeNodeKeys>(
        property?: Property,
        value?: CreativeDataNode[Property],
        type: ElementChangeType = ElementChangeType.Instant
    ): void {
        this._change$.next({ property, value, type });
    }
}
