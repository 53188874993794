<div class="quality-section">
    <div class="setting-label">
        Quality
        @if (!isVideo) {
            <ui-svg-icon
                class="info"
                icon="question-mark"
                [uiTooltipMaxWidth]="'200px'"
                [uiTooltip]="
                    '85% is recommended. Changing quality will affect the weight of the creative.'
                "></ui-svg-icon>
        }
    </div>

    <studio-ui-slider
        [config]="{ min: 1, max: 100 }"
        [value]="quality || 0"
        (mouseUp)="notifyChange()"
        (valueChange)="updateQuality($event)">
    </studio-ui-slider>

    <ui-number-input
        [attr.data-test-id]="isVideo ? 'video-optimization-input' : 'image-optimization-input'"
        unitLabel="%"
        [allowEmpty]="mixedQualities"
        [keyboardEmit]="true"
        [max]="100"
        [min]="1"
        [placeholder]="mixedQualities ? '-' : opacityPlaceholder"
        [value]="quality"
        (valueChange)="updateQuality($event, true)">
    </ui-number-input>
</div>
