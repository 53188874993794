import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UIDialogService, UIModule } from '@bannerflow/ui';
import { ConnectedUser } from '@domain/signalr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConcurrencyService } from '../../../shared/services/concurrency.service';
import { StudioHubService } from '../../services/studio-hub.service';
import { UserIconComponent } from '../user-icon/user-icon.component';
import { CreativesetInUseDialogComponent } from './creativeset-in-use-dialog/creativeset-in-use-dialog.component';

@Component({
    standalone: true,
    imports: [CommonModule, UIModule, UserIconComponent],
    selector: 'current-users',
    templateUrl: './current-users.component.html',
    styleUrls: ['./current-users.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CurrentUsersComponent {
    readonly ICON_LIMIT = 5;
    users$: Observable<ConnectedUser[]>;

    constructor(
        private studioHubService: StudioHubService,
        private uiDialogService: UIDialogService,
        private concurrencyService: ConcurrencyService
    ) {
        this.users$ = this.studioHubService.usersUpdated$.pipe(
            map(update => update.users),
            takeUntilDestroyed()
        );
    }

    openCreativesetInUseDialog(users: ConnectedUser[]): void {
        this.concurrencyService.trackEvent('see-users');
        this.uiDialogService.openComponent(CreativesetInUseDialogComponent, {
            headerText: 'Creative set is opened by multiple users',
            theme: 'default',
            width: '500px',
            data: {
                users
            }
        });
    }
}
