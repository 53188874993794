<studio-ui-section
    class="no-padding"
    *ngIf="keyframes.length"
    headline="Keyframe{{ keyframes.length > 1 ? 's' : '' }}">
    <div class="section-body">
        <div class="body">
            <div class="setting">
                <!-- Timing function properties-->
                <div
                    class="setting-row alternate"
                    [class.inactive]="selectedTimingFunctionKey === undefined">
                    <div
                        class="setting-label"
                        [uiTooltip]="
                            'The easing of the animation between<br> this keyframe and the previous one'
                        ">
                        Easing
                    </div>
                    <div class="setting-value easing-setting col-1 wide">
                        <ui-select
                            [selected]="selectedTimingFunctionKey"
                            (selectedChange)="applyTimingFunction($event)"
                            placeholder="Various"
                            allowEmpty="true"
                            [useTargetWidth]="true">
                            <ui-option
                                *ngFor="let timingFunction of timingFunctions | keyvalue"
                                [value]="timingFunction.key">
                                {{ timingFunction.value.name }}
                            </ui-option>
                        </ui-select>
                    </div>
                </div>

                <!-- Duration -->
                <div
                    class="setting-row wide"
                    [class.inactive]="duration === undefined">
                    <div
                        class="setting-label"
                        [uiTooltip]="
                            'Time this keyframe will be still<br> before animating to next keyframe'
                        ">
                        Duration
                    </div>
                    <div class="setting-value col-2 wide">
                        <div class="property-input slider">
                            <studio-ui-slider
                                [value]="duration || 0"
                                (valueChange)="applyDuration($event)"
                                [config]="{
                                    min: 0,
                                    max: maxDuration,
                                    accuracy: 100
                                }"></studio-ui-slider>
                        </div>
                        <div class="property-input">
                            <ui-number-input
                                [value]="duration"
                                (valueChange)="applyDuration($event)"
                                [keyboardEmit]="true"
                                [placeholder]="'-'"
                                [min]="0"
                                [max]="maxDuration"
                                [step]="durationStep"
                                [allowEmpty]="true"
                                [disableUndo]="true"></ui-number-input>
                        </div>
                    </div>
                </div>

                <!-- Time -->
                <div
                    class="setting-row wide"
                    [class.disabled]="keyframes.length > 1">
                    <div
                        class="setting-label"
                        [uiTooltip]="'Position in time of this keyframe<br> relative to the element'">
                        Time
                    </div>
                    <div class="setting-value col-2 wide">
                        <div class="property-input slider">
                            <studio-ui-slider
                                [value]="time || 0"
                                (valueChange)="applyTime($event)"
                                [config]="{ min: 0, max: maxTime, accuracy: 100 }"></studio-ui-slider>
                        </div>
                        <div class="property-input">
                            <ui-number-input
                                [value]="time"
                                [placeholder]="'-'"
                                (valueChange)="applyTime($event)"
                                [keyboardEmit]="true"
                                [min]="0"
                                [max]="maxTime"
                                [step]="timeStep"
                                [allowEmpty]="true"
                                [disableUndo]="true"></ui-number-input>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</studio-ui-section>
