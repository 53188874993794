import {
    TIMELINE_ELEMENT_BORDER_COLOR_FADED,
    TIMELINE_ELEMENT_BORDER_COLOR
} from '../../../pages/design-view/timeline/timeline.constants';

export const OUTLINE_COLOR = '#AAAAAA';
export const OUTLINE_COLOR_FADED = '#AAAAAA';
export const BORDER_DIMMED_COLOR = 'rgba(204, 204, 204, 0.7)';
export const SNAPPING_COLOR = '#e5007d';
const BORDER_COLOR = '#00AAFF';
const RECORDING_BORDER_COLOR = '#C34746';
const RECORDING_BORDER_COLOR_FADED = 'rgba(195, 71, 70, 0.4)';
const TRANSITION_PATH_COLOR = 'rgba(0, 170, 255, 0.4)';

class GizmoColorManager {
    inRecordMode = false;

    border(): string {
        if (this.inRecordMode) {
            return RECORDING_BORDER_COLOR;
        }
        return BORDER_COLOR;
    }

    transitionPath(): string {
        if (this.inRecordMode) {
            return RECORDING_BORDER_COLOR_FADED;
        }
        return TRANSITION_PATH_COLOR;
    }

    timelineBorder(faded?: boolean): string {
        if (this.inRecordMode) {
            return faded ? RECORDING_BORDER_COLOR_FADED : RECORDING_BORDER_COLOR;
        }
        return faded ? TIMELINE_ELEMENT_BORDER_COLOR_FADED : TIMELINE_ELEMENT_BORDER_COLOR;
    }
}

export const GizmoColor = new GizmoColorManager();
