import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserSettingsState, USER_SETTINGS_FEATURE_KEY } from './user-settings.reducer';

export const getUserSettingsState = createFeatureSelector<UserSettingsState>(USER_SETTINGS_FEATURE_KEY);

export const getManageViewSettings = createSelector(
    getUserSettingsState,
    (state: UserSettingsState) => state.manageView
);

export const getDesignViewSettings = createSelector(
    getUserSettingsState,
    (state: UserSettingsState) => state.designView
);

export const getSharedSettings = createSelector(
    getUserSettingsState,
    (state: UserSettingsState) => state.sharedSettings
);

export const getIsLibraryPinned = createSelector(
    getUserSettingsState,
    (state: UserSettingsState) => state.designView.isLibraryPinned
);

export const getLibraryKind = createSelector(
    getUserSettingsState,
    (state: UserSettingsState) => state.designView.libraryKind
);

export const getSelectedFeedId = createSelector(
    getUserSettingsState,
    (state: UserSettingsState) => state.designView.selectedFeedId
);

export const getDimOutsideCanvas = createSelector(
    getUserSettingsState,
    (state: UserSettingsState) => state.designView.dimOutsideCanvas
);

export const getSnapping = createSelector(
    getUserSettingsState,
    (state: UserSettingsState) => state.designView.snapping
);

export const getOutlineVisible = createSelector(
    getUserSettingsState,
    (state: UserSettingsState) => state.designView.outlineVisible
);

export const getGuidelineVisible = createSelector(
    getUserSettingsState,
    (state: UserSettingsState) => state.designView.guidelineVisible
);

export const getGuidelineColor = createSelector(
    getUserSettingsState,
    (state: UserSettingsState) => state.designView.guidelineColor
);

export const getGrid = createSelector(
    getUserSettingsState,
    (state: UserSettingsState) => state.designView.grid
);

export const getGridColor = createSelector(
    getUserSettingsState,
    (state: UserSettingsState) => state.designView.gridColor
);

export const getTimelineHeight = createSelector(
    getUserSettingsState,
    (state: UserSettingsState) => state.designView.timelineHeight
);

export const getDisplayGrid = createSelector(
    getUserSettingsState,
    (state: UserSettingsState) => state.designView.displayGrid
);

export const getAnimation = createSelector(
    getUserSettingsState,
    (state: UserSettingsState) => state.designView.animation
);

export const getFontValidationWarnings = createSelector(
    getUserSettingsState,
    (state: UserSettingsState) => state.ignoredWarnings.fontValidationWarnings
);

export const getVideoValidationWarnings = createSelector(
    getUserSettingsState,
    (state: UserSettingsState) => state.ignoredWarnings.videoValidationWarnings
);

export const getTranslationPageLayout = createSelector(
    getUserSettingsState,
    (state: UserSettingsState) => state.translationPage.layout
);

export const getTranslationPageTabs = createSelector(
    getUserSettingsState,
    (state: UserSettingsState) => state.translationPage.tabs[state.creativeSetId ?? ''] ?? 'content'
);

export const getTranslationPageShowAllElements = createSelector(
    getUserSettingsState,
    (state: UserSettingsState) => state.translationPage.showAllElements
);
