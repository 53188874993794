<ui-dropdown
    #menu
    [width]="'135'"
    [minWidth]="135"
    type="menu"
    [offset]="{ x: 0, y: -30 }"
    class="approval-status-dropdown">
    <ui-dropdown-item
        id="set-status-nostatus"
        (click)="statusChange(ApprovalStatus.None)"
        [class.highlight]="isHighlight(ApprovalStatus.None)"
        class="approval-status-item"
        svgIcon="status-dot">
        No status
    </ui-dropdown-item>

    <ui-dropdown-item
        id="set-status-notapproved"
        (click)="statusChange(ApprovalStatus.NotApproved)"
        [class.highlight]="isHighlight(ApprovalStatus.NotApproved)"
        class="approval-status-item ap-not-approved"
        svgIcon="status-dot"
        >Not approved</ui-dropdown-item
    >

    <ui-dropdown-item
        id="set-status-inprogress"
        (click)="statusChange(ApprovalStatus.InProgress)"
        [class.highlight]="isHighlight(ApprovalStatus.InProgress)"
        class="approval-status-item ap-in-progress"
        svgIcon="status-dot"
        >In progress</ui-dropdown-item
    >

    <ui-dropdown-item
        id="set-status-forreview"
        (click)="statusChange(ApprovalStatus.ForReview)"
        [class.highlight]="isHighlight(ApprovalStatus.ForReview)"
        class="approval-status-item ap-for-review"
        svgIcon="status-dot"
        >For review</ui-dropdown-item
    >

    <ui-dropdown-item
        id="set-status-approved"
        (click)="statusChange(ApprovalStatus.Approved)"
        [class.highlight]="isHighlight(ApprovalStatus.Approved)"
        class="approval-status-item ap-approved"
        svgIcon="status-dot"
        >Approved</ui-dropdown-item
    >
</ui-dropdown>
