import { WebStorageService } from './web-storage.service';
import { IStorageService } from './storage.model';
import { InMemoryStorageService } from './memory-storage.';

export function localStorageFactory(): IStorageService {
    try {
        if ((typeof localStorage as any) !== 'undefined' && isStorageAvailable(localStorage)) {
            return new WebStorageService(localStorage);
        }
        // eslint-disable-next-line no-empty
    } catch {}

    return new InMemoryStorageService();
}

export function isStorageAvailable(storage: Storage): boolean {
    if (!storage) {
        return false;
    }

    try {
        const now = Date.now();
        const testItemKey = `test-key-${now}`;
        const testItemValue = `test-value-${now}`;
        storage.setItem(testItemKey, testItemValue);
        const retrievedItemValue = storage.getItem(testItemKey);
        storage.removeItem(testItemKey);

        return retrievedItemValue === testItemValue;
    } catch {
        return false;
    }
}
