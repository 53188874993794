import {
    BrandLibraryQueryResult,
    CreateBrandLibraryFolderResult,
    CreateElementInBrandLibraryResult,
    DeleteElementsInBrandLibraryResult,
    Folder,
    IBrandLibraryElement,
    UpdateElementInBrandLibraryResult
} from '@domain/brand/brand-library';
import { IElement } from '@domain/creativeset/element';
import { gql } from 'apollo-angular';
import {
    CreateBrandLibraryFolderVariables,
    CreateElementInBrandLibraryVariables,
    UpdateElementInBrandLibraryVariables
} from './brand-library.queries.types';
import { IMAGE_FRAGMENT, VIDEO_FRAGMENT } from './creativeset.queries';

const BRAND_LIBRARY_FRAGMENT = gql<BrandLibraryQueryResult, void>`
    fragment BrandLibraryFragment on BrandLibraryType {
        id
        brandId
        elements {
            id
            name
            parentId
            parentFolderId
            type
            created
            modified
            properties {
                name
                unit
                value
                versionPropertyId
                label
            }
        }
        folders {
            id
            name
            parentFolderId
        }
        images {
            ...ImageFragment
        }
        widgets {
            id
            thumbnail
            animatedThumbnail
            bannerflowLibraryId
            created
            modified
        }
        videos {
            ...VideoFragment
        }
    }
    ${IMAGE_FRAGMENT}
    ${VIDEO_FRAGMENT}
`;

export const GET_BRAND_LIBRARY = gql<BrandLibraryQueryResult, { brandId: string }>`
    query BrandLibraryQuery($brandId: String!) {
        brandLibrary(brandId: $brandId) {
            ...BrandLibraryFragment
        }
    }
    ${BRAND_LIBRARY_FRAGMENT}
`;

export const CREATE_ELEMENT = gql<
    CreateElementInBrandLibraryResult,
    CreateElementInBrandLibraryVariables
>`
    mutation CreateElementInBrandLibrary($element: CreateElementInputType!, $brandId: String!) {
        createElementInBrandLibrary(element: $element, brandLibraryId: $brandId) {
            ...BrandLibraryFragment
        }
    }
    ${BRAND_LIBRARY_FRAGMENT}
`;

export const UPDATE_ELEMENT = gql<
    UpdateElementInBrandLibraryResult,
    UpdateElementInBrandLibraryVariables
>`
    mutation UpdateElementInBrandLibrary($element: ElementInputType!, $brandId: String!) {
        updateElementInBrandLibrary(element: $element, brandLibraryId: $brandId) {
            ...BrandLibraryFragment
        }
    }
    ${BRAND_LIBRARY_FRAGMENT}
`;

export const DELETE_ELEMENT = gql<
    DeleteElementsInBrandLibraryResult,
    { ids: string[]; brandId: string }
>`
    mutation DeleteElementsInBrandLibrary($ids: [String]!, $brandId: String!) {
        deleteElementsInBrandLibrary(ids: $ids, brandLibraryId: $brandId) {
            ...BrandLibraryFragment
        }
    }
    ${BRAND_LIBRARY_FRAGMENT}
`;

export const CREATE_BRAND_LIBRARY_FOLDER = gql<
    CreateBrandLibraryFolderResult,
    CreateBrandLibraryFolderVariables
>`
    mutation CreateBrandLibraryFolder(
        $name: String!
        $brandLibraryId: String!
        $parentFolderId: String
    ) {
        createBrandLibraryFolder(
            brandLibraryId: $brandLibraryId
            parentFolderId: $parentFolderId
            name: $name
        ) {
            folders {
                id
                name
                parentFolderId
            }
        }
    }
`;

type DeleteBrandLibraryFolderResult = {
    deleteBrandLibraryContents: {
        folders: Folder[];
        elements: IBrandLibraryElement[];
    };
};

export const DELETE_BRAND_LIBRARY_FOLDER = gql<
    DeleteBrandLibraryFolderResult,
    { contentIds: string[]; brandLibraryId: string }
>`
    mutation StudioMutation($contentIds: [String]!, $brandLibraryId: String!) {
        deleteBrandLibraryContents(brandLibraryId: $brandLibraryId, contentIds: $contentIds) {
            folders {
                id
                name
                parentFolderId
            }
            elements {
                id
                name
                parentFolderId
                parentId
                type
                properties {
                    id
                    name
                    label
                    unit
                    value
                    versionPropertyId
                }
            }
        }
    }
`;

type UpdateBrandLibraryFolderNameResult = {
    deleteBrandLibraryContents: {
        folders: Folder[];
        elements: IElement[];
    };
};

type UpdateBrandLibraryFolderNameVariables = {
    id: string;
    name: string;
    brandLibraryId: string;
};
export const UPDATE_FOLDER_NAME = gql<
    UpdateBrandLibraryFolderNameResult,
    UpdateBrandLibraryFolderNameVariables
>`
    mutation StudioMutation($name: String!, $brandLibraryId: String!, $id: String!) {
        renameBrandLibraryFolder(name: $name, brandLibraryId: $brandLibraryId, id: $id) {
            folders {
                id
                name
                parentFolderId
            }
        }
    }
`;

type MoveBrandLibraryFolderResult = {
    moveBrandLibraryContents: {
        elements: IBrandLibraryElement[];
    };
};

type MoveBrandLibraryFolderVariables = {
    contentIds: string[];
    brandLibraryId: string;
    parentFolderId?: string;
};

export const MOVE_BRAND_LIBRARY_FOLDER = gql<
    MoveBrandLibraryFolderResult,
    MoveBrandLibraryFolderVariables
>`
    mutation StudioMutation($contentIds: [String]!, $brandLibraryId: String!, $parentFolderId: String) {
        moveBrandLibraryContents(
            contentIds: $contentIds
            brandLibraryId: $brandLibraryId
            parentFolderId: $parentFolderId
        ) {
            elements {
                id
                name
                parentFolderId
                parentId
                type
                properties {
                    id
                    name
                    label
                    unit
                    value
                    versionPropertyId
                }
            }
        }
    }
`;
