@if (showFileDropZone) {
    <div
        class="fileDropOverlay"
        #fileDropZone>
        <ui-svg-icon
            class="arrow"
            icon="upload-media"></ui-svg-icon>
        Drop to upload
    </div>
}

<div
    class="list-wrapper"
    [attr.data-test-id]="'bl-' + kind">
    <div
        class="list-top"
        (click)="stopPropagation($event)"
        (mousedown)="stopPropagation($event)">
        @if ((!currentFeed && !currentFolder) || (inDialog && !currentFolder)) {
            <div
                class="search"
                [class.active]="!!searchQuery.length">
                <div
                    id="media-library-search-icon"
                    class="search-icon"
                    [class.darker]="!!searchQuery.length"
                    (click)="onSearchIconClick()">
                    <ui-svg-icon [icon]="searchQuery ? 'clear' : 'search'"></ui-svg-icon>
                </div>
                <ui-input
                    #searchInput
                    [placeholder]="libraryKindToSearchPlaceholder"
                    [value]="searchQuery"
                    data-cy="brandlibrary-search"
                    (valueChange)="search($event)">
                </ui-input>
            </div>
        }
        @if ((!inDialog && (currentFeed || currentFolder)) || (inFeedPicker && currentFolder)) {
            <div
                id="media-library-back-to-feeds"
                class="crumbs"
                (click)="backToFeedList($event)">
                <div class="feed-navigation">
                    <ui-svg-icon icon="direction-left"></ui-svg-icon>
                    <ui-svg-icon
                        class="gutter"
                        [icon]="
                            !inFeedPicker && currentFeed ? 'feed' : currentFolder ? 'folder' : 'none'
                        "></ui-svg-icon>
                    <div
                        class="feed-navigation__name"
                        truncateSpan
                        [spanText]="
                            (!inFeedPicker && currentFeed?.name) || currentFolder?.name || 'Feeds'
                        "></div>
                </div>
            </div>
        }
        <!-- BL MENU BUTTON - SORT -->
        @if (context !== AssetPropertyContext.Replace && kind !== 'feeds') {
            <div
                id="interaction-bl-menu-sort"
                class="sort-icon"
                [uiDropdownTarget]="sortDropdown"
                (dropdownOpened)="menuOpened = true"
                (dropdownClosed)="menuOpened = false">
                <ui-svg-icon
                    class="icon"
                    icon="sort"
                    [ngClass]="{ active: menuOpened }"></ui-svg-icon>
                <ui-dropdown
                    #sortDropdown
                    width="170"
                    [offset]="{ x: -2, y: -1 }"
                    type="menu">
                    <ui-dropdown-item
                        id="sort-name"
                        class="menu-item"
                        [svgIcon]="
                            sortingStrategy.sortBy === 'name' ? 'checkbox-checkmark-default' : ''
                        "
                        (click)="updateSorting({ sortBy: 'name' })">
                        Sort by name
                    </ui-dropdown-item>
                    <ui-dropdown-item
                        id="sort-created"
                        class="menu-item"
                        [svgIcon]="
                            sortingStrategy.sortBy === 'created' ? 'checkbox-checkmark-default' : ''
                        "
                        (click)="updateSorting({ sortBy: 'created' })">
                        Sort by date created
                    </ui-dropdown-item>
                    <ui-dropdown-item
                        id="sort-modified"
                        class="menu-item"
                        [svgIcon]="
                            sortingStrategy.sortBy === 'modified' ? 'checkbox-checkmark-default' : ''
                        "
                        (click)="updateSorting({ sortBy: 'modified' })">
                        Sort by date changed
                    </ui-dropdown-item>

                    <ui-dropdown-divider></ui-dropdown-divider>

                    <ui-dropdown-item
                        id="sort-asc"
                        class="menu-item"
                        [svgIcon]="
                            sortingStrategy.direction === 'asc' ? 'checkbox-checkmark-default' : ''
                        "
                        svgIcon="checkbox-checkmark-default"
                        (click)="updateSorting({ direction: 'asc' })">
                        Ascending
                    </ui-dropdown-item>
                    <ui-dropdown-item
                        id="sort-desc"
                        class="menu-item"
                        [svgIcon]="
                            sortingStrategy.direction === 'desc' ? 'checkbox-checkmark-default' : ''
                        "
                        (click)="updateSorting({ direction: 'desc' })">
                        Descending
                    </ui-dropdown-item>
                </ui-dropdown>
            </div>
        }
        <!-- BL MENU BUTTON - ADD -->
        @if (context !== AssetPropertyContext.Replace && kind !== LibraryKind.Feeds) {
            <div
                id="interaction-bl-menu"
                class="add-icon"
                ui-popover-target
                #genAiPopoverTarget="ui-popover-target"
                [uiDropdownTarget]="addMenuDropdown"
                (dropdownOpened)="menuOpened = true"
                (dropdownClosed)="menuOpened = false">
                <ui-svg-icon
                    class="icon"
                    icon="plus-small"
                    [ngClass]="{ active: menuOpened }"></ui-svg-icon>
                <ui-dropdown
                    #addMenuDropdown
                    width="170"
                    [offset]="{ x: -2, y: -1 }"
                    type="menu">
                    <ui-dropdown-item
                        id="new-folder"
                        class="menu-item"
                        svgIcon="folder"
                        (click)="startCreatingFolder(); addMenuDropdown.close.emit()">
                        Create new folder
                    </ui-dropdown-item>
                    <ui-dropdown-divider></ui-dropdown-divider>
                    @if (kind === LibraryKind.Image) {
                        <ui-dropdown-item
                            *permissions="'StudioGenAiImage'"
                            id="interaction-ai-gen-image"
                            class="menu-item"
                            svgIcon="ai_stars"
                            (click)="genAiPopover.open(genAiPopoverTarget)">
                            Generate image
                        </ui-dropdown-item>

                        <ui-dropdown-item
                            *permissions="'!StudioGenAiImage'"
                            id="interaction-ai-gen-image-noaccess"
                            class="menu-item"
                            svgIcon="ai_stars"
                            (click)="showAIStudioNoAccessDialog()">
                            Generate image
                        </ui-dropdown-item>
                    }
                    <ui-dropdown-item
                        id="interaction-upload-file"
                        (click)="fileInput.click()"
                        svgIcon="upload-file">
                        Upload file
                        <input
                            #fileInput
                            id="upload-file"
                            style="display: none"
                            type="file"
                            multiple
                            [accept]="acceptedUploadFormats"
                            (change)="uploadFilesAndClearFileInput(fileInput, parentFolderId)" />
                    </ui-dropdown-item>
                    <ui-dropdown-item
                        id="interaction-upload-font"
                        svgIcon="font-file"
                        class="menu-item"
                        (click)="uploadFont()">
                        Upload font...
                    </ui-dropdown-item>
                    @if (kind === LibraryKind.Widget) {
                        <ui-dropdown-item
                            id="new-widget"
                            svgIcon="widget-new"
                            class="menu-item"
                            (click)="createNewWidget()">
                            New widget
                        </ui-dropdown-item>
                        @if (kind === LibraryKind.Widget) {
                            <ui-dropdown-divider></ui-dropdown-divider>
                            <ui-dropdown-item
                                id="interaction-import-stock-widget"
                                class="menu-item prevent-ml-close"
                                [disabled]="
                                    !bannerflowLibraryWidgetsLoaded || !bannerflowLibraryWidgets.length
                                "
                                [loading]="!bannerflowLibraryWidgetsLoaded"
                                [uiDropdownTarget]="bannerflowLibraryWidgetsDropdown">
                                Import stock widget
                            </ui-dropdown-item>
                        }
                    }
                </ui-dropdown>
                <ui-dropdown
                    #bannerflowLibraryWidgetsDropdown
                    [maxHeight]="450"
                    type="menu">
                    @for (widget of bannerflowLibraryWidgets; track widget.id) {
                        <ui-dropdown-item
                            class="prevent-ml-close"
                            [id]="'import-' + widget.name"
                            [attr.data-cy]="'import-' + widget.name | searchReplace: searchRegex : '-'"
                            [disabled]="widget.imported || false"
                            (click)="importBannerflowLibraryWidget(widget)">
                            {{ widget.name }}
                        </ui-dropdown-item>
                    }
                </ui-dropdown>
            </div>
        }
        <!-- END BL MENU BUTTON -->
        <!-- folders dialog mode -->
        @if (inDialog) {
            <ng-container *ngTemplateOutlet="foldersTemplate"></ng-container>
        }
    </div>
    <div class="ml-inner">
        @if (dataSource && !dataSource.data.length && foldersSource && !foldersSource.data.length) {
            <div
                class="asset-list-empty"
                [ngClass]="{ border: !inDialog }">
                @if (kind === 'feeds') {
                    <div class="top">No dynamic content on this brand</div>
                } @else {
                    @if (!selectedFolder) {
                        <div class="top">No {{ libraryKindToName }} in the library</div>
                    } @else {
                        <div class="top">Folder contains no {{ libraryKindToName }}</div>
                    }
                }
                @if (!inDialog && !selectedFolder) {
                    <div class="middle">
                        <div class="title">{{ libraryKindTitle }}</div>
                        {{ libraryKindToMessage }}
                    </div>
                    <div
                        class="bottom"
                        [class.hidden]="kind !== 'image'">
                        <div class="arrow">
                            <svg
                                width="39px"
                                height="50px"
                                viewBox="0 0 39 50"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink">
                                <g
                                    id="--Library"
                                    stroke="none"
                                    stroke-width="1"
                                    fill="none"
                                    fill-rule="evenodd"
                                    stroke-linecap="round"
                                    stroke-linejoin="round">
                                    <g
                                        id="Library-/-Rectangles---empty"
                                        transform="translate(-81.000000, -765.000000)"
                                        stroke="#B5B5B5">
                                        <g
                                            id="Group-2"
                                            transform="translate(28.000000, 765.000000)">
                                            <g id="Group-8">
                                                <g
                                                    id="Group"
                                                    transform="translate(53.500000, 0.000000)">
                                                    <polyline
                                                        id="Stroke-2"
                                                        transform="translate(19.000000, 9.606278) rotate(90.000000) translate(-19.000000, -9.606278) "
                                                        points="28.5 28.2125556 9.5 9.60627778 28.5 -9"></polyline>
                                                    <path
                                                        d="M-5,24.627 L43,24.627"
                                                        id="Stroke-5"
                                                        transform="translate(19.000000, 24.627000) rotate(90.000000) translate(-19.000000, -24.627000) "></path>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div class="text">Drop elements here or</div>
                        <div class="button">
                            <ui-button
                                id="media-library-file-upload"
                                icon="plus-skinny-minimal"
                                text="Upload"
                                (click)="fileInput.nativeElement.click()">
                            </ui-button>
                        </div>
                    </div>
                }
            </div>
        }
        <div
            class="asset-list ui-scrollbar"
            #assetList>
            @if (searchQuery && !dataSource.filteredLength && !foldersSource.filteredLength) {
                <div class="no-result">No results</div>
            }

            <!-- folders normal mode -->
            @if (!inDialog) {
                <ng-container *ngTemplateOutlet="foldersTemplate"></ng-container>
            }

            @if (creativesetDataService.creativeset) {
                <div
                    [class.main-content]="inDialog && kind !== 'feeds'"
                    [class.isDragging]="isDraggingElements">
                    @if (itemsIsLoading) {
                        <ui-loader [@.disabled]="true"></ui-loader>
                    }

                    <ui-list
                        class="list"
                        selectionType="multi"
                        layout="grid"
                        [sortBy]="
                            (sortingStrategy.direction === 'asc' ? '' : '-') + sortingStrategy.sortBy
                        "
                        [dataSource]="dataSource"
                        [expandable]="false"
                        [multiSelect]="showGridView"
                        [showHeader]="false"
                        (selectionChange)="selectionChanged($event)"
                        [showBackButton]="false"
                        [showEmptyState]="false"
                        [class.one-column]="
                            viewMode === LibraryViewMode.Preview || viewMode === LibraryViewMode.List
                        "
                        [class.hidden]="
                            (searchQuery && !dataSource.filteredLength && !inDialog) ||
                            (currentFeed && !inDialog)
                        "
                        #list>
                        @if (kind === LibraryKind.Feeds) {
                            <ui-list-grid property="name">
                                <ng-template
                                    let-data="data"
                                    let-value="value"
                                    ui-list-cell-template>
                                    <div
                                        class="library-element-row"
                                        [class.error]="data.isBroken">
                                        <div
                                            class="library-element-row__wrapper"
                                            #tooltipTrigger
                                            [uiTooltip]="value"
                                            uiTooltipClass="ml-tooltip"
                                            uiTooltipPosition="top"
                                            uiTooltipMaxWidth="140px"
                                            (click)="tooltip.create()">
                                            <ui-svg-icon
                                                icon="feed"
                                                class="library-element-row__icon"></ui-svg-icon>
                                            <span class="library-element-row__name">{{ value }}</span>
                                            <ui-svg-icon
                                                class="library-element-row__control"
                                                icon="warning"
                                                [uiTooltip]="data.errorMessage"
                                                [uiTooltipTriggerHost]="tooltipTrigger"
                                                [uiTooltipDisabled]="!data.errorMessage"
                                                #tooltip="uiTooltip"></ui-svg-icon>
                                        </div>
                                        <ui-svg-icon
                                            icon="go-to-url"
                                            class="library-element-row__link"
                                            uiTooltip="Edit feed source"
                                            (click)="openFeedURL($event, data)"></ui-svg-icon>
                                    </div>
                                </ng-template>
                            </ui-list-grid>
                        }

                        @if (kind !== LibraryKind.Feeds) {
                            <ui-list-grid property="name">
                                <ng-template
                                    let-data="data"
                                    let-rowIndex="rowIndex"
                                    let-displayValue="displayValue"
                                    let-value="value"
                                    ui-list-cell-template>
                                    @if (!itemsIsLoading) {
                                        <library-element
                                            [element]="data"
                                            (mousedown)="onStartDragAsset($event, data)"
                                            (dblclick)="
                                                inDialog
                                                    ? insertSelected()
                                                    : addAssetToCanvas($event, data)
                                            "
                                            (addToCanvas)="addAssetToCanvas($event, data)"
                                            (duplicateElement)="duplicateElement($event)"
                                            (editElement)="onEditElement($event)"
                                            [class.disabled]="data.progress"
                                            [viewMode]="viewMode"></library-element>
                                    }
                                </ng-template>
                            </ui-list-grid>
                        }
                        <ui-list-column
                            name="Name"
                            property="name">
                        </ui-list-column>
                    </ui-list>
                    @if (feedData && !inDialog) {
                        <div class="feed-view">
                            @for (item of feedData; track item.id) {
                                <feed-field
                                    #feedField
                                    (mousedown)="onStartDragFeedItem($event, item, feedField)"
                                    (dblclick)="addFeededElementToCanvas($event, item)"
                                    [item]="item">
                                </feed-field>
                            }
                        </div>
                    }
                    @if (!inFeedPicker && inDialog) {
                        <div
                            class="action-bar"
                            (click)="stopPropagation($event)"
                            (mousedown)="stopPropagation($event)">
                            <!-- Visible if media library is shown in fullscreen/dialog -->
                            <!-- To be implemented -->
                            @if (context === AssetPropertyContext.Replace) {
                                <div class="settings">
                                    @if (showReplaceSizes) {
                                        <ui-checkbox
                                            (click)="$event.stopPropagation()"
                                            [disabled]="list.selection!.isEmpty()"
                                            [(selected)]="replaceAllOfAssetType"
                                            [label]="
                                                'Replace ' + filteredAssetType + ' in all sizes'
                                            "></ui-checkbox>
                                    }
                                </div>
                            }
                            <div class="buttons">
                                <ui-button
                                    text="Cancel"
                                    (click)="closeBrandDialog()"></ui-button>
                                <ui-button
                                    type="primary"
                                    data-test-id="replace-asset"
                                    (click)="insertSelected($event)"
                                    [disabled]="!selection.length"
                                    [text]="
                                        context === AssetPropertyContext.Replace ? 'Replace' : 'Insert'
                                    "></ui-button>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    </div>
</div>
@if (!inDialog) {
    <div
        class="list-bottom"
        [class.list-bottom--feeds]="kind === 'feeds'">
        @if (kind !== 'feeds') {
            <div class="view-modes">
                <ui-svg-icon
                    [id]="'media-library-switch-view-' + LibraryViewMode.List"
                    class="icon"
                    [ngClass]="{ active: viewMode === LibraryViewMode.List }"
                    icon="styles-minimal-view"
                    (click)="switchViewMode(LibraryViewMode.List)"></ui-svg-icon>
                <ui-svg-icon
                    [id]="'media-library-switch-view-' + LibraryViewMode.Grid"
                    class="icon"
                    [ngClass]="{ active: viewMode === LibraryViewMode.Grid }"
                    icon="columns-grid"
                    (click)="switchViewMode(LibraryViewMode.Grid)"></ui-svg-icon>
                <ui-svg-icon
                    [id]="'media-library-switch-view-' + LibraryViewMode.Preview"
                    class="icon"
                    [ngClass]="{ active: viewMode === LibraryViewMode.Preview }"
                    icon="big-preview"
                    (click)="switchViewMode(LibraryViewMode.Preview)"></ui-svg-icon>
            </div>
        }
        <ui-svg-icon
            id="media-library-pin-toggle"
            class="icon"
            [ngClass]="{ active: isPinned }"
            icon="pin"
            (click)="togglePin()"></ui-svg-icon>
    </div>
}
@if (inFeedPicker) {
    <div class="feed-wrapper main-content">
        @if (!feedData) {
            <div>
                <!-- Empty space -->
            </div>
        } @else {
            <div
                id="media-library-feeds"
                class="feed-view"
                #feedView>
                @for (item of feedData; track item.id) {
                    <feed-field
                        (mousedown)="onStartDragFeedItem($event, item)"
                        (dblclick)="insertSelected()"
                        (click)="selectFeedItem(item)"
                        [item]="item"
                        [selection]="selectedFeedItem"
                        [inDialog]="inDialog"
                        view="grid">
                    </feed-field>
                }
            </div>
        }
        @if (inDialog) {
            <div
                class="action-bar"
                (click)="stopPropagation($event)"
                (mousedown)="stopPropagation($event)">
                @if (context === AssetPropertyContext.Replace) {
                    <div class="settings">
                        <ui-checkbox
                            (click)="$event.stopPropagation()"
                            [disabled]="!currentFeed"
                            [(selected)]="replaceAllOfAssetType"
                            [label]="
                                filteredAssetType === ElementKind.Image
                                    ? 'Replace image in all sizes'
                                    : 'Replace video in all sizes'
                            "></ui-checkbox>
                    </div>
                }
                <div class="buttons">
                    <ui-button
                        text="Cancel"
                        (click)="closeBrandDialog()"></ui-button>
                    <ui-button
                        type="primary"
                        (click)="insertSelected($event)"
                        [disabled]="!selectedFeedItem"
                        text="Insert">
                    </ui-button>
                </div>
            </div>
        }
    </div>
}

<ng-template #foldersTemplate>
    <div
        #folders
        class="ml-folders"
        [ngClass]="{ 'ui-scrollbar': inDialog }">
        @if (selectedFolder && kind !== 'feeds') {
            <div
                class="ml-folders-header"
                [class.ml-folders-selected]="!!selectedFolder"
                [uiTooltip]="selectedFolder.name"
                uiTooltipTrigger="hover"
                uiTooltipClass="ml-tooltip"
                uiTooltipPosition="top"
                uiTooltipMaxWidth="140px">
                <div
                    id="media-library-folder-open-parent"
                    class="ml-folders-header__back prevent-ml-close"
                    (click)="openParentFolder()">
                    <ui-svg-icon icon="arrow-left"></ui-svg-icon>
                </div>
                <div class="ml-folders-header__name">{{ selectedFolder.name }}</div>
            </div>
        }
        <ui-list
            class="list"
            selectionType="none"
            [sortBy]="(sortingStrategy.direction === 'asc' ? '' : '-') + sortingStrategy.sortBy"
            [layout]="'list'"
            [dataSource]="foldersSource"
            columnHeight="27px"
            [expandable]="false"
            [multiSelect]="false"
            [showHeader]="false"
            [class.one-column]="isElementsLibrary"
            [showBackButton]="false"
            [showEmptyState]="false"
            [outerBorder]="false"
            [class.hidden]="searchQuery && !foldersSource.filteredLength && !inDialog"
            #foldersList>
            <ui-list-column
                name="Name"
                property="name">
                <ng-template
                    let-data="data"
                    let-value="value"
                    ui-list-cell-template>
                    <library-folder
                        [uiTooltip]="data.name"
                        uiTooltipTrigger="hover"
                        uiTooltipClass="ml-tooltip"
                        uiTooltipPosition="top"
                        uiTooltipMaxWidth="140px"
                        (folderDeleted)="deleteFolder($event)"
                        [folderId]="data.id"
                        [folderName]="data.name"
                        [mediaLibraryKind]="kind"></library-folder>
                </ng-template>
            </ui-list-column>
        </ui-list>
        <div class="new-folder-wrap">
            @if (newFolder) {
                <library-folder
                    (folderCreated)="createFolder($event)"
                    [isNewFolder]="true"
                    [folderName]="newFolder.name"></library-folder>
            }
        </div>
    </div>
</ng-template>

<ai-image-generation-popover
    #genAiPopover
    [parentFolderId]="parentFolderId" />
