import { Injectable, inject } from '@angular/core';
import { CreativesetDataService } from '../../creativeset/creativeset.data.service';
import { Store } from '@ngrx/store';
import { ICreative } from '@domain/creativeset/creative';
import { map } from 'rxjs';
import * as DisplayCampaignActions from './display-campaign.actions';
import * as DisplayCampaignSelectors from './display-campaign.selectors';
import { isCreativePublishable } from './display-campaign.utils';

@Injectable({ providedIn: 'root' })
export class DisplayCampaignService {
    private store = inject(Store);
    private creativeSetDataService = inject(CreativesetDataService);

    loaded$ = this.store.select(DisplayCampaignSelectors.getLoaded);
    error$ = this.store.select(DisplayCampaignSelectors.getError);
    campaigns$ = this.store.select(DisplayCampaignSelectors.getCampaigns);
    isPublishing$ = this.store.select(DisplayCampaignSelectors.getIsPublishing);

    hasConnectedCampaigns$ = this.store.select(DisplayCampaignSelectors.getHasConnectedCampaigns);
    hasChangesToPublish$ = this.campaigns$.pipe(
        map(campaignPublishStatus =>
            this.creativeSetDataService.creativeset.creatives.some(creative =>
                isCreativePublishable(creative, campaignPublishStatus)
            )
        )
    );

    campaignIds$ = this.store.select(DisplayCampaignSelectors.getCampaignIds);

    pushingChangesOnCampaignIds$ = this.store.select(
        DisplayCampaignSelectors.getPushingChangesOnCampaigns
    );

    pushChangesPrompt(creatives: ICreative[]): void {
        this.store.dispatch(DisplayCampaignActions.pushChangesPrompt({ creatives }));
    }

    cancelPublish(): void {
        this.store.dispatch(DisplayCampaignActions.cancelPublish());
    }
}
