import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject, Subject, map, scan } from 'rxjs';
import { AssetUploadService } from './asset-upload.service';

export const EditorSaveStatus = {
    Saving: 'saving',
    Uploading: 'uploading',
    Idle: 'idle'
} as const;
type CreativeSaveStatusType = (typeof EditorSaveStatus)[keyof typeof EditorSaveStatus];

export interface ISaveCreative {
    saveAll: boolean;
    saveAndExit: boolean;
}

export interface ICreativeSaveState {
    /** Whether save button is disabled */
    disabled: boolean;
    status: CreativeSaveStatusType;
}

@Injectable()
export class EditorSaveStateService {
    private _state$ = new BehaviorSubject<Partial<ICreativeSaveState>>({
        status: EditorSaveStatus.Idle,
        disabled: true
    });
    state$ = this._state$.asObservable().pipe(
        scan((prevState, newState) => {
            return Object.assign(prevState, prevState, newState);
        }, {} as ICreativeSaveState)
    );

    private _save$ = new Subject<Omit<ISaveCreative, 'origin'>>();
    save$ = this._save$.asObservable();
    private _saveSuccess$ = new Subject<void>();
    saveSuccess$ = this._saveSuccess$.asObservable();
    isUploading$ = this.state$.pipe(map(({ status }) => status === EditorSaveStatus.Uploading));

    constructor(private assetUploadService: AssetUploadService) {
        this.assetUploadService.uploadProgress$.pipe(takeUntilDestroyed()).subscribe(({ status }) => {
            if (status === 'LOAD_ASSET_FILE_STARTED') {
                this.setStatus(EditorSaveStatus.Uploading);
            }
            if (status === 'COMPLETE' || status === 'FAIL') {
                this.setStatus(EditorSaveStatus.Idle);
            }
        });
    }

    save({ saveAll, saveAndExit }: ISaveCreative): void {
        this._save$.next({ saveAll, saveAndExit });
    }

    saveSuccess(): void {
        this.setStatus(EditorSaveStatus.Idle);
        this._saveSuccess$.next();
    }

    setDisabled(disabled: boolean): void {
        this._state$.next({ disabled });
    }

    setStatus(status: CreativeSaveStatusType): void {
        this._state$.next({ status });
    }
}
