import { IHotkey } from '@domain/hotkeys/hotkeys.types';

export const TimelineHotkeys: IHotkey[] = [
    {
        command: 'RecordAnimation',
        keyCombination: { win: 'A(KeyUp)', mac: 'A(KeyUp)' },
        context: 'Timeline',
        category: ['Timeline', 'Design view'],
        meta: { description: 'Record Animation', shortcut: { mac: 'A', win: 'A' } }
    },
    {
        command: 'PlayPause',
        keyCombination: 'Spacebar(KeyUp)',
        context: 'Timeline',
        category: 'Timeline',
        meta: { description: 'Play/Pause', shortcut: 'Spacebar' }
    },
    {
        command: 'ZoomTimeline',
        keyCombination: { win: 'Ctrl + Wheel', mac: 'Cmd + Wheel' },
        context: 'Timeline',
        category: 'Timeline',
        meta: { description: 'Zoom with scroll', shortcut: { mac: '⌘ + scroll', win: 'Ctrl + scroll' } }
    },
    {
        command: 'MoveElementUp',
        keyCombination: { win: 'Ctrl + Alt + ArrowUp', mac: 'Cmd + Alt + ArrowUp' },
        context: 'Timeline',
        category: 'Timeline',
        meta: { description: 'Arrange foward', shortcut: { mac: '⌘ + ⌥ + ↑', win: 'Ctrl + Alt + ↑' } }
    },
    {
        command: 'MoveElementDown',
        keyCombination: { win: 'Ctrl + Alt + ArrowDown', mac: 'Cmd + Alt + ArrowDown' },
        context: 'Timeline',
        category: 'Timeline',
        meta: { description: 'Arrange backward', shortcut: { mac: '⌘ + ⌥ + ↓', win: 'Ctrl + Alt + ↓' } }
    },
    {
        command: 'MoveElementFront',
        keyCombination: { win: 'Ctrl + Alt + Shift + ArrowUp', mac: 'Cmd + Alt + Shift + ArrowUp' },
        context: 'Timeline',
        category: 'Timeline',
        meta: {
            description: 'Arrange to front',
            shortcut: { mac: '⌘ + ⌥ + ⇧ + ↑', win: 'Ctrl + Alt + Shift + ↑' }
        }
    },
    {
        command: 'MoveElementBack',
        keyCombination: { win: 'Ctrl + Alt + Shift + ArrowDown', mac: 'Cmd + Alt + Shift + ArrowDown' },
        context: 'Timeline',
        category: 'Timeline',
        meta: {
            description: 'Arrange to back',
            shortcut: { mac: '⌘ + ⌥ + ⇧ + ↓', win: 'Ctrl + Alt + Shift + ↓' }
        }
    },
    {
        command: 'NudgeLeft',
        keyCombination: 'ArrowLeft',
        context: 'Timeline',
        category: 'Move keyframe',
        meta: { description: 'Adjust keyframe to start slightly sooner', shortcut: '←' }
    },
    {
        command: 'NudgeRight',
        keyCombination: 'ArrowRight',
        context: 'Timeline',
        category: 'Edit elements',
        meta: { description: 'Adjust keyframe to start slightly later', shortcut: '→' }
    },
    {
        command: 'MoveLeft',
        keyCombination: 'Shift + ArrowLeft',
        context: 'Timeline',
        category: 'Edit elements',
        meta: {
            description: 'Adjust keyframe to start sooner',
            shortcut: { mac: '⇧ + ←', win: 'Shift + ←' }
        }
    },
    {
        command: 'MoveRight',
        keyCombination: 'Shift + ArrowRight',
        context: 'Timeline',
        category: 'Edit elements',
        meta: {
            description: 'Adjust keyframe to start later',
            shortcut: { mac: '⇧ + →', win: 'Shift + →' }
        }
    },
    {
        command: 'DeleteKeyframe',
        keyCombination: 'Delete',
        context: 'Timeline',
        category: 'Edit keyframes',
        meta: { description: 'Delete selected', shortcut: 'Delete or Backspace' },
        returnCommandImmediately: false
    },
    {
        command: 'DeleteKeyframe',
        keyCombination: 'Backspace',
        context: 'Timeline',
        category: 'Edit keyframes',
        meta: undefined,
        returnCommandImmediately: false
    }
];
