import { getTargetUrl } from '@ad/data/get-ad-data-creative';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ElementRef,
    forwardRef,
    HostBinding,
    HostListener,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    QueryList,
    TemplateRef,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {
    IPositions,
    UIDropdownItemComponent,
    UIDropdownTargetDirective,
    UIInputComponent,
    UINotificationService
} from '@bannerflow/ui';
import {
    animationDirectionValues,
    DEFAULT_ANIMATION_TEMPLATE_LENGTH,
    inAnimationTemplates,
    outAnimationTemplates
} from '@creative/animation-templates';
import {
    getAllKeyframes,
    getAnimationDurationOfType,
    getAnimationsOfType,
    getInAnimationDuration,
    getOutAnimationDuration,
    hasAnimationsOfType,
    MIN_ELEMENT_DURATION
} from '@creative/animation.utils';
import { IAnimator } from '@creative/animator.header';
import { hasFeededContent } from '@creative/elements/feed/feeds.utils';
import {
    forEachDataElement,
    forEachParentNode,
    isGroupDataNode,
    isHidden,
    toFlatNodeList
} from '@creative/nodes/helpers';
import { RendererEvents } from '@creative/renderer.header';
import { AnimationType, IAnimationSettings, IAnimationTemplate } from '@domain/animation';
import { IVersion } from '@domain/creativeset/version';
import { IBoundingBox, IBounds, IPosition, ISize } from '@domain/dimension';
import { ElementKind } from '@domain/elements';
import { IHotkeyContext } from '@domain/hotkeys/hotkeys.types';
import { ICreativeDataNode, ITimelineNode, OneOfDataNodes, OneOfElementDataNodes } from '@domain/nodes';
import { BTreeFilter } from '@domain/utils/btree';
import { ICrossValue, IPointResult, IQueryPoint } from '@domain/workspace';
import { UserSettingsService } from '@studio/stores/user-settings';
import { BTreeIndex } from '@studio/utils/btree';
import { cloneDeep } from '@studio/utils/clone';
import { setCursorOnElement } from '@studio/utils/cursor';
import { isElementDescendantOfElement } from '@studio/utils/dom-utils';
import { MouseObservable } from '@studio/utils/mouse-observable';
import { clamp, omitUndefined } from '@studio/utils/utils';
import { BehaviorSubject, firstValueFrom, fromEvent, merge, Observable, Subject } from 'rxjs';
import { auditTime, filter, map, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { GizmoColor } from '../../../../shared/components/canvas-drawer/gizmo.colors';
import { StudioUISliderComponent } from '../../../../shared/components/studio-ui-slider/studio-ui-slider.component';
import { MediaLibraryService } from '../../../../shared/media-library/state/media-library.service';
import { EnvironmentService } from '../../../../shared/services/environment.service';
import { HotkeyBetterService } from '../../../../shared/services/hotkeys/hotkey.better.service';
import { SocialGuideService } from '../../../../shared/services/social-guide.service';
import { VersionsService } from '../../../../shared/versions/state/versions.service';
import { DesignViewComponent } from '../../design-view.component';
import { PropertiesService } from '../../properties-panel';
import { EditorEventService, EditorStateService, HistoryService } from '../../services';
import { changeFilter, ElementChangeType } from '../../services/editor-event';
import { ElementSelectionService } from '../../services/element-selection.service';
import { MutatorService } from '../../services/mutator.service';
import { StudioWorkspaceComponent } from '../../workspace/studio-workspace.component';
import { AnimationRecorderService } from '../animation-recorder.service';
import { TimelineGizmoDrawer } from '../timeline-gizmo-drawer';
import { TimelineScrollService } from '../timeline-scroll.service';
import { ActionMode, TimelineTransformService } from '../timeline-transformer.service';
import { TimelineZoomService } from '../timeline-zoom.service';
import {
    DEFAULT_LEFTPANEL_WIDTH,
    HEIGHT_OFFSET,
    MAX_TIMELINE_DURATION,
    SCROLL_PADDING_BOTTOM,
    SCROLL_PADDING_RIGHT,
    SCROLLBAR_HANDLE_WIDTH,
    SCROLLBAR_TRACK_WIDTH,
    SNAP_TOLERANCE,
    TIMELINE_ELEMENT_HEIGHT,
    TIMERULER_HEIGHT,
    ZOOM_WIDTH
} from '../timeline.constants';
import {
    AnimationService,
    auditInterval,
    KeyframeService,
    TimelineElementComponent,
    TimelineElementService
} from './../timeline-element';

export enum ElementArrange {
    Front,
    Forward,
    Backward,
    Back
}

enum SnapPointsId {
    Default = -1,
    Playhead = -2
}

interface EdgePoint {
    time: number;
    offset: number;
}
interface ElementEdges {
    left?: EdgePoint;
    right?: EdgePoint;
}

@Component({
    selector: 'studio-timeline',
    templateUrl: './studio-timeline.component.html',
    styleUrls: ['./studio-timeline.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudioTimelineComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() animator: IAnimator;
    @Input() isZooming: boolean;
    @Input() workspace: StudioWorkspaceComponent;
    @Input() mouseOverTimeline: boolean;

    @ViewChild('elementsContainer') elementsContainer: ElementRef<HTMLDivElement>;
    @ViewChild('zoomControl') zoomControl: ElementRef<HTMLDivElement>;
    @ViewChild('slider') slider: StudioUISliderComponent;
    @ViewChild('transitionAnimationMenuTrigger')
    transitionAnimationMenuTrigger: UIDropdownTargetDirective;
    @ViewChild('seekInput') seekInput: UIInputComponent;
    @ViewChild('durationInput') durationInput: UIInputComponent;
    @ViewChild('playButton', { read: ElementRef }) playButtonElement: ElementRef;
    @ViewChild('backButton', { read: ElementRef }) backButtonElement: ElementRef;
    @ViewChild('forwardButton', { read: ElementRef }) forwardButtonElement: ElementRef;
    @ViewChild('playhead') playhead: ElementRef<HTMLElement>;
    @ViewChild('gizmoOverlayCanvas') canvas: ElementRef<HTMLCanvasElement>;

    @ViewChildren(TimelineElementComponent)
    timelineElementComponents: QueryList<TimelineElementComponent>;
    @ContentChild(TemplateRef) template: TemplateRef<UIDropdownItemComponent>;

    @HostBinding('class.keyframes-enabled') keyframesEnabled = true;
    @HostBinding('class.collapsed') collapsed = false;
    @HostBinding('playhead-active') get isSeeking(): boolean {
        return this.timelineTransformService.actionMode === ActionMode.Seek;
    }

    get zoomBox(): IBounds | undefined {
        return this.zoomService.zoomBox;
    }
    get zoomConfig(): { min: number; max: number } {
        return {
            min: this.zoomService.minZoom,
            max: this.zoomService.maxZoom
        };
    }
    ActionMode = ActionMode;

    gizmoDrawer: TimelineGizmoDrawer;
    mouseObservable: MouseObservable;
    selectionNet?: IBoundingBox;
    snapPoints = new BTreeIndex<ICrossValue>();
    resizeExpandedHeight: number;
    collapsedHeight = 27;
    defaultHeight = 180;
    height: number;
    top: number;
    private resizeStartPosition: number;
    private resizeStartHeight: number;
    private minHeight = 50;
    private minLeftPanelWidth = DEFAULT_LEFTPANEL_WIDTH;
    private mediaLibraryWidth = 0;
    private toolbarOffset = 0;
    private isTargetUrlNotificationShown = false;

    get isRecordingKeyframes(): boolean {
        return this.animationRecorderService.isRecording;
    }
    get timelineElementLeftOffset(): number {
        return this.leftPanelWidth + this.mediaLibraryWidth + this.toolbarOffset;
    }
    get scroll(): Readonly<IPosition> {
        return this.scrollService.scroll;
    }

    animationDirectionValues = animationDirectionValues;
    transitionAnimationMenuPositions: IPositions[] = [
        {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom'
        },
        {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top'
        }
    ];
    transitionAnimationSubMenuPositions: IPositions[] = [
        {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom',
            offsetY: 33
        },
        {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
            offsetY: -33
        }
    ];
    animationTemplates = cloneDeep(inAnimationTemplates);
    private currentAnimationTemplate?: IAnimationTemplate;
    selectedElementAnimations?: ISelectedElementAnimations;
    playheadPosition: number;
    currentTime: string;
    hotkeyContext: IHotkeyContext;
    playheadIsVisible = false;
    private width: number;
    currentCursor: string;
    isSocialCreative$: Observable<boolean>;

    unsubscribe$ = new Subject<void>();
    windowResize$: Observable<MouseEvent>;
    ElementKind = ElementKind;

    nodes: OneOfDataNodes[];
    nodes$: Observable<ITimelineNode[]>;

    selectedVersion: IVersion;

    get creative(): ICreativeDataNode {
        return this.editorStateService.document;
    }

    get duration(): number {
        return this.creative.duration;
    }

    get stopTime(): number {
        const stopTime = this.mutatorService.creativeDocument.getStopTime_m();
        return Math.max(0, stopTime);
    }

    get scrollViewportSize(): ISize {
        return {
            width: this.gizmoDrawer.width,
            height: this.gizmoDrawer.height - this.getTimelineHeaderHeight()
        };
    }

    get scrollContentSize(): ISize {
        return {
            width: this.secondsToPixels(this.duration) + SCROLL_PADDING_RIGHT,
            height: this.getContentHeight() + SCROLL_PADDING_BOTTOM
        };
    }

    get isZoomControlVisible(): boolean {
        if (this.zoomControl?.nativeElement) {
            const style = window.getComputedStyle(this.zoomControl.nativeElement);
            return style.display !== 'none' && style.opacity === '1';
        } else {
            return false;
        }
    }

    set leftPanelWidth(width: number) {
        this.setLeftPanelWidth(width);
    }

    get leftPanelWidth(): number {
        return this._leftPanelWidth$.getValue();
    }
    private _leftPanelWidth$ = new BehaviorSubject<number>(0);
    leftPanelWidth$ = this._leftPanelWidth$.asObservable();

    private _leftOffset$ = new BehaviorSubject<void>(undefined);
    leftOffset$ = merge(this._leftOffset$.asObservable(), this.leftPanelWidth$).pipe(
        map(() => this.timelineElementLeftOffset),
        takeUntil(this.unsubscribe$)
    );

    constructor(
        @Inject(forwardRef(() => DesignViewComponent))
        public editor: DesignViewComponent,
        public editorStateService: EditorStateService,
        public host: ElementRef<HTMLElement>,
        public hotkeyBetterService: HotkeyBetterService,
        private changeDetector: ChangeDetectorRef,
        private userSettingsService: UserSettingsService,
        private uiNotificationService: UINotificationService,
        private historyService: HistoryService,
        private mediaLibraryService: MediaLibraryService,
        public timelineTransformService: TimelineTransformService,
        public animationRecorderService: AnimationRecorderService,
        private keyframeService: KeyframeService,
        private animationService: AnimationService,
        private propertiesService: PropertiesService,
        public scrollService: TimelineScrollService,
        public zoomService: TimelineZoomService,
        private socialGuideService: SocialGuideService,
        private mutatorService: MutatorService,
        private elementSelectionService: ElementSelectionService,
        private editorEventService: EditorEventService,
        private timelineElementService: TimelineElementService,
        private versionsService: VersionsService,
        private environmentService: EnvironmentService
    ) {
        this.updateNodes();

        this.versionsService.selectedVersion$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(selectedVersion => {
                this.selectedVersion = selectedVersion;
            });

        merge(this.scrollService.scroll$, this.zoomService.zoom$)
            .pipe(auditTime(auditInterval), takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.onScroll();
                this.detectChanges();
                this.setPlayheadPosition();
            });

        merge(
            this.historyService.snapshotApply$,
            this.editorEventService.renderedCanvas$,
            this.editorEventService.creative.change$,
            this.editorEventService.elements.change$.pipe(
                changeFilter({
                    explicitProperties: ['animations', 'actions', 'masking']
                })
            )
        )
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.updateElements();
            });

        this.timelineElementService.toggleGroup$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(([tlComponent, nodeList]) =>
                this.updateCollapsedNodeStates(tlComponent, nodeList)
            );

        this.timelineElementService.toggleVisibility$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(nodeList => this.updateVisibilityNodeStates(nodeList));

        this.editorEventService.creative.change$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => this.updateNodes());

        this.elementSelectionService.change$
            .pipe(
                filter(elements => elements.length === 0),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => this.keyframeService.clear());

        this.nodes$ = this.timelineElementService.nodes$;

        this.host.nativeElement.addEventListener('wheel', this.mouseWheel, {
            capture: true,
            passive: false
        });

        this.editor.renderer.on('click', this.showClickthroughToast);
    }

    private updateNodes(): void {
        this.nodes = toFlatNodeList(this.creative);
        const mappedNodes = this.nodes
            .slice()
            .reverse()
            .map(node => {
                const isGroup = isGroupDataNode(node);
                const currentNode = this.timelineElementService.nodes.find(n => n.node.id === node.id);
                let collapsed = currentNode?.collapsed ?? false;
                const inHiddenNodeTree = currentNode?.inHiddenNodeTree ?? false;

                if (node.__parentNode) {
                    const timelineElements = this.timelineElementComponents?.toArray();
                    collapsed = this.hasCollapsedAncestor(node, timelineElements);
                }

                return {
                    collapsed,
                    node,
                    isGroup,
                    inHiddenNodeTree
                };
            });

        this.timelineElementService.setNodeList(mappedNodes);
    }

    private updateCollapsedNodeStates(
        tlComponent: TimelineElementComponent,
        nodeList: ITimelineNode[]
    ): void {
        if (!isGroupDataNode(tlComponent.node)) {
            return;
        }

        for (const nodeItem of nodeList) {
            const timelineElements = this.timelineElementComponents?.toArray();
            nodeItem.collapsed = this.hasCollapsedAncestor(nodeItem.node, timelineElements);
        }

        this.timelineElementService.setNodeList(nodeList);
        this.detectChanges();
    }

    private updateVisibilityNodeStates(nodeList: ITimelineNode[]): void {
        for (const nodeItem of nodeList) {
            nodeItem.inHiddenNodeTree = isHidden(nodeItem.node);
        }

        this.timelineElementService.setNodeList(nodeList);
        this.detectChanges();
    }

    private hasCollapsedAncestor(
        node: OneOfDataNodes,
        timelineElements?: TimelineElementComponent[]
    ): boolean {
        let hasCollapsedParent = false;

        forEachParentNode(node, parent => {
            const parentElement = timelineElements?.find(n => n.node.id === parent.id);

            if (parentElement?.collapsedGroup) {
                hasCollapsedParent = true;
                return true;
            }
        });

        return hasCollapsedParent;
    }

    setZoom = (zoom: number): void => {
        this.zoomService.setZoom(zoom);
    };

    private arrangeFront = (): void => this.arrange(ElementArrange.Front);
    private arrangeForward = (): void => this.arrange(ElementArrange.Forward);
    private arrangeBackward = (): void => this.arrange(ElementArrange.Backward);
    private arrangeBack = (): void => this.arrange(ElementArrange.Back);

    private onWindowMouseDown = (event: MouseEvent): void => {
        if (
            event.target === this.playButtonElement.nativeElement ||
            isElementDescendantOfElement(this.playButtonElement.nativeElement, event.target)
        ) {
            return;
        }

        if (this.animator.isPlaying) {
            if (
                event.target === this.backButtonElement.nativeElement ||
                isElementDescendantOfElement(this.backButtonElement.nativeElement, event.target)
            ) {
                return;
            }
            if (
                event.target === this.forwardButtonElement.nativeElement ||
                isElementDescendantOfElement(this.forwardButtonElement.nativeElement, event.target)
            ) {
                return;
            }
            if (!isElementDescendantOfElement(this.editor.renderer.rootElement, event.target)) {
                this.pause();
            }
        }
    };

    @HostListener('mousedown') onMouseDown(): void {
        this.mutatorService.workspaceFocused = true;
        this.editor.workspace.transform.cancel();
    }

    ngOnInit(): void {
        this.isSocialCreative$ = this.socialGuideService.isSocialCreative$;

        this.editorEventService.creative.change$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(change => {
                if (change.property === 'gifExport') {
                    this.updateMouseObservableOffsets();
                }
                this.updateElements();
                this.gizmoDrawer.draw();
            });

        this.hotkeyContext = {
            name: 'Timeline',
            input: window
        };
        this.hotkeyBetterService.placeBeforeContext('Workspace', this.hotkeyContext);
        this.hotkeyBetterService.on('PlayPause', this.togglePlay);
        this.hotkeyBetterService.on('MoveElementUp', this.arrangeForward);
        this.hotkeyBetterService.on('MoveElementFront', this.arrangeFront);
        this.hotkeyBetterService.on('MoveElementDown', this.arrangeBackward);
        this.hotkeyBetterService.on('MoveElementBack', this.arrangeBack);
        this.hotkeyBetterService.on('NudgeLeft', this.nudgeLeft);
        this.hotkeyBetterService.on('MoveLeft', this.moveLeft);
        this.hotkeyBetterService.on('NudgeRight', this.nudgeRight);
        this.hotkeyBetterService.on('MoveRight', this.moveRight);
        this.hotkeyBetterService.on('DeleteKeyframe', this.deleteKeyframes);
        this.hotkeyBetterService.on('RecordAnimation', this.toggleRecording);

        this.elementSelectionService.change$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(this.onSelectionChange);
        this.animator.on('seek', this.setPlayheadPosition);
        this.animator.on('tick', this.setPlayheadPosition);

        // In case it's triggered by a widget
        this.animator.on('play', this.play);
        this.animator.on('pause', this.pause);

        this.windowResize$ = fromEvent<MouseEvent>(window, 'resize').pipe(
            withLatestFrom(this.editorEventService.timelineInit$),
            filter(mergedValue => mergedValue[1]),
            map(([mouseEvent]) => mouseEvent),
            takeUntil(this.unsubscribe$)
        );

        this.windowResize$.subscribe(() => {
            this.onWindowResize();
            this.cacheTopOffset();
        });

        this.animationRecorderService.recording$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.detectChanges();
        });

        this.hotkeyBetterService.keyEvent$
            .pipe(
                filter(
                    keyEvent =>
                        !!keyEvent.up &&
                        keyEvent.keys.some(key => key === 'ArrowUp') &&
                        this.animator.isPlaying
                )
            )
            .subscribe(() => {
                this.restart();
            });

        this.setPlayheadPosition();
        this.setCurrentTimeText();
        this.changeDetector.markForCheck();
    }

    async ngAfterViewInit(): Promise<void> {
        this.defaultHeight = await firstValueFrom(this.userSettingsService.timelineHeight$);

        if (this.defaultHeight < this.minHeight) {
            this.collapsed = true;
        }
        this.gizmoDrawer = new TimelineGizmoDrawer(this, this.canvas.nativeElement);

        this.mouseObservable = new MouseObservable(this.host.nativeElement);
        this.updateMouseObservableOffsets();

        this.timelineTransformService.timeline = this;
        this.timelineTransformService.onInit();

        window.addEventListener('mousedown', this.onWindowMouseDown, { capture: true });

        this.scrollService.connect(this);
        this.zoomService.connect(this);

        this.width = this.host.nativeElement.offsetWidth;
        this.setLeftPanelMinWidth();

        // Set min/max zoom level
        this.setMinMaxZoom();

        // Set zoom to fit 11 seconds in the timeline + padding. Scale from 0 to not trigger scroll
        this.zoomService.setZoom(Math.round(this.width / 11), { x: 0, y: 0 });

        this.resizeTimeline(this.defaultHeight, true);

        this.mediaLibraryService.mediaLibraryState$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(mediaLibraryState => {
                setTimeout(() => {
                    this.width = this.host.nativeElement.offsetWidth;
                    this.toolbarOffset = mediaLibraryState.isOpen ? this.editor.toolbar.width : 0;
                    this.mediaLibraryWidth = mediaLibraryState.isOpen
                        ? this.editor.mediaLibraryWidth
                        : 0;
                    this._leftOffset$.next();
                });
            });

        this.leftOffset$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => this.updateMouseObservableOffsets());

        this.cacheTopOffset();

        this.gizmoDrawer.draw();
        this.gizmoDrawer.canvasSizeSet$.subscribe(() => {
            this.setPlayheadPosition();
        });

        this.editorStateService.timeline = this;
        this.updateElements();
        this.editorEventService.timelineInit();
    }

    private onWindowResize = (): void => {
        this.width = this.host.nativeElement.offsetWidth;
    };

    getElementId(_index: number, node: ITimelineNode): string {
        return node.node.id;
    }

    showClickthroughToast = ({ event, deepLinkUrl }: RendererEvents['click']): void => {
        const targetUrl = getTargetUrl(this.selectedVersion, this.editorStateService.creative);
        const resolvedTargetUrl = deepLinkUrl || targetUrl;
        if (resolvedTargetUrl) {
            if (this.environmentService.bfstudio.inPreviewMode || this.animator.isPlaying) {
                if (event instanceof Event) {
                    event.stopPropagation();
                }

                if (!this.isTargetUrlNotificationShown) {
                    this.uiNotificationService.open(
                        `Target URL was triggered (<a href="${resolvedTargetUrl}" target="_blank" rel="noopener noreferrer">open link</a>)`,
                        {
                            autoCloseDelay: 5000,
                            placement: 'top'
                        }
                    );
                }

                this.isTargetUrlNotificationShown = true;
                this.uiNotificationService.notificationRef.instance.onClose
                    .pipe(take(1))
                    .subscribe(() => {
                        this.isTargetUrlNotificationShown = false;
                    });
            }
        }
    };

    arrange(arr: ElementArrange): void {
        this.workspace.contextMenu.elementMenuComponent.arrange(arr);
    }

    mouseWheel = (event: WheelEvent): void => {
        event.preventDefault();
        if (event.deltaX !== 0) {
            this.detectChanges();
            this.setPlayheadPosition();
        }
    };

    ngOnDestroy(): void {
        this.animator.off('seek', this.setPlayheadPosition);
        this.animator.off('tick', this.setPlayheadPosition);
        this.animator.off('play', this.play);
        this.animator.off('pause', this.pause);
        this.gizmoDrawer.destroy();
        window.removeEventListener('mousedown', this.onWindowMouseDown, { capture: true });
        this.host.nativeElement.removeEventListener('wheel', this.mouseWheel);
        this.zoomService.disconnect();
        this.hotkeyBetterService.off('PlayPause', this.togglePlay);
        this.hotkeyBetterService.off('MoveElementUp', this.arrangeForward);
        this.hotkeyBetterService.off('MoveElementFront', this.arrangeFront);
        this.hotkeyBetterService.off('MoveElementDown', this.arrangeBackward);
        this.hotkeyBetterService.off('MoveElementBack', this.arrangeBack);
        this.hotkeyBetterService.off('RecordAnimation', this.toggleRecording);
        this.hotkeyBetterService.off('DeleteKeyframe', this.deleteKeyframes);
        this.hotkeyBetterService.off('NudgeLeft', this.nudgeLeft);
        this.hotkeyBetterService.off('MoveLeft', this.moveLeft);
        this.hotkeyBetterService.off('NudgeRight', this.nudgeRight);
        this.hotkeyBetterService.off('MoveRight', this.moveRight);

        this.hotkeyBetterService.popContext();
        this.environmentService.bfstudio.inPreviewMode = false;
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private elementHasChanged = (): void => {
        this.detectChanges();
        if (this.timelineTransformService) {
            this.timelineTransformService.timelineChange();
        }
    };

    /**
     * Detect changes in view. Allows being called inside timeout since
     * it checks if the component is destroyed before triggering.
     * Called by children due CD for @HostBinding being handled by
     * parent components.
     */
    detectChanges = (): void => {
        if (!this.changeDetector['destroyed']) {
            this.changeDetector.detectChanges();
            this.timelineElementComponents.forEach(elComp => elComp.detectChanges());
        }
    };

    private nudgeRight = (): void => this.moveKeyframe(0.01);
    private moveRight = (): void => this.moveKeyframe(0.1);
    private nudgeLeft = (): void => this.moveKeyframe(-0.01);
    private moveLeft = (): void => this.moveKeyframe(-0.1);

    private moveKeyframe(deltaTime: number): void {
        if (this.elementSelectionService.latestSelectionType === 'keyframe') {
            this.keyframeService.nudgeMoveKeyframes$.next(deltaTime);
        }
    }

    deleteKeyframes = (): void => {
        if (this.elementSelectionService.latestSelectionType === 'keyframe') {
            this.workspace.contextMenu.animationMenuComponent.deleteKeyframes();
        }
    };

    getAnimationBarBounds(element: OneOfElementDataNodes, withOffset?: boolean): IBounds {
        let yOffset = 0;
        if (withOffset) {
            yOffset = HEIGHT_OFFSET;
        }

        return {
            x: this.secondsToScrolledPixels(element.time),
            y: this.nodes.indexOf(element) * TIMELINE_ELEMENT_HEIGHT - this.scroll.y + yOffset,
            width: this.secondsToPixels(element.duration),
            height: TIMELINE_ELEMENT_HEIGHT
        };
    }

    getInAnimationBounds(element: OneOfElementDataNodes): IBounds {
        const animations = element.animations;
        const inDuration = getInAnimationDuration(animations);
        const outDuration = getOutAnimationDuration(animations);
        const barBounds = this.getAnimationBarBounds(element);
        let width = 0;

        if (inDuration) {
            width = Math.max(this.secondsToPixels(inDuration), 0);
        } else if (!inDuration && !outDuration) {
            const diff = element.duration / ((DEFAULT_ANIMATION_TEMPLATE_LENGTH + 0.01) * 2);
            width = this.secondsToPixels(
                Math.min(DEFAULT_ANIMATION_TEMPLATE_LENGTH * diff, DEFAULT_ANIMATION_TEMPLATE_LENGTH)
            );
        } else {
            const outTransitionDuration = outDuration + 0.01;

            width = Math.max(
                this.secondsToPixels(
                    Math.min(
                        DEFAULT_ANIMATION_TEMPLATE_LENGTH,
                        DEFAULT_ANIMATION_TEMPLATE_LENGTH -
                            (DEFAULT_ANIMATION_TEMPLATE_LENGTH +
                                outTransitionDuration -
                                element.duration)
                    )
                ),
                0
            );
        }

        return {
            x: barBounds.x,
            y: 0,
            width: width,
            height: TIMELINE_ELEMENT_HEIGHT
        };
    }

    getOutAnimationBounds(element: OneOfElementDataNodes): IBounds {
        const animations = element.animations;
        const inDuration = getInAnimationDuration(animations);
        const outDuration = getOutAnimationDuration(animations);
        const barBounds = this.getAnimationBarBounds(element);
        let x: number;
        let width: number;

        if (outDuration) {
            width = Math.max(this.secondsToPixels(outDuration), 0);
            x = barBounds.x + this.secondsToPixels(element.duration - outDuration);
        } else if (!outDuration && !inDuration) {
            const diff = element.duration / ((DEFAULT_ANIMATION_TEMPLATE_LENGTH + 0.01) * 2);
            width = this.secondsToPixels(
                Math.min(DEFAULT_ANIMATION_TEMPLATE_LENGTH * diff, DEFAULT_ANIMATION_TEMPLATE_LENGTH)
            );
            x = barBounds.x + (this.secondsToPixels(element.duration) - width);
        } else {
            const inTransitionDuration = inDuration + 0.01;

            width = Math.max(
                this.secondsToPixels(
                    Math.min(
                        DEFAULT_ANIMATION_TEMPLATE_LENGTH,
                        DEFAULT_ANIMATION_TEMPLATE_LENGTH -
                            (DEFAULT_ANIMATION_TEMPLATE_LENGTH +
                                inTransitionDuration -
                                element.duration)
                    )
                ),
                0
            );
            x = barBounds.x + (this.secondsToPixels(element.duration) - width);
        }

        return {
            x,
            y: 0,
            width,
            height: TIMELINE_ELEMENT_HEIGHT
        };
    }

    getLocalPosition(): IPosition {
        const boundingRect = this.host.nativeElement.getBoundingClientRect();

        return {
            x: boundingRect.left,
            y: boundingRect.top
        };
    }

    setCurrentTimeText(): void {
        if (this.animator.isPlaying) {
            this.currentTime = (Math.floor(this.animator.time * 10) / 10).toFixed(2);
        } else {
            this.currentTime = this.animator.time.toFixed(2);
        }
    }

    /**
     * Set duration of creative. Skip passing value to reset input field to previous state
     */
    setDuration(input: string | undefined): void {
        let duration = this.duration;
        if (input) {
            const parsedInput = parseFloat(input.replace(/[,:;]/g, '.'));

            if (!isNaN(parsedInput)) {
                duration = parsedInput;
            }
        }
        duration = clamp(duration, MIN_ELEMENT_DURATION, MAX_TIMELINE_DURATION);
        this.mutatorService.setCreativeDuration(duration);
        this.durationInput.writeValue(duration.toFixed(2));
        this.animator.render_m();
    }

    drawDurationLine(): void {
        if (this.nodes.length > 0) {
            const x =
                Math.min(Math.max(this.secondsToPixels(this.duration) - this.scroll.x, 0), this.width) +
                this.leftPanelWidth;

            const color = this.timelineTransformService.durationLineActive
                ? GizmoColor.border()
                : '#efefef';

            const el = this.elementsContainer.nativeElement;
            el.style.setProperty('--durationLineX', `${x}px`);
            el.style.setProperty('--durationLineColor', color);
        }
    }

    play = (): void => {
        this.mutatorService.renderer.feedStore?.skipNextIndexUpdate(false);
        this.editor.renderer.rootElement.classList.add('playing');
        const element = this.elementSelectionService.currentSelection.element;
        if (element) {
            this.editor.renderer.clearAdditionalStates_m();
            this.editor.renderer.setViewElementValues_m(element, this.editor.renderer.time_m);
        }
        this.animator.play();
        this.workspace.contextMenu.tryCloseMenus();
        this.workspace.gizmoDrawer.drawElementGizmos = false;
        this.workspace.gizmoDrawer.draw();
        this.environmentService.bfstudio.inPreviewMode = true;
    };

    togglePlay = (event: MouseEvent): void => {
        event?.stopPropagation();
        if (this.workspace.wasPanning || this.creative.elements.length === 0) {
            return;
        }
        this.animationRecorderService.stopRecording();
        if (this.animator.isPlaying) {
            this.pause();
            this.resetFeededElements();
        } else {
            this.play();
        }
    };

    pause = (): void => {
        this.animator.pause();
        this.editor.rerenderNode(this.elementSelectionService.currentSelection.element);
        this.workspace.gizmoDrawer.drawElementGizmos = true;
        this.workspace.gizmoDrawer.draw();

        this.editor.renderer.rootElement.classList.remove('playing');
        this.environmentService.bfstudio.inPreviewMode = false;
        this.resetFeededElements();

        this.indexSnapPoints();
        this.detectChanges();
        this.animationService.pause$.next();
    };

    resetFeededElements(): void {
        const renderer = this.mutatorService.renderer;
        this.animator.loop = 1;
        renderer.feedStore?.resetIndexState();
        renderer.feedStore?.skipNextIndexUpdate();
        renderer.setAllViewElementsValues_m(this.animator.time, true);
    }

    stepBack(event: MouseEvent): void {
        event.stopPropagation();

        if (this.animator.isPlaying) {
            this.restart();
            return;
        }
        const snap = this.getSnapPoints(this.animator.time - 0.05, 0, {
            ignorePlayhead: true,
            direction: 'backward',
            tolerance: Infinity
        });
        this.seek(snap?.time ?? 0);
    }

    stepForward(event: MouseEvent): void {
        event.stopPropagation();

        const snap = this.getSnapPoints(this.animator.time + 0.05, 0, {
            ignorePlayhead: true,
            direction: 'forward',
            tolerance: Infinity
        });
        this.seek(snap?.time ?? this.duration);
    }

    restart(): void {
        this.animator.restart();
        this.changeDetector.detectChanges();
    }

    seek(time?: string | number): void {
        if (time === undefined) {
            time = this.animator.time;
        } else if (typeof time === 'string') {
            time = parseFloat(time.replace(/[,:;]/g, '.'));
        }

        this.animator.seek(time);
        this.durationInput.writeValue(this.duration.toFixed(2));
        this.scrollToTime();
        this.workspace.gizmoDrawer.draw();
    }

    toggle(): void {
        if (this.collapsed) {
            this.collapsed = false;
        } else {
            if (this.getHostHeight() !== this.collapsedHeight) {
                this.resizeExpandedHeight = this.getHostHeight();
            }

            this.collapsed = true;
        }

        const toggledHeight = this.collapsed
            ? this.collapsedHeight
            : this.resizeExpandedHeight || this.defaultHeight;
        this.host.nativeElement.style.height = `${toggledHeight}px`;
    }

    toggleRecording = (event?: MouseEvent): void => {
        event?.stopPropagation();

        if (this.isRecordingKeyframes) {
            this.animationRecorderService.stopRecording();
            this.detectChanges();
            return;
        }

        if (this.animator.isPlaying) {
            this.pause();
        }

        this.animationRecorderService.startRecording();
        this.detectChanges();
    };

    zoomIn(event: MouseEvent): void {
        event.stopPropagation();
        event.preventDefault();
        this.zoomService.zoomIn();
    }

    zoomOut(event: MouseEvent): void {
        event.stopPropagation();
        event.preventDefault();
        this.zoomService.zoomOut();
    }

    indexSnapPoints(): void {
        this.unindexElementPoints();

        this.snapPoints.insert(0, { crossValue: 0 }, SnapPointsId.Default);
        this.snapPoints.insert(this.duration, { crossValue: 0 }, SnapPointsId.Default);
        this.snapPoints.insert(this.animator.time, { crossValue: 0 }, SnapPointsId.Playhead);

        if (this.creative.loops > 0 && this.creative.stopTime) {
            this.snapPoints.insert(this.stopTime, { crossValue: 0 }, SnapPointsId.Default);
        }

        this.snapPoints.insert(
            this.creative.getFirstPreloadImageFrame(),
            { crossValue: 0 },
            SnapPointsId.Default
        );

        forEachDataElement(this.mutatorService.renderer.creativeDocument, this.indexElementSnapPoints);
    }

    private indexElementSnapPoints = (element: OneOfElementDataNodes): void => {
        const isMutating =
            this.timelineTransformService.actionMode === ActionMode.ScaleElement ||
            this.timelineTransformService.actionMode === ActionMode.MoveElement ||
            this.timelineTransformService.actionMode === ActionMode.ScaleTransition;

        if (this.elementSelectionService.currentSelection.has(element) && isMutating) {
            return;
        }

        const animations = element.animations;
        const index = 0;

        // Only add keyframes as snapPoints if element is expanded in timeline
        if (this.timelineElementComponents.some(comp => comp.expanded && comp.node.id === element.id)) {
            getAllKeyframes(element).forEach(({ time, duration }) => {
                if (time && time < element.duration) {
                    this.snapPoints.insert(element.time + time, { crossValue: 0 }, index);
                }
                if (duration && time + duration < element.duration) {
                    this.snapPoints.insert(element.time + time + duration, { crossValue: 0 }, index);
                }
            });
        }
        // If collapsed, add points for in/out animations
        else {
            const inDuration = getInAnimationDuration(animations);
            const outDuration = getOutAnimationDuration(animations);

            if (inDuration) {
                this.snapPoints.insert(element.time + inDuration, { crossValue: 0 }, index);
            }
            if (outDuration) {
                this.snapPoints.insert(
                    element.time + element.duration - outDuration,
                    { crossValue: 0 },
                    index
                );
            }
        }

        // Always add start and end of element
        this.snapPoints.insert(element.time, { crossValue: 0 }, index);
        this.snapPoints.insert(element.time + element.duration, { crossValue: 0 }, index);
    };

    private unindexElementPoints(): void {
        this.snapPoints.clear();
    }

    /**
     * Gey the snap point closest to any of the provided points
     * @param edges A list of points which in this cases is the edges we want to snap
     * @param tolerance How far in time a snapPoint must be to an edge to snap
     * @param snapFilter A custom filter to make more advanced snap operations
     * @returns
     */
    private getSnapPointClosestToEdges(
        edges: IterableIterator<IQueryPoint>,
        tolerance: number,
        snapFilter?: SnapFilter
    ): ITimeSnapPoint | undefined {
        const index = this.snapPoints;

        let closestDistance = Infinity;
        let closestPoint: IPointResult | undefined;
        for (const edge of edges) {
            const item = index.closest(edge.value, tolerance, snapFilter);
            if (item && item.distance < closestDistance) {
                closestDistance = item.distance;
                closestPoint = {
                    item,
                    point: edge
                };
            }
        }

        if (closestPoint) {
            const item = closestPoint.item;
            const point = closestPoint.point;
            return {
                time: item.key,
                offset: point.offset
            };
        }
    }

    getSnapPoints(time: number, duration = 0, options: ISnapOptions = {}): ITimeSnapPoint | undefined {
        options = { ...snapDefaults, ...omitUndefined(options) };

        const edges: ElementEdges = {};
        if (options.snapToStart) {
            edges.left = {
                time,
                offset: 0
            };
        }
        if (options.snapToEnd && duration > 0) {
            edges.right = {
                time: time + duration,
                offset: duration
            };
        }

        // Nothing to snap to
        if (!edges.right && !edges.left) {
            return;
        }

        // Make list of points from edges. Basically "Which points to snap"
        const edgePoints = this.getPositionToCrossValuesMap(edges).values();
        const tolerance = this.pixelsToSeconds(options.tolerance ?? 0);
        const firstTime = edges.left?.time ?? edges.right?.time ?? 0;
        const lastTime = edges.right?.time ?? edges.left?.time ?? 0;
        const { direction, ignorePlayhead } = options;
        let snapFilter: SnapFilter | undefined;

        // Only run filter if needed
        if (direction || ignorePlayhead) {
            snapFilter = (item): boolean => {
                if (direction === 'forward' && item.key < firstTime) {
                    return false;
                }
                if (direction === 'backward' && item.key > lastTime) {
                    return false;
                }
                return !ignorePlayhead || item.values[0]?.id !== SnapPointsId.Playhead;
            };
        }

        return this.getSnapPointClosestToEdges(edgePoints, tolerance, snapFilter);
    }

    private getPositionToCrossValuesMap(elementEdges: ElementEdges): Map<number, IQueryPoint> {
        const timeToCrossValuesMap = new Map<number, IQueryPoint>();
        const xPoints: number[] = [];
        for (const name in elementEdges) {
            const edge = elementEdges[name];
            const time = edge.time;
            const queryPoint = timeToCrossValuesMap.get(time);
            if (!queryPoint) {
                timeToCrossValuesMap.set(time, {
                    value: time,
                    offset: -edge.offset,
                    crossValues: []
                });
            }
            xPoints.push(time);
        }

        return timeToCrossValuesMap;
    }

    updateElements = (): void => {
        this.updateNodes();
        this.setMinMaxZoom();
        this.indexSnapPoints();
        this.elementHasChanged();
    };

    pixelsToSeconds(pixels: number): number {
        return this.zoomService.pixelsToSeconds(pixels);
    }

    secondsToPixels(seconds: number): number {
        return this.zoomService.secondsToPixels(seconds);
    }

    secondsToScrolledPixels(seconds: number): number {
        return this.secondsToPixels(seconds) - this.scroll.x;
    }

    /**
     * Get seconds from a position which has not taken scroll in account.
     */
    scrolledPixelsToSeconds(pixelsWithoutScroll: number): number {
        return this.pixelsToSeconds(pixelsWithoutScroll + this.scroll.x);
    }

    setPlayheadPosition = (): void => {
        const time = this.animator.time;
        const position = this.secondsToScrolledPixels(time) + this.leftPanelWidth;
        if (position < this.leftPanelWidth || position > this.width - ZOOM_WIDTH) {
            if (this.playheadIsVisible) {
                this.playheadIsVisible = false;
            }
        } else if (!this.playheadIsVisible) {
            this.playheadIsVisible = true;
        }

        this.playheadPosition = position;
        this.animationService.seek$.next(time);
        this.setCurrentTimeText();
        this.detectChanges();
    };

    stopTimePosition(): number {
        return this.secondsToScrolledPixels(this.stopTime);
    }

    preloadFramePosition(frame: number): number {
        return this.secondsToScrolledPixels(frame);
    }

    onStartResizeTimeline(event: MouseEvent): void {
        event.stopPropagation();
        this.resizeStartPosition = event.pageY;
        this.resizeStartHeight = this.getHostHeight();

        this.host.nativeElement.classList.add('resizing');
        document.addEventListener('mousemove', this.onResizePreview);
        document.addEventListener('mouseup', this.onStopResizePreview);

        setCursorOnElement(document.body, `resize-90`);
    }

    private onResizePreview = (event: MouseEvent): void => {
        const newHeight = this.resizeStartHeight + (this.resizeStartPosition - event.pageY);
        this.resizeTimeline(newHeight);
    };

    private onStopResizePreview = (): void => {
        const currentHeight = this.getHostHeight();

        if (currentHeight === this.collapsedHeight) {
            this.collapsed = true;
        } else {
            this.collapsed = false;
            this.resizeExpandedHeight = currentHeight;
        }

        this.host.nativeElement.classList.remove('resizing');
        document.removeEventListener('mousemove', this.onResizePreview);
        document.removeEventListener('mouseup', this.onStopResizePreview);

        this.userSettingsService.setDesignViewSetting('timelineHeight', currentHeight);
        setCursorOnElement(document.body);
    };

    private resizeTimeline(newHeight: number, isInitial?: boolean): void {
        if (isInitial) {
            this.host.nativeElement.style.transition = 'none';
        }

        const maxHeight = window.innerHeight - 50; // 50 is height of ui-header
        const currentHeight = this.getHostHeight();
        const minHeight =
            currentHeight === this.collapsedHeight ? this.collapsedHeight : this.minHeight;
        let height = 0;
        if (newHeight < this.minHeight) {
            height = this.collapsedHeight;
            this.collapsed = true;
        } else {
            height = clamp(newHeight, minHeight, maxHeight);
            this.collapsed = false;
        }

        this.host.nativeElement.style.height = `${height}px`;
        this.height = height;
        this.cacheTopOffset();

        if (isInitial) {
            setTimeout(() => (this.host.nativeElement.style.transition = ''));
        } else {
            this.timelineTransformService.timelineChange();
        }
    }

    getHostHeight(): number {
        return parseInt(
            window.getComputedStyle(this.host.nativeElement, null).getPropertyValue('height'),
            10
        );
    }

    private cacheTopOffset(): void {
        this.top = this.host.nativeElement.getBoundingClientRect().top;
        this.updateMouseObservableOffsets();
    }

    private updateMouseObservableOffsets(): void {
        this.mouseObservable.setOffsets({
            x: this.timelineElementLeftOffset,
            y: this.top
        });
    }

    setCursor(cursor = 'default'): void {
        if (this.currentCursor !== cursor) {
            this.currentCursor = cursor;
            this.editor.setCursor(this.host.nativeElement, cursor);
        }
    }

    shouldScrollbarShow(): { horizontal: boolean; vertical: boolean } {
        return {
            horizontal: this.scrollContentSize.width > this.scrollViewportSize.width,
            vertical: this.scrollContentSize.height > this.scrollViewportSize.height
        };
    }

    /**
     * Make sure a specific time is in view on timeline.
     * @param time
     */
    scrollToTime(time?: number): void {
        if (time === undefined) {
            time = this.animator.time;
        }
        if (this.shouldScrollbarShow().horizontal && !this.timeInView(time)) {
            const timePixels = this.secondsToPixels(time);
            const margin = 50;

            // Playhead/time is outside the view on the right side
            if (timePixels >= this.scroll.x + this.scrollViewportSize.width) {
                this.scrollService.setScroll({
                    x: timePixels - (this.scroll.x + this.scrollViewportSize.width) + margin
                });
            }
            // Left side
            else {
                this.scrollService.setScroll({
                    x: timePixels - margin
                });
            }
        }
    }

    timeInView(time: number): boolean {
        const timePixels = this.secondsToPixels(time);
        return (
            this.scroll.x <= timePixels && this.scroll.x + this.scrollViewportSize.width >= timePixels
        );
    }

    private onScroll = (): void => {
        this.elementsContainer.nativeElement.style.transform = `translateY(-${this.scroll.y}px)`;
        this.gizmoDrawer.draw();
    };

    /**
     * Get height of timeline ruler plus Gif-frames if open.
     */
    getTimelineHeaderHeight(): number {
        return TIMERULER_HEIGHT * (this.creative.gifExport.show ? 2 : 1);
    }

    getHorizontalScrollbarBounds(): IBounds {
        const barWidth =
            this.scrollViewportSize.width *
            (this.scrollViewportSize.width / this.scrollContentSize.width);
        const margin = { x: 5, y: 8 };

        return {
            x:
                Math.min(
                    (this.scroll.x / this.scrollContentSize.width) * this.scrollViewportSize.width,
                    this.scrollViewportSize.width - barWidth
                ) + margin.x,
            y: this.height - (SCROLLBAR_TRACK_WIDTH + SCROLLBAR_HANDLE_WIDTH) / 2,
            width: Math.max(barWidth - margin.x * 2, 15),
            height: SCROLLBAR_HANDLE_WIDTH
        };
    }

    getVerticalScrollbarBounds(): IBounds {
        const barHeight =
            this.scrollViewportSize.height *
            (this.scrollViewportSize.height / this.scrollContentSize.height);
        const margin = { x: 4, y: 5 };

        return {
            x: this.scrollViewportSize.width - (SCROLLBAR_TRACK_WIDTH + SCROLLBAR_HANDLE_WIDTH) / 2, // Why + 12?
            y:
                (this.scroll.y / this.scrollContentSize.height) * this.scrollViewportSize.height +
                this.getTimelineHeaderHeight() +
                margin.y,
            width: SCROLLBAR_HANDLE_WIDTH,
            height: Math.max(barHeight - margin.y * 2, 15)
        };
    }

    openAnimationMenu(
        type: 'in' | 'out',
        mousePosition: IPosition,
        timelineElement: TimelineElementComponent
    ): void {
        const node = timelineElement.node;
        if (isGroupDataNode(node)) {
            return;
        }

        if (!this.isZooming) {
            const animationTemplates = cloneDeep(
                type === 'in' ? inAnimationTemplates : outAnimationTemplates
            ) as IAnimationTemplate[];
            this.animationTemplates = animationTemplates.sort(
                (a: IAnimationTemplate, b: IAnimationTemplate) => (a.name < b.name ? -1 : 1)
            );

            if (node) {
                const templateId = getAnimationsOfType(node, type).find(
                    animation => animation.templateId
                )?.templateId;
                this.selectedElementAnimations = { type, element: node, templateId };
                this.transitionAnimationMenuTrigger['hostElementRef'].nativeElement.style.top =
                    `${mousePosition.y}px`;
                this.transitionAnimationMenuTrigger['hostElementRef'].nativeElement.style.left =
                    `${mousePosition.x}px`;
                this.transitionAnimationMenuTrigger.openDropdown();
                this.transitionAnimationMenuTrigger.dropdownClosed.pipe(take(1)).subscribe(() => {
                    timelineElement.onCloseTransitionDropdown();
                });
            }
        }

        this.changeDetector.detectChanges();
    }

    setTransitionAnimationOnElement(animationTemplate: IAnimationTemplate): void {
        const type = animationTemplate.type;
        const element = this.elementSelectionService.currentSelection.element;
        if (!element) {
            throw new Error('No selection found when setting transition of animation on element');
        }

        let time = getAnimationDurationOfType(element.animations, type);

        // If no animation is applied, get width of "empty state"
        if (!hasAnimationsOfType(element, type)) {
            const bounds =
                type === 'in'
                    ? this.getInAnimationBounds(element)
                    : this.getOutAnimationBounds(element);
            time = this.pixelsToSeconds(bounds.width);
        }

        this.mutatorService.applyAnimationTemplateOnElement(
            animationTemplate,
            element,
            time,
            ElementChangeType.Instant
        );
        this.selectedElementAnimations = {
            type,
            element,
            templateId: animationTemplate.id
        };

        if (this.keyframeService.keyframes.size > 0) {
            this.keyframeService.clear();
            this.propertiesService.selectedStateChange$.next(undefined);
        }

        this.editorEventService.elements.change(element, {
            animations: element.animations
        });

        this.timelineTransformService.timelineChange(this.selectedElementAnimations.element);
        this.gizmoDrawer.draw();
        this.workspace.gizmoDrawer.draw();
    }

    setAnimationWithSettingOnElement(settings: Omit<IAnimationSettings, 'distance'>): void {
        if (this.elementSelectionService.currentSelection.element && this.currentAnimationTemplate) {
            const animation = { ...this.currentAnimationTemplate };
            animation.settings = {
                ...animation.settings,
                ...settings
            };
            this.setTransitionAnimationOnElement(animation);
        }
        this.currentAnimationTemplate = undefined;
    }

    setCurrentTemplate(template?: IAnimationTemplate): void {
        this.currentAnimationTemplate = template;
    }

    clearAnimation(type?: AnimationType): void {
        const element = this.elementSelectionService.currentSelection.element;
        if (element && type) {
            this.mutatorService.removeAnimationTypeOnElement(type, element);
            this.editorEventService.elements.change(element, {
                animations: element.animations
            });
        }
        this.timelineTransformService.timelineChange(element);
    }

    private onSelectionChange = (): void => {
        this.editor.workspace.transform.onGuidelineChange$.next(undefined);
    };

    private setMinMaxZoom(): void {
        const timelineWidth = this.host.nativeElement.offsetWidth;
        const doubleTimelineDuration = timelineWidth / (this.duration * 2);
        this.zoomService.maxZoom = Math.min(this.zoomService.zoom + 200, 1000);
        this.zoomService.minZoom = Math.min(
            Math.max(doubleTimelineDuration, 30),
            Math.round(timelineWidth / 11)
        );
    }

    setLeftPanelWidth(width?: number): void {
        width = width ?? this.minLeftPanelWidth;
        width = Math.min(Math.max(width, this.minLeftPanelWidth), this.width / 2);

        if (width !== this.leftPanelWidth) {
            this.host.nativeElement.style.setProperty('--left-panel-width', `${width}px`);
            this._leftPanelWidth$.next(width);
        }
    }

    setLeftPanelMinWidth(): void {
        let featureCount = 0;
        for (const element of this.editorStateService.document.elements) {
            const features: Set<Feature> = new Set();
            if (hasFeededContent(element)) {
                features.add(Feature.Feeds);
            }

            if (element.actions.length) {
                features.add(Feature.Actions);
            }

            if (element.animations.length) {
                features.add(Feature.Animations);
            }

            featureCount = features.size;

            if (features.size === 3) {
                break;
            }
        }

        const featuresWidth = featureCount * 24;

        this.minLeftPanelWidth = DEFAULT_LEFTPANEL_WIDTH + featuresWidth;

        if (this.minLeftPanelWidth > this.leftPanelWidth) {
            this.setLeftPanelWidth(this.minLeftPanelWidth);
        }
    }

    private getContentHeight(): number {
        let rows = this.nodes.length;

        if (this.timelineElementComponents.length === rows) {
            this.timelineElementComponents.forEach(el => {
                if (el.expanded) {
                    rows += el.animations.length;
                }
            });
        }
        return rows * TIMELINE_ELEMENT_HEIGHT;
    }
}

const snapDefaults: Readonly<ISnapOptions> = {
    ignorePlayhead: false,
    snapToStart: true,
    snapToEnd: true,
    tolerance: SNAP_TOLERANCE
};

interface ISnapOptions {
    /**
     * If the playhead should be included as a snap point or not
     */
    ignorePlayhead?: boolean;

    /**
     * If an elements start position (element.time)
     * should snap to nearest snap point
     */
    snapToStart?: boolean;

    /**
     * If an elements end position (element.time + element.duration)
     * should snap to nearest snap point
     */
    snapToEnd?: boolean;

    /**
     * If an element can snap only to a certain direction.
     * 'forward' means that the element should only be allowed to snap
     * to points forward in time.
     */
    direction?: 'backward' | 'forward';

    /**
     * Tolerance in pixels that should activate snapping
     */
    tolerance?: number;
}

type SnapFilter = BTreeFilter<ICrossValue>;

interface ITimeSnapPoint {
    time: number;
    offset: number;
}

interface ISelectedElementAnimations {
    element: OneOfElementDataNodes;
    type: AnimationType;
    templateId?: string;
}

export enum Feature {
    Feeds = 'feeds',
    Actions = 'actions',
    Animations = 'animations'
}
