import { IHotkey } from '@domain/hotkeys/hotkeys.types';

export const WorkspaceHotkeys: IHotkey[] = [
    {
        command: 'EnableZoom',
        keyCombination: 'Z',
        context: 'Workspace',
        meta: { description: 'Hold Z to enable the zoom-in tool', shortcut: 'Z (Hold)' }
    },
    {
        command: 'EnableZoomOut',
        keyCombination: 'Alt + Z',
        context: 'Workspace',
        meta: {
            description: 'Hold Z and AltKey to enable the zoom-out tool',
            shortcut: { mac: '⌥ + Z (Hold)', win: 'Alt + Z (Hold)' }
        }
    },
    {
        command: 'DisableZoom',
        keyCombination: { win: 'Z(KeyUp)', mac: 'Z(KeyUp)' },
        context: 'Workspace',
        meta: { description: 'Release the Z key to disable the zoom-tool', shortcut: 'Z (KeyUp)' }
    },
    {
        command: 'DisableZoom',
        keyCombination: { win: 'Alt + Z(KeyUp)', mac: 'Alt + Z(KeyUp)' },
        context: 'Workspace',
        meta: undefined
    },
    {
        command: 'ZoomIn',
        keyCombination: 'Z + Click',
        context: 'Workspace',
        meta: { description: 'Zoom in', shortcut: 'Z + click' }
    },
    {
        command: 'ZoomIn',
        keyCombination: { win: 'Ctrl + Plus', mac: 'Cmd + Plus' },
        context: 'Workspace',
        category: ['Timeline', 'Design view'],
        meta: {
            description: 'Zoom in',
            shortcut: { mac: '⌘ + Plus or Z + click', win: 'Ctrl + Plus or Z + click' }
        }
    },
    {
        command: 'ZoomOut',
        keyCombination: { win: 'Ctrl + Minus', mac: 'Cmd + Minus' },
        context: 'Workspace',
        meta: { description: 'Zoom out', shortcut: { mac: '⌘ + Minus', win: 'Ctrl + Minus' } }
    },
    {
        command: 'ZoomOut',
        keyCombination: 'Alt + Z + Click',
        context: 'Workspace',
        category: ['Timeline', 'Design view'],
        meta: {
            description: 'Zoom out',
            shortcut: { mac: '⌘ + Minus or ⌥ + Z + click', win: 'Ctrl + Minus or Alt + Z + click' }
        }
    },
    {
        command: 'ZoomWheel',
        keyCombination: { win: 'Ctrl + Wheel', mac: 'Cmd + Wheel' },
        context: 'Workspace',
        category: ['Timeline', 'Design view'],
        meta: { description: 'Zoom with scroll', shortcut: { mac: '⌘ + scroll', win: 'Ctrl + scroll' } }
    },
    {
        command: 'ZoomPreview',
        keyCombination: '§',
        context: 'Workspace',
        category: 'Design view',
        meta: { description: 'Hold § to preview the creative at 100%', shortcut: '§ (Hold)' }
    },
    {
        command: 'ZoomPreviewEnd',
        keyCombination: '§(KeyUp)',
        context: 'Workspace',
        meta: { description: 'Release § to go back to previous zoom percentage', shortcut: '§ (KeyUp)' }
    },
    {
        command: 'ZoomToBox',
        keyCombination: 'Z(DoublePress)',
        context: 'Workspace',
        category: 'Design view',
        meta: { description: 'Double press Z zooms to selection box', shortcut: 'Z (DoublePress)' }
    },
    {
        command: 'ZoomReset',
        keyCombination: { win: 'Ctrl + 0', mac: 'Cmd + 0' },
        context: 'Workspace',
        category: 'Design view',
        meta: { description: 'Set zoom to 100%', shortcut: { mac: '⌘ + 0', win: 'Ctrl + 0' } }
    },
    {
        command: 'PanOn',
        keyCombination: 'Spacebar',
        context: 'Workspace',
        category: ['Design view', 'Timeline'],
        meta: { description: 'Hold down spacebar to enable pan-tool', shortcut: 'Spacebar (Hold)' }
    },
    {
        command: 'PanOff',
        keyCombination: 'Spacebar(KeyUp)',
        returnCommandImmediately: false,
        context: 'Workspace',
        meta: { description: 'Release spacebar to disable the pan-tool', shortcut: 'Spacebar (KeyUp)' }
    },
    {
        command: 'CreateTextElement',
        keyCombination: 'T',
        context: 'Workspace',
        category: 'Toolbar',
        meta: { description: 'Select the text-tool', shortcut: 'T' }
    },
    {
        command: 'CreateButtonElement',
        keyCombination: 'B',
        context: 'Workspace',
        category: 'Toolbar',
        meta: { description: 'Select the button-tool', shortcut: 'B' }
    },
    {
        command: 'CreateRectangleElement',
        keyCombination: 'R',
        context: 'Workspace',
        category: 'Toolbar',
        meta: { description: 'Select the rectangle-tool', shortcut: 'R' }
    },
    {
        command: 'CreateEllipseElement',
        keyCombination: 'E',
        context: 'Workspace',
        category: 'Toolbar',
        meta: { description: 'Select the ellipse-tool', shortcut: 'E' }
    },
    {
        command: 'ToggleSelectionTool',
        keyCombination: 'V',
        context: 'Workspace',
        category: 'Toolbar',
        meta: { description: 'Enable the selection-tool', shortcut: 'V' }
    },
    {
        command: 'GroupElements',
        keyCombination: { win: 'Ctrl + G', mac: 'Cmd + G' },
        context: 'Workspace',
        category: 'Edit elements',
        meta: { description: 'Group selected element', shortcut: { mac: '⌘ + G', win: 'Ctrl + G' } }
    },
    {
        command: 'UngroupElements',
        keyCombination: { win: 'Ctrl + Shift + G', mac: 'Cmd + Shift + G' },
        context: 'Workspace',
        category: 'Edit elements',
        meta: {
            description: 'Ungroup selected element',
            shortcut: { mac: '⌘ + Shift + G', win: 'Ctrl + Shift + G' }
        }
    },
    {
        command: 'ToggleMask',
        keyCombination: { win: 'Ctrl + M', mac: 'Cmd + M' },
        context: 'Workspace',
        category: 'Edit elements',
        meta: {
            description: 'Toggle mask on selected elements',
            shortcut: { mac: '⌘ + M', win: 'Ctrl + M' }
        }
    },
    {
        command: 'ToggleLock',
        keyCombination: { win: 'Ctrl + L', mac: 'Cmd + L' },
        context: 'Workspace',
        category: 'Edit elements',
        meta: {
            description: 'Toggle lock on selected elements',
            shortcut: { mac: '⌘ + L', win: 'Ctrl + L' }
        }
    },
    {
        command: 'ToggleVisibility',
        keyCombination: { win: 'Ctrl + H', mac: 'Cmd + H' },
        context: 'Workspace',
        category: 'Edit elements',
        meta: {
            description: 'Toggle visibility on selected elements',
            shortcut: { mac: '⌘ + H', win: 'Ctrl + H' }
        }
    },
    {
        command: 'DeleteElement',
        keyCombination: 'Delete',
        context: 'Workspace',
        category: 'Edit elements',
        meta: { description: 'Delete selected', shortcut: 'Delete or Backspace' },
        returnCommandImmediately: false
    },
    {
        command: 'DeleteElement',
        keyCombination: 'Backspace',
        context: 'Workspace',
        category: 'Edit elements',
        meta: undefined,
        returnCommandImmediately: false
    },
    {
        command: 'NudgeLeft',
        keyCombination: 'ArrowLeft',
        context: 'Workspace',
        category: 'Edit elements',
        meta: { description: 'Move element 1 pixel left', shortcut: '←' }
    },
    {
        command: 'NudgeRight',
        keyCombination: 'ArrowRight',
        context: 'Workspace',
        category: 'Edit elements',
        meta: { description: 'Move element 1 pixel right', shortcut: '→' }
    },
    {
        command: 'NudgeUp',
        keyCombination: 'ArrowUp',
        context: 'Workspace',
        category: 'Edit elements',
        meta: { description: 'Move element 1 pixel up', shortcut: '↑' }
    },
    {
        command: 'NudgeDown',
        keyCombination: 'ArrowDown',
        context: 'Workspace',
        category: 'Edit elements',
        meta: { description: 'Move element 1 pixel down', shortcut: '↓' }
    },
    {
        command: 'MoveLeft',
        keyCombination: 'Shift + ArrowLeft',
        context: 'Workspace',
        category: 'Edit elements',
        meta: {
            description: 'Move element 10 pixels left',
            shortcut: { mac: '⇧ + ←', win: 'Shift + ←' }
        }
    },
    {
        command: 'MoveRight',
        keyCombination: 'Shift + ArrowRight',
        context: 'Workspace',
        category: 'Edit elements',
        meta: {
            description: 'Move element 10 pixels right',
            shortcut: { mac: '⇧ + →', win: 'Shift + →' }
        }
    },
    {
        command: 'MoveUp',
        keyCombination: 'Shift + ArrowUp',
        context: 'Workspace',
        category: 'Edit elements',
        meta: {
            description: 'Move element 10 pixels up',
            shortcut: { mac: '⇧ + ↑', win: 'Shift + ↑' }
        }
    },
    {
        command: 'MoveDown',
        keyCombination: 'Shift + ArrowDown',
        context: 'Workspace',
        category: 'Edit elements',
        meta: {
            description: 'Move element 10 pixels down',
            shortcut: { mac: '⇧ + ↓', win: 'Shift + ↓' }
        }
    },
    {
        command: 'FindInLibrary',
        keyCombination: { win: 'Ctrl + F', mac: 'Cmd + F' },
        context: 'Workspace',
        category: 'Design view',
        meta: {
            description: 'Find element in brand library',
            shortcut: { mac: '⌘ + F', win: 'Ctrl + F' }
        }
    }
];
