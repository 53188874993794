import { parseColor } from '@creative/color.utils';
import { Color } from '@creative/color';

export function addColor(array: Color[], color?: Color): void {
    if (color) {
        if (color.stops && color.stops.length) {
            color.stops.forEach(c => array.push(new Color(c.color)));
        } else {
            array.push(parseColor(color.toString()));
        }
    }
}

export function colorChanged(oldColor: Color, newColor: Color): boolean {
    for (const field of Object.keys(newColor)) {
        /* OBS!. When marker is at left side the lightness and saturation gets mixed up somewhere when saved as text colors */
        const isSkipVariable = field === 'lightness' || field === 'saturation';

        if (
            !isSkipVariable &&
            newColor[field].constructor !== Array &&
            newColor[field] !== oldColor[field]
        ) {
            return true;
        }
    }

    return false;
}
