import { IBounds } from '@domain/dimension';
import { IButtonElementDataNode, ITextElementDataNode } from '@domain/nodes';
import { IPadding, IRadius, IShadow } from '@domain/style';
import { clamp } from '@studio/utils/utils';
import { GroupOrLayoutElement, ILayoutRule, isLayoutElement } from '../rules';
import { ElementKind } from '@domain/elements';

export class ScaleElementPropertiesRule implements ILayoutRule {
    applyRenderRule?(layoutElement: GroupOrLayoutElement, calculatedBounds: IBounds): void {
        if (isLayoutElement(layoutElement)) {
            const { element } = layoutElement;
            const xScale = calculatedBounds.width / element.width;
            const yScale = calculatedBounds.height / element.height;
            const minScale = Math.min(xScale, yScale);

            element.radius = ScaleDefault.scaleRadius(
                element.radius,
                element.width,
                element.height,
                calculatedBounds
            );

            if (element.kind === ElementKind.Text || element.kind === ElementKind.Button) {
                ScaleText.scale(element, minScale);
            }

            element.shadows = ScaleDefault.scaleShadows(element.shadows, minScale);
        }
    }
}

export class ScaleDefault {
    static scaleRadius(
        radius: IRadius,
        width: number,
        height: number,
        calculatedBounds: IBounds
    ): IRadius {
        const scaledRadius: IRadius = radius;

        Object.keys(radius).forEach(key => {
            const value = radius[key];
            const scale = value / Math.min(width, height);

            scaledRadius[key] = clamp(
                Math.round(Math.min(calculatedBounds.width, calculatedBounds.height) * scale),
                0,
                999999
            );
        });

        return scaledRadius;
    }

    static scaleShadows(shadows: IShadow[] | undefined, scale: number): IShadow[] | undefined {
        if (shadows) {
            return shadows.map(s => ({
                blur: clamp(Math.ceil(s.blur * scale), 0, 250),
                color: s.color,
                offsetX: clamp(Math.ceil(s.offsetX * scale), -30000, 30000),
                offsetY: clamp(Math.ceil(s.offsetY * scale), -30000, 30000),
                spread: Math.ceil(s.spread * scale)
            }));
        }
    }
}

export class ScaleText {
    static scale(element: ITextElementDataNode | IButtonElementDataNode, scale: number): void {
        element.padding = ScaleText.scalePadding(element.padding, scale);
        element.fontSize = ScaleText.scaleFont(element.fontSize, scale);
    }

    static scalePadding(padding: IPadding, scale: number): IPadding {
        // Note padding can only be set "together" so make all same value
        return {
            top: clamp(Math.round(padding.top * scale), 0, 5000),
            left: clamp(Math.round(padding.left * scale), 0, 5000),
            right: clamp(Math.round(padding.right * scale), 0, 5000),
            bottom: clamp(Math.round(padding.bottom * scale), 0, 5000)
        };
    }

    static scaleFont(fontSize: number, scale: number): number {
        return clamp(Math.round(fontSize * scale), 1, 2500);
    }
}
