import { BrandManagerBrandDto } from '@domain/brand';
import { createActionGroup, props } from '@ngrx/store';
import { IShowcaseBrand } from '@studio/domain/showcase/showcase';

export const loadActions = createActionGroup({
    source: 'Brand',
    events: {
        'load brand': props<{ brandId: string }>(),
        'load brand success': props<{ brand: BrandManagerBrandDto }>(),
        'load brand failure': props<{ error: unknown }>()
    }
});

export const showcaseCreativeSetActions = createActionGroup({
    source: 'BrandShowcase',
    events: {
        'load showcase success': props<{
            brand: IShowcaseBrand;
        }>()
    }
});
