import { Injectable } from '@angular/core';
import { ITimelineNode, OneOfDataNodes } from '@domain/nodes';
import { BehaviorSubject, Subject, tap, withLatestFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { TimelineElementComponent } from './timeline-element.component';

@Injectable({ providedIn: 'root' })
export class TimelineElementService {
    isRenaming = false;
    private _toggleGroup$ = new Subject<TimelineElementComponent>();
    private _toggleVisibility$ = new Subject<void>();
    private _nodes$ = new BehaviorSubject<ITimelineNode[]>([]);
    nodeIsMoving = false;
    nodes$ = this._nodes$.asObservable().pipe(tap(nodes => (this._nodes = nodes)));
    toggleGroup$ = this._toggleGroup$.asObservable().pipe(withLatestFrom(this.nodes$));
    toggleVisibility$ = this._toggleVisibility$.asObservable().pipe(
        withLatestFrom(this.nodes$),
        map(([_, nodes]) => nodes)
    );

    _nodes: ITimelineNode[] = [];
    get nodes(): ITimelineNode[] {
        return this._nodes;
    }

    toggleGroup(tlComponent: TimelineElementComponent): void {
        this._toggleGroup$.next(tlComponent);
    }

    toggleVisibility(): void {
        this._toggleVisibility$.next();
    }

    setNodeList(nodeList: ITimelineNode[]): void {
        this._nodes$.next(nodeList);
    }

    isCollapsed(node: OneOfDataNodes): boolean {
        return !!this._nodes.find(n => n.node.id === node.id)?.collapsed;
    }
}
