/*
 *  1. sets the onerror method to log widget errors to console
 *  2. Wraps setTimeout to catch callback errors
 */
export const widgetErrorHandler = `
window.errorFunction = window.parent.displayErrorFromWidget;
window.parent = window.top = undefined;
window.onerror = errorHandler;
window.onunhandledrejection = unhandledPromiseRejectErrorHandler;

function unhandledPromiseRejectErrorHandler(e) {
    errorHandler(e.reason);
}

function errorHandler(e) {
    window.handleError(e);
}

var stf = setTimeout;
setTimeout = function(f,ms) {
    return stf(() => {
        try {
            f();
        }
        catch(e) {
            errorHandler(e);
        }
    },ms)
};
`;
