import { inject, Injectable } from '@angular/core';
import { UIAlertDialogService } from '@bannerflow/ui';

@Injectable({ providedIn: 'root' })
export class AIStudioDialogService {
    private alertDialogService = inject(UIAlertDialogService);

    async showNoAccessDialog(): Promise<void> {
        await this.alertDialogService.show({
            headerText: 'You do not have access to this feature',
            text: 'To gain access to AI Studio you need to contact your Customer Success Manager or send an email to <a href="mailto:customersuccess@bannerflow.com" id="interaction-gen-ai-noaccess-email">customersuccess@bannerflow.com</a>.<br/><br/>Want to learn more about our AI Studio? <a href="https://support.bannerflow.com/en/articles/9618649-ai-image-generation-and-editing" target="_blank" id="interaction-gen-ai-noaccess-link">Click here!</a>'
        });
    }
}
