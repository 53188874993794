import { parseGIF } from 'gifuct-js';
import { ISize } from '@domain/dimension';
import { base64ToArrayBuffer } from './array';

interface ILoadMediaFileOptions {
    file: File;
    brandId: string;
    allowHeavyVideos?: boolean;
    loaded: (base64Data: string, size: ISize, formData: FormData) => void;
    error: (message: string) => void;
}

const GIF_MAX_MP = 100000000;

export function loadImageFile({ file, brandId, loaded, error }: ILoadMediaFileOptions): void {
    // Skip if file is not an image
    const imageExtensionRegex = /image\/(gif|jpe?g|png|(svg\+xml))+/gi;
    if (!imageExtensionRegex.test(file.type)) {
        return error('Only JPG, JPEG, PNG, GIF and SVG files can be uploaded.');
    }
    // Skip if file is too large in filesize (6MB)
    else if (Math.round(file.size / 1024) / 1000 > 6) {
        return error('Image can not be larger than 6MB.');
    }

    const formData = new FormData();
    formData.append('image', file);
    formData.append('brandId', brandId);
    // Read file to get the image as a base64 URI
    const reader = new FileReader();

    reader.onload = (event: ProgressEvent<FileReader>): void => {
        const base64Data = (event.target?.result || '') as string;
        const image = new Image();
        image.src = base64Data;

        // Load the image to check its dimensions
        image.onload = (): void => {
            const imageError = validateImage(file, image, base64Data);
            if (imageError) {
                return error(imageError);
            }
            loaded(base64Data, { width: image.width, height: image.height }, formData);
        };
    };
    reader.readAsDataURL(file);
}

function validateImage(file: File, image: HTMLImageElement, base64Data: string): string | undefined {
    const isGIF = file.type.includes('gif');
    if (image.width > 10000 || image.height > 10000) {
        return 'Image can not be larger than 10000x10000 pixels.';
    }

    if (isGIF) {
        try {
            const validGIF = validateGIFSize(base64Data, image.width, image.height);
            if (!validGIF) {
                return 'GIF can not be larger than 100MP.';
            }
        } catch {
            return 'Could not parse the GIF file.';
        }
    }
}

export function loadVideoFile({ file, brandId, loaded }: ILoadMediaFileOptions): void {
    const formData = new FormData();
    formData.append('video', file);
    formData.append('brandId', brandId);

    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>): void => {
        const base64Data = e.target?.result as string;
        const video = document.createElement('video');
        video.setAttribute('src', base64Data);
        video.src = base64Data;
        video.onloadeddata = (): void => {
            const size: ISize = { width: video.videoWidth, height: video.videoHeight };
            loaded(base64Data, size, formData);
        };
    };
    reader.readAsDataURL(file);
}

// checks a gif by calculating the total frame size -- height x width x frames
function validateGIFSize(base64Data: string, width: number, height: number): boolean {
    const buffer = base64ToArrayBuffer(base64Data);
    const gif = parseGIF(buffer);
    const totalFrames = gif.frames.length - 1;
    return width * height * totalFrames < GIF_MAX_MP;
}
