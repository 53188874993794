import { OneOfLibraryAssets } from '@domain/brand/brand-library';
import { IFeed } from '@domain/feed';
import { IImageElementDataNode, IVideoElementDataNode } from '@domain/nodes';

export type ElementWithAssetProperty = IImageElementDataNode | IVideoElementDataNode;

export enum AssetPropertyContext {
    Replace = 'replace-asset',
    Widget = 'widget-image'
}

export type PartialLibraryAsset<T extends OneOfLibraryAssets = OneOfLibraryAssets> = Partial<T> &
    Pick<T, 'thumbnail' | 'url'>;

export interface IFeedChange {
    feed: IFeed;
    replaceAll: boolean;
}
