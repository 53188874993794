import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { UIFormatBytesPipe } from '@bannerflow/ui';
import { isVideoElement, isWidgetElement } from '@creative/nodes';
import { VideoLibraryAsset } from '@domain/brand/brand-library';
import { IElement } from '@domain/creativeset/element';
import { getLibraryWidgetReferenceOfElement } from '@studio/utils/element.utils';
import { isHeavyVideo } from '@studio/utils/media';
import { BrandLibraryDataService } from '../media-library/brand-library.data.service';
import { WidgetService } from '../services/widget.service';

@Pipe({
    standalone: true,
    name: 'brandLibraryElementTooltip'
})
export class BrandLibraryElementTooltipPipe implements PipeTransform {
    constructor(
        private brandLibraryDataService: BrandLibraryDataService,
        private widgetService: WidgetService,
        private formatBytes: UIFormatBytesPipe
    ) {}

    async transform(element: IElement): Promise<string> {
        if (isVideoElement(element)) {
            return this.getVideoTooltip(element);
        }
        if (isWidgetElement(element)) {
            return await this.getWidgetTooltip(element);
        }
        return element.name;
    }

    private getVideoTooltip(element: IElement): string {
        const videoAsset = this.brandLibraryDataService.getAssetByElement<VideoLibraryAsset>(element);

        if (videoAsset) {
            return `Name: ${element.name}<br>
                    Duration: ${
                        videoAsset.durationInMilliseconds
                            ? formatDate(videoAsset.durationInMilliseconds, 'mm:ss', 'en')
                            : ''
                    }<br>
                    Size: ${this.formatBytes.transform(videoAsset.fileSize)}
                    ${
                        isHeavyVideo(videoAsset.fileSize)
                            ? '<span class="heavy-video"> - Heavy video</span>'
                            : ''
                    }`;
        }

        return element.name;
    }

    private async getWidgetTooltip(element: IElement): Promise<string> {
        if (isWidgetElement(element)) {
            const widgetReference = getLibraryWidgetReferenceOfElement(element);

            if (!widgetReference) {
                return element.name;
            }

            const bflWidget = await this.widgetService.get(widgetReference.value);

            if (bflWidget) {
                return `${element.name}${!bflWidget.exportable ? '<br><svg class="non-exportable-icon"><use href="#icon-ui-html-crossed"></use></svg> Cannot be used in HTML export' : ''}`;
            }
        }

        return element.name;
    }
}
