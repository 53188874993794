import { NgStyle } from '@angular/common';
import { Component, computed, input, output } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UIModule } from '@bannerflow/ui';
import { ExternalFontFamilyDto, ExternalFontStyleDto } from '@studio/domain/api/font-manager.types';
import { fromEvent } from 'rxjs';
import { v4 } from 'uuid';
import { getDefaultFontStyleForExternalFamily, injectFontFamily } from '../utils/font.utils';
import { ExternalFontStyleComponent } from './external-font-style/external-font-style.component';

@Component({
    standalone: true,
    imports: [UIModule, NgStyle, ExternalFontStyleComponent],
    selector: 'external-font-family',
    templateUrl: './external-font-family.component.html',
    styleUrl: './external-font-family.component.scss'
})
export class ExternalFontFamilyComponent {
    externalFontFamily = input.required<ExternalFontFamilyDto>();
    selectedFamily = output<ExternalFontStyleDto>();
    selectedStyle = output<ExternalFontStyleDto>();

    fontFamilyId = computed(() => this.computeFontFamilyId());
    loaded = computed(() => this.computeLoaded());
    sortedFontStyles = computed(() => this.computeSortedFontStyles());
    private fontFace = computed(() => this.computeFontFace());
    private fontFaceLoaded = toSignal(fromEvent(document.fonts, 'loadingdone'));

    plusClicked(): void {
        const fontFamily = this.externalFontFamily();
        const defaultFontStyle = getDefaultFontStyleForExternalFamily(fontFamily);
        this.selectedFamily.emit(defaultFontStyle);
    }

    styleSelected(style: ExternalFontStyleDto): void {
        this.selectedStyle.emit(style);
    }

    private computeFontFamilyId(): string {
        const fontFamily = this.externalFontFamily();
        const uuid = v4();
        const familySlug = fontFamily.family.toLowerCase().replace(/\s/gi, '-');
        return `${familySlug}-${uuid}`;
    }

    private computeFontFace(): FontFace | undefined {
        const fontFamily = this.externalFontFamily();
        const familyId = this.fontFamilyId();
        const defaultFontStyle = getDefaultFontStyleForExternalFamily(fontFamily);

        return injectFontFamily(familyId, defaultFontStyle.file);
    }

    private computeLoaded(): boolean {
        const fontFace = this.fontFace();
        const loading = this.fontFaceLoaded();
        if (!loading || !fontFace) {
            return false;
        }
        if (loading instanceof FontFaceSetLoadEvent) {
            return loading.fontfaces.includes(fontFace);
        }
        return false;
    }

    private computeSortedFontStyles(): ExternalFontStyleDto[] {
        const fontFamily = this.externalFontFamily();
        const sortFunction = (a: ExternalFontStyleDto, b: ExternalFontStyleDto): number => {
            if (a.weight === b.weight) {
                return a.italic ? 1 : -1;
            }
            return a.weight - b.weight;
        };
        return [...fontFamily.styles].sort(sortFunction);
    }
}
