/// <reference types="resize-observer-browser" />

import { ResizeObserver as ResizeObserverPolyfill, ResizeObserverEntry } from '@juggle/resize-observer';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

export interface IResizeObservableEntry extends ResizeObserverEntry {
    width: number;
    height: number;
}

/**
 * Create an observable which will emit every time the passed in elements get resized
 * The event will contain a target: `Element` and contentRect: `ResizeObserverEntry`
 * @param elements The elements you want to get resize events for
 */
export function fromResize(...elements: Element[]): Observable<IResizeObservableEntry> {
    if (!elements.length) {
        throw new Error('Could not initiate fromResize. No elements provided to observe');
    }

    return new Observable(observer => {
        const Observer =
            typeof ResizeObserver !== 'undefined' ? ResizeObserver : ResizeObserverPolyfill;

        const resizeObserver = new Observer(observerEntries => {
            // trigger a new item on the stream when resizes happen
            for (const entry of observerEntries) {
                const box = entry.borderBoxSize && entry.borderBoxSize[0];
                let width = box?.inlineSize; // Safari returns inlineSize undefined
                let height = box?.blockSize; // Safari returns blockSize undefined

                if (typeof width !== 'number' || typeof height !== 'number') {
                    const boundingRect = entry.target.getBoundingClientRect();
                    width = boundingRect.width;
                    height = boundingRect.height;
                }
                observer.next({
                    ...entry,
                    width,
                    height
                } as IResizeObservableEntry);
            }
        });

        // start listening for resize events
        for (const el of elements) {
            resizeObserver.observe(el);
        }

        // cancel resize observer on cancelation
        return (): void => resizeObserver.disconnect();
    });
}

export function fromHeightResize(...elements: Element[]): Observable<IResizeObservableEntry> {
    let lastHeight: number | undefined;

    return fromResize(...elements).pipe(
        filter(({ height }) => height !== lastHeight),
        tap(({ height }) => (lastHeight = height))
    );
}

export function fromWidthResize(...elements: Element[]): Observable<IResizeObservableEntry> {
    let lastWidth: number | undefined;

    return fromResize(...elements).pipe(
        filter(({ width }) => width !== lastWidth),
        tap(({ width }) => (lastWidth = width))
    );
}
