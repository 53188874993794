import { Component, computed, effect, inject, input, viewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { CreativeConverterStateService } from '@app/manage-view/creative-converter/state/creative-converter.service';
import { UIModule, UIPopoverDirective, UIPopoverTargetDirective } from '@bannerflow/ui';
import { FixFontPopoverInput } from '@studio/domain/components/psd-import/import-fonts.types';
import { PSDErrorType } from '@studio/domain/components/psd-import/psd';

@Component({
    standalone: true,
    imports: [UIModule],
    selector: 'fix-font-popover',
    templateUrl: './fix-font-popover.component.html',
    styleUrls: ['./fix-font-popover.component.scss']
})
export class FixFontPopoverComponent {
    private creativeConverterStateService = inject(CreativeConverterStateService);
    layerInput = input.required<FixFontPopoverInput | undefined>();
    importFontPopover = viewChild.required<UIPopoverDirective>('popover');

    shouldShowCheckbox = computed(() => this.computeShouldShowCheckbox());
    applyToAll = false;
    fontToFix = computed(() => this.layerInput()?.layer.error?.data?.missingFontName);
    alternativeFontName = computed(() => this.layerInput()?.layer.error?.data?.alternativeFontName);

    private layers = toSignal(this.creativeConverterStateService.psdLayers$, { initialValue: [] });

    constructor() {
        effect(() => {
            const layerInput = this.layerInput();
            if (!layerInput) {
                this.closePopover();
                return;
            }
            this.openPopover(layerInput.target);
        });
    }

    useAlternativeFont(): void {
        const fontToFix = this.layerInput()?.layer.error?.data?.missingFontName;
        if (fontToFix) {
            this.creativeConverterStateService.useAlternativeFont(fontToFix, this.applyToAll);
        }
    }

    searchForFont(): void {
        const fontToFix = this.layerInput()?.layer.error?.data?.missingFontName;
        if (fontToFix) {
            this.creativeConverterStateService.setFontToFix(fontToFix, this.applyToAll);
        }
    }

    closePopoverClicked(): void {
        this.closePopover();
    }

    private openPopover(target: UIPopoverTargetDirective): void {
        const popover = this.importFontPopover();
        popover.open(target);
    }

    private closePopover(): void {
        const popover = this.importFontPopover();
        if (!popover?.isPopoverOpen) {
            return;
        }
        popover.close();
    }

    private computeShouldShowCheckbox(): boolean {
        const differentFontNames = new Set<string>();
        for (const layer of this.layers()) {
            if (layer.error?.type !== PSDErrorType.OriginalFontNotFound || layer.error.isFixed) {
                continue;
            }
            const fontToFix = layer.error?.data?.missingFontName;
            if (fontToFix) {
                differentFontNames.add(fontToFix);
            }
        }
        return differentFontNames.size > 1;
    }
}
