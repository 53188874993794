<ui-popover
    #popover="ui-popover"
    [config]="{
        width: '285px',
        arrowPosition: 'left',
        position: 'right',
        panelClass: 'no-padding',
        offset: { x: 70, y: 0 }
    }">
    <ng-template ui-popover-template>
        <div
            class="wrapper"
            data-test-id="font-fix-popover">
            <div class="headline">
                <strong> Missing font </strong>
                <ui-svg-icon
                    data-test-id="close-font-fix-popover"
                    [icon]="'close'"
                    (click)="closePopoverClicked()" />
            </div>
            <div class="section">
                The <strong>{{ fontToFix() ?? '' }}</strong> font in the imported PSD file that doesn't
                exist in your brand. Would you like to search for the similar font in the external
                library, or use the suggested alternative – <strong>{{ alternativeFontName() }}</strong>
            </div>
            @if (shouldShowCheckbox()) {
                <div class="checkbox">
                    <ui-checkbox
                        [(selected)]="applyToAll"
                        label="Apply to all layers with missing fonts" />
                </div>
            }
            <div class="buttons">
                <ui-button
                    type="default"
                    text="Use alternative"
                    data-test-id="use-alternative-font"
                    (click)="useAlternativeFont()" />
                <ui-button
                    type="primary"
                    text="Search"
                    data-test-id="search-font"
                    (click)="searchForFont()" />
            </div>
        </div>
    </ng-template>
</ui-popover>
