import { inject, Pipe, PipeTransform } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { SizeAddStateService } from '../state/size-add.service';

@Pipe({
    standalone: true,
    name: 'collectionName'
})
export class CollectionNamePipe implements PipeTransform {
    private sizeAddStateService = inject(SizeAddStateService);

    private collections = toSignal(this.sizeAddStateService.collections$);

    transform(collectionId: string): string {
        return this.collections()?.find(({ id }) => id === collectionId)?.name ?? '';
    }
}
