<div class="palette">
    @for (color of swatches; track color; let i = $index; let last = $last) {
        @if (color) {
            <div
                class="color-container"
                [class.active]="!colorMixed && color.toString() === selectedColorString"
                (click)="selectColor(color)"
                (mouseenter)="onMouseEnter(color)"
                (mouseleave)="onMouseLeave(color)">
                <div
                    class="color"
                    [uiTooltip]="tooltips[i]"
                    [class.active]="color.toString() === selectedColorString"
                    [style.backgroundColor]="color"></div>
            </div>
        }

        @if (!color && !last) {
            <div class="color-container-empty"></div>
        }
    }
</div>
