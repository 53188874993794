import {
    IExportCreativeResult,
    IExportCreativeStatusResult,
    IExportCreativeStatusVariables,
    IExportCreativeVariables
} from '@domain/creativeset/creative/export-creative';
import { gql } from 'apollo-angular';

export const EXPORT_CREATIVE = gql<IExportCreativeResult, IExportCreativeVariables>`
    mutation ExportCreative(
        $brandId: String!
        $exportCreativeRequest: ExportCreativeRequestInputType!
    ) {
        createExportCreativeRequest(brandId: $brandId, exportCreativeRequest: $exportCreativeRequest) {
            correlationId
        }
    }
`;

export const GET_EXPORT_STATUS = gql<IExportCreativeStatusResult, IExportCreativeStatusVariables>`
    query ExportCreativeStatus($creativesetId: String!, $correlationId: String!) {
        exportCreativeRequestByCorrelationId(
            creativesetId: $creativesetId
            correlationId: $correlationId
        ) {
            correlationId
            uploadedFilePath
            failures
        }
    }
`;
