import { forEachDataElement } from '@creative/nodes';
import { CreativeSize, ICreativeset, IDesign } from '@domain/creativeset';
import { IElement } from '@domain/creativeset/element';
import {
    IBoundingBox,
    IBounds,
    IConstraintV2,
    IDistance,
    IHorizontalConstraint,
    ISize,
    IVerticalConstraint
} from '@domain/dimension';
import { ICreativeDataNode, OneOfElementDataNodes } from '@domain/nodes';
import {
    alignHorizontallyWithinBounds,
    alignVerticallyWithinBounds,
    alignWithinBounds,
    aspectRatioScale,
    getBoundingRectangleOfRotatedBox,
    getOuterBounds,
    intersectionFactor,
    isSameBounds,
    isSameSize,
    roundBounds,
    scaleRectWithinBounds,
    sizeToBounds
} from '@studio/utils/geom';
import { uuidv4 } from '@studio/utils/id';
import {
    getMainStateOverlap,
    getMainStateTimelinePosition,
    getTimelineOverlap,
    isMainStateOverlap
} from '../animation.utils';
import { cloneCreativeDocument } from '../serialization';
import { GroupOrLayoutElement, IContstraintGroup, ILayoutElement, ILayoutRule } from './rules';
import { AlignRatioConstrainedRule } from './rules/align-ratio-constrained';
import { AlignToNewCanvasSizeRule } from './rules/align-to-canvas';
import { ButtonRatioRule } from './rules/button-ratio';
import { CenterRule } from './rules/center';
import { ImageRatioRule } from './rules/image-ratio';
import { MinMaxRule } from './rules/min-max-size';
import { PreserveCircleRule } from './rules/preserve-circle';
import { PreserveLineRule } from './rules/preserve-line';
import { ScaleElementPropertiesRule } from './rules/scale-element-properties';
import { ScaleStatePropertiesRule } from './rules/scale-state-properties';
import { SnapToEdgesRule } from './rules/snap-to-edges';

export class Layouter {
    newCreative: ICreativeDataNode;

    private rules: ILayoutRule[] = [
        new SnapToEdgesRule(),
        new ButtonRatioRule(),
        new ImageRatioRule(),
        new PreserveLineRule(),
        new PreserveCircleRule(),
        new MinMaxRule(),
        new ScaleElementPropertiesRule(),
        new CenterRule(),
        new AlignRatioConstrainedRule(),
        new ScaleStatePropertiesRule(),
        new AlignToNewCanvasSizeRule()
    ];

    private layoutElements: ILayoutElement[] = [];
    private groups: IContstraintGroup[] = [];

    constructor(
        public originalCreative: ICreativeDataNode,
        public targetSize: ISize
    ) {
        this.newCreative = cloneCreativeDocument(originalCreative);

        // Only do smartness if size has changed
        if (!isSameSize(originalCreative, targetSize)) {
            this.newCreative.width = targetSize.width;
            this.newCreative.height = targetSize.height;

            // Gather all data needed for the rules in on object
            forEachDataElement(this.newCreative, element => {
                this.layoutElements.push(
                    this.createLayoutElement(element, originalCreative, this.newCreative)
                );
            });

            this.createGroups();

            this.applyConstraintsToGroups();

            this.applyContraintsToElements();
        }
    }

    static getBestDesignBasedOnSize(size: CreativeSize, creativeset: ICreativeset): IDesign {
        const designs = creativeset.designs;
        let bestDesigns: IDesign[] = [designs.at(0)!];
        let bestRatio = 9999;

        designs.forEach(design => {
            if (
                design.document.elements.length &&
                elementsExist(design.document.elements, creativeset.elements)
            ) {
                const designRatio = design.document.width / design.document.height;
                const sizeRatio = size.width / size.height;
                const ratioDiff = Math.abs(sizeRatio - designRatio);

                if (ratioDiff < bestRatio) {
                    bestDesigns = [design];
                    bestRatio = ratioDiff;
                } else if (ratioDiff === bestRatio) {
                    bestDesigns.push(design);
                }
            }
        });

        // Get all ghosts in this size.
        const ghostCreatives = creativeset.creatives
            .filter(creative => isSameSize(creative.size, size) && !creative.design)
            .sort((a, b) => (a.id < b.id ? -1 : 1));
        const ghostSizes = ghostCreatives.map(ghost => ghost.size);

        if (bestDesigns.length > 1 && ghostSizes.length > 1) {
            const index = ghostSizes.indexOf(size);
            if (index > -1) {
                return bestDesigns[index % bestDesigns.length];
            }
        }

        return bestDesigns[0];
    }

    private createGroups(): IContstraintGroup[] {
        // Only elements not covering canvas can be a part of a group
        const filteredElements = this.layoutElements.filter(layoutElement => {
            const canvas = layoutElement.originalCreative;
            const element = layoutElement.rotatedBoundingBox;
            const canvasIntersection = intersectionFactor(canvas, element);
            return (
                canvasIntersection < 0.99 &&
                canvas.width > element.width &&
                canvas.height > element.height
            );
        });

        filteredElements.forEach(layoutElement => {
            const canvasIntersection = intersectionFactor(
                layoutElement.originalCreative,
                layoutElement.rotatedBoundingBox
            );

            // Only elements not covering canvas will be a part of a group
            if (canvasIntersection < 0.99) {
                const elementBox = layoutElement.rotatedBoundingBox;

                // Find an container that have more than 75% of this element inside of it
                const containerElement = filteredElements.find(container => {
                    if (layoutElement !== container) {
                        const intersection = intersectionFactor(
                            elementBox,
                            this.groupOrElement(container).rotatedBoundingBox
                        ); // Overlap in position
                        const timelineOverlap = isMainStateOverlap(
                            layoutElement.element,
                            container.element
                        ); // Overlap in time
                        return intersection > 0.75 && timelineOverlap;
                    }
                });

                // A match is found, go ahead and create a group
                if (containerElement) {
                    const existingGroup = containerElement.group;
                    const overlap =
                        existingGroup &&
                        getTimelineOverlap(
                            existingGroup.timelinePosition,
                            getMainStateTimelinePosition(containerElement.element)
                        );

                    // Update bounding of existing group and reference the group from the element
                    if (overlap && existingGroup) {
                        const newBounds = getOuterBounds(elementBox, existingGroup.rotatedBoundingBox);
                        Object.assign(existingGroup.rotatedBoundingBox, newBounds);
                        existingGroup.timelinePosition = overlap;
                        layoutElement.group = existingGroup;
                        existingGroup.layoutElements.push(layoutElement);
                    }
                    // Add new group
                    else {
                        const timelinePosition = getMainStateOverlap(
                            layoutElement.element,
                            containerElement.element
                        )!;
                        const rotatedBoundingBox = getOuterBounds(
                            elementBox,
                            containerElement.rotatedBoundingBox
                        );
                        const group: IContstraintGroup = {
                            kind: 'group',
                            layoutElements: [containerElement, layoutElement],
                            id: uuidv4(),
                            constraint: {
                                auto: true
                            },
                            originalCreative: layoutElement.originalCreative,
                            rotatedBoundingBox,
                            timelinePosition,
                            newCanvasSize: { ...layoutElement.newCanvasSize }
                        };
                        this.groups.push(group);
                        layoutElement.group = group;
                    }
                }
            }
        });
        return this.groups;
    }

    private applyContraintsToElements(): void {
        this.layoutElements.forEach(layoutElement => {
            const group = layoutElement.group;

            // Go through all rules affecting auto constraint settings
            this.applyAutoContraintRules(layoutElement);

            const finalConstraint = getFinalConstraint(layoutElement);
            const targetSize = group?.newBoundingBox || this.newCreative;
            const newPosition = constraintToRectangle(
                finalConstraint,
                targetSize,
                layoutElement.element
            );

            this.applyRenderRules(layoutElement, newPosition);

            this.setRectangle(layoutElement.element, newPosition);
        });
    }

    private applyConstraintsToGroups(): void {
        this.groups.forEach(group => {
            // Go through all rules affecting auto constraint settings (most of them does not affect groups)
            this.applyAutoContraintRules(group);

            const finalConstraint = getFinalConstraint(group);
            group.newBoundingBox = constraintToRectangle(finalConstraint, this.newCreative);
            this.applyRenderRules(group, group.newBoundingBox);
        });
    }

    private groupOrElement(layoutElement: ILayoutElement): ILayoutElement | IContstraintGroup {
        return layoutElement.group || layoutElement;
    }

    private createLayoutElement(
        element: OneOfElementDataNodes,
        originalCreative: ICreativeDataNode,
        newCanvasSize: ISize
    ): ILayoutElement {
        const rotatedBoundingBox = getBoundingRectangleOfRotatedBox(element);
        const calculatedRatio = rotatedBoundingBox.width / rotatedBoundingBox.height;
        const ratio = element.ratio !== undefined ? { value: calculatedRatio } : undefined;
        return {
            constraint: {
                auto: true,
                ratio
            },
            element,
            rotatedBoundingBox,
            originalCreative,
            newCanvasSize: {
                width: newCanvasSize.width,
                height: newCanvasSize.height
            }
        };
    }

    private applyAutoContraintRules(layoutElement: GroupOrLayoutElement): void {
        for (const rule of this.rules) {
            if (typeof rule.applyAutoContraintRule === 'function') {
                rule.applyAutoContraintRule(layoutElement);
            }
        }
    }

    private applyRenderRules(layoutElement: GroupOrLayoutElement, calculatedBounds: IBounds): void {
        for (const rule of this.rules) {
            if (typeof rule.applyRenderRule === 'function') {
                rule.applyRenderRule(layoutElement, calculatedBounds);
            }
        }
    }

    private setRectangle(element: OneOfElementDataNodes, box: IBoundingBox): void {
        element.x = box.x;
        element.y = box.y;
        element.width = Math.max(box.width, 1);
        element.height = Math.max(box.height, 1);
    }
}

// TODO, HANDLE MORE CASES
function mergeWithMissingConstraintProperties(
    constraint: IConstraintV2,
    defaultConstraint: IConstraintV2
): IConstraintV2 {
    const merged = { ...constraint };
    const verticalDefault = getVerticalConstraintFromConstraint(defaultConstraint);
    const horizontalDefault = getHorizontalConstraintFromConstraint(defaultConstraint);

    const horizontalCount =
        (constraint.left ? 1 : 0) + (constraint.width ? 1 : 0) + (constraint.right ? 1 : 0);
    const verticalCount =
        (constraint.top ? 1 : 0) + (constraint.height ? 1 : 0) + (constraint.bottom ? 1 : 0);

    if (horizontalCount === 0) {
        Object.assign(merged, horizontalDefault);
    } else if (horizontalCount === 1) {
        if (constraint.left || constraint.right) {
            merged.width = defaultConstraint.width;
        }
        // TODO: HANDLE IF ONLY WIDTH IS SET BETTER
        else if (constraint.width) {
            merged.left = defaultConstraint.left;
        }
    }

    if (verticalCount === 0) {
        Object.assign(merged, verticalDefault);
    } else if (verticalCount === 1) {
        if (constraint.top || constraint.bottom) {
            merged.height = defaultConstraint.height;
        }
        // TODO: HANDLE IF ONLY HEIGHT IS SET BETTER
        else if (constraint.height) {
            merged.top = defaultConstraint.top;
        }
    }

    return merged;
}

function isHorizontallyCenteredConstraint(constraint: IConstraintV2): boolean {
    const { left, right } = constraint;
    if (left && right && left.unit === right.unit) {
        if (left.unit === '%') {
            return Math.abs(left.value - right.value) <= 0.01;
        } else {
            return Math.abs(left.value - right.value) < 2;
        }
    }
    return false;
}

function isVerticallyCenteredConstraint(constraint: IConstraintV2): boolean {
    const { top, bottom } = constraint;
    if (top && bottom && top.unit === bottom.unit) {
        if (top.unit === '%') {
            return Math.abs(top.value - bottom.value) <= 0.01;
        } else {
            return Math.abs(top.value - bottom.value) < 2;
        }
    }
    return false;
}

export function getFinalConstraint(layoutElement: GroupOrLayoutElement): IConstraintV2 {
    const group = layoutElement.group;
    const canvas = group?.rotatedBoundingBox || layoutElement.originalCreative;
    const defaultConstraint = getDefaultConstraintFromBounds(layoutElement.rotatedBoundingBox, canvas);
    return mergeWithMissingConstraintProperties(layoutElement.constraint, defaultConstraint);
}

export function getDefaultConstraintFromBounds(box: IBounds, canvas: ISize | IBounds): IConstraintV2 {
    // Change tolerance if you want a stricter centering (1 should be the minimum)
    const centerTolerance = 2;
    const bounds = sizeToBounds(canvas);
    let leftPx = box.x - bounds.x;
    let topPx = box.y - bounds.y;
    let rightPx = bounds.width - (leftPx + box.width);
    let bottomPx = bounds.height - (topPx + box.height);

    // Make sure we center a bit more gentle than we have to
    if (Math.abs(rightPx - leftPx) <= centerTolerance) {
        leftPx = rightPx = (leftPx + rightPx) / 2;
    }
    if (Math.abs(bottomPx - topPx) <= centerTolerance) {
        topPx = bottomPx = (topPx + bottomPx) / 2;
    }

    return {
        auto: true,
        width: pixelsToRelativeDistance(box.width, bounds.width),
        height: pixelsToRelativeDistance(box.height, bounds.height),
        left: pixelsToRelativeDistance(leftPx, bounds.width),
        right: pixelsToRelativeDistance(rightPx, bounds.width),
        top: pixelsToRelativeDistance(topPx, bounds.height),
        bottom: pixelsToRelativeDistance(bottomPx, bounds.height)
    };
}

export function constraintToRectangle(
    constraint: IConstraintV2,
    canvas: IBounds | ISize,
    element?: IBoundingBox
): IBounds {
    let rect: IBounds = {
        x: constraintToX(constraint, canvas),
        y: constraintToY(constraint, canvas),
        width: constraintToWidth(constraint, canvas),
        height: constraintToHeight(constraint, canvas)
    };

    const ratio = constraint.ratio;

    const alignH = getHorizontalAlignFromConstraint(constraint);
    const alignV = getVerticalAlignFromConstraint(constraint);

    // Handle minWidth & maxWidth
    const minMaxRect = { ...rect };
    const minWidth = constraint.minWidth
        ? constraintValueToPixels(constraint.minWidth, canvas.width)
        : 1;
    const maxWidth = constraint.maxWidth
        ? constraintValueToPixels(constraint.maxWidth, canvas.width)
        : Number.MAX_VALUE;
    const minHeight = constraint.minHeight
        ? constraintValueToPixels(constraint.minHeight, canvas.height)
        : 1;
    const maxHeight = constraint.maxHeight
        ? constraintValueToPixels(constraint.maxHeight, canvas.height)
        : Number.MAX_VALUE;
    minMaxRect.width = Math.max(minWidth, Math.min(maxWidth, minMaxRect.width));
    minMaxRect.height = Math.max(minHeight, Math.min(maxHeight, minMaxRect.height));

    if (!isSameBounds(minMaxRect, rect)) {
        rect = alignWithinBounds(minMaxRect, rect, alignH, alignV, 'round');
    }

    // Handle ratio
    if (ratio !== undefined) {
        const ratioHeight = ratio.value !== undefined ? rect.width / ratio.value : rect.height;
        const maxRatioHeight = ratio.min !== undefined ? rect.width / ratio.min : ratioHeight;
        const minRatioHeight = ratio.max !== undefined ? rect.width / ratio.max : ratioHeight;
        const cappedHeight = Math.min(maxRatioHeight, Math.max(minRatioHeight, ratioHeight));
        const aspectRect = aspectRatioScale(
            { width: rect.width, height: cappedHeight },
            rect,
            ratio.fitting
        );
        rect = alignWithinBounds(aspectRect, rect, alignH, alignV, 'round');
    }

    // Rotation
    if (element?.rotationZ) {
        rect = scaleRectWithinBounds(element, rect);
    }
    // Sometimes rounding is wrong with 1px. Sample:
    // A centered rect of 5x5 px in size get a x value 0f 2 on a 10x10 px canvas.
    // This might cause or tools not understanding this is a centered element
    else if (!constraint.ratio) {
        rect = roundBounds(rect);

        if (isHorizontallyCenteredConstraint(constraint)) {
            rect.x = alignHorizontallyWithinBounds(rect.width, canvas, 'center');
        }
        if (isVerticallyCenteredConstraint(constraint)) {
            rect.y = alignVerticallyWithinBounds(rect.height, canvas, 'center');
        }
    }

    return roundBounds(rect);
}

export function getHorizontalAlignFromConstraint(
    constraint: IConstraintV2
): 'left' | 'center' | 'right' {
    if (constraint.ratio?.horizontalAlign) {
        return constraint.ratio.horizontalAlign;
    } else if (constraint.left?.unit === 'px' && constraint.right?.unit !== 'px') {
        return 'left';
    } else if (constraint.right?.unit === 'px' && constraint.left?.unit !== 'px') {
        return 'right';
    }
    return 'center';
}

export function getVerticalAlignFromConstraint(constraint: IConstraintV2): 'top' | 'center' | 'bottom' {
    if (constraint.ratio?.verticalAlign) {
        return constraint.ratio.verticalAlign;
    } else if (constraint.top?.unit === 'px' && constraint.bottom?.unit !== 'px') {
        return 'top';
    } else if (constraint.bottom?.unit === 'px' && constraint.top?.unit !== 'px') {
        return 'bottom';
    }
    return 'center';
}

function constraintToX(constraint: IConstraintV2, canvas: ISize | IBounds): number {
    const canvasX = ('x' in canvas && canvas.x) || 0;
    const canvasWidth = canvas.width;

    if (constraint.left !== undefined) {
        return canvasX + constraintValueToPixels(constraint.left, canvasWidth);
    }
    if (constraint.right !== undefined && constraint.width !== undefined) {
        return (
            canvasX +
            canvasWidth -
            constraintValueToPixels(constraint.right, canvasWidth) -
            constraintValueToPixels(constraint.width, canvasWidth)
        );
    }
    throw new Error(
        `Property x can only be calculated with either "left" OR both of "width" and "right" provided`
    );
}

function constraintToY(constraint: IConstraintV2, canvas: ISize | IBounds): number {
    const canvasY = ('y' in canvas && canvas.y) || 0;
    const canvasHeight = canvas.height;

    if (constraint.top !== undefined) {
        return canvasY + constraintValueToPixels(constraint.top, canvasHeight);
    }
    if (constraint.bottom !== undefined && constraint.height !== undefined) {
        return (
            canvasY +
            canvasHeight -
            constraintValueToPixels(constraint.bottom, canvasHeight) -
            constraintValueToPixels(constraint.height, canvasHeight)
        );
    }
    throw new Error(
        `Property y can only be calculated with either "left" OR both of "height" and "bottom" provided`
    );
}

export function constraintToWidth(constraint: IConstraintV2, canvas: ISize | IBounds): number {
    const canvasWidth = canvas.width;

    if (constraint.width !== undefined) {
        return Math.max(1, constraintValueToPixels(constraint.width, canvasWidth));
    }
    if (constraint.left !== undefined && constraint.right !== undefined) {
        const left = constraintValueToPixels(constraint.left, canvasWidth);
        const right = constraintValueToPixels(constraint.right, canvasWidth);
        return Math.max(1, canvasWidth - left - right);
    }
    throw new Error(
        `Property width can only be calculated with either "width" OR both of "left" and "right" provided`
    );
}

export function constraintToHeight(constraint: IConstraintV2, canvasSize: ISize): number {
    const canvasHeight = canvasSize.height;

    if (constraint.height !== undefined) {
        return Math.max(1, constraintValueToPixels(constraint.height, canvasHeight));
    }
    if (constraint.top !== undefined && constraint.bottom !== undefined) {
        const top = constraintValueToPixels(constraint.top, canvasHeight);
        const bottom = constraintValueToPixels(constraint.bottom, canvasHeight);
        return Math.max(1, canvasHeight - top - bottom);
    }
    throw new Error(
        `Property height can only be calculated with either "height" OR both of "top" and "bottom" provided`
    );
}

function constraintValueToPixels(constraintValue: IDistance | number, canvasSize: number): number {
    if (typeof constraintValue === 'number') {
        return constraintValue;
    } else if (constraintValue.unit === 'px') {
        return constraintValue.value;
    }
    return constraintValue.value * canvasSize;
}

function pixelsToRelativeDistance(value: number, relativeTo: number): IDistance {
    return {
        value: value / relativeTo,
        unit: '%' // TODO: PERCENT IS NOT A PERCENTAGE
    };
}

function getHorizontalConstraintFromConstraint(constraint: IConstraintV2): IHorizontalConstraint {
    return {
        left: constraint.left,
        width: constraint.width,
        right: constraint.right
    };
}

function getVerticalConstraintFromConstraint(constraint: IConstraintV2): IVerticalConstraint {
    return {
        top: constraint.top,
        height: constraint.height,
        bottom: constraint.bottom
    };
}

function elementsExist(
    documentElements: OneOfElementDataNodes[],
    creativesetElements: IElement[]
): boolean {
    return documentElements.every(element => creativesetElements.find(el => el.id === element.id));
}
