import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { deserializeCreativeset } from '@data/deserialization/creativeset';
import { ICreativeset } from '@domain/creativeset/creativeset';
import { IShowcaseCreativesetResponse } from '@studio/domain/showcase';
import { FontFamiliesService } from '@studio/stores/font-families';
import { ImageOptimizerUrlBuilder } from '@studio/utils/ad/image-optimizer';
import { cloneDeep } from '@studio/utils/clone';
import { SafeEval } from '@studio/utils/safe-eval';
import { firstValueFrom, race } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CreativeSetShowcaseService } from '../shared/creativeset-showcase/state/creativeset-showcase.service';
import { CreativesetDataService } from '../shared/creativeset/creativeset.data.service';
import { VersionsService } from '../shared/versions/state/versions.service';

export const creativesetShowcaseResolverFn: ResolveFn<
    Promise<ICreativeset | void>
> = async activatedRoute => {
    const creativesetShowcaseService = inject(CreativeSetShowcaseService);
    const creativesetDataService = inject(CreativesetDataService);
    const versionsService = inject(VersionsService);
    const fontFamiliesService = inject(FontFamiliesService);
    await ImageOptimizerUrlBuilder.init_m(environment.origins.imageOptimizer);
    await SafeEval.init();

    const showcaseId = activatedRoute.params['shareId'];
    creativesetShowcaseService.loadShowcaseCreativeset(showcaseId);
    const responseOk = creativesetShowcaseService.showcaseCreativesetResponse$;
    const responseError = creativesetShowcaseService.error$.pipe(filter(error => !!error));

    const response = await firstValueFrom(race(responseOk, responseError));

    if (response instanceof Error || response instanceof HttpErrorResponse) {
        throw response;
    }

    const showcaseResponse = response as IShowcaseCreativesetResponse;

    const showcaseCreativeset = deserializeCreativeset(cloneDeep(showcaseResponse.creativeset));

    const brand = { ...showcaseResponse.brand };

    creativesetDataService.setCreativeset(showcaseCreativeset);
    creativesetDataService.setBrand(brand);

    fontFamiliesService.setFontFamiliesOfCreativeSet(showcaseResponse.fonts);

    await firstValueFrom(creativesetDataService.creativeset$);

    const selectableIds = showcaseResponse.creativeset.versions.map(({ id }) => id);
    let { versions } = showcaseCreativeset;

    if (!versions.find(({ id }) => id === showcaseCreativeset.defaultVersion.id)) {
        versions = [showcaseCreativeset.defaultVersion, ...versions];
    }

    const versionsParamProvided = 'version' in activatedRoute.queryParams;

    versionsService.init(
        showcaseCreativeset.id,
        versions,
        versionsParamProvided
            ? activatedRoute.queryParams['version'].split(',').filter(Boolean)
            : ['all'],
        versionsParamProvided,
        versions.find(v => v.id === showcaseCreativeset.defaultVersion.id)!,
        selectableIds
    );

    // Route user to regular studio view if redirect is on or fullaccess
    const redirect = activatedRoute.queryParams.r === 'on';
    const fullAccess = showcaseResponse.allowedOperations.includes('fullAccess');
    if (redirect || fullAccess) {
        window.location.href = `/brand/${showcaseCreativeset.brandId}/creativeset/${showcaseCreativeset.id}`;
    }

    return showcaseCreativeset;
};
