import { EnvironmentProviders, importProvidersFrom } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CreativeSetShowcaseEffects as CreativeSetShowcaseEffects } from './creativeset-showcase.effects';
import { CREATIVESET_SHOWCASE_FEATURE_KEY, reducer } from './creativeset-showcase.reducer';

export function provideCreativeSetShowcaseStore(): EnvironmentProviders {
    return importProvidersFrom(
        StoreModule.forFeature(CREATIVESET_SHOWCASE_FEATURE_KEY, reducer),
        EffectsModule.forFeature(CreativeSetShowcaseEffects)
    );
}
