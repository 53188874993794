import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { ColorService } from '../services/color.service';

@Pipe({ standalone: true, name: 'picker' })
export class PickerPipe implements PipeTransform {
    constructor(public colorService: ColorService) {}

    transform(name: string): Observable<boolean> {
        return this.colorService.isColorPickerOpen(name);
    }
}
