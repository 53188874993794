@let font = externalFontFamily();
@if (font) {
    <ui-accordion-item
        class="font-item-container"
        [leftSideCaret]="true">
        <ng-template ui-accordion-header-template>
            <div class="font-item">
                <div class="content">
                    <div>
                        <strong class="font-name">
                            {{ font.family }}
                        </strong>
                        <span class="font-styles-count"> ({{ font.styles.length }} styles) </span>
                        <a
                            [href]="'https://fonts.google.com/specimen/' + font.family"
                            target="_blank"
                            (click)="$event.stopPropagation()"
                            [uiTooltip]="'Google Fonts link'"
                            (aria-label)="('Open ' + font.family + ' in Google Fonts')">
                            <ui-svg-icon icon="go-to-url" />
                        </a>
                    </div>
                    <div
                        class="font-sample"
                        [attr.data-family]="font.family"
                        [class.loaded]="loaded()"
                        [ngStyle]="{ 'font-family': fontFamilyId() }">
                        <span> The quick brown fox jumps over the lazy dog </span>
                    </div>
                </div>
                <div class="actions">
                    <ui-button
                        svgIcon="plus"
                        (click)="plusClicked()" />
                </div>
            </div>
        </ng-template>
        <div class="font-styles">
            @for (style of sortedFontStyles(); track $index) {
                <external-font-style
                    [externalFontFamilyId]="fontFamilyId()"
                    [externalFontStyle]="style"
                    (selectedStyle)="styleSelected($event)" />
            }
        </div>
    </ui-accordion-item>
}
