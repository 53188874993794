import { EnvironmentProviders, importProvidersFrom } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { GenAIEffects } from './gen-ai.effects';
import { GEN_AI_FEATURE_KEY, reducer } from './gen-ai.reducer';

export function provideGenAIStore(): EnvironmentProviders {
    return importProvidersFrom(
        StoreModule.forFeature(GEN_AI_FEATURE_KEY, reducer),
        EffectsModule.forFeature(GenAIEffects)
    );
}
