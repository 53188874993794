import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { EXPORT_CREATIVE, GET_EXPORT_STATUS } from '@data/graphql/export.queries';
import {
    IExportCreativeRequest,
    IExportVideoRequest,
    IExportCreativeStatusVariables,
    IExportCreativeStatusResult,
    ExportCreativeStatusDto,
    ZCorrelationId,
    ZExportCreativeStatus,
    ExportCreativeDto
} from '@domain/creativeset/creative/export-creative';
import { Logger } from '@bannerflow/sentinel-logger';
import { Apollo, QueryRef } from 'apollo-angular';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CreativeExport } from './state/export.models';

type ExportVideoResult = {
    message: string;
    type: 'success' | 'error';
};

@Injectable({ providedIn: 'root' })
export class ExportCreativeDataService {
    private _exportFailure$ = new Subject<{ failures?: string[]; error?: Error }>();
    exportFailure$ = this._exportFailure$.asObservable();

    private _exportFinished$ = new Subject<{ filePath: string }>();
    exportFinished$ = this._exportFinished$.asObservable();

    private _exportVideoResult$ = new Subject<ExportVideoResult>();
    exportVideoResult$ = this._exportVideoResult$.asObservable();

    private logger = new Logger('ExportCreativeDataService');

    constructor(
        private httpClient: HttpClient,
        private apollo: Apollo
    ) {}

    exportCreativeVideo(request: IExportVideoRequest): void {
        const videoConverterUrl = `${environment.origins.sapi}/export/video`;
        this.httpClient.post(videoConverterUrl, request, { observe: 'response' }).subscribe({
            next: response => {
                if (response.ok) {
                    return this._exportVideoResult$.next({
                        message:
                            'Your videos are being exported, you will receive an email once they are ready',
                        type: 'success'
                    });
                }
            },
            error: error => {
                this.logger.error(error);

                this._exportVideoResult$.next({
                    message: 'Unable to export video',
                    type: 'error'
                });
            }
        });
    }

    exportCreative(
        brandId: string,
        request: IExportCreativeRequest
    ): Observable<ExportCreativeDto | undefined> {
        return this.apollo
            .mutate({
                mutation: EXPORT_CREATIVE,
                variables: {
                    brandId,
                    exportCreativeRequest: request
                }
            })
            .pipe(map(res => ZCorrelationId.parse(res.data?.createExportCreativeRequest)));
    }

    checkExportStatus(creativeExport: CreativeExport): void {
        if (!creativeExport.correlationId) {
            throw new Error(
                'Can not check the progress of a download that does not exist or has not started. Please provide a valid correlationId'
            );
        }

        const queryRef = this.getExportStatus(
            creativeExport.correlationId,
            creativeExport.creativesetId
        );
        queryRef.valueChanges
            .pipe(
                map(res => ZExportCreativeStatus.parse(res.data?.exportCreativeRequestByCorrelationId))
            )
            .subscribe({
                next: status => {
                    this.handleExportStatus(status, creativeExport, queryRef);
                },
                error: error => {
                    this.emitExportFailure(undefined, error);
                }
            });
    }

    private handleExportStatus(
        status: ExportCreativeStatusDto,
        creativeExport: CreativeExport,
        queryRef: QueryRef<IExportCreativeStatusResult, IExportCreativeStatusVariables>
    ): void {
        this.logger.debug(status);

        if (status.failures.length) {
            queryRef.stopPolling();
            this.emitExportFailure(status);
            return;
        }

        if (creativeExport.isTimedOut || status.uploadedFilePath) {
            queryRef.stopPolling();
            if (status.uploadedFilePath) {
                this.emitExportFinished(status.uploadedFilePath, status.correlationId);
            } else {
                this.logger.warn(`Export timed out after ${creativeExport.timeout / 1000}s`);
            }
        } else if (!status.uploadedFilePath) {
            queryRef.startPolling(1000);
        }
    }

    private getExportStatus(
        correlationId: string,
        creativesetId: string
    ): QueryRef<IExportCreativeStatusResult, IExportCreativeStatusVariables> {
        return this.apollo.watchQuery({
            query: GET_EXPORT_STATUS,
            variables: {
                correlationId,
                creativesetId
            },
            fetchPolicy: 'network-only'
        });
    }

    private emitExportFailure(status?: ExportCreativeStatusDto, error?: Error): void {
        if (error) {
            this.logger.error(error);
        } else {
            this.logger.warn(`Export failed - ${status?.correlationId}`);
        }
        this._exportFailure$.next({ failures: status?.failures, error });
    }

    private emitExportFinished(filePath: string, correlationId: string): void {
        this.logger.verbose(`Export finished - ${correlationId}`);
        this._exportFinished$.next({ filePath });
    }
}
