import {
    AfterViewInit,
    Component,
    computed,
    effect,
    ElementRef,
    HostListener,
    inject,
    viewChild
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { BrushHistory, Point } from '@studio/domain/components/gen-ai';
import { DescriptiveLoaderComponent } from '../../../components/descriptive-loader/descriptive-loader.component';
import { GenAIService } from '../../state/gen-ai.service';
import { getScaledAssetDimensions } from '../../utils/scale.utils';
import { AIStudioCanvasService } from '../ai-studio-canvas.service';

@Component({
    standalone: true,
    selector: 'erase-canvas',
    imports: [DescriptiveLoaderComponent],
    templateUrl: './erase-canvas.component.html',
    styleUrls: ['./erase-canvas.component.scss'],
    providers: [AIStudioCanvasService]
})
export class EraseCanvasComponent implements AfterViewInit {
    overlayCanvas = viewChild<ElementRef<HTMLCanvasElement>>('overlayCanvas');

    private aiStudioCanvasService = inject(AIStudioCanvasService);
    private genAIService = inject(GenAIService);

    currentImage = toSignal(this.genAIService.currentImage$);

    private currentImageSize = toSignal(this.genAIService.currentImageSize$);
    size = computed(() => {
        const currentImageSize = this.currentImageSize();
        return getScaledAssetDimensions(currentImageSize);
    });

    private brushSize = toSignal(this.genAIService.eraseBrushSize$, { initialValue: 40 });
    private brushHistory = toSignal(this.genAIService.eraseBrushHistory$, { initialValue: [] });

    private brushStroke: Point[] = [];
    private isPainting = false;

    constructor() {
        effect(() => {
            const brushHistory = this.brushHistory();
            this.aiStudioCanvasService.drawBrush(brushHistory, true);
        });
    }

    ngAfterViewInit(): void {
        const canvas = this.overlayCanvas()?.nativeElement;

        if (!canvas) {
            return;
        }

        this.aiStudioCanvasService.setCanvas(canvas);
    }

    startPainting(event: MouseEvent): void {
        this.isPainting = true;
        this.draw(event);
    }

    @HostListener('window:mouseup')
    globalStopPainting(): void {
        if (!this.isPainting) {
            return;
        }
        this.stopPainting();
    }

    stopPainting(): void {
        if (!this.isPainting) {
            return;
        }
        this.isPainting = false;
        const brushHistory = this.brushHistory();
        const brushStrokes = [...brushHistory, this.brushStroke];
        this.exportMask(brushStrokes);
        this.genAIService.setEraseBrushHistory(brushStrokes);
        this.brushStroke = [];
    }

    onMouseMove(event: MouseEvent): void {
        if (this.isPainting) {
            this.draw(event);
        }
    }

    private draw(event: MouseEvent): void {
        const x = event.offsetX;
        const y = event.offsetY;

        const width = this.brushSize();

        this.brushStroke.push({ x, y, width });
        this.aiStudioCanvasService.drawColorStroke({ x, y, width });
    }

    private exportMask(brushStrokes: BrushHistory): void {
        this.aiStudioCanvasService.drawMask(brushStrokes);

        // Defer imageMask by not awaiting it
        this.aiStudioCanvasService.toBlob().then(blob => {
            if (blob) {
                const linkToBase64 = URL.createObjectURL(blob);
                this.genAIService.setEraseImageMask(linkToBase64);
            }
        });

        this.aiStudioCanvasService.drawBrush(brushStrokes);
    }
}
