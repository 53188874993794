import { IPosition, IResizeDirection, RotationLocation } from './dimension';
import { IBTreeItem } from './utils/btree';

export const enum TransformMode {
    None = 'none',
    Resize = 'resize',
    Rotate = 'rotate',
    Move = 'move',
    EditText = 'editText',
    Select = 'select',
    EditGradient = 'editGradient',
    CreateElement = 'createElement',
    Clone = 'clone',
    Zoom = 'zoom',
    Guidelines = 'guidelines',
    PositionImage = 'positionImage'
}

export enum LockedDirection {
    VERTICAL,
    HORIZONTAL,
    NONE
}

export interface ITransformDirection {
    resize?: IResizeDirection;
    rotate?: RotationLocation;
}

export interface IQueryPoint {
    value: number;
    offset: number;
    crossValues: number[];
}

export interface ISnapLine {
    base: number;
    start: number;
    end: number;
    circles: Set<number>;
}

export interface ICrossValue {
    crossValue: number;
}

export enum GuidelineType {
    Horizontal = 'horizontal',
    Vertical = 'vertical'
}
export interface IGuideline {
    id: string;
    position: IPosition;
    type: GuidelineType;
    preview: boolean;
    social?: boolean;
}
export interface ISnapPoint {
    position: number;
    offset: number;
}

export interface ISnapPoints {
    x?: ISnapPoint;
    y?: ISnapPoint;
}

export interface ISnapLines {
    vertical: ISnapLine[];
    horizontal: ISnapLine[];
}

export interface IPositionToCrossValuesMap {
    xToCrossValuesMap: Map<number, IQueryPoint>;
    yToCrossValuesMap: Map<number, IQueryPoint>;
}

export interface IPointResult {
    item: IBTreeItem<ICrossValue>;
    point: IQueryPoint;
}
