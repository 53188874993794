import {
    isNewlineLikeCharacter,
    isSpaceLikeCharacter,
    isWordLikeCharacter
} from '@creative/elements/rich-text/utils';
import { IVersionedText } from '@domain/creativeset/version';
import { SpanType, VARIABLE_PREFIX } from '@domain/text';
import { cloneDeep } from '@studio/utils/clone';

export function validateAndFixVersionedText(versionedText: IVersionedText): IVersionedText {
    let copyOfVersionedText = cloneDeep(versionedText);

    const rules: Array<(versionedText: IVersionedText) => IVersionedText> = [
        fixVersionedTextStylesEmptyStringRule,
        fixCurrentSpanPositionShouldBeEqualToPreviousSpanLengthPlusPositionRule,
        fixLastTextSpanLengthPlusPositionShouldBeEqualToTextLengthRule,
        fixSpanTypeEndCompositionEllipsisShouldNotExistRule,
        fixVariableShouldNotBeSetForSpanTypeOtherThanVariableRule,
        fixTextSpanCharactersShouldBeInAllowedRangeRule,
        fixTextVariableShouldHaveFeedTypeTextRule,
        fixVisibleFeedStepShouldNotBeSetOnVersionPropertyTextVariablesRule
    ];

    for (const rule of rules) {
        copyOfVersionedText = rule(copyOfVersionedText);
    }
    return copyOfVersionedText;
}

// NOTE: Name for rules copied from BE
export function fixVersionedTextStylesEmptyStringRule(versionedText: IVersionedText): IVersionedText {
    if (versionedText.text.length === 0 && versionedText.styles.length !== 0) {
        return {
            text: '',
            styles: []
        };
    }
    return versionedText;
}

export function fixCurrentSpanPositionShouldBeEqualToPreviousSpanLengthPlusPositionRule(
    versionedText: IVersionedText
): IVersionedText {
    for (let index = 0; index < versionedText.styles.length; index++) {
        const span = versionedText.styles[index];
        const previousSpan = versionedText.styles[index - 1];
        const previousSpanEnd = !previousSpan ? 0 : previousSpan.position + previousSpan.length;
        if (span.position === previousSpanEnd) {
            continue;
        }

        throw new Error(
            '"CurrentSpanPositionShouldBeEqualToPreviousSpanLengthPlusPositionRule" failed '
        );
    }

    return versionedText;
}

export function fixLastTextSpanLengthPlusPositionShouldBeEqualToTextLengthRule(
    versionedText: IVersionedText
): IVersionedText {
    const lastSpan = versionedText.styles.at(-1);
    if (!lastSpan || lastSpan.position + lastSpan.length === versionedText.text.length) {
        return versionedText;
    }

    throw new Error('"LastTextSpanLengthPlusPositionShouldBeEqualToTextLengthRule" failed');
}

export function fixSpanTypeEndCompositionEllipsisShouldNotExistRule(
    versionedText: IVersionedText
): IVersionedText {
    for (const span of versionedText.styles) {
        if (span.type === SpanType.Composition) {
            throw new Error(
                '"SpanTypeEndCompositionEllipsisShouldNotExistRule" failed. Found "Composition"'
            );
        }
        if (span.type === SpanType.End) {
            throw new Error('"SpanTypeEndCompositionEllipsisShouldNotExistRule" failed. Found "End"');
        }
        if (span.type === SpanType.Ellipsis) {
            throw new Error(
                '"SpanTypeEndCompositionEllipsisShouldNotExistRule" failed. Found "Ellipsis"'
            );
        }
    }
    return versionedText;
}

export function fixVariableShouldNotBeSetForSpanTypeOtherThanVariableRule(
    versionedText: IVersionedText
): IVersionedText {
    for (const span of versionedText.styles) {
        if (span.type !== SpanType.Variable && span.variable) {
            delete span.variable;
        }
    }
    return versionedText;
}

export function fixTextSpanCharactersShouldBeInAllowedRangeRule(
    versionedText: IVersionedText
): IVersionedText {
    for (const span of versionedText.styles) {
        const rangedText = versionedText.text.substring(span.position, span.position + span.length);
        const rangedTextArray = [...rangedText];
        switch (span.type) {
            case SpanType.Word:
                if (rangedTextArray.some(character => isSpaceLikeCharacter(character.charCodeAt(0)))) {
                    throw new Error(
                        '"TextSpanCharactersShouldBeInAllowedRangeRule" failed. Found "space" in "Word"'
                    );
                }
                if (
                    rangedTextArray.some(character => isNewlineLikeCharacter(character.charCodeAt(0)))
                ) {
                    throw new Error(
                        '"TextSpanCharactersShouldBeInAllowedRangeRule" failed. Found "newline" in "Word"'
                    );
                }
                if (rangedTextArray[0] === VARIABLE_PREFIX && span.variable) {
                    throw new Error(
                        '"TextSpanCharactersShouldBeInAllowedRangeRule" failed. "Word" resembles "Variable"'
                    );
                }
                break;
            case SpanType.Newline:
                if (rangedTextArray.some(character => isSpaceLikeCharacter(character.charCodeAt(0)))) {
                    throw new Error(
                        '"TextSpanCharactersShouldBeInAllowedRangeRule" failed. Found "space" in "Newline"'
                    );
                }
                if (rangedTextArray.some(character => isWordLikeCharacter(character.charCodeAt(0)))) {
                    throw new Error(
                        '"TextSpanCharactersShouldBeInAllowedRangeRule" failed. Found "word" in "Newline"'
                    );
                }
                break;
            case SpanType.Space:
                if (
                    rangedTextArray.some(character => isNewlineLikeCharacter(character.charCodeAt(0)))
                ) {
                    throw new Error(
                        '"TextSpanCharactersShouldBeInAllowedRangeRule" failed. Found "newline" in "Space"'
                    );
                }
                if (rangedTextArray.some(character => isWordLikeCharacter(character.charCodeAt(0)))) {
                    throw new Error(
                        '"TextSpanCharactersShouldBeInAllowedRangeRule" failed. Found "word" in "Space"'
                    );
                }
                break;
            case SpanType.Variable:
                if (rangedTextArray[0] !== VARIABLE_PREFIX) {
                    throw new Error(
                        `"TextSpanCharactersShouldBeInAllowedRangeRule" failed. "Variable" doesn't start with "${VARIABLE_PREFIX}"`
                    );
                }
                if (!span.variable) {
                    throw new Error(
                        `"TextSpanCharactersShouldBeInAllowedRangeRule" failed. "Variable" doesn't have feed definition`
                    );
                }
                if (rangedText !== `${VARIABLE_PREFIX}${span.variable.path}`) {
                    throw new Error(
                        `"TextSpanCharactersShouldBeInAllowedRangeRule" failed. Wrong path. Found "${rangedText}". Expected be "${VARIABLE_PREFIX}${span.variable.path}"`
                    );
                }
                break;
            default:
                throw new Error(`Invalid SpanType. Found "${span.type}`);
        }
    }

    return versionedText;
}

export function fixTextVariableShouldHaveFeedTypeTextRule(
    versionedText: IVersionedText
): IVersionedText {
    for (const span of versionedText.styles) {
        if (span.type === SpanType.Variable && span.variable?.type !== 'text') {
            if (!span.variable) {
                throw new Error('No variable data set for Variable span');
            }
            span.variable.type = 'text';
        }
    }
    return versionedText;
}

export function fixVisibleFeedStepShouldNotBeSetOnVersionPropertyTextVariablesRule(
    versionedText: IVersionedText
): IVersionedText {
    for (const span of versionedText.styles) {
        if (span.variable && 'visibleFeedStep' in span.variable) {
            delete span.variable['visibleFeedStep'];
        }
    }
    return versionedText;
}
