<div
    class="align-bar"
    [ngClass]="{ disabled: !currentSelection?.length }">
    <div
        class="button"
        uiTooltip="Align left ({{ keyboardShortcuts.Editor.AlignLeft }})"
        [ngClass]="{ active: !!isAligned[ElementAlign.Left] }"
        (click)="onAlign(ElementAlign.Left)">
        <ui-svg-icon icon="align-left"></ui-svg-icon>
    </div>
    <div
        class="button"
        uiTooltip="Align center ({{ keyboardShortcuts.Editor.AlignCenter }})"
        [ngClass]="{ active: !!isAligned[ElementAlign.Center] }"
        (click)="onAlign(ElementAlign.Center)">
        <ui-svg-icon icon="align-center"></ui-svg-icon>
    </div>
    <div
        class="button"
        uiTooltip="Align right ({{ keyboardShortcuts.Editor.AlignRight }})"
        [ngClass]="{ active: !!isAligned[ElementAlign.Right] }"
        (click)="onAlign(ElementAlign.Right)">
        <ui-svg-icon icon="align-right"></ui-svg-icon>
    </div>
    <div
        class="button"
        uiTooltip="Distribute horizontally ({{ keyboardShortcuts.Editor.DistributeHorizontally }})"
        [ngClass]="{ active: !!isDistributed[ElementDistribution.Horizontal], disabled: inStateView }"
        (click)="onDistribute(ElementDistribution.Horizontal)">
        <ui-svg-icon icon="distribute-horisontally"></ui-svg-icon>
    </div>
    <div
        class="button"
        uiTooltip="Distribute vertically ({{ keyboardShortcuts.Editor.DistributeVertically }})"
        [ngClass]="{ active: !!isDistributed[ElementDistribution.Vertical], disabled: inStateView }"
        (click)="onDistribute(ElementDistribution.Vertical)">
        <ui-svg-icon icon="distribute-vertically"></ui-svg-icon>
    </div>
    <div
        class="button"
        uiTooltip="Align top ({{ keyboardShortcuts.Editor.AlignTop }})"
        [ngClass]="{ active: !!isAligned[ElementAlign.Top] }"
        (click)="onAlign(ElementAlign.Top)">
        <ui-svg-icon icon="align-top"></ui-svg-icon>
    </div>
    <div
        class="button"
        uiTooltip="Align middle ({{ keyboardShortcuts.Editor.AlignMiddle }})"
        uiTooltipPosition="left"
        [ngClass]="{ active: !!isAligned[ElementAlign.Middle] }"
        (click)="onAlign(ElementAlign.Middle)">
        <ui-svg-icon icon="align-middle"></ui-svg-icon>
    </div>
    <div
        class="button"
        data-test-id="align-bottom"
        uiTooltip="Align bottom ({{ keyboardShortcuts.Editor.AlignBottom }})"
        uiTooltipPosition="left"
        [ngClass]="{ active: !!isAligned[ElementAlign.Bottom] }"
        (click)="onAlign(ElementAlign.Bottom)">
        <ui-svg-icon icon="align-bottom"></ui-svg-icon>
    </div>
</div>
