import { inject, Injectable } from '@angular/core';
import { UIDialogService } from '@bannerflow/ui';
import { ICreative } from '@domain/creativeset/creative';
import { ExportType } from '@domain/creativeset/creative/export-creative';
import { BrandService } from '@studio/stores/brand';
import { Subject } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import { ExportDialogComponent } from '../export-dialog/export-dialog.component';
import { ExportService } from './export.service';

interface ExportDialogData {
    type: ExportType;
    creatives: ICreative[];
}

@Injectable({ providedIn: 'root' })
export class ExportCreativeDialogService {
    private _openDialog$ = new Subject<ExportDialogData>();

    private readonly uiDialogService = inject(UIDialogService);
    private readonly brandService = inject(BrandService);
    private readonly exportService = inject(ExportService);

    constructor() {
        this._openDialog$
            .pipe(withLatestFrom(this.brandService.brandId$))
            .subscribe(([dialogOptions, brandId]) => {
                this.openDialogComponent(dialogOptions, brandId);
            });
    }

    openDialog(settings: ExportDialogData): void {
        this._openDialog$.next(settings);
    }

    private async openDialogComponent(
        { type, creatives }: ExportDialogData,
        brandId: string
    ): Promise<void> {
        let headerText = '';

        switch (type) {
            case 'mp4':
                headerText = 'Export mp4 video';
                break;
            case 'gif':
                headerText = 'Export gif animation';
                break;
            case 'jpg':
            case 'png':
                headerText = `Export ${type} image`;
                break;
        }

        const data = {
            type,
            creatives,
            brandId
        };
        const dialogRef = this.uiDialogService.openComponent(ExportDialogComponent, {
            headerText,
            theme: 'default',
            width: 570,
            data
        });
        await dialogRef.afterViewInit;
        const settings = await (dialogRef.subComponentRef.instance as ExportDialogComponent).initiate();
        dialogRef.close();
        this.exportService.startExport(settings);
    }
}
