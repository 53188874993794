import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { getFontStyleIdsFromElements } from '@creative/font-families.utils';
import { ICreativeset } from '@domain/creativeset';
import { BrandService } from '@studio/stores/brand';
import { FontFamiliesDataService } from '@studio/stores/font-families/font-families.data.service';
import { ImageOptimizerUrlBuilder } from '@studio/utils/ad/image-optimizer';
import { SafeEval } from '@studio/utils/safe-eval';
import { filter, Observable, of, switchMap, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { CreativesetDataService } from '../shared/creativeset/creativeset.data.service';
import { VersionsService } from '../shared/versions/state/versions.service';

export const creativesetResolverFn: ResolveFn<Observable<ICreativeset>> = activatedRoute => {
    const { creativesetId } = activatedRoute.params;
    const brandService = inject(BrandService);
    const creativesetDataService = inject(CreativesetDataService);
    const versionsService = inject(VersionsService);
    const fontFamiliesDataService = inject(FontFamiliesDataService);

    return brandService.loaded$.pipe(
        filter(loaded => !loaded),
        switchMap(() => brandService.error$),
        switchMap(() => creativesetDataService.getCreativeset(creativesetId)),
        switchMap(() => creativesetDataService.creativeset$),
        switchMap(async creativeset => {
            await Promise.all([
                ImageOptimizerUrlBuilder.init_m(environment.origins.imageOptimizer),
                SafeEval.init()
            ]);
            return creativeset;
        }),
        tap(creativeset => {
            const versions = creativeset.versions;
            const verionsParamProvided = 'version' in activatedRoute.queryParams;

            const versionParamExists = (activatedRoute.queryParams['version'] || '')
                .split(',')
                .filter(Boolean);

            versionsService.init(
                creativeset.id,
                versions,
                versionParamExists,
                verionsParamProvided,
                versions.find(({ id }) => id === creativeset.defaultVersion.id)!
            );
        }),
        switchMap(creativeset => {
            const elements = creativeset.designs.flatMap(design => design.document.elements);
            const fontStyleIds = getFontStyleIdsFromElements(elements);
            if (fontStyleIds.length > 0) {
                return fontFamiliesDataService.getFontFamiliesByStyleIds(fontStyleIds);
            }

            return of([]);
        }),
        switchMap(fontFamilies => {
            return creativesetDataService.syncFonts(fontFamilies);
        }),
        switchMap(() => {
            return creativesetDataService.creativeset$;
        })
    );
};
