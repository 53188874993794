import { cloneDeep } from './clone';

/**
 * A simple cache to cache return values for primitive function inputs
 */
export class SimpleCache {
    private static cache = new Map<string, any>();

    static set<T>(key: string, value: T): T {
        this.cache.set(key, cloneDeep(value));
        return value;
    }

    static get<T>(key: string): T | undefined {
        const value = this.cache.get(key);

        return cloneDeep(value);
    }

    static clear(): void {
        this.cache.clear();
    }
}
