<ng-container
    *ngIf="
        propertiesExists &&
            (isShowingAllVersions$ | async) === false &&
            (versionsLoaded$ | async) &&
            (sortedElements$ | async) as sortedElements;
        else noProperties
    ">
    <mv-text-properties
        *ngIf="sortedElements.texts.length > 0"
        [elements]="$any(sortedElements.texts)"></mv-text-properties>
    <mv-widget-properties
        *ngIf="sortedElements.widgets.length > 0"
        [elements]="$any(sortedElements.widgets)"></mv-widget-properties>
    <ng-container *permissions="['Default']">
        <mv-feed-properties
            *ngIf="sortedElements.feeds.length > 0"
            [elements]="sortedElements.feeds"></mv-feed-properties>
    </ng-container>

    <div
        class="element-actions"
        *ngIf="isDirty">
        <ui-button
            id="cancel-save-versioned-element-properties"
            (click)="cancelChanges(sortedElements)"
            text="CANCEL">
        </ui-button>
        <ui-button
            #saveButton
            id="save-versioned-element-properties"
            text="SAVE"
            type="primary"
            [submit]="submit"
            [done]="finishSaving">
        </ui-button>
    </div>
</ng-container>

<ng-template #noProperties>
    <div class="translation-panel empty">
        <div class="panel-header"></div>
        <div class="panel-center">
            <div class="center-header">TRANSLATION PANEL</div>
            <div class="center-content">
                Edit translatable content (such as button texts etc) here once added to a design.
            </div>
        </div>
    </div>
</ng-template>
