import { Component, OnInit } from '@angular/core';
import { PullRequestsService } from '../../services/pull-requests.service';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { UIModule } from '@bannerflow/ui';
import { CommonModule } from '@angular/common';
import { DotDotDotPipe } from '../../pipes/dotdotdot.pipe';

@Component({
    standalone: true,
    imports: [CommonModule, UIModule, DotDotDotPipe],
    selector: 'toggle-environment',
    templateUrl: './toggle-environment.component.html',
    styleUrls: ['./toggle-environment.component.scss']
})
export class ToggleEnvironmentComponent implements OnInit {
    showToggleEnvironment: boolean;
    origin: string;
    currentBranch = environment.build.branch;
    isOnMain = environment.build.branch === 'main';
    pullRequests$: Observable<string[]>;
    private sandboxUrl = 'https://sandbox-studio.bannerflow.com';

    constructor(public pullRequestsService: PullRequestsService) {}

    ngOnInit(): void {
        this.origin = window.location.origin;
        this.checkEnvironment();

        if (!this.showToggleEnvironment) {
            return;
        }

        this.pullRequests$ = this.pullRequestsService.getPullRequests();
    }

    selectPullRequest(pullRequestBranchName: string): void {
        this.redirectToEnvironment(pullRequestBranchName);
    }

    switchToMain(): void {
        this.redirectToEnvironment();
    }

    private redirectToEnvironment(branch?: string): void {
        const url = new URL(window.location.href);
        url.searchParams.delete('branch');
        if (branch) {
            url.searchParams.set('branch', branch);
        }
        window.open(url.href, '_self', 'noreferrer');
    }

    private checkEnvironment(): void {
        this.showToggleEnvironment = this.sandboxUrl === this.origin;
    }
}
