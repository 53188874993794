import { NgClass } from '@angular/common';
import { Component, computed, inject, input } from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { ISelectableSize } from '@studio/domain/components/size-list.types';
import { SlugPipe } from '../../../../shared/pipes/slug.pipe';
import { SizeThumbnailComponent } from '../size-thumbnail/size-thumbnail.component';
import { SizeAddStateService } from '../state/size-add.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { SelectedSizes } from '@studio/domain/state';

@Component({
    standalone: true,
    imports: [UIModule, SizeThumbnailComponent, NgClass, SlugPipe],
    selector: 'size-list-item',
    templateUrl: './size-list-item.component.html',
    styleUrl: './size-list-item.component.scss'
})
export class SizeListItemComponent {
    private sizeAddStateService = inject(SizeAddStateService);

    size = input.required<ISelectableSize>();

    collectionName = computed(() => this.computeCollectionName());
    numberOfSelected = computed(() => this.computeNumberOfSelected());

    private selectedCollections = toSignal(this.sizeAddStateService.selectedCollections$, {
        initialValue: []
    });
    private selectedSizes = toSignal(this.sizeAddStateService.selectedSizes$, {
        initialValue: {} as SelectedSizes
    });

    addSize(size: ISelectableSize): void {
        this.sizeAddStateService.increaseSelectedSize(size);
    }

    removeSize(size: ISelectableSize): void {
        const delta = -1;
        this.sizeAddStateService.increaseSelectedSize(size, delta);
    }

    private computeNumberOfSelected(): number {
        const selectedSizes = this.selectedSizes();
        const sizeId = this.size().id;

        return selectedSizes?.[sizeId]?.numberOfSizes ?? 0;
    }

    private computeCollectionName(): string {
        const sizeId = this.size().id;
        const collections = this.selectedCollections();

        return (
            collections.find(({ sizeFormatIds }) => sizeFormatIds?.includes(sizeId))?.name ?? 'no-coll'
        );
    }
}
