import { getAnimationsOfType, getNameOfAnimations } from '@creative/animation.utils';
import { GroupDataNode } from '@creative/nodes';
import { isGroupDataNode, isHidden } from '@creative/nodes/helpers';
import { IBounds } from '@domain/dimension';
import { OneOfDataNodes, OneOfElementDataNodes } from '@domain/nodes';
import { drawRoundRect, fitCanvasText } from '@studio/utils/canvas-utils';
import { getCenter } from '@studio/utils/geom';
import { fromResize } from '@studio/utils/resize-observable';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GizmoColor, OUTLINE_COLOR } from '../../../../shared/components/canvas-drawer/gizmo.colors';
import { StudioTimelineComponent } from '../studio-timeline/studio-timeline.component';
import { ActionMode } from '../timeline-transformer.service';
import {
    TIMELINE_ELEMENT_CANVAS_PADDING,
    TIMELINE_ELEMENT_DURATION_BOX_HEIGHT,
    TIMELINE_ELEMENT_HEIGHT,
    TIMELINE_ELEMENT_VERTICAL_PADDING,
    TIMELINE_GROUP_ADD_COLOR,
    TIMELINE_GROUP_BACKDROP_ACTIVE_COLOR,
    TIMELINE_GROUP_BACKDROP_COLOR,
    TIMELINE_GROUP_BACKDROP_DASH_PADDING,
    TIMELINE_GROUP_BACKDROP_PADDING,
    TIMELINE_GROUP_HIDDEN_OPACITY,
    TIMELINE_GROUP_HOVER_OUTLINE_COLOR,
    TIMELINE_GROUP_NAME_COLOR,
    TIMELINE_GROUP_SELECTED_BORDER_COLOR
} from '../timeline.constants';
import { TimelineElementComponent } from './timeline-element.component';
import { TimelineElementService } from './timeline-element.service';
import { ElementKind } from '@domain/elements';

export class TimelineElementGizmoDrawer {
    private ctx: CanvasRenderingContext2D;
    private width: number;
    private height: number;
    private unsubscribe$ = new Subject<void>();
    private node: OneOfDataNodes;
    private bounds: IBounds;

    constructor(
        private timelineElement: TimelineElementComponent,
        private timelineElementService: TimelineElementService,
        private timeline: StudioTimelineComponent,
        private canvas: HTMLCanvasElement
    ) {
        this.ctx = this.canvas.getContext('2d')!;

        setTimeout(() => {
            this.setCanvasSize();
        }, 0);

        fromResize(this.canvas)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(rect => this.setCanvasSize(rect.width, rect.height));
    }

    destroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    draw(): void {
        const timeline = this.timeline;
        this.node = this.timelineElement.node;
        if (!timeline.zoomService.zoom) {
            return;
        }

        this.ctx.clearRect(
            -TIMELINE_ELEMENT_CANVAS_PADDING,
            0,
            this.width + TIMELINE_ELEMENT_CANVAS_PADDING,
            this.height
        );

        /**
         * Move everything 2px to the right.
         * Allows us to draw elements at x = 0
         * Selections would be at x = -2.
         */
        this.ctx.translate(TIMELINE_ELEMENT_CANVAS_PADDING, 0);

        this.bounds = this.timelineElement.bounds;

        this.drawElement();

        this.drawElementSelection();

        // Move back canvas
        this.ctx.translate(-TIMELINE_ELEMENT_CANVAS_PADDING, 0);

        this.clearPaddingIfScrolled();

        this.timeline.detectChanges();
    }

    private drawElement(): void {
        const element = this.node;
        const bounds = this.bounds;

        this.ctx.fillStyle = this.getElementKindRGBAColor(element);

        drawRoundRect(
            this.ctx,
            bounds.x,
            TIMELINE_ELEMENT_VERTICAL_PADDING,
            bounds.width,
            TIMELINE_ELEMENT_DURATION_BOX_HEIGHT,
            3,
            true
        );

        if (isGroupDataNode(element)) {
            this.drawElementName();
            this.drawClamps();
            if (!this.timelineElement.collapsedGroup) {
                this.drawBackdrop();
            }
            this.updateGroupCanvasSize();

            return;
        }

        // In Transition
        this.drawInOrOutAnimation('in', element);
        // Out Transition
        this.drawInOrOutAnimation('out', element);
    }

    private clearPaddingIfScrolled(): void {
        if (this.bounds.x < 0) {
            this.ctx.clearRect(0, 0, TIMELINE_ELEMENT_CANVAS_PADDING, this.height);
        }
    }

    private drawElementSelection(): void {
        const isGroupNode = isGroupDataNode(this.node);
        const timeline = this.timeline;
        const bounds = this.bounds;

        const x = bounds.x;
        const y = TIMELINE_ELEMENT_VERTICAL_PADDING;
        const width = bounds.width;

        // Draw rectangle around element is selected
        if (
            timeline.timelineTransformService.actionMode === ActionMode.ScaleTimeline ||
            this.timelineElement.selected
        ) {
            const SELECT_COLOR = isGroupNode
                ? TIMELINE_GROUP_SELECTED_BORDER_COLOR
                : GizmoColor.timelineBorder();

            this.drawSelection(x, y, width, 14, SELECT_COLOR);
        } else if (this.timelineElement.isHovered) {
            const HOVER_COLOR = isGroupNode ? TIMELINE_GROUP_HOVER_OUTLINE_COLOR : OUTLINE_COLOR;

            this.drawSelection(x, y, width, 14, HOVER_COLOR);
        }
    }

    private drawElementName(): void {
        const element = this.node;
        const bounds = this.bounds;
        const name = element.name;
        const truncatedName = this.fitString(name, bounds.width - 20);

        this.ctx.save();
        this.ctx.font =
            'bold 9px "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif';
        this.ctx.textAlign = 'center';
        this.ctx.fillStyle = this.getGroupNameFill();
        this.ctx.fillText(
            truncatedName,
            bounds.x + bounds.width / 2,
            bounds.y + 10 + TIMELINE_ELEMENT_VERTICAL_PADDING
        );
        this.ctx.restore();
    }

    private drawBackdrop(): void {
        const bounds = this.bounds;
        const height = this.getHeightOfDescendingNodes();
        const x = bounds.x + TIMELINE_GROUP_BACKDROP_PADDING;
        const y = bounds.y + TIMELINE_ELEMENT_HEIGHT - TIMELINE_ELEMENT_VERTICAL_PADDING;
        const width = bounds.width - TIMELINE_GROUP_BACKDROP_PADDING * 2;

        if (!height) {
            return;
        }

        this.ctx.save();

        this.ctx.fillStyle = this.getBackdropFill();

        drawRoundRect(this.ctx, x - 1, y, width + 2, height, [0, 0, 2, 2], true);

        // clip to backdrop
        this.ctx.clip();
        this.drawBackdropStroke(x, y, width, height, 'left');
        this.drawBackdropStroke(x, y, width, height, 'right');

        this.ctx.restore();
    }

    private drawBackdropStroke(
        x: number,
        y: number,
        width: number,
        height: number,
        edge: 'left' | 'right'
    ): void {
        this.ctx.save();

        this.ctx.strokeStyle = this.getGroupStrokeFill();

        // Move it down 1.5px
        y += TIMELINE_GROUP_BACKDROP_DASH_PADDING;
        height -= TIMELINE_GROUP_BACKDROP_DASH_PADDING;

        if (edge === 'right') {
            x += width + 1;
        } else {
            x -= 1;
        }

        this.ctx.beginPath();
        this.ctx.lineWidth = 2;
        this.ctx.setLineDash([3, 1]);
        this.ctx.moveTo(x, y);
        this.ctx.lineTo(x, y + height);
        this.ctx.stroke();
        this.ctx.closePath();

        this.ctx.restore();
    }

    private drawClamps(): void {
        const bounds = this.bounds;
        const CLAMP_WIDTH = 2;
        const CLAMP_HEIGHT = TIMELINE_ELEMENT_DURATION_BOX_HEIGHT;
        const LEFT_CLAMP_RADIUS = [2, 0, 0, 2];
        const RIGHT_CLAMP_RADIUS = [0, 2, 2, 0];

        this.ctx.save();

        this.ctx.fillStyle = this.getGroupStrokeFill();

        // Draw left clamp
        drawRoundRect(
            this.ctx,
            bounds.x - 1,
            bounds.y + TIMELINE_ELEMENT_VERTICAL_PADDING,
            CLAMP_WIDTH,
            CLAMP_HEIGHT,
            LEFT_CLAMP_RADIUS,
            true
        );

        // Draw right clamp
        drawRoundRect(
            this.ctx,
            bounds.width + bounds.x - CLAMP_WIDTH + 1,
            bounds.y + TIMELINE_ELEMENT_VERTICAL_PADDING,
            CLAMP_WIDTH,
            CLAMP_HEIGHT,
            RIGHT_CLAMP_RADIUS,
            true
        );

        this.ctx.restore();
    }

    private drawInOrOutAnimation(type: 'in' | 'out', element: OneOfElementDataNodes): void {
        const animations = getAnimationsOfType(element, type);
        const hasAnimation = animations.length > 0;
        const BORDER_RADIUS = type === 'in' ? [2, 0, 0, 2] : [0, 2, 2, 0];
        const bounds =
            type === 'in'
                ? this.timelineElement.inAnimation.bounds
                : this.timelineElement.outAnimation.bounds;

        this.ctx.fillStyle = 'rgba(0,0,0,0.15)';
        if (this.timelineElement.transitionHighlighted === type) {
            this.ctx.fillStyle = 'rgba(0,0,0,0.25)';
        }

        if (!hasAnimation) {
            if (this.timelineElement.isHovered || this.timelineElement.selected) {
                this.drawTransitionPlusIcon(bounds, type);
            } else {
                this.ctx.fillStyle = 'transparent';
            }
        }

        drawRoundRect(
            this.ctx,
            bounds.x,
            bounds.y + TIMELINE_ELEMENT_VERTICAL_PADDING,
            bounds.width,
            TIMELINE_ELEMENT_DURATION_BOX_HEIGHT,
            BORDER_RADIUS,
            true
        );

        if (hasAnimation) {
            this.ctx.font =
                '8px -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif';
            this.ctx.textAlign = 'center';
            this.ctx.fillStyle = 'rgba(255,255,255,0.9)';
            const animationName = getNameOfAnimations(animations);
            const nameString = this.fitString(animationName, bounds.width - 10);
            const nameStringWidth = this.ctx.measureText(nameString).width;

            // Only draw arrow if it can fit and ctrl/cmd is not down
            if (
                this.timelineElement.transitionHighlighted === type &&
                bounds.width - nameStringWidth >= 20
            ) {
                const arrowPosition = {
                    x: bounds.x + bounds.width / 2 + nameStringWidth / 2 + 5,
                    y: bounds.y + 12
                };

                this.ctx.beginPath();
                this.ctx.moveTo(arrowPosition.x, arrowPosition.y);
                this.ctx.lineTo(arrowPosition.x - 2, arrowPosition.y - 2);
                this.ctx.lineTo(arrowPosition.x + 2, arrowPosition.y - 2);
                this.ctx.fill();
            }

            this.ctx.fillText(
                nameString,
                bounds.x + bounds.width / 2,
                bounds.y + 10 + TIMELINE_ELEMENT_VERTICAL_PADDING
            );
        }
    }

    private drawSelection(x: number, y: number, width: number, height: number, color: string): void {
        const isGroupNode = isGroupDataNode(this.node);
        const selectionPadding = 2;
        this.ctx.save();
        this.ctx.lineWidth = 1;

        if (isGroupNode) {
            this.ctx.lineWidth = 2;

            x -= 1;
            width += 2;

            if (
                this.timelineElement.timelineElementService.nodeIsMoving &&
                !this.timelineElement.elementSelectionService.currentSelection.has(this.node)
            ) {
                color = TIMELINE_GROUP_ADD_COLOR;
            }
        }

        this.ctx.strokeStyle = color;

        drawRoundRect(
            this.ctx,
            x - selectionPadding,
            y - selectionPadding,
            width + selectionPadding * 2,
            height + selectionPadding * 2,
            4,
            false,
            true
        );

        this.ctx.restore();
    }

    private drawTransitionPlusIcon(transitionBounds: IBounds, type: 'in' | 'out'): void {
        const plusSize = 10;
        const thickness = 1;
        const halfSize = (plusSize - thickness) / 2;
        const center = getCenter(transitionBounds);
        this.ctx.save();

        this.ctx.fillStyle = this.getTransitionPlusFill(type);

        this.ctx.fillRect(center.x, center.y - halfSize, thickness, plusSize);
        this.ctx.fillRect(center.x - halfSize, center.y, plusSize, thickness);

        this.ctx.restore();
    }

    private fitString(str: string, maxWidth: number): string {
        const width = this.ctx.measureText(str).width;
        const ellipsis = '…';
        const ellipsisWidth = this.ctx.measureText(ellipsis).width;

        if (width <= maxWidth || width <= ellipsisWidth) {
            return str;
        } else {
            // Remove ' in'  and ' out' from string if too long
            str = str.replace(/\s((in)|(out))$/g, '');

            return fitCanvasText(this.ctx, str, maxWidth);
        }
    }

    private getElementKindRGBAColor(element: OneOfDataNodes): string {
        const hidden = isHidden(element);
        let opacity = hidden ? 0.2 : 1;
        if (isGroupDataNode(element) && hidden) {
            opacity = TIMELINE_GROUP_HIDDEN_OPACITY;
        }

        switch (element.kind) {
            case ElementKind.Text:
                return `rgba(160, 187, 197, ${opacity})`;
            case ElementKind.Button:
                return `rgba(236, 183, 156, ${opacity})`;
            case ElementKind.Rectangle:
            case ElementKind.Ellipse:
                return `rgba(241, 150, 148, ${opacity})`;
            case ElementKind.Image:
                return `rgba(162, 200, 155, ${opacity})`;
            case ElementKind.Widget:
                return `rgba(213, 200, 218, ${opacity})`;
            case ElementKind.Video:
                return `rgba(126, 194, 185, ${opacity})`;
            case ElementKind.Group:
                return this.getGroupFill(opacity);
            default:
                return `rgba(238, 238, 238, ${opacity})`;
        }
    }

    private setCanvasSize = (width?: number, height?: number): void => {
        const ratio = window.devicePixelRatio;

        if (typeof width !== 'number' || typeof height !== 'number') {
            const rect = this.canvas.getBoundingClientRect();
            width = rect.width;
            height = rect.height;
        }

        if (width !== this.width || height !== this.height) {
            this.width = width;
            this.height = height;
            this.canvas.width = width * ratio;
            this.canvas.height = height * ratio;
            this.canvas.getContext('2d')!.scale(ratio, ratio);
            this.draw();
        }
    };

    private updateGroupCanvasSize(): void {
        const parentElement = this.canvas.parentElement;
        if (parentElement) {
            const height = this.getHeightOfDescendingNodes() + TIMELINE_ELEMENT_HEIGHT;
            parentElement.style.height = `${height}px`;
        }
    }

    private getHeightOfDescendingNodes(): number {
        const lastTimelineElement = this.getLastTimelineElementChildOfNode(this.node);

        if (!lastTimelineElement) {
            return 0;
        }

        const startY = this.timelineElement.rect.y + TIMELINE_ELEMENT_HEIGHT;
        const endY =
            lastTimelineElement.rect.y + TIMELINE_ELEMENT_HEIGHT + TIMELINE_ELEMENT_VERTICAL_PADDING;

        return endY - startY;
    }

    private getLastTimelineElementChildOfNode(
        node: OneOfDataNodes
    ): TimelineElementComponent | undefined {
        const groupNode = node as GroupDataNode;
        const visibleNodes = groupNode.nodes.filter(n => !this.timelineElementService.isCollapsed(n));
        let lastVisibleNode = visibleNodes.slice().reverse()[visibleNodes.length - 1];

        if (isGroupDataNode(lastVisibleNode)) {
            return this.getLastTimelineElementChildOfNode(lastVisibleNode);
        } else {
            if (!lastVisibleNode) {
                // Group has no visible children, so group itself is the last visible node
                lastVisibleNode = groupNode;
            }

            return this.timeline.timelineElementComponents?.find(
                tlComponent => tlComponent.node.id === lastVisibleNode?.id
            );
        }
    }

    private getTransitionPlusFill(type: 'in' | 'out'): string {
        if (this.timelineElement.transitionHighlighted === type) {
            return 'rgba(255,255,255,1)';
        }

        return 'rgba(255,255,255,0.5)';
    }

    private getGroupNameFill(): string {
        if (isHidden(this.node)) {
            return `rgba(54, 54, 54, ${TIMELINE_GROUP_HIDDEN_OPACITY})`;
        }

        return TIMELINE_GROUP_NAME_COLOR;
    }

    private getGroupFill(opacity: number): string {
        const { selected, isHovered } = this.timelineElement;

        if (selected || isHovered) {
            return `rgba(255, 251, 233, ${opacity})`;
        }

        return `rgba(235, 235, 235, ${opacity})`;
    }

    private getGroupStrokeFill(): string {
        const hidden = isHidden(this.node);
        const opacity = hidden ? TIMELINE_GROUP_HIDDEN_OPACITY : 1;
        const { selected, isHovered } = this.timelineElement;

        if (selected || isHovered) {
            return `rgba(54, 54, 54, ${opacity})`;
        }

        return `rgba(155, 155, 155, ${opacity})`;
    }

    private getBackdropFill(): string {
        const { node, isHovered } = this.timelineElement;

        if (isHovered) {
            return TIMELINE_GROUP_BACKDROP_ACTIVE_COLOR;
        }

        if (node.__parentNode) {
            // Group is nested
            return 'transparent';
        }

        return TIMELINE_GROUP_BACKDROP_COLOR;
    }
}
