<div class="campaigns">
    <div class="text">
        One or more creatives are being used by a campaign. You need to disconnect it/them before
        it/they can be deleted.
        <br /><br />
        Creative(s) is/are used in:<br />
    </div>

    <div
        class="campaign"
        *ngFor="let campaign of campaigns">
        <div
            class="link"
            (click)="openCampaign(campaign.id)"
            [uiTooltip]="'Open campaign in a new tab'">
            <ui-svg-icon icon="campaigns-s"></ui-svg-icon> {{ campaign.name }}
        </div>
    </div>
</div>

<ui-dialog-buttons>
    <ui-button
        text="Cancel"
        (click)="cancel()"></ui-button>
</ui-dialog-buttons>
