import { distinctString, fixedEncodeURIComponent } from '@studio/utils/utils';

export const FONT_SERVICE_TEXT_PARAMETER = '&t=';

export interface IFontServiceParams {
    textParams: string;
    distinctText: string;
}

export const getFontServiceParamsFromString = (text: string): IFontServiceParams => {
    const distinctText = distinctString(text);
    return {
        textParams: FONT_SERVICE_TEXT_PARAMETER + fixedEncodeURIComponent(distinctText),
        distinctText
    };
};
