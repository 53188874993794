<!-- In/out animation -->
<studio-ui-section
    class="no-padding"
    headline="{{ this.type | capitalize }} animation"
    [clickableHeader]="(animation$ | async) === undefined"
    [empty]="(animation$ | async) === undefined && !animationsMixed"
    (headerClick)="addAnimation()"
    [actions]="[
        {
            id: 'add-' + type + '-animation-button',
            icon: 'plus-small',
            action: addAnimation,
            hidden: (animation$ | async) !== undefined && !animationsMixed
        }
    ]">
    <ng-container *ngIf="!animationsMixed; else animationPlaceholder">
        <ng-container *ngIf="animation$ | async as animation">
            <div class="body">
                <div class="setting">
                    <div class="setting-row wide">
                        <div
                            class="setting-label"
                            [uiTooltip]="tooltips[this.type]">
                            Duration
                        </div>
                        <div class="setting-value col-2 wide">
                            <div class="property-input slider">
                                <studio-ui-slider
                                    [value]="animation.duration"
                                    (valueChange)="setDuration($event, animation)"
                                    (mouseUp)="
                                        setDuration(
                                            animation.duration,
                                            animation,
                                            ElementChangeType.Force
                                        )
                                    "
                                    [config]="{
                                        min: minDuration,
                                        max: maxDuration,
                                        accuracy: 100
                                    }"></studio-ui-slider>
                            </div>
                            <div class="property-input">
                                <ui-number-input
                                    [value]="
                                        animation.duration === 0
                                            ? undefined
                                            : +animation.duration.toFixed(2)
                                    "
                                    id="animation-duration"
                                    data-test-id="animation-duration-input"
                                    (valueChange)="
                                        setDuration($event, animation, ElementChangeType.Burst)
                                    "
                                    [keyboardEmit]="true"
                                    [min]="minDuration"
                                    [max]="maxDuration"
                                    [step]="minDuration"
                                    [placeholder]="'-'"
                                    [allowEmpty]="true"
                                    [disableUndo]="true">
                                </ui-number-input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="body divider">
                <div class="setting">
                    <div class="setting-row alternate">
                        <div class="setting-label">Type</div>
                        <div class="setting-value select type-value col-1 wide">
                            <ui-select
                                [selected]="animation.name === undefined ? 'Mixed' : animation.id"
                                id="animation-type"
                                (selectedChange)="setAnimationName($event)"
                                [useTargetWidth]="true">
                                <ng-container *ngIf="animation.name === undefined">
                                    <ui-option
                                        [value]="'Mixed'"
                                        [selected]="true">
                                        Mixed
                                    </ui-option>
                                    <div class="option-divider"></div>
                                </ng-container>
                                <ui-option
                                    *ngFor="let t of animationTemplates"
                                    [value]="t.id"
                                    >{{ t.name }}</ui-option
                                >
                            </ui-select>
                            <div
                                class="remove-button"
                                [id]="type + '-animation-remove-btn'"
                                (click)="removeAnimation()">
                                <ui-svg-icon icon="delete"></ui-svg-icon>
                            </div>
                        </div>
                    </div>
                    <!-- Animation settings -->
                    <div
                        class="setting-row alternate"
                        *ngFor="let setting of animation.settings | keyvalue">
                        <ng-container *ngIf="setting && setting.value && setting.value.name">
                            <div class="setting-label">{{ setting.value.name }}</div>
                            <!-- Angle property -->
                            <div
                                *ngIf="animationSettingPropertyToUnitMap[setting.key] === 'angle'"
                                class="setting-value angle-setting col-1 wide">
                                <ui-button-group
                                    [options]="angleOptions"
                                    [value]="animationDirection.isMixed ? '' : setting.value.value + ''"
                                    (valueChange)="setSettingValue(setting, +$event)">
                                </ui-button-group>
                                <ui-number-input
                                    unitLabel="°"
                                    *ngIf="animation.id !== 'flip-in' && animation.id !== 'flip-out'"
                                    id="animation-unit-degree"
                                    [allowEmpty]="true"
                                    [placeholder]="'-'"
                                    [step]="1"
                                    [min]="minAnimationDirection"
                                    [max]="maxAnimationDirection"
                                    [value]="
                                        animationDirection.isMixed ? undefined : +setting.value.value
                                    "
                                    (valueChange)="setSettingValue(setting, $event)">
                                </ui-number-input>
                            </div>
                            <!-- Number property -->
                            <div
                                class="setting-value number-setting"
                                *ngIf="animationSettingPropertyToUnitMap[setting.key] === 'number'">
                                <ui-number-input
                                    unitLabel="px"
                                    id="animation-unit-px"
                                    [allowEmpty]="true"
                                    [placeholder]="'-'"
                                    [step]="1"
                                    [min]="minAnimationDistance"
                                    [max]="maxAnimationDistance"
                                    [value]="
                                        animationDistance.isMixed ? undefined : +setting.value.value
                                    "
                                    (valueChange)="setSettingValue(setting, $event)"></ui-number-input>
                            </div>
                        </ng-container>
                    </div>

                    <!-- Easing properties-->
                    <div class="setting-row alternate">
                        <div class="setting-label">Easing</div>
                        <div class="setting-value select easing-setting col-1 wide">
                            <ui-select
                                [selected]="
                                    animation.timingFunction === undefined
                                        ? 'Mixed'
                                        : animation.timingFunction
                                "
                                id="animation-easing"
                                (selectedChange)="setEasing($event)"
                                [useTargetWidth]="true">
                                <ng-container *ngIf="animation.timingFunction === undefined">
                                    <ui-option
                                        [value]="'Mixed'"
                                        [selected]="true">
                                        Mixed
                                    </ui-option>
                                    <div class="option-divider"></div>
                                </ng-container>
                                <ui-option
                                    *ngFor="let timingFunction of timingFunctions | keyvalue"
                                    [value]="timingFunction.key"
                                    >{{ timingFunction.value.name }}</ui-option
                                >
                            </ui-select>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
    <ng-template #animationPlaceholder>
        <p
            class="placeholder-text"
            id="animation-placeholder">
            Click + to replace mixed properties
        </p>
    </ng-template>
</studio-ui-section>
