<ui-dropdown
    #dropdown
    [width]="'150'"
    [minWidth]="150"
    type="menu"
    (open)="onOpenDropdown()"
    class="manage-menu">
    <ui-dropdown-item
        id="interaction-export-jpg"
        [disabled]="disabledOptions"
        svgIcon="image"
        (click)="openExportDialog('jpg')">
        JPG image
    </ui-dropdown-item>
    <ui-dropdown-item
        id="interaction-export-png"
        [disabled]="disabledOptions"
        svgIcon="image"
        (click)="openExportDialog('png')">
        PNG image
    </ui-dropdown-item>
    <ui-dropdown-item
        id="interaction-export-gif"
        [disabled]="disabledOptions"
        svgIcon="image-frames"
        (click)="openExportDialog('gif')">
        GIF animation
    </ui-dropdown-item>
    <ui-dropdown-item
        id="interaction-export-video"
        [disabled]="disabledOptions"
        svgIcon="video"
        (click)="openExportDialog('mp4')">
        MP4 video
    </ui-dropdown-item>
</ui-dropdown>
