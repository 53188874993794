<color-header
    [allowGradient]="allowGradient"
    [type]="color.type"
    [palettes]="colorPalette"
    (typeChange)="setColorType($event)"></color-header>

<color-picker
    [selectedColor$]="_selectedColor$"
    [colorMixed]="colorMixed"
    (editStart)="notifyEditStart()"
    (editEnd)="notifyColorChanged()"
    (preventTextBlur)="notifyPickerBlur()"></color-picker>

<color-palette
    #colorPalette
    [selectedColor$]="_selectedColor$"
    [palettes]="colorPalettes"
    [colorMixed]="colorMixed"
    [auxPalettes]="brandPalettes$ | async"
    (editStart)="notifyEditStart()"
    (valueChange)="notifyColorChange()"
    (editEnd)="notifyColorChanged()"
    (colorHover)="emitPreview($event)"
    (colorLeave)="stopPreview()"></color-palette>
