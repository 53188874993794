import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    Renderer2,
    ViewChild
} from '@angular/core';
import {
    UIDropdownComponent,
    UIDropdownItemComponent,
    UIDropdownTargetDirective
} from '@bannerflow/ui';
import { IPosition } from '@domain/dimension';
import { OneOfDataNodes } from '@domain/nodes';
import { getHotkeysAsKeyValueList } from '@studio/hotkeys';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DesignViewComponent } from '../../design-view.component';
import { StudioWorkspaceComponent } from '../studio-workspace.component';
import { WorkspaceTransformService } from '../workspace-transform.service';
import { AnimationMenuComponent } from './animation-menu/animation-menu.component';
import { CanvasMenuComponent } from './canvas-menu/canvas-menu.component';
import { ElementMenuComponent } from './element-menu/element-menu.component';
import { StateMenuComponent } from './state-menu/state-menu.component';

export type ContextMenu = 'element' | 'animation' | 'canvas' | 'state';

type OneOfMenuComponents =
    | ElementMenuComponent
    | CanvasMenuComponent
    | AnimationMenuComponent
    | StateMenuComponent;

@Component({
    selector: 'context-menu',
    templateUrl: 'context-menu.component.html',
    styleUrls: ['context-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContextMenuComponent implements OnDestroy {
    @ViewChild('elementMenuComponent') elementMenuComponent: ElementMenuComponent;
    @ViewChild('canvasMenuComponent') canvasMenuComponent: CanvasMenuComponent;
    @ViewChild('animationMenuComponent') animationMenuComponent: AnimationMenuComponent;
    @ViewChild('stateMenuComponent') stateMenuComponent: StateMenuComponent;
    @ViewChild('guidelineMenu') guidelineMenu: UIDropdownComponent;
    @ViewChild('guidelineMenuTrigger') guidelineMenuTrigger: UIDropdownTargetDirective;
    @ViewChild('uiDropdownTarget') uiDropdownTarget: UIDropdownTargetDirective;

    private position: IPosition;
    canvasPosition: IPosition;
    singleElementToChange?: OneOfDataNodes;
    private dropdownClose$ = new Subject<void>();
    isMac: boolean;
    currentMenuComponent?: OneOfMenuComponents;
    keyboardShortcuts = getHotkeysAsKeyValueList(['Editor', 'Workspace', 'Timeline']);

    constructor(
        private renderer: Renderer2,
        public designView: DesignViewComponent,
        public workspace: StudioWorkspaceComponent,
        private transform: WorkspaceTransformService,
        private changeDetector: ChangeDetectorRef
    ) {
        this.isMac = navigator.userAgent.includes('Mac');
    }

    ngOnDestroy(): void {
        this.dropdownClose$.next();
        this.dropdownClose$.complete();
    }

    tryCloseMenus(): void {
        this.closeMenus();
    }

    contextMenuHandler = (event: MouseEvent, menuType: ContextMenu, element?: OneOfDataNodes): void => {
        if (event.shiftKey) {
            return;
        }

        if (menuType === 'element' && element === undefined) {
            menuType = 'canvas';
        }

        this.singleElementToChange = element;

        event.preventDefault();
        const workspace = this.workspace;
        const timelineTransformBinder = workspace.designView.timeline.timelineTransformService;
        const workspaceTransformBinder = this.transform;
        if (timelineTransformBinder && workspaceTransformBinder) {
            this.position = workspace.getMousePositionRelativeToWorkspace(event);
            this.canvasPosition = workspace.getCanvasPositionFromDocumentPosition(event);
            const isGuidelineUnderMousePosition = workspaceTransformBinder.getGuidelineUnderMouse(
                workspace,
                this.canvasPosition
            );

            this.tryCloseMenus();

            if (isGuidelineUnderMousePosition) {
                this.openGuidelineMenu();
                return;
            }

            this.openContextMenu(menuType);
        }
        this.changeDetector.detectChanges();
    };

    private openContextMenu(menuType: ContextMenu): void {
        if (this.workspace.designView.animator?.isPlaying) {
            return;
        }

        let menuComponent: OneOfMenuComponents | undefined;

        switch (menuType) {
            case 'element':
                menuComponent = this.elementMenuComponent;
                break;
            case 'state':
                menuComponent = this.stateMenuComponent;
                break;
            case 'canvas':
                menuComponent = this.canvasMenuComponent;
                this.workspace.deselectAllElements();
                this.workspace.gizmoDrawer.draw();
                break;
            case 'animation':
                menuComponent = this.animationMenuComponent;
                this.animationMenuComponent.hasAnimation();
                break;
        }

        if (!menuComponent) {
            return;
        }

        this.currentMenuComponent = menuComponent;
        const menuTrigger = menuComponent.menuTrigger;
        const triggerElement = menuTrigger.hostElementRef.nativeElement;
        triggerElement.style.top = `${this.position.y}px`;
        triggerElement.style.left = `${this.position.x}px`;
        menuTrigger.openDropdown();
        this.workspace.contextMenuOpen = true;
        menuComponent.menu.close.pipe(takeUntil(this.dropdownClose$)).subscribe(this.onDropdownClose);
    }

    private openGuidelineMenu(): void {
        if (this.workspace.designView.animator?.isPlaying) {
            return;
        }

        const triggerElement = this.guidelineMenuTrigger.hostElementRef.nativeElement;
        triggerElement.style.top = `${this.position.y}px`;
        triggerElement.style.left = `${this.position.x}px`;
        this.guidelineMenuTrigger.openDropdown();
        this.workspace.contextMenuOpen = true;
        this.guidelineMenu.close.pipe(takeUntil(this.dropdownClose$)).subscribe(this.onDropdownClose);
    }

    onDropdownClose = (): void => {
        setTimeout(() => (this.singleElementToChange = undefined));
        this.dropdownClose$.next();
    };

    private closeMenus(): void {
        this.guidelineMenuTrigger.closeDropdown();
        if (this.currentMenuComponent) {
            this.currentMenuComponent.menuTrigger.closeDropdown();
        }
        this.currentMenuComponent = undefined;
    }

    contextMenuClosed(): void {
        setTimeout(() => (this.workspace.contextMenuOpen = false));
    }

    deleteGuideline(): void {
        this.transform.removeActiveGuideline(true);
    }

    leaveSubmenu(el: UIDropdownItemComponent): void {
        this.renderer.removeClass(el._getHostElement(), 'active-dropdown-item');
    }

    openSubmenu(el: UIDropdownItemComponent): void {
        this.renderer.addClass(el._getHostElement(), 'active-dropdown-item');
    }
}
