import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UIModule } from '@bannerflow/ui';
import { isVersionedText } from '@creative/elements/rich-text/utils';
import {
    GroupedElements,
    ITPVersionedElementProperty,
    IVersionedElementPropertyValuesByElement,
    IVersionedText,
    IVersionProperty
} from '@domain/creativeset/version';
import { StudioUISectionComponent } from '../../../../shared/components/section/studio-ui-section/studio-ui-section.component';
import { AssignVisibleFeedStepPipe } from '../../../../shared/pipes/assign-visible-feed-step.pipe';
import { GroupHeadlinePipe } from '../../../../shared/pipes/grouping/group-headline.pipe';
import { GroupTextsPipe } from '../../../../shared/pipes/grouping/group-texts.pipe';
import { SortGroupPipe } from '../../../../shared/pipes/grouping/sort-group.pipe';
import { VersionsService } from '../../../../shared/versions/state/versions.service';
import { RichTextInputComponent } from '../../rich-text-input/rich-text-input.component';
import { RichTextPreviewComponent } from '../../rich-text-preview/rich-text-preview.component';
import { TranslationPanelService } from '../translation-panel.service';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        UIModule,
        StudioUISectionComponent,
        GroupTextsPipe,
        AssignVisibleFeedStepPipe,
        SortGroupPipe,
        GroupHeadlinePipe,
        RichTextInputComponent,
        RichTextPreviewComponent
    ],
    selector: 'mv-text-properties',
    templateUrl: './mv-text-properties.component.html',
    styleUrls: ['./mv-text-properties.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MvTextPropertiesComponent {
    @Input() elements: IVersionedElementPropertyValuesByElement<IVersionedText>[];

    constructor(
        private translationPanelService: TranslationPanelService,
        private versionsService: VersionsService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        this.versionsService.renderDirtyVersionProperty$
            .pipe(takeUntilDestroyed())
            .subscribe(change => {
                const element = this.elements.find(({ elementId }) => elementId === change.elementId);
                if (!element || !isVersionedText(change.versionProperty)) {
                    return;
                }
                const elementProperty = element.properties.find(
                    ({ id }) => id === change.versionProperty.id
                );
                if (!elementProperty) {
                    return;
                }
                elementProperty.value = change.versionProperty.value;
                this.changeDetectorRef.detectChanges();
            });
    }

    expandGroup(group: GroupedElements): void {
        group.isExpanded = true;
    }

    isVersionedElementProperty(
        property: IVersionProperty | undefined
    ): property is ITPVersionedElementProperty<IVersionedText> {
        return isVersionedText(property);
    }

    onTextChange(
        text: IVersionedText,
        elements: IVersionedElementPropertyValuesByElement<IVersionedText>[]
    ): void {
        this.translationPanelService.onTextChanged(text, elements);
    }
}
