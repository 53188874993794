import { IFeedDataValue } from './feed';
import { ElementKind } from './elements';

export enum LibraryKind {
    Rectangle = ElementKind.Rectangle,
    Text = ElementKind.Text,
    Image = ElementKind.Image,
    Video = ElementKind.Video,
    Ellipse = ElementKind.Ellipse,
    Button = ElementKind.Button,
    Widget = ElementKind.Widget,
    Feeds = 'feeds',
    Effects = 'effects',
    Selection = 'selection',
    Any = 'any'
}

export interface IFeedFieldListItem {
    id: string;
    name: string;
    values: IFeedDataValue['value'][];
    targetUrls: IFeedDataValue['targetUrl'][];
}

export enum LibraryViewMode {
    List = 'list',
    Grid = 'grid',
    Preview = 'preview'
}
