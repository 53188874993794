<div class="button">
    <div class="button__background">
        <div
            *ngIf="!colorMixed; else dots"
            class="button__color"
            [style.background]="cssColor"></div>

        <ng-template #dots>
            <div class="mixed">
                <ui-svg-icon
                    id="color-mixed-icon"
                    icon="progress"></ui-svg-icon>
            </div>
        </ng-template>
    </div>
</div>
