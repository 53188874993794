import { zodOptional } from '@zod/zod';
import { z } from 'zod';

const ZLoadAsset = z.object({
    weight: z.number(),
    name: z.string(),
    url: z.string(),
    parentUrl: z.string(),
    type: z.enum(['Application', 'Audio', 'Font', 'Image', 'Text', 'Unknown', 'Video'])
});

export type ICreativeWeightAssetDto = z.infer<typeof ZLoadAsset>;

const ZWeightLoad = z.object({
    totalWeight: z.number(),
    assets: z.array(ZLoadAsset)
});

const ZCreativeWeightDto = z.object({
    id: z.string(),
    creativeId: z.string(),
    creativeChecksum: z.string(),
    failed: z.boolean(),
    weights: zodOptional(
        z.object({
            url: zodOptional(z.string()),
            totalWeight: z.number(),
            frameworkOverhead: zodOptional(ZWeightLoad),
            initialLoad: zodOptional(ZWeightLoad),
            subLoad: zodOptional(ZWeightLoad)
        })
    )
});

export const ZCreativeWeightsDto = z.array(ZCreativeWeightDto);
export type CreativeWeightDto = z.infer<typeof ZCreativeWeightDto>;
export interface ICreativeWeight extends z.infer<typeof ZCreativeWeightDto> {}

const ZWeightCalculationCreative = z.object({
    id: z.string(),
    checksum: z.string()
});

const ZWeightCalculationFailure = z.object({
    id: z.string(),
    message: z.string()
});

export const ZBeginWeightCalculation = z.object({
    creatives: z.array(ZWeightCalculationCreative),
    failures: z.array(ZWeightCalculationFailure)
});
export type BeginWeightCalculationDto = z.infer<typeof ZBeginWeightCalculation>;

export interface BeginWeightCalculationResult {
    beginWeightCalculation: BeginWeightCalculationDto;
}

export interface BeginWeightCalculationVariables {
    creativesetId: string;
    creativeIds: string[];
}

export interface CreativeWeightsResult {
    creativeWeightsByChecksums: CreativeWeightDto[];
}

export interface CreativeWeightsVariables {
    creativeChecksumList: string[];
}
