import { Injectable } from '@angular/core';
import { CREATE_SIZES, UPDATE_NAME } from '@data/graphql';
import { ICreativeset } from '@domain/creativeset/creativeset';
import { CreativeSize, ZCreativeSize, ZNewCreativeSize } from '@domain/creativeset/size';
import { Apollo } from 'apollo-angular';
import { map, Observable, Subject, switchMap, tap } from 'rxjs';
import { CreativesetDataService } from '../creativeset/creativeset.data.service';

@Injectable({ providedIn: 'root' })
export class SizesService {
    private _update$ = new Subject<CreativeSize>();
    update$ = this._update$.asObservable();

    constructor(
        private apollo: Apollo,
        private creativesetDataService: CreativesetDataService
    ) {}

    createSizes(sizes: CreativeSize[], creativesetId: string): Observable<ICreativeset> {
        return this.apollo
            .mutate({
                mutation: CREATE_SIZES,
                variables: {
                    sizes: sizes.map(size => ZNewCreativeSize.parse(size)),
                    creativesetId
                }
            })
            .pipe(switchMap(() => this.creativesetDataService.getCreativeset(creativesetId)));
    }

    updateSizeName(sizeId: string, name: string, creativesetId: string): Observable<CreativeSize> {
        return this.apollo
            .mutate({
                mutation: UPDATE_NAME,
                variables: {
                    sizeId,
                    name,
                    creativesetId
                }
            })
            .pipe(
                map(({ data }) => {
                    const size = data!.renameSizeInCreativeset.sizes.find(s => s.id === sizeId);
                    return ZCreativeSize.parse(size);
                }),
                tap(size => this._update$.next(size))
            );
    }
}
