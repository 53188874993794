import { Directive, ElementRef, AfterViewChecked } from '@angular/core';

@Directive({ selector: '[appMultiLineEllipsis]', standalone: true })
export class MultilineEllipsisDirective implements AfterViewChecked {
    constructor(private host: ElementRef) {}

    ngAfterViewChecked(): void {
        setTimeout(() => this.applyEllipsis());
    }

    applyEllipsis(): void {
        const el = this.host.nativeElement;
        const wordArray = el.innerText.split(' ');

        while (el.scrollHeight > el.offsetHeight && wordArray.length) {
            wordArray.pop();
            el.innerText = `${wordArray.join(' ')}...`;
        }
    }
}
