<div class="info-container">
    <div
        class="label"
        *ngIf="creatives">
        <div *ngIf="creatives.length === 1; else multipleCreatives">
            Target URL - {{ creatives[0].size.width }} × {{ creatives[0].size.height }}
            {{ (creatives[0].version.id | versionFromId | async)!.name }}
        </div>
        <ng-template #multipleCreatives> {{ creatives.length }} Creatives selected</ng-template>
    </div>

    <div
        class="open"
        [hidden]="!isValid() || !url"
        (click)="openUrl()">
        Open in new tab
    </div>
</div>
<div class="input-container">
    <ui-input
        [placeholder]="placeholder"
        [(value)]="url"
        (submit)="save()"></ui-input>
</div>

<ui-dialog-buttons>
    <ui-button
        text="Cancel"
        (click)="cancel()"></ui-button>
    <ui-button
        id="set-target-url"
        type="primary"
        [disabled]="!isValid()"
        [text]="buttonLabel"
        (click)="save()"></ui-button>
</ui-dialog-buttons>
