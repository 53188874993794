export interface IMediaAsset {
    width: number;
    height: number;
    url: string;
}

export interface IElementAsset extends IMediaAsset {
    id: string;
    __loading?: boolean;
}

export interface IImageElementAsset extends IElementAsset {
    name?: string;
    isGenAi?: boolean;
}

export interface IVideoElementAsset extends IElementAsset {
    fileSize: number;
    name: string;
}

export type OneOfElementAssets = IImageElementAsset | IVideoElementAsset;

export const AssetReference = {
    Widget: 'widgetReference',
    Image: 'imageReference',
    Video: 'videoReference',
    BannerflowLibraryWidget: 'bannerflowLibraryWidgetReference',
    WidgetContentUrl: 'widgetContentBlobReference'
} as const;

export type AssetReferenceValue = (typeof AssetReference)[keyof typeof AssetReference];
