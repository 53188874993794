<div class="search">
    <div class="current">
        @if (currentlyUsed) {
            Currently used feed: <b>{{ currentlyUsed.name }}</b>
        } @else {
            No feed is currently being used.
        }
    </div>
    <ui-input
        [(value)]="feeds.filter"
        placeholder="Search feeds"></ui-input>
</div>

<ui-list
    #list
    [dataSource]="feeds"
    selectionType="single"
    columnHeight="40px"
    [expandable]="false"
    [showHeader]="false"
    (selectionChange)="selectionChange($event)">
    <ui-list-column
        name="Name"
        property="name">
        <ng-template
            let-data="data"
            let-rowIndex="rowIndex"
            let-displayValue="displayValue"
            let-value="value"
            ui-list-cell-template>
            <div class="feed-item-row">
                {{ data.name }}
                <ui-svg-icon
                    icon="go-to-url"
                    class="go-to-url"
                    [uiTooltip]="'Edit feed source'"
                    (click)="openFeed(data)"></ui-svg-icon>
            </div>
        </ng-template>
    </ui-list-column>
</ui-list>
<div class="buttons">
    <ui-button
        text="Cancel"
        (click)="cancel()">
    </ui-button>
    <ui-button
        [disabled]="!selectedFeed"
        text="Select feed"
        (click)="confirmSelection($event)"
        type="primary">
    </ui-button>
</div>
