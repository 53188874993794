import { CreativeSocialGuideDto, GuidelineDto } from '@domain/api/generated/sapi';
import { ICreativeSocialGuide, SocialNetworkType, SocialPlacement } from '@domain/social';
import { GuidelineType, IGuideline } from '@domain/workspace';

export function convertGuidelinesToDtos(guidelines: IGuideline[]): GuidelineDto[] {
    const filteredGuidelines = guidelines.filter(guideline => !guideline.social);

    return filteredGuidelines.map(guideline => ({
        id: guideline.id,
        position: {
            x: guideline.position.x,
            y: guideline.position.y
        },
        type: guideline.type,
        preview: guideline.preview
    }));
}

export function deserializeGuidelines(guidelinesDto: GuidelineDto[]): IGuideline[] {
    return guidelinesDto.map(guideline => ({
        id: guideline.id,
        position: {
            x: guideline.position.x,
            y: guideline.position.y
        },
        type: guideline.type as GuidelineType,
        preview: guideline.preview
    }));
}

export function deserializeSocialGuide(
    socialGuideDto?: CreativeSocialGuideDto
): ICreativeSocialGuide | undefined {
    if (!socialGuideDto) {
        return;
    }

    const deserializedSocialGuide: ICreativeSocialGuide = {
        network: socialGuideDto.network as SocialNetworkType,
        placement: socialGuideDto.placement as SocialPlacement,
        overlay: socialGuideDto.overlay,
        guidelines: socialGuideDto.guidelines
    };

    return deserializedSocialGuide;
}
