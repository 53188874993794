import { IUser } from '@domain/user';
import { createActionGroup, props } from '@ngrx/store';

export const loadActions = createActionGroup({
    source: 'User',
    events: {
        'load user': props<{ accountSlug: string }>(),
        'load user success': props<{ user: IUser }>(),
        'load user failure': props<{ error: unknown }>()
    }
});
