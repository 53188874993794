import { ICampaignStatus, PublishStatus } from '@domain/campaign';
import { IAsyncState } from '@domain/store/async';
import { createReducer, on } from '@ngrx/store';
import * as DisplayCampaignActions from './display-campaign.actions';

export const DISPLAY_CAMPAIGN_FEATURE_KEY = 'displayCampaign';
export interface DisplayCampaignPartialState {
    readonly [DISPLAY_CAMPAIGN_FEATURE_KEY]: DisplayCampaignState;
}

export interface DisplayCampaignState extends IAsyncState {
    campaignIds: string[];
    campaigns: ICampaignStatus[];
    pushingChangesOnCampaignIds: string[];
}

export const initialState: DisplayCampaignState = {
    loaded: false,
    campaignIds: [],
    campaigns: [],
    pushingChangesOnCampaignIds: []
};

export const reducer = createReducer<DisplayCampaignState>(
    initialState,

    on(DisplayCampaignActions.loadCampaignsStatus, (state, { campaignIds }) => {
        return {
            ...state,
            loaded: false,
            campaignIds
        };
    }),

    on(
        DisplayCampaignActions.loadCampaignsStatusSuccess,
        (state, { campaignPublishStatus, polling }) => {
            if (!polling?.creatives) {
                return {
                    ...state,
                    campaigns: campaignPublishStatus,
                    loaded: true
                };
            }
            // on polling, remove campaigns that are not in Publishing state
            const mergedCampaignStatus = [
                ...state.campaigns.filter(
                    ({ id }) => !campaignPublishStatus.some(campaignResult => campaignResult.id === id)
                ),
                ...campaignPublishStatus
            ];
            // Keep campaigns that are getting pusblished
            const newPushingChanges: string[] = [];
            for (const campaignId of state.pushingChangesOnCampaignIds) {
                const campaign = mergedCampaignStatus.find(({ id }) => id === campaignId);
                if (!campaign) {
                    continue;
                }
                if (campaign.status === PublishStatus.Publishing) {
                    newPushingChanges.push(campaignId);
                    continue;
                }
                for (const creative of polling.creatives) {
                    const creativeInCampaign = campaign.creatives.find(
                        ({ creativeId }) => creative.id === creativeId
                    );
                    if (!creativeInCampaign) {
                        continue;
                    }
                    if (creative.checksum !== creativeInCampaign.checksum) {
                        newPushingChanges.push(campaignId);
                        break;
                    }
                }
            }
            return {
                ...state,
                loaded: true,
                campaigns: mergedCampaignStatus,
                pushingChangesOnCampaignIds: newPushingChanges
            };
        }
    ),

    on(
        DisplayCampaignActions.loadCampaignsStatusFailure,
        DisplayCampaignActions.pushChangesFailure,
        (state, { error }) => {
            return {
                ...state,
                loaded: true,
                error
            };
        }
    ),

    on(DisplayCampaignActions.pushChanges, (state, { campaigns }) => {
        return { ...state, loaded: false, pushingChangesOnCampaignIds: campaigns.map(({ id }) => id) };
    }),

    on(DisplayCampaignActions.pushChangesSuccess, state => {
        return {
            ...state,
            loaded: true
        };
    }),

    on(DisplayCampaignActions.cancelPublish, state => {
        return { ...state, pushingChangesOnCampaignIds: [] };
    })
);
