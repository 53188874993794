<studio-ui-section
    headline="Texts"
    [collapsable]="true">
    <ng-container *ngFor="let group of elements | grouptexts | async | sortgroup">
        <div *ngIf="group.isExpanded; else groupedTexts">
            <ng-container *ngFor="let element of group.elements; let i = index">
                <div
                    class="section-wrapper"
                    *ngIf="isVersionedElementProperty(element.properties[0])">
                    <div class="section">
                        <div class="title-wrapper">
                            <ui-svg-icon
                                (click)="group.isExpanded = false"
                                *ngIf="i === 0"
                                class="icon"
                                icon="collapse"></ui-svg-icon>
                            <span
                                *ngIf="i > 0"
                                class="title-prefix"
                                >-</span
                            >
                            <div class="title">
                                {{ element.name }}
                            </div>
                        </div>
                        <rich-text-input
                            #richTextInput
                            [id]="element.elementId"
                            [originalValue]="
                                element.properties[0].originalValue || element.properties[0].value
                            "
                            [value]="element.properties[0].value"
                            (valueChange)="onTextChange($event, [$any(element)])">
                        </rich-text-input>
                        <rich-text-preview
                            *ngIf="element.properties[0].originalValue"
                            [text]="element.properties[0].originalValue"
                            (stylingSelected)="richTextInput.setStyle($event)">
                        </rich-text-preview>
                    </div>
                </div>
            </ng-container>
        </div>
        <ng-template #groupedTexts>
            <div
                class="section-wrapper"
                *ngIf="isVersionedElementProperty(group.elements[0].properties[0])">
                <div class="section">
                    <div class="title-wrapper">
                        <ui-svg-icon
                            (click)="expandGroup(group)"
                            class="icon"
                            icon="expand"
                            *ngIf="group.elements.length > 1"></ui-svg-icon>
                        <div
                            class="title"
                            [uiTooltip]="group.elements | headline">
                            {{ group.elements | headline }}
                        </div>
                    </div>
                    <rich-text-input
                        #richTextInput
                        [id]="group.elements[0].elementId"
                        [originalValue]="
                            group.elements[0].properties[0].originalValue ||
                            group.elements[0].properties[0].value
                        "
                        [value]="group.elements[0].properties[0].value"
                        [visibleSteps]="group.elements | assignVisibleFeedStep"
                        (valueChange)="onTextChange($event, $any(group.elements))">
                    </rich-text-input>
                    <rich-text-preview
                        *ngIf="group.elements[0].properties[0].originalValue"
                        [text]="group.elements[0].properties[0].originalValue"
                        (stylingSelected)="richTextInput.setStyle($event)">
                    </rich-text-preview>
                </div>
            </div>
        </ng-template>
    </ng-container>
</studio-ui-section>
