import { clamp } from '@studio/utils/utils';
import { IAnimation, IAnimationKeyframe, ITime } from '@domain/animation';
import {
    ITimingFunction,
    TimingFunctionKey,
    TimingFunctionFn,
    TimingFunctionKeyMacro
} from '@domain/timing-functions';
import { getTimeBetween, isTimeAt } from './animation.utils';

export const DEFAULT_TIMING_FUNCTION: TimingFunctionKey = 'easeInOutCubic';

export function getTimingFunctionValueBetween(
    time: number,
    before: ITime,
    after: ITime,
    timingFunction: TimingFunctionFn
): number {
    const duration = getTimeBetween(before, after);
    const startTime = before.time + (before.duration || 0);

    // No time between before after
    if (!duration) {
        return isTimeAt(time, before) ? 1 : 0;
    }

    // Progress of animation between two times/keyframes (between 0 and 1)
    const progress = clamp((time - startTime) / duration, 0, 1);

    // Apply timing function on that value
    return timingFunction(progress);
}

export function getTimingFunctionFromKeyframe(
    animation: IAnimation,
    keyframe: IAnimationKeyframe
): TimingFunctionFn {
    let key = keyframe.timingFunction;
    if (key === '@timingFunction') {
        key = animation.timingFunction;
    }
    return getTimingFunctionByKey(key);
}

export function getTimingFunctionByKey(key: TimingFunctionKey = 'linear'): TimingFunctionFn {
    return timingFunctions[key].func;
}

/**
 * easeOutBack should become easeInBack and vice versa. easeInOut should stay the same
 * @param timing
 */
export function getOppositeTimingFunctionKey(
    timing: TimingFunctionKey | TimingFunctionKeyMacro
): TimingFunctionKey {
    if (timing.indexOf('InOut') === -1 && timing.indexOf('@') === -1) {
        let reverse: TimingFunctionKey | undefined;
        if (timing.indexOf('In') > -1) {
            reverse = timing.replace(/In/g, 'Out') as TimingFunctionKey;
        } else if (timing.indexOf('Out') > -1) {
            reverse = timing.replace(/Out/g, 'In') as TimingFunctionKey;
        }

        if (reverse !== undefined && timingFunctions[reverse]) {
            return reverse;
        }
    }

    return timing as TimingFunctionKey;
}

export const timingFunctions: { [key in TimingFunctionKey]: ITimingFunction } = {
    linear: {
        name: 'Linear',
        type: 'none',
        func: (t: number) => t
    },
    easeInExpo: {
        name: 'InExpo',
        type: 'in',
        func: (t: number) => (t === 0 ? 0 : Math.pow(2, 10 * (t - 1)))
    },
    easeOutExpo: {
        name: 'OutExpo',
        type: 'in',
        func: (t: number) => (t === 1 ? 1 : -Math.pow(2, -10 * t) + 1)
    },
    easeInOutExpo: {
        name: 'InOutExpo',
        type: 'inout',
        func: (t: number) => {
            if (t === 0) {
                return 0;
            }
            if (t === 1) {
                return 1;
            }
            if ((t /= 0.5) < 1) {
                return 0.5 * Math.pow(2, 10 * (t - 1));
            }
            return 0.5 * (-Math.pow(2, -10 * --t) + 2);
        }
    },
    easeInQuad: {
        name: 'InQuad',
        type: 'in',
        func: (t: number) => t * t
    },
    easeOutQuad: {
        name: 'OutQuad',
        type: 'out',
        func: (t: number) => t * (2 - t)
    },
    easeInOutQuad: {
        name: 'InOutQuad',
        type: 'inout',
        func: (t: number) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t)
    },
    easeInCubic: {
        name: 'InCubic',
        type: 'in',
        func: (t: number) => t * t * t
    },
    easeOutCubic: {
        name: 'OutCubic',
        type: 'out',
        func: (t: number) => --t * t * t + 1
    },
    easeInOutCubic: {
        name: 'InOutCubic',
        type: 'inout',
        func: (t: number) => (t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1)
    },
    easeInQuart: {
        name: 'InQuart',
        type: 'in',
        func: (t: number) => t * t * t * t
    },
    easeOutQuart: {
        name: 'OutQuart',
        type: 'out',
        func: (t: number) => 1 - --t * t * t * t
    },
    easeInOutQuart: {
        name: 'InOutQuart',
        type: 'inout',
        func: (t: number) => (t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t)
    },
    easeInQuint: {
        name: 'InQuint',
        type: 'in',
        func: (t: number) => t * t * t * t * t
    },
    easeOutQuint: {
        name: 'OutQuint',
        type: 'out',
        func: (t: number) => 1 + --t * t * t * t * t
    },
    easeInOutQuint: {
        name: 'InOutQuint',
        type: 'inout',
        func: (t: number) => (t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t)
    },
    easeInElastic: {
        name: 'InElastic',
        type: 'in',
        func: (t: number) => {
            if (t === 1) {
                return 1;
            }
            return ((0.04 * t) / --t) * Math.sin(25 * t);
        }
    },
    easeOutElastic: {
        name: 'OutElastic',
        type: 'out',
        func: (t: number) => {
            if (t === 0) {
                return 0;
            }
            return (0.04 - 0.04 / t) * Math.sin(25 * t) + 1;
        }
    },
    easeInOutElastic: {
        name: 'InOutElastic',
        type: 'inout',
        func: (t: number) => {
            if (t === 0.5) {
                return 0.5;
            }
            return (t -= 0.5) < 0
                ? (0.02 + 0.01 / t) * Math.sin(50 * t)
                : (0.02 - 0.01 / t) * Math.sin(50 * t) + 1;
        }
    },
    easeInBack: {
        name: 'InBack',
        type: 'in',
        func: (t: number) => {
            let overshoot = 1;
            if (!overshoot && overshoot !== 0) {
                overshoot = 1.70158;
            }
            return 1 * t * t * ((overshoot + 1) * t - overshoot);
        }
    },
    easeOutBack: {
        name: 'OutBack',
        type: 'out',
        func: (t: number) => {
            let overshoot = 1;
            if (!overshoot && overshoot !== 0) {
                overshoot = 1.70158;
            }
            t = t - 1;
            return t * t * ((overshoot + 1) * t + overshoot) + 1;
        }
    },
    easeInOutBack: {
        name: 'InOutBack',
        type: 'inout',
        func: (t: number) => {
            let overshoot = 1;
            if (overshoot === undefined) {
                overshoot = 1.70158;
            }
            if ((t /= 0.5) < 1) {
                return 0.5 * (t * t * (((overshoot *= 1.525) + 1) * t - overshoot));
            }
            return 0.5 * ((t -= 2) * t * (((overshoot *= 1.525) + 1) * t + overshoot) + 2);
        }
    },
    easeInBounce: {
        name: 'InBounce',
        type: 'in',
        func: (t: number) => 1 - bounce(1 - t)
    },
    easeOutBounce: {
        name: 'OutBounce',
        type: 'out',
        func: (t: number) => bounce(t)
    },
    easeInOutBounce: {
        name: 'InOutBounce',
        type: 'inout',
        func: (t: number) => {
            if (t < 0.5) {
                return bounce(t * 2) * 0.5;
            }
            return bounce(t * 2 - 1) * 0.5 + 1 * 0.5;
        }
    }
};

function bounce(t: number): number {
    if (t < 0.36363636363636365) {
        return 7.5625 * t * t;
    } else if (t < 0.7272727272727273) {
        t = t - 0.5454545454545454;
        return 7.5625 * t * t + 0.75;
    } else if (t < 0.9090909090909091) {
        t = t - 0.8181818181818182;
        return 7.5625 * t * t + 0.9375;
    } else {
        t = t - 0.9545454545454546;
        return 7.5625 * t * t + 0.984375;
    }
}
