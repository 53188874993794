import { CommonModule } from '@angular/common';
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';

@Component({
    standalone: true,
    imports: [CommonModule],
    selector: 'studio-ui-slider',
    templateUrl: './studio-ui-slider.component.html',
    styleUrls: ['./studio-ui-slider.component.scss'],
    host: {
        '[class.vertical]': `direction === 'vertical'`,
        '[class.horizontal]': `direction === 'horizontal'`,
        '[class.tracklight]': `tracklight`,
        '[class.disabled]': `disabled`
    }
})
export class StudioUISliderComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    @ViewChild('handle', { static: true }) handle: ElementRef;
    @ViewChild('background', { static: true }) background: ElementRef;

    @Input() config: ISliderConfig = {};
    @Input() direction: 'horizontal' | 'vertical' = 'horizontal';
    @Input() tracklight = true;
    @Input() disabled = false;
    @Output() valueChange = new EventEmitter<number>();
    /**
     * Mouse up event which listens to the global mouseup event instead of the element specific one
     */
    @Output() mouseUp = new EventEmitter<number>();
    @Input('value') public get value(): number {
        return this.number;
    }

    public set value(val: number) {
        this.valueChangeMoveHandle(val);
    }

    mouseIsDown = false;
    hostSize: number;

    private get max(): number {
        return this.config.max || 0;
    }
    private get min(): number {
        return this.config.min || 0;
    }

    private number: number;
    private startPos = 0;
    private handleStart = 0;

    constructor(private host: ElementRef) {}

    ngOnInit(): void {
        this.host.nativeElement.addEventListener('mousedown', this.hostMouseDown);
    }

    ngAfterViewInit(): void {
        this.setHandleStartPosition();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.config) {
            this.setHandleStartPosition();
        }

        if (changes.value) {
            this.setHandleStartPosition();
        }
    }

    ngOnDestroy(): void {
        window.document.removeEventListener('mousemove', this.onMouseDown);
        window.document.removeEventListener('mouseup', this.onMouseUp);
        this.host.nativeElement.removeEventListener('mousedown', this.hostMouseDown);
    }

    valueChangeMoveHandle(value: number): void {
        this.number = value;
        if (!this.mouseIsDown) {
            this.moveHandle(false);
        }
    }

    private setHostSize(): void {
        this.hostSize =
            this.direction === 'horizontal'
                ? this.host.nativeElement.getBoundingClientRect().width
                : this.host.nativeElement.getBoundingClientRect().height;
    }

    private setHandleStartPosition(): void {
        this.moveHandle(false);
    }

    private notify(): void {
        this.valueChange.emit(this.number);
    }

    private moveHandle(notify: boolean): void {
        const p = this.getRealtivePosition();

        if (this.handle && this.background) {
            if (this.direction === 'horizontal') {
                this.handle.nativeElement.style.left = `${p}%`;
                this.background.nativeElement.style.right = `${100 - p}%`;
            } else {
                this.handle.nativeElement.style.top = `${100 - p}%`;
                this.background.nativeElement.style.bottom = `${p}%`;
            }
            if (notify) {
                this.notify();
            }
        }
    }

    private getRealtivePosition(): number {
        const min = this.config.min ? this.config.min : 0;
        const max = this.config.max ? this.config.max : 100;

        let relativePosition = 0;
        if (this.value > max) {
            relativePosition = max;
        } else if (this.value < min) {
            relativePosition = min;
        } else {
            relativePosition = ((this.value - min) / (max - min)) * 100;
        }

        return relativePosition;
    }

    private setNumber(pos: number): void {
        if (!this.hostSize) {
            this.setHostSize();
        }

        const p = Math.min(Math.max(pos, 0), this.hostSize);
        const val = p / this.hostSize;
        const accuracy = this.config.accuracy || 1;
        this.number =
            Math.round(
                ((this.direction === 'horizontal' ? val : 1 - val) * (this.max - this.min) + this.min) *
                    accuracy
            ) / accuracy;
        this.value = this.number;
    }

    private hostMouseDown = (event: MouseEvent): void => {
        this.mouseIsDown = true;
        this.startPos = this.direction === 'horizontal' ? event.clientX : event.clientY;
        this.handleStart = this.direction === 'horizontal' ? event.offsetX : event.offsetY;

        this.setNumber(this.handleStart);
        this.moveHandle(false);
        this.notify();

        window.document.addEventListener('mousemove', this.onMouseDown);
        window.document.addEventListener('mouseup', this.onMouseUp);

        event.preventDefault();
        event.stopPropagation();
    };

    private onMouseDown = (event: MouseEvent): void => {
        const delta = (this.direction === 'horizontal' ? event.clientX : event.clientY) - this.startPos;
        this.setNumber(this.handleStart + delta);
        this.moveHandle(true);
    };

    private onMouseUp = (): void => {
        this.mouseIsDown = false;
        this.mouseUp.emit(this.number);
        window.document.removeEventListener('mousemove', this.onMouseDown);
        window.document.removeEventListener('mouseup', this.onMouseUp);
    };
}

export interface ISliderConfig {
    min?: number;
    max?: number;
    accuracy?: number;
}
