import { Injectable } from '@angular/core';
import { calculateBoundingBox } from '@creative/elements/utils';
import { isLocked } from '@creative/nodes';
import { isHidden, toFlatElementNodeList } from '@creative/nodes/helpers';
import { IBoundingBox } from '@domain/dimension';
import { combineLatest, merge, Observable } from 'rxjs';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { PropertiesService } from '../properties-panel/properties.service';
import { EditorEventService } from './editor-event/editor-event.service';
import { ElementSelectionService } from './element-selection.service';

/** Default bounding box is with hidden elements excluded */
type BoundingBoxes = {
    fullSelection: Readonly<IBoundingBox>;
    lockedExcluded: Readonly<IBoundingBox>;
    hiddenExcluded: Readonly<IBoundingBox>;
    lockedAndHiddenExcluded: Readonly<IBoundingBox>;
};

@Injectable()
export class ElementSelectionBoundingBoxService {
    boundingBox$: Observable<BoundingBoxes>;
    boundingBoxes: Readonly<BoundingBoxes | undefined>;

    constructor(
        private propertiesService: PropertiesService,
        private editorEventService: EditorEventService,
        private elementSelectionService: ElementSelectionService
    ) {
        const selectionElementChange$ = this.editorEventService.elements.immediateChange$.pipe(
            withLatestFrom(this.elementSelectionService.change$),
            filter(([value, selection]) => Boolean(value.element && selection.has(value.element))),
            map(([_, selection]) => selection)
        );

        this.boundingBox$ = combineLatest([
            this.propertiesService.selectedStateChange$,
            merge(elementSelectionService.change$, selectionElementChange$)
        ]).pipe(
            map(([state, selection]) => {
                const elements = selection.elements;
                const flatNodeList = toFlatElementNodeList(selection.nodes);
                const notHiddenNodes = flatNodeList.filter(node => !isHidden(node));
                const notLockedNodes = flatNodeList.filter(node => !isLocked(node));
                const notHiddenAndLockedNodes = flatNodeList.filter(
                    node => !isHidden(node) && !isLocked(node)
                );

                const boundingBoxes: BoundingBoxes = {
                    fullSelection: calculateBoundingBox(elements, state),
                    lockedExcluded: calculateBoundingBox(notLockedNodes, state),
                    hiddenExcluded: calculateBoundingBox(notHiddenNodes, state),
                    lockedAndHiddenExcluded: calculateBoundingBox(notHiddenAndLockedNodes, state)
                };

                return boundingBoxes;
            }),
            tap(boundingBoxes => {
                this.boundingBoxes = boundingBoxes;
            })
        );
    }
}
