import { Component, Injector, Input } from '@angular/core';
import { ISocialPlacement } from '@domain/social';
import { BrandService } from '@studio/stores/brand';
import { Observable } from 'rxjs';

@Component({
    template: ''
})
export abstract class BaseOverlayComponent {
    @Input() selectedPlacement: ISocialPlacement;
    brandName$: Observable<string | undefined>;
    brandLogoUrl$: Observable<string | undefined>;

    protected constructor(private injector: Injector) {
        const brandService = this.injector.get(BrandService);
        this.brandName$ = brandService.name$;
        this.brandLogoUrl$ = brandService.brandLogoUrl$;
    }
}
