@let imageSize = size();
@let image = currentImage();
@if (image) {
    <img
        [width]="imageSize.width"
        [height]="imageSize.height"
        [src]="image"
        alt="Asset to be edited" />
}
<canvas
    #overlayCanvas
    [width]="imageSize.width"
    [height]="imageSize.height"
    (mousedown)="startPainting($event)"
    (mouseup)="stopPainting()"
    (mousemove)="onMouseMove($event)"
    class="overlay-canvas">
</canvas>
