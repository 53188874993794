import { CommonModule } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    effect,
    ElementRef,
    HostListener,
    inject,
    input,
    Input,
    OnDestroy,
    output,
    Renderer2,
    viewChild
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UIInputComponent, UIModule } from '@bannerflow/ui';
import { Color } from '@creative/color';
import { fromHSV, parseColorIfValid, toHEX, toHSV } from '@creative/color.utils';
import { IColor } from '@domain/color';
import { ISelectedColor } from '@studio/domain/components/color-picker/color.types';
import { fromEvent, merge, ReplaySubject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
    standalone: true,
    imports: [UIModule, CommonModule],
    selector: 'color-picker',
    templateUrl: './color-picker.component.html',
    styleUrls: ['./color-picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorPickerComponent implements AfterViewInit, OnDestroy {
    private changeDetectorRef = inject(ChangeDetectorRef);
    private destroyRef = inject(DestroyRef);
    private renderer = inject(Renderer2);
    public host = inject(ElementRef);

    paletteCanvas = viewChild.required<ElementRef<HTMLCanvasElement>>('palette');
    hueCanvas = viewChild.required<ElementRef<HTMLCanvasElement>>('hue');
    alphaCanvas = viewChild.required<ElementRef<HTMLCanvasElement>>('alpha');
    colorInput = viewChild.required<UIInputComponent>('colorInput');
    alphaInput = viewChild.required<UIInputComponent>('alphaInput');

    alphaSelector = viewChild.required<ElementRef<HTMLElement>>('alphaSelector');
    hueSelector = viewChild.required<ElementRef<HTMLElement>>('hueSelector');
    paletteSelector = viewChild.required<ElementRef<HTMLElement>>('paletteSelector');

    colorMixed = input<boolean>(false);
    disabled = input<boolean>(false);
    startingColor = input<IColor>();

    undo = output<void>();
    redo = output<void>();
    valueChange = output<Color>();
    editStart = output<void>();
    editEnd = output<boolean>();
    willChange = output<void>();
    preventTextBlur = output<void>();

    @Input()
    set value(value: Color) {
        this.startColor = value.toString();

        const colorInput = this.colorInput()?.valueContainer?.nativeElement;
        const alphaInput = this.alphaInput()?.valueContainer?.nativeElement;
        if (document.activeElement !== colorInput && document.activeElement !== alphaInput) {
            this.setColor(value);
            this.render();
        }
    }

    get value(): Color {
        return this.color;
    }

    @Input() selectedColor$?: ReplaySubject<ISelectedColor>;

    hexColor: string;
    alphaValue: number;

    paletteMarker = { x: 0, y: 0 };
    hueMarker = { y: 0 };
    alphaMarker = { y: 0 };

    initialized = false;

    private startColor?: string;
    private color: Color = new Color();
    private paletteCtx: CanvasRenderingContext2D | null;
    private hueCtx: CanvasRenderingContext2D | null;
    private alphaCtx: CanvasRenderingContext2D | null;
    private canvasSize = { width: 0, height: 0 };
    private mouseDown = false;
    private checkerImage = new Image();
    private loaded: boolean;
    private oldColor: string;

    private mouseMoveUnlisten?: () => void;
    private mouseUpUnlisten?: () => void;

    @HostListener('mousedown', ['$event'])
    onMouseDown(event: MouseEvent): void {
        event.stopPropagation();
    }

    constructor() {
        effect(() => {
            const startingColor = this.startingColor();
            if (!startingColor) {
                return;
            }
            this.value = startingColor;
        });
        effect(() => {
            const paletteCanvas = this.paletteCanvas().nativeElement;
            const canvasRect = paletteCanvas.getBoundingClientRect();
            this.canvasSize = {
                width: canvasRect.width,
                height: canvasRect.height
            };
            const canvasParentRect = paletteCanvas.parentElement!.getBoundingClientRect();
            paletteCanvas.width = canvasParentRect.width;
            paletteCanvas.height = canvasParentRect.height;
        });
    }

    ngAfterViewInit(): void {
        this.checkerImage.src =
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAMElEQVQokWM8f/78fwYswMDAgBGbOBM2QXxgOGhg/P8fayAxXLhwAavEIPQD7TUAAC36CoBkKtP7AAAAAElFTkSuQmCC';

        this.checkerImage.onload = (): void => {
            this.loaded = true;
            this.paletteCtx = this.paletteCanvas().nativeElement.getContext('2d');
            this.hueCtx = this.hueCanvas().nativeElement.getContext('2d');
            this.alphaCtx = this.alphaCanvas().nativeElement.getContext('2d');
            this.bindHueEvents();
            this.bindPaletteEvents();
            this.bindAlphaEvents();
            this.setupBlurPrevention();
            this.render();
            this.drawHue();
            this.initialized = true;
            this.detectChanges();
        };

        if (this.selectedColor$) {
            this.selectedColor$
                .pipe(
                    filter(selectedColor => selectedColor.sender !== 1),
                    takeUntilDestroyed(this.destroyRef)
                )
                .subscribe(({ color }) => {
                    if (color) {
                        this.startColor = color.toString();
                        this.setColor(color);
                        this.render();
                    }
                });
        }
    }

    emitUndo(): void {
        if (this.disabled()) {
            return;
        }
        this.undo.emit();
    }

    emitRedo(): void {
        if (this.disabled()) {
            return;
        }
        this.redo.emit();
    }

    onBlur(): void {
        if (this.disabled()) {
            return;
        }
        this.editEnd.emit(this.oldColor !== this.color.toString());
    }

    /**
     * When user inputs a color by textfield
     */
    onHexInput(hexColor: string): void {
        if (this.disabled()) {
            return;
        }
        this.hexColor = hexColor;
        const newValue = parseColorIfValid(hexColor);

        if (newValue) {
            newValue.alpha = this.alphaValue;
            this.oldColor = this.color.toString();

            this.color = newValue;
            this.colorInput().value = this.hexColor;
            this.render();
            this.notify();
        }
    }

    onAlphaInput(alpha: number): void {
        if (this.disabled()) {
            return;
        }
        this.willChange.emit();
        this.color.alpha = alpha;
        this.alphaValue = alpha;
        this.render();
        this.notify();
    }

    /**
     * Emit changes
     */
    private notify(): void {
        this.selectedColor$?.next({ color: this.color, sender: 1 });
        this.valueChange.emit(this.color);
    }

    /**
     * Update view
     */
    render(): void {
        if (!this.loaded) {
            return;
        }
        this.positionMarkers(this.color);
        this.drawPalette(this.getHueColor());
        this.drawAlpha(this.color);
        this.detectChanges();
    }

    private getHueColor(): Color {
        return fromHSV(-(this.hueMarker.y / this.canvasSize.height) + 1, 1, 1);
    }

    private getMouseCoords(e: MouseEvent, canvas: Element): { x: number; y: number } {
        const canvasRect = canvas.getBoundingClientRect();

        let x = e.pageX - canvasRect.left;
        let y = e.pageY - canvasRect.top;

        if (x < 0) {
            x = 0;
        }

        if (x >= canvasRect.width) {
            x = canvasRect.width;
        }

        if (y < 0) {
            y = 0;
        }

        if (y >= canvasRect.height) {
            y = canvasRect.height;
        }

        return { x: x, y: y };
    }

    /**
     * Position markers based on colors
     * @param color
     */
    private positionMarkers(color: Color): void {
        const hsv = toHSV(color);

        // Palette marker
        this.paletteMarker.x = hsv.s * this.canvasSize.width;
        this.paletteMarker.y = this.canvasSize.height - hsv.v * this.canvasSize.height;

        // Hue marker
        this.hueMarker.y = this.canvasSize.height * (1 - hsv.h);

        // Alpha marker
        this.alphaMarker.y = (1 - color.alpha / 100) * this.canvasSize.height;
    }

    /**
     * Set color based on palette marker position
     */
    private selectColorByMarker(): void {
        const x = this.paletteMarker.x;
        const y = this.paletteMarker.y;
        const hy = this.hueMarker.y;

        const h = Math.max(-(hy / this.canvasSize.height) + 1, 0);
        const s = Math.max(Math.min(Math.max(x / this.canvasSize.width, 0), 1), 0);
        const v = Math.max(-((y - this.canvasSize.height) / this.canvasSize.height), 0);

        const color = fromHSV(h, s, v);

        color.alpha = Math.round((1 - this.alphaMarker.y / this.canvasSize.height) * 100);

        this.setColor(color);

        this.drawPalette(this.getHueColor());
        this.drawAlpha(color);

        this.detectChanges();
        this.notify();
    }
    /**
     * Draw palette colors
     * @param color
     */
    private drawPalette(color: Color): void {
        const ctx = this.paletteCtx;
        if (!ctx) {
            return;
        }
        const canvas = this.paletteCanvas().nativeElement;

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.fillStyle = toHEX(color);
        ctx.rect(0, 0, canvas.width, canvas.height);
        ctx.fill();

        const whiteGradient = ctx.createLinearGradient(0, 0, canvas.width, 0);
        whiteGradient.addColorStop(0, '#fff');
        whiteGradient.addColorStop(1, 'transparent');
        ctx.fillStyle = whiteGradient;
        ctx.rect(0, 0, canvas.width, canvas.height);
        ctx.fill();

        const blackGradient = ctx.createLinearGradient(0, 0, 0, canvas.height);
        blackGradient.addColorStop(0, 'transparent');
        blackGradient.addColorStop(1, '#000');
        ctx.fillStyle = blackGradient;
        ctx.rect(0, 0, canvas.width, canvas.height);
        ctx.fill();
    }

    /**
     * Draw hue slider
     */
    private drawHue(): void {
        const canvas = this.hueCanvas().nativeElement;
        const { width, height } = canvas.parentElement!.getBoundingClientRect();
        canvas.width = width;
        canvas.height = height;

        const ctx = this.hueCtx;
        if (!ctx) {
            return;
        }

        const hueGradient = ctx.createLinearGradient(0, 0, 0, canvas.height);
        hueGradient.addColorStop(0.0, '#ff0000');
        hueGradient.addColorStop(0.17, '#ff00ff');
        hueGradient.addColorStop(0.33, '#0000ff');
        hueGradient.addColorStop(0.5, '#00ffff');
        hueGradient.addColorStop(0.67, '#00ff00');
        hueGradient.addColorStop(0.83, '#ffff00');
        hueGradient.addColorStop(1.0, '#ff0000');

        ctx.fillStyle = hueGradient;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
    }

    /**
     * Draw alpha slider background
     * @param event
     */
    private drawAlpha(color: Color): void {
        const canvas = this.alphaCanvas().nativeElement;
        const { width, height } = canvas.parentElement!.getBoundingClientRect();
        canvas.width = width;
        canvas.height = height;

        const ctx = this.alphaCtx;
        if (!ctx) {
            return;
        }

        const alphaGradient = ctx.createLinearGradient(0, 0, 0, canvas.height);

        alphaGradient.addColorStop(0.0, `rgba(${color.red}, ${color.green}, ${color.blue}, 1)`);
        alphaGradient.addColorStop(1.0, `rgba(${color.red}, ${color.green}, ${color.blue}, 0)`);

        const pattern = ctx.createPattern(this.checkerImage, 'repeat');
        if (pattern) {
            ctx.fillStyle = pattern;
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            ctx.fillStyle = alphaGradient;
            ctx.fillRect(0, 0, canvas.width, canvas.height);
        }
    }

    /**
     * Change position of palette marker
     * @param event
     */
    private setPaletteColor(event: MouseEvent): void {
        event.stopPropagation();
        this.paletteMarker = this.getMouseCoords(event, this.paletteCanvas().nativeElement);
        this.selectColorByMarker();
    }

    /**
     * Change position of hue marker
     * @param event
     */
    private setHue(event: MouseEvent): void {
        event.stopPropagation();
        this.hueMarker = this.getMouseCoords(event, this.hueCanvas().nativeElement);
        if (this.colorMixed()) {
            this.paletteMarker.x = this.canvasSize.width;
            this.paletteMarker.y = 0;
        }
        this.selectColorByMarker();
    }

    /**
     * When mousedown on alpha slider. Start listen to mouse move changes
     */
    private setAlpha(event: MouseEvent): void {
        event.stopPropagation();
        this.alphaMarker = this.getMouseCoords(event, this.alphaCanvas().nativeElement);
        this.selectColorByMarker();
    }

    /**
     * When mousedown on palette slider. Start listen to mouse move changes
     */
    private bindPaletteEvents(): void {
        const palette = fromEvent<PointerEvent>(this.paletteCanvas().nativeElement, 'mousedown');
        const paletteSelector = fromEvent<PointerEvent>(
            this.paletteSelector().nativeElement,
            'mousedown'
        );

        merge(palette, paletteSelector)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(event => {
                if (this.disabled()) {
                    return;
                }
                this.startColor = this.color.toString();
                this.editStart.emit();
                this.mouseDown = true;
                this.setPaletteColor(event);
                this.bindDocumentEvents(this.setPaletteColor);
            });
    }

    /**
     * When text elemtn is selected. Prevent text element selection to blur.
     */
    private setupBlurPrevention(): void {
        this.renderer.listen(this.colorInput().valueContainer.nativeElement, 'focus', () => {
            if (this.disabled()) {
                return;
            }
            this.preventTextBlur.emit();
        });

        this.renderer.listen(this.alphaInput().valueContainer.nativeElement, 'focus', () => {
            if (this.disabled()) {
                return;
            }
            this.preventTextBlur.emit();
        });
    }

    /**
     * When mousedown on hue slider. Start listen to mouse move changes
     */
    private bindHueEvents(): void {
        const hue = fromEvent<PointerEvent>(this.hueCanvas().nativeElement, 'mousedown');
        const hueSelector = fromEvent<PointerEvent>(this.hueSelector().nativeElement, 'mousedown');

        merge(hue, hueSelector)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(event => {
                this.startColor = this.color.toString();
                this.editStart.emit();
                this.mouseDown = true;
                this.setHue(event);

                this.bindDocumentEvents(this.setHue);
            });
    }

    /**
     * Listen to alpha drag changes
     */
    private bindAlphaEvents(): void {
        const alpha = fromEvent<PointerEvent>(this.alphaCanvas().nativeElement, 'mousedown');
        const selector = fromEvent<PointerEvent>(this.alphaSelector().nativeElement, 'mousedown');

        merge(alpha, selector)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(event => {
                this.startColor = this.color.toString();
                this.editStart.emit();
                this.mouseDown = true;
                this.setAlpha(event);
                this.bindDocumentEvents(this.setAlpha);
            });
    }

    /**
     * Listen to mouse events outside this component
     * @param func
     */
    private bindDocumentEvents(func: (event: MouseEvent) => void): void {
        this.unbindDocumentEvents();

        this.mouseMoveUnlisten = this.renderer.listen(document, 'mousemove', (event: MouseEvent) => {
            if (this.mouseDown) {
                func.bind(this)(event);
            }
        });
        this.mouseUpUnlisten = this.renderer.listen(document, 'mouseup', () => {
            this.mouseDown = false;
            this.unbindDocumentEvents();
            const wasModified = this.startingColor()
                ? this.startingColor()?.toString() !== this.color.toString()
                : this.color.toString() !== this.startColor;

            this.editEnd.emit(wasModified);
        });
    }

    /**
     * Remove document mouse event listeners
     */
    private unbindDocumentEvents(): void {
        if (this.mouseMoveUnlisten) {
            this.mouseMoveUnlisten();
            this.mouseMoveUnlisten = undefined;
        }
        if (this.mouseUpUnlisten) {
            this.mouseUpUnlisten();
            this.mouseUpUnlisten = undefined;
        }
    }

    /**
     * Select color. If a gradient use color stop selected by gradientHelper on canvas
     */
    private setColor(color: Color): void {
        this.editStart.emit();
        this.color = color;
        this.hexColor = toHEX(this.color);
        this.alphaValue = this.color.alpha;
        this.detectChanges();
    }

    /**
     * Detect changes in view
     */
    detectChanges(): void {
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }
    }

    /**
     * Kill and unbind all listeners
     */
    ngOnDestroy(): void {
        this.unbindDocumentEvents();
    }
}
