<div
    class="full-screen"
    (click)="fullScreenClick($event)">
    <div
        class="full-screen-header"
        [ngClass]="{ hidden: isHidingUI }">
        <div class="full-screen-header-left">
            <ui-svg-icon
                icon="close"
                (click)="close()"></ui-svg-icon>
            <ng-container
                *ngIf="creative.version.id | localizationForVersion | async as localizationId">
                <version-flag
                    [localizationId]="localizationId"
                    [showVersionName]="true"
                    [isFullScreen]="true"
                    [versionId]="creative.version.id">
                </version-flag>
            </ng-container>
        </div>
        <ui-toggle-switch
            class="target-url"
            [(selected)]="isTargetURLOn"
            label="Target URLs"
            >Target URLs</ui-toggle-switch
        >
    </div>
    <div class="full-screen-body">
        <div
            #creativeContainer
            class="creative-container">
            <div
                #responsiveElement
                class="responsive">
                <studio-creative
                    [attr.data-test-id]="'creative-' + creative.id"
                    [ngClass]="{ 'ignore-events': !isHidingUI }"
                    [creative]="creative"
                    [renderOnInit]="true"
                    [shouldPlay]="true"
                    [preview]="true"
                    [showTargetUrl]="isTargetURLOn"></studio-creative>
            </div>
        </div>
    </div>
    <div
        class="full-screen-footer"
        [ngClass]="{ hidden: isHidingUI }">
        <ui-svg-icon
            icon="arrow-left"
            [ngClass]="{
                disable: isFirstCreative
            }"
            (click)="prevCreative($event)">
        </ui-svg-icon>
        <div>
            <span class="size">{{ creative.size.width }} × {{ creative.size.height }}</span>
            <span
                *ngIf="creative.size.name"
                class="size-name"
                truncateSpan
                [spanText]="creative.size.name"></span>
        </div>
        <ui-svg-icon
            icon="arrow-right"
            [ngClass]="{
                disable: isLastCreative
            }"
            (click)="nextCreative($event)">
        </ui-svg-icon>
    </div>
</div>
