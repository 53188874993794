import { ActionOperationMethod, ActionTrigger } from '@domain/action';

export interface IMappedAction {
    name: ActionName;
    value: ActionOperationMethod;
}

type ActionName = 'Go to URL' | 'Set state' | 'Remove state' | 'Clear all states';

export const ActionTriggers: ITrigger[] = [
    {
        name: 'Click',
        value: ActionTrigger.Click
    },
    {
        /**
         * Intentionally not matching due to event propagations
         * and naming being more common
         */
        name: 'Mouse over',
        value: ActionTrigger.MouseEnter
    },
    {
        name: 'Mouse leave',
        value: ActionTrigger.MouseLeave
    },
    {
        name: 'Mouse down',
        value: ActionTrigger.MouseDown
    },
    {
        name: 'Mouse up',
        value: ActionTrigger.MouseUp
    }
];

export interface ITrigger {
    name: TriggerName;
    value: ActionTrigger;
}

type TriggerName = 'Click' | 'Mouse leave' | 'Mouse over' | 'Mouse down' | 'Mouse up';
