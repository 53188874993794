import { IState, OneOfStatePropertyKeys } from './state';

export const animatableProperties: OneOfStatePropertyKeys[] = [
    'x',
    'y',
    'originX',
    'originY',
    'rotationX',
    'rotationY',
    'rotationZ',
    'opacity',
    'scaleX',
    'scaleY',
    'fill',
    'radius',
    'shadows',
    'filters',
    'textColor',
    'border'
] as const;

export type AnimatableProperty = (typeof animatableProperties)[number];

export type AnimatablePropertyValue = IState[AnimatableProperty];

export const transitionSettingPropertyToUnitMap = {
    direction: 'angle',
    distance: 'number'
};
