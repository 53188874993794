import { IFontFamily, IFontFamilyStyle } from '@domain/font-families';
import {
    LayerItem,
    PSDElement,
    PSDElementType,
    PSDErrorType
} from '@studio/domain/components/psd-import/psd';
import { cloneDeep } from '@studio/utils/clone';

export function setVisibilityOnLayerAndChildren(
    layers: LayerItem[],
    targetLayer: LayerItem,
    newVisibleValue: boolean
): LayerItem[] {
    const isTargetRoot = targetLayer.type === PSDElementType.Root;

    if (isTargetRoot) {
        return layers.map(layer => ({ ...layer, hidden: newVisibleValue }));
    }

    const newLayers: LayerItem[] = [];

    const layersIdToChange = new Set<string>(getLayerIds(targetLayer));

    for (const layer of layers) {
        if (isTargetRoot || layersIdToChange.has(layer.id)) {
            newLayers.push({
                ...layer,
                hidden: newVisibleValue
            });
            continue;
        }
        newLayers.push(layer);
    }

    return newLayers;
}

export function setSelectionOnLayerAndChildren(
    layers: LayerItem[],
    targetLayer: LayerItem | undefined,
    newSelectedValue: boolean
): LayerItem[] {
    const isTargetRoot = !targetLayer || targetLayer.type === PSDElementType.Root;

    if (isTargetRoot) {
        return layers.map(layer => ({ ...layer, selected: newSelectedValue }));
    }

    const newLayers: LayerItem[] = [];

    const layersIdToChange = new Set<string>(getLayerIds(targetLayer));

    for (const layer of layers) {
        if (isTargetRoot || layersIdToChange.has(layer.id)) {
            newLayers.push({
                ...layer,
                selected: newSelectedValue
            });
            continue;
        }
        newLayers.push(layer);
    }

    return newLayers;
}

export function setCollapseOnLayerAndChildren(
    layers: LayerItem[],
    targetLayer: LayerItem,
    newCollapseValue: boolean
): LayerItem[] {
    const isTargetRoot = targetLayer.type === PSDElementType.Root;

    if (isTargetRoot) {
        return layers.map(layer => ({ ...layer, collapsed: newCollapseValue }));
    }

    const newLayers: LayerItem[] = [];

    const layersIdToChange = new Set<string>(getLayerIds(targetLayer));

    for (const layer of layers) {
        if (isTargetRoot || layersIdToChange.has(layer.id)) {
            newLayers.push({
                ...layer,
                collapsed: newCollapseValue
            });
            continue;
        }
        newLayers.push(layer);
    }

    return newLayers;
}

export function getLayerIds(layer: PSDElement, ids?: string[]): string[] {
    if (layer.type !== PSDElementType.Group) {
        return [...(ids ?? []), layer.id];
    }
    return [
        ...(ids ?? []),
        layer.id,
        ...(layer.data.children?.flatMap(child => getLayerIds(child, ids)) ?? [])
    ];
}

export function getValidChildren(layers: LayerItem[], layer: LayerItem): LayerItem[] {
    const childrenIds = new Set(getLayerIds(layer));
    const validChildren = layers.filter(
        layer =>
            childrenIds.has(layer.id) &&
            layer.selected &&
            !layer.error?.isCritical &&
            layer.type !== PSDElementType.Group
    );
    return validChildren;
}

export function fixFont(
    layers: LayerItem[],
    fontFamily: IFontFamily,
    fontStyle: IFontFamilyStyle,
    fontToFix: string,
    applyToAll: boolean
): LayerItem[] {
    const newLayers: LayerItem[] = [];
    for (const layer of layers) {
        if (layer.error?.type !== PSDErrorType.OriginalFontNotFound || layer.error?.isFixed) {
            newLayers.push(layer);
            continue;
        }

        if (layer.error.data?.missingFontName !== fontToFix && !applyToAll) {
            newLayers.push(layer);
            continue;
        }
        newLayers.push(cloneLayerWithFont(layer, fontFamily, fontStyle));
    }
    return newLayers;
}

function cloneLayerWithFont(
    layer: LayerItem,
    fontFamily: IFontFamily,
    fontStyle: IFontFamilyStyle
): LayerItem {
    const clonedLayer = cloneDeep(layer);

    if (clonedLayer.type === PSDElementType.Text) {
        clonedLayer.data.font = fontStyle.id;
    }

    if (clonedLayer.error) {
        clonedLayer.error.isFixed = true;
    }
    return clonedLayer;
}
