<ng-container *ngIf="sizes">
    <div class="info-container">
        <p
            class="delete-sizes"
            *ngIf="amountOfSizes === 1; else multipleSizes">
            You are deleting the size:
        </p>
        <ng-template #multipleSizes>
            <p class="delete-sizes">You are deleting {{ amountOfSizes }} sizes:</p>
        </ng-template>
        <div class="size-container">
            <ul class="size-list">
                <li
                    *ngFor="let size of displaySizeArr"
                    class="list-item">
                    <p>{{ size.width }} &times; {{ size.height }}</p>
                </li>
            </ul>
            <p
                class="tooltip-text"
                *ngIf="amountOfSizes > 3"
                uiTooltip="{{ tooltipSizes }}"
                uiTooltipTrigger="hover"
                uiTooltipPosition="right">
                +{{ amountOfSizes - displaySizeArr.length }} more
            </p>
        </div>
        <p class="warning-text">Elements not used in other active sizes will be lost.</p>
        <p class="warning-text second-row">
            This action affects <strong>all versions</strong> and can not be undone.
        </p>
    </div>
    <ui-dialog-buttons ui-theme="default">
        <ui-button
            text="Cancel"
            (click)="cancel()">
        </ui-button>
        <ui-button
            type="primary"
            text="{{ deleteButtontext }}"
            (click)="deleteSizes()">
        </ui-button>
    </ui-dialog-buttons>
</ng-container>
