import {
    Component,
    ChangeDetectionStrategy,
    Output,
    EventEmitter,
    ChangeDetectorRef,
    OnInit,
    OnDestroy
} from '@angular/core';
import { getHotkeysAsKeyValueList } from '@studio/hotkeys';
import { ElementAlign } from './element-align.enum';
import { ElementDistribution } from './element-distribution.enum';
import { PropertiesService } from './properties.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MutatorService } from '../services/mutator.service';
import { EditorEventService, ElementSelectionService } from '../services';
import { changeFilter } from '../services/editor-event';
import { ElementSelection } from '@creative/nodes/selection';

@Component({
    selector: 'align-bar',
    templateUrl: './align-bar.component.html',
    styleUrls: ['./align-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlignBarComponent implements OnInit, OnDestroy {
    @Output() align: EventEmitter<ElementAlign> = new EventEmitter<ElementAlign>();
    @Output() distribute: EventEmitter<ElementDistribution> = new EventEmitter<ElementDistribution>();
    ElementAlign = ElementAlign;
    ElementDistribution = ElementDistribution;

    isAligned = {};
    isDistributed = {};
    keyboardShortcuts = getHotkeysAsKeyValueList(['Editor']);
    inStateView = false;
    unsubscribe$ = new Subject<void>();
    alignmentConfiguration: IAlignBarConfig;
    currentSelection?: ElementSelection;

    constructor(
        public propertiesService: PropertiesService,
        private changeDetection: ChangeDetectorRef,
        private editorEvent: EditorEventService,
        private mutatorService: MutatorService,
        private elementSelectionService: ElementSelectionService
    ) {}

    ngOnInit(): void {
        this.propertiesService
            .observeDataElementOrStateChange(false)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.inStateView = this.propertiesService.inStateView;
                this.updateAlignments();
            });

        this.editorEvent.elements.change$
            .pipe(changeFilter({ explicitProperties: ['x', 'y', 'width', 'height'] }))
            .subscribe(() => this.updateAlignments());

        this.elementSelectionService.change$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.currentSelection = this.elementSelectionService.currentSelection;
            this.changeDetection.detectChanges();
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    updateAlignments(): void {
        const alignments = this.mutatorService.getElementsAlignmentWithinSelection(
            this.elementSelectionService.currentSelection
        );
        const distributions = this.mutatorService.getElementsDistributionWithinSelection();

        this.isAligned = {};
        this.isDistributed = {};

        alignments.forEach(element => {
            this.isAligned[element] = true;
        });

        distributions.forEach(element => {
            this.isDistributed[element] = true;
        });

        this.alignmentConfiguration = {
            alignments,
            distributions
        };

        this.changeDetection.detectChanges();
    }

    onAlign(alignment: ElementAlign): void {
        if (!this.currentSelection?.length) {
            return;
        }
        this.align.emit(alignment);
    }

    onDistribute(distribution: ElementDistribution): void {
        if (!this.currentSelection?.length) {
            return;
        }
        this.distribute.emit(distribution);
    }
}

export interface IAlignBarConfig {
    alignments: ElementAlign[];
    distributions: ElementDistribution[];
}
