import { deserializeVersionProperties } from '@creative/serialization/property-value-serializer';
import { VersionDtoV2 } from '@domain/api/generated/sapi';
import { IVersion } from '@domain/creativeset/version';
import { removeGraphQlTypename } from './creativeset';

export function deserializeVersion(version: VersionDtoV2): IVersion {
    removeGraphQlTypename(version);
    return {
        ...version,
        localization: { id: version.localization?.id },
        properties: deserializeVersionProperties(version.properties)
    };
}

export function deserializeVersions(versions: VersionDtoV2[]): IVersion[] {
    return versions.map(version => deserializeVersion(version));
}
