import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Logger } from '@bannerflow/sentinel-logger';
import { UIDialogComponent } from '@bannerflow/ui';
import QRCodeStyling from 'qr-code-styling';
import { QRCodeService } from '../../services/qr-code.service';

@Component({
    selector: 'qr-code-modal',
    templateUrl: './qr-code-modal.component.html',
    styleUrls: ['./qr-code-modal.component.scss']
})
export class QRCodeModalComponent implements OnInit {
    @ViewChild('canvas', { static: true }) canvas: ElementRef<HTMLElement>;

    public targetMessage: string;
    private logger = new Logger('QRCodeModalComponent');

    constructor(
        private dialog: UIDialogComponent,
        private qrCodeService: QRCodeService
    ) {}

    async ngOnInit(): Promise<void> {
        const { link, message } = this.dialog.config.data;
        if (!link) {
            throw new Error('No temporary link found');
        }
        this.targetMessage = message;

        await this.generateQRCode(link, this.canvas.nativeElement);
    }

    close(): void {
        this.dialog.dialogRef.close();
    }

    private async generateQRCode(data: string, element: HTMLElement): Promise<void> {
        if (!QRCodeStyling) {
            this.logger.warn('Generating QR code canceled, because the library has not been found');
            return;
        }

        const qrCode = new QRCodeStyling({
            width: 186,
            height: 186,
            data,
            image: 'assets/logo.png',
            dotsOptions: {
                type: 'square',
                color: '#000000'
            },
            cornersSquareOptions: {
                type: 'square',
                color: '#000000'
            },
            qrOptions: {
                typeNumber: 0,
                mode: 'Byte',
                errorCorrectionLevel: 'Q'
            },
            imageOptions: {
                imageSize: 0.4,
                margin: 6
            }
        });

        qrCode.append(element);
    }
}
