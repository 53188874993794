import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sortArray',
    standalone: true
})
export class SortArrayPipe implements PipeTransform {
    transform<T>(array: Readonly<T>[], sortProperties: ISortPriorities): T[] {
        // destructuring, since array will be readonly and it cannot be sorted
        return [...array].sort((current, next) => {
            let sum = 0;
            for (const [key, value] of Object.entries(sortProperties)) {
                if (current[key] > next[key]) {
                    sum += value === 'asc' ? 1 : -1;
                } else {
                    sum += value === 'asc' ? -1 : 1;
                }
            }
            return sum;
        });
    }
}

export interface ISortPriorities {
    [property: string]: 'asc' | 'desc';
}
