import { Injectable, ErrorHandler } from '@angular/core';
import { UINotificationService } from '@bannerflow/ui';
import { isValidationError, IValidationError } from '@creative/serialization/validator.utils';
import { isApolloError, ApolloError } from '@apollo/client/core';
import { HttpErrorResponse } from '@angular/common/http';

export type HandledError = Error | ApolloError | IValidationError;

@Injectable()
export class SaveErrorHandlerService {
    constructor(
        private errorHandler: ErrorHandler,
        private uiNotificationService: UINotificationService
    ) {}

    handleSaveError(error: HandledError | unknown): void {
        const errorMessage = this.getErrorNotificationMessage(error);
        this.uiNotificationService.open(errorMessage, {
            type: 'error',
            placement: 'top'
        });
        this.errorHandler.handleError(error);
    }

    private getErrorNotificationMessage(error: HandledError | unknown): string {
        if (!(error instanceof Error)) {
            return 'Could not save. Unknown error.';
        }

        if (isApolloError(error)) {
            if (error.graphQLErrors.length) {
                return `We could not save your creative - please try again after a few actions.`;
            }

            if (error.networkError) {
                if (
                    error.networkError instanceof HttpErrorResponse &&
                    error.networkError.status === 401
                ) {
                    return 'Could not save due to authorization issues. Please try again, login again or contact support.';
                }

                return 'Could not save due to network issues. Please try again or contact support';
            }
        }

        if (isValidationError(error)) {
            return 'We could not process the creative - please try again or contact support';
        }

        return `Could not save. ${error.message}. Please try again or contact support`;
    }
}
