<div
    class="overlay-section top"
    *ngIf="
        selectedPlacement.type === MetaPlacement.Instagram ||
        selectedPlacement.type === MetaPlacement.Facebook ||
        selectedPlacement.type === MetaPlacement.Messenger
    ">
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 382 90">
        <g
            id="gradient"
            transform="matrix(1,0,0,1.00524,2.84217e-14,0)">
            <rect
                x="0"
                y="0"
                width="382"
                height="89.531"
                style="fill: url(#linear1)" />
        </g>
        <circle
            id="brand_logo"
            cx="28.352"
            cy="31.336"
            r="15"
            fill="url(#logo)" />
        <g
            id="sponsored"
            transform="matrix(1,0,0,1,-121.876,-21.1504)">
            <text
                x="178.579px"
                y="64.682px"
                style="
                    font-family: 'OpenSans-Regular', 'Open Sans', sans-serif;
                    font-size: 13px;
                    fill: white;
                ">
                Sponsored
            </text>
        </g>
        <g
            id="brand_name"
            transform="matrix(1,0,0,1,-121.876,-36.1065)">
            <text
                x="178.579px"
                y="64.682px"
                style="
                    font-family: 'OpenSans-Bold', 'Open Sans', sans-serif;
                    font-weight: 700;
                    font-size: 13px;
                    fill: white;
                ">
                {{ (brandName$ | async) || 'Bannerflow' }}
            </text>
        </g>
        <path
            id="more"
            d="M319.328,31.336C319.328,30.512 318.66,29.844 317.836,29.844C317.012,29.844 316.344,30.512 316.344,31.336C316.344,32.16 317.012,32.828 317.836,32.828C318.66,32.828 319.328,32.16 319.328,31.336ZM325.297,31.336C325.297,30.512 324.629,29.844 323.805,29.844C322.981,29.844 322.312,30.512 322.312,31.336C322.312,32.16 322.981,32.828 323.805,32.828C324.629,32.828 325.297,32.16 325.297,31.336ZM329.773,29.844C330.598,29.844 331.266,30.512 331.266,31.336C331.266,32.16 330.598,32.828 329.773,32.828C328.949,32.828 328.281,32.16 328.281,31.336C328.281,30.512 328.949,29.844 329.773,29.844Z"
            style="fill: white" />
        <path
            id="close"
            d="M354.171,24.397C354.459,24.109 354.927,24.109 355.215,24.397L360.362,29.544L365.511,24.397C365.8,24.109 366.267,24.109 366.556,24.397C366.844,24.686 366.844,25.153 366.556,25.442L361.408,30.59L366.556,35.738C366.844,36.026 366.844,36.494 366.556,36.782C366.267,37.071 365.8,37.071 365.511,36.782L360.363,31.634L355.215,36.782C354.927,37.071 354.459,37.071 354.171,36.782C353.882,36.494 353.882,36.026 354.171,35.738L359.319,30.59L354.171,25.442C353.882,25.153 353.882,24.686 354.171,24.397Z"
            style="fill: white" />

        <path
            id="progress_bar"
            d="M376.031,8.207C376.031,7.795 375.696,7.461 375.285,7.461L6.715,7.461C6.303,7.461 5.969,7.795 5.969,8.207L5.969,8.207C5.969,8.619 6.303,8.953 6.715,8.953L375.285,8.953C375.696,8.953 376.031,8.619 376.031,8.207L376.031,8.207Z"
            style="fill: white; fill-opacity: 0.5" />
        <path
            id="progress_body"
            d="M191,8.207C191,7.795 190.665,7.461 190.254,7.461L6.715,7.461C6.303,7.461 5.969,7.795 5.969,8.207L5.969,8.207C5.969,8.619 6.303,8.953 6.715,8.953L190.254,8.953C190.665,8.953 191,8.619 191,8.207L191,8.207Z"
            style="fill: white" />
        <defs>
            <pattern
                id="logo"
                x="15"
                y="15"
                patternContentUnits="objectBoundingBox"
                height="100%"
                width="100%"
                viewBox="0 0 1 1"
                preserveAspectRatio="xMidYMid slice">
                <image
                    height="1"
                    width="1"
                    preserveAspectRatio="xMidYMid slice"
                    [attr.xlink:href]="(brandLogoUrl$ | async) || 'assets/logo.png'"></image>
            </pattern>
            <linearGradient
                id="linear1"
                x1="0"
                y1="0"
                x2="1"
                y2="0"
                gradientUnits="userSpaceOnUse"
                gradientTransform="matrix(5.4822e-15,89.5312,-89.5312,5.4822e-15,191,0)">
                <stop
                    offset="0"
                    style="stop-color: black; stop-opacity: 0.5" />
                <stop
                    offset="1"
                    style="stop-color: black; stop-opacity: 0" />
            </linearGradient>
        </defs>
    </svg>
</div>

<div class="overlay-section reels-title">
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 382 90"
        *ngIf="
            selectedPlacement.type === MetaPlacement.FacebookReels ||
            selectedPlacement.type === MetaPlacement.InstagramReels
        ">
        <circle
            id="brand_logo"
            cx="28.352"
            cy="34"
            r="15"
            fill="url(#logo)" />
        <g
            id="brand_name"
            transform="matrix(1,0,0,1,-121.876,-36.1065)">
            <text
                x="178.579px"
                y="67px"
                style="
                    font-family: 'OpenSans-Bold', 'Open Sans', sans-serif;
                    font-weight: 700;
                    font-size: 13px;
                    fill: white;
                ">
                {{ (brandName$ | async) || 'Bannerflow' }}
            </text>
        </g>
        <pattern
            id="logo"
            x="15"
            y="15"
            patternContentUnits="objectBoundingBox"
            height="100%"
            width="100%"
            viewBox="0 0 1 1"
            preserveAspectRatio="xMidYMid slice">
            <image
                height="1"
                width="1"
                preserveAspectRatio="xMidYMid slice"
                [attr.xlink:href]="(brandLogoUrl$ | async) || 'assets/logo.png'"></image>
        </pattern>
    </svg>
</div>
<img
    class="overlay-section bottom"
    alt="Social Media Overlay"
    [src]="selectedPlacement | socialImage" />
