<div
    class="left"
    *media="Breakpoint.DesktopUp">
    <ng-content select="[left]"></ng-content>
</div>

<div class="center">
    <ng-content select="[center]"></ng-content>
</div>

<div
    class="right"
    *media="Breakpoint.DesktopUp">
    <ng-content select="[right]"></ng-content>
</div>
