import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as AnimationControlActions from './animation-control.actions';
import * as AnimationControlSelectors from './animation-control.selectors';

@Injectable({ providedIn: 'root' })
export class AnimationControlService {
    private readonly store = inject(Store);

    isPlaying$ = this.store.select(AnimationControlSelectors.getIsPlaying);
    showPreloadImage$ = this.store.select(AnimationControlSelectors.getShowPreloadImage);
    shouldAutoPause$ = this.store
        .select(AnimationControlSelectors.getShouldAutoPause)
        .pipe(filter(shouldAutoPause => shouldAutoPause));
    stepForward$ = new Subject<void>();
    stepBack$ = new Subject<void>();
    stepToStart$ = new Subject<void>();
    isInactive$ = this.store.select(AnimationControlSelectors.getIsInactive);
    isDisabled$ = this.store.select(AnimationControlSelectors.getIsDisabled);
    shownElement$ = this.store
        .select(AnimationControlSelectors.getShownElement)
        .pipe(filter((shownElementId): shownElementId is string => !!shownElementId));

    playAnimations(): void {
        this.store.dispatch(AnimationControlActions.controlActions.playAnimations());
    }

    stopAnimations(): void {
        this.store.dispatch(AnimationControlActions.controlActions.stopAnimations());
    }

    toggleAnimations(): void {
        this.store.dispatch(AnimationControlActions.controlActions.toggleAnimations());
    }

    stepBack(): void {
        this.stepBack$.next();
    }

    stepForward(): void {
        this.stepForward$.next();
    }

    stepToStart(): void {
        this.stepToStart$.next();
        this.store.dispatch(AnimationControlActions.controlActions.stepToStart());
    }

    stepToPreloadImage(): void {
        this.store.dispatch(AnimationControlActions.controlActions.stepToPreloadImage());
    }

    initShowcaseState(): void {
        this.store.dispatch(AnimationControlActions.initActions.initShowcaseState());
    }

    initDisabledState(): void {
        this.store.dispatch(AnimationControlActions.initActions.initDisabledAnimationsState());
    }

    showElement(elementId: string): void {
        this.store.dispatch(AnimationControlActions.controlActions.showElement({ elementId }));
    }
}
