import {
    CreateSizesInCreativesetVariables,
    CreativeSizeNameUpdateResult,
    CreativeSizeNameUpdateVariables,
    ZCreativeSizeDto
} from '@domain/creativeset/size';
import { toGraphqlDto } from '@zod/zod';
import { gql } from './gql.types';

export const CREATE_SIZES = gql<{ id: string }, CreateSizesInCreativesetVariables>`
    mutation CreateSizesInCreativeset($sizes: [SizeInputType]!, $creativesetId: String!) {
        createSizesInCreativeset(sizes: $sizes, creativesetId: $creativesetId) {
            id
        }
    }
`;

export const UPDATE_NAME = gql<CreativeSizeNameUpdateResult, CreativeSizeNameUpdateVariables>`
mutation RenameSizeMutation($sizeId: String!, $name: String!, $creativesetId: String!) {
    renameSizeInCreativeset(
        creativesetId: $creativesetId,
        sizeId: $sizeId,
        name: $name
    )
    {
        id
        sizes {
            ${toGraphqlDto(ZCreativeSizeDto)}
        }
    }
}`;

type DeleteSizesVariables = { ids: string[]; creativesetId: string };

export const DELETE_SIZES = gql<{ id: string }, DeleteSizesVariables>`
    mutation DeleteSizesMutation($ids: [String]!, $creativesetId: String!) {
        deleteSizesInCreativeset(ids: $ids, creativesetId: $creativesetId) {
            creativesetId: id
        }
    }
`;
