import { Injectable } from '@angular/core';
import { IBrandLocalization } from '@domain/brand';
import {
    ILocalization,
    IVersion,
    IVersionedText,
    IVersionProperty,
    RenderDirtyVersionProperty
} from '@domain/creativeset/version';
import { IFeed } from '@domain/feed';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { DirtyProperties, VersionToDirtyProperties } from '@studio/domain/components/translation-page';
import { deepEqual } from '@studio/utils/utils';
import { distinctUntilKeyChanged, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { VersionEntity } from './version.entity';
import { VersionsActions } from './versions.actions';
import * as VersionsSelectors from './versions.selectors';

@Injectable({
    providedIn: 'root'
})
export class VersionsService {
    state$ = this.store.select(VersionsSelectors.getVersionsState);

    loaded$ = this.store.select(VersionsSelectors.getLoaded);
    loading$ = this.store.select(VersionsSelectors.getLoading);
    updateSuccess$ = this.store.select(VersionsSelectors.getUpdatedSuccess);
    error$ = this.store.select(VersionsSelectors.getError);

    versions$ = this.store.select(VersionsSelectors.getVersions);
    versionIds$ = this.store.select(VersionsSelectors.getVersionIds);
    selectableVersions$ = this.store.select(VersionsSelectors.getSelectableVersions);
    defaultVersion$ = this.store.select(VersionsSelectors.getDefaultVersion).pipe(
        filter(version => !!version),
        distinctUntilKeyChanged('id')
    );
    defaultVersionProperties$ = this.store.select(VersionsSelectors.getDefaultVersionProperties);
    selectedVersion$ = this.store
        .select(VersionsSelectors.getSelectedVersion)
        .pipe(distinctUntilKeyChanged('id'));
    selectedVersions$ = this.store.select(VersionsSelectors.getSelectedVersions);
    selectedVersionProperties$ = this.store.select(VersionsSelectors.getSelectedVersionProperties);

    sortedSelectedVersions$ = this.store.select(VersionsSelectors.getSortedSelectedVersions);

    newVersionPlaceholder$ = this.store.select(VersionsSelectors.getNewVersionPlaceholder);
    newVersionPropertiesIds$ = this.store.select(VersionsSelectors.getNewVersionPropertiesIds);

    creativesetId$ = this.store.select(VersionsSelectors.getCreativesetId);
    shouldCheckPristine$ = this.store.select(VersionsSelectors.getShouldCheckPristine);

    translationSaved$ = this.store.select(VersionsSelectors.getTranslationSaved);

    private _renderDirtyVersionProperty$ = new Subject<RenderDirtyVersionProperty>();
    renderDirtyVersionProperty$ = this._renderDirtyVersionProperty$.asObservable();

    dirtyProperties$: Observable<VersionToDirtyProperties> | undefined;

    constructor(private readonly store: Store) {}

    init(
        creativesetId: string,
        versions: IVersion[],
        selectedVersionsIds: string[],
        versionParamProvided: boolean,
        defaultVersion: IVersion,
        selectableIds?: string[]
    ): void {
        this.store.dispatch(
            VersionsActions.init({
                creativesetId,
                versions,
                selectedVersionsIds,
                versionParamProvided,
                defaultVersion,
                selectableIds
            })
        );
    }

    resetVersions(versions: IVersion[]): void {
        this.store.dispatch(VersionsActions.resetVersions({ versions }));
    }

    // --- CRUD Versions

    prepareNewVersion(localization: IBrandLocalization, newVersionName?: string): void {
        this.store.dispatch(VersionsActions.preparePlaceholder({ localization, newVersionName }));
    }

    cancelNewVersion(): void {
        this.store.dispatch(VersionsActions.cancelPlaceholder());
    }

    saveNewVersion(newVersionName: string, autoTranslate = false): void {
        this.store.dispatch(VersionsActions.saveNewVersion({ newVersionName, autoTranslate }));
    }

    updateVersion(version: IVersion): void {
        this.store.dispatch(VersionsActions.updateVersions({ versions: [version] }));
    }

    createVersion(localization: ILocalization, newVersionName: string, autoTranslate = false): void {
        this.store.dispatch(
            VersionsActions.createVersion({ localization, newVersionName, autoTranslate })
        );
    }

    deleteVersion(versions: IVersion | IVersion[]): void {
        this.store.dispatch(
            VersionsActions.deleteVersions({
                versions: Array.isArray(versions) ? versions : [versions]
            })
        );
    }

    setDefaultVersion(version: IVersion): void {
        this.store.dispatch(VersionsActions.setDefaultVersion({ version }));
    }

    // --- Properties

    updateVersionedText(
        versionId: string,
        versionPropertyId: string,
        value: Partial<IVersionedText>
    ): void {
        this.store.dispatch(
            VersionsActions.updateVersionedText({ versionId, versionPropertyId, value })
        );
    }

    updateVersionPropertyFeed(
        versionId: string,
        versionPropertyId: string,
        feedId: string,
        changes: Partial<IFeed> = {}
    ): void {
        this.store.dispatch(
            VersionsActions.updateVersionPropertyFeed({ versionId, versionPropertyId, feedId, changes })
        );
    }

    addVersionPropertiesToSelected(versionProperties: IVersionProperty[] | IVersionProperty): void {
        this.store.dispatch(VersionsActions.addVersionPropertiesToSelected({ versionProperties }));
    }

    addVersionPropertiesToDefault(versionProperties: IVersionProperty[] | IVersionProperty): void {
        this.store.dispatch(VersionsActions.addVersionPropertiesToDefault({ versionProperties }));
    }

    upsertVersionProperty(versionIds: string | string[], versionProperty: IVersionProperty): void {
        this.store.dispatch(
            VersionsActions.upsertVersionProperty({
                versionIds: Array.isArray(versionIds) ? versionIds : [versionIds],
                versionProperty
            })
        );
    }

    addVersionProperty(versionIds: string | string[], versionProperty: IVersionProperty): void {
        this.store.dispatch(
            VersionsActions.addVersionProperty({
                versionIds: Array.isArray(versionIds) ? versionIds : [versionIds],
                versionProperty
            })
        );
    }

    addVersionPropertiesToVersions(
        changes: { versionId: string; versionProperty: IVersionProperty }[]
    ): void {
        this.store.dispatch(VersionsActions.addVersionPropertiesToVersions({ changes }));
    }

    removeVersionPropertiesByIds(propertyIdsToRemove: string[], fromSelected = false): void {
        if (fromSelected) {
            this.store.dispatch(
                VersionsActions.removeVersionPropertiesFromSelectedVersions({
                    propertyIdsToRemove
                })
            );
        } else {
            this.store.dispatch(
                VersionsActions.removeVersionPropertiesFromAllVersions({
                    propertyIdsToRemove
                })
            );
        }
    }

    getVersionByID(id: string): Observable<VersionEntity | undefined> {
        return this.store.select(VersionsSelectors.getVersionByID(id));
    }

    copyStyleIdsBetweenDocuments({
        targetDocumentId,
        sourceDocumentId
    }: {
        targetDocumentId: string;
        sourceDocumentId: string;
    }): void {
        this.store.dispatch(
            VersionsActions.copyStyleIdsBetweenDocuments({ sourceDocumentId, targetDocumentId })
        );
    }

    cleanStyleIds(validDocumentIds: string[]): void {
        this.store.dispatch(VersionsActions.cleanStyleIds({ validDocumentIds: validDocumentIds }));
    }

    // --- Pristine
    updateShouldCheckPristine(shouldCheckPristine: boolean): void {
        this.store.dispatch(VersionsActions.updateShouldCheckPristine({ shouldCheckPristine }));
    }

    // --- Design
    designUpdated(): void {
        this.store.dispatch(VersionsActions.designUpdated());
    }

    // Syncing from Creativeset
    onUpdateDesignsInCreativeset(versions: IVersion[]): void {
        this.store.dispatch(VersionsActions.onUpdatedDesigns({ versions }));
    }

    renderDirtyVersionProperty(change: RenderDirtyVersionProperty): void {
        this._renderDirtyVersionProperty$.next(change);
    }

    getDirtyVersionPropertiesOf(versionId: string | undefined): Observable<IVersionProperty[]> {
        if (!versionId || !this.dirtyProperties$) {
            return of([]);
        }

        return this.dirtyProperties$.pipe(
            map(changes => changes[versionId]),
            distinctUntilChanged<DirtyProperties>((a, b) => deepEqual(a, b)),
            map(dirtyProperties => (dirtyProperties ? Object.values(dirtyProperties) : []))
        );
    }

    getOriginalVersionPropertyOf(
        versionId: string,
        versionPropertyId: string
    ): Observable<IVersionProperty | undefined> {
        return this.versions$.pipe(
            concatLatestFrom(() => this.defaultVersion$),
            map(([versions, defaultVersion]) => {
                const defaultVersionProperty = defaultVersion.properties.find(
                    ({ id }) => id === versionPropertyId
                );
                const versionProperty = versions
                    .find(({ id }) => id === versionId)
                    ?.properties.find(({ id }) => id === versionPropertyId);
                return versionProperty ?? defaultVersionProperty;
            })
        );
    }
}
