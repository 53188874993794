<div
    class="feed-property"
    [class.unselected]="!value.id"
    *ngIf="!minimized"
    (click)="openDialog()"
    data-test-id="feed-picker-edit">
    <ui-svg-icon
        class="feed-icon"
        *ngIf="value.id && !mixed"
        icon="feed"></ui-svg-icon>
    <div
        [uiTooltipDisabled]="!value.id"
        [uiTooltip]="name"
        class="feed-name">
        {{ name || 'Select feed' }}
    </div>
</div>
<div *ngIf="minimized">
    <ui-svg-icon
        [icon]="'edit'"
        (click)="openDialog()"
        data-test-id="feed-picker-edit"></ui-svg-icon>
</div>
