<div
    class="section-header"
    [ngClass]="{ 'no-gradient': !allowGradient }">
    <!-- Color type selection -->
    <div
        class="color-types"
        *ngIf="allowGradient">
        <div
            class="color-type"
            data-test-id="color-type-switch-solid"
            [ngClass]="{ active: type === ColorType.Solid }"
            (click)="setColorType(ColorType.Solid)">
            <!-- Solid -->
            <svg
                class="color-preview"
                *ngIf="type !== ColorType.Solid"
                width="14px"
                height="14px"
                viewBox="0 0 14 14"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <defs></defs>
                <g
                    id="PP-/-Color-picker-/-Solid"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd">
                    <path
                        d="M2,1.5 C1.72385763,1.5 1.5,1.72385763 1.5,2 L1.5,12 C1.5,12.2761424 1.72385763,12.5 2,12.5 L12,12.5 C12.2761424,12.5 12.5,12.2761424 12.5,12 L12.5,2 C12.5,1.72385763 12.2761424,1.5 12,1.5 L2,1.5 Z"
                        id="Rectangle-4-Copy-2"
                        stroke="#B5B5B5"
                        fill="#D8D8D8"></path>
                </g>
            </svg>

            <!-- Solid selected -->
            <svg
                class="color-preview selected"
                *ngIf="type === ColorType.Solid"
                width="14px"
                height="14px"
                viewBox="0 0 14 14"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <defs>
                    <path
                        d="M2,1 L12,1 C12.5522847,1 13,1.44771525 13,2 L13,12 C13,12.5522847 12.5522847,13 12,13 L2,13 C1.44771525,13 1,12.5522847 1,12 L1,2 C1,1.44771525 1.44771525,1 2,1 Z"
                        id="path-1"></path>
                </defs>
                <g
                    id="PP-/-Color-picker-/-Solid---selected"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd">
                    <g id="Rectangle-4-Copy-2">
                        <use
                            fill="#FFFFFF"
                            xlink:href="#path-1"></use>
                        <path
                            stroke="#1B75DD"
                            stroke-width="1"
                            d="M2,1.5 C1.72385763,1.5 1.5,1.72385763 1.5,2 L1.5,12 C1.5,12.2761424 1.72385763,12.5 2,12.5 L12,12.5 C12.2761424,12.5 12.5,12.2761424 12.5,12 L12.5,2 C12.5,1.72385763 12.2761424,1.5 12,1.5 L2,1.5 Z"
                            fill-opacity="0.5"
                            fill="#1B75DD"></path>
                    </g>
                </g>
            </svg>
        </div>
        <div
            class="color-type"
            data-test-id="color-type-switch-linear-gradient"
            [ngClass]="{ active: type === ColorType.LinearGradient }"
            (click)="setColorType(ColorType.LinearGradient)">
            <!-- Linear -->
            <svg
                class="color-preview"
                *ngIf="type !== ColorType.LinearGradient"
                id="color-type-linear-selected"
                width="14px"
                height="14px"
                viewBox="0 0 14 14"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <defs>
                    <linearGradient
                        x1="53.322347%"
                        y1="16.587321%"
                        x2="53.322347%"
                        y2="83.412679%"
                        id="linearGradient-1">
                        <stop
                            stop-color="#FBFBFB"
                            offset="0%"></stop>
                        <stop
                            stop-color="#C3C3C3"
                            offset="52.0908598%"></stop>
                        <stop
                            stop-color="#838383"
                            offset="100%"></stop>
                    </linearGradient>
                </defs>
                <g
                    id="PP-/-Color-picker-/-Linear"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                    stroke-opacity="0.2">
                    <path
                        d="M2,1.5 C1.72385763,1.5 1.5,1.72385763 1.5,2 L1.5,12 C1.5,12.2761424 1.72385763,12.5 2,12.5 L12,12.5 C12.2761424,12.5 12.5,12.2761424 12.5,12 L12.5,2 C12.5,1.72385763 12.2761424,1.5 12,1.5 L2,1.5 Z"
                        id="Rectangle-4-Copy-6"
                        stroke="#333333"
                        [attr.fill]="linearGradientLocation"></path>
                </g>
            </svg>

            <!-- Linear selected -->
            <svg
                class="color-preview selected"
                *ngIf="type === ColorType.LinearGradient"
                id="color-type-linear-selected"
                width="14px"
                height="14px"
                viewBox="0 0 14 14"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <defs>
                    <linearGradient
                        x1="53.322347%"
                        y1="16.587321%"
                        x2="53.322347%"
                        y2="83.412679%"
                        id="linearGradient-1">
                        <stop
                            stop-color="#1B75DD"
                            stop-opacity="0.15"
                            offset="0%"></stop>
                        <stop
                            stop-color="#1B75DD"
                            stop-opacity="0.6"
                            offset="52.0908598%"></stop>
                        <stop
                            stop-color="#1B75DD"
                            stop-opacity="0.85"
                            offset="100%"></stop>
                    </linearGradient>
                    <path
                        d="M2,1 L12,1 C12.5522847,1 13,1.44771525 13,2 L13,12 C13,12.5522847 12.5522847,13 12,13 L2,13 C1.44771525,13 1,12.5522847 1,12 L1,2 C1,1.44771525 1.44771525,1 2,1 Z"
                        id="path-2"></path>
                </defs>
                <g
                    id="PP-/-Color-picker-/-Linear---selected"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd">
                    <g id="Rectangle-4-Copy-6">
                        <use
                            fill="#FFFFFF"
                            xlink:href="#path-2"></use>
                        <path
                            stroke="#1B75DD"
                            stroke-width="1"
                            d="M2,1.5 C1.72385763,1.5 1.5,1.72385763 1.5,2 L1.5,12 C1.5,12.2761424 1.72385763,12.5 2,12.5 L12,12.5 C12.2761424,12.5 12.5,12.2761424 12.5,12 L12.5,2 C12.5,1.72385763 12.2761424,1.5 12,1.5 L2,1.5 Z"
                            [attr.fill]="linearGradientLocation"></path>
                    </g>
                </g>
            </svg>
        </div>
    </div>

    <div
        *ngIf="palettes.auxPalettes.length"
        class="menu"
        [uiDropdownTarget]="brandPalettesSection"
        data-test-id="palettes-menu">
        <ui-svg-icon icon="kebab"></ui-svg-icon>
    </div>
</div>

<ui-dropdown
    type="menu"
    [offset]="{ x: 18, y: -20 }"
    #brandPalettesSection>
    <ui-dropdown-item
        *ngFor="let palette of palettes.auxPalettes"
        [toggle]="palette.selected"
        (click)="palettes.useAuxPalette(palette)"
        data-test-id="palettes-menu-item">
        {{ palette.name }}
    </ui-dropdown-item>
</ui-dropdown>
