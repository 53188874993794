import { Injectable } from '@angular/core';
import { ICreative } from '@domain/creativeset/creative';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ManageViewContextMenuService {
    private _opened$ = new Subject<MenuOpenData>();
    opened$ = this._opened$.asObservable();

    open(
        event: MouseEvent,
        creatives: ICreative[],
        showSelectionOptions = false,
        offsetX = 0,
        offsetY = 0
    ): void {
        const x = event.pageX + offsetX;
        const y = event.pageY + offsetY;
        this._opened$.next({ creatives, showSelectionOptions, x, y, isOpen: true });
    }

    close(): void {
        this._opened$.next({ creatives: [], showSelectionOptions: false, x: 0, y: 0, isOpen: false });
    }
}

export type MenuOpenData = {
    creatives: ICreative[];
    showSelectionOptions: boolean;
    x: number;
    y: number;
    isOpen: boolean;
};
