<div
    #textarea
    class="textarea input ui-input"></div>
<ui-svg-icon
    icon="text-styles-clear-formatting"
    class="clear-format-button"
    [class.active]="hasSelectedStyles$ | async"
    uiTooltip="Clear character styles"
    uiTooltipPosition="left"
    (mousedown)="clearTextAttributes()">
</ui-svg-icon>
