<ng-content></ng-content>
<span
    class="overflow"
    #tooltipTrigger
    *ngIf="overflowingItems.length">
    ...
    <span
        [uiTooltip]="overflowingItems.join('')"
        #uiTooltip="uiTooltip"
        uiTooltipMaxWidth="280px"
        [uiTooltipTriggerHost]="tooltipTrigger"
        >({{ overflowingItems.length }})</span
    ></span
>
