import { Injectable } from '@angular/core';
import { Subject, take, timer } from 'rxjs';

export interface CollapsibleItem {
    collapsed: boolean | undefined;
    id: string;
}

interface VSState {
    initialItemSize: number;
    itemSize: number;
    bufferPx: number;
    lastScrollPosition: number;
    lastCollapsibleItemsState: CollapsibleItem[];
}

@Injectable({ providedIn: 'root' })
export class VirtualScrollService {
    private state: VSState;
    private readonly GROUP_HEADER_HEIGHT = 49;

    selectedItem$ = new Subject<{ index: number; forceScroll: boolean; id: string }>();
    isSmoothScrolling$ = new Subject<boolean>();

    setState(state: VSState): void {
        this.state = state;
    }

    getState(): VSState {
        return this.state;
    }

    updateSelectedItemById(id: string): void {
        const index = this.state.lastCollapsibleItemsState?.findIndex(item => item.id === id);
        this.selectedItem$.next({ index, id, forceScroll: true });
        this.isSmoothScrolling$.next(true);
        timer(1000)
            .pipe(take(1))
            .subscribe(() => this.isSmoothScrolling$.next(false));
    }

    updateSelectedItemByIndex(index: number): void {
        const id = this.state.lastCollapsibleItemsState[index].id;
        this.selectedItem$.next({ index, id, forceScroll: false });
    }

    // this is needed when navigating from MV -> DV -> MV (and at least one group item is collapsed in MV )
    // when going back from DV to MV ALL the items are back expanded and the total height of the list is bigger than before (with collapsed items)
    // so we need to recalculate the new scroll position from the top of that list
    getNewScrollPositionFromTheTop(): number {
        const { lastCollapsibleItemsState, lastScrollPosition, initialItemSize } = this.state;

        const numberOfCollapsedItems = lastCollapsibleItemsState?.filter(item => item.collapsed).length;

        if (!numberOfCollapsedItems) {
            return lastScrollPosition;
        }

        let scrollPosition = 0;
        let index = 0;
        let leftoverPx = 0;

        for (const item of lastCollapsibleItemsState) {
            if (item.collapsed) {
                scrollPosition += this.GROUP_HEADER_HEIGHT;
            } else {
                scrollPosition += initialItemSize;
            }

            if (scrollPosition > lastScrollPosition) {
                leftoverPx = lastScrollPosition - (scrollPosition - initialItemSize);
                break;
            }

            index++;
        }

        return index * initialItemSize + leftoverPx;
    }

    adjustBufferSizes(isCollapsed: boolean): number {
        const { itemSize, bufferPx } = this.state;
        const newBuffersSize = isCollapsed ? bufferPx + itemSize : bufferPx - itemSize;
        return Math.round(newBuffersSize);
    }

    calculateNewTotalOffsetHeight(group: CollapsibleItem[]): number {
        const collapsedGroupItemsLength = group.filter(item => item.collapsed).length;
        const uncollapsedGroupItemsLength = group.filter(item => !item.collapsed).length;

        const uncollapsedGroupItemHeight = this.state.initialItemSize;
        const collapsedGroupItemHeight = this.GROUP_HEADER_HEIGHT;

        return (
            uncollapsedGroupItemsLength * uncollapsedGroupItemHeight +
            collapsedGroupItemsLength * collapsedGroupItemHeight
        );
    }
}
