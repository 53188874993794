<ng-container *ngIf="sortedGroupCreatives$ | filterGroupedSizeCreatives | async as sortedGroup">
    <cdk-virtual-scroll-viewport
        *ngIf="sortedGroup.length"
        data-test-id="virtual-scroll-vieport"
        [itemSize]="itemSize"
        [minBufferPx]="bufferPx"
        [maxBufferPx]="bufferPx"
        class="virtual-scroll-viewport ui-scrollbar"
        #cdkVirtualScrollViewport>
        <div
            *cdkVirtualFor="let group of sortedGroup"
            class="creative-list-group"
            #creativeListGroupItem
            cdkObserveContent>
            <creative-list-groupheader
                [uiDropDown]="uiDropDown"
                [group]="group"
                (collapse)="onCollapse(sortedGroup, $event)"
                *media="Breakpoint.DesktopUp">
            </creative-list-groupheader>
            <div
                #creativeListGroup
                class="items all-version"
                *ngIf="group.collapsed !== true"
                [id]="group.id">
                <creative-list-item
                    class="creative-list-item"
                    *ngFor="
                        let creative of group.creatives;
                        let creativeIndex = index;
                        trackBy: trackByCreative
                    "
                    #creativeListItem
                    observeVisibility
                    [headerTemplate]="creativeItemHeaderTemplate"
                    [footerTemplate]="creativeItemFooterTemplate"
                    [index]="creativeIndex"
                    [isCreativeGroup]="true"
                    [creative]="creative"
                    [uiDropDown]="uiDropDown"
                    (click)="openFullScreenDialog(creative, sortedGroup, undefined)">
                </creative-list-item>
            </div>
        </div>
    </cdk-virtual-scroll-viewport>
</ng-container>

<!-- Single version start -->
<ng-container
    *ngIf="sortedCreatives$ | filterCreatives | async as creatives"
    class="">
    <div class="items single-version">
        <creative-list-item
            class="creative-list-item"
            *ngFor="let creative of creatives; let creativeIndex = index; trackBy: trackByCreative"
            #creativeListItem
            observeVisibility
            [headerTemplate]="creativeItemHeaderTemplate"
            [footerTemplate]="creativeItemFooterTemplate"
            [index]="creativeIndex"
            [creative]="creative"
            [uiDropDown]="uiDropDown"
            (click)="openFullScreenDialog(creative, undefined, creatives)">
        </creative-list-item>
    </div>
</ng-container>

<div
    *ngIf="!(sortedCreatives$ | async)?.length"
    class="no-creatives">
    No creatives in this set
</div>

<ui-dropdown #uiDropDown></ui-dropdown>
<export-dropdown #exportDropdown></export-dropdown>
