import { Injectable } from '@angular/core';
import { ElementSelectionBoundingBoxService } from '../services/element-selection-bounding-box.service';
import { ElementSelectionService } from '../services/element-selection.service';
import { WorkspaceTransformService } from './workspace-transform.service';
import { Color } from '@creative/color';
import { calculateBoundingBox } from '@creative/elements/utils';
import { ElementSelection } from '@creative/nodes/selection';
import { EventEmitter } from '@studio/utils/event-emitter';
import { rotatePosition } from '@studio/utils/utils';
import { StudioWorkspaceComponent } from './studio-workspace.component';
import { IBoundingBox, IPosition, ISize } from '@domain/dimension';
import { TransformMode } from '@domain/workspace';

type GradientHelperEvents = {
    start: Color;
    stop: void;
    point_dragged: void;
    point_select: void;
    point_change: void;
};

@Injectable()
export class WorkspaceGradientHelperService extends EventEmitter<GradientHelperEvents> {
    workspace: StudioWorkspaceComponent;
    gradient?: Color;
    points: IColorPoint[];
    selectedPoint?: IColorPoint;
    draggingPoint?: IColorPoint;
    active: boolean;
    nodeBounds: ISize & IPosition;

    constructor(
        private elementSelectionService: ElementSelectionService,
        private elementSelectionBoundingBoxService: ElementSelectionBoundingBoxService,
        private workspaceTransformService: WorkspaceTransformService
    ) {
        super();
    }

    get startPoint(): IColorPoint {
        return this.points[0];
    }

    get endPoint(): IColorPoint {
        return this.points[this.points.length - 1];
    }

    get currentPointIndex(): number {
        return this.selectedPoint && this.points ? this.points.indexOf(this.selectedPoint) : -1;
    }

    setup(workspace: StudioWorkspaceComponent): void {
        this.workspace = workspace;
    }

    start(gradient: Color): void {
        this.active = true;
        this.emit('start', gradient);

        this.drawPicker(gradient);
    }

    drawPicker(gradient: Color): void {
        this.gradient = gradient;
        const selection = this.elementSelectionService.currentSelection;
        const boundingBox =
            this.elementSelectionBoundingBoxService.boundingBoxes?.lockedAndHiddenExcluded ||
            calculateBoundingBox([]);

        const rotation = selection.element ? selection.element.rotationZ : 0;

        this.nodeBounds = this.getNodeBounds(selection, boundingBox);
        this.workspaceTransformService.setTransformMode(TransformMode.EditGradient);

        const center = {
            x: this.nodeBounds.x + this.nodeBounds.width / 2,
            y: this.nodeBounds.y + this.nodeBounds.height / 2
        };

        const pointIndex = this.currentPointIndex;

        this.points = [
            {
                color: gradient.start.color,
                position: rotatePosition(
                    this.getAbsolutePosition(gradient.start.position!, this.nodeBounds),
                    center,
                    -rotation
                )
            },
            {
                color: gradient.end.color,
                position: rotatePosition(
                    this.getAbsolutePosition(gradient.end.position!, this.nodeBounds),
                    center,
                    -rotation
                )
            }
        ];

        this.selectPointByIndex(pointIndex > -1 ? pointIndex : 0, true);
        this.workspace.gizmoDrawer.draw();
    }

    /**
     * Keep points in place when scrolling/panning
     * @param delta
     */
    pan(delta: IPosition): void {
        if (this.gradient && delta) {
            this.points.forEach(p => {
                p.position.x = Math.round(p.position.x + delta.x);
                p.position.y = Math.round(p.position.y + delta.y);
            });
        }
    }

    movePointAtAngle(point: IPosition, angle: number, distance: number): IPosition {
        return {
            x: point.x + Math.sin(angle) * distance,
            y: point.y - Math.cos(angle) * distance
        };
    }

    stopDrag(): void {
        this.emit('point_dragged');
        this.draggingPoint = undefined;
    }

    updateSelectedColor(color: Color): void {
        if (this.selectedPoint) {
            this.selectedPoint.color.setColor(color);
            this.workspace.gizmoDrawer.draw();
        }
    }

    stop(): void {
        this.active = false;
        this.gradient = undefined;
        this.selectedPoint = undefined;
        this.points = [];

        this.emit('stop');

        // Remove all listeners
        this.off('point_select');
        this.off('point_change');
        this.off('point_dragged');
        this.off('stop');

        if (this.workspace && this.workspace.gizmoDrawer) {
            if (this.workspace.transform.mode === TransformMode.EditGradient) {
                this.workspace.transform.setTransformMode(TransformMode.None);
            }
            this.workspace.gizmoDrawer.draw();
        }
    }

    selectPoint(point: IColorPoint, noEmit = false): void {
        this.selectedPoint = point;

        if (!noEmit) {
            this.emit('point_select');
        }
    }

    selectPointByIndex(index: number, noEmit = false): void {
        this.selectPoint(this.points[index], noEmit);
    }

    movePoint(x: number, y: number): void {
        const selection = this.elementSelectionService.currentSelection;
        const rotation = selection?.element ? selection.element.rotationZ : 0;
        const center = {
            x: this.nodeBounds.x + this.nodeBounds.width / 2,
            y: this.nodeBounds.y + this.nodeBounds.height / 2
        };
        const rotatedStartPosition = rotatePosition(this.startPoint.position, center, rotation);
        const rotatedEndPosition = rotatePosition(this.endPoint.position, center, rotation);

        this.selectedPoint!.position.x = x;
        this.selectedPoint!.position.y = y;

        // For now all colors are from 0 to 100%...
        this.gradient!.start.offset = 0;
        this.gradient!.end.offset = 100;

        if (this.points.length > 1) {
            this.gradient!.start.position = this.getRelativePosition(
                rotatedStartPosition,
                this.nodeBounds
            );
            this.gradient!.end.position = this.getRelativePosition(rotatedEndPosition, this.nodeBounds);
        }

        this.workspace.gizmoDrawer.draw();
        this.emit('point_change');
    }

    pointAt(x: number, y: number): IColorPoint | undefined {
        for (let i = 0; i < this.points.length; i++) {
            const p = this.points[i];
            if (x > p.position.x - 10 && x < p.position.x + 10) {
                if (y > p.position.y - 10 && y < p.position.y + 10) {
                    return p;
                }
            }
        }

        return undefined;
    }

    /**
     * Convert points in pixels (canvas 0,0 origin) to points relative to the element
     * @param point
     * @param nodeBounds
     */
    private getRelativePosition(point: IPosition, nodeBounds: IPosition & ISize): IPosition {
        return {
            x: (point.x - nodeBounds.x) / nodeBounds.width,
            y: (point.y - nodeBounds.y) / nodeBounds.height
        };
    }

    /**
     * Convert points relative to the elemant to position in pixels
     * @param point
     * @param nodeBounds
     */
    private getAbsolutePosition(point: IPosition, nodeBounds: IPosition & ISize): IPosition {
        return {
            x: nodeBounds.x + point.x * nodeBounds.width,
            y: nodeBounds.y + point.y * nodeBounds.height
        };
    }

    private getNodeBounds(selection: ElementSelection, boundingBox: IBoundingBox): ISize & IPosition {
        const canvasPosition = this.workspace.canvas.getPosition();
        return selection.length > 0
            ? this.workspace.getBoundingRect(boundingBox, 'workspace')
            : {
                  x:
                      canvasPosition.x -
                      (this.workspace.design.document.width *
                          (this.workspace.editorStateService.zoom - 1)) /
                          2,
                  y:
                      canvasPosition.y -
                      (this.workspace.design.document.height *
                          (this.workspace.editorStateService.zoom - 1)) /
                          2,
                  width: this.workspace.design.document.width * this.workspace.editorStateService.zoom,
                  height: this.workspace.design.document.height * this.workspace.editorStateService.zoom
              };
    }

    onSelectionChange = (): void => {
        this.stop();
    };
}

export interface IColorPoint {
    position: IPosition;
    color: Color;
}
