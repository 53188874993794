import { IRichTextEditorService } from '@domain/rich-text/rich-text.editor.header';
import { IRichText } from '@domain/rich-text/rich-text.header';
import { IRichTextHtmlResolver } from '@domain/rich-text/rich-text.html-resolver.header';
import { __parent, parent } from '@studio/utils/di';

export class RichTextHtmlResolver implements IRichTextHtmlResolver {
    constructor(@parent() private editor: IRichTextEditorService) {}

    get text(): IRichText {
        return this.editor.text;
    }

    resolveHtmlString(): string {
        return '';
    }
}

__parent(RichTextHtmlResolver, 'editor', 0);
