import { v4, validate } from 'uuid';

/**
 * If passing optional as true and called in ACG the ID should return as undefined
 * as creation of new IDs should not occur due to discrepancies occuring when
 * generating the document and ad-data. Use Non-Null Operator (`!`) with caution!
 */
export function uuidv4(optional: true): string | undefined;
export function uuidv4(optional: false): string;
export function uuidv4(optional?: undefined): string;
export function uuidv4(optional?: boolean | undefined): string | undefined {
    if (optional) {
        return process.env['STUDIO_JS'] ? v4() : undefined;
    }
    return v4();
}

export function isUUID(value?: string): boolean {
    return validate(value ?? '');
}
