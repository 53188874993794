<div class="creative-meta-wrapper">
    <creative-meta-rocket
        *permissions="['Default', 'CampaignActions']"
        [creative]="creative"></creative-meta-rocket>

    @if (creative?.targetUrl && !inShowcase) {
        <div
            class="target-url"
            (click)="overrideTargetUrl()">
            <ui-svg-icon
                [uiTooltip]="creative?.targetUrl"
                icon="link-s"></ui-svg-icon>
        </div>
    }

    @if (!!(loaded$ | async)) {
        @if (commentService.commentCount(commentsToken, creative?.id); as commentCount) {
            <div class="comments-wrapper">
                <div
                    class="comments"
                    [class.active]="commentsActive"
                    #commentsThread="csThreads"
                    *permissions="'Comments'"
                    [csThreads]="{ tokenId: commentsToken, parentId: creative?.id }"
                    (click)="$event.stopPropagation()">
                    <ui-svg-icon icon="comments"></ui-svg-icon>
                    <span>{{ commentCount }}</span>
                </div>
            </div>
        }
    }
</div>
@if (!!(loaded$ | async)) {
    @if (!commentService.commentCount(commentsToken, creative?.id)) {
        <div class="no-comments-wrapper">
            <div
                class="comments"
                [class.active]="commentsActive"
                [csThreads]="{ tokenId: commentsToken, parentId: creative?.id }"
                #commentsThread="csThreads"
                [class.hidden]="true"
                *permissions="'Comments'"
                (click)="$event.stopPropagation()">
                <ui-svg-icon icon="comments-add"></ui-svg-icon>
            </div>
        </div>
    }
}
