<studio-ui-section
    [headline]="dataElement ? dataElement.name : ''"
    [headlineIcon]="icon"
    [collapsable]="collapsable"
    [collapsed]="collapsed && !stateInputVisible"
    [customAction]="disabled ? undefined : collapsable ? hasState : hasNoState"
    [inactive]="false"
    [empty]="disabled"
    #sectionWrapper
    class="no-padding">
    <div
        class="tabs-wrapper ui-scrollbar"
        *ngIf="!disabled">
        <div class="tabs">
            <div
                id="state-add-button"
                class="tab add"
                #addStateButton
                (click)="triggerAddStateButton($event)">
                <ui-icon
                    icon="checkmark"
                    [style.visibility]="'hidden'"></ui-icon>
                <div class="placeholder">States</div>
                <ui-svg-icon
                    id="state-add-custom-button"
                    class="icon action"
                    icon="plus-small"
                    [uiDropdownTarget]="dropdown"
                    [style.width]="addStateIconWidth + 'px'"
                    (click)="addCustomState($event, true)"></ui-svg-icon>
            </div>
            <div
                id="state-select-default"
                class="tab"
                [class.selected]="!selectedState"
                (click)="deselect()">
                <ui-icon
                    icon="checkmark"
                    [style.visibility]="!selectedState ? 'visible' : 'hidden'"></ui-icon>
                <div class="placeholder">Default</div>
            </div>

            <div
                class="tab"
                *ngFor="let state of reservedStates"
                [id]="'state-select-' + state.name"
                [attr.data-test-id]="'state-tab-' + state.name"
                [class.selected]="state === selectedState"
                [class.pristine]="stateIsPristine(state)"
                (click)="selectState(state.id)">
                <ui-icon
                    icon="checkmark"
                    [style.visibility]="state === selectedState ? 'visible' : 'hidden'">
                </ui-icon>
                <div class="placeholder">{{ state.name }}</div>

                <ui-svg-icon
                    *ngIf="stateHasAction(state)"
                    class="has-linked-action icon"
                    icon="de-action"></ui-svg-icon>

                <ui-svg-icon
                    id="state-delete"
                    data-test-id="state-delete"
                    class="delete"
                    icon="delete"
                    (click)="deleteState(state)"></ui-svg-icon>
            </div>
            <div
                class="tab"
                *ngFor="let state of customStates; let indexOfElement = index"
                [id]="'state-select-custom-' + indexOfElement"
                [class.selected]="state === selectedState"
                [class.pristine]="stateIsPristine(state)"
                [class.divider]="indexOfElement === 0"
                (click)="selectState(state.id)">
                <ui-icon
                    icon="checkmark"
                    [style.visibility]="state === selectedState ? 'visible' : 'hidden'"></ui-icon>

                <ui-input
                    #stateName
                    [value]="state.name"
                    [disabled]="state !== selectedState"
                    [blurOnSubmit]="true"
                    [superDiscrete]="true"
                    [dynamicWidth]="true"
                    (submit)="saveStateName(stateName.value!)"
                    (blur)="onBlur()"></ui-input>

                <ui-button
                    icon="checkmark"
                    class="margin-left-light"
                    (click)="saveStateName(stateName.value!)"
                    [style.visibility]="
                        stateName.value === state.name ? 'hidden' : 'visible'
                    "></ui-button>

                <ui-svg-icon
                    *ngIf="stateHasAction(state)"
                    class="has-linked-action icon"
                    icon="de-action"></ui-svg-icon>

                <ui-svg-icon
                    class="delete"
                    icon="delete"
                    data-test-id="state-delete"
                    (click)="deleteState(state)"></ui-svg-icon>
            </div>

            <div
                *ngIf="stateInputVisible"
                class="tab custom"
                [class.active]="stateInputVisible">
                <ui-icon
                    icon="checkmark"
                    [style.visibility]="'hidden'"></ui-icon>
                <ui-input
                    #customStateInput
                    [superDiscrete]="true"
                    (keydown.Escape)="cancelInput($event)"
                    (submit)="addState(customStateInput.value || '')">
                </ui-input>
                <ui-button
                    icon="checkmark"
                    class="margin-left-light"
                    (click)="addState(customStateInput.value || '')"></ui-button>
            </div>
        </div>
    </div>
</studio-ui-section>
<div
    *ngIf="!disabled && !sectionWrapper.collapsed"
    class="resize bottom-line resize-90"
    (mousedown)="beginResize($event)"></div>

<ui-dropdown
    #dropdown
    [offset]="placeDropdown()"
    type="menu"
    [disabled]="stateInputVisible || (hasReservedPressed && hasReservedHover)">
    <ui-dropdown-item
        id="add-hover-state"
        (click)="addReservedActionState('hover')"
        [disabled]="hasReservedHover">
        Add Hover State
    </ui-dropdown-item>
    <ui-dropdown-item
        id="add-pressed-state"
        (click)="addReservedActionState('pressed')"
        [disabled]="hasReservedPressed">
        Add Pressed State
    </ui-dropdown-item>
    <ui-dropdown-divider></ui-dropdown-divider>
    <ui-dropdown-item
        id="add-custom-state"
        (click)="addCustomState($event)">
        Add Custom State&hellip;
    </ui-dropdown-item>
</ui-dropdown>

<ng-template #hasState>
    <span
        [uiTooltip]="selectedState ? (selectedState.name ? selectedState.name : '') : 'Default'"
        class="selected-state disabled"
        >{{ selectedState ? selectedState.name : 'Default' }}</span
    >
</ng-template>
<ng-template #hasNoState>
    <ng-container *ngIf="!dataElement.hidden">
        <span
            class="toggle-action"
            id="add-state"
            [uiDropdownTarget]="dropdown"
            [style.width]="customActionWidth + 'px'"
            >Add State</span
        >
    </ng-container>
</ng-template>
