import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    standalone: true,
    name: 'slug'
})
export class SlugPipe implements PipeTransform {
    transform(value: string): string {
        // Taken from: https://dev.to/bybydev/how-to-slugify-a-string-in-javascript-4o9n
        value = value.replace(/^\s+|\s+$/g, ''); // trim leading/trailing white space
        value = value.toLowerCase(); // convert string to lowercase
        value = value
            .replace(/[^a-z0-9 -]/g, '') // remove any non-alphanumeric characters
            .replace(/\s+/g, '-') // replace spaces with hyphens
            .replace(/-+/g, '-'); // remove consecutive hyphens
        return value;
    }
}
