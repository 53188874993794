import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs';
import * as AnimationControlActions from '../../animation-control/state/animation-control.actions';
import * as CreativesActions from './creatives.actions';

@Injectable()
export class CreativesEffects {
    private actions$ = inject(Actions);

    showElement$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CreativesActions.focusElement),
            map(({ elementId }) => {
                return AnimationControlActions.controlActions.showElement({ elementId });
            })
        );
    });
}
