export function copyToClipboard(string: string): void {
    const handler = (event: ClipboardEvent): void => {
        event.clipboardData?.setData('text/plain', string);
        event.preventDefault();
        document.removeEventListener('copy', handler, true);
    };

    if (string) {
        document.addEventListener('copy', handler, true);
        document.execCommand('copy');
        setTimeout(() => document.removeEventListener('copy', handler));
    }
}
