import { parseColor, RGBToHSL, toLinearGradient, toRGBA } from '@creative/color.utils';
import { ColorType, IColor, IColorStop } from '@domain/color';
import { IPosition } from '@domain/dimension';

export class Color implements IColor {
    /**
     * Value between 0 and 255
     */
    red: number;

    /**
     * Value between 0 and 255
     */
    green: number;

    /**
     * Value between 0 and 255
     */
    blue: number;
    hue: number;
    saturation: number;
    lightness: number;

    /**
     * Value between 0 and 100
     */
    alpha: number;

    type: ColorType = ColorType.Solid;

    stops: IColorStop[] = [];

    constructor(color?: IColor) {
        if (color) {
            this.setColor(color);
        } else {
            this.red = 0;
            this.green = 0;
            this.blue = 0;
            this.hue = this.saturation = this.lightness = 0;
            this.alpha = 100;

            const hsl = RGBToHSL(this.red, this.green, this.blue);
            this.hue = hsl.h;
            this.saturation = hsl.s;
            this.lightness = hsl.l;
        }
    }

    get angle(): number {
        if (this.stops.length > 1) {
            const angle =
                (Math.atan2(
                    this.end.position!.y - this.start.position!.y,
                    this.end.position!.x - this.start.position!.x
                ) *
                    180) /
                Math.PI;

            return Math.round((90 + angle) % 360);
        }
        return 0;
    }

    get start(): IColorStop {
        return this.stops[0];
    }

    get end(): IColorStop {
        return this.stops[this.stops.length - 1];
    }

    copy(): Color {
        return new Color(this);
    }

    /**
     * This makes colors output nicely when using JSON.stringify
     */
    toJSON(): string {
        return this.toString();
    }

    toString(): string {
        switch (this.type) {
            case ColorType.Solid:
                return toRGBA(this);
            case ColorType.LinearGradient:
                return toLinearGradient(this);
        }
    }

    addColorStop(color: IColor, offset: number, position?: IPosition): void {
        this.stops.push({
            color,
            offset,
            position
        });
    }

    setColor(color: IColor): IColor {
        this.type = color.type;

        if (color.type === 'solid') {
            this.red = color.red;
            this.green = color.green;
            this.blue = color.blue;
            this.alpha = color.alpha;
            this.saturation = color.saturation;
            this.lightness = color.lightness;
            this.hue = color.hue;
        } else {
            this.alpha = color.alpha;
            // this.angle = color.angle;
            this.stops = color.stops.map(stop => ({
                color: parseColor(stop.color),
                offset: stop.offset,
                position: stop.position ? { ...stop.position } : undefined
            }));
        }

        return this;
    }

    /**
     * Parse color string into a color object. If not a recognized pattern
     * return color based on default value
     * @param color
     * @param defaultValue
     */
    static parse(color: string | Color, defaultValue = '#FFFFFF'): Color {
        return parseColor(color, defaultValue);
    }
}
