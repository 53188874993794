import { Component, OnInit } from '@angular/core';
import { UIConfirmDialogResult, UIDialogComponent } from '@bannerflow/ui';

@Component({
    selector: 'update-widget-dialog',
    templateUrl: './update-widget-dialog.component.html',
    styleUrls: ['./update-widget-dialog.component.scss']
})
export class UpdateWidgetDialogComponent implements OnInit {
    private closePromise: Promise<UIConfirmDialogResult>;
    private resolveClosePromise: (result: UIConfirmDialogResult) => void;
    isEffect = false;

    constructor(private dialog: UIDialogComponent) {}

    ngOnInit(): void {
        this.isEffect = this.dialog.config.data.isEffect;
    }

    initiate(): Promise<UIConfirmDialogResult> {
        if (!this.closePromise) {
            this.closePromise = new Promise(resolve => (this.resolveClosePromise = resolve));
        }
        return this.closePromise;
    }

    confirm(): void {
        this.resolveClosePromise('confirm');
        this.close();
    }

    cancel(): void {
        this.resolveClosePromise('cancel');
        this.close();
    }

    private close(): void {
        this.dialog.dialogRef.close();
    }
}
