import { IAsyncState } from '@domain/store/async';
import { Role, UserPermission } from '@domain/user';
import { createReducer, on } from '@ngrx/store';
import * as UserActions from './user.actions';

export const USER_FEATURE_KEY = 'user';

export interface UserPartialState {
    readonly [USER_FEATURE_KEY]: UserState;
}

export interface UserState extends IAsyncState {
    isEmployee: boolean;
    permissions: UserPermission[];
    role?: Role;
}

export const initialState: UserState = {
    loaded: false,
    error: undefined,
    isEmployee: false,
    permissions: [],
    role: undefined
};

export const reducer = createReducer(
    initialState,

    on(UserActions.loadActions.loadUser, state => {
        return { ...state, loaded: false, error: undefined };
    }),

    on(UserActions.loadActions.loadUserSuccess, (state, { user }) => {
        return {
            ...state,
            isEmployee: !!user.isEmployee,
            permissions: user.permissions || [],
            role: user.role,
            loaded: true
        };
    }),

    on(UserActions.loadActions.loadUserFailure, (state, { error }) => {
        return { ...state, error };
    })
);
