import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { Logger } from '@bannerflow/sentinel-logger';
import { Color } from '@creative/color';
import { fromLinearGradient } from '@creative/color.utils';
import { ColorType } from '@domain/color';
import { ISelectedColor } from '@studio/domain/components/color-picker/color.types';
import { BrandService } from '@studio/stores/brand';
import { cloneDeep } from '@studio/utils/clone';
import { isChildOfSelector } from '@studio/utils/dom-utils';
import { fromEvent, Observable, ReplaySubject, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { ColorService } from '../../../services/color.service';
import { IColorPalette } from '../../color-picker/color-palette.interface';
import { colorChanged } from '../color.util';

@Component({
    selector: 'color-section',
    templateUrl: './color-section.component.html',
    styleUrls: ['./color-section.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorSectionComponent implements OnInit, OnDestroy {
    private _color: Color;

    // the actual color, either a solid one or a gradient
    @Input()
    set color(c: Color | undefined) {
        if (c && this.isNewColor(c)) {
            this.logger.debug(c);
            this._color = cloneDeep(c);
            this.setSelectedColor(c);
        }
    }

    get color(): Color {
        return this._color;
    }

    @Input() colorMixed = false;

    // the currently selected color that will be changed, either through the picker or palette. Should always be a solid.
    // when selecting a color stop, this color will be changed
    _selectedColor$: ReplaySubject<ISelectedColor> = new ReplaySubject<ISelectedColor>(1);
    selectedColor$ = this._selectedColor$
        .asObservable()
        .pipe(map(selectedColor => selectedColor.color));

    @Input() allowGradient = false;
    @Input() preventCloseElements?: string[];
    @Input() name: string;

    @Output() onColorChanged = new EventEmitter<Color>();
    @Output() onColorChange = new EventEmitter<Color>();
    @Output() editStart = new EventEmitter<void>();
    @Output() preview = new EventEmitter<Color>();
    @Output() previewStop = new EventEmitter<Color>();
    @Output() onPickerFocus = new EventEmitter<void>();

    colorPalettes: IColorPalette[];
    brandPalettes$: Observable<IColorPalette[]>;

    private logger = new Logger('ColorSectionComponent');
    private unsubscribe$ = new Subject<void>();

    constructor(
        public colorService: ColorService,
        private brandService: BrandService
    ) {
        this.brandPalettes$ = this.brandService.brandPalettes$;

        this.colorService.gradientPointSelected.pipe(takeUntil(this.unsubscribe$)).subscribe(color => {
            this.logger.verbose('GradientPoint selected');

            this.notifyEditStart();
            this.selectColor(color);
        });

        this.colorService.gradientPointChange
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((gradient: Color) => {
                this.logger.verbose('GradientPoint change');
                this.color.setColor(gradient);
                this.notifyColorChange();
            });

        this.colorService.gradientPointDragged.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.logger.verbose('GradientPoint dragged');
            this.notifyColorChanged();
        });
    }

    ngOnInit(): void {
        this.logger.verbose('ngOnInit');

        this.colorPalettes = this.colorService.getColorsInUse();

        // listen to picker and palette changes
        this.selectedColor$
            .pipe(
                filter(color => this.isNewColor(color)),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(color => {
                if (this.isSolidColor(this.color)) {
                    this.color.setColor(color);
                } else {
                    this.colorService.updateGradientHelper(color);
                    this.updateGradientColor(color);
                }
                this.notifyColorChange();
            });

        // auto-close color picker when outside clicking. Using mousedown instead of click to prevent drag selecting to close it
        if (this.preventCloseElements) {
            this.addAutoCloseListener();
        }
    }

    ngOnDestroy(): void {
        this.colorService.destroyGradientHelper();
        this.closePicker();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private addAutoCloseListener(): void {
        fromEvent<MouseEvent>(document, 'mousedown')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((event: MouseEvent) => {
                const target = event.target as HTMLElement;
                let preventClose = false;

                this.preventCloseElements!.forEach(selector => {
                    if (isChildOfSelector(target, selector)) {
                        preventClose = true;
                    }
                });

                // check if color type was clicked
                const isColorTypeClicked = isChildOfSelector(target, '.color-preview');

                // check if a dropdown (e.g. brand colors, border type) was clicked
                const classList = (target as any).parentNode.classList;
                const isContextMenuClicked =
                    classList.contains('ui-dropdown-item') ||
                    classList.contains('ui-dropdown-list') ||
                    classList.contains('checkmark') ||
                    classList.contains('cdk-overlay-container') ||
                    target.classList.contains('ui-option');

                const isGradientHelperClicked = this.colorService.isGradientHelperClick();

                if (
                    !preventClose &&
                    !isContextMenuClicked &&
                    !isGradientHelperClicked &&
                    !isColorTypeClicked
                ) {
                    this.closePicker();
                }
            });
    }

    private closePicker(): void {
        if (this.name) {
            this.colorService.closeColorPicker(this.name);
        } else {
            this.logger.warn('Trying to close picker, but name was not provided');
        }
    }

    emitPreview(color: Color): void {
        const previewColor = this.getPreviewColor(color);
        this.preview.emit(previewColor);
    }

    stopPreview(): void {
        this.previewStop.emit(this.color);
    }

    private getPreviewColor(color: Color): Color {
        if (!this.color || this.isSolidColor(this.color)) {
            return color;
        } else {
            const preview = new Color(this.color);
            const index = this.colorService.gradientHelper.currentPointIndex;
            if (index > -1 && preview.stops.length > index) {
                preview.stops[index].color = color;
            }
            return preview;
        }
    }

    private updateGradientColor(color: Color): void {
        if (this.colorService.gradientHelper.active) {
            // find the actual color stop and change it
            this.color.stops[this.colorService.gradientHelper.currentPointIndex].color = color;
        } else {
            this.logger.error('Gradient changed, but gradientHelper was not available!');
        }
    }

    private setSelectedColor(color: Color): void {
        let selectedColor;
        // Gradient
        if (color.type !== ColorType.Solid) {
            this.colorService.initGradientHelper(color);
            selectedColor = this.colorService.gradientHelper.selectedPoint
                ? this.colorService.gradientHelper.selectedPoint.color
                : color.stops[0].color;
        } else {
            this.colorService.stopGradientHelper();
            selectedColor = color;
        }
        this.selectColor(selectedColor);
    }

    private selectColor(color: Color): void {
        this._selectedColor$.next({ color, sender: 0 });
        this.colorService.updateGradientHelper(color);
    }

    /**
     * Change color type (solid, gradient)
     */
    setColorType(type: ColorType): void {
        if (type === this.color.type) {
            return;
        }
        this.logger.verbose(`setting color type[${type}]`);

        this._color.type = type;

        switch (type) {
            case ColorType.Solid: {
                if (this.color.start) {
                    this.color.setColor(this.color.start.color);
                }
                break;
            }
            case ColorType.LinearGradient: {
                // If no gradient stops exit, create a new basic gradient
                if (this.color.stops.length < 2) {
                    this.color.setColor(
                        fromLinearGradient('linear-gradient(0deg,#000000 0%,#ffffff 100%)')
                    );
                }
                break;
            }
            default:
                throw new Error(`Invalid color type: ${type}`);
        }

        this.setSelectedColor(this.color);
        this.notifyColorChanged();
    }

    notifyColorChange(): void {
        this.logger.debug(`Color change: ${this.color}`);
        this.onColorChange.emit(this.color);
    }

    notifyColorChanged(): void {
        this.logger.debug(`Color changed: ${this.color}`);
        this.onColorChanged.emit(this.color.copy());
    }

    notifyEditStart(): void {
        this.logger.verbose('Notify edit start');
        this.editStart.emit();
    }

    notifyPickerBlur(): void {
        this.onPickerFocus.emit();
    }

    private isSolidColor(color: Color): boolean {
        return color.type === ColorType.Solid;
    }

    private isNewColor(color: Color): boolean {
        if (!this.color) {
            return true;
        }

        return colorChanged(this.color, color);
    }
}
