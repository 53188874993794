import { IState } from './state';
import { TimingFunctionKey, TimingFunctionKeyMacro } from './timing-functions';

export interface ITime {
    time: number;
    duration?: number;
}

export interface ITimeAndDuration extends ITime {
    duration: number;
}

export enum AnimationTypes {
    in,
    out,
    repeating,
    action,
    keyframe
}

// This is equivalent to:
// export type AnimationType = 'in' | 'out' | 'repeating' | 'action' | 'keyframe';
export type AnimationType = keyof typeof AnimationTypes;

export interface IAnimation {
    name: string;
    id: string;
    templateId?: AnimationTemplateId;
    type?: AnimationType;
    keyframes: IAnimationKeyframe[];
    timingFunction: TimingFunctionKey;
    hidden: boolean;
    /**
     * Inherited from old transition. TODO: Should we use the same
     */
    settings?: IAnimationSettings;
}

type AnimationTemplateId =
    | 'fade-in'
    | 'slide-in'
    | 'ascend-in'
    | 'scale-in'
    | 'flip-in'
    | 'blur-in'
    | 'fade-out'
    | 'slide-out'
    | 'descend-out'
    | 'scale-out'
    | 'flip-out'
    | 'blur-out';

export interface IAnimationTemplate {
    id: AnimationTemplateId;
    name: string;
    type: AnimationType;
    keyframes: IAnimationTemplateKeyframe[];
    timingFunction: TimingFunctionKey;
    settings?: IAnimationSettings;
}

export interface IAnimationTemplateKeyframe extends ITime {
    id?: string;

    /**
     * Template of a a state. Undefined means that this refers to main state.
     * If color etc is needed, turn this to a IStateDto
     */
    state?: IState;

    /**
     * Function returning value between 0-1 (some like Elastic will be over 1).
     * The value decides how much of the keyframe that should be visible.
     * "@timingFunction" means that it should inherit the value from the animation
     */
    timingFunction?: TimingFunctionKeyMacro | TimingFunctionKey;
}

export interface IAnimationKeyframe extends ITimeAndDuration {
    id: string;

    /**
     * Time of the keyframe. Note that this is relative to the time of the element.
     */
    time: number;

    /**
     * Reference to a state. Undefined means that this refers to main state.
     */
    stateId?: string;

    /**
     * Function returning value between 0-1 (some like Elastic will be over 1).
     * The value decides how much of the keyframe that should be visible.
     * "@timingFunction" means that it should inherit the value from the animation
     */
    timingFunction?: TimingFunctionKeyMacro | TimingFunctionKey;
}

export const keyframeProperties: (keyof IAnimationKeyframe)[] = [
    'time',
    'timingFunction',
    'id',
    'stateId',
    'duration'
];

export type IAnimationSettings = {
    direction?: IAnimationSetting;
    distance?: IAnimationSetting;
};

export interface IAnimationSetting {
    name: string;
    value: number;
}
