@if (state$ | async; as currentState) {
    @switch (currentState) {
        @case ('list') {
            <ui-button
                id="interaction-topbar-open-campaigns"
                type="default"
                [text]="'VIEW CAMPAIGNS ' + '(' + ((numberOfCampaigns$ | async) ?? 0) + ')'"
                [uiDropdownTarget]="campaignsDropdown.dropdown"
                [loading]="(isUpdatingChecksums$ | async)!"
                svgIcon="campaigns-l">
            </ui-button>
        }
        @case ('push') {
            <ui-button
                id="push-changes-button"
                class="push-button"
                type="primary"
                text="PUSH CHANGES"
                [loading]="!!(isPublishing$ | async) || (isUpdatingChecksums$ | async)!"
                svgIcon="campaigns-l"
                (click)="onPushChanges()">
            </ui-button>
        }
        @case ('create') {
            <ui-button
                id="create-campaign-button"
                (click)="onCreateCampaign()"
                [uiDropdownTarget]="hasSocialCampaignPermissions ? campaignsDropdown.dropdown : empty"
                type="primary"
                text="CREATE CAMPAIGN"
                svgIcon="campaigns-l"
                uiTooltip="Create new campaign to publish your creatives"
                uiTooltipPosition="bottom"
                [uiTooltipWidth]="265">
            </ui-button>
        }
    }

    <campaigns-dropdown
        #campaignsDropdown
        [isAddToCampaignVisible]="currentState === 'list'">
    </campaigns-dropdown>
    <ui-dropdown #empty></ui-dropdown>

    <add-to-campaign-dropdown #addToCampaignDropdown></add-to-campaign-dropdown>
}
