export const Breakpoints = {
    Mobile: '576px',
    Tablet: '768px',
    Desktop: '992px',
    FullHd: '1344px'
};

export enum Breakpoint {
    MobileDown,
    MobileUp,
    TabletDown,
    TabletUp,
    DesktopDown,
    DesktopUp,
    FullHdDown,
    FullHdUp
}

// these breakpoints have to match the $screen-breakpoints from variables.scss
// xxx.98px used because Safari bugs
export function getQueryFromBreakpoint(breakpoint: Breakpoint): string {
    switch (breakpoint) {
        case Breakpoint.MobileDown:
            return '(max-width: 575.98px)';
        case Breakpoint.MobileUp:
            return '(min-width: 576px)';
        case Breakpoint.TabletDown:
            return '(max-width: 767.98px)';
        case Breakpoint.TabletUp:
            return '(min-width: 768px)';
        case Breakpoint.DesktopDown:
            return '(max-width: 991.98px)';
        case Breakpoint.DesktopUp:
            return '(min-width: 992px)';
        case Breakpoint.FullHdDown:
            return '(max-width: 1343.98px)';
        case Breakpoint.FullHdUp:
            return '(min-width: 1344px)';
    }
}
