@if (!layers().length) {
    <div class="psd-content">
        Nothing is selected for import <br />
        Please select a file first
    </div>
} @else {
    <size-add-overview
        label="Select layers to import"
        [currentSelected]="numberOfSelectedPsdLayers()"
        (toggleAll)="toggleAllSelection()" />

    @for (layer of layers(); track layer.id; let index = $index) {
        <div
            class="layer ui-scrollbar"
            [ngClass]="{ 'layer--group': layer.type === 'group' }"
            data-test-id="psd-layer">
            @if (layer.type === 'group') {
                <div class="group-layer">
                    <div
                        class="layer__left"
                        (click)="toggleGroupCollapse(layer)">
                        <div class="layer__icon">
                            <ui-svg-icon
                                [ngClass]="{ collapsed: layer.collapsed }"
                                icon="arrow-up" />
                        </div>
                        <div class="layer__name layer__name--group">
                            <span class="title bold">{{ layer.name }} </span>
                            <span
                                >{{ getSelectedChildrenCount(layer) }} /
                                {{ getChildrenCount(layer) }}</span
                            >
                        </div>
                    </div>

                    <div class="layer__right">
                        @if (!layer.error?.isFixed) {
                            <div
                                class="layer__toggle layer__toggle--visibility"
                                (click)="toggleVisibility(layer)">
                                <ui-svg-icon
                                    class="layer__icon"
                                    [class.layer__icon--hidden]="layer.hidden"
                                    data-test-id="psd-visibility-icon"
                                    [icon]="
                                        layer.hidden ? 'visibility-hidden' : 'visibility-visible'
                                    " />
                            </div>
                        }
                        @if (!layer.error?.isCritical) {
                            <div
                                #toggleAll
                                class="layer__toggle layer__toggle--selection"
                                (click)="toggleSelection(layer)">
                                <ui-checkbox
                                    [indeterminate]="
                                        selectionState(layer) === LayerSelectedState.SomeSelected
                                    "
                                    [selected]="!layer.error && layer.selected" />
                            </div>
                        }
                    </div>
                </div>
            } @else {
                @if (!layer.collapsed) {
                    <div
                        class="any-layer"
                        (click)="toggleSelection(layer)">
                        <div class="layer__left">
                            <div class="layer__thumbnail">
                                @if (layer.thumbnail) {
                                    <img
                                        [src]="layer.thumbnail"
                                        data-test-id="psd-thumbnail"
                                        alt="Layer thumbnail" />
                                }
                            </div>
                            <div
                                class="layer__name"
                                [class.warning]="!!layer.error">
                                <span class="title">{{ layer.name }}</span>
                            </div>
                        </div>

                        <div class="layer__right">
                            <div class="layer__toggle layer__toggle--warning">
                                @if (layer.error && !layer.error.isFixed) {
                                    <ui-svg-icon
                                        ui-popover-target
                                        #warningTarget="ui-popover-target"
                                        [id]="'target-' + index"
                                        [uiTooltip]="layer.error.message"
                                        [uiTooltipDisabled]="!layer.error"
                                        class="layer__icon layer__icon--warning"
                                        data-test-id="psd-warning-icon"
                                        icon="warning" />
                                }
                            </div>
                            @if (!layer.error?.isCritical) {
                                <div
                                    class="layer__toggle layer__toggle--visibility"
                                    (click)="toggleVisibility(layer)">
                                    <ui-svg-icon
                                        class="layer__icon"
                                        [class.layer__icon--hidden]="layer.hidden"
                                        data-test-id="psd-visibility-icon"
                                        [icon]="
                                            layer.hidden ? 'visibility-hidden' : 'visibility-visible'
                                        " />
                                </div>
                                <div class="layer__toggle layer__toggle--selection">
                                    <ui-checkbox [selected]="layer.selected" />
                                </div>
                            }
                        </div>
                    </div>
                }
            }
        </div>
    }
}
<fix-font-popover [layerInput]="importFontData()" />
