import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class Store<T> {
    private saved: T | undefined;

    get(): T | undefined {
        return this.saved;
    }

    save(item: T): void {
        this.saved = item;
    }
}
