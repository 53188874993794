interface RenderingOptions {
    resolutionMinWidth: number;
    resolutionMinHeight: number;
    aspectRatioGroup: string;
}

export const renderingOptions: RenderingOptions[] = [
    {
        resolutionMinWidth: 398,
        resolutionMinHeight: 208,
        aspectRatioGroup: '9:16'
    },
    {
        resolutionMinWidth: 600,
        resolutionMinHeight: 600,
        aspectRatioGroup: '1.91:1'
    },
    {
        resolutionMinWidth: 1080,
        resolutionMinHeight: 1080,
        aspectRatioGroup: '1:1'
    }
];
