import { Component, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { BrandLibraryElementDeletionService } from '@app/design-view/media-library/brandlibrary-element-deletion.service';
import { BrandLibraryDataService } from '@app/shared/media-library/brand-library.data.service';
import { FileDownloadService } from '@app/shared/services';
import { UIModule } from '@bannerflow/ui';
import { ImageLibraryAsset } from '@domain/brand/brand-library';
import { IImageElementAsset } from '@domain/creativeset/element-asset';
import { AIStudioService } from '../ai-studio.service';
import { GenAIService } from '../state/gen-ai.service';

@Component({
    selector: 'ai-studio-topbar',
    templateUrl: 'ai-studio-topbar.component.html',
    styleUrls: ['ai-studio-topbar.component.scss'],
    standalone: true,
    imports: [UIModule]
})
export class AIStudioTopbarComponent {
    private aiStudioService = inject(AIStudioService);
    private genAIService = inject(GenAIService);
    private brandLibraryDataService = inject(BrandLibraryDataService);
    private brandLibraryElementDeletionService = inject(BrandLibraryElementDeletionService);
    private fileDownloaderService = inject(FileDownloadService);

    private elementId = toSignal(this.genAIService.openedElementId$);
    element = computed(() => this.brandLibraryDataService.getElementById(this.elementId()));

    imageAsset = toSignal(this.genAIService.currentImageAsset$);
    imageSize = toSignal(this.genAIService.currentImageSize$);

    downloadImage(): void {
        const imageAsset = this.imageAsset();
        if (imageAsset) {
            this.fileDownloaderService.download(imageAsset.url, imageAsset.name);
        }
    }

    isImageLibraryAsset(
        asset: ImageLibraryAsset | IImageElementAsset | undefined
    ): asset is ImageLibraryAsset {
        return !!asset && 'fileSize' in asset;
    }

    async deleteElement(): Promise<void> {
        const element = this.element();
        if (element) {
            const result = await this.brandLibraryElementDeletionService.delete(element);
            if (result === 'confirm') {
                this.aiStudioService.closeAIStudio();
            }
        }
    }
}
