<div
    class="wrapper"
    data-test-id="size-add-collection-overview">
    <strong>
        @if (hasSelectedCollections()) {
            Filtered Sizes
        } @else {
            All Sizes
        }
    </strong>
    @if (hasSelectedSizes()) {
        <span (click)="deselectAllSizes()">Deselect all</span>
    } @else {
        <span (click)="selectAllSizes()">Select all</span>
    }
</div>
