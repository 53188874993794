type ResponseType = 'text' | 'arrayBuffer';

type ResponseWithBps<T extends ResponseType> = {
    data: T extends 'text' ? string : ArrayBuffer;
    bps: number; // Bits per second
};

export class StreamingHttpClient {
    bps = 0;

    async fetchText(url: string): Promise<string> {
        const { data, bps } = await this.fetchData(url, 'text');
        this.bps = bps;
        return data;
    }

    async fetchChunk(url: string): Promise<ArrayBuffer> {
        const { data, bps } = await this.fetchData(url, 'arrayBuffer');
        this.bps = bps;
        return data;
    }

    async fetchChunkAndAppend(chunkUrl: string, sourceBuffer: SourceBuffer): Promise<void> {
        const buffer = await this.fetchChunk(chunkUrl);
        return new Promise((resolve, reject) => {
            sourceBuffer.addEventListener('updateend', () => resolve(), { once: true });
            try {
                sourceBuffer.appendBuffer(buffer);
            } catch (error) {
                reject(error);
            }
        });
    }

    async fetchData<T extends ResponseType>(url: string, responseType: T): Promise<ResponseWithBps<T>> {
        const start = performance.now();
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`Error fetching: ${url}`);
        }

        let data: string | ArrayBuffer;
        if (responseType === 'text') {
            data = await response.text();
        } else if (responseType === 'arrayBuffer') {
            data = await response.arrayBuffer();
        } else {
            throw new Error(`Invalid response type: ${responseType}`);
        }

        const end = performance.now();
        const seconds = (end - start) / 1000;

        let bytes = +(response.headers.get('Content-Length') || 0);
        if (!bytes) {
            // fallback to calculate size from fetched data
            bytes = data instanceof ArrayBuffer ? data.byteLength : new Blob([data]).size;
        }
        const bps = calculateBitsPerSecond(bytes, seconds);
        console.debug({ bytes, duration: seconds.toFixed(2), bps });

        return { data, bps } as ResponseWithBps<T>;
    }
}

function calculateBitsPerSecond(bytes: number, seconds: number): number {
    const B = Number(bytes);
    const b = B * 8;
    return Math.round(b / seconds);
}
