import { IExportCreativeItem } from '@domain/creativeset/creative/export-creative';

export class CreativeExport {
    type: string;

    /**
     * A quality between 0-100 for JPG and PNG. 1-256 for GIF.
     */
    quality: number;

    /**
     * Times a gif should play before stopping. Does only apply to GIF
     */
    loops?: number;

    creatives: IExportCreativeItem[];

    startTime: number;
    timeout = 30000;

    creativesetId: string;
    correlationId: string;

    get elapsed(): number {
        return Date.now() - this.startTime;
    }

    get isTimedOut(): boolean {
        return this.elapsed >= this.timeout;
    }
}
