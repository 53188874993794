import { Directive, Host, ElementRef, Output, EventEmitter } from '@angular/core';
import { fromEvent, merge } from 'rxjs';
import { repeat, map } from 'rxjs/operators';

export enum PreviewEvents {
    PREVIEW,
    REVERT,
    APPLY
}

@Directive({
    selector: '[appPreviewDirective]',
    exportAs: 'appPreviewDirective'
})
export class PreviewDirective {
    @Output() preview = new EventEmitter<PreviewEvents.PREVIEW>();
    @Output() revert = new EventEmitter<PreviewEvents.REVERT>();
    @Output() apply = new EventEmitter<PreviewEvents.APPLY>();

    private previewActions = {
        [PreviewEvents.PREVIEW]: (): void => this.preview.emit(),
        [PreviewEvents.REVERT]: (): void => this.revert.emit(),
        [PreviewEvents.APPLY]: (): void => this.apply.emit()
    };

    constructor(@Host() host: ElementRef) {
        const preview$ = fromEvent<PreviewEvents>(host.nativeElement, 'mouseenter').pipe(
            map(() => PreviewEvents.PREVIEW)
        );
        const revert$ = fromEvent<PreviewEvents>(host.nativeElement, 'mouseleave').pipe(
            map(() => PreviewEvents.REVERT)
        );
        const apply$ = fromEvent<PreviewEvents>(host.nativeElement, 'mouseup').pipe(
            map(() => PreviewEvents.APPLY)
        );

        const previewEvents$ = merge(preview$, revert$, apply$).pipe(repeat());

        previewEvents$.subscribe(previewEvent => this.previewActions[previewEvent]());
    }
}
