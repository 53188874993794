<div class="left-panel">
    <div
        class="icon eye"
        data-test-id="element-visibility-toggle"
        [class.visibility-hidden]="animation.hidden">
        <ui-svg-icon
            [icon]="animation.hidden ? 'visibility-hidden' : 'visibility-visible'"
            (click)="toggleVisibility()">
        </ui-svg-icon>
    </div>
    <div
        class="name"
        [class.active]="hasSelectedKeyframes"
        [ngClass]="'indent-level-' + indentLevel"
        #elementName>
        <ui-input
            #name
            ui-theme="tiny"
            [discrete]="true"
            [value]="animation.name"
            (cancel)="cancelSetName()"
            (blur)="setName(name.value)"
            (submit)="submitName()"></ui-input>
    </div>
    <div
        class="icon menu"
        [ngClass]="{ active: selected && timeline.editor.workspace.contextMenuOpen }"
        (mousedown)="openContextMenu($event, true)">
        <ui-svg-icon icon="kebab"></ui-svg-icon>
    </div>
</div>

<div class="right-canvas">
    <canvas-drawer
        [shapes$]="shapes$"
        [originX]="canvasPadding">
    </canvas-drawer>
</div>
