<asset-picker
    [showReplaceButton]="showReplaceButton"
    [elements]="elements"
    [allowFeed]="allowFeed"
    [allowUpload]="allowUpload"
    [allowRemove]="allowRemove"
    [isMixed]="isMixedAssets"
    [isFeed]="isFeed"
    [name]="name"
    [label]="label"
    [icon]="icon"
    [asset]="asset"
    [assetType]="assetType"
    [context]="context"
    [isSizeModeEnabled]="isSizeModeEnabled"
    (openFeedSettings)="openFeedSettings($event)"
    (assetRemoved)="onAssetRemoved()"
    (assetSelected)="onAssetSelected($event)"
    (feedSelected)="onFeedItemSelected($event)"
    (updateImageSizeMode)="onUpdateImageSizeMode($event)">
</asset-picker>
