import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import {
    EraseRequest,
    GenerateImageRequest,
    ImageFormat,
    InpaintRequest,
    OutpaintImageRequest,
    RemoveBackgroundRequest,
    SearchAndReplaceRequest
} from '@studio/domain/api/gen-ai';
import { OutpaintSettings } from '@studio/domain/components/gen-ai/outpaint.types';
import { environment } from '../../../../environments/environment';
import { cleanOutpaintRequest, getImageExtensionFromBase64, handlePostResponse } from '../utils';

@Injectable({
    providedIn: 'root'
})
export class GenAIDataService {
    private httpClient = inject(HttpClient);

    private apiRoute = `${environment.origins.genAiImage}/api/image`;

    generateImage(prompt: string): Observable<string> {
        const url = `${this.apiRoute}/generate`;
        const body: GenerateImageRequest = {
            prompt,
            outputImageFormat: ImageFormat.PNG
        };
        return this.httpClient.post(url, body, { responseType: 'blob' }).pipe(handlePostResponse);
    }

    removeBackground(imageInBase64: string): Observable<string> {
        const url = `${this.apiRoute}/remove-background`;
        const body: RemoveBackgroundRequest = {
            imageUrlOrBase64: imageInBase64,
            outputImageFormat: ImageFormat.PNG
        };

        return this.httpClient.post(url, body, { responseType: 'blob' }).pipe(handlePostResponse);
    }

    inpaint({
        prompt,
        negativePrompt,
        imageInBase64,
        imageMask
    }: {
        prompt: string;
        negativePrompt?: string;
        imageInBase64: string;
        imageMask: string;
    }): Observable<string> {
        const extension = getImageExtensionFromBase64(imageInBase64);
        if (!extension) {
            throw new Error('Unable to determine image extension');
        }
        const body: InpaintRequest = {
            imageUrlOrBase64: imageInBase64,
            outputImageFormat: extension === ImageFormat.PNG ? ImageFormat.PNG : ImageFormat.JPG,
            imageMask,
            negativePrompt,
            prompt
        };
        const url = `${this.apiRoute}/inpaint`;
        return this.httpClient.post(url, body, { responseType: 'blob' }).pipe(handlePostResponse);
    }

    erase({
        imageInBase64,
        imageMask
    }: {
        imageInBase64: string;
        imageMask: string;
    }): Observable<string> {
        const extension = getImageExtensionFromBase64(imageInBase64);
        if (!extension) {
            throw new Error('Unable to determine image extension');
        }
        const body: EraseRequest = {
            imageUrlOrBase64: imageInBase64,
            outputImageFormat: extension === ImageFormat.PNG ? ImageFormat.PNG : ImageFormat.JPG,
            imageMask
        };
        const url = `${this.apiRoute}/erase`;
        return this.httpClient.post(url, body, { responseType: 'blob' }).pipe(handlePostResponse);
    }

    outpaint(
        imageInBase64: string,
        settigns: OutpaintSettings,
        prompt?: string,
        negativePrompt?: string
    ): Observable<string> {
        const extension = getImageExtensionFromBase64(imageInBase64);
        if (!extension) {
            throw new Error('Unable to determine image extension');
        }

        const body: OutpaintImageRequest = cleanOutpaintRequest({
            ...settigns,
            outputImageFormat: extension === ImageFormat.PNG ? ImageFormat.PNG : ImageFormat.JPG,
            prompt,
            negativePrompt,
            imageUrlOrBase64: imageInBase64
        });

        const url = `${this.apiRoute}/outpaint`;
        return this.httpClient.post(url, body, { responseType: 'blob' }).pipe(handlePostResponse);
    }

    searchAndReplace(
        searchPrompt: string,
        replacePrompt: string,
        imageUrlOrBase64: string
    ): Observable<string> {
        const extension = getImageExtensionFromBase64(imageUrlOrBase64);
        const body: SearchAndReplaceRequest = {
            imageUrlOrBase64,
            outputImageFormat: extension === ImageFormat.PNG ? ImageFormat.PNG : ImageFormat.JPG,
            prompt: replacePrompt,
            searchPrompt
        };

        return this.httpClient
            .post(`${this.apiRoute}/search-and-replace`, body, { responseType: 'blob' })
            .pipe(handlePostResponse);
    }
}
