<ui-popover
    #popover="ui-popover"
    ui-popover-target
    [config]="{
        arrowPosition: 'left',
        position: 'bottom',
        panelClass: 'no-padding generate-image-popover',
        maxWidth: 300,
        width: 'auto',
        backdropClickClose: true,
        hasBackdrop: true,
        offset: { y: 0, x: 0 }
    }">
    <ng-template ui-popover-template>
        <div class="wrapper prevent-ml-close">
            <div class="header">
                <h4 class="title">Generate with AI</h4>
                <ui-svg-icon
                    icon="close"
                    (click)="popover.close()"></ui-svg-icon>
            </div>

            <div class="prompt">
                <ui-textarea
                    [autofocus]="true"
                    [blurOnSubmit]="true"
                    placeholder="Enter a detailed prompt (E.g., a portrait of a woman in the style of Banksy)"
                    (valueChange)="onTextareaChange($event)"></ui-textarea>
            </div>

            <div class="generate-actions">
                @if (!currentImage) {
                    <ui-button
                        id="interaction-generate-image"
                        type="primary"
                        text="Generate"
                        [disabled]="disableButtons()"
                        (click)="generateImage()"></ui-button>
                } @else {
                    <ui-button
                        id="interaction-regenerate-image"
                        type="primary"
                        text="Generate again"
                        [disabled]="disableButtons()"
                        (click)="regenerateImage()"></ui-button>
                }
            </div>

            @if (isRequestingGeneration()) {
                <descriptive-loader [type]="loaderType" />
            }

            @if (currentImage; as image) {
                <div class="result">
                    <img
                        alt="Generated result"
                        [src]="image" />
                </div>
                <div class="save-actions">
                    <ui-button
                        id="interaction-generate-image-save"
                        type="primary"
                        text="Save to library"
                        [loading]="isRequestingSaveToBrandLibrary()"
                        [disabled]="disableButtons()"
                        (click)="saveToBrandLibrary()">
                    </ui-button>
                    <ui-button
                        id="interaction-generate-image-save"
                        type="default"
                        text="Edit in AI Studio"
                        [loading]="isRequestingOpenInAIStudio()"
                        [disabled]="disableButtons()"
                        (click)="openInAiStudio()">
                    </ui-button>
                </div>
            }
        </div>
    </ng-template>
</ui-popover>
