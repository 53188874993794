import { getLibraryKindFromElementKind } from '@creative/nodes/helpers';
import {
    IBrandLibraryElement,
    ImageLibraryAsset,
    INewBrandLibraryElement,
    IWidgetLibraryAsset,
    LibraryWidget,
    OneOfLibraryAssets
} from '@domain/brand/brand-library';
import { AssetReference } from '@domain/creativeset/element-asset';
import { LibraryKind } from '@domain/media-library';
import { ElementKind } from '@domain/elements';
import {
    createBrandlibraryElement,
    getLibraryWidgetReferenceOfElement
} from '@studio/utils/element.utils';
import { effectIds } from './environments/effects';

export function isEffectWidget(widget: LibraryWidget): boolean {
    if (!widget.id) {
        return false;
    }
    return effectIds.includes(widget.id);
}

export function isEffectLibraryElement(
    element: IBrandLibraryElement | INewBrandLibraryElement
): boolean {
    if (element.type !== ElementKind.BannerflowLibraryWidget) {
        return false;
    }

    const widgetReference = getLibraryWidgetReferenceOfElement(element);

    if (widgetReference?.value && effectIds.includes(widgetReference.value)) {
        return true;
    }

    return false;
}

export function isElementTypeMatchLibraryType(
    element: IBrandLibraryElement | INewBrandLibraryElement,
    libraryKind: LibraryKind
): boolean {
    // Effects are widgets, but should not be included in the widget library.
    if (isEffectLibraryElement(element)) {
        return libraryKind === LibraryKind.Effects;
    }

    return (
        getLibraryKindFromElementKind(element.type) === libraryKind || libraryKind === LibraryKind.Any
    );
}

export function createNewBrandLibraryElementFromWidget(widget: LibraryWidget): INewBrandLibraryElement {
    return createBrandlibraryElement({
        name: widget.name,
        type: ElementKind.BannerflowLibraryWidget,
        properties: [
            {
                name: AssetReference.BannerflowLibraryWidget,
                unit: 'id',
                value: widget.id
            }
        ]
    });
}

export function isAnimatedThumbnailSupportedAsset(
    asset: OneOfLibraryAssets | IWidgetLibraryAsset
): asset is ImageLibraryAsset | IWidgetLibraryAsset {
    return 'animatedThumbnail' in asset;
}
