<div
    class="status-list"
    [class.collapsed]="collapsed">
    <div
        class="header"
        [class.collapsed]="collapsed">
        <ui-svg-icon
            class="filter-icon"
            icon="visibility-visible"
            data-test-id="status-filter-reset"
            (click)="clearStatus()"></ui-svg-icon>
        <span class="title">Status</span>
        <div class="temporary-container-waiting-for-icon"></div>

        <ui-svg-icon
            (click)="collapse()"
            class="icon collapse"
            [icon]="collapsed ? 'arrow-up' : 'arrow-down'"></ui-svg-icon>
    </div>
    @if (!collapsed) {
        <div>
            <div
                class="no-status list-item"
                (click)="filterStatus(ApprovalStatus.NoStatus)">
                <ui-svg-icon
                    class="status-visibility-icon"
                    [class.selected]="isActive(ApprovalStatus.NoStatus)"
                    [class.not-selected]="!isActive(ApprovalStatus.NoStatus)"
                    icon="visibility-visible"></ui-svg-icon>
                <ui-svg-icon
                    class="no-status status-icon"
                    icon="status-dot"></ui-svg-icon>
                No status <span class="count"> {{ statusCounters[ApprovalStatus.NoStatus] }}</span>
            </div>

            <div
                class="not-approved list-item"
                (click)="filterStatus(ApprovalStatus.NotApproved)">
                <ui-svg-icon
                    class="status-visibility-icon"
                    [class.selected]="isActive(ApprovalStatus.NotApproved)"
                    [class.not-selected]="!isActive(ApprovalStatus.NotApproved)"
                    icon="visibility-visible"></ui-svg-icon>
                <ui-svg-icon
                    class="not-approved status-icon"
                    icon="status-dot"></ui-svg-icon>
                Not approved
                <span class="count">{{ statusCounters[ApprovalStatus.NotApproved] }}</span>
            </div>

            <div
                class="progress list-item"
                (click)="filterStatus(ApprovalStatus.InProgress)">
                <ui-svg-icon
                    class="status-visibility-icon"
                    [class.selected]="isActive(ApprovalStatus.InProgress)"
                    [class.not-selected]="!isActive(ApprovalStatus.InProgress)"
                    icon="visibility-visible"></ui-svg-icon>
                <ui-svg-icon
                    class="progress status-icon"
                    icon="status-dot"></ui-svg-icon
                >In progress
                <span class="count">{{ statusCounters[ApprovalStatus.InProgress] }}</span>
            </div>

            <div
                class="review list-item"
                (click)="filterStatus(ApprovalStatus.ForReview)">
                <ui-svg-icon
                    class="status-visibility-icon"
                    [class.selected]="isActive(ApprovalStatus.ForReview)"
                    [class.not-selected]="!isActive(ApprovalStatus.ForReview)"
                    icon="visibility-visible"></ui-svg-icon>
                <ui-svg-icon
                    class="review status-icon"
                    icon="status-dot"></ui-svg-icon
                >For review
                <span class="count">{{ statusCounters[ApprovalStatus.ForReview] }}</span>
            </div>

            <div
                class="approved list-item"
                (click)="filterStatus(ApprovalStatus.Approved)">
                <ui-svg-icon
                    class="status-visibility-icon"
                    [class.selected]="isActive(ApprovalStatus.Approved)"
                    [class.not-selected]="!isActive(ApprovalStatus.Approved)"
                    icon="visibility-visible"></ui-svg-icon>
                <ui-svg-icon
                    class="approved status-icon"
                    icon="status-dot"></ui-svg-icon>
                Approved <span class="count"> {{ statusCounters[ApprovalStatus.Approved] }}</span>
            </div>
        </div>
    }
</div>
