import { IAdData } from '@domain/ad/ad-data';
import {
    IAdDataCreative,
    IAdDataCreativeBase,
    IAdDataCreativeSize,
    IAdDataCreativeVersion,
    INameAndId
} from '@domain/ad/ad-data-creative';
import { CreativeType } from '@domain/creativeset/creative';
import { simpleClone } from '@studio/utils/clone';
import { getFirstUrl } from '@studio/utils/url';
import { SAMPLE_AD_DATA } from './sample-ad-data';

export interface IInputCreative {
    id?: string;
    size: IAdDataCreativeSize;
    design?: INameAndId;
    version?: IAdDataCreativeVersion;
    targetUrl?: string;
}
interface ITargetUrl {
    targetUrl?: string;
}

export function getSampleAdData(): IAdData {
    return simpleClone<IAdData>(SAMPLE_AD_DATA);
}

export function getStudioAdDataCreative(
    creativeset: INameAndId,
    creative: IInputCreative,
    brandId: string,
    version?: IAdDataCreativeVersion & ITargetUrl
): IAdDataCreative {
    return {
        ...getAdDataCreativeBase(creativeset, creative, version),
        brand: {
            id: brandId
        },
        adVersion: 1,
        animated: {
            dependencies: [],
            files: []
        }
    };
}

export function getAdDataCreativeBase(
    creativeset: INameAndId,
    creative: IInputCreative,
    version?: IAdDataCreativeVersion & ITargetUrl
): IAdDataCreativeBase {
    if (!version) {
        if (creative.version) {
            version = creative.version;
        } else {
            throw new Error('Version not provided');
        }
    }

    const size = creative.size;
    const design = creative.design || { name: '', id: '' };
    const localization = version.localization;

    return {
        id: creative.id || '0',
        type: CreativeType.Animated,
        creativeset: {
            id: creativeset.id,
            name: creativeset.name
        },
        version: {
            id: version.id,
            name: version.name,
            localization: {
                id: localization.id,
                name: localization.name,
                cultureCode: localization.cultureCode,
                cultureName: localization.cultureName
            }
        },
        design: {
            id: design.id,
            name: design.name
        },
        size: {
            id: size.id,
            width: size.width,
            height: size.height
        },
        created: new Date().toISOString(),
        targetUrl: getTargetUrl(version, creative)
    };
}

export function getTargetUrl(version: ITargetUrl, creative: ITargetUrl): string {
    return getFirstUrl(creative.targetUrl, version.targetUrl) || '';
}
