import { Pipe, PipeTransform } from '@angular/core';
import { Color } from '@creative/color';
import { IColor } from '@domain/color';

@Pipe({
    standalone: true,
    name: 'parseColor'
})
export class ParseColorPipe implements PipeTransform {
    transform(value: IColor | undefined | 'mixed'): IColor {
        return Color.parse(value ?? '', '#000000');
    }
}
