import { getXmlAttribute, getXmlElement } from '@studio/utils/dom-utils';
import { StreamingManifest, StreamingRepresentation } from './manifest.model';

export function parseManifest(manifest: string): StreamingManifest {
    const parser = new DOMParser();
    const xml = parser.parseFromString(manifest, 'text/xml');
    const mpd = getMPD(xml);
    const totalDuration = getTotalDuration(mpd);
    const maxSegmentDuration = getMaxSegmentDuration(mpd);
    const totalChunks = Math.ceil(totalDuration / maxSegmentDuration);
    const videoRepresentations = Array.from(
        xml.querySelectorAll('Representation[mimeType="video/mp4"]')
    )
        .map(representation => mapToStreamingRepresentation(representation))
        .sort(sortRepresentationByBandwidth);

    const audioRepresentations = Array.from(
        xml.querySelectorAll('Representation[mimeType="audio/mp4"]')
    ).map(representation => mapToStreamingRepresentation(representation));

    const videoCodecs = videoRepresentations.length ? videoRepresentations[0].codecs : '';
    const audioCodecs = audioRepresentations.length ? audioRepresentations[0].codecs : '';

    return {
        totalDuration,
        totalChunks,
        representations: {
            audio: audioRepresentations,
            video: videoRepresentations
        },
        codecs: {
            audio: audioCodecs,
            video: videoCodecs
        }
    };
}

export function mapToStreamingRepresentation(representation: Element): StreamingRepresentation {
    const adaptationSet = representation.parentElement;
    if (!adaptationSet) {
        throw new Error('No AdaptationSet element found next to the representation');
    }
    const isVideo = representation.getAttribute('mimeType') === 'video/mp4';
    const bandwidth = +getXmlAttribute(representation, 'bandwidth');
    const id = getXmlAttribute(representation, 'id');
    const identifier = isVideo
        ? `${representation.getAttribute('height')}p`
        : representation.getAttribute('audioSamplingRate');
    const codecs = getXmlAttribute(representation, 'codecs');
    const name = `${identifier}, ${Math.floor(bandwidth / 1000)} kbps`;

    const segmentTemplate = getXmlElement(adaptationSet, 'SegmentTemplate');
    const duration = +getXmlAttribute(segmentTemplate, 'duration');
    const timescale = +getXmlAttribute(segmentTemplate, 'timescale');
    const startIndex = +getXmlAttribute(segmentTemplate, 'startNumber');
    const chunkUrlTemplate = getXmlAttribute(segmentTemplate, 'media');
    const initTemplate = getXmlAttribute(segmentTemplate, 'initialization');
    const initUrl = initTemplate.replace('$RepresentationID$', id);

    return {
        id,
        name,
        duration: duration / timescale,
        chunkUrlTemplate,
        initUrl,
        bandwidth,
        startIndex,
        codecs
    };
}

function sortRepresentationByBandwidth(
    repA: StreamingRepresentation,
    repB: StreamingRepresentation
): number {
    return repA.bandwidth - repB.bandwidth;
}

export function getMPD(manifest: Document): Element {
    const mpd = manifest.querySelector('MPD');
    if (!mpd) {
        throw new Error('No MPD element found in the manifest');
    }
    return mpd;
}
export function getMediaPresentationDurationInSeconds(duration: string): number {
    const match = duration.match(/PT(\d+H)?(\d+M)?(\d+(\.\d+)?S)?/);
    if (!match) {
        throw new Error('Invalid duration format');
    }
    const hours = parseFloat(match[1]) || 0;
    const minutes = parseFloat(match[2]) || 0;
    const seconds = parseFloat(match[3]) || 0;

    return hours * 3600 + minutes * 60 + seconds;
}

export function getTotalDuration(mpd: Element): number {
    const mediaPresentationDuration = getXmlAttribute(mpd, 'mediaPresentationDuration');
    return getMediaPresentationDurationInSeconds(mediaPresentationDuration);
}

export function getMaxSegmentDuration(mpd: Element): number {
    const maxSegmentDuration = getXmlAttribute(mpd, 'maxSegmentDuration');
    return getMediaPresentationDurationInSeconds(maxSegmentDuration);
}
