import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { UIPopoverDirective, UIPopoverTargetDirective } from '@bannerflow/ui';
import { getClosestGap, isTimeAt, isTimeBetween, sortByTime } from '@creative/animation.utils';
import { ITime } from '@domain/animation';
import { ICreativeDataNode } from '@domain/nodes';
import { MouseObservable } from '@studio/utils/mouse-observable';
import { clamp, decimal } from '@studio/utils/utils';
import { merge, Subject } from 'rxjs';
import { auditTime, takeUntil } from 'rxjs/operators';
import { EditorEventService, EditorStateService, HistoryService } from '../../services';
import { changeFilter } from '../../services/editor-event';
import { StudioTimelineComponent } from '../studio-timeline/studio-timeline.component';
import { ACTION_THRESHOLD, AnimationService, auditInterval } from '../timeline-element';
import { TimelineScrollService } from '../timeline-scroll.service';
import { ActionMode, TimelineTransformService } from '../timeline-transformer.service';
import { TimelineZoomService } from '../timeline-zoom.service';

const MIN_GIF_FRAME_DISTANCE = 0.05;

@Component({
    selector: 'gif-frames',
    templateUrl: './gif-frames.component.html',
    styleUrls: ['./gif-frames.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GifFramesComponent implements OnInit, OnDestroy {
    @ViewChild('addIcon') private addIcon: ElementRef<HTMLElement>;
    @ViewChild('framePopover') private framePopover: UIPopoverDirective;

    selectedFrame?: ITime;
    openFrame?: ITime;

    get document(): ICreativeDataNode {
        return this.editorStateService.document;
    }

    get time(): number {
        return this.editorStateService.renderer.time_m;
    }

    get frames(): ITime[] {
        return this.document.gifExport.frames;
    }

    set frames(frames: ITime[]) {
        this.document.gifExport.frames = frames;
    }

    private mouseObservable: MouseObservable;
    private unsubscribe$ = new Subject<void>();
    private mouseDownMoved = false;

    constructor(
        public scrollService: TimelineScrollService,
        private host: ElementRef,
        private editorStateService: EditorStateService,
        private changeDetector: ChangeDetectorRef,
        private zoomService: TimelineZoomService,
        private timeline: StudioTimelineComponent,
        private historyService: HistoryService,
        private animationService: AnimationService,
        private editorEvent: EditorEventService,
        private timelineTransformService: TimelineTransformService
    ) {}

    ngOnInit(): void {
        merge(
            this.editorEvent.elements.immediateChange$.pipe(
                changeFilter({ explicitProperties: ['duration', 'time'] })
            ),
            this.editorEvent.creative.change$
        )
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.detectChanges();
                // Ensure no frame is outside of duration
                this.frames.forEach(frame => this.moveFrame(frame, frame.time));
            });

        this.mouseObservable = new MouseObservable(this.host.nativeElement, {
            offset: {
                x: this.timeline.timelineElementLeftOffset,
                y: 0
            },
            mouseDownMoveTreshold: { x: ACTION_THRESHOLD }
        });

        this.mouseObservable.mouseDownMove$.subscribe(({ mousePosition, mouseDownMoved }) => {
            if (this.selectedFrame) {
                this.timelineTransformService.actionMode = ActionMode.MoveGifFrame;
                const time = this.timeline.scrolledPixelsToSeconds(mousePosition.x);
                this.moveFrame(this.selectedFrame, time);
            }
            this.mouseDownMoved = mouseDownMoved;
        });

        this.mouseObservable.mouseUp$.subscribe(() => (this.selectedFrame = undefined));
        this.mouseObservable.mouseDown$.subscribe(() => (this.mouseDownMoved = false));
        this.mouseObservable.doubleClick$.subscribe(({ mousePosition }) => {
            const frame = this.addFrame(this.timeline.scrolledPixelsToSeconds(mousePosition.x));
            if (frame) {
                this.timeline.seek(frame.time);
            }
        });

        merge(this.scrollService.scroll$, this.zoomService.zoom$, this.historyService.snapshotApply$)
            .pipe(auditTime(auditInterval), takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.detectChanges();
            });

        this.animationService.seek$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.updateAddIconDisabledState();
        });

        this.timeline.leftOffset$.pipe(takeUntil(this.unsubscribe$)).subscribe(offset => {
            this.mouseObservable.setOffsets({
                x: offset,
                y: 0
            });
        });
    }

    onMouseDown(frame: ITime): void {
        this.selectedFrame = frame;
    }

    onFramePopoverClose(): void {
        this.openFrame = undefined;
    }

    openFramePopover(frameElement: UIPopoverTargetDirective, frame: ITime): void {
        if (!this.mouseDownMoved) {
            this.openFrame = frame;
            this.framePopover.open(frameElement);
        }
        this.mouseDownMoved = false;
    }

    getXFromTime(frame: ITime): number {
        return this.timeline.secondsToScrolledPixels(frame.time);
    }

    addFrame(time?: number): ITime | undefined {
        time = typeof time === 'number' ? time : this.time;
        const duration = this.editorStateService.document.duration;
        const frames = this.frames;

        if (isTimeBetween(time, 0, duration) && !this.hasFrameAt(time)) {
            const frame: ITime = { time };
            frames.push(frame);
            frames.sort(sortByTime);
            this.detectChanges();
            return frame;
        }
    }

    hasFrameAt(time: number, tolerance = MIN_GIF_FRAME_DISTANCE): boolean {
        return this.frames.some(f => isTimeAt(time, f.time, tolerance));
    }

    deleteFrame(frame: ITime): void {
        this.frames = removeItemInArray(this.frames, frame);
        this.framePopover.close();
        this.detectChanges();
    }

    moveFrame(frame: ITime, time: number): void {
        const frames = this.frames;
        const snapPoint = this.timeline.getSnapPoints(time, 0);

        // Snap if the keyframe is moved by dragging
        if (this.mouseDownMoved) {
            if (snapPoint) {
                time = snapPoint.time;
            } else {
                time = decimal(time);
            }
        }

        const frameRelativeTime = clamp(time, 0, this.document.duration);

        // Remove the current selected frame so as to avoid jittering
        // Also remove duration when calculating where to place frame
        const filtered = frames.filter(f => f !== frame).map(f => ({ ...f, duration: 0 }));

        filtered.sort(sortByTime);

        const newTime = getClosestGap(
            {
                time: frameRelativeTime
            },
            filtered,
            this.document.duration,
            MIN_GIF_FRAME_DISTANCE
        );

        if (typeof newTime !== 'undefined') {
            frame.time = newTime;
        }

        // Sort frames by time as the export is following the index instead of the time when exporting.
        this.frames.sort(sortByTime);

        this.detectChanges();
    }

    setFrameDuration(frame: ITime, duration: number | undefined | null): void {
        // Note ui-number-input will return null when empty
        frame.duration = typeof duration === 'number' ? Math.max(0, duration) : undefined;
    }

    detectChanges = (): void => {
        this.updateAddIconDisabledState();
        this.changeDetector.detectChanges();
    };

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.mouseObservable?.destroy();
    }

    /**
     * For performance reasons, don't rerender componenent on seek.
     * Instead just toggle class from here
     */
    private updateAddIconDisabledState(): void {
        const element = this.addIcon?.nativeElement;
        if (element) {
            if (this.hasFrameAt(this.time) || !isTimeBetween(this.time, 0, this.document.duration)) {
                element.classList.add('disabled');
            } else {
                element.classList.remove('disabled');
            }
        }
    }
}

function removeItemInArray(array: any[], item: any): any[] {
    const index = array.indexOf(item);
    if (index > -1) {
        return array.slice(0, index).concat(array.slice(index + 1));
    }
    return array.slice();
}
