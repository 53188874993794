import { KeyValue } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    forwardRef,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges
} from '@angular/core';
import { UIButtonGroupOption } from '@bannerflow/ui';
import { animationDirectionValues, animationTemplates } from '@creative/animation-templates';
import {
    getInAnimationDuration,
    getOutAnimationDuration,
    IN_OUT_ANIMATION_GAP,
    MAX_ANIMATION_DIRECTION,
    MAX_ANIMATION_DISTANCE,
    MIN_ANIMATION_DIRECTION,
    MIN_ANIMATION_DISTANCE,
    MIN_ANIMATION_DURATION
} from '@creative/animation.utils';
import { IAnimator } from '@creative/animator.header';
import { timingFunctions } from '@creative/timing-functions';
import { IAnimationSetting, IAnimationSettings, IAnimationTemplate } from '@domain/animation';
import { OneOfElementDataNodes } from '@domain/nodes';
import { TimingFunctionKey } from '@domain/timing-functions';
import { transitionSettingPropertyToUnitMap } from '@domain/transition';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DesignViewComponent } from '../../design-view.component';
import { EditorEventService } from '../../services';
import { changeFilter, ElementChangeType } from '../../services/editor-event';
import { MutatorService } from '../../services/mutator.service';
import { createMixedProperty } from '../mixed-properties';

interface IMixedAnimationTemplate extends Omit<IAnimationTemplate, 'name' | 'timingFunction'> {
    duration: number;
    name?: string;
    timingFunction?: TimingFunctionKey;
}

@Component({
    selector: 'animation-properties',
    templateUrl: './animation-properties.component.html',
    styleUrls: ['./animation-properties.component.scss', '../common.scss'],
    host: { '[hidden]': '!animator' },
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnimationPropertiesComponent implements OnInit, OnChanges, OnDestroy {
    @Input() type: 'in' | 'out';
    @Input() animator: IAnimator;

    @Input() elements$: Observable<OneOfElementDataNodes[]>;

    elements: OneOfElementDataNodes[] = [];

    private unsubscribe$ = new Subject<void>();

    animationTemplates: IMixedAnimationTemplate[] = [];
    timingFunctions = { ...timingFunctions };
    angleOptions: UIButtonGroupOption[] = [];
    ElementChangeType = ElementChangeType;
    animationSettingPropertyToUnitMap = transitionSettingPropertyToUnitMap;
    tooltips = {
        in: 'The duration of the transition to this state from the default state',
        out: 'The duration of the transition from this state to the default state'
    };
    animationsMixed = false;
    animationDirection = createMixedProperty<IAnimationSettings['direction']>();
    minAnimationDirection = MIN_ANIMATION_DIRECTION;
    maxAnimationDirection = MAX_ANIMATION_DIRECTION;

    animationDistance = createMixedProperty<IAnimationSettings['distance']>();
    minAnimationDistance = MIN_ANIMATION_DISTANCE;
    maxAnimationDistance = MAX_ANIMATION_DISTANCE;

    animation$ = new BehaviorSubject<IMixedAnimationTemplate | undefined>(undefined);

    readonly minDuration = MIN_ANIMATION_DURATION;

    get maxDuration(): number {
        const animations = this.elements[0].animations;
        const oppositeDuration =
            this.type === 'in'
                ? getOutAnimationDuration(animations)
                : getInAnimationDuration(animations);
        return Math.max(
            this.minDuration,
            this.elements[0].duration - IN_OUT_ANIMATION_GAP - oppositeDuration
        );
    }

    constructor(
        @Inject(forwardRef(() => DesignViewComponent))
        private editor: DesignViewComponent,
        private editorEvent: EditorEventService,
        private mutatorService: MutatorService
    ) {}

    ngOnInit(): void {
        this.editorEvent.elements.immediateChange$
            .pipe(changeFilter({ explicitProperties: ['animations'] }), takeUntil(this.unsubscribe$))
            .subscribe(() => {
                if (this.elements?.length) {
                    this.updateProperties();
                }
            });

        this.elements$.pipe(takeUntil(this.unsubscribe$)).subscribe(elements => {
            this.elements = elements;
            if (this.elements?.length) {
                this.updateProperties();
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        // Refresh dropdown list of animations & direction options
        if (changes.type) {
            this.animationTemplates = animationTemplates
                .filter(t => t.type === this.type)
                .map(t => ({ ...t, duration: 0.4 }));

            this.updateAngleOptions();
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    addAnimation = (): void => {
        const animationTemplate = animationTemplates.filter(t => t.type === this.type);
        for (const element of this.elements) {
            this.mutatorService.applyAnimationTemplateOnElement(
                animationTemplate[0],
                element,
                0.4,
                ElementChangeType.Instant
            );
        }
        this.updateCanvas();
    };

    setAnimationName(animationTemplateId: string): void {
        const newAnimation = this.animationTemplates.find(
            animation => animation.id === animationTemplateId
        )!;
        for (const element of this.elements) {
            const duration = this.getDurationOfAnimation(element);
            this.mutatorService.applyAnimationTemplateOnElement(
                newAnimation as IAnimationTemplate,
                element,
                duration,
                ElementChangeType.Instant
            );
        }
    }

    removeAnimation(): void {
        this.animation$.next(undefined);
        for (const element of this.elements) {
            this.mutatorService.removeAnimationTypeOnElement(this.type, element);
        }

        this.updateCanvas();
    }

    setDuration(
        value: number,
        animation: IMixedAnimationTemplate,
        eventType: ElementChangeType = ElementChangeType.Skip
    ): void {
        const duration = Math.min(Math.max(this.minDuration, value), this.maxDuration);
        animation.duration = duration;

        for (const element of this.elements) {
            this.mutatorService.setDurationOnAnimationsOfType(element, this.type, duration);
            this.editorEvent.elements.change(element, { animations: element.animations }, eventType);
        }
        this.updateCanvas();
    }

    private getDurationOfAnimation(element: OneOfElementDataNodes): number {
        const animations = element.animations;
        const duration =
            this.type === 'in'
                ? getInAnimationDuration(animations)
                : getOutAnimationDuration(animations)!;
        return duration || 0.4;
    }

    private updateProperties(): void {
        const firstElementAnimation = this.elements[0].animations.find(
            ({ type }) => type === this.type
        );

        const firstElementDuration = this.getDurationOfAnimation(this.elements[0]);
        if (!firstElementAnimation) {
            this.animationsMixed = this.elements.some(
                element => !!element.animations.find(({ type }) => type === this.type)
            );
            this.animation$.next(undefined);
            return;
        }

        const firstElementAnimationTemplate = this.animationTemplates.find(
            t => t.id === firstElementAnimation.templateId
        )!;
        firstElementAnimationTemplate.duration = firstElementDuration;

        const animationSameCategory = this.elements.every(element => {
            const elementAnimation = element.animations.find(({ type }) => type === this.type);
            if (!elementAnimation) {
                return false;
            }
            const animationTemplate = this.animationTemplates.find(
                template => template.id === elementAnimation.templateId
            )!;
            const categorizedAnimationNames = this.getCategorizedAnimationNames(
                firstElementAnimationTemplate.name!
            );
            return categorizedAnimationNames.includes(animationTemplate.name!);
        });

        this.animationsMixed = !animationSameCategory;

        let animation: IMixedAnimationTemplate | undefined = firstElementAnimationTemplate;
        animation.settings = firstElementAnimation.settings;
        animation.timingFunction = firstElementAnimation.timingFunction;

        if (animationSameCategory) {
            for (const element of this.elements) {
                const elementAnimation = element.animations.find(({ type }) => type === this.type);
                if (!elementAnimation) {
                    continue;
                }

                if (animation) {
                    const templateAnimation = this.animationTemplates.find(
                        animationTemplate => animationTemplate.id === elementAnimation.templateId
                    )!;

                    const timingFunction =
                        animation.timingFunction === elementAnimation.timingFunction
                            ? elementAnimation.timingFunction
                            : undefined;

                    const name =
                        animation?.name === templateAnimation.name ? templateAnimation.name : undefined;

                    const animationDuration = this.getDurationOfAnimation(element);
                    const duration = animation?.duration === animationDuration ? animationDuration : 0;

                    this.compareAnimationSettings(animation.settings, elementAnimation.settings);

                    animation = {
                        id: templateAnimation.id,
                        name,
                        duration,
                        keyframes: templateAnimation.keyframes,
                        type: templateAnimation.type,
                        settings: animation.settings,
                        timingFunction
                    };
                }
            }
        }

        this.animation$.next(animation);
    }

    private compareAnimationSettings(
        firstAnimationSettings?: IAnimationSettings,
        elementAnimationSettings?: IAnimationSettings
    ): void {
        if (!firstAnimationSettings || !elementAnimationSettings) {
            return;
        }

        const directionMixed =
            firstAnimationSettings.direction?.value !== elementAnimationSettings.direction?.value;
        const distanceMixed =
            firstAnimationSettings.distance?.value !== elementAnimationSettings.distance?.value;

        this.animationDirection = createMixedProperty(firstAnimationSettings.direction, directionMixed);
        this.animationDistance = createMixedProperty(firstAnimationSettings.distance, distanceMixed);
    }

    private getCategorizedAnimationNames(animationName: string): string[] {
        switch (animationName) {
            case 'Blur':
            case 'Fade':
            case 'Scale':
                return ['Blur', 'Fade', 'Scale'];
            case 'Flip':
                return ['Flip'];
            case 'Descend':
            case 'Ascend':
                return ['Descend', 'Ascend'];
            case 'Slide':
                return ['Slide'];
            default:
                return [];
        }
    }

    private updateAngleOptions(): void {
        if (this.type) {
            this.angleOptions = [
                {
                    id: 'angle-left',
                    svgIcon: 'direction-left',
                    value: animationDirectionValues[this.type].left.toString()
                },
                {
                    id: 'angle-up',
                    svgIcon: 'direction-up',
                    value: animationDirectionValues[this.type].up.toString()
                },
                {
                    id: 'angle-right',
                    svgIcon: 'direction-right',
                    value: animationDirectionValues[this.type].right.toString()
                },
                {
                    id: 'angle-down',
                    svgIcon: 'direction-down',
                    value: animationDirectionValues[this.type].down.toString()
                }
            ];
        }
    }

    private updateCanvas(): void {
        this.animator.render_m();
        this.editor.workspace.gizmoDrawer.draw();
    }

    setSettingValue(keyValue: KeyValue<string, IAnimationSetting | undefined>, newValue: number): void {
        // keyvalue pipe doesn't resolve to keyof T
        const setting = keyValue as KeyValue<keyof IAnimationSettings, IAnimationSetting>;
        for (const element of this.elements) {
            const elementAnimation = element.animations.find(({ type }) => type === this.type);
            const currentAnimation = this.animation$.value;
            if (currentAnimation?.settings && elementAnimation?.settings) {
                currentAnimation.settings[setting.key] = {
                    ...setting.value,
                    value: newValue ?? 0
                };
                this.mutatorService.applyAnimationTemplateOnElement(
                    currentAnimation as IAnimationTemplate,
                    element,
                    currentAnimation.duration,
                    ElementChangeType.Instant
                );
            }
        }
    }

    setEasing(timingFunction: TimingFunctionKey): void {
        for (const element of this.elements) {
            const elementAnimation = element.animations.find(animation => animation.type === this.type);
            const animationTemplate = Object.assign(
                {},
                this.animationTemplates.find(template => template.id === elementAnimation?.templateId)
            )!;
            animationTemplate.timingFunction = timingFunction;
            if (animationTemplate.settings && elementAnimation?.settings) {
                animationTemplate.settings = elementAnimation.settings;
            }
            const duration = this.getDurationOfAnimation(element);
            this.mutatorService.applyAnimationTemplateOnElement(
                animationTemplate as IAnimationTemplate,
                element,
                duration,
                ElementChangeType.Instant
            );
        }
    }
}
