import { ICaretRange, ILineColumnPosition, ILineColumnPositionDirection } from '@domain/rich-text';
import { ITextSelection } from '@domain/rich-text/rich-text.selection.header';
import { ResolvedTextDirection } from '@domain/text';
import { cloneDeep } from '@studio/utils/clone';
import { CaretRange } from './rich-text.types';

export class TextSelection implements ITextSelection {
    private anchorIsBeforeFocus: boolean;
    private _anchor: ILineColumnPositionDirection;
    private _focus: ILineColumnPositionDirection;

    constructor(anchor: ILineColumnPositionDirection, focus: ILineColumnPositionDirection) {
        this._anchor = anchor;
        this._focus = focus;
        this.compareAnchorAndFocus();
    }

    /**
     * Returns true when no characters are selected (caret is placed inbetween 2 characters)
     */
    get isCollapsed(): boolean {
        return this.anchor.line === this._focus.line && this.anchor.column === this._focus.column;
    }

    getRangeLength(numberOfColumns: number[]): number {
        if (this.start.line === this.end.line) {
            return this.end.column - this.start.column;
        }

        let length = numberOfColumns[0] - this.start.column;
        for (let i = this.start.line + 1; i < this.end.line; i++) {
            length += numberOfColumns[i];
        }
        length += this.end.column;
        return length;
    }

    get dir(): ResolvedTextDirection {
        return this.start.dir;
    }

    get start(): ILineColumnPositionDirection {
        return this.anchorIsBeforeFocus ? this._anchor : this._focus;
    }

    set start(position: ILineColumnPositionDirection) {
        if (this.anchorIsBeforeFocus) {
            this._anchor = position;
        } else {
            this._focus = position;
        }
        this.compareAnchorAndFocus();
    }

    get end(): ILineColumnPositionDirection {
        return this.anchorIsBeforeFocus ? this._focus : this._anchor;
    }

    set end(position: ILineColumnPositionDirection) {
        if (this.anchorIsBeforeFocus) {
            this._focus = position;
        } else {
            this._anchor = position;
        }
        this.compareAnchorAndFocus();
    }

    get anchor(): ILineColumnPositionDirection {
        return this._anchor;
    }

    set anchor(position: ILineColumnPositionDirection) {
        this._anchor = position;
        this.compareAnchorAndFocus();
    }

    get focus(): ILineColumnPositionDirection {
        return this._focus;
    }

    set focus(position: ILineColumnPositionDirection) {
        this._focus = position;
        this.compareAnchorAndFocus();
    }

    get caretRange(): ICaretRange {
        return new CaretRange(this.start, this.end);
    }

    clone(): TextSelection {
        return new TextSelection(cloneDeep(this._anchor), cloneDeep(this._focus));
    }

    isEqual(textSelection: TextSelection): boolean {
        return (
            this._anchor.line === textSelection._anchor.line &&
            this._anchor.column === textSelection._anchor.column &&
            this._focus.line === textSelection._focus.line &&
            this._focus.column === textSelection._focus.column
        );
    }

    private compareAnchorAndFocus(): void {
        this.anchorIsBeforeFocus =
            compareCaretPositions(this._anchor, this._focus) === CompareResult.Before;
    }
}

const enum CompareResult {
    After = -1,
    Same = 0,
    Before = 1
}

function compareCaretPositions(first: ILineColumnPosition, second: ILineColumnPosition): CompareResult {
    if (first.line < second.line) {
        return CompareResult.Before;
    } else if (first.line > second.line) {
        return CompareResult.After;
    } else if (first.column < second.column) {
        return CompareResult.Before;
    }
    return CompareResult.Same;
}
