import { IPosition } from './dimension';

export enum ColorType {
    Solid = 'solid',
    LinearGradient = 'linear-gradient'
}

export interface IColor {
    /**
     * Value between 0 and 255
     */
    red: number;

    /**
     * Value between 0 and 255
     */
    green: number;

    /**
     * Value between 0 and 255
     */
    blue: number;
    hue: number;
    saturation: number;
    lightness: number;

    /**
     * Value between 0 and 100
     */
    alpha: number;

    type: ColorType;

    stops: IColorStop[];

    angle: number;

    start: IColorStop;

    end: IColorStop;

    copy: () => IColor;

    toJSON: () => string;

    toString: () => string;

    addColorStop: (color: IColor, offset: number, position?: IPosition) => void;

    setColor: (color: IColor) => IColor;
}

export interface IColorStop {
    /**
     * Color of stop
     */
    color: IColor;

    /**
     * Percentage (100 = end of element)
     */
    offset: number;

    /**
     * Position of point relative to the element ({ x: 0.5, y: 0.5 } = center).
     * Only applies to start and en position.
     */
    position?: IPosition;
}
