export const IdentityMatrix = [
    1, 0, 0, 0,
    0, 1, 0, 0,
    0, 0, 1, 0,
    0, 0, 0, 1
];

/**
 * Class to mimic the behaviour of transform and output a matrix3d
 * Useful resources on understanding matrix3d:
 * https://developer.mozilla.org/en-US/docs/Web/API/WebGL_API/Matrix_math_for_the_web
 * https://dev.opera.com/articles/understanding-the-css-transforms-matrix/
 * https://github.com/jlmakes/rematrix
 * https://drafts.csswg.org/css-transforms-2/#decomposing-a-3d-matrix
 * https://www.senocular.com/flash/tutorials/transformmatrix/
 */

const cos = Math.cos;
const sin = Math.sin;
const tan = Math.tan;
const sqrt = Math.sqrt;
const pow = Math.pow;

export class Matrix {

    private _matrix: number[];

    constructor(matrix?: string) {
        if (matrix) {
            this.parse_m(matrix);
        }
        else {
            // Set default matrix
            this._matrix = this._getIdentityMatrix();
        }
    }

    parse_m(matrixString: string): Matrix {
        const c = matrixString.split(/\s*[(),]\s*/).slice(1, -1);

        if (c.length === 6) {
            // 'matrix()' (3x2)
            this._matrix = [
                +c[0], +c[2], 0, +c[4],
                +c[1], +c[3], 0, +c[5],
                0, 0, 1, 0,
                0, 0, 0, 1
            ];
        }
        // matrix3d() (4x4)
        else if (c.length === 16) {
            this._matrix = [
                +c[0], +c[4], +c[8], +c[12],
                +c[1], +c[5], +c[9], +c[13],
                +c[2], +c[6], +c[10], +c[14],
                +c[3], +c[7], +c[11], +c[15]
            ];
        }
        // handle 'none' or invalid values.
        else {
            this._getIdentityMatrix();
        }
        return this;
    }

    /**
     * Get matrix as a CSS-transform
     */
    toCSS_m(): string {
        return `matrix3d(${this._matrix.join(',')})`;
    }

    /**
     * Multiply current matrix with another matrix
     * @param factorMatrix
     */
    multiply_m(factorMatrix: number[]): Matrix {
        this._matrix = this._multiplyMatrices(this._matrix, factorMatrix);

        return this;
    }

    /**
     * Rotate on the x,y and z axis. Values are in radians.
     * @param x
     * @param y
     * @param z
     * @returns
     */
    rotate_m(x = 0, y = 0, z = 0): Matrix {
        const sinX = sin(x);
        const cosX = cos(x);
        const sinY = sin(y);
        const cosY = cos(y);
        const sinZ = sin(z);
        const cosZ = cos(z);

        const rotationMatrixX = [
            1, 0, 0, 0,
            0, cosX, -sinX, 0,
            0, sinX, cosX, 0,
            0, 0, 0, 1
        ];

        const rotationMatrixY = [
            cosY, 0, sinY, 0,
            0, 1, 0, 0,
            -sinY, 0, cosY, 0,
            0, 0, 0, 1
        ];

        const rotationMatrixZ = [
            cosZ, sinZ, 0, 0,
            -sinZ, cosZ, 0, 0,
            0, 0, 1, 0,
            0, 0, 0, 1
        ];

        const m = this._multiplyMatrices(
            this._multiplyMatrices(
                rotationMatrixX,
                rotationMatrixY
            ),
            rotationMatrixZ
        );

        return this.multiply_m(m);
    }

    /**
     * Rotate around the x axis.
     * @param radians
     * @returns
     */
    rotateX_m(radians: number): Matrix {
        const s = sin(radians);
        const c = cos(radians);

        return this.multiply_m([
            1, 0, 0, 0,
            0, c, -s, 0,
            0, s, c, 0,
            0, 0, 0, 1
        ]);
    }

    /**
     * Rotate around the y axis
     * @param radians
     * @returns
     */
    rotateY_m(radians: number): Matrix {
        const s = sin(radians);
        const c = cos(radians);

        return this.multiply_m([
            c, 0, s, 0,
            0, 1, 0, 0,
            -s, 0, c, 0,
            0, 0, 0, 1
        ]);
    }

    /**
     * Rotate around the z axis
     * @param radians
     * @returns
     */
    rotateZ_m(radians: number): Matrix {
        const s = sin(radians);
        const c = cos(radians);

        return this.multiply_m([
            c, s, 0, 0,
            -s, c, 0, 0,
            0, 0, 1, 0,
            0, 0, 0, 1
        ]);
    }

    skew_m(x: number, y: number): Matrix {
        const tanX = tan(x);
        const tanY = tan(y);

        return this.multiply_m([
            1, tanX, 0, 0,
            tanY, 1, 0, 0,
            0, 0, 1, 0,
            0, 0, 0, 1
        ]);
    }

    translate_m(x: number, y: number, z = 0): Matrix {
        return this.multiply_m([
            1, 0, 0, 0,
            0, 1, 0, 0,
            0, 0, 1, 0,
            x, y, z, 1
        ]);
    }

    scale_m(x = 1, y = 1, z = 1): Matrix {
        return this.multiply_m([
            x, 0, 0, 0,
            0, y, 0, 0,
            0, 0, z, 0,
            0, 0, 0, 1
        ]);
    }

    getTranslateX_m(): number {
        return this._getValue(1, 4);
    }
    getTranslateY_m(): number {
        return this._getValue(2, 4);
    }

    /**
     *
     */
    getTranslateZ_m(): number {
        return this._getValue(3, 4);
    }

    getScaleX_m(): number {
        return this._getVectorLength(this._getValue(1, 1), this._getValue(1, 2), this._getValue(1, 3));
    }
    getScaleY_m(): number {
        return this._getVectorLength(this._getValue(2, 1), this._getValue(2, 2), this._getValue(2, 3));
    }

    getXValue_m(): number {
        return this._getValue(4, 1)
    }

    getYValue_m(): number {
        return this._getValue(4, 2)
    }

    getScaleXValue_m(): number {
        return this._getValue(1, 1)
    }

    getScaleYValue_m(): number {
        return this._getValue(2, 2)
    }

    perspective_m(distance: number): Matrix {
        const matrix = this._getIdentityMatrix();
        matrix[11] = -1 / distance;
        return this.multiply_m(matrix);
    }

    isMirrorX_m(): boolean {
        return this._getValue(1, 1) < 0;
    }

    isMirrorY_m(): boolean {
        return this._getValue(2, 2) < 0;
    }

    // TODO: Skew
    // getSkewX(): number {
    //     return -atan2(this.getValue(1, 2), this.getValue(1, 1));


    //     // var my_matrix = my_mc.transform.matrix;

    //     // var px = new flash.geom.Point(0, 1);
    //     // px = my_matrix.deltaTransformPoint(px);
    //     // var py = new flash.geom.Point(1, 0);
    //     // py = my_matrix.deltaTransformPoint(py);

    //     // return -atan2(this.getValue(1, 2), this.getValue(1, 1));

    //     // trace("x skew: " +((180/PI) * atan2(px.y, px.x) - 90));
    //     // trace("y skew: " +((180/PI) * atan2(py.y, py.x)));
    // }

    // getSkewY(): number {


    //     // return -atan2(this.getValue(1, 1), this.getValue(1, 2));


    //     // var my_matrix = my_mc.transform.matrix;

    //     // var px = new flash.geom.Point(0, 1);
    //     // px = my_matrix.deltaTransformPoint(px);
    //     // var py = new flash.geom.Point(1, 0);
    //     // py = my_matrix.deltaTransformPoint(py);

    //     // return -atan2(this.getValue(1, 2), this.getValue(1, 1));

    //     // trace("x skew: " +((180/PI) * atan2(px.y, px.x) - 90));
    //     // trace("y skew: " +((180/PI) * atan2(py.y, py.x)));
    // }

    // toFormattedString(matrix: number[] = this.matrix): string {
    //     let str = '';
    //     matrix.forEach((num, index) => {
    //         if (index > 0) {
    //             str += index % 4 === 0 ? '\n' : '\t';
    //         }
    //         str += `${ num.toFixed(2) },`;
    //     });
    //     return str;
    // }

    // print(matrix: number[] = this.matrix): void {
    //     console.log(this.toFormattedString(matrix));
    // }

    private _multiplyMatrixAndPoint(matrix: number[], point: number[]): number[] {

        // Give a simple variable name to each part of the matrix, a column and row number
        const c0r0 = matrix[0];
        const c1r0 = matrix[1];
        const c2r0 = matrix[2];
        const c3r0 = matrix[3];

        const c0r1 = matrix[4];
        const c1r1 = matrix[5];
        const c2r1 = matrix[6];
        const c3r1 = matrix[7];

        const c0r2 = matrix[8];
        const c1r2 = matrix[9];
        const c2r2 = matrix[10];
        const c3r2 = matrix[11];

        const c0r3 = matrix[12];
        const c1r3 = matrix[13];
        const c2r3 = matrix[14];
        const c3r3 = matrix[15];

        // Now set some simple names for the point
        const x = point[0] || 0;
        const y = point[1] || 0;
        const z = point[2] || 0;
        const w = point[3] || 0;

        // Multiply the point against each part of the 1st column, then add together
        const resultX = (x * c0r0) + (y * c0r1) + (z * c0r2) + (w * c0r3);

        // Multiply the point against each part of the 2nd column, then add together
        const resultY = (x * c1r0) + (y * c1r1) + (z * c1r2) + (w * c1r3);

        // Multiply the point against each part of the 3rd column, then add together
        const resultZ = (x * c2r0) + (y * c2r1) + (z * c2r2) + (w * c2r3);

        // Multiply the point against each part of the 4th column, then add together
        const resultW = (x * c3r0) + (y * c3r1) + (z * c3r2) + (w * c3r3);

        return [resultX, resultY, resultZ, resultW];
    }

    private _multiplyMatrices(matrixA: number[], matrixB: number[]): number[] {

        // A faster implementation of this function would not create
        // any new arrays. This creates arrays for code clarity.

        // Slice the second matrix up into rows
        const row0 = [matrixB[0], matrixB[1], matrixB[2], matrixB[3]];
        const row1 = [matrixB[4], matrixB[5], matrixB[6], matrixB[7]];
        const row2 = [matrixB[8], matrixB[9], matrixB[10], matrixB[11]];
        const row3 = [matrixB[12], matrixB[13], matrixB[14], matrixB[15]];

        // Multiply each row by the matrix
        const result0 = this._multiplyMatrixAndPoint(matrixA, row0);
        const result1 = this._multiplyMatrixAndPoint(matrixA, row1);
        const result2 = this._multiplyMatrixAndPoint(matrixA, row2);
        const result3 = this._multiplyMatrixAndPoint(matrixA, row3);

        // Turn the results back into a single matrix
        return [
            result0[0], result0[1], result0[2], result0[3],
            result1[0], result1[1], result1[2], result1[3],
            result2[0], result2[1], result2[2], result2[3],
            result3[0], result3[1], result3[2], result3[3],
        ];
    }

    private _getValue(col: number, row: number): number {
        return this._matrix[(row - 1) * 4 + (col - 1)];
    }

    private _getIdentityMatrix(): number[] {
        return IdentityMatrix.slice();
    }

    private _getVectorLength(x: number, y: number, z: number): number {
        return sqrt(pow(x, 2) + pow(y, 2) + pow(z, 2));
    }
}
