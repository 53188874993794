<ui-dropdown
    #dropdown
    type="menu"
    class="manage-menu"
    [width]="'150'"
    [minWidth]="150">
    @for (campaign of (campaigns$ | async) ?? []; track campaign.id) {
        <ui-dropdown-item
            class="add-to-campaign-item"
            [uiTooltip]="campaign.name"
            [uiTooltipDisabled]="!campaign.name"
            svgIcon="display-campaign"
            (click)="addToCampaign(campaign.id)">
            {{ campaign.name }}
        </ui-dropdown-item>
    }
</ui-dropdown>
