import { CommonModule } from '@angular/common';
import { Component, computed, inject, OnDestroy } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UIModule } from '@bannerflow/ui';
import { toFixedDecimal } from '@studio/utils/utils';
import { GenAIService } from '../state/gen-ai.service';

@Component({
    standalone: true,
    imports: [CommonModule, UIModule],
    selector: 'zoom-tooltip',
    templateUrl: './zoom-tooltip.component.html',
    styleUrls: ['./zoom-tooltip.component.scss']
})
export class ZoomTooltipComponent implements OnDestroy {
    private genAIService = inject(GenAIService);

    private zoomLevel = toSignal(this.genAIService.zoomLevel$);
    currentZoom = computed(() => toFixedDecimal(this.zoomLevel() ?? 100, 0));

    ngOnDestroy(): void {
        this.genAIService.setZoom(100);
    }

    zoomIn(): void {
        this.genAIService.zoomIn();
    }

    zoomOut(): void {
        this.genAIService.zoomOut();
    }
}
