import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
    ICreativesetShowcase,
    ICreativesetShowcaseCreateData,
    ICreativesetShowcasesResponse,
    IShowcaseCreativesetResponse
} from '@studio/domain/showcase';

export const creativeSetShowcaseActions = createActionGroup({
    source: 'Showcase',
    events: {
        'Load Showcase CreativeSet ': props<{ key: string }>(),
        'Load Showcase CreativeSet Success': props<IShowcaseCreativesetResponse>(),
        'Load Showcase CreativeSet Failure': props<{ error: unknown }>(),

        'Load CreativeSet Showcases': props<{ creativesetId: string }>(),
        'Load CreativeSet Showcases Success': props<{ response: ICreativesetShowcasesResponse }>(),
        'Load CreativeSet Showcases Failure': props<{ error: unknown }>(),

        'Create CreativeSet Showcase': props<{ showcase: ICreativesetShowcaseCreateData }>(),
        'Create CreativeSet Showcase Success': props<{ showcase: ICreativesetShowcase }>(),
        'Create CreativeSet Showcase Failure': props<{ error: unknown }>(),

        'Update CreativeSet Showcase': props<{
            changes: Partial<ICreativesetShowcase>;
            showcaseKey: string;
        }>(),
        'Update CreativeSet Showcase Success': props<{ showcase: ICreativesetShowcase }>(),
        'Update CreativeSet Showcase Failure': props<{ error: unknown }>(),

        'Delete CreativeSet Showcase': props<{ showcaseKey: string }>(),
        'Delete CreativeSet Showcase Success': props<{ showcaseKey: string }>(),
        'Delete CreativeSet Showcase Failure': props<{ error: unknown }>(),

        'Send CreativeSet Showcase Link': props<{ showcase: ICreativesetShowcase }>(),

        'Reset Created Showcase': emptyProps(),

        'Complete Translation': props<{ creativesetId: string; showcaseKey: string }>(),
        'Complete Translation Success': emptyProps(),
        'Complete Translation Failure': props<{ error: unknown }>()
    }
});
