<ng-container *permissions="'Default'">
    <div
        id="interaction-hamburger-menu"
        class="menu hidden-desktop-down"
        data-test-id="topbar-menu"
        *ngIf="target"
        [class.border]="border"
        [class.active]="menuOpen"
        [class.hidden-desktop-down]="isMobileShowcase$ | async"
        (dropdownOpened)="menuOpen = true"
        (dropdownClosed)="menuOpen = false"
        [uiDropdownTarget]="target">
        <ui-svg-icon icon="hamburger"></ui-svg-icon>
    </div>
</ng-container>
<div class="logo">
    <ui-logo [small]="true"></ui-logo>
</div>
