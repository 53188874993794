import { ITextSpan, IVersionProperty, IVersionedText } from '@domain/creativeset/version';
import { IFeedStep } from '@domain/feed';
import { ITextVariable, SpanType } from '@domain/text';
import Ajv from 'ajv/dist/jtd';
import {
    ensureEnumOrDefault,
    ensureNumberOrDefault,
    ensureStringOrDefault
} from '../serialization.utils';
import {
    ISerializedStyleMap,
    ISerializedVariableTextSpan,
    OneOfSerializedSpans,
    versionedTextSchema
} from './version-schemas';

const ajv = new Ajv();
const serializeText = ajv.compileSerializer(versionedTextSchema);

export function serializeVersionedText(versionProperty: IVersionProperty<IVersionedText>): string {
    return serializeVersionedTextValue(versionProperty.value);
}

export function serializeVersionedTextValue(versionedText: IVersionedText): string {
    const validText = {
        text: ensureStringOrDefault(versionedText.text),
        styles: versionedText.styles.map(serializeSpan)
    };

    return serializeText(validText);
}

function serializeSpan(span: ITextSpan): OneOfSerializedSpans {
    if (
        span.type !== SpanType.Word &&
        span.type !== SpanType.Space &&
        span.type !== SpanType.Newline &&
        span.type !== SpanType.Variable
    ) {
        throw new Error(`Invalid span type found`);
    }

    if (span.type !== SpanType.Variable) {
        return {
            length: span.length,
            position: span.position,
            type: span.type,
            styleIds: serializeStyleIds(span.styleIds)
        };
    }
    return serializeVariableSpan(span);
}

function serializeVariableSpan(span: ITextSpan): ISerializedVariableTextSpan {
    if (!span.variable) {
        throw new Error('Variable span without variable data');
    }
    const variable: ITextVariable = {
        id: ensureStringOrDefault(span.variable.id),
        path: ensureStringOrDefault(span.variable.path),
        fallback: ensureStringOrDefault(span.variable.fallback),
        type: 'text',
        step: {
            size: ensureNumberOrDefault(span.variable.step.size),
            start: ensureNumberOrDefault(span.variable.step.start),
            occurrence: ensureEnumOrDefault<IFeedStep['occurrence']>(span.variable.step.occurrence, [
                'loop',
                'none'
            ])
        },
        spanId: span.variable.spanId
    };
    return {
        variable,
        length: span.length,
        position: span.position,
        type: SpanType.Variable,
        styleIds: serializeStyleIds(span.styleIds)
    };
}

function serializeStyleIds(styleIds: ITextSpan['styleIds'] | undefined): ISerializedStyleMap[] {
    const serializedStyleIds: ISerializedStyleMap[] = [];
    for (const [documentId, styleId] of Object.entries(styleIds ?? {})) {
        serializedStyleIds.push({ documentId, styleId });
    }
    return serializedStyleIds.sort((a, b) => {
        if (a.documentId > b.documentId) {
            return 1;
        } else if (a.documentId < b.documentId) {
            return -1;
        } else if (a.styleId > b.styleId) {
            return 1;
        } else if (a.styleId < b.styleId) {
            return -1;
        }
        throw new Error('StyleId is not unique');
    });
}
