import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MediaLibraryState, MEDIA_LIBRARY_FEATURE_KEY } from './media-library.reducer';

export const getMediaLibraryState = createFeatureSelector<MediaLibraryState>(MEDIA_LIBRARY_FEATURE_KEY);

export const getIsOpen = createSelector(
    getMediaLibraryState,
    (state: MediaLibraryState) => state.isOpen
);

export const getIsPinned = createSelector(
    getMediaLibraryState,
    (state: MediaLibraryState) => state.isPinned
);

export const getIsDialogOpen = createSelector(
    getMediaLibraryState,
    (state: MediaLibraryState) => state.isDialogOpen
);

export const getIsEditingElement = createSelector(
    getMediaLibraryState,
    (state: MediaLibraryState) => state.isEditingElement
);

export const getKind = createSelector(getMediaLibraryState, (state: MediaLibraryState) => state.kind);

export const getViewMode = createSelector(
    getMediaLibraryState,
    (state: MediaLibraryState) => state.viewMode
);

export const getSelectedFeedId = createSelector(
    getMediaLibraryState,
    (state: MediaLibraryState) => state.selectedFeedId ?? 'none'
);

export const search = createSelector(
    getMediaLibraryState,
    (state: MediaLibraryState) => state.searchQuery
);

// Async
export const getLoaded = createSelector(getMediaLibraryState, state => state.loaded);

export const getError = createSelector(getMediaLibraryState, state => state.error);
