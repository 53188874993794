import { zodOptional } from '@zod/zod';
import { z } from 'zod';

export const ZCreativeSize = z.object({
    id: z.string(),
    width: z.number(),
    height: z.number(),
    name: zodOptional(z.string())
});

export type CreativeSize = z.infer<typeof ZCreativeSize>;

export const ZNewCreativeSize = ZCreativeSize.omit({ id: true });

export const ZCreativeSizeDto = ZCreativeSize.extend({});

export type CreateSizesInCreativesetVariables = {
    creativesetId: string;
    sizes: Omit<CreativeSize, 'id'>[];
};

export type CreativeSizeNameUpdateResult = {
    renameSizeInCreativeset: {
        sizes: CreativeSize[];
        id: string;
    };
};

export type CreativeSizeNameUpdateVariables = {
    creativesetId: string;
    sizeId: string;
    name: string;
};
