import { isImageNode, isVideoNode } from '@creative/nodes/helpers';
import { IElementProperty } from '@domain/creativeset';
import { AssetReference, OneOfElementAssets } from '@domain/creativeset/element-asset';
import { FeededReference } from '@domain/feed';
import { IMediaElementDataNode, OneOfElementDataNodes } from '@domain/nodes';

export function getImageDimensions(src: string): Promise<{ width: number; height: number }> {
    return new Promise<{ width: number; height: number }>(resolve => {
        const img = new Image();

        img.onload = (): void => {
            resolve({ width: img.naturalWidth, height: img.naturalHeight });
        };
        img.src = src;
    });
}

export function getVideoMetadata(src: string): Promise<{ video: HTMLVideoElement; thumbnail: string }> {
    return new Promise(async resolve => {
        const videoUrl = src;
        const video = document.createElement('video');
        const canvas = document.createElement('canvas');

        document.body.appendChild(canvas);

        const videoBlob = await fetch(videoUrl).then(r => r.blob());
        const videoObjectUrl = URL.createObjectURL(videoBlob);

        video.addEventListener('loadeddata', async function () {
            video.currentTime = 1;
        });

        video.addEventListener('seeked', async () => {
            const context = canvas.getContext('2d')!;
            context.drawImage(video, 0, 0, canvas.width, canvas.height);

            const dataUrl = canvas.toDataURL();
            resolve({
                thumbnail: dataUrl,
                video
            });
        });

        video.src = videoObjectUrl;
    });
}

export const getAssetOfMediaElement = (
    element: IMediaElementDataNode
): OneOfElementAssets | undefined => {
    if (isVideoNode(element)) {
        return element.videoAsset;
    } else if (isImageNode(element)) {
        return element.imageAsset;
    }
};

export const isMediaElementNode = (
    element: OneOfElementDataNodes
): element is IMediaElementDataNode => {
    return isImageNode(element) || isVideoNode(element);
};

export const isElementMediaAssetLoading = (element: IMediaElementDataNode): boolean => {
    return !!getAssetOfMediaElement(element)?.__loading;
};

export const hasImageReference = (property: IElementProperty): boolean => {
    return property.name === AssetReference.Image;
};

export const hasFeededImageReference = (property: IElementProperty): boolean => {
    return property.name === FeededReference.Image;
};

export const hasVideoReference = (property: IElementProperty): boolean => {
    return property.name === AssetReference.Video;
};

export const hasMediaReference = (property: IElementProperty): boolean => {
    return hasImageReference(property) || hasVideoReference(property);
};

export const isHeavyVideo = (fileSize?: number): boolean => {
    return fileSize ? fileSize > 1024 * 1000 * 2 && !isTooHeavyVideo(fileSize, 50) : false;
};

export const isTooHeavyVideo = (fileSize: number, maxLimit: number): boolean => {
    return fileSize ? fileSize > 1024 * 1000 * maxLimit : false;
};

export const isImageFile = (file: File): boolean => {
    return file.type.includes('image');
};

export const isVideoFile = (file: File): boolean => {
    return file.type.includes('video');
};

export const isMP4File = (file: File): boolean => {
    return file.type === 'video/mp4';
};

export const isSVGFile = (file: Blob): boolean => {
    return file.type === 'image/svg+xml';
};

export const filterSVGFiles = (files: File[]): File[] => {
    return files.filter(isSVGFile);
};
