import { Injectable } from '@angular/core';
import { Logger } from '@bannerflow/sentinel-logger';
import { ICreativeDataNode } from '@domain/nodes';
import {
    ICreativeSocialGuide,
    ISocialPlacement,
    SocialNetworkType,
    SocialPlacement
} from '@domain/social';
import { SOCIAL_NETWORKS } from '@studio/domain/constants/social';
import { ISocialNetwork } from '@studio/domain/social';
import { getAspectRatio } from '@studio/utils/geom';
import { BehaviorSubject, tap } from 'rxjs';

@Injectable()
export class SocialGuideService {
    private _socialNetworks: ISocialNetwork[] = SOCIAL_NETWORKS;
    private _socialNetworks$ = new BehaviorSubject(this._socialNetworks);
    socialNetworks$ = this._socialNetworks$
        .asObservable()
        .pipe(tap(networks => (this._socialNetworks = networks)));

    private _isSocialCreative$ = new BehaviorSubject(false);
    isSocialCreative$ = this._isSocialCreative$.asObservable();

    private _selectedNetwork$ = new BehaviorSubject(this._socialNetworks[0]);
    selectedNetwork$ = this._selectedNetwork$.asObservable();

    private _selectedPlacement$ = new BehaviorSubject(this._socialNetworks[0].placements[0]);
    selectedPlacement$ = this._selectedPlacement$.asObservable();

    private _showOverlay$ = new BehaviorSubject(false);
    showOverlay$ = this._showOverlay$.asObservable();

    private _showGuidelines$ = new BehaviorSubject(false);
    showGuidelines$ = this._showGuidelines$.asObservable();

    private logger = new Logger('SocialGuideService');

    initSocialCreative(creativeDocument: ICreativeDataNode): void {
        const { width, height } = creativeDocument;
        this._socialNetworks = this.calculateSocialCreative(width, height);
        this._socialNetworks$.next(this._socialNetworks);
        const isSocialCreative = this._socialNetworks.length > 0;

        if (!isSocialCreative) {
            return;
        }

        const socialGuide = this.getSocialGuide(creativeDocument);
        const selectedNetwork = this.getNetworkByType(socialGuide.network);
        const selectedPlacement = this.getPlacementByType(selectedNetwork, socialGuide.placement);

        this.logger.verbose(
            `Social Size detected. Network: ${socialGuide.network}, Placement: ${socialGuide.placement}, Overlay: ${socialGuide.overlay}, guidelines: ${socialGuide.guidelines}`
        );

        this.selectNetwork(selectedNetwork);
        this.selectPlacement(selectedPlacement);

        this.setShowOverlay(socialGuide.overlay);
        this.setShowGuidelines(socialGuide.guidelines);

        this.setIsSocialCreative(isSocialCreative);
    }

    private calculateSocialCreative(width: number, height: number): ISocialNetwork[] {
        const ratio = getAspectRatio(width, height);
        return SOCIAL_NETWORKS.filter(network => network.ratios.includes(ratio));
    }

    private getSocialGuide(creativeDocument: ICreativeDataNode): ICreativeSocialGuide {
        return (
            creativeDocument.socialGuide || {
                guidelines: false,
                network: this._socialNetworks[0].type,
                overlay: false,
                placement: this._socialNetworks[0].placements[0].type
            }
        );
    }

    private getNetworkByType(type: SocialNetworkType): ISocialNetwork {
        return this._socialNetworks.find(network => network.type === type) || this._socialNetworks[0];
    }

    private getPlacementByType(network: ISocialNetwork, type: SocialPlacement): ISocialPlacement {
        return (
            network.placements.find(placement => placement.type === type) ||
            this._socialNetworks[0].placements[0]
        );
    }

    setIsSocialCreative(isSocialCreative: boolean): void {
        this._isSocialCreative$.next(isSocialCreative);
    }

    selectNetwork(network: ISocialNetwork): void {
        this._selectedNetwork$.next(network);
        this.selectPlacement(network.placements[0]);
    }

    selectPlacement(placement: ISocialPlacement): void {
        this._selectedPlacement$.next(placement);
    }

    setShowOverlay(show: boolean): void {
        this._showOverlay$.next(show);
    }

    setShowGuidelines(show: boolean): void {
        this._showGuidelines$.next(show);
    }
}
