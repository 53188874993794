<div
    *ngIf="folderName"
    [attr.data-folderId]="folderId"
    class="ml-folders-folder prevent-ml-close"
    [class.ml-folders-folder--isEditingName]="isEditingName"
    (click)="openFolder()">
    <ui-svg-icon
        class="ml-folders-folder__icon"
        icon="folder"></ui-svg-icon>
    <div
        class="ml-folders-folder__name"
        *ngIf="isEditingName; else viewMode">
        <ui-input
            #nameInput
            [(ngModel)]="folderName"
            class="ml-folders-folder__name-input"
            [minlength]="1"
            (click)="$event.stopPropagation()"
            (dblclick)="$event.stopPropagation()"
            (mousedown)="$event.stopPropagation()"
            (blur)="updateFolderName()"
            [selectTextOnFocus]="true"
            (keyup.enter)="nameInput.blurInput()"
            autocomplete="off"></ui-input>
    </div>

    <ng-template #viewMode>
        <div
            class="ml-folders-folder__name"
            truncateSpan
            [spanText]="folderName"></div>
    </ng-template>

    <div
        class="ml-folders-folder__control prevent-ml-close"
        [uiDropdownTarget]="folderMenu"
        (click)="$event.stopPropagation()">
        <ui-svg-icon icon="kebab"></ui-svg-icon>
    </div>
</div>

<ui-dropdown #folderMenu>
    <ui-dropdown-item
        (click)="startEditingName()"
        svgIcon="edit"
        >Rename</ui-dropdown-item
    >
    <ui-dropdown-item
        [disabled]="mediaLibraryKind !== 'any'"
        (itemClicked)="deleteFolder()"
        svgIcon="delete"
        uiTooltip="It is only possible to delete folders in the Brand Library"
        [uiTooltipDisabled]="mediaLibraryKind === 'any'"
        >Delete
    </ui-dropdown-item>
</ui-dropdown>
