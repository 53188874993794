<div class="content">
    <div class="logo">
        <ui-logo [small]="true"></ui-logo>
    </div>
    <div class="title">
        <h1>We are sorry.</h1>
    </div>
    <div class="text">
        <p data-test-id="error-message">{{ errorMessage }}</p>
        <a
            *ngIf="linkToCreativeset"
            class="link-to-creativeset"
            [href]="linkToCreativeset"
            data-test-id="link-to-creativeset">
            Go to Creative set
        </a>
        <p class="second-ph">
            If you need any assistance,<br />please contact
            <a href="mailto:support@bannerflow.com">support&#64;bannerflow.com</a>
        </p>
        <p class="third-ph">The Bannerflow Team</p>
    </div>
</div>
