import { ShadowDto, TextShadowDto } from './api/generated/sapi';
import { IColor } from './color';
import { ISize } from './dimension';

export type AppearenceStyles = Partial<IAppearence & ISize>;

export interface IAppearence {
    fill: IColor;
    fillSize: string;
    radius: IRadius;
    opacity: number;
    border: IBorder;
    shadows: IShadow[];
}

export enum RadiusType {
    Joint = 'joint',
    Separate = 'separate'
}

export interface IRadius {
    type: RadiusType;
    topLeft: number;
    topRight: number;
    bottomRight: number;
    bottomLeft: number;
}

export type BorderStyle = 'solid' | 'dotted' | 'dashed';

export interface IBorder<T = IColor> {
    thickness: number;
    color: T;
    style: BorderStyle;
}

export interface IShadow<T = IColor> {
    offsetX: number;
    offsetY: number;
    blur: number;
    spread: number;
    color: T;
}

export type SerializedShadow = [
    `${ShadowDto['offsetX']}`,
    `${ShadowDto['offsetY']}`,
    `${ShadowDto['blur']}`,
    `${ShadowDto['spread']}`,
    `${ShadowDto['color']}`
];

export interface ITextShadow {
    offsetX: number;
    offsetY: number;
    blur: number;
    color: IColor;
}

export type SerializedTextShadow = [
    `${TextShadowDto['offsetX']}`,
    `${TextShadowDto['offsetY']}`,
    `${TextShadowDto['blur']}`,
    `${TextShadowDto['color']}`
];

export enum PaddingType {
    Joint = 'joint',
    Separate = 'separate'
}
export interface IPadding {
    top: number;
    right: number;
    bottom: number;
    left: number;
}

export enum FilterType {
    Blur = 'blur',
    Contrast = 'contrast',
    Invert = 'invert',
    Grayscale = 'grayscale',
    Saturation = 'saturate',
    Sepia = 'sepia'
}

export enum FilterUnit {
    Length = 'px',
    Percentage = '%'
}

export interface IFilter {
    value?: number;
}
export type IFilterMap = Partial<Record<FilterType, IFilter | undefined>>;

export interface FilterInfo {
    id: FilterType;
    name: string;
    // ! Do not change this to be Icon!
    icon:
        | 'filter-blur'
        | 'filter-sepia'
        | 'filter-invert'
        | 'filter-saturate'
        | 'filter-grayscale'
        | 'filter-contrast';
    unit: FilterUnit;
    max: number;
    min: number;
    default?: number;
}

export const FILTER_MAP: Record<FilterType, Omit<FilterInfo, 'id'>> = {
    [FilterType.Blur]: { name: 'Blur', icon: 'filter-blur', unit: FilterUnit.Length, min: 0, max: 100 },
    [FilterType.Contrast]: {
        name: 'Contrast',
        icon: 'filter-contrast',
        unit: FilterUnit.Percentage,
        min: 0,
        max: 200,
        default: 100
    },
    [FilterType.Invert]: {
        name: 'Invert',
        icon: 'filter-invert',
        unit: FilterUnit.Percentage,
        min: 0,
        max: 100
    },
    [FilterType.Grayscale]: {
        name: 'Grayscale',
        icon: 'filter-grayscale',
        unit: FilterUnit.Percentage,
        min: 0,
        max: 100
    },
    [FilterType.Saturation]: {
        name: 'Saturation',
        icon: 'filter-saturate',
        unit: FilterUnit.Percentage,
        min: 0,
        max: 200,
        default: 100
    },
    [FilterType.Sepia]: {
        name: 'Sepia',
        icon: 'filter-sepia',
        unit: FilterUnit.Percentage,
        min: 0,
        max: 100
    }
};

type FiltersArray = ReadonlyArray<[FilterType, (typeof FILTER_MAP)[keyof typeof FILTER_MAP]]>;
// Construct an array of FilterInfos with added ids for easy lookup and rendering.
export const FILTER_LIST = (Object.entries(FILTER_MAP) as FiltersArray).reduce(
    (memo: ReadonlyArray<FilterInfo>, [key, filter]) => [...memo, { id: key, ...filter }],
    []
);
