import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { UIDialogComponent, UIModule } from '@bannerflow/ui';
import { ICampaignStatus } from '@domain/campaign';
import { ICreative } from '@domain/creativeset/creative/creative';
import { NavigationService } from '../../../shared/services/navigation.service';

@Component({
    standalone: true,
    imports: [CommonModule, UIModule],
    selector: 'publish-changes-dialog',
    templateUrl: './publish-changes-dialog.component.html',
    styleUrls: ['./publish-changes-dialog.component.scss']
})
export class PublishChangesDialogComponent {
    private navigationService = inject(NavigationService);
    private dialog = inject(UIDialogComponent);

    campaigns: ICampaignStatus[] = [];
    creatives: ICreative[];

    constructor() {
        this.dialog.config.data.confirmed = false;
        this.campaigns = this.dialog.config.data.campaigns as ICampaignStatus[];
        this.creatives = this.dialog.config.data.creatives as ICreative[];
    }

    cancel(): void {
        this.dialog.dialogRef.close();
    }

    openCampaign(id: string): void {
        this.navigationService.openCampaign(id);
    }

    publishChanges(): void {
        this.dialog.config.data.confirmed = true;
        this.dialog.dialogRef.close();
    }
}
