import { get } from '@studio/utils/ad/http';

export const E_PRIVACY_COUNTRIES = [
    'AT',
    'BE',
    'BG',
    'HR',
    'CH',
    'CY',
    'CZ',
    'DK',
    'EE',
    'FI',
    'FR',
    'DE',
    'GR',
    'HU',
    'IE',
    'IT',
    'LV',
    'LT',
    'LU',
    'MT',
    'NL',
    'PL',
    'PT',
    'RO',
    'SK',
    'SI',
    'ES',
    'SE',
    'IS',
    'LI',
    'NO',
    'GB',
    'RU',
    'US'
];
const COUNTRY_CHECK_URL = 'https://c.bannerflow.net/cdn-cgi/trace';

export function isEPrivacyCountry(cb: (isEPrivacyCountry: boolean) => void): void {
    get(COUNTRY_CHECK_URL, (response: string) => {
        const countryCode = extractCountryCode(response);
        const isEPC = E_PRIVACY_COUNTRIES.includes(countryCode);
        cb(isEPC);
    });
}

function extractCountryCode(apiResponse: string): string {
    const lines = apiResponse.split('\n');
    for (const line of lines) {
        if (line.startsWith('loc=')) {
            return line.replace('loc=', '');
        }
    }
    return '';
}
