@if (group) {
    @if (!selectedElement && group.elements.length > 1) {
        <group-input-header
            [groupLength]="group.elements.length"
            (toggleExpand)="toggleExpand($event)"></group-input-header>
    }
    @for (value of values$ | async; track value.renderId; let index = $index) {
        @if (selectedElement ?? group.elements[index]; as groupElement) {
            <div class="versioned-content__wrapper">
                <ng-template #leadingIcon>
                    @if (!selectedElement && group.elements.length > 1 && !expanded) {
                        <group-option-number [number]="group.elements.length"></group-option-number>
                    } @else {
                        <ui-svg-icon [icon]="(groupElement | iconForElement) ?? ''"></ui-svg-icon>
                    }
                </ng-template>
                <mat-input
                    [attr.data-test-id]="'version-input-' + group.id"
                    [value]="value.text"
                    [type]="'textarea'"
                    [label]="version | versionToLabel: (expanded ? groupElement.name : undefined)"
                    [leadingIcon]="leadingIcon"
                    [disabled]="value.disabled"
                    [highlightedAreas]="value.highlightedAreas$ | async"
                    (valueChange)="onValueChange($event, index, value)"
                    (undoChange)="onUndoChange()"
                    (redoChange)="onRedoChange()"
                    (inputFocus)="highlightElement(groupElement.id, version.id)"
                    (inputBlur)="blurElement()"
                    (inputMouseOver)="highlightElement(groupElement.id, version.id)"
                    (inputMouseOut)="blurElement()"></mat-input>

                @if (!(inShowcaseMode$ | async) && value.showFeedPills) {
                    <feed-pills
                        [elements]="group.elements"
                        [element]="groupElement"
                        [version]="version"
                        [expanded]="expanded"
                        [versionProperty]="value.versionProperty"
                        [visibleFeedSteps]="value.visibleFeedSteps"
                        (feedMouseEnter)="onFeedHovered(groupElement.id, $event)"
                        (feedMouseLeave)="onFeedUnhovered()"></feed-pills>
                }
            </div>
        }
    }
}
