import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { StudioUISliderComponent } from '../../../../shared/components/studio-ui-slider/studio-ui-slider.component';
import { UIDialogComponent, UIModule } from '@bannerflow/ui';
import { ICreative } from '@domain/creativeset/creative';
import {
    ExportType,
    GifFrameLimitMode,
    IExportSettings
} from '@domain/creativeset/creative/export-creative';

@Component({
    standalone: true,
    imports: [CommonModule, UIModule, StudioUISliderComponent],
    selector: 'export-dialog',
    templateUrl: 'export-dialog.component.html',
    styleUrls: ['export-dialog.component.scss']
})
export class ExportDialogComponent {
    type: ExportType;
    limitMode: GifFrameLimitMode;
    quality = 90;
    optimizeGifColors = true;
    fileSizeLimit = 300;
    frameLimit = 15;
    loopsSettings: 'Forever' | 'Custom' = 'Forever';
    loops?: number;
    creatives: ICreative[];
    videoFrameRate = 30;
    manualGifFramesEnabled: boolean;
    manualGifFrameLabel: string;
    private closePromise: Promise<IExportSettings | undefined>;
    private resolveClosePromise: (value?: IExportSettings | undefined) => void;
    private brandId: string;

    constructor(private dialog: UIDialogComponent) {
        const data = this.dialog.config.data;
        const creatives = data.creatives as ICreative[];

        if (!creatives?.length || typeof data.type !== 'string' || typeof data.brandId !== 'string') {
            throw new Error('Could not initiate export dialog');
        }

        this.type = data.type;
        this.brandId = data.brandId;
        this.quality = this.type === 'gif' ? 100 : 90;
        this.creatives = creatives;
        this.manualGifFramesEnabled = this.getCreativeWithManualGifFrames(creatives).length > 0;
        this.manualGifFrameLabel = this.getManualGifFramesLabel(creatives);
        this.limitMode = this.getDefaultLimitMode(creatives);
    }

    get gifColors(): number {
        return qualityToGifColors(this.quality);
    }

    set gifColors(colors: number) {
        this.quality = gifColorsToQuality(colors);
    }

    get buttonLabel(): string {
        const creatives = this.getCreativesToExport();
        const plural = creatives.length > 1 ? `s (${creatives.length})` : '';
        return `Export file${plural}`;
    }

    initiate(): Promise<IExportSettings | undefined> {
        if (!this.closePromise) {
            this.closePromise = new Promise(resolve => (this.resolveClosePromise = resolve));
        }
        return this.closePromise;
    }

    cancel(): void {
        this.resolveClosePromise();
    }

    export(): void {
        const isGif = this.type === 'gif';

        const fileSizeLimit = isGif && this.limitMode === 'file-size' ? this.fileSizeLimit : undefined;

        const frameLimit = isGif && this.limitMode === 'frame-count' ? this.frameLimit : undefined;

        const frameRate = this.type === 'mp4' ? this.videoFrameRate : undefined;

        const loops = isGif && this.loops ? this.loops : 0;

        let quality = this.quality;
        if (isGif) {
            quality = this.optimizeGifColors ? this.gifColors : 0;
        }

        this.resolveClosePromise({
            quality,
            type: this.type,
            gifFrameLimitMode: this.limitMode,
            fileSizeLimit,
            frameLimit,
            frameRate,
            loops,
            creatives: this.getCreativesToExport(),
            brandId: this.brandId
        });
    }

    private getCreativesToExport(): ICreative[] {
        if (this.type === 'gif' && this.limitMode === 'manual') {
            return this.getCreativeWithManualGifFrames(this.creatives);
        }
        return this.creatives;
    }

    private getCreativeWithManualGifFrames(creatives: ICreative[]): ICreative[] {
        return creatives.filter(creative => creative.design?.document.gifExport.frames.length);
    }

    private getManualGifFramesLabel(creatives: ICreative[]): string {
        const count = this.getCreativeWithManualGifFrames(creatives).length;
        const missing = creatives.length - count;
        let extra = '';

        if (!count) {
            extra = 'No creative with manual frames selected';
        } else if (missing > 0) {
            const pluralText = missing === 1 ? 'One creative is' : `${missing} creatives are`;
            extra = `${pluralText} missing manual frames`;
        }

        return `Use manual GIF frames${extra ? ` (${extra})` : ''}`;
    }

    private getDefaultLimitMode(creatives: ICreative[]): GifFrameLimitMode {
        if (this.getCreativeWithManualGifFrames(creatives).length) {
            return 'manual';
        }
        return 'frame-count';
    }
}

/**
 * Take a quilty from 0 to 100 and convert to an mount of colors of a GIF
 * @param quality
 */
export function qualityToGifColors(quality: number): number {
    if (typeof quality !== 'number' || quality > 100 || quality < 0) {
        throw new Error(
            `Can't convert '${quality}' to GifColors, value need to be a number between 0 and 100`
        );
    }
    const pow = Math.round(quality / (100 / 8));
    return Math.pow(2, pow);
}

export function gifColorsToQuality(colors: number): number {
    if (typeof colors !== 'number' || colors > 256 || colors < 0) {
        throw new Error(
            `Can't convert '${colors}' to GifColors, value need to be a number between 0 and 256`
        );
    }
    const pow = Math.round(Math.log2(colors));
    return pow * (100 / 8);
}
