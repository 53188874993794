import { CommonModule } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    effect,
    EventEmitter,
    inject,
    input,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UIModule } from '@bannerflow/ui';
import { Color } from '@creative/color';
import { toHEX } from '@creative/color.utils';
import { IColor } from '@domain/color';
import { ISelectedColor } from '@studio/domain/components/color-picker/color.types';
import { ReplaySubject, Subject } from 'rxjs';
import { IAuxColorPalette, IColorPalette } from './color-palette.interface';

@Component({
    standalone: true,
    imports: [UIModule, CommonModule],
    selector: 'color-palette',
    templateUrl: 'color-palette.component.html',
    styleUrls: ['color-palette.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorPaletteComponent implements OnInit, AfterViewInit {
    private changeDetectorRef = inject(ChangeDetectorRef);
    private destroyedRef = inject(DestroyRef);

    @Input() colorMixed = false;
    _palettes: IColorPalette[] = [];
    @Input() set palettes(palettes: IColorPalette[]) {
        this.setPalettes(palettes);
    }

    _auxPalettes: IAuxColorPalette[] = [];
    @Input() set auxPalettes(palettes: IColorPalette[] | null) {
        if (palettes) {
            this.setAuxPalettes(palettes);
        }
    }

    get auxPalettes(): IAuxColorPalette[] {
        return this._auxPalettes;
    }

    /**
     * Color to edit
     */
    @Input() get value(): Color {
        return this._color;
    }
    set value(value: Color) {
        if (value) {
            this._color = value;
            this.selectedColorString = value.toString();
            this.detectChanges();
        }
    }

    @Input() selectedColor$?: ReplaySubject<ISelectedColor>;
    selectedColor = input<IColor | undefined>();

    @Input() disabled = false;

    @Output() valueChange = new EventEmitter<Color>();

    @Output() colorHover = new EventEmitter<Color>();
    @Output() colorLeave = new EventEmitter<Color>();

    @Output() editStart = new EventEmitter<void>();
    @Output() editEnd = new EventEmitter<boolean>();

    selectedColorString: string;
    swatches: Color[] = [];
    tooltips: string[] = [];

    auxPaletteChanged$ = new Subject<void>();

    private _color: Color;

    constructor() {
        effect(() => {
            const selectedColor = this.selectedColor();
            if (!selectedColor) {
                return;
            }
            this._color = selectedColor;
            this.selectedColorString = selectedColor.toString();
            this.detectChanges();
        });
    }

    ngOnInit(): void {
        this.generateSwatches();
    }

    ngAfterViewInit(): void {
        if (this.selectedColor$) {
            this.selectedColor$.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe(({ color }) => {
                if (color) {
                    this._color = color;
                    this.selectedColorString = color.toString();
                    this.detectChanges();
                }
            });
        }
    }

    /**
     * Select color
     * @param color
     */
    selectColor(color: Color): void {
        if (this.disabled) {
            return;
        }
        this.editStart.emit();

        // No color set, set new
        if (!this.value) {
            this.value = new Color(color);
        }
        // Modify current color without changing reference
        else if (this.value.toString() !== color.toString()) {
            this.value = this.value.setColor(color);
        }
        this.valueChange.emit(this.value);
        this.selectedColor$?.next({ color: this.value, sender: 2 });
        this.editEnd.emit();
    }

    /**
     * Add a palette to be shown
     * @param palette
     */
    add(palette: IColorPalette): void {
        this._palettes.push(palette);
        this.generateSwatches();
        this.detectChanges();
    }

    clear(): void {
        this._palettes = [];
        this.swatches = [];
        this.detectChanges();
    }

    setAuxPalettes(palettes: IColorPalette[]): void {
        if (!palettes.length) {
            return;
        }

        this._auxPalettes = palettes.map(palette => ({ ...palette, selected: true }));
        this._auxPalettes.forEach(p => this._palettes.unshift(p));
        this.generateSwatches();
        this.detectChanges();
    }

    useAuxPalette(palette: IAuxColorPalette): void {
        palette.selected = !palette.selected;
        this.generateSwatches();
        this.detectChanges();
    }

    setPalettes(palette: IColorPalette | IColorPalette[]): void {
        this._palettes = palette instanceof Array ? palette.slice() : [palette];
        this.generateSwatches();
        this.detectChanges();
    }

    detectChanges(): void {
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }
    }

    onMouseLeave(color: Color): void {
        if (this.disabled) {
            return;
        }
        this.colorLeave.emit(color);
    }

    onMouseEnter(color: Color): void {
        if (this.disabled) {
            return;
        }
        this.colorHover.emit(color);
    }

    private generateSwatches(): void {
        this.swatches = [];
        this.tooltips = [];
        this._palettes.forEach(p => {
            const auxPalette = p as IAuxColorPalette;
            // filter aut aux palettes (e.g. brand colors) that are not selected
            if (auxPalette.selected !== undefined && !auxPalette.selected) {
                return;
            }

            this.tooltips.push(...p.swatch.map(c => `${p.name}: ${toHEX(c)}`), '');
            this.swatches = this.swatches.concat(p.swatch);
            this.swatches.push(null as unknown as Color); // to separate palettes
        });
    }
}
