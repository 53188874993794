import { EnvironmentProviders, importProvidersFrom } from '@angular/core';
import { CREATIVE_CONVERTER_FEATURE_KEY, reducer } from './creative-converter.reducer';
import { CreativeConverterEffects } from './creative-converter.effects';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

export function provideCreativeConverterStore(): EnvironmentProviders {
    return importProvidersFrom(
        StoreModule.forFeature(CREATIVE_CONVERTER_FEATURE_KEY, reducer),
        EffectsModule.forFeature(CreativeConverterEffects)
    );
}
