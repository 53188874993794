import { Pipe, PipeTransform } from '@angular/core';
import { GroupedSizeCreatives } from '../../pages/manage-view/services/tile-select.service';
import { filterCreatives } from '../../pages/manage-view/utils/filter-creatives.utils';
import { FiltersService } from '../filters/state/filters.service';
import { concatLatestFrom } from '@ngrx/operators';
import { Observable, map } from 'rxjs';

@Pipe({
    standalone: true,
    name: 'filterGroupedSizeCreatives'
})
export class FilterGroupedSizeCreativesPipe implements PipeTransform {
    constructor(private filtersService: FiltersService) {}

    transform(value: Observable<GroupedSizeCreatives[]>): Observable<GroupedSizeCreatives[]> {
        return value.pipe(
            concatLatestFrom(() => [this.filtersService.sizes$, this.filtersService.statuses$]),
            map(([inputArray, sizes, statuses]) =>
                inputArray
                    .map(groupedSizeCreative => ({
                        ...groupedSizeCreative,
                        creatives: filterCreatives(groupedSizeCreative.creatives, sizes, statuses)
                    }))
                    .filter(({ creatives }) => creatives.length)
            )
        );
    }
}
