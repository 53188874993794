import { BrandLibraryHotkeys } from './brand-library.hotkeys';
import { EditorHotkeys } from './editor.hotkeys';
import {
    HotkeyCommandList,
    IHotkey,
    IKeyCodeMap,
    OneOfHotkeyContext
} from '@domain/hotkeys/hotkeys.types';
import { ManagePageHotkeys } from './manage-page.hotkeys';
import { TextEditModeHotkeys } from './text-edit.hotkeys';
import { TimelineHotkeys } from './timeline.hotkeys';
import { TranslationPageHotkeys } from './translation-page.hotkeys';
import { WorkspaceHotkeys } from './workspace.hotkeys';

export const HOTKEYS: IHotkey[] = [
    ...TextEditModeHotkeys,
    ...EditorHotkeys,
    ...BrandLibraryHotkeys,
    ...WorkspaceHotkeys,
    ...TimelineHotkeys,
    ...ManagePageHotkeys,
    ...TranslationPageHotkeys,
    {
        command: 'Escape',
        keyCombination: 'Escape',
        context: 'EditElement',
        meta: { description: 'Escape current context', shortcut: 'Escape' }
    },
    {
        command: 'SaveWidget',
        keyCombination: { mac: 'Cmd + S', win: 'Ctrl + S' },
        context: 'WidgetEditor',
        meta: { description: 'Save widget', shortcut: { mac: '⌘ + S', win: 'Ctrl + S' } }
    },
    {
        command: 'ZoomTimeline',
        keyCombination: { win: 'Ctrl + Wheel', mac: 'Cmd + Wheel' },
        context: 'Timeline',
        meta: { description: 'Zoom with scroll', shortcut: { mac: '⌘ + scroll', win: 'Ctrl + scroll' } }
    }
];

const isMac = typeof window !== 'undefined' && navigator.userAgent.includes('Mac');
const hotkeyList = {} as HotkeyCommandList;
HOTKEYS.forEach(hotkey => {
    if (hotkey.meta) {
        const contexts = Array.isArray(hotkey.context) ? hotkey.context : [hotkey.context];
        for (const context of contexts) {
            hotkeyList[context] = hotkeyList[context] || {};
            if (
                typeof hotkey.meta.shortcut === 'object' &&
                hotkey.meta.shortcut.mac &&
                hotkey.meta.shortcut.win
            ) {
                hotkeyList[context][hotkey.command] = isMac
                    ? hotkey.meta.shortcut.mac
                          .split('+')
                          .join('')
                          .replace(/ /g, '')
                          .replace(/Alt/gi, '⌥')
                    : hotkey.meta.shortcut.win;
            } else if (typeof hotkey.meta.shortcut === 'string') {
                hotkeyList[context][hotkey.command] = hotkey.meta.shortcut;
            }
        }
    }
});

/**
 *
 * Returns an object with hotkeys (key: value) e.g:
 * ```
 * {
 *      Editor.AlignTop,
 *      Editor.DeleteElement,
 *      Editor.SelectAll
 * }
 * ```
 */
export function getHotkeysAsKeyValueList(contexts: OneOfHotkeyContext[]): HotkeyCommandList {
    const filteredList = {} as HotkeyCommandList;
    contexts.forEach(context => {
        filteredList[context] = hotkeyList[context];
    });
    return filteredList;
}

const defmod = typeof navigator !== 'undefined' && navigator.userAgent.includes('Mac') ? 'Cmd' : 'Ctrl';
export const BrowserDefaultHotkeys = {
    SelectAll: `${defmod} + A`,
    Save: `${defmod} + S`,
    SaveAndExit: `${defmod} + Shift + S`,
    Cut: `${defmod} + X`,
    PasteStyle: `${defmod} + Shift + V`,
    OpenLocation: `${defmod} + L`,
    ShowFullHistory: `${defmod} + Y`,
    HideOrHistory: `${defmod} + H`,
    Undo: `${defmod} + Z`,
    Redo: `${defmod} + Shift + Z`,
    NewTab: `${defmod} + T`,
    NewWindow: `${defmod} + N`,
    CloseTab: `${defmod} + W`,
    Find: `${defmod} + F`,
    FindNext: `${defmod} + G`,
    FindPrevious: `${defmod} + Shift + G`,
    ZoomIn: `${defmod} + Plus`,
    ZoomOut: `${defmod} + Minus`,
    ZoomWheel: `${defmod} + Wheel`,
    Help: `${defmod} + ?`,
    OpenSource: `${defmod} + U`,
    GoBack:
        typeof navigator !== 'undefined' && navigator.userAgent.includes('Firefox')
            ? 'Backspace'
            : 'Alt + ArrowLeft',
    GoForward: 'Alt + ArrowRight'
};

export const controlKeys = ['Ctrl', 'Cmd', 'Alt', 'Meta', 'Shift'];

export const ignoredKeys = ['CapsLock'];

export const charCodeExclusion = [
    'Control',
    'AltGraph',
    'Home',
    'End',
    'PageUp',
    'PageDown',
    '+',
    '-',
    '§',
    'ArrowDown',
    'ArrowUp',
    'ArrowLeft',
    'ArrowRight',
    'Backspace',
    'Escape',
    'Delete'
] as const;

export const keyCodeMap: IKeyCodeMap = {
    Control: 'Ctrl'
};
