import { ICreative, ICreativeDto } from '@domain/creativeset/creative/creative';
import { gql } from './gql.types';

export type UpdateApprovalStatusResult = {
    setApprovalStatusOnCreatives: Omit<ICreativeDto, 'connectedCampaigns'>[];
};
export type UpdateApprovalStatusVariables = {
    creativesetId: string;
    creatives: Pick<ICreative, 'id' | 'approvalStatus'>[];
};

export const UPDATE_APPROVAL_STATUS = gql<UpdateApprovalStatusResult, UpdateApprovalStatusVariables>`
    mutation SetApprovalStatusMutation(
        $creativesetId: String!
        $creatives: [UpdateCreativeInputType]!
    ) {
        setApprovalStatusOnCreatives(creativesetId: $creativesetId, creatives: $creatives) {
            id
            targetUrl
            checksum
            approvalStatus
            designId
            sizeId
            versionId
        }
    }
`;

export type UpdateCreativeStatusResult = {
    updateCreatives: Pick<ICreativeDto, 'id' | 'approvalStatus' | 'targetUrl' | 'checksum'>[];
};
export type UpdateCreativeStatusVariables = {
    creatives: Pick<ICreative, 'id' | 'approvalStatus' | 'targetUrl' | 'checksum'>[];
};

export const UPDATE_CREATIVES = gql<UpdateCreativeStatusResult, UpdateCreativeStatusVariables>`
    mutation UpdateCreativesMutation($creatives: [UpdateCreativeInputType]!) {
        updateCreatives(creatives: $creatives) {
            id
            targetUrl
            checksum
            approvalStatus
        }
    }
`;

export type BeginCreativeGenerationResult = {
    beginCreativeGeneration: {
        creatives: Pick<ICreativeDto, 'id' | 'checksum'>[];
        failures: {
            id: string;
            message: string;
        }[];
    };
};
export type BeginCreativeGenerationVariables = { creativeIds: string[]; creativesetId: string };

export const BEGIN_CREATIVE_GENERATION = gql<
    BeginCreativeGenerationResult,
    BeginCreativeGenerationVariables
>`
    mutation BeginCreativeGenerationMutation($creativeIds: [String]!, $creativesetId: String!) {
        beginCreativeGeneration(
            creativesToGenerate: [{ creativesetId: $creativesetId, creativeIds: $creativeIds }]
        ) {
            creatives {
                id
                checksum
            }
            failures {
                id
                message
            }
        }
    }
`;
