<div
    *ngIf="selectedVersions$ | async as selectedVersions"
    [class.mobile-showcase]="isMobileShowcase$ | async"
    class="container">
    <div
        class="version-picker"
        *ngIf="{ viewMode: viewMode$ | async } as data">
        <div
            *ngIf="data.viewMode !== 'add' && data.viewMode !== 'loading'"
            id="select-version"
            class="selected-version"
            [ngClass]="{ select: data.viewMode === 'select' }"
            (click)="toggleSelectView()"
            data-test-id="version-picker">
            <div class="selected-row">
                <ng-container *ngIf="isShowingAllVersions$ | async; else notShowingAll">
                    <!-- all versions -->
                    <ui-svg-icon
                        class="all-versions-icon"
                        icon="thumbnails"></ui-svg-icon>
                    <div class="version-name"><span>All versions</span></div>
                </ng-container>

                <ng-template #notShowingAll>
                    <ng-container
                        [ngSwitch]="selectedVersions.length"
                        #notShowingAll>
                        <!-- single version selected -->
                        <ng-container *ngSwitchCase="1">
                            <version-flag
                                [localizationId]="selectedVersions[0].localization.id"
                                [title]="selectedVersions[0].name"
                                [showName]="true"></version-flag>
                        </ng-container>
                        <!-- multiple version selected -->
                        <ng-container *ngSwitchDefault>
                            <div class="flag-wrapper">
                                <ng-container *ngFor="let version of selectedVersions; let i = index">
                                    <version-flag
                                        *ngIf="3 > i"
                                        [localizationId]="version.localization.id"></version-flag>
                                </ng-container>
                            </div>
                            <span
                                >{{ selectedVersions.length }} of
                                {{ (versions$ | async)?.length }}</span
                            >
                        </ng-container>
                    </ng-container>
                </ng-template>

                <div class="arrow">
                    <ui-svg-icon
                        *ngIf="versionPickerArrow$ | async as versionPickerArrow"
                        [icon]="versionPickerArrow"></ui-svg-icon>
                </div>
            </div>
        </div>

        <div
            class="loading"
            *ngIf="data.viewMode === 'loading'">
            <ui-loader></ui-loader>
        </div>

        <div
            class="add-version"
            *ngIf="data.viewMode === 'add'">
            <ng-container *ngIf="newVersionPlaceholder$ | async as newVersion">
                <version-flag [localizationId]="newVersion.localization.id"></version-flag>
                <ui-input
                    #versionNameInput
                    autocomplete="off"
                    [maxlength]="250"
                    [(value)]="newVersionName"
                    (submit)="saveNewVersion()"
                    (cancel)="stopAddVersion()"
                    (blur)="saveNewVersion()"></ui-input>
            </ng-container>
        </div>

        <div
            class="version-dropdown"
            #versionList
            (click)="$event.stopPropagation()"
            *ngIf="data.viewMode === 'select' || data.viewMode === 'select-localization'">
            <div
                class="version-list"
                *ngIf="versions$ | async as versions">
                <div
                    *ngIf="versions.length > 1 && showAllVersions"
                    id="interaction-all-versions"
                    class="version-item"
                    (click)="selectAllVersions()"
                    [ngClass]="{ selected: (isShowingAllVersions$ | async) }">
                    <ui-svg-icon
                        icon="thumbnails"
                        class="all-versions-icon"></ui-svg-icon>
                    <span>All versions</span>
                </div>
                <div
                    id="version-{{ i + 1 }}"
                    class="version-item"
                    [attr.data-test-id]="'version-' + version.name"
                    *ngFor="let version of versions; let i = index"
                    (click)="selectVersion($event, version)"
                    [ngClass]="{
                        selected:
                            selectedVersions.indexOf(version) !== -1 &&
                            (isShowingAllVersions$ | async) === false
                    }">
                    <version-flag
                        [localizationId]="version.localization.id"
                        [showName]="true"
                        [title]="version.name"></version-flag>
                </div>
            </div>
            <ng-container *permissions="'ManageVersions'">
                <ng-container *ngIf="allowManageVersions">
                    <div
                        class="version-toolbar"
                        *permissions="'Default'">
                        <toolbar-button
                            id="interaction-add-version"
                            icon="plus"
                            [iconSize]="12"
                            (click)="showAddLocalizationView($event)"
                            [uiDropdownTarget]="localizationsDropdown.dropdown">
                        </toolbar-button>
                        <version-language-picker
                            #localizationsDropdown
                            (selectLocalization)="
                                selectLocalization($event.localization, $event.autoTranslate)
                            "
                            [offset]="{ x: 0, y: -5 }"
                            [positions]="[
                                {
                                    originX: 'end',
                                    originY: 'top',
                                    overlayX: 'start',
                                    overlayY: 'top'
                                }
                            ]"></version-language-picker>
                        <toolbar-button
                            id="interaction-version-settings"
                            icon="settings"
                            [iconSize]="14"
                            (click)="openVersionDialog()">
                        </toolbar-button>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>
