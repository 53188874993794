import {
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import { filter, map, Observable, switchMap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CreativeSetShowcaseService } from '../../shared/creativeset-showcase/state/creativeset-showcase.service';
import { EnvironmentService } from '../../shared/services/environment.service';
import { StudioHubService } from '../../shared/services/studio-hub.service';
import { AuthService } from './auth.service';

/**
 Intercepts HTTPClient requests and adds the authorization header,
 as well as the signalR connection header.

 This works for all libraries that use the Angular HttpClient. The apollo client for collections is not included.
 **/
@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
    private readonly auth0HttpInterceptor = inject(AuthHttpInterceptor);
    private readonly environmentService = inject(EnvironmentService);
    private readonly studioHubService = inject(StudioHubService);
    private readonly creativesetShowcaseService = inject(CreativeSetShowcaseService);
    private readonly authService = inject(AuthService);
    private brandId = '';
    private creativeSetId = '';

    intercept(request: HttpRequest<HttpHeaders>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        // E2E TESTS
        if (environment.stage === 'test') {
            return next.handle(request);
        }
        // SHOWCASE
        if (this.environmentService.inShowcaseMode) {
            return this.creativesetShowcaseService.showcaseToken$.pipe(
                switchMap(showcaseToken => {
                    request = request.clone({
                        setHeaders: {
                            Authorization: `Bearer ${showcaseToken}`
                        }
                    });

                    return next.handle(request);
                })
            );
        }

        // REGULAR
        const connectionId = this.studioHubService.connectionId;
        if (connectionId) {
            request = request.clone({
                setHeaders: {
                    'X-BF-ConnectionId': connectionId,
                    'BF-Studio-SignalR-ConnectionId': connectionId
                }
            });
        }

        if (this.brandId && this.creativeSetId) {
            request = this.addBFHeaders(request, this.brandId, this.creativeSetId);
            return this.auth0HttpInterceptor.intercept(request, next);
        }

        // AUTH0 first redirection
        return this.authService.getAppState().pipe(
            map(({ target }) => target),
            filter(target => target !== undefined),
            switchMap(target => {
                const url = new URL(window.location.origin + target);
                const urlChunks = url.pathname.split('/');
                this.brandId = urlChunks[2] ?? '';
                this.creativeSetId = urlChunks[4] ?? '';

                request = this.addBFHeaders(request, this.brandId, this.creativeSetId);

                return this.auth0HttpInterceptor.intercept(request, next);
            })
        );
    }

    private addBFHeaders(
        request: HttpRequest<HttpHeaders>,
        brandId: string,
        creativeSetId: string
    ): HttpRequest<HttpHeaders> {
        return request.clone({
            setHeaders: {
                'BF-Brand-Id': brandId,
                'BF-CreativeSet-Id': creativeSetId
            }
        });
    }
}
