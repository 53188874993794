import { Component, computed, effect, inject, signal } from '@angular/core';
import { GenAIService } from '../../state/gen-ai.service';
import { UIModule } from '@bannerflow/ui';
import { CommonModule } from '@angular/common';
import { ExpandablePromptComponent } from '../expandable-prompt/expandable-prompt.component';
import { ExpandablePromptInputType } from '@studio/domain/components/ai-studio.types';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
    standalone: true,
    imports: [UIModule, CommonModule, ExpandablePromptComponent],
    selector: 'search-and-replace-options',
    templateUrl: './search-and-replace-options.component.html',
    styleUrls: ['./search-and-replace-options.component.scss']
})
export class SearchAndReplaceOptionsComponent {
    private genAiService = inject(GenAIService);

    ExpandablePromptInputType = ExpandablePromptInputType;

    searchPrompt = signal('');
    replacePrompt = signal('');
    isRequestingSearchAndReplace = toSignal(this.genAiService.isRequestingSearchAndReplace$, {
        initialValue: false
    });
    error = toSignal(this.genAiService.error$, { initialValue: '' });

    actionsDisabled = computed(() => this.computeActionsDisabled());

    constructor() {
        effect(
            () => {
                if (!this.isRequestingSearchAndReplace() && !this.error()) {
                    this.searchPrompt.set('');
                    this.replacePrompt.set('');
                }
            },
            { allowSignalWrites: true }
        );
    }

    onSearchPromptChange(newValue: string): void {
        this.searchPrompt.set(newValue);
    }

    onReplacePromptChange(newValue: string): void {
        this.replacePrompt.set(newValue);
    }

    searchAndReplace(): void {
        this.genAiService.searchAndReplace(this.searchPrompt(), this.replacePrompt());
    }

    private computeActionsDisabled(): boolean {
        return !this.searchPrompt() || !this.replacePrompt() || this.isRequestingSearchAndReplace();
    }
}
