import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const initActions = createActionGroup({
    source: 'AnimationControl',
    events: {
        'init showcase state': emptyProps(),
        'init disabled animations state': emptyProps()
    }
});
export const controlActions = createActionGroup({
    source: 'AnimationControl',
    events: {
        'toggle animations': emptyProps(),
        'play animations': emptyProps(),
        'stop animations': emptyProps(),
        'step forward': emptyProps(),
        'step back': emptyProps(),
        'step to start': emptyProps(),
        'step to preload image': emptyProps(),
        'disable animations': props<{ isDisabled: boolean }>(),
        'show element': props<{ elementId: string }>()
    }
});
