import { inject, Injectable } from '@angular/core';
import { AnimationControlService } from '../../animation-control/state/animation-control.service';
import { CreativesetDataService } from '../../creativeset/creativeset.data.service';
import { UIDialogService } from '@bannerflow/ui';
import { take } from 'rxjs';
import { VersionDialogComponent } from './index';

@Injectable({ providedIn: 'root' })
export class VersionDialogService {
    private animationControlService = inject(AnimationControlService);
    private creativesetDataService = inject(CreativesetDataService);
    private uiDialogService = inject(UIDialogService);

    openVersionDialog(callback?: () => void): void {
        // pause animations when managing versions
        this.animationControlService.stopAnimations();

        const creativeset = this.creativesetDataService.creativeset;

        const dialog = this.uiDialogService.openComponent(VersionDialogComponent, {
            headerText: 'Manage versions',
            panelClass: ['no-padding', 'fullscreen'],
            maxWidth: '100%',
            width: '100%',
            height: '100%',
            theme: 'default',
            closeButton: true,
            data: {
                creativeset: creativeset
            }
        });

        dialog
            .afterClose()
            .pipe(take(1))
            .subscribe(() => {
                callback?.();
            });
    }
}
