@if (groupLength > 1) {
    <div
        class="group-header"
        (click)="onToggleExpand()">
        <ui-svg-icon [icon]="expanded ? 'collapse' : 'expand'"> </ui-svg-icon>
        <span> found {{ groupLength }} times </span>
        <span class="link">
            @if (expanded) {
                collapse
            } @else {
                expand
            }
        </span>
    </div>
}
