import { createReducer, on } from '@ngrx/store';
import * as AnimationControlActions from './animation-control.actions';

export const ANIMATION_CONTROL_FEATURE_KEY = 'animationControl';

export interface AnimationControlPartialState {
    readonly [ANIMATION_CONTROL_FEATURE_KEY]: AnimationControlState;
}

export interface AnimationControlState {
    isPlaying: boolean;
    isDisabled: boolean;
    showPreloadImage: boolean;
    shouldAutoPause: boolean;
    shownElementId?: string;
}

export const initialState: AnimationControlState = {
    isPlaying: false,
    isDisabled: false,
    showPreloadImage: false,
    shouldAutoPause: true
};

export const reducer = createReducer<AnimationControlState>(
    initialState,
    on(AnimationControlActions.controlActions.toggleAnimations, state => {
        return {
            ...state,
            isPlaying: !state.isPlaying,
            showPreloadImage: false
        };
    }),
    on(AnimationControlActions.controlActions.stopAnimations, state => {
        return {
            ...state,
            isPlaying: false,
            showPreloadImage: false
        };
    }),
    on(AnimationControlActions.controlActions.playAnimations, state => {
        return {
            ...state,
            isPlaying: true,
            showPreloadImage: false
        };
    }),
    on(
        AnimationControlActions.controlActions.stepForward,
        AnimationControlActions.controlActions.stepToStart,
        AnimationControlActions.controlActions.stepBack,
        state => {
            return {
                ...state,
                showPreloadImage: false
            };
        }
    ),
    on(AnimationControlActions.controlActions.stepToPreloadImage, state => {
        return {
            ...state,
            isPlaying: false,
            showPreloadImage: true
        };
    }),
    on(AnimationControlActions.initActions.initShowcaseState, state => {
        return {
            ...state,
            isPlaying: true,
            shouldAutoPause: false
        };
    }),
    on(AnimationControlActions.controlActions.disableAnimations, (state, { isDisabled }) => {
        return {
            ...state,
            isDisabled,
            isPlaying: isDisabled ? false : state.isPlaying
        };
    }),
    on(AnimationControlActions.controlActions.showElement, (state, { elementId }) => {
        return {
            ...state,
            shownElementId: elementId,
            isPlaying: false
        };
    })
);
