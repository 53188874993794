@if (loading()) {
    <descriptive-loader
        [overlay]="true"
        [type]="loaderType" />
}
@if (!creative) {
    <div class="import-area">
        <ui-file-dropzone
            [accept]="'.psd'"
            hintText="Drag and drop a PSD file to upload it or select it from your computer"
            buttonText="Select PSD File"
            [maxFiles]="1"
            (filesUploaded)="onFileChange($event)" />

        <div class="note">
            Please note that artboards cannot be larger than 5000x5000px, and individual images have a
            size limitation of 6MB. You can find more details in
            <a
                href="https://support.bannerflow.com/en/articles/7234607-photoshop-psd-import"
                target="_blank">
                this article </a
            >.
        </div>
    </div>
}

<div
    class="preview-wrapper"
    #creativePreview>
    @if (creative) {
        <div class="preview">
            @if (zoomValue(); as _zoomValue) {
                <div
                    class="creative"
                    [style.width.px]="creative.size.width * (_zoomValue / 100)"
                    [style.height.px]="creative.size.height * (_zoomValue / 100)">
                    <div class="creative__size">
                        {{ creative.size.width }} × {{ creative.size.height }}
                    </div>
                    <div class="creative__outlet">
                        <studio-creative
                            #psdCreative
                            [creative]="creative"
                            [size]="creative.size"
                            [renderOnInit]="true"
                            [showTargetUrl]="false" />
                    </div>
                </div>
            }
        </div>
    }
</div>

<ui-dialog-buttons
    class="actions"
    align="right">
    <ui-button
        id="cancel-button"
        text="Cancel"
        (click)="cancel()" />
    <ui-button
        id="interaction-save-psd-creative"
        text="Add size"
        type="primary"
        data-test-id="save-psd-creative"
        (click)="saveCreative()"
        [loading]="isSaving"
        [disabled]="isSaving || !creative" />
</ui-dialog-buttons>
