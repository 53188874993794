import { IEventEmitter } from '@domain/events';
import { sanitizeUrl } from './sanitizer';

export function loadImagePromise(url: string): PromiseResolver<HTMLImageElement> {
    return loadHtmlElement(new Image(), url);
}

export function loadHtmlElement<T extends HTMLIFrameElement | HTMLImageElement | HTMLScriptElement>(
    element: T,
    url?: string
): PromiseResolver<T> {
    url = url && sanitizeUrl(url);
    const resolver = new PromiseResolver<T>();
    element.onload = (): void => {
        resolver.resolve(element);
    };
    element.onerror = (e): void => {
        resolver.reject(e);
    };
    if (typeof url === 'string') {
        element.src = url;
    }
    return resolver;
}

export function eventListenerToPromise<T = void>(target: EventTarget, eventName: string): Promise<T> {
    const resolver = new PromiseResolver<T>();
    const options: AddEventListenerOptions = { once: true };
    target.addEventListener(eventName, resolver.resolve as EventListener, options);
    return resolver.promise;
}

export function eventToPromise<T = Event>(
    emitter: IEventEmitter<Record<string, unknown>>,
    eventName: string
): Promise<T> {
    const resolver = new PromiseResolver<T>();
    emitter.on(eventName, resolver.resolve);
    return resolver.promise;
}

export class PromiseResolver<T = void> {
    resolve: (value: T | PromiseLike<T>) => void;
    reject: (reason?: unknown) => void;
    promise: Promise<T>;

    constructor() {
        this.promise = new Promise<T>((res, rej) => {
            this.resolve = res;
            this.reject = rej;
        });
    }
}

export function requestAnimationFramePromise(): Promise<number> {
    return new Promise<number>(resolve => {
        requestAnimationFrame((time: number) => {
            resolve(time);
        });
    });
}

export function sleep(milliseconds = 0): Promise<void> {
    return new Promise(resolve => {
        setTimeout(resolve, milliseconds);
    });
}

/**
 * Skip a number of animation frames.
 * 1 means that you skip the first and resolves on the second frame
 * @param frameToSkip
 */
export async function skipAnimationFrames(frameToSkip = 1): Promise<void> {
    for (let i = 0; i < frameToSkip + 1; i++) {
        await requestAnimationFramePromise();
    }
}
