@let _collectionName = collectionName();
@let _size = size();
@let _numberOfSelected = numberOfSelected();

<div
    id="{{ _collectionName | slug }}-sizes-{{ _size.width }}x{{ _size.height }}"
    class="size add-multiple"
    [class.added]="_numberOfSelected > 0"
    [class.is-social]="!!_size.compatibility?.length"
    (click)="addSize(_size)">
    <div class="size__wrapper">
        <div class="thumbnail">
            @if (_size.thumbnail) {
                <ui-svg-icon
                    class="thumbnail__icon"
                    [icon]="_size.thumbnail"></ui-svg-icon>
            } @else {
                <size-thumbnail [size]="_size"></size-thumbnail>
            }
        </div>
        <div class="value">
            <div class="size-value">
                {{ _size.width }} × {{ _size.height }}
                @if (_size.compatibility?.length) {
                    <div class="compatibility">
                        @for (icon of _size.compatibility; track icon) {
                            <ui-svg-icon
                                class="compatibility__icon"
                                [icon]="icon"></ui-svg-icon>
                        }
                    </div>
                }
            </div>
            @if (_size.name) {
                <div
                    class="size-name"
                    [uiTooltip]="_size.name">
                    {{ _size.name }}
                </div>
            }
        </div>
    </div>
    <div class="add">
        <div class="add__multiple">
            <div
                class="add__multiple__sub"
                (click)="removeSize(_size); $event.stopPropagation()">
                <ui-svg-icon icon="minus-small"></ui-svg-icon>
            </div>
            <div class="add__multiple__label">{{ _numberOfSelected }}</div>
            <div
                class="add__multiple__add"
                (click)="addSize(_size); $event.stopPropagation()">
                <ui-svg-icon icon="plus-tiny"></ui-svg-icon>
            </div>
        </div>
    </div>
</div>
