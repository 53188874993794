<!-- Menubar top -->
@if (designViewInit$ | async) {
    <ui-header
        [full]="true"
        [showLogo]="false"
        [style.visibility]="editingElement ? 'hidden' : ''">
        <editor-topbar
            id="my-body"
            #topbar></editor-topbar>
    </ui-header>
}

<ui-body
    [full]="true"
    [showOverflow]="true"
    #uiBody>
    @if ((designViewInit$ | async) && (workspaceInit$ | async)) {
        <div class="editor">
            <div
                class="left"
                [style.visibility]="editingElement ? 'hidden' : ''">
                <!-- Toolbar left -->
                <studio-toolbar
                    #toolbar
                    (toolbarIsOpen)="toolbarIsOpen($event)"></studio-toolbar>

                <!-- Media library -->
                <media-library
                    #mediaLibrary
                    [width]="mediaLibraryWidth"></media-library>
            </div>

            <!-- Edit asset -->
            @if (editingElement) {
                <edit-element
                    class="element-edit"
                    [element]="editingElement"
                    (closeSettings)="onCloseEditElement($event)"
                    data-test-id="element-edit-view"></edit-element>
            }

            <div
                class="bottom"
                [ngClass]="{ 'library-open': isMediaLibraryOpen }"
                [style.display]="editingElement ? 'none' : ''">
                <!-- Timeline -->
                <studio-timeline
                    id="timeline"
                    #timeline
                    [isZooming]="workspace.isZooming"
                    [animator]="animator!"
                    [workspace]="workspace"
                    [mouseOverTimeline]="mouseOverTimeline"
                    (mouseenter)="onMouseEnterTimeline()"
                    (mouseleave)="onMouseLeaveTimeline()"></studio-timeline>
            </div>
            @if (!editingElement) {
                <div class="right">
                    <!-- Properties right -->
                    @if (editorStateService.designFork) {
                        <properties-panel
                            *ngIf="editorStateService.designFork"
                            ui-theme="tiny"
                            [animator]="animator!"></properties-panel>
                    }
                </div>
            }
        </div>
    }
    <div class="body">
        <!-- Zoom control -->
        @if (workspaceInit$ | async) {
            <zoom-control
                [zoom]="editorStateService.zoom * 100"
                (isHovered)="workspace.zoomControlHover($event)"
                (mousedown)="workspace.transform.cancel()"></zoom-control>
        }
        <!-- Workspace -->
        @if (editorStateService.designFork && designViewInit$ | async) {
            <studio-workspace
                id="workspace"
                #workspace
                appZoomControl
                [zoom]="editorStateService.zoom"
                [isZooming]="workspace.isZooming"
                [class.hide]="editingElement"
                [design]="editorStateService.designFork"
                [mouseOverTimeline]="mouseOverTimeline"></studio-workspace>
        }
    </div>
</ui-body>
<ng-template draggableContainer></ng-template>
<ui-loader *ngIf="loading"></ui-loader>
<studio-devtools [designView]="this"></studio-devtools>
