<div
    class="dropdown-trigger"
    #menuTrigger="uiDropdownTarget"
    [uiDropdownTarget]="menu"
    [hasBackdrop]="false"
    (dropdownClosed)="contextMenu.contextMenuClosed()"></div>

<ui-dropdown
    #menu
    type="menu">
    <ui-dropdown-item
        class="custom-dropdown"
        [disabled]="!designView.copiedSnapshot"
        (click)="pasteOnPosition()">
        <div class="custom-item">
            <div class="custom-column"></div>
            <div class="custom-text">Paste</div>
            <div [class]="contextMenu.isMac ? 'mac custom-action' : 'custom-action'">
                {{ keyboardShortcuts.Editor.Paste }}
            </div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-item
        class="custom-dropdown"
        [disabled]="!workspaceHasElements()"
        (click)="selectAll()"
        data-cy="select-all">
        <div class="custom-item">
            <div class="custom-column"></div>
            <div class="custom-text">Select All</div>
            <div [class]="contextMenu.isMac ? 'mac custom-action' : 'custom-action'">
                {{ keyboardShortcuts.Editor.SelectAll }}
            </div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-item
        *ngIf="lockedElementsExists()"
        class="custom-dropdown"
        (click)="unlockAll()">
        <div class="custom-item">
            <ui-svg-icon
                class="custom-column icon"
                icon="lock-open"></ui-svg-icon>
            <div class="custom-text">Unlock all</div>
        </div>
    </ui-dropdown-item>
</ui-dropdown>
