import { inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { Logger } from '@bannerflow/sentinel-logger';
import { BrandService, BrandState } from '@studio/stores/brand';
import { firstValueFrom } from 'rxjs';

export const brandResolverFn: ResolveFn<Promise<BrandState | undefined>> = async activatedRoute => {
    const brandService = inject(BrandService);
    const brandId = activatedRoute.params['brandId'];
    const logger = new Logger('BrandResolver');

    if (!brandId) {
        logger.error('Brand ID must be provided!');
        const router = inject(Router);
        await router.navigate(['404']);
    }

    logger.debug(`Fetching brand[${brandId}]`);

    brandService.loadBrand(brandId);

    return firstValueFrom(brandService.brand$);
};
