import { DuplicateCreativeResult, DuplicateCreativeStatus } from '@domain/signalr';
import { Observable, of } from 'rxjs';

export class MockHubConnection {
    handlers: Map<string, (...args: unknown[]) => void> = new Map();

    start(): void {
        this.handlers = new Map();
        addEventListener('MockHubConnectionEvent', this.customEventListener as EventListener, false);
    }
    stop(): void {
        this.handlers.clear();
        removeEventListener('MockHubConnectionEvent', this.customEventListener as EventListener, false);
    }
    invoke(): Promise<void> {
        return Promise.resolve();
    }

    on(eventName: string, callback: (...args: unknown[]) => void): void {
        this.handlers.set(eventName, callback);
    }

    customEventListener = (event: CustomEvent): void => {
        const callback = this.handlers.get(event.detail.eventName);
        if (callback) {
            callback(event.detail.payload);
        }
    };
}

export class TestSignalRHubService {
    private connection: MockHubConnection;

    duplicationResult$: Observable<DuplicateCreativeResult> = of({
        creativeSetId: '1',
        status: DuplicateCreativeStatus.Completed
    });

    startConnection(): void {
        this.connection = new MockHubConnection();
    }

    subscribeToNotification(): Promise<void> {
        return this.connection.invoke();
    }

    destroy(): void {
        this.connection.stop();
    }
}
