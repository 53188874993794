import { AIStudioSnapshot, GenAIOption } from '@studio/domain/components/ai-studio.types';
import { GenAIState } from '../state/gen-ai.reducer';

export function createSnapshot(
    state: GenAIState,
    snapshotType: AIStudioSnapshot['type']
): AIStudioSnapshot {
    return {
        type: snapshotType,
        currentImage: state.currentImage,
        previousImage: state.previousImage,
        generativeFillImageMaskLink: state.generativeFillImageMaskLink,
        generativeFillBrushSettings: {
            history: state.generativeFillBrushSettings.history
        },
        eraseImageMaskLink: state.eraseImageMaskLink,
        eraseBrushSettings: {
            history: state.eraseBrushSettings.history
        },
        outpaintSettings: state.outpaintSettings,
        backgroundRemovalSettings: {
            backgroundRemoved: state.backgroundRemovalSettings.backgroundRemoved
        }
    };
}

export function addSnapshotToHistory(
    currentHistory: GenAIState['history'],
    snapshot: AIStudioSnapshot
): GenAIState['history'] {
    const newUrlsToClear = getUrlsFromStack(currentHistory.redoStack);

    const newUndoStack = [...currentHistory.undoStack, snapshot];

    return {
        redoStack: [],
        undoStack: newUndoStack,
        urlsToClean: [...currentHistory.urlsToClean, ...newUrlsToClear]
    };
}

function revokeObjectURLsFromSnapshots(urlsToClean: GenAIState['history']['urlsToClean']): void {
    for (const url of urlsToClean) {
        if (url) {
            URL.revokeObjectURL(url);
        }
    }
}

export function revokeObjectURLs(history: GenAIState['history']): void {
    const newUrlsToClear = getUrlsFromStack([...history.undoStack, ...history.redoStack]);
    revokeObjectURLsFromSnapshots([...history.urlsToClean, ...newUrlsToClear]);
}

export function getSnapshotIndex(
    stack: AIStudioSnapshot[],
    selectedOption: GenAIOption | undefined
): number {
    if (selectedOption === GenAIOption.GenerativeFill) {
        return stack.findLastIndex(({ type }) => type === 'request' || type === 'generativeFillBrush');
    }
    if (selectedOption === GenAIOption.Erase) {
        return stack.findLastIndex(({ type }) => type === 'request' || type === 'eraseBrush');
    }
    return stack.findLastIndex(({ type }) => type !== 'generativeFillBrush' && type !== 'eraseBrush');
}

function getUrlsFromStack(stack: AIStudioSnapshot[]): string[] {
    const urls = new Set<string>();
    for (const snapshot of stack) {
        if (snapshot.generativeFillImageMaskLink) {
            urls.add(snapshot.generativeFillImageMaskLink);
        }
        if (snapshot.eraseImageMaskLink) {
            urls.add(snapshot.eraseImageMaskLink);
        }
        if (snapshot.currentImage) {
            urls.add(snapshot.currentImage);
        }
        if (snapshot.previousImage) {
            urls.add(snapshot.previousImage);
        }
    }
    return Array.from(urls);
}

export function removeSnapshotsByType(
    stack: AIStudioSnapshot[],
    typeToRemove: AIStudioSnapshot['type']
): AIStudioSnapshot[] {
    return stack.filter(({ type }) => type !== typeToRemove);
}
