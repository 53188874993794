import { isVersionedFeed } from '@creative/elements/feed/feeds.utils';
import { isVersionedText } from '@creative/elements/rich-text/utils';
import { IVersion, IVersionProperty, IVersionedText } from '@domain/creativeset/version';
import { SpanType } from '@domain/text';
import { HighlightedFeed, IMatInputHighlightedArea } from '@studio/domain/components/translation-page';
import { deepEqual } from '@studio/utils/utils';

export function getFeedIdsFromVersions(versions: IVersion[]): Set<string> {
    const feedIds = new Set<string>();
    for (const version of versions) {
        for (const versionProperty of version.properties) {
            if (isVersionedFeed(versionProperty)) {
                if (versionProperty.value.id) {
                    feedIds.add(versionProperty.value.id);
                }
                continue;
            }
            if (!isVersionedText(versionProperty)) {
                continue;
            }

            for (const span of versionProperty.value.styles) {
                if (!span.variable?.id || span.type !== SpanType.Variable) {
                    continue;
                }
                feedIds.add(span.variable.id);
            }
        }
    }
    return feedIds;
}

export function getHighlightedAreasFor(
    versionProperty: IVersionProperty<IVersionedText>,
    highlightedFeed?: HighlightedFeed
): IMatInputHighlightedArea[] {
    const highlightedAreas: IMatInputHighlightedArea[] = [];
    for (const span of versionProperty.value.styles) {
        if (span.type !== SpanType.Variable) {
            continue;
        }

        const isSameSpan = deepEqual(highlightedFeed?.span, span);
        highlightedAreas.push({
            start: span.position,
            end: span.position + span.length,
            focused: !!highlightedFeed && isSameSpan
        });
    }
    return highlightedAreas;
}
