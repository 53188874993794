import { Injectable, inject } from '@angular/core';
import { UINotificationService } from '@bannerflow/ui';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { BrandService } from '@studio/stores/brand';
import { from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ListHubService } from '../list-hub.service';
import * as DuplicateCreativeActions from './duplicate-creative.actions';
import { clearWildcards } from './duplicate-creative.utils';
import { DuplicateCreativesDataService } from './duplicate-creatives.data.service';

@Injectable()
export class DuplicateCreativeEffects {
    private actions$ = inject(Actions);
    private duplicateCreativesDataService = inject(DuplicateCreativesDataService);
    private brandService = inject(BrandService);
    private listHubService = inject(ListHubService);
    private uiNotificationService = inject(UINotificationService);

    loadPage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DuplicateCreativeActions.loadDuplicateCreativesPage),
            concatLatestFrom(_ => this.brandService.brandId$),
            switchMap(([{ folderId, pageIndex, sort }, brandId]) =>
                this.duplicateCreativesDataService
                    .getCreativeSetsWithFolders({ brandId, pageIndex, folderId, sort })
                    .pipe(
                        map(data => {
                            for (const item of data.items) {
                                item.modifiedAt ??= item.createdAt;
                            }
                            return DuplicateCreativeActions.loadDuplicateCreativesPageSuccess({
                                data,
                                pageIndex
                            });
                        }),
                        catchError(error =>
                            of(DuplicateCreativeActions.loadDuplicateCreativesPageFailure({ error }))
                        )
                    )
            )
        );
    });

    search$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DuplicateCreativeActions.searchDuplicateCreatives),
            concatLatestFrom(_ => this.brandService.brandId$),
            switchMap(([{ searchTerm, pageIndex, sort }, brandId]) => {
                const searchTermWithoutWildcards = clearWildcards(searchTerm);
                return this.duplicateCreativesDataService
                    .getCreativeSetsWithFolders({
                        brandId,
                        pageIndex,
                        folderId: undefined,
                        searchTerm: searchTermWithoutWildcards,
                        sort
                    })
                    .pipe(
                        map(data => {
                            for (const item of data.items) {
                                item.modifiedAt ??= item.createdAt;
                            }
                            return DuplicateCreativeActions.searchDuplicateCreativesSuccess({
                                data,
                                pageIndex,
                                searchTerm
                            });
                        }),
                        catchError(error =>
                            of(DuplicateCreativeActions.searchDuplicateCreativesFailure({ error }))
                        )
                    );
            })
        );
    });

    duplicateToNew$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DuplicateCreativeActions.createDuplicationToNew),
            concatLatestFrom(_ => this.brandService.brandId$),
            switchMap(([action, brandId]) =>
                from(
                    this.listHubService.subscribeToNotification(action.actionCorrelationId, brandId)
                ).pipe(map(() => ({ action, brandId })))
            ),
            switchMap(({ action, brandId }) =>
                this.duplicateCreativesDataService
                    .duplicateToNew({ brandId, ...action })
                    .pipe(map(() => brandId))
            ),
            switchMap(brandId =>
                this.listHubService.duplicationResult$.pipe(
                    map(result => {
                        const data = {
                            brandId: brandId,
                            creativeSetId: result.creativeSetId
                        };

                        return DuplicateCreativeActions.createDuplicationToNewSuccess({ data });
                    })
                )
            ),
            catchError(error => of(DuplicateCreativeActions.createDuplicationToNewFailure({ error })))
        );
    });

    duplicateToExisting$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DuplicateCreativeActions.createDuplicationToExisting),
            concatLatestFrom(_ => this.brandService.brandId$),
            switchMap(
                ([{ creativeset, sourceSizeIds, sourceVersionIds, targetCreativesetId }, brandId]) =>
                    this.duplicateCreativesDataService
                        .duplicateToExisting(
                            brandId,
                            creativeset,
                            sourceSizeIds,
                            sourceVersionIds,
                            targetCreativesetId
                        )
                        .pipe(
                            map(data =>
                                DuplicateCreativeActions.createDuplicationToExistingSuccess({ data })
                            ),
                            catchError(error =>
                                of(
                                    DuplicateCreativeActions.createDuplicationToExistingFailure({
                                        error
                                    })
                                )
                            )
                        )
            )
        );
    });

    createNewFolder$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DuplicateCreativeActions.createNewFolder),
            concatLatestFrom(_ => this.brandService.brandId$),
            switchMap(([{ brandId, name, targetFolderId }]) =>
                this.duplicateCreativesDataService.createNewFolder(brandId, name, targetFolderId).pipe(
                    map(newFolder => {
                        this.uiNotificationService.open(`Folder ${name} is successfully created.`, {
                            type: 'success',
                            placement: 'top',
                            hideCloseIcon: true,
                            autoCloseDelay: 3000
                        });
                        return DuplicateCreativeActions.createNewFolderSuccess({ newFolder });
                    }),
                    catchError(error => of(DuplicateCreativeActions.createNewFolderFailure({ error })))
                )
            )
        );
    });
}
