import { Component } from '@angular/core';
import { ICreative } from '@domain/creativeset/creative';
import { CreativeSize } from '@domain/creativeset';
import { CommonModule } from '@angular/common';
import { UIModule } from '@bannerflow/ui';

@Component({
    standalone: true,
    imports: [CommonModule, UIModule],
    selector: 'delete-size-dialog',
    templateUrl: './delete-size-dialog.component.html',
    styleUrls: ['./delete-size-dialog.component.scss']
})
export class DeleteSizeDialogComponent {
    private closePromise: Promise<string>;
    private resolveClosePromise: (value: string) => void;
    sizes: CreativeSize[];

    amountOfSizes: number;
    deleteButtontext: string;

    displaySizeArr: CreativeSize[];
    tooltipSizes: string;

    initiate(creatives: ICreative[]): Promise<string> {
        this.sizes = creatives.map(creative => creative.size);
        this.amountOfSizes = this.sizes.length;
        this.deleteButtontext =
            this.amountOfSizes > 1 ? `Delete sizes (${this.amountOfSizes})` : `Delete size`;
        this.displaySizeArr = this.sizes.slice(0, 3);
        if (this.amountOfSizes > 3) {
            const remainingSizes = this.sizes
                .slice(3)
                .map(uniqueSize => `${uniqueSize.width} &times; ${uniqueSize.height}`);
            this.tooltipSizes = remainingSizes.join('<br>');
        }
        if (!this.closePromise) {
            this.closePromise = new Promise(resolve => (this.resolveClosePromise = resolve));
        }
        return this.closePromise;
    }
    deleteSizes(): void {
        this.resolveClosePromise('confirm');
    }
    cancel(): void {
        this.resolveClosePromise('cancel');
    }
}
