import { IElementSelection } from '@domain/creative/nodes/selection';
import { OneOfDataNodes, OneOfElementDataNodes, OneOfSelectableElements } from '@domain/nodes';
import { getDuration, getTime } from '../animation.utils';
import { asSortedArray, getNodeIndex, isGroupDataNode, toFlatElementNodeList } from './helpers';

export class ElementSelection implements IElementSelection {
    private _elements: Set<OneOfElementDataNodes>;
    get elements(): OneOfElementDataNodes[] {
        return Array.from(this._elements);
    }

    private _nodes: Set<OneOfDataNodes>;
    get nodes(): OneOfDataNodes[] {
        return Array.from(this._nodes);
    }

    get time(): number {
        return getTime(...this.elements);
    }

    get duration(): number {
        return getDuration(...this.elements);
    }

    get element(): OneOfElementDataNodes | undefined {
        return this.elements?.[0];
    }

    constructor(nodes: OneOfDataNodes[] = []) {
        /**
         * Make sure we filter out nodes that are part of an included group
         * as a group selection propagates down to all it's children
         */
        for (const node of nodes) {
            if (isGroupDataNode(node)) {
                nodes = nodes.filter(n => n.__parentNode?.id !== node.id);
            }
        }

        this._elements = new Set(toFlatElementNodeList(nodes));
        this._nodes = new Set(nodes);
    }

    asSortedArray(): OneOfElementDataNodes[] {
        return asSortedArray(this.elements);
    }

    nodesAsSortedArray(): OneOfDataNodes[] {
        return this.nodes.sort((a, b) => getNodeIndex(a) - getNodeIndex(b));
    }

    has(node: OneOfDataNodes): boolean {
        const nodeId = node.id;
        for (const n of [...this.nodes, ...this.elements]) {
            if (n.id === nodeId) {
                return true;
            }

            if (isGroupDataNode(n) && n.findNodeById_m(nodeId, true)) {
                return true;
            }
        }

        return false;
    }

    get length(): number {
        return this.elements.length;
    }
}

export function isElementSelection(
    selection: OneOfSelectableElements | OneOfDataNodes
): selection is IElementSelection {
    return selection instanceof ElementSelection;
}
