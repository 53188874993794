<studio-ui-section headline="Guideline">
    <div class="setting padding-top">
        <!-- Position -->
        <div class="setting-row">
            <div class="setting-label">Position</div>
            <div class="setting-value col-2">
                <div class="property-input">
                    <ui-number-input
                        *ngIf="guideline.type === 'vertical'"
                        [(value)]="x"
                        (valueChange)="updatePosition()"
                        [allowEmpty]="false"
                        [disableUndo]="true"
                        (submit)="updatePosition()"
                        unitLabel="X"></ui-number-input>
                </div>
                <div class="property-input">
                    <ui-number-input
                        *ngIf="guideline.type === 'horizontal'"
                        [(value)]="y"
                        (valueChange)="updatePosition()"
                        [allowEmpty]="false"
                        [disableUndo]="true"
                        (submit)="updatePosition()"
                        unitLabel="Y"></ui-number-input>
                </div>
            </div>
        </div>
    </div>
</studio-ui-section>
