<div
    class="content"
    [class.active]="commentsOpen"
    [class.loading]="loading"
    [class.no-design]="creative && !creative.design"
    [class.selected]="selected$ | async"
    [id]="'interaction-creative-' + index"
    [ngStyle]="{ display: inView ? 'block' : 'none' }"
    (click)="onCreativeClick($event)">
    <div class="header">
        <ng-container
            [ngTemplateOutlet]="headerTemplate"
            [ngTemplateOutletContext]="{
                $implicit: creative,
                creativeIndex: index,
                isCreativeGroup: isCreativeGroup
            }"></ng-container>
    </div>
    <div class="responsive-wrapper">
        <div class="container">
            <div
                #creativeContainer
                class="creativeContainer">
                <ui-loader *ngIf="(visible$ | async) === false"></ui-loader>
                <div
                    #responsiveElement
                    class="responsive"
                    [ngClass]="{ 'in-mobile-showcase': isMobileShowcase }">
                    <div
                        #clickLabel
                        *ngIf="!creativeHasDesign()"
                        class="click-label">
                        Click to edit
                    </div>
                    <ui-loader *ngIf="loading"></ui-loader>
                    <div
                        class="editCreative"
                        *permissions="['EditCreative']; let permissions = permissions"
                        (click)="editCreative($event, permissions)"></div>
                    <studio-creative
                        *ngIf="(visible$ | async) && creative"
                        [creative]="creative"
                        [renderOnInit]="true"
                        [showTargetUrl]="!isMobileShowcase"></studio-creative>
                    <div
                        class="creative-meta"
                        #metaElement>
                        <creative-list-item-meta
                            [creative]="creative"
                            [inView]="inView"
                            (commentsToggled)="commentsOpen = $event"></creative-list-item-meta>
                    </div>
                </div>
            </div>
        </div>

        <div class="footer">
            <ng-container
                *ngTemplateOutlet="
                    footerTemplate;
                    context: { $implicit: creative, index: index, scale: scale }
                "></ng-container>
        </div>
    </div>
</div>
