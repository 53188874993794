import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthGuard as Auth0Guard, AuthService as Auth0AuthService } from '@auth0/auth0-angular';
import { filter, Observable, switchMap, take } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    private auth0Guard = inject(Auth0Guard);
    private auth0AuthService = inject(Auth0AuthService);

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | boolean {
        if (environment.stage === 'test') {
            return true;
        }

        return this.isAuthenticatedAuth0(() => this.auth0Guard.canActivate(route, state));
    }

    private isAuthenticatedAuth0(fn: () => Observable<boolean>): Observable<boolean> {
        return this.auth0AuthService.isAuthenticated$.pipe(
            filter(isAuthenticated => isAuthenticated !== null),
            take(1),
            switchMap(fn)
        );
    }
}
