interface ICursor {
    position: number;
    line: number;
    column: number;
}

interface ICursorDiff {
    position: number;
    line: number;
}

interface IPlaceholderCursor extends ICursor {
    textEnd: string;
    placeholder: string;
}

interface ITextWriterOptions {
    treatWhiteSpaceAsSpace?: boolean;
    indentationSpaces?: number;
}

export class TextWriter {
    indentation = 0;
    private line = 1;
    private column = 0;
    private position = 0;
    private savedLine: number;
    private savedColumn: number;
    private savedPosition: number;
    private placeholders: Map<string, ICursor> = new Map();
    private previousCursors: IPlaceholderCursor[] = [];
    private text = '';
    private indentationSpaces: number;
    private treatWhiteSpaceAsSpace: boolean;

    constructor(options?: ITextWriterOptions) {
        this.indentationSpaces =
            options && typeof options.indentationSpaces !== 'undefined' ? options.indentationSpaces : 4;
        this.treatWhiteSpaceAsSpace = !!options && !!options.treatWhiteSpaceAsSpace;
    }

    writeLine(text?: string): void {
        if (this.column === 0 && !this.treatWhiteSpaceAsSpace) {
            this.writeIndent();
        }
        if (text) {
            if (this.treatWhiteSpaceAsSpace) {
                this.text += text;
                this.text += ' ';
                this.position += text.length + 1;
            } else {
                this.text += text;
                this.text += '\n';
                this.position += text.length + 1;
            }
        } else {
            if (this.treatWhiteSpaceAsSpace) {
                this.text += ' ';
            } else {
                this.text += '\n';
            }
            this.position++;
        }
        this.line++;
        this.column = 0;
    }

    write(text: string): void {
        if (typeof text !== 'string') {
            throw new Error('Text not a string');
        }
        if (this.column === 0 && !this.treatWhiteSpaceAsSpace) {
            this.writeIndent();
        }
        this.text += text;
        this.position += text.length;
        this.column += text.length;
    }

    save(): void {
        if (isNaN(this.position)) {
            throw new Error(`Can't save position`);
        }
        this.savedPosition = this.position;
        this.savedLine = this.line;
        this.savedColumn = this.column;
    }

    restore(): void {
        if (isNaN(this.savedPosition)) {
            throw new Error(`Can't restore position`);
        }
        this.position = this.savedPosition;
        this.line = this.savedLine;
        this.column = this.savedColumn;
        this.text = this.text.substring(0, this.position);
    }

    indent(): void {
        this.indentation++;
    }

    unindent(): void {
        this.indentation--;
    }

    addPlaceholder(name: string): void {
        if (this.placeholders.get(name)) {
            throw new Error(`You cannot override existing placeholder '${name}'.`);
        }
        this.placeholders.set(name, {
            line: this.line,
            column: this.column,
            position: this.position
        });
    }

    removePlaceholder(name: string): void {
        if (!this.placeholders.get(name)) {
            throw new Error(`Placeholder '${name}' has not been added.`);
        }

        this.placeholders.delete(name);
    }

    beginWriteOnPlaceholder(name: string): void {
        const placeholder = this.placeholders.get(name);
        if (!placeholder) {
            throw new Error(`Placeholder '${name}' has not been added.`);
        }
        if (placeholder.position > this.text.length) {
            throw new Error(
                `Cannot begin writing on placeholder '${name}' that begins after the current placeholder '${
                    this.previousCursors.pop()!.placeholder
                }'.`
            );
        }
        this.saveCurrentCursor(name, placeholder);
        this.line = placeholder.line;
        this.column = placeholder.column;
        this.position = placeholder.position;
        this.text = this.text.substring(0, placeholder.position);
    }

    endWriteOnPlaceholder(): void {
        const previousCursor = this.previousCursors.pop()!;
        const placeholder = this.placeholders.get(previousCursor.placeholder)!;
        const diffLine = this.line - placeholder.line;
        this.restorePreviousCursor(previousCursor, {
            position: this.position - placeholder.position,
            line: diffLine
        });
    }

    toString(): string {
        return this.text;
    }

    private saveCurrentCursor(placeholder: string, cursor: ICursor): void {
        const textEnd = this.text.substring(cursor.position);
        this.previousCursors.push({
            position: this.position,
            line: this.line,
            column: this.column,
            placeholder,
            textEnd
        });
    }

    private restorePreviousCursor(previousCursor: IPlaceholderCursor, diff: ICursorDiff): void {
        this.position = previousCursor.position + diff.position;
        this.line = previousCursor.line + diff.line;
        this.column = previousCursor.column;
        this.text += previousCursor.textEnd;
    }

    private writeIndent(): void {
        const numberOfSpaces = this.indentationSpaces * this.indentation;
        this.text += ' '.repeat(numberOfSpaces);
        this.position += numberOfSpaces;
        this.column += numberOfSpaces;
    }
}
