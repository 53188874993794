import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getImageSize, imageUrlToBase64 } from '../utils';
import { GEN_AI_FEATURE_KEY, GenAIState } from './gen-ai.reducer';
import { GenAIOption } from '@studio/domain/components/ai-studio.types';

export const getGenAIState = createFeatureSelector<GenAIState>(GEN_AI_FEATURE_KEY);

export const getLoaded = createSelector(getGenAIState, state => state.loaded);

export const getError = createSelector(getGenAIState, state => state.error);

export const getZoomLevel = createSelector(getGenAIState, state => state.zoomLevel);

export const getGenerativeFillBrushSize = createSelector(
    getGenAIState,
    state => state.generativeFillBrushSettings.size
);
export const getGenerativeFillBrushHistory = createSelector(
    getGenAIState,
    state => state.generativeFillBrushSettings.history
);
export const getGenerativeFillImageMask = createSelector(getGenAIState, state =>
    imageUrlToBase64(state.generativeFillImageMaskLink)
);

export const getEraseBrushSize = createSelector(getGenAIState, state => state.eraseBrushSettings.size);
export const getEraseBrushHistory = createSelector(
    getGenAIState,
    state => state.eraseBrushSettings.history
);
export const getEraseImageMask = createSelector(getGenAIState, state =>
    imageUrlToBase64(state.eraseImageMaskLink)
);

export const getCurrentImage = createSelector(getGenAIState, state => state.currentImage);
export const getCurrentImageAsBase64 = createSelector(getCurrentImage, currentImage =>
    imageUrlToBase64(currentImage)
);

export const getCurrentImageSize = createSelector(getCurrentImage, currentImage =>
    getImageSize(currentImage)
);

export const getPreviousImage = createSelector(getGenAIState, state => state.previousImage);

export const getCurrentImageAsset = createSelector(getGenAIState, state => state.currentImageAsset);

export const getImageUrl = createSelector(
    getCurrentImageAsset,
    currentImageAsset => currentImageAsset?.url
);

export const getCurrentOptionSelected = createSelector(
    getGenAIState,
    state => state.currentOptionSelected
);

export const getOpenedElementId = createSelector(getGenAIState, state => state.openedElementId);
export const getOpenedElementParentFolderId = createSelector(
    getGenAIState,
    state => state.openedElementParentFolderId
);

export const getAssetName = createSelector(getGenAIState, state => state.assetName);

// Background Removal
export const getBackgroundRemovalSettings = createSelector(
    getGenAIState,
    state => state.backgroundRemovalSettings
);

export const getBackgroundRemoved = createSelector(
    getBackgroundRemovalSettings,
    ({ backgroundRemoved }) => backgroundRemoved
);

export const getShowOriginalBackground = createSelector(
    getBackgroundRemovalSettings,
    ({ showOriginalBackground }) => showOriginalBackground
);

// Outpaint
export const getOutpaintSettings = createSelector(getGenAIState, state => state.outpaintSettings);

// Current task
export const getIsRequesting = createSelector(getGenAIState, state => state.currentRequest);
export const getIsRequestingAnything = createSelector(getIsRequesting, isRequesting =>
    Object.values(isRequesting).some(Boolean)
);
export const getIsRequestingOutpaint = createSelector(
    getIsRequesting,
    isRequesting => isRequesting.outpaint
);
export const getIsRequestingInpaint = createSelector(
    getIsRequesting,
    isRequesting => isRequesting.inpaint
);

export const getIsRequestingErase = createSelector(getIsRequesting, isRequesting => isRequesting.erase);

export const getIsRequestingSearchAndReplace = createSelector(
    getIsRequesting,
    isRequesting => isRequesting.searchAndReplace
);
export const getIsRequestingGeneration = createSelector(
    getIsRequesting,
    isRequesting => isRequesting.generation
);
export const getIsRequestingBackgroundRemoval = createSelector(
    getIsRequesting,
    isRequesting => isRequesting.backgroundRemoval
);
export const getIsRequestingOpenInAIStudio = createSelector(
    getIsRequesting,
    isRequesting => isRequesting.openInAIStudio
);
export const getIsRequestingSaveToBrandLibrary = createSelector(
    getIsRequesting,
    isRequesting => isRequesting.saveToBrandLibrary
);
export const getIsRequestingBrandLibrarySave = createSelector(
    getIsRequesting,
    isRequesting => isRequesting.brandLibrarySave
);
export const getIsRequestingBrandLibraryDuplicate = createSelector(
    getIsRequesting,
    isRequesting => isRequesting.brandLibraryDuplicate
);
export const getIsRequestingOnCanvasSaveAndReplace = createSelector(
    getIsRequesting,
    isRequesting => isRequesting.onCanvasSaveAndReplace
);
export const getIsRequestingOnCanvasSaveAsDuplicate = createSelector(
    getIsRequesting,
    isRequesting => isRequesting.onCanvasSaveAsDuplicate
);
export const getSaveOnCanvasPayload = createSelector(getGenAIState, state => state.saveOnCanvasPayload);

// History
export const getHistory = createSelector(getGenAIState, state => state.history);

export const getHasUnsavedChanges = createSelector(getHistory, history =>
    history.undoStack.some(({ type }) => type === 'request')
);

export const getHistoryCanUndo = createSelector(
    getCurrentOptionSelected,
    getHistory,
    (optionSelected, history) => {
        // Reequest can be undone regardless of optionSelected
        if (history.undoStack.some(({ type }) => type === 'request')) {
            return true;
        }
        if (optionSelected === GenAIOption.GenerativeFill) {
            return history.undoStack.some(({ type }) => type === 'generativeFillBrush');
        } else if (optionSelected === GenAIOption.Erase) {
            return history.undoStack.some(({ type }) => type === 'eraseBrush');
        }
        return false;
    }
);

export const getHistoryCanRedo = createSelector(
    getCurrentOptionSelected,
    getHistory,
    (optionSelected, history) => {
        // Reequest can be redone regardless of optionSelected
        if (history.redoStack.some(({ type }) => type === 'request')) {
            return true;
        }
        if (optionSelected === GenAIOption.GenerativeFill) {
            return history.redoStack.some(({ type }) => type === 'generativeFillBrush');
        } else if (optionSelected === GenAIOption.Erase) {
            return history.redoStack.some(({ type }) => type === 'eraseBrush');
        }
        return false;
    }
);
