import { Injectable } from '@angular/core';
import { IWidgetCustomProperty } from '@domain/widget';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WidgetPropertiesService {
    private _propertiesChange$ = new Subject<IWidgetCustomProperty[]>();
    propertiesChange$ = this._propertiesChange$.asObservable();

    private _libraryElementUpdate$ = new Subject<IWidgetCustomProperty[]>();
    libraryElementUpdate$ = this._libraryElementUpdate$.asObservable();

    changeProperties(customProperties: IWidgetCustomProperty[]): void {
        this._propertiesChange$.next(customProperties);
    }

    updateLibraryElement(customProperties: IWidgetCustomProperty[]): void {
        this._libraryElementUpdate$.next(customProperties);
    }
}
