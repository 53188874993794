import { OneOfStatesDtoKeys } from './serialization';
import { ICharacterProperties, ITextElementProperties, TextStyle } from './text';
import { StringUnionToTuple } from './utils/types';

export type TextProperties = StringUnionToTuple<
    keyof ITextElementProperties | keyof TextStyle,
    [
        'content',
        'horizontalAlignment',
        'verticalAlignment',
        'font',
        'fontSize',
        'maxRows',
        'padding',
        'textOverflow',
        'textColor',
        'uppercase',
        'underline',
        'strikethrough',
        'characterSpacing',
        'lineHeight',
        'textShadows',
        'width',
        'height',
        '__fontFamilyId'
    ]
>;

export const textProperties: TextProperties = [
    'content',
    'horizontalAlignment',
    'verticalAlignment',
    'font',
    'fontSize',
    'maxRows',
    'padding',
    'textOverflow',
    'textColor',
    'uppercase',
    'underline',
    'strikethrough',
    'characterSpacing',
    'lineHeight',
    'textShadows',
    'width',
    'height',
    '__fontFamilyId'
];

export type CharacterProperties = StringUnionToTuple<
    keyof ICharacterProperties,
    [
        '__fontFamilyId',
        'characterSpacing',
        'font',
        'fontSize',
        'lineHeight',
        'strikethrough',
        'textColor',
        'textShadows',
        'underline',
        'uppercase'
    ]
>;

export const characterProperties: CharacterProperties = [
    '__fontFamilyId',
    'characterSpacing',
    'font',
    'fontSize',
    'lineHeight',
    'strikethrough',
    'textColor',
    'textShadows',
    'underline',
    'uppercase'
];

export const textAndCharacterProperties = [...textProperties, ...characterProperties] as const;

export type SerializableStateProperties = StringUnionToTuple<
    OneOfStatesDtoKeys,
    [
        'border',
        'fill',
        'filters',
        'id',
        'mirrorX',
        'mirrorY',
        'name',
        'opacity',
        'originX',
        'originY',
        'radius',
        'ratio',
        'rotationX',
        'rotationY',
        'rotationZ',
        'scaleX',
        'scaleY',
        'shadows',
        'textColor',
        'x',
        'y'
    ]
>;

export const serializableStateProperties: SerializableStateProperties = [
    'border',
    'fill',
    'filters',
    'id',
    'mirrorX',
    'mirrorY',
    'name',
    'opacity',
    'originX',
    'originY',
    'radius',
    'ratio',
    'rotationX',
    'rotationY',
    'rotationZ',
    'scaleX',
    'scaleY',
    'shadows',
    'textColor',
    'x',
    'y'
] as const;
