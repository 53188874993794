import { Component, Injector } from '@angular/core';
import { TiktokPlacement } from '@domain/social';
import { BaseOverlayComponent } from '../base-overlay.component';

@Component({
    selector: 'tiktok-overlay',
    templateUrl: 'tiktok-overlay.component.html',
    styleUrls: ['./tiktok-overlay.component.scss', '../shared.scss']
})
export class TiktokOverlayComponent extends BaseOverlayComponent {
    TiktokPlacement = TiktokPlacement;

    constructor(injector: Injector) {
        super(injector);
    }
}
