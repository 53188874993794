import { zodOptional } from '@zod/zod';
import { z } from 'zod';
import { IMediaAsset } from '../../creativeset/element-asset';
import { ZAsset } from './library-asset';

export interface VideoLibraryAsset {
    id: string;
    name: string;
    url: string;
    width: number;
    height: number;
    thumbnail: IMediaAsset;
    fileSize: number;
    durationInMilliseconds: number;
    created: string;
    modified?: string;
}

/**
 * @deprecated Use VideoLibraryAsset interface instead
 */
export const ZVideoLibraryAsset = z.object({
    id: z.string(),
    name: z.string(),
    url: z.string().min(1),
    width: z.number(),
    height: z.number(),
    thumbnail: ZAsset,
    fileSize: z.number(),
    durationInMilliseconds: z.number(),
    created: z.string(),
    modified: zodOptional(z.string())
});
