import { Component, Input, ElementRef } from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { Icon } from '@bannerflow/ui/components/icon/svg-icon/icons';

@Component({
    standalone: true,
    imports: [UIModule],
    selector: 'toolbar-button',
    templateUrl: './toolbar-button.component.html',
    styleUrls: ['./toolbar-button.component.scss']
})
export class ToolbarButtonComponent {
    @Input() icon: Icon;
    @Input() iconSize = 10;

    @Input()
    set width(val: string) {
        this.host.nativeElement.style.maxWidth = val;
    }

    constructor(private host: ElementRef) {}
}
