import { OutpaintImageRequest } from '@studio/domain/api/gen-ai';
import { map, Observable } from 'rxjs';

export function cleanOutpaintRequest(requestBody: OutpaintImageRequest): OutpaintImageRequest {
    const newBody = { ...requestBody };
    for (const [key, value] of Object.entries(newBody)) {
        if (!value) {
            delete newBody[key];
        }
    }
    return newBody;
}

export function handlePostResponse(source$: Observable<Blob>): Observable<string> {
    return source$.pipe(
        map(blob => {
            const linkToBase64 = URL.createObjectURL(blob);
            return linkToBase64;
        })
    );
}
