export interface IInjectionParameter<T> {
    optional: boolean;
    token: T;
    forwardRef: boolean;
}

export interface IChildContainerInjection {
    parameterIndex: number;
    target: new (...args: any[]) => any;
}

export interface IInjectionOption {
    optional?: boolean;
    forwardRef?: boolean;
}

export type ParameterMap<T> = Map<number, IInjectionParameter<T>>;

declare type ParameterDecorator = (
    target: object,
    propertyKey: string | symbol | undefined,
    parameterIndex: number
) => void;

const metadata = new Map<any, Map<string, any>>();

export function defineMetadata(name: string, value: any, target: any): void {
    let decorations = metadata.get(target);
    if (!decorations) {
        decorations = new Map<string, any>();
    }
    decorations.set(name, value);
    metadata.set(target, decorations);
}

export function getOwnMetadata(name: string, target: any, _?: string | symbol): any {
    const decorations = metadata.get(target);
    if (!decorations) {
        return undefined;
    }
    return decorations.get(name);
}

/**
 * USE TOGETHER WITH __inject
 *
 * Inject dependency to a class.
 *
 * @example
 * class RichText {
 *     constructor(@inject(T.RichTextSelection) selection: IRichTextSelection)
 * }
 */
export function inject<T>(token: T, option: IInjectionOption = {}): ParameterDecorator {
    return function (
        target: object,
        propertyKey: string | symbol | undefined,
        parameterIndex: number
    ): void {
        const parameterMap: ParameterMap<T> =
            getOwnMetadata('i', target, propertyKey) || new Map<symbol, IInjectionParameter<T>>();
        parameterMap.set(parameterIndex, {
            token,
            optional: option.optional ?? false,
            forwardRef: option.forwardRef ?? false
        });
        defineMetadata('i', parameterMap, target);
    };
}

/**
 * Angular remove decorators. So we need this helper decorator.
 * https://github.com/angular/angular-cli/issues/9306
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function __inject<T>(
    token: T,
    option: IInjectionOption = {},
    target: object,
    propertyKey: string | symbol,
    parameterIndex: number
): void {
    const parameterMap: ParameterMap<T> =
        getOwnMetadata('i', target, propertyKey) || new Map<symbol, IInjectionParameter<T>>();
    parameterMap.set(parameterIndex, {
        token,
        optional: option.optional ?? false,
        forwardRef: option.forwardRef ?? false
    });
    defineMetadata('i', parameterMap, target);
}

/**
 * USE TOGETHER WITH __parent
 *
 * Inject parent to a class.
 *
 * @example
 * class RichText {
 *     constructor(@parent() text: IRichText)
 * }
 */
export function parent(): ParameterDecorator {
    return function (
        target: object,
        propertyKey: string | symbol | undefined,
        parameterIndex: number
    ): void {
        defineMetadata('p', parameterIndex, target);
    };
}

/**
 * Angular remove decorators. So we need this helper decorator.
 * https://github.com/angular/angular-cli/issues/9306
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function __parent(target: any, propertyKey: string | symbol, parameterIndex: number): void {
    defineMetadata('p', parameterIndex, target);
}

export function DIContainer(): ParameterDecorator {
    return function (
        target: any,
        propertyKey: string | symbol | undefined,
        parameterIndex: number
    ): void {
        defineMetadata('c', parameterIndex, target);
    };
}

/**
 * Angular remove decorators. So we need this helper decorator.
 * https://github.com/angular/angular-cli/issues/9306
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function __container(target: any, propertyKey: string | symbol, parameterIndex: number): void {
    defineMetadata('c', parameterIndex, target);
}
