<div
    class="wrapper"
    #wrapper
    [style.--zoom-level]="scale()">
    @switch (currentSelectedOption()) {
        @case (GenAIOption.GenerativeFill) {
            <generative-fill-canvas />
        }
        @case (GenAIOption.Erase) {
            <erase-canvas />
        }
        @case (GenAIOption.Outpaint) {
            <outpaint-canvas />
        }
        @case (GenAIOption.RemoveBackground) {
            <remove-background-canvas />
        }
        @default {
            @let image = currentImage();
            @if (image) {
                @let imageSize = size();
                <img
                    [width]="imageSize.width"
                    [height]="imageSize.height"
                    [src]="image"
                    alt="Asset to be edited" />
            }
        }
    }
</div>
@if (currentRequestAnything()) {
    <descriptive-loader
        [overlay]="true"
        [type]="loaderType" />
}
