import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { isImageNode } from '@creative/nodes/helpers';
import { ImageSizeMode } from '@domain/image';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PropertiesService } from '../../properties.service';

@Component({
    selector: 'fit-options',
    templateUrl: './fit-options.component.html',
    styleUrls: ['fit-options.component.scss', '../../common.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FitOptionsComponent implements OnInit, OnDestroy {
    @Input() sizeMode: ImageSizeMode | 'mixed';
    @Output() sizeModeChanged = new EventEmitter<ImageSizeMode>();

    classEmptyState = false;
    ImageSizeMode = ImageSizeMode;
    private unsubscribe$ = new Subject<void>();

    constructor(
        private changeDetector: ChangeDetectorRef,
        private propertiesService: PropertiesService
    ) {}

    ngOnInit(): void {
        this.propertiesService
            .observeDataElementOrStateChange()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(({ element }) => {
                if (isImageNode(element)) {
                    this.sizeMode = element.imageSettings.sizeMode;
                    this.changeDetector.detectChanges();
                }
                this.classEmptyState = this.propertiesService.stateValueIsUndefined('imageSettings');
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    setSizeMode(option: ImageSizeMode): void {
        this.sizeModeChanged.emit(option);
    }
}
