<div class="wrapper">
    <ui-select
        [allowUnselected]="true"
        [multiSelect]="true"
        [searchable]="true"
        [useTargetWidth]="true"
        [value]="values()"
        [placeholder]="placeholder()"
        (selectedChange)="onSelectedChanged($event)">
        <span ui-select-summary-value>
            {{ summaryValue() ?? '' }}
        </span>

        @let _collections = collections();
        @if (_collections.length) {
            <div class="collections-container">
                <div class="title">
                    <strong> Quick filter by collection </strong>
                </div>
                @for (collection of _collections; track collection.id) {
                    <ui-option
                        [value]="collection"
                        [selected]="collection.selected"
                        (click)="toggleCollection(collection)">
                        <div class="size">
                            <span>
                                {{ collection.name }}
                            </span>
                        </div>
                    </ui-option>
                }
            </div>
        }
        <div class="sizes-container">
            <div class="title">
                <strong> All sizes </strong>
            </div>
            @for (size of sizes(); track size.id) {
                <ui-option
                    (click)="toggleSize(size)"
                    [selected]="size.selected"
                    [value]="size">
                    <div class="size">
                        <span>
                            {{ size.width }} × {{ size.height }}
                            @if (size.name) {
                                <span class="size-name">{{ size.name }}</span>
                            }
                        </span>
                    </div>
                </ui-option>
            }
        </div>
    </ui-select>
</div>
