<ui-help-menu
    (showIntercom)="onIntercom()"
    (showIntercomNews)="onIntercomNews()"
    (showKeyboardShortcuts)="onKeyboardShortcuts()" />

<div
    class="ui-popover-target"
    ui-popover-target
    #keymapOptionsPopoverTarget="ui-popover-target"></div>

<keymap-options-popover #keymapOptionsPopover />
