import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CreativesetDataService } from '../../../../shared/creativeset/creativeset.data.service';
import { CreativeSize } from '@domain/creativeset';
import { ICreative } from '@domain/creativeset/creative/creative';
import { hasDesign } from '@studio/utils/design.utils';
import { BehaviorSubject, Subject, filter, merge, takeUntil } from 'rxjs';
import { EditCreativeService } from '../edit-creative.service';
import { renderingOptions } from './social-placements.data';

@Injectable({
    providedIn: 'root'
})
export class SocialPlacementsService implements OnDestroy {
    private _isSocialCampaignAvailable$ = new BehaviorSubject<boolean>(false);
    isSocialCampaignAvailable$ = this._isSocialCampaignAvailable$.asObservable();

    unsubscribe$ = new Subject<void>();

    constructor(
        private creativeDataService: CreativesetDataService,
        private editCreativeService: EditCreativeService,
        private router: Router
    ) {
        this._isSocialCampaignAvailable$.next(this._isSocialCampaignAvailable());

        merge(
            this.editCreativeService.updateView$,
            this.router.events.pipe(filter(event => event instanceof NavigationEnd))
        )
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this._isSocialCampaignAvailable$.next(this._isSocialCampaignAvailable());
            });
    }

    private _isSocialCampaignAvailable(): boolean {
        const creativesWithDesigns = this.creativeDataService.creativeset.creatives.filter(creative =>
            hasDesign(creative)
        );
        return this._haveAtLeastOneValidSocialCampaignSize(creativesWithDesigns);
    }

    private _haveAtLeastOneValidSocialCampaignSize(creatives: ICreative[]): boolean {
        const validCreatives = creatives.filter(creative =>
            this._isValidSocialCampaignSize(creative.size)
        );
        return validCreatives.length > 0;
    }

    private _isValidSocialCampaignSize(size: CreativeSize): boolean {
        const { width, height } = size;

        return renderingOptions.some(
            option =>
                this._isSizeMatchingMinimumDimensions(
                    width,
                    height,
                    option.resolutionMinWidth,
                    option.resolutionMinHeight
                ) && this._isSizeMatchingAspectRatio(width, height, option.aspectRatioGroup)
        );
    }

    private _isSizeMatchingMinimumDimensions(
        creativeWidth: number,
        creativeHeight: number,
        resolutionMinWidth: number,
        resolutionMinHeight: number
    ): boolean {
        return creativeWidth >= resolutionMinWidth && creativeHeight >= resolutionMinHeight;
    }

    private _isSizeMatchingAspectRatio(
        width: number,
        height: number,
        placementAspectRatio: string
    ): boolean {
        const aspectRatio = (width / height).toFixed(2);

        const placementRatioHeight = Number(placementAspectRatio.split(':')[1]);
        const placementRatioWidth = Number(placementAspectRatio.split(':')[0]);

        const placementRatio = (placementRatioWidth / placementRatioHeight).toFixed(2);

        return aspectRatio === placementRatio;
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
