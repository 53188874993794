import { Injectable, OnDestroy } from '@angular/core';
import { IBounds, IPosition } from '@domain/dimension';
import { setCursorOnElement } from '@studio/utils/cursor';
import { getMousewheelSpeed } from '@studio/utils/mouse-observable';
import { clamp } from '@studio/utils/utils';
import { Subject, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StudioTimelineComponent } from './studio-timeline/studio-timeline.component';
import { TimelineScrollService } from './timeline-scroll.service';

@Injectable()
export class TimelineZoomService implements OnDestroy {
    private _zoom$ = new Subject<number>();
    zoom$ = this._zoom$.asObservable();

    zoom = 72;
    minZoom = 50;
    maxZoom = 122;
    zoomBox?: IBounds;

    private unsubcribe$ = new Subject<void>();
    private timeline?: StudioTimelineComponent;

    constructor(private scrollService: TimelineScrollService) {}

    connect(timeline: StudioTimelineComponent): TimelineZoomService {
        this.timeline = timeline;

        const mouseObservable = timeline.mouseObservable;
        mouseObservable.mouseDown$
            .pipe(takeUntil(this.unsubcribe$))
            .subscribe(({ mousePosition }) => this.mouseDown(mousePosition));

        mouseObservable.mouseDownMove$
            .pipe(takeUntil(this.unsubcribe$))
            .subscribe(({ mouseDelta }) => this.mouseDownMove(mouseDelta));

        mouseObservable.mouseUp$
            .pipe(takeUntil(this.unsubcribe$))
            .subscribe(({ event }) => this.mouseUp(event));

        mouseObservable.mouseWheel$
            .pipe(takeUntil(this.unsubcribe$))
            .subscribe(({ event }) => this.mouseWheel(event));

        fromEvent(timeline.host.nativeElement, 'keyup')
            .pipe(takeUntil(this.unsubcribe$))
            .subscribe(() => {
                setCursorOnElement(timeline.host.nativeElement);
            });

        timeline.hotkeyBetterService.on('ZoomTimeline', this.mouseWheel);
        timeline.hotkeyBetterService.on('EnableZoomOut', this.onEnableZoomOut);
        timeline.hotkeyBetterService.on('EnableZoom', this.onEnableZoom);
        timeline.hotkeyBetterService.on('ZoomIn', this.zoomIn);
        timeline.hotkeyBetterService.on('ZoomOut', this.zoomOut);
        return this;
    }

    disconnect(): void {
        if (this.timeline) {
            this.timeline.hotkeyBetterService.off('ZoomTimeline', this.mouseWheel);
            this.timeline.hotkeyBetterService.off('EnableZoomOut', this.onEnableZoomOut);
            this.timeline.hotkeyBetterService.off('EnableZoom', this.onEnableZoom);
            this.timeline.hotkeyBetterService.off('ZoomIn', this.zoomIn);
            this.timeline.hotkeyBetterService.off('ZoomOut', this.zoomOut);
            this.timeline = undefined;
        }
    }

    private onEnableZoom = (): void => {
        if (!this.timeline) {
            return;
        }

        setCursorOnElement(this.timeline.host.nativeElement, 'zoom-in');
    };

    private onEnableZoomOut = (): void => {
        if (!this.timeline) {
            return;
        }

        setCursorOnElement(this.timeline.host.nativeElement, 'zoom-out');
    };

    setZoom(zoom: number, position?: IPosition): void {
        const timeline = this.timeline;

        if (!timeline) {
            return;
        }

        zoom = clamp(zoom, this.minZoom, this.maxZoom);

        if (this.zoom !== zoom) {
            let scrollX = 0;

            // Canvas has elements, keep playhead centered
            if (timeline.nodes.length) {
                const duration = timeline.duration;
                const time = timeline.animator.time;
                const xPos = position ? position.x : 0;
                const p = duration * zoom - duration * this.zoom;
                const rel =
                    (position ? xPos + timeline.scroll.x : time * this.zoom) / (duration * this.zoom);
                scrollX = Math.max(timeline.scroll.x + p * rel, 0);
            }
            this.zoom = zoom;
            this._zoom$.next(zoom);
            this.scrollService.setScroll({
                x: scrollX
            });
        }
    }

    zoomIn = (position?: IPosition): void => {
        if (this.timeline?.mouseOverTimeline) {
            this.setZoom(this.zoom * 1.1, position);
        }
    };

    zoomOut = (position?: IPosition): void => {
        if (this.timeline?.mouseOverTimeline) {
            this.setZoom(this.zoom / 1.1, position);
        }
    };

    pixelsToSeconds(pixels: number): number {
        return pixels / this.zoom;
    }

    secondsToPixels(seconds: number): number {
        return seconds * this.zoom;
    }

    private mouseDown = (mousePosition: IPosition): void => {
        const timeline = this.timeline;
        if (timeline?.isZooming) {
            this.zoomBox = {
                x: mousePosition.x,
                y: mousePosition.y,
                width: 0,
                height: 0
            };
        }
    };

    private mouseDownMove = (mouseDelta: IPosition): void => {
        const timeline = this.timeline;
        if (timeline?.isZooming) {
            if (this.zoomBox) {
                this.zoomBox.width = mouseDelta.x;
                this.zoomBox.height = mouseDelta.y;

                timeline.gizmoDrawer.draw();
            }
        }
    };

    private mouseUp = (event: MouseEvent): void => {
        const timeline = this.timeline;

        if (!timeline?.isZooming) {
            return;
        }

        if (this.zoomBox) {
            if (this.zoomBox.width < 0) {
                this.zoomBox.x += this.zoomBox.width;
                this.zoomBox.width = this.zoomBox.width * -1;
            }
            if (this.zoomBox.height < 0) {
                this.zoomBox.y += this.zoomBox.height;
                this.zoomBox.height = this.zoomBox.height * -1;
            }

            // If zoombox is too small, treat as click
            if (this.zoomBox.height * this.zoomBox.width < 50) {
                if (!event.altKey) {
                    this.zoomIn();
                } else {
                    this.zoomOut();
                }
                this.zoomBox = undefined;
                return;
            }

            const { x, width } = this.zoomBox;
            const newZoom = (timeline.host.nativeElement.offsetWidth / width) * this.zoom;
            const ratio = (x + width / 2) / (timeline.host.nativeElement.offsetWidth / 2) - 1;

            this.setZoom(newZoom, {
                x: x + width / 2 + width * ratio,
                y: 0
            });

            this.zoomBox = undefined;

            timeline.gizmoDrawer.draw();
        } else if (!event.altKey) {
            this.zoomIn();
        } else {
            this.zoomOut();
        }
    };

    private mouseWheel = (event: WheelEvent): void => {
        const timeline = this.timeline;
        if (timeline && (event.ctrlKey || event.metaKey)) {
            const speed = getMousewheelSpeed(event);
            this.setZoom(this.zoom - speed.y);
        }
    };

    ngOnDestroy(): void {
        this.disconnect();
    }
}
