import {
    ISelectableSize,
    ISizeCollection,
    SizeCompatibility
} from '@studio/domain/components/size-list.types';
import { DefaultCollectionsEnum, SelectedSizes } from '@studio/domain/state/size-add.types';

export function getSocialSizes(): ISelectableSize[] {
    return [
        {
            id: 'social-300x60',
            width: 300,
            height: 60,
            thumbnail: 'creative_300x60',
            compatibility: [SizeCompatibility.YouTube],
            amount: 0
        },
        {
            id: 'social-600x900',
            width: 600,
            height: 900,
            thumbnail: 'creative_600x900',
            compatibility: [SizeCompatibility.Linkedin],
            amount: 0
        },
        {
            id: 'social-1080x1080',
            width: 1080,
            height: 1080,
            thumbnail: 'creative_1080x1080',
            compatibility: [
                SizeCompatibility.Facebook,
                SizeCompatibility.Instagram,
                SizeCompatibility.Messenger,
                SizeCompatibility.YouTube,
                SizeCompatibility.Tiktok,
                SizeCompatibility.Linkedin,
                SizeCompatibility.Twitter,
                SizeCompatibility.Pinterest
            ],
            amount: 0
        },
        {
            id: 'social-1080x1350',
            width: 1080,
            height: 1350,
            thumbnail: 'creative_1080x1350',
            compatibility: [
                SizeCompatibility.Facebook,
                SizeCompatibility.Instagram,
                SizeCompatibility.Messenger
            ],
            amount: 0
        },
        {
            id: 'social-1080x1920',
            width: 1080,
            height: 1920,
            thumbnail: 'creative_1080x1920',
            compatibility: [
                SizeCompatibility.Facebook,
                SizeCompatibility.Instagram,
                SizeCompatibility.Messenger,
                SizeCompatibility.YouTube,
                SizeCompatibility.Tiktok,
                SizeCompatibility.Snapchat,
                SizeCompatibility.Linkedin,
                SizeCompatibility.Twitter,
                SizeCompatibility.Pinterest
            ],
            amount: 0
        },
        {
            id: 'social-1200x628',
            width: 1200,
            height: 628,
            thumbnail: 'creative_1200x628',
            compatibility: [
                SizeCompatibility.Facebook,
                SizeCompatibility.Instagram,
                SizeCompatibility.Linkedin
            ],
            amount: 0
        },
        {
            id: 'social-1920x1080',
            width: 1920,
            height: 1080,
            thumbnail: 'creative_1920x1080',
            compatibility: [
                SizeCompatibility.Facebook,
                SizeCompatibility.Instagram,
                SizeCompatibility.YouTube,
                SizeCompatibility.Tiktok,
                SizeCompatibility.Linkedin,
                SizeCompatibility.Twitter,
                SizeCompatibility.Pinterest
            ],
            amount: 0
        }
    ];
}

export function getOrphanSizesCollection(
    sizes: ISelectableSize[],
    collections: ISizeCollection[]
): ISizeCollection {
    const orphanSizes: string[] = [];
    for (const size of sizes) {
        if (!collections.some(({ sizeFormatIds }) => sizeFormatIds?.includes(size.id))) {
            orphanSizes.push(size.id);
        }
    }

    return {
        id: DefaultCollectionsEnum.OrphanSizes,
        name: 'Sizes with no collection',
        sizeFormatIds: orphanSizes
    };
}

export function getCustomSizesCollection(): ISizeCollection {
    return {
        id: DefaultCollectionsEnum.CustomSizes,
        name: 'Custom Sizes',
        sizeFormatIds: []
    };
}

export function getSocialSizesCollection(): ISizeCollection {
    const socialSizes = getSocialSizes();

    return {
        id: DefaultCollectionsEnum.SocialSizes,
        name: 'Social Sizes',
        sizeFormatIds: socialSizes.map(({ id }) => id)
    };
}

export function selectAllSizes(
    oldSelectedSizes: SelectedSizes,
    sizeIdsToUpdate: string[]
): SelectedSizes {
    const newSelectedSizes: SelectedSizes = {
        ...oldSelectedSizes
    };
    for (const sizeId of sizeIdsToUpdate) {
        newSelectedSizes[sizeId] = {
            numberOfSizes: (oldSelectedSizes[sizeId]?.numberOfSizes ?? 0) + 1
        };
    }
    return newSelectedSizes;
}

export function deselectAllSizes(
    oldSelectedSizes: SelectedSizes,
    sizeIdsToUpdate: string[]
): SelectedSizes {
    const newSelectedSizes: SelectedSizes = {
        ...oldSelectedSizes
    };
    for (const sizeId of sizeIdsToUpdate) {
        newSelectedSizes[sizeId] = {
            numberOfSizes: 0
        };
    }
    return newSelectedSizes;
}

export function getSizeIdsToUpdate(
    selectedCollectionIds: string[],
    collections: ISizeCollection[],
    sizes: ISelectableSize[]
): string[] {
    let sizeIdsToUpdate: string[] = [];
    if (selectedCollectionIds.length) {
        for (const collectionId of selectedCollectionIds) {
            const collection = collections.find(({ id }) => id === collectionId);
            if (!collection?.sizeFormatIds) {
                continue;
            }
            sizeIdsToUpdate.push(...collection.sizeFormatIds);
        }
        sizeIdsToUpdate = Array.from(new Set(sizeIdsToUpdate));
    } else {
        sizeIdsToUpdate = sizes.map(({ id }) => id);
    }
    return sizeIdsToUpdate;
}
