import { Injectable } from '@angular/core';
import { OneOfDataNodes } from '@domain/nodes';
import { Subject } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';

type HighlightContext = 'workspace' | 'timeline';

export interface INodeHighlight {
    node: OneOfDataNodes;
    context: HighlightContext;
}

@Injectable()
export class ElementHighlightService {
    private _elementHighlight$ = new Subject<INodeHighlight | undefined>();
    currentHighlight: INodeHighlight | undefined;

    elementHighlight$ = this._elementHighlight$.asObservable().pipe(
        distinctUntilChanged(
            (prev, curr) => prev?.node.id === curr?.node.id && prev?.context === curr?.context
        ),
        tap(highlight => {
            this.currentHighlight = highlight;
        })
    );

    setHighlight(node: undefined, context?: HighlightContext): void;
    setHighlight(node: OneOfDataNodes, context: HighlightContext): void;
    setHighlight(node: OneOfDataNodes | undefined, context: HighlightContext): void {
        if (node) {
            this._elementHighlight$.next({ node, context });
            return;
        }

        this._elementHighlight$.next(undefined);
    }

    clearHighlight(): void {
        this.setHighlight(undefined);
    }
}
