<div class="left-panel">
    <div class="gif-icon">
        <ui-svg-icon icon="gif-frame"></ui-svg-icon>
    </div>
    <div class="name">GIF frames</div>
    <div
        #addIcon
        id="interaction-add-gif-frame"
        class="add-icon"
        (click)="addFrame()">
        <ui-svg-icon icon="plus-small"></ui-svg-icon>
    </div>
</div>
<div
    class="right-canvas"
    [ngClass]="{ scrolled: scrollService.scroll.x }">
    <div
        id="interaction-gif-frames"
        class="frames">
        <div
            class="frame"
            [ngClass]="{
                selected: frame === selectedFrame || frame === openFrame,
                disabled: frame.time > document.duration
            }"
            *ngFor="let frame of frames"
            (mousedown)="onMouseDown(frame)"
            (click)="openFramePopover(frameElement, frame)"
            ui-popover-target
            #frameElement="ui-popover-target"
            style.transform="translateX({{ getXFromTime(frame) }}px)">
            <ui-svg-icon icon="gif-frame-small"></ui-svg-icon>
        </div>
    </div>
    <time-ruler [showNumbers]="false"></time-ruler>
</div>

<ui-popover
    (close)="onFramePopoverClose()"
    #framePopover="ui-popover"
    [config]="{
        arrowPosition: 'bottom',
        position: 'top',
        theme: 'tiny',
        panelClass: 'no-padding',
        width: 130
    }">
    <ng-template ui-popover-template>
        <div
            class="frame-popover"
            *ngIf="openFrame">
            <div class="row">
                <div class="text">Time</div>
                <ui-number-input
                    [id]="'popover-time'"
                    [value]="openFrame.time"
                    (valueChange)="moveFrame(openFrame, $event)"
                    [step]="0.1"
                    [min]="0"
                    [max]="document.duration"
                    unitLabel="s">
                </ui-number-input>
            </div>
            <div class="row">
                <div class="text">Duration</div>
                <ui-number-input
                    [id]="'popover-duration'"
                    [value]="openFrame.duration"
                    (valueChange)="setFrameDuration(openFrame, $event)"
                    [allowEmpty]="true"
                    placeholder="Auto"
                    [min]="0"
                    [step]="0.1"
                    [unitLabel]="openFrame.duration !== undefined ? 's' : undefined">
                </ui-number-input>
            </div>
            <div class="row icon">
                <ui-svg-icon
                    (click)="deleteFrame(openFrame)"
                    icon="delete">
                </ui-svg-icon>
            </div>
        </div>
    </ng-template>
</ui-popover>
