import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ICulture, ILocalization } from '@domain/creativeset/version';
import { TranslationRequestDto, TranslationResponseDto } from '@domain/translations';
import { Logger } from '@bannerflow/sentinel-logger';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class BannerlingoDataService {
    private readonly BANNERLINGO_ENDPOINT: string;
    private logger = new Logger('BannerlingoDataService');

    constructor(private httpClient: HttpClient) {
        this.BANNERLINGO_ENDPOINT = environment.origins.bannerlingo;
    }

    translateTexts(
        texts: string[],
        target: ILocalization,
        source?: ILocalization
    ): Observable<string[]> {
        this.logger.verbose(`Translating ${texts.length} texts to ${target.cultureCode}`);
        return this.getTextTranslations(texts, target, source).pipe(
            map(translationsDto => translationsDto.translations.map(translation => translation.text))
        );
    }

    private getTextTranslations(
        texts: string[],
        target: ILocalization,
        source?: ILocalization
    ): Observable<TranslationResponseDto> {
        const url = `${this.BANNERLINGO_ENDPOINT}/api/translation/translate`;
        const body = this.createTranslationRequestDto(texts, target, source);

        return this.httpClient.post<TranslationResponseDto>(url, body);
    }

    private createTranslationRequestDto(
        texts: string[],
        targetLocalization: ILocalization,
        sourceLocalization?: ILocalization
    ): TranslationRequestDto {
        let sourceCulture: ICulture | undefined;
        if (sourceLocalization) {
            sourceCulture = this.toCulture(sourceLocalization);
        }
        return { texts, targetCulture: this.toCulture(targetLocalization), sourceCulture };
    }

    private toCulture(localization: ILocalization): ICulture {
        return {
            cultureName: localization.cultureName,
            cultureCode: localization.cultureCode
        };
    }
}
