@if (enabled$ | async) {
    <div
        class="hover-area"
        (mouseover)="hoverPopoverRoot.open(hoverTarget)"
        ui-popover-target
        #hoverTarget="ui-popover-target"></div>
}
<ui-popover
    #hoverPopoverRoot="ui-popover"
    [config]="{
        arrowPosition: 'bottom',
        position: 'top',
        panelClass: 'no-padding devtools',
        maxWidth: 500,
        width: 'auto',
        backdropClickClose: false,
        hasBackdrop: false,
        popoverType: 'menu',
        offset: { y: 35, x: 0 }
    }">
    <ng-template ui-popover-template>
        <div class="wrapper with-content">
            <ui-svg-icon
                icon="close"
                (click)="hoverPopoverRoot.close()"></ui-svg-icon>
            <div class="header">DEVTOOLS</div>
            <div class="content">
                <ui-button
                    svgIcon="preview_ads"
                    [uiTooltip]="'Inspect node (click on a node to output to console)'"
                    [class.active]="elementSelectorEnabled"
                    [disabled]="!designView"
                    (click)="toggleElementSelector()"></ui-button>
                <ui-button
                    text="Watch node changes"
                    uiTooltip="Click and select which node to watch"
                    [disabled]="!designView"
                    (click)="watchInspectedNode()"></ui-button>
                <ui-button
                    svgIcon="log"
                    [uiTooltip]="'Logging'"
                    (click)="loggingPopover.open(loggingPopoverTarget)"></ui-button>
                <ui-button
                    svgIcon="composition-new"
                    uiTooltip="Show preview options"
                    [disabled]="!designView"
                    (click)="showPreviewOptions()"></ui-button>
                <ui-button
                    svgIcon="settings"
                    uiTooltip="Show dev settings"
                    (click)="settingsPopover.open(settingsPopoverTarget)"></ui-button>
            </div>
        </div>
    </ng-template>
</ui-popover>

<ui-popover
    #loggingPopover="ui-popover"
    ui-popover-target
    #loggingPopoverTarget="ui-popover-target"
    [config]="{
        arrowPosition: 'bottom',
        position: 'top',
        panelClass: 'no-padding devtools',
        maxWidth: 500,
        width: 'auto',
        backdropClickClose: true,
        hasBackdrop: true,
        offset: { y: 0, x: 100 }
    }">
    <ng-template ui-popover-template>
        <div class="wrapper with-content">
            <ui-svg-icon
                icon="close"
                (click)="loggingPopover.close()"></ui-svg-icon>
            <div class="header">Log to console</div>
            <div class="content">
                <ui-button
                    text="Page state"
                    (click)="logPageState()"></ui-button>
                <ui-button
                    text="Environments"
                    (click)="logEnvironments()"></ui-button>
                <ui-button
                    text="Creativeset data"
                    (click)="logCreativesetData()"></ui-button>
            </div>
            <div class="copy">
                <ui-checkbox
                    [(selected)]="copyToClipboard"
                    label="Copy to clipboard"></ui-checkbox>
            </div>
        </div>
    </ng-template>
</ui-popover>

<ui-popover
    #settingsPopover="ui-popover"
    ui-popover-target
    #settingsPopoverTarget="ui-popover-target"
    [config]="{
        arrowPosition: 'bottom',
        position: 'top',
        panelClass: 'no-padding devtools',
        maxWidth: 500,
        width: 'auto',
        backdropClickClose: true,
        hasBackdrop: true,
        offset: { y: 0, x: 100 }
    }">
    <ng-template ui-popover-template>
        <div class="wrapper with-content">
            <ui-svg-icon
                icon="close"
                (click)="settingsPopover.close()"></ui-svg-icon>
            <div class="header">Settings</div>
            <div class="content">
                <ui-checkbox
                    label="Enabled"
                    [selected]="(enabled$ | async)!"
                    uiTooltip="Disable devtools"
                    (selectedChange)="disableDevtools()"></ui-checkbox>
                <ui-checkbox
                    label="Feature toggle"
                    [selected]="(featureToggleEnabled$ | async)!"
                    (selectedChange)="toggleFeatureToggle($event)"></ui-checkbox>
            </div>
        </div>
    </ng-template>
</ui-popover>
