import {
    BeginWeightCalculationResult,
    BeginWeightCalculationVariables,
    CreativeWeightsResult,
    CreativeWeightsVariables
} from '@domain/creativeset/creative/weight';
import { gql } from 'apollo-angular';

export const BEGIN_WEIGHT_CALCULATION = gql<
    BeginWeightCalculationResult,
    BeginWeightCalculationVariables
>`
    mutation BeginWeightCalculationMutation($creativesetId: String!, $creativeIds: [String]!) {
        beginWeightCalculation(
            creativesToWeigh: [{ creativesetId: $creativesetId, creativeIds: $creativeIds }]
        ) {
            creatives {
                id
                checksum
            }
            failures {
                id
                message
            }
        }
    }
`;

export const GET_CREATIVE_WEIGHTS = gql<CreativeWeightsResult, CreativeWeightsVariables>`
    query CreativeWeightsByChecksumsQuery($creativeChecksumList: [String]!) {
        creativeWeightsByChecksums(creativeChecksumList: $creativeChecksumList) {
            id
            creativeId
            creativeChecksum
            failed
            weights {
                url
                totalWeight
                frameworkOverhead {
                    totalWeight
                    assets {
                        weight
                        name
                        url
                        parentUrl
                        type
                    }
                }
                initialLoad {
                    totalWeight
                    assets {
                        weight
                        name
                        url
                        parentUrl
                        type
                    }
                }
                subLoad {
                    totalWeight
                    assets {
                        weight
                        name
                        url
                        parentUrl
                        type
                    }
                }
            }
        }
    }
`;
