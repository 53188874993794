import { Injectable, inject } from '@angular/core';
import { SentinelService } from '@bannerflow/sentinel';
import { Logger } from '@bannerflow/sentinel-logger';
import { ILogger } from '@domain/logger';
import {
    StudioActivityEvent,
    StudioCaptureEvent,
    StudioConsoleEvent,
    StudioEvent,
    StudioTrackerEvent
} from '@studio/domain/monitoring/event-logger.interface';
import { ActivityLoggerService } from '../activity-logger.service';

@Injectable({ providedIn: 'root' })
export class EventLoggerService {
    private fallbackLogger = new Logger();
    private loggedEvents = new Set<string>();
    private sentinelService = inject(SentinelService);
    private activityService = inject(ActivityLoggerService);

    public log(event: StudioEvent, logger?: ILogger): void {
        if (event.unique) {
            const uniqueIdentifier = `${event.constructor.name}-${event?.uniqueIdentifier?.() || ''}`;
            if (this.loggedEvents.has(uniqueIdentifier)) {
                return;
            }
            this.loggedEvents.add(uniqueIdentifier);
        }

        if (isActivityEvent(event)) {
            this.activityService.log(event.description, event.data);
        }

        if (isTrackingEvent(event)) {
            if (event.start === true) {
                this.sentinelService.startTrace(event.message);
            } else if (event.start === false) {
                this.sentinelService.finishTrace(event.message);
            } else {
                this.sentinelService.trackPageAction(event.message, event.data);
            }
        }

        if (isCaptureEvent(event)) {
            this.sentinelService.error(event, {
                ...event.data,
                severity: event.severity,
                description: event.description
            });
        }

        if (isConsoleEvent(event)) {
            const loggerInstance = logger ?? this.fallbackLogger;
            const logLevel = event.level ?? 'log';
            loggerInstance[logLevel](event.description);
        }
    }
}

function isConsoleEvent(event: StudioEvent): event is StudioConsoleEvent {
    return !!event.log;
}

function isActivityEvent(event: StudioEvent): event is StudioActivityEvent {
    return !!event.activity;
}

function isTrackingEvent(event: StudioEvent): event is StudioTrackerEvent {
    return !!event.track;
}

function isCaptureEvent(event: StudioEvent): event is StudioCaptureEvent {
    return !!event.capture;
}
