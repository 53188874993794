@let colorMixedValue = colorMixed();
@let disabledValue = disabled();
<div
    class="picker"
    [class.disabled]="disabledValue"
    [attr.data-initialized]="initialized">
    <div class="color-field main">
        <canvas #palette></canvas>
        <div
            [hidden]="colorMixedValue"
            class="selector"
            #paletteSelector
            style.transform="translate({{ paletteMarker.x }}px, {{ paletteMarker.y }}px)"></div>
    </div>
    <div class="color-field hue">
        <canvas #hue></canvas>
        <div
            [hidden]="colorMixedValue"
            class="selector"
            #hueSelector
            style.transform="translateY({{ hueMarker.y }}px)"></div>
    </div>
    <div class="color-field alpha">
        <canvas #alpha></canvas>
        <div
            [hidden]="colorMixedValue"
            class="selector"
            #alphaSelector
            style.transform="translateY({{ alphaMarker.y }}px)"></div>
    </div>
</div>
<div class="value">
    <div class="color">
        <ui-input
            #colorInput
            id="color-picker-input"
            [disabled]="disabledValue"
            (undo)="emitUndo()"
            (redo)="emitRedo()"
            (blur)="onBlur()"
            (valueChange)="onHexInput($event)"
            [value]="colorMixedValue ? undefined : hexColor"
            [placeholder]="colorMixedValue ? 'Mixed' : ''"
            [disableUndo]="true" />
    </div>
    <div class="alpha">
        <ui-number-input
            #alphaInput
            unitLabel="%"
            [disabled]="disabledValue"
            [min]="0"
            [max]="100"
            [allowEmpty]="colorMixedValue"
            (undo)="emitUndo()"
            (redo)="emitRedo()"
            [disableUndo]="true"
            [value]="colorMixedValue ? undefined : alphaValue"
            placeholder="-"
            (valueChange)="onAlphaInput($event)" />
    </div>
</div>
