import { Component, computed, inject, input, output } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UIConfirmDialogService, UIDialogService, UIModule } from '@bannerflow/ui';
import { BrandLibraryDataService } from '../../../media-library/brand-library.data.service';
import { GenAIService } from '../../state/gen-ai.service';
import { SaveAndReplaceComponent } from '../save-and-replace/save-and-replace.component';
import { SaveType } from '@studio/domain/components/ai-studio.types';

@Component({
    standalone: true,
    imports: [UIModule],
    selector: 'ai-studio-actions',
    templateUrl: './ai-studio-actions.component.html',
    styleUrls: ['./ai-studio-actions.component.scss']
})
export class AIStudioActionsComponent {
    disabled = input.required<boolean>();
    unsavedChanges = input.required<boolean>();
    validName = input.required<boolean>();

    cancelButtonClicked = output();

    private brandLibraryDataService = inject(BrandLibraryDataService);
    private genAIService = inject(GenAIService);
    private uiConfirmDialogService = inject(UIConfirmDialogService);
    private uiDialogService = inject(UIDialogService);

    private hasUnsavedChanges = toSignal(this.genAIService.hasUnsavedChanges$, { initialValue: false });

    canSave = computed(() => this.computeCanSave());
    elementId = toSignal(this.genAIService.openedElementId$);
    isBrandLibraryElement = computed(() => this.computeIsBrandLibraryElement());
    isRequestingBrandLibrarySave = toSignal(this.genAIService.isRequestingBrandLibrarySave$, {
        initialValue: false
    });
    isRequestingBrandLibraryDuplicate = toSignal(this.genAIService.isRequestingBrandLibraryDuplicate$, {
        initialValue: false
    });

    isRequestingOnCanvasSaveAndReplace = toSignal(
        this.genAIService.isRequestingOnCanvasSaveAndReplace$,
        {
            initialValue: false
        }
    );

    isRequestingOnCanvasSaveAsDuplicate = toSignal(
        this.genAIService.isRequestingOnCanvasSaveAsDuplicate$,
        {
            initialValue: false
        }
    );

    async onCancelButtonClicked(): Promise<void> {
        const hasUnsavedChanges = this.unsavedChanges() || this.hasUnsavedChanges();
        if (!hasUnsavedChanges) {
            this.genAIService.closeAIStudio();
            return;
        }

        const result = await this.uiConfirmDialogService.confirm({
            headerText: 'Exit without saving',
            confirmText: 'Exit without saving',
            discardText: 'Back to AI Studio',
            text: `You are about to exit the AI Studio view. All unsaved changes to the image will be lost.\n If you want to save your image, please return to the AI Studio.`,
            showCancelButton: false
        });

        if (result === 'confirm') {
            this.genAIService.closeAIStudio();
        }
    }

    onSaveAndReplaceButtonClicked(): void {
        this.genAIService.saveToBrandLibrary(true);
    }

    onSaveToBrandLibraryNoReplace(): void {
        this.genAIService.saveToBrandLibrary(false);
    }

    async onSaveAndReplaceToCanvasButtonClicked(): Promise<void> {
        this.uiDialogService.openComponent(SaveAndReplaceComponent, {
            headerText: 'Save and replace',
            panelClass: ['no-padding'],
            width: '600px',
            height: '308px',
            theme: 'default',
            data: {
                callback: (replaceInAllDesigns: boolean) =>
                    this.genAIService.saveOnCanvas(SaveType.Replace, replaceInAllDesigns)
            }
        });
    }

    onSaveAsDuplicateToCanvasButtonClicked(): void {
        this.genAIService.saveOnCanvas(SaveType.Duplicate);
    }

    private computeIsBrandLibraryElement(): boolean {
        return !!this.brandLibraryDataService.getElementById(this.elementId());
    }

    private computeCanSave(): boolean {
        if (!this.elementId()) {
            return this.validName();
        }
        return !!this.hasUnsavedChanges() && this.validName();
    }
}
