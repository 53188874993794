import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnInit,
    signal,
    ViewChild
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { UIModule } from '@bannerflow/ui';
import { ICreative } from '@domain/creativeset/creative';
import { filter } from 'rxjs/operators';
import { VersionFlagComponent } from '../../../shared/components/version-picker/version-flag/version-flag.component';
import { PermissionsDirective } from '../../../shared/directives/permissions.directive';
import { TruncateSpanComponent } from '../../../shared/directives/truncate-span.directive';
import { LocalizationForVersionPipe } from '../../../shared/pipes/localization-for-version.pipe';
import { EditCreativeService } from '../services/edit-creative.service';
import { StatusDropdownComponent } from '../status-dropdown/status-dropdown.component';

@Component({
    selector: 'creative-list-item-title',
    standalone: true,
    imports: [
        CommonModule,
        UIModule,
        PermissionsDirective,
        StatusDropdownComponent,
        TruncateSpanComponent,
        VersionFlagComponent,
        LocalizationForVersionPipe,
        FormsModule,
        TruncateSpanComponent
    ],
    templateUrl: './creative-list-item-title.component.html',
    styleUrls: ['./creative-list-item-title.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreativeListItemTitleComponent implements OnInit {
    @Input() index: number;
    @Input() creative?: ICreative;
    @Input() isCreativeGroup = false;

    @ViewChild('sizeNameCalc') sizeNameCalc: ElementRef;
    @ViewChild('nameInput') set nameInput(el: ElementRef) {
        if (el) {
            el.nativeElement.focus();
        }
    }

    statusClass = signal('no-status');
    isEditing = signal(false);
    isSaving = signal(false);

    constructor(private editCreativeService: EditCreativeService) {
        this.editCreativeService.statusUpdated$
            .pipe(
                filter(creatives => creatives.some(creative => creative.id === this.creative?.id)),
                takeUntilDestroyed()
            )
            .subscribe(() => {
                this.setApprovalStatusClass();
            });
    }

    ngOnInit(): void {
        this.setApprovalStatusClass();
    }

    editSizeName(): void {
        this.isEditing.set(true);
    }

    cancelEditSizeName(): void {
        this.isEditing.set(false);
    }

    async saveSizeName(name: string): Promise<void> {
        if (this.isSaving() || !this.creative) {
            return;
        }
        this.isSaving.set(true);
        this.isEditing.set(false);

        await this.editCreativeService.saveSizeName(this.creative.size, name);
        this.isSaving.set(false);
    }

    private setApprovalStatusClass(): void {
        const status = this.creative?.approvalStatus?.replace(/\s+/g, '-').toLowerCase() || 'no-status';
        this.statusClass.set(status);
    }
}
