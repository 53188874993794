import { validationMap } from '@domain/api/validations';
import { PropertyInputValidation } from '../property-validation';

type Keys =
    | 'fontSize'
    | 'lineHeight'
    | 'textColorAlpha'
    | 'textShadowOffset'
    | 'textShadowBlur'
    | 'maxRows'
    | 'characterSpacing'
    | 'padding';

export const textPropertiesInputValidation: PropertyInputValidation<Keys> = {
    fontSize: validationMap.text.fontSize,
    maxRows: validationMap.text.maxRows,
    characterSpacing: validationMap.text.characterSpacing,
    lineHeight: validationMap.text.lineHeight,
    padding: validationMap.text.padding,
    textShadowOffset: validationMap.text.textShadow.offset,
    textShadowBlur: validationMap.text.textShadow.blur,
    textColorAlpha: {
        min: 0,
        max: 100
    }
} as const;
