import {
    IAnimation,
    IAnimationKeyframe,
    IAnimationSettings,
    IAnimationTemplate
} from '@domain/animation';
import { IState } from '@domain/state';
import { uuidv4 } from '@studio/utils/id';
import { isTransitionType, sortByTime } from './animation.utils';
import { cloneState } from './rendering/states.utils';
import { getOppositeTimingFunctionKey } from './timing-functions';

export const DEFAULT_ANIMATION_TEMPLATE_LENGTH = 0.4;

export function getAnimationFromTemplateId(
    id: string,
    elementDuration: number
): { animation: IAnimation; states: IState[] } {
    const template = animationTemplates.find(t => t.id === id);
    if (!template) {
        throw new Error(`No template with id "${id}" found`);
    }
    return getAnimationFromTemplate(template, elementDuration);
}

export function getAnimationFromTemplate(
    template: IAnimationTemplate,
    elementDuration: number
): { animation: IAnimation; states: IState[] } {
    const { name, timingFunction, settings, type, id } = template;
    const nameSuffix = isTransitionType(type) ? ` ${type}` : '';
    const states: IState[] = [];
    const keyframes: IAnimationKeyframe[] = template.keyframes.map(keyframe => {
        const duration = type === 'out' || keyframe.time < 0 ? elementDuration : 0;
        const time = keyframe.time + duration;
        const state = keyframe.state ? cloneState(keyframe.state) : undefined;
        if (state) {
            state.id = uuidv4();
            states.push(state);
        }
        return {
            id: uuidv4(),
            time,
            timingFunction: keyframe.timingFunction,
            stateId: state && state.id,
            duration: keyframe.duration || 0
        };
    });
    return {
        states,
        animation: {
            id: uuidv4(),
            name: name + nameSuffix,
            timingFunction,
            type,
            templateId: id,
            keyframes,
            settings: cloneSettings(settings),
            hidden: false
        }
    };
}

export function cloneSettings(settings?: IAnimationSettings): IAnimationSettings | undefined {
    if (settings) {
        const clone: IAnimationSettings = {} as IAnimationSettings;
        for (const key in settings) {
            const { name, value } = settings[key];
            clone[key] = { name, value };
        }
        return clone;
    }
}

export const animationDirectionValues = {
    in: {
        left: 90,
        up: 180,
        right: 270,
        down: 0
    },
    out: {
        left: 270,
        up: 0,
        right: 90,
        down: 180
    }
};

export const inAnimationTemplates: Readonly<IAnimationTemplate[]> = Object.freeze([
    {
        id: 'fade-in',
        name: 'Fade',
        type: 'in',
        timingFunction: 'linear',
        keyframes: [
            {
                time: 0,
                state: {
                    opacity: 0
                }
            },
            {
                time: DEFAULT_ANIMATION_TEMPLATE_LENGTH,
                timingFunction: '@timingFunction'
            }
        ]
    },
    {
        id: 'slide-in',
        name: 'Slide',
        type: 'in',
        timingFunction: 'easeOutExpo',
        settings: {
            direction: {
                name: 'Direction',
                value: animationDirectionValues.in.right
            }
        },
        keyframes: [
            {
                time: 0,
                state: {
                    x: '@edgePoint(@transition.direction).x',
                    y: '@edgePoint(@transition.direction).y'
                }
            },
            {
                time: DEFAULT_ANIMATION_TEMPLATE_LENGTH,
                timingFunction: '@timingFunction'
            }
        ]
    },
    {
        id: 'ascend-in',
        name: 'Ascend',
        type: 'in',
        timingFunction: 'easeOutQuad',
        settings: {
            direction: {
                name: 'Direction',
                value: animationDirectionValues.in.up
            },
            distance: {
                name: 'Distance',
                value: 10
            }
        },
        keyframes: [
            {
                time: 0,
                state: {
                    opacity: 0,
                    x: 'sin(@transition.direction * (pi / 180)) * @transition.distance',
                    y: '-cos(@transition.direction * (pi / 180)) * @transition.distance'
                }
            },
            {
                time: DEFAULT_ANIMATION_TEMPLATE_LENGTH,
                timingFunction: '@timingFunction'
            }
        ]
    },
    {
        id: 'scale-in',
        name: 'Scale',
        type: 'in',
        timingFunction: 'easeOutExpo',
        keyframes: [
            {
                time: 0,
                state: {
                    scaleX: 0,
                    scaleY: 0
                }
            },
            {
                time: DEFAULT_ANIMATION_TEMPLATE_LENGTH,
                timingFunction: '@timingFunction'
            }
        ]
    },
    {
        id: 'flip-in',
        name: 'Flip',
        type: 'in',
        timingFunction: 'easeOutBack',
        settings: {
            direction: {
                name: 'Direction',
                value: animationDirectionValues.in.left
            }
        },
        keyframes: [
            {
                time: 0,
                state: {
                    rotationX: `abs(sin(@transition.direction * (pi / 180)) * @value) - cos(@transition.direction * (pi / 180)) * pi / 2`,
                    rotationY: `abs(cos(@transition.direction * (pi / 180)) * @value) - sin(@transition.direction * (pi / 180)) * pi / 2`
                }
            },
            {
                time: DEFAULT_ANIMATION_TEMPLATE_LENGTH,
                timingFunction: '@timingFunction'
            }
        ]
    },
    {
        id: 'blur-in',
        name: 'Blur',
        type: 'in',
        timingFunction: 'linear',
        keyframes: [
            {
                time: 0,
                state: {
                    opacity: 0,
                    filters: {
                        blur: { value: 100 }
                    }
                }
            },
            {
                time: DEFAULT_ANIMATION_TEMPLATE_LENGTH,
                timingFunction: '@timingFunction'
            }
        ]
    }
] as Readonly<IAnimationTemplate[]>);

export const outAnimationTemplates: Readonly<IAnimationTemplate[]> = Object.freeze(
    inAnimationTemplates.map(template => {
        const keyframes = template.keyframes
            .map((keyframe, index) => {
                const timing = template.keyframes[template.keyframes.length - 1 - index].timingFunction;

                return {
                    ...keyframe,
                    timingFunction: timing ? getOppositeTimingFunctionKey(timing) : undefined,
                    time: -keyframe.time
                };
            })
            .sort(sortByTime);
        keyframes.forEach(keyframe => keyframe.time * -1);

        const settings = cloneSettings(template.settings);
        const timingFunction = getOppositeTimingFunctionKey(template.timingFunction);
        let id = template.id.replace('-in', '-out');
        let name = template.name;

        // Special case Ascend -> Descend
        if (id === 'ascend-out') {
            id = 'descend-out';
            name = 'Descend';
        }

        return {
            ...template,
            timingFunction,
            settings,
            keyframes,
            type: 'out',
            id,
            name
        };
    })
) as Readonly<IAnimationTemplate[]>;

export const animationTemplates = [...inAnimationTemplates, ...outAnimationTemplates].sort((a, b) =>
    a.name.localeCompare(b.name)
);
