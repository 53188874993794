@let fontStyle = externalFontStyle();
<div class="font-style">
    <div class="font-style-content">
        <div class="font-style-name">
            <strong> {{ fontStyle.name }}</strong>
        </div>
        <div
            class="font-sample"
            [class.loaded]="loaded()"
            [ngStyle]="{ 'font-family': fontStyleId() }">
            <span> The quick brown fox jumps over the lazy dog </span>
            <span>0123456789</span>
            <span>!?€$£%</span>
        </div>
    </div>
    <div class="actions">
        <ui-button
            svgIcon="plus"
            (click)="plusClicked()" />
    </div>
</div>
