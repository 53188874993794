<mat-form-field
    *ngIf="{
        groups: groups$ | async,
        selectedGroup: selectedGroup$ | async,
        selectedElement: selectedElement$ | async,
        label: label$ | async
    } as data"
    [appearance]="'outline'"
    [color]="'accent'">
    <mat-label>{{ data.label }}</mat-label>

    <mat-select
        #select
        [hideSingleSelectionIndicator]="true"
        [value]="data.selectedElement?.id ?? data.selectedGroup?.id ?? ''"
        [panelClass]="'ui-scrollbar'">
        <mat-select-trigger>
            @if (data.selectedGroup) {
                @if (data.selectedElement) {
                    <ui-svg-icon
                        *ngIf="data.selectedElement | iconForElement as icon"
                        [icon]="icon"></ui-svg-icon>
                    <span>{{ data.selectedGroup.isWidget ? 'Widget' : data.selectedGroup.value }}</span>
                } @else {
                    <div class="optgroup-label">
                        <group-option-number
                            *ngIf="data.selectedGroup.elements.length > 1"
                            [number]="data.selectedGroup.elements.length"></group-option-number>
                        <span>
                            <ng-container
                                *ngTemplateOutlet="
                                    versionName;
                                    context: { version: data.selectedGroup.version }
                                "></ng-container>
                            {{ data.selectedGroup.isWidget ? 'Widget' : data.selectedGroup.value }}
                        </span>
                    </div>
                }
            }
        </mat-select-trigger>

        @for (group of data.groups; track group.id) {
            <ng-template
                #elementOption
                let-active="active"
                let-element="element">
                <mat-option
                    [class.active]="active"
                    [value]="element.id"
                    (mouseenter)="onElementHover(element.id)"
                    (mouseleave)="onElementUnhover()"
                    (click)="selectElement(element, $event)">
                    <ui-svg-icon
                        *ngIf="element | iconForElement as icon"
                        [icon]="icon"></ui-svg-icon>
                    @if (group.isWidget) {
                        {{ element.name }} - Widget
                    } @else {
                        {{ element.name }} - {{ group.value }}
                    }
                </mat-option>
            </ng-template>

            @if (group.elements.length === 1) {
                <ng-container
                    *ngTemplateOutlet="
                        elementOption;
                        context: { element: group.elements[0], active: true }
                    "></ng-container>
            } @else {
                <mat-optgroup (click)="selectGroup(group)">
                    <div class="optgroup-label">
                        <group-option-number [number]="group.elements.length"></group-option-number>
                        <span>
                            <ng-container
                                *ngTemplateOutlet="
                                    versionName;
                                    context: { version: group.version }
                                "></ng-container>
                            @if (group.isWidget) {
                                Widget
                            } @else {
                                {{ group.value }}
                            }
                        </span>

                        <div
                            class="toggle"
                            (click)="toggleOptionGroup(group, $event)">
                            <ui-svg-icon
                                matSuffix
                                [className]="group.id === openedGroupId ? 'opened' : 'closed'"
                                [icon]="'arrow-down'"></ui-svg-icon>
                        </div>
                    </div>
                    <!-- Dummy option. mat-select doesn't render mat-select-trigger without it -->
                    <mat-option
                        [value]="group.id"
                        [style.display]="'none'">
                    </mat-option>
                    @for (element of group.elements; track element.id) {
                        <ng-container
                            *ngTemplateOutlet="
                                elementOption;
                                context: {
                                    element: element,
                                    active: group.id === openedGroupId
                                }
                            "></ng-container>
                    }
                </mat-optgroup>
            }
        }
    </mat-select>
    <ui-svg-icon
        matSuffix
        class="mat-suffix"
        [className]="select.panelOpen ? 'opened' : 'closed'"
        [icon]="'arrow-down'"></ui-svg-icon>
</mat-form-field>

<navigation-buttons></navigation-buttons>

<ng-template
    #versionName
    let-version="version">
    <ng-container *ngIf="version | versionToLabel as label">
        <span
            *ngIf="label.icon.length"
            class="label-icon"
            >{{ label.icon }}</span
        >
        <span>{{ label.text }} - </span>
    </ng-container>
</ng-template>
