import {
    IBoundingBox,
    IBounds,
    IHorizontalConstraint,
    ISize,
    IVerticalConstraint
} from '@domain/dimension';
import { ILayoutElement, ILayoutRule } from '../rules';

/**
 * If closer to edges than this percentage, use pixel constraints
 */
const CANVAS_MARGIN = 0.15;
const MAX_MARGIN_PX = 50;

/**
 * Only apply this rule if scaling change is not too big 0.3 = 30% of original size
 */
const SCALE_THRESHOLD = 0.3;

export class SnapToEdgesRule implements ILayoutRule {
    applyAutoContraintRule(layoutElement: ILayoutElement): void {
        const bounds = layoutElement.group?.rotatedBoundingBox || layoutElement.originalCreative;

        if (bounds.width > 70 && bounds.height > 70) {
            if (
                layoutElement.newCanvasSize.width / layoutElement.originalCreative.width >
                SCALE_THRESHOLD
            ) {
                Object.assign(
                    layoutElement.constraint,
                    boundingBoxToHorizontalConstraints(layoutElement.rotatedBoundingBox, bounds)
                );
            }
            if (
                layoutElement.newCanvasSize.height / layoutElement.originalCreative.height >
                SCALE_THRESHOLD
            ) {
                Object.assign(
                    layoutElement.constraint,
                    boundingBoxToVerticalConstraints(layoutElement.rotatedBoundingBox, bounds)
                );
            }
        }
    }
}

function boundingBoxToHorizontalConstraints(
    box: IBoundingBox,
    canvas: ISize | IBounds
): IHorizontalConstraint {
    const constraint: IHorizontalConstraint = {};
    const canvasWidth = canvas.width;
    const canvasX = ('x' in canvas && canvas.x) || 0;

    const leftPx = box.x - canvasX;
    const rightPx = canvasWidth - (box.x + box.width - canvasX);

    // Positions in percent
    const left = leftPx / canvasWidth;
    const right = rightPx / canvasWidth;

    // Align in fixed pixels to the left
    if (alignedToEdges(left) && leftPx <= MAX_MARGIN_PX) {
        constraint.left = {
            unit: 'px',
            value: leftPx
        };
    }

    // Align in fixed pixels to the right
    if (alignedToEdges(right) && rightPx <= MAX_MARGIN_PX) {
        constraint.right = {
            unit: 'px',
            value: rightPx
        };
    }

    return constraint;
}

function boundingBoxToVerticalConstraints(
    box: IBoundingBox,
    canvas: ISize | IBounds
): IVerticalConstraint {
    const constraint: IVerticalConstraint = {};
    const canvasHeight = canvas.height;
    const canvasY = ('y' in canvas && canvas.y) || 0;

    const topPx = box.y - canvasY;
    const bottomPx = canvasHeight - (box.y + box.height - canvasY);

    // Positions in percent
    const top = topPx / canvasHeight;
    const bottom = bottomPx / canvasHeight;

    // Align in fixed pixels to the top
    if (alignedToEdges(top) && topPx <= MAX_MARGIN_PX) {
        constraint.top = {
            unit: 'px',
            value: topPx
        };
    }

    // Align in fixed pixels to the bottom
    if (alignedToEdges(bottom) && bottomPx <= MAX_MARGIN_PX) {
        constraint.bottom = {
            unit: 'px',
            value: bottomPx
        };
    }

    return constraint;
}

function alignedToEdges(distance: number): boolean {
    return distance >= 0 && distance <= CANVAS_MARGIN;
}
