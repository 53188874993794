export class HistoryQueue<Data> {
    private _undos: Array<Data> = [];
    private _redos: Array<Data> = [];
    public current: Data | undefined;

    private traverseQueue(queue: Array<Data>, history: Array<Data>): Data | undefined {
        const tail = queue.pop();
        if (tail !== undefined) {
            const historic = this.current;
            if (historic !== undefined) {
                history.push(historic);
            }
            this.current = tail;
            return tail;
        }
    }

    get undos(): ReadonlyArray<Data> {
        return this._undos;
    }

    get redos(): ReadonlyArray<Data> {
        return this._redos;
    }

    push = (snapshot: Data): void => {
        if (this.current) {
            this._undos.push(this.current);
        }
        this.current = snapshot;
    };

    popUndo = (): Data | undefined => this.traverseQueue(this._undos, this._redos);
    popRedo = (): Data | undefined => this.traverseQueue(this._redos, this._undos);

    clearUndos(): void {
        this._undos = [];
    }

    clearRedos(): void {
        this._redos = [];
    }
}
