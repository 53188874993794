<ui-popover
    #popover="ui-popover"
    [config]="{ width: '100%', height: '100%', maxWidth: '100%' }">
    <ng-template ui-popover-template>
        <div class="header-close pull-right">
            <ui-icon
                icon="close-big"
                (click)="popover.close()"></ui-icon>
        </div>
        <studio-keymaps></studio-keymaps>
    </ng-template>
</ui-popover>
