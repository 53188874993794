import { parseColor } from '@creative/color.utils';
import { isFormulaValue } from '@creative/rendering';
import { serializeNumberOrFormula } from '@creative/serialization/serialization.utils';
import { serializableStateProperties } from '@domain/property';
import { OneOfStatesDto } from '@domain/serialization';
import { IState } from '@domain/state';
import { decimal } from '@studio/utils/utils';
import {
    convertBorderToDto,
    convertFiltersToDto,
    convertRadiusToDto,
    convertShadowsToDto,
    deserializeBorder,
    deserializeFilter,
    deserializeRadius,
    deserializeShadows
} from './property-serializer';

export function convertStateToDto(state: IState): OneOfStatesDto {
    const stateDto: OneOfStatesDto = { id: state.id! };
    for (const property of serializableStateProperties) {
        if (state[property] === undefined) {
            continue;
        }
        switch (property) {
            case 'border':
                if (state[property]) {
                    stateDto[property] = convertBorderToDto(state[property]);
                }
                break;
            case 'fill':
            case 'textColor':
                stateDto[property] = state[property].toString();
                break;
            case 'filters':
                stateDto[property] = convertFiltersToDto(state[property]);
                break;
            case 'mirrorX':
            case 'mirrorY':
                stateDto[property] = state[property];
                break;
            case 'name':
                stateDto[property] = state[property];
                break;
            case 'opacity':
            case 'originX':
            case 'originY':
            case 'scaleX':
            case 'scaleY':
                stateDto[property] = serializeNumberOrFormula(state[property], property);
                break;
            case 'radius':
                stateDto[property] = convertRadiusToDto(state[property]);
                break;
            case 'ratio':
                stateDto[property] = decimal(state[property]);
                break;
            case 'rotationX':
            case 'rotationY':
            case 'rotationZ':
            case 'x':
            case 'y':
                stateDto[property] = serializeNumberOrFormula(state[property], property);
                break;
            case 'shadows':
                stateDto[property] = convertShadowsToDto(state[property]);
                break;
        }
    }

    return stateDto;
}

export function deserializeState(stateDto: OneOfStatesDto): IState {
    const state: IState = { id: stateDto.id };

    for (const property of serializableStateProperties) {
        if (stateDto[property] === undefined) {
            continue;
        }

        switch (property) {
            case 'border':
                if (stateDto[property]) {
                    state[property] = deserializeBorder(stateDto[property]);
                }
                break;
            case 'fill':
            case 'textColor':
                state[property] = parseColor(stateDto[property]);
                break;
            case 'filters':
                state[property] = deserializeFilter(stateDto[property]);
                break;
            case 'mirrorX':
            case 'mirrorY':
                state[property] = stateDto[property];
                break;
            case 'name':
                state[property] = stateDto[property];
                break;
            case 'opacity':
            case 'originX':
            case 'originY':
            case 'scaleX':
            case 'scaleY':
            case 'ratio':
                state[property] = stateDto[property];
                break;
            case 'radius':
                state[property] = deserializeRadius(stateDto[property]);
                break;
            case 'rotationX':
            case 'rotationY':
            case 'rotationZ':
            case 'x':
            case 'y':
                state[property] = isFormulaValue(stateDto[property])
                    ? stateDto[property]
                    : Number(stateDto[property]);
                break;
            case 'shadows':
                state[property] = deserializeShadows(stateDto[property]);
                break;
        }
    }

    return state;
}
