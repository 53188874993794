@let currentName = assetName();
@let disableInteractions = isRequestingAnything();

<div class="wrapper">
    <div class="details">
        <ui-input
            #name
            label="Name"
            placeholder="Image name"
            autocomplete="off"
            [disabled]="disableInteractions"
            [value]="currentName"
            [validation]="nameValidation"
            [class.errorFlash]="nameValidation.errors?.errorFlash"
            [maxlength]="250"
            (valueChange)="onNameChange($event)" />
    </div>

    <div class="sections">
        @let selected = selectedOption();
        @let isFeatureSupported = featureSupport();

        @for (section of sections; track section.option) {
            <studio-ui-section
                [id]="'interaction-' + section.option"
                [disabled]="disableInteractions || !isFeatureSupported[section.option]"
                [headline]="section.label"
                [collapsed]="selected !== section.option"
                [collapsable]="true"
                [clickableHeader]="true"
                [headlineTooltipDisabled]="true"
                [uiTooltip]="
                    'This feature is not available for this image due to its size or aspect ratio.'
                "
                [uiTooltipDisabled]="isFeatureSupported[section.option]"
                [uiTooltipPosition]="'right'"
                [headlineIconAfter]="getHeadlineIcon(section.option)"
                (headerClick)="handleOptionSelect(section.option)">
                <ng-container *ngComponentOutlet="section.component" />
            </studio-ui-section>
        }
    </div>

    <ai-studio-actions
        [validName]="validName()"
        [unsavedChanges]="nameValidation.touched"
        [disabled]="disableInteractions" />
</div>
