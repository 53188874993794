import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { EditCreativeService } from '../../pages/manage-view/services/edit-creative.service';
import { ICreative } from '@domain/creativeset/creative';
import { Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';

@Directive({
    standalone: true,
    selector: '[observeVisibility]'
})
export class ObserveVisibilityDirective implements OnDestroy, OnInit, AfterViewInit {
    @Input() debounceTime = 300;
    @Input() creative: ICreative;

    static disconnectAll$ = new Subject<void>();

    private observer: IntersectionObserver | undefined;
    private intersection$ = new Subject<{
        entry: IntersectionObserverEntry;
        observer: IntersectionObserver;
    }>();
    private unsubscribe$ = new Subject<void>();

    constructor(
        private element: ElementRef,
        private editCreativeService: EditCreativeService
    ) {
        ObserveVisibilityDirective.disconnectAll$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => this.disconnect());
    }

    ngOnInit(): void {
        this.createObserver();
    }

    ngAfterViewInit(): void {
        this.startObservingElements();
    }

    disconnect(): void {
        if (this.observer) {
            this.observer.disconnect();
            this.observer = undefined;
        }
    }

    ngOnDestroy(): void {
        this.disconnect();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private isVisible(element: HTMLElement): Promise<boolean> {
        return new Promise(resolve => {
            const observer = new IntersectionObserver(([entry]) => {
                resolve(entry.isIntersecting);
                observer.disconnect();
            });

            observer.observe(element);
        });
    }

    private createObserver(): void {
        const options: IntersectionObserverInit = {
            rootMargin: '0px',
            threshold: [0, 1]
        };

        const isIntersecting = (entry: IntersectionObserverEntry): boolean =>
            entry.isIntersecting || entry.intersectionRatio > 0;

        this.observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (isIntersecting(entry)) {
                    this.intersection$.next({ entry, observer });
                } else if (entry.intersectionRatio !== 1) {
                    this.editCreativeService.setCreativeVisiblityStatus(this.creative, {
                        visible: false
                    });
                }
            });
        }, options);
    }

    private startObservingElements(): void {
        if (!this.observer) {
            return;
        }

        this.observer.observe(this.element.nativeElement);

        this.intersection$
            .pipe(delay(this.debounceTime), takeUntil(this.unsubscribe$))
            .subscribe(async value => {
                if (value) {
                    const { entry } = value;
                    const target = entry.target as HTMLElement;
                    const isStillVisible = await this.isVisible(target);
                    if (isStillVisible) {
                        this.editCreativeService.setCreativeVisiblityStatus(this.creative, {
                            visible: true
                        });
                    }
                }
            });
    }
}
