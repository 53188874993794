import { Injectable } from '@angular/core';
import {
    createBaseElement,
    createButtonElementTemplate,
    createEllipseElementTemplate,
    createImageElementTemplate,
    createRectangleElementTemplate,
    createTextElementTemplate,
    createVideoElementTemplate
} from '@creative/element-templates';
import { CreativeDataNode, isGroupDataNode, toFlatNodeList } from '@creative/nodes';
import { GroupDataNode } from '@creative/nodes/';
import {
    ICreativeDataNode,
    IElementDataNode,
    ITextDataNode,
    NodeName,
    OneOfDataNodes,
    OneOfElementPropertyKeys
} from '@domain/nodes';

import { ElementKind } from '@domain/elements';
import { uuidv4 } from '@studio/utils/id';
import { capitalize } from '@studio/utils/string';
import { generateUniqueName } from '@studio/utils/utils';
import { environment } from '../../../../environments/environment';

/** Generate deterministic ids for e2e tests */
let DETERMINISTIC_TEST_ID = 1;

@Injectable()
export class NodeCreatorService {
    private creative?: CreativeDataNode;

    setCreative(creative: CreativeDataNode | ICreativeDataNode): void {
        this.creative = creative as CreativeDataNode;
    }

    create<Kind extends ElementKind, Element = Extract<OneOfDataNodes, { kind: Kind }>>(
        kind: Kind,
        data: Partial<IElementDataNode<Kind>> | Partial<ITextDataNode<Kind>> = {}
    ): Element {
        const nodeBase = this.createNodeBaseTemplate(kind, data);

        if (!isGroupDataNode(nodeBase)) {
            const propertyKeys = Object.keys(data) as OneOfElementPropertyKeys[];
            for (const property of propertyKeys) {
                if (property in data) {
                    nodeBase[property] = data[property];
                }
            }
        }

        const node = this.initializeNodeBase(nodeBase);
        return node as unknown as Element;
    }

    private createNodeBaseTemplate<Kind extends ElementKind>(
        kind: Kind,
        data: Partial<IElementDataNode<Kind>>
    ): OneOfDataNodes {
        switch (kind) {
            case ElementKind.Rectangle:
                return createRectangleElementTemplate(data);
            case ElementKind.Ellipse:
                return createEllipseElementTemplate(data);
            case ElementKind.Text:
                return createTextElementTemplate(data);
            case ElementKind.Button:
                return createButtonElementTemplate(data);
            case ElementKind.Image:
                return createImageElementTemplate(data);
            case ElementKind.Video:
                return createVideoElementTemplate(data);
            case ElementKind.Widget:
                return createBaseElement(kind, data);
            case ElementKind.Group:
                return new GroupDataNode({ id: '', name: data.name ?? '' });
        }

        throw new Error(`Node creation for type ${kind} does not exist.`);
    }

    private initializeNodeBase<Node extends OneOfDataNodes>(node: Node): Node {
        const baseName = NodeName[capitalize(node.kind)];
        const nodeName = node.name?.length > 0 ? node.name : baseName;

        const uniqueName = this.creative
            ? generateUniqueName(nodeName, toFlatNodeList(this.creative))
            : nodeName;
        node.id = node.id || this.generateId();
        node.name = uniqueName;
        node.__rootNode = this.creative;

        return node;
    }

    private generateId(): string {
        if (environment.stage === 'test') {
            const id = DETERMINISTIC_TEST_ID;
            DETERMINISTIC_TEST_ID++;
            return `${id}`;
        }

        return uuidv4();
    }
}
