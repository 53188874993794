import {
    ChangeDetectorRef,
    Component,
    DestroyRef,
    HostListener,
    inject,
    Input,
    OnInit,
    ViewChild
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UserService } from '@app/shared/user/state/user.service';
import { UIPopoverRef } from '@bannerflow/ui';
import { IAnimator } from '@creative/animator.header';
import { Color } from '@creative/color';
import {
    isElementDataNode,
    isHidden,
    isImageNode,
    isTextNode,
    isVideoNode,
    isWidgetNode
} from '@creative/nodes/helpers';
import { isReservedActionState } from '@creative/rendering/states.utils';
import { IAnimationKeyframe } from '@domain/animation';
import {
    IImageElementDataNode,
    IVideoElementDataNode,
    OneOfElementDataNodes,
    OneOfTextDataNodes
} from '@domain/nodes';
import { IWidgetElementDataNode } from '@domain/widget';
import { IGuideline, TransformMode } from '@domain/workspace';
import { combineLatest, Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { FeedPopoverComponent } from '../../../shared/components/feeds/feed-popover.component';
import { DesignViewComponent } from '../design-view.component';
import { ElementSelectionService } from '../services';
import { MutatorService } from '../services/mutator.service';
import { AnimationRecorderService } from '../timeline';
import { KeyframeService } from '../timeline/timeline-element/keyframe.service';
import { ActionsService } from './actions';
import { ActionPropertiesComponent } from './actions/action-properties/action-properties.component';
import { IAlignBarConfig } from './align-bar.component';
import { ElementAlign } from './element-align.enum';
import { ElementDistribution } from './element-distribution.enum';
import { PropertiesService } from './properties.service';

@Component({
    selector: 'properties-panel',
    templateUrl: './properties-panel.component.html',
    styleUrls: ['./properties-panel.component.scss', './common.scss'],
    host: {
        '[class.prevent-text-blur]': 'true',
        '[class.ui-scrollbar]': 'true',
        '[class.state-view]': 'inStateView'
    },
    providers: [ActionsService]
})
export class PropertiesPanelComponent implements OnInit {
    @Input() animator: IAnimator;
    @Input() hideDefaultProperties = false;
    @ViewChild('actions') ActionPropertiesComponent: ActionPropertiesComponent;
    preview = false;
    alignmentConfiguration: IAlignBarConfig;
    fill: Color;
    guideline?: IGuideline;
    feedPopoverRef: UIPopoverRef<FeedPopoverComponent>;
    isWidgetElement: boolean;
    inStateView = false;
    inReservedStateView = false;
    showKeyframePanel = false;
    isPropertiesHidden = false;
    selectedTextElements$: Observable<OneOfTextDataNodes[]>;
    selectedImageElements$: Observable<IImageElementDataNode[]>;
    selectedWidgetElements$: Observable<IWidgetElementDataNode[]>;
    selectedVideoElements$: Observable<IVideoElementDataNode[]>;
    selectedElements$: Observable<OneOfElementDataNodes[]>;
    private isSDAEnabled = false;

    editor = inject(DesignViewComponent);
    private keyframeService = inject(KeyframeService);
    private destroyRef = inject(DestroyRef);
    private propertiesService = inject(PropertiesService);
    private animationRecorder = inject(AnimationRecorderService);
    private mutatorService = inject(MutatorService);
    private elementSelectionService = inject(ElementSelectionService);
    private changeDetector = inject(ChangeDetectorRef);
    private userService = inject(UserService);

    constructor() {
        this.preview = this.mutatorService.preview;
    }

    async ngOnInit(): Promise<void> {
        this.selectedElements$ = this.elementSelectionService.change$.pipe(
            startWith({ elements: [] as OneOfElementDataNodes[] }),
            map(({ elements }) => [...elements]),
            tap(elements => {
                this.isPropertiesHidden = elements.some(element => isHidden(element));
            }),
            takeUntilDestroyed(this.destroyRef)
        );

        this.selectedTextElements$ = this.selectedElements$.pipe(
            map(elements => (elements.every(isTextNode) ? elements : [])),
            takeUntilDestroyed(this.destroyRef)
        );

        this.selectedImageElements$ = this.selectedElements$.pipe(
            map(elements => (elements.every(isImageNode) ? elements : [])),
            takeUntilDestroyed(this.destroyRef)
        );

        this.selectedWidgetElements$ = this.selectedElements$.pipe(
            map(elements => (elements.every(isWidgetNode) ? elements : [])),
            takeUntilDestroyed(this.destroyRef)
        );

        this.selectedVideoElements$ = this.selectedElements$.pipe(
            map(elements => (elements.every(isVideoNode) ? elements : [])),
            takeUntilDestroyed(this.destroyRef)
        );

        /**
         * Fire change detection when all selection emissions are done
         * as there's some delay before PP updates otherwise
         */
        this.selectedElements$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.changeDetector.detectChanges());

        this.editor.workspace.transform.onGuidelineChange$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(guideline => {
                this.guideline = guideline?.social ? undefined : guideline;
            });

        this.propertiesService.dataElementChange$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(element => {
                if (isWidgetNode(element)) {
                    this.isWidgetElement = true;
                } else {
                    this.isWidgetElement = false;
                }
            });

        combineLatest([
            this.elementSelectionService.change$,
            this.propertiesService.selectedStateChange$
        ])
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(([{ elements }, state]) => {
                const elementsArray = Array.from(elements);
                this.inStateView = !!state;
                if (elementsArray.length === 1 && isElementDataNode(elementsArray[0]) && state) {
                    const dataElement = elementsArray[0];
                    this.inReservedStateView = isReservedActionState(dataElement, state);
                } else {
                    this.inReservedStateView = false;
                }
            });

        combineLatest([
            this.keyframeService.selectedKeyframes$.pipe(startWith(new Set<IAnimationKeyframe>())),
            this.animationRecorder.recording$.pipe(startWith(false))
        ])
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(([keyframes, isRecording]) => {
                this.showKeyframePanel = keyframes.size === 1 || isRecording;
            });

        this.isSDAEnabled = await this.userService.hasPermission('SocialDynamicAdvertising');
    }

    @HostListener('mousedown', ['$event'])
    onMouseDown(): void {
        const transformMode = this.editor.workspace.transform.mode;
        if (transformMode !== TransformMode.EditGradient && transformMode !== TransformMode.EditText) {
            this.editor.workspace.transform.cancel();
        }
    }

    @HostListener('window:keydown', ['$event'])
    onKeyDown(event: KeyboardEvent): void {
        if (event.target === document.body && event.keyCode === 32) {
            event.preventDefault();
        }
    }

    alignSelection(align: ElementAlign): void {
        this.editor.workspace.alignSelection(align);
    }

    distributeSelection(distribution: ElementDistribution): void {
        this.editor.workspace.distributeSelection(distribution);
    }

    isDynamicContentPropertiesVisible(elements?: OneOfElementDataNodes[]): boolean {
        const isDynamicPropertyCompatible = elements?.every(isImageNode) || elements?.every(isTextNode);
        return !!isDynamicPropertyCompatible && this.isSDAEnabled;
    }
}
