import { EnvironmentProviders, importProvidersFrom } from '@angular/core';
import { AnimationControlsEffects } from './animation-control.effects';
import { ANIMATION_CONTROL_FEATURE_KEY, reducer } from './animation-control.reducer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

export function provideAnimationControlStore(): EnvironmentProviders {
    return importProvidersFrom(
        StoreModule.forFeature(ANIMATION_CONTROL_FEATURE_KEY, reducer),
        EffectsModule.forFeature(AnimationControlsEffects)
    );
}
