import { ICreative } from '@domain/creativeset/creative/creative';
import { gql } from './gql.types';

export const VERSION_FRAGMENT = gql`
    fragment VersionFragment on VersionType {
        id
        name
        targetUrl
        localization {
            id
        }
        properties {
            id
            name
            value
        }
    }
`;

export const IMAGE_FRAGMENT = gql`
    fragment ImageFragment on ImageAssetType {
        id
        original {
            url
            width
            height
        }
        thumbnail {
            url
            width
            height
        }
        animatedThumbnail {
            url
            width
            height
        }
        name
        created
        modified
        fileSize
        isGenAi
    }
`;

export const VIDEO_FRAGMENT = gql`
    fragment VideoFragment on VideoAssetType {
        id
        name
        url
        width
        height
        thumbnail {
            url
            width
            height
        }
        fileSize
        durationInMilliseconds
        created
        modified
    }
`;

export const GET_FOLDER_ID = gql<{ creativeset: { folderId: string } }, { id: string }>`
    query GetCreativeset($id: String!) {
        creativeset(id: $id) {
            folderId
        }
    }
`;

type UpdateCreativeStatusVariable = {
    creativesetId: string;
    stateId: string;
    creatives: Pick<ICreative, 'id' | 'checksum'>[];
};
export const UPDATE_CREATIVESET_STATE_ID = gql<{ id: string }, UpdateCreativeStatusVariable>`
    mutation UpdateStateIdMutation(
        $creativesetId: String!
        $stateId: String
        $creatives: [UpdateCreativeInputType]!
    ) {
        updateCreativesetStateId(
            creativesetId: $creativesetId
            stateId: $stateId
            creatives: $creatives
        ) {
            id
        }
    }
`;
