import { Injectable } from '@angular/core';
import { TransformMode } from '@domain/workspace';
import { setCursorOnElement } from '@studio/utils/cursor';
import {
    IMouseDown,
    IMouseDownMove,
    IMouseWheelSpeed,
    MIDDLE_MOUSE_DOWN
} from '@studio/utils/mouse-observable';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MutatorService } from '../services/mutator.service';
import { StudioWorkspaceComponent } from './studio-workspace.component';
import { WorkspaceTransformService } from './workspace-transform.service';

@Injectable()
export class WorkspacePanService {
    workspace: StudioWorkspaceComponent;
    private mouseDown = false;
    private unsubscribe$ = new Subject<void>();

    constructor(
        private transformService: WorkspaceTransformService,
        private mutatorService: MutatorService
    ) {}

    init(): void {
        const mouseObervable = this.workspace.mouseObervable;

        mouseObervable.mouseDown$.pipe(takeUntil(this.unsubscribe$)).subscribe(this.onMouseDown);

        mouseObervable.mouseDownMove$.pipe(takeUntil(this.unsubscribe$)).subscribe(this.mouseDownMove);

        mouseObervable.mouseUp$.pipe(takeUntil(this.unsubscribe$)).subscribe(this.onMouseUp);

        mouseObervable.mouseWheel$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(mouseValue => this.onMouseWheel(mouseValue.speed));
    }

    destroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private onMouseWheel = (speed: IMouseWheelSpeed): void => {
        if (this.workspace.isPanning && !this.mutatorService.preview && !this.workspace.isZooming) {
            const workspace = this.workspace;
            const canvasPosition = workspace.canvas.getPosition();

            const delta = {
                x: (speed.x / 1.5) * -1,
                y: (speed.y / 1.5) * -1
            };

            this.workspace.canvas.setPosition({
                x: Math.round(canvasPosition.x + delta.x),
                y: Math.round(canvasPosition.y + delta.y)
            });

            if (this.transformService.mode === TransformMode.EditGradient) {
                workspace.gradientHelper.pan(delta);
            }

            this.workspace.gizmoDrawer.draw();

            workspace.wasPanning = true;
        }
    };

    private onMouseDown = ({ button }: IMouseDown): void => {
        if (this.mutatorService.preview) {
            return;
        }
        if (this.workspace.isPanning || button === MIDDLE_MOUSE_DOWN) {
            const workspace = this.workspace;
            const canvasStartPosition = workspace.canvasStartPosition;
            const canvasPosition = workspace.canvas.getPosition();
            this.mouseDown = true;
            this.setCursor(true);
            canvasStartPosition.x = canvasPosition.x;
            canvasStartPosition.y = canvasPosition.y;
        }
    };

    private mouseDownMove = (mouseValue: IMouseDownMove): void => {
        const workspace = this.workspace;
        if (workspace.isPanning) {
            const { mouseDelta } = mouseValue;
            const canvasStartPosition = workspace.canvasStartPosition;
            this.workspace.canvas.setPosition({
                x: canvasStartPosition.x + mouseDelta.x,
                y: canvasStartPosition.y + mouseDelta.y
            });
            this.workspace.gizmoDrawer.draw();
            workspace.wasPanning = true;
        }
    };

    private onMouseUp = (): void => {
        const workspace = this.workspace;
        if (!workspace.isPanning) {
            setCursorOnElement(workspace.host.nativeElement, 'selection-0');
        }
        this.mouseDown = false;
    };

    setCursor(set: boolean): void {
        const workspace = this.workspace;
        if (set) {
            const cursor = this.mouseDown ? 'grabbing' : 'grab';
            if (CSS.supports('cursor', cursor)) {
                setCursorOnElement(workspace.host.nativeElement, cursor);
            }
            if (CSS.supports('cursor', `-webkit-${cursor}`)) {
                setCursorOnElement(workspace.host.nativeElement, `-webkit-${cursor}`);
            }
            if (CSS.supports('cursor', `-moz-${cursor}`)) {
                setCursorOnElement(workspace.host.nativeElement, `-moz-${cursor}`);
            }
        } else {
            setCursorOnElement(workspace.host.nativeElement, 'selection-0');
        }
    }
}
