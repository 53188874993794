import { EventHandlerType, IEEventTarget, IEventListenerReference, IEventUtils } from '@domain/events';

export class EventUtils implements IEventUtils {
    listeners: IEventListenerReference[] = [];

    once(
        element: IEEventTarget,
        eventName: string,
        eventHandler: EventHandlerType
    ): (e: EventListenerOrEventListenerObject) => void {
        const eventListener = (e: EventListenerOrEventListenerObject): void => {
            eventHandler(e);

            // remove event listener
            if (element && typeof eventListener !== 'undefined') {
                this.off(element, eventName, eventListener);
            }
        };

        this.on(element, eventName, eventListener);

        return eventListener;
    }

    windowLoad(targetWindow: Window = window, eventHandler: EventHandlerType): void {
        if (targetWindow.document.readyState === 'complete') {
            eventHandler();
        } else {
            this.once(targetWindow, 'load', eventHandler);
        }
    }

    on(element: IEEventTarget, eventName: string, eventHandler: EventHandlerType): void {
        if (element.addEventListener) {
            element.addEventListener(eventName, eventHandler, false);
        } else if (element.attachEvent) {
            element.attachEvent(`on${eventName}`, eventHandler);
        }

        this.listeners.push({ element, eventName, eventHandler });
    }

    off(element: IEEventTarget, eventName: string, eventHandler: EventHandlerType): void {
        if (element.removeEventListener) {
            element.removeEventListener(eventName, eventHandler);
        } else if (element.detachEvent) {
            element.detachEvent(`on${eventName}`, eventHandler);
        }

        for (let i = 0; i < this.listeners.length; i++) {
            const l = this.listeners[i];
            if (l.element === element && l.eventName === eventName && l.eventHandler === eventHandler) {
                this.listeners.splice(i, 1);
            }
        }
    }

    dispatch(element: IEEventTarget, eventName: string, data?: any): void {
        // Create the event.
        const event = new Event(eventName);

        if (data) {
            (event as any).data = data;
        }

        element.dispatchEvent(event);
    }

    clear(): void {
        while (this.listeners.length) {
            const l = this.listeners.pop()!;
            this.off(l.element, l.eventName, l.eventHandler);
        }
    }
}
