<div class="setting-row alternate">
    <div class="select-label">Size mode</div>
    <div class="setting-value select col-1 wide">
        <ui-select
            class="fitting-options"
            [(selected)]="sizeMode"
            (selectedChange)="setSizeMode($event)"
            [class.empty-state]="classEmptyState"
            [useTargetWidth]="true">
            <ng-container *ngIf="sizeMode === 'mixed'">
                <ui-option [value]="'mixed'"> Mixed </ui-option>
                <div class="option-divider"></div>
            </ng-container>
            <ui-option
                id="size-mode-crop"
                [value]="ImageSizeMode.Crop">
                Crop
            </ui-option>
            <ui-option
                id="size-mode-fit"
                [value]="ImageSizeMode.Fit">
                Fit
            </ui-option>
            <ui-option
                id="size-mode-stretch"
                [value]="ImageSizeMode.Stretch">
                Stretch
            </ui-option>
        </ui-select>
    </div>
</div>
