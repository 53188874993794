@if (versionsLoaded$ | async) {
    @if (hasTranslatableElements$ | async) {
        <panel-tabs>
            <panel-content-wrapper contentTab>
                @if ((selectedTab$ | async) == 'content') {
                    <panel-content panelContent></panel-content>
                }
            </panel-content-wrapper>
            <panel-content-wrapper stylingTab>
                @if ((selectedTab$ | async) == 'styling') {
                    <panel-styling panelContent></panel-styling>
                }
            </panel-content-wrapper>
        </panel-tabs>
    } @else {
        <div class="empty-panel-message">Edit translatable content here once added to a design.</div>
    }
} @else {
    <ui-loader></ui-loader>
}
