import { Injectable, Optional, inject } from '@angular/core';
import { DisplayCampaignService } from '../../../shared/display-campaign/state/display-campaign.service';
import { UIConfirmDialogResult, UIDialogService, UINotificationService } from '@bannerflow/ui';
import { isVideoElement } from '@creative/nodes/helpers';
import { IBrandLibraryElement } from '@domain/brand/brand-library';
import { isHeavyVideo, isMP4File, isTooHeavyVideo, isVideoFile } from '@studio/utils/media';
import { Observable, firstValueFrom, map } from 'rxjs';
import { BrandLibraryDataService } from '../../../shared/media-library/brand-library.data.service';
import { UserService } from '../../../shared/user/state/user.service';
import { EditorStateService } from '../services/editor-state.service';
import { HeavyVideoWarningDialogComponent } from './heavy-video-warning-dialog/heavy-video-warning-dialog.component';

@Injectable({ providedIn: 'root' })
export class HeavyVideoService {
    private displayCampaignService = inject(DisplayCampaignService);

    constructor(
        private uiDialogService: UIDialogService,
        @Optional() private editorStateService: EditorStateService,
        private userService: UserService,
        private uiNotificationService: UINotificationService,
        private brandLibraryDataService: BrandLibraryDataService
    ) {}

    async promptRemoveVideoUploads(files: File[], isDirectCanvasUpload = false): Promise<File[]> {
        if (!(await this.userService.hasPermission('StudioVideoLibrary'))) {
            return files.filter(file => !isVideoFile(file));
        }

        const allowHeavyVideos = await this.userService.hasPermission('AllowHeavyVideos');
        const VIDEO_SIZE_LIMIT = allowHeavyVideos ? 50 : 2;
        const heavyVideos = files.filter(file => isMP4File(file) && isHeavyVideo(file.size));
        const tooHeavyVideos = files.filter(
            file => isMP4File(file) && isTooHeavyVideo(file.size, VIDEO_SIZE_LIMIT)
        );
        const unsupportedVideos = files.filter(file => isVideoFile(file) && !isMP4File(file));

        if (tooHeavyVideos.length) {
            this.uiNotificationService.open(`Video can not be larger than ${VIDEO_SIZE_LIMIT}MB.`, {
                type: 'error',
                placement: 'top',
                autoCloseDelay: 5000
            });

            files = files.filter(file => !tooHeavyVideos.includes(file));
        }

        if (heavyVideos.length && isDirectCanvasUpload) {
            const allowVideos = allowHeavyVideos ? await this.promptHeavyVideo() : false;
            if (!allowVideos) {
                files = files.filter(file => !heavyVideos.includes(file));
            }
        } else if (heavyVideos.length) {
            await this.showHeavyVideoWarningNotification();
        }

        if (unsupportedVideos.length) {
            this.uiNotificationService.open(`Only MP4 video files can be uploaded.`, {
                type: 'warning',
                placement: 'top',
                autoCloseDelay: 5000
            });

            files = files.filter(file => !unsupportedVideos.includes(file));
        }

        return files;
    }

    async promptRemoveVideoElements(elements: IBrandLibraryElement[]): Promise<IBrandLibraryElement[]> {
        const heavyVideos = elements.filter(element => {
            if (!isVideoElement(element)) {
                return;
            }

            const asset = this.brandLibraryDataService.getAssetByElement(element);

            return isHeavyVideo(asset?.fileSize);
        });

        if (heavyVideos.length) {
            const allowVideos = await this.promptHeavyVideo();

            if (!allowVideos) {
                elements = elements.filter(element => !heavyVideos.includes(element));
            }
        }

        return elements;
    }

    async promptVideoElement(element: IBrandLibraryElement): Promise<boolean> {
        if (!isVideoElement(element)) {
            return true;
        }

        const asset = this.brandLibraryDataService.getAssetByElement(element);

        if (isHeavyVideo(asset?.fileSize)) {
            const allowHeavyVideo = await this.promptHeavyVideo();

            if (!allowHeavyVideo) {
                return false;
            }
        }

        return true;
    }

    async showHeavyVideoWarningNotification(): Promise<void> {
        this.uiNotificationService.open(
            `This video is over 2MB. Please acknowledge that it can't be used for Display Ads.`,
            { type: 'warning', placement: 'top', autoCloseDelay: 5000 }
        );
    }

    async promptHeavyVideo(): Promise<boolean> {
        const result = await this.showHeavyVideoWarningDialog();
        return result === 'confirm';
    }

    private async showHeavyVideoWarningDialog(): Promise<UIConfirmDialogResult> {
        const allowHeavyVideo = await firstValueFrom(this.publishOptionsAllowHeavyVideo());
        const dialogRef = this.uiDialogService.openComponent(HeavyVideoWarningDialogComponent, {
            headerText: 'Heavy video warning',
            theme: 'default',
            width: '620px',
            data: { allowHeavyVideo }
        });

        await dialogRef.afterViewInit;

        return (dialogRef.subComponentRef.instance as HeavyVideoWarningDialogComponent).initiate();
    }

    // Find out if creative's publish destinations supports heavy video
    private publishOptionsAllowHeavyVideo(): Observable<boolean | undefined> {
        return this.displayCampaignService.campaigns$.pipe(
            map(campaignPublishStatus => {
                const creativeId = this.editorStateService?.creative.id;
                if (!creativeId) {
                    return true;
                }
                return campaignPublishStatus
                    .map(({ creatives }) =>
                        creatives.find(creative => creative.creativeId === creativeId)
                    )
                    .every(
                        creativeInCampaign =>
                            !creativeInCampaign || creativeInCampaign.publishOptionsSupportHeavyVideo
                    );
            })
        );
    }
}
