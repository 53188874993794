<ng-container *ngIf="showingAll$ | async; else singleElement">
    <div class="summary">Showing all elements</div>
    <div
        data-test-id="toggle-all-elements"
        id="interaction-single-element"
        class="all-elements-toggle"
        (click)="hideAllElements()">
        One element
    </div>
</ng-container>

<ng-template #singleElement>
    <div class="summary"></div>
    <div
        data-test-id="toggle-all-elements"
        id="interaction-show-all-elements"
        class="all-elements-toggle"
        (click)="showAllElements()">
        All elements
    </div>
</ng-template>
