import { CreativeType } from '@domain/creativeset/creative';
import { IAdData } from '@domain/ad/ad-data';

export const SAMPLE_AD_DATA: IAdData = {
    id: 'adId',
    adTagId: 'adTagId',
    displayType: 'animated',
    origin: 'http://localhost/acg/',
    creatives: [
        {
            id: 'creativeId',
            type: CreativeType.Animated,
            brand: {
                id: 'brandId'
            },
            adVersion: 1,
            created: '1970-01-01T00:00:00.000Z',
            creativeset: {
                id: 'creativesetId',
                name: 'Creativeset name'
            },
            version: {
                id: 'versionId',
                name: 'Version name',
                localization: {
                    id: 'localizationId',
                    name: 'Swedish',
                    cultureCode: 'se',
                    cultureName: 'sv'
                }
            },
            design: {
                id: 'designId',
                name: 'Design name'
            },
            animated: {
                dependencies: [],
                files: [
                    {
                        id: 'abc',
                        url: 'sample-animated-creative.js'
                    }
                ]
            },
            image: {
                url: 'https://play.bannerflow.com/eric/images/300x250.jpg?asdasds'
            },
            size: {
                id: 'sizeId',
                name: 'Size name',
                width: 350,
                height: 150
            },
            targetUrl: 'https://feber.se'
        }
    ],
    account: {
        slug: 'accountSlug'
    },
    brand: {
        id: 'brandId'
    },
    campaign: {
        id: 'campaignId',
        name: 'Campaign name'
    },
    tracking: {
        url: '', // View in https://beeceptor.com/console/bf-sandbox-analytics
        disabled: true,
        pixelEnabled: false
    },
    target: {
        url: undefined
    },
    destination: {
        id: 'destinationId',
        publishOptionId: 'publishOptionId'
    },
    adScripts: []
};
