<ui-dropdown
    #dropdown
    type="menu"
    [offset]="{ x: -1, y: 0 }"
    class="manage-menu">
    <ng-container *permissions="'Default'">
        <ng-container *permissions="'ManageVersions'">
            <ui-dropdown-item
                id="manage-versions"
                class="menu-option"
                (click)="manageVersions()">
                Manage versions
            </ui-dropdown-item>
        </ng-container>
        <ng-container *permissions="'ShareCreativeSet'">
            <ui-dropdown-divider></ui-dropdown-divider>
            @if (hasDesigns) {
                <ui-dropdown-item
                    id="share-creative-set"
                    class="menu-option"
                    (click)="sharePublicShowcaseDialog()"
                    svgIcon="share">
                    Share creative set
                </ui-dropdown-item>
            }
            <ui-dropdown-item
                id="mobile-preview"
                class="menu-option"
                (click)="showMobilePreviewModal()"
                icon="qr-code">
                Mobile preview
            </ui-dropdown-item>
            <ui-dropdown-divider></ui-dropdown-divider>
        </ng-container>
        <ng-container *permissions="'Duplicate'">
            <ui-dropdown-item
                [uiDropdownTarget]="duplicateSection"
                [hasBackdrop]="false"
                class="menu-option"
                svgIcon="copy-s"
                data-test-id="duplicate-creatives-item">
                Duplicate to {{ selectedText }}
            </ui-dropdown-item>
        </ng-container>
        <ng-container *permissions="'Analytics'">
            <ui-dropdown-item
                id="interaction-analytics-set"
                svgIcon="analytics-s"
                (click)="navitageToAnalytics()"
                class="menu-option">
                Analyze creative set
            </ui-dropdown-item>
        </ng-container>
        <ng-container *permissions="'CampaignActions'">
            <ui-dropdown-item
                id="create-new-campaign"
                svgIcon="campaigns-s"
                class="menu-option"
                [uiDropdownTarget]="campaignsDropdown.dropdown">
                {{ (hasConnectedCampaigns$ | async) ? 'Campaigns' : 'Create new campaign' }}
            </ui-dropdown-item>
        </ng-container>
        @if (exportDropdown.dropdown) {
            <ui-dropdown-item
                id="interacton-export-creatives"
                class="menu-option"
                [disabled]="selectedCreatives === 0"
                [uiDropdownTarget]="exportDropdown.dropdown">
                Export creatives {{ countText }}
            </ui-dropdown-item>
        }
        <ng-container *permissions="'EditCreative'">
            <ui-dropdown-item
                id="manage-fonts"
                class="menu-option"
                (click)="manageFonts()">
                Manage fonts
            </ui-dropdown-item>
        </ng-container>
        <ui-dropdown-item
            id="manage-warnings"
            class="menu-option"
            [uiDropdownTarget]="manageWarningsDropdown.dropdown">
            Manage warnings
        </ui-dropdown-item>
        <ui-dropdown-item
            id="exit-manage-view"
            (click)="exit()">
            Exit
        </ui-dropdown-item>
    </ng-container>
    @if ((hasConnectedCampaigns$ | async) && !!(isEmployee$ | async)) {
        <ui-dropdown-item
            id="regenerate-checksum"
            data-test-id="regenerate-checksum"
            (click)="regenerateSet()">
            Regenerate (employee only)
        </ui-dropdown-item>
    }
</ui-dropdown>

<ui-dropdown
    #duplicateSection
    [hasBackdrop]="false"
    type="menu">
    <ui-dropdown-item
        id="interaction-duplicate-creative-to-new"
        class="custom-dropdown"
        data-test-id="duplicate-creative-to-new"
        (click)="duplicateSize(true)">
        <div class="custom-item">
            <div class="custom-column"></div>
            <div class="custom-text">New creative set</div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-item
        id="interaction-duplicate-creative-to-existing"
        class="custom-dropdown"
        data-test-id="duplicate-creative-to-existing"
        (click)="duplicateSize(false)">
        <div class="custom-item">
            <div class="custom-column"></div>
            <div class="custom-text">Existing creative set</div>
        </div>
    </ui-dropdown-item>
</ui-dropdown>

<export-dropdown #exportDropdown></export-dropdown>

<campaigns-dropdown #campaignsDropdown></campaigns-dropdown>

<manage-warnings-dropdown
    #manageWarningsDropdown
    [isManageView]="true"></manage-warnings-dropdown>
