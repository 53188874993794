/* eslint-disable @typescript-eslint/no-explicit-any */
import { createFeed } from '../elements/feed/feeds.utils';
import { serializeWidgetTextValue } from './versions/widgetText-serializer';
import { VersionPropertyDto } from '@domain/api/generated/sapi';
import { IColor } from '@domain/color';
import { IImageElementAsset } from '@domain/creativeset/element-asset';
import {
    ISerializedVersionProperty,
    IVersionedText,
    IVersionProperty,
    IWidgetText,
    OneOfVersionableProperties,
    VersionPropertyName
} from '@domain/creativeset/version';
import { IFeed, IFeedStep } from '@domain/feed';
import { IFontStyle, SerializedFontStyle } from '@domain/font';
import { AllDataNodes, OneOfElementDataNodes } from '@domain/nodes';
import {
    BorderStyle,
    IBorder,
    IPadding,
    IShadow,
    ITextShadow,
    SerializedShadow,
    SerializedTextShadow
} from '@domain/style';
import { CharacterPropertyKeys, ICharacterProperties } from '@domain/text';
import { isUrl } from '@studio/utils/url';
import { Color } from '../color';
import { parseColor } from '../color.utils';
import { deserializeVersionedText, serializeVersionedText } from './text-serializer';

/** Deserialize property values that are serialized as strings, i.e in BrandLibrary */
export function deserializeElementPropertyStringValue<
    Key extends keyof AllDataNodes,
    Value extends AllDataNodes[Key]
>(key: Key, value?: string): Value | undefined {
    if (value === null || value === undefined) {
        return undefined;
    }

    switch (key) {
        case 'duration':
        case 'time':
        case 'lineHeight':
        case 'characterSpacing':
        case 'fontSize':
        case 'originX':
        case 'originY':
        case 'scaleX':
        case 'scaleY':
        case 'opacity':
        case 'rotationX':
        case 'rotationY':
        case 'rotationZ':
        case 'ratio':
            return parseFloat(value) as Value;

        case 'x':
        case 'y':
        case 'width':
        case 'height':
        case 'maxRows':
            return Number(value) as Value;

        case 'horizontalAlignment':
        case 'verticalAlignment':
        case 'textOverflow':
            return value as Value;

        case 'hidden':
        case 'locked':
        case 'mirrorX':
        case 'mirrorY':
        case 'strikethrough':
        case 'underline':
        case 'uppercase':
            return (typeof value === 'string' ? value.toLowerCase() === 'true' : value) as Value;

        case 'font': {
            if (value === undefined) {
                return undefined;
            }
            const fontStyle = (
                typeof value === 'string' ? value.split(' ') : []
            ) as SerializedFontStyle;
            let src = fontStyle[3];
            try {
                // Support old font url that where encoded with base64 without data prefix.
                //
                // Note: that exported HTML creatives can have base64 encoded fonts which begins with data prefix.
                if (!src.startsWith('data') && !isUrl(src)) {
                    if (typeof window !== 'undefined') {
                        src = atob(src);
                    } else {
                        src = Buffer.from(src, 'base64').toString();
                    }
                }
            } catch {
                /* empty */
            }

            return {
                id: fontStyle[0],
                weight: parseInt(fontStyle[1], 10),
                style: fontStyle[2],
                src,
                fontFamilyId: fontStyle[4]
            } satisfies IFontStyle as Value;
        }

        case 'imageAsset': {
            try {
                value = JSON.parse(value);
                if (typeof value === 'object') {
                    return value;
                }
            } catch {
                const imageAsset = (value as string).split(' ');

                // old images are missing gen ai flag. We have to detect that in order
                // to know if name is on 4th or 5th position
                const hasGenAiFlag = imageAsset[4]?.match(/^(true|false|undefined)$/);
                return {
                    id: imageAsset[0],
                    url: imageAsset[1],
                    width: parseInt(imageAsset[2], 10),
                    height: parseInt(imageAsset[3], 10),
                    isGenAi: !!hasGenAiFlag && imageAsset[4] === 'true',
                    name: hasGenAiFlag ? imageAsset[5] : imageAsset[4]
                } satisfies IImageElementAsset as Value;
            }
            break;
        }

        case 'padding': {
            const padding = value.split(' ') || [];
            return {
                top: padding[0] ? parseFloat(padding[0]) : 0,
                right: padding[1] ? parseFloat(padding[1]) : 0,
                bottom: padding[2] ? parseFloat(padding[2]) : 0,
                left: padding[3] ? parseFloat(padding[3]) : 0
            } as IPadding as Value;
        }

        case 'border': {
            const border = value.split(' ') as [`${number}`, `${BorderStyle}`, `${string}`];
            if (border.length <= 0) {
                return undefined as Value;
            }

            return {
                thickness: parseInt(border[0], 10),
                style: border[1],
                color: parseColor(border[2])
            } satisfies IBorder as Value;
        }

        case 'shadows': {
            const shadows = value ? splitSerializedShadowValue<SerializedShadow>(value) : undefined;
            if (!shadows) {
                return undefined as Value;
            }

            return shadows.map((s): IShadow => {
                const shadow = s.split(' ') as SerializedShadow;
                return {
                    offsetX: parseInt(shadow[0], 10),
                    offsetY: parseInt(shadow[1], 10),
                    blur: parseInt(shadow[2], 10),
                    spread: parseInt(shadow[3], 10),
                    color: parseColor(shadow[4])
                };
            }) as Value;
        }

        case 'textShadows': {
            const textShadows = value
                ? splitSerializedShadowValue<SerializedTextShadow>(value)
                : undefined;

            if (!textShadows) {
                return undefined as Value;
            }

            return textShadows.map((s): ITextShadow => {
                const shadow = s.split(' ') as SerializedTextShadow;
                return {
                    offsetX: parseInt(shadow[0], 10),
                    offsetY: parseInt(shadow[1], 10),
                    blur: parseInt(shadow[2], 10),
                    color: parseColor(shadow[3])
                };
            }) as Value;
        }

        case 'textColor':
        case 'fill':
            return parseColor(value) as Value;

        case 'feed':
            return deserializeFeedString(value) as Value;

        case 'filters':
        case 'imageSettings':
        case 'states':
        case 'animations':
        case 'videoAsset':
        case 'videoSettings':
        case 'actions':
        case 'radius':
            if (typeof value === 'string') {
                return value.length ? JSON.parse(value) : value;
            }
            return value;

        case 'content': {
            return (typeof value === 'string'
                ? deserializeVersionedText(value)
                : value) as unknown as Value;
        }

        default:
            return value as Value;
    }
}

export function deserializeTextStyle<
    Key extends CharacterPropertyKeys,
    Value extends Exclude<ICharacterProperties[Key], undefined>
>(key: Key, value: string): Value {
    switch (key) {
        case '__fontFamilyId':
            return value as Value;

        case 'variable':
            return deserializeElementPropertyStringValue('feed', value) as Value;

        default:
            return deserializeElementPropertyStringValue(key, value) as Value;
    }
}

export function serializeTextStyle(
    key: CharacterPropertyKeys,
    value: ICharacterProperties[CharacterPropertyKeys]
): string | undefined {
    switch (key) {
        case '__fontFamilyId':
            return value as string;

        case 'variable':
            return serializeFeedValue(value);

        case 'textColor':
            return serializeElementPropertyToStringValue('textColor', value as IColor);

        case 'font':
            return serializeElementPropertyToStringValue('font', value as IFontStyle);

        case 'textShadows':
            return serializeElementPropertyToStringValue('textShadows', value as ITextShadow[]);

        default:
            return value?.toString();
    }
}

/** Poorly typed, should only be used by version property due to messy types */
export function deserializeVersionPropertyValue(
    propertyName: IVersionProperty['name'],
    value: any
): OneOfVersionableProperties | undefined {
    if (value === null) {
        value = undefined;
    }

    switch (propertyName) {
        case 'feed':
            return deserializeFeedString(value);
        case 'content': {
            // Elements doesn't have "content" and value will instead be fetched from Version.properties
            if (value === undefined) {
                return undefined;
            }
            return typeof value === 'string' ? deserializeVersionedText(value) : value;
        }
        case 'text':
        case 'widgetText': {
            if (typeof value === 'string') {
                return value.length ? JSON.parse(value) : value;
            }
            return value;
        }
        default:
            return value;
    }
}

export function serializeVersionPropertyValue(
    propertyName: IVersionProperty['name'],
    value: OneOfVersionableProperties
): string {
    switch (propertyName) {
        case 'feed':
            return serializeFeedValue(value);
        case 'content': {
            return typeof value === 'string' ? value : serializeVersionedText(value as IVersionedText);
        }
        case 'widgetText': {
            return typeof value === 'string' ? value : serializeWidgetTextValue(value as IWidgetText);
        }
        default:
            return typeof value === 'string' ? value : JSON.stringify(value);
    }
}

/**
 * Serialize propertyValue to a string
 * @param propertyName
 * @param value
 */
export function serializeElementPropertyToStringValue<
    Key extends keyof (AllDataNodes & OneOfElementDataNodes)
>(key: Key, value: (AllDataNodes & OneOfElementDataNodes)[Key]): string | undefined {
    switch (key) {
        case 'duration':
        case 'time':
        case 'lineHeight':
        case 'characterSpacing':
        case 'fontSize':
        case 'originX':
        case 'originY':
        case 'scaleX':
        case 'scaleY':
        case 'opacity':
        case 'rotationX':
        case 'rotationY':
        case 'rotationZ':
        case 'ratio':
        case 'x':
        case 'y':
        case 'width':
        case 'height':
        case 'maxRows':
            return `${value}`;

        case 'horizontalAlignment':
        case 'verticalAlignment':
        case 'textOverflow':
            return value as string;

        case 'hidden':
        case 'locked':
        case 'mirrorX':
        case 'mirrorY':
        case 'strikethrough':
        case 'underline':
        case 'uppercase':
            return Boolean(value).toString();

        case 'font': {
            const fontStyle = value as IFontStyle;
            const src = fontStyle.src;
            return `${fontStyle.id} ${fontStyle.weight} ${fontStyle.style} ${src} ${fontStyle.fontFamilyId}`;
        }

        case 'border': {
            const border = value as IBorder;
            return border ? `${border.thickness} ${border.style} ${border.color}` : '';
        }

        case 'padding': {
            const padding = value as IPadding;
            return `${padding.top} ${padding.right || ''} ${padding.bottom || ''} ${
                padding.left || ''
            }`;
        }

        case 'shadows': {
            const shadows = value as IShadow[];
            return shadows
                ? shadows
                      .map(s => `${s.offsetX} ${s.offsetY} ${s.blur} ${s.spread} ${s.color}`)
                      .join(',')
                : '';
        }

        case 'textShadows': {
            const textShadow = value as ITextShadow[] | undefined;
            return textShadow
                ? textShadow.map(s => `${s.offsetX}px ${s.offsetY}px ${s.blur}px ${s.color}`).join(',')
                : '';
        }

        case 'textColor':
        case 'fill': {
            const color = value as Color;
            if (!color) {
                return '';
            } else {
                return color.toString();
            }
        }

        case 'content':
            return serializeVersionedText(value as IVersionedText);

        case 'feed':
            return serializeFeedValue(value);

        case 'filters':
        case 'imageSettings':
        case 'imageAsset':
        case 'states':
        case 'animations':
        case 'videoAsset':
        case 'videoSettings':
        case 'actions':
        case 'radius':
            return JSON.stringify(value);

        default:
            return value as string;
    }
}

export function splitSerializedShadowValue<T extends SerializedShadow | SerializedTextShadow>(
    str: string
): T {
    return str.split(/,(?![^(]*\))/) as T;
}

export function deserializeVersionProperty(
    versionProperty: IVersionProperty | ISerializedVersionProperty | VersionPropertyDto
): IVersionProperty {
    let newValue = deserializeVersionPropertyValue(versionProperty.name, versionProperty.value);

    if (!newValue) {
        throw new Error(`Couldn't deserialize version property`);
    }

    if (versionProperty.name === 'widgetText') {
        newValue = {
            text: (newValue as IWidgetText).text
        };
    }

    return {
        id: versionProperty.id,
        name: versionProperty.name as VersionPropertyName,
        value: newValue
    };
}

export function deserializeVersionProperties(
    versionProperties: Array<IVersionProperty | ISerializedVersionProperty | VersionPropertyDto> = []
): IVersionProperty[] {
    return versionProperties.map(versionProperty => deserializeVersionProperty(versionProperty));
}

export function deserializeFeedString(value: unknown): IFeed {
    if (!!value && typeof value !== 'string' && typeof value === 'object' && 'path' in value) {
        return value as IFeed;
    }

    if (typeof value === 'string') {
        try {
            const feed = JSON.parse(value);
            if (!('path' in feed)) {
                // Continue to old deserialization
                throw new Error();
            }

            return feed;
        } catch {
            const feedValues = value.split(' ');
            const feed: IFeed = {
                id: feedValues[0],
                path: feedValues[1] ?? '',
                step: {
                    start: parseInt(feedValues[2] ?? 1, 10),
                    size: parseInt(feedValues[3] ?? 1, 10),
                    occurrence: (feedValues[4] ?? 'loop') as IFeedStep['occurrence']
                },
                fallback: feedValues[5] ?? '',
                type: 'text'
            };
            return feed;
        }
    }

    return createFeed('');
}

export function serializeFeedValue(feed: unknown): string {
    if (typeof feed === 'object') {
        return JSON.stringify(feed);
    }

    if (typeof feed === 'string') {
        return feed;
    }

    return JSON.stringify(createFeed(''));
}
