import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { Logger } from '@bannerflow/sentinel-logger';
import { UINotificationService, UISubmitResponse } from '@bannerflow/ui';
import { isVersionedFeed } from '@creative/elements/feed/feeds.utils';
import { isVersionedText, isVersionedWidgetText } from '@creative/elements/rich-text/utils';
import {
    deserializeVersionProperty,
    deserializeVersionPropertyValue,
    serializeVersionPropertyValue
} from '@creative/serialization/property-value-serializer';
import { IElement, IElementProperty } from '@domain/creativeset/element';
import {
    ITPVersionedElementProperty,
    IVersion,
    IVersionedElementPropertyValuesByElement,
    IVersionedText,
    IVersionProperty,
    IWidgetText,
    VersionedPropertyUnit,
    VersionPropertyName
} from '@domain/creativeset/version';
import { IBfFeed, IFeed } from '@domain/feed';
import { IStyleIdMap, SpanType } from '@domain/text';
import { EventLoggerService, TranslationPanelSaveEvent } from '@studio/monitoring/events';
import { cloneDeep } from '@studio/utils/clone';
import { BehaviorSubject, filter, firstValueFrom } from 'rxjs';
import { CreativeSetShowcaseService } from '../../../shared/creativeset-showcase/state/creativeset-showcase.service';
import { EnvironmentService } from '../../../shared/services/environment.service';
import { VersionsService } from '../../../shared/versions/state/versions.service';
import {
    findVersionPropertyValue,
    isVersionedElementProperty
} from '../../../shared/versions/versions.utils';
import { EditCreativeService } from '../services/edit-creative.service';

export type PristineVersionCheck = () => Promise<boolean>;

interface SaveState {
    initialValue?: string;
    currentValue?: string;
}

@Injectable({ providedIn: 'root' })
export class TranslationPanelService {
    // state is used to determine if values has changed, so we know what to save when saving
    // or what to clear when you cancel changes
    private state: Map<string, SaveState> = new Map();

    pristineCallback: PristineVersionCheck;

    private _dirty$ = new BehaviorSubject<boolean>(false);
    dirty$ = this._dirty$.asObservable();
    private dirty = false;

    private logger = new Logger('TranslationPanelService');

    private shouldCheckPristine = false;

    // TODO: When refactoring translation panel, please, get rid of these.
    // Now they need to be updated from the TP component in order to have the latest changes in time
    versions: IVersion[];
    defaultVersion: IVersion;
    selectedVersion: IVersion;

    constructor(
        private editCreativeService: EditCreativeService,
        private uiNotificationService: UINotificationService,
        private eventLoggerService: EventLoggerService,
        private versionsService: VersionsService,
        private environmentService: EnvironmentService,
        private router: Router,
        private creativesetShowcaseService: CreativeSetShowcaseService
    ) {
        this.versionsService.shouldCheckPristine$
            .pipe(takeUntilDestroyed())
            .subscribe(shouldCheckPristine => {
                this.shouldCheckPristine = shouldCheckPristine;
            });
    }

    getElementsWithVersionedPropertyValues(
        elements: IElement[]
    ): IVersionedElementPropertyValuesByElement[] {
        const versionedElementPropertyValuesByElements: IVersionedElementPropertyValuesByElement[] = [];

        if (!this.selectedVersion) {
            throw new Error('No version selected.');
        }

        for (const element of elements) {
            const data: IVersionedElementPropertyValuesByElement = {
                elementId: element.id,
                name: element.name,
                properties: []
            };
            for (const property of element.properties) {
                if (!isVersionedElementProperty(property)) {
                    continue;
                }

                const epv = this.getVersionPropertyFromVersion(property);
                // 'content' is the only static name, widget names are dynamic
                const propertyName = property.name as Extract<VersionPropertyName, 'content'>;

                const unit =
                    propertyName === 'content' ? 'content' : (property.unit as VersionedPropertyUnit);

                if (unit !== 'content' && unit !== 'feed' && unit !== 'text') {
                    continue;
                }

                const originalValue =
                    this.defaultVersion.id === this.selectedVersion.id
                        ? undefined
                        : findVersionPropertyValue(this.defaultVersion, property.versionPropertyId);

                data.properties.push({
                    id: property.versionPropertyId,
                    label: property.label,
                    name: propertyName,
                    unit,
                    value: cloneDeep(epv.value),
                    originalValue
                });
            }

            if (data.properties.length > 0) {
                versionedElementPropertyValuesByElements.push(data);
            }
        }
        return versionedElementPropertyValuesByElements;
    }

    private getVersionPropertyFromVersion(property: IElementProperty): IVersionProperty {
        const selectedVersionProperty = this.selectedVersion.properties.find(
            ({ id }) => id === property.versionPropertyId
        );
        if (selectedVersionProperty) {
            return selectedVersionProperty;
        }
        const defaultVersionProperty = this.defaultVersion.properties.find(
            ({ id }) => id === property.versionPropertyId
        );
        if (defaultVersionProperty) {
            return defaultVersionProperty;
        }
        throw new Error(
            `Could not find element property value with versionPropertyId '${property.versionPropertyId}'.`
        );
    }

    setStartDirtyState(elements: IVersionedElementPropertyValuesByElement[]): void {
        this.state.clear();
        for (const element of elements) {
            for (const property of element.properties) {
                this.setDirtyState(property);
            }
        }
        this._dirty$.next(false);
    }

    private createVersionProperty(id: string, newPropertyValue?: string): IVersionProperty {
        let versionProperty: IVersionProperty | undefined;
        for (const version of this.versions) {
            const property = version.properties.find(prop => prop.id === id);
            if (property) {
                versionProperty = property;
                break;
            }
        }

        // if we cant find the property in the versions, we have to search in the original (in shared view for instance)
        if (!versionProperty) {
            const defaultVersionProperty = this.defaultVersion.properties.find(prop => prop.id === id);
            if (!defaultVersionProperty) {
                throw new Error(`Can not create version property.`);
            }
            versionProperty = defaultVersionProperty;
        }

        const propertyName = versionProperty.name;
        const deserializedPropertyValue = deserializeVersionPropertyValue(
            propertyName,
            newPropertyValue
        );

        return {
            id,
            name: propertyName,
            value: deserializedPropertyValue!
        };
    }

    async saveVersionedElementPropertyValues(): Promise<UISubmitResponse<string>> {
        this.eventLoggerService.log(
            new TranslationPanelSaveEvent(this.environmentService.bfstudio.inShowcaseMode)
        );

        const dirtyProperties: IVersionProperty[] = [];
        for (const [propertyId, value] of this.state) {
            const dirtyProperty = this.createVersionProperty(propertyId, value.currentValue);
            dirtyProperties.push(dirtyProperty);
        }

        try {
            const newVersionPropertiesArray = [
                ...this.selectedVersion.properties.filter(
                    vp => !dirtyProperties.find(dp => dp.id === vp.id)
                ),
                ...dirtyProperties
            ];

            const updatedVersion: IVersion = {
                ...this.selectedVersion!,
                properties: newVersionPropertiesArray
            };
            this.versionsService.updateVersion(updatedVersion);

            // wait until request is done
            await firstValueFrom(this.versionsService.updateSuccess$.pipe(filter(Boolean)));
            const error = await firstValueFrom(this.versionsService.error$);
            if (error) {
                throw error;
            }

            this.uiNotificationService.open(`${this.selectedVersion.name} was saved`, {
                autoCloseDelay: 5000,
                placement: 'top',
                type: 'info'
            });
        } catch (error) {
            return { error, state: this.selectedVersion.name };
        }
        this._dirty$.next(false);
        this.state.clear();
        this.editCreativeService.updateView();

        return { state: this.selectedVersion.name };
    }

    cancelChanges(elements: IVersionedElementPropertyValuesByElement[]): void {
        elements.forEach(element => {
            element.properties.forEach(property => {
                const state = this.state.get(property.id);
                if (!state?.initialValue) {
                    return;
                }
                const propertyUnit = isVersionedText(property) ? 'content' : property.unit;
                property.value = deserializeVersionPropertyValue(propertyUnit, state.initialValue)!;

                this.versionsService.renderDirtyVersionProperty({
                    versionProperty: property,
                    elementId: element.elementId
                });
            });
        });

        this.editCreativeService.updateView();

        this._dirty$.next(false);
    }

    propertyValueIsUndefined(property: ITPVersionedElementProperty): boolean {
        const originalValueIsUndefined =
            !property.originalValue || typeof property.originalValue !== 'undefined';

        if (isVersionedText(property)) {
            const versionIsUndefined = property.value.text === '' || property.value.text === undefined;
            const defaultVersionIsUndefined = originalValueIsUndefined;

            return versionIsUndefined && defaultVersionIsUndefined;
        }

        return originalValueIsUndefined && typeof property.value === 'undefined';
    }

    setDirtyState(property: ITPVersionedElementProperty): void {
        if (!property.value) {
            return;
        }
        const existingState = this.state.get(property.id);

        let propertyName: VersionPropertyName | VersionedPropertyUnit = isVersionedText(property)
            ? 'content'
            : property.unit;
        propertyName = isVersionedWidgetText(property) ? 'widgetText' : propertyName;

        const propertyValueDto = serializeVersionPropertyValue(propertyName, property.value);

        const initialValue = existingState ? existingState.initialValue : propertyValueDto;
        this.state.set(property.id, {
            initialValue,
            currentValue: propertyValueDto
        });
    }

    private canAccessDirtyProperties(): boolean {
        if (this.router.url.includes('/translate')) {
            return false;
        }
        if (this.environmentService.isMobile) {
            return false;
        }
        if (!this.defaultVersion) {
            return false;
        }
        if (this.environmentService.inShowcaseMode) {
            return this.creativesetShowcaseService.operationsAllowed(['updateVersions']);
        }
        // in MV desktop
        return true;
    }

    getDirtyProperties(version: IVersion): IVersionProperty[] {
        if (!this.canAccessDirtyProperties()) {
            // TP is hidden on Mobile
            return [];
        }
        const output: IVersionProperty[] = [];
        for (const property of version.properties) {
            if (!this.state.has(property.id)) {
                continue;
            }
            const dirtyProp = {
                ...property,
                value: this.state.get(property.id)!.currentValue!
            };
            output.push(deserializeVersionProperty(dirtyProp));
        }
        if (version.id === this.defaultVersion.id) {
            return output;
        }
        // Fill in default properties if needed. Fix for non-default versions not getting dirty props correctly
        const defaultVersionDirtyProperties = this.getDirtyProperties(this.defaultVersion);
        const missingDefaultDirtyProps = defaultVersionDirtyProperties.filter(
            ({ id }) => !output.find(dirtyProp => dirtyProp.id === id)
        );
        output.push(...missingDefaultDirtyProps);
        return output;
    }

    setNewDirty(dirty: boolean): void {
        const currentDirty = this.dirty;
        if (currentDirty !== dirty) {
            this._dirty$.next(dirty);
        }
    }

    saveVersionedElementPropertyValuesFinished = (error: any, versionName: string): void => {
        if (error) {
            this.logger.error(error);
            return this.uiNotificationService.open(`Could not save version '${versionName}'.`, {
                type: 'error',
                placement: 'top',
                autoCloseDelay: 5000
            });
        }
    };

    onTextChanged(
        newText: IVersionedText,
        elements: IVersionedElementPropertyValuesByElement<IVersionedText>[]
    ): void {
        this.setNewDirty(true);
        // properties[0] is always an instance of IVersionedText for text elements
        const elementStyle = elements[0].properties[0].value.styles.find(
            style => Object.keys(style.styleIds).length > 0
        );
        // Get the documentId
        const firstElementRootNodeId = Array.from(
            Object.keys(elementStyle?.styleIds || {}) || []
        ).pop();
        for (const element of elements) {
            const propertyValue = element.properties[0].value;
            const currentStyle = propertyValue.styles.find(
                style => Object.keys(style.styleIds).length > 0
            );
            const currentElementRootNodeId = Array.from(
                Object.keys(currentStyle?.styleIds || {}) || []
            ).pop();
            const newStyles = newText.styles.map(style => {
                let newStyleIds: IStyleIdMap = style.styleIds;
                if (firstElementRootNodeId && currentElementRootNodeId) {
                    const styleIdsArray = Array.from(Object.entries(style.styleIds));
                    styleIdsArray.forEach(entry => {
                        if (entry[0] === firstElementRootNodeId) {
                            entry[0] = currentElementRootNodeId;
                        }
                    });
                    newStyleIds = Object.fromEntries(styleIdsArray);
                }
                return {
                    ...style,
                    styleIds: newStyleIds
                };
            });

            element.properties[0].value = {
                text: newText.text,
                styles: newStyles
            };

            this.versionsService.renderDirtyVersionProperty({
                versionProperty: element.properties[0],
                elementId: element.elementId
            });
            this.setDirtyState(element.properties[0]);
        }
    }

    onWidgetChanged(
        versionProperty: IVersionProperty<IWidgetText>,
        elements: IVersionedElementPropertyValuesByElement[]
    ): void {
        this.setNewDirty(true);
        for (const element of elements) {
            for (const property of element.properties) {
                if (property.name !== versionProperty.name) {
                    continue;
                }
                property.value = { text: versionProperty.value.text };
                this.versionsService.renderDirtyVersionProperty({
                    versionProperty: property,
                    elementId: element.elementId
                });
                this.setDirtyState(property);
            }
        }
    }

    onFeedChanged(
        feed: IFeed,
        oldFeed: IFeed,
        elements: IVersionedElementPropertyValuesByElement[]
    ): void {
        this.setNewDirty(true);
        for (const element of elements) {
            for (const property of element.properties) {
                if (!isVersionedText(property)) {
                    continue;
                }
                for (let index = 0; index < property.value.styles.length; index++) {
                    const style = property.value.styles[index];

                    if (style.type !== SpanType.Variable || !style.variable) {
                        continue;
                    }

                    if (oldFeed.path !== feed.path) {
                        property.value.text = property.value.text.replaceAll(
                            `@${oldFeed.path}`,
                            `@${feed.path}`
                        );
                        const deltaLength = feed.path.length - oldFeed.path.length;
                        style.variable.path = feed.path;
                        style.length = feed.path.length + 1;

                        // Update other properties position
                        property.value.styles.slice(index + 1).forEach(nextStyle => {
                            nextStyle.position += deltaLength;
                        });

                        // update old path so this if doesn't get executed more than once
                        oldFeed.path = feed.path;
                    }

                    style.variable.step = { ...feed.step };
                    this.versionsService.renderDirtyVersionProperty({
                        versionProperty: property,
                        elementId: element.elementId
                    });
                    this.setDirtyState(property);
                }
            }
        }
    }

    onFeedSourceChanged(feed: IBfFeed, elements: IVersionedElementPropertyValuesByElement[]): void {
        this.setNewDirty(true);

        function mutateFeedId(property: ITPVersionedElementProperty): void {
            if (isVersionedFeed(property)) {
                property.value.id = feed.id;
                return;
            }
            if (isVersionedText(property)) {
                for (const style of property.value.styles) {
                    if (style.type === SpanType.Variable) {
                        style.variable!.id = feed.id;
                    }
                }
            }
        }

        for (const element of elements) {
            for (const property of element.properties) {
                mutateFeedId(property);

                this.versionsService.renderDirtyVersionProperty({
                    versionProperty: property,
                    elementId: element.elementId
                });
                this.setDirtyState(property);
            }
        }
    }

    async isPristine(): Promise<boolean> {
        return !this.shouldCheckPristine || this.pristineCallback?.();
    }
}
