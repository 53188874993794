import { IAd } from '@domain/ad/ad';
import { IAdDataCreative } from '@domain/ad/ad-data-creative';
import { AdEvents } from '@domain/ad/ad-events';
import { AdScriptMethodName, IAdScript, IAdScriptLoader } from '@domain/ad/ad-script';
import { IBannerflowWindow } from '@domain/ad/bannerflow-window';
import { IRedirect } from '@domain/ad/redirect';

export class AdScriptLoader implements IAdScriptLoader {
    /**
     * List of initiated custom scripts
     */
    scripts: IAdScript[] = [];

    /**
     * If custom script is handling preload,
     * keep track of werther ready has been fired or not
     */
    pageReady?: boolean;

    constructor(private ad: IAd) {
        // Keep state of pageready
        ad.once(AdEvents.PageReady, () => {
            this.pageReady = true;
        });

        this._loadScripts();
        this._initScriptsFromWindow();
    }

    private _initScriptsFromWindow(): void {
        const win = window as unknown as IBannerflowWindow;

        if (win._bannerflow) {
            const scripts = win._bannerflow.adScripts || [];

            for (let i = 0; i < scripts.length; i++) {
                try {
                    const cs = new (scripts[i] as any)(this.ad);
                    this.scripts.push(cs);
                } catch (e) {
                    console.error('Could not initiate AdScript, reason: ', e);
                }
            }
        }
    }

    private _loadScripts(): void {
        const scripts = this.ad.data.adScripts || this.ad.data.customScripts || [];

        for (let i = 0; i < scripts.length; i++) {
            try {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const AdScriptClass = new Function(`${scripts[i]}; return AdScript;`)();
                const cs = new AdScriptClass(this.ad);
                this.scripts.push(cs);
            } catch (e) {
                console.error('Could not initiate AdScript, reason: ', e);
            }
        }
    }

    /**
     * By defining this method and returning true when called you will "manually" handle preload.
     * To tell ad that "window.load" have been fired run ad.emit('reaady').
     */
    overridePreload(): boolean {
        let override = false;
        const method = 'overridePreload';

        try {
            this._forEach(script => {
                if (script[method]!(this.ad) === true) {
                    override = true;
                }
            }, method);
        } catch (e) {
            this._outputException(method, e);
        }

        return override;
    }

    /**
     * Custom target url
     */
    overrideRedirect(redirectUrl: IRedirect, targetUrl: string | undefined): IRedirect | undefined {
        let override: IRedirect | undefined;
        const method: AdScriptMethodName = 'overrideRedirect';

        try {
            this._forEach(script => {
                override = script[method]!(override || redirectUrl, targetUrl, this.ad) || override;
            }, method);
        } catch (e) {
            this._outputException(method, e);
        }

        return override;
    }

    /**
     * Custom target url
     */
    overrideTargetUrl(targetUrl: string | undefined): string | undefined {
        // let overrideUrl: string | undefined;

        const method = 'overrideTargetUrl';

        try {
            this._forEach(script => {
                const result = script[method]!(targetUrl, this.ad);
                targetUrl = typeof result === 'string' ? result : targetUrl;
            }, method);
        } catch (e) {
            this._outputException(method, e);
        }

        return targetUrl;
    }

    /**
     * Get domain of current web page. Used to provide an accurate domain reporting to tracker.
     */
    overrideDomain(): string {
        let domain = '';

        const method = 'overrideDomain';

        try {
            this._forEach(script => {
                domain = script[method]!(this.ad) || domain;
            }, method);
        } catch (e) {
            this._outputException(method, e);
        }

        return domain;
    }

    /**
     * Used to apply a custom open method. Maybe to open in-app links etc
     * Return true if the open has been overridden and you want to prevent default behaviour
     * false if not.
     */
    overrideOpen(targetUrl: string | undefined): boolean {
        let overridden = false;

        const method = 'overrideOpen';

        try {
            this._forEach(script => {
                overridden = script[method]!(targetUrl, this.ad) || overridden;
            }, method);
        } catch (e) {
            this._outputException(method, e);
        }

        return overridden;
    }

    /**
     * Custom logic for selecting creative
     */
    selectCreative(): IAdDataCreative | undefined {
        let creative;

        const method = 'selectCreative';

        this._forEach(script => {
            const fn = script[method];
            if (typeof fn === 'function') {
                try {
                    creative = fn(this.ad.data.creatives) || creative;
                } catch (e) {
                    this._outputException(method, e);
                }
            }
        }, method);

        return creative;
    }

    /**
     * Custom logic to choose where creative should be rendered
     */
    overrideContainer(): HTMLElement | undefined {
        let container: HTMLElement | undefined;

        const method = 'overrideContainer';

        try {
            this._forEach(script => {
                container = script[method]!(this.ad) || container;
            }, method);
        } catch (e) {
            this._outputException(method, e);
        }

        return container;
    }

    hasMethod(methodName: AdScriptMethodName): boolean {
        let foundMethod = false;

        this._forEach(script => {
            if (typeof script[methodName] === 'function') {
                foundMethod = true;
            }
        });

        return foundMethod;
    }

    private _outputException(method: AdScriptMethodName, error: Error | unknown): void {
        console.error(`Could not run AdScript.${method}, reason:`, error);
        if (error instanceof Error) {
            this.ad.emit(AdEvents.Error, { event: error });
        } else {
            this.ad.emit(AdEvents.Error, { event: `unkown error - ${error}` });
        }
    }

    private _forEach(callback: (script: IAdScript) => void, method?: AdScriptMethodName): void {
        for (let i = 0; i < this.scripts.length; i++) {
            const script = this.scripts[i];
            if (!method || typeof script[method] === 'function') {
                callback(script);
            }
        }
    }
}
