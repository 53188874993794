import { IBounds } from '@domain/dimension';
import { GroupOrLayoutElement, ILayoutRule } from '../rules';

/**
 * Rule to align ratio locked layout items to the new canvas bounds
 */
export class AlignToNewCanvasSizeRule implements ILayoutRule {
    applyRenderRule(layoutElement: GroupOrLayoutElement, calculatedBounds: IBounds): void {
        // Only applies to elements with aspect ratio locked
        if (!layoutElement.constraint.ratio) {
            return;
        }

        const { rotatedBoundingBox, originalCreative, newCanvasSize } = layoutElement;

        // check whether the layoutElement from the original canvas was aligned to left, right, top, or bottom.
        const isAlignedToLeft = rotatedBoundingBox.x === 0;
        const isAlignedToRight =
            originalCreative.width - rotatedBoundingBox.width === rotatedBoundingBox.x;
        const isAlignedToTop = rotatedBoundingBox.y === 0;
        const isAlignedToBottom =
            originalCreative.height - rotatedBoundingBox.height === rotatedBoundingBox.y;

        // align layoutElement to the new canvas bounds
        if (isAlignedToLeft) {
            calculatedBounds.x = 0;
        }
        if (isAlignedToRight) {
            calculatedBounds.x = newCanvasSize.width - calculatedBounds.width;
        }
        if (isAlignedToTop) {
            calculatedBounds.y = 0;
        }
        if (isAlignedToBottom) {
            calculatedBounds.y = newCanvasSize.height - calculatedBounds.height;
        }
    }
}
