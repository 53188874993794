import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ApolloError } from '@apollo/client/errors';
import {
    UIConfirmDialogResult,
    UIConfirmDialogService,
    UIDialogService,
    UINotificationService
} from '@bannerflow/ui';
import { Layouter } from '@creative/layout/layout';
import { CreativeDataNode } from '@creative/nodes/base-data-node';
import { IRenderer } from '@creative/renderer.header';
import { ApprovalStatus, ICreative } from '@domain/creativeset/creative';
import { ICreativeset } from '@domain/creativeset/creativeset';
import { CreativeSize } from '@domain/creativeset/size';
import { IVersion } from '@domain/creativeset/version';
import { isKnownErrorResponse } from '@studio/domain/api/error';
import { cloneDeep } from '@studio/utils/clone';
import { createDesign, hasDesign } from '@studio/utils/design.utils';
import { uuidv4 } from '@studio/utils/id';
import { BehaviorSubject, firstValueFrom, Subject, take, takeUntil } from 'rxjs';
import {
    ICreateDesignInputType,
    IUpdateDesignInputType,
    OneOfDesignInputTypes
} from '../../../shared/creativeset/creativeset-mutations';
import { CreativesetDataService } from '../../../shared/creativeset/creativeset.data.service';
import { SizesService } from '../../../shared/sizes/sizes.data.service';
import { VersionsService } from '../../../shared/versions/state/versions.service';
import { DeleteActiveSizeDialogComponent, DialogAction } from '../delete-creative-dialog';
import { DeleteSizeDialogComponent } from '../delete-creative-dialog/delete-size-dialog.component';
import { UrlDialogService } from '../url-dialog/url-dialog.service';
import { ChangeState, CreativeMutation } from './creative-mutation';
import { TileSelectService } from './tile-select.service';

@Injectable({ providedIn: 'root' })
export class EditCreativeService {
    activation = new CreativeMutation<ICreative[]>();
    deactivation = new CreativeMutation<ICreative[]>();
    deletion = new CreativeMutation<CreativeSize[]>();
    updatedCreativeTargetUrl$ = new Subject<ICreative[]>();

    private _creativeComponentLoaded$ = new Subject<string /** creativeId */>();
    creativeComponentLoaded$ = this._creativeComponentLoaded$.asObservable();
    private creativeset: ICreativeset;

    private _updateView$ = new Subject<void>();
    updateView$ = this._updateView$.asObservable();

    private _isUpdatingChecksums$ = new BehaviorSubject<boolean>(false);
    isUpdatingChecksums$ = this._isUpdatingChecksums$.asObservable();

    private _statusUpdated$ = new Subject<ICreative[]>();
    statusUpdated$ = this._statusUpdated$.asObservable();

    private _creativeVisibilityStatus: ICreativeVisiblity = {};
    get creativeVisibilityStatus(): Readonly<ICreativeVisiblity> {
        return this._creativeVisibilityStatus;
    }
    private _creativeVisibilityStatus$ = new BehaviorSubject<ICreativeVisiblity>({});
    creativeVisibilityStatus$ = this._creativeVisibilityStatus$.asObservable();
    private deleteDialogOpen: boolean;
    copiedCreative: ICreative;
    private selectedCreatives: ICreative[];

    private versions: IVersion[] = [];
    private defaultVersion: IVersion;

    private unsubscribe$ = new Subject<void>();

    constructor(
        private creativesetDataService: CreativesetDataService,
        private tileSelectService: TileSelectService,
        private urlDialogService: UrlDialogService,
        private uiConfirmDialogService: UIConfirmDialogService,
        private uiNotificationService: UINotificationService,
        private uiDialogService: UIDialogService,
        private versionsService: VersionsService,
        private sizeService: SizesService
    ) {
        this.sizeService.update$.pipe(takeUntil(this.unsubscribe$)).subscribe(size => {
            const creativesetSize = this.creativeset.sizes.find(s => s.id === size.id)!;

            creativesetSize.name = size.name;

            this.creativesetDataService.creativesetChanged();
        });

        this.creativesetDataService.creativeset$
            .pipe(takeUntilDestroyed())
            .subscribe(creativeset => (this.creativeset = creativeset));
    }

    init(): void {
        this.creativeset = this.creativesetDataService.creativeset;
        this.tileSelectService.selection$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(creatives => (this.selectedCreatives = creatives));

        this.versionsService.versions$.pipe(takeUntil(this.unsubscribe$)).subscribe(versions => {
            this.versions = versions;
        });

        this.versionsService.defaultVersion$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(defaultVersion => {
                this.defaultVersion = defaultVersion;
            });
    }

    destroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    updateView(): void {
        this._updateView$.next();
    }

    async deactivateSelectedDesigns(creatives: ICreative[]): Promise<void> {
        creatives = creatives.filter(creative => !!creative.design);
        const clearQueue = this.selectedCreatives.filter(creative => !!creative.design);

        const creativesToDeactivate = clearQueue.length ? clearQueue : creatives;

        if (!creativesToDeactivate?.length) {
            return;
        }

        const ids: string[] = [];
        for (const creative of creatives) {
            if (creative.design) {
                ids.push(creative.design.id);
            }
        }

        this.deactivation.emit(creatives, ChangeState.PENDING);

        const propertiesIdsToRemove = await firstValueFrom(
            this.creativesetDataService.deleteDesignsInCreativeset(ids)
        );
        this.versionsService.removeVersionPropertiesByIds(propertiesIdsToRemove);
        this.deactivation.emit(creatives, ChangeState.FINISHED);
    }

    async activateCreatives(
        creatives: ICreative[],
        copiedCreative?: ICreative,
        keepDocumentId = false
    ): Promise<void> {
        const newCreativesDesigns = await this.getDesignRequests(
            'create',
            creatives,
            copiedCreative,
            keepDocumentId
        );
        this.activation.emit(creatives, ChangeState.PENDING);
        const response = await firstValueFrom(
            this.creativesetDataService.createDesignsWithExistingSize(newCreativesDesigns)
        );

        if (!response || isKnownErrorResponse(response)) {
            this.uiNotificationService.open(
                'Something went wrong when activating sizes. Contact support if the issue persists.'
            );
            return;
        }

        this.versionsService.onUpdateDesignsInCreativeset(response.versions);
        this.activation.emit(creatives, ChangeState.FINISHED);
        this.updateView();
    }

    private async getDesignRequests<
        RequestType extends 'create' | 'update',
        DesignInput = RequestType extends 'create' ? ICreateDesignInputType : IUpdateDesignInputType
    >(
        requestType: RequestType,
        creatives: ICreative[],
        copiedCreative?: ICreative,
        keepDocumentId = false
    ): Promise<DesignInput[]> {
        const designRequests: OneOfDesignInputTypes[] = [];
        const filteredCreatives = creatives.filter(creative => copiedCreative || !creative.design);

        for (const creative of filteredCreatives) {
            for (const creativesetCreative of this.creativesetDataService.creativeset.creatives) {
                const isInvalidSize =
                    creativesetCreative.size.id !== creative.size.id ||
                    designRequests.some(accCreative => accCreative.size.id === creative.size.id);

                if (isInvalidSize) {
                    continue;
                }

                const request =
                    requestType === 'create'
                        ? this.createDesignRequest(creative, copiedCreative, keepDocumentId)
                        : this.updateDesignRequest(creative, copiedCreative);

                designRequests.push(request);
            }
        }

        return (await this.patchDesignRequests(designRequests)) as DesignInput[];
    }

    private async patchDesignRequests(
        designRequests: Omit<OneOfDesignInputTypes, 'versions'>[]
    ): Promise<OneOfDesignInputTypes[]> {
        const creativesetDocumentIds = this.creativeset.creatives.map(
            creative => creative.design?.document.id || ''
        );
        const designRequestsDocumentIds = designRequests.map(
            designRequest => designRequest.design.document.id
        );
        this.versionsService.cleanStyleIds([...creativesetDocumentIds, ...designRequestsDocumentIds]);
        const versions = await firstValueFrom(this.versionsService.versions$);
        return designRequests.map(designRequest => ({
            ...designRequest,
            versions
        }));
    }

    async deleteSizes(creatives?: ICreative[]): Promise<void> {
        if (!creatives) {
            creatives = this.tileSelectService.getSelected();
        }

        if (!creatives.length) {
            return;
        }
        const activeSizesToDelete = this.getActivatedCreatives(creatives);
        const lastActiveSizeInDeletion = this.isAllActivatedInSelection(activeSizesToDelete);
        const allSelected = this.isAllCreativesInSelection(creatives);
        let result: string | undefined;

        if ((activeSizesToDelete && lastActiveSizeInDeletion) || allSelected) {
            result = await this.deleteLastActiveSizeModal(allSelected);
        } else {
            result = await this.deleteSizeModal(creatives);
        }

        if (result === 'confirm') {
            const sizes = creatives.map(c => c.size);
            this.deletion.emit(sizes, ChangeState.PENDING);
            const creativeset = await firstValueFrom(
                this.creativesetDataService.deleteSizesInCreativeset(sizes)
            );
            this.versionsService.resetVersions(creativeset.versions);
            this.tileSelectService.remove(...creatives);
            this.deletion.emit(sizes, ChangeState.FINISHED);
            this.creativesetDataService.creativesetChanged();
            this.updateView();
        }
    }

    async deleteLastActiveSizeModal(allSelected: boolean): Promise<string | undefined> {
        if (this.deleteDialogOpen) {
            return;
        }

        this.deleteDialogOpen = true;

        const dialogRef = this.uiDialogService.openComponent(DeleteActiveSizeDialogComponent, {
            headerText: allSelected ? 'Delete all sizes' : 'Delete last active size',
            width: '512px'
        });

        await dialogRef.afterViewInit;
        dialogRef.afterClose().subscribe(() => {
            this.deleteDialogOpen = false;
        });
        const type = allSelected ? DialogAction.DeleteAll : DialogAction.Delete;
        const dialogResponse = await (
            dialogRef.subComponentRef.instance as DeleteActiveSizeDialogComponent
        ).initiate(type);
        dialogRef.close();

        return dialogResponse;
    }
    async deleteSizeModal(creatives: ICreative[]): Promise<string | undefined> {
        if (this.deleteDialogOpen) {
            return;
        }

        this.deleteDialogOpen = true;

        const dialogRef = this.uiDialogService.openComponent(DeleteSizeDialogComponent, {
            headerText: 'Delete selected sizes',
            width: '492px'
        });

        await dialogRef.afterViewInit;
        dialogRef.afterClose().subscribe(() => {
            this.deleteDialogOpen = false;
        });
        const uniqueSizeCreatives = this.getUniqueSizeCreatives(creatives);
        const dialogResponse = await (
            dialogRef.subComponentRef.instance as DeleteSizeDialogComponent
        ).initiate(uniqueSizeCreatives);
        dialogRef.close();

        return dialogResponse;
    }

    async overrideTargetUrl(creatives: ICreative[]): Promise<void> {
        const [first] = creatives;
        const hasSameUrl = creatives.every(creative => creative.targetUrl === first.targetUrl);

        const url = await this.urlDialogService.open(hasSameUrl ? first.targetUrl : undefined, {
            creatives
        });

        if (url !== 'cancel') {
            for (const creative of creatives) {
                creative.targetUrl = url;
            }
            try {
                await firstValueFrom(this.creativesetDataService.updateCreatives(creatives));
                this.updatedCreativeTargetUrl$.next(creatives);
            } catch (e) {
                if (e instanceof ApolloError) {
                    if (e.networkError instanceof HttpErrorResponse && e.networkError.status === 400) {
                        const message = 'Invalid target URL. Please, check your URL and try again';
                        this.uiNotificationService.open(message, { placement: 'top', type: 'error' });
                    }
                } else {
                    throw e;
                }
            }
        }
    }

    private createDesignRequest(
        creative: ICreative,
        copiedCreative?: ICreative,
        keepDocumentId = false
    ): ICreateDesignInputType {
        const originalDesign = copiedCreative
            ? copiedCreative.design!
            : Layouter.getBestDesignBasedOnSize(creative.size, this.creativesetDataService.creativeset);
        const layouter = new Layouter(originalDesign.document, creative.size);
        const document = CreativeDataNode.copy(layouter.newCreative, keepDocumentId);
        document.guidelines = [];
        document.width = creative.size.width;
        document.height = creative.size.height;

        const design = createDesign({
            name: `design-${uuidv4()}`,
            document,
            elements: cloneDeep(originalDesign.elements),
            hasHeavyVideo: originalDesign.hasHeavyVideo
        });

        this.versionsService.copyStyleIdsBetweenDocuments({
            sourceDocumentId: originalDesign.document.id,
            targetDocumentId: design.document.id
        });

        return {
            size: creative.size,
            design,
            versions: []
        };
    }

    private updateDesignRequest(
        creative: ICreative,
        copiedCreative?: ICreative
    ): IUpdateDesignInputType {
        const originalDesign = copiedCreative
            ? copiedCreative.design!
            : Layouter.getBestDesignBasedOnSize(creative.size, this.creativesetDataService.creativeset);
        const layouter = new Layouter(originalDesign.document, creative.size);
        const document = CreativeDataNode.copy(layouter.newCreative);
        document.guidelines = [];
        document.width = creative.size.width;
        document.height = creative.size.height;
        const design = creative.design!;
        if (!design) {
            throw new Error('Trying to update creative design, but design does not exist');
        }

        design.document = document;
        design.elements = cloneDeep(originalDesign.elements);
        design.hasHeavyVideo = originalDesign.hasHeavyVideo;
        design.name = originalDesign.name;

        this.versionsService.copyStyleIdsBetweenDocuments({
            sourceDocumentId: originalDesign.document.id,
            targetDocumentId: design.document.id
        });

        return {
            size: creative.size,
            design,
            versions: []
        };
    }

    async activateDesign(creative: ICreative): Promise<void> {
        const creatives = [creative];
        await this.activateCreatives(creatives);
        this.updateView();
    }

    async deactivateDesign(creatives: ICreative[]): Promise<void> {
        creatives = creatives.filter(hasDesign);

        const activeSizesToDeactivate = this.getActivatedCreatives(creatives);
        const lastActiveSizeInDeactivation = this.isAllActivatedInSelection(activeSizesToDeactivate);
        const allSelected = this.isAllCreativesInSelection(creatives);
        let result;
        if ((activeSizesToDeactivate && lastActiveSizeInDeactivation) || allSelected) {
            result = await this.deactivateLastActiveDesignModal(allSelected);
        } else {
            result = await this.deactivateDesignModal(creatives);
        }
        if (result === 'confirm') {
            await this.deactivateSelectedDesigns(creatives);
            this.updateView();
        }
    }

    async saveNewCreative(creative: ICreative): Promise<void> {
        // keep design.document.id when saving to avoid character styles having invalid document-style map
        await this.activateCreatives([creative], creative, true);
    }

    private async deactivateLastActiveDesignModal(allSelected: boolean): Promise<string | undefined> {
        if (this.deleteDialogOpen) {
            return;
        }

        this.deleteDialogOpen = true;
        const type = allSelected ? DialogAction.DeactivateAll : DialogAction.Deactivate;
        const dialogRef = this.uiDialogService.openComponent(DeleteActiveSizeDialogComponent, {
            headerText: allSelected ? 'Deactivate all sizes' : 'Deactivate last active size',
            width: '512px'
        });

        await dialogRef.afterViewInit;
        dialogRef.afterClose().subscribe(() => {
            this.deleteDialogOpen = false;
        });

        const dialogResponse = await (
            dialogRef.subComponentRef.instance as DeleteActiveSizeDialogComponent
        ).initiate(type);
        dialogRef.close();

        return dialogResponse;
    }

    private async deactivateDesignModal(creatives: ICreative[]): Promise<UIConfirmDialogResult> {
        let headerText = `Deactivate size ${creatives[0].size.width} × ${creatives[0].size.height}?`;
        let confirmText = 'Deactivate size';

        if (creatives.length > 1) {
            confirmText = `Deactivate sizes (${creatives.length})`;
            headerText = 'Deactivate selected sizes?';
        }

        return this.uiConfirmDialogService.confirm({
            headerText,
            confirmText,
            discardText: 'Cancel',
            text: `Elements not used in other active sizes will be lost.<br/><br/>
                This action affects all versions and cannot be undone.`
        });
    }

    async updateApprovalStatus(approvalStatus: ApprovalStatus, creatives: ICreative[]): Promise<void> {
        this.creativesetDataService
            .setApprovalStatus(approvalStatus, creatives, this.creativeset.id)
            .pipe(take(1))
            .subscribe({
                next: () => {
                    creatives.forEach(creative => {
                        creative.approvalStatus = approvalStatus;
                    });
                    this._statusUpdated$.next(creatives);
                },
                error: () => {
                    this.uiNotificationService.open(`Could not save. Failed to update statuses.`, {
                        type: 'error',
                        autoCloseDelay: 5000,
                        placement: 'top'
                    });
                }
            });
    }

    async saveSizeName(size: CreativeSize, name: string): Promise<void> {
        if (name === size.name) {
            return;
        }

        const updatedSize = await firstValueFrom(
            this.sizeService.updateSizeName(size.id, name, this.creativeset.id)
        );

        size.name = updatedSize.name;
    }

    copyDesign(): void {
        const creative = this.selectedCreatives[0];
        if (!creative) {
            return;
        }
        this.copiedCreative = creative;
    }

    async pasteDesign(): Promise<void> {
        let creatives = this.selectedCreatives.filter(
            c => !hasDesign(this.copiedCreative) || c.design !== this.copiedCreative.design
        );

        const distinctCreatives = this.getDistinctCreatives(creatives);
        const result = await this.pasteDesignModal(distinctCreatives, this.copiedCreative);

        if (result === 'confirm') {
            const nonActiveCreatives = creatives.filter(creative => !hasDesign(creative));
            creatives = creatives.filter(hasDesign);
            const promises: PromiseLike<void>[] = [];
            if (nonActiveCreatives.length) {
                promises.push(this.activateCreatives(nonActiveCreatives, this.copiedCreative, true));
            }

            if (creatives.length) {
                promises.push(this.updateDesigns(creatives, this.copiedCreative));
            }

            this.activation.emit(creatives, ChangeState.PENDING);
            await Promise.all(promises);
            this.activation.emit(creatives, ChangeState.FINISHED);
        }
    }

    private async updateDesigns(creatives: ICreative[], copiedCreative?: ICreative): Promise<void> {
        const selectedCreatives = await this.getDesignRequests('update', creatives, copiedCreative);
        const designs = selectedCreatives.map(c => c.design);
        const dirtyVersions: IVersion[] = selectedCreatives.reduce(
            (acc, item) => [...acc, ...item.versions.filter(({ id }) => !acc.find(v => v.id === id))],
            [] as IVersion[]
        );
        const response = await firstValueFrom(
            this.creativesetDataService.updateDesignsInCreativeset(
                this.versions,
                designs,
                dirtyVersions,
                this.defaultVersion
            )
        );

        this.activation.emit(creatives, ChangeState.FINISHED);

        if (isKnownErrorResponse(response)) {
            this.uiNotificationService.open(
                `Failed to save: ${response.message ?? 'Something went wrong'}`,
                {
                    type: 'error',
                    autoCloseDelay: 5000,
                    placement: 'top'
                }
            );
            return;
        }
        if (response) {
            this.versionsService.onUpdateDesignsInCreativeset(response.versions);
        }
    }

    async pasteDesignModal(
        creatives: ICreative[],
        copiedCreative: ICreative
    ): Promise<UIConfirmDialogResult> {
        const creativesText = creatives.map(
            creative => `${creative.size.width} × ${creative.size.height}`
        );
        return this.uiConfirmDialogService.confirm({
            headerText: 'Paste design',
            confirmText: `Paste design (${creatives.length})`,
            discardText: 'Cancel',
            text: `Paste the copied design of ${copiedCreative.size.width} × ${
                copiedCreative.size.height
            } onto:
                <p>${creativesText.join('<br>')}</p>
                Note! Existing designs on these sizes will get overwritten. This action cannot be undone.`
        });
    }

    async forceUpdateChecksums(): Promise<void> {
        this._isUpdatingChecksums$.next(true);
        await firstValueFrom(this.creativesetDataService.updateStateId());
        this.uiNotificationService.open(
            'Regeneration successful! Press "Push changes" to update your campaign.',
            {
                type: 'info',
                placement: 'top',
                autoCloseDelay: 5000
            }
        );
        this._isUpdatingChecksums$.next(false);
    }

    setCreativeVisiblityStatus(creative: ICreative, status: Partial<ICreativeVisiblityStatus>): void {
        const currentStatus = this.creativeVisibilityStatus[creative.id];
        const visible = status.visible ?? currentStatus?.visible;
        const renderer = status.renderer ?? currentStatus?.renderer;
        this._creativeVisibilityStatus[creative.id] = {
            visible,
            renderer
        };

        this._creativeVisibilityStatus$.next(this.creativeVisibilityStatus);
    }

    clearCreativeVisiblityStatus(): void {
        this._creativeVisibilityStatus = {};
    }

    getUniqueSizeCreatives(creatives: ICreative[]): ICreative[] {
        return creatives.filter(
            (creative, index, arr) =>
                index === arr.findIndex(tmpCreative => tmpCreative.size.id === creative.size.id)
        );
    }

    onCreativeComponentLoaded(creativeId: string): void {
        this._creativeComponentLoaded$.next(creativeId);
    }

    private getDistinctCreatives(creatives: ICreative[]): ICreative[] {
        const distinctCreatives: ICreative[] = [];

        creatives.forEach(creative => {
            if (!creative.design || !distinctCreatives.find(dc => dc.design === creative.design)) {
                distinctCreatives.push(creative);
            }
        });
        return distinctCreatives;
    }

    private getActivatedCreatives(creatives: ICreative[]): ICreative[] | undefined {
        return creatives.filter(hasDesign);
    }

    private isAllActivatedInSelection(creatives: ICreative[] | undefined): boolean {
        if (!creatives) {
            return false;
        }
        const creativeDesignIds = creatives.map(creative => creative.design?.id);
        const allActiveCreatives = this.creativeset.creatives.filter(creative => creative.design);
        const allActiveDesignIds = allActiveCreatives.map(creative => creative.design?.id);
        const allUniqueDesignIds = [...new Set(allActiveDesignIds)];
        if (allUniqueDesignIds.length === 0) {
            return false;
        }
        return allUniqueDesignIds.every(id => {
            return creativeDesignIds.includes(id);
        });
    }

    private isAllCreativesInSelection(creatives: ICreative[]): boolean {
        const allSelectedSizeIds = creatives.map(creative => creative.size.id);
        const allUniqueSelectedSizeIds = [...new Set(allSelectedSizeIds)];
        const allCreativesSizeIds = this.creativeset.creatives.map(creative => creative.size.id);
        const allUniqueSizeIds = [...new Set(allCreativesSizeIds)];
        return allUniqueSizeIds.every(id => {
            return allUniqueSelectedSizeIds.includes(id);
        });
    }
}

interface ICreativeVisiblityStatus {
    visible: boolean;
    renderer?: IRenderer;
}

export interface ICreativeVisiblity {
    [creativeId: string]: ICreativeVisiblityStatus;
}
