<ng-container *permissions="'Comments'">
    <div
        class="comments"
        [class.active]="commentsIsOpen"
        #commentsThread="csThreads"
        *ngIf="commentsInitialized$ | async"
        [csThreads]="threadConfig">
        <ui-svg-icon icon="comments"></ui-svg-icon>
        <span>{{ commentsCount }}</span>
    </div>
</ng-container>
