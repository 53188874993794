import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CREATIVES_FEATURE_KEY, CreativesState } from './creatives.reducer';

export const selectCreativesetState = createFeatureSelector<CreativesState>(CREATIVES_FEATURE_KEY);

export const getFocusedElementId = createSelector(
    selectCreativesetState,
    ({ focusedElementId }) => focusedElementId
);

export const getFocusedElement = createSelector(
    selectCreativesetState,
    ({ focusedElementId, focusedVersionId }) => ({ focusedVersionId, focusedElementId })
);
