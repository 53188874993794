import {
    getAllKeyframes,
    getNextTime,
    getPreviousTime,
    getTimePropertyIsAnimating,
    isTimeAt,
    sortByTime
} from '@creative/animation.utils';
import { Color } from '@creative/color';
import { toRGBA } from '@creative/color.utils';
import { calculateBoundingBox } from '@creative/elements/utils';
import {
    hasSelectionOfAllNodesInGroup,
    isEllipseNode,
    isImageNode,
    isLocked,
    isVisibleAtTime
} from '@creative/nodes';
import { isHidden, isSelectionVisibleAtTime, positionIsInBounds } from '@creative/nodes/helpers';
import { animationsToState, getViewElementPropertyValue } from '@creative/rendering';
import { ITime } from '@domain/animation';
import { IBoundingBox, IBoundingCorners, IPosition, ISize } from '@domain/dimension';
import { ElementKind } from '@domain/elements';
import { ImageSizeMode } from '@domain/image';
import {
    ICreativeDataNode,
    OneOfDataNodes,
    OneOfElementDataNodes,
    OneOfViewNodes
} from '@domain/nodes';
import { IState } from '@domain/state';
import { GuidelineType, ISnapLine, TransformMode } from '@domain/workspace';
import {
    distance,
    distance2D,
    getBoundingRectangleOfRotatedBox,
    isSameBounds,
    pointsToAngle,
    withinImageBounds
} from '@studio/utils/geom';
import { decimalToPercentage, rotatePosition, toDegrees } from '@studio/utils/utils';
import { firstValueFrom, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    BORDER_DIMMED_COLOR,
    GizmoColor,
    OUTLINE_COLOR,
    SNAPPING_COLOR
} from '../../../shared/components/canvas-drawer/gizmo.colors';
import { CreativesetDataService } from '../../../shared/creativeset/creativeset.data.service';
import {
    ElementHighlightService,
    ElementSelectionBoundingBoxService,
    ElementSelectionService,
    SelectionNetService
} from '../services';
import { EditorEventService } from '../services/editor-event/editor-event.service';
import { EditorStateService } from '../services/editor-state.service';
import { StudioWorkspaceComponent } from './studio-workspace.component';
import { IColorPoint } from './workspace-gradient-helper.service';
import { UserSettingsService, IDesignViewSettings } from '@studio/stores/user-settings';

const BALL_SIZE = 4;
const BALL_COLOR = '#FFFFFF';
const LINE_WIDTH = 1;
const TRANSITION_PATH_WIDTH = 1;
const TRANSITION_KEYFRAME_DOT_RADIUS = 2;
const TRANSITION_EASING_DOT_RADIUS = 1;
const TRANSITION_EASING_DOT_MIN_DISTANCE = 3;

export class WorkspaceGizmoDrawer {
    xHelpers: number[] = [];
    yHelpers: number[] = [];
    drawElementGizmos = true;
    drawCreativeBorder = false;
    drawOverflow: boolean;
    hideOverflow: boolean;
    drawOutline: boolean;
    gridEnabled: boolean;
    drawGuideline = true;
    drawGuidelineTooltip = false;
    gridOptions: {
        width: number;
        height: number;
    };
    gridColor = 'rgba(0, 0, 0, 0.5)';

    private canvasElementSize: ISize = {} as ISize;
    private ctx: CanvasRenderingContext2D;
    private canvas: HTMLCanvasElement;
    private fontFamily = '"Open Sans", sans-serif';
    private selectionNet: IBoundingBox | undefined;
    private unsubscribe$ = new Subject<void>();
    private guidelineColor: string;
    private isWorkspaceInitialized = false;

    constructor(
        private mount: HTMLElement,
        private workspace: StudioWorkspaceComponent,
        private editorStateService: EditorStateService,
        private editorEventService: EditorEventService,
        private userSettingsService: UserSettingsService,
        private elementSelectionService: ElementSelectionService,
        private selectionNetService: SelectionNetService,
        private elementHighlightService: ElementHighlightService,
        private elementSelectionBoundingBoxService: ElementSelectionBoundingBoxService,
        private creativesetDataService: CreativesetDataService
    ) {
        this.canvas = document.createElement('canvas');
        this.canvas.style.position = 'absolute';
        this.canvas.style.top = '0';
        this.canvas.style.zIndex = '1';
        this.canvas.style.pointerEvents = 'none';
        mount.appendChild(this.canvas);
        window.addEventListener('resize', this.setCanvasSize);
        this.ctx = this.canvas.getContext('2d')!;
        this.ctx.imageSmoothingEnabled = true;
        (this.ctx as any).webkitImageSmoothingEnabled = true;

        this.elementHighlightService.elementHighlight$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => this.draw());

        this.selectionNetService.selectionNet$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(selectionNet => {
                this.selectionNet = selectionNet;
                this.draw();
            });

        this.editorEventService.workspaceViewInit$.subscribe(isInitialized => {
            this.isWorkspaceInitialized = isInitialized;
            this.init();
        });
    }

    destroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        window.removeEventListener('resize', this.setCanvasSize);
    }

    private init(): void {
        this.setCanvasSize();
        this.userSettingsService.designViewSettings$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(settings => this.setDesignViewSettings(settings));
    }

    draw(boundingBoxOverwrite?: IBoundingBox): void {
        if (!this.isWorkspaceInitialized) {
            return;
        }

        const workspace = this.workspace;
        const creativeDocument = this.workspace.editorStateService.renderer.creativeDocument;
        const gradientHelper = workspace.gradientHelper;
        const state = workspace.designView.propertiesService.getCalculatedStateAtCurrentTime();
        const transformMode = this.workspace.transform.mode;
        const time = this.workspace.designView.time;

        this.clear();

        // Draw the workspace opacity
        if (this.drawOverflow || this.hideOverflow) {
            this.drawWorkspaceOverflow(!!this.hideOverflow);
        }

        // Draw the border of the canvas
        if (this.drawCreativeBorder) {
            this.drawBorder();
        }

        if (this.drawOutline && this.drawElementGizmos) {
            this.drawElementsOutline(creativeDocument.elements);
        }

        if (!workspace.designView.animator?.isPlaying && !state) {
            this.drawImageOutline();
        }

        if (this.drawGuideline && !this.workspace.mutatorService.preview) {
            this.drawGuidelines(creativeDocument);
        }
        if (this.drawGuidelineTooltip && !this.workspace.transform.guidelineSelection.instance) {
            this.drawGuidelineLabel('New guideline');
        }
        // Draw helper guides when objects is moving/being transformed
        this.drawHelpers();
        if (this.gridEnabled && !workspace.designView.animator?.isPlaying) {
            this.drawGrid();
        }

        this.drawSnapLines(workspace.horizontalSnapLines, 'x');
        this.drawSnapLines(workspace.verticalSnapLines, 'y');

        if (transformMode === TransformMode.EditGradient && this.drawElementGizmos) {
            this.drawGradientHelper(gradientHelper.points);
            return;
        }

        const selectionNet = this.selectionNet;
        if (selectionNet) {
            this.drawSelectionNetGizmo(selectionNet);
        }

        if (workspace.zoomBox) {
            this.drawZoomBox(workspace.zoomBox);
        }

        if (workspace.createElementBox) {
            const box = workspace.createElementBox;
            this.drawCreateElementGizmo(box, workspace.createElementKind!);
            this.drawLabel(`width: ${box.width} height: ${box.height}`);
        }

        const selection = this.elementSelectionService.currentSelection;
        const elements = selection.elements;
        const notHiddenElements = elements.filter(element => !isHidden(element));
        const hiddenElements = elements.filter(element => isHidden(element));
        const boundingBox =
            boundingBoxOverwrite ||
            this.elementSelectionBoundingBoxService.boundingBoxes?.fullSelection;

        for (const element of hiddenElements) {
            if (!isVisibleAtTime(element, time)) {
                continue;
            }

            if (elements.length === 1 && selection.has(element)) {
                this.drawHighlightGizmo(element);
            } else {
                this.drawWireframeOutline(element);
            }
        }

        this.drawGroupOutlines();

        if (
            boundingBox &&
            notHiddenElements.length > 0 &&
            this.drawElementGizmos &&
            !this.workspace.transform.isPositioningImage
        ) {
            if (!isSelectionVisibleAtTime(notHiddenElements[0], time)) {
                return;
            }

            for (const element of notHiddenElements) {
                if (isImageNode(element)) {
                    const box = calculateBoundingBox([element]);
                    const foundImage = this.creativesetDataService.creativeset.images.find(
                        image => image.id === element.imageAsset?.id
                    );
                    if (foundImage?.isGenAi || (!foundImage && element.imageAsset?.isGenAi)) {
                        this.drawAIGeneratedImageLabel(box);
                    }
                }
            }

            // Single select
            if (notHiddenElements.length === 1) {
                const selectedElement = notHiddenElements[0];

                if (transformMode === TransformMode.EditText) {
                    this.drawDimmedGizmo(selectedElement);
                    return;
                }

                if (transformMode === TransformMode.Resize) {
                    this.drawResizeLabel(selectedElement, state);
                }

                if (transformMode === TransformMode.Rotate) {
                    this.drawRotateLabel(selectedElement, state);
                }
            }

            if (transformMode !== TransformMode.Move) {
                notHiddenElements.forEach(element => {
                    const viewElement = this.editorStateService.renderer.getViewElementById(element.id);
                    if (!viewElement) {
                        return;
                    }

                    if (!isSameBounds(viewElement, element)) {
                        const corners = this.getElementBoundingCorners(viewElement);

                        if (transformMode !== TransformMode.Resize) {
                            this.drawTransitionPaths([element]);
                            this.drawTransitionsOutlines(element, viewElement, corners);
                        } else {
                            this.drawTransitionsOutlinesDuringResizing(element, corners);
                        }
                    }

                    if (isEllipseNode(viewElement)) {
                        this.drawEllipseOutline(viewElement);
                    }

                    if (notHiddenElements.length > 1 && !element.__parentNode) {
                        // Highlight elements within multiple selection
                        this.drawHighlightGizmo(element);
                    }
                });

                this.drawTransformGizmo(boundingBox);
            }
        }

        this.drawHighlightOutline();
    }

    private drawGroupOutlines(): void {
        const selection = this.elementSelectionService.currentSelection;
        const { nodes } = selection;

        const allNodesInGroupSelected = hasSelectionOfAllNodesInGroup(selection);

        if (!allNodesInGroupSelected) {
            for (const node of nodes) {
                if (!isVisibleAtTime(node, this.workspace.designView.time)) {
                    continue;
                }
                const boundingBox = calculateBoundingBox([node]);
                this.drawHighlightGizmo(boundingBox);
                if (node.__parentNode) {
                    this.drawWireframeOutline(node.__parentNode);
                }
            }
        }
    }

    private drawHighlightOutline(): void {
        const currentHighlight = this.elementHighlightService.currentHighlight;
        if (!currentHighlight) {
            return;
        }

        const { node, context } = currentHighlight;
        const selection = this.elementSelectionService.currentSelection;

        if (!isVisibleAtTime(node, this.workspace.designView.time)) {
            return;
        }

        if (!selection.has(node)) {
            const viewElement = this.editorStateService.renderer.getViewElementById(node.id);
            if (isEllipseNode(viewElement)) {
                this.drawEllipseOutline(viewElement);
            }

            if (context === 'timeline') {
                this.drawHighlightGizmo(calculateBoundingBox([node]));
            } else {
                const groupOrNode = this.elementSelectionService.getGroupOrNode(node);

                if (groupOrNode) {
                    this.drawHighlightGizmo(calculateBoundingBox([groupOrNode]));
                }
            }
        }
    }

    private drawAIGeneratedImageLabel(box: IBoundingBox): void {
        const { topRight } = this.getElementBoundingCorners(box);
        const labelText = 'AI generated image';
        const textMetrics = this.ctx.measureText(labelText);
        const labelWidth = textMetrics.width + 10; // Added some padding
        const labelHeight = 17;
        const borderRadius = 2;
        const distanceFromTopRightCorner = 5;

        // Adjust position coordinates for background to encapsulate text
        const labelX = topRight.x - labelWidth - distanceFromTopRightCorner;
        const labelY = topRight.y - labelHeight - distanceFromTopRightCorner;

        // Drawing the label background with rounded corners
        this.ctx.fillStyle = 'rgba(3, 170, 255, 1)';
        this.ctx.beginPath();
        this.ctx.moveTo(labelX + borderRadius, labelY);
        this.ctx.lineTo(labelX + labelWidth - borderRadius, labelY);
        this.ctx.arcTo(
            labelX + labelWidth,
            labelY,
            labelX + labelWidth,
            labelY + borderRadius,
            borderRadius
        );
        this.ctx.lineTo(labelX + labelWidth, labelY + labelHeight - borderRadius);
        this.ctx.arcTo(
            labelX + labelWidth,
            labelY + labelHeight,
            labelX + labelWidth - borderRadius,
            labelY + labelHeight,
            borderRadius
        );
        this.ctx.lineTo(labelX + borderRadius, labelY + labelHeight);
        this.ctx.arcTo(
            labelX,
            labelY + labelHeight,
            labelX,
            labelY + labelHeight - borderRadius,
            borderRadius
        );
        this.ctx.lineTo(labelX, labelY + borderRadius);
        this.ctx.arcTo(labelX, labelY, labelX + borderRadius, labelY, borderRadius);
        this.ctx.closePath();
        this.ctx.fill();

        // Drawing the label text
        this.ctx.font = `11px ${this.fontFamily}`;
        this.ctx.fillStyle = '#fff';
        this.ctx.textAlign = 'left';
        this.ctx.textBaseline = 'top';
        this.ctx.fillText(labelText, labelX + 5, labelY + 4); // Adding small padding for text inside the background
    }

    private drawRotateLabel(selectedElement: OneOfElementDataNodes, state: IState | undefined): void {
        let text = `${toDegrees(selectedElement.rotationZ)}º`;
        if (state && typeof state.rotationZ === 'number') {
            text = `Offset: ${toDegrees(state.rotationZ)}º`;
        }
        this.drawLabel(text);
    }

    private drawResizeLabel(selectedElement: OneOfElementDataNodes, state: IState | undefined): void {
        let text = `width: ${Math.round(selectedElement.width)} height: ${Math.round(
            selectedElement.height
        )}`;
        if (state) {
            text = `scaleX: ${decimalToPercentage(
                state.scaleX
            )}% scaleY: ${decimalToPercentage(state.scaleY)}%`;
        }
        this.drawLabel(text);
    }

    private setDesignViewSettings(settings: IDesignViewSettings): void {
        const {
            dimOutsideCanvas,
            guidelineVisible,
            outlineVisible,
            guidelineColor,
            displayGrid,
            gridColor,
            grid
        } = settings;

        this.drawOverflow = dimOutsideCanvas;
        this.drawGuideline = guidelineVisible;
        this.drawOutline = outlineVisible;
        this.guidelineColor = guidelineColor;
        this.gridEnabled = displayGrid;
        this.gridColor = gridColor;

        if (grid) {
            this.gridOptions = grid;
        }

        if (this.workspace.canvas) {
            this.draw();
        }
    }

    private drawZoomBox(zoomBox: IBoundingBox): any {
        const { x, y } = this.workspace.getPositionRelativeToWorkspace(zoomBox);
        const { width, height } = zoomBox;
        const zoom = this.editorStateService.zoom;
        const widthAcc = width * zoom;
        const heightAcc = height * zoom;
        this.ctx.strokeStyle = '#919191';
        this.ctx.lineWidth = 0.5;
        this.ctx.fillStyle = 'rgba(0, 0, 0, 0.01)';
        this.ctx.beginPath();
        this.ctx.moveTo(x, y);
        this.ctx.lineTo(x + widthAcc, y);
        this.ctx.lineTo(x + widthAcc, y + heightAcc);
        this.ctx.lineTo(x, y + heightAcc);
        this.ctx.closePath();
        this.ctx.stroke();
        this.ctx.fill();
    }

    private async drawGuidelines(creativeDocument: ICreativeDataNode): Promise<void> {
        const guidelines = creativeDocument.guidelines.filter(
            guideline => !guideline.social || creativeDocument.socialGuide?.guidelines
        );

        if (!guidelines.length) {
            return;
        }

        const designView = this.workspace.designView;
        for (const { type, position, id, social } of guidelines) {
            const guidelinePosition = this.workspace.getPositionRelativeToWorkspace(position);
            const activeGuideline = this.workspace.transform.guidelineSelection.instance;
            const isActive = activeGuideline && activeGuideline.id === id;

            const positions =
                type === GuidelineType.Vertical
                    ? this.getVerticalCoordinates(guidelinePosition)
                    : this.getHorizontalCoordinates(guidelinePosition);
            this.ctx.beginPath();
            this.ctx.moveTo(positions[0].x, positions[0].y);
            this.ctx.lineTo(positions[1].x, positions[1].y);
            this.ctx.lineWidth = LINE_WIDTH;

            let styleColor = this.guidelineColor;

            if (social) {
                styleColor = 'rgba(3, 170, 255, 1)';
            } else if (isActive) {
                const color = Color.parse(styleColor);
                color.alpha = 100;
                styleColor = color.toString();
            }

            this.ctx.strokeStyle = styleColor;

            this.ctx.stroke();

            if (activeGuideline && activeGuideline.id === id && this.drawGuidelineTooltip) {
                const containsPreviews = guidelines.some(guideline => guideline.preview);

                if (type === GuidelineType.Vertical && !containsPreviews) {
                    const isMediaLibraryOpen = await firstValueFrom(
                        this.workspace.mediaLibraryService.isOpen$
                    );
                    if (
                        guidelinePosition.x >= this.canvasElementSize.width ||
                        guidelinePosition.x < 0 ||
                        (isMediaLibraryOpen && guidelinePosition.x <= designView.mediaLibraryWidth)
                    ) {
                        this.drawGuidelineLabel(`Delete guideline`);
                    } else {
                        this.drawGuidelineLabel(`${Math.round(position.x)} px`);
                    }
                } else if (type === GuidelineType.Horizontal && !containsPreviews) {
                    if (
                        guidelinePosition.y >=
                            this.canvasElementSize.height -
                                this.workspace.designView.timeline.getHostHeight() ||
                        guidelinePosition.y < 0
                    ) {
                        this.drawGuidelineLabel(`Delete guideline`);
                    } else {
                        this.drawGuidelineLabel(`${Math.round(position.y)} px`);
                    }
                } else {
                    this.drawGuidelineLabel(`New guideline`);
                }
            }
        }
    }

    private getHorizontalCoordinates = (guidelinePosition: IPosition): IPosition[] => [
        { x: 0.5, y: Math.round(guidelinePosition.y) + 0.5 },
        { x: Math.round(this.canvasElementSize.width) - 0.5, y: Math.round(guidelinePosition.y) + 0.5 }
    ];

    private getVerticalCoordinates = (guidelinePosition: IPosition): IPosition[] => [
        { x: Math.floor(guidelinePosition.x) + 0.5, y: 0 },
        { x: Math.floor(guidelinePosition.x) + 0.5, y: Math.round(this.canvasElementSize.height) + 0.5 }
    ];

    private drawSnapLines(lines: ISnapLine[], baseAxis: 'x' | 'y'): void {
        for (const line of lines) {
            this.drawSnapLine(line, baseAxis);
        }
    }

    private drawSnapLine(line: ISnapLine, baseAxis: 'x' | 'y'): void {
        const crossAxis = baseAxis === 'y' ? 'x' : 'y';
        const workspacePositionStart = this.workspace.getPositionRelativeToWorkspace({
            [baseAxis]: line.base,
            [crossAxis]: line.start
        } as unknown as IPosition);
        this.ctx.beginPath();
        this.ctx.moveTo(workspacePositionStart.x + 0.5, workspacePositionStart.y + 0.5);

        const workspacePositionEnd = this.workspace.getPositionRelativeToWorkspace({
            [baseAxis]: line.base,
            [crossAxis]: line.end
        } as unknown as IPosition);
        this.ctx.lineTo(workspacePositionEnd.x + 0.5, workspacePositionEnd.y + 0.5);
        this.ctx.lineWidth = LINE_WIDTH;
        this.ctx.strokeStyle = SNAPPING_COLOR;
        this.ctx.stroke();

        for (const circlePosition of line.circles) {
            const workspacePosition = this.workspace.getPositionRelativeToWorkspace({
                [baseAxis]: line.base,
                [crossAxis]: circlePosition
            } as unknown as IPosition);
            this.ctx.beginPath();
            this.ctx.arc(workspacePosition.x + 0.5, workspacePosition.y + 0.5, 1.5, 0, 2 * Math.PI);
            this.ctx.fillStyle = SNAPPING_COLOR;
            this.ctx.fill();
        }
    }

    private drawGrid(): void {
        const { x, y } = this.workspace.getPositionRelativeToWorkspace({ x: 0, y: 0 });
        const { canvasSize } = this.workspace;
        const zoom = this.editorStateService.zoom;
        const width = canvasSize.width * zoom;
        const height = canvasSize.height * zoom;
        const gridWidth = this.gridOptions.width * zoom;
        const gridHeight = this.gridOptions.height * zoom;
        this.ctx.strokeStyle = this.gridColor;
        this.ctx.beginPath();
        this.drawVerticalGridLine(gridWidth, width, x, y, height);
        this.drawHorizontalGridLine(gridHeight, height, x, y, width);
        this.ctx.stroke();
        this.ctx.closePath();

        this.ctx.beginPath();
        this.ctx.moveTo(x, y);
        this.ctx.lineTo(x + width, y);
        this.ctx.lineTo(x + width, y + height);
        this.ctx.lineTo(x, y + height);
        this.ctx.lineTo(x, y);
        this.ctx.closePath();
        this.ctx.strokeStyle = '#575757';
        this.ctx.stroke();
    }

    private drawHorizontalGridLine(
        gridHeight: number,
        height: number,
        x: number,
        y: number,
        width: number
    ): void {
        for (let lineY = gridHeight; lineY <= height - 0.5; lineY = lineY + gridHeight) {
            const yPos = this.roundToDecimal(Math.round(lineY + y));
            this.ctx.moveTo(this.roundToDecimal(x), yPos);
            this.ctx.lineTo(this.roundToDecimal(Math.round(x + width)), yPos);
        }
    }

    private drawVerticalGridLine(
        gridWidth: number,
        width: number,
        x: number,
        y: number,
        height: number
    ): void {
        for (let lineX = gridWidth; lineX <= width - 0.5; lineX = lineX + gridWidth) {
            const xPos = this.roundToDecimal(Math.round(lineX + x));
            this.ctx.moveTo(xPos, this.roundToDecimal(y));
            this.ctx.lineTo(xPos, this.roundToDecimal(Math.round(y + height)));
        }
    }

    private roundToDecimal(value: number): number {
        if (value % 1 === 0) {
            return value + 0.5;
        }
        return value;
    }

    private drawWorkspaceOverflow(hideOverflow?: boolean): void {
        const { x, y } = this.workspace.getPositionRelativeToWorkspace({ x: 0, y: 0 });
        const { canvasSize } = this.workspace;

        this.ctx.fillStyle = `rgba(246, 246, 246, ${hideOverflow ? 1 : 0.9})`;
        this.ctx.fillRect(0, 0, this.canvasElementSize.width, this.canvasElementSize.height);
        this.ctx.clearRect(
            x,
            y,
            canvasSize.width * this.editorStateService.zoom,
            canvasSize.height * this.editorStateService.zoom
        );
    }

    // private drawPlayingPreviewBackdrop(hideOverflow?: boolean): void {
    //     const { x, y } = this.workspace.getPositionRelativeToWorkspace({ x: 0, y: 0 });
    //     const { zoom, canvasSize } = this.workspace;

    //     this.ctx.fillStyle = `rgba(220, 220, 220, ${hideOverflow ? 1 : 0.9})`;
    //     this.ctx.fillRect(0, 0, this.canvasElementSize.width, this.canvasElementSize.height);
    //     this.ctx.clearRect(x, y, canvasSize.width * zoom, canvasSize.height * zoom);
    // }

    /**
     * @todo
     * We need to clear the elements actual shape, not the selectionBox containing it.
    private clearElementsOverflow(): void {
        for (const element of this.workspace.selection.elements) {
            const { x, y, width, height } = this.workspace.getBoundingRect(element, 'workspace');
            if (element.rotationZ) {
                this.ctx.save();
                this.ctx.translate(x + width / 2, y + height / 2);
                this.ctx.rotate(element.rotationZ);
                this.ctx.clearRect(-width / 2, -height / 2, width, height);
                this.ctx.restore();
            }
            else {
                this.ctx.clearRect(x, y, width, height);
            }
        }
    }
    */

    private drawBorder(): void {
        const { x, y } = this.workspace.getPositionRelativeToWorkspace({ x: 0, y: 0 });
        const { canvasSize } = this.workspace;

        this.ctx.strokeStyle = '#e6e6e6';
        this.ctx.lineWidth = 1;
        this.ctx.strokeRect(
            x,
            y,
            canvasSize.width * this.editorStateService.zoom,
            canvasSize.height * this.editorStateService.zoom
        );
    }

    clear(): void {
        this.ctx.clearRect(0, 0, this.canvasElementSize.width, this.canvasElementSize.height);
    }

    private async drawGuidelineLabel(text: string): Promise<void> {
        const workspace = this.workspace;
        const designView = workspace.designView;

        if (!workspace.mousePosition) {
            return;
        }
        const mousePosition = workspace.mousePosition;
        const boundingRect = this.mount.getBoundingClientRect();
        const timelinePosition = workspace.getTimelinePositionRelativeToWorkspace();
        const radius = 3;
        const height = 19;

        let x = mousePosition.x + 10;
        let y = mousePosition.y + 10;

        const textWidth = this.ctx.measureText(text).width;
        const width = textWidth + 14;

        const isMediaLibraryOpen = await firstValueFrom(this.workspace.mediaLibraryService.isOpen$);

        this.ctx.fillStyle = 'rgba(145, 145, 145,.8)';
        this.ctx.font = `11px ${this.fontFamily}`;

        if (workspace.toolbar.isOpen) {
            x = workspace.toolbar.width - 40;
        }

        if (x + width >= boundingRect.width) {
            x = boundingRect.width - width - 10;
        }

        if (x <= 10) {
            x = 10;
        }

        if (y <= 10) {
            y = 10;
        }
        if (y >= timelinePosition.y) {
            y = timelinePosition.y - height - 10;
        }

        if (isMediaLibraryOpen && x <= designView.mediaLibraryWidth) {
            x = designView.mediaLibraryWidth + 10;
        }

        this.ctx.beginPath();
        this.ctx.moveTo(x + radius, y);
        this.ctx.lineTo(x + width - radius, y);
        this.ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
        this.ctx.lineTo(x + width, y + height - radius);
        this.ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
        this.ctx.lineTo(x + radius, y + height);
        this.ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
        this.ctx.lineTo(x, y + radius);
        this.ctx.quadraticCurveTo(x, y, x + radius, y);
        this.ctx.closePath();
        this.ctx.fill();

        this.ctx.fillStyle = '#fff';
        this.ctx.textBaseline = 'top';
        this.ctx.fillText(text, x + 7, y + 5);
        this.ctx.closePath();
    }

    private drawLabel(text: string): void {
        const workspace = this.workspace;
        if (!workspace.mousePosition) {
            throw new Error('Mouse position is not set.');
        }
        const mousePosition = workspace.mousePosition;
        const x = mousePosition.x + 20;
        const y = mousePosition.y + 20;
        const radius = 3;
        this.ctx.font = `12px ${this.fontFamily}`;
        const textWidth = this.ctx.measureText(text).width;
        this.ctx.fillStyle = '#000';
        const width = textWidth + 14;
        const height = 22;

        this.ctx.beginPath();
        this.ctx.moveTo(x + radius, y);
        this.ctx.lineTo(x + width - radius, y);
        this.ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
        this.ctx.lineTo(x + width, y + height - radius);
        this.ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
        this.ctx.lineTo(x + radius, y + height);
        this.ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
        this.ctx.lineTo(x, y + radius);
        this.ctx.quadraticCurveTo(x, y, x + radius, y);
        this.ctx.closePath();
        this.ctx.fill();

        this.ctx.fillStyle = '#fff';
        this.ctx.textBaseline = 'top';
        this.ctx.fillText(text, x + 7, y + 5);
    }

    private drawHelpers(): void {
        this.xHelpers.forEach(x => {
            x = this.workspace.getPositionRelativeToWorkspace({ x, y: 0 }).x - 0.5;
            this.ctx.beginPath();
            this.ctx.moveTo(x, 0);
            this.ctx.lineTo(x, 10000);
            this.ctx.closePath();
            this.ctx.strokeStyle = '#ee67a8';
            this.ctx.lineWidth = 1;
            this.ctx.stroke();
        });
        this.yHelpers.forEach(y => {
            y = this.workspace.getPositionRelativeToWorkspace({ x: 0, y }).y - 0.5;
            this.ctx.beginPath();
            this.ctx.moveTo(0, y);
            this.ctx.lineTo(10000, y);
            this.ctx.closePath();
            this.ctx.strokeStyle = '#ee67a8';
            this.ctx.lineWidth = 1;
            this.ctx.stroke();
        });
    }

    /**
     * The blue box shown when hovering element
     */
    private drawHighlightGizmo(boundingBox: IBoundingBox): void {
        if (
            ![...this.workspace.transform.imageSelectionAreas.values()].some(e => e.hover) &&
            !this.workspace.transform.isPositioningImage
        ) {
            const { topLeft, topRight, bottomLeft, bottomRight } =
                this.getElementBoundingCorners(boundingBox);

            // Rectangle
            this.ctx.beginPath();
            this.ctx.moveTo(topLeft.x, topLeft.y);
            this.ctx.lineTo(topRight.x, topRight.y);
            this.ctx.lineTo(bottomRight.x, bottomRight.y);
            this.ctx.lineTo(bottomLeft.x, bottomLeft.y);
            this.ctx.closePath();
            this.ctx.strokeStyle = GizmoColor.border();
            this.ctx.lineWidth = LINE_WIDTH;
            this.ctx.stroke();
        }
    }

    private drawElementsOutline(nodes: OneOfElementDataNodes[]): void {
        this.ctx.strokeStyle = OUTLINE_COLOR;
        for (const node of nodes) {
            if (isVisibleAtTime(node, this.workspace.designView.animator?.time || 0)) {
                const { topLeft, topRight, bottomLeft, bottomRight } =
                    this.getElementBoundingCorners(node);

                this.ctx.beginPath();
                this.ctx.moveTo(topLeft.x, topLeft.y);
                this.ctx.lineTo(topRight.x, topRight.y);
                this.ctx.lineTo(bottomRight.x, bottomRight.y);
                this.ctx.lineTo(bottomLeft.x, bottomLeft.y);
                this.ctx.closePath();
                this.ctx.stroke();
            }
        }
    }

    private drawWireframeOutline(node: OneOfDataNodes): void {
        this.ctx.strokeStyle = BORDER_DIMMED_COLOR;

        const boundingBox = calculateBoundingBox([node]);

        if (isVisibleAtTime(node, this.workspace.designView.animator?.time || 0)) {
            const { topLeft, topRight, bottomLeft, bottomRight } =
                this.getElementBoundingCorners(boundingBox);

            this.ctx.beginPath();
            this.ctx.moveTo(topLeft.x, topLeft.y);
            this.ctx.lineTo(topRight.x, topRight.y);
            this.ctx.lineTo(bottomRight.x, bottomRight.y);
            this.ctx.lineTo(bottomLeft.x, bottomLeft.y);
            this.ctx.closePath();
            this.ctx.stroke();
        }
    }

    private drawImageOutline(): void {
        if (this.workspace.isPanning) {
            return;
        }

        const node = this.elementSelectionService.currentSelection.element;

        if (!node || this.inStateMode(node) || node.kind !== ElementKind.Image || isHidden(node)) {
            return;
        }

        const { imageAsset, imageSettings } = node;

        if (!imageAsset?.width || !imageAsset.height || imageSettings.sizeMode !== ImageSizeMode.Crop) {
            return;
        }

        const imageAssetWidth = imageAsset.width;
        const imageAssetHeight = imageAsset.height;
        const scale = Math.max(node.width / imageAssetWidth, node.height / imageAssetHeight);

        let imageWidth = imageAssetWidth * scale;
        let imageHeight = imageAssetHeight * scale;

        const zoom = this.editorStateService.zoom;
        const nodeBoundingCorners = this.getElementBoundingCorners(node, true);

        const { topLeft, topRight, bottomRight, bottomLeft } =
            this.workspace.getBoundingCornersWithOffsetToTopLeft({
                ...node,
                width: imageWidth,
                height: imageHeight,
                rotationZ: 0
            });

        const maxX = imageWidth - node.width;
        const minX = -maxX;
        const maxY = imageHeight - node.height;
        const minY = -maxY;

        let imagePositionX = -(imageSettings.x * (maxX - minX) + minX);
        let imagePositionY = -(imageSettings.y * (maxY - minY) + minY);

        // Flip the coordinates if element is mirrored
        imagePositionX = node.mirrorX ? -imagePositionX : imagePositionX;
        imagePositionY = node.mirrorY ? -imagePositionY : imagePositionY;

        const withinBounds = withinImageBounds(
            imagePositionX,
            imagePositionY,
            imageWidth,
            node.width,
            imageHeight,
            node.height
        );
        let { fitX: fitPositionX, fitY: fitPositionY } = withinBounds;

        fitPositionX *= zoom;
        fitPositionY *= zoom;

        imageWidth *= zoom;
        imageHeight *= zoom;

        const nodeWidth = node.width * zoom;
        const nodeHeight = node.height * zoom;

        const imageXPosition = -fitPositionX / 2;
        const imageYPosition = -fitPositionY / 2;

        const centerX = imageWidth / 2 - nodeWidth / 2;
        const centerY = imageHeight / 2 - nodeHeight / 2;
        const originBoundingCornerns = {
            x: nodeBoundingCorners.center!.x,
            y: nodeBoundingCorners.center!.y
        };

        const x1 = topLeft.position.x - imageXPosition - centerX;
        const y1 = topLeft.position.y - imageYPosition - centerY;
        const rotatedTopLeft = rotatePosition(
            { x: x1, y: y1 },
            originBoundingCornerns,
            -node.rotationZ
        );

        const x2 = topRight.position.x - imageXPosition - centerX;
        const y2 = topRight.position.y - imageYPosition - centerY;
        const rotatedTopRight = rotatePosition(
            { x: x2, y: y2 },
            originBoundingCornerns,
            -node.rotationZ
        );

        const x3 = bottomRight.position.x - imageXPosition - centerX;
        const y3 = bottomRight.position.y - imageYPosition - centerY;
        const rotatedBottomRight = rotatePosition(
            { x: x3, y: y3 },
            originBoundingCornerns,
            -node.rotationZ
        );

        const x4 = bottomLeft.position.x - imageXPosition - centerX;
        const y4 = bottomLeft.position.y - imageYPosition - centerY;
        const rotatedBottomLeft = rotatePosition(
            { x: x4, y: y4 },
            originBoundingCornerns,
            -node.rotationZ
        );

        const box = {
            topLeft: rotatedTopLeft,
            topRight: rotatedTopRight,
            bottomRight: rotatedBottomRight,
            bottomLeft: rotatedBottomLeft
        };

        const selectionAreas = {
            top: {
                x1: box.topLeft.x,
                y1: box.topLeft.y,
                x2: box.topRight.x,
                y2: box.topRight.y,
                x3: nodeBoundingCorners.topRight.x,
                y3: nodeBoundingCorners.topRight.y,
                x4: nodeBoundingCorners.topLeft.x,
                y4: nodeBoundingCorners.topLeft.y
            },
            bottom: {
                x1: nodeBoundingCorners.bottomLeft.x,
                y1: nodeBoundingCorners.bottomLeft.y,
                x2: nodeBoundingCorners.bottomRight.x,
                y2: nodeBoundingCorners.bottomRight.y,
                x3: box.bottomRight.x,
                y3: box.bottomRight.y,
                x4: box.bottomLeft.x,
                y4: box.bottomLeft.y
            },
            left: {
                x1: box.topLeft.x,
                y1: box.topLeft.y,
                x2: nodeBoundingCorners.topLeft.x,
                y2: nodeBoundingCorners.topLeft.y,
                x3: nodeBoundingCorners.bottomLeft.x,
                y3: nodeBoundingCorners.bottomLeft.y,
                x4: box.bottomLeft.x,
                y4: box.bottomLeft.y
            },
            right: {
                x1: nodeBoundingCorners.topRight.x,
                y1: nodeBoundingCorners.topRight.y,
                x2: box.topRight.x,
                y2: box.topRight.y,
                x3: box.bottomRight.x,
                y3: box.bottomRight.y,
                x4: nodeBoundingCorners.bottomRight.x,
                y4: nodeBoundingCorners.bottomRight.y
            }
        };
        const elementPosition = this.workspace.getPositionRelativeToWorkspace(node);
        const selectionAreaTop = {
            x: x1,
            y: y1,
            width: nodeWidth,
            height: Math.abs(Math.round(y1 - elementPosition.y)) - 10
        };
        const selectionAreaBottom = {
            x: elementPosition.x,
            y: elementPosition.y + nodeHeight + 10,
            width: nodeWidth,
            height: Math.abs(Math.round(elementPosition.y + nodeHeight - y4)) - 10
        };
        const selectionAreaLeft = {
            x: x1,
            y: y1,
            width: Math.abs(Math.round(elementPosition.x - x1)) - 10,
            height: nodeHeight
        };
        const selectionAreaRight = {
            x: elementPosition.x + nodeWidth + 10,
            y: elementPosition.y,
            width: Math.abs(Math.round(elementPosition.x + nodeWidth - x2)) - 10,
            height: nodeHeight
        };

        const imageSelectionAreas = this.workspace.transform.imageSelectionAreas;
        imageSelectionAreas.set('top', selectionAreaTop);
        imageSelectionAreas.set('bottom', selectionAreaBottom);
        imageSelectionAreas.set('left', selectionAreaLeft);
        imageSelectionAreas.set('right', selectionAreaRight);

        let isHovered = false;
        imageSelectionAreas.forEach(imageSelection => {
            const selection = this.elementSelectionService.currentSelection;
            if (selection?.element && this.workspace.mousePosition) {
                const origin = {
                    x: elementPosition.x + nodeWidth / 2,
                    y: elementPosition.y + nodeHeight / 2
                };
                const rotatedMousePosition = rotatePosition(
                    this.workspace.mousePosition,
                    origin,
                    selection.element.rotationZ || 0
                );

                const inBounds = positionIsInBounds(rotatedMousePosition, {
                    x: imageSelection.x,
                    y: imageSelection.y,
                    width: imageSelection.width,
                    height: imageSelection.height
                });

                if (inBounds) {
                    imageSelection.hover = true;
                    isHovered = true;
                } else {
                    imageSelection.hover = false;
                }
            }
        });

        this.ctx.lineWidth = 1;
        this.ctx.strokeStyle = 'rgb(251, 251, 251, 0.4)';
        this.ctx.fillStyle =
            isHovered || this.workspace.transform.isPositioningImage
                ? 'rgba(0, 0, 0, .3)'
                : 'rgba(0, 0, 0, 0.1)';

        this.ctx.beginPath();

        this.ctx.moveTo(selectionAreas.top.x1, selectionAreas.top.y1);
        this.ctx.lineTo(selectionAreas.top.x2, selectionAreas.top.y2);
        this.ctx.lineTo(selectionAreas.top.x3, selectionAreas.top.y3);
        this.ctx.lineTo(selectionAreas.top.x4, selectionAreas.top.y4);
        this.ctx.fill();
        this.ctx.stroke();
        this.ctx.closePath();

        this.ctx.beginPath();

        this.ctx.moveTo(selectionAreas.bottom.x1, selectionAreas.bottom.y1);
        this.ctx.lineTo(selectionAreas.bottom.x2, selectionAreas.bottom.y2);
        this.ctx.lineTo(selectionAreas.bottom.x3, selectionAreas.bottom.y3);
        this.ctx.lineTo(selectionAreas.bottom.x4, selectionAreas.bottom.y4);
        this.ctx.fill();
        this.ctx.stroke();
        this.ctx.closePath();

        this.ctx.beginPath();

        this.ctx.moveTo(selectionAreas.left.x1, selectionAreas.left.y1);
        this.ctx.lineTo(selectionAreas.left.x2, selectionAreas.left.y2);
        this.ctx.lineTo(selectionAreas.left.x3, selectionAreas.left.y3);
        this.ctx.lineTo(selectionAreas.left.x4, selectionAreas.left.y4);
        this.ctx.fill();
        this.ctx.stroke();
        this.ctx.closePath();

        this.ctx.beginPath();

        this.ctx.moveTo(selectionAreas.right.x1, selectionAreas.right.y1);
        this.ctx.lineTo(selectionAreas.right.x2, selectionAreas.right.y2);
        this.ctx.lineTo(selectionAreas.right.x3, selectionAreas.right.y3);
        this.ctx.lineTo(selectionAreas.right.x4, selectionAreas.right.y4);
        this.ctx.fill();
        this.ctx.stroke();
        this.ctx.closePath();
    }

    private drawTransitionPaths(elements: OneOfElementDataNodes[]): void {
        this.ctx.save();
        this.ctx.strokeStyle = GizmoColor.transitionPath();
        this.ctx.lineWidth = TRANSITION_PATH_WIDTH;
        this.ctx.fillStyle = GizmoColor.border();

        const playheadTime = this.workspace.designView.time;

        for (const element of elements) {
            if (element && isSelectionVisibleAtTime(element, playheadTime)) {
                const animations = element.animations;
                const animationTimes = getTimePropertyIsAnimating(element, 'x', 'y');

                // Duration and time of animations
                const duration = element.duration;
                const from = element.time;
                const to = from + duration;

                const granularity = duration * 0.01;
                const currentPos = this.getAnimatedPositionRelativeToWorkspace(element, playheadTime);

                const fromPos = this.getAnimatedPositionRelativeToWorkspace(element, from);
                const toPos = this.getAnimatedPositionRelativeToWorkspace(element, to);

                // Don't draw line when no movement happens (both check are due to the case when element animates back to start position)
                if (
                    distance(toPos, currentPos) < 2 &&
                    distance(toPos, fromPos) < 2 &&
                    !animationTimes.length
                ) {
                    return;
                }

                // Any keyframes within the "moving" time spans
                const keyframes = getAllKeyframes(animations).filter(k =>
                    animationTimes.some(t => isTimeAt(k, t))
                );

                const dots: (IPosition & ITime & { highlight?: boolean })[] = [
                    0,
                    ...keyframes.map(k => k.time),
                    to - element.time
                ].map(t => ({
                    time: element.time + t,
                    ...this.getAnimatedPositionRelativeToWorkspace(element, element.time + t),
                    highlight: true
                }));

                // Add dot showing easing
                if (granularity) {
                    // Loop through all times where either x or y changes
                    animationTimes.forEach(({ time, duration }) => {
                        for (let t = time + granularity; t < time + duration; t += granularity) {
                            const pos = this.getAnimatedPositionRelativeToWorkspace(element, t);

                            if (
                                !dots.some(d => distance(pos, d) < TRANSITION_EASING_DOT_MIN_DISTANCE)
                            ) {
                                dots.push({ ...pos, time: t });
                            }
                        }
                    });

                    dots.sort(sortByTime);
                }

                // Begin line
                this.ctx.beginPath();
                this.ctx.moveTo(dots[0].x, dots[0].y);

                // Draw lines between the dots
                dots.forEach(dot => {
                    this.ctx.lineTo(dot.x, dot.y);
                    this.ctx.moveTo(dot.x, dot.y);
                });

                // End line
                this.ctx.closePath();
                this.ctx.stroke();

                // Draw dots
                dots.filter(dot => distance(dot, currentPos) > 2).forEach(dot =>
                    this.drawDot(
                        dot,
                        dot.highlight ? TRANSITION_KEYFRAME_DOT_RADIUS : TRANSITION_EASING_DOT_RADIUS
                    )
                );

                // Get previous position so the arrow can be in correct angle
                let lastPos: IPosition | undefined = getPreviousTime(dots, playheadTime);

                // If no previous is found calculate previous based on the direction to the next one
                if (!lastPos) {
                    const next = getNextTime(dots, playheadTime);
                    if (next) {
                        const d = distance2D(currentPos, next, false);
                        lastPos = {
                            x: currentPos.x - d.x,
                            y: currentPos.y - d.y
                        };
                    }
                }

                // Draw arrow or circle if static
                if (lastPos) {
                    if (distance(lastPos, currentPos) > 0.00001) {
                        this.drawArrow(lastPos, currentPos);
                    } else {
                        this.drawDot(currentPos, TRANSITION_KEYFRAME_DOT_RADIUS);
                    }
                }
            }
        }

        this.ctx.restore();
    }

    private drawTransitionsOutlines(
        node: OneOfElementDataNodes,
        viewElement: OneOfViewNodes,
        corners: IBoundingCorners
    ): void {
        this.ctx.save();
        this.ctx.strokeStyle = BORDER_DIMMED_COLOR;

        if (isSelectionVisibleAtTime(node, this.workspace.designView.time)) {
            const transitionBox = getBoundingRectangleOfRotatedBox(viewElement);
            const from = this.workspace.getPositionRelativeToWorkspace({
                x: node.x + node.width / 2,
                y: node.y + node.height / 2
            });
            const to = this.workspace.getPositionRelativeToWorkspace({
                x: transitionBox.x + transitionBox.width / 2,
                y: transitionBox.y + transitionBox.height / 2
            });
            if (from.x !== to.x || from.y !== to.y) {
                this.drawOutlines(corners);
            }
        }
        this.ctx.restore();
    }

    private drawTransitionsOutlinesDuringResizing(
        node: OneOfElementDataNodes,
        corners: IBoundingCorners
    ): void {
        this.ctx.strokeStyle = BORDER_DIMMED_COLOR;
        if (isVisibleAtTime(node, this.workspace.designView.time)) {
            this.drawOutlines(corners);
        }
    }

    private drawOutlines(corners: IBoundingCorners): void {
        this.ctx.beginPath();
        this.ctx.moveTo(corners.topLeft.x, corners.topLeft.y);
        this.ctx.lineTo(corners.topRight.x, corners.topRight.y);
        this.ctx.lineTo(corners.bottomRight.x, corners.bottomRight.y);
        this.ctx.lineTo(corners.bottomLeft.x, corners.bottomLeft.y);
        this.ctx.closePath();
        this.ctx.lineWidth = 1;
        this.ctx.stroke();
    }

    private inStateMode(element: OneOfElementDataNodes): boolean {
        return this.workspace.editorStateService.renderer.getAdditionalStates_m(element).length > 0;
    }

    private drawSelectionNetGizmo(selection: IBoundingBox): void {
        const { x, y } = this.workspace.getPositionRelativeToWorkspace(selection);
        const { width, height } = selection;
        const zoom = this.editorStateService.zoom;
        const widthAcc = width * zoom;
        const heightAcc = height * zoom;
        this.ctx.strokeStyle = GizmoColor.border();
        this.ctx.lineWidth = 0.5;
        this.ctx.fillStyle = 'rgba(0, 0, 0, 0.01)';
        this.ctx.beginPath();
        this.ctx.moveTo(x, y);
        this.ctx.lineTo(x + widthAcc, y);
        this.ctx.lineTo(x + widthAcc, y + heightAcc);
        this.ctx.lineTo(x, y + heightAcc);
        this.ctx.closePath();
        this.ctx.stroke();
        this.ctx.fill();
    }

    private drawCreateElementGizmo(selection: IBoundingBox, nodeKind: ElementKind): void {
        // const borderColor = WorkspaceGizmoDrawer.borderColor;
        const { x, y } = this.workspace.getPositionRelativeToWorkspace(selection);
        let { width, height } = selection;

        // Adjust to zoom
        width *= this.editorStateService.zoom;
        height *= this.editorStateService.zoom;

        this.ctx.strokeStyle = '#919191';
        this.ctx.lineWidth = 0.5;
        this.ctx.fillStyle = 'rgba(0, 0, 0, 0.01)';

        if (nodeKind === ElementKind.Ellipse) {
            this.ctx.beginPath();
            this.ctx.ellipse(x + width / 2, y + height / 2, width / 2, height / 2, 0, 0, 360);
            this.ctx.closePath();
            this.ctx.stroke();
            this.ctx.fill();

            return;
        }

        // Draw rectangle in all other cases
        this.ctx.beginPath();
        this.ctx.moveTo(x, y);
        this.ctx.lineTo(x + width, y);
        this.ctx.lineTo(x + width, y + height);
        this.ctx.lineTo(x, y + height);
        this.ctx.closePath();
        this.ctx.stroke();
        this.ctx.fill();
    }

    private drawEllipseOutline(selection: IBoundingBox): void {
        const { x, y } = this.workspace.getPositionRelativeToWorkspace(selection);
        let { width, height } = selection;

        width *= this.editorStateService.zoom;
        height *= this.editorStateService.zoom;

        this.ctx.beginPath();
        this.ctx.ellipse(x + width / 2, y + height / 2, width / 2, height / 2, 0, 0, 360);
        this.ctx.closePath();
        this.ctx.strokeStyle = GizmoColor.border();
        this.ctx.lineWidth = LINE_WIDTH;
        this.ctx.stroke();
    }

    private drawDimmedGizmo(element: IBoundingBox): void {
        const linewidth = LINE_WIDTH;
        const { topLeft, topRight, bottomLeft, bottomRight } = this.getElementBoundingCorners(element);

        // Rectangle
        this.ctx.beginPath();
        this.ctx.moveTo(topLeft.x, topLeft.y);
        this.ctx.lineTo(topRight.x, topRight.y);
        this.ctx.lineTo(bottomRight.x, bottomRight.y);
        this.ctx.lineTo(bottomLeft.x, bottomLeft.y);
        this.ctx.closePath();
        this.ctx.strokeStyle = BORDER_DIMMED_COLOR;
        this.ctx.lineWidth = linewidth;
        this.ctx.stroke();
    }

    private drawTransformGizmo(box: IBoundingBox | OneOfViewNodes): void {
        const { topLeft, topRight, bottomLeft, bottomRight } = this.getElementBoundingCorners(box);
        const strokeStyle = GizmoColor.border();

        // Rectangle
        this.ctx.beginPath();
        this.ctx.moveTo(topLeft.x, topLeft.y);
        this.ctx.lineTo(topRight.x, topRight.y);
        this.ctx.lineTo(bottomRight.x, bottomRight.y);
        this.ctx.lineTo(bottomLeft.x, bottomLeft.y);
        this.ctx.closePath();
        this.ctx.strokeStyle = strokeStyle;
        this.ctx.lineWidth = LINE_WIDTH;
        this.ctx.stroke();

        if (this.elementSelectionService.currentSelection.nodes.some(node => isLocked(node))) {
            return;
        }

        // Top-Left ball
        this.ctx.beginPath();
        this.ctx.arc(topLeft.x, topLeft.y, BALL_SIZE, 0, 2 * Math.PI);
        this.ctx.fillStyle = BALL_COLOR;
        this.ctx.fill();
        this.ctx.lineWidth = LINE_WIDTH;
        this.ctx.strokeStyle = strokeStyle;
        this.ctx.stroke();

        // Top-Right ball
        this.ctx.beginPath();
        this.ctx.arc(topRight.x, topRight.y, BALL_SIZE, 0, 2 * Math.PI);
        this.ctx.fillStyle = BALL_COLOR;
        this.ctx.fill();
        this.ctx.lineWidth = LINE_WIDTH;
        this.ctx.strokeStyle = strokeStyle;
        this.ctx.stroke();

        // Bottom-Right ball
        this.ctx.beginPath();
        this.ctx.arc(bottomRight.x, bottomRight.y, BALL_SIZE, 0, 2 * Math.PI);
        this.ctx.fillStyle = BALL_COLOR;
        this.ctx.fill();
        this.ctx.lineWidth = LINE_WIDTH;
        this.ctx.strokeStyle = strokeStyle;
        this.ctx.stroke();

        // Bottom-Left ball
        this.ctx.beginPath();
        this.ctx.arc(bottomLeft.x, bottomLeft.y, BALL_SIZE, 0, 2 * Math.PI);
        this.ctx.fillStyle = BALL_COLOR;
        this.ctx.fill();
        this.ctx.lineWidth = LINE_WIDTH;
        this.ctx.strokeStyle = strokeStyle;
        this.ctx.stroke();
    }

    private getAnimatedPositionRelativeToWorkspace(
        element: OneOfElementDataNodes,
        time: number
    ): IPosition {
        const additionalStates =
            this.workspace.editorStateService.renderer.getAdditionalStates_m(element);
        const stateAtTime = animationsToState(
            element,
            this.workspace.canvasSize,
            time,
            additionalStates
        );
        const width = getViewElementPropertyValue('width', element, stateAtTime);
        const height = getViewElementPropertyValue('height', element, stateAtTime);
        const x = getViewElementPropertyValue('x', element, stateAtTime) + width / 2;
        const y = getViewElementPropertyValue('y', element, stateAtTime) + height / 2;
        return this.workspace.getPositionRelativeToWorkspace({ x, y });
    }

    private getElementBoundingCorners(
        element: IBoundingBox,
        calculateCenter = false
    ): IBoundingCorners {
        return this.workspace.getBoundingCorners(element, 'workspace', LINE_WIDTH / 2, calculateCenter);
    }

    private drawGradientHelper(colorPoints: IColorPoint[]): void {
        // TODO: SUPPORT MULTIPLE POINTS
        if (colorPoints.length > 1) {
            const startPoint = colorPoints[0];
            const endPoint = colorPoints[1];

            this.ctx.beginPath();
            this.ctx.shadowColor = 'rgba(0,0,0,0.5)';
            this.ctx.shadowBlur = 3;
            this.ctx.shadowOffsetY = 2;

            this.ctx.strokeStyle = 'white';
            this.ctx.lineWidth = 2;
            this.ctx.moveTo(startPoint.position.x, startPoint.position.y);
            this.ctx.lineTo(endPoint.position.x, endPoint.position.y);
            this.ctx.stroke();

            this.ctx.shadowBlur = 0;
            this.ctx.shadowOffsetY = 0;
            this.ctx.shadowColor = 'none';

            const startCircleRadius =
                this.workspace.gradientHelper.selectedPoint === colorPoints[0] ? 6 : 4;
            // Start circle
            this.ctx.beginPath();
            this.ctx.arc(
                startPoint.position.x,
                startPoint.position.y,
                startCircleRadius + 2,
                0,
                2 * Math.PI
            );
            this.ctx.fillStyle = '#ffffff';

            this.ctx.shadowColor = 'rgba(0,0,0,0.5)';
            this.ctx.shadowBlur = 3;
            this.ctx.shadowOffsetY = 2;

            this.ctx.fill();
            this.ctx.beginPath();
            this.ctx.arc(
                startPoint.position.x,
                startPoint.position.y,
                startCircleRadius,
                0,
                2 * Math.PI
            );
            this.ctx.fillStyle = toRGBA(startPoint.color);

            this.ctx.shadowBlur = 0;
            this.ctx.shadowOffsetY = 0;
            this.ctx.shadowColor = 'none';

            this.ctx.fill();

            const endCircleRadius =
                this.workspace.gradientHelper.selectedPoint === colorPoints[1] ? 6 : 4;
            // End circle
            this.ctx.beginPath();
            this.ctx.arc(endPoint.position.x, endPoint.position.y, endCircleRadius + 2, 0, 2 * Math.PI);
            this.ctx.fillStyle = '#ffffff';

            this.ctx.shadowColor = 'rgba(0,0,0,0.5)';
            this.ctx.shadowBlur = 3;
            this.ctx.shadowOffsetY = 2;

            this.ctx.fill();
            this.ctx.beginPath();
            this.ctx.arc(endPoint.position.x, endPoint.position.y, endCircleRadius, 0, 2 * Math.PI);
            this.ctx.fillStyle = toRGBA(endPoint.color);

            this.ctx.shadowBlur = 0;
            this.ctx.shadowOffsetY = 0;
            this.ctx.shadowColor = 'none';

            this.ctx.fill();
        }
    }

    private drawDot = (p: IPosition, radius = 1.5): void => {
        this.ctx.beginPath();
        this.ctx.arc(p.x, p.y, radius, 0, 2 * Math.PI);
        this.ctx.closePath();
        this.ctx.fill();
    };

    private drawArrow(from: IPosition, to: IPosition, size = 5): void {
        const arrowAngle = -pointsToAngle(from, to);
        const arrowEdge1 = rotatePosition(
            {
                x: to.x + size,
                y: to.y
            },
            to,
            arrowAngle
        );
        const arrowEdge2 = rotatePosition(
            {
                x: to.x + size,
                y: to.y
            },
            to,
            arrowAngle + (Math.PI * 2) / 3
        );
        const arrowEdge3 = rotatePosition(
            {
                x: to.x + size,
                y: to.y
            },
            to,
            arrowAngle - (Math.PI * 2) / 3
        );

        this.ctx.moveTo(arrowEdge1.x, arrowEdge1.y);
        this.ctx.beginPath();
        this.ctx.lineTo(arrowEdge2.x, arrowEdge2.y);
        this.ctx.lineTo(arrowEdge3.x, arrowEdge3.y);
        this.ctx.lineTo(arrowEdge1.x, arrowEdge1.y);
        this.ctx.closePath();
        this.ctx.fill();
    }

    setCanvasSize = (): void => {
        if (!this.workspace) {
            return;
        }

        const ratio = window.devicePixelRatio;
        const boundingRect = this.mount.getBoundingClientRect();
        const oldWidth = (this.canvasElementSize.width = boundingRect.width);
        const oldHeight = (this.canvasElementSize.height = boundingRect.height);
        this.canvas.width = oldWidth * ratio;
        this.canvas.height = oldHeight * ratio;
        this.ctx.scale(ratio, ratio);
        this.workspace.canvas.setBoundingRect();
        this.canvas.style.transform = `scale(${1 / ratio})`;
        this.canvas.style.transformOrigin = '0 0';
    };
}
