import { createAction, props } from '@ngrx/store';
import { ICampaignStatus } from '@domain/campaign';
import { ICreative } from '@domain/creativeset/creative';

type Polling = {
    lastPoll: number;
    intervalIndex: number;
    creatives: ICreative[];
};
// -- Load Status
export const loadCampaignsStatus = createAction(
    '[DisplayCampaign] Load Campaigns Status',
    props<{
        campaignIds: string[];
        polling?: Polling;
    }>()
);

export const loadCampaignsStatusSuccess = createAction(
    '[DisplayCampaign] Load Campaigns Status Success',
    props<{
        campaignPublishStatus: ICampaignStatus[];
        polling?: Polling;
    }>()
);

export const loadCampaignsStatusFailure = createAction(
    '[DisplayCampaign] Load Campaigns Status Failure',
    props<{ error: unknown }>()
);

// -- Push changes

export const pushChangesPrompt = createAction(
    '[DisplayCampaign] Push Changes Prompt',
    props<{ creatives: ICreative[] }>()
);

export const pushChanges = createAction(
    '[DisplayCampaign] Push Changes',
    props<{ creatives: ICreative[]; campaigns: ICampaignStatus[] }>()
);

export const pushChangesSuccess = createAction(
    '[DisplayCampaign] Push Changes Success',
    props<{ creatives: ICreative[] }>()
);

export const pushChangesFailure = createAction(
    '[DisplayCampaign] Push Changes Failure',
    props<{ error: unknown }>()
);

export const cancelPublish = createAction('[DisplayCampaign] Cancel publish');
