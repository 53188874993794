import { EnvironmentProviders, importProvidersFrom } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslationPageEffects } from './translation-page.effects';
import { reducer, TRANSLATION_PAGE_FEATURE_KEY } from './translation-page.reducer';

export function provideTranslationPageStore(): EnvironmentProviders {
    return importProvidersFrom(
        StoreModule.forFeature(TRANSLATION_PAGE_FEATURE_KEY, reducer),
        EffectsModule.forFeature(TranslationPageEffects)
    );
}
