import { Injectable } from '@angular/core';
import { ElementKind } from '@domain/elements';

export enum GainsightEvent {
    StartRecordAnimation = 'Start record animation',
    StopRecordAnimation = 'Stop record animation',
    ApplyCharacterStyling = 'Apply character styling',
    ClearCharacterStyling = 'Clear character styling',
    AddWidgetToCanvas = 'Add widget to canvas',
    AddEffectToCanvas = 'Add effect to canvas',
    ImportEffect = 'Import effect',
    Concurrency = 'Concurrency',
    UseMask = 'Use mask'
}

type GainsightEffectWidget = {
    id: string;
    name: string;
};

type GainsightWidget = {
    id: string;
    name: string;
};

type GainsightUseMask = {
    elementType: ElementKind;
};

export type GainsightMap = {
    [GainsightEvent.StartRecordAnimation]: never;
    [GainsightEvent.StopRecordAnimation]: never;
    [GainsightEvent.ApplyCharacterStyling]: never;
    [GainsightEvent.ClearCharacterStyling]: never;
    [GainsightEvent.AddWidgetToCanvas]: GainsightWidget;
    [GainsightEvent.AddEffectToCanvas]: GainsightEffectWidget;
    [GainsightEvent.ImportEffect]: GainsightEffectWidget;
    [GainsightEvent.UseMask]: GainsightUseMask;
    [GainsightEvent.Concurrency]: { type: 'show' | 'reload' | 'ignore' | 'see-users' };
};

/**
 * GainsightService is used for sending custom events.
 * Note: Google Tag Manager injects the script and it is available in sandbox/production
 *
 * https://support.gainsight.com/PX/API_for_Developers/02Usage_of_Different_APIs/Use_Custom_Event_API
 */
@Injectable({ providedIn: 'root' })
export class GainsightService {
    public sendCustomEvent<
        GSEvent extends keyof GainsightMap,
        GSProperties extends GainsightMap[GSEvent]
    >(event: GSEvent, properties?: GSProperties): void {
        if (window.aptrinsic) {
            window.aptrinsic('track', event, properties);
        }
    }
}
