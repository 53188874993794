import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    standalone: true,
    name: 'fractionToPercentage'
})
export class FractionToPercentagePipe implements PipeTransform {
    transform(value: number): number {
        if (value > 1) {
            return value;
        }

        return Math.round(value * 100);
    }
}
