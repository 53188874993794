import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { UIDialogComponent } from '@bannerflow/ui';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'font-manager',
    templateUrl: 'font-manager.component.html',
    styleUrls: ['font-manager.component.scss']
})
export class FontManagerComponent implements OnInit {
    url: SafeUrl;
    constructor(
        private dialogComponent: UIDialogComponent,
        private sanitizer: DomSanitizer
    ) {}

    ngOnInit(): void {
        const brandId = this.dialogComponent.config.data?.brandId;
        if (!brandId) {
            throw new Error('Brand ID not set');
        }
        const unsanitizedUrl = `${environment.origins.fontManager}/in-frame/${brandId}`;
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(unsanitizedUrl);
    }
}
