export const getManageViewTitle = (creativeSetName: string): string => `${creativeSetName} - Studio`;

export const getDesignViewTitle = (width: number, height: number, creativeSetName: string): string =>
    `${width}x${height} - ${creativeSetName} - Studio`;

export const getTranslationPageTitle = (
    versionName: string | undefined,
    creativesetName: string
): string =>
    versionName ? `${versionName} - ${creativesetName} - Studio` : `${creativesetName} - Studio`;
