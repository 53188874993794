@if (sizes.length) {
    <div class="format-list">
        <quick-filters />
        <!-- TODO: Refactor this to use signals internally-->
        <filter-list-sizes [sizes]="sizes" />
        <ng-container *media="Breakpoint.DesktopUp">
            <filter-list-status *permissions="'Status'" />
        </ng-container>
    </div>
}
