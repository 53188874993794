import { gql } from 'apollo-angular';

export const GET_USER = gql`
    query UserQuery($accountSlug: String!) {
        user(accountSlug: $accountSlug) {
            isEmployee
            permissions
            role
        }
    }
`;
