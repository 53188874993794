import schema from './generated/sapi.schema';

const schemas = schema.components.schemas;
const rectangleElementDto = schemas.RectangleElementDto.properties;
const stateDto = schemas.StateDto.properties;
const radiusDto = schemas.RadiusDto.properties;
const borderDto = schemas.BorderDto.properties;
const shadowDto = schemas.ShadowDto.properties;
const textElementDto = schemas.TextElementDto.properties;
const paddingDto = schemas.PaddingDto.properties;
const textShadowDto = schemas.TextShadowDto.properties;

type PropertyRange = Pick<typeof rectangleElementDto.width, 'maximum' | 'minimum'>;

function toRangeValidation({ maximum, minimum }: PropertyRange): { max: number; min: number } {
    return {
        max: maximum,
        min: minimum
    };
}

export const validationMap = {
    layout: {
        position: toRangeValidation(rectangleElementDto.x),
        size: toRangeValidation(rectangleElementDto.width),
        rotation: toRangeValidation(rectangleElementDto.rotationX),
        scale: toRangeValidation(stateDto.scaleX),
        radius: toRangeValidation(radiusDto.bottomLeft)
    },
    default: {
        border: toRangeValidation(borderDto.thickness),
        shadow: {
            offset: toRangeValidation(shadowDto.offsetX),
            blur: toRangeValidation(shadowDto.blur)
        }
    },
    text: {
        fontSize: toRangeValidation(textElementDto.fontSize),
        maxRows: toRangeValidation(textElementDto.maxRows),
        characterSpacing: toRangeValidation(textElementDto.characterSpacing),
        lineHeight: toRangeValidation(textElementDto.lineHeight),
        padding: toRangeValidation(paddingDto.left),
        textShadow: {
            offset: toRangeValidation(textShadowDto.offsetX),
            blur: toRangeValidation(textShadowDto.blur)
        }
    }
} as const;
