import { Injectable, OnDestroy } from '@angular/core';
import { positionIsInBounds } from '@creative/nodes/helpers';
import { IBounds, IPosition } from '@domain/dimension';
import { isSamePosition } from '@studio/utils/geom';
import { IMouseDownMove, IMouseWheelSpeed } from '@studio/utils/mouse-observable';
import { clamp } from '@studio/utils/utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StudioTimelineComponent } from './studio-timeline/studio-timeline.component';
import { KeyframeAction, KeyframeService } from './timeline-element';
import { ActionMode } from './timeline-transformer.service';
import { SCROLLBAR_TRACK_WIDTH, TIMERULER_HEIGHT } from './timeline.constants';

interface IDisabledDirection {
    x?: boolean;
    y?: boolean;
}

@Injectable()
export class TimelineScrollService implements OnDestroy {
    private _scroll$ = new Subject<IPosition>();
    scroll$ = this._scroll$.asObservable();
    scroll: Readonly<IPosition> = { x: 0, y: 0 };
    scrollMode: ScrollMode = ScrollMode.None;
    scrollBarHighlighted?: 'horizontal' | 'vertical';
    scrollBarTrackHighlighted?: 'horizontal' | 'vertical';
    private disabledDirections: IDisabledDirection = {
        x: false,
        y: false
    };

    get isScrolling(): boolean {
        return this.scrollMode !== ScrollMode.None;
    }

    private timeline?: StudioTimelineComponent;
    private unsubscribe$ = new Subject<void>();
    private initialScroll: IPosition = { x: 0, y: 0 };

    constructor(private keyframeService: KeyframeService) {}

    connect(timeline: StudioTimelineComponent): void {
        this.timeline = timeline;

        const mouseObservable = this.timeline.mouseObservable;
        mouseObservable.mouseDown$.pipe(takeUntil(this.unsubscribe$)).subscribe(({ mousePosition }) => {
            this.mouseDown(mousePosition);
        });

        mouseObservable.mouseMove$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(({ mousePosition }) => this.mouseMove(mousePosition));

        mouseObservable.mouseDownMove$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(mouseValue => this.mouseDownMove(mouseValue));

        mouseObservable.mouseUp$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.mouseUp());

        mouseObservable.mouseWheel$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(({ event, speed }) => this.mouseWheel(event, speed));

        mouseObservable.mouseEnter$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => this.mouseEnter());

        mouseObservable.mouseLeave$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => this.mouseLeave());
    }

    disconnect(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.timeline = undefined;
    }

    disableDirections(direction: IDisabledDirection = { x: true, y: true }): void {
        this.disabledDirections = direction;
    }

    enableDirections(direction: IDisabledDirection = { x: false, y: false }): void {
        this.disabledDirections = direction;
    }

    private mouseDown = (mousePosition: IPosition): void => {
        const timeline = this.timeline!;

        if (
            this.isPositionOverVerticalScrollTrack(mousePosition) ||
            this.isPositionOverHorizontalScrollTrack(mousePosition)
        ) {
            timeline.timelineTransformService.actionMode = ActionMode.None;
        }

        if (
            timeline.editor.workspace.isZooming ||
            timeline.slider?.mouseIsDown ||
            timeline.isZoomControlVisible
        ) {
            return;
        }

        if (this.isPositionOverVerticalScrollTrack(mousePosition)) {
            this.scrollMode = ScrollMode.Vertical;
            this.initialScroll = this.scroll;
            return;
        }

        if (this.isPositionOverHorizontalScrollTrack(mousePosition)) {
            this.scrollMode = ScrollMode.Horizontal;
            this.initialScroll = this.scroll;
            return;
        }
    };

    private mouseMove = (mousePosition: IPosition): void => {
        const timeline = this.timeline!;
        const { gizmoDrawer } = timeline;
        const timelineActionMode = timeline.timelineTransformService.actionMode;

        if (!gizmoDrawer) {
            return;
        }

        if (
            timelineActionMode !== ActionMode.None ||
            this.keyframeService.actionMode !== KeyframeAction.None
        ) {
            return;
        }

        if (
            (this.scrollMode === ScrollMode.None && mousePosition.y < 0) ||
            timeline.editor.workspace.isZooming ||
            timeline.slider?.mouseIsDown ||
            timeline.isZoomControlVisible ||
            timelineActionMode !== ActionMode.None
        ) {
            return;
        }

        if (this.scrollMode !== ScrollMode.None) {
            return;
        }

        let redraw = false;
        if (this.isPositionOnVerticalScrollBar(mousePosition)) {
            redraw = this.scrollBarHighlighted !== 'vertical';
            this.scrollBarHighlighted = 'vertical';
            timeline.setCursor();
        } else if (this.isPositionOnHorizontalScrollBar(mousePosition)) {
            redraw = this.scrollBarHighlighted !== 'horizontal';
            this.scrollBarHighlighted = 'horizontal';
            timeline.setCursor();
        } else if (this.isPositionOverVerticalScrollTrack(mousePosition)) {
            redraw = this.scrollBarTrackHighlighted !== 'vertical';
            this.scrollBarTrackHighlighted = 'vertical';
            timeline.setCursor();
        } else if (this.isPositionOverHorizontalScrollTrack(mousePosition)) {
            redraw = this.scrollBarTrackHighlighted !== 'horizontal';
            this.scrollBarTrackHighlighted = 'horizontal';
            timeline.setCursor();
        } else {
            redraw =
                this.scrollBarHighlighted !== undefined || this.scrollBarTrackHighlighted !== undefined;
            this.scrollBarHighlighted = undefined;
            this.scrollBarTrackHighlighted = undefined;
        }

        if (redraw) {
            timeline.gizmoDrawer.draw();
        }
    };

    private mouseDownMove(mouseValue: IMouseDownMove): void {
        const { mouseDelta } = mouseValue;
        const timeline = this.timeline!;

        if (this.scrollMode === ScrollMode.Vertical) {
            const scale = timeline.scrollContentSize.height / timeline.scrollViewportSize.height;
            this.setScroll({
                x: this.initialScroll.x,
                y: this.initialScroll.y + mouseDelta.y * scale
            });
            timeline.gizmoDrawer.shouldDrawScrollBars = true;
        } else if (this.scrollMode === ScrollMode.Horizontal) {
            const scale = timeline.scrollContentSize.width / timeline.scrollViewportSize.width;
            this.setScroll({
                x: this.initialScroll.x + mouseDelta.x * scale,
                y: this.initialScroll.y
            });
            timeline.gizmoDrawer.shouldDrawScrollBars = true;
        }
    }

    private mouseUp = (): void => {
        this.scrollMode = ScrollMode.None;
    };

    private mouseEnter = (): void => {
        const gizmoDrawer = this.timeline?.gizmoDrawer;
        if (!this.isScrolling && gizmoDrawer) {
            gizmoDrawer.shouldDrawScrollBars = true;
            gizmoDrawer.draw();
        }
    };

    private mouseLeave = (): void => {
        const gizmoDrawer = this.timeline?.gizmoDrawer;
        if (!this.isScrolling && gizmoDrawer) {
            gizmoDrawer.shouldDrawScrollBars = false;
            gizmoDrawer.draw();
        }
    };

    private mouseWheel = (event: MouseEvent, speed: IMouseWheelSpeed): void => {
        const timeline = this.timeline!;

        if (timeline.isZooming || event.altKey || event.ctrlKey || event.metaKey) {
            return;
        }

        const moveY = speed.y / 2;
        const moveX = speed.x / 2;
        const currentScroll = this.scroll;
        if (event.shiftKey) {
            this.setScroll({
                x: currentScroll.x + moveY,
                y: currentScroll.y
            });
        } else {
            this.setScroll({
                x: currentScroll.x + moveX,
                y: currentScroll.y + moveY
            });
        }
    };

    setScroll(position: Partial<IPosition>): void {
        const timeline = this.timeline;

        let x: number = position.x ?? this.scroll.x;
        let y: number = position.y ?? this.scroll.y;

        // Limit values
        if (timeline) {
            x = clamp(x, 0, timeline.scrollContentSize.width - timeline.scrollViewportSize.width);
            y = clamp(y, 0, timeline.scrollContentSize.height - timeline.scrollViewportSize.height);
        }

        const newPosition = { x, y };

        if (this.disabledDirections.x) {
            newPosition.x = this.scroll.x;
        }

        if (this.disabledDirections.y) {
            newPosition.y = this.scroll.y;
        }

        // Only emit if changed
        if (!isSamePosition(this.scroll, newPosition)) {
            this.scroll = newPosition;
            this._scroll$.next(newPosition);
        }
    }

    isPositionOnScrollbars(mousePosition: IPosition): boolean {
        const relativeMousePosition = {
            x: mousePosition.x - this.timeline!.leftPanelWidth,
            y: mousePosition.y
        };
        const scroll = this.timeline!.shouldScrollbarShow();
        if (scroll.vertical && this.isPositionOverVerticalScrollTrack(relativeMousePosition)) {
            return true;
        } else if (
            scroll.horizontal &&
            this.isPositionOverHorizontalScrollTrack(relativeMousePosition)
        ) {
            return true;
        }
        return false;
    }

    private isPositionOverVerticalScrollTrack(position: IPosition): boolean {
        return (
            !!this.timeline?.gizmoDrawer &&
            positionIsInBounds(position, this.getVerticalScrollTrackBounds())
        );
    }

    private isPositionOverHorizontalScrollTrack(position: IPosition): boolean {
        return (
            !!this.timeline?.gizmoDrawer &&
            positionIsInBounds(position, this.getHorizontalScrollTrackBounds())
        );
    }

    private isPositionOnVerticalScrollBar(position: IPosition): boolean {
        const barBounds = this.timeline!.getVerticalScrollbarBounds();
        return positionIsInBounds(position, {
            x: barBounds.x - 6,
            y: barBounds.y - 6,
            width: barBounds.width + SCROLLBAR_TRACK_WIDTH,
            height: barBounds.height + SCROLLBAR_TRACK_WIDTH
        });
    }

    private isPositionOnHorizontalScrollBar(position: IPosition): boolean {
        const barBounds = this.timeline!.getHorizontalScrollbarBounds();
        return positionIsInBounds(position, {
            x: barBounds.x - 6,
            y: barBounds.y - 6,
            width: barBounds.width + SCROLLBAR_TRACK_WIDTH,
            height: barBounds.height + SCROLLBAR_TRACK_WIDTH
        });
    }

    getVerticalScrollTrackBounds(): IBounds {
        const gizmoDrawer = this.timeline!.gizmoDrawer;
        return {
            x: gizmoDrawer.width - SCROLLBAR_TRACK_WIDTH,
            y: TIMERULER_HEIGHT,
            width: SCROLLBAR_TRACK_WIDTH,
            height: gizmoDrawer.height - TIMERULER_HEIGHT
        };
    }

    getHorizontalScrollTrackBounds(): IBounds {
        const gizmoDrawer = this.timeline!.gizmoDrawer;
        return {
            x: 0,
            y: gizmoDrawer.height - SCROLLBAR_TRACK_WIDTH,
            width: gizmoDrawer.width,
            height: SCROLLBAR_TRACK_WIDTH
        };
    }

    ngOnDestroy(): void {
        this.disconnect();
    }
}

export enum ScrollMode {
    None,
    Horizontal,
    Vertical
}
