<div
    class="menu-trigger"
    #menuTrigger="uiDropdownTarget"
    [uiDropdownTarget]="menu"
    [style.top.px]="top"
    [style.left.px]="left"></div>
<ui-dropdown
    #menu
    (open)="toggle(true)"
    (close)="toggle(false)"
    [width]="'250'"
    [minWidth]="250"
    type="menu">
    @if (showSelectionOptions) {
        @if ((tileSelectService.isAllSelected$ | async) === false) {
            <ui-dropdown-item
                id="menu-select-all"
                class="custom-dropdown"
                (click)="selectAll()">
                <div class="custom-item">
                    <div class="custom-column"></div>
                    <div class="custom-text">Select all</div>
                    <div [class]="isMac ? 'mac custom-action' : 'custom-action'">
                        {{ keyboardShortcuts.ManagePage.SelectAll }}
                    </div>
                </div>
            </ui-dropdown-item>
        }
        @if (anyIsSelected) {
            <ui-dropdown-item
                id="menu-select-none"
                class="custom-dropdown"
                (click)="deselectAll()">
                <div class="custom-item">
                    <div class="custom-column"></div>
                    <div class="custom-text">Clear selection</div>
                    <div [class]="isMac ? 'mac custom-action' : 'custom-action'">
                        {{ keyboardShortcuts.ManagePage.Deselect }}
                    </div>
                </div>
            </ui-dropdown-item>
        }
        <ui-dropdown-divider></ui-dropdown-divider>
    }

    <ng-container *permissions="['Default', 'ChangeDesign']">
        @if (oneIsSelected) {
            <ui-dropdown-item
                id="menu-edit-design"
                (click)="editDesign($event)"
                class="custom-dropdown">
                <div class="custom-item">
                    <div class="custom-column"></div>
                    <div class="custom-text">Edit design</div>
                    <div class="custom-action"></div>
                </div>
            </ui-dropdown-item>
        }
        @if (anyIsSelected) {
            <ui-dropdown-item
                id="copy-design"
                class="custom-dropdown"
                [disabled]="!canCopy"
                (click)="copyDesign()">
                <div class="custom-item">
                    <div class="custom-column"></div>
                    <div class="custom-text">Copy design</div>
                    <div class="custom-action">{{ keyboardShortcuts.ManagePage.Copy }}</div>
                </div>
            </ui-dropdown-item>
            <ui-dropdown-item
                id="paste-design"
                class="custom-dropdown"
                (click)="pasteDesign()"
                [disabled]="!canPaste">
                <div class="custom-item">
                    <div class="custom-column"></div>
                    <div class="custom-text">Paste design</div>
                    <div class="custom-action">{{ keyboardShortcuts.ManagePage.Paste }}</div>
                </div>
            </ui-dropdown-item>
        }

        <ng-container *permissions="'Duplicate'">
            <ui-dropdown-item
                class="custom-dropdown"
                id="interaction-duplicate"
                data-test-id="duplicate-creatives-item"
                [hasBackdrop]="false"
                [uiDropdownTarget]="duplicateSection">
                <div class="custom-item">
                    <div class="custom-column">
                        <ui-svg-icon icon="copy-s"></ui-svg-icon>
                    </div>
                    <div class="custom-text">Duplicate to {{ creativesSelectedText }}</div>
                </div>
            </ui-dropdown-item>
        </ng-container>

        @if (anyIsSelected) {
            <ui-dropdown-divider></ui-dropdown-divider>
            <ui-dropdown-item
                id="menu-activate-size"
                [disabled]="!canActivateCreatives"
                (click)="activateSize()">
                {{ activateText }}
            </ui-dropdown-item>
            <ui-dropdown-item
                id="menu-deactivate-size"
                [disabled]="!canDeactivateCreatives"
                (click)="deactivateSize()">
                {{ deactivateText }}
            </ui-dropdown-item>
            <ui-dropdown-divider></ui-dropdown-divider>
        }
    </ng-container>

    <ui-dropdown-item
        [disabled]="!anyIsSelected"
        id="interaction-ctx-menu-filter"
        [uiDropdownTarget]="filterSection"
        [hasBackdrop]="false">
        Filter
    </ui-dropdown-item>
    <ng-container *permissions="'Default'">
        @if (anyIsSelected) {
            <ui-dropdown-item
                id="menu-set-target"
                (click)="overrideTargetUrl()">
                Set target URL {{ creativesSelectedText }}
            </ui-dropdown-item>
        }
    </ng-container>
    <ng-container *permissions="'Status'">
        <ui-dropdown-divider></ui-dropdown-divider>

        @if (statusDropdown.dropdown) {
            <ui-dropdown-item
                id="interaction-setstatus"
                [disabled]="!anyIsSelected"
                [uiDropdownTarget]="statusDropdown.dropdown">
                Set status {{ creativesSelectedText }}
            </ui-dropdown-item>
        }
    </ng-container>

    <ng-container *permissions="'Default'">
        <ng-container *permissions="'ChangeDesign'">
            <ui-dropdown-divider></ui-dropdown-divider>
            <ui-dropdown-item
                id="menu-delete"
                class="custom-dropdown"
                [disabled]="disableDelete"
                [uiTooltipDisabled]="!disableDelete"
                [uiTooltip]="
                    'The size can not be deleted while at least one version is connected to the published ad'
                "
                (click)="delete()">
                <div class="custom-item">
                    <div class="custom-column">
                        <ui-svg-icon icon="delete"></ui-svg-icon>
                    </div>
                    <div class="custom-text">{{ deleteQueueText }}</div>
                    <div class="custom-action">{{ keyboardShortcuts.ManagePage.DeleteSize }}</div>
                </div>
            </ui-dropdown-item>
        </ng-container>
        <ng-container *permissions="'ShareCreativeSet'">
            <ui-dropdown-item
                id="mobile-preview"
                class="menu-option"
                icon="qr-code"
                [disabled]="mobilePreviewDisabled"
                (click)="showMobilePreviewModal()">
                Mobile preview
            </ui-dropdown-item>
        </ng-container>
        <ng-container *permissions="'CalculateWeights'">
            <ui-dropdown-divider></ui-dropdown-divider>
            <ui-dropdown-item
                id="menu-calculate-weights"
                class="custom-dropdown"
                [disabled]="calculateWeightDisabled"
                (click)="calculateWeights()">
                <div class="custom-item">
                    <div class="custom-column">
                        <ui-svg-icon icon="sync"></ui-svg-icon>
                    </div>
                    <div class="custom-text">{{ calculateWeightQueueText }}</div>
                    <div class="custom-action"></div>
                </div>
            </ui-dropdown-item>
        </ng-container>
        <ui-dropdown-divider></ui-dropdown-divider>

        @if (exportDropdown?.dropdown) {
            <ui-dropdown-item
                class="custom-dropdown"
                id="interaction-export"
                [disabled]="exportDisabled"
                [uiDropdownTarget]="exportDropdown.dropdown">
                <div class="custom-item">
                    <div class="custom-column">
                        <ui-svg-icon icon="export"></ui-svg-icon>
                    </div>
                    <div
                        id="interaction-export-creatives"
                        class="custom-text">
                        {{ exportText }}
                    </div>
                    <div class="custom-action"></div>
                </div>
            </ui-dropdown-item>
        }

        <ng-container *permissions="'CampaignActions'">
            @if (addToCampaignDropdown?.dropdown) {
                <ui-dropdown-item
                    id="add-to-campaign-context-menu"
                    class="custom-dropdown"
                    [disabled]="!!(addToCampaignDisabled$ | async)"
                    [uiDropdownTarget]="addToCampaignDropdown.dropdown">
                    <div class="custom-item">
                        <div class="custom-column"></div>
                        <div class="custom-text">{{ addToCampaignText$ | async }}</div>
                        <div class="custom-action"></div>
                    </div>
                </ui-dropdown-item>
            }
            <ui-dropdown-item
                id="menu-publish-changes"
                class="custom-dropdown"
                [disabled]="publishDisabled"
                (click)="publishChanges()">
                <div class="custom-item">
                    <div class="custom-column">
                        <ui-svg-icon icon="campaigns-s"></ui-svg-icon>
                    </div>
                    <div class="custom-text">{{ publishQueueText }}</div>
                    <div class="custom-action"></div>
                </div>
            </ui-dropdown-item>
        </ng-container>
    </ng-container>
</ui-dropdown>

<ui-dropdown
    #filterSection
    [hasBackdrop]="false"
    [width]="'150'"
    [minWidth]="150"
    type="menu">
    <ui-dropdown-item
        [disabled]="!anyIsSelected"
        id="interaction-ctx-menu-filter-hide-selected"
        (click)="hideSelected()"
        >Hide selected
    </ui-dropdown-item>
    <ui-dropdown-item
        [disabled]="!anyIsSelected"
        id="interaction-ctx-menu-filter-hide-other"
        (click)="hideOthers()"
        class="custom-dropdown">
        <div class="custom-item">
            <div class="custom-column"></div>
            <div class="custom-text">Hide others</div>
            <div class="custom-action">H</div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-divider></ui-dropdown-divider>
    <ui-dropdown-item
        class="custom-dropdown"
        id="interaction-ctx-menu-filter-clear"
        [disabled]="(filtersService.hasActiveFilters$ | async) === false"
        (click)="removeAllFilters()">
        <div class="custom-item">
            <div class="custom-column">
                <ui-svg-icon icon="filter-clear"></ui-svg-icon>
            </div>
            <div class="custom-text">Clear all filters</div>
            <div class="custom-action"></div>
        </div>
    </ui-dropdown-item>
</ui-dropdown>

<ui-dropdown
    #duplicateSection
    [width]="'150'"
    [minWidth]="150"
    [hasBackdrop]="false"
    type="menu">
    <ui-dropdown-item
        id="interaction-duplicate-creative-here"
        class="custom-dropdown"
        data-test-id="duplicate-creative-here"
        (click)="duplicateCreatives()">
        <div class="custom-item">
            <div class="custom-column"></div>
            <div class="custom-text">Duplicate Here</div>
            <div class="custom-action"></div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-item
        id="interaction-duplicate-size-to-new"
        class="custom-dropdown"
        data-test-id="duplicate-creative-to-new"
        (click)="duplicateSize(true)">
        <div class="custom-item">
            <div class="custom-column"></div>
            <div class="custom-text">New creative set</div>
            <div class="custom-action"></div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-item
        id="interaction-duplicate-size-to-existing"
        class="custom-dropdown"
        data-test-id="duplicate-creative-to-existing"
        (click)="duplicateSize(false)">
        <div class="custom-item">
            <div class="custom-column"></div>
            <div class="custom-text">Existing creative set</div>
            <div class="custom-action"></div>
        </div>
    </ui-dropdown-item>
</ui-dropdown>

<status-dropdown #statusDropdown></status-dropdown>
<export-dropdown #exportDropdown></export-dropdown>

<add-to-campaign-dropdown #addToCampaignDropdown></add-to-campaign-dropdown>
