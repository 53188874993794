import { IAsyncSubject, ISubscription } from '@domain/async';

export class Subscription<T> implements ISubscription<T> {
    private _completed = false;

    constructor(
        private _subject: AsyncSubject<T>,
        private _callback: (data: T) => void
    ) {}

    complete_m(data: T): void {
        if (this._completed) {
            return;
        }
        this._callback(data);
        this._completed = true;
    }

    unsubscribe(): void {
        this._subject.unsubscribe_m(this);
    }
}

export class AsyncSubject<T> implements IAsyncSubject<T> {
    private _subscriptions: Subscription<T>[] = [];
    private _completed = false;

    constructor(public value: T | undefined) {}

    subscribe(callback: (data: T) => void): Subscription<T> {
        const subscription = new Subscription(this, callback);
        this._subscriptions.push(subscription);
        if (this._completed) {
            subscription.complete_m(this.value!);
        }
        return subscription;
    }

    unsubscribe_m(subscription: Subscription<T>): void {
        this._subscriptions = this._subscriptions.filter(s => s !== subscription);
    }

    next_m(data: T): void {
        this.value = data;
    }

    complete_m(): void {
        for (const subscription of this._subscriptions) {
            subscription.complete_m(this.value!);
        }
        this._completed = true;
    }
}
