import { Injectable, OnDestroy } from '@angular/core';
import { createAction } from '@creative/actions/actions.utils';
import { ActionOperationMethod, ActionTrigger, IAction } from '@domain/action';
import { OneOfElementDataNodes } from '@domain/nodes';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EditorStateService } from '../../services/editor-state.service';
import { PropertiesService } from '../properties.service';

@Injectable()
export class ActionsService implements OnDestroy {
    dataElement?: OneOfElementDataNodes;
    change$ = new Subject<void>();
    private unsubscribe$ = new Subject<void>();

    constructor(
        private propertiesService: PropertiesService,
        private editorStateService: EditorStateService
    ) {
        this.propertiesService.dataElementChange$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(dataElement => (this.dataElement = dataElement));
    }

    addAction(action?: IAction, trigger?: ActionTrigger): string {
        const state = this.dataElement!.states.find(state => {
            if (state.name) {
                const action = this.dataElement!.actions.find(action =>
                    action.operations.find(operation => operation.value === state.id)
                );
                if (!action?.templateId) {
                    return true;
                }
            }
            return false;
        });

        let newAction: IAction;
        if (trigger === ActionTrigger.Click && !this.clickthroughActionExists()) {
            newAction =
                action ||
                createAction({
                    triggers: trigger ? [trigger] : undefined,
                    method: ActionOperationMethod.OpenUrl,
                    target: undefined,
                    stateId: undefined
                });
        } else {
            newAction =
                action ||
                createAction({
                    triggers: trigger ? [trigger] : undefined,
                    target: this.dataElement!.id,
                    stateId: state?.id
                });
        }

        this.editorStateService.mutatorService.setElementPropertyValue(this.dataElement!, 'actions', [
            ...this.dataElement!.actions,
            newAction
        ]);
        this.change$.next();
        return newAction.id;
    }

    private clickthroughActionExists(): boolean {
        return this.dataElement!.actions.some(
            action =>
                action.triggers.some(trigger => trigger === ActionTrigger.Click) &&
                action.operations.some(operation => operation.method === ActionOperationMethod.OpenUrl)
        );
    }

    deleteAction(action: IAction): void {
        if (!this.dataElement) {
            throw new Error('Can not delete action on a non selected dataElement');
        }

        const actions = this.dataElement.actions.filter(a => a.id !== action.id);
        this.editorStateService.mutatorService.setElementPropertyValue(
            this.dataElement!,
            'actions',
            actions
        );
        this.change$.next();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
