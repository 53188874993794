<div class="center">
    <div #canvas></div>
    <p class="description">
        Scan this QR code with your mobile device to open mobile preview of
        <span>{{ targetMessage }}</span>
    </p>
    <ui-button
        text="Done"
        type="primary"
        (click)="close()">
    </ui-button>
</div>
