<span
    id="mv-preview"
    class="scale"
    *ngIf="!isMobileShowcase && creative?.design?.elements?.length"
    [uiTooltip]="percentageTooltip"
    [uiTooltipHideArrow]="true"
    (click)="openCreativeDialog()">
    {{ scale * 100 | number: '1.0-0' }}%
</span>

<ui-dialog
    #dialog="ui-dialog"
    *ngIf="creative"
    [config]="dialogCreativeConfig">
    <ng-template ui-dialog-template>
        <div
            class="full-preview"
            [style.height.px]="creative.size.height"
            [style.width.px]="creative.size.width">
            <studio-creative
                id="fullPreviewCreative"
                [ngClass]="{ 'in-mobile-showcase': isMobileShowcase }"
                [showTargetUrl]="!isMobileShowcase"
                [creative]="creative"
                [preview]="true"
                [renderOnInit]="true"
                [shouldPlay]="true"></studio-creative>
        </div>
    </ng-template>
</ui-dialog>
