import { IFeedDataKeyValueItem } from '@domain/feed';

const REGEX_URL =
    /https?:\/\/(www\.)?([-a-zA-Z0-9@:%_+~#=]+\.){1,256}[a-z]{2,}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)((?!\^).)*$/i;
const REGEX_NUMBER = /^-?\d+(\.\d+)?(e-?\d+)?$/gi;

// const regexNum = new RegExp(REGEX_NUMBER, 'ig');
// const regexUrl = new RegExp(REGEX_URL, 'i');

export function isValidUrl(string: string): boolean {
    let url: URL;

    try {
        url = new URL(string);
    } catch {
        return false;
    }

    const match = typeof string === 'string' ? REGEX_URL.test(string) : false;

    return match && (url.protocol === 'http:' || url.protocol === 'https:');
}

export function isValidNumber(num: string): boolean {
    return typeof num === 'string' ? REGEX_NUMBER.test(num) : false;
}

export function validateFeedData(feedData: IFeedDataKeyValueItem[]): void {
    const InvalidFeedError = new Error(`
    Attempt to override a feed with invalid feed data. The feed data has to follow the following schema:

    [
        {
            MyFeedItemKey: {
                value: 'feed value',
                targetUrl: 'https://website.com'
            },
            MyFeedItemKey2: {
                value: 'feed value',
                targetUrl: 'https://website.com'
            }
        }
    ]
    `);

    if (!Array.isArray(feedData)) {
        throw InvalidFeedError;
    }
    for (const dataItem of feedData) {
        if (typeof dataItem !== 'object') {
            throw InvalidFeedError;
        }

        for (const item of Object.keys(dataItem)) {
            if (!Object.prototype.hasOwnProperty.call(dataItem[item], 'value')) {
                throw InvalidFeedError;
            }
        }
    }
}
