import { IAd } from '@domain/ad/ad';
import { concatUrl, isRelativeUrl } from '@studio/utils/url';
import { IAdPreloadImage } from '@domain/ad/preload-image';

const FADE_DURATION = 200;

export class PreloadImage implements IAdPreloadImage {
    private image?: HTMLImageElement;

    constructor(private ad: IAd) {}

    /**
     * Load and add image to DOM.
     */
    add(
        container: HTMLElement,
        callback?: (creative?: HTMLImageElement) => void
    ): HTMLImageElement | undefined {
        const creative = this.ad.selectedCreative;

        if (creative.image?.url) {
            let url: string | undefined = creative.image.url;

            // Url is relative, merge with origin
            if (isRelativeUrl(url)) {
                url = concatUrl(this.ad.getOrigin(), url);
            }
            // Try to load webp. Note: image change on every publish. Also it's server push so ad service need to be updated to enable this
            // if (creative.image.optimizeUrl) {
            //     url = optimizeImageUrl(this.ad.isWebPSupported, creative.image.optimizeUrl, url, creative.size.width, creative.size.height, '');
            // }

            const image = new Image(creative.size.width, creative.size.height);
            image.src = url;
            const style = image.style;
            style.opacity = '0';
            style.display = 'block';
            style.width = `${creative.size.width}px`;
            style.height = `${creative.size.height}px`;
            style.maxWidth = '100%';
            style.position = 'absolute';
            style.top = style.left = style.margin = style.padding = style.zIndex = '0';
            style.border = style.outline = style.transform = style.filter = 'none';
            style.cursor = 'pointer';

            if (this.ad.shouldFadeIn()) {
                style.transition = `opacity ${FADE_DURATION}ms ease`;
            }
            if (this.ad.responsive) {
                style.width = '100%';
                style.height = 'auto';
            }

            this.ad.events.once(image, 'load', () => {
                container.appendChild(image);
                style.opacity = '1';

                this.ad.events.on(image, 'click', this.onClick);

                if (typeof callback === 'function') {
                    callback(this.image);
                }
            });

            this.image = image;

            return image;
        } else {
            // TODO: Throw error or be gentle?
        }
    }

    /**
     * Fade out image and remove from done when done.
     */
    remove(): void {
        if (this.image) {
            this.image.style.opacity = '0';
            this.image.style.pointerEvents = 'none';
            setTimeout(this.destroy, this.ad.shouldFadeIn() ? FADE_DURATION + 30 : 0);
        }
    }

    destroy = (): void => {
        if (this.image) {
            if (this.image.parentNode) {
                this.image.parentNode.removeChild(this.image);
            }
            this.ad.events.off(this.image, 'click', this.onClick);
            this.image = undefined;
        }
    };

    private onClick = (e: MouseEvent): void => {
        this.ad.tracking.click({ x: e.clientX, y: e.clientY });
    };
}
