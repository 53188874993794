import { Pipe, PipeTransform } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IBfFeed } from '@domain/feed';
import { TranslationPageService } from '../state/translation-page.service';

const NO_FEED_TEXT = '(Feed not selected)';

@Pipe({
    name: 'feedSourceName',
    standalone: true
})
export class FeedSourceNamePipe implements PipeTransform {
    feedSources: IBfFeed[];

    constructor(private translationPageService: TranslationPageService) {
        this.translationPageService.feedSources$.pipe(takeUntilDestroyed()).subscribe(feedSources => {
            this.feedSources = feedSources;
        });
    }

    transform(feedSourceId: string): string | undefined {
        return this.feedSources.find(({ id }) => id === feedSourceId)?.name ?? NO_FEED_TEXT;
    }
}
