import { IAsyncState } from '@domain/store/async';
import { createReducer, on } from '@ngrx/store';
import { ISelectableSize, ISizeCollection } from '@studio/domain/components/size-list.types';
import { DefaultCollectionsEnum, SelectedSizes } from '@studio/domain/state/size-add.types';
import { SizeAddInitActions, SizeAddSelectionActions } from './size-add.actions';
import {
    deselectAllSizes,
    getCustomSizesCollection,
    getOrphanSizesCollection,
    getSizeIdsToUpdate,
    getSocialSizes,
    getSocialSizesCollection,
    selectAllSizes
} from './size-add.utils';

export const SIZE_ADD_FEATURE_KEY = 'sizeAdd';

export interface SizeAddPartialState {
    readonly [SIZE_ADD_FEATURE_KEY]: SizeAddState;
}

export interface SizeAddState extends IAsyncState {
    sizes: ISelectableSize[];
    collections: ISizeCollection[];

    selectedSizes: SelectedSizes;
    selectedCollectionIds: ISizeCollection['id'][];
}

export const initialState: SizeAddState = {
    loaded: false,
    error: undefined,
    sizes: [],
    collections: [],
    selectedSizes: {},
    selectedCollectionIds: []
};

export const reducer = createReducer(
    initialState,

    on(SizeAddInitActions.initialize, (state, { sizes, collections }) => {
        const socialSizes = getSocialSizes();
        const socialCollection = getSocialSizesCollection();
        const customSizesCollection = getCustomSizesCollection();
        const orphanSizesCollection = getOrphanSizesCollection(sizes, collections);
        return {
            ...state,
            sizes: [...socialSizes, ...sizes],
            collections: [
                orphanSizesCollection,
                socialCollection,
                customSizesCollection,
                ...collections
            ]
        };
    }),

    on(SizeAddInitActions.reset, state => {
        return {
            ...state,
            selectedSizes: {},
            selectedCollectionIds: []
        };
    }),

    on(SizeAddSelectionActions.selectAllSizes, state => {
        const sizeIdsToUpdate = getSizeIdsToUpdate(
            state.selectedCollectionIds,
            state.collections,
            state.sizes
        );
        const newSelectedSizes = selectAllSizes(state.selectedSizes, sizeIdsToUpdate);

        return {
            ...state,
            selectedSizes: newSelectedSizes
        };
    }),

    on(SizeAddSelectionActions.deselectAllSizes, state => {
        const sizeIdsToUpdate = getSizeIdsToUpdate(
            state.selectedCollectionIds,
            state.collections,
            state.sizes
        );
        const newSelectedSizes = deselectAllSizes(state.selectedSizes, sizeIdsToUpdate);

        return {
            ...state,
            selectedSizes: newSelectedSizes
        };
    }),

    on(SizeAddSelectionActions.setSelectedCollections, (state, { selectedCollectionIds }) => {
        return {
            ...state,
            selectedCollectionIds
        };
    }),

    on(SizeAddSelectionActions.increaseSelectedSizeCount, (state, { size, byNumber }) => {
        const newNumber = Math.max(0, (state.selectedSizes[size.id]?.numberOfSizes ?? 0) + byNumber);
        return {
            ...state,
            selectedSizes: {
                ...state.selectedSizes,
                [size.id]: {
                    numberOfSizes: newNumber
                }
            }
        };
    }),

    on(SizeAddSelectionActions.addSizeToCustomSizesCollection, (state, { size }) => {
        const collections = state.collections.map(collection => {
            if (collection.id !== DefaultCollectionsEnum.CustomSizes) {
                return collection;
            }
            return {
                ...collection,
                sizeFormatIds: [...(collection.sizeFormatIds ?? []), size.id]
            };
        });
        return {
            ...state,
            sizes: [size, ...state.sizes],
            collections,
            selectedSizes: {
                ...state.selectedSizes,
                [size.id]: { numberOfSizes: 1 }
            }
        };
    }),
    on(SizeAddSelectionActions.clearSelection, state => {
        return { ...state, selectedSizes: {} };
    })
);
