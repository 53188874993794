import { Injectable, OnDestroy, inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { environment } from '../../environments/environment';

type PristineCheck = () => Promise<boolean>;
type PristineUnloadCheck = () => boolean;

@Injectable({ providedIn: 'root' })
export class NavigationGuard implements OnDestroy {
    private pristineChecks: PristineCheck[] = [];
    private preventUnloadChecks: (() => boolean)[] = [];

    constructor() {
        window.addEventListener('beforeunload', this.onBeforeUnload);
    }

    /**
     * Adds a check that will be checked before navigating.
     * If all responses is true the navigation will happen.
     * @param callback
     */
    addPristineCheck(callback: PristineCheck): void {
        this.pristineChecks.push(callback);
    }

    removePristineCheck(callback: PristineCheck): void {
        const index = this.pristineChecks.findIndex(checks => checks === callback);
        if (index === -1) {
            return;
        }
        this.pristineChecks.splice(index, 1);
    }

    /**
     * Adds a check that will be checked before refreshing / leaving page.
     * If all responses is true the navigation will happen.
     * @param callback
     */
    addPristineUnloadCheck(callback: PristineUnloadCheck): void {
        this.preventUnloadChecks.push(callback);
    }

    removePristineUnloadCheck(callback: PristineUnloadCheck): void {
        const index = this.preventUnloadChecks.findIndex(checks => checks === callback);
        if (index === -1) {
            return;
        }
        this.preventUnloadChecks.splice(index, 1);
    }

    async isPristine(): Promise<boolean> {
        let isPristine = true;
        for (const check of this.pristineChecks) {
            if (isPristine) {
                if (!(await check())) {
                    isPristine = false;
                    break;
                }
            }
        }
        if (isPristine) {
            this.preventUnloadChecks = [];
        }
        return Promise.resolve(isPristine);
    }

    async canDeactivate(): Promise<boolean> {
        return this.isPristine();
    }

    private canUnload(): boolean {
        let isPristine = true;
        for (const check of this.preventUnloadChecks) {
            if (isPristine) {
                if (!check()) {
                    isPristine = false;
                    break;
                }
            }
        }
        return isPristine;
    }

    private onBeforeUnload = (e: BeforeUnloadEvent): void => {
        if (environment.stage !== 'test' && !this.canUnload()) {
            // Cancel the event as stated by the standard.
            e.preventDefault();

            // Chrome requires returnValue to be set.
            e.returnValue = ''; // Return value '' does not display any message.
        }
    };

    ngOnDestroy(): void {
        window.removeEventListener('beforeunload', this.onBeforeUnload);
    }
}

export const navigationGuardCanDeactivate: CanDeactivateFn<Promise<boolean>> = () => {
    const navigationGuard = inject(NavigationGuard);
    return navigationGuard.canDeactivate();
};
