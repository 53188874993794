import { isTextNode } from '@creative/nodes';
import { IFontFamily, IFontFamilyStyle } from '@domain/font-families';
import { OneOfElementDataNodes } from '@domain/nodes';
import { cloneDeep } from '@studio/utils/clone';

export function getFontByStyleId(
    fontFamilies: IFontFamily[] | Readonly<IFontFamily[]>,
    fontStyleId: string
): IFontFamily | undefined {
    for (const fontFamily of fontFamilies) {
        if (fontFamily.fontStyles.find(({ id }) => id === fontStyleId)) {
            return fontFamily;
        }
    }
    throw new Error(`Could not find font style with id '${fontStyleId}'.`);
}

export function getFontStyleById(
    fontFamilies: IFontFamily[] | Readonly<IFontFamily[]>,
    fontStyleId: string
): IFontFamilyStyle | undefined {
    for (const fontFamily of fontFamilies) {
        for (const fontStyle of fontFamily.fontStyles) {
            if (fontStyle.id === fontStyleId) {
                return fontStyle;
            }
        }
    }
    throw new Error(`Could not find font style with id '${fontStyleId}'.`);
}

export function tryGetFontByStyleId(
    fontFamilies: IFontFamily[] | Readonly<IFontFamily[]>,
    fontStyleId: string
): IFontFamily | undefined {
    for (const fontFamily of fontFamilies) {
        if (fontFamily.fontStyles.find(({ id }) => id === fontStyleId)) {
            return fontFamily;
        }
    }
}

export function tryGetFontStyleById(
    fontFamilies: IFontFamily[] | Readonly<IFontFamily[]>,
    fontStyleId: string
): IFontFamilyStyle | undefined {
    for (const fontFamily of fontFamilies) {
        for (const fontStyle of fontFamily.fontStyles) {
            if (fontStyle.id === fontStyleId) {
                return fontStyle;
            }
        }
    }
}

export function getActiveFontsOnThisBrand(
    fontFamilies: IFontFamily[] | Readonly<IFontFamily[]>,
    brandId: string
): IFontFamily[] {
    return fontFamilies
        .filter(
            fontFamily =>
                !fontFamily.deletedAt &&
                (!fontFamily.visibleBrandIds || fontFamily.visibleBrandIds.includes(brandId))
        )
        .map(fontFamily => ({
            ...fontFamily,
            fontStyles: fontFamily.fontStyles.filter(fontStyle => !fontStyle.deletedAt && fontStyle)
        }));
}

export function removeDeletedFonts(fontFamilies: IFontFamily[]): IFontFamily[] {
    const filteredFontFamilies: IFontFamily[] = [];
    for (const fontFamily of fontFamilies) {
        if (fontFamily.deletedAt) {
            continue;
        }
        filteredFontFamilies.push({
            ...fontFamily,
            fontStyles: fontFamily.fontStyles.filter(fonStyle => !fonStyle.deletedAt)
        });
    }
    return filteredFontFamilies;
}

export function mergeFontFamilies(oldData: IFontFamily[], newData: IFontFamily[]): IFontFamily[] {
    const output = cloneDeep(oldData || []);
    for (const fontFamily of newData) {
        const oldFontFamily = output.find(({ id }) => id === fontFamily.id);
        if (!oldFontFamily) {
            output.push(fontFamily);
            continue;
        }
        const filteredFontStyles = fontFamily.fontStyles.filter(
            ({ id }) => !oldFontFamily.fontStyles.find(fontStyle => fontStyle.id === id)
        );
        oldFontFamily.fontStyles = [...filteredFontStyles, ...oldFontFamily.fontStyles];
    }
    return output;
}

// returns a unique set of font style ids from text elements, including character style fonts
export function getFontStyleIdsFromElements(nodes: OneOfElementDataNodes[]): string[] {
    return Array.from(
        nodes.filter(isTextNode).reduce((prev, curr) => {
            if (curr.__fontStyleId) {
                prev.add(curr.__fontStyleId);
            }
            curr.characterStyles.forEach(charStyle => {
                const charFontStyleId = charStyle.font as unknown as string | undefined; // font is an ID when we receive it
                if (charFontStyleId) {
                    prev.add(charFontStyleId);
                }
            });

            return prev;
        }, new Set<string>())
    );
}

export function sortFontFamilies(fontFamilies: IFontFamily[]): IFontFamily[] {
    return fontFamilies.sort((a, b) => a.name.localeCompare(b.name));
}
