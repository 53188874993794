import {
    IPosition,
    IBoundingBox,
    IBoundingCorners,
    IBounds,
    IPointRect,
    ISize,
    IScale,
    IOrigin
} from '@domain/dimension';
import { clamp, rotatePosition } from './utils';
import { Matrix } from './matrix';

export function getBoundingCorners(
    boundingBox: IBoundingBox,
    inset = 0,
    calculateCenter = false
): IBoundingCorners {
    const { x, y, width, height, rotationZ } = boundingBox;

    const origin: IPosition = { x: x + width / 2, y: y + height / 2 };
    let topLeft: IPosition = { x: x + inset, y: y + inset };
    let topRight: IPosition = { x: x + width - inset, y: y + inset };
    let bottomLeft: IPosition = { x: x + inset, y: y + height - inset };
    let bottomRight: IPosition = { x: x + width - inset, y: y + height - inset };

    if (typeof rotationZ !== 'undefined') {
        topLeft = rotatePosition(topLeft, origin, -rotationZ);
        topRight = rotatePosition(topRight, origin, -rotationZ);
        bottomLeft = rotatePosition(bottomLeft, origin, -rotationZ);
        bottomRight = rotatePosition(bottomRight, origin, -rotationZ);
    }

    const result: IBoundingCorners = {
        topLeft,
        topRight,
        bottomLeft,
        bottomRight
    };

    if (calculateCenter) {
        result.center = {
            x:
                (Math.min(topLeft.x, topRight.x, bottomLeft.x, bottomRight.x) +
                    Math.max(topLeft.x, topRight.x, bottomLeft.x, bottomRight.x)) /
                2,
            y:
                (Math.min(topLeft.y, topRight.y, bottomLeft.y, bottomRight.y) +
                    Math.max(topLeft.y, topRight.y, bottomLeft.y, bottomRight.y)) /
                2
        };
    }

    return result;
}

export function getBoundingRectangleOfRotatedBox(boundingBox: IBoundingBox): IBounds {
    const corners = getBoundingCorners(boundingBox);

    const minX = Math.min(
        ...[corners.topLeft.x, corners.bottomLeft.x, corners.topRight.x, corners.bottomRight.x]
    );
    const minY = Math.min(
        ...[corners.topLeft.y, corners.bottomLeft.y, corners.topRight.y, corners.bottomRight.y]
    );
    const maxX = Math.max(
        ...[corners.topLeft.x, corners.bottomLeft.x, corners.topRight.x, corners.bottomRight.x]
    );
    const maxY = Math.max(
        ...[corners.topLeft.y, corners.bottomLeft.y, corners.topRight.y, corners.bottomRight.y]
    );

    return {
        x: minX,
        y: minY,
        width: maxX - minX,
        height: maxY - minY
    };
}

export function withinImageBounds(
    fitX: number,
    fitY: number,
    imageWidth: number,
    width: number,
    imageHeight: number,
    height: number
): { fitX: number; fitY: number; percentX: number; percentY: number } {
    const maxY = Math.round(imageHeight - height);
    const minY = Math.round(-Math.abs(maxY));
    const maxX = Math.round(imageWidth - width);
    const minX = Math.round(-Math.abs(maxX));

    if (fitX > maxX) {
        fitX = maxX;
    }
    if (fitX < minX) {
        fitX = minX;
    }
    if (fitY > maxY) {
        fitY = maxY;
    }
    if (fitY < minY) {
        fitY = minY;
    }

    function getPercentage(startpos: number, endpos: number, currentpos: number): number {
        const posDistance = endpos - startpos;
        const displacement = currentpos - startpos;
        return displacement / posDistance;
    }

    return {
        fitX,
        fitY,
        percentX: getPercentage(maxX, minX, fitX),
        percentY: getPercentage(maxY, minY, fitY)
    };
}

export function imageScaleFactor(
    imageWidth: number,
    boxWidth: number,
    imageHeight: number,
    boxHeight: number
): { scale: number; width: number; height: number } {
    const scale = Math.max(boxWidth / imageWidth, boxHeight / imageHeight);
    const width = imageWidth * scale;
    const height = imageHeight * scale;

    return { scale, width, height };
}

/**
 * Get a new IBounds covering all of the IBounds provided
 * @param bounds
 */
export function getOuterBounds(...bounds: IBounds[]): IBounds {
    let left = Infinity;
    let top = Infinity;
    let right = -Infinity;
    let bottom = -Infinity;

    for (const b of bounds) {
        left = Math.min(b.x, left);
        top = Math.min(b.y, top);
        right = Math.max(b.x + b.width, right);
        bottom = Math.max(b.y + b.height, bottom);
    }
    return {
        x: left,
        y: top,
        width: right - left,
        height: bottom - top
    };
}

export function getBoundsOfScaledRectangle(rect: IBoundingBox & IScale & IOrigin): IBoundingBox {
    const originX = 'originX' in rect && rect.originX !== undefined ? rect.originX : 0.5;
    const originY = 'originY' in rect && rect.originY !== undefined ? rect.originY : 0.5;
    const scaleX = rect?.scaleX !== undefined ? Math.abs(rect.scaleX) : 1;
    const scaleY = rect?.scaleY !== undefined ? Math.abs(rect.scaleY) : 1;

    return {
        x: rect.x - rect.width * (scaleX - 1) * originX,
        y: rect.y - rect.height * (scaleY - 1) * originY,
        width: rect.width * scaleX,
        height: rect.height * scaleY,
        rotationZ: rect.rotationZ
    };
}

const compareSize = (a: ISize, b: ISize): boolean => a.width === b.width && a.height === b.height;

const comparePosition = (a: IPosition, b: IPosition): boolean => a.x === b.x && a.y === b.y;

const compareRotation = (a: IBoundingBox, b: IBoundingBox): boolean => a.rotationZ === b.rotationZ;

/**
 * Check if all boxes provided have the same size, position and rotation as the first box
 * @param comparativeBox a box object that should be compared with
 * @param boxes the boxes that should be compared with the comparativeBox
 */
export function isSameBoundsAndRotation(
    comparativeBox: IBoundingBox,
    ...boxes: IBoundingBox[]
): boolean {
    return boxes.every(
        box =>
            compareSize(comparativeBox, box) &&
            comparePosition(comparativeBox, box) &&
            compareRotation(comparativeBox, box)
    );
}
/**
 * Check if all bounds provided have the same size and position as the first bounds
 * @param comparativeBound a bound object that should be compared with
 * @param bounds the bounds that should be compared with the comparativeBound
 */
export function isSameBounds(comparativeBound: IBounds, ...bounds: IBounds[]): boolean {
    return bounds.every(
        bound => compareSize(comparativeBound, bound) && comparePosition(comparativeBound, bound)
    );
}

export function isSameSize(comparativeSize: ISize, ...sizes: ISize[]): boolean {
    return sizes.every(size => compareSize(comparativeSize, size));
}

export function isSamePosition(comparativePosition: IPosition, ...positions: IPosition[]): boolean {
    return positions.every(position => comparePosition(comparativePosition, position));
}

/**
 * Get distance between two points as a vector (x, y).
 * @param from
 * @param to
 * @param absolute
 */
export function distance2D(from: IPosition, to: IPosition, absolute?: boolean): IPosition {
    const abs = absolute ? Math.abs : (n: number): number => n;

    return {
        x: abs(to.x - from.x),
        y: abs(to.y - from.y)
    };
}

/**
 * Cypress and old browsers doesn't support event.x so fallback on clientX
 */
export function eventToPosition(event: MouseEvent): IPosition {
    return {
        x: typeof event.x === 'number' ? event.x : event.clientX,
        y: typeof event.y === 'number' ? event.y : event.clientY
    };
}

/**
 * Get the diagonal by using pythagoras.
 * @param width
 * @param height
 */
export function diagonal(width: number, height: number): number {
    return Math.sqrt(Math.pow(width, 2) + Math.pow(height, 2));
}

/**
 * Get distance between two points as a positive number
 * @param from
 * @param to
 */
export function distance(from: IPosition, to: IPosition): number {
    const dist = distance2D(from, to);
    return diagonal(dist.x, dist.y);
}

export function normalizeAngleDegrees(degrees: number): number {
    degrees = ((degrees % 360) + 360) % 360;

    // force into the minimum absolute value residue class, so that -180 < angle <= 180
    if (degrees > 180) {
        degrees -= 360;
    }
    return degrees;
}

export function getCenter(bounds: ISize | IBounds): IPosition {
    const box = sizeToBounds(bounds);

    return {
        x: box.x + box.width / 2,
        y: box.y + box.height / 2
    };
}

/**
 * Angle between positions in radians
 * @param from
 * @param to
 */
export function pointsToAngle(from: IPosition, to: IPosition): number {
    return Math.atan2(to.y - from.y, to.x - from.x);
}

/**
 * Get the intersecting rectangle of two rectangles
 * @param r1
 * @param r2
 */
export function getIntersectingRectangle(
    rect1: IBounds | ISize,
    rect2: IBounds | ISize
): IBounds | undefined {
    const r1 = boundsToPointRect(sizeToBounds(rect1));
    const r2 = boundsToPointRect(sizeToBounds(rect2));

    const noIntersect = r2.x1 > r1.x2 || r2.x2 < r1.x1 || r2.y1 > r1.y2 || r2.y2 < r1.y1;

    return noIntersect
        ? undefined
        : pointRectToBounds({
              x1: Math.max(r1.x1, r2.x1), // _[0] is the lesser,
              y1: Math.max(r1.y1, r2.y1), // _[1] is the greater
              x2: Math.min(r1.x2, r2.x2),
              y2: Math.min(r1.y2, r2.y2)
          });
}

export function coversHorizontally(
    element: IBounds | ISize,
    container: IBounds | ISize,
    tolerance = 1
): boolean {
    const intersection = getIntersectingRectangle(element, container);
    return (
        !!intersection &&
        Math.abs(intersection.width - container.width) <= tolerance * 2 &&
        intersection.x <= tolerance
    );
}

export function coversVertically(
    element: IBounds | ISize,
    container: IBounds | ISize,
    tolerance = 1
): boolean {
    const intersection = getIntersectingRectangle(element, container);
    return (
        !!intersection &&
        Math.abs(intersection.height - container.height) <= tolerance * 2 &&
        intersection.y <= tolerance
    );
}

/**
 * How much much of an element covers the container (from 0 to 1)
 * @param element
 * @param container
 */
export function intersectionFactor(element: IBounds | ISize, container: IBounds | ISize): number {
    const elementBounds = sizeToBounds(element);
    const containerBounds = sizeToBounds(container);
    const intersectionRect = getIntersectingRectangle(elementBounds, containerBounds);
    if (intersectionRect) {
        return areaRelation(intersectionRect, element);
    }
    return 0;
}

export function sizeToBounds(size: IBounds | ISize): IBounds {
    return {
        x: 0,
        y: 0,
        ...size
    };
}

/**
 * Get relation between area of two object. >1 means that the first object is bigger
 * @param size
 * @param reference
 */
export function areaRelation(size: ISize, reference: ISize): number {
    return (size.width * size.height) / (reference.width * reference.height);
}

export function isHorizontallyCentered(element: IBounds, container: IBounds, tolerance = 0.5): boolean {
    return Math.abs(element.x + element.width / 2 - (container.x + container.width / 2)) <= tolerance;
}

export function isVerticallyCentered(element: IBounds, container: IBounds, tolerance = 0.5): boolean {
    return Math.abs(element.y + element.height / 2 - (container.y + container.height / 2)) <= tolerance;
}

export function boundsToPointRect(bounds: IBounds): IPointRect {
    return {
        x1: bounds.x,
        y1: bounds.y,
        x2: bounds.x + bounds.width,
        y2: bounds.y + bounds.height
    };
}

export function pointRectToBounds(rect: IPointRect): IBounds {
    return {
        x: Math.min(rect.x1, rect.x2),
        y: Math.min(rect.y1, rect.y2),
        width: Math.abs(rect.x1 - rect.x2),
        height: Math.abs(rect.y1 - rect.y2)
    };
}

export function scaleRectWithinBounds(element: IBoundingBox, bounds: IBounds): IBoundingBox {
    if (!element.rotationZ) {
        return { ...bounds };
    }

    const originalRotatedBoundingBox = getBoundingRectangleOfRotatedBox(element);

    const scaleX = bounds.width / originalRotatedBoundingBox.width;
    const scaleY = bounds.height / originalRotatedBoundingBox.height;
    const center = getCenter(bounds);
    const matrix = new Matrix();

    // 1. Move the 1x1 "pixel" to the center of new bounding box
    matrix.translate_m(center.x, center.y);
    // 2. Set width and height to the original element size
    matrix.scale_m(element.width, element.height);
    // 3. Rotate original
    matrix.rotateZ_m(element.rotationZ);
    // 4. Scale rotated element to the size of new bounding box
    matrix.scale_m(scaleX, scaleY);
    // 5. Rotate back to get the right x & y
    matrix.rotateZ_m(-element.rotationZ);

    // TODO: Also return skew to be fully accurate
    return {
        x: matrix.getTranslateX_m() - matrix.getScaleX_m() / 2,
        y: matrix.getTranslateY_m() - matrix.getScaleY_m() / 2,
        width: matrix.getScaleX_m(),
        height: matrix.getScaleY_m(),
        rotationZ: element.rotationZ
    };
}

export function aspectRatioScale(
    element: ISize | IBounds,
    bounds: ISize | IBounds,
    fit: 'cover' | 'contain' = 'contain',
    rounding?: NumberRoundingFn
): IBounds {
    const scaleFn = fit === 'cover' ? Math.max : Math.min;
    const roundFn = getRoundingFn(rounding);
    const scale = scaleFn(bounds.width / element.width, bounds.height / element.height);
    const width = roundFn(element.width * scale);
    const height = roundFn(element.height * scale);

    // Return with centered position
    return alignWithinBounds({ width, height }, bounds, 'center', 'center', rounding);
}

export function alignWithinBounds(
    element: ISize,
    bounds: ISize | IBounds,
    horizontalAlign: HorizontalAlign = 'center',
    verticalAlign: VerticalAlign = 'center',
    rounding?: NumberRoundingFn
): IBounds {
    const roundFn = getRoundingFn(rounding);
    const x = roundFn(alignHorizontallyWithinBounds(element.width, bounds, horizontalAlign));
    const y = roundFn(alignVerticallyWithinBounds(element.height, bounds, verticalAlign));
    return { x, y, width: element.width, height: element.height };
}

export function alignHorizontallyWithinBounds(
    width: number,
    bounds: { x?: number; width: number },
    horizontalAlign: HorizontalAlign = 'center'
): number {
    const alignX = alignToNumber(horizontalAlign);
    return (bounds.x || 0) - (width - bounds.width) * alignX;
}

export function alignVerticallyWithinBounds(
    height: number,
    bounds: { y?: number; height: number },
    verticalAlign: VerticalAlign = 'center'
): number {
    const alignY = alignToNumber(verticalAlign);
    return (bounds.y || 0) - (height - bounds.height) * alignY;
}

export function roundBounds(bounds: IBounds, rounding: NumberRoundingFn = 'round'): IBounds {
    return {
        ...bounds,
        ...roundPosition(bounds, rounding),
        ...roundSize(bounds, rounding)
    };
}

export function roundSize(bounds: ISize, rounding: NumberRoundingFn = 'round'): ISize {
    const roundFn = getRoundingFn(rounding);
    const { width, height } = bounds;
    return {
        width: typeof width === 'number' ? roundFn(width) : width,
        height: typeof height === 'number' ? roundFn(height) : height
    };
}

export function roundPosition(bounds: IPosition, rounding: NumberRoundingFn = 'round'): IPosition {
    const roundFn = getRoundingFn(rounding);
    const { x, y } = bounds;
    return {
        x: typeof x === 'number' ? roundFn(x) : x,
        y: typeof y === 'number' ? roundFn(y) : y
    };
}

function getRoundingFn(rounding?: NumberRoundingFn): (num: number) => number {
    return rounding && rounding !== 'none' ? Math[rounding] : (num: number): number => num;
}

/**
 * Converts an alignment to a number between 0 and 1
 * @param align
 */
export function alignToNumber(align: HorizontalAlign | VerticalAlign): number {
    if (typeof align === 'number') {
        return clamp(align, 0, 1);
    }
    switch (align) {
        case 'center':
            return 0.5;
        case 'left':
        case 'top':
            return 0;
        case 'right':
        case 'bottom':
            return 1;
    }
    throw new Error(`Could not convert align '${align}' to number`);
}

export function getRatio(width: number, height: number): number {
    if (width === 0 && height === 0) {
        return 1;
    }
    if (height === 0) {
        return Number.MAX_VALUE;
    }
    return width / height;
}

export function getBoundingboxWithinBoundary(
    box: ISize,
    maxBoundaries: ISize,
    position?: IPosition
): IBoundingBox {
    let ratio = 1;
    if (box.width > maxBoundaries.width) {
        ratio = getRatio(maxBoundaries.width, box.width);
    }

    if (box.height * ratio > maxBoundaries.height) {
        ratio = getRatio(maxBoundaries.height, box.height);
    }

    const width = box.width * ratio;
    const height = box.height * ratio;
    const centerX = width / 2;
    const centerY = height / 2;
    const centerBoundaryX = maxBoundaries.width / 2;
    const centerBoundaryY = maxBoundaries.height / 2;

    const x = position ? position.x - centerX : centerBoundaryX - width / 2;

    const y = position ? position.y - centerY : centerBoundaryY - height / 2;

    return {
        x,
        y,
        width,
        height
    };
}

export function getAspectRatio(width: number, height: number): string {
    function gcd(a: number, b: number): number {
        return b === 0 ? a : gcd(b, a % b);
    }
    const r = gcd(width, height);
    const w = width / r;
    const h = height / r;
    return `${w}:${h}`;
}

export type HorizontalAlign = 'left' | 'center' | 'right' | number;
export type VerticalAlign = 'top' | 'center' | 'bottom' | number;
type NumberRoundingFn = 'ceil' | 'floor' | 'round' | 'none';
