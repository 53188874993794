import { IDimension, IPosition, ISize } from '@domain/dimension';
import {
    getPSDErrorMessage,
    IPSDElement,
    PSDElement,
    PSDElementData,
    PSDElementGroupData,
    PSDElementLayerData,
    PSDElementRootData,
    PSDElementTextData,
    PSDElementType,
    PSDElementVectorData,
    PSDErrorType,
    PSDGroupElement,
    PSDLayerElement,
    PSDRootElement,
    PSDStyleRun,
    TextFontCaps
} from '@studio/domain/components/psd-import/psd';
import { uint8ArrayToBase64 } from '@studio/utils/encoding';
import { uuidv4 } from '@studio/utils/id';
import { Color, Grayscale, LinkedFile, RGB, RGBA } from 'ag-psd/dist-es';
import { Layer, LayerTextData, Psd } from 'ag-psd/dist/psd';
import { PSDConversionError } from '../conversion-errors';

export function createBlueprintFromPsd(psd: Psd): PSDRootElement {
    const rootElement = parsePSDLayer(psd, psd.linkedFiles) as PSDRootElement;
    if (!rootElement.data.children) {
        throw new PSDConversionError(
            getPSDErrorMessage(PSDErrorType.CannotReadElements),
            PSDErrorType.CannotReadElements
        );
    }
    rootElement.data.flatChildren = getLayersFromBlueprint(rootElement.data.children).reverse();
    return rootElement;
}

function getLayersFromBlueprint(elements: PSDElement[]): PSDElement[] {
    const layers: PSDElement[] = [];
    elements.forEach((element: PSDElement) => {
        if (isPSDGroupElement(element)) {
            setHiddenStatus(element);
            layers.push(...getLayersFromBlueprint(element.data.children!));
        }
        layers.push(element);
    });
    return layers;
}

function setHiddenStatus(element: PSDGroupElement): void {
    if (element.hidden && element.data.children) {
        element?.data.children.forEach((child: PSDElement) => {
            child.hidden = true;
        });
    }
}

function getLayerPosition(layer: Layer): IPosition {
    return {
        x: layer.left ?? 0,
        y: layer.top ?? 0
    };
}

function getLayerSize(layer: Layer): ISize {
    if (isPSDRoot(layer)) {
        return {
            width: layer.width,
            height: layer.height
        };
    } else {
        return {
            width: (layer.right ?? 0) - (layer.left ?? 0),
            height: (layer.bottom ?? 0) - (layer.top ?? 0)
        };
    }
}

function getLayerOpacity(layer: Layer): number {
    return layer.opacity ? +layer.opacity.toPrecision(2) : 1;
}

export function parsePSDLayer(layer: Layer | Psd, linkedFiles?: LinkedFile[]): PSDElement {
    console.debug(`Parsing layer: ${layer.name}`);
    let data: PSDElementData = {};
    let position = getLayerPosition(layer);
    let size = getLayerSize(layer);
    const opacity = getLayerOpacity(layer);
    const type = getLayerType(layer);

    let error: PSDConversionError | undefined;
    try {
        switch (type) {
            case PSDElementType.Vector: {
                const { position: newPosition, size: newSize } = getVectorDimensions(layer, position);
                position = newPosition;
                size = newSize;
                data = getVectorData(layer);
                break;
            }
            case PSDElementType.Text:
                data = getTextData(layer);
                break;
            case PSDElementType.Layer:
                data = getLayerData(layer, linkedFiles);
                break;
            case PSDElementType.Group:
                data = getGroupData(layer);
                break;
        }
    } catch (e: unknown) {
        error = e as PSDConversionError;
    }

    if (isPSDRoot(layer) || type === PSDElementType.Group) {
        (data as PSDElementRootData).children = layer.children?.map(child =>
            parsePSDLayer(child, linkedFiles)
        );
    }

    const thumbnail = getLayerThumbnail(type, layer, data);

    return {
        id: uuidv4(),
        type,
        name: isPSDRoot(layer) ? 'Root' : layer.name || '',
        position,
        size,
        thumbnail,
        hidden: isPSDRoot(layer) ? true : layer.hidden,
        opacity,
        data,
        error
    } as PSDElement;
}

function getSVGLayerData(layer: Layer, linkedFiles?: LinkedFile[]): string | undefined {
    const linkedFileId = layer.placedLayer?.id;
    if (linkedFileId && linkedFiles) {
        const file = linkedFiles.find(({ id }) => id === linkedFileId);
        if (file?.name.endsWith('.svg') && file?.data) {
            return `data:image/svg+xml;base64,${uint8ArrayToBase64(file.data)}`;
        }
    }
}

function getLayerData(layer: Layer, linkedFiles?: LinkedFile[]): PSDElementLayerData {
    if (!layer.canvas) {
        throw new PSDConversionError('Failed to extract image data from layer');
    }

    let url = getSVGLayerData(layer, linkedFiles);
    if (!url) {
        url = layer.canvas.toDataURL('image/png');
    }
    if (isImageSizeTooBig(url)) {
        throw new PSDConversionError('Image can not be larger than 6MB');
    }

    return {
        url
    };
}

function getTextData(layer: Layer): PSDElementTextData {
    const textData = layer.text;

    if (!textData || isTextLayerEmpty(textData.text)) {
        throw new PSDConversionError('Text layer does not contain any text');
    }

    const fontSize = getTextLayerFontSize(textData);
    const lineHeight = getTextLayerLineHeight(textData, fontSize);

    let justification = textData.paragraphStyle?.justification;

    if (justification !== 'left' && justification !== 'center' && justification !== 'right') {
        justification = 'center';
    }

    return {
        color: textData.style?.fillColor,
        text: textData.text,
        font: textData.style?.font?.name,
        fontSize,
        justification,
        lineHeight,
        styleRuns: textData.styleRuns,
        strikethrough: textData.style?.strikethrough,
        underline: textData.style?.underline,
        uppercase: textData.style?.fontCaps === TextFontCaps.AllCaps,
        characterSpacing:
            typeof textData.style?.tracking !== 'undefined' ? textData.style.tracking / 1000 : undefined
    };
}

function getTextLayerFontSize(textData: LayerTextData): number | undefined {
    const fontSize = textData.style?.fontSize ?? getFontSizeFromPSDRuns(textData.styleRuns);
    // transform -- a 2d transform matrix [xx, xy, yx, yy, tx, ty]
    // https://github.com/Agamnentzar/ag-psd/blob/623d17437596cc9f5df077e7270b74371f2eaee4/src/additionalInfo.ts#L505C47-L505C47
    const transform = textData.transform;
    if (!transform) {
        return fontSize;
    }
    const maxTransform = Math.max(transform[0], transform[3]);
    const textFontSize = fontSize ?? 1;
    return textFontSize * maxTransform;
}

function getTextLayerLineHeight(
    textData: LayerTextData,
    fontSize: number | undefined
): number | undefined {
    const lineHeight = textData.style?.leading;
    if (!lineHeight) {
        return;
    }
    const textFontSize = fontSize ?? 1;

    return lineHeight / textFontSize;
}

function getFontSizeFromPSDRuns(styleRuns: PSDStyleRun[] | undefined): number | undefined {
    if (!styleRuns) {
        return;
    }
    return styleRuns.reduce((size, run) => Math.max(size, run.style?.fontSize ?? 0), 0);
}

function getGroupData(layer: Layer): PSDElementGroupData {
    return {
        opened: !!layer.opened,
        children: []
    };
}

function getVectorDimensions(layer: Layer, position: IPosition): IDimension {
    if (!layer.vectorOrigination) {
        throw new PSDConversionError(
            'Trying to extract vector dimensions from layer, but "vectorOrigination" not found'
        );
    }

    const boundingBox = layer.vectorOrigination.keyDescriptorList[0].keyOriginShapeBoundingBox;
    if (!boundingBox) {
        throw new PSDConversionError('Could not get bounding box of vector');
    }

    const { top, left, right, bottom } = boundingBox;

    return {
        position: {
            y: top.value,
            x: left.value
        },
        size: {
            width: right.value - position.x,
            height: bottom.value - position.y
        }
    };
}

function getVectorData(layer: Layer): PSDElementVectorData {
    const data: PSDElementVectorData = {};
    if (layer.vectorFill?.type === 'color') {
        data.color = layer.vectorFill.color;
    }

    return data;
}

function getLayerType(layer: Layer): PSDElementType {
    if (isPSDRoot(layer)) {
        return PSDElementType.Root;
    } else if (layer.text) {
        return PSDElementType.Text;
    } else if (layer.opened !== undefined) {
        return PSDElementType.Group;
    } else if (layer.vectorOrigination?.keyDescriptorList[0].keyOriginShapeBoundingBox) {
        return PSDElementType.Layer; // treat vectors as Layer type for now
    } else if (layer.canvas) {
        return PSDElementType.Layer;
    }
    return PSDElementType.Unknown;
}

function getLayerThumbnail(
    type: PSDElementType,
    layer: Layer,
    data: PSDElementData
): string | undefined {
    if (!layer.canvas) {
        return;
    }
    if (type === PSDElementType.Layer && (data as PSDElementLayerData).url) {
        return (data as PSDElementLayerData).url;
    }
    return layer.canvas.toDataURL('image/png');
}

export function isPSDLayerElement(layer: IPSDElement): layer is PSDLayerElement {
    return layer.type === PSDElementType.Layer;
}

export function isPSDGroupElement(layer: IPSDElement): layer is PSDGroupElement {
    return layer.type === PSDElementType.Group;
}

export function isPSDRoot(layer: Layer | Psd): layer is Psd {
    return (
        !!(layer as Psd).artboards || !!(layer as Psd).linkedFiles || !!(layer as Psd).imageResources
    );
}

export function isImageSizeTooBig(base64: string): boolean {
    const MAX_IMAGE_SIZE = 6291456; // 6MB
    return base64.length > MAX_IMAGE_SIZE;
}

export function isTextLayerEmpty(text: string): boolean {
    return text.trim().length === 0;
}

export function isRGBAPSDColor(color: Color): color is RGBA {
    return 'r' in color && 'g' in color && 'b' in color && 'a' in color;
}

export function isRGBPSDColor(color: Color): color is RGB {
    return 'r' in color && 'g' in color && 'b' in color && !('a' in color);
}

export function isGrayscalePSDColor(color: Color): color is Grayscale {
    return 'k' in color && !('c' in color);
}
