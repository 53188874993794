import { Pipe, PipeTransform } from '@angular/core';
import { IElement } from '@domain/creativeset/element';
import { Icon } from '@bannerflow/ui/components/icon/svg-icon/icons';
import { ElementKind } from '@domain/elements';

@Pipe({
    name: 'iconForElement',
    standalone: true
})
export class IconForElementPipe implements PipeTransform {
    transform(element: IElement): Icon | 'none' {
        switch (element.type) {
            case ElementKind.Text:
                return 'text';
            case ElementKind.Button:
                return 'button';
            case ElementKind.Widget:
            case ElementKind.BannerflowLibraryWidget:
            case ElementKind.BannerflowLibraryWidgetInstance:
                return 'widget';
            default:
                return 'none';
        }
    }
}
