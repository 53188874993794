<div class="wrapper">
    @let image = currentImage();
    @let underlay = underlaySize();
    <div
        class="underlay"
        [style.width]="underlay.width + 'px'"
        [style.height]="underlay.height + 'px'"
        [style.left]="-underlay.left + 'px'"
        [style.top]="-underlay.up + 'px'"></div>
    @if (image) {
        <img
            [src]="image"
            alt="original"
            [width]="size().width"
            [height]="size().height" />
    }
</div>
