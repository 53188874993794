import { gql } from './gql.types';

export type DeleteDesignsResult = {
    deleteDesigns: {
        ids: string[];
    };
};

export const DELETE_DESIGNS = gql<DeleteDesignsResult, { designIds: string[]; creativesetId: string }>`
    mutation DeleteDesignsMutation($designIds: [String]!, $creativesetId: String!) {
        deleteDesigns(designIds: $designIds, creativesetId: $creativesetId) {
            ids
        }
    }
`;
