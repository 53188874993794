<p class="text">This creative is currently opened in another tab or by another user(s):</p>

<ui-list [dataSource]="userList">
    <ui-list-column
        name="User"
        property="name"
        [sortable]="true">
        <ng-template
            let-user="data"
            ui-list-cell-template>
            <div
                class="user__name"
                [class.inactive]="!user.isActive">
                <user-icon
                    class="user__name__icon"
                    [class.inactive]="!user.isActive"
                    [color]="user.color"
                    [shortName]="user.shortName"></user-icon>
                {{ user.name }}
            </div>
        </ng-template>
    </ui-list-column>

    <ui-list-column
        name="Activity"
        property="name"
        [sortable]="true">
        <ng-template
            let-user="data"
            ui-list-cell-template>
            <div
                class="user__activity"
                [class.inactive]="!user.isActive">
                <div *ngIf="user.isActive; else inactive">Active less than 1h ago</div>
                <ng-template #inactive> Inactive </ng-template>
            </div>
        </ng-template>
    </ui-list-column>
</ui-list>

<p class="text">
    Multiple users working in the same creative produces the risk of saving over each others work.
</p>

<ui-dialog-buttons>
    <ui-button
        text="Got it"
        type="primary"
        (click)="close()"></ui-button>
</ui-dialog-buttons>
