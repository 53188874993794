import { IAd } from '@domain/ad/ad';
import { TCData } from '@domain/ad/tcf';
import { ISubscription } from '@domain/async';
import { CreativeMode, ICreativeEnvironment } from '@domain/creative/environment';
import { IWidgetElementDataNode, IWidgetViewElement } from '@domain/widget';
import { WidgetEvent } from '@domain/widget-events';
import { __container, __inject, Container, DIContainer, inject } from '@studio/utils/di';
import { Animator } from '../../animator';
import { T } from '../../creative.container';
import { IRenderer } from '../../renderer.header';
import { AdApi } from './ad-api';
import { CreativeApi } from './creative-api';
import { TimelineApi } from './timeline-api';
import { Widget } from './widget';
import { IWidgetInstance, IWidgetRenderer } from './widget-renderer.header';

export class WidgetRenderer implements IWidgetRenderer {
    widgets_m: IWidgetInstance[] = [];
    widgetInitializingPromises: Promise<void>[] = [];
    /** Used to wait for async resources that the widget(s) explicitly need */
    widgetLoadingPromises: Promise<unknown>[] = [];
    creativeApi: CreativeApi;
    timelineApi_m: TimelineApi;
    adApi_m: AdApi;
    private _tcDataSubscriptions: ISubscription<TCData>[] = [];

    constructor(
        @DIContainer() private _container: Container<T>,
        @inject(T.AD) public ad: IAd,
        @inject(T.RENDERER) public renderer: IRenderer,
        @inject(T.ANIMATOR) private animator: Animator,
        @inject(T.ENVIRONMENT) private _env: ICreativeEnvironment
    ) {
        this.creativeApi = this._container.resolve(T.CREATIVE_API);
        this.creativeApi.widgetRenderer_m = this;
        this.timelineApi_m = this._container.resolve(T.TIMELINE_API);
        this.adApi_m = this._container.resolve(T.AD_API);
    }

    // Called from renderer
    async createWidget(
        elementNode: IWidgetViewElement,
        dataNode: IWidgetElementDataNode
    ): Promise<void> {
        const widget = new Widget(elementNode, dataNode, this, this._env);
        this.widgets_m.push({ dataNode, instance: widget });
        this.widgetInitializingPromises.push(widget.isInitialized_m);
        const tcDataSubscription = this.ad.tcDataSubject.subscribe(async tcData => {
            await widget.isInitialized_m;
            widget.emit(WidgetEvent.TCData, tcData);
        });
        this._tcDataSubscriptions.push(tcDataSubscription);
        await widget.isInitialized_m;

        if (this._env.MODE === CreativeMode.DesignView) {
            let originalMouseInteraction: boolean;
            const originalCursor = widget.cursor;
            widget.cursor = '';

            this.animator.on('play', () => {
                if (typeof originalMouseInteraction === 'undefined') {
                    originalMouseInteraction = widget.mouseInteractions;
                }
                this.creativeApi.environment = 'creative';
                widget.mouseInteractions = originalMouseInteraction;
                widget.cursor = originalCursor;
            });
            this.animator.on('pause', () => {
                this.creativeApi.environment = 'design-view';
                widget.mouseInteractions = false;
                widget.cursor = '';
            });
        }

        this.creativeApi.bind();
    }

    destroy(preserveDomListeners = false): void {
        this.timelineApi_m.destroy();
        this.creativeApi.destroy(preserveDomListeners);
        while (this.widgets_m.length) {
            this.widgets_m.pop()!.instance.destroy();
        }
        for (const subscription of this._tcDataSubscriptions) {
            subscription.unsubscribe();
        }
        this.widgetInitializingPromises = [];
        this.widgetLoadingPromises = [];
    }

    getWidgetInstanceFromIframeId(iframeId: string): IWidgetInstance | undefined {
        return this.widgets_m.find(widget => iframeId.includes(widget.instance.id));
    }
}

__container(WidgetRenderer, '_container', 0);
__inject(T.AD, {}, WidgetRenderer, 'ad', 1);
__inject(T.RENDERER, {}, WidgetRenderer, 'renderer', 2);
__inject(T.ANIMATOR, {}, WidgetRenderer, 'animator', 3);
__inject(T.ENVIRONMENT, {}, WidgetRenderer, '_env', 4);
