<div
    class="dropdown-trigger"
    #menuTrigger="uiDropdownTarget"
    [uiDropdownTarget]="menu"
    [hasBackdrop]="false"
    (dropdownClosed)="contextMenu.contextMenuClosed()"></div>

<ui-dropdown
    #menu
    type="menu">
    <ui-dropdown-item
        id="add-keyframe"
        class="custom-dropdown"
        [disabled]="!canAddKeyframe"
        (click)="addKeyframe()">
        <div class="custom-item">
            <div class="custom-column"></div>
            <div class="custom-text">Add keyframe</div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-item
        id="add-keyframe"
        class="custom-dropdown"
        [disabled]="!canAddKeyframe"
        (click)="addKeyframe(false)">
        <div class="custom-item">
            <div class="custom-column"></div>
            <div class="custom-text">Add empty keyframe</div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-item
        class="custom-dropdown"
        [disabled]="(!keyframesSelected && !hasSelectedAnimation) || isTransitionAnimation"
        (click)="deleteKeyframes()">
        <div class="custom-item">
            <div class="custom-column"></div>
            <div class="custom-text">Delete {{ keyframesSelected > 1 ? 'keyframes' : 'keyframe' }}</div>
            <div [class]="contextMenu.isMac ? 'mac custom-action' : 'custom-action'">
                {{ keyboardShortcuts.Editor.DeleteElement }}
            </div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-item
        class="custom-dropdown"
        [disabled]="(!keyframesSelected && !hasSelectedAnimation) || isTransitionAnimation"
        (click)="copyKeyframes()">
        <div class="custom-item">
            <div class="custom-column"></div>
            <div class="custom-text">Copy {{ keyframesSelected > 1 ? 'keyframes' : 'keyframe' }}</div>
            <div [class]="contextMenu.isMac ? 'mac custom-action' : 'custom-action'">
                {{ keyboardShortcuts.Editor.Copy }}
            </div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-item
        class="custom-dropdown"
        [disabled]="!copiedKeyframeSelection || isTransitionAnimation"
        (click)="pasteKeyframes()">
        <div class="custom-item">
            <div class="custom-column"></div>
            <div class="custom-text">
                Paste {{ copiedKeyframeSelection > 1 ? 'keyframes' : 'keyframe' }}
            </div>
            <div [class]="contextMenu.isMac ? 'mac custom-action' : 'custom-action'">
                {{ keyboardShortcuts.Editor.Paste }}
            </div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-divider></ui-dropdown-divider>
    <ui-dropdown-item
        class="custom-dropdown"
        [disabled]="!elementCanDistributeKeyframes"
        (click)="distributeKeyframes()">
        <div class="custom-item">
            <div class="custom-column"></div>
            <div class="custom-text">
                Distribute {{ keyframesSelected >= 3 ? 'selected' : 'all' }} keyframes
            </div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-divider></ui-dropdown-divider>
    <ui-dropdown-item
        class="custom-dropdown"
        [disabled]="elementHasAnimation"
        (click)="addAnimation()">
        <div class="custom-item">
            <div class="custom-column"></div>
            <div class="custom-text">Add animation</div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-item
        class="custom-dropdown"
        (click)="deleteAnimation()">
        <div class="custom-item">
            <div class="custom-column"></div>
            <div class="custom-text">Delete animation</div>
            <div [class]="contextMenu.isMac ? 'mac custom-action' : 'custom-action'">
                {{ keyboardShortcuts.Editor.DeleteElement }}
            </div>
        </div>
    </ui-dropdown-item>
</ui-dropdown>
