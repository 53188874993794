@if (features.length && isEnabled) {
    <ui-select
        placeholder="No feature selected"
        [useTargetWidth]="true"
        width="100"
        [multiSelect]="true"
        [selected]="activatedFeatures"
        (selectedChange)="toggleFeature($event)">
        @for (feature of features; track feature.name) {
            <ui-option
                [selected]="feature.active"
                [value]="feature.name">
                {{ feature.name }}
            </ui-option>
        }
    </ui-select>
}
