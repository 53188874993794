import { IFontFamily } from '@domain/font-families';
import { createAction, createActionGroup, props } from '@ngrx/store';
import {
    ExternalFontFamilyDto,
    ExternalFontResponse,
    ExternalImportResponse
} from '@studio/domain/api/font-manager.types';

export const loadFontFamiliesOfBrand = createAction('[FontFamilies] Load FontFamiliesOfBrand');

export const loadFontFamiliesOfBrandSuccess = createAction(
    '[FontFamilies] Load FontFamiliesOfBrand Success',
    props<{ fontFamilies: IFontFamily[] }>()
);

export const loadFontFamiliesOfBrandFailure = createAction(
    '[FontFamilies] Load FontFamiliesOfBrand Failure',
    props<{ error: unknown }>()
);

export const searchExternalFonts = createAction(
    '[FontFamilies] Search for external fonts',
    props<{ searchTerm: string }>()
);
export const searchExternalFontsSuccess = createAction(
    '[FontFamilies] Search for external fonts success',
    props<{ response: ExternalFontResponse }>()
);
export const searchExternalFontsFailure = createAction(
    '[FontFamilies] Search for external fonts failure',
    props<{ error: unknown }>()
);

export const importExternalFonts = createAction(
    '[FontFamilies] Import external fonts',
    props<{ externalFontFamilies: ExternalFontFamilyDto[] }>()
);
export const importExternalFontsSuccess = createAction(
    '[FontFamilies] Import external fonts Success',
    props<{ response: ExternalImportResponse }>()
);
export const importExternalFontsFailure = createAction(
    '[FontFamilies] Import external fonts failure',
    props<{ error: unknown }>()
);

export const setFontFamiliesOfCreativeSet = createAction(
    '[FontFamilies] Set FontFamiliesOfCreativeSet',
    props<{ fontFamilies: IFontFamily[] }>()
);

export const addFontFamilies = createAction(
    '[FontFamilies] Add font families',
    props<{ fontFamilies: IFontFamily[] }>()
);

export const resetExternalFonts = createAction('[FontFamilies] Reset ExternalFonts data');
export const resetImportedFonts = createAction('[FontFamilies] Reset ImportedFonts data');

export const fontFamiliesShowcaseCreativeSetActions = createActionGroup({
    source: 'FontFamiliesShowcase',
    events: {
        'load showcase success': props<{
            fonts: IFontFamily[];
        }>()
    }
});
