import { Injectable, OnDestroy } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Logger } from '@bannerflow/sentinel-logger';
import { UIConfirmDialogService } from '@bannerflow/ui';
import { isWidgetNode } from '@creative/nodes';
import { IElement } from '@domain/creativeset';
import { IDesign } from '@domain/creativeset/design';
import { IVersion, IVersionProperty } from '@domain/creativeset/version';
import { IWidgetElementDataNode } from '@domain/widget';
import {
    EventLoggerService,
    InvalidDesignEvent,
    RelationValidationEvent
} from '@studio/monitoring/events';
import { formatTime } from '@studio/utils/utils';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CreativesetDataService } from '../../../shared/creativeset/creativeset.data.service';
import { EditorSaveStateService } from './editor-save-state.service';
import { HistoryService, IEditorSnapshot } from './history.service';

type ValidationStatus = 'VALID' | 'INVALID' | 'PENDING' | 'UNKNOWN';

@Injectable()
export class ValidationService implements OnDestroy {
    private _status$ = new BehaviorSubject<ValidationStatus>('UNKNOWN');
    status$ = this._status$.asObservable();

    private _rollback$ = new Subject<IEditorSnapshot>();
    rollback$ = this._rollback$.asObservable();

    private isDialogOpen = false;

    get status(): ValidationStatus {
        return this._status$.getValue();
    }

    private validatedSnapshot: Snapshot;

    private logger = new Logger('ValidationService');
    private unsubscribe$ = new Subject<void>();

    constructor(
        private uiConfirmDialogService: UIConfirmDialogService,
        private historyService: HistoryService,
        private eventLoggerService: EventLoggerService,
        private editorSaveStateService: EditorSaveStateService,
        private creativesetDataService: CreativesetDataService
    ) {
        this.status$.pipe(takeUntil(this.unsubscribe$)).subscribe(async status => {
            if (status === 'INVALID') {
                if (this.isDialogOpen) {
                    return;
                } else if (!this.validatedSnapshot) {
                    this.logger.warn('Invalid designs but no snapshot to return to!');
                    return;
                }

                this.isDialogOpen = true;

                const result = await this.uiConfirmDialogService.confirm({
                    headerText: 'Latest changes will prevent you from saving',
                    confirmText: 'Roll Back',
                    discardText: 'Ignore',
                    closeButton: false,
                    escKeyClose: false,
                    backdropClickClose: false,
                    text: `We encountered an error with one of your latest actions which will prevent you from saving. To protect your work, we recommend you to roll back to the state it was in at <b>${formatTime(
                        this.validatedSnapshot.created
                    )}</b> and save to reduce the risk of errors going forward.

We are investigating the issue and apologise for the inconvenience. Please contact support if the problem persists.
`
                });

                this.isDialogOpen = false;

                if (result === 'confirm') {
                    const validatedSnapshot = this.validatedSnapshot.snapshot;
                    this.logger.verbose('User confirmed to roll back.');
                    this._rollback$.next(validatedSnapshot);
                }
            }
        });

        this.editorSaveStateService.saveSuccess$.pipe(takeUntilDestroyed()).subscribe(() => {
            this.storeValidationSnapshot();
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    async validateDesigns(
        designs: IDesign[],
        versions: IVersion[],
        defaultVersion: IVersion
    ): Promise<void> {
        if (!this.isDesignCreated(designs)) {
            this.logger.verbose('Not validating designs that have not been created yet');
            return;
        }

        if (this.status === 'PENDING') {
            this.logger.verbose('Validation is already ongoing.');
            return;
        }

        this.logger.verbose('Validating designs');

        this._status$.next('PENDING');

        try {
            const payloadBody = this.creativesetDataService.prepareDesignsAndVersionsUpdate(
                versions,
                designs,
                versions,
                defaultVersion
            );

            if (payloadBody.creatives.length === 0) {
                this.logger.verbose('No creative has changed. Skipping validation.');
                return;
            }

            const validationResult = await this.creativesetDataService.validate(payloadBody);

            if (validationResult) {
                this.storeValidationSnapshot();
                this._status$.next('VALID');
            } else {
                this.handleInvalidDesign('Invalid design document');
            }
        } catch (e) {
            const networkError = (e as any).networkError?.result?.errors[0];
            if (networkError?.extensions.code === `VALIDATION_ERROR`) {
                this.handleInvalidDesign(networkError.message, networkError.extensions);
            } else {
                this._status$.next('UNKNOWN');
                throw e;
            }
        }
    }

    private handleInvalidDesign(message: string, validation?: any): void {
        this.eventLoggerService.log(new InvalidDesignEvent(message, validation));
        this._status$.next('INVALID');
    }

    storeValidationSnapshot(): void {
        this.logger.verbose('Storing a validation snapshot.');
        this.validatedSnapshot = new Snapshot(this.historyService.createSnapshot());
    }

    private isDesignCreated(designs: IDesign[]): boolean {
        return designs.every(design => design.id && design.name);
    }

    validateRelationalData(design: IDesign, versionProperties: IVersionProperty[]): void {
        const { elements, document } = design;
        this.logger.verbose('Validating element relation data.');
        try {
            for (const dataNode of document.elements) {
                const element = elements.find(el => el.id === dataNode.id);

                if (!element) {
                    this.eventLoggerService.log(
                        new RelationValidationEvent('Element relation is broken.', {
                            elementId: dataNode.id
                        })
                    );
                    return;
                }

                if (isWidgetNode(dataNode)) {
                    this.validateWidgetProperties(dataNode, element, versionProperties);
                }
            }
        } catch (e) {
            this._status$.next('INVALID');
            throw e;
        }
    }

    private validateWidgetProperties(
        dataNode: IWidgetElementDataNode,
        element: IElement,
        versionProperties: IVersionProperty[]
    ): void {
        for (const customProperty of dataNode.customProperties) {
            const versionPropertyId = customProperty.versionPropertyId;
            if (versionPropertyId) {
                const property = element.properties.find(prop => prop.name === customProperty.name);

                if (!property || property.versionPropertyId !== versionPropertyId) {
                    this.eventLoggerService.log(
                        new RelationValidationEvent(
                            "Widget element node contains widget properties which are not present in it's related element properties.",
                            { elementId: element.id, versionPropertyId }
                        )
                    );
                    return;
                }

                const versionProperty = versionProperties.some(vp => vp.id === versionPropertyId);

                if (!versionProperty) {
                    this.eventLoggerService.log(
                        new RelationValidationEvent(
                            'Widget element node contains a versionProperty reference that does not exist.',
                            { elementId: element.id, versionPropertyId }
                        ),
                        this.logger
                    );
                    return;
                }
            }
        }
    }

    init(): void {
        this.storeValidationSnapshot();
    }

    setStatusInvalid(): void {
        this._status$.next('INVALID');
    }
}

class Snapshot {
    created = Date.now();

    constructor(public snapshot: IEditorSnapshot) {}
}
