import { parseColor } from '@creative/color.utils';
import { PaletteDto } from '@domain/api/generated/sapi';
import { IBrandLocalization, IBrandPalette } from '@domain/brand';
import { ILocalization } from '@domain/creativeset/version';

export function filterExistingLocalizations(localizations: IBrandLocalization[]): IBrandLocalization[] {
    // localization in showcase has no state
    return localizations.filter(local => {
        if (isBrandLocalization(local)) {
            return local.state === 0;
        }
        return true;
    });
}

export function isBrandLocalization(localization: ILocalization): localization is IBrandLocalization {
    return 'state' in localization;
}

export function mapColorPalette(palette: PaletteDto): IBrandPalette {
    return { name: palette.name, swatch: palette.swatch.map(s => parseColor(s)) };
}
