import { Component, Input, Output, EventEmitter, HostListener, HostBinding } from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { Icon } from '@bannerflow/ui/components/icon/svg-icon/icons';

@Component({
    selector: 'button-toggle',
    templateUrl: './button-toggle.component.html',
    styleUrls: ['./button-toggle.component.scss'],
    standalone: true,
    imports: [UIModule]
})
export class ButtonToggleComponent {
    @Input() icon: Icon;

    @HostBinding('class.active')
    @Input()
    active: boolean;

    @Input() disabled = false;

    @Output() activeChange = new EventEmitter<boolean>();

    @HostListener('click')
    toggle(): void {
        if (this.disabled) {
            return;
        }
        this.active = !this.active;
        this.activeChange.emit(this.active);
    }
}
