import { IAnimationSettings } from '@domain/animation';
import { IPosition, ISize } from '@domain/dimension';
import { OneOfElementDataNodes } from '@domain/nodes';
import { Formula } from '@domain/state';
import { AnimatableProperty } from '@domain/transition';
import { getBoundingRectangleOfRotatedBox } from '@studio/utils/geom';
import { SafeEval } from '@studio/utils/safe-eval';
import { deepEqual, roundToNearestMultiple } from '@studio/utils/utils';

/**
 * Detect if string is a formula.
 * Currently the rule is that it should contain an '@'
 */
export function isFormulaValue(value?: number | string): value is Formula {
    if (typeof value === 'string' && value.indexOf('@') >= 0) {
        return true;
    }
    return false;
}

export function formulaToValue(
    formula: Formula,
    dependencies: IFormulaDependecies
): undefined | number | string {
    // Formula is just @value (default value). TODO: SHOULDN't be the case anymore
    if (formula === '@value') {
        return undefined;
    }
    // Value is a formula
    else if (isFormulaValue(formula)) {
        // Reuse already resolved formulas if nothing have changed
        const cache = getCachedEval(formula, dependencies);
        if (typeof cache === 'number') {
            return cache;
        }

        const { settings, canvasSize, element } = dependencies;
        const mainValue = dependencies.propertyName && element[dependencies.propertyName];

        const { x, y, width, height, rotationX, rotationY, rotationZ } = element;

        const boundingBox = getBoundingRectangleOfRotatedBox({
            x: 0,
            y: 0,
            width,
            height,
            rotationZ
        });

        // Exposed properties and method
        const props: IExposedProperties = {
            x,
            y,
            rotationX,
            rotationY,
            rotationZ,
            width: boundingBox.width,
            height: boundingBox.height,
            creativeWidth: canvasSize.width,
            creativeHeight: canvasSize.height,
            edgePoint: (angle: number) => {
                const edge = getEdgePointBasedOnAngle({ x, y }, boundingBox, angle, canvasSize);
                edge.x -= x;
                edge.y -= y;
                return edge;
            },
            settings: {},
            transition: {}
        };

        // Let us use "@value" inside the transition formula
        props.value = mainValue;

        if (settings) {
            for (const key in settings) {
                props.settings![key] = settings[key].value;
                props.transition![key] = settings[key].value;
            }
        }

        const expression = formulaToEvalExpression(formula);
        const parsedValue = SafeEval.eval(expression, props);

        // Detect any errors early in the chain to not pass around NaN values
        if (isNaN(parsedValue)) {
            throw new Error(`Could not resolve formula: ${formula}`);
        }

        // Store value in chache
        evalCacheValueMap.set(getCacheValidationKey(element, formula), parsedValue);

        return parsedValue;
    }
    // Not a formula but can be converted to a number. TODO: TOO NICE?
    else if (typeof formula === 'string' && !isNaN(+formula)) {
        return +formula;
    }
    // TODO: SHOULD WE EVEN COME HERE?
    else {
        return formula;
    }
}

const evalCacheValidationMap = new Map<string, IExposedProperties>();
const evalCacheValueMap = new Map<string, number>();

function getCachedEval(formula: Formula, dependencies: IFormulaDependecies): number | undefined {
    const validation = getCacheValidationObject(dependencies);
    const key = getCacheValidationKey(dependencies.element, formula);
    const cache = evalCacheValidationMap.get(key);

    if (cache && deepEqual(cache, validation)) {
        return evalCacheValueMap.get(key);
    } else {
        evalCacheValidationMap.set(key, validation);
        evalCacheValueMap.delete(key);
    }
}

function getCacheValidationKey(element: OneOfElementDataNodes, formula: string): string {
    return `${element.id}:${formula}`;
}

function getCacheValidationObject(dependencies: IFormulaDependecies): CacheValidationObject {
    const { settings, canvasSize, element } = dependencies;
    const value = dependencies.propertyName && element[dependencies.propertyName];

    const { x, y, width, height, rotationX, rotationY, rotationZ } = element;
    return {
        x,
        y,
        width,
        height,
        rotationX,
        rotationY,
        rotationZ,
        value,
        settings,
        creativeWidth: canvasSize.width,
        creativeHeight: canvasSize.height
    };
}

function formulaToEvalExpression(formula: Formula): string {
    return formula.replace(/@/g, '');
}

function getEdgePointBasedOnAngle(
    position: IPosition,
    elementSize: ISize,
    angle: number,
    canvasSize: ISize
): IPosition {
    angle = (angle - 90) * (Math.PI / 180);
    const roundTo = 0.00001;
    const dx = roundToNearestMultiple(Math.cos(angle), roundTo);
    const dy = roundToNearestMultiple(Math.sin(angle), roundTo);
    const d = dy / dx;
    const vertical = dx === 0;
    const horizontal = dy === 0;

    // Left border
    if (dx < 1.0e-16 && !vertical) {
        const y = position.x * d + (canvasSize.height - position.y);
        if (y >= -elementSize.height && y <= canvasSize.height + elementSize.height) {
            return {
                x: -elementSize.width,
                y: canvasSize.height - y - elementSize.height * -(dy * 0.5)
            };
        }
    }

    // Right border
    if (dx > 1.0e-16 && !vertical) {
        const y = (canvasSize.width - position.x) * d + position.y;
        if (y >= -elementSize.height && y <= canvasSize.height + elementSize.height) {
            return {
                x: canvasSize.width,
                y: y + elementSize.height * (dy * 0.5)
            };
        }
    }

    // Top border
    if (dy < 1.0e-16 && !horizontal) {
        const x = (position.y * dx) / dy + (canvasSize.width - position.x);

        if (x >= -elementSize.width && x <= canvasSize.width + elementSize.width) {
            return {
                x: canvasSize.width - x - elementSize.width * -(dx * 0.5),
                y: -elementSize.height
            };
        }
    }

    // Bottom border
    if (dy > 1.0e-16 && !horizontal) {
        const x = ((canvasSize.height - position.y) * dx) / dy + position.x;
        if (x >= -elementSize.width && x <= canvasSize.width + elementSize.width) {
            return {
                x: x + elementSize.width * (dx * 0.5),
                y: canvasSize.height
            };
        }
    }

    return position;
}

export interface IFormulaDependecies {
    element: OneOfElementDataNodes;
    canvasSize: ISize;
    settings?: IAnimationSettings;
    propertyName: AnimatableProperty | string;
}

interface IExposedProperties {
    x: number;
    y: number;
    width: number;
    height: number;
    creativeWidth: number;
    creativeHeight: number;
    rotationX: number;
    rotationY: number;
    rotationZ: number;
    value?: AnimatableProperty;
    settings?: IAnimationSettings;
    transition?: IAnimationSettings;
    edgePoint?: (angle: number) => IPosition;
}

type CacheValidationObject = Omit<IExposedProperties, 'transition'>;
