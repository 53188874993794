import { EnvironmentProviders, importProvidersFrom } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BrandEffects } from './brand.effects';
import { BRAND_FEATURE_KEY, reducer } from './brand.reducer';

export function provideBrandStore(): EnvironmentProviders {
    return importProvidersFrom(
        StoreModule.forFeature(BRAND_FEATURE_KEY, reducer),
        EffectsModule.forFeature(BrandEffects)
    );
}
