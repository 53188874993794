import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AppState, AuthService as Auth0AuthService, GenericError } from '@auth0/auth0-angular';
import { Logger } from '@bannerflow/sentinel-logger';
import { filter, map, Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IAuthUser } from './auth-user';
import { authUserMock } from './auth-user.mock';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private logger = new Logger(AuthService.name);
    private auth0AuthService = inject(Auth0AuthService);
    private readonly inTest = environment.stage === 'test';

    constructor() {
        this.logoutOnAuthErrors();
    }

    getAppState(): Observable<AppState> {
        return this.auth0AuthService.appState$;
    }

    getAuthUser(): Observable<IAuthUser> {
        if (this.inTest) {
            return of(authUserMock);
        }

        return this.auth0AuthService.user$.pipe(
            filter(user => user !== null && user !== undefined),
            map(user => user as IAuthUser)
        );
    }

    getAccessToken(): Observable<string> {
        if (this.inTest) {
            return of('accessTokenMock');
        }

        return this.auth0AuthService.getAccessTokenSilently().pipe(filter(token => !!token));
    }

    private logoutOnAuthErrors(): void {
        this.auth0AuthService.error$
            .pipe(
                takeUntilDestroyed(),
                filter(
                    e =>
                        e instanceof GenericError &&
                        (e.error === 'login_required' || e.error === 'invalid_grant')
                )
            )
            .subscribe(() => {
                this.logger.warn('Unauthorized, logging out.');
                this.auth0AuthService.logout();
            });
    }
}
