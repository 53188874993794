import { ServerError, ServerParseError } from '@apollo/client/core';
import { GraphQLFormattedError } from 'graphql';

class BaseError extends Error {}

export class BadRequestException extends BaseError {
    statusCode = 400;
}

export class NotFoundError extends BaseError {
    statusCode = 404;
    id?: number | string;
}

export class ForbiddenError extends BaseError {
    statusCode = 403;
    constructor(public override message: string) {
        super();
    }
}

export class BadParameterException extends BaseError {
    statusCode = 422;
}
export function getPrimaryGraphQlError(
    graphQLErrors: ReadonlyArray<GraphQLFormattedError>,
    networkError: Error | ServerParseError | ServerError | null
): Error {
    if (graphQLErrors && graphQLErrors.length > 0) {
        // filter all unhandled errors
        const errors = graphQLErrors
            .filter(e => !e.extensions?.['handled'])
            .map(e => e.message)
            .join('\n');

        // mark errors as handled
        for (const e of graphQLErrors) {
            if (e.extensions) {
                e.extensions['handled'] = true;
            }
        }

        if (errors) {
            return new Error(errors);
        }
    } else if (networkError) {
        const { error } = networkError as any;
        // handle weird network error errors (e.g. on invalid versions)
        if (error) {
            if (error.errors[0]) {
                return new Error(error.errors[0].message);
            } else {
                return new Error(error);
            }
        }
        return networkError;
    }

    return new Error('Unknown graphql error');
}

/**
 * Sometimes errors are wrapped, return real error in those cases
 * @param error
 */
export function getPrimaryError(error: any): any {
    if (error) {
        // Promise rejection, use rejection instead
        if (error.rejection) {
            return error.rejection;
        }
        // Errors from graphql
        else if (error.networkError) {
            return error.networkError;
        }
        // Errors from express?
        else if (error.originalError) {
            return error.originalError;
        }
    }
    return error || {};
}
