import { BreakpointObserver } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import {
    AfterViewInit,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild
} from '@angular/core';
import { TruncateSpanComponent } from '../../../shared/directives/truncate-span.directive';
import { LocalizationForVersionPipe } from '../../../shared/pipes/localization-for-version.pipe';
import { UIDialogComponent, UIModule } from '@bannerflow/ui';
import { ICreative } from '@domain/creativeset/creative';
import { Breakpoint, getQueryFromBreakpoint } from '@studio/utils/breakpoints';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { StudioCreativeComponent } from '../../../shared/components/creative/studio-creative.component';
import { VersionFlagComponent } from '../../../shared/components/version-picker/version-flag/version-flag.component';
import { VersionEntity } from '../../../shared/versions/state/version.entity';
import { VersionsService } from '../../../shared/versions/state/versions.service';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        UIModule,
        StudioCreativeComponent,
        VersionFlagComponent,
        TruncateSpanComponent,
        LocalizationForVersionPipe
    ],
    selector: 'full-screen-dialog',
    templateUrl: './full-screen-dialog.component.html',
    styleUrls: ['./full-screen-dialog.component.scss']
})
export class FullScreenDialogComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('creativeContainer') creativeContainer: ElementRef<HTMLElement>;
    @ViewChild('responsiveElement') responsiveElement: ElementRef<HTMLElement>;

    private unsubscribe$ = new Subject<void>();
    private startX: number | undefined = undefined;
    private endX: number | undefined = undefined;

    creative: ICreative;
    creatives: ICreative[] = [];
    isFirstCreative = false;
    isLastCreative = false;
    version: VersionEntity | undefined;
    scale: number;
    isTargetURLOn = false;
    isHidingUI = false;

    @HostListener('window:resize')
    resizeResponsiveContent(): void {
        if (!this.responsiveElement) {
            return;
        }
        const responsiveEl = this.responsiveElement.nativeElement;

        this.setScale();

        const scaledSize = {
            width: (this.creative?.size?.width ?? 0) * this.scale,
            height: (this.creative?.size?.height ?? 0) * this.scale
        };

        this.renderer.setStyle(responsiveEl, 'width', `${scaledSize.width}px`);
        this.renderer.setStyle(responsiveEl, 'height', `${scaledSize.height}px`);
    }

    @HostListener('touchstart', ['$event'])
    onTouchStart(event: TouchEvent): void {
        this.startX = event.touches[0].clientX;
    }

    @HostListener('touchmove', ['$event'])
    onTouchMove(event: TouchEvent): void {
        this.endX = event.touches[0].clientX;
    }

    @HostListener('touchend')
    onTouchEnd(): void {
        if (
            typeof this.endX === 'undefined' ||
            typeof this.startX === 'undefined' ||
            Math.abs(this.startX - this.endX) < 50 ||
            this.isTargetURLOn
        ) {
            return;
        }
        if (this.startX < this.endX) {
            this.prevCreative();
        } else if (this.startX > this.endX) {
            this.nextCreative();
        }
    }

    constructor(
        private breakpointObserver: BreakpointObserver,
        private dialog: UIDialogComponent,
        private renderer: Renderer2,
        private versionsService: VersionsService
    ) {}

    ngOnInit(): void {
        this.breakpointObserver
            .observe(getQueryFromBreakpoint(Breakpoint.DesktopUp))
            .pipe(
                filter(matchBreakpoint => matchBreakpoint.matches),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => {
                this.close();
            });

        this.creative = this.dialog.dialogRef.config.data.creative;
        this.creatives = this.dialog.dialogRef.config.data.creatives;
        this.checkFirstLastCreative();
    }

    ngAfterViewInit(): void {
        if (this.creative.version.id) {
            this.versionsService
                .getVersionByID(this.creative.version.id)
                .pipe(take(1))
                .subscribe(version => (this.version = version));
        }

        this.resizeResponsiveContent();
    }

    close(): void {
        this.dialog.dialogRef.close();
    }

    fullScreenClick($event: MouseEvent): void {
        if (($event.target as HTMLElement).classList.contains('switch')) {
            return;
        }

        this.isHidingUI = !this.isHidingUI;
    }

    private setScale(): void {
        const width = this.creativeContainer.nativeElement.offsetWidth;
        const height = this.creativeContainer.nativeElement.offsetHeight;
        const borderThickness = 2;

        const actualWidth = this.creative.size?.width ?? 0;
        const actualHeight = this.creative.size?.height ?? 0;

        this.scale = Math.max(
            0.01,
            this.calculateScale(width, height, borderThickness, actualWidth, actualHeight)
        );
    }

    private calculateScale = (
        width: number,
        height: number,
        borderThickness: number,
        actualWidth: number,
        actualHeight: number
    ): number =>
        Math.min(
            Math.min(
                (width - borderThickness) / actualWidth,
                (height - borderThickness) / actualHeight
            ),
            1
        );

    prevCreative(event?: MouseEvent): void {
        if (event) {
            event.stopPropagation();
        }

        if (this.isFirstCreative) {
            return;
        }

        const index = this.creatives.findIndex(creative => creative.id === this.creative.id);
        this.moveToCreative(index - 1);
    }

    nextCreative(event?: MouseEvent): void {
        if (event) {
            event.stopPropagation();
        }

        if (this.isLastCreative) {
            return;
        }

        const index = this.creatives.findIndex(creative => creative.id === this.creative.id);
        this.moveToCreative(index + 1);
    }

    private moveToCreative(index: number): void {
        if (index < 0 || index >= this.creatives.length) {
            throw new Error('Invalid index for creative');
        }

        this.creative = this.creatives[index];
        this.resizeResponsiveContent();
        this.checkFirstLastCreative();
    }

    private checkFirstLastCreative(): void {
        const index = this.creatives.findIndex(creative => creative.id === this.creative.id);
        this.isFirstCreative = index === 0;
        this.isLastCreative = index === this.creatives.length - 1;
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
