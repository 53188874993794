import { Logger } from '@bannerflow/sentinel-logger';
import { ITextSpan, IVersionedText } from '@domain/creativeset/version';
import { IFeedInfo, SpanType, SplitText } from '@domain/text';
import { cloneDeep } from '@studio/utils/clone';
import { getSpanType } from '../../pages/translation-page/utils/span.utils';

const logger = new Logger('SpanStyles-Utils');

/*
 * The idea is to apply previous styles by word position instead of character position.
 * Also, when 2 words have different size (say "buy"(en) and "comprar"(es)), character styles
 * will be applied proportionaly.
 * Workflow:
 * * Get all feeds in the versioned text
 * * Transform the new text into an internal type. Splitting words and spaces
 * * Apply feeds into the new type
 * * Transform old text into internal type
 * * Merge styles from old text into new one
 * * Transform the internal type back to IVersionedText
 */
export function getNewStylesForTranslatedText(
    versionedText: IVersionedText,
    newText: string
): ITextSpan[] {
    let newWords = splitNewText(newText);
    try {
        const feedsInfo = generateFeedsInfo(versionedText);
        newWords = replaceFeedInfosInSplitText(newWords, feedsInfo);
        const oldSplitText = splitOldText(versionedText);
        const mergedSplitText = mergeWords(oldSplitText, newWords);
        const textSpans = convertSplitTextIntoTextSpans(mergedSplitText);
        return textSpans;
    } catch (e) {
        logger.error(e);
        return convertSplitTextIntoTextSpans(newWords);
    }
}

function mergeWords(oldWords: SplitText[], newWords: SplitText[]): SplitText[] {
    const splitTexts: SplitText[] = [];

    let oldWordsIndex = 0;
    const unusedOldWords: SplitText[] = [];
    const getOldWord = (ofType: SpanType): SplitText | undefined => {
        const unusedOldWordIndex = unusedOldWords.findIndex(({ styles }) => styles[0].type === ofType);
        if (unusedOldWordIndex !== -1) {
            const unusedOldWord = unusedOldWords.at(unusedOldWordIndex);
            unusedOldWords.splice(unusedOldWordIndex, 1);
            return unusedOldWord;
        }
        let oldWord: SplitText = oldWords[oldWordsIndex];
        while (oldWord && oldWord.styles[0].type !== ofType) {
            if (oldWord.styles[0].type !== SpanType.Variable) {
                unusedOldWords.push(oldWord);
            }
            oldWordsIndex++;
            oldWord = oldWords[oldWordsIndex];
        }
        return oldWord;
    };
    for (let i = 0; i < newWords.length; i++) {
        const newWord = newWords[i];
        if (newWord.styles[0].type === SpanType.Variable) {
            splitTexts.push({ ...newWord });
            continue;
        }
        const oldWord = getOldWord(newWord.styles[0].type);
        if (!oldWord) {
            splitTexts.push({ ...newWord });
            continue;
        }
        const mergedWord = mergeWord(oldWord, newWord, newWord.styles[0].position);
        splitTexts.push(mergedWord);
        oldWordsIndex++;
    }
    // Some words are missing, i.e: having a feed as last word
    return splitTexts;
}

export function mergeWord(oldWord: SplitText, newWord: SplitText, newWordPosition: number): SplitText {
    const mergedStyles: ITextSpan[] = [];
    for (let i = 0; i < oldWord.styles.length; i++) {
        const oldWordStyle = oldWord.styles[i];
        let newWordStyle = newWord.styles[i];
        if (oldWordStyle.type === SpanType.Variable) {
            mergedStyles.push(cloneDeep(newWordStyle));
            continue;
        }
        // ignore feeds
        if (newWordStyle?.type === SpanType.Variable) {
            mergedStyles.push(cloneDeep(newWordStyle));
            newWordStyle = newWord[i + 1];
        }
        const prevStyle = mergedStyles.at(-1);
        const relativeStart = !prevStyle ? 0 : prevStyle.length;
        const relativeLength = Math.ceil(
            newWord.text.length * (oldWordStyle.length / oldWord.text.length)
        );
        // If relativeLength exceeds the word's length, cut it
        const newStyleLength = Math.min(relativeLength, newWord.text.length - relativeStart);

        if (newStyleLength > 0) {
            mergedStyles.push({
                position: newWordPosition,
                length: newStyleLength,
                styleIds: cloneDeep(oldWordStyle.styleIds),
                type: oldWordStyle.type
            });
            newWordPosition += relativeLength;
        }
    }
    // Double check that all characters are covered by some style item
    const stylesLength = mergedStyles.reduce((acc, { length }) => acc + length, 0);
    if (mergedStyles.length && stylesLength !== newWord.text.length) {
        const lastSpanStyle = mergedStyles.at(-1)!;
        lastSpanStyle.length += newWord.text.length - stylesLength;
    }
    return {
        text: newWord.text,
        styles: mergedStyles
    };
}

/**
 * splits new text into an array of words with empty styles
 */
export function splitNewText(newText: string): SplitText[] {
    const splitTexts: SplitText[] = [];
    for (let i = 0; i < newText.length; i++) {
        const character = newText[i];
        const characterSpanType = getSpanType(character);
        const lastSpan = splitTexts.at(-1);
        const lastSpanStyle = lastSpan?.styles.at(-1);
        if (!lastSpan || lastSpanStyle?.type !== characterSpanType) {
            splitTexts.push({
                text: character,
                styles: [
                    {
                        type: characterSpanType,
                        styleIds: {},
                        length: 1,
                        position: i
                    }
                ]
            });
            continue;
        }
        // Same SpanType => merge
        lastSpan.text += character;
        lastSpanStyle!.length += 1;
    }
    return splitTexts;
}

export function replaceFeedInfosInSplitText(newWords: SplitText[], feedsInfo: IFeedInfo): SplitText[] {
    const feedsInfoKeys = Object.keys(feedsInfo);
    if (!feedsInfoKeys.length) {
        return newWords;
    }
    const splitTexts: SplitText[] = [];
    for (const word of newWords) {
        // Ignore already replaced feeds
        if (word.styles?.[0]?.type === SpanType.Variable) {
            splitTexts.push({ ...word });
            continue;
        }
        const feedKey = feedsInfoKeys.find(key => word.text.includes(key));
        if (!feedKey) {
            // not a feed
            splitTexts.push({ ...word });
            continue;
        }
        const feed = feedsInfo[feedKey];
        const feedValue = feed.shift();

        // Remove feed
        if (!feed.length) {
            delete feedsInfo[feedKey];
        }

        if (word.text === feedKey) {
            // the whole span is a feed
            const feededWord = mergeFeedInFullWord(word, feedValue);
            splitTexts.push(feededWord);
            continue;
        }
        const feededWords = mergeFeedInPartialWord(word, feedValue, feedKey);
        splitTexts.push(...feededWords);
    }
    if (Object.keys(feedsInfo).length) {
        const someKeyExistsInTexts = Object.keys(feedsInfo).some(feedKey =>
            splitTexts.some(
                ({ text, styles }) =>
                    text.includes(feedKey) && styles.every(({ type }) => type !== SpanType.Variable)
            )
        );
        if (someKeyExistsInTexts) {
            // a little bit of recurssion
            return replaceFeedInfosInSplitText(splitTexts, feedsInfo);
        }
    }
    return splitTexts;
}

export function mergeFeedInPartialWord(
    word: SplitText,
    feedValue: ITextSpan | undefined,
    feedKey: string
): SplitText[] {
    const words: SplitText[] = [];

    const wordSpan = word.styles[0];
    const startIndex = word.text.indexOf(feedKey);
    if (startIndex > 0) {
        // split begining into its own style item
        words.push({
            text: word.text.substring(0, startIndex),
            styles: [
                {
                    type: SpanType.Word,
                    position: wordSpan.position,
                    length: startIndex,
                    styleIds: {}
                }
            ]
        });
    }
    const variablePosition = startIndex <= 0 ? wordSpan.position : wordSpan.position + startIndex;
    words.push({
        text: feedKey,
        styles: [
            {
                type: SpanType.Variable,
                position: variablePosition,
                length: feedKey.length,
                styleIds: cloneDeep(feedValue?.styleIds ?? {}),
                variable: feedValue?.variable
            }
        ]
    });
    if (feedKey.length < word.text.length) {
        // split all text after feed
        if (word.text.length - feedKey.length - startIndex) {
            words.push({
                text: word.text.substring(feedKey.length),
                styles: [
                    {
                        type: SpanType.Word,
                        position: variablePosition + feedKey.length,
                        length: word.text.length - feedKey.length - startIndex,
                        styleIds: {}
                    }
                ]
            });
        }
    }
    return words;
}
function mergeFeedInFullWord(word: SplitText, feedValue: ITextSpan | undefined): SplitText {
    return {
        ...word,
        styles: [
            {
                ...word.styles[0],
                variable: feedValue?.variable,
                type: SpanType.Variable,
                styleIds: cloneDeep(feedValue?.styleIds ?? {})
            }
        ]
    };
}

/**
 * splits the versionedText into an array of words with their different styles
 */
export function splitOldText(versionedText: IVersionedText): SplitText[] {
    const oldWords: SplitText[] = [];
    for (const style of versionedText.styles) {
        const spanText = versionedText.text.substring(style.position, style.position + style.length);
        const lastSpan = oldWords.at(-1);
        if (!lastSpan) {
            oldWords.push({
                styles: [style],
                text: spanText
            });
            continue;
        }
        const lastSpanStyle = lastSpan.styles.at(-1);
        if (style.type === lastSpanStyle?.type) {
            // merge texts, insert style
            lastSpan.text += spanText;
            lastSpan.styles.push(style); // styles should be different
            continue;
        }
        oldWords.push({
            text: spanText,
            styles: [style]
        });
    }
    return oldWords;
}

export function generateFeedsInfo(versionedText: IVersionedText): IFeedInfo {
    const feedInfo: IFeedInfo = {};
    for (const span of versionedText.styles) {
        if (span.type !== SpanType.Variable || !span.variable) {
            continue;
        }
        const pathKey = `@${span.variable.path}`;
        feedInfo[pathKey] ??= [];
        feedInfo[pathKey].push(span);
    }
    return feedInfo;
}

function convertSplitTextIntoTextSpans(newWords: SplitText[]): ITextSpan[] {
    return newWords.reduce<ITextSpan[]>((acc, { styles }) => [...acc, ...styles], []);
}
