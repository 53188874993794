<div
    class="playhead"
    data-test-id="playhead"
    *ngIf="playheadPosition >= 0"
    #playhead
    [style.transform]="'translateX(' + playheadPosition + 'px)'"
    [class.hidden]="!playheadIsVisible"
    [class.active]="timelineTransformService.actionMode === ActionMode.Seek"
    [class.nohover]="timelineTransformService.actionMode !== ActionMode.None"
    [class.recording]="animationRecorderService.recording$ | async">
    <div class="head"></div>
    <div class="line"></div>
</div>
<div
    class="resize-handle resize-90"
    (dblclick)="toggle()"
    (mousedown)="onStartResizeTimeline($event)"></div>
<div class="wrapper">
    <div
        class="placeholder"
        [style.height]="
            'calc(100% - var(--time-ruler-height) * ' + (creative.gifExport.show ? 2 : 1) + ')'
        "
        *ngIf="!nodes.length && !collapsed">
        <div class="placeholder-elements">No elements</div>
        <div class="placeholder-timeruler">
            Control duration and animation of elements here after creating them on canvas
        </div>
    </div>
    <div class="top">
        <div class="playback">
            <div class="controls">
                <ui-svg-icon
                    id="step-back-btn"
                    data-test-id="step-back-btn"
                    #backButton
                    [icon]="animator.isPlaying ? 'playback-start-small' : 'playback-step-left-small'"
                    [uiTooltipDisabled]="!animator.isPlaying"
                    [uiTooltip]="'Jump to start (↑)'"
                    (click)="stepBack($event)"></ui-svg-icon>
                <ui-svg-icon
                    id="play-pause-btn"
                    data-test-id="play-pause-btn"
                    #playButton
                    class="primary"
                    (click)="togglePlay($event)"
                    [icon]="
                        animator.isPlaying ? 'playback-pause-small' : 'playback-play-small'
                    "></ui-svg-icon>
                <ui-svg-icon
                    id="step-forward-btn"
                    data-test-id="step-forward-btn"
                    #forwardButton
                    icon="playback-step-right-small"
                    (click)="stepForward($event)"></ui-svg-icon>
                <ui-svg-icon
                    id="record-btn"
                    #recordButton
                    class="record"
                    [class.recording]="animationRecorderService.recording$ | async"
                    icon="playback-rec"
                    (click)="toggleRecording($event)"></ui-svg-icon>
            </div>
            <div class="time">
                <ui-input
                    class="seek-input"
                    #seekInput
                    ui-theme="tiny"
                    [discrete]="true"
                    [selectTextOnFocus]="true"
                    [blurOnSubmit]="true"
                    [value]="currentTime"
                    (cancel)="seek()"
                    (blur)="seek($any(seekInput.value))"
                    (submit)="seek($any(seekInput.value))">
                </ui-input>
                <span class="time-slash">/</span>
                <ui-input
                    class="duration-input"
                    #durationInput
                    ui-theme="tiny"
                    id="duration"
                    [ngClass]="{ 'duration-warning': duration > 15 && (isSocialCreative$ | async) }"
                    [uiTooltip]="
                        'Duration of this creative is longer than 15 seconds. If you use it as a video in Facebook or Instagram story, it will be truncated.'
                    "
                    [uiTooltipDelay]="0"
                    [uiTooltipWidth]="250"
                    [uiTooltipDisabled]="!(duration > 15 && (isSocialCreative$ | async))"
                    [selectTextOnFocus]="true"
                    [blurOnSubmit]="true"
                    [discrete]="true"
                    [value]="duration.toFixed(2)"
                    (cancel)="setDuration(undefined)"
                    (blur)="setDuration(durationInput.value)"
                    (submit)="setDuration(durationInput.value)">
                </ui-input>
            </div>
        </div>
        <div class="timeruler-space">
            <time-ruler [showNumbers]="true"></time-ruler>
            <ng-container *ngIf="nodes?.length">
                <div
                    uiTooltip="Stop here on the {{ creative.loops | uiOrdinal }} loop"
                    [uiTooltipDisabled]="
                        timelineTransformService.actionMode === ActionMode.SetStopTime ||
                        timelineTransformService.actionMode === ActionMode.Seek
                    "
                    *ngIf="creative.loops > 0 && stopTimePosition() >= 0"
                    class="timeline-draggable-icon stop-time"
                    [ngClass]="{ active: timelineTransformService.stopTimeMarkerActive }"
                    [style.transform]="'translateX(' + stopTimePosition() + 'px)'">
                    <div class="loops">{{ creative.loops }}</div>
                </div>
                <div
                    *ngIf="scroll.x < secondsToPixels(creative.getFirstPreloadImageFrame()) + 5"
                    uiTooltip="Capture preload image at this point in time"
                    [uiTooltipDisabled]="
                        timelineTransformService.actionMode === ActionMode.SetPreloadFrame ||
                        timelineTransformService.actionMode === ActionMode.Seek
                    "
                    class="timeline-draggable-icon preload-frame"
                    data-test-id="preload-image-frame"
                    [ngClass]="{ active: timelineTransformService.preloadFrameActive }"
                    [style.transform]="
                        'translateX(' +
                        preloadFramePosition(creative.getFirstPreloadImageFrame()) +
                        'px)'
                    ">
                    <ui-svg-icon icon="image"></ui-svg-icon>
                </div>
            </ng-container>
        </div>
        <div
            class="toggle-button"
            [ngClass]="{ collapsed: collapsed }"
            (click)="toggle()">
            <ui-svg-icon icon="arrow-down"></ui-svg-icon>
        </div>
    </div>
    <gif-frames *ngIf="creative.gifExport.show"></gif-frames>
    <div class="elements-container">
        <div
            id="timeline-elements"
            class="elements"
            #elementsContainer>
            <div class="elements-inner-wrapper">
                <timeline-element
                    *ngFor="let nodeItem of nodes$ | async; let i = index; trackBy: getElementId"
                    [ngStyle]="{
                        display: nodeItem.collapsed && nodeItem.node.__parentNode ? 'none' : 'block'
                    }"
                    [ngClass]="{
                        scrolling: scrollService.isScrolling,
                        selecting: selectionNet,
                        'group-node': nodeItem.node.kind === ElementKind.Group
                    }"
                    [style.z-index]="nodeItem.node.kind === ElementKind.Group ? 0 : 1"
                    [elementIndex]="nodes.length - i - 1"
                    [attr.data-test-id]="'timeline-element-' + i"
                    [attr.data-test-name]="'timeline-element-' + nodeItem.node.name"
                    [node]="nodeItem.node"
                    [collapsed]="nodeItem.collapsed && !!nodeItem.node.__parentNode"></timeline-element>
            </div>
        </div>
    </div>
    <div
        #zoomControl
        class="zoom"
        [ngClass]="{
            zooming: slider.mouseIsDown,
            disabled: zoomBox || !nodes.length || scrollService.isScrolling
        }">
        <div class="slider">
            <studio-ui-slider
                #slider
                [value]="(zoomService.zoom$ | async) || zoomConfig.min"
                (valueChange)="zoomService.setZoom($event)"
                [config]="zoomConfig"
                [tracklight]="false"
                direction="vertical"></studio-ui-slider>
        </div>
        <ui-svg-icon
            (click)="zoomIn($event)"
            class="zoom-in"
            icon="zoom-in"></ui-svg-icon>
        <ui-svg-icon
            (click)="zoomOut($event)"
            class="zoom-out"
            icon="zoom-out">
        </ui-svg-icon>

        <ui-svg-icon
            class="magnifier"
            icon="zoom"></ui-svg-icon>
    </div>
    <canvas
        #gizmoOverlayCanvas
        class="gizmo-overlay-canvas"></canvas>
</div>

<!-- Animation menu -->
<div
    class="dropdown-trigger"
    #transitionAnimationMenuTrigger="uiDropdownTarget"
    [uiDropdownTarget]="transitionAnimationMenu"
    [hasBackdrop]="true"></div>

<ui-dropdown
    #transitionAnimationMenu
    id="timeline-animation-menu"
    type="menu"
    [minWidth]="120"
    [positions]="transitionAnimationMenuPositions"
    [width]="'120'">
    <ui-dropdown-item
        class="custom-dropdown"
        (click)="clearAnimation(selectedElementAnimations?.type)">
        <div
            class="custom-item"
            data-test-id="timeline-animation-none">
            <ui-svg-icon
                class="custom-column icon"
                icon="minus-small"></ui-svg-icon>
            <div class="custom-text">No animation</div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-divider></ui-dropdown-divider>
    <ng-container *ngFor="let animation of animationTemplates">
        <ui-dropdown-item
            class="custom-dropdown"
            [attr.data-test-id]="'timeline-animation-' + animation.name"
            (click)="setTransitionAnimationOnElement(animation)"
            *ngIf="!animation.settings">
            <div class="custom-item">
                <div class="custom-column">
                    <ui-svg-icon
                        *ngIf="
                            selectedElementAnimations &&
                            selectedElementAnimations.templateId === animation.id
                        "
                        class="icon active"
                        icon="menu-checked">
                    </ui-svg-icon>
                </div>
                <div class="custom-text">
                    {{ animation.name }}
                </div>
            </div>
        </ui-dropdown-item>
        <ui-dropdown-item
            *ngIf="animation.settings"
            class="custom-dropdown"
            (click)="setTransitionAnimationOnElement(animation)"
            (mouseenter)="setCurrentTemplate(animation)"
            [uiDropdownTarget]="directionMenu">
            <div class="custom-item">
                <div class="custom-column">
                    <ui-svg-icon
                        *ngIf="
                            selectedElementAnimations &&
                            selectedElementAnimations.templateId === animation.id
                        "
                        class="icon active"
                        icon="menu-checked"></ui-svg-icon>
                </div>
                <div
                    class="custom-text"
                    [attr.data-test-id]="'timeline-animation-' + animation.name">
                    {{ animation.name }}
                </div>
            </div>
        </ui-dropdown-item>
    </ng-container>
</ui-dropdown>

<ui-dropdown
    type="menu"
    #directionMenu
    [width]="'120'"
    [minWidth]="120"
    [useTargetWidth]="false"
    [positions]="transitionAnimationSubMenuPositions">
    <ui-dropdown-item
        class="custom-dropdown"
        *ngIf="selectedElementAnimations"
        (itemClicked)="
            setAnimationWithSettingOnElement({
                direction: {
                    name: 'Direction',
                    value: animationDirectionValues[selectedElementAnimations.type].left
                }
            })
        ">
        <div class="custom-item">
            <ui-svg-icon
                class="custom-column icon active"
                icon="direction-left"></ui-svg-icon>
            <div
                class="custom-text"
                id="timeline-animation-direction-left">
                Left
            </div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-item
        class="custom-dropdown"
        *ngIf="selectedElementAnimations"
        (itemClicked)="
            setAnimationWithSettingOnElement({
                direction: {
                    name: 'Direction',
                    value: animationDirectionValues[selectedElementAnimations.type].up
                }
            })
        ">
        <div class="custom-item">
            <ui-svg-icon
                class="custom-column icon"
                icon="direction-up"></ui-svg-icon>
            <div
                class="custom-text"
                id="timeline-animation-direction-up">
                Up
            </div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-item
        class="custom-dropdown"
        *ngIf="selectedElementAnimations"
        (itemClicked)="
            setAnimationWithSettingOnElement({
                direction: {
                    name: 'Direction',
                    value: animationDirectionValues[selectedElementAnimations.type].right
                }
            })
        ">
        <div class="custom-item">
            <ui-svg-icon
                class="custom-column icon"
                icon="direction-right"></ui-svg-icon>
            <div
                class="custom-text"
                id="timeline-animation-direction-right">
                Right
            </div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-item
        class="custom-dropdown"
        *ngIf="selectedElementAnimations"
        (itemClicked)="
            setAnimationWithSettingOnElement({
                direction: {
                    name: 'Direction',
                    value: animationDirectionValues[selectedElementAnimations.type].down
                }
            })
        ">
        <div class="custom-item">
            <ui-svg-icon
                class="custom-column icon"
                icon="direction-down"></ui-svg-icon>
            <div
                class="custom-text"
                id="timeline-animation-direction-down">
                Down
            </div>
        </div>
    </ui-dropdown-item>
</ui-dropdown>

<masking-svgs></masking-svgs>
