export const SNAP_TOLERANCE = 8;
export const SCROLL_PADDING_RIGHT = 50;
export const SCROLL_PADDING_BOTTOM = 20;
export const SCROLLBAR_HANDLE_WIDTH = 4;
export const SCROLLBAR_TRACK_WIDTH = 12;

export const TIMERULER_HEIGHT = 24;
export const ZOOM_WIDTH = 26;
export const HEIGHT_OFFSET = 30;
export const DEFAULT_LEFTPANEL_WIDTH = 180;
export const MAX_TIMELINE_DURATION = 2000;

// Timeline element
export const TIMELINE_ELEMENT_VERTICAL_PADDING = 6;
export const TIMELINE_ELEMENT_CANVAS_PADDING = 5; // Should be synced with --canvas-padding css var
export const TIMELINE_ELEMENT_HEIGHT = 26; // Should be synced with --timeline-row-height css var
export const TIMELINE_ELEMENT_DURATION_BOX_HEIGHT = 14;
export const TIMELINE_ELEMENT_BORDER_COLOR = `rgba(27, 117, 221)`; // '#1B75DD';
export const TIMELINE_ELEMENT_BORDER_COLOR_FADED = `rgba(27, 117, 221, 0.4)`; // '#1B75DD';

// Timeline group element
export const TIMELINE_GROUP_BACKDROP_PADDING = 1;
export const TIMELINE_GROUP_BACKDROP_DASH_PADDING = 1.5;
export const TIMELINE_GROUP_CHILD_HORIZONTAL_OFFSET = 3;
export const TIMELINE_GROUP_HIDDEN_OPACITY = 0.4;
export const TIMELINE_GROUP_ADD_COLOR = '#2FA940';
export const TIMELINE_GROUP_NAME_COLOR = '#363636';
export const TIMELINE_GROUP_HOVER_OUTLINE_COLOR = '#4F4F4F';
export const TIMELINE_GROUP_SELECTED_BORDER_COLOR = '#03AAFF';
export const TIMELINE_GROUP_CLAMP_COLOR = '#9B9B9B';
export const TIMELINE_GROUP_CLAMP_ACTIVE_COLOR = '#363636';
export const TIMELINE_GROUP_BACKDROP_COLOR = '#F5F5F5';
export const TIMELINE_GROUP_BACKDROP_ACTIVE_COLOR = '#FFFBE9';
export const TIMELINE_GROUP_BACKDROP_STROKE_COLOR = '#9B9B9B';
export const TIMELINE_GROUP_BACKDROP_STROKE_ACTIVE_COLOR = '#363636';
