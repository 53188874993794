export function _performanceMark(name: string): void {
    if (!performance || !performance.mark) {
        return;
    }
    performance.mark(name);
}

type Measure = { end: number; start: number };

const measures = new Map<string, { start: number; entries: Measure[] }>();

export function measureStart(name: string): void {
    if (!performance || !performance.mark) {
        return;
    }

    if (!measures.has(name)) {
        measures.set(name, {
            start: performance.now(),
            entries: []
        });
    }

    const measure = measures.get(name);
    measure!.entries.push({ start: performance.now(), end: Infinity });
}

export function measureEnd(name: string): void {
    if (!performance || !performance.mark || !measures.has(name)) {
        return;
    }

    const measure = measures.get(name)!;
    const lastEntry = measure.entries[measure.entries.length - 1];
    lastEntry.end = performance.now();

    // auto-log and clear after 500 entries
    if (measure.entries.length >= 500) {
        printMeasures();
        measures.delete(name);
    }
}

export function printMeasures(): void {
    console.log('printing measures');
    for (const [name, measure] of measures) {
        console.groupCollapsed(name);
        console.info(
            'Avg:',
            measure.entries.reduce((acc, curr) => acc + (curr.end - curr.start), 0) /
                measure.entries.length
        );
        console.info(
            'Max:',
            measure.entries.reduce((acc, curr) => Math.max(acc, curr.end - curr.start), 0)
        );
        console.info(
            'Min:',
            measure.entries.reduce((acc, curr) => Math.min(acc, curr.end - curr.start), 0)
        );
        console.info('Entries:', measure.entries.length);
        console.info('Total time:', performance.now() - measure.start, 'ms');
        console.groupEnd();
    }
}
