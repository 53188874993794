import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ISelectableSize, ISizeCollection } from '@studio/domain/components/size-list.types';

export const SizeAddInitActions = createActionGroup({
    source: 'SizeAdd',
    events: {
        initialize: props<{ sizes: ISelectableSize[]; collections: ISizeCollection[] }>(),
        reset: emptyProps()
    }
});

export const SizeAddSelectionActions = createActionGroup({
    source: 'SizeAdd',
    events: {
        'set selected collections': props<{ selectedCollectionIds: string[] }>(),
        'select all sizes': emptyProps(),
        'deselect all sizes': emptyProps(),
        'increase selected size count': props<{ size: ISelectableSize; byNumber: number }>(),
        'add size to custom sizes collection': props<{ size: ISelectableSize }>(),
        'clear selection': emptyProps()
    }
});
