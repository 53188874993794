import { IAction, IActionOperation } from '@domain/action';
import { IAnimation, IAnimationKeyframe } from '@domain/animation';
import { IColor } from '@domain/color';
import { IBoundingBox, IOrigin, IScale, ISize } from '@domain/dimension';
import { MaskNode, OneOfMaskableElementDataNodes } from '@domain/mask';
import {
    IElementDataNode,
    ITextElementDataNode,
    NumberNodeProperties,
    OneOfElementDataNodes,
    OneOfElementPropertyKeys,
    OneOfTextDataNodes
} from '@domain/nodes';
import { serializableStateProperties, textAndCharacterProperties } from '@domain/property';
import { IState, OneOfStatePropertyKeys, StateProperties } from '@domain/state';
import {
    FILTER_MAP,
    IBorder,
    IFilterMap,
    IPadding,
    IRadius,
    IShadow,
    ITextShadow
} from '@domain/style';
import { animatableProperties, AnimatableProperty, AnimatablePropertyValue } from '@domain/transition';
import { getBoundsOfScaledRectangle } from '@studio/utils/geom';
import { isUUID, uuidv4 } from '@studio/utils/id';
import { deepEqual, isNumber, lerp, omit, toDegrees } from '@studio/utils/utils';
import { isReservedAction } from '../actions/actions.utils';
import {
    getAllKeyframes,
    getNextTime,
    getPreviousTime,
    isTimeAt,
    toRelativeTime
} from '../animation.utils';
import { Color } from '../color';
import { mixColor, parseColor } from '../color.utils';
import { getTimingFunctionFromKeyframe, getTimingFunctionValueBetween } from '../timing-functions';
import { formulaToValue, isFormulaValue } from './formula.utils';

const AdditiveProperties = ['x', 'y', 'rotationX', 'rotationY', 'rotationZ'];

/**
 * Element properties that need to be scaled in order to scale a creative to any particular size
 */
export const scalableProperties = [
    'x',
    'y',
    'width',
    'height',
    'scaleX',
    'scaleY',
    'fitPositionX',
    'fitPositionY',
    'radius',
    'border',
    'fontSize',
    'padding',
    'shadows',
    'textShadows'
];

export const propertyMaxValues = {
    opacity: 1
};

export const propertyMinValues = {
    radius: 0,
    opacity: 0,
    scaleX: 0,
    scaleY: 0
};

export const DEFAULT_EMPTY_STATE: Readonly<IState> = Object.freeze({
    ratio: 1
});

export function createEmptyState(name?: string): IState {
    const state: IState = {
        ...DEFAULT_EMPTY_STATE,
        id: uuidv4()
    };

    if (name !== undefined) {
        state.name = name;
    }

    return state;
}

export function isEmptyState(state: IState): boolean {
    return getPropertyKeysInStates(state).length === 0;
}

/**
 * Merges all states with current element values
 * @param timeStates
 * @param element
 */
export function mergeStates(
    timeStates: ITimeState[],
    element: IElementDataNode,
    lerpAdditive?: boolean
): IState<number> {
    const merged: IState<number> = {};

    // Last states will overwrite previous states so reverse the array to prio actions and animation order...
    timeStates.forEach(timeState => {
        const state = timeState.state;
        const ratio = timeState.rate !== undefined ? timeState.rate : 1;

        if (ratio === 0) {
            return;
        }

        const properties = Object.keys(state) as Partial<OneOfStatePropertyKeys>[];

        for (const property of properties) {
            if (state[property] !== undefined) {
                switch (property) {
                    case 'x':
                    case 'y':
                    case 'scaleX':
                    case 'scaleY':
                    case 'rotationX':
                    case 'rotationY':
                    case 'rotationZ':
                    case 'opacity':
                    case 'originX':
                    case 'originY': {
                        const currentValue =
                            merged[property] ?? (isAdditiveProperty(property) ? 0 : element[property]);
                        const value = state[property];

                        merged[property] = mergeNumbers(
                            property as AnimatableProperty,
                            currentValue,
                            value,
                            ratio,
                            lerpAdditive
                        );

                        break;
                    }

                    default:
                        merged[property as string] = mergeProperty(
                            property as AnimatableProperty,
                            merged[property] || element[property],
                            state[property],
                            ratio
                        );
                }
            } else {
                merged[property] = state[property];
            }
        }
    });
    return merged;
}

export function mergeProperty<T extends AnimatablePropertyValue>(
    property: AnimatableProperty,
    from: T | undefined,
    to: T | undefined,
    ratio: number
): AnimatablePropertyValue | undefined {
    switch (property) {
        case 'x':
        case 'y':
        case 'scaleX':
        case 'scaleY':
        case 'rotationX':
        case 'rotationY':
        case 'rotationZ':
        case 'opacity':
        case 'originX':
        case 'originY':
            return mergeNumbers(property, from as number, to as number, ratio);
        case 'radius':
            return mergeRadius(from as IRadius, to as IRadius, ratio);
        case 'fill':
        case 'textColor':
            return mergeColors(from as IColor, to as IColor, ratio);
        case 'shadows':
            return mergeShadows(from as IShadow[], to as IShadow[], ratio);
        case 'filters':
            return mergeFilters(ratio, from as IFilterMap, to as IFilterMap);
        case 'border':
            return mergeBorders(from as IBorder, to as IBorder, ratio);
        default:
            // For non numerical values, only set value when rate is 1
            if (ratio === 1) {
                return to;
            }
            break;
    }
}

/**
 * Merge two shadows with eachother. Amount control where they should meet.
 * @param fromShadows
 * @param toShadows
 * @param amount
 */
function mergeShadows<Shadow extends ITextShadow | IShadow>(
    fromShadows: Shadow[],
    toShadows: Shadow[],
    amount: number
): Shadow[] {
    const shadows: ITextShadow[] | IShadow[] = [];

    // Total amount of shadows
    const length = Math.max(fromShadows?.length || 0, toShadows?.length || 0);

    for (let i = 0; i < length; i++) {
        let from = fromShadows && fromShadows[i];
        let to = toShadows && toShadows[i];

        const fromColor = from?.color;
        const toColor = to?.color;

        if (!from) {
            from = { ...to! };
        }
        if (!to) {
            to = { ...from };
        }

        shadows[i] = {
            blur: Math.max(0, lerp(from.blur, to.blur, amount)),
            offsetX: lerp(from.offsetX, to.offsetX, amount),
            offsetY: lerp(from.offsetY, to.offsetY, amount),
            color: mergeColors(fromColor, toColor, amount)
        };

        if (isSpreadShadow(from) && isSpreadShadow(to) && from.spread !== undefined) {
            (shadows[i] as IShadow).spread = Math.max(0, lerp(from.spread, to.spread, amount));
        }
    }

    return shadows as Shadow[];
}

function isSpreadShadow(value: IShadow | ITextShadow): value is IShadow {
    return 'spread' in value;
}

function mergeFilters(amount: number, from: IFilterMap = {}, to: IFilterMap = {}): IFilterMap {
    const filterKeySet = new Set([...Object.keys(from), ...Object.keys(to)]) as Set<keyof IFilterMap>;

    return [...filterKeySet]
        .filter(key => from[key] !== undefined || to[key] !== undefined)
        .reduce((memo, key) => {
            const filter = FILTER_MAP[key];
            return {
                ...memo,
                [key]: {
                    value: lerp(
                        from[key]?.value ?? (filter?.default || 0),
                        to[key]?.value ?? (filter?.default || 0),
                        amount
                    )
                }
            };
        }, {});
}

function mergeBorders(
    from: IBorder | undefined,
    to: IBorder | undefined,
    amount: number
): IBorder | undefined {
    if (from || to) {
        from = from || { ...to!, thickness: 0 };
        to = to || { ...from, thickness: 0 };

        if (to && from) {
            return {
                thickness: Math.max(0, lerp(from.thickness, to.thickness, amount)),
                style: amount === 1 ? to.style : from.style, // Swap style when 100% in
                color: mixColor(from.color, { color: to.color, amount })
            };
        }
    }
}

function mergeColors(from: IColor | undefined, to: IColor | undefined, amount: number): IColor {
    if (!to) {
        to = parseColor(from!);
        to.alpha = 0;
    }
    if (!from) {
        from = parseColor(to);
        from.alpha = 0;
    }
    const tint = { color: to, amount };
    return mixColor(from, tint);
}

function mergeRadius(
    from: IRadius | undefined,
    to: IRadius | undefined,
    amount: number
): IRadius | undefined {
    if (to && from) {
        return {
            type: to.type,
            topLeft: mergeNumbers('radius', from.topLeft, to.topLeft, amount),
            topRight: mergeNumbers('radius', from.topRight, to.topRight, amount),
            bottomLeft: mergeNumbers('radius', from.bottomLeft, to.bottomLeft, amount),
            bottomRight: mergeNumbers('radius', from.bottomRight, to.bottomRight, amount)
        };
    }
}

function mergeNumbers(
    property: AnimatableProperty,
    from: number | undefined,
    to: number | undefined,
    ratio: number,
    lerpAdditive = false
): number {
    if (typeof to !== 'number') {
        if (typeof from === 'number') {
            return from;
        }
        throw new Error('Can only merge numeric values');
    }
    // Additive properties like x, y, rotations
    if (isAdditiveProperty(property) && !lerpAdditive) {
        if (typeof from !== 'number') {
            from = 0;
        }
        return limitNumberProperty(property, from + to * ratio);
    }
    // Average properties like scale etc
    else {
        if (typeof from !== 'number') {
            from = 1;
        }
        return limitNumberProperty(property, lerp(from, to, ratio));
    }
}

function limitNumberProperty(property: AnimatableProperty, value: number): number {
    const min = propertyMinValues[property];
    const max = propertyMaxValues[property];
    if (typeof min === 'number') {
        value = Math.max(min, value);
    }
    if (typeof max === 'number') {
        value = Math.min(max, value);
    }
    return value;
}

/**
 * Resolve values so they can be rendered.
 * Typically turn any formulas into numbers
 * @param element
 * @param animation
 * @param canvasSize
 * @param property
 * @param value
 */
export function resolveStateFormulaValue(
    element: OneOfElementDataNodes,
    animation: IAnimation,
    canvasSize: ISize,
    property: AnimatableProperty,
    value: StateProperties
): StateProperties {
    switch (property) {
        case 'x':
        case 'y':
        case 'scaleX':
        case 'scaleY':
        case 'rotationX':
        case 'rotationY':
        case 'rotationZ':
        case 'opacity':
        case 'originX':
        case 'originY':
            if ((typeof value === 'number' && !isNaN(value)) || value === undefined) {
                return value;
            }

            if ((typeof value === 'string' || typeof value === 'number') && isFormulaValue(value)) {
                return formulaToValue(value, {
                    element,
                    propertyName: property,
                    settings: animation.settings,
                    canvasSize
                });
            }

            if (typeof value === 'string' && !isNaN(+value)) {
                return +value;
            }

            throw new Error('Unexpected number value.');
    }

    return value;
}

export function getResolvedStatePropertyValue(
    value: StateProperties,
    property: AnimatableProperty,
    element?: OneOfElementDataNodes
): StateProperties {
    switch (property) {
        case 'rotationZ':
        case 'rotationX':
        case 'rotationY':
            return toDegrees((value as number) || 0) as StateProperties;
        case 'opacity':
        case 'radius':
            return value ?? element?.[property];
        default:
            if (typeof value === 'number') {
                return Math.round(value as number) || 0;
            }
            return value;
    }
}

export function getValueFromKeyframe(
    property: AnimatableProperty,
    keyframe: IAnimationKeyframe,
    animation: IAnimation,
    element: OneOfElementDataNodes,
    canvasSize: ISize
): StateProperties {
    const state = getStateById(element, keyframe.stateId);
    const value = state?.[property];
    return resolveStateFormulaValue(element, animation, canvasSize, property, value);
}

export function getViewElementPropertyValue<Node extends OneOfElementDataNodes, Key extends keyof Node>(
    propertyKey: Key,
    element: Node,
    mergedState: IState<number> = {},
    scale = 1
): Node[Key] {
    // Re-cast to make cases respect narrowing
    const key = propertyKey as OneOfElementPropertyKeys;

    switch (key) {
        case 'width':
        case 'height':
        case 'fontSize': {
            if (isScalableProperty(key)) {
                return scaleNumericValue(element[key], scale) as Node[Key];
            }

            return element[key];
        }

        case 'x':
        case 'y':
        case 'scaleX':
        case 'scaleY':
        case 'originX':
        case 'originY':
        case 'opacity':
        case 'rotationX':
        case 'rotationY':
        case 'rotationZ': {
            const stateValue = mergedState[key];
            const numericValue = getNumericPropertyValue(key, element[key], stateValue);

            if (isScalableProperty(key)) {
                return scaleNumericValue(numericValue, scale) as Node[Key];
            }

            return numericValue as Node[Key];
        }

        case 'fill':
        case 'textColor': {
            const value = (mergedState[key] || element[key]) as IColor | undefined;

            if (!value) {
                return undefined as Node[Key];
            }

            return new Color(value) as Node[Key];
        }

        case 'border': {
            const value = mergedState[key] || element[key];

            if (!value) {
                return undefined as Node[Key];
            }

            return getViewElementBorder(value, scale) as Node[Key];
        }

        case 'shadows': {
            const value = mergedState[key] || element[key];

            if (!value) {
                return undefined as Node[Key];
            }

            return value.map(shadow => getShadowPropertyValue(shadow, scale)) as Node[Key];
        }

        case 'textShadows': {
            const value = (element as OneOfTextDataNodes)[key];

            if (!value) {
                return undefined as Node[Key];
            }

            return value.map(shadow => getTextShadowPropertyValue(shadow, scale)) as Node[Key];
        }

        case 'imageAsset':
        case 'imageSettings':
            return { ...(mergedState[key] || element[key]) };

        case 'padding': {
            const value = (mergedState[key] || element[key]) as IPadding | undefined;

            if (!value) {
                return undefined as Node[Key];
            }

            return getViewElementPadding(mergedState[key] || element[key], scale) as Node[Key];
        }

        case 'radius': {
            const value = mergedState[key] || element[key];

            return {
                ...value,
                topLeft: value.topLeft * scale,
                topRight: value.topRight * scale,
                bottomLeft: value.bottomLeft * scale,
                bottomRight: value.bottomRight * scale
            } as Node[Key];
        }

        default:
            return (mergedState[key] || element[key]) as Node[Key];
    }
}

function isScalableProperty(property: OneOfElementPropertyKeys): boolean {
    return scalableProperties.includes(property);
}

function getNumericPropertyValue(
    property: NumberNodeProperties,
    value: number,
    stateValue?: number
): number {
    if (isAdditiveProperty(property)) {
        return value + (stateValue || 0);
    } else {
        return typeof stateValue === 'number' ? stateValue : stateValue || value;
    }
}

function scaleNumericValue(value: number, scale: number): number {
    return value * scale;
}

export function getShadowPropertyValue(shadow: IShadow, scale: number): IShadow {
    const newShadow = { ...shadow };
    newShadow.offsetX *= scale;
    newShadow.offsetY *= scale;
    newShadow.spread *= scale;
    newShadow.blur *= scale;
    return newShadow;
}

export function getTextShadowPropertyValue(shadow: ITextShadow, scale: number): ITextShadow {
    const newShadow = { ...shadow };
    newShadow.offsetX *= scale;
    newShadow.offsetY *= scale;
    newShadow.blur *= scale;
    return newShadow;
}

function getViewElementBorder(stateValueOrValue: IBorder, scale: number): IBorder {
    return {
        thickness: stateValueOrValue.thickness * scale,
        color: stateValueOrValue.color,
        style: stateValueOrValue.style
    };
}

function getViewElementPadding(stateValueOrValue: IPadding, scale: number): IPadding {
    return {
        bottom: stateValueOrValue.bottom * scale,
        top: stateValueOrValue.top * scale,
        right: stateValueOrValue.right * scale,
        left: stateValueOrValue.left * scale
    };
}
/**
 * Merge an animation to a "relative" state. Pass extra states to show states not added by timeline
 */
export function animationsToState(
    element: OneOfElementDataNodes,
    canvasSize: ISize,
    time = 0,
    states: ITimeState[] = []
): IState<number> {
    // Merge states from actions
    states = mergeActionStates(element, states);

    element.animations
        .filter(animation => !animation.hidden)
        .forEach(animation => {
            const animationStates = animationToStates(element, animation, canvasSize, time).filter(
                state => !states.some(s => state.state === s.state)
            ); // Make sure we don't render states twice

            states.unshift(...animationStates);
        });

    return mergeStates(states, element);
}

export function maskingAnimationsToState(
    maskNode: OneOfMaskableElementDataNodes & MaskNode,
    maskedNode: OneOfMaskableElementDataNodes,
    canvasSize: ISize,
    time = 0,
    states: ITimeState[] = []
): IState<number> {
    const allAnimationStates = mergeActionStates(maskedNode, states);

    for (const node of [maskNode, maskedNode]) {
        node.animations
            .filter(animation => !animation.hidden)
            .forEach(animation => {
                const animationStates = animationToStates(node, animation, canvasSize, time).filter(
                    state => !states.some(s => state.state === s.state)
                ); // Make sure we don't render states twice

                allAnimationStates.unshift(...animationStates);
            });
    }

    return mergeStates(allAnimationStates, maskNode);
}

export function mergeActionStates(element: OneOfElementDataNodes, states: ITimeState[]): ITimeState[] {
    if (states.length > 1) {
        const maxRate = Math.max(...states.map(s => s.rate), 0);

        // No need to spend cpu on when completely "faded" out
        if (maxRate === 0) {
            return [];
        }

        // States scaled so highest rate is 1
        const normalizedStates = states.map(s => {
            // Later all values will be scaled by maxRate so compensate for that
            const rate = s.rate / maxRate;
            return {
                rate,
                state: s.state
            };
        });
        const state = mergeStates(normalizedStates, element, true);
        return [{ rate: maxRate, state }];
    }

    // Return shallow clone to not overwrite original
    return states.slice(0);
}

export function getStateById(element: OneOfElementDataNodes, id?: string): IState | undefined {
    if (element.states?.length && id) {
        return element.states.find(state => state.id === id);
    }
}

export function cloneState(state: IState): IState {
    const clone = {};
    for (const property in state) {
        const value = state[property];
        const type = typeof value;

        if (type === 'string' || type === 'number' || type === 'object') {
            clone[property] = value;
        }
    }
    return clone;
}

/**
 * Calulate a state of an animation at a certain point in time.
 * Note: Don't use this to render element
 */
export function calculateStateFromAnimationAtTime(
    element: OneOfElementDataNodes,
    animation: IAnimation,
    canvasSize: ISize,
    time = 0
): IState {
    const states = animationToStates(element, animation, canvasSize, time);
    return omit(mergeStates(states, element), 'id');
}

/**
 * Go through all properties in a state and resolve any formulas found
 * @param element
 * @param animation
 * @param canvasSize
 * @param state
 * @returns
 */
function resolveStateFormulas(
    element: OneOfElementDataNodes,
    animation: IAnimation,
    canvasSize: ISize,
    state: IState
): IState<number> {
    const resolvedState: IState<number> = {};
    // Resolve any formulas
    for (const key in state) {
        resolvedState[key] = resolveStateFormulaValue(
            element,
            animation,
            canvasSize,
            key as AnimatableProperty,
            state[key]
        );
    }
    return resolvedState;
}

/**
 * Get all states from an animation with a "rate"
 * which is how much from 0 to 1 that this state should be "visible"
 */
export function animationToStates(
    element: OneOfElementDataNodes,
    animation: IAnimation,
    canvasSize: ISize,
    absoluteTime = 0
): ITimeState[] {
    const states: ITimeState[] = [];
    const { keyframes } = animation;
    if (!keyframes?.length || !isTimeAt(absoluteTime, element)) {
        return states;
    }

    const time = toRelativeTime(element, absoluteTime);
    const tolerance = 0.000001;

    const prev = getPreviousTime(keyframes, time, tolerance);
    const next = getNextTime(keyframes, time, tolerance);
    const prevState = prev?.stateId && {
        ...resolveStateFormulas(element, animation, canvasSize, getStateById(element, prev.stateId)!)
    };
    const nextState = next?.stateId && {
        ...resolveStateFormulas(element, animation, canvasSize, getStateById(element, next.stateId)!)
    };

    // Should only happen if no keyframes is defined
    if (!prev && !next) {
        throw new Error('Animation has no keyframes');
    }
    // When within the duration of a keyframe,
    else if (prev === next || !next || (prev && isTimeAt(time, prev))) {
        // Ignore double default state case
        if (prevState) {
            states.push({ state: prevState, rate: 1 });
        }
    }
    // In between two states => apply easing
    else if (prev && next) {
        const timingFunction = getTimingFunctionFromKeyframe(animation, next);
        const rate = getTimingFunctionValueBetween(time, prev, next, timingFunction);

        // Inverted rate when animating to the default state.
        // Between states the previous value should always be at 100%
        if (prevState) {
            // Both states defined
            if (nextState) {
                const props = getPropertyKeysInStates(prevState, nextState);

                // Make sure both states have the same values
                props.forEach(p => {
                    const defaultValue = isAdditiveProperty(p) ? 0 : element[p];

                    if (prevState[p] === undefined && defaultValue !== undefined) {
                        prevState[p] = defaultValue;
                    }
                    if (nextState[p] === undefined) {
                        nextState[p] =
                            defaultValue !== undefined
                                ? defaultValue
                                : mergeProperty(p, prevState[p], defaultValue, 1);
                    }
                });

                const additive = getAdditiveStateProperties(prevState);
                const nonAdditive = getNonAdditiveStateProperties(prevState);
                if (nonAdditive) {
                    states.push({ state: nonAdditive, rate: 1 });
                }
                if (additive) {
                    states.push({ state: additive, rate: 1 - rate });
                }
            } else {
                states.push({ state: prevState, rate: 1 - rate });
            }
        }
        if (nextState) {
            states.push({ state: nextState, rate });
        }
    }
    return states;
}

export function setNewPropertyIds(
    originalElement: OneOfElementDataNodes,
    newElement: OneOfElementDataNodes
): Map<string, string> {
    const idChangeMap = new Map<string, string>();
    function isActionOperation(value: unknown): value is IActionOperation {
        return !!value && typeof value === 'object' && 'target' in value && 'method' in value;
    }

    function setNewIdOnItem(
        item: IAction | IActionOperation | IState | IAnimation | IAnimationKeyframe
    ): void {
        const newId = uuidv4();
        let oldId: string | undefined;

        if (isActionOperation(item)) {
            oldId = item.value;
            item.value = newId;
        } else {
            oldId = item.id;
            item.id = newId;
        }

        idChangeMap.set(oldId!, newId);
    }
    const stateIdsMap = {};
    for (const state of newElement.states) {
        const oldId = state?.id;
        if (oldId) {
            setNewIdOnItem(state);
            stateIdsMap[oldId] = state.id;
        }
    }
    for (const animation of newElement.animations) {
        setNewIdOnItem(animation);
        for (const keyframe of animation.keyframes) {
            setNewIdOnItem(keyframe);
            /**
             * Migrated IDs (e.g keyframe-0) may be the same
             * on mulitple elements hence we need to set the stateId
             * reference here right away. The ID patching is otherwise
             * done in designView.patchPropertyIds.
             * This can probably be removed once all design elements
             * has been migrated.
             */
            const keyframeStateId = keyframe?.stateId;
            if (keyframeStateId && stateIdsMap[keyframeStateId] && !isUUID(keyframeStateId)) {
                keyframe.stateId = stateIdsMap[keyframeStateId];
            }
        }
    }

    for (const action of newElement.actions) {
        setNewIdOnItem(action);
    }

    /**
     * Validate that all ids are unique
     */

    const idSet = new Set<string>();

    const properties: OneOfElementPropertyKeys[] = ['animations', 'actions', 'states'];

    const recursiveFindIds = (object: unknown): void => {
        if (typeof object !== 'object' || !object) {
            return;
        }

        if ('id' in object && typeof object.id === 'string') {
            if (idSet.has(object.id)) {
                throw new Error('Duplicate IDs found.');
            }

            if (object.id) {
                idSet.add(object.id);
            }
        }

        if (Array.isArray(object)) {
            for (const prop of object) {
                recursiveFindIds(prop);
            }
        } else {
            for (const prop in object) {
                recursiveFindIds(object[prop]);
            }
        }
    };

    for (const key of Object.keys(originalElement) as OneOfElementPropertyKeys[]) {
        if (!properties.includes(key)) {
            continue;
        }
        recursiveFindIds(originalElement[key]);
        recursiveFindIds(newElement[key]);
    }

    return idChangeMap;
}

export function isStateProperty(property: string): property is OneOfStatePropertyKeys {
    return !!property && serializableStateProperties.indexOf(property as OneOfStatePropertyKeys) > -1;
}

export function isTextState(
    state: IState | OneOfElementDataNodes,
    property: OneOfElementPropertyKeys
): state is IState & ITextElementDataNode {
    return property in textAndCharacterProperties;
}

export function isReservedActionState(element: OneOfElementDataNodes, state: IState): boolean {
    return element.actions.some(
        action => isReservedAction(action) && action.operations.some(op => op.value === state.id)
    );
}

export function isAnimationState(element: OneOfElementDataNodes, state: IState): boolean {
    if (!state.id) {
        return false;
    }
    return getAllKeyframes(element).some(keyframe => keyframe.stateId === state.id);
}

/**
 * Check if all bounds provided have the same size & position
 * @param bounds
 */
export function isEqualStates(...states: (IState | undefined)[]): boolean {
    if (!states || states.length < 2) {
        throw new Error('2 or more states must be passed to compare them');
    }

    // Id is irrelevant
    states = states.map(s => s && omit(s, 'id'));

    const first = states[0];
    const toCompare = states.slice(1);

    if (toCompare.some(s => !deepEqual(first, s))) {
        return false;
    }
    return true;
}

export function getReservedStates(element: OneOfElementDataNodes): IState[] {
    return element.states.filter(state => isReservedActionState(element, state));
}

/**
 * Custom states that are states that are not related to
 * reserved States (actions) and not related to any keyframe animation
 */
export function getCustomStates(element: OneOfElementDataNodes): IState[] {
    return element.states.filter(
        state => !isAnimationState(element, state) && !isReservedActionState(element, state)
    );
}

export function getBoundingBoxOfElementWithState(
    element: IBoundingBox | OneOfElementDataNodes,
    state: IState = {},
    includeScale?: boolean
): IBoundingBox {
    const scaleX = 'scaleX' in element && isNumber(element.scaleX) ? element.scaleX : 1;
    const scaleY = 'scaleY' in element && isNumber(element.scaleY) ? element.scaleY : 1;
    const originX = 'originX' in element && isNumber(element.originX) ? element.originX : 0.5;
    const originY = 'originY' in element && isNumber(element.originY) ? element.originY : 0.5;

    const box: IBoundingBox & IScale & IOrigin = {
        x: element.x + (isNumber(state.x) ? state.x : 0),
        y: element.y + (isNumber(state.y) ? state.y : 0),
        scaleX: scaleX * (isNumber(state.scaleX) ? state.scaleX : 1),
        scaleY: scaleY * (isNumber(state.scaleY) ? state.scaleY : 1),
        originX: isNumber(state.originX) ? state.originX : originX,
        originY: isNumber(state.originY) ? state.originY : originY,
        // Note state shouldn't have width and height ATM
        width: element.width,
        height: element.height,
        rotationZ: (element.rotationZ || 0) + (isNumber(state.rotationZ) ? state.rotationZ : 0)
    };

    if (includeScale) {
        return getBoundsOfScaledRectangle(box);
    }

    return box;
}

export function getPropertyKeysInStates(...states: (IState | undefined)[]): AnimatableProperty[] {
    const keys: AnimatableProperty[] = [];
    states.forEach(state => {
        for (const k in state) {
            const key = k as AnimatableProperty;
            if (
                state[key] !== undefined &&
                keys.indexOf(key) === -1 &&
                animatableProperties.indexOf(key) > -1
            ) {
                keys.push(key);
            }
        }
    });
    return keys;
}

export function isAdditiveProperty(property: string): boolean {
    return AdditiveProperties.indexOf(property) > -1;
}

export function getAdditiveStateProperties(state: IState): IState<number> | undefined {
    const additive: IState<number> = {};

    for (const key in state) {
        if (isAdditiveProperty(key)) {
            additive[key] = state[key];
        }
    }
    return !isEmptyState(additive) ? additive : undefined;
}

export function getNonAdditiveStateProperties(state: IState): IState<number> | undefined {
    const nonAdditive: IState<number> = {};

    for (const key in state) {
        if (!isAdditiveProperty(key)) {
            nonAdditive[key] = state[key];
        }
    }
    return Object.keys(nonAdditive).length ? nonAdditive : undefined;
}

export interface ITimeState {
    /**
     * How much from 0-1 this states is active.
     * Note that when elastic easings are in use this number may be a bit over 1.
     */
    rate: number;

    /**
     *
     */
    state: Partial<IState<number>>;
}
