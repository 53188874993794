<div class="size-header">
    <div class="header left">
        @if (!(isMobileShowcase$ | async)) {
            @if (selected$ | async) {
                <ui-svg-icon
                    class="checked"
                    icon="checkbox-true"
                    (click)="deselectGroup()">
                </ui-svg-icon>
                <span
                    (click)="deselectGroup()"
                    class="select-all">
                    Deselect all
                </span>
            } @else {
                <ui-svg-icon
                    class="unchecked"
                    icon="checkbox-false"
                    (click)="selectGroup()">
                </ui-svg-icon>
                <span
                    (click)="selectGroup()"
                    class="select-all">
                    Select all
                </span>
            }
        }
    </div>

    <div class="center header">
        <div
            class="creative-size-fake-input"
            [ngClass]="{ editing: isEditing, hover: hovered }">
            <span
                class="creative-size-name"
                [ngClass]="{ editing: isEditing, saving: isSaving }"
                >{{ group.size.width }} ×
                {{ group.size.height }}
            </span>
            @if (group.size.name && !isEditing) {
                <span
                    class="size-name"
                    truncateSpan
                    [spanText]="group.size.name">
                </span>
            }
            <ng-container *permissions="'Default'">
                @if (isEditing) {
                    <input
                        type="text"
                        [ngModel]="group.size.name"
                        class="name-input"
                        [maxlength]="250"
                        #nameInput
                        placeholder="Name"
                        [style.width.px]="(sizeNameCalc && sizeNameCalc.scrollWidth + 15) || 30"
                        (click)="$event.stopPropagation()"
                        (blur)="saveSizeName(nameInput.value)"
                        (keyup.enter)="saveSizeName(nameInput.value)"
                        (keyup.esc)="cancelEditSizeName()"
                        (clickOutside)="saveSizeName(nameInput.value)" />
                    <div
                        #sizeNameCalc
                        class="size-name-calc">
                        {{ group.size.name }}
                    </div>
                }
                @if (isSaving) {
                    <div class="edit-loader">
                        <ui-loader></ui-loader>
                    </div>
                }
                @if (!isSaving) {
                    <ui-svg-icon
                        *media="Breakpoint.DesktopUp"
                        id="creative-list-edit-size-name"
                        icon="edit"
                        (click)="editGroupName()"
                        (mouseover)="hovered = true"
                        (mouseout)="hovered = false"
                        [uiTooltip]="
                            group.size.name && group.size.name.length ? 'Rename size' : 'Add name'
                        "
                        class="hover-icon">
                    </ui-svg-icon>
                }
            </ng-container>
        </div>
    </div>
    <div class="header right">
        <div class="actions header">
            <ui-svg-icon
                id="creative-list-collapse-icon"
                [icon]="!group.collapsed ? 'arrow-up' : 'arrow-down'"
                class="collapse-icon"
                [class.collapsed]="group.collapsed"
                (click)="toggleCollapse()">
            </ui-svg-icon>
            <ui-svg-icon
                icon="kebab"
                class="group-dropdown"
                [uiDropdownTarget]="groupMenu"
                (click)="setSelectGroup()"></ui-svg-icon>
            <ui-dropdown
                #groupMenu
                [hasBackdrop]="true"
                type="menu">
                <ng-container *permissions="['Default', 'ChangeDesign']">
                    <ui-dropdown-item
                        [disabled]="!(canDeleteSize$ | async)"
                        id="creative-list-delete-size"
                        (click)="deleteSize(group.creatives[0])">
                        Delete
                    </ui-dropdown-item>
                    @if (canActivateDesigns) {
                        <ui-dropdown-item
                            id="creative-list-size-activate"
                            (click)="activateDesign(group.creatives[0])">
                            Activate size
                        </ui-dropdown-item>
                    } @else {
                        <ui-dropdown-item
                            id="creative-list-size-deactivate"
                            (click)="deactivateDesign(group.creatives[0])">
                            Deactivate size
                        </ui-dropdown-item>
                    }
                </ng-container>
                <ui-dropdown-item
                    id="creative-list-apply-filter"
                    (click)="applyGroupFilter(group.size)">
                    Apply filter
                </ui-dropdown-item>
                <ui-dropdown-item
                    id="creative-list-set-status"
                    *permissions="'Status'"
                    [uiDropdownTarget]="
                        statusDropdown && statusDropdown.dropdown ? statusDropdown.dropdown : uiDropDown
                    ">
                    Set status
                </ui-dropdown-item>
            </ui-dropdown>

            <status-dropdown #statusDropdown></status-dropdown>
            <div class="padding"></div>
        </div>
    </div>
</div>
