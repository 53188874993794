import { getFileExtension } from '@studio/utils/url';
import { HttpClient } from '@angular/common/http';
import { Injectable, RendererFactory2, Renderer2 } from '@angular/core';
import { Logger } from '@bannerflow/sentinel-logger';

@Injectable({ providedIn: 'root' })
export class FileDownloadService {
    private renderer: Renderer2;
    private logger = new Logger('FileDownloadService');

    constructor(
        private http: HttpClient,
        rendererFactory: RendererFactory2
    ) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    download(uri: string, name?: string): void {
        this.http.get(uri, { responseType: 'blob' }).subscribe((response: Blob | MediaSource) => {
            const download = this.renderer.createElement('a') as HTMLElement;
            const _OBJECT_URL = URL.createObjectURL(response);

            // Set href as a local object URL
            this.renderer.setAttribute(download, 'href', _OBJECT_URL);

            const fileExtension = getFileExtension(uri);
            const fileName = name
                ? `${name}.${fileExtension}`
                : uri.split('/')[uri.split('/').length - 1];

            this.logger.verbose(`downloading [${fileName}]`);
            // Set name of download
            this.renderer.setAttribute(download, 'download', `${fileName}`);
            // Simulate click to trigger download
            download.dispatchEvent(
                new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window })
            );

            // Free up resources
            window.URL.revokeObjectURL(_OBJECT_URL);
        });
    }

    dataToFile(data: string | object, fileName: string): void {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style.display = 'none';
        const json = JSON.stringify(data, null, 4);
        const blob = new Blob([json], { type: 'octet/stream' });
        const extension = typeof data === 'string' ? 'txt' : 'json';
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = `${fileName}.${extension}`;
        a.click();
        window.URL.revokeObjectURL(url);
    }
}
