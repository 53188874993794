import { T } from '@creative/creative.container';
import { IAd } from '@domain/ad/ad';
import { ICustomEvent, ICustomProgressEvent } from '@domain/ad/tracking-data';
import { IUrlParameterMap } from '@domain/ad/url-parameters';
import { ICreativeEnvironment } from '@domain/creative/environment';
import { __inject, inject } from '@studio/utils/di';
import { IAdApi } from './declarations/ad-api';

export class AdApi implements IAdApi {
    parameters: IUrlParameterMap;
    customDomain?: string;

    constructor(
        @inject(T.AD) public ad: IAd,
        @inject(T.ENVIRONMENT) _env: ICreativeEnvironment
    ) {
        this.parameters = ad.parameters;
        this.customDomain = _env.CUSTOM_AD_DOMAIN;
    }

    trackCustomEvent(eventData: ICustomEvent): void {
        this.ad.tracking.trackCustomEvent(eventData);
    }

    trackCustomProgressEvent(eventData: ICustomProgressEvent): void {
        if (eventData.value < 0 && eventData.value > 1) {
            throw new Error('Event value for custom progess events has to be between 0 and 1');
        }
        this.ad.tracking.trackCustomProgressEvent(eventData);
    }
}

__inject(T.AD, {}, AdApi, '_ad', 0);
__inject(T.ENVIRONMENT, {}, AdApi, '_env', 1);
