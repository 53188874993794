<div class="size-selector-dialog">
    <div class="size-selector-header">
        <div class="header-filter">
            <ui-svg-icon icon="filter"></ui-svg-icon>
            <span>Filters</span>
        </div>
        <ui-svg-icon
            icon="close"
            (click)="closeDialog()"></ui-svg-icon>
    </div>

    <filter-list class="creative-filters"></filter-list>
</div>
