<studio-list-section
    id="versions"
    class="version-section"
    [selected]="selectedState()"
    (toggleAll)="toggleAllVersions($event)">
    <div
        sectionTitle
        class="section-title">
        <div class="section-label">Select versions</div>
        {{ selectedVersions }}/{{ versions.length }}
    </div>
    <div
        id="{{ version }}-{{ version.localization }}"
        *ngFor="let version of versions; let i = index"
        class="version"
        [ngClass]="{ added: version.selected }"
        (click)="toggleVersion(version)">
        <div class="version__wrapper">
            <div class="flag">
                <version-flag [localizationId]="version.localization.id"></version-flag>
            </div>
            <div class="value">
                {{ version.name }}
            </div>
        </div>
        <div class="add">
            <ui-checkbox
                class="add__checkbox"
                [selected]="!!version.selected"></ui-checkbox>
        </div>
    </div>
</studio-list-section>
