export type SocialPlacement = MetaPlacement | TiktokPlacement | PinterestPlacement | SnapchatPlacement;

export enum MetaPlacement {
    Instagram = 'meta-instagram',
    Facebook = 'meta-facebook',
    Messenger = 'meta-messenger',
    FacebookReels = 'meta-facebookreels',
    InstagramReels = 'meta-instagramreels'
}

export enum TiktokPlacement {
    Default = 'tiktok-default',
    After9s = 'tiktok-after-9s',
    After9sWithCard = 'tiktok-after-9s-with-card'
}

export enum PinterestPlacement {
    BrowseImage = 'browse-image',
    BrowseVideo = 'browse-video',
    WatchVideo = 'watch-video'
}

export enum SocialNetworkType {
    Meta = 'meta',
    TikTok = 'tiktok',
    Pinterest = 'pinterest',
    Snapchat = 'snapchat'
}

export enum SnapchatPlacement {
    Default = 'snapchat-default'
}

export interface ISocialPlacement {
    type: SocialPlacement;
    text: string;
    position: PlacementPostion;
}

export interface ICreativeSocialGuide {
    network: SocialNetworkType;
    placement: SocialPlacement;
    overlay: boolean;
    guidelines: boolean;
}

export interface PlacementPostion {
    top: number;
    bottom: number;
    left?: number;
    right?: number;
}
