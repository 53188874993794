import { Injectable } from '@angular/core';
import { UIDialogService } from '@bannerflow/ui';
import { UrlDialogComponent, IUrlDialogConfig } from './url-dialog.component';

/**
 * Too coupled with other parts (CreativeManageService) of
 * the app to be scoped to the MV module.
 * Refactoring needed before it can be scoped
 */
@Injectable({ providedIn: 'root' })
export class UrlDialogService {
    constructor(private uiDialogService: UIDialogService) {}

    async open(
        url: string | undefined,
        config?: IUrlDialogConfig
    ): Promise<string | 'cancel' | undefined> {
        const dialogRef = this.uiDialogService.openComponent(UrlDialogComponent, {
            headerText: 'Set target url',
            theme: 'default',
            width: 570
        });
        await dialogRef.afterViewInit;
        const updatedUrl = await (dialogRef.subComponentRef.instance as UrlDialogComponent).initiate(
            url,
            config
        );
        dialogRef.close();
        return updatedUrl;
    }
}
