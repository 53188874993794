import { Pipe, PipeTransform } from '@angular/core';
import { concatLatestFrom } from '@ngrx/operators';
import { IVersion } from '@domain/creativeset/version';
import { map, Observable, of } from 'rxjs';
import { VersionsService } from '../versions/state/versions.service';

@Pipe({
    standalone: true,
    name: 'versionFromId'
})
export class VersionFromIdPipe implements PipeTransform {
    constructor(private versionsService: VersionsService) {}

    // Receives a version ID, returns an Observable with the version object
    transform(value: string): Observable<IVersion> {
        return of(value).pipe(
            concatLatestFrom(() => this.versionsService.versions$),
            map(([versionId, versions]) => {
                return versions.find(({ id }) => id === versionId)!;
            })
        );
    }
}
