import type { AuthConfig } from '@auth0/auth0-angular';
import { environment } from '../../../environments/environment';

const STUDIO_SCOPE =
    'email offline_access profile openid studio design fontmanager bannerflowlibrary campaignservice commentservice videoconverter listservice bannerlingoservice';

const NO_AUTH_ORIGINS = [environment.origins.studioBlobStorage];

export const AUTH0_CONFIG: AuthConfig = {
    domain: environment.auth0.domain,
    clientId: environment.auth0.clientId,
    authorizationParams: {
        redirect_uri: `${window.location.origin}/${window.location.search}`,
        audience: 'https://bannerflow.com/resources/',
        scope: STUDIO_SCOPE
    },
    httpInterceptor: {
        allowedList: Object.values(environment.origins)
            .filter(origin => !NO_AUTH_ORIGINS.includes(origin))
            .map(origin => `${origin}/*`)
    },
    useRefreshTokens: true
};
