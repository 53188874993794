import { IAd } from './ad';

export enum AdEvents {
    /**
     * When creative becomes visible (preload or creative)
     */
    Render = 'render',

    /**
     * When preload is done
     */
    Preload = 'preload',

    /**
     * When preload is done
     */
    CreativeLoad = 'creativeload',

    /**
     * Initialization of Ad script is done and API is available
     */
    Init = 'init',

    /**
     * Click on creative is triggered
     */
    Click = 'click',

    /**
     * Creative is scrolled in view (at least one pixel is shown)
     */
    ViewEnter = 'viewenter',

    /**
     * Creative is scrolled out of view (nothing of the creative is shown)
     */
    ViewLeave = 'viewleave',

    /**
     * Creative is scrolled in view according (30% or 50% depending on ad size)
     */
    IABViewEnter = 'iab_viewenter',

    /**
     * Creative is scrolled out of view (30% or 50% depending on ad size)
     */
    IABViewLeave = 'iab_viewleave',

    /**
     * Page is ready for us to load animated creative
     */
    PageReady = 'ready',

    /**
     * Something made the Ad crash
     */
    Error = 'error',

    /**
     * When add is removed and memory should be freed.
     */
    Destroy = 'destroy',

    Play = 'play',

    Stop = 'stop',

    Seek = 'seek',

    Loop = 'loop',

    Pause = 'pause',

    Mute = 'mute',

    /**
     * Transparency and Consensus data.
     *
     * @link
     * https://bannerflow.atlassian.net/secure/RapidBoard.jspa?rapidView=40&modal=detail&selectedIssue=STUDIO-5168
     */
    TCData = 'tcdata'
}

export type AdEventType =
    | AdEvents.Render
    | AdEvents.Preload
    | AdEvents.CreativeLoad
    | AdEvents.Init
    | AdEvents.Click
    | AdEvents.ViewEnter
    | AdEvents.ViewLeave
    | AdEvents.IABViewEnter
    | AdEvents.IABViewLeave
    | AdEvents.PageReady
    | AdEvents.Play
    | AdEvents.Stop
    | AdEvents.Loop
    | AdEvents.Pause
    | AdEvents.Seek
    | AdEvents.Mute
    | AdEvents.Destroy
    | AdEvents.TCData
    | AdEvents.Error;

export type AdEventsType = {
    [AdEvents.Render]: void;
    [AdEvents.Preload]: void;
    [AdEvents.CreativeLoad]: void;
    [AdEvents.Init]: void;
    [AdEvents.Click]: string | undefined;
    [AdEvents.ViewEnter]: void;
    [AdEvents.ViewLeave]: void;
    [AdEvents.IABViewEnter]: void;
    [AdEvents.IABViewLeave]: void;
    [AdEvents.PageReady]: void;
    [AdEvents.Play]: IAdEventData;
    [AdEvents.Stop]: IAdEventData;
    [AdEvents.Loop]: IAdEventData;
    [AdEvents.Pause]: IAdEventData;
    [AdEvents.Seek]: IAdEventData;
    [AdEvents.Mute]: boolean | undefined;
    [AdEvents.Destroy]: void;
    [AdEvents.TCData]: void;
    [AdEvents.Error]: {
        event: Error | ErrorEvent | PromiseRejectionEvent | string;
        destroyAd?: boolean;
    };
};

export interface IAdEventData {
    loop: number;
    time: number;
    ad: IAd;
}
