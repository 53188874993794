<div
    class="header"
    (click)="onCollapseHeader($event)">
    <div class="inner">
        <div class="headline-icon">
            <ui-svg-icon
                class="collapse-button"
                [ngClass]="{ collapsed: isCollapsed() }"
                icon="arrow-up"></ui-svg-icon>
        </div>
        <div class="headline"><ng-content select="[sectionTitle]"></ng-content></div>
    </div>
    @if (selectable()) {
        <div
            #toggleAllCheckbox
            class="toggle-all"
            (click)="onToggleAll()">
            @if (selected(); as selected) {
                <ui-checkbox
                    class="checkbox"
                    [indeterminate]="selected === SectionSelectedState.SomeSelected"
                    [selected]="
                        selected === SectionSelectedState.AllSelected ||
                        selected === SectionSelectedState.SomeSelected
                    ">
                </ui-checkbox>
            }
        </div>
    }
</div>
<div
    class="section-body ui-scrollbar"
    [style.display]="isCollapsed() ? 'none' : 'block'">
    <ng-content></ng-content>
</div>
