import { Injectable } from '@angular/core';
import { IBoundingBox } from '@domain/dimension';
import { Subject } from 'rxjs';

@Injectable()
export class SelectionNetService {
    private _selectionNet$ = new Subject<IBoundingBox | undefined>();
    selectionNet$ = this._selectionNet$.asObservable();

    setSelectionNet(selectionBoundingBox: IBoundingBox | undefined): void {
        this._selectionNet$.next(selectionBoundingBox);
    }
}
