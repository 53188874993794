import {
    ActionOperationMethod,
    ActionTrigger,
    IAction,
    IActionOperation,
    ReservedActionId
} from '@domain/action';
import { ActionDto, OperationDto } from '@domain/api/generated/sapi';
import { OneOfElementDataNodes } from '@domain/nodes';
import { TimingFunctionKey } from '@domain/timing-functions';
import { createAction, isStateActionMethod } from '../actions/actions.utils';

export function convertActionToDto(action: IAction, elements?: OneOfElementDataNodes[]): ActionDto {
    const actionDto: ActionDto = {} as ActionDto;
    actionDto.id = action.id;
    actionDto.triggers = [...action.triggers];
    actionDto.disabled = action.disabled;
    actionDto.preventClickThrough = action.preventClickThrough;
    actionDto.templateId = action.templateId;
    actionDto.operations = action.operations.map(operation =>
        convertOperationToDto(operation, elements)
    );

    return actionDto;
}

function convertOperationToDto(
    operation: IActionOperation,
    elements?: OneOfElementDataNodes[]
): OperationDto {
    validateDeserializedOperation(operation, elements);

    const { method, value, target, animation } = operation;

    return {
        method,
        value: value,
        target,
        animation: typeof animation === 'object' ? { ...animation } : undefined
    };
}

export function validateDeserializedOperation(
    operation: IActionOperation,
    elements?: OneOfElementDataNodes[]
): void {
    if (operation.method === ActionOperationMethod.OpenUrl && typeof operation.value !== 'string') {
        throw new Error('Invalid value for action operation with method type OpenUrl.');
    }

    if (isStateActionMethod(operation.method)) {
        if (
            elements &&
            operation.value !== undefined &&
            !elements.find(el => el.states.find(state => state.id === operation.value))
        ) {
            throw new Error('Defined state id does not exist on any available elements.');
        } else if (!operation.target) {
            throw new Error(
                'A target value is required for actions with operation method of type SetState or RemoveState.'
            );
        }
    }
}

export function deserializeAction(actionDto: ActionDto, elements?: OneOfElementDataNodes[]): IAction {
    const { id, triggers, templateId, disabled, preventClickThrough } = actionDto;
    const action = createAction();

    action.id = id;
    action.triggers = triggers as ActionTrigger[];
    action.templateId = templateId as ReservedActionId;
    action.disabled = disabled;
    action.preventClickThrough = preventClickThrough;
    action.operations = deserializeActionOperations(actionDto, elements);
    return action;
}

function deserializeActionOperations(
    actionDto: ActionDto,
    elements?: OneOfElementDataNodes[]
): IActionOperation[] {
    return actionDto.operations.map(operationDto => {
        const animationDto = operationDto.animation
            ? {
                  timingFunction: operationDto.animation.timingFunction as TimingFunctionKey,
                  duration: operationDto.animation.duration || 0
              }
            : undefined;

        const operation: IActionOperation = {
            method: operationDto.method as ActionOperationMethod,
            value: operationDto.value,
            animation: animationDto,
            target: operationDto.target
        };

        validateDeserializedOperation(operation, elements);

        return operation;
    });
}
