import { validationMap } from '@domain/api/validations';
import { PropertyInputValidation } from '../property-validation';

type Keys = 'alpha' | 'borderThickness' | 'shadowOffset' | 'shadowBlur';

// Note: Filter validation is defined in libs/domain/style.ts
export const defaultPropertiesInputValidation: PropertyInputValidation<Keys> = {
    alpha: {
        min: 0,
        max: 100
    },
    borderThickness: validationMap.default.border,
    shadowOffset: validationMap.default.shadow.offset,
    shadowBlur: validationMap.default.shadow.blur
} as const;
