import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import {
    DirtyCharacterStylingElements,
    DirtyProperties
} from '@studio/domain/components/translation-page';
import * as fromTranslationPage from './translation-page.reducer';

export const getTranslationPageState = createFeatureSelector<fromTranslationPage.TranslationPageState>(
    fromTranslationPage.TRANSLATION_PAGE_FEATURE_KEY
);

export const getLoaded = createSelector(getTranslationPageState, state => state.loaded);

export const getInitialized = createSelector(getTranslationPageState, state => state.initialized);

export const getError = createSelector(getTranslationPageState, state => state.error);

export const getElements = createSelector(getTranslationPageState, state => state.elements);

export const getSelectedElement = createSelector(getTranslationPageState, state =>
    state.elements.find(({ id }) => id === state.selectedElementId)
);

export const getIsShowingAll = createSelector(getTranslationPageState, state => state.isShowingAll);

export const getCanSelectPrevious = createSelector(getTranslationPageState, state => {
    const selectedGroupIndex = state.groups.findIndex(({ id }) => id === state.selectedGroupId);
    return selectedGroupIndex - 1 >= 0;
});

export const getCanSelectNext = createSelector(getTranslationPageState, state => {
    const selectedGroupIndex = state.groups.findIndex(({ id }) => id === state.selectedGroupId);
    return selectedGroupIndex + 1 < state.groups.length;
});

export const getDirtyProperties = createSelector(
    getTranslationPageState,
    state => state?.dirtyProperties
);

export const getHasDirtyProperties = createSelector(getDirtyProperties, dirtyProperties => {
    return Object.values(dirtyProperties).some(value => {
        return !!Object.keys(value).length;
    });
});
export const getTranslatableElementsAmount = createSelector(getElements, elements => elements.length);

export const getHasTranslatableElements = createSelector(
    getTranslatableElementsAmount,
    amount => amount > 0
);

export const getDirtyPropertiesForVersion = (
    versionId: string
): MemoizedSelector<object, DirtyProperties> => {
    return createSelector(getDirtyProperties, dirtyProperties => {
        return dirtyProperties?.[versionId];
    });
};

export const getFeedSources = createSelector(getTranslationPageState, state => state.feedSources);

export const getGroups = createSelector(getTranslationPageState, state => state.groups);

export const getSelectedGroup = createSelector(getTranslationPageState, state =>
    state.groups.find(({ id }) => id === state.selectedGroupId)
);

export const getSelectedElements = createSelector(
    getIsShowingAll,
    getElements,
    getSelectedElement,
    getSelectedGroup,
    (isShowingAll, elements, selectedElement, selectedGroup) => {
        if (isShowingAll) {
            return elements;
        }
        if (selectedElement) {
            return [selectedElement];
        }
        if (selectedGroup) {
            return [...selectedGroup.elements];
        }
        return [];
    }
);

export const getDirtyCharacterStyling = createSelector(
    getTranslationPageState,
    state => state.dirtyCharacterStyling
);

export const getDirtyCharacterStylingForCreative = (
    creativeId: string
): MemoizedSelector<object, DirtyCharacterStylingElements | undefined> => {
    return createSelector(
        getDirtyCharacterStyling,
        (dirtyCharacterStyling): DirtyCharacterStylingElements | undefined => {
            return dirtyCharacterStyling?.[creativeId];
        }
    );
};

export const getUsedColors = createSelector(getTranslationPageState, state => state.usedColors);

export const getSelectedText = createSelector(getTranslationPageState, state => state.selectedText);
