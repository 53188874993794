import { CommonModule } from '@angular/common';
import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    ElementRef,
    QueryList,
    ViewChild
} from '@angular/core';
import { UIModule, UITooltipDirective } from '@bannerflow/ui';
import { OverflowItemComponent } from './overflow-item.component';

@Component({
    standalone: true,
    imports: [CommonModule, UIModule],
    selector: 'item-list-overflow',
    templateUrl: 'item-list-overflow.component.html',
    styleUrls: ['item-list-overflow.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemListOverflowComponent implements AfterViewChecked {
    @ContentChildren(OverflowItemComponent) items: QueryList<OverflowItemComponent>;
    @ViewChild('uiTooltip', { static: true }) uiTooltip: UITooltipDirective;
    overflowingItems: string[] = [];

    constructor(
        private host: ElementRef<HTMLElement>,
        private cdr: ChangeDetectorRef
    ) {}

    ngAfterViewChecked(): void {
        this.calculateOverflow();
    }

    calculateOverflow(): void {
        if (!this.items.length) {
            return;
        }
        const elementsToHide: HTMLElement[] = [];
        this.overflowingItems = [];
        this.items.forEach((item, i) => {
            const el = item.host.nativeElement as HTMLElement;
            el.classList.remove('hide');
            el.classList.remove('lasst');

            if (el.offsetLeft + el.offsetWidth > this.host.nativeElement.offsetWidth - 15) {
                el.classList.add('hide');
                elementsToHide.push(el);
            } else {
                return;
            }

            const clone = el.cloneNode(true) as HTMLElement;
            if (this.items.length - 1 === i) {
                if (clone.querySelector('.separator')) {
                    clone.querySelector('.separator')!.remove();
                }
                if (el.querySelector('.separator')) {
                    el.querySelector('.separator')!.remove();
                }
            }
            this.overflowingItems.push(clone.outerHTML);
        });

        if (!elementsToHide.length) {
            this.cdr.detectChanges();
            return;
        }

        if (elementsToHide[0].previousElementSibling) {
            const el = elementsToHide[0].previousElementSibling!;
            el.classList.add('last');
        }
        this.cdr.detectChanges();
    }
}
