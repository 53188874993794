import { Injectable, inject } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CreativesetDataService } from '../creativeset/creativeset.data.service';

@Injectable({ providedIn: 'root' })
export class NavigationService {
    private creativesetDataService = inject(CreativesetDataService);

    openCampaign(campaignId: string): void {
        const url = `${this.getDisplayCampaignOrigin()}/campaign/${campaignId}/list`;
        window.open(url, '_blank');
    }

    openCreateCampaign(): void {
        const creativesetId = this.creativesetDataService.creativeset.id;
        const url = `${this.getDisplayCampaignOrigin()}/creativeset/${creativesetId}`;
        window.open(url, '_blank');
    }

    openSocialCampaigns(): void {
        const accountSlug = this.creativesetDataService.getAccountSlug();
        const brandSlug = this.creativesetDataService.getBrandSlug();
        const creativesetId = this.creativesetDataService.creativeset.id;
        const url = `${environment.origins.socialCampaignManager}/${accountSlug}/${brandSlug}/creativeset/${creativesetId}`;
        window.open(url, '_blank');
    }

    openAddCreativesToCampaign(campaignId: string, creatives: string[]): void {
        const creativeSetId = this.creativesetDataService.creativeset.id;
        const cmWindow = window.open(
            `${this.getDisplayCampaignOrigin()}/campaign/${campaignId}/add-creatives`
        );
        if (cmWindow === null) {
            throw new Error('CM Window has failed to open.');
        }

        const eventListener = (event: MessageEvent<string>): void => {
            if (
                event.origin === `${environment.origins.campaignManager || ''}` &&
                event.data === 'CM_LOADED'
            ) {
                cmWindow.postMessage(
                    { creatives, creativeSetId },
                    `${environment.origins.campaignManager}`
                );
                window.removeEventListener('message', eventListener);
            }
        };

        window.addEventListener('message', eventListener);
    }

    private getDisplayCampaignOrigin(): string {
        const accountSlug = this.creativesetDataService.getAccountSlug();
        const brandSlug = this.creativesetDataService.getBrandSlug();
        return `${environment.origins.campaignManager}/${accountSlug}/${brandSlug}`;
    }
}
