import { Pipe, PipeTransform } from '@angular/core';
import { GroupedElements } from '@studio/domain/components/translation-page';

@Pipe({
    name: 'versionedGroupType',
    standalone: true
})
export class VersionedGroupTypePipe implements PipeTransform {
    transform(element: GroupedElements | undefined): 'widget' | 'text' | undefined {
        if (element?.isWidget) {
            return 'widget';
        } else if (element?.isWidget === false) {
            return 'text';
        }
    }
}
