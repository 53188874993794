<div class="wrapper">
    <ui-select
        placeholder="Filter by size collection"
        [searchable]="true"
        [multiSelect]="true"
        [tokenField]="true"
        [width]="'309px'"
        [theme]="'default'"
        (selectedChange)="onSelectedCollectionChange($event)"
        [selected]="selectedCollectionsIds()">
        <!-- tried, but could not get this component to be smaller -->
        <ng-template
            [ui-select-token-field-template]
            let-collectionId="value">
            @if (defaultCollections.includes(collectionId)) {
                <i class="collection-name">
                    {{ collectionId | collectionName }}
                </i>
            } @else {
                <span class="collection-name">
                    {{ collectionId | collectionName }}
                </span>
            }
        </ng-template>
        @for (collection of collections(); track collection.id) {
            @let count = collection.sizeFormatIds?.length ?? 0;
            @if (count > 0) {
                <ui-option [value]="collection.id">
                    @if (defaultCollections.includes(collection.id)) {
                        <i class="collection-name">
                            {{ collection.name }}
                        </i>
                    } @else {
                        <span class="collection-name">
                            {{ collection.name }}
                        </span>
                    }
                    <span class="collection-count">
                        @if (count === 1) {
                            1 size
                        } @else {
                            {{ count }} sizes
                        }
                    </span>
                </ui-option>
            }
        }
    </ui-select>
</div>
