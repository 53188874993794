import { Injectable } from '@angular/core';
import { Logger } from '@bannerflow/sentinel-logger';
import { UIDialogService, UINotificationService, UINotificationType } from '@bannerflow/ui';
import { CreativeSize } from '@domain/creativeset';
import { ICreative } from '@domain/creativeset/creative';
import { generateUniqueNameInList } from '@studio/utils/utils';
import { CreativesetDataService } from '../../../shared/creativeset/creativeset.data.service';
import { EditCreativeService } from '../services/edit-creative.service';
import { SizeAddService } from '../size-add-dialog/size-add.service';
import { DuplicateCreativeDialogComponent } from './duplicate-creative-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class DuplicateCreativeService {
    private logger = new Logger('DuplicateCreativeService');

    constructor(
        private uiDialogService: UIDialogService,
        private creativesetDataService: CreativesetDataService,
        private uiNotificationService: UINotificationService,
        private editCreativeService: EditCreativeService,
        private sizeAddService: SizeAddService
    ) {}

    openDialog(duplicateToNew: boolean): void {
        const headerText = duplicateToNew
            ? 'Duplicate creatives to new creative set'
            : 'Duplicate creatives to existing creative set';

        this.uiDialogService.openComponent(DuplicateCreativeDialogComponent, {
            headerText: headerText,
            panelClass: ['no-padding', 'fullscreen'],
            maxWidth: '100%',
            width: '100%',
            height: '100%',
            data: {
                duplicateToNew
            }
        });
    }

    async duplicateCreatives(creatives: ICreative[], creativesetId: string): Promise<void> {
        await this.createSizes(creatives, creativesetId);
        let message = 'Creatives duplicated successfully';
        if (creatives.length === 1) {
            message = 'Creative duplicated successfully';
        }
        this.showNotification(message, 'info');
    }

    private async createSizes(creatives: ICreative[], creativesetId: string): Promise<void> {
        try {
            this.logger.verbose('Creating new sizes');
            const sizes = creatives.map(creative => {
                const size = creative.size;
                let sizeName = size.name;
                if (sizeName) {
                    sizeName = generateUniqueNameInList(sizeName, this.getSizesNames());
                }
                return {
                    name: sizeName,
                    width: size.width,
                    height: size.height
                } as CreativeSize;
            });
            await this.sizeAddService.addSizes(creativesetId, sizes);
            this.editCreativeService.updateView();
        } catch {
            this.showNotification(
                `There was an error when creating the creative size. Please try again. If the problem persists, please contact our support team for assistance. We apologize for any inconvenience.`,
                'error'
            );
        }
    }

    private getSizesNames(): string[] {
        const sizesNames = this.creativesetDataService.creativeset.creatives
            .map(creative => creative.size.name)
            .filter((name): name is string => name !== undefined);

        return sizesNames;
    }

    private showNotification(text: string, type: UINotificationType): void {
        this.uiNotificationService.open(text, {
            type,
            placement: 'top',
            autoCloseDelay: 5000
        });
    }
}
