import { IHotkey } from '@domain/hotkeys/hotkeys.types';

export const ManagePageHotkeys: IHotkey[] = [
    {
        command: 'OpenCreativeInNewTab',
        keyCombination: { mac: 'Cmd + Click', win: 'Ctrl + Click' },
        context: 'ManagePage',
        category: 'Manage view',
        meta: {
            description: 'Open Creative in New Tab',
            shortcut: { mac: '⌘ + Click', win: 'Ctrl + Click' }
        }
    },
    {
        command: 'NavigateToTP',
        keyCombination: { win: 'Ctrl + Alt + T', mac: 'Cmd + Alt + T' },
        context: 'ManagePage',
        category: 'Manage view',
        meta: {
            description: 'Go to Translation Page',
            shortcut: { mac: '⌘ + ⌥ + T', win: 'Ctrl + Alt + T' }
        }
    },
    {
        command: 'ToggleOtherSizes',
        keyCombination: 'H',
        context: 'ManagePage',
        category: 'Manage view',
        meta: { description: 'Hide/show other sizes', shortcut: 'H' }
    },
    {
        command: 'Deselect',
        keyCombination: 'Escape',
        context: 'ManagePage',
        category: 'Manage view',
        meta: { description: 'Deselect', shortcut: 'Escape' }
    },
    {
        command: 'SelectAll',
        keyCombination: { win: 'Ctrl + A', mac: 'Cmd + A' },
        context: 'ManagePage',
        category: 'Manage view',
        meta: { description: 'Select all', shortcut: { mac: '⌘ + A', win: 'Ctrl + A' } }
    },
    {
        command: 'SelectMultiple',
        keyCombination: { win: 'Shift + Click', mac: 'Shift + Click' },
        context: 'ManagePage',
        category: 'Manage view',
        meta: {
            description: 'Select multiple',
            shortcut: { mac: 'Shift + Click', win: 'Shift + Click' }
        }
    },
    {
        command: 'DeleteSize',
        keyCombination: 'Delete',
        context: 'ManagePage',
        category: 'Manage view',
        meta: { description: 'Delete size', shortcut: 'Delete or Backspace' }
    },
    {
        command: 'DeleteSize',
        keyCombination: 'Backspace',
        context: 'ManagePage',
        category: 'Manage view',
        meta: undefined
    },
    {
        command: 'Copy',
        keyCombination: { win: 'Ctrl + C', mac: 'Cmd + C' },
        context: 'ManagePage',
        category: 'Manage view',
        meta: { description: 'Copy design', shortcut: { mac: '⌘ + C', win: 'Ctrl + C' } }
    },
    {
        command: 'Paste',
        keyCombination: { win: 'Ctrl + Alt + V', mac: 'Cmd + Alt + V' },
        context: 'ManagePage',
        category: 'Manage view',
        meta: { description: 'Paste design', shortcut: { mac: '⌘ + ⌥ + V', win: 'Ctrl + Alt + V' } }
    },
    {
        command: 'PlayPause',
        keyCombination: 'Spacebar(KeyUp)',
        context: 'ManagePage',
        category: 'Manage view',
        meta: { description: 'Play/Pause', shortcut: 'Spacebar' }
    },
    /** SHOWCASE */
    {
        command: 'ToggleOtherSizes',
        keyCombination: 'H',
        context: 'Showcase',
        category: 'Manage view',
        meta: { description: 'Hide/show other sizes', shortcut: 'H' }
    },
    {
        command: 'Deselect',
        keyCombination: 'Escape',
        context: 'Showcase',
        category: 'Manage view',
        meta: { description: 'Deselect', shortcut: 'Escape' }
    },
    {
        command: 'SelectAll',
        keyCombination: { win: 'Ctrl + A', mac: 'Cmd + A' },
        context: 'Showcase',
        category: 'Manage view',
        meta: { description: 'Select all', shortcut: { mac: '⌘ + A', win: 'Ctrl + A' } }
    }
];
