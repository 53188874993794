<div
    class="dropdown-trigger"
    #menuTrigger="uiDropdownTarget"
    [uiDropdownTarget]="menu"
    [hasBackdrop]="false"
    (dropdownClosed)="contextMenu.contextMenuClosed()"></div>

<ui-dropdown
    #menu
    type="menu">
    <ui-dropdown-item class="custom-dropdown transform">
        <div class="custom-item transform">
            <div class="custom-column"></div>
            <div class="custom-text">{{ elementMenu?.transformHint || 'Transform' }}</div>
            <div
                class="transform-action first"
                appPreviewDirective
                (preview)="elementMenu?.rotate(-90)"
                (revert)="elementMenu?.revertRotate(-90)"
                (apply)="elementMenu?.applyTransform(-90)">
                <ui-svg-icon
                    class="action-icon"
                    icon="rotate-left"></ui-svg-icon>
            </div>
            <div
                class="transform-action"
                appPreviewDirective
                (preview)="elementMenu?.rotate(90)"
                (revert)="elementMenu?.revertRotate(90)"
                (apply)="elementMenu?.applyTransform(90)">
                <ui-svg-icon
                    class="action-icon"
                    icon="rotate-right"></ui-svg-icon>
            </div>
            <div
                class="transform-action"
                appPreviewDirective
                (preview)="elementMenu?.flip('mirrorX')"
                (revert)="elementMenu?.revertFlip('mirrorX')"
                (apply)="elementMenu?.applyTransform('mirrorX')">
                <ui-svg-icon
                    class="action-icon"
                    icon="flip-h"></ui-svg-icon>
            </div>
            <div
                class="transform-action last"
                appPreviewDirective
                (preview)="elementMenu?.flip('mirrorY')"
                (revert)="elementMenu?.revertFlip('mirrorY')"
                (apply)="elementMenu?.applyTransform('mirrorY')">
                <ui-svg-icon
                    class="action-icon"
                    icon="flip-v"></ui-svg-icon>
            </div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-divider></ui-dropdown-divider>
    <ui-dropdown-item
        class="custom-dropdown"
        #alignSectionElement
        [uiDropdownTarget]="alignSection"
        (dropdownOpened)="contextMenu.openSubmenu(alignSectionElement)"
        (dropdownClosed)="contextMenu.leaveSubmenu(alignSectionElement)">
        <div class="custom-item">
            <div class="custom-column"></div>
            <div class="custom-text">Align & Distribution</div>
            <div class="custom-action"></div>
        </div>
    </ui-dropdown-item>
</ui-dropdown>
<ui-dropdown
    type="menu"
    #alignSection>
    <ui-dropdown-item
        class="custom-dropdown"
        (click)="elementMenu?.alignSelection(ElementAlign.Left)">
        <div class="custom-item">
            <ui-svg-icon
                class="custom-column icon"
                icon="align-left"></ui-svg-icon>
            <div class="custom-text">Align left</div>
            <div class="custom-action">{{ keyboardShortcuts.Editor.AlignLeft }}</div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-item
        class="custom-dropdown"
        (click)="elementMenu?.alignSelection(ElementAlign.Center)">
        <div class="custom-item">
            <ui-svg-icon
                class="custom-column icon"
                icon="align-center"></ui-svg-icon>
            <div class="custom-text">Align center</div>
            <div class="custom-action">{{ keyboardShortcuts.Editor.AlignCenter }}</div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-item
        class="custom-dropdown"
        (click)="elementMenu?.alignSelection(ElementAlign.Right)">
        <div class="custom-item">
            <ui-svg-icon
                class="custom-column icon"
                icon="align-right"></ui-svg-icon>
            <div class="custom-text">Align right</div>
            <div class="custom-action">{{ keyboardShortcuts.Editor.AlignRight }}</div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-divider></ui-dropdown-divider>
    <ui-dropdown-item
        class="custom-dropdown"
        (click)="elementMenu?.alignSelection(ElementAlign.Top)">
        <div class="custom-item">
            <ui-svg-icon
                class="custom-column icon"
                icon="align-top"></ui-svg-icon>
            <div class="custom-text">Align top</div>
            <div class="custom-action">{{ keyboardShortcuts.Editor.AlignTop }}</div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-item
        class="custom-dropdown"
        (click)="elementMenu?.alignSelection(ElementAlign.Middle)">
        <div class="custom-item">
            <ui-svg-icon
                class="custom-column icon"
                icon="align-middle"></ui-svg-icon>
            <div class="custom-text">Align middle</div>
            <div class="custom-action">{{ keyboardShortcuts.Editor.AlignMiddle }}</div>
        </div>
    </ui-dropdown-item>
    <ui-dropdown-item
        class="custom-dropdown"
        (click)="elementMenu?.alignSelection(ElementAlign.Bottom)">
        <div class="custom-item">
            <ui-svg-icon
                class="custom-column icon"
                icon="align-bottom"></ui-svg-icon>
            <div class="custom-text">Align bottom</div>
            <div class="custom-action">{{ keyboardShortcuts.Workspace.AlignBottom }}</div>
        </div>
    </ui-dropdown-item>
</ui-dropdown>
