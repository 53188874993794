@let selectedStyles = selectedStylesSignal();
<ui-popover
    #stylingPopover="ui-popover"
    [config]="{
        width: '235px',
        arrowPosition: 'top',
        position: 'bottom',
        panelClass: 'no-padding no-min-width',
        offset: { x: 5, y: -38 }
    }">
    <ng-template ui-popover-template>
        <div
            class="popover-wrapper"
            id="style-popover">
            <div class="popover-header">
                <div class="popover-header-left">{{ elementName() }}</div>
                <div
                    class="btn btn-close"
                    (click)="close()">
                    <ui-svg-icon icon="close" />
                </div>
            </div>
            <div class="popover-options">
                <div class="options-row">
                    <span class="options-row__label">Presets</span>
                    <div class="options-row__input">
                        <ui-select
                            class="presets"
                            [disabled]="!allowStyling()"
                            [value]="selectedPreset">
                            @if (selectedPreset === 'default') {
                                <ui-option
                                    [value]="'default'"
                                    [disabled]="true">
                                    Default
                                </ui-option>
                            } @else if (selectedPreset === 'mixed') {
                                <ui-option
                                    [value]="'mixed'"
                                    [disabled]="true">
                                    Mixed
                                </ui-option>
                            }
                            @for (preset of presets; track preset.styleId) {
                                <ui-option
                                    [uiTooltip]="'This option is not present in all selected creatives'"
                                    [uiTooltipDisabled]="!preset.disabled"
                                    (click)="presetSelected(preset, $event)"
                                    [value]="preset"
                                    [style]="preset.cssStyles">
                                    {{ preset.name + preset.fontSize }}
                                </ui-option>
                            }
                        </ui-select>
                    </div>
                </div>
                @if (selectedPreset !== 'default' && allowStyling()) {
                    <div class="options-row">
                        <span class="options-row__label"></span>
                        <div class="options-row__input">
                            <a
                                class="remove-styling"
                                (click)="onRemoveStylesClicked()">
                                Remove Styling
                            </a>
                        </div>
                    </div>
                }

                <div class="options-row">
                    <font-picker
                        [disabled]="inShowcaseMode()"
                        [searchable]="false"
                        (selectedFontChange)="selectedFontChange($event)"
                        [selectedFontFamilyId]="selectedStyles.fontFamilyId"
                        [selectedFontStyleId]="selectedStyles.fontStyleId" />
                </div>

                <div class="options-row">
                    <div class="options-row__label"></div>
                    <div class="options-row__input decoration">
                        <button-toggle
                            id="strikethrough-button"
                            icon="strikethrough"
                            (activeChange)="onStrikethroughToggled($event)"
                            [disabled]="inShowcaseMode()"
                            [active]="!!selectedStyles.strikethrough" />
                        <button-toggle
                            id="underline-button"
                            icon="underline"
                            (activeChange)="onUnderlineToggled($event)"
                            [disabled]="inShowcaseMode()"
                            [active]="!!selectedStyles.underline" />
                        <button-toggle
                            id="uppercase-button"
                            icon="uppercase"
                            (activeChange)="onUppercaseToggled($event)"
                            [disabled]="inShowcaseMode()"
                            [active]="!!selectedStyles.uppercase" />
                    </div>
                </div>

                <div class="options-row">
                    <div class="options-row__label">Color</div>
                    <div class="options-row__input color-picker">
                        @let currentColorPickerValue =
                            colorPickerValue() ?? (selectedStyles.textColor | parseColor);
                        @let isColorMixed = selectedStyles.textColor === 'mixed';
                        <color-button
                            [colorMixed]="isColorMixed"
                            [color]="currentColorPickerValue"
                            (click)="toggleColorPicker()"
                            data-test-id="color-button" />
                        @if (showColorPicker()) {
                            <color-picker
                                [startingColor]="selectedStyles.textColor | parseColor"
                                [colorMixed]="isColorMixed"
                                [disabled]="inShowcaseMode()"
                                (editEnd)="onColorPickerEditEnded($event)"
                                (willChange)="onColorPickerWillChange()"
                                (valueChange)="onColorPickerValueChanged($event)" />
                        }
                        <color-palette
                            [disabled]="inShowcaseMode()"
                            [palettes]="usedColorsPalette()"
                            [auxPalettes]="brandPalettes()"
                            [selectedColor]="currentColorPickerValue"
                            [colorMixed]="selectedStyles.textColor === 'mixed'"
                            (valueChange)="onColorSelected($event)" />
                    </div>
                </div>

                <div class="options-row">
                    <div class="options-row__label">Size</div>
                    <div class="options-row__input">
                        <ui-number-input
                            id="text-font-size-input"
                            class="size-input"
                            unitLabel="px"
                            [value]="
                                selectedStyles.fontSize === 'mixed'
                                    ? undefined
                                    : selectedStyles.fontSize
                            "
                            [placeholder]="'-'"
                            [disabled]="true" />
                    </div>
                </div>

                <div class="options-row">
                    <div class="options-row__label">Spacing</div>
                    <div class="options-row__input spacing">
                        <div>
                            <ui-number-input
                                id="text-character-spacing-input"
                                unitLabel="Char"
                                [disabled]="inShowcaseMode()"
                                [value]="
                                    selectedStyles.characterSpacing === 'mixed'
                                        ? undefined
                                        : selectedStyles.characterSpacing
                                "
                                (valueChange)="onCharacterSpacingChange($event)"
                                [step]="0.01"
                                [min]="inputValidation.characterSpacing.min"
                                [max]="inputValidation.characterSpacing.max"
                                placeholder="-" />
                        </div>
                        <div>
                            <ui-number-input
                                id="text-line-height-input"
                                class="line-height-input"
                                unitLabel="Line"
                                [value]="
                                    selectedStyles.lineHeight === 'mixed'
                                        ? undefined
                                        : selectedStyles.lineHeight
                                "
                                [placeholder]="'-'"
                                (valueChange)="onLineHeightUpdated($event)"
                                [disabled]="inShowcaseMode()" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</ui-popover>
