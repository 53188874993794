import { IHotkey } from '@domain/hotkeys/hotkeys.types';

export const EditorHotkeys: IHotkey[] = [
    {
        command: 'Save',
        keyCombination: { win: 'Ctrl + S', mac: 'Cmd + S' },
        context: 'Editor',
        category: 'Design view',
        meta: { description: 'Save', shortcut: { mac: '⌘ + S', win: 'Ctrl + S' } }
    },
    {
        command: 'SaveAndExit',
        keyCombination: { win: 'Ctrl + Shift + S(KeyUp)', mac: 'Cmd + Shift + S' },
        context: 'Editor',
        category: 'Design view',
        meta: { description: 'Save and exit', shortcut: { mac: '⌘ + ⇧ + S', win: 'Ctrl + Shift + S' } }
    },
    {
        command: 'NavigateToTP',
        keyCombination: { win: 'Ctrl + Alt + T', mac: 'Cmd + Alt + T' },
        context: 'Editor',
        category: 'Design view',
        meta: {
            description: 'Go to Translation Page',
            shortcut: { mac: '⌘ + ⌥ + T', win: 'Ctrl + Alt + T' }
        }
    },
    {
        command: 'Undo',
        keyCombination: { win: 'Ctrl + Z', mac: 'Cmd + Z' },
        context: 'Editor',
        category: 'Edit elements',
        meta: { description: 'Undo', shortcut: { mac: '⌘ + Z', win: 'Ctrl + Z' } }
    },
    {
        command: 'Redo',
        keyCombination: { win: 'Ctrl + Y', mac: 'Cmd + Shift + Z' },
        context: 'Editor',
        category: 'Edit elements',
        meta: { description: 'Redo', shortcut: { mac: '⌘ + ⇧ + Z', win: 'Ctrl + Y' } }
    },
    {
        command: 'Redo',
        keyCombination: 'Cmd + Y',
        context: 'Editor',
        category: 'Edit elements',
        meta: undefined
    },
    {
        command: 'Deselect',
        keyCombination: 'Escape',
        context: 'Editor',
        category: 'Edit elements',
        meta: { description: 'Deselect', shortcut: 'Escape' }
    },
    {
        command: 'Copy',
        keyCombination: { win: 'Ctrl + C', mac: 'Cmd + C' },
        context: 'Editor',
        category: 'Edit elements',
        meta: { description: 'Copy', shortcut: { mac: '⌘ + C', win: 'Ctrl + C' } }
    },
    {
        command: 'Paste',
        keyCombination: { win: 'Ctrl + V', mac: 'Cmd + V' },
        context: 'Editor',
        category: 'Edit elements',
        meta: { description: 'Paste', shortcut: { mac: '⌘ + V', win: 'Ctrl + V' } }
    },
    {
        command: 'Clone',
        keyCombination: { win: 'Alt + drag', mac: 'Alt + drag' },
        context: 'Editor',
        category: 'Edit elements',
        meta: { description: 'Clone', shortcut: { mac: '⌥ + drag', win: 'Alt + drag' } }
    },
    {
        command: 'PasteStyle',
        keyCombination: { win: 'Ctrl + Alt + V', mac: 'Cmd + Alt + V' },
        context: 'Editor',
        category: 'Edit elements',
        meta: { description: 'Paste style', shortcut: { mac: '⌘ + ⌥ + V', win: 'Ctrl + Alt + V' } }
    },
    {
        command: 'PasteLayout',
        keyCombination: { win: 'Ctrl + Shift + V', mac: 'Cmd + Shift + V' },
        context: 'Editor',
        category: 'Edit elements',
        meta: { description: 'Paste layout', shortcut: { mac: '⌘ + ⇧ + V', win: 'Ctrl + Shift + V' } }
    },
    {
        command: 'PasteAnimation',
        keyCombination: { win: 'Ctrl + Alt + Shift + V', mac: 'Cmd + Alt + Shift + V' },
        context: 'Editor',
        category: 'Edit elements',
        meta: {
            description: 'Paste animations',
            shortcut: { mac: '⌘ + ⌥ + ⇧ + V', win: 'Ctrl + Shift + Alt + V' }
        }
    },
    {
        command: 'Cut',
        keyCombination: { win: 'Ctrl + X', mac: 'Cmd + X' },
        context: 'Editor',
        category: 'Edit elements',
        meta: { description: 'Cut', shortcut: { mac: '⌘ + X', win: 'Ctrl + X' } }
    },
    {
        command: 'SelectAll',
        keyCombination: { win: 'Ctrl + A', mac: 'Cmd + A' },
        returnUntilKeyup: true,
        context: 'Editor',
        category: 'Edit elements',
        meta: { description: 'Select all', shortcut: { mac: '⌘ + A', win: 'Ctrl + A' } }
    },
    {
        command: 'AlignLeft',
        keyCombination: 'Shift + L',
        context: 'Editor',
        category: 'Edit elements',
        meta: { description: 'Align left', shortcut: { mac: '⇧ + L', win: 'Shift + L' } }
    },
    {
        command: 'AlignRight',
        keyCombination: 'Shift + R',
        context: 'Editor',
        category: 'Edit elements',
        meta: { description: 'Align right', shortcut: { mac: '⇧ + R', win: 'Shift + R' } }
    },
    {
        command: 'AlignCenter',
        keyCombination: 'Shift + C',
        context: 'Editor',
        category: 'Edit elements',
        meta: { description: 'Align center', shortcut: { mac: '⇧ + C', win: 'Shift + C' } }
    },
    {
        command: 'AlignTop',
        keyCombination: 'Shift + T',
        context: 'Editor',
        category: 'Edit elements',
        meta: { description: 'Align top', shortcut: { mac: '⇧ + T', win: 'Shift + T' } }
    },
    {
        command: 'AlignBottom',
        keyCombination: 'Shift + B',
        context: 'Editor',
        category: 'Edit elements',
        meta: { description: 'Align bottom', shortcut: { mac: '⇧ + B', win: 'Shift + B' } }
    },
    {
        command: 'AlignMiddle',
        keyCombination: 'Shift + M',
        context: 'Editor',
        category: 'Edit elements',
        meta: { description: 'Align middle', shortcut: { mac: '⇧ + M', win: 'Shift + M' } }
    },
    {
        command: 'DistributeHorizontally',
        keyCombination: 'Shift + H',
        context: 'Editor',
        category: 'Edit elements',
        meta: { description: 'Distribute Horizontally', shortcut: { mac: '⇧ + H', win: 'Shift + H' } }
    },
    {
        command: 'DistributeVertically',
        keyCombination: 'Shift + V',
        context: 'Editor',
        category: 'Edit elements',
        meta: { description: 'Distribute Vertically', shortcut: { mac: '⇧ + V', win: 'Shift + V' } }
    }
];
