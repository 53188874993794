import {
    CreativeKind,
    IButtonElementDataNode,
    ICreativeDataNode,
    IEllipseElementDataNode,
    IImageElementDataNode,
    IRectangleElementDataNode,
    ITextElementDataNode,
    IVideoElementDataNode,
    OneOfDataNodes,
    OneOfElementDataNodes
} from '@domain/nodes';
import { IWidgetElementDataNode } from '@domain/widget';
import { CreativeDataNode } from './nodes/base-data-node';
import { isElementDataNode, isHidden, isTextNode } from './nodes/helpers';
import { ElementKind } from '@domain/elements';

export interface INodeVisitor {
    visitCreative_m(creative: ICreativeDataNode): void;
    visitRectangle_m(rect: IRectangleElementDataNode): void;
    visitEllipse_m(ellipse: IEllipseElementDataNode): void;
    visitImage_m(image: IImageElementDataNode): void;
    visitButton_m(text: IButtonElementDataNode): void;
    visitText_m(text: ITextElementDataNode): void;
    visitWidget_m(widget: IWidgetElementDataNode): void;
    visitVideo_m(video: IVideoElementDataNode, skipVideoRenderer?: boolean): void;
}

export function visitOneNode(
    node: OneOfElementDataNodes | CreativeDataNode,
    visitor: INodeVisitor
): void {
    if (isElementDataNode(node) && isHidden(node)) {
        return;
    }
    switch (node.kind) {
        case CreativeKind.Creative:
            visitor.visitCreative_m(node);
            break;
        case ElementKind.Rectangle:
            visitor.visitRectangle_m(node);
            break;
        case ElementKind.Ellipse:
            visitor.visitEllipse_m(node);
            break;
        case ElementKind.Image:
            visitor.visitImage_m(node);
            break;
        case ElementKind.Text:
            visitor.visitText_m(node);
            break;
        case ElementKind.Button:
            visitor.visitButton_m(node);
            break;
        case ElementKind.Widget:
            visitor.visitWidget_m(node);
            break;
        case ElementKind.Video:
            visitor.visitVideo_m(node);
            break;
    }
}

export function visitAllNodes(
    node: OneOfDataNodes | ICreativeDataNode,
    visitor: INodeVisitor,
    visitOnlyTextLikeNodes = false
): void {
    switch (node.kind) {
        case CreativeKind.Creative:
            visitor.visitCreative_m(node);
            visitAllChildren(node.nodes, visitor, visitOnlyTextLikeNodes);
            break;
        case ElementKind.Group:
            visitAllChildren(node.nodes, visitor, visitOnlyTextLikeNodes);
            break;
        default:
            if (!visitOnlyTextLikeNodes || isTextNode(node)) {
                visitOneNode(node, visitor);
            }
    }
}

export function visitAllChildren(
    children: OneOfDataNodes[],
    visitor: INodeVisitor,
    visitOnlyTextLikeNodes = false
): void {
    for (const child of children) {
        if (!isHidden(child)) {
            visitAllNodes(child, visitor, visitOnlyTextLikeNodes);
        }
    }
}
