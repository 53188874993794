<div>Leaving the box unchecked and saving will replace the image in one size only.</div>
<div>Checking the box and saving will replace the image in all sizes within the creative set.</div>

<ui-checkbox
    [(selected)]="replaceInAllDesigns"
    label="Replace image in all sizes"></ui-checkbox>

<div class="btns-wrapper">
    <ui-button
        text="Back to AI Studio"
        (click)="backToAIStudio()" />
    <ui-button
        type="primary"
        text="Save and replace"
        (click)="saveAndReplace()" />
</div>
