<div
    class="draggable-element"
    [ngClass]="{ widget: isWidget }"
    [style.width.px]="width"
    [style.height.px]="height"
    [style.z-index]="zIndex"
    [style.transform]="transform">
    <div
        class="checkered-background"
        *ngIf="showDefaultImage"></div>
    <div
        #viewContainer
        class="background-image"
        [style.backgroundImage]="thumbnailElement ? 'none' : 'url(' + src + ')'"></div>
</div>
