import { IColor } from '@domain/color';
import { IElement } from '@domain/creativeset/element';
import { IVersion, IVersionProperty } from '@domain/creativeset/version';
import { IBfFeed } from '@domain/feed';
import { createAction, props } from '@ngrx/store';
import {
    DirtyCharacterStylingChanges,
    DirtyVersionPropertiesChanges,
    GroupedElements
} from '@studio/domain/components/translation-page';
import { TranslationPageState } from './translation-page.reducer';

export const loadTranslationPage = createAction('[TranslationPage] Load TranslationPage');

export const exitTranslationPage = createAction('[TranslationPage] Exit TranslationPage');

export const loadTranslationPageSuccess = createAction(
    '[TranslationPage] Load TranslationPage Success',
    props<{
        elements: IElement[];
        groups: GroupedElements[];
        showAllElements: boolean;
    }>()
);

export const loadTranslationPageFailure = createAction(
    '[TranslationPage] Load TranslationPage Failure',
    props<{ error: unknown }>()
);

export const initializeFeedStoreSingleton = createAction(
    '[TranslationPage] Initialize FeedStoreSingleton'
);

export const updateElementsOnFilterChangeSuccess = createAction(
    '[TranslationPage] Update Elements On Size Change Success',
    props<{
        elements: IElement[];
        groups: GroupedElements[];
    }>()
);

export const selectElement = createAction(
    '[TranslationPage] Select element',
    props<{ elementId: string }>()
);

export const selectNext = createAction('[TranslationPage] Select Next');

export const selectPrevious = createAction('[TranslationPage] Select Previous');

export const selectGroup = createAction('[TranslationPage] Select group', props<{ groupId: string }>());

export const modifyDirtyVersionProperties = createAction(
    '[TranslationPage] Modify dirty version properties',
    props<{
        changes: DirtyVersionPropertiesChanges;
    }>()
);
export const modifyDirtyCharacterStyles = createAction(
    '[TranslationPage] Modify dirty character styles',
    props<{
        changes: DirtyCharacterStylingChanges;
    }>()
);

export const upsertDirtyVersionProperty = createAction(
    '[TranslationPage] Upsert Dirty Version Property',
    props<{
        versionId: string;
        versionProperty: IVersionProperty;
    }>()
);

export const removeDirtyVersionProperty = createAction(
    '[TranslationPage] Remove Dirty Version Property',
    props<{
        versionId: string;
        versionPropertyId: string;
    }>()
);

export const cancelTranslations = createAction('[TranslationPage] Cancel Translations');

export const cancelStyleEditing = createAction('[TranslationPage] Cancel style editing');

export const saveDirtyVersions = createAction('[TranslationPage] Save Dirty Versions');

export const saveDirtyVersionsSuccess = createAction('[TranslationPage] Save Dirty Versions Success');

export const saveDirtyVersionsFailure = createAction(
    '[TranslationPage] Save Dirty Versions Failure',
    props<{ error: unknown }>()
);

export const showAll = createAction('[TranslationPage] Show All');

export const hideAll = createAction('[TranslationPage] Hide All');

export const initBfFeeds = createAction(
    '[TranslationPage] Initialize Bf Feeds',
    props<{ feeds: IBfFeed[] }>()
);

export const setUsedColors = createAction(
    '[TranslationPage] Set Used Colors',
    props<{ colors: IColor[] }>()
);

export const selectText = createAction(
    '[TranslationPage] Set selectedText',
    props<{
        selectedText: TranslationPageState['selectedText'];
    }>()
);

export const undo = createAction('[TranslationPage] Undo change');
export const redo = createAction('[TranslationPage] Redo change');

export const changeHistoryPreSave = createAction(
    '[TranslationPage] Change history pre save',
    props<{ versions: IVersion[] }>()
);
