import { ExternalFontFamilyDto, ExternalFontStyleDto } from '@studio/domain/api/font-manager.types';

export function injectFontFamily(fontName: string, fontUrl: string): FontFace {
    const fontFace = new FontFace(fontName, `url(${fontUrl})`);
    document.fonts.add(fontFace);
    return fontFace;
}

export function getDefaultFontStyleForExternalFamily(
    fontFamily: ExternalFontFamilyDto
): ExternalFontStyleDto {
    if (fontFamily.styles.length === 0) {
        throw new Error('No styles in font family');
    }

    for (const style of fontFamily.styles) {
        if (
            (style.weight === 400 && !style.italic) ||
            style.name.toLowerCase() === 'regular' ||
            style.name.toLowerCase() === 'medium'
        ) {
            return style;
        }
    }
    return fontFamily.styles[Math.floor(fontFamily.styles.length / 2)];
}
