import { EnvironmentProviders, importProvidersFrom } from '@angular/core';
import { SizeAddEffects } from './size-add.effects';
import { reducer, SIZE_ADD_FEATURE_KEY } from './size-add.reducer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

export function provideSizeAddStore(): EnvironmentProviders {
    return importProvidersFrom(
        StoreModule.forFeature(SIZE_ADD_FEATURE_KEY, reducer),
        EffectsModule.forFeature(SizeAddEffects)
    );
}
