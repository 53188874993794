<div
    class="brand"
    *ngIf="selectedPlacement.type === PinterestPlacement.WatchVideo">
    <svg
        width="70"
        height="70"
        viewBox="0 0 70 70"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle
            cx="35"
            cy="35"
            r="35"
            fill="url(#logo)" />
        <pattern
            id="logo"
            patternContentUnits="objectBoundingBox"
            height="100%"
            width="100%"
            viewBox="0 0 1 1"
            preserveAspectRatio="xMidYMid slice">
            <image
                height="1"
                width="1"
                preserveAspectRatio="xMidYMid slice"
                [attr.xlink:href]="(brandLogoUrl$ | async) || 'assets/logo.png'"></image>
        </pattern>
    </svg>
    <div class="brand-name">{{ (brandName$ | async) || 'Bannerflow' }}</div>
    <img
        alt="Follow button"
        class="brand-follow-btn"
        src="assets/images/social/pinterest-follow-button.svg" />
</div>

<img
    class="overlay-section"
    alt="Pinterest Overlay"
    [src]="selectedPlacement | socialImage" />
