import { Injectable } from '@angular/core';
import { forEachParentNode, isGroupDataNode, toFlatNodeList } from '@creative/nodes/helpers';
import { ElementSelection } from '@creative/nodes/selection';
import { IGroupElementDataNode, OneOfDataNodes } from '@domain/nodes';
import { cloneDeep } from '@studio/utils/clone';
import { ReplaySubject } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';

export type InteractableInstance = 'keyframe' | 'animation' | 'element' | undefined;

@Injectable()
export class ElementSelectionService {
    private _change$ = new ReplaySubject<ElementSelection>(1);
    change$ = this._change$.asObservable().pipe(
        distinctUntilChanged((prev, curr) => {
            if (prev.length !== curr.length) {
                return false;
            }

            // Object matching fails when using undo/redo
            const prevArrIds = Array.from(prev.nodes).map(el => el.id);
            const currArrIds = Array.from(curr.nodes).map(el => el.id);
            for (const id of prevArrIds) {
                if (!currArrIds.includes(id)) {
                    return false;
                }
            }
            return true;
        }),
        tap(value => (this._currentSelection = cloneDeep(value)))
    );

    private _currentSelection: ElementSelection = new ElementSelection();
    get currentSelection(): ElementSelection {
        return this._currentSelection;
    }

    getGroupOrNode(node?: OneOfDataNodes): OneOfDataNodes | undefined {
        if (!node) {
            return undefined;
        }

        const selection = this.currentSelection;
        const parentNode = node.__parentNode;
        let topParent: IGroupElementDataNode | undefined;

        forEachParentNode(node, parent => {
            topParent = parent;
        });

        if (!topParent?.findNodeById_m(node.id)) {
            return node;
        }

        const shouldSelectTopParent = selection.nodes.every(
            selectionNode =>
                !topParent?.findNodeById_m(selectionNode.id, true) && selectionNode.id !== topParent?.id
        );

        if (!selection.length || shouldSelectTopParent) {
            return topParent || node;
        }

        const shouldSelectParent = selection.nodes.every(selectionNode => {
            const isChildOfNodeGroup = !!node.__parentNode?.findNodeById_m(selectionNode.id);
            const isSameGroup = selectionNode.id === parentNode?.id;
            const hasSameParent = selectionNode.__parentNode?.id === parentNode?.id;

            if (!isChildOfNodeGroup && !isSameGroup && !hasSameParent) {
                return true;
            }
        });

        if (shouldSelectParent) {
            return node.__parentNode;
        }

        return node;
    }

    latestSelectionType: InteractableInstance;

    setSelection(...nodes: OneOfDataNodes[]): void {
        this._change$.next(new ElementSelection(nodes));
        this.latestSelectionType = 'element';
    }

    addSelection(...nodes: OneOfDataNodes[]): void {
        this.setSelection(...this.currentSelection.nodes, ...nodes);
    }

    deleteSelection(...nodes: OneOfDataNodes[]): void {
        const currentNodes = toFlatNodeList(this.currentSelection.nodes);

        const newNodes = currentNodes.filter(node =>
            nodes.every(n => {
                if (n.id !== node.id && !isGroupDataNode(n)) {
                    return true;
                }

                if (n.id !== node.id && isGroupDataNode(n) && !n.findNodeById_m(node.id, true)) {
                    return true;
                }
            })
        );

        this.setSelection(...newNodes);
    }

    clearSelection(): void {
        this._change$.next(new ElementSelection());
    }
}
