<ng-container *ngIf="feed">
    <div class="header">
        <div class="feed">
            <ui-svg-icon icon="feed"></ui-svg-icon>
            <strong
                truncateSpan
                [spanText]="feedName"></strong>
        </div>
        <feed-picker
            *ngIf="canEditSource"
            (feedSelectionChanged)="useSelectedFeedItem($event)"
            [value]="feed"
            [minimized]="true"></feed-picker>
    </div>
    <div class="settings">
        <div
            class="setting"
            data-test-id="path-input">
            Field

            <div *ngIf="disableInputs; else fieldSelect">
                {{ path }}
            </div>

            <ng-template #fieldSelect>
                <ui-select
                    [useTargetWidth]="true"
                    [(selected)]="path"
                    [placeholder]="placeholder"
                    [class.deleted]="!!placeholder"
                    (selectedChange)="onFeedPathValueChanged($event)"
                    data-test-id="feed-field">
                    <ui-option
                        *ngFor="let field of fields"
                        [value]="field.text"
                        [uiTooltip]="field.disabledTooltipText"
                        [uiTooltipDisabled]="!field.showDisabledText || !field.disabledTooltipText"
                        [disabled]="field.showDisabledText">
                        {{ field.text }}
                    </ui-option>
                </ui-select>
            </ng-template>
        </div>
        <div
            class="setting"
            data-test-id="occurrence-input">
            Step on

            <div *ngIf="disableInputs; else occurrenceInput">
                {{ visibleFeedStep.occurrence }}
            </div>

            <ng-template #occurrenceInput>
                <ui-select
                    [useTargetWidth]="true"
                    [(selected)]="visibleFeedStep.occurrence"
                    (selectedChange)="onFeedOccurrenceChanged($event)"
                    placeholder="-">
                    <ui-option value="loop">Creative loop</ui-option>
                    <ui-option value="none">Never</ui-option>
                </ui-select>
            </ng-template>
        </div>
        <div class="half">
            <div
                class="setting"
                data-test-id="size-input">
                Step size

                <div *ngIf="disableInputs; else sizeInput">
                    {{ visibleFeedStep.size }}
                </div>

                <ng-template #sizeInput>
                    <ui-number-input
                        [(value)]="visibleFeedStep.size"
                        [min]="1"
                        placeholder="-"
                        [disabled]="feed.step.occurrence === 'none'"
                        (valueChange)="onFeedValueChanged($event, 'size')"
                        data-test-id="feed-step-size">
                    </ui-number-input>
                </ng-template>
            </div>
            <div
                class="setting"
                data-test-id="start-input">
                Start at

                <div *ngIf="disableInputs; else startInput">
                    {{ visibleFeedStep.start }}
                </div>

                <ng-template #startInput>
                    <ui-number-input
                        [value]="visibleFeedStep.start"
                        [min]="1"
                        [max]="totalFeedItems || 99"
                        placeholder="-"
                        (valueChange)="onFeedValueChanged($event, 'start')"
                        data-test-id="feed-step-start">
                    </ui-number-input>
                </ng-template>
            </div>
        </div>
    </div>
</ng-container>
