import { isVersionedFeed } from '@creative/elements/feed/feeds.utils';
import {
    isNewlineLikeCharacter,
    isSpaceLikeCharacter,
    isVersionedText
} from '@creative/elements/rich-text/utils';
import { ITextSpan, IVersion, IVersionedText } from '@domain/creativeset/version';
import { IFeed } from '@domain/feed';
import { IStyleIdMap, SpanType } from '@domain/text';
import {
    FeedPill,
    IMatInputTextSelection,
    PresetsContent,
    StyleIdsModification
} from '@studio/domain/components/translation-page';
import { cloneDeep } from '@studio/utils/clone';
import { deepEqual } from '@studio/utils/utils';

export function getSpansFromNewValue(
    newValue: string,
    oldValue: string,
    oldSpans: ITextSpan[]
): ITextSpan[] {
    const result: ITextSpan[] = [];

    const isAddingCharacters = newValue.length > oldValue.length;

    let oldValueDeltaPosition = 0;

    for (let index = 0; index < newValue.length; index++) {
        const newCharacter = newValue[index];

        const newSpanType = getSpanType(newCharacter);

        const oldValuePosition = index - oldValueDeltaPosition;
        if (newCharacter === oldValue[oldValuePosition]) {
            // same character as before, copy the styles
            const oldSpan = getSpanForPosition(oldSpans, oldValuePosition);
            result.push({
                type: newSpanType,
                length: 1,
                position: index,
                styleIds: {
                    ...oldSpan?.styleIds
                }
            });
            continue;
        }

        if (isAddingCharacters) {
            oldValueDeltaPosition++;
            const lastSpan = result.at(-1);
            result.push({
                type: newSpanType,
                length: 1,
                position: index,
                styleIds: {
                    ...lastSpan?.styleIds
                }
            });
            continue;
        }
        oldValueDeltaPosition = oldValue.length - newValue.length;
        const oldSpan = getSpanForPosition(oldSpans, index + oldValueDeltaPosition) ?? result.at(-1);
        result.push({
            type: newSpanType,
            length: 1,
            position: index,
            styleIds: {
                ...oldSpan?.styleIds
            }
        });
    }

    return mergeSpans(result);
}

export function mergeSpans(spans: ITextSpan[]): ITextSpan[] {
    const result: ITextSpan[] = [];
    for (const span of spans) {
        const lastSpan = result.at(-1);
        if (lastSpan?.type === span.type && deepEqual(lastSpan?.styleIds ?? {}, span.styleIds)) {
            lastSpan.length += span.length;
            continue;
        }
        result.push({ ...span });
    }

    return result;
}

export function getSpanForPosition(spans: ITextSpan[], position: number): ITextSpan | undefined {
    return spans.find(span => position >= span.position && position - span.position < span.length);
}

export function getSpanType(character: string): SpanType {
    if (isNewlineLikeCharacter(character.charCodeAt(0))) {
        return SpanType.Newline;
    }
    if (isSpaceLikeCharacter(character.charCodeAt(0))) {
        return SpanType.Space;
    }
    return SpanType.Word;
}

// Returns selected spans without modification
export function getSpansInSelection(
    selectedText: IMatInputTextSelection,
    spans: ITextSpan[]
): ITextSpan[] {
    const newSpans: ITextSpan[] = [];
    for (const span of spans) {
        const spanEnd = span.position + span.length;
        const isSpanCompletelyOutsideSelection =
            spanEnd <= selectedText.start || span.position >= selectedText.end;
        if (isSpanCompletelyOutsideSelection) {
            continue;
        }
        newSpans.push(span);
    }
    return newSpans;
}

function updateStyleIds(
    styleIds: IStyleIdMap,
    styleIdsModification?: StyleIdsModification
): IStyleIdMap {
    if (!styleIdsModification) {
        return styleIds;
    }
    switch (styleIdsModification.type) {
        case 'merge': {
            return {
                ...styleIds,
                ...styleIdsModification.modification
            };
        }
        case 'remove': {
            const newStyleIds: IStyleIdMap = {};
            for (const [documentId, styleId] of Object.entries(styleIds)) {
                if (styleIdsModification.modification[documentId] === styleId) {
                    continue;
                }
                newStyleIds[documentId] = styleId;
            }
            return newStyleIds;
        }
    }
}

export function applySelectionToSpans(
    selectedText: IMatInputTextSelection,
    spans: ITextSpan[],
    styleIdsModification?: StyleIdsModification
): ITextSpan[] {
    const newSpans: ITextSpan[] = [];

    for (const span of spans) {
        const spanEnd = span.position + span.length;
        const isSpanCompletelyOutsideSelection =
            spanEnd <= selectedText.start || span.position >= selectedText.end;
        if (isSpanCompletelyOutsideSelection) {
            newSpans.push(span);
            continue;
        }
        const isSpanCompletelyInsideSelection =
            span.position >= selectedText.start && spanEnd <= selectedText.end;
        if (isSpanCompletelyInsideSelection) {
            newSpans.push({ ...span, styleIds: updateStyleIds(span.styleIds, styleIdsModification) });
            continue;
        }
        // Select feeds as a whole span
        if (span.type === SpanType.Variable) {
            newSpans.push(span);
            continue;
        }
        // split span
        let deltaLength = 0;
        if (span.position < selectedText.start) {
            deltaLength = selectedText.start - span.position;
            newSpans.push({
                ...span,
                length: deltaLength
            });
        }
        const newSpanLength = Math.min(
            span.length - deltaLength,
            selectedText.end - (span.position + deltaLength)
        );
        const newSpanPosition = span.position + deltaLength;
        const newStyleIds = updateStyleIds(span.styleIds, styleIdsModification);
        newSpans.push({
            ...span,
            position: newSpanPosition,
            length: newSpanLength,
            styleIds: newStyleIds ?? span.styleIds
        });
        if (spanEnd > selectedText.end) {
            newSpans.push({
                ...span,
                position: selectedText.end,
                length: spanEnd - selectedText.end
            });
        }
    }

    return newSpans;
}

export function changeFeedInVersionedText(
    newFeed: IFeed,
    feedPill: FeedPill,
    versionedText: IVersionedText
): IVersionedText {
    const newValueStyles: ITextSpan[] = [];
    let newValueText = versionedText.text;
    let textUpdated = false;
    const lengthDelta = newFeed.path.length - feedPill.feed.path.length;

    for (const span of versionedText.styles) {
        const isSameFeed = span.variable && compareFeededSpan(span, feedPill.span);
        if (!textUpdated && isSameFeed) {
            newValueStyles.push({
                ...span,
                length: span.length + lengthDelta,
                variable: {
                    ...newFeed,
                    type: 'text'
                }
            });
            newValueText = `${versionedText.text.substring(0, span.position)}@${
                newFeed.path
            }${versionedText.text.substring(span.position + span.length)}`;
            textUpdated = true;
            continue;
        }
        if (textUpdated) {
            newValueStyles.push({
                ...span,
                position: span.position + lengthDelta
            });
            continue;
        }
        newValueStyles.push({
            ...span
        });
    }

    return {
        text: newValueText,
        styles: newValueStyles
    };
}

function compareFeededSpan(a: ITextSpan, b: ITextSpan): boolean {
    return a.type === b.type && a.length === b.length && a.position === b.position;
}

export function hasFeedInVersion(version: IVersion, versionPropertyId: string): boolean {
    const versionProperty = version.properties.find(({ id }) => id === versionPropertyId);
    if (!versionProperty) {
        return false;
    }
    if (isVersionedFeed(versionProperty)) {
        return true;
    }
    if (isVersionedText(versionProperty)) {
        const textWithFeed = versionProperty.value.styles.some(
            ({ type }) => type === SpanType.Variable
        );
        if (textWithFeed) {
            return true;
        }
    }
    return false;
}

export function getSelectedStylesFromPresets(
    selection: IMatInputTextSelection,
    presets: PresetsContent[]
): PresetsContent['style'][] {
    if (selection.length === 0) {
        return [];
    }
    const selectedPresets: PresetsContent['style'][] = [];

    let index = 0;
    for (const preset of presets) {
        const presetLength = preset.content.length;
        const presetEnd = index + presetLength;

        if (presetEnd <= selection.start) {
            // Preset is before selection
            index += presetLength;
            continue;
        }
        if (index >= selection.end) {
            // preset is after selection
            break;
        }
        // entire/partial preset is selected
        selectedPresets.push(cloneDeep(preset.style));
        index += presetLength;
    }

    return selectedPresets;
}
