import { ElementKind } from '@domain/elements';
import { areaRelation } from '@studio/utils/geom';
import { GroupOrLayoutElement, IContstraintGroup, ILayoutRule, isLayoutElement } from '../rules';

/**
 * Only allow ratio to change a certain percent
 */
const ACCEPTED_RATIO_DISTORTION = 1.3;

export class ButtonRatioRule implements ILayoutRule {
    applyAutoContraintRule(layoutElement: GroupOrLayoutElement): void {
        const isButton =
            isLayoutElement(layoutElement) && layoutElement.element.kind === ElementKind.Button;

        // Applies only to buttons
        if (isButton || this.isButtonGroup(layoutElement)) {
            const rotatedBoundingBox = layoutElement.rotatedBoundingBox;
            const ratioObject = layoutElement.constraint.ratio || {};
            const ratio = ratioObject.value || rotatedBoundingBox.width / rotatedBoundingBox.height;

            ratioObject.min = ratioObject.min || ratio / ACCEPTED_RATIO_DISTORTION;
            ratioObject.max = ratioObject.max || ratio * ACCEPTED_RATIO_DISTORTION;

            layoutElement.constraint.ratio = ratioObject;
        }
    }

    private isButtonGroup(layoutElement: GroupOrLayoutElement): layoutElement is IContstraintGroup {
        if (!isLayoutElement(layoutElement)) {
            return layoutElement.layoutElements.some(({ element }) => {
                if (element.kind === ElementKind.Button) {
                    // 0 means that button and group is the same size. 0.5 means that one element is double the size of the other
                    return Math.abs(areaRelation(element, layoutElement.rotatedBoundingBox) - 1) < 0.1;
                }
            });
        }
        return false;
    }
}
