<ai-studio-topbar />
<div class="content">
    <ai-studio-sidebar
        class="left ui-scrollbar"
        data-test-id="edit-element" />
    <div class="center">
        <div class="tooltips-wrapper">
            <undo-redo-tooltip />
            <zoom-tooltip />
        </div>
        <ai-studio-canvas />
    </div>
</div>
