import { CommonModule } from '@angular/common';
import { Component, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UIDropdownComponent, UIModule } from '@bannerflow/ui';
import { ICreative } from '@domain/creativeset/creative/creative';
import { DisplayCampaignService } from '../../../shared/display-campaign/state/display-campaign.service';
import { NavigationService } from '../../../shared/services/navigation.service';
import { TileSelectService } from '../services/tile-select.service';

@Component({
    standalone: true,
    imports: [CommonModule, UIModule],
    selector: 'add-to-campaign-dropdown',
    templateUrl: './add-to-campaign-dropdown.component.html',
    styleUrls: ['./add-to-campaign-dropdown.component.scss']
})
export class AddToCampaignDropdownComponent {
    private displayCampaignService = inject(DisplayCampaignService);
    private navigationService = inject(NavigationService);
    private tileSelectService = inject(TileSelectService);

    campaigns$ = this.displayCampaignService.campaigns$;

    @ViewChild('dropdown', { static: true }) dropdown: UIDropdownComponent;

    private creatives: ICreative[];

    constructor() {
        this.tileSelectService.selection$.pipe(takeUntilDestroyed()).subscribe(selectedCreatives => {
            this.creatives = selectedCreatives.filter(creative => creative.design);
        });
    }

    addToCampaign(campaignId: string): void {
        const creativesIds = this.creatives.map(({ id }) => id);

        this.navigationService.openAddCreativesToCampaign(campaignId, creativesIds);
    }
}
