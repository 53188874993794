import { ElementKind } from '@domain/elements';
import { GroupOrLayoutElement, ILayoutRule, isLayoutElement } from '../rules';

/**
 * A line must be smaller or equal to this size in either width or height
 */
const LINE_DEFINITION_THICKNESS = 2;

export class PreserveLineRule implements ILayoutRule {
    applyAutoContraintRule(layoutElement: GroupOrLayoutElement): void {
        if (isLayoutElement(layoutElement)) {
            const { element, constraint } = layoutElement;
            const { width, height } = element;

            // Apply only to rects which are not squares
            if (element.kind === ElementKind.Rectangle && width !== height) {
                if (height <= LINE_DEFINITION_THICKNESS) {
                    constraint.height = {
                        value: height,
                        unit: 'px'
                    };
                }
                if (width <= LINE_DEFINITION_THICKNESS) {
                    constraint.width = {
                        value: width,
                        unit: 'px'
                    };
                }
            }
        }
    }
}
