import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GenAIService } from '@app/shared/ai-studio/state/gen-ai.service';
import { imageUrlToFile } from '@app/shared/ai-studio/utils';
import { Logger } from '@bannerflow/sentinel-logger';
import {
    forEachDataElement,
    isImageNode,
    isVideoNode,
    ratioLockSvgElement
} from '@creative/nodes/helpers';
import { ImageLibraryAsset, VideoLibraryAsset } from '@domain/brand/brand-library';
import { IImageElementAsset, IVideoElementAsset } from '@domain/creativeset/element-asset';
import { IPosition } from '@domain/dimension';
import { IFontFamily } from '@domain/font-families';
import { IImageElementDataNode, IMediaElementDataNode, IVideoElementDataNode } from '@domain/nodes';
import { SaveType } from '@studio/domain/components/ai-studio.types';
import { AssetUploadCompleteEvent, EventLoggerService } from '@studio/monitoring/events';
import { FontFamiliesService } from '@studio/stores/font-families';
import { getBoundingboxWithinBoundary } from '@studio/utils/geom';
import {
    getAssetOfMediaElement,
    hasMediaReference,
    isImageFile,
    isVideoFile
} from '@studio/utils/media';
import { removeFileExtension } from '@studio/utils/url';
import { filter } from 'rxjs';
import { CreativesetDataService } from '../../../../shared/creativeset/creativeset.data.service';
import { UserService } from '../../../../shared/user/state/user.service';
import {
    AssetUploadService,
    IAssetUploadAfterProgressState,
    IAssetUploadLoadedState,
    IAssetUploadState
} from '../../services/asset-upload.service';
import { CopyPasteService } from '../../services/copy-paste.service';
import { EditorEventService } from '../../services/editor-event/editor-event.service';
import { ElementChangeType } from '../../services/editor-event/element-change';
import { EditorStateService } from '../../services/editor-state.service';
import { ElementCreatorService } from '../../services/element-creator.service';
import { HistoryService } from '../../services/history.service';
import { MutatorService } from '../../services/mutator.service';
import { StudioWorkspaceComponent } from '../studio-workspace.component';

@Injectable()
export class WorkspaceUploadAssetService {
    private assetUploadService = inject(AssetUploadService);
    private editorStateService = inject(EditorStateService);
    private editorEventService = inject(EditorEventService);
    private eventLoggerService = inject(EventLoggerService);
    private userService = inject(UserService);
    private copyPasteService = inject(CopyPasteService);
    private historyService = inject(HistoryService);
    private mutatorService = inject(MutatorService);
    private elementCreatorService = inject(ElementCreatorService);
    private creativesetDataService = inject(CreativesetDataService);
    private fontFamiliesService = inject(FontFamiliesService);
    private genAIService = inject(GenAIService);

    private assetUploadProcessId: string;
    private workspace: StudioWorkspaceComponent;
    private placeholders = new Map<string, IVideoElementDataNode | IImageElementDataNode>();
    private currAssetPosition: IPosition;
    private logger = new Logger('WorkspaceUploadAssetService');

    private creativeSetFontFamilies: IFontFamily[] = [];

    constructor() {
        this.assetUploadService.uploadProgress$
            .pipe(takeUntilDestroyed())
            .subscribe(this.onAssetUploadStateChange);

        this.fontFamiliesService.creativeSetFontFamilies$
            .pipe(takeUntilDestroyed())
            .subscribe(
                creativesetFontFamilies => (this.creativeSetFontFamilies = creativesetFontFamilies)
            );

        this.genAIService.saveOnCanvasPayload$
            .pipe(
                takeUntilDestroyed(),
                filter(payload => payload?.saveType === SaveType.Duplicate)
            )
            .subscribe(payload => {
                if (!payload) return;
                this.saveGenAIAssetToCanvasAsDuplicate(payload.imageAsset);
            });
    }

    initService(workspace: StudioWorkspaceComponent): void {
        this.workspace = workspace;
    }

    async uploadAssets(files: File[], currAssetPosition: IPosition, isGenAi = false): Promise<void> {
        this.logger.verbose(`Uploading images to workspace`);

        this.currAssetPosition = currAssetPosition;

        const id = await this.assetUploadService.uploadAssets({
            files,
            isDirectCanvasUpload: true,
            isGenAi
        });
        if (id) {
            this.assetUploadProcessId = id;
        }
    }

    private removeFailedUpload(assetId: string): void {
        const element = this.editorStateService.elements.find(
            el =>
                !!el.properties.find(
                    property => hasMediaReference(property) && property.value === assetId
                )
        );

        if (element) {
            const elements = this.editorStateService.elements.filter(el => el.id !== element.id);
            this.editorStateService.updateElements(elements);
            forEachDataElement(this.editorStateService.document, node => {
                if (node.id !== element.id) {
                    this.editorStateService.document.removeNodeById_m(node.id);
                }
            });
        }
    }

    private onAssetUploadStateChange = (uploadState: IAssetUploadState): void => {
        if (this.assetUploadProcessId !== uploadState.uploadProcessId) {
            return;
        }
        switch (uploadState.status) {
            case 'LOAD_ASSET_FILE_STARTED':
                this.uploadStarted(uploadState);
                break;
            case 'AFTER_PROGRESS':
                this.uploadFinished(uploadState);
                break;
            case 'COMPLETE':
                this.eventLoggerService.log(new AssetUploadCompleteEvent(), this.logger);
                break;
            case 'FAIL': {
                this.logger.warn(`Asset upload failed`);
                const placeHolder = this.placeholders.get(uploadState.asset.id);
                if (!placeHolder) {
                    return;
                }
                this.mutatorService.removeSelection(placeHolder);
                const asset = getAssetOfMediaElement(placeHolder);
                if (!asset) {
                    return;
                }
                this.removeFailedAssetUpload(asset.id);
                this.workspace.deselectAllElements();
                this.placeholders.delete(placeHolder.id);
                this.removeFailedUpload(asset.id);
                break;
            }
        }
    };

    private async uploadStarted(uploadState: IAssetUploadLoadedState): Promise<void> {
        this.logger.verbose(`Asset[${uploadState.file.name}] upload started`);

        const { asset, url, size, file } = uploadState;
        const { width, height } = size;
        asset.name = removeFileExtension(file.name);

        const positionAndSize = {
            ...getBoundingboxWithinBoundary(
                size,
                this.editorStateService.canvasSize,
                this.currAssetPosition
            )
        };

        if (isImageFile(file)) {
            const imageAsset: IImageElementAsset = {
                id: asset.id,
                url,
                ...size,
                name: asset.name,
                __loading: true
            };

            const imageElement = this.elementCreatorService.createImage({
                ...positionAndSize,
                name: asset.name,
                imageAsset
            });

            this.placeholders.set(asset.id, imageElement);
        } else if (isVideoFile(file)) {
            if (!(await this.userService.hasPermission('StudioVideoLibrary'))) {
                return;
            }

            const videoAsset: IVideoElementAsset = {
                id: asset.id,
                url: url,
                ...size,
                name: asset.name,
                fileSize: asset.fileSize,
                __loading: true
            };

            asset.url = url;
            asset.width = width;
            asset.height = height;
            asset.fileSize = file.size;

            const video = this.elementCreatorService.createVideo({
                ...positionAndSize,
                name: asset.name,
                videoAsset
            });

            this.placeholders.set(asset.id, video);
        }
    }

    private uploadFinished(uploadState: IAssetUploadAfterProgressState): void {
        this.logger.verbose(`Asset[${uploadState.asset.name}] upload finished`);

        const placeholder = this.placeholders.get(uploadState.asset.id);

        if (placeholder) {
            this.updateAsset(placeholder, uploadState);
        } else {
            throw new Error('No placeholder found');
        }
    }

    private updateAsset(
        placeHolder: IMediaElementDataNode,
        uploadState: IAssetUploadAfterProgressState
    ): void {
        const elementAsset = getAssetOfMediaElement(placeHolder);
        if (!elementAsset) {
            throw new Error('No asset found!');
        }
        const oldAssetId = uploadState.asset.id;
        const newAssetId = uploadState.newAsset.id;
        const newUrl = uploadState.newAsset.url;
        let newSize = 0;

        elementAsset.id = newAssetId;
        elementAsset.url = newUrl;
        elementAsset.__loading = false;

        if ('fileSize' in elementAsset) {
            newSize = uploadState.newAsset.fileSize;
            elementAsset.fileSize = newSize;
        }

        this.editorStateService.updateNewElementAsset(oldAssetId, newAssetId, newUrl, newSize);
        this.updateSnapshotsAssetUrl(oldAssetId, newAssetId, newUrl);

        if (isImageNode(placeHolder)) {
            ratioLockSvgElement(newUrl, placeHolder);
            this.creativesetDataService.creativeset.images.push(
                uploadState.newAsset as ImageLibraryAsset
            );
        } else if (isVideoNode(placeHolder)) {
            this.updateVideoThumbnail(placeHolder);
            this.creativesetDataService.creativeset.videos.push(
                uploadState.newAsset as VideoLibraryAsset
            );
        }

        const time = this.workspace.designView.animator?.time || 1;
        this.editorStateService.renderer.setViewElementValues_m(placeHolder, time);

        this.placeholders.delete(placeHolder.id);
    }

    private updateSnapshotsAssetUrl(oldAssetId: string, newAssetId: string, newUrl: string): void {
        this.logger.verbose('Updating snapshot assets!');
        this.historyService.updateSnapshotsAssetUrl(oldAssetId, newAssetId, newUrl);
        this.updateCopyPasteSnapshot();
    }

    private removeFailedAssetUpload(assetId: string): void {
        this.historyService.removeFailedAssetUpload(assetId);
        this.updateCopyPasteSnapshot();
    }

    private updateCopyPasteSnapshot(): void {
        const snapshot = this.copyPasteService.copiedSnapshot;
        if (snapshot) {
            this.copyPasteService.copyAndSetLocalstorage(
                snapshot,
                this.workspace.creativesetDataService.brand.id,
                this.creativeSetFontFamilies
            );
        }
    }

    private updateVideoThumbnail(element: IVideoElementDataNode): void {
        this.editorEventService.elements.change(element, element, ElementChangeType.Instant);
    }

    private saveGenAIAssetToCanvasAsDuplicate(imageAsset: IImageElementAsset): void {
        const { url, name } = imageAsset;

        imageUrlToFile(url, name ?? '').subscribe(async imageFile => {
            await this.uploadAssets(
                [imageFile],
                {
                    x: this.editorStateService.canvasSize.width / 2,
                    y: this.editorStateService.canvasSize.height / 2
                },
                true
            );
            this.genAIService.closeAIStudio();
        });
    }
}
