import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

parseURLParams();

let hasInitiated = false;

async function bootstrap(): Promise<void> {
    hasInitiated = true;

    if (environment.production) {
        enableProdMode();
    }

    platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .catch(err => (window.onerror ? window.onerror(err) : console.error(err)));
}

const handleVisibilityChange = (): void => {
    if (!document.hidden && !hasInitiated) {
        bootstrap();
        document.removeEventListener('visibilitychange', handleVisibilityChange, false);
    }
};

// bootstrap page when document is not hidden in order to boost performance when user cmd + click open new tabs
if (!document.hidden && !hasInitiated) {
    bootstrap();
} else {
    document.addEventListener('visibilitychange', handleVisibilityChange, false);
}

function parseURLParams(): void {
    const url = new URL(window.location.href);
    // setting page title to title if search params for title is provided in cmd + click open new tabs
    const title = url.searchParams.get('title');
    document.title = title || 'Studio';
    window.app = 'bannerflow';
}
