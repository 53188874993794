<div class="information-text">
    @if (duplicateToNew) {
        Please select a folder where you want your new creative set to be located.
    } @else {
        Please select an existing creative set to duplicate to. Only default version will be duplicated.
    }
</div>

<ui-panel>
    <ng-container panel-header>
        <ui-breadcrumbs
            class="breadcrumbs"
            [popoverConfig]="{ theme: 'small', panelClass: 'breadcrumb-panel' }">
            @for (breadcrumb of displayBreadcrumbs; track breadcrumb.id; let index = $index) {
                <ui-breadcrumb (click)="navigateToFolder(breadcrumb.id, index)">
                    {{ breadcrumb.name }}
                </ui-breadcrumb>
            }
        </ui-breadcrumbs>
        <ui-button
            class="create-folder-btn"
            [attr.data-testid]="'create-new-folder'"
            text="NEW FOLDER"
            svgIcon="plus"
            ui-theme="small"
            [disabled]="isNewFolder"
            (click)="createNewFolder()" />
    </ng-container>
    <div class="search-wrapper">
        <ui-input
            [icon]="'search'"
            [placeholder]="'Search in creative sets'"
            [(value)]="searchTerm"
            (keyup)="searchTermChanged(searchTerm)" />
    </div>
    <div
        #dialogTable
        class="dialog-table ui-scrollbar">
        <ui-table
            [loading]="isPendingRequest"
            (rowClicked)="selectionChange($event)"
            [columnNames]="columnNames"
            [dataSource]="dataSource"
            matSort
            matSortDisableClear
            [matSortActive]="(matSort$ | async)?.active ?? 'name'"
            [matSortDirection]="(matSort$ | async)?.direction ?? 'asc'"
            (matSortChange)="changeSorting($event)">
            <ng-container cdkColumnDef="name">
                <th
                    cdk-header-cell
                    mat-sort-header="name"
                    *cdkHeaderCellDef
                    class="cell-header text"
                    [ngStyle]="{ width: '80%' }">
                    Creative sets
                </th>

                <td
                    cdk-cell
                    class="cell text"
                    [ngClass]="{
                        selected: row.id === targetItemId,
                        disabled:
                            row.id === creativesetId ||
                            row.externalId === creativesetId ||
                            (duplicateToNew && row.type === 'CreativeSet'),
                        creativeset: row.type === 'CreativeSet'
                    }"
                    *cdkCellDef="let row; let i = index">
                    <div
                        class="row-wrapper ellipsis-wrapper"
                        [ngClass]="{ displayFlex: isNewFolder }"
                        [uiTooltip]="row.name"
                        [uiTooltipDisabled]="isNewFolder"
                        [uiTooltipOnlyWhenTruncated]="!isNewFolder"
                        uiTooltipMaxWidth="500px"
                        [attr.data-testid]="'list-name-' + i">
                        @if (isPendingRequest) {
                            <ui-skeleton-cell class="skeleton-cell"> </ui-skeleton-cell>
                        } @else {
                            @if (row.type === 'Folder') {
                                <ui-svg-icon
                                    class="icon folder-icon"
                                    icon="folder" />
                            }

                            @if (row.type === 'CreativeSet') {
                                <ui-logo
                                    class="logo icon"
                                    [small]="true" />
                            }

                            @if (isNewFolder && row['id'] === 'newFolderId') {
                                <ui-inline-edit
                                    [attr.data-testid]="'new-folder-inline-edit'"
                                    [isEditing]="true"
                                    [value]="''"
                                    [autoClose]="false"
                                    [clickOutside]="false"
                                    (cancel)="cancelNewFolder()"
                                    (save)="saveNewFolder($event)" />
                            }

                            <span class="row-name">{{ row.name }}</span>
                        }
                    </div>
                </td>
            </ng-container>

            <ng-container cdkColumnDef="createdAt">
                <th
                    cdk-header-cell
                    mat-sort-header="createdAt"
                    *cdkHeaderCellDef
                    class="cell-header text">
                    Created
                </th>
                <td
                    cdk-cell
                    class="cell text"
                    [ngClass]="{
                        selected: row.id === targetItemId,
                        disabled:
                            row.id === creativesetId ||
                            row.externalId === creativesetId ||
                            (duplicateToNew && row.type === 'CreativeSet')
                    }"
                    *cdkCellDef="let row; let i = index">
                    <span class="row-name">{{ row.createdAt }}</span>
                </td>
            </ng-container>
        </ui-table>
    </div>
    @if (showLoadMore) {
        <a
            class="load"
            (click)="loadMoreData()">
            Load more creativesets
        </a>
    }
</ui-panel>

<div
    class="action-info"
    data-testid="action-info">
    @if (!disableDuplication) {
        @if (duplicateToNew) {
            New creative set with the selected sizes and versions will be created in folder

            @if (breadcrumbs.length) {
                <ui-svg-icon
                    icon="folder"
                    class="folder-icon" />
            }
            <b>
                {{ breadcrumbs.length ? breadcrumbs[breadcrumbs.length - 1].name : 'Creative Sets' }}
            </b>
        } @else {
            Selected sizes will be duplicated to the creative set
            <ui-svg-icon
                icon="creative-set"
                class="creative-set-icon" />
            <b> {{ targetCreativesetName }}</b>
        }
    }
</div>

<ui-dialog-buttons
    class="action-buttons"
    ui-theme="default">
    <ui-button
        text="Cancel"
        type="default"
        (click)="cancel()" />
    <ui-button
        [id]="duplicateToNew ? 'interaction-duplicate-to-new' : 'interaction-duplicate-to-existing'"
        type="primary"
        [disabled]="disableDuplication"
        [loading]="!!(isDuplicating$ | async)"
        [text]="duplicateToNew ? 'Create' : 'Duplicate'"
        (click)="duplicateCreatives()" />
</ui-dialog-buttons>
