import { Injectable, OnDestroy } from '@angular/core';
import { Logger } from '@bannerflow/sentinel-logger';
import {
    HotkeyCommands,
    HotkeyEvents,
    IContextualCommand,
    IHotkey,
    IHotkeyBetterService,
    IHotkeyContext,
    IHotkeyStoredContext,
    KeyCombinationWithContext,
    OneOfHotkeyContext,
    OneOfHotkeyInput,
    OneOfKeyType
} from '@domain/hotkeys/hotkeys.types';
import { charCodeExclusion, controlKeys, HOTKEYS, ignoredKeys, keyCodeMap } from '@studio/hotkeys';
import { ActivityLoggerService } from '@studio/monitoring/activity-logger.service';
import { EventEmitter } from '@studio/utils/event-emitter';
import { Subject } from 'rxjs';

interface KeyEvent {
    up?: boolean;
    down?: boolean;
    keys: string[];
}

@Injectable({ providedIn: 'root' })
export class HotkeyBetterService
    extends EventEmitter<HotkeyEvents>
    implements IHotkeyBetterService, OnDestroy
{
    private _keyEvent$ = new Subject<KeyEvent>();
    keyEvent$ = this._keyEvent$.asObservable();

    private contextStack: IHotkeyStoredContext[] = [];
    private activeKeys: string[] = [];
    private activeControlKeys = new Set<string>();
    private hotkeyMap = new Map<KeyCombinationWithContext, IContextualCommand>();
    private isMac = navigator.userAgent.includes('Mac');
    private commandKeyIsDown = false;
    private currentEvent?: Event;
    private currentInput?: Window | HTMLInputElement | HTMLTextAreaElement;
    private currentKey?: string;
    private doublePressMaxThreshold = 300;
    private doublePressMinThreshold = 90;
    private lastKeypressTime = 0;
    private lastPressedKey: string;
    private isDead = false;
    private inCompositionMode = false;
    private suspended = false;
    private inputs = new Map<any, number /* frequency */>();
    private stopPropagationUntilRelease = false;

    private logger = new Logger('HotkeyBetterService');

    constructor(private activityLoggerService: ActivityLoggerService) {
        super();

        this.registerHotkeys();
        (window as any).hotkeyService = this;

        window.onfocus = (): void => {
            this.clearActiveControlKeys();
            this.clearActiveKeys();
        };
    }

    ngOnDestroy(): void {
        this.logger.verbose('Destroying');
        /*
            Used for rich-text-input.component.
            rich-text-input.component has its own instance of HotKeyBetterService.
            When the component is destroyed it will destroy its own instance of HotkeyBetterService
        */

        for (const context of this.contextStack) {
            context.input.removeEventListener(
                'keydown',
                (context as IHotkeyStoredContext).__keyDownHandler!
            );
            context.input.removeEventListener(
                'keyup',
                (context as IHotkeyStoredContext).__keyUpHandler!
            );
        }
    }

    registerHotkeys(): void {
        for (const hotkey of HOTKEYS) {
            const context = hotkey.context;
            if (typeof context === 'string') {
                this.registerHotkeyByContext(hotkey, context);
            } else {
                for (const c of context) {
                    this.registerHotkeyByContext(hotkey, c);
                }
            }
        }
    }

    forward(event: HotkeyCommands, target: HotkeyCommands): () => void {
        const callback = (): void => {
            this.emit(target);
        };
        this.on(event, callback);
        return callback;
    }

    unforward(event: HotkeyCommands, callback: () => void): void {
        this.off(event, callback);
    }

    private registerHotkeyByContext(hotkey: IHotkey, context: OneOfHotkeyContext): void {
        hotkey.returnCommandImmediately =
            typeof hotkey.returnCommandImmediately === 'boolean'
                ? hotkey.returnCommandImmediately
                : true;

        if (typeof hotkey.keyCombination === 'string') {
            this.hotkeyMap.set(`${context}:${hotkey.keyCombination}`, {
                context,
                name: hotkey.command,
                hotkey
            });
        } else {
            const keyCombination = this.isMac ? hotkey.keyCombination.mac : hotkey.keyCombination.win;
            if (!keyCombination) {
                return;
            }
            this.hotkeyMap.set(`${context}:${keyCombination}`, {
                context,
                name: hotkey.command,
                hotkey
            });
        }
    }

    placeBeforeContext(before: string, placement: IHotkeyContext): void {
        const currentIndex = this.contextStack.findIndex(c => c.name === before);
        this.contextStack.splice(Math.max(currentIndex - 1, 0), 0, placement as IHotkeyStoredContext);
        this.bindContext(placement);
    }

    /**
     * Add a context which
     */
    pushContext(context: IHotkeyContext): void {
        const hotkeyContext = context as IHotkeyStoredContext;
        this.contextStack.push(hotkeyContext);
        this.bindContext(context);
    }

    private bindContext(context: IHotkeyContext): void {
        this.logger.verbose(`bindContext - ${context.name}`);

        if (this.inputs.has(context.input)) {
            this.inputs.set(context.input, this.inputs.get(context.input)! + 1);
            return;
        }
        context.input.addEventListener('compositionstart', this.startComposition);
        context.input.addEventListener('compositionend', this.endComposition);
        context.input.addEventListener('keydown', this.onKeyDown as any);
        context.input.addEventListener('keyup', this.onKeyUp as any);
        context.input.addEventListener('blur', this.onBlur as any);
        this.inputs.set(context.input, 1);
    }

    /** Unset the current context */
    popContext(): void {
        if (this.contextStack.length === 0) {
            return;
        }

        // When popping context it sometimes the input can be stucked in the old input.
        this.currentInput = undefined;
        const context = this.contextStack.pop()!;
        this.logger.verbose(`Popping context - ${context.name}`);

        const frequency = this.inputs.get(context.input);
        if (frequency === 1) {
            context.input.removeEventListener('compositionstart', this.startComposition);
            context.input.removeEventListener('compositionend', this.endComposition);
            context.input.removeEventListener('keydown', this.onKeyDown as any);
            context.input.removeEventListener('keyup', this.onKeyUp as any);
            context.input.removeEventListener('blur', this.onBlur as any);
            this.inputs.delete(context.input);
        }
    }

    startComposition = (): void => {
        this.inCompositionMode = true;
    };

    endComposition = (): void => {
        this.inCompositionMode = false;
        this.activeKeys = [];
        this.activeControlKeys.clear();
    };

    emitKey(key: string, input: OneOfHotkeyInput, ...args: unknown[]): boolean {
        this.currentEvent = undefined;
        this.currentInput = input;
        const handledEvent = this.registerKey(key, ...args);
        this.unregisterEvent(key);
        this.currentKey = key;
        return handledEvent;
    }

    registerKey(event: string, ...args: unknown[]): boolean {
        this.registerActiveKey(event);
        return this.tryApplyCurrentCommand(...args);
    }

    unregisterEvent(event: string): void {
        this.unregisterActiveKey(event);
        this.tryApplyCurrentCommand();
    }

    // Suspend the service until restored
    suspend(): void {
        this.logger.verbose('Suspending');
        this.suspended = true;
    }

    // Restores the service from suspension
    restore(): void {
        this.logger.verbose('Restoring');
        this.suspended = false;
    }

    private onKeyDown = (event: KeyboardEvent): void => {
        this.logger.debug(`Key down: ${event.key}`);
        if (this.suspended) {
            return;
        }
        if (event.key === 'Dead') {
            this.isDead = true;
            return;
        }
        if (this.currentInput && this.currentInput !== event.currentTarget) {
            return;
        }
        // Filter out non-hotkey inputs
        if (
            (document.activeElement instanceof HTMLTextAreaElement ||
                document.activeElement instanceof HTMLInputElement) &&
            document.activeElement !== event.currentTarget
        ) {
            return;
        }
        // Filter CapsLock. On Mac it will prevent some actions. STUDIO-7652
        if (ignoredKeys.includes(event.key)) {
            return;
        }

        this.currentEvent = event;
        this.setCurrentInput(event.currentTarget as OneOfHotkeyInput);
        this.registerControlKeysFromEvent(event);

        let key = String.fromCharCode(event.which);
        if ([...charCodeExclusion, ...controlKeys].includes(event.key) || this.inCompositionMode) {
            key = event.key;
        }

        this.registerActiveKey(key);
        this._keyEvent$.next({ down: true, keys: this.activeKeys });
        this.tryApplyCurrentCommand();
    };

    private registerControlKeysFromEvent(event: KeyboardEvent): void {
        if (event.metaKey) {
            this.registerActiveKey('Meta');
        } else {
            this.unregisterActiveKey('Meta');
        }
        if (event.shiftKey) {
            this.registerActiveKey('Shift');
        } else {
            this.unregisterActiveKey('Shift');
        }
        if (event.ctrlKey) {
            this.registerActiveKey('Ctrl');
        } else {
            this.unregisterActiveKey('Ctrl');
        }
        if (event.altKey) {
            this.registerActiveKey('Alt');
        } else {
            this.unregisterActiveKey('Alt');
        }
    }

    private setCurrentInput(input: Window | HTMLInputElement | HTMLTextAreaElement): void {
        this.currentInput = input;

        // Multiple inputs can be fired
        window.setTimeout(() => {
            this.currentInput = undefined;
        }, 0);
    }

    private onBlur = (): void => {
        this.activeKeys = [];
    };

    private onKeyUp = (event: KeyboardEvent): void => {
        if (event.key === 'Dead') {
            return;
        }
        if (this.isDead) {
            this.isDead = false;
            this.activeKeys = [];
            this.activeControlKeys.clear();
            return;
        }
        if (this.currentInput && this.currentInput !== event.currentTarget!) {
            return;
        }
        // Filter out non-hotkey inputs
        if (
            (document.activeElement instanceof HTMLTextAreaElement ||
                document.activeElement instanceof HTMLInputElement) &&
            document.activeElement !== event.currentTarget
        ) {
            return;
        }
        this.setCurrentInput(event.currentTarget! as any);
        const key = [...charCodeExclusion, ...controlKeys].includes(event.key)
            ? event.key
            : String.fromCharCode(event.which);

        const now = Date.now();

        this._keyEvent$.next({ up: true, keys: this.activeKeys });
        this.registerControlKeysFromEvent(event);
        this.unregisterActiveKey(key);

        if (
            this.lastPressedKey === key &&
            now - this.lastKeypressTime <= this.doublePressMaxThreshold &&
            now - this.lastKeypressTime >= this.doublePressMinThreshold
        ) {
            this.registerActiveKey(key, '(DoublePress)');
            const commands = this.getCurrentCommands();
            this.unregisterActiveKey(key, '(DoublePress)');
            if (commands.length > 0) {
                this.executeCommands(commands);
                return;
            }
        }
        this.registerActiveKey(key, '(KeyUp)');
        const commands = this.getCurrentCommands();
        this.unregisterActiveKey(key, '(KeyUp)');
        this.lastKeypressTime = now;
        this.lastPressedKey = key;
        if (commands.length > 0) {
            this.executeCommands(commands);
            return;
        }
        if (!this.activeKeys.length) {
            this.stopPropagationUntilRelease = false;
        }
        this.setCurrentInput(event.currentTarget as OneOfHotkeyInput);
        this.tryApplyCurrentCommand();
    };

    private tryApplyCurrentCommand(...args: unknown[]): boolean {
        const commands = this.getCurrentCommands();
        if (commands.length > 0) {
            this.executeCommands(commands, ...args);
        }
        return commands.length > 0;
    }

    private registerActiveKey = (event: string, modifier?: OneOfKeyType): void => {
        const key = (this.currentKey = this.getKey(event));

        if (key === 'Cmd') {
            if (modifier) {
                return;
            }
            this.commandKeyIsDown = true;
            this.activeKeys = ['Cmd'];
            this.activeControlKeys.clear();
            this.activeControlKeys.add('Cmd');
            return;
        }
        const modifiedKey = key + (modifier || '');
        if (!this.activeKeys.includes(modifiedKey)) {
            // If command key is down, remove any none 'Cmd' key. To get around a Mac
            // shortcoming that prevent keyup event being fired on any key when 'Cmd'
            // is pressed.
            if (this.commandKeyIsDown) {
                this.activeKeys = Array.from(this.activeControlKeys);
            }
            this.activeKeys.push(modifiedKey);
            if (controlKeys.includes(key)) {
                this.activeControlKeys.add(key);
            }
            this.sortActiveKeys();
        }
    };

    private getContexts(input: EventTarget): IHotkeyStoredContext[] | undefined {
        return this.contextStack
            .slice()
            .reverse()
            .filter(context => context.input === input);
    }

    private getCurrentCommands(): IContextualCommand[] {
        const commands: IContextualCommand[] = [];
        const contexts = this.getContexts(this.currentInput!);
        if (!contexts) {
            return commands;
        }

        let returnImmediate = false;
        for (const context of contexts) {
            if (
                this.currentKey !== undefined &&
                this.currentEvent &&
                context.keyPropogationExclusions &&
                context.keyPropogationExclusions.includes(this.currentKey)
            ) {
                this.currentEvent.stopPropagation();
            }
            if (this.currentKey && context.keyDefaultBehaviourExclusions && this.currentEvent) {
                if (context.keyDefaultBehaviourExclusions.includes(this.activeKeys.join(' + '))) {
                    this.currentEvent.preventDefault();
                }
            }

            if (this.stopPropagationUntilRelease) {
                continue;
            }

            const hotkey = `${context.name}:${this.activeKeys.join(' + ')}`;
            if (this.hotkeyMap.has(hotkey) && !returnImmediate) {
                const keyString = this.activeKeys.join(' + ');
                const key = `${context.name}:${keyString}`;
                const command = this.hotkeyMap.get(key);
                if (!command) {
                    continue;
                }
                commands.push(command);

                if (command.hotkey.returnUntilKeyup) {
                    this.stopPropagationUntilRelease = true;
                }

                if (command.hotkey.returnCommandImmediately) {
                    returnImmediate = true;
                    break;
                }
            }
        }
        return commands;
    }

    private executeCommands(commands: IContextualCommand[], ...args: unknown[]): void {
        for (const command of commands) {
            const activity = `Applying the shortcut '${this.getKeyCombinationFromHotKey(
                command.hotkey
            )}' of '${command.name}' in context '${command.context.toString()}'`;
            this.logger.verbose(activity);
            this.activityLoggerService.log(activity);

            this.emit(command.name, ...args);
        }
    }

    private getKeyCombinationFromHotKey(hotkey: IHotkey): string {
        const keyCombination = hotkey.keyCombination;
        if (typeof keyCombination === 'string') {
            return keyCombination;
        }
        if (this.isMac && keyCombination.mac) {
            return keyCombination.mac;
        }
        return keyCombination.win;
    }

    private getKey(event: string): string {
        const key = keyCodeMap[event] || event;
        if (key === 'Meta') {
            if (this.isMac) {
                return 'Cmd';
            } else {
                return 'Win';
            }
        }
        if (key === ' ') {
            return 'Spacebar';
        }
        if (key === '+') {
            return 'Plus';
        }
        if (key === '-') {
            return 'Minus';
        }
        return key.length === 1 ? key.toUpperCase() : key;
    }

    private sortActiveKeys(): void {
        this.activeKeys = this.activeKeys.sort((a, b) => {
            const indexA = controlKeys.indexOf(a);
            const indexB = controlKeys.indexOf(b);
            const priorityA = controlKeys.length - (indexA !== -1 ? indexA : Infinity);
            const priorityB = controlKeys.length - (indexB !== -1 ? indexB : Infinity);
            return priorityB - priorityA;
        });
    }

    private unregisterActiveKey = (event: string, modifier?: OneOfKeyType): void => {
        const key = this.getKey(event);
        const modifiedKey = key + (modifier || '');
        if (this.activeControlKeys.has(key)) {
            if (key === 'Cmd') {
                this.commandKeyIsDown = false;
            }
            this.activeControlKeys.delete(key);

            // Clear all active keys due to a shortcoming in Mac. When holding CMD key on Mac, it
            // doesn't releases other buttons when you physically releases them.
            if (this.isMac) {
                this.activeKeys = Array.from(this.activeControlKeys);
            } else {
                this.activeKeys = this.activeKeys.filter(k => k !== modifiedKey);
            }
            this.sortActiveKeys();
            return;
        }
        if (this.activeKeys.includes(modifiedKey)) {
            this.activeKeys = this.activeKeys.filter(k => k !== modifiedKey);
            this.sortActiveKeys();
        }
    };

    clearActiveKeys(): void {
        this.activeKeys = [];
    }

    clearActiveControlKeys(): void {
        this.activeControlKeys.clear();
    }
}
