import { Injectable, EventEmitter } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ItemListOverflowService {
    updateObserver = new EventEmitter();

    update(): void {
        this.updateObserver.emit();
    }
}
