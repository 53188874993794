@let _numberOfSelectedSizes = numberOfSelectedSizes();
@if (_numberOfSelectedSizes) {
    <div class="wrapper">
        <span>
            @if (_numberOfSelectedSizes === 1) {
                1 size selected
            } @else {
                {{ _numberOfSelectedSizes }} sizes selected
            }
        </span>
        <a
            class="action"
            (click)="clearSelection()">
            Clear the selection
        </a>
    </div>
}
