import { Component, ViewChild } from '@angular/core';
import { TileSelectService } from '../../services/tile-select.service';
import { Logger } from '@bannerflow/sentinel-logger';
import { UIDropdownComponent, UIModule } from '@bannerflow/ui';
import { ICreative } from '@domain/creativeset/creative';
import { ExportType } from '@domain/creativeset/creative/export-creative';
import { hasDesign } from '@studio/utils/design.utils';
import { ExportCreativeDialogService } from '../state/export-dialog.service';
import { CommonModule } from '@angular/common';

@Component({
    standalone: true,
    imports: [CommonModule, UIModule],
    selector: 'export-dropdown',
    templateUrl: './export-dropdown.component.html',
    styleUrls: ['./export-dropdown.component.scss']
})
export class ExportDropdownComponent {
    @ViewChild('dropdown', { static: true }) dropdown: UIDropdownComponent;

    disabledOptions = false;

    private creatives: ICreative[];
    private logger = new Logger('ExportDropdownComponent');

    constructor(
        private exportCreativeDialogService: ExportCreativeDialogService,
        private tileSelectService: TileSelectService
    ) {}

    isExportable(creatives: ICreative[]): ICreative[] {
        return creatives.filter(hasDesign);
    }

    async openExportDialog(type: ExportType): Promise<any> {
        if (this.creatives.length) {
            this.exportCreativeDialogService.openDialog({ type, creatives: this.creatives });
        } else {
            this.logger.warn('Trying to export without any creatives');
        }
    }

    onOpenDropdown(): void {
        this.creatives = this.isExportable(this.tileSelectService.getSelected());
        this.disabledOptions = this.creatives ? this.creatives.length < 1 : true;
    }
}
