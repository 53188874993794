<div
    class="control"
    [class.disabled]="disabled">
    <ui-svg-icon
        *media="Breakpoint.DesktopUp"
        id="animation-controls-replay"
        class="icon restart"
        (click)="stepToStart()"
        uiTooltip="Jump to start"
        [uiTooltipHideArrow]="true"
        [uiTooltipDisabled]="disabled"
        icon="playback-start-small"></ui-svg-icon>
    <ui-svg-icon
        *media="Breakpoint.DesktopUp"
        id="animation-controls-step-back"
        class="icon step-left"
        (click)="stepBack()"
        uiTooltip="Step -1 second"
        [uiTooltipHideArrow]="true"
        [uiTooltipDisabled]="disabled"
        icon="playback-step-left-small"></ui-svg-icon>
    <ui-svg-icon
        id="animation-controls-play-pause"
        class="icon play"
        (click)="togglePlay()"
        uiTooltip="Play / pause (space)"
        [class.inactive]="isInactive$ | async"
        [uiTooltipDisabled]="disabled || isMobileShowcase"
        [uiTooltipHideArrow]="true"
        [icon]="(isPlaying$ | async) ? 'playback-pause' : 'playback-play'"></ui-svg-icon>
    <ui-svg-icon
        *media="Breakpoint.DesktopUp"
        id="animation-controls-step-forward"
        class="icon step-forward"
        (click)="stepForward()"
        uiTooltip="Step +1 second"
        [uiTooltipHideArrow]="true"
        [uiTooltipDisabled]="disabled"
        icon="playback-step-right-small"></ui-svg-icon>
    <ui-svg-icon
        id="animation-controls-view-preload-image"
        class="icon preload"
        (click)="stepToPreloadImage()"
        [class.active]="preloadActive$ | async"
        [uiTooltipHideArrow]="true"
        uiTooltip="View preload image"
        [uiTooltipDisabled]="disabled || isMobileShowcase"
        icon="image"></ui-svg-icon>
</div>
