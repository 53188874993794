import { EnvironmentProviders, importProvidersFrom } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FontFamiliesEffects } from './font-families.effects';
import { FONT_FAMILIES_FEATURE_KEY, reducer } from './font-families.reducer';

export function provideFontFamiliesStore(): EnvironmentProviders {
    return importProvidersFrom(
        StoreModule.forFeature(FONT_FAMILIES_FEATURE_KEY, reducer),
        EffectsModule.forFeature(FontFamiliesEffects)
    );
}
