import { z, ZodTypeAny } from 'zod';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const zodOptional = <T extends z.ZodTypeAny>(_z: T) =>
    z.preprocess(v => (v === null ? undefined : v), _z.optional());

export const toGraphqlDto = <T extends z.ZodObject<Record<string, ZodTypeAny>>>(_z: T): string => {
    let dtoString = '';
    for (const key in _z.shape) {
        const value = _z.shape[key];
        dtoString += `${key}`;
        const isZodObject = value instanceof z.ZodObject;
        const isZodArray = value instanceof z.ZodArray;
        if (isZodArray) {
            dtoString += ` { ${toGraphqlDto(value.element)} }`;
        }

        if (isZodObject) {
            dtoString += ` { ${toGraphqlDto(value)} }`;
        }

        dtoString += ' ';
    }
    return dtoString.trim();
};
