import { Pipe, PipeTransform } from '@angular/core';
import { sortByFormatSize } from '@studio/utils/utils';
import { CreativeSize } from '@domain/creativeset';

@Pipe({ standalone: true, name: 'sortByGroupWidth' })
export class SortByGroupWidthPipe implements PipeTransform {
    transform(sizes: CreativeSize[]): CreativeSize[] {
        return sortByFormatSize([...sizes]);
    }
}
