import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UINotificationService, UIPopoverComponent } from '@bannerflow/ui';
import { IFeedStore } from '@domain/creative/feed/feed-store.header';
import { IBfFeed, IFeed, IFeedDataKeyValueItem, IFeedStep } from '@domain/feed';
import { FeedSettingService } from './feed-settings.service';
import { FeedService } from './feed.service';

@Component({
    selector: 'feed-popover',
    templateUrl: 'feed-popover.component.html',
    styleUrls: ['feed-popover.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedPopoverComponent implements OnInit {
    canEditSource = true;
    feed?: IFeed;
    feedName: string;
    feedStore: IFeedStore;
    elementId: string;

    totalFeedItems: number;
    path: string;
    fields: IFieldOption[] = [];
    placeholder: string;

    disableInputs = false;
    // used for mixed IFeedStep values 'start', 'size', 'occurence'
    // when grouped element is collapsed
    visibleFeedStep: IFeedStep;

    constructor(
        private feedService: FeedService,
        private changeDetector: ChangeDetectorRef,
        private uiNotificationService: UINotificationService,
        private feedSettingsService: FeedSettingService,
        private uiPopoverComponent: UIPopoverComponent
    ) {
        this.disableInputs = !!this.uiPopoverComponent.config?.data?.disableInputs;

        this.feedSettingsService.updateFeedPopOver$.pipe(takeUntilDestroyed()).subscribe(() => {
            this.updatePopoverValues();
        });
    }

    ngOnInit(): void {
        this.updatePopoverValues();
    }

    hideEditSource(): void {
        this.canEditSource = false;
        this.changeDetector.detectChanges();
    }

    onFeedPathValueChanged(value: string): void {
        this.feed!.path = value;
        this.feedSettingsService.changeFeedValue(this.feed!);
        this.changeDetector.detectChanges();
    }

    onFeedOccurrenceChanged(newValue: 'loop' | 'none'): void {
        if (!this.feed) {
            return;
        }
        this.feed = {
            ...this.feed,
            step: {
                ...this.feed.step,
                occurrence: newValue
            }
        };
        this.feedSettingsService.changeFeedValue(this.feed);
        this.changeDetector.detectChanges();
    }
    onFeedValueChanged(value: number, key: 'size' | 'start' | 'occurence'): void {
        if (!this.feed) {
            return;
        }
        this.feed = {
            ...this.feed,
            step: {
                ...this.feed.step,
                [key]: value
            }
        };
        this.feedSettingsService.changeFeedValue(this.feed);
        this.changeDetector.detectChanges();
    }

    async useSelectedFeedItem(selectedFeed: IBfFeed): Promise<void> {
        if (!this.feed) {
            return;
        }
        if (!this.feedStore.feeds.has(selectedFeed.id)) {
            await this.feedStore.add(selectedFeed.id);
        }
        const feed = this.feedStore.feeds.get(selectedFeed.id)?.feed;
        const { path } = this.feed;
        const pathExistInNewData = feed?.data.some(data => data[path]);
        if (pathExistInNewData === false) {
            this.uiNotificationService.open(
                `The selected feed source doesn't include variable named "${this.feed.path}"`,
                {
                    autoCloseDelay: 5000,
                    placement: 'top',
                    type: 'error'
                }
            );
            return;
        }
        this.feed.id = selectedFeed.id;
        this.feedName = selectedFeed.name;
        const feedIsImage = this.feed.type === 'image';
        this.populateFields(feed!.data[0], feedIsImage);
        this.feedSettingsService.changeFeedValue(this.feed);

        this.changeDetector.detectChanges();
    }

    private async updatePopoverValues(): Promise<void> {
        this.feed = this.feedSettingsService.feed;
        this.elementId = this.feedSettingsService.elementId!;
        this.feedStore = this.feedSettingsService.feedStore;
        if (this.feedSettingsService.visibleFeedStep) {
            this.visibleFeedStep = this.feedSettingsService.visibleFeedStep;
        } else {
            this.visibleFeedStep = this.feed?.step;
        }

        if (!this.feed) {
            return;
        }
        const feedElement = this.feedStore.elements.get(this.elementId);
        this.totalFeedItems = feedElement!.items;
        const elementFeed = await this.feedService.getNestedFeed(feedElement!.feed.id);
        const feedData = this.feedStore.feeds.get(this.feed.id)!.feed;

        if (feedData && feedData.data.length > 0) {
            const feedIsImage = feedElement!.feed.type === 'image';
            const firstValue = feedData.data[0];
            this.populateFields(firstValue, feedIsImage);
        }

        this.feedName = elementFeed ? elementFeed.name : '[Deleted feed]';
        const feedPath = decodeURIComponent(this.feed.path);

        if (feedData && (!feedData.data[0] || !(feedPath in feedData.data[0]))) {
            this.placeholder = '[Field not found]';
        } else {
            this.path = feedPath;
        }

        this.changeDetector.detectChanges();
    }

    private populateFields(feed: IFeedDataKeyValueItem, feedIsImage: boolean): void {
        this.fields = [];
        for (const label of Object.keys(feed)) {
            const value = feed[label].value;
            const valueHasImageExt = value?.toString().match(/\.png|\.jpeg|\.gif|\.jpg|\.svg/);
            const showWarning =
                (feedIsImage && !valueHasImageExt) || (!feedIsImage && valueHasImageExt);

            const newOption: IFieldOption = {
                text: label,
                showDisabledText: !!showWarning,
                disabledTooltipText: 'This feed field is not compatible with the element type'
            };
            this.fields.push(newOption);
        }
    }
}

interface IFieldOption {
    text: string;
    showDisabledText: boolean;
    disabledTooltipText?: string;
}
