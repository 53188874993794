import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { PermissionsDirective } from '../../directives/permissions.directive';
import { EnvironmentService } from '../../services/environment.service';
import { UIDropdownComponent, UIModule } from '@bannerflow/ui';
import { Observable, Subject, merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PointerStatesService } from '../../services/pointer-state.service';
import { logoAnimation } from './topbar-context-menu.animation';

@Component({
    standalone: true,
    imports: [CommonModule, UIModule, PermissionsDirective],
    selector: 'topbar-context-menu',
    templateUrl: './topbar-context-menu.component.html',
    styleUrls: ['./topbar-context-menu.component.scss'],
    animations: [logoAnimation]
})
export class TopbarContextMenuComponent implements AfterViewInit, OnDestroy {
    @Input() target: UIDropdownComponent;
    @Input() border = false;
    @Input() static = false;
    menuOpen = false;
    active = false;
    isMobile = false;
    isMobileShowcase$: Observable<boolean>;
    private unsubscribe$ = new Subject<void>();

    constructor(
        // TODO-REF: kill that service or improve hover state sharing
        private pointerStateService: PointerStatesService,
        private environmentService: EnvironmentService
    ) {
        this.isMobile = this.environmentService.isMobile;
        this.isMobileShowcase$ = this.environmentService.isMobileShowcase$;
    }

    ngAfterViewInit(): void {
        merge(
            this.pointerStateService.workspaceHovered,
            this.pointerStateService.managePageScrollViewHovered
        )
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(isHovered => (this.active = !isHovered));
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
