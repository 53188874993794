import { IVersionProperty, IWidgetText } from '@domain/creativeset/version';

export function serializeWidgetText(versionProperty: IVersionProperty<IWidgetText>): string {
    return serializeWidgetTextValue(versionProperty.value);
}
export function serializeWidgetTextValue(value: IWidgetText): string {
    const validProperty: IWidgetText = {
        text: value.text
    };
    return JSON.stringify(validProperty);
}
