import { Pipe, PipeTransform } from '@angular/core';
import { VersionsService } from '../../../shared/versions/state/versions.service';
import { isWidgetElement } from '@creative/nodes';
import { IElement } from '@domain/creativeset/element';
import { IVersion, IVersionedText } from '@domain/creativeset/version';
import { take } from 'rxjs';

@Pipe({
    name: 'defaultValue',
    standalone: true
})
export class ElementDefaultValuePipe implements PipeTransform {
    private defaultVersion: IVersion;

    constructor(private versionsService: VersionsService) {
        this.versionsService.defaultVersion$.pipe(take(1)).subscribe(defaultVersion => {
            this.defaultVersion = defaultVersion;
        });
    }
    transform(element: IElement): string | undefined {
        if (isWidgetElement(element)) {
            return 'Widget';
        }
        const textProperty = element.properties[0];
        const versionPropertyId = textProperty?.versionPropertyId;
        if (!versionPropertyId) {
            return (textProperty.value as IVersionedText)?.text;
        }

        const versionProperty = this.defaultVersion.properties.find(
            property => property.id === versionPropertyId
        );

        return (versionProperty?.value as IVersionedText)?.text;
    }
}
