import { CommonModule } from '@angular/common';
import { Component, OnDestroy, TemplateRef } from '@angular/core';
import { IUIDialog, UIModule } from '@bannerflow/ui';
import { IVersion } from '@domain/creativeset/version';
import { VersionFlagComponent } from '../version-picker/version-flag/version-flag.component';

@Component({
    standalone: true,
    imports: [CommonModule, UIModule, VersionFlagComponent],
    selector: 'version-delete-dialog',
    styleUrls: ['./version-delete-dialog.component.scss'],
    templateUrl: './version-delete-dialog.component.html'
})
export class VersionDeleteDialogComponent implements IUIDialog, OnDestroy {
    dialogTemplate: TemplateRef<any>;
    version: IVersion;
    nonDefaultVersions: IVersion[];
    versionToReplaceDefault: IVersion;
    state: 'forbidden' | 'auto-default' | 'choose-default' | 'initial';
    private resolve: (
        value: IVersionDeleteDialogResponse | PromiseLike<IVersionDeleteDialogResponse>
    ) => void;
    private confirmPromise: Promise<IVersionDeleteDialogResponse>;

    initiate(
        version: IVersion,
        versions: IVersion[],
        _defaultVersion: IVersion
    ): Promise<IVersionDeleteDialogResponse> {
        this.version = version;
        this.nonDefaultVersions = versions
            .filter(v => v.id !== version.id)
            .sort((a, b) => a.name.localeCompare(b.name));

        if (this.nonDefaultVersions.length === 0) {
            this.state = 'forbidden';
        } else if (this.nonDefaultVersions.length === 1) {
            this.state = 'auto-default';
            this.versionToReplaceDefault = this.nonDefaultVersions[0];
        } else if (this.nonDefaultVersions.length > 1) {
            this.state = 'choose-default';
            this.versionToReplaceDefault = this.nonDefaultVersions[0];
        }

        this.confirmPromise = new Promise<IVersionDeleteDialogResponse>(resolve => {
            this.resolve = resolve;
        });

        return this.confirmPromise;
    }

    delete(): void {
        this.resolve({
            doDelete: true,
            newDefaultVersion: this.state !== 'forbidden' ? this.versionToReplaceDefault : undefined
        });
    }

    cancel(): void {
        this.resolve({ doDelete: false });
    }

    ngOnDestroy(): void {
        if (this.resolve) {
            this.resolve({ doDelete: false });
        }
    }
}

export interface IVersionDeleteDialogResponse {
    doDelete: boolean;
    newDefaultVersion?: IVersion;
}
