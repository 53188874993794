import { Injectable, OnDestroy } from '@angular/core';
import { Logger } from '@bannerflow/sentinel-logger';
import { Color } from '@creative/color';
import { ColorType } from '@domain/color';
import { ICreativeDataNode } from '@domain/nodes';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { EditorStateService } from '../../pages/design-view/services';
import { WorkspaceGradientHelperService } from '../../pages/design-view/workspace/workspace-gradient-helper.service';
import { IColorPalette } from '../components/color-picker/color-palette.interface';
import { addColor } from '../components/color/color.util';

interface IColorPickerToggle {
    subject: BehaviorSubject<boolean>;
    open: Observable<boolean>;
}

@Injectable()
export class ColorService implements OnDestroy {
    colorPickerToggles = new Map<string, IColorPickerToggle>();

    gradientPointSelected = new Subject<Color>(); // emits the selected color point of the gradient
    gradientPointChange = new Subject<Color>(); // emits the selected gradient color
    gradientPointDragged = new Subject<Color>();

    private logger = new Logger('ColorService');

    constructor(
        private editorStateService: EditorStateService,
        public gradientHelper: WorkspaceGradientHelperService
    ) {}

    ngOnDestroy(): void {
        this.destroyGradientHelper();
    }

    initColorPicker(name: string): void {
        const picker = this.colorPickerToggles.get(name);
        if (!picker) {
            const colorPickerSubject$ = new BehaviorSubject<boolean>(false);
            const colorPickerOpen$ = colorPickerSubject$.asObservable();

            this.colorPickerToggles.set(name, { subject: colorPickerSubject$, open: colorPickerOpen$ });
        }
    }

    isColorPickerOpen(name: string): Observable<boolean> {
        this.logger.debug(`isColorPickerOpen[${name}]`);

        const picker = this.getColorPicker(name);
        return picker.open;
    }

    toggleColorPicker(name: string): void {
        this.logger.verbose(`Toggling color picker[${name}]`);

        const picker = this.getColorPicker(name);
        const value = picker.subject.value;
        if (!value) {
            // close all pickers before opening a new one, otherwise the old destroy will happen after init and destroy gradient helpers
            this.colorPickerToggles.forEach(toggle => {
                toggle.subject.next(false);
            });
        }
        picker.subject.next(!value); // tried to apply some RXJS magic for toggling, but then the value couldnt be set manually
    }

    closeColorPicker(name: string): void {
        const picker = this.getColorPicker(name);
        if (picker.subject.value) {
            this.logger.verbose(`Closing color picker[${name}]`);
            picker.subject.next(false);
        }
    }

    /**
     * Show gradient helper on canvas
     */
    initGradientHelper(color: Color): void {
        this.logger.verbose('initGradientHelper');
        if (color.type !== ColorType.Solid) {
            this.unlistenGradientHelper();
            this.gradientHelper.on('point_select', this.onPointSelect);
            this.gradientHelper.on('point_change', this.onPointChange);
            this.gradientHelper.on('point_dragged', this.onPointDragged);
            this.gradientHelper.start(color);
        } else {
            this.logger.warn('cant init gradient helper on solids');
        }
    }

    unlistenGradientHelper(): void {
        this.gradientHelper.off('point_select', this.onPointSelect);
        this.gradientHelper.off('point_change', this.onPointChange);
        this.gradientHelper.off('point_dragged', this.onPointDragged);
    }

    updateGradientHelper(color: Color): void {
        this.gradientHelper.updateSelectedColor(color);
    }

    /**
     * Stop gradient helper on canvas
     */
    stopGradientHelper(): void {
        if (this.gradientHelper.active) {
            this.gradientHelper.stop();
        }
    }

    destroyGradientHelper(): void {
        this.logger.verbose('destroyGradientHelper');
        this.stopGradientHelper();
        this.unlistenGradientHelper();
    }

    onPointSelect = (): void => {
        this.gradientPointSelected.next(this.gradientHelper.selectedPoint!.color);
    };

    onPointChange = (): void => {
        this.gradientPointChange.next(this.gradientHelper.gradient!);
    };

    onPointDragged = (): void => {
        this.gradientPointDragged.next(this.gradientHelper.gradient!);
    };

    /**
     * Get all colors used on canvas
     */
    getColorsInUse(): IColorPalette[] {
        this.logger.debug('getColorsInUse()');

        let colors: Color[] = [];

        if (this.editorStateService.document) {
            const creative = this.editorStateService.document;

            if (creative) {
                colors = this.getColorsOfCreative(creative);
            }
        }

        // Return only unique colors
        return [
            {
                name: 'Recent used colors in creative set',
                swatch: colors.filter(
                    (color, i, a) => a.findIndex(c => c.toString() === color.toString()) === i
                )
            }
        ];
    }

    getColorsOfCreative(creative: ICreativeDataNode): Color[] {
        const colors: Color[] = [];
        addColor(colors, creative.fill);
        creative.elements.forEach(element => {
            if (element.fill) {
                addColor(colors, element.fill);
            }
            if (element.kind === 'text' && element.textColor) {
                addColor(colors, element.textColor);
            }
            if (element.border) {
                addColor(colors, element.border.color);
            }
            if (element.shadows && element.shadows.length) {
                element.shadows.forEach(shadow => {
                    addColor(colors, shadow.color);
                });
            }
        });

        return colors;
    }

    isGradientHelperClick(): boolean {
        const mousePoition = this.gradientHelper.workspace.mousePosition;
        if (mousePoition) {
            return (
                this.gradientHelper.active &&
                !!this.gradientHelper.pointAt(mousePoition.x, mousePoition.y)
            );
        }
        return false;
    }

    private getColorPicker(name: string): IColorPickerToggle {
        const picker = this.colorPickerToggles.get(name);
        if (!picker) {
            this.logger.verbose(`ColorPicker[${name}] was not registered! Doing it now.`);
            this.initColorPicker(name);
            return this.getColorPicker(name);
        }

        return picker;
    }
}
