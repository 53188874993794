import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { SizeAddInitActions, SizeAddSelectionActions } from './size-add.actions';
import * as SizeAddSelectors from './size-add.selectors';
import { ISelectableSize } from '@studio/domain/components/size-list.types';

@Injectable({ providedIn: 'root' })
export class SizeAddStateService {
    private store = inject(Store);

    loaded$ = this.store.select(SizeAddSelectors.getLoaded);
    error$ = this.store.select(SizeAddSelectors.getError);

    sizes$ = this.store.select(SizeAddSelectors.getSizes);
    collections$ = this.store.select(SizeAddSelectors.getCollections);

    selectedCollections$ = this.store.select(SizeAddSelectors.getSelectedCollections);
    selectedCollectionsIds$ = this.store.select(SizeAddSelectors.getSelectedCollectionsIds);

    selectedSizes$ = this.store.select(SizeAddSelectors.getSelectedSizes);
    filteredSizes$ = this.store.select(SizeAddSelectors.getFilteredSizes);

    setSelectedCollections(selectedCollectionIds: string[]): void {
        this.store.dispatch(SizeAddSelectionActions.setSelectedCollections({ selectedCollectionIds }));
    }

    selectAllSizes(): void {
        this.store.dispatch(SizeAddSelectionActions.selectAllSizes());
    }

    deselectAllSizes(): void {
        this.store.dispatch(SizeAddSelectionActions.deselectAllSizes());
    }

    clearSelection(): void {
        this.store.dispatch(SizeAddSelectionActions.clearSelection());
    }

    increaseSelectedSize(size: ISelectableSize, byNumber = 1): void {
        this.store.dispatch(SizeAddSelectionActions.increaseSelectedSizeCount({ size, byNumber }));
    }

    addSizeToCustomSizesCollection(size: ISelectableSize): void {
        this.store.dispatch(SizeAddSelectionActions.addSizeToCustomSizesCollection({ size }));
    }

    reset(): void {
        this.store.dispatch(SizeAddInitActions.reset());
    }
}
