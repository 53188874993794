import { ImageFormat } from '@studio/domain/api/gen-ai';
import { EMPTY, from, map, Observable, switchMap } from 'rxjs';

export function getFileNameFromPrompt(prompt: string): string {
    return prompt.substring(0, 30).replace(/\s+/g, '_');
}

export function removeHeaderOfBase64(base64: string): string {
    let strippedBase64 = base64.split(',')[1];

    // Fix padding
    const remainder = strippedBase64.length % 4;
    if (remainder > 0) {
        strippedBase64 += '='.repeat(4 - remainder);
    }

    return strippedBase64;
}

export function getImageExtensionFromBase64(base64String: string): string {
    const mimeRegex = /^data:image\/(.*?);base64/;
    const match = mimeRegex.exec(base64String);

    if (!match) {
        throw new Error('Invalid Base64 image string');
    }

    return match[1];
}
export function base64ToFile(base64: string, fileName: string): File {
    const base64Split = base64.split(',');
    if (!base64Split.length) {
        throw new Error('Invalid Base64');
    }
    const mimeRegex = /:(.*?);/;
    const mime = mimeRegex.exec(base64Split?.[0] ?? '')?.[1];
    if (!mime) {
        throw new Error('Invalid mime type');
    }
    const dataString = atob(base64Split[base64Split.length - 1]);
    let fileLength = dataString.length;
    const u8arr = new Uint8Array(fileLength);
    while (fileLength--) {
        u8arr[fileLength] = dataString.charCodeAt(fileLength);
    }
    const extension = mime.toLowerCase() === 'image/png' ? ImageFormat.PNG : ImageFormat.JPG;
    return new File([u8arr], `${fileName}.${extension}`, { type: mime });
}

export function imageUrlToBase64(url: string | undefined): Observable<string> {
    if (!url) {
        return EMPTY;
    }
    return imageUrlToBlob(url).pipe(switchMap(blob => fileToBase64(blob)));
}

export function imageUrlToFile(url: string | undefined, fileName: string): Observable<File> {
    if (!url) {
        return EMPTY;
    }
    return imageUrlToBlob(url).pipe(
        switchMap(blob => from(fileToBase64(blob)).pipe(map(base64 => base64ToFile(base64, fileName))))
    );
}

export function imageUrlToBlob(url: string): Observable<Blob> {
    return from(fetch(url).then(response => response.blob()));
}

function fileToBase64(file: File | Blob): Promise<string> {
    return new Promise<string>((resolve, reject): void => {
        const reader: FileReader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (): void => {
            resolve(reader.result as string);
        };

        reader.onerror = (): void => {
            reject(new Error('File read error'));
        };
    });
}
