import { IBoundingBox, IBounds, IPosition } from '@domain/dimension';
import { drawRoundRect } from '@studio/utils/canvas-utils';
import { fromResize } from '@studio/utils/resize-observable';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GizmoColor } from '../../../shared/components/canvas-drawer/gizmo.colors';
import { StudioTimelineComponent } from './studio-timeline/studio-timeline.component';
import { SCROLLBAR_TRACK_WIDTH, TIMERULER_HEIGHT } from './timeline.constants';

export const LABEL_HEIGHT = 22;

export class TimelineGizmoDrawer {
    shouldDrawScrollBars = false;

    width: number;
    height: number;
    private ctx: CanvasRenderingContext2D;
    mousePosition: IPosition;
    transitionHighlighted?: 'in' | 'out';
    private unsubscribe$ = new Subject<void>();
    fontFamily: string;
    canvasSizeSet$ = new Subject<void>();

    constructor(
        public timeline: StudioTimelineComponent,
        private canvas: HTMLCanvasElement
    ) {
        this.ctx = this.canvas.getContext('2d') as CanvasRenderingContext2D;

        this.setCanvasSize();

        fromResize(this.canvas)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(rect => this.setCanvasSize(rect.width, rect.height));

        this.fontFamily = getComputedStyle(document.body).fontFamily!;
    }

    destroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    draw(): void {
        const timeline = this.timeline;
        const width = this.width;
        const height = this.height;
        const zoom = timeline.zoomService.zoom;

        if (!timeline.animator || !zoom || !width || !height) {
            return;
        }

        this.ctx.clearRect(0, 0, width, height);

        this.timeline.drawDurationLine();

        if (timeline.scroll.x > 0) {
            this.drawOverflowShadow(height);
        }

        this.drawSelectionNet(timeline.selectionNet);
        this.drawDivider(TIMERULER_HEIGHT);
        this.drawOverflowShadow(TIMERULER_HEIGHT);
        this.drawRightOverflowColor();

        if (this.shouldDrawScrollBars) {
            this.drawScrollbars();
        }

        const zoomBox = timeline.zoomService.zoomBox;

        if (zoomBox) {
            this.drawZoomBox(zoomBox);
        }

        if (this.transitionHighlighted) {
            this.drawLabel(`Add ${this.transitionHighlighted} animation`, this.mousePosition);
        }
    }

    private drawDivider(height: number): void {
        this.ctx.fillStyle = '#efefef';

        this.ctx.fillRect(0, 0, 1, height);
    }

    private drawOverflowShadow(height: number): void {
        const distance = Math.min(this.timeline.scroll.x / 20, 1);
        const x = -2;
        const shadowWidth = 57;
        const gradient = this.ctx.createLinearGradient(x, 0, x + shadowWidth, 0);
        gradient.addColorStop(0, `rgba(0,0,0,${0.04 * distance})`);
        gradient.addColorStop(0.4, 'rgba(0,0,0,0)');

        this.ctx.fillStyle = gradient;
        this.ctx.fillRect(x, 0, shadowWidth, height);
    }

    private drawRightOverflowColor(): void {
        const durationInPixels = this.timeline.secondsToScrolledPixels(this.timeline.duration);
        this.ctx.fillStyle = 'rgba(235, 235, 235, 0.2)';
        this.ctx.fillRect(
            Math.max(durationInPixels - 1, 0),
            this.timeline.getTimelineHeaderHeight(),
            this.width - durationInPixels + 1,
            this.height
        );
    }

    private drawSelectionNet(selection?: IBoundingBox): void {
        if (selection) {
            const { x, y, width, height } = selection;
            this.ctx.strokeStyle = GizmoColor.border();
            this.ctx.lineWidth = 0.5;
            this.ctx.fillStyle = 'rgba(0, 0, 0, 0.01)';
            this.ctx.beginPath();
            this.ctx.moveTo(x, y);
            this.ctx.lineTo(x + width, y);
            this.ctx.lineTo(x + width, y + height);
            this.ctx.lineTo(x, y + height);
            this.ctx.closePath();
            this.ctx.stroke();
            this.ctx.fill();
        }
    }

    private drawZoomBox(bounds: IBounds): void {
        const { x, y } = bounds;
        const { width, height } = bounds;
        const widthAcc = width * 1;
        const heightAcc = height * 1;
        this.ctx.strokeStyle = '#919191';
        this.ctx.lineWidth = 0.5;
        this.ctx.fillStyle = 'rgba(0, 0, 0, 0.01)';
        this.ctx.beginPath();
        this.ctx.moveTo(x, y);
        this.ctx.lineTo(x + widthAcc, y);
        this.ctx.lineTo(x + widthAcc, y + heightAcc);
        this.ctx.lineTo(x, y + heightAcc);
        this.ctx.closePath();
        this.ctx.stroke();
        this.ctx.fill();
    }

    private drawScrollbars(): void {
        const { timeline, ctx } = this;
        const showScrollbars = timeline.shouldScrollbarShow();
        const scrollService = timeline.scrollService;
        const scrollBarColor = 'rgba(0,0,0,0.25)';
        const scrollBarColorHighlighted = 'rgba(0,0,0,0.35)';

        if (showScrollbars.horizontal) {
            const bounds = timeline.getHorizontalScrollbarBounds();

            if (
                scrollService.scrollBarHighlighted === 'horizontal' ||
                scrollService.scrollBarTrackHighlighted === 'horizontal'
            ) {
                ctx.fillStyle = '#fff';
                ctx.strokeStyle = '#eee';
                ctx.lineWidth = 1;
                ctx.beginPath();
                ctx.rect(0, this.height - SCROLLBAR_TRACK_WIDTH, this.width, SCROLLBAR_TRACK_WIDTH);
                ctx.closePath();
                ctx.fill();
                ctx.stroke();
            }

            ctx.fillStyle =
                scrollService.scrollBarHighlighted === 'horizontal'
                    ? scrollBarColorHighlighted
                    : scrollBarColor;
            drawRoundRect(
                ctx,
                bounds.x,
                bounds.y,
                bounds.width,
                bounds.height,
                bounds.height / 2,
                true
            );
        }

        if (showScrollbars.vertical) {
            const bounds = timeline.getVerticalScrollbarBounds();

            // This is the "background bar" of the scroll
            if (
                scrollService.scrollBarHighlighted === 'vertical' ||
                scrollService.scrollBarTrackHighlighted === 'vertical'
            ) {
                const track = scrollService.getVerticalScrollTrackBounds();
                ctx.fillStyle = '#fff';
                ctx.strokeStyle = '#eee';
                ctx.lineWidth = 1;
                ctx.beginPath();
                ctx.rect(track.x, track.y, track.width, track.height);
                ctx.closePath();
                ctx.fill();
                ctx.stroke();
            }

            ctx.fillStyle =
                scrollService.scrollBarHighlighted === 'vertical'
                    ? scrollBarColorHighlighted
                    : scrollBarColor;
            drawRoundRect(ctx, bounds.x, bounds.y, bounds.width, bounds.height, bounds.width / 2, true);
        }
    }

    private drawLabel(text: string, position: IPosition): void {
        this.ctx.save();

        const x = Math.max(position.x, 0);
        const y = position.y;
        const radius = 3;
        this.ctx.font = `12px ${this.fontFamily}`;
        const textWidth = this.ctx.measureText(text).width;
        this.ctx.fillStyle = '#000';
        const width = textWidth + 14;
        const height = LABEL_HEIGHT;

        this.ctx.beginPath();
        this.ctx.moveTo(x + radius, y);
        this.ctx.lineTo(x + width - radius, y);
        this.ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
        this.ctx.lineTo(x + width, y + height - radius);
        this.ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
        this.ctx.lineTo(x + radius, y + height);
        this.ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
        this.ctx.lineTo(x, y + radius);
        this.ctx.quadraticCurveTo(x, y, x + radius, y);
        this.ctx.closePath();
        this.ctx.fill();

        this.ctx.textAlign = 'left';
        this.ctx.fillStyle = '#fff';
        this.ctx.textBaseline = 'top';
        this.ctx.fillText(text, x + 7, y + 5);
        this.ctx.textBaseline = 'alphabetic';
        this.ctx.restore();
    }

    private setCanvasSize = (width?: number, height?: number): void => {
        const ratio = window.devicePixelRatio;

        if (typeof width !== 'number' || typeof height !== 'number') {
            width = this.canvas.offsetWidth;
            height = this.canvas.offsetHeight;
        }

        if (width !== this.width || height !== this.height) {
            this.width = width;
            this.height = height;
            this.canvas.width = width * ratio;
            this.canvas.height = height * ratio;
            this.canvas.getContext('2d')!.scale(ratio, ratio);
            this.draw();
            this.canvasSizeSet$.next();
        }
    };
}
