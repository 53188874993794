import { EnvironmentProviders, importProvidersFrom } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { DuplicateCreativeEffects } from './duplicate-creative.effects';
import { DUPLICATE_CREATIVES_FEATURE_KEY, reducer } from './duplicate-creative.reducer';

export function provideDuplicateCreativesStore(): EnvironmentProviders {
    return importProvidersFrom(
        StoreModule.forFeature(DUPLICATE_CREATIVES_FEATURE_KEY, reducer),
        EffectsModule.forFeature(DuplicateCreativeEffects)
    );
}
