<div class="text-container">
    <ui-svg-icon
        class="warning-icon"
        icon="warning"></ui-svg-icon>
    <div
        class="text-box"
        *ngIf="infoText">
        <p class="text">{{ infoText }}</p>
        <p class="text row-two">This action affects all versions and can not be undone.</p>
    </div>
</div>
<ui-checkbox
    label="I understand the risk and want to proceed"
    [(selected)]="verified"></ui-checkbox>
<ui-dialog-buttons ui-theme="default">
    <ui-button
        class="buttons"
        text="Cancel"
        (click)="cancel()">
    </ui-button>
    <ui-button
        *ngIf="buttonText"
        type="primary"
        text="{{ buttonText }}"
        [disabled]="!verified"
        (click)="deleteDeactivateSize()">
    </ui-button>
</ui-dialog-buttons>
