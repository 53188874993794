<div class="body">
    <div class="setting padding-top position-settings">
        <!-- Position -->
        <form>
            <div class="setting-value col-2 mb-1">
                <div
                    class="property-input mr-1"
                    [uiTooltip]="x.value ? x.value.toString() : ''"
                    [uiTooltipDisabled]="!x.isFormula">
                    <ui-number-input
                        [(value)]="x.resolvedValue"
                        id="x-input"
                        [class.empty-state]="
                            propertiesService.inStateView &&
                            propertiesService.stateValueIsUndefined('x')
                        "
                        (valueChange)="updatePosition($event, 'x')"
                        [allowEmpty]="propertiesService.inStateView || elements.length > 1"
                        [min]="-9999999"
                        [max]="9999999"
                        (undo)="undo()"
                        (redo)="redo()"
                        [disabled]="readonly || x.isFormula"
                        [disableUndo]="true"
                        [arrowButtons]="x.value !== 'mixed'"
                        [keyboardEmit]="true"
                        [format]="'1.0-0'"
                        placeholder="-"
                        unitLabel="{{
                            propertiesService.inStateView ? 'offsetX' : 'X'
                        }}"></ui-number-input>
                </div>
                <div
                    class="property-input"
                    [uiTooltip]="y.value ? y.value.toString() : ''"
                    [uiTooltipDisabled]="!y.isFormula">
                    <ui-number-input
                        [(value)]="y.resolvedValue"
                        id="y-input"
                        [class.empty-state]="
                            propertiesService.inStateView &&
                            propertiesService.stateValueIsUndefined('y')
                        "
                        (valueChange)="updatePosition($event, 'y')"
                        [allowEmpty]="propertiesService.inStateView || elements.length > 1"
                        [min]="-9999999"
                        [max]="9999999"
                        (undo)="undo()"
                        (redo)="redo()"
                        [disabled]="readonly || y.isFormula"
                        [disableUndo]="true"
                        [arrowButtons]="y.value !== 'mixed'"
                        [keyboardEmit]="true"
                        [placeholder]="'-'"
                        [format]="'1.0-0'"
                        unitLabel="{{
                            propertiesService.inStateView ? 'offsetY' : 'Y'
                        }}"></ui-number-input>
                </div>
            </div>
        </form>
        <!-- Size -->
        <form *ngIf="!isPropertiesHidden">
            <div
                class="setting-value col-3 size mb-1"
                *ngIf="!propertiesService.inStateView">
                <div class="property-input">
                    <ui-number-input
                        id="width-input"
                        [value]="width"
                        (valueChange)="updateSize($event, 'width')"
                        (mouseUp)="resizeEnd()"
                        [allowEmpty]="elements.length > 1"
                        [min]="inputValidation.size.min"
                        [max]="inputValidation.size.max"
                        (undo)="undo()"
                        (redo)="redo()"
                        [arrowButtons]="width !== undefined"
                        [disabled]="readonly"
                        [disableUndo]="true"
                        [keyboardEmit]="true"
                        [format]="'1.0-0'"
                        placeholder="-"
                        unitLabel="W"></ui-number-input>
                </div>
                <ng-container *ngIf="stateOrElement | isElementNode as element">
                    <div
                        *ngIf="height !== undefined && width !== undefined; else ratioLockPlaceholder"
                        class="ratio-lock"
                        [ngClass]="{ active: ratio !== undefined }"
                        (click)="toggleRatioLock()">
                        <ui-svg-icon
                            [icon]="
                                element.ratio !== undefined ? 'lock-closed' : 'lock-open'
                            "></ui-svg-icon>
                    </div>
                    <ng-template #ratioLockPlaceholder>
                        <div class="ratio-lock-placeholder"></div>
                    </ng-template>
                </ng-container>
                <div class="property-input">
                    <ui-number-input
                        id="height-input"
                        [value]="height"
                        (valueChange)="updateSize($event, 'height')"
                        (mouseUp)="resizeEnd()"
                        [allowEmpty]="elements.length > 1"
                        [min]="inputValidation.size.min"
                        [max]="inputValidation.size.max"
                        (undo)="undo()"
                        (redo)="redo()"
                        [arrowButtons]="height !== undefined"
                        [disabled]="readonly"
                        [format]="'1.0-0'"
                        [disableUndo]="true"
                        [keyboardEmit]="true"
                        placeholder="-"
                        unitLabel="H"></ui-number-input>
                </div>
            </div>
        </form>
        <!-- Scale -->
        <form>
            <ng-container *ngIf="propertiesService.inStateView">
                <!-- Use pixel values toggle -->
                <div class="setting-row auto">
                    <div class="setting-label">
                        Use pixel values
                        <ui-svg-icon
                            class="info"
                            icon="question-mark"
                            [uiTooltipMaxWidth]="'200px'"
                            [uiTooltip]="
                                'Toggle between relative (%) or absolute (pixel) values to animate with width and height properties.'
                            "></ui-svg-icon>
                    </div>
                    <div class="setting-value end">
                        <ui-toggle-switch
                            id="interaction-use-pixels-toggle"
                            [selected]="usePixelValues"
                            (selectedChange)="setUsePixelValues($event)"></ui-toggle-switch>
                    </div>
                </div>
                <div
                    *ngIf="!usePixelValues; else scaleFromPixelValues"
                    class="setting-value col-3 size mb-1">
                    <div class="property-input">
                        <ui-number-input
                            id="scale-x-input"
                            [class.empty-state]="propertiesService.stateValueIsUndefined('scaleX')"
                            [(value)]="scaleX"
                            (valueChange)="updateScale('scaleX')"
                            (mouseUp)="resizeEnd()"
                            [keyboardEmit]="true"
                            (undo)="undo()"
                            (redo)="redo()"
                            [disabled]="readonly"
                            (submit)="updateScale('scaleX')"
                            [min]="inputValidation.scale.min"
                            [max]="inputValidation.scale.max"
                            [placeholder]="'100'"
                            [disableUndo]="true"
                            unitLabel="%W"></ui-number-input>
                    </div>
                    <div
                        class="ratio-lock"
                        [ngClass]="{ active: stateOrElement?.ratio !== undefined }"
                        (click)="toggleRatioLock()">
                        <ui-svg-icon
                            [icon]="
                                stateOrElement?.ratio !== undefined ? 'lock-closed' : 'lock-open'
                            "></ui-svg-icon>
                    </div>
                    <div class="property-input">
                        <ui-number-input
                            id="scale-y-input"
                            [class.empty-state]="propertiesService.stateValueIsUndefined('scaleY')"
                            [(value)]="scaleY"
                            (valueChange)="updateScale('scaleY')"
                            (mouseUp)="resizeEnd()"
                            [keyboardEmit]="true"
                            (undo)="undo()"
                            (redo)="redo()"
                            [disabled]="readonly"
                            (submit)="updateScale('scaleY')"
                            [min]="inputValidation.scale.min"
                            [max]="inputValidation.scale.max"
                            [placeholder]="'100'"
                            [disableUndo]="true"
                            unitLabel="%H"></ui-number-input>
                    </div>
                </div>
                <ng-template #scaleFromPixelValues>
                    <div class="setting-value col-3 size mb-1">
                        <div class="property-input">
                            <ui-number-input
                                id="target-width-input"
                                [class.empty-state]="propertiesService.stateValueIsUndefined('scaleX')"
                                [(value)]="targetWidth"
                                (valueChange)="updateScaleWithPixelValues($event, 'scaleX')"
                                (mouseUp)="resizeEnd()"
                                [min]="inputValidation.scale.min"
                                [max]="inputValidation.scale.max"
                                (undo)="undo()"
                                (redo)="redo()"
                                [disabled]="readonly"
                                [disableUndo]="true"
                                [keyboardEmit]="true"
                                placeholder="-"
                                unitLabel="W"></ui-number-input>
                        </div>
                        <div
                            *ngIf="
                                targetHeight !== undefined && targetWidth !== undefined;
                                else ratioLockPlaceholder
                            "
                            class="ratio-lock"
                            [ngClass]="{ active: ratio !== undefined }"
                            (click)="toggleRatioLock()">
                            <ui-svg-icon
                                [icon]="
                                    stateOrElement?.ratio !== undefined ? 'lock-closed' : 'lock-open'
                                "></ui-svg-icon>
                        </div>
                        <ng-template #ratioLockPlaceholder>
                            <div class="ratio-lock-placeholder"></div>
                        </ng-template>
                        <div class="property-input">
                            <ui-number-input
                                id="target-height-input"
                                [class.empty-state]="propertiesService.stateValueIsUndefined('scaleY')"
                                [(value)]="targetHeight"
                                (valueChange)="updateScaleWithPixelValues($event, 'scaleY')"
                                (mouseUp)="resizeEnd()"
                                [min]="inputValidation.scale.min"
                                [max]="inputValidation.scale.max"
                                (undo)="undo()"
                                (redo)="redo()"
                                [disabled]="readonly"
                                [disableUndo]="true"
                                [keyboardEmit]="true"
                                placeholder="-"
                                unitLabel="H"></ui-number-input>
                        </div>
                    </div>
                </ng-template>
            </ng-container>
        </form>
        <!-- Rotation -->
        <form>
            <div class="rotation-container">
                <div
                    class="property-input"
                    [uiTooltip]="rotationZ.value ? rotationZ.value.toString() : ''"
                    [uiTooltipDisabled]="!rotationZ.isFormula">
                    <ui-number-input
                        id="rotationZ-input"
                        #rotationZControl
                        [(value)]="rotationZ.resolvedValue"
                        [class.empty-state]="
                            propertiesService.inStateView &&
                            propertiesService.stateValueIsUndefined('rotationZ')
                        "
                        (valueChange)="updateRotationZ($event)"
                        (mouseUp)="rotateEnd()"
                        [keyboardEmit]="true"
                        [placeholder]="'-'"
                        [arrowButtons]="rotationZ.value !== 'mixed'"
                        (undo)="undo()"
                        (redo)="redo()"
                        [min]="inputValidation.rotation.min"
                        [max]="inputValidation.rotation.max"
                        [disabled]="readonly || rotationZ.isFormula"
                        [disableUndo]="true"></ui-number-input>
                    <ui-svg-icon
                        class="property-icon"
                        icon="rotate-right"></ui-svg-icon>
                </div>

                <div
                    class="property-input"
                    [uiTooltip]="rotationY.value ? rotationY.value.toString() : ''"
                    [uiTooltipDisabled]="!rotationY.isFormula">
                    <ui-number-input
                        id="rotationY-input"
                        #rotationYControl
                        [(value)]="rotationY.resolvedValue"
                        [class.empty-state]="
                            propertiesService.inStateView &&
                            propertiesService.stateValueIsUndefined('rotationY')
                        "
                        (valueChange)="updateRotationY($event)"
                        (mouseUp)="rotateEnd()"
                        [keyboardEmit]="true"
                        [arrowButtons]="rotationY.value !== 'mixed'"
                        [placeholder]="'-'"
                        (focus)="rotationControlFocusHandler('update-rotation-y')"
                        (blur)="rotationControlFocusHandler('update-rotation-y', true)"
                        (undo)="undo()"
                        (redo)="redo()"
                        [min]="inputValidation.rotation.min"
                        [max]="inputValidation.rotation.max"
                        [disabled]="readonly || rotationY.isFormula"
                        [disableUndo]="true">
                    </ui-number-input>
                    <ui-svg-icon
                        class="property-icon"
                        icon="rotate-y"></ui-svg-icon>
                </div>

                <div
                    class="property-input"
                    [uiTooltip]="rotationX.value ? rotationX.value.toString() : ''"
                    [uiTooltipDisabled]="!rotationX.isFormula">
                    <ui-number-input
                        id="rotationX-input"
                        #rotationXControl
                        [(value)]="rotationX.resolvedValue"
                        [class.empty-state]="
                            propertiesService.inStateView &&
                            propertiesService.stateValueIsUndefined('rotationX')
                        "
                        (valueChange)="updateRotationX($event)"
                        (mouseUp)="rotateEnd()"
                        [keyboardEmit]="true"
                        [arrowButtons]="rotationX.value !== 'mixed'"
                        [placeholder]="'-'"
                        (undo)="undo()"
                        (redo)="redo()"
                        [min]="inputValidation.rotation.min"
                        [max]="inputValidation.rotation.max"
                        [disabled]="readonly || rotationX.isFormula"
                        [disableUndo]="true">
                    </ui-number-input>
                    <ui-svg-icon
                        class="property-icon"
                        icon="rotate-x"></ui-svg-icon>
                </div>
            </div>
        </form>
    </div>
</div>
<ng-container *ngIf="!isWidgetEditor">
    <div class="body divider">
        <div class="setting">
            <!-- Radius -->
            <div
                data-test-id="layout-property-radius"
                *ngIf="showRadiusProperty"
                class="setting-row wide">
                <div class="setting-label">Radius</div>
                <div class="setting-value col-2">
                    <div class="property-input">
                        <ui-button-group
                            class="button-group"
                            id="radius-type-input"
                            [options]="radiusOptions"
                            [primarySelectionStyle]="true"
                            [value]="radiusType"
                            (valueChange)="setRadiusType($event)">
                        </ui-button-group>
                    </div>
                    <form>
                        <div class="property-input">
                            <ui-number-input
                                id="radius-input"
                                [(value)]="fullRadius"
                                [disabled]="radiusType === RadiusTypeEnum.Separate"
                                (valueChange)="setFullRadius($event, ElementChangeType.Burst)"
                                [keyboardEmit]="true"
                                [min]="inputValidation.radius.min"
                                [max]="inputValidation.radius.max"
                                [step]="1"
                                [arrowButtons]="radiusType !== RadiusTypeEnum.Separate"
                                [placeholder]="fullRadius === undefined ? 'Mixed' : ''"
                                [unitLabel]="fullRadius ? 'px' : ''"
                                [allowEmpty]="
                                    radiusType === RadiusTypeEnum.Separate || fullRadius === undefined
                                "
                                (undo)="undo()"
                                (redo)="redo()"
                                [disableUndo]="true"
                                [class.empty-state]="
                                    propertiesService.inStateView &&
                                    propertiesService.stateValueIsUndefined('radius')
                                ">
                            </ui-number-input>
                        </div>
                    </form>
                </div>
            </div>
            <div
                *ngIf="radiusType === RadiusTypeEnum.Separate && showRadiusProperty"
                class="align-end">
                <div class="separate-radius-container">
                    <div class="property-input">
                        <ui-number-input
                            id="radius-input-top-left"
                            [value]="radius.value?.topLeft"
                            (valueChange)="setRadius($event, ElementChangeType.Burst, 'topLeft')"
                            [min]="inputValidation.radius.min"
                            [max]="inputValidation.radius.max"
                            [step]="1"
                            [arrowButtons]="false"
                            [keyboardEmit]="true"
                            [allowEmpty]="radius.value?.topLeft === undefined"
                            [placeholder]="radius.value?.topLeft === undefined ? 'Mixed' : ''">
                        </ui-number-input>
                    </div>
                    <div class="property-input">
                        <ui-number-input
                            id="radius-input-top-right"
                            [value]="radius.value?.topRight"
                            (valueChange)="setRadius($event, ElementChangeType.Burst, 'topRight')"
                            [min]="inputValidation.radius.min"
                            [max]="inputValidation.radius.max"
                            [step]="1"
                            [arrowButtons]="false"
                            [keyboardEmit]="true"
                            [allowEmpty]="radius.value?.topRight === undefined"
                            [placeholder]="radius.value?.topRight === undefined ? 'Mixed' : ''">
                        </ui-number-input>
                    </div>
                    <div class="property-input">
                        <ui-number-input
                            id="radius-input-bottom-right"
                            [value]="radius.value?.bottomRight"
                            (valueChange)="setRadius($event, ElementChangeType.Burst, 'bottomRight')"
                            [min]="inputValidation.radius.min"
                            [max]="inputValidation.radius.max"
                            [step]="1"
                            [arrowButtons]="false"
                            [keyboardEmit]="true"
                            [allowEmpty]="radius.value?.bottomRight === undefined"
                            [placeholder]="radius.value?.bottomRight === undefined ? 'Mixed' : ''">
                        </ui-number-input>
                    </div>
                    <div class="property-input">
                        <ui-number-input
                            id="radius-input-bottom-left"
                            [value]="radius.value?.bottomLeft"
                            (valueChange)="setRadius($event, ElementChangeType.Burst, 'bottomLeft')"
                            [min]="inputValidation.radius.min"
                            [max]="inputValidation.radius.max"
                            [step]="1"
                            [arrowButtons]="false"
                            [keyboardEmit]="true"
                            [allowEmpty]="radius.value?.bottomLeft === undefined"
                            [placeholder]="radius.value?.bottomLeft === undefined ? 'Mixed' : ''">
                        </ui-number-input>
                    </div>
                </div>
                <div class="separate-radius-container col-4">
                    <div class="setting-value"><ui-svg-icon [icon]="'corner-t-l'"></ui-svg-icon></div>
                    <div class="setting-value"><ui-svg-icon [icon]="'corner-t-r'"></ui-svg-icon></div>
                    <div class="setting-value"><ui-svg-icon [icon]="'corner-b-r'"></ui-svg-icon></div>
                    <div class="setting-value"><ui-svg-icon [icon]="'corner-b-l'"></ui-svg-icon></div>
                </div>
            </div>
            <!-- Opacity -->
            <div
                data-test-id="opacity"
                class="setting-row wide">
                <div class="setting-label">Opacity</div>
                <div class="setting-value col-2 wide">
                    <div class="property-input slider">
                        <studio-ui-slider
                            id="opacity-slider"
                            [value]="opacity.value || 0"
                            (valueChange)="updateOpacity($event, ElementChangeType.Skip)"
                            (mouseUp)="updateOpacity(opacity.value, ElementChangeType.Force)"
                            [config]="{
                                min: inputValidation.opacity.min,
                                max: inputValidation.opacity.max
                            }"></studio-ui-slider>
                    </div>
                    <form>
                        <div class="property-input">
                            <ui-number-input
                                id="opacity-input"
                                [value]="opacity.value"
                                (valueChange)="updateOpacity($event, ElementChangeType.Burst)"
                                [keyboardEmit]="true"
                                [arrowButtons]="!opacity.isMixed"
                                [placeholder]="'-'"
                                [min]="inputValidation.opacity.min"
                                [max]="inputValidation.opacity.max"
                                (undo)="undo()"
                                (redo)="redo()"
                                [disableUndo]="true"
                                [class.empty-state]="
                                    propertiesService.inStateView &&
                                    propertiesService.stateValueIsUndefined('opacity')
                                "
                                unitLabel="%"></ui-number-input>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</ng-container>
