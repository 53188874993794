import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { ApolloError } from '@apollo/client/errors';
import { CreativesetErrorMessage, ShowcaseErrorMessage } from '@domain/api/problem-details';
import {
    isCreativesetProblemDetail,
    isForbiddenError,
    isNotFoundError,
    isValidShowcaseError
} from '@studio/domain/api/error';
import { take } from 'rxjs';
import { AppComponent } from '../../app.component';
import { CreativeSetShowcaseService } from '../../shared/creativeset-showcase/state/creativeset-showcase.service';
import { EnvironmentService } from '../../shared/services/environment.service';
import { ErrorsRedirectionService } from '../../shared/services/errors-redirection.service';

const DEFAULT_ERROR_MESSAGE = 'Something went wrong. Try again or contact our support for help.';
const FORBIDDEN_ERROR_MESSAGE = 'You do not have permissions to access to this set.';

@Component({
    selector: 'error-404',
    templateUrl: 'error-404-page.component.html',
    styleUrls: ['error-404-page.component.scss']
})
export class Error404Component {
    errorMessage = DEFAULT_ERROR_MESSAGE;
    linkToCreativeset?: string;

    constructor(
        private app: AppComponent,
        private creativesetShowcaseService: CreativeSetShowcaseService,
        private environmentService: EnvironmentService,
        private errorsRedirectionService: ErrorsRedirectionService
    ) {
        this.app.loaded = true;

        if (this.environmentService.inShowcaseMode) {
            this.creativesetShowcaseService.error$.pipe(take(1)).subscribe(error => {
                if (error instanceof HttpErrorResponse) {
                    this.handleShowcaseRestError(error);
                } else if (error instanceof ApolloError) {
                    this.handleApolloError(error);
                }
            });
        } else {
            const error = this.errorsRedirectionService.error;
            if (error instanceof HttpErrorResponse) {
                this.handleRESTError(error);
            } else if (error instanceof ApolloError) {
                this.handleApolloError(error);
            }
        }
    }

    private handleRESTError(error: HttpErrorResponse): void {
        if (isNotFoundError(error)) {
            this.errorMessage = this.getNotFoundError(error);
        } else if (isForbiddenError(error)) {
            this.errorMessage = FORBIDDEN_ERROR_MESSAGE;
        } else {
            this.errorMessage = DEFAULT_ERROR_MESSAGE;
        }
    }

    private handleApolloError(error: ApolloError): void {
        const networkError = error.networkError;

        if (!this.isHandledApolloNetworkError(networkError)) {
            return;
        }

        this.errorMessage = networkError.error.errors[0].message;

        if (networkError.status !== 400) {
            return;
        }

        const { brandId, creativeSetId } = networkError.error.errors[0]?.extensions?.data || {};

        if (brandId && creativeSetId) {
            this.linkToCreativeset = `/brand/${brandId}/creativeset/${creativeSetId}`;
        }
    }

    private handleShowcaseRestError(errorResponse: HttpErrorResponse): void {
        if (!isValidShowcaseError(errorResponse)) {
            return;
        }

        const errorType = errorResponse.error.errorType;

        this.errorMessage = ShowcaseErrorMessage[errorType] ?? ShowcaseErrorMessage.showcaseNotFound;

        switch (errorType) {
            case 'showcaseDisabled':
            case 'showcaseExpired': {
                const { brandId, creativeSetId } = errorResponse.error;
                this.linkToCreativeset = `/brand/${brandId}/creativeset/${creativeSetId}`;
                break;
            }
        }
    }

    private getNotFoundError(errorResponse: HttpErrorResponse): string {
        if (!isCreativesetProblemDetail(errorResponse.error)) {
            // handle brand manager not found error
            if (errorResponse.url?.includes('brand-manager')) {
                return 'Brand not found';
            }
            return CreativesetErrorMessage.creativeSetNotFound;
        }

        const errorType = errorResponse.error.errorType;

        return CreativesetErrorMessage[errorType];
    }

    private isHandledApolloNetworkError(
        networkError: ApolloError['networkError']
    ): networkError is HttpErrorResponse {
        if (!(networkError instanceof HttpErrorResponse)) {
            return false;
        }

        return networkError.status === 404 || networkError.status === 400;
    }
}
