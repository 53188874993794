<mat-tab-group
    dynamicHeight
    #matTabGroup
    [selectedIndex]="selectedTabIndex$ | async"
    (selectedTabChange)="onSelectedTabChange($event)">
    <mat-tab>
        <ng-template mat-tab-label>
            <div
                id="interaction-content-panel"
                class="tab-label">
                <ui-svg-icon icon="edit"></ui-svg-icon>
                Content
            </div>
        </ng-template>
        <ng-content select="[contentTab]"></ng-content>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            <div
                id="interaction-styling-panel"
                class="tab-label">
                <ui-svg-icon icon="text-style-apply-formatting"></ui-svg-icon>
                Styling
            </div>
        </ng-template>
        <ng-content select="[stylingTab]"></ng-content>
    </mat-tab>
</mat-tab-group>
