// From https://stackoverflow.com/a/7193622/449132
const ARABIC_DIACRTIC_CHARS = `0651
0652
0670
0640
0627
064C-065F`;

export const ARABIC_DIACRTIC_RANGE_MAP = decodeCharacterRangeMap(ARABIC_DIACRTIC_CHARS);

// https://www.ietf.org/rfc/rfc3454.txt
const L_BIDI_CHARS = `0041-005A
0061-007A
00AA
00B5
00BA
00C0-00D6
00D8-00F6
00F8-0220
0222-0233
0250-02AD
02B0-02B8
02BB-02C1
02D0-02D1
02E0-02E4
02EE
037A
0386
0388-038A
038C
038E-03A1
03A3-03CE
03D0-03F5
0400-0482
048A-04CE
04D0-04F5
04F8-04F9
0500-050F
0531-0556
0559-055F
0561-0587
0589
0903
0905-0939
093D-0940
0949-094C
0950
0958-0961
0964-0970
0982-0983
0985-098C
098F-0990
0993-09A8
09AA-09B0
09B2
09B6-09B9
09BE-09C0
09C7-09C8
09CB-09CC
09D7
09DC-09DD
09DF-09E1
09E6-09F1
09F4-09FA
0A05-0A0A
0A0F-0A10
0A13-0A28
0A2A-0A30
0A32-0A33
0A35-0A36
0A38-0A39
0A3E-0A40
0A59-0A5C
0A5E
0A66-0A6F
0A72-0A74
0A83
0A85-0A8B
0A8D
0A8F-0A91
0A93-0AA8
0AAA-0AB0
0AB2-0AB3
0AB5-0AB9
0ABD-0AC0
0AC9
0ACB-0ACC
0AD0
0AE0
0AE6-0AEF
0B02-0B03
0B05-0B0C
0B0F-0B10
0B13-0B28
0B2A-0B30
0B32-0B33
0B36-0B39
0B3D-0B3E
0B40
0B47-0B48
0B4B-0B4C
0B57
0B5C-0B5D
0B5F-0B61
0B66-0B70
0B83
0B85-0B8A
0B8E-0B90
0B92-0B95
0B99-0B9A
0B9C
0B9E-0B9F
0BA3-0BA4
0BA8-0BAA
0BAE-0BB5
0BB7-0BB9
0BBE-0BBF
0BC1-0BC2
0BC6-0BC8
0BCA-0BCC
0BD7
0BE7-0BF2
0C01-0C03
0C05-0C0C
0C0E-0C10
0C12-0C28
0C2A-0C33
0C35-0C39
0C41-0C44
0C60-0C61
0C66-0C6F
0C82-0C83
0C85-0C8C
0C8E-0C90
0C92-0CA8
0CAA-0CB3
0CB5-0CB9
0CBE
0CC0-0CC4
0CC7-0CC8
0CCA-0CCB
0CD5-0CD6
0CDE
0CE0-0CE1
0CE6-0CEF
0D02-0D03
0D05-0D0C
0D0E-0D10
0D12-0D28
0D2A-0D39
0D3E-0D40
0D46-0D48
0D4A-0D4C
0D57
0D60-0D61
0D66-0D6F
0D82-0D83
0D85-0D96
0D9A-0DB1
0DB3-0DBB
0DBD
0DC0-0DC6
0DCF-0DD1
0DD8-0DDF
0DF2-0DF4
0E01-0E30
0E32-0E33
0E40-0E46
0E4F-0E5B
0E81-0E82
0E84
0E87-0E88
0E8A
0E8D
0E94-0E97
0E99-0E9F
0EA1-0EA3
0EA5
0EA7
0EAA-0EAB
0EAD-0EB0
0EB2-0EB3
0EBD
0EC0-0EC4
0EC6
0ED0-0ED9
0EDC-0EDD
0F00-0F17
0F1A-0F34
0F36
0F38
0F3E-0F47
0F49-0F6A
0F7F
0F85
0F88-0F8B
0FBE-0FC5
0FC7-0FCC
0FCF
1000-1021
1023-1027
1029-102A
102C
1031
1038
1040-1057
10A0-10C5
10D0-10F8
10FB
1100-1159
115F-11A2
11A8-11F9
1200-1206
1208-1246
1248
124A-124D
1250-1256
1258
125A-125D
1260-1286
1288
128A-128D
1290-12AE
12B0
12B2-12B5
12B8-12BE
12C0
12C2-12C5
12C8-12CE
12D0-12D6
12D8-12EE
12F0-130E
1310
1312-1315
1318-131E
1320-1346
1348-135A
1361-137C
13A0-13F4
1401-1676
1681-169A
16A0-16F0
1700-170C
170E-1711
1720-1731
1735-1736
1740-1751
1760-176C
176E-1770
1780-17B6
17BE-17C5
17C7-17C8
17D4-17DA
17DC
17E0-17E9
1810-1819
1820-1877
1880-18A8
1E00-1E9B
1EA0-1EF9
1F00-1F15
1F18-1F1D
1F20-1F45
1F48-1F4D
1F50-1F57
1F59
1F5B
1F5D
1F5F-1F7D
1F80-1FB4
1FB6-1FBC
1FBE
1FC2-1FC4
1FC6-1FCC
1FD0-1FD3
1FD6-1FDB
1FE0-1FEC
1FF2-1FF4
1FF6-1FFC
200E
2071
207F
2102
2107
210A-2113
2115
2119-211D
2124
2126
2128
212A-212D
212F-2131
2133-2139
213D-213F
2145-2149
2160-2183
2336-237A
2395
249C-24E9
3005-3007
3021-3029
3031-3035
3038-303C
3041-3096
309D-309F
30A1-30FA
30FC-30FF
3105-312C
3131-318E
3190-31B7
31F0-321C
3220-3243
3260-327B
327F-32B0
32C0-32CB
32D0-32FE
3300-3376
337B-33DD
33E0-33FE
3400-4DB5
4E00-9FA5
A000-A48C
AC00-D7A3
D800-FA2D
FA30-FA6A
FB00-FB06
FB13-FB17
FF21-FF3A
FF41-FF5A
FF66-FFBE
FFC2-FFC7
FFCA-FFCF
FFD2-FFD7
FFDA-FFDC
10300-1031E
10320-10323
10330-1034A
10400-10425
10428-1044D
1D000-1D0F5
1D100-1D126
1D12A-1D166
1D16A-1D172
1D183-1D184
1D18C-1D1A9
1D1AE-1D1DD
1D400-1D454
1D456-1D49C
1D49E-1D49F
1D4A2
1D4A5-1D4A6
1D4A9-1D4AC
1D4AE-1D4B9
1D4BB
1D4BD-1D4C0
1D4C2-1D4C3
1D4C5-1D505
1D507-1D50A
1D50D-1D514
1D516-1D51C
1D51E-1D539
1D53B-1D53E
1D540-1D544
1D546`;

export const L_BIDI_CHAR_RANGE_MAP = decodeCharacterRangeMap(L_BIDI_CHARS);

const R_AND_AL_BIDI_CHARS = `05BE
05C0
05C3
05D0-05EA
05F0-05F4
061B
061F
0621-063A
0640-064A
066D-066F
0671-06D5
06DD
06E5-06E6
06FA-06FE
0700-070D
0710
0712-072C
0780-07A5
07B1
200F
FB1D
FB1F-FB28
FB2A-FB36
FB38-FB3C
FB3E
FB40-FB41
FB43-FB44
FB46-FBB1
FBD3-FD3D
FD50-FD8F
FD92-FDC7
FDF0-FDFC
FE70-FE74
FE76-FEFC`;

export const RTL_MARK = decodeCharacterRangeMap('200F');

export const R_AND_AL_BIDI_CHAR_RANGE_MAP = decodeCharacterRangeMap(R_AND_AL_BIDI_CHARS);

function decodeCharacterRangeMap(encodedMap: string): number[][] {
    const map: number[][] = [];
    const conditions = encodedMap.split('\n');
    for (const condition of conditions) {
        const trimmedCondition = condition.trim();
        if (trimmedCondition === '') {
            continue;
        }
        const range = trimmedCondition.split('-');
        if (range.length === 1) {
            map.push([parseInt(range[0], 16)]);
        } else {
            map.push([parseInt(range[0], 16), parseInt(range[1], 16)]);
        }
    }
    return map;
}
