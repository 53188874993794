import {
    CreativeSetDeletedProblemDetails,
    CreativeSetNotFoundProblemDetails,
    ShowcaseDisabledProblemDetails,
    ShowcaseExpiredProblemDetails,
    ShowcaseInvalidKeyProblemDetails,
    ShowcaseNotFoundProblemDetails
} from './generated/sapi';

export type OneOfShowcaseProblemDetails =
    | ShowcaseDisabledProblemDetails
    | ShowcaseExpiredProblemDetails
    | ShowcaseNotFoundProblemDetails
    | ShowcaseInvalidKeyProblemDetails;

export type OneOfShowcaseErrorType = OneOfShowcaseProblemDetails['errorType'];

export const ShowcaseErrorMessage: Readonly<Record<OneOfShowcaseErrorType, string>> = {
    showcaseDisabled: 'Showcase is disabled',
    showcaseExpired: 'Showcase is expired',
    showcaseInvalidKey: 'Showcase not found',
    showcaseNotFound: 'Showcase not found'
};

export type OneOfCreativeSetProblemDetails =
    | CreativeSetDeletedProblemDetails
    | CreativeSetNotFoundProblemDetails;

export type OneOfCreativeSetErrorType = OneOfCreativeSetProblemDetails['errorType'];

export const CreativesetErrorMessage: Readonly<Record<OneOfCreativeSetErrorType, string>> = {
    creativeSetDeleted: 'Creative set has been deleted',
    creativeSetNotFound: 'Could not find the creative set'
};
