import { TimingFunctionKey } from './timing-functions';

export interface IAction {
    id: string;
    triggers: ActionTrigger[];
    operations: IActionOperation[];
    disabled: boolean;
    preventClickThrough?: boolean;
    templateId?: ReservedActionId;
}

export interface IActionCondition {
    operator: ActionConditionOperator;
    property: ActionConditionProperty;
    value: number;
}

export interface IActionOperation {
    /**
     * SET_STATE and REMOVE_STATE always require a state id as value.
     *
     * If set to CLEAR_STATES, then operation value is `undefined`
     */
    method: ActionOperationMethod;
    /**
     * State ID, url (`string`) or `undefined`
     *
     * Setting the state to `undefined` removes all applied States (`CLEAR_STATES`)
     * */
    value: ActionOperationValue;
    /** DataElement ID or undefined. Should always be available if value is a state ID */
    target?: string;
    animation?: {
        timingFunction: TimingFunctionKey;
        duration: number;
    };
}

export type ActionConditionOperator = '<' | '<=' | '=' | '>' | '>=';

export type ActionConditionProperty = 'creative.width' | 'ceative.height';

export type ActionOperationValue = string | undefined;

export enum ActionOperationMethod {
    SetState = 'SET_STATE',
    RemoveState = 'REMOVE_STATE',
    ClearStates = 'CLEAR_STATES',
    OpenUrl = 'OPEN_URL'
}

export enum ActionTrigger {
    Click = 'click',
    MouseDown = 'mousedown',
    MouseUp = 'mouseup',
    MouseEnter = 'mouseenter',
    MouseLeave = 'mouseleave',
    TouchStart = 'touchstart',
    TouchEnd = 'touchend',
    // TouchMove = 'touchmove',
    TouchCancel = 'touchcancel'
}

export type ReservedActionId = 'reserved-pressed' | 'reserved-hover';

export interface IActionListener {
    trigger: ActionTrigger;
    event: (event: MouseOrTouchEvent, forceClearStates?: boolean) => void;
    action: IAction;
}

export type MouseOrTouchEvent = MouseEvent | TouchEvent;
