import { ElementKind } from '@domain/elements';
import { GroupOrLayoutElement, ILayoutRule, isLayoutElement } from '../rules';

/**
 * Only allow ratio to change a certain percent
 */
const ACCEPTED_RATIO_DISTORTION = 1.03;

export class ImageRatioRule implements ILayoutRule {
    applyAutoContraintRule(layoutElement: GroupOrLayoutElement): void {
        if (isLayoutElement(layoutElement) && layoutElement.element.kind === ElementKind.Image) {
            const { element } = layoutElement;
            const { imageAsset, imageSettings } = element;
            const sizeMode = imageSettings.sizeMode;
            const ratio = layoutElement.constraint.ratio?.value;

            // Only do this when fitting option is not set (old images)
            if (!ratio && imageAsset?.width && imageAsset.height && !sizeMode) {
                const ratioObject = layoutElement.constraint.ratio || {};
                const elementRatio = element.width / element.height;
                const imageRatio = imageAsset.width / imageAsset.height;

                // Image is very close to original aspect ration
                if (
                    imageRatio * ACCEPTED_RATIO_DISTORTION >= elementRatio &&
                    imageRatio / ACCEPTED_RATIO_DISTORTION <= elementRatio
                ) {
                    ratioObject.value = imageRatio;
                }
                layoutElement.constraint.ratio = ratioObject;
            }
        }
    }
}
