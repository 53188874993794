import { CdkTextareaAutosize, TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    inject,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { UIModule } from '@bannerflow/ui';
import { isNewlineLikeCharacter } from '@creative/elements/rich-text/utils';
import {
    IMatContentSpan,
    IMatInputHighlightedArea,
    IMatInputLabel,
    IMatInputTextSelection,
    IMatValueChangeEvent,
    StartAndEndOfSelection
} from '@studio/domain/components/translation-page';
import { filter, fromEvent, map, Subject, tap } from 'rxjs';
import { getInputSelection } from '../../../pages/translation-page/utils/tp.utils';
import { TruncateSpanComponent } from '../../directives/';

@Component({
    standalone: true,
    imports: [
        MatInputModule,
        MatSelectModule,
        MatFormFieldModule,
        TextFieldModule,
        UIModule,
        CommonModule,
        ReactiveFormsModule,
        TruncateSpanComponent
    ],
    selector: 'mat-input',
    templateUrl: './mat-input.component.html',
    styleUrls: ['./mat-input.component.scss']
})
export class MatInputComponent implements OnInit, OnChanges, AfterViewInit {
    private cdRef = inject(ChangeDetectorRef);
    private elementRef = inject(ElementRef);

    @Input() charCount: boolean | undefined;

    @Input() currentTextSelection: IMatInputTextSelection | undefined;

    @Input() disabled = false;

    @Input() highlightedAreas: IMatInputHighlightedArea[] | undefined | null;

    @Input() hint: string | undefined;

    @Input() isStyledContentFocused: boolean;

    @Input() label: IMatInputLabel | undefined;

    @Input() leadingIcon: TemplateRef<HTMLElement> | undefined;

    @Input() maxLength: number | undefined;

    @Input() placeholder: string | undefined;

    @Input() showStyledText = false;

    @Input() styledContent: IMatContentSpan[] | undefined;

    @Input() trailingIcon: TemplateRef<HTMLElement>;

    @Input() type: 'text' | 'textarea' | 'presentational' = 'text';

    @Input() value: string | undefined = '';

    @Output() inputBlur = new EventEmitter<void>();

    @Output() inputClick = new EventEmitter<MouseEvent>();

    @Output() inputFocus = new EventEmitter<void>();

    @Output() inputKeyDown = new EventEmitter<KeyboardEvent>();

    @Output() inputKeyup = new EventEmitter<KeyboardEvent>();

    @Output() inputMouseOut = new EventEmitter<void>();

    @Output() inputMouseOver = new EventEmitter<void>();

    @Output() redoChange = new EventEmitter<void>();

    @Output() selectedText = new EventEmitter<IMatInputTextSelection | undefined>();

    @Output() undoChange = new EventEmitter<void>();

    @Output() valueChange = new EventEmitter<IMatValueChangeEvent>();

    @ViewChild('autosize') autosize: CdkTextareaAutosize;
    @ViewChild('input') private input: ElementRef<HTMLTextAreaElement | HTMLInputElement>;
    @ViewChild('matFormField', { read: ElementRef }) matFormField: ElementRef;

    @ViewChild('styledContentWrapper') private styledContentWrapper:
        | ElementRef<HTMLDivElement>
        | undefined;

    private _highlights$ = new Subject<string | undefined>();
    highlights$ = this._highlights$.asObservable();

    private currentSelection: IMatInputTextSelection | undefined;
    private mouseUp = false;

    private beforeInputSelection: IMatValueChangeEvent['selection'];
    private beforeInputValue: string;
    private afterInputValue: string;

    @HostListener('document:click', ['$event'])
    onClick(event: MouseEvent): void {
        const el = this.elementRef.nativeElement;
        const clickedOutside = !el.contains(event.target);
        const isFocused = !!el.querySelector('.mat-focused');

        if (clickedOutside && isFocused) {
            this.onInputBlur();
        } else if (!clickedOutside && !isFocused) {
            this.onInputFocus();
        }
    }

    @HostListener('document:keydown.escape')
    onKeydownHandler(): void {
        setTimeout(() => {
            (document.activeElement as HTMLElement).blur();
            this.onInputBlur();
        });
    }

    @HostListener('document:keydown', ['$event'])
    onKeydown(event: KeyboardEvent): void {
        // Prevent default behavior for Ctrl/Cmd + S and Ctrl/Cmd + H on Windows and MAC
        // needed for SaveFieldValue and ToggleAllElements shortcuts
        if ((event.key === 's' || event.key === 'h') && (event.ctrlKey || event.metaKey)) {
            event.preventDefault();
            (document.activeElement as HTMLElement).blur();
        }
    }

    constructor() {
        fromEvent(document, 'mouseup')
            .pipe(
                tap(() => {
                    if (this.highlightedAreas?.length) {
                        this.handleHighlightedAreasSelection(document.getSelection());
                    }
                }),
                takeUntilDestroyed(),
                filter(() => !!this.styledContent),
                map(() => document.getSelection()),
                filter(selection => {
                    const rangeIsChildrenOfWrapper = !!this.matFormField?.nativeElement.contains(
                        selection?.anchorNode?.parentNode ?? null
                    );

                    if (!rangeIsChildrenOfWrapper) {
                        // User clicked somewhere
                        this.currentSelection = undefined;
                        return false;
                    }
                    return rangeIsChildrenOfWrapper;
                })
            )
            .subscribe(selection => {
                if (this.styledContent) {
                    this.handleSelectionChange(selection);
                    this.mouseUp = false;
                }
            });
    }

    ngAfterViewInit(): void {
        if (this.value && this.highlightedAreas?.length) {
            this.updateHighlight(this.value);
        }
        this.triggerTextAreaResize();
    }

    @HostListener('window:mousedown')
    onMouseDown(): void {
        this.mouseUp = false;
    }

    @HostListener('window:mouseup')
    onMouseUp(): void {
        this.mouseUp = true;
        const selection = window.getSelection();
        if (selection) {
            this.handleSelectionChange(selection, true);
        }
        if (this.currentSelection) {
            this.selectedText.emit(this.currentSelection);
            this.currentSelection = undefined;
            this.mouseUp = false;
        }
    }

    ngOnInit(): void {
        if (this.showStyledText) {
            this.type = 'textarea';
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.handleValueChange(changes);
        this.handleTypeChange(changes);
        this.handleShowStyledTextChange(changes);
        this.handleHighlightedAreasChange(changes);
        this.handleStyledContentFocusChange();
    }

    onInput(event: Event): void {
        const inputType = (event as InputEvent).inputType;
        if (inputType === 'historyRedo') {
            event.preventDefault();
            event.stopPropagation();
            this.redoChange.emit();
            return;
        }
        if (inputType === 'historyUndo') {
            event.preventDefault();
            event.stopPropagation();
            this.undoChange.emit();
            return;
        }
        const newValue = (event.target as HTMLTextAreaElement).value;
        let newValueType: IMatValueChangeEvent['type'] = 'add';
        const hasSelection =
            this.beforeInputSelection &&
            this.beforeInputSelection?.start !== this.beforeInputSelection?.end;
        if (inputType.includes('delete')) {
            newValueType = 'delete';
            if (this.beforeInputSelection.start === this.beforeInputSelection.end) {
                // fix selection when none. Also, if emojis are in, they count as 2 characters
                if (inputType === 'deleteContentForward') {
                    this.beforeInputSelection.end += this.beforeInputValue.length - newValue.length;
                } else {
                    this.beforeInputSelection.start -= this.beforeInputValue.length - newValue.length;
                }
            }
        } else if (hasSelection) {
            newValueType = 'replace';
        }

        this.afterInputValue = newValue;

        const newChangeValueEvent: IMatValueChangeEvent = {
            type: newValueType,
            newValue: newValue,
            oldValue: this.beforeInputValue,
            selection: this.beforeInputSelection
        };
        this.valueChange.emit(newChangeValueEvent);
    }

    onBeforeInput(event: InputEvent): void {
        if (event.inputType.startsWith('history')) {
            return;
        }
        if (event.inputType.startsWith('delete')) {
            this.handleDeleteInTextarea(event);
        }
        const textarea = event.target as HTMLTextAreaElement;

        this.beforeInputValue = textarea.value;
        this.beforeInputSelection = {
            start: textarea.selectionStart,
            end: textarea.selectionEnd
        };
    }

    onInputKeyUp(event: KeyboardEvent): void {
        this.inputKeyup.emit(event);
    }

    onInputKeyDown(event: KeyboardEvent): void {
        const isArrowKey = event.key.includes('Arrow');
        if (isArrowKey && this.highlightedAreas?.length) {
            // Wait for caret to move in Textarea
            requestAnimationFrame(() => {
                this.handleCaretMoveInTextarea(event.key);
            });
        }
        this.inputKeyDown.emit(event);
    }

    onInputFocus(): void {
        this.inputFocus.emit();
    }

    onInputBlur(): void {
        this.inputBlur.emit();
    }

    onMouseOver(): void {
        this.inputMouseOver.emit();
    }

    onMouseOut(): void {
        this.inputMouseOut.emit();
    }

    onInputClick(event: MouseEvent): void {
        // No caret (click on border, for instance)
        if (this.input.nativeElement.selectionStart !== null) {
            // Wait for selection to be finished
            requestAnimationFrame(() => {
                this.handleCaretMoveInTextarea('Click');
            });
        }
        this.inputClick.emit(event);
    }

    onTextareaSelect(event: Event): void {
        if (!event.target) {
            this.selectedText.emit();
            return;
        }
        const eventTarget = event.target as HTMLTextAreaElement;
        const selectedText = eventTarget.value.substring(
            eventTarget.selectionStart,
            eventTarget.selectionEnd
        );
        const textSelection: IMatInputTextSelection = {
            start: eventTarget.selectionStart,
            end: eventTarget.selectionEnd,
            length: eventTarget.selectionEnd - eventTarget.selectionStart,
            text: selectedText
        };

        this.selectedText.emit(textSelection);
    }

    private handleHighlightedAreasChange(changes: SimpleChanges): void {
        if (!('highlightedAreas' in changes) || this.type !== 'textarea') {
            return;
        }

        const { currentValue, previousValue } = changes['highlightedAreas'];
        if (currentValue === previousValue) {
            return;
        }
        this.updateHighlight(this.afterInputValue ?? this.value ?? '');
    }

    private updateHighlight(newText: string): void {
        let highlightedText = newText;

        // #reverse() mutates the array
        const reversedHighlightedAreas = [...(this.highlightedAreas ?? [])].reverse();
        for (const { start, end, focused } of reversedHighlightedAreas) {
            const markedText = highlightedText.substring(start, end);
            const html = `<mark class="${focused ? 'focused' : ''}">${markedText}</mark>`;
            highlightedText =
                highlightedText.substring(0, start) + html + highlightedText.substring(end);
        }
        const newHtml = highlightedText.replace(/\n$/g, '\n\n');

        this._highlights$.next(newHtml);
        this.cdRef.detectChanges();
    }

    private handleSelectionChange(selection: Selection | null, extendSelection = false): void {
        if (!selection || !this.styledContentWrapper) {
            return;
        }
        if (selection.type === 'None') {
            return;
        }
        const startingSpan = selection.anchorNode;
        if (!startingSpan) {
            return;
        }
        const children = this.styledContentWrapper.nativeElement.children;
        const { start, end } = this.getStartAndEndOfSelection(selection, children, extendSelection);
        const selectionLength = end - start;
        const selectedText = this.value?.substring(start, end) ?? '';
        const selectedTextObject = {
            start,
            end,
            length: selectionLength,
            text: selectedText
        };
        this.currentSelection = selectedTextObject;

        if (this.mouseUp && !extendSelection) {
            this.selectedText.emit(selectedTextObject);
        }
    }

    private handleValueChange(changes: SimpleChanges): void {
        if (!('value' in changes)) {
            return;
        }

        const { currentValue, previousValue } = changes['value'];
        if (currentValue === previousValue) {
            return;
        }
        this.updateHighlight(currentValue ?? '');
    }

    private handleTypeChange(changes: SimpleChanges): void {
        if (!('type' in changes)) {
            return;
        }
        const { currentValue, previousValue } = changes['type'];
        if (currentValue === previousValue) {
            return;
        }
    }

    private handleShowStyledTextChange(changes: SimpleChanges): void {
        if (!('styledContent' in changes)) {
            if (!('showStyledText' in changes)) {
                return;
            }
            const { currentValue, previousValue } = changes['showStyledText'];
            if (currentValue === previousValue) {
                return;
            }

            if (!currentValue) {
                // If set to false, no need to do anything, styledContentWrapper will get erased in template
                return;
            }
        }

        if (this.type !== 'textarea') {
            this.type = 'textarea';
        }

        requestAnimationFrame(() => {
            this.renderStyledText();
        });
    }

    private renderStyledText(): void {
        const contentWrapper = this.styledContentWrapper?.nativeElement;
        if (!contentWrapper) {
            return;
        }

        contentWrapper.innerHTML = '';
        contentWrapper.style.width = '100%';
        let charCount = 0;
        const windowSelection = window.getSelection();
        windowSelection?.removeAllRanges();

        for (const styledSpan of this.styledContent ?? []) {
            const span = this.createStyledContentHtmlElement(styledSpan);
            if (!span.firstChild) {
                continue;
            }
            contentWrapper.appendChild(span);
            if (!this.currentSelection || !windowSelection) {
                continue;
            }
            const isElementInSelectedRange =
                charCount + styledSpan.content.length > this.currentSelection.start &&
                charCount < this.currentSelection.end;
            if (isElementInSelectedRange) {
                const range = document.createRange();
                range.setStart(span.firstChild, Math.max(0, this.currentSelection.start - charCount));
                range.setEnd(
                    span.firstChild,
                    Math.min(styledSpan.content.length, this.currentSelection.end - charCount)
                );
                windowSelection.addRange(range);
            }
            charCount += styledSpan.content.length;
        }
        this.fixHeight(contentWrapper);
    }

    private fixHeight(contentWrapper: HTMLElement): void {
        // Make contentWrapper scrollable if it overflows textarea
        let newHeight = Math.min(300, contentWrapper.scrollHeight);
        this.input.nativeElement.style.height = `${newHeight}px`;
        // Changing the input's height changes the contentWrapper scrollHeight, so
        // Recalculate height after adjustment
        newHeight = Math.min(300, contentWrapper.scrollHeight);
        this.input.nativeElement.style.height = `${newHeight}px`;
    }

    private createStyledContentHtmlElement({
        content,
        userSelect,
        selected
    }: IMatContentSpan): HTMLSpanElement | HTMLBRElement {
        const elementType = this.getStyleContentHtmlElementType(content);
        const htmlElement = document.createElement(elementType);
        const sanitizedContent = `${content.replace(/\s/g, ' ')}&zwnj;`;
        htmlElement.innerHTML = selected ? `<mark>${sanitizedContent}</mark>` : sanitizedContent;
        if (userSelect) {
            htmlElement.style.userSelect = 'text';
            htmlElement.dataset.userSelect = userSelect;
        }

        return htmlElement;
    }

    private getStyleContentHtmlElementType(styledSpanContent: string): 'br' | 'span' {
        const isBreakLine =
            styledSpanContent.length === 1 && isNewlineLikeCharacter(styledSpanContent.charCodeAt(0));
        return isBreakLine ? 'br' : 'span';
    }
    /**
     * Preconditions:
     * * selection?.anchorNode is not falsy
     * * selection start and end are inside children
     * Returns:
     * * 0 <= start <= end
     */
    private getStartAndEndOfSelection(
        selection: Selection,
        children: HTMLCollection,
        extendSelection: boolean
    ): StartAndEndOfSelection {
        // Selection is only one span wide
        if (selection.anchorNode === selection.focusNode) {
            return this.getStartAndEndOfSingleSpanSelection(selection, children, extendSelection);
        }
        return this.getStartAndEndOfMultipleSpanSelection(selection, children, extendSelection);
    }

    private getStartAndEndOfMultipleSpanSelection(
        selection: Selection,
        children: HTMLCollection,
        extendSelection: boolean
    ): StartAndEndOfSelection {
        let selectionStartIndex = 0;
        let foundStart = false;
        let selectionEndIndex = 0;
        let foundEnd = false;
        for (let i = 0; i < children.length; i++) {
            const child = children.item(i) as HTMLElement;
            if (!child?.textContent) {
                continue;
            }

            const isUserSelectAll = child.dataset.userSelect === 'all';

            if (selection.anchorNode?.parentNode === child) {
                let anchorOffset = selection.anchorOffset;
                if (isUserSelectAll) {
                    // foundEnd === true means backwards selection
                    anchorOffset = foundEnd ? child.textContent.length - 1 : 0;
                    // Force selection on whole word
                    if (extendSelection) {
                        selection.extend(selection.anchorNode, anchorOffset);
                    }
                }
                selectionStartIndex += this.calculateSelectionIndex(
                    child.textContent,
                    selection.anchorOffset
                );
                foundStart = true;
            }
            if (selection.focusNode?.parentNode === child) {
                let focusOffset = selection.focusOffset;
                if (isUserSelectAll) {
                    // foundStart === true means backwards selection
                    focusOffset = foundStart ? child.textContent.length - 1 : 0;
                    // Force selection on whole word
                    if (extendSelection) {
                        selection.extend(selection.focusNode, focusOffset);
                    }
                }

                selectionEndIndex += this.calculateSelectionIndex(
                    child.textContent,
                    selection.focusOffset
                );
                foundEnd = true;
            }
            if (!foundStart) {
                selectionStartIndex += child.textContent.length - 1; // remove ZeroWidthNonJoiner
            }
            if (!foundEnd) {
                selectionEndIndex += child.textContent.length - 1; // remove ZeroWidthNonJoiner
            }
            if (foundEnd && foundStart) {
                break;
            }
        }

        const start = Math.min(Math.max(selectionStartIndex, 0), selectionEndIndex);
        const end = Math.max(selectionEndIndex, selectionStartIndex);

        return {
            start,
            end
        };
    }

    private calculateSelectionIndex(childTextContent: string, offset: number): number {
        if (childTextContent.length - 1 < offset) {
            return offset - 1;
        } else {
            return offset;
        }
    }

    private getStartAndEndOfFeedSpanSelection(
        selection: Selection,
        offset: number
    ): StartAndEndOfSelection {
        if (!selection.anchorNode) {
            return { start: 0, end: 0 };
        }
        const end = selection.anchorNode.textContent?.length ?? 0;

        selection.setBaseAndExtent(selection.anchorNode, 0, selection.anchorNode, end);

        const textIncludesZeroWidthNonJoiner = selection.anchorNode?.textContent
            ?.substring(0, end)
            ?.includes('‌');

        return {
            start: offset,
            end: offset + (textIncludesZeroWidthNonJoiner ? end - 1 : end)
        };
    }

    private getStartAndEndOfSingleSpanSelection(
        selection: Selection,
        children: HTMLCollection,
        extendSelection: boolean
    ): StartAndEndOfSelection {
        const selectionOffset = this.getSelectionOffset(selection, children);

        if (selection.anchorNode?.parentElement?.dataset.userSelect === 'all' && extendSelection) {
            return this.getStartAndEndOfFeedSpanSelection(selection, selectionOffset);
        }

        const start = Math.min(selection.anchorOffset, selection.focusOffset);
        const end = Math.max(selection.anchorOffset, selection.focusOffset);

        const textIncludesZeroWidthNonJoiner = selection.anchorNode?.textContent
            ?.substring(start, end)
            ?.includes('‌');

        if (textIncludesZeroWidthNonJoiner) {
            return {
                start: start + selectionOffset,
                end: end - 1 + selectionOffset
            };
        }
        return {
            start: start + selectionOffset,
            end: end + selectionOffset
        };
    }

    // Offset from beginning of value
    private getSelectionOffset(selection: Selection, children: HTMLCollection): number {
        let selectionOffset = 0;
        for (let i = 0; i < children.length; i++) {
            const child = children.item(i);
            if (child === selection.anchorNode?.parentNode) {
                break;
            }
            selectionOffset += (child?.textContent?.length ?? 1) - 1;
        }
        return selectionOffset;
    }

    private handleStyledContentFocusChange(): void {
        const action = this.isStyledContentFocused ? 'add' : 'remove';
        this.styledContentFocusStyles(action);
    }

    private styledContentFocusStyles(action: 'remove' | 'add'): void {
        const matFormField = this.matFormField?.nativeElement;
        matFormField?.classList[action]('mat-focused');
        const divInsideMatFormField = matFormField?.querySelector('.mat-mdc-text-field-wrapper');
        divInsideMatFormField?.classList[action]('mdc-text-field--focused');
    }

    private handleHighlightedAreasSelection(documentSelection: Selection | null): void {
        if (!documentSelection) {
            this.input.nativeElement.setSelectionRange(null, null);
            return;
        }
        let selection = getInputSelection(this.input.nativeElement);
        if (!selection || selection.start === selection.end) {
            return;
        }
        for (const highlightedArea of this.highlightedAreas ?? []) {
            const { start, end } = selection;
            const isPartialyHighlighted =
                (highlightedArea.start < start && start < highlightedArea.end) ||
                (highlightedArea.start < end && end < highlightedArea.end);
            if (!isPartialyHighlighted) {
                continue;
            }

            const newStart = Math.min(start, highlightedArea.start);
            const newEnd = Math.max(end, highlightedArea.end);
            this.input.nativeElement.setSelectionRange(
                newStart,
                newEnd,
                this.input.nativeElement.selectionDirection ?? 'forward'
            );
            selection = getInputSelection(this.input.nativeElement);
            if (!selection) {
                return;
            }
        }
    }

    private handleCaretMoveInTextarea(key: string): void {
        if (this.input.nativeElement.selectionStart !== this.input.nativeElement.selectionEnd) {
            // Selection is handled by #handleHighlightedAreasSelection()
            return;
        }
        const isLeftArrow = key === 'ArrowLeft';
        const isClick = key === 'Click';
        const caretPosition = this.input.nativeElement.selectionStart ?? 0;

        for (const highlightedArea of this.highlightedAreas ?? []) {
            const isCaretInBetween =
                highlightedArea.start < caretPosition && caretPosition < highlightedArea.end;
            if (!isCaretInBetween) {
                continue;
            }
            let newPosition = isLeftArrow ? highlightedArea.start : highlightedArea.end;
            if (isClick) {
                const isCaretCloserToStart =
                    caretPosition - highlightedArea.start <= highlightedArea.end - caretPosition;
                newPosition = isCaretCloserToStart ? highlightedArea.start : highlightedArea.end;
            }
            this.input.nativeElement.setSelectionRange(newPosition, newPosition);
            break;
        }
    }

    private handleDeleteInTextarea(event: InputEvent): void {
        const selection = getInputSelection(this.input.nativeElement);
        if (!selection) {
            return;
        }
        if (selection.start !== selection.end) {
            // this shouldn't happen, since selection is handled in #handleHighlightedAreasSelection()
            return;
        }
        const forwardDelete = event.inputType === 'deleteContentForward';
        for (const highlightedArea of this.highlightedAreas ?? []) {
            const { start, end } = selection;
            if (
                (forwardDelete && start === highlightedArea.start) ||
                (!forwardDelete && end === highlightedArea.end)
            ) {
                const newValue = `${this.value?.slice(0, highlightedArea.start)}${this.value?.slice(
                    highlightedArea.end
                )}`;
                this.input.nativeElement.value = newValue;
                this.input.nativeElement.selectionStart = highlightedArea.start;
                this.input.nativeElement.selectionEnd = highlightedArea.start;
                event.preventDefault();
                return;
            }
        }
    }

    private triggerTextAreaResize(): void {
        // Wait for changes to be applied, then trigger textarea resize.
        this.autosize?.resizeToFitContent(true);
    }
}
