import { IAnimator } from '../../animator.header';
import { __inject, inject } from '@studio/utils/di';
import { EventEmitter } from '@studio/utils/event-emitter';
import { AnimatorEvents } from '../../animator';
import { T } from '../../creative.container';
import { ITimeline } from './declarations/timeline-api';

export class TimelineApi extends EventEmitter<AnimatorEvents> implements ITimeline {
    get duration(): number {
        return this.animator.duration;
    }

    get currentTime(): number {
        return this.animator.time;
    }

    get loop(): number {
        return this.animator.loop;
    }

    get loops(): number {
        return this.animator.creative.loops;
    }

    get isPlaying(): boolean {
        return this.animator.isPlaying;
    }

    constructor(@inject(T.ANIMATOR) public animator: IAnimator) {
        super();

        this.animator.on('loop', (loop: number) => this.emit('loop', loop));
        this.animator.on('pause', (time: number) => this.emit('pause', time));
        this.animator.on('play', (time: number) => this.emit('play', time));
        this.animator.on('stop', (time: number) => this.emit('stop', time));
        this.animator.on('restart', (time: number) => this.emit('restart', time));
        this.animator.on('replay', (time: number) => this.emit('replay', time));
        this.animator.on('seek', (time: number) => this.emit('seek', time));
        this.animator.on('tick', (time: number) => this.emit('tick', time));
    }

    play(): this {
        this.animator.addToQueueFromWidget(this.animator.play);
        return this;
    }

    pause(): this {
        this.animator.addToQueue(this.animator.pause);
        return this;
    }

    restart(): this {
        this.animator.restart();
        return this;
    }

    replay(): this {
        this.animator.replay();
        return this;
    }

    nextLoop(replay?: boolean, offset?: number): this {
        if (offset) {
            offset = Math.abs(offset);
        }
        this.animator.addToQueue(this.animator.next, [replay, offset]);
        return this;
    }

    previousLoop(replay?: boolean, offset?: number, exceedLimit?: boolean): this {
        if (offset) {
            offset = Math.abs(offset);
        }
        this.animator.addToQueue(this.animator.prev, [replay, offset, exceedLimit]);
        return this;
    }

    seek(time: number): this {
        if (typeof time !== 'number') {
            console.error('Seek requires 1 argument with the type of number.');
            return this;
        }

        this.animator.addToQueue(this.animator.seek, [time]);
        return this;
    }

    setLoop(loop: number): this {
        if (typeof loop !== 'number') {
            console.error('setloop requires 1 argument with the type of number.');
            return this;
        }

        this.animator.addToQueue(this.animator.setLoop, [loop]);
        return this;
    }

    suspendEvents(): void {
        this.skipEmission = true;
    }

    resumeEvents(): void {
        this.skipEmission = false;
    }

    destroy(): void {
        this.clearEvents();
    }
}

__inject(T.ANIMATOR, {}, TimelineApi, 'animator', 0);
