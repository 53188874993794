import { CommonModule } from '@angular/common';
import { Component, computed, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UIModule } from '@bannerflow/ui';
import { ExpandablePromptInputType } from '@studio/domain/components/ai-studio.types';
import { GenAIService } from '../../state/gen-ai.service';
import { ExpandablePromptComponent } from '../expandable-prompt/expandable-prompt.component';
import { OutpaintInputMode } from '@studio/domain/components/gen-ai';

@Component({
    standalone: true,
    imports: [CommonModule, UIModule, ExpandablePromptComponent],
    selector: 'outpaint-options',
    templateUrl: './outpaint-options.component.html',
    styleUrls: ['./outpaint-options.component.scss']
})
export class OutpaintOptionsComponent {
    private genAIService = inject(GenAIService);

    readonly ExpandablePromptInputType = ExpandablePromptInputType;
    readonly OutpaintInputMode = OutpaintInputMode;

    private outpaintSettings = toSignal(this.genAIService.outpaintSettings$, {
        initialValue: {
            left: 0,
            right: 0,
            up: 0,
            down: 0
        }
    });

    leftValue = computed(() => this.outpaintSettings().left);
    rightValue = computed(() => this.outpaintSettings().right);
    upValue = computed(() => this.outpaintSettings().up);
    downValue = computed(() => this.outpaintSettings().down);

    isOutpaintInputMixed = computed(() => {
        const { left, right, up, down } = this.outpaintSettings();
        return left !== right || right !== up || up !== down;
    });

    promptValue = signal('');
    negativePromptValue = signal('');
    selectedOutpaintInputMode = signal(OutpaintInputMode.Separate);
    jointOutpaintValue = signal(0);

    isRequestingOutpaint = toSignal(this.genAIService.isRequestingOutpaint$, { initialValue: false });

    private isRequestingAnything = toSignal(this.genAIService.isRequestingAnything$, {
        initialValue: false
    });

    actionsDisabled = computed(() => this.computeActionsDisabled());

    onLeftValueChange(newValue: number): void {
        const outpaintSettings = this.outpaintSettings();
        this.genAIService.outpaintSettingsUpdated({
            ...outpaintSettings,
            left: newValue
        });
    }

    onRightValueChange(newValue: number): void {
        const outpaintSettings = this.outpaintSettings();
        this.genAIService.outpaintSettingsUpdated({
            ...outpaintSettings,
            right: newValue
        });
    }

    onDownValueChange(newValue: number): void {
        const outpaintSettings = this.outpaintSettings();
        this.genAIService.outpaintSettingsUpdated({
            ...outpaintSettings,
            down: newValue
        });
    }

    onUpValueChange(newValue: number): void {
        const outpaintSettings = this.outpaintSettings();
        this.genAIService.outpaintSettingsUpdated({
            ...outpaintSettings,
            up: newValue
        });
    }

    onJointValueChange(newValue: number): void {
        this.jointOutpaintValue.set(newValue);
        if (this.selectedOutpaintInputMode() === OutpaintInputMode.Joint) {
            this.genAIService.outpaintSettingsUpdated({
                left: newValue,
                right: newValue,
                up: newValue,
                down: newValue
            });
        }
    }

    onPromptValueChange(newPrompt: string): void {
        this.promptValue.set(newPrompt);
    }

    onNegativePromptValueChange(event: string): void {
        this.negativePromptValue.set(event);
    }

    onOutpaintInputModeChange(newMode: OutpaintInputMode): void {
        this.selectedOutpaintInputMode.set(newMode);

        if (newMode === OutpaintInputMode.Joint) {
            if (!this.isOutpaintInputMixed()) {
                const value = this.leftValue();
                this.jointOutpaintValue.set(value);
            }
        }
    }

    expandImage = async (): Promise<void> => {
        const settings = this.outpaintSettings();
        const prompt = this.promptValue();
        const negativePrompt = this.negativePromptValue();
        this.genAIService.outpaint(settings, prompt, negativePrompt);
    };

    private computeActionsDisabled(): boolean {
        const isRequestingAnything = this.isRequestingAnything();
        const { left, right, up, down } = this.outpaintSettings();

        return isRequestingAnything || !(left || right || up || down);
    }
}
