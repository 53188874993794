import { ISize } from '@domain/dimension';
import { IScaledSize } from '@studio/domain/components/gen-ai';
import { DEFAULT_MAX_HEIGHT, DEFAULT_MAX_WIDTH } from '../ai-studio.constants';

export function getScaledAssetDimensions(size: ISize | undefined): IScaledSize {
    const result: IScaledSize = {
        width: 0,
        height: 0,
        scale: 1
    };
    if (!size) {
        result.width = DEFAULT_MAX_WIDTH;
        result.height = DEFAULT_MAX_HEIGHT;
        return result;
    }
    const { width, height } = size;
    result.width = width;
    result.height = height;

    if (width > height) {
        if (width > DEFAULT_MAX_WIDTH) {
            result.scale = DEFAULT_MAX_WIDTH / width;
            result.height = height * (DEFAULT_MAX_WIDTH / width);
            result.width = DEFAULT_MAX_WIDTH;
        }
    } else if (height > DEFAULT_MAX_HEIGHT) {
        result.scale = DEFAULT_MAX_HEIGHT / height;
        result.width = width * (DEFAULT_MAX_HEIGHT / height);
        result.height = DEFAULT_MAX_HEIGHT;
    }
    return result;
}

export async function getImageSize(imageUrl: string | undefined): Promise<ISize> {
    if (!imageUrl) {
        return Promise.resolve({ width: 0, height: 0 });
    }
    return new Promise(resolve => {
        const image = new Image();
        image.src = imageUrl;
        image.onload = (): void => {
            const result = {
                width: image.width,
                height: image.height
            };
            resolve(result);
        };
    });
}
