import { loadHtmlElement } from './promises';
import { getCookie } from './utils';

let iframe: HTMLIFrameElement | undefined;
let iframePromise: Promise<HTMLIFrameElement>;

type EvalWindow = Window & {
    context: IEvalWindowContext;
    evalMathExpression: Function;
};

interface IEvalWindowContext {
    sin: (x: number) => number;
    cos: (x: number) => number;
    pi: number;
    PI: number;
    abs: (x: number) => number;
    round: (x: number) => number;
    ceil: (x: number) => number;
    floor: (x: number) => number;
    pow: (x: number, p: number) => number;
    sqrt: (x: number) => number;
    random: () => number;
}

// Always include math operations
const defaultContext: IEvalWindowContext = {
    sin: Math.sin,
    cos: Math.cos,
    pi: Math.PI,
    PI: Math.PI,
    abs: Math.abs,
    round: Math.round,
    ceil: Math.ceil,
    floor: Math.floor,
    pow: Math.pow,
    sqrt: Math.sqrt,
    random: Math.random
};

let isSandboxed = false; // Checks whether in sandboxed(i.e <iframe sandbox>) environment
// Needs to be string evaluated to get around 'use strict' restriction, because we have to use with-statements.
/** Assign values in try-catch.
 * `context` is something not defined and throws error in strict mode
 * so we need to reassign the value to a hoisted variable then */
const evalMathExpressionFunctionBody = `
    try {
        context.eval = eval;
        context.code = code;
    } catch(e) {
        var context = window.context || {};
        context.eval = eval;
        context.code = code;
    }
    const scopeProxy = new Proxy(context, {
        has(prop) { return !(prop in context) },
        get(target, prop) {
            if (prop in target) {
                return target[prop];
            }
            if (!(typeof prop === 'symbol')) {
                throw new Error(\`Unexpected token '\${prop.toString()}'.\`);
            }
        }
    });
    with (scopeProxy) {
        return eval(code);
    }`;

const evalMathExpression = new Function('code', evalMathExpressionFunctionBody);
export class SafeEval {
    static eval(code: string, context: any = {}): number {
        if (!iframe) {
            SafeEval.init();
        }

        if (isSandboxed) {
            (window as any).context = { ...defaultContext, ...context };
            return evalMathExpression(code);
        }

        // Doesn't exist on when "isSandboxed"
        const contentWindow = iframe?.contentWindow as EvalWindow | undefined | null;
        const iframeEvalMathExpression = iframe?.contentDocument && contentWindow?.evalMathExpression;

        if (!iframeEvalMathExpression || !contentWindow) {
            throw new Error('Safe eval iframe is not initiated.');
        }

        contentWindow.context = { ...defaultContext, ...context };
        return iframeEvalMathExpression(code);
    }

    /**
     * @summary
     * Initialize safe eval function for evaluating animation expressions.
     *
     * @description
     * This function will determine whether to use a stricter iframe sandboxed approach.
     * The stricter approach is mainly for safe-gaurding access to global state in the Studio client,
     * but it's used whenever it can, even in published ads. The "looser" approach is used only when
     * we are in a sandboxed(i.e. <iframe sandbox>) environment.
     *
     * Note, an iframe is needed because '({}).constructor === Object' and you can from there access
     * all global state. An iframe creates a copy of all global objects including '({}).constructor'
     * that is different from Studio client's. Thus, safe-guarding us from acessing Studio client's
     * global state.
     *
     * Note, all published creatives will either be wrapped in an iframe or not. When they are
     * wrapped in an iframe, they are safe. Published ads served through sandboxed iframes prohibits
     * us from creating additional iframes. We don't need the iframe or use the stricter approach,
     * since we already are inside an iframe.
     */
    static async init(isSandboxedIframe?: boolean): Promise<HTMLIFrameElement | void> {
        if (iframePromise) {
            return iframePromise;
        }

        if (!iframe && typeof window !== 'undefined') {
            iframe = document.createElement('iframe');
            const style = iframe.style;
            style.position = 'absolute';
            style.pointerEvents = 'none';
            style.width = style.height = style.opacity = style.top = style.left = '0';
            iframePromise = loadHtmlElement(iframe).promise;
            document.body.appendChild(iframe);
            await iframePromise;
            const iframeDoc = iframe.contentDocument;
            if (isSandboxedIframe || !iframeDoc) {
                isSandboxed = isSandboxedIframe || !iframeDoc;
            } else {
                const STUDIO_JS = window?.process?.env?.STUDIO_JS;
                const nonce: string | undefined = STUDIO_JS ? getCookie('script-nonce') : '';

                try {
                    const evalScript = document.createElement('script');
                    const url = URL.createObjectURL(
                        new Blob([`window.evalMathExpression = ${evalMathExpression.toString()}`])
                    );
                    evalScript.nonce = nonce;
                    evalScript.src = url;
                    const scriptPromise = loadHtmlElement(evalScript).promise;
                    iframeDoc.head.appendChild(evalScript);
                    await scriptPromise;
                    URL.revokeObjectURL(url);
                } catch {
                    // Fallback to old injection which causes document.write warnings
                    iframeDoc.open();
                    iframeDoc.write(
                        `<!DOCTYPE html><html><head><script nonce="${nonce}">window.evalMathExpression = ${evalMathExpression.toString()}</script></head></html>`
                    );
                    iframeDoc.close();
                }
            }
        }
        return iframe;
    }

    static destroy(): void {
        if (iframe) {
            document.body.removeChild(iframe);
            iframe = undefined;
        }
    }
}
