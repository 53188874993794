import { IStorageService } from './storage.model';

export class InMemoryStorageService implements IStorageService {
    private readonly storage: Map<string, string> = new Map<string, string>();

    has(key: string): boolean {
        return this.storage.has(key) !== null;
    }
    get(key: string): string | undefined {
        const value = this.storage.get(key);

        return value !== null ? value : undefined;
    }
    set(key: string, value: string): void {
        this.storage.set(key, value);
    }
    remove(key: string): void {
        this.storage.delete(key);
    }
    clear(): void {
        this.storage.clear();
    }
}
