import { Injectable } from '@angular/core';
import { ICreativeset } from '@domain/creativeset/creativeset';
import { IVideoValidationState } from '@studio/domain/video-validation';
import { UserSettingsService } from '@studio/stores/user-settings/user-settings.service';
import { firstValueFrom, Subject } from 'rxjs';
import { SessionStorageService } from './session-storage.service';

@Injectable({ providedIn: 'root' })
export class VideoValidationService {
    private _videoValidation$ = new Subject<IVideoValidationState[]>();
    videoValidationChange$ = this._videoValidation$.asObservable();

    constructor(
        private userSettingsService: UserSettingsService,
        private sessionStorageService: SessionStorageService
    ) {}

    validateElements(creativeset: ICreativeset): void {
        const results = [
            {
                id: creativeset.id,
                hasHeavyVideo: [...creativeset.designs].some(design => design.hasHeavyVideo)
            }
        ];

        this._videoValidation$.next(results);
    }

    async filterIgnoredResults(
        videoValidationResults: IVideoValidationState[]
    ): Promise<IVideoValidationState[]> {
        const ignoredVideoValidationResults = await firstValueFrom(
            this.userSettingsService.videoValidationWarnings$
        );

        if (ignoredVideoValidationResults.length === 0) {
            return videoValidationResults;
        }

        return videoValidationResults.filter(
            validationResult =>
                !ignoredVideoValidationResults.find(
                    ignoredValidationResult => validationResult.id === ignoredValidationResult.id
                )
        );
    }

    async updateIgnoredResults(videoValidationResults: IVideoValidationState[]): Promise<void> {
        const ignoredVideoValidationResults = await firstValueFrom(
            this.userSettingsService.videoValidationWarnings$
        );
        const newIgnoredResults = [
            ...ignoredVideoValidationResults.filter(
                element => ![...videoValidationResults].find(fvelement => fvelement.id === element.id)
            ),
            ...videoValidationResults
        ];

        this.userSettingsService.setIgnoreWarnings('videoValidationWarnings', [...newIgnoredResults]);
    }

    async clearSuppressedWarnings(idToClear: string): Promise<void> {
        const ignoredVideoValidationResults = await firstValueFrom(
            this.userSettingsService.videoValidationWarnings$
        );
        const filteredIgnored = ignoredVideoValidationResults.filter(({ id }) => id !== idToClear);

        this.userSettingsService.setIgnoreWarnings('videoValidationWarnings', filteredIgnored);
        this.sessionStorageService.setSessionStorageItem(
            'visitedCreativesetsWithHeavyVideos',
            JSON.stringify(filteredIgnored)
        );
    }
}
