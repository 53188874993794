/**
 * Taken from angulars implementation: https://github.com/angular/angular/blob/master/packages/core/src/sanitization/url_sanitizer.ts
 */
import { isBase64Image } from './url';

const SAFE_URL_PATTERN = /^(?:(?:https?|mailto|ftp|tel|file):|[^&:/?#]*(?:[/?#]|$))/gi;

/** A pattern that matches safe data URLs. Only matches image, video and audio types. */
// a pattern that can be !very! performance heavy, depending on the base64 content. e.g. maximum call stack issues
// const DATA_URL_PATTERN = /^data:(?:image\/(?:bmp|gif|jpeg|jpg|png|tiff|webp|svg\+xml)|video\/(?:mpeg|mp4|ogg|webm)|audio\/(?:mp3|oga|ogg|opus));base64,[a-z0-9+\/]+=*$/i;

export function sanitizeUrl(url: string): string {
    url = url?.trim();
    if (isSafeUrl(url)) {
        return url;
    }
    throw new Error(`Error: unsafe URL value: "${url}" (see http://g.co/ng/security#xss)`);
}

export function sanitizeSrcset(srcset: string): string {
    return srcset
        .split(',')
        .map(set => sanitizeUrl(set.trim()))
        .join(', ');
}

export function isSafeUrl(url: string): boolean {
    url = url.trim();
    if (!url || url === '#' || isBase64Image(url) || url.match(SAFE_URL_PATTERN)) {
        return true;
    }
    return false;
}
