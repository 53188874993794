<div
    class="panel__content"
    [ngClass]="{
        'showing-all': !!(isShowingAllElements$ | async),
        'showing-actions': !!(hasDirtyProperties$ | async)
    }">
    <div class="header">
        <panel-summary></panel-summary>
        <element-navigation *ngIf="(isShowingAllElements$ | async) === false"></element-navigation>
    </div>
    <div class="content ui-scrollbar">
        <ng-content select="[panelContent]"></ng-content>
    </div>
    <panel-actions></panel-actions>
</div>
