import { PreloadImageDto } from '@domain/api/generated/sapi';
import { IPreloadImage, PreloadImageFormat } from '@domain/creativeset/creative';

export function deserializePreloadImage(preloadImageDto: PreloadImageDto): IPreloadImage {
    return {
        frames: preloadImageDto.frames,
        format: preloadImageDto.format as PreloadImageFormat,
        quality: preloadImageDto.quality
    };
}
