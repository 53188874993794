import {
    ActionOperationMethod,
    ActionTrigger,
    IAction,
    MouseOrTouchEvent,
    ReservedActionId
} from '@domain/action';
import { OneOfElementDataNodes } from '@domain/nodes';
import { uuidv4 } from '@studio/utils/id';
import { DEFAULT_TIMING_FUNCTION } from '../timing-functions';

interface ICreateActionOptions {
    target?: string;
    stateId?: string;
    method?: ActionOperationMethod;
    triggers?: ActionTrigger[];
    templateId?: ReservedActionId;
    preventClickThrough?: boolean;
}

export function createAction(options: ICreateActionOptions = {}): IAction {
    if (!options.target) {
        if (options.templateId) {
            throw new Error('Actions with templateId requires a target.');
        }

        if (options.stateId) {
            throw new Error('Actions with state IDs requires a target.');
        }
    }

    if (options.triggers?.find(trigger => trigger === ActionTrigger.TouchEnd)) {
        options.triggers.push(ActionTrigger.TouchCancel);
    }

    const action: IAction = {
        id: uuidv4(),
        triggers: options.triggers?.length ? options.triggers : [ActionTrigger.MouseDown],
        operations: [
            {
                method: options.method || ActionOperationMethod.SetState,
                value:
                    options.method === ActionOperationMethod.OpenUrl
                        ? ''
                        : options.stateId || undefined,
                target: options.target || undefined,
                animation: DEFAULT_ACTION_ANIMATION
            }
        ],
        disabled: false,
        templateId: options.templateId,
        preventClickThrough: options.preventClickThrough
    };

    return action;
}

interface IActionEventListeners {
    ActionHandler: IActionHandler;
}

export interface IActionHandler {
    callback: (event: MouseOrTouchEvent, trigger: ActionTrigger, forceClearStates?: boolean) => void;
}

export function registerActionEventHandlers(actionHandler: IActionHandler): IActionEventListeners {
    const triggers: IActionEventListeners = {} as IActionEventListeners;
    for (const trigger in ActionTrigger) {
        triggers[ActionTrigger[trigger]] = (
            event: MouseOrTouchEvent,
            forceClearStates?: boolean
        ): void => actionHandler.callback(event, ActionTrigger[trigger], forceClearStates);
    }
    return triggers;
}

export function isReservedAction(action: IAction): boolean {
    return !!action.templateId;
}

export function hasActionPreventClickthrough(dataElement?: OneOfElementDataNodes): boolean {
    const actions = dataElement?.actions;
    if (!actions) {
        return false;
    }

    return actions.some(action => !action.disabled && action.preventClickThrough);
}

export function hasActionTargetUrl(dataElement?: OneOfElementDataNodes): boolean {
    const actions = dataElement?.actions;
    if (!actions) {
        return false;
    }

    return actions.some(
        action =>
            !action.disabled &&
            action.operations.some(op => op.method === ActionOperationMethod.OpenUrl && op.value)
    );
}

export function isCancelTrigger(trigger: ActionTrigger): boolean {
    return (
        trigger === ActionTrigger.MouseLeave ||
        trigger === ActionTrigger.TouchEnd ||
        trigger === ActionTrigger.TouchCancel
    );
}

export function isStateActionMethod(method: ActionOperationMethod): boolean {
    const stateMethods = [
        ActionOperationMethod.SetState,
        ActionOperationMethod.RemoveState,
        ActionOperationMethod.ClearStates
    ];

    return stateMethods.indexOf(method) > -1;
}

export const DEFAULT_ACTION_ANIMATION = {
    timingFunction: DEFAULT_TIMING_FUNCTION,
    duration: 0.2
};
