import { Injectable, OnDestroy } from '@angular/core';
import { Logger } from '@bannerflow/sentinel-logger';
import { MIN_ELEMENT_DURATION } from '@creative/animation.utils';
import { MAX_NESTED_GROUPS, getNestingLevel, getSelectionDepth } from '@creative/nodes';
import { positionIsInBounds } from '@creative/nodes/helpers';
import { ElementSelection } from '@creative/nodes/selection';
import { IAnimationKeyframe } from '@domain/animation';
import { IBounds, IPosition } from '@domain/dimension';
import { OneOfDataNodes, OneOfElementDataNodes } from '@domain/nodes';
import { isChildOfSelector } from '@studio/utils/dom-utils';
import { getBoundingCorners } from '@studio/utils/geom';
import { IMouseDownMove, IMouseValue } from '@studio/utils/mouse-observable';
import { isRightClick, polygonIsIntersecting, toFixedDecimal } from '@studio/utils/utils';
import { Subject, fromEvent, timer } from 'rxjs';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { HotkeyBetterService } from '../../../shared/services/hotkeys/hotkey.better.service';
import { ElementSelectionService } from '../services';
import { MutatorService } from '../services/mutator.service';
import { StudioTimelineComponent } from './studio-timeline/studio-timeline.component';
import { KeyframeAction, KeyframeService, TimelineElementComponent } from './timeline-element';
import { ScrollMode, TimelineScrollService } from './timeline-scroll.service';
import { MAX_TIMELINE_DURATION, TIMELINE_ELEMENT_HEIGHT, TIMERULER_HEIGHT } from './timeline.constants';

/**
 * "Hit area" size for mouse events (in each direction (+/-))
 */
const MOUSE_TOLERANCE = 5;
const MOVE_THRESHOLD = 5;

@Injectable()
export class TimelineTransformService implements OnDestroy {
    actionMode: ActionMode = ActionMode.None;
    durationLineActive = false;
    stopTimeMarkerActive = false;
    preloadFrameActive = false;
    resizeDirection: ResizeDirection | undefined;
    timeline: StudioTimelineComponent;
    preventClearingSelection = false;
    private _change$ = new Subject<OneOfElementDataNodes | void>();
    change$ = this._change$.asObservable();
    elementSelection?: ElementSelection;
    prevElementChangeYPostition?: number;

    private nodeStart: NodeStart;
    private mouseStart: IPosition;
    private playheadStart = 0;
    private stopTimeStart = 0;
    private preloadFrameStart = 0;
    private leftPanelWidthStart = 0;
    private unsubscribe$ = new Subject<void>();

    private logger = new Logger('TimelineTransformer');

    constructor(
        private keyframeService: KeyframeService,
        private scrollService: TimelineScrollService,
        private hotkeyService: HotkeyBetterService,
        private mutatorService: MutatorService,
        private elementSelectionService: ElementSelectionService
    ) {}

    onInit(): void {
        const mouseObservable = this.timeline.mouseObservable;
        mouseObservable.mouseDown$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(mouseValue => this.mouseDown(mouseValue));

        mouseObservable.mouseUp$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(mouseValue => this.mouseUp(mouseValue));

        mouseObservable.mouseMove$
            .pipe(
                filter(() => this.keyframeService.actionMode === KeyframeAction.None),
                filter(mouseValue => mouseValue.event.buttons === 0),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(mouseValue => this.mouseMove(mouseValue));

        mouseObservable.mouseDownMove$
            .pipe(
                filter(() => this.keyframeService.actionMode === KeyframeAction.None),
                tap(mouseValue => {
                    timer(0, 10)
                        .pipe(
                            map(() => mouseValue),
                            takeUntil(fromEvent<MouseEvent>(document, 'mouseup').pipe(take(1))),
                            takeUntil(fromEvent<MouseEvent>(document, 'mousemove').pipe(take(1)))
                        )
                        .subscribe(({ event, mousePosition }) => this.autoScroll(event, mousePosition));
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(mouseValue => this.mouseDownMove(mouseValue));

        this.change$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.drawGizmoLayers());

        this.hotkeyService.keyEvent$
            .pipe(
                filter(keyEvent => !!keyEvent.down && keyEvent.keys.some(key => key === 'Escape')),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => {
                this.onEscapeKeyDown();
            });

        this.elementSelectionService.change$.pipe(takeUntil(this.unsubscribe$)).subscribe(selection => {
            this.elementSelection = selection;
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    timelineChange(node?: OneOfElementDataNodes): void {
        this._change$.next(node);
    }

    private isPositionOnTimeruler(position: IPosition): boolean {
        return (
            position.y < TIMERULER_HEIGHT &&
            position.x > 0 &&
            position.x < this.timeline.host.nativeElement.offsetWidth - TIMERULER_HEIGHT
        );
    }

    private isPositionOnGifFrames(position: IPosition): boolean {
        return TIMERULER_HEIGHT < position.y && position.y < this.timeline.getTimelineHeaderHeight();
    }

    private isPositionOnLeftPanel(position: IPosition): boolean {
        return position.y > TIMERULER_HEIGHT && position.x < 0;
    }

    private isPositionOnPlaybackControls(position: IPosition): boolean {
        return (
            position.y < TIMERULER_HEIGHT && position.x > 0 && position.x < this.timeline.leftPanelWidth
        );
    }

    private isPositionOnLeftPanelResizeHandle(position: IPosition): boolean {
        return this.isWithinTolerance(position.x, 0);
    }

    private isPositionOnStopTime(position: IPosition): boolean {
        if (this.timeline.creative.loops === 0 || !this.isPositionOnTimeruler(position)) {
            return false;
        }

        const stopTime = this.timeline.creative.getStopTime_m();
        const relativePosition = this.getPositionRelativeToTimeline(position);

        return this.isWithinTolerance(relativePosition.x, this.timeline.secondsToPixels(stopTime));
    }

    private isPositionOnFirstGifFrameAtZero(position: IPosition): boolean {
        const timeline = this.timeline;
        const frame = timeline.creative.gifExport.frames[0];
        const framePosition = timeline.secondsToPixels(frame?.time || 0);
        const relativePosition = this.getPositionRelativeToTimeline(position);

        return (
            !!frame &&
            this.scrollService.scroll.x === 0 &&
            framePosition <= MOUSE_TOLERANCE &&
            this.isPositionOnGifFrames(position) &&
            this.isWithinTolerance(relativePosition.x, framePosition)
        );
    }

    private isPositionOnPreloadFrame(position: IPosition): boolean {
        if (!this.isPositionOnTimeruler(position)) {
            return false;
        }

        const framePosition = this.timeline.secondsToPixels(
            this.timeline.creative.getFirstPreloadImageFrame()
        );
        const relativePosition = this.getPositionRelativeToTimeline(position);

        return this.isWithinTolerance(relativePosition.x, framePosition);
    }

    private isPositionOnPlayhead(position: IPosition): boolean {
        const timeline = this.timeline;
        const relativePosition = this.getPositionRelativeToTimeline(position);

        return (
            this.isWithinTolerance(
                relativePosition.x,
                timeline.secondsToPixels(timeline.animator.time)
            ) && relativePosition.y < 0
        );
    }

    private isPositionOnDurationLine(position: IPosition): boolean {
        const timeline = this.timeline;
        const x = position.x + this.scrollService.scroll.x;
        const width = timeline.secondsToPixels(timeline.duration);

        return x >= width - 3 && x <= width + 15;
    }

    private mouseDown = (mouseValue: IMouseValue): void => {
        const { event, mousePosition } = mouseValue;
        const timeline = this.timeline;
        this.mouseStart = mousePosition;
        this.timeline.workspace.transform.forceStopEditText();

        if (
            timeline.editor.workspace.isZooming ||
            timeline.slider.mouseIsDown ||
            timeline.scrollService.isScrolling ||
            timeline.scrollService.scrollBarTrackHighlighted ||
            timeline.isZoomControlVisible
        ) {
            return;
        }

        if (
            this.isPositionOnDurationLine(mousePosition) &&
            !this.isPositionOnLeftPanelResizeHandle(mousePosition) &&
            !this.isPositionOnStopTime(mousePosition) &&
            !this.isPositionOnPreloadFrame(mousePosition) &&
            !this.isPositionOnPlayhead(mousePosition)
        ) {
            this.actionMode = ActionMode.ScaleTimeline;

            this.resizeDirection = ResizeDirection.Right;
            this.initializeDurationScaleOfSelection(/* all */ true);

            this.durationLineActive = true;
        } else {
            if (
                this.isPositionOnLeftPanelResizeHandle(mousePosition) &&
                !this.isPositionOnStopTime(mousePosition) &&
                !this.isPositionOnPreloadFrame(mousePosition) &&
                !this.isPositionOnPlayhead(mousePosition) &&
                !this.isPositionOnFirstGifFrameAtZero(mousePosition)
            ) {
                this.actionMode = ActionMode.ResizeLeftPanel;
                this.leftPanelWidthStart = timeline.leftPanelWidth;
            } else if (this.isPositionOnTimeruler(mousePosition)) {
                if (
                    !this.isPositionOnPlayhead(mousePosition) &&
                    this.isPositionOnStopTime(mousePosition)
                ) {
                    this.actionMode = ActionMode.SetStopTime;
                    this.stopTimeStart = timeline.creative.getStopTime_m();
                } else if (
                    !this.isPositionOnPlayhead(mousePosition) &&
                    this.isPositionOnPreloadFrame(mousePosition)
                ) {
                    this.actionMode = ActionMode.SetPreloadFrame;
                    this.preloadFrameStart = timeline.creative.getFirstPreloadImageFrame();
                }
                // This is kind of a hacky way to check if we're interacting with the time ruler here
                // we should really extract this code from the timeline-transformer service and just
                // add a mouse event on the time-ruler component instead.
                else if (
                    event.target &&
                    ((event.target as Element).localName === 'time-ruler' ||
                        (event.target as Element).classList.contains('head') ||
                        (event.target as Element).classList.contains('playhead'))
                ) {
                    this.actionMode = ActionMode.Seek;

                    if (!this.isPositionOnPlayhead(mousePosition)) {
                        timeline.animator.seek(timeline.scrolledPixelsToSeconds(mousePosition.x - 3));
                        timeline.workspace.designView.workspace.gizmoDrawer.draw();
                    }

                    timeline.indexSnapPoints();

                    this.playheadStart = timeline.animator.time;
                }
            }
        }

        this.drawGizmoLayers();
    };

    private mouseMove(mouseValue: IMouseValue): void {
        const mousePosition = mouseValue.mousePosition;

        const timeline = this.timeline;
        let cursor = '';

        if (this.actionMode !== ActionMode.None) {
            timeline.setCursor(cursor);
            return;
        }

        if (mousePosition.y < 0) {
            this.toggleDurationLine(false);
            return;
        }

        for (const component of this.timeline.timelineElementComponents) {
            if (component.isHovered || this.keyframeService.hoveredKeyframe$.value) {
                return;
            }
        }

        if (
            this.isPositionOnDurationLine(mousePosition) &&
            !this.isPositionOnPlayhead(mousePosition) &&
            !this.isPositionOnStopTime(mousePosition) &&
            !this.isPositionOnPreloadFrame(mousePosition) &&
            !this.isPositionOnFirstGifFrameAtZero(mousePosition) &&
            this.timeline.nodes.length > 0
        ) {
            cursor = 'resize-0';
            this.toggleDurationLine(true);
            this.preloadFrameActive = false;
            this.stopTimeMarkerActive = false;
            timeline.setCursor(cursor);
            return;
        }

        if (
            !this.isPositionOnPlayhead(mousePosition) &&
            !this.isPositionOnFirstGifFrameAtZero(mousePosition)
        ) {
            if (this.isPositionOnStopTime(mousePosition)) {
                this.stopTimeMarkerActive = true;
                this.preloadFrameActive = false;
                this.toggleDurationLine(false);
            } else if (this.isPositionOnPreloadFrame(mousePosition)) {
                this.preloadFrameActive = true;
                this.stopTimeMarkerActive = false;
                this.toggleDurationLine(false);
            } else if (this.isPositionOnLeftPanelResizeHandle(mousePosition)) {
                this.preloadFrameActive = false;
                this.stopTimeMarkerActive = false;
                cursor = 'resize-0';
                this.toggleDurationLine(false);
            } else {
                this.toggleDurationLine(false);
                this.stopTimeMarkerActive = false;
                this.preloadFrameActive = false;
            }
        } else {
            this.toggleDurationLine(false);
            this.stopTimeMarkerActive = false;
            this.preloadFrameActive = false;
        }

        timeline.setCursor(cursor);
    }

    private mouseDownMove(mouseValue: IMouseDownMove): void {
        const { event, mousePosition, mouseDelta, mouseDownEvent } = mouseValue;

        const timeline = this.timeline;

        let updateAllAnimations = false;

        if (this.scrollService.scrollMode === ScrollMode.None) {
            if (
                this.mouseStart.x > 0 &&
                this.actionMode === ActionMode.None &&
                this.mouseStart.y > TIMERULER_HEIGHT &&
                !this.isPositionOnGifFrames(mousePosition)
            ) {
                this.actionMode = ActionMode.Select;
            }

            if (this.actionMode === ActionMode.Select) {
                if (isRightClick(mouseDownEvent)) {
                    return;
                }
                this.selectFromSelectionNet(mousePosition, mouseDelta);
            }
        }

        if (this.actionMode === ActionMode.None) {
            return;
        }

        if (this.actionMode === ActionMode.Seek) {
            this.scrollService.disableDirections({ y: true });
            this.seek(event, mousePosition);
        }

        if (this.actionMode === ActionMode.ResizeLeftPanel) {
            if (mouseDelta) {
                timeline.leftPanelWidth = this.leftPanelWidthStart + mouseDelta.x;
                updateAllAnimations = true;
            }
        }

        if (this.actionMode === ActionMode.ScaleTimeline) {
            this.scaleByMouseDelta(mouseDelta, event);

            timeline.animator.render_m();
            updateAllAnimations = true;
        }

        if (this.actionMode === ActionMode.SetPreloadFrame) {
            const time = this.preloadFrameStart + timeline.pixelsToSeconds(mouseDelta.x);
            const snapPoint = timeline.getSnapPoints(time, 0);
            const preloadTime =
                this.shouldSnap(event) && snapPoint !== undefined ? snapPoint.time : time;
            this.mutatorService.setPreloadImageFrames([preloadTime]);
            timeline.setCursor();
        }

        if (this.actionMode === ActionMode.SetStopTime) {
            const time = this.stopTimeStart + timeline.pixelsToSeconds(mouseDelta.x);
            const snapPoint = timeline.getSnapPoints(time, 0);
            const stopTime = this.shouldSnap(event) && snapPoint !== undefined ? snapPoint.time : time;
            this.mutatorService.setCreativeStopTime(stopTime);
            timeline.setCursor();
        }

        if (updateAllAnimations) {
            this.timelineChange();
        }

        this.drawGizmoLayers();
    }

    private mouseUp = (mouseValue: IMouseValue): void => {
        const { event, mousePosition } = mouseValue;
        const eventTarget = event.target as HTMLElement;
        const timeline = this.timeline;
        let updateAnimations = false;
        let skipClearTimelineBars = false;

        this.scrollService.enableDirections();

        if (this.actionMode === ActionMode.Select) {
            this.resetSelectionNet();
            skipClearTimelineBars = true;
        }

        if (
            this.isPositionOnPlaybackControls(mousePosition) ||
            this.timeline.workspace.contextMenuOpen
        ) {
            this.actionMode = ActionMode.None;
            this.timeline.detectChanges();
            return;
        }

        if (this.actionMode === ActionMode.Seek) {
            this.playheadStart = timeline.animator.time;
            skipClearTimelineBars = true;
        }

        if (this.actionMode === ActionMode.ScaleTimeline) {
            this.durationLineActive = false;
            this.resizeDirection = undefined;
            updateAnimations = true;
        }

        this.actionMode = ActionMode.None;
        timeline.workspace.transform.indexElementPoints();
        timeline.indexSnapPoints();

        if (
            this.timeline.isRecordingKeyframes ||
            (eventTarget?.nodeName === 'INPUT' && isChildOfSelector(eventTarget, 'timeline-element'))
        ) {
            skipClearTimelineBars = true;
        }

        this.timeline.detectChanges();

        if (updateAnimations) {
            this.timelineChange();
        } else if (
            !skipClearTimelineBars &&
            !this.preventClearingSelection &&
            !this.isPositionOnLeftPanel(mousePosition)
        ) {
            this.keyframeService.clear();
            this.elementSelectionService.clearSelection();
        }

        this.preventClearingSelection = false;
    };

    private onEscapeKeyDown(): void {
        this.logger.verbose('onEscapeKeyDown');
        if (this.actionMode === ActionMode.Seek) {
            this.actionMode = ActionMode.None;
            this.timeline.animator.seek(this.playheadStart);
        }
        this.timeline.gizmoDrawer.draw();
    }

    autoScroll(event: MouseEvent, mousePosition: IPosition): void {
        if (this.scrollService.scrollMode !== ScrollMode.None) {
            return;
        }

        const timeline = this.timeline;
        const viewPort = timeline.scrollViewportSize;

        const leftEdge = timeline.timelineElementLeftOffset - timeline.leftPanelWidth;
        const rightEdge = viewPort.width - 10;
        const topEdge = timeline.top;
        const bottomEdge = timeline.height + timeline.top;
        const scrollAmount = 10;

        if (mousePosition.x < leftEdge) {
            this.scrollService.setScroll({
                x: timeline.scroll.x - scrollAmount
            });
        } else if (mousePosition.x > rightEdge) {
            this.scrollService.setScroll({
                x: timeline.scroll.x + scrollAmount
            });
        }

        if (event.clientY < topEdge) {
            this.scrollService.setScroll({
                y: timeline.scroll.y - scrollAmount
            });
        } else if (event.clientY > bottomEdge) {
            this.scrollService.setScroll({
                y: timeline.scroll.y + scrollAmount
            });
        }
    }

    scaleByMouseDelta(mouseDelta: IPosition, event: MouseEvent | KeyboardEvent): void {
        const timeline = this.timeline;
        const mutatorService = this.mutatorService;

        if (mouseDelta) {
            if (this.resizeDirection === ResizeDirection.Left) {
                const durationDelta = timeline.pixelsToSeconds(mouseDelta.x);
                const duration = this.nodeStart.duration - durationDelta;
                const time = this.nodeStart.time + durationDelta;
                const snapPoint = timeline.getSnapPoints(time, duration, { snapToEnd: false });
                const endTime = this.nodeStart.time + this.nodeStart.duration;
                const direction = event.altKey ? 'both' : 'left';
                mutatorService.setDurationOfSelection(
                    this.shouldSnap(event) && typeof snapPoint !== 'undefined'
                        ? endTime - snapPoint.time
                        : duration,
                    direction
                );
            } else {
                const durationDelta = timeline.pixelsToSeconds(mouseDelta.x);
                const direction = event.altKey ? 'both' : 'right';

                if (this.actionMode === ActionMode.ScaleTimeline) {
                    const firstElement = timeline.nodes
                        .map(e => e)
                        .sort((a, b) => (a.time < b.time ? -1 : 1))[0];
                    const delta = durationDelta * (1 - firstElement.time / timeline.creative.duration);
                    const duration = this.nodeStart.duration + delta;

                    if (MAX_TIMELINE_DURATION >= duration && MIN_ELEMENT_DURATION <= duration) {
                        mutatorService.setCreativeDuration(duration);
                    }
                } else {
                    const duration = this.nodeStart.duration + durationDelta;
                    const snapPoint = timeline.getSnapPoints(this.nodeStart.time, duration, {
                        snapToStart: false
                    });
                    const scaleDuration =
                        this.shouldSnap(event) && typeof snapPoint !== 'undefined'
                            ? snapPoint.time - this.nodeStart.time
                            : duration;

                    const endTime = this.nodeStart.time + duration;
                    if (endTime <= MAX_TIMELINE_DURATION && endTime >= MIN_ELEMENT_DURATION) {
                        mutatorService.setDurationOfSelection(scaleDuration, direction);
                    }
                }
            }
        }
    }

    moveElements(event: MouseEvent, mouseDelta: IPosition): boolean {
        const timeline = this.timeline;
        const mutatorService = this.mutatorService;
        const selection = this.elementSelection;

        if (!selection) {
            throw new Error('No selection found when moving elements');
        }

        if (!this.prevElementChangeYPostition) {
            return false;
        }

        // handle horizontal movement
        const selectionHasLockedElement =
            selection.asSortedArray().filter(e => e.locked === true).length > 0;
        // If not shift key is down, nor selection contains locked element, allow
        // horizontal movement of the element
        if (!event.shiftKey && !selectionHasLockedElement) {
            const time = Math.max(this.nodeStart.time + timeline.pixelsToSeconds(mouseDelta.x), 0);
            const snapPoint = timeline.getSnapPoints(time, selection.duration);

            const maxTimeToDrag = time + selection.duration;
            if (maxTimeToDrag <= MAX_TIMELINE_DURATION && maxTimeToDrag >= MIN_ELEMENT_DURATION) {
                mutatorService.moveAnimation(
                    this.shouldSnap(event) && typeof snapPoint !== 'undefined'
                        ? snapPoint.time + snapPoint.offset
                        : time
                );
            }
        }

        // handle vertical movement
        const pixelsSinceLastMove = Math.abs(event.pageY - this.prevElementChangeYPostition);
        const direction = event.pageY - this.prevElementChangeYPostition < 0 ? 'up' : 'down';

        if (Math.abs(pixelsSinceLastMove) < MOVE_THRESHOLD) {
            return false;
        }

        const nodeAtIndex = this.getElementByMouseEvent(event, direction);
        const selectionDepth = getSelectionDepth(this.elementSelectionService.currentSelection.nodes);
        const targetNestingLevel = nodeAtIndex ? getNestingLevel(nodeAtIndex.node) : 0;
        const isMaxGroupLevel = selectionDepth + targetNestingLevel > MAX_NESTED_GROUPS;

        if (isMaxGroupLevel) {
            return false;
        }

        const visibleTimelineElements = this.timeline.timelineElementComponents
            .toArray()
            .filter(el => !el.collapsed);
        const nodeWasMoved = mutatorService.setIndexFromTimelineIndex(
            selection,
            nodeAtIndex,
            direction,
            visibleTimelineElements
        );

        if (nodeWasMoved) {
            this.prevElementChangeYPostition = event.pageY;
        }

        return nodeWasMoved;
    }

    private getElementByMouseEvent(
        event: MouseEvent,
        direction: 'up' | 'down'
    ): TimelineElementComponent | undefined {
        const elementComponents: TimelineElementComponent[] = [
            ...this.timeline.timelineElementComponents
        ];
        const timelinesWithoutCollapsedContent = elementComponents.filter(comp => !comp.collapsed);
        const spaces: Array<{
            topEdge: number;
            bottomEdge: number;
            element?: TimelineElementComponent;
        }> = [];
        let offset = TIMELINE_ELEMENT_HEIGHT / 2;
        if (direction === 'up') {
            offset = -offset;
        }

        timelinesWithoutCollapsedContent.forEach((e, i) => {
            let top: number;
            if (i === 0) {
                top = 0;
            } else {
                top = spaces[0].bottomEdge;
            }

            const bottom = e.rect.top + TIMELINE_ELEMENT_HEIGHT;

            const space = {
                topEdge: top,
                bottomEdge: bottom,
                element: e
            };

            // Note that component order and view element order is the opposite
            // so make sure spaces are added in reverse order for them to match
            spaces.unshift(space);
            if (i === timelinesWithoutCollapsedContent.length - 1) {
                spaces.unshift({
                    topEdge: bottom,
                    bottomEdge: bottom + TIMELINE_ELEMENT_HEIGHT * 2
                });
            }
        });

        return spaces.find(
            e => e.topEdge + offset <= event.pageY && e.bottomEdge + offset >= event.pageY
        )?.element;
    }

    private resetSelectionNet(): void {
        const selection = this.elementSelectionService.currentSelection;
        this.timeline.selectionNet = undefined;
        if (selection && selection.length > 1) {
            this.elementSelectionService.latestSelectionType = 'element';
        } else if (this.keyframeService.keyframes.size > 0) {
            this.elementSelectionService.latestSelectionType = 'keyframe';
        }

        this.timeline.gizmoDrawer.draw();
    }

    private selectFromSelectionNet(mousePosition: IPosition, delta: IPosition): void {
        if (this.timeline.isRecordingKeyframes) {
            return;
        }
        const timeline = this.timeline;
        const x = Math.min(this.mouseStart.x, this.mouseStart.x + delta.x);
        const y = Math.min(this.mouseStart.y, this.mouseStart.y + delta.y);
        const width = Math.abs(delta.x);
        const height = Math.abs(delta.y);
        timeline.selectionNet = { x, y, width, height };
        const elementsInSelection: OneOfDataNodes[] = [];
        let selectedKeyframes: IAnimationKeyframe[] = [];

        timeline.timelineElementComponents.toArray().forEach(elementComponent => {
            if (this.elementInSelectionNet(elementComponent, mousePosition)) {
                elementsInSelection.push(elementComponent.node);
            }
        });

        if (elementsInSelection.length === 0) {
            timeline.timelineElementComponents.toArray().forEach(elementComponent => {
                if (elementComponent.expanded) {
                    const keyframes = this.findKeyframesInSelectionNetForElement(elementComponent);
                    if (keyframes.length) {
                        selectedKeyframes = selectedKeyframes.concat(keyframes);
                        elementsInSelection.push(elementComponent.node);
                    }
                }
            });
        } else {
            timeline.timelineElementComponents.toArray().forEach(elementComponent => {
                const element = elementComponent.node;
                if (elementComponent.expanded && !elementsInSelection.includes(element)) {
                    const keyframes = this.findKeyframesInSelectionNetForElement(elementComponent);
                    if (keyframes.length) {
                        elementsInSelection.push(element);
                    }
                }
            });
        }

        this.elementSelectionService.setSelection(...elementsInSelection);
        this.updateKeyframeSelection(selectedKeyframes);
    }

    private updateKeyframeSelection(selectedKeyframes: Array<IAnimationKeyframe>): void {
        this.keyframeService.keyframes.forEach(kf => {
            if (!selectedKeyframes.includes(kf)) {
                this.keyframeService.remove(kf);
            } else {
                selectedKeyframes.splice(selectedKeyframes.indexOf(kf), 1);
            }
        });
        if (selectedKeyframes.length > 0) {
            this.keyframeService.add(...selectedKeyframes);
            this.elementSelectionService.latestSelectionType = 'keyframe';
        }
    }

    /**
     * Find keyframes in an element if they are within the selection net
     * @param elementComponent
     * @returns Array of keyframes.
     */
    private findKeyframesInSelectionNetForElement(
        elementComponent: TimelineElementComponent
    ): Array<IAnimationKeyframe> {
        const topBorder = this.timeline.canvas.nativeElement.getBoundingClientRect().top;
        const selectionNet = this.timeline.selectionNet;
        const selectedKeyframes: Array<IAnimationKeyframe> = [];
        if (selectionNet) {
            elementComponent.animationComponents.toArray().forEach(animationComponent => {
                animationComponent.animation.keyframes.forEach(kf => {
                    // TODO: fix magic number (relates to magic numbers in animation.component)
                    const selectionSensitivity = 10;
                    const x =
                        this.timeline.secondsToScrolledPixels(elementComponent.node.time + kf.time) -
                        selectionSensitivity / 2;
                    const y =
                        animationComponent.rect.top -
                        topBorder +
                        animationComponent.rect.height / 2 -
                        selectionSensitivity / 2;
                    const elementCorners = getBoundingCorners({
                        x: x,
                        y: y,
                        width:
                            this.timeline.secondsToScrolledPixels(kf.duration) + selectionSensitivity,
                        height: selectionSensitivity
                    });
                    const selectionNetCorners = getBoundingCorners(selectionNet);
                    const intersecting = polygonIsIntersecting(
                        [
                            elementCorners.topLeft,
                            elementCorners.topRight,
                            elementCorners.bottomRight,
                            elementCorners.bottomLeft
                        ],
                        [
                            selectionNetCorners.topLeft,
                            selectionNetCorners.topRight,
                            selectionNetCorners.bottomRight,
                            selectionNetCorners.bottomLeft
                        ]
                    );
                    const keyframeIndex = selectedKeyframes.indexOf(kf);
                    if (intersecting) {
                        if (!(keyframeIndex > -1)) {
                            selectedKeyframes.push(kf);
                        }
                    } else {
                        if (keyframeIndex > -1) {
                            selectedKeyframes.splice(keyframeIndex, 1);
                        }
                    }
                });
            });
        }
        return selectedKeyframes;
    }

    /**
     * Detect if an element is within the selection net
     * @param elementComponent
     */
    private elementInSelectionNet(
        elementComponent: TimelineElementComponent,
        mousePosition: IPosition
    ): boolean {
        const topBorder = this.timeline.canvas.nativeElement.getBoundingClientRect().top;
        let selectionNet = this.timeline.selectionNet;
        if (this.timeline.isRecordingKeyframes) {
            const x = mousePosition.x - this.timeline.leftPanelWidth;
            const y = mousePosition.y;
            selectionNet = { x, y, width: 1, height: 1 };
        }
        let intersecting = false;
        if (selectionNet) {
            const elementCorners = getBoundingCorners({
                x: this.timeline.secondsToScrolledPixels(elementComponent.node.time),
                y: elementComponent.rect.top - topBorder,
                width: this.timeline.secondsToPixels(elementComponent.node.duration),
                height: TIMELINE_ELEMENT_HEIGHT
            });
            const selectionNetCorners = getBoundingCorners(selectionNet);
            intersecting = polygonIsIntersecting(
                [
                    elementCorners.topLeft,
                    elementCorners.topRight,
                    elementCorners.bottomRight,
                    elementCorners.bottomLeft
                ],
                [
                    selectionNetCorners.topLeft,
                    selectionNetCorners.topRight,
                    selectionNetCorners.bottomRight,
                    selectionNetCorners.bottomLeft
                ]
            );
        }
        return intersecting;
    }

    toggleDurationLine(toggle: boolean): void {
        if (toggle && !this.durationLineActive) {
            this.durationLineActive = toggle;
            this.drawGizmoLayers();
        } else if (!toggle && this.durationLineActive) {
            this.durationLineActive = toggle;
            this.drawGizmoLayers();
        }
    }

    inTimelineBounds(event: MouseEvent, mousePosition: IPosition): boolean {
        if (mousePosition.x < 0 || mousePosition.y < 0) {
            return false;
        }

        const rect = this.timeline.host.nativeElement.getBoundingClientRect();

        const bounds: IBounds = {
            x: rect.x,
            y: event.pageY - rect.y,
            width: rect.width,
            height: rect.height
        };

        return positionIsInBounds(mousePosition, bounds);
    }

    initializeAnimationMove(): void {
        this.timeline.indexSnapPoints();
        const selection = this.elementSelection;
        if (!selection) {
            throw new Error('No selection found when initializing animation move');
        }

        this.nodeStart = {
            time: selection.time,
            duration: selection.duration,
            index: this.mutatorService.renderer.creativeDocument.elements.indexOf(selection.element!)
        };

        this.mutatorService.moveAnimationStart(selection);
    }

    initializeDurationScaleOfSelection(scaleAll?: boolean): void {
        let selection = this.elementSelectionService.currentSelection;
        this.timeline.indexSnapPoints();
        if (!selection) {
            throw new Error('No selection found when initializing duration scale of selection');
        }

        const elements = this.mutatorService.renderer.creativeDocument.elements;

        if (scaleAll) {
            selection = new ElementSelection(elements);
        }

        this.nodeStart = {
            time: selection.time,
            duration: selection.duration,
            index: elements.indexOf(selection.element!)
        };

        this.mutatorService.scaleElementDurationStart(selection);
    }

    private seek(event: MouseEvent, mousePosition: IPosition): void {
        const timeline = this.timeline;
        const scrolledTime = toFixedDecimal(timeline.scrolledPixelsToSeconds(mousePosition.x - 3), 2);
        const snapPoint = timeline.getSnapPoints(scrolledTime, 0, { ignorePlayhead: true });
        const seekTo =
            this.shouldSnap(event) && typeof snapPoint !== 'undefined' ? snapPoint.time : scrolledTime;
        timeline.animator.seek(seekTo);
        this.drawGizmoLayers();
        timeline.editor.workspace.gizmoDrawer.draw();
    }

    shouldSnap(event: MouseEvent | KeyboardEvent): boolean {
        return !event.metaKey && !event.ctrlKey;
    }

    /**
     * Translate document relative position to timeline relative
     */
    getPositionRelativeToTimeline(position: IPosition): IPosition {
        const { x: scrollX, y: scrollY } = this.scrollService.scroll;
        return {
            x: position.x + scrollX,
            y: position.y - this.timeline.getTimelineHeaderHeight() + scrollY
        };
    }

    private drawGizmoLayers(): void {
        this.timeline.gizmoDrawer?.draw();
    }

    private isWithinTolerance(p1: number, p2: number, tolerance: number = MOUSE_TOLERANCE): boolean {
        return Math.abs(p1 - p2) <= tolerance;
    }
}

export enum ActionMode {
    None,
    MoveElement,
    ScaleElement,
    ScaleTimeline,
    ScaleTransition,
    SetStopTime,
    Seek,
    SetPreloadFrame,
    ResizeLeftPanel,
    Select,
    MoveGifFrame
}

export const enum ResizeDirection {
    Left,
    Right
}

interface NodeStart {
    duration: number;
    time: number;
    index: number;
}
