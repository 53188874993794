import { IEventEmitter } from '../events';
import { ILineColumnPosition } from '../rich-text';
import { UserPermission } from '../user';

export type HotkeyCommandList = {
    [key in OneOfHotkeyContext]: { [command in HotkeyCommands]: string };
};

export interface IContextualCommand {
    context: OneOfHotkeyContext | OneOfHotkeyContext[];
    name: HotkeyCommands;
    hotkey: IHotkey;
}

export type KeyCombinationWithContext = string;

export type OneOfHotkeyInput = Window | HTMLInputElement | HTMLTextAreaElement;

export interface IHotkeyContext {
    name: string;
    input: EventTarget;

    /**
     * Dont propogate(bubble down) keys through the context stack. _(event.stopPropagation())_
     *
     * We want to be able to press the key 'Z'. When we are in 'TextEditMode'.
     * Without this flag, it will propogate and fire a zoom in (ZoomIn command).
     * If we want to prevent the key from propogating from one context to another
     * we will just add the key on the exclusion list.
     */
    keyPropogationExclusions?: string[];

    /**
     * Don't use the default behaviour of the key/combination. _(event.preventDefault())_
     *
     * Needed when we don't want to use the browser's default behaviour, e.g
     * CTRL/CMD + S
     */
    keyDefaultBehaviourExclusions?: string[];
}

export interface IHotkeyStoredContext extends IHotkeyContext {
    id: number;
    __compositionStartHandler: (event: Event) => void;
    __compositionEndHandler: (event: Event) => void;

    __keyDownHandler: (event: Event) => void;
    __keyUpHandler: (event: Event) => void;
    __blurHandler: (event: Event) => void;
}

export type HotkeyEvents = { [key in HotkeyCommands]: unknown } & {
    SelectTextToCurrentClick: ILineColumnPosition;
    ZoomIn: PointerEvent | undefined;
    ZoomOut: PointerEvent | undefined;
    ZoomWheel: WheelEvent;
    ZoomTimeline: WheelEvent;
    PlayPause: MouseEvent;
    RecordAnimation: MouseEvent | undefined;
    Save: boolean;
    Undo: Event;
};

export interface IHotkeyBetterService extends IEventEmitter<HotkeyEvents> {
    registerHotkeys: () => void;
    // willApplyCommand: (event: KeyboardEvent) => boolean;
    pushContext: (context: IHotkeyContext) => void;
    popContext: (contextAssertion: string) => void;
    emitKey: (key: string, input: OneOfHotkeyInput, ...args: unknown[]) => boolean;
    registerKey: (event: string, ...args: unknown[]) => boolean;
    unregisterEvent: (event: string) => void;

    forward(event: string, target: string): () => void;
}

export type OneOfHotkeyCategory =
    | 'Design view'
    | 'Edit text'
    | 'Edit elements'
    | 'Timeline'
    | 'Toolbar'
    | 'Manage view'
    | 'Translation Page'
    | 'Move keyframe'
    | 'Edit keyframes'
    | 'Save'
    | 'Save and Exit';

export type OneOfHotkeyContext =
    | 'ManagePage'
    | 'TranslationPage'
    | 'Showcase'
    | 'Workspace'
    | 'Editor'
    | 'TextEditMode'
    | 'TextElementSelected'
    | 'BrandLibrary'
    | 'EditElement'
    | 'WidgetEditor'
    | 'Timeline';

export type Key =
    | 'A'
    | 'B'
    | 'C'
    | 'D'
    | 'E'
    | 'F'
    | 'G'
    | 'H'
    | 'I'
    | 'J'
    | 'K'
    | 'L'
    | 'M'
    | 'N'
    | 'O'
    | 'P'
    | 'Q'
    | 'R'
    | 'S'
    | 'T'
    | 'U'
    | 'V'
    | 'W'
    | 'X'
    | 'Y'
    | 'Z'
    | '0'
    | '1'
    | '2'
    | '3'
    | '4'
    | '5'
    | '6'
    | '7'
    | '8'
    | '9'
    | '§'
    | 'Plus'
    | 'Minus'
    | 'Spacebar'
    | 'ArrowUp'
    | 'ArrowRight'
    | 'ArrowDown'
    | 'ArrowLeft'
    | 'Home'
    | 'End'
    | 'Delete'
    | 'Backspace'
    | 'Tab'
    | 'Escape';

export type HotkeyCommands =
    | 'NextField'
    | 'PreviousField'
    | 'SaveFieldValue'
    | 'UnfocusField'
    | 'OpenContentPanel'
    | 'OpenStylingPanel'
    | 'LeftAlignedPanel'
    | 'RightAlignedPanel'
    | 'PreviousElement'
    | 'NextElement'
    | 'ToggleAllElements'
    | 'ToggleOtherSizes'
    | 'ExitTranslationPage'
    | 'SelectTextToNextWord'
    | 'OpenCreativeInNewTab'
    | 'SelectTextToPreviousWord'
    | 'SelectTextToStartOfText'
    | 'SelectTextToEndOfText'
    | 'MoveCaretToStartOfText'
    | 'MoveCaretToEndOfText'
    | 'MoveCaretToStartOfParagraph'
    | 'MoveCaretToEndOfParagraph'
    | 'SelectTextToStartOfParagraph'
    | 'SelectTextToEndOfParagraph'
    | 'SelectTextToStartOfLine'
    | 'SelectTextToEndOfLine'
    | 'MoveCaretToPreviousWord'
    | 'MoveCaretToNextWord'
    | 'ToggleBoldTextStyle'
    | 'ToggleItalicTextStyle'
    | 'ToggleUnderlineTextStyle'
    | 'MoveCaretToStartOfLine'
    | 'MoveCaretToEndOfLine'
    | 'MoveCaretForward'
    | 'MoveCaretBackward'
    | 'MoveCaretUpward'
    | 'MoveCaretDownward'
    | 'SelectTextForward'
    | 'SelectTextBackward'
    | 'SelectTextUpward'
    | 'SelectTextDownward'
    | 'SelectTextToCurrentClick'
    | 'SelectAllText'
    | 'EraseTextForward'
    | 'EraseTextBackward'
    | 'EraseWord'
    | 'EraseToStartOfLine'
    | 'Escape'
    | 'UndoTextChange'
    | 'RedoTextChange'
    | 'CopySelectedText'
    | 'CutSelectedText'
    | 'PasteSelectedText'
    | 'Save'
    | 'SaveAndExit'
    | 'SaveOnTextEditMode'
    | 'SaveAndExitOnTextEditMode'
    | 'Undo'
    | 'Redo'
    | 'Deselect'
    | 'Copy'
    | 'Paste'
    | 'PasteStyle'
    | 'PasteLayout'
    | 'PasteAnimation'
    | 'PasteDesign'
    | 'Cut'
    | 'SelectMultiple'
    | 'SelectAll'
    | 'AlignLeft'
    | 'AlignRight'
    | 'AlignCenter'
    | 'AlignTop'
    | 'AlignBottom'
    | 'AlignMiddle'
    | 'DistributeHorizontally'
    | 'DistributeVertically'
    | 'OpenBrandLibrary'
    | 'OpenImageLibrary'
    | 'OpenVideoLibrary'
    | 'OpenWidgetLibrary'
    | 'OpenFeedsLibrary'
    | 'OpenEffectsLibrary'
    | 'FindInLibrary'
    | 'ToggleMask'
    | 'SaveWidget'
    | 'EnableZoom'
    | 'EnableZoomOut'
    | 'DisableZoom'
    | 'ZoomIn'
    | 'ZoomOut'
    | 'ZoomWheel'
    | 'ZoomPreview'
    | 'ZoomPreviewEnd'
    | 'ZoomToBox'
    | 'ZoomReset'
    | 'PanOn'
    | 'PanOff'
    | 'CreateTextElement'
    | 'CreateButtonElement'
    | 'CreateRectangleElement'
    | 'CreateEllipseElement'
    | 'GroupElements'
    | 'UngroupElements'
    | 'ToggleSelectionTool'
    | 'ToggleLock'
    | 'ToggleVisibility'
    | 'MoveElementUp'
    | 'MoveElementDown'
    | 'MoveElementFront'
    | 'MoveElementBack'
    | 'DeleteElement'
    | 'DeleteKeyframe'
    | 'NudgeLeft'
    | 'NudgeRight'
    | 'NudgeUp'
    | 'NudgeDown'
    | 'MoveLeft'
    | 'MoveRight'
    | 'MoveUp'
    | 'MoveDown'
    | 'HideOtherSizes'
    | 'DeleteSize'
    | 'ApplyFilter'
    | 'ZoomTimeline'
    | 'PlayPause'
    | 'RecordAnimation'
    | 'Clone'
    | 'NavigateToTP';

export type Modifier = 'Cmd' | 'Ctrl' | 'Alt' | 'Shift';
export type ModifierCombination<OSMod extends Modifier> =
    | `${Extract<Modifier, OSMod>}`
    | `${Extract<Modifier, 'Shift'>}`
    | `${Extract<Modifier, 'Alt'>}`
    | `${Extract<Modifier, OSMod>} + ${Extract<Modifier, 'Shift'>}`
    | `${Extract<Modifier, OSMod>} + ${Extract<Modifier, 'Alt'>}`
    | `${Extract<Modifier, 'Alt'>} + ${Extract<Modifier, 'Shift'>}`
    | `${Extract<Modifier, OSMod>} + ${Extract<Modifier, 'Alt'>} + ${Extract<Modifier, 'Shift'>}`;

export type ModifierCombinationWindows = ModifierCombination<'Ctrl'>;
export type ModifierCombinationMac = ModifierCombination<'Cmd'>;

export type Mouse = 'Wheel' | 'Click' | 'drag';
export type OneOfKeyType = '(KeyUp)' | '(DoublePress)' | '(Delayed)';
export type KeyWithWithType = `${Key}${OneOfKeyType}`;

export type KeyWithModifierWindows = `${ModifierCombinationWindows} + ${Key | KeyWithWithType}`;
export type KeyWithModifierMac = `${ModifierCombinationMac} + ${Key | KeyWithWithType}`;
export type MouseWithModifierWindows = `${ModifierCombinationWindows} + ${Mouse}`;
export type MouseWithModifierMac = `${ModifierCombinationMac} + ${Mouse}`;
export type SpecialCombination = 'Alt + Z + Click' | 'Z + Click';

export interface IOsSpecificKeyCombination {
    win:
        | Key
        | KeyWithWithType
        | KeyWithModifierWindows
        | MouseWithModifierWindows
        | SpecialCombination
        | 'None';
    mac:
        | Key
        | KeyWithWithType
        | KeyWithModifierMac
        | MouseWithModifierMac
        | SpecialCombination
        | 'None';
}

export type KeyCombination =
    | IOsSpecificKeyCombination
    | IOsSpecificKeyCombination[keyof IOsSpecificKeyCombination];

export interface IOsSpecificKeyTextCombination {
    win?: string;
    mac?: string;
}

export interface IHotkeyMeta {
    description: string | IOsSpecificKeyTextCombination;
    shortcut: string | IOsSpecificKeyTextCombination;
}

export interface IHotkey {
    keyCombination: KeyCombination;
    command: HotkeyCommands;
    context: OneOfHotkeyContext | OneOfHotkeyContext[];
    category?: OneOfHotkeyCategory | OneOfHotkeyCategory[];
    shouldBeEnabledForEmployeeOnly?: boolean;
    permission?: UserPermission;

    /**
     * @summary
     * Specify whether the current command should be returned immediately. Or let the hotkey service
     * continue searching for matching commands.
     *
     * @default
     * true
     */
    returnCommandImmediately?: boolean;
    returnUntilKeyup?: boolean;
    meta?: IHotkeyMeta;
}

export interface IKeyCodeMap {
    [key: string]: string;
}
