import { CommonModule } from '@angular/common';
import { Component, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { StudioUISliderComponent } from '@app/shared/components/studio-ui-slider/studio-ui-slider.component';
import { UIModule } from '@bannerflow/ui';
import { GenAIService } from '../../state/gen-ai.service';

@Component({
    standalone: true,
    imports: [UIModule, StudioUISliderComponent, CommonModule],
    selector: 'erase-options',
    templateUrl: './erase-options.component.html',
    styleUrls: ['./erase-options.component.scss']
})
export class EraseOptionsComponent {
    private genAiService = inject(GenAIService);

    brushSizeSignal = toSignal(this.genAiService.eraseBrushSize$, { initialValue: 40 });
    isRequestingErase = toSignal(this.genAiService.isRequestingErase$, { initialValue: false });
    private brushHistory = toSignal(this.genAiService.eraseBrushHistory$);
    private hasMask = computed(() => !!this.brushHistory()?.length);
    actionsDisabled = computed(() => this.isRequestingErase() || !this.hasMask());

    setEraseBrushSize(brushSize: number): void {
        this.genAiService.setEraseBrushSize(brushSize);
    }

    erase(): void {
        this.genAiService.erase();
    }
}
