import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { IVersion } from '@domain/creativeset/version';
import { SectionSelectedState } from '@studio/domain/components/studio-list-section';
import { StudioListSectionComponent } from '../../../../shared/components/section/studio-list-section/studio-list-section.component';
import { VersionFlagComponent } from '../../../../shared/components/version-picker/version-flag/version-flag.component';

@Component({
    standalone: true,
    imports: [CommonModule, UIModule, StudioListSectionComponent, VersionFlagComponent],
    selector: 'version-list',
    templateUrl: './version-list.component.html',
    styleUrls: ['./version-list.component.scss']
})
export class VersionListComponent {
    @Input() versions: ISelectableVersion[];
    @Output() versionsChanged = new EventEmitter<IVersion[]>();

    selectedState = signal(SectionSelectedState.Unselected);
    selectedVersions = 0;

    toggleVersion(version: ISelectableVersion): void {
        version.selected = !version.selected;
        this.checkSelectedState();
        this.emitSelectedVersions();
    }

    toggleAllVersions(state: boolean): void {
        this.versions.forEach(version => (version.selected = state));
        this.checkSelectedState();
        this.emitSelectedVersions();
    }

    private checkSelectedState(): void {
        this.selectedVersions = this.versions.filter(version => version.selected).length;

        switch (this.selectedVersions) {
            case 0:
                this.updateSelectedState(SectionSelectedState.Unselected);
                break;
            case this.versions.length:
                this.updateSelectedState(SectionSelectedState.AllSelected);
                break;
            default:
                this.updateSelectedState(SectionSelectedState.SomeSelected);
                break;
        }
    }

    private updateSelectedState(state: SectionSelectedState): void {
        this.selectedState.set(state);
    }

    private emitSelectedVersions(): void {
        this.versionsChanged.emit(this.versions.filter(version => version.selected));
    }
}

export interface ISelectableVersion extends IVersion {
    selected?: boolean;
}
