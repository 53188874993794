export enum CreativeMode {
    AnimatedCreative,
    ManageView,
    DesignView,
    ImageGenerator,
    VideoGenerator,
    TranslationPanel
}

export interface ICreativeEnvironment {
    STUDIO_JS?: boolean; // rename to IN_STUDIO, scoped out for now
    IN_TEST?: boolean;
    MODE: CreativeMode;
    FONTSERVICE_API_ORIGIN: string;
    IMAGE_OPTIMIZER_ORIGIN: string;
    FEEDS_STORAGE_ORIGIN: string;
    VIDEOS_STORAGE_ORIGIN: string;
    FONTS_AZURE_STORAGE_CDN_ORIGIN?: string;
    CUSTOM_AD_DOMAIN?: string;
}
