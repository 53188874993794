<div
    class="wrapper"
    [ngClass]="{ 'full-screen': isFullScreen }">
    @if (cultureCode) {
        <ui-flag
            [culture]="cultureCode"
            [size]="(flagSize$ | async)!"></ui-flag>
    }

    @if (showName) {
        <span
            class="select-label"
            truncateSpan
            [spanText]="name!">
        </span>
    }
    @if (showVersionName && versionId) {
        <span
            class="select-label"
            truncateSpan
            [spanText]="(versionId | versionFromId | async)?.name!"
            [ngClass]="{ small: sizeSelectLabel === 'small' }">
        </span>
    }
</div>
