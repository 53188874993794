import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ColorType } from '@domain/color';
import { ColorPaletteComponent } from '../../color-picker/color-palette.component';

@Component({
    selector: 'color-header',
    templateUrl: './color-header.component.html',
    styleUrls: ['./color-header.component.scss']
})
export class ColorHeaderComponent {
    ColorType = ColorType;

    @Input() type?: ColorType;
    @Input() palettes: ColorPaletteComponent;
    @Input() allowGradient = false;

    @Output() typeChange: EventEmitter<ColorType> = new EventEmitter<ColorType>();

    // FIX FOR SAFARI
    linearGradientLocation = `url('${location.href}#linearGradient-1')`;

    setColorType(type: ColorType): void {
        this.typeChange.emit(type);
    }
}
