import { isBase64 } from './utils';

/**
 * Regular btoa does not support non ASCII characters (outside latin1).
 */
export function b64EncodeUnicode(str: string): string {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(
        encodeURIComponent(str).replace(
            /%([0-9A-F]{2})/g,
            function toSolidBytes(match: string, p1: string): string {
                return String.fromCharCode(parseInt(`0x${p1}`, 16));
            }
        )
    );
}

/**
 * Regular atob does not support non ASCII characters (outside latin1).
 */
export function b64DecodeUnicode(str?: string): string {
    if (!str) {
        return '';
    }

    if (!isBase64(str)) {
        return str;
    }

    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(
        atob(str)
            .split('')
            .map(function (c: string): string {
                return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
            })
            .join('')
    );
}

export function uint8ArrayToBase64(data: Uint8Array): string {
    let binaryString = '';
    for (let i = 0; i < data.length; i++) {
        binaryString += String.fromCharCode(data[i]);
    }

    return btoa(binaryString);
}
