import { Component, Injector } from '@angular/core';
import { PinterestPlacement } from '@domain/social';
import { BaseOverlayComponent } from '../base-overlay.component';

@Component({
    selector: 'pinterest-overlay',
    templateUrl: './pinterest.component.html',
    styleUrls: ['./pinterest.component.scss', '../shared.scss']
})
export class PinterestComponent extends BaseOverlayComponent {
    PinterestPlacement = PinterestPlacement;

    constructor(injector: Injector) {
        super(injector);
    }
}
