export function cloneDeep<T>(value: T): T {
    return cloneDeepHelper(value, new Map()) as T;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function cloneDeepHelper(val: any, seen: Map<unknown, unknown>): unknown {
    switch (Object.prototype.toString.call(val)) {
        case '[object Array]': {
            if (seen.has(val)) {
                return seen.get(val);
            }
            const copy1 = val.slice(0);
            seen.set(val, copy1);
            copy1.forEach((child: unknown, i: string | number) => {
                copy1[i] = cloneDeepHelper(child, seen);
            });
            return copy1;
        }
        case '[object Object]': {
            if (seen.has(val)) {
                return seen.get(val);
            }
            const copy2 = Object.create(Object.getPrototypeOf(val));
            seen.set(val, copy2);
            Object.keys(val).forEach(key => {
                if (key !== '__widget') {
                    copy2[key] = cloneDeepHelper(val[key], seen);
                }
            });
            return copy2;
        }
        default:
            return val;
    }
}

export function cloneMapDeep<M extends Map<any, any> | undefined>(map?: M): M {
    if (!map) {
        return map as M;
    }
    const clonedMap = new Map<any, any>() as M;
    for (const [key, value] of map.entries()) {
        clonedMap!.set(key, cloneDeep(value));
    }
    return clonedMap;
}

export function simpleClone<T>(value: T): T {
    return JSON.parse(JSON.stringify(value));
}
