import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { UIConfirmDialogService, UIModule, UINotificationService } from '@bannerflow/ui';
import { EventLoggerService } from '@studio/monitoring/events/event-logger';
import { TranslationPanelDoneEvent } from '@studio/monitoring/events/events';
import { Observable } from 'rxjs';
import { CreativeSetShowcaseService } from '../../creativeset-showcase/state/creativeset-showcase.service';
import { CreativesetDataService } from '../../creativeset/creativeset.data.service';
import { PermissionsDirective } from '../../directives/permissions.directive';
import { VersionsService } from '../../versions/state/versions.service';

@Component({
    selector: 'mark-done-button',
    standalone: true,
    imports: [UIModule, CommonModule, PermissionsDirective],
    templateUrl: './mark-done-button.component.html',
    styleUrls: ['./mark-done-button.component.scss']
})
export class MarkDoneButtonComponent {
    showcaseMarkedDone = false;
    translationSaved$: Observable<boolean>;

    constructor(
        private activetedRoute: ActivatedRoute,
        private eventLoggerService: EventLoggerService,
        private uiConfirmDialogService: UIConfirmDialogService,
        private uiNotificationService: UINotificationService,
        private creativesetShowcaseService: CreativeSetShowcaseService,
        private versionsService: VersionsService,
        private creativesetDataService: CreativesetDataService
    ) {
        this.translationSaved$ = this.versionsService.translationSaved$;

        this.creativesetShowcaseService.translationCompleted$
            .pipe(takeUntilDestroyed())
            .subscribe(() => {
                this.showcaseMarkedDone = true;
                this.uiNotificationService.open(
                    'Translation marked as done. You will be redirected away from this page shortly.',
                    {
                        autoCloseDelay: 5000,
                        placement: 'top',
                        type: 'success'
                    }
                );
                setTimeout(() => (location.href = 'https://bannerflow.com'), 5000);
            });
    }

    async completeTranslation(): Promise<void> {
        const res = await this.uiConfirmDialogService.confirm({
            headerText: 'MARK DONE',
            text: 'The user that created your task will be notified that it has been completed. You will no longer be able to access this view.',
            cancelText: 'CANCEL',
            confirmText: 'MARK DONE'
        });

        if (res === 'confirm') {
            const creativesetId = this.creativesetDataService.creativeset.id;
            const shareId = this.getShareId();

            this.eventLoggerService.log(new TranslationPanelDoneEvent());
            this.creativesetShowcaseService.completeTranslation(creativesetId, shareId);
        }
    }

    private getShareId(): string {
        // TPage in showcase
        // For some reason, this.activetedRoute doesn't have the paramenter
        if (this.activetedRoute.parent?.parent) {
            return this.activetedRoute.parent.parent.snapshot.params['shareId'] ?? '';
        }

        return this.activetedRoute?.snapshot?.params?.['shareId'] ?? '';
    }
}
