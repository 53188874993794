import { Pipe, PipeTransform } from '@angular/core';
import { GroupedElements } from '@domain/creativeset/version';

@Pipe({ standalone: true, name: 'sortgroup' })
export class SortGroupPipe implements PipeTransform {
    transform(groups: GroupedElements[] | null): GroupedElements[] {
        if (!groups) {
            return [];
        }
        return groups.sort((g1, g2) => {
            if (g1.elements.length > g2.elements.length) {
                return -1;
            }
            if (g2.elements.length > g1.elements.length) {
                return 1;
            }
            return 0;
        });
    }
}
