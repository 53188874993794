import { createAction, props } from '@ngrx/store';
import {
    ICLVList,
    ICLVListItem,
    IDuplicationResponse
} from '@studio/domain/api/duplicate-creatives.types';
import { Sort } from '@studio/domain/components/duplicate-creatives/duplicate-creatives.types';

export const loadDuplicateCreativesPage = createAction(
    '[DuplicateCreative] Load loadDuplicateCreativesPage',
    props<{ folderId?: string; pageIndex: number; sort?: Sort }>()
);

export const loadDuplicateCreativesPageSuccess = createAction(
    '[DuplicateCreative] Load DuplicateCreatives Success',
    props<{ data: ICLVList; pageIndex: number }>()
);

export const loadDuplicateCreativesPageFailure = createAction(
    '[DuplicateCreative] Load DuplicateCreatives Failure',
    props<{ error: unknown }>()
);

export const searchDuplicateCreatives = createAction(
    '[DuplicateCreative] Load searchDuplicateCreatives',
    props<{ pageIndex: number; searchTerm?: string; sort?: Sort }>()
);

export const searchDuplicateCreativesSuccess = createAction(
    '[DuplicateCreative] Load searchDuplicateCreatives Success',
    props<{ data: ICLVList; pageIndex?: number; searchTerm?: string }>()
);

export const searchDuplicateCreativesFailure = createAction(
    '[DuplicateCreative] Load searchDuplicateCreatives Failure',
    props<{ error: unknown }>()
);

export const clearSearchDuplicateCreatives = createAction(
    '[DuplicateCreative] Clear searchDuplicateCreatives term'
);

export const createDuplicationToNewB2 = createAction(
    '[DuplicateCreative] Create DuplicationToNewB2',
    props<{
        creativeset: string;
        sourceSizeIds: string[];
        sourceVersionIds: string[];
        targetFolderId?: string;
    }>()
);

export const createDuplicationToNew = createAction(
    '[DuplicateCreative] Create DuplicationToNew',
    props<{
        creativesetId: string;
        sourceSizeIds: string[];
        sourceVersionIds: string[];
        targetFolderId: string;
        actionCorrelationId: string;
    }>()
);

export const createDuplicationToNewSuccess = createAction(
    '[DuplicateCreative] Create DuplicationToNew Success',
    props<{ data: IDuplicationResponse }>()
);

export const createDuplicationToNewFailure = createAction(
    '[DuplicateCreative] Create DuplicationToNew Failure',
    props<{ error: unknown }>()
);

export const createDuplicationToExisting = createAction(
    '[DuplicateCreative] Create DuplicationToExisting',
    props<{
        creativeset: string;
        sourceSizeIds: string[];
        sourceVersionIds: string[];
        targetCreativesetId: string;
    }>()
);

export const createDuplicationToExistingSuccess = createAction(
    '[DuplicateCreative] Create DuplicationToExisting Success',
    props<{ data: IDuplicationResponse }>()
);

export const createDuplicationToExistingFailure = createAction(
    '[DuplicateCreative] Create DuplicationToExisting Failure',
    props<{ error: unknown }>()
);

export const clearDuplicationState = createAction('[DuplicateCreative] Clear Duplication State');

export const createNewFolder = createAction(
    '[DuplicateCreative] Create New Folder',
    props<{ brandId: string; name: string; targetFolderId?: string }>()
);

export const createNewFolderSuccess = createAction(
    '[DuplicateCreative] Create Folder Success',
    props<{ newFolder: ICLVListItem }>()
);

export const createNewFolderFailure = createAction(
    '[DuplicateCreative] Create Folder Failure',
    props<{ error: unknown }>()
);
