@let selectedElement = element();
@let selectedAsset = imageAsset();
@let selectedSize = imageSize();

<ui-header
    [full]="true"
    [showLogo]="false">
    <div class="edit-element-topbar">
        <div class="edit-header">
            <div class="logo">
                <ui-logo [small]="true"></ui-logo>
                <p>AI Studio</p>
            </div>
            @if (isImageLibraryAsset(selectedAsset) && selectedElement) {
                <div>
                    <ui-svg-icon
                        icon="kebab"
                        class="more-menu"
                        [uiDropdownTarget]="moreMenu" />
                    <ui-dropdown #moreMenu>
                        <ui-dropdown-item
                            id="edit-element-delete"
                            (click)="deleteElement()">
                            Delete
                        </ui-dropdown-item>
                    </ui-dropdown>
                </div>
            }
        </div>

        <div class="media-asset-details">
            @if (selectedSize) {
                <div class="size">{{ selectedSize.width }}x{{ selectedSize.height }}</div>

                <div class="right">
                    @if (selectedElement) {
                        <div
                            id="edit-element-media-download"
                            class="name"
                            (click)="downloadImage()">
                            {{ selectedElement.name }}
                        </div>
                    }

                    <div>
                        @if (isImageLibraryAsset(selectedAsset)) {
                            {{ selectedAsset.fileSize | uiFormatBytes }}
                        } @else {
                            N/A
                        }
                    </div>
                </div>
            }
        </div>
    </div>
</ui-header>
