import { TimingFunctionKey } from '@domain/timing-functions';
import { clamp, lerp } from '@studio/utils/utils';
import { getTimingFunctionByKey } from './timing-functions';

type NumberObject = { [prop: string]: number };
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyObject = { [prop: string]: any };

export interface IAnimateToSettings {
    duration?: number;
    callback?: (animator?: AnimatorLite) => void;
    timingFunction?: TimingFunctionKey;
    customFPSInterval?: number;
}

/** Used to animate elements if the creative is paused */
export class AnimatorLite {
    private _speed = 1;

    private _requestId?: number;
    private _lastTimestamp: number;

    private _fromValue: NumberObject;
    private _toValue: NumberObject;

    private _customFPSinterval?: number; // Interval in ms for the desired fps from ad tag parameter
    private _lastDrawTime = 0;

    private _time = 0;
    private _duration = 0.5;
    private _timingFunction = getTimingFunctionByKey('linear');
    private _onTick?: (animator?: AnimatorLite) => void;

    private _paused = true;
    private _onCompletedCallbacks: (() => void)[] = [];
    get isPlaying(): boolean {
        return !this._paused;
    }

    constructor(public target: AnyObject) {}

    static to(
        target: AnyObject,
        to: number | NumberObject,
        settings?: IAnimateToSettings
    ): AnimatorLite {
        return new AnimatorLite(target).to(to, settings);
    }

    to(to: number | NumberObject, settings?: IAnimateToSettings): AnimatorLite {
        return this.fromTo(this.target, to, settings);
    }

    from(from: number | NumberObject, settings?: IAnimateToSettings): AnimatorLite {
        return this.fromTo(from, this.target, settings);
    }

    fromTo(
        from: number | NumberObject,
        to: number | NumberObject,
        settings?: IAnimateToSettings
    ): AnimatorLite {
        this._applySettings(settings);

        this._fromValue = this._filterAnimatableProperties(from);
        this._toValue = this._filterAnimatableProperties(to);

        return this.restart().play();
    }

    play(): AnimatorLite {
        if (this._paused === true) {
            this._paused = false;
            this._requestId = requestAnimationFrame(this._tick);
        }

        return this;
    }

    pause(): void {
        this._paused = true;
        this._lastTimestamp = 0;
        this._cancelAnimation();
    }

    restart(): AnimatorLite {
        this._time = 0;
        return this;
    }

    revert(): AnimatorLite {
        this._speed *= -1;
        return this.play();
    }

    private _applySettings(settings: IAnimateToSettings = {}): void {
        if (settings.customFPSInterval !== undefined) {
            this._customFPSinterval = settings.customFPSInterval;
        }
        if (settings.duration !== undefined) {
            this._duration = settings.duration;
        }
        if (settings.timingFunction !== undefined) {
            this._timingFunction = getTimingFunctionByKey(settings.timingFunction);
        }
        this._onTick = settings.callback;
    }

    private _updateTarget(time: number): boolean {
        const progress = this._getCompletionRate(time);
        const timing = this._getTimingValue(progress);

        if (typeof this._toValue === 'object') {
            Object.keys(this._toValue).forEach(key => {
                const from = this._fromValue[key];
                const to = this._toValue[key];

                if (typeof from !== 'number') {
                    throw new Error(`Cannot animate property ${key}. Must be of type number`);
                }
                this.target[key] = lerp(from, to, timing);
            });
        }

        if (this._onTick) {
            this._onTick(this);
        }

        const isCompleted = this._speed < 0 ? progress <= 0 : progress >= 1;

        if (isCompleted) {
            for (const callback of this._onCompletedCallbacks) {
                callback();
            }
        }

        // Return true if animation is completed
        return isCompleted;
    }

    onCompleted = (callback: () => void): void => {
        this._onCompletedCallbacks.push(callback);
    };

    private _getTimingValue(progress: number): number {
        return this._timingFunction(progress);
    }

    /**
     * Get a value between 0 and 1 on how much progress on time have been
     * @param time
     */
    private _getCompletionRate(time: number): number {
        return this._duration > 0 ? clamp(time / this._duration, 0, 1) : 1;
    }

    private _cancelAnimation(): void {
        if (this._requestId) {
            cancelAnimationFrame(this._requestId);
            this._requestId = undefined;
        }
    }

    private _tick = (t: number): void => {
        if (this._paused) {
            return;
        }

        // throttle to desired fps
        if (this._customFPSinterval && t - this._lastDrawTime < this._customFPSinterval) {
            this._requestId = requestAnimationFrame(this._tick);
            return;
        }

        this._lastDrawTime = t;

        if (!this._lastTimestamp) {
            this._lastTimestamp = t;
        }

        const timestampDiff = (t - this._lastTimestamp) / 1000;
        this._time += timestampDiff * this._speed;

        this._lastTimestamp = t;

        if (!this._updateTarget(this._time)) {
            this._requestId = requestAnimationFrame(this._tick);
        } else {
            this.pause();
        }
    };

    private _filterAnimatableProperties(obj: number | NumberObject): NumberObject {
        const result: NumberObject = {};
        if (typeof obj === 'number') {
            result.value = obj;
        }
        if (typeof obj === 'object') {
            Object.keys(obj).forEach(key => {
                const value = obj[key];
                if (typeof value === 'number') {
                    result[key] = value;
                }
            });
        }
        return result;
    }
}
