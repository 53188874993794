import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { fromWidthResize } from '@studio/utils/resize-observable';
import { Subject, takeUntil } from 'rxjs';
import { TruncationService } from '../services/truncation.service';

const ELLIPSES = '...';

@Component({
    selector: '[truncateSpan]',
    template: `<span #span></span>`,
    styles: [
        `
            :host {
                white-space: nowrap;
            }
        `
    ],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TruncateSpanComponent implements AfterViewInit, OnChanges, OnInit, OnDestroy {
    @ViewChild('span', { static: true }) private spanElement: ElementRef;

    @Input() spanText = '';
    @Input() truncateOn?: Subject<void>;

    @Input() disableTruncate = false;

    @HostListener('window:resize')
    resizeTruncate(): void {
        this.truncate();
    }

    private unsubscribe$ = new Subject<void>();

    constructor(
        private host: ElementRef,
        private truncationService: TruncationService
    ) {}

    ngOnInit(): void {
        this.truncateOn?.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.truncate());

        const hostParentElement = (this.host.nativeElement as HTMLElement)?.parentElement;
        if (!hostParentElement) {
            return;
        }
        fromWidthResize(hostParentElement)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => this.truncate());
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    ngAfterViewInit(): void {
        if (!this.truncateOn) {
            this.truncate();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.spanText) {
            if (changes.spanText.currentValue !== changes.spanText.previousValue) {
                this.truncate();
            }
        }
    }

    private truncate(): void {
        if (this.disableTruncate) {
            this.spanElement.nativeElement.textContent = this.spanText.trim();
            return;
        }
        if (!this.spanElement) {
            return;
        }

        if (!this.spanText) {
            this.spanElement.nativeElement.textContent = '';
            return;
        }
        this.truncateText();
    }

    private truncateText(): void {
        this.spanElement.nativeElement.textContent = this.spanText!.trim();

        const hostElement = this.host.nativeElement as HTMLElement;
        const hostWidth = this.truncationService.getWidth(hostElement);
        const textElement = this.spanElement.nativeElement as HTMLElement;
        let truncTextLength = textElement.textContent!.length;
        const textWidth = this.truncationService.getWidth(textElement);

        const separatorLength = ELLIPSES.length;

        const pixelsPerCharact = textWidth / truncTextLength;
        const charactersThatFit = Math.ceil(hostWidth / pixelsPerCharact);
        const startCharacter = Math.ceil((charactersThatFit - separatorLength) / 2);

        if (hostWidth <= 0 || charactersThatFit >= truncTextLength) {
            return;
        }

        // While overflowing, cut letters from middle
        textElement.textContent = this.truncationService.truncateTextByPadding(
            textElement.textContent!,
            startCharacter,
            startCharacter,
            ELLIPSES
        );
        truncTextLength = textElement.textContent.length;
        while (this.truncationService.getWidth(textElement) > hostWidth && truncTextLength > 3) {
            textElement.textContent = this.truncationService.truncate(
                textElement.textContent!,
                truncTextLength
            );
            truncTextLength--;
        }
    }
}
