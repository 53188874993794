import { Component, inject } from '@angular/core';
import { UIDialogComponent, UIModule } from '@bannerflow/ui';

@Component({
    selector: 'save-and-replace',
    templateUrl: `./save-and-replace.component.html`,
    styleUrl: './save-and-replace.component.scss',
    standalone: true,
    imports: [UIModule]
})
export class SaveAndReplaceComponent {
    private dialog = inject(UIDialogComponent);

    callback: (replaceInAllDesigns: boolean) => void;

    replaceInAllDesigns = false;

    constructor() {
        this.callback = this.dialog.config.data.callback;
    }

    backToAIStudio(): void {
        this.dialog.close();
    }

    saveAndReplace(): void {
        this.callback(this.replaceInAllDesigns);
        this.dialog.close();
    }
}
