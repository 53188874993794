import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { VersionsEffects } from './versions.effects';
import { EnvironmentProviders, importProvidersFrom } from '@angular/core';
import { VERSIONS_FEATURE_KEY, reducer } from './versions.reducer';

export function provideVersionsStore(): EnvironmentProviders {
    return importProvidersFrom(
        StoreModule.forFeature(VERSIONS_FEATURE_KEY, reducer),
        EffectsModule.forFeature(VersionsEffects)
    );
}
