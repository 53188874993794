<div
    #creativePreviewWrapper
    class="section creative-preview ui-scrollbar">
    @for (size of sizes; track size.id) {
        <div
            class="creative-wrapper"
            [ngStyle]="{
                width: size.width * (zoomValue / 100) + 'px',
                height: size.height * (zoomValue / 100) + 'px'
            }">
            <div class="creative-size">{{ size.width }} × {{ size.height }}</div>
            @if (version$ | async; as version) {
                <div class="creative-container">
                    <studio-creative
                        [version]="version"
                        [size]="size"
                        [renderOnInit]="true" />
                </div>
            }
        </div>
    }
    <size-add-tooltip />
</div>
<ui-dialog-buttons align="right">
    <ui-button
        id="cancel-button"
        text="Cancel"
        (click)="cancel()" />
    <ui-button
        id="add-sizes-button"
        text="Add sizes ({{ sizes.length }})"
        type="primary"
        [submit]="onAddSizes"
        [done]="onAddSizesFinished"
        [disabled]="!sizes.length" />
</ui-dialog-buttons>
