import { getDomain } from '@studio/utils/url';

/**
 * Decodes a URL string multiple times until it no longer changes.
 * This ensures that multiple encoded redirect URLs are fully decoded.
 * @param encodedURI - the URI to decode
 */
export function fullyDecodeUrl(encodedURI: string): string {
    let decodedURI: string;

    try {
        decodedURI = decodeURIComponent(encodedURI);
    } catch {
        return encodedURI;
    }

    if (decodedURI === encodedURI) {
        return encodedURI;
    }

    return fullyDecodeUrl(decodedURI);
}

/**
 * Encodes invalid URI characters in a URL, according to https://www.ietf.org/rfc/rfc3986.txt
 * @param url
 */
export function encodeInvalidURICharacters(url: string): string {
    return url.replace(/[\s<>#%{}|\\^~[\]`]/g, encodeURIComponent);
}

/**
 * Turn a url to a wildcard domain patter.
 * https://bannerflow.com -> *.bannerflow.*
 * @param targetUrl
 */
export function getWildcardDomain(targetUrl: string): string | undefined {
    if (targetUrl) {
        const domain = getDomain(targetUrl);
        if (domain) {
            const parts = domain.split('.');
            if (parts.length > 1) {
                const main = parts[parts.length - 2];
                return `*.${main}.*`;
            }
        }
    }
}

/**
 * Get regex based on domain pattern.
 * Sample inputs "*.domain.top", "domain.*" or "domain.top"
 */
export function getUrlRegex(url = ''): RegExp {
    const parts = url.split('.');

    if (parts.length === 1) {
        parts.unshift('*');
        parts.push('*');
    }

    if (parts.length < 2 || parts.length > 3) {
        throw new Error(
            `Can not parse: "${url}"., url needs to contain 1 or 2 dots. Sample "*.domain.top" or "domain.*"`
        );
    }

    let regexStr = '^';
    parts.forEach((part, index) => {
        const trailingDot = index !== parts.length - 1 ? '\\.' : '';
        if (part === '*') {
            regexStr += `(\\.?[\\w-]+${trailingDot})?`;
        } else {
            regexStr += `(${part})${trailingDot}`;
        }
    });
    regexStr += '$';

    return new RegExp(regexStr);
}
