import { IAd } from '@domain/ad/ad';
import { IVideoRenderer } from '@domain/creative/elements/video/video-renderer.header';
import { CreativeMode, ICreativeEnvironment } from '@domain/creative/environment';
import { IVideoElementDataNode, IVideoViewElement, OneOfElementDataNodes } from '@domain/nodes';
import { IVideoRendererSettings, VideoSettingPropertyType, VideoSizeMode } from '@domain/video';
import { cloneDeep } from '@studio/utils/clone';
import { __inject, inject } from '@studio/utils/di';
import { getVideoErrorMessage, VideoError } from '@studio/utils/errors';
import { ErrorMessage } from '@studio/utils/errors/error-message.enum';
import { isBannerflow, isBase64Mp4Video, isRelativeUrl, replaceOrigin } from '@studio/utils/url';
import { deepEqual } from '@studio/utils/utils';
import { isValidUrl } from '@studio/utils/validation';
import { Animator } from '../../animator';
import { T } from '../../creative.container';
import { IRenderer } from '../../renderer.header';
import { StreamManager } from './video-streamer/stream-manager';

/** @@remove STUDIO:START */
import { createSVGLoaderImage } from '../../svg-background';
const SVG_NAMESPACE = 'http://www.w3.org/2000/svg';
/** @@remove STUDIO:END */

export class VideoRenderer implements IVideoRenderer {
    private _settings: IVideoRendererSettings;
    private _dataElement: IVideoElementDataNode;
    private _loaderElement: SVGElement;
    private _shadowRoot: ShadowRoot;
    private _videoDOMElement: HTMLVideoElement;
    private _videoPlaybackButtons: HTMLDivElement;
    private _requestId: number;
    private _destroyed = false;
    private _userInitiatedPlaybackState: 'play' | 'pause' | undefined;
    private reachedTrackingPoints = new Set<25 | 50 | 75 | 95>();
    private _streamManager?: StreamManager;

    private get _isPlaying(): boolean {
        return !this._videoDOMElement.paused;
    }

    private get _startTime(): number {
        let animatorTime = this._animator.time;
        if (this._env.MODE === CreativeMode.ImageGenerator) {
            animatorTime = this._renderer.creativeDocument.startTime ?? 0;
        }
        return Math.max(animatorTime - this._viewElement.time, 0) + this._settings.startTime;
    }

    private get _endTime(): number | undefined {
        if (!this._settings.endTime) {
            return undefined;
        }

        return Math.min(this._viewElement.time, this.duration_m) + this._settings.endTime;
    }

    get duration_m(): number {
        return this._videoDOMElement.duration;
    }

    get videoDOMElement(): HTMLVideoElement {
        return this._videoDOMElement;
    }

    constructor(
        @inject(T.AD) public _ad: IAd,
        @inject(T.RENDERER) private _renderer: IRenderer,
        @inject(T.ANIMATOR) private _animator: Animator,
        @inject(T.ENVIRONMENT) private _env: ICreativeEnvironment,
        private _viewElement: IVideoViewElement
    ) {
        this._dataElement = _viewElement.__data;

        this._settings = {
            ...this._dataElement.videoSettings,
            url: this.getVideoUrl()
        };

        if (!this._animator) {
            throw new Error('Animator not provided.');
        }

        this._animator.on('play', this.onAnimatorPlay);
        this._animator.on('pause', this.pause);
        this._animator.on('stop', this.stop);
        this._animator.on('loop', this.onAnimationLoop);
        this._animator.on('animation_start', this.onAnimationStart);
        this._animator.on('animation_end', this.onAnimationEnd);
        this._animator.on('seek', this.onAnimatorSeek);
        this._renderer.on('mute', this.onRendererMute);
        this.init();
    }

    private async init(): Promise<void> {
        await this.createVideoDOMElement();
        this.updateVideo(true);
    }

    private async createVideoDOMElement(): Promise<void> {
        const videoRenderer = document.createElement('video-renderer');
        this._shadowRoot = videoRenderer.attachShadow({ mode: 'open' });
        videoRenderer.style.width = '100%';
        videoRenderer.style.height = '100%';
        videoRenderer.style.display = 'block';

        const video = document.createElement('video');
        video.preload = 'auto';
        this.setMuted(video);
        video.setAttribute('playsinline', '');
        video.style.pointerEvents = 'none';

        this._videoDOMElement = video;

        this._setStartTime();

        this._preparePreloading();

        this._setVideoStyles();

        try {
            await this._initVideoSource();
        } catch (e: unknown) {
            const error = e as Error;
            throw new VideoError(`${ErrorMessage.VideoSourceInitFailed}: ${error.message}`);
        }

        this._shadowRoot.appendChild(this._videoDOMElement);
        const foreignObject = this._viewElement.__rootElement?.querySelector('foreignObject');
        foreignObject?.firstElementChild?.appendChild(videoRenderer);
        this._createPlaybackButtons();
    }

    private _setVideoStyles(): void {
        const style = document.createElement('style');
        style.textContent = `
            video {
                width: 100%;
                height: 100%;
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
                object-position: center center;
                pointer-events: none;
                left: 50%;
                top: 50%;
                position: absolute;
                transform: translate(-50%, -50%);
            }

            video.cropMode {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .playbackButton {
                transform: scale(var(--playbackButtonSize));
            }

            .play, .pause {
                width: 100%;
                height: 100%;
                position: absolute;
                fill: var(--playbackButtonColor);
                top: 0;
                left: 0;
            }

            .play {
                display: var(--playbackButtonPlayState)
            }

            .pause {
                display: var(--playbackButtonPauseState)
            }
        `;

        this._shadowRoot.appendChild(style);
    }

    private _createPlaybackButtons(): void {
        this._videoPlaybackButtons = document.createElement('div');
        this._videoPlaybackButtons.className = 'playbackButton';
        this._videoPlaybackButtons.style.position = 'absolute';
        this._videoPlaybackButtons.style.display = 'none';
        this._videoPlaybackButtons.style.top = '0px';
        this._videoPlaybackButtons.style.left = '0px';
        this._videoPlaybackButtons.style.width = '100%';
        this._videoPlaybackButtons.style.height = '100%';
        this._videoPlaybackButtons.style.objectFit = 'cover';
        this._videoPlaybackButtons.style.objectPosition = 'center center';
        this._videoPlaybackButtons.addEventListener('click', this._onPlaybackButtonClick);

        this._videoPlaybackButtons.innerHTML = playbackButtonHtml;
        this._shadowRoot.appendChild(this._videoPlaybackButtons);
    }

    private _onPlaybackButtonClick = (event: MouseEvent): void => {
        if (this._renderer.isEditorMode) {
            return;
        }

        event.stopPropagation();
        event.preventDefault();

        if (!this._isPlaying) {
            this._userInitiatedPlaybackState = 'play';
            this.play();
        } else {
            this._userInitiatedPlaybackState = 'pause';
            this.pause();
        }
    };

    private _preparePreloading(): void {
        let videoPromise: { resolve: () => void; reject: (error: Error) => void };
        const videoLoadPromise = new Promise<void>((resolve, reject) => {
            videoPromise = {
                resolve,
                reject
            };
        });

        this._renderer.preloadingElements.set(this._dataElement.id, videoLoadPromise);

        const onVideoCanPlaythrough = (): void => {
            videoPromise.resolve();
            this._videoDOMElement.removeEventListener('canplaythrough', onVideoCanPlaythrough);
        };
        this._videoDOMElement.addEventListener('canplaythrough', onVideoCanPlaythrough);

        const onVideoError = (): void => {
            const videoError = new VideoError(getVideoErrorMessage(this._videoDOMElement.error?.code), {
                src: this._videoDOMElement.src,
                code: this._videoDOMElement.error?.code
            });
            videoPromise.reject(videoError);
            this._videoDOMElement.removeEventListener('error', onVideoError);
            this.destroy();
        };
        this._videoDOMElement.addEventListener('error', onVideoError);
        this._videoDOMElement.addEventListener('ended', this.onVideoEnd);
    }

    updateVideo(initialRender?: boolean): void {
        /** @@remove STUDIO:START */
        this._setVideoLoader();
        /** @@remove STUDIO:END */

        if ((this._isPlaying || this._animator.isPlaying) && !initialRender) {
            return;
        }

        for (const videoSetting in this._settings) {
            const setting = videoSetting as keyof IVideoRendererSettings;

            if (
                !initialRender &&
                deepEqual(this._settings[setting], this._dataElement.videoSettings[setting])
            ) {
                continue;
            }

            const settingValue = this.getElementSettingValue(setting);
            this._settings[setting as string] = cloneDeep(settingValue);

            /**
             * The autoplay attribute always has to be unset as it instead
             * has to be controlled from a timeline standpoint through
             * the animator events
             **/
            if (setting === 'autoplay') {
                continue;
            }

            switch (setting) {
                case 'startTime':
                case 'endTime':
                    this._setStartTime();
                    break;
                case 'url':
                    this._setVideoSrc();
                    break;
                case 'sizeMode':
                    this.setSizeMode();
                    break;
                case 'playbackButton':
                    this.setPlaybackButton();
                    break;
                default:
                    this._videoDOMElement[setting] = settingValue;
                    break;
            }
        }

        if (this._startTime !== this._videoDOMElement.currentTime) {
            this._setStartTime();
        }
    }

    private setMuted(video: HTMLVideoElement): void {
        video.muted =
            this._env.MODE !== CreativeMode.VideoGenerator &&
            this._env.MODE !== CreativeMode.DesignView;
    }

    private setPlaybackButton(): void {
        const { color, size, enabled } = this._settings.playbackButton;
        const style = this._videoPlaybackButtons.style;

        style.display = enabled || size === 0 ? 'block' : 'none';
        style.setProperty(PlaybackButtonVariable.Color, color.toString());
        style.setProperty(PlaybackButtonVariable.Size, String(size / 100));
        this.setPlaybackButtonState();
    }

    private setPlaybackButtonState(): void {
        const style = this._videoPlaybackButtons.style;
        const playButtonVisible = this._isPlaying ? 'none' : 'block';
        const pauseButtonVisible = !this._isPlaying ? 'none' : 'block';

        style.setProperty(PlaybackButtonVariable.PlayState, playButtonVisible);
        style.setProperty(PlaybackButtonVariable.PauseState, pauseButtonVisible);
    }

    private getVideoUrl(): string {
        if (this._dataElement.feed) {
            const feedValue = this._renderer.feedStore?.getFeedValueUrl(
                this._dataElement.feed,
                this._dataElement.id,
                this._dataElement
            );
            return (feedValue ?? '').trim();
        }

        const customOrigin = this._env.CUSTOM_AD_DOMAIN;
        if (customOrigin && this._dataElement.videoAsset) {
            return replaceOrigin(this._dataElement.videoAsset.url, customOrigin).trim();
        }

        return this._dataElement.videoAsset!.url.trim();
    }

    private getElementSettingValue(
        setting: keyof IVideoRendererSettings
    ): VideoSettingPropertyType | string {
        if (setting === 'url') {
            return this.getVideoUrl();
        }

        return this._dataElement.videoSettings[setting];
    }

    private setSizeMode(): void {
        const mode = this._dataElement.videoSettings.sizeMode;

        switch (mode) {
            case VideoSizeMode.Fit:
                this._videoDOMElement.classList.remove('cropMode');
                break;
            case VideoSizeMode.Crop:
                this._videoDOMElement.classList.add('cropMode');
                break;
        }
    }

    private async _initVideoSource(): Promise<void> {
        const shouldStream =
            !isBannerflow(window.location.href) && this._dataElement.videoSettings.streaming.enabled;
        if (shouldStream) {
            try {
                await this._setVideoStream();
            } catch {
                delete this._streamManager;
                console.warn(`Failed to stream video, falling back to: ${this.getVideoUrl()}`);
                this._setVideoSrc();
            }
        } else {
            this._setVideoSrc();
        }
    }

    private async _setVideoStream(): Promise<void> {
        this._streamManager = new StreamManager(this._videoDOMElement, this._settings.url);
        this._videoDOMElement.src = await this._streamManager.loadStreams();
    }

    private _setVideoSrc(): void {
        const url = this.getVideoUrl();

        if (this._streamManager || this._videoDOMElement.src === url || !this._isSrcValid(url)) {
            return;
        }

        this._videoDOMElement.src = url;
        this._videoDOMElement.load();
    }

    private _isSrcValid(url: string): boolean {
        if (!url) {
            return false;
        }
        if (isRelativeUrl(url)) {
            return true;
        }
        const { pathname, hostname, href: src } = new URL(url);
        const isStorageEmulatorUrl = hostname === 'storage-emulator';
        const isBase64VideoUrl = isBase64Mp4Video(pathname);

        return !(isStorageEmulatorUrl && isValidUrl(src) && isBase64VideoUrl);
    }

    private onAnimatorPlay = (): void => {
        this._userInitiatedPlaybackState = undefined;

        if (!this._settings.autoplay) {
            return;
        }

        const animatorTime = this._animator?.time || 0;
        if (animatorTime > this._viewElement.time) {
            this.play();
        }
    };

    private onAnimationStart = (element: OneOfElementDataNodes): void => {
        if (this._dataElement.id !== element.id) {
            return;
        }

        if (!this._settings.autoplay) {
            if (!this._userInitiatedPlaybackState || this._userInitiatedPlaybackState === 'pause') {
                return;
            }
        }

        if (!this._settings.loop && this._settings.restartWithCreative) {
            this.loop();
            this.play();
        } else if (this._settings.loop) {
            this.play();
        }
    };

    private onAnimationLoop = (): void => {
        if (this._settings.restartWithCreative) {
            this.loop();
        }
        if (this._dataElement.feed) {
            this._setVideoSrc();
            this.play();
        }
    };

    private onAnimationEnd = (element: OneOfElementDataNodes): void => {
        if (this._dataElement.id !== element.id) {
            return;
        }

        this.pause();
    };

    private onAnimatorSeek = (): void => {
        if (!this._videoDOMElement) {
            return;
        }
        this._setStartTime();
    };

    private onRendererMute = (muted: boolean): void => {
        if (!this._videoDOMElement) {
            return;
        }
        this._videoDOMElement.muted = muted;
    };

    private play = (): void => {
        if (this._isPlaying) {
            return;
        }

        this._videoDOMElement.play();
        this.setPlaybackButtonState();
        this._requestId = requestAnimationFrame(this._tick);
    };

    private pause = (): void => {
        const continueLooping = !this._settings.stopWithCreative && this._settings.loop;
        if (continueLooping && this._env.MODE === CreativeMode.AnimatedCreative) {
            return;
        }
        this._videoDOMElement.pause();
        this.setPlaybackButtonState();
        cancelAnimationFrame(this._requestId);
    };

    private stop = (): void => {
        if (this._settings.stopWithCreative) {
            this.pause();
        }
    };

    private loop(): void {
        this._setStartTime();
    }

    private onVideoEnd = (): void => {
        if (!this._isPlaying) {
            return;
        }

        if (this._settings.loop) {
            this.loop();
            this.play();
        } else {
            this.pause();
        }
    };

    private _setStartTime = (): void => {
        this._videoDOMElement.currentTime = this._startTime;
    };

    private _tick = (): void => {
        if (this._destroyed) {
            return;
        }

        this._trackVideoPlayback();

        if (this._endTime && this._videoDOMElement.currentTime >= this._endTime) {
            this.onVideoEnd();
        }

        this._requestId = requestAnimationFrame(this._tick);
    };

    private _trackVideoPlayback(): void {
        const { loop, restartWithCreative } = this._settings;

        /**
         * Video tracking quartiles are based on the longest possible playtime of the video.
         * It's only allowed to span over multiple loops iTf the duration of the element is the same
         * as the entire creative as the video will contiously play across the loops and always be visible.
         * Otherwise it's based on the shortest viable playtime.
         */

        const endTime = this._endTime ?? Number.MAX_SAFE_INTEGER;
        const shortestPlaytime =
            this._dataElement.duration === this._animator.duration && loop && !restartWithCreative
                ? Math.min(endTime, this.duration_m)
                : Math.min(this._dataElement.duration, endTime, this.duration_m);

        const percentilePlayed = this._videoDOMElement.currentTime / shortestPlaytime;
        const url = this._settings.url;
        const filename = this._dataElement.videoAsset?.name;

        let progressValue = 0;

        // 95% is considered a complete playthrough
        if (percentilePlayed >= 0.95 && !this.reachedTrackingPoints.has(95)) {
            progressValue = 1;
            this.reachedTrackingPoints.add(95);
        } else if (percentilePlayed >= 0.75 && !this.reachedTrackingPoints.has(75)) {
            progressValue = 0.75;
            this.reachedTrackingPoints.add(75);
        } else if (percentilePlayed >= 0.5 && !this.reachedTrackingPoints.has(50)) {
            progressValue = 0.5;
            this.reachedTrackingPoints.add(50);
        } else if (percentilePlayed >= 0.25 && !this.reachedTrackingPoints.has(25)) {
            progressValue = 0.25;
            this.reachedTrackingPoints.add(25);
        }

        if (progressValue > 0) {
            this._ad.tracking.trackCustomProgressEvent({
                name: 'Video completion',
                id: url,
                label: filename,
                value: progressValue
            });
        }
    }

    /** @@remove STUDIO:START */
    private _setVideoLoader(): void {
        const loading = this._viewElement.__data.videoAsset?.__loading;

        if (loading) {
            this._showLoader();
        } else {
            this._hideLoader();
        }
    }

    private _showLoader(): void {
        if (!this._loaderElement) {
            this._createLoaderElement();
        }
        if (!this._loaderElement.parentNode) {
            this._shadowRoot.appendChild(this._loaderElement);
        }
    }

    private _createLoaderElement(): void {
        this._loaderElement = document.createElementNS(SVG_NAMESPACE, 'svg');
        const svg = createSVGLoaderImage(this._viewElement);
        const { style } = this._loaderElement;

        style.position = 'absolute';
        style.left = '0';
        style.top = '0';
        style.width = '100%';
        style.height = '100%';
        style.overflow = 'visible';
        style.pointerEvents = 'none';

        this._loaderElement.appendChild(svg);
    }

    private _hideLoader(): void {
        if (this._loaderElement?.parentNode) {
            this._shadowRoot.removeChild(this._loaderElement);
        }
    }

    /** @@remove STUDIO:END */

    destroy(): void {
        this._animator?.off('play', this.onAnimatorPlay);
        this._animator?.off('pause', this.pause);
        this._animator?.off('stop', this.stop);
        this._animator?.off('loop', this.onAnimationLoop);
        this._animator?.off('animation_start', this.onAnimationStart);
        this._animator?.off('animation_end', this.onAnimationEnd);
        this._animator?.off('seek', this.onAnimatorSeek);
        this._renderer?.off('mute', this.onRendererMute);
        this._videoDOMElement.removeEventListener('ended', this.onVideoEnd);
        cancelAnimationFrame(this._requestId);
        this._destroyed = true;
    }
}

enum PlaybackButtonVariable {
    Color = '--playbackButtonColor',
    Size = '--playbackButtonSize',
    PlayState = '--playbackButtonPlayState',
    PauseState = '--playbackButtonPauseState'
}

const playbackButtonHtml = `
<svg class="play" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100%" height="100%" viewBox="0 0 640 640" xml:space="preserve">
<g>
    <path d="M546.273 93.726C485.834 33.286 405.476 0 320 0 234.525 0 154.166 33.286 93.726 93.726S0 234.525 0 320c0 85.476 33.286 165.834 93.726 226.273C154.166 606.714 234.524 640 320 640s165.835-33.286 226.273-93.727C606.714 485.835 640 405.476 640 320s-33.286-165.834-93.727-226.274zM320 582C175.533 582 58 464.467 58 320 58 175.533 175.533 58 320 58c144.467 0 262 117.533 262 262 0 144.467-117.533 262-262 262z"/>
    <path d="M243.289 320V188.698L357 254.35 470.711 320 357 385.651l-113.711 65.651z"/>
</g>
</svg>
<svg class="pause" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100%" height="100%" viewBox="0 0 640 640" xml:space="preserve">
<g>
    <path d="M546.273 93.726C485.834 33.286 405.476 0 320 0 234.525 0 154.166 33.286 93.726 93.726S0 234.525 0 320c0 85.476 33.286 165.834 93.726 226.273C154.166 606.714 234.524 640 320 640s165.835-33.286 226.273-93.727C606.714 485.835 640 405.476 640 320s-33.286-165.834-93.727-226.274zM320 582C175.533 582 58 464.467 58 320 58 175.533 175.533 58 320 58c144.467 0 262 117.533 262 262 0 144.467-117.533 262-262 262z"/>
    <path d="M375 192.854h52.486v254.291H375zM215 192.854h52.486v254.291H215z"/>
</g>
</svg>`;

__inject(T.AD, {}, VideoRenderer, '_ad', 0);
__inject(T.RENDERER, {}, VideoRenderer, '_renderer', 1);
__inject(T.ANIMATOR, {}, VideoRenderer, '_animator', 2);
__inject(T.ENVIRONMENT, {}, VideoRenderer, '_env', 3);
