import { Component } from '@angular/core';
import { AppComponent } from '../../app.component';

@Component({
    selector: 'studio-oops',
    templateUrl: 'oops-page.component.html',
    styleUrls: ['oops-page.component.scss']
})
export class OopsComponent {
    constructor(private app: AppComponent) {
        this.app.loaded = true;
    }
}
