import { deepEqual } from '@studio/utils/utils';
import { Subject } from 'rxjs';
import { filter, map, pairwise, startWith } from 'rxjs/operators';

export enum ChangeState {
    /** Default state */
    ACTIVE = 'ACTIVE',
    PENDING = 'PENDING',
    FINISHED = 'FINISHED'
}

export interface IChangeState<T> {
    state: ChangeState;
    data: T;
}

export class CreativeMutation<T> {
    private state: ChangeState = ChangeState.ACTIVE;
    private _change$ = new Subject<IChangeState<T>>();
    change$ = this._change$.asObservable().pipe(
        startWith({ data: [] as unknown, state: this.state } as IChangeState<T>),
        pairwise(),
        filter(([prev, curr]) => prev.state !== curr.state || deepEqual(prev.data, curr.data)),
        map(pair => pair[1])
    );

    emit(data: T, state: ChangeState): void {
        this.state = state;
        this._change$.next({ data, state: this.state });
        if (this.state === ChangeState.FINISHED) {
            this.state = ChangeState.ACTIVE;
        }
    }
}
