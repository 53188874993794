import {
    ISocialPlacement,
    MetaPlacement,
    PinterestPlacement,
    PlacementPostion,
    SnapchatPlacement,
    SocialNetworkType,
    TiktokPlacement
} from '@domain/social';
import { ISocialNetwork } from '../social';

const DEFAULT_META_PLACEMENT_POSITION: PlacementPostion = { top: 0.14, bottom: 0.206 };
const DEFAULT_META_REELS_PLACEMENT_POSITION: PlacementPostion = {
    top: 0.14,
    left: 0.06,
    right: 0.21,
    bottom: 0.4
};

const META_PLACEMENTS: ISocialPlacement[] = [
    {
        type: MetaPlacement.Instagram,
        text: 'Instagram Story',
        position: { ...DEFAULT_META_PLACEMENT_POSITION }
    },
    {
        type: MetaPlacement.Facebook,
        text: 'Facebook Story',
        position: { ...DEFAULT_META_PLACEMENT_POSITION }
    },
    {
        type: MetaPlacement.Messenger,
        text: 'Messenger Story',
        position: { ...DEFAULT_META_PLACEMENT_POSITION }
    },
    {
        type: MetaPlacement.FacebookReels,
        text: 'Facebook Reels',
        position: { ...DEFAULT_META_REELS_PLACEMENT_POSITION }
    },
    {
        type: MetaPlacement.InstagramReels,
        text: 'Instagram Reels',
        position: { ...DEFAULT_META_REELS_PLACEMENT_POSITION }
    }
];

const DEFAULT_TIKTOK_PLACEMENT_POSITION: PlacementPostion = {
    top: 126 / 960,
    bottom: 320 / 960,
    left: 60 / 540,
    right: 120 / 540
};

const TIKTOK_PLACEMENTS: ISocialPlacement[] = [
    {
        type: TiktokPlacement.Default,
        text: 'Default',
        position: { ...DEFAULT_TIKTOK_PLACEMENT_POSITION }
    },
    {
        type: TiktokPlacement.After9s,
        text: 'After 9s',
        position: { ...DEFAULT_TIKTOK_PLACEMENT_POSITION }
    },
    {
        type: TiktokPlacement.After9sWithCard,
        text: 'After 9s with card',
        position: { ...DEFAULT_TIKTOK_PLACEMENT_POSITION }
    }
];

const DEFAULT_PINTEREST_PLACEMENT_POSITION: PlacementPostion = {
    top: 0.11,
    bottom: 0.252,
    left: 0.09,
    right: 0.092
};

const PINTEREST_PLACEMENTS: ISocialPlacement[] = [
    {
        type: PinterestPlacement.BrowseImage,
        text: '"Browse" Image',
        position: { ...DEFAULT_PINTEREST_PLACEMENT_POSITION }
    },
    {
        type: PinterestPlacement.BrowseVideo,
        text: '"Browse" Video',
        position: { ...DEFAULT_PINTEREST_PLACEMENT_POSITION }
    },
    {
        type: PinterestPlacement.WatchVideo,
        text: '"Watch" Video',
        position: { top: 0.1, bottom: 0.24, left: 0.0245, right: 0.115 }
    }
];

const SNAPCHAT_PLACEMENTS: ISocialPlacement[] = [
    {
        type: SnapchatPlacement.Default,
        text: 'Default',
        position: { top: 0.16, bottom: 0.084, left: 0.11, right: 0.09 }
    }
];

export const SOCIAL_PLACEMENTS: ISocialPlacement[] = [
    ...META_PLACEMENTS,
    ...TIKTOK_PLACEMENTS,
    ...SNAPCHAT_PLACEMENTS,
    ...PINTEREST_PLACEMENTS
];

export const SOCIAL_NETWORKS: ISocialNetwork[] = [
    {
        type: SocialNetworkType.Meta,
        text: 'Meta',
        icon: 'meta',
        placementText: 'Placement',
        placements: META_PLACEMENTS,
        ratios: ['9:16']
    },
    {
        type: SocialNetworkType.TikTok,
        text: 'TikTok',
        icon: 'tiktok',
        placementText: 'CTA Style',
        placements: TIKTOK_PLACEMENTS,
        ratios: ['9:16']
    },
    {
        type: SocialNetworkType.Pinterest,
        text: 'Pinterest',
        icon: 'pinterest',
        placementText: 'Placement',
        placements: PINTEREST_PLACEMENTS,
        ratios: ['9:16']
    },
    {
        type: SocialNetworkType.Snapchat,
        text: 'Snapchat',
        icon: 'snapchat',
        placements: SNAPCHAT_PLACEMENTS,
        ratios: ['9:16']
    }
];
