import { Pipe, PipeTransform } from '@angular/core';
import { isGroupDataNode, isState } from '@creative/nodes/helpers';
import { OneOfDataNodes, OneOfElementDataNodes } from '@domain/nodes';
import { IState } from '@domain/state';

@Pipe({ name: 'isElementNode' })
export class IsElementsPipe implements PipeTransform {
    transform(node?: OneOfDataNodes | IState): OneOfElementDataNodes | undefined {
        if (!isState(node) && !isGroupDataNode(node)) {
            return node;
        }
    }
}
