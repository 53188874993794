import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { Color } from '@creative/color';
import { toCSS } from '@creative/color.utils';

@Component({
    selector: 'color-button',
    templateUrl: './color-button.component.html',
    styleUrls: ['./color-button.component.scss'],
    standalone: true,
    imports: [UIModule, NgIf]
})
export class ColorButtonComponent {
    cssColor: string;
    @Input() colorMixed = false;

    @Input() set color(c: Color | undefined) {
        if (!c) {
            return;
        }
        this.cssColor = toCSS(c);
    }
}
