<mat-tab-group
    dynamicHeight
    (selectedTabChange)="selectTab($event)">
    <mat-tab>
        <ng-template mat-tab-label>
            <div class="tab-label">ADD SIZES</div>
        </ng-template>
        <ng-content select="[tab1]"></ng-content>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            <div class="tab-label">IMPORT PSD</div>
        </ng-template>
        <ng-content select="[tab2]"></ng-content>
    </mat-tab>
</mat-tab-group>
