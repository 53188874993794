import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { pipe } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BrandState, BRAND_FEATURE_KEY } from './brand.reducer';

export const getBrandState = createFeatureSelector<BrandState>(BRAND_FEATURE_KEY);

export const getBrandWhenLoaded = pipe(
    select(getBrandState),
    filter(state => state.loaded)
);

export const getBrandId = createSelector(getBrandState, (state: BrandState) => state.id!);

export const getBrandLoaded = createSelector(getBrandState, (state: BrandState) => state.loaded);

export const getBrandError = createSelector(getBrandState, (state: BrandState) => state.error);

export const getBrandLocalizations = createSelector(
    getBrandState,
    (state: BrandState) => state.localizations
);

export const getBrandPalettes = createSelector(getBrandState, (state: BrandState) => state.palettes);

export const getBrandName = createSelector(getBrandState, (state: BrandState) => state.name);

export const getBrandLogoUrl = createSelector(getBrandState, (state: BrandState) => state.brandLogoUrl);

export const getAccountSlug = createSelector(getBrandState, (state: BrandState) => state.accountSlug);

export const getBrandSlug = createSelector(getBrandState, (state: BrandState) => state.slug);

export const getBrandSizes = createSelector(getBrandState, (state: BrandState) => state.sizeFormats);

export const getBrandSizeGroups = createSelector(
    getBrandState,
    (state: BrandState) => state.sizeFormatGroups
);
