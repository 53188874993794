import { Injectable, OnDestroy } from '@angular/core';
import { getAnimationByStateId, getKeyframeByStateId } from '@creative/animation.utils';
import { isGroupDataNode } from '@creative/nodes/helpers';
import {
    calculateStateFromAnimationAtTime,
    isAdditiveProperty,
    isTextState
} from '@creative/rendering/states.utils';
import { ITextElementDataNode, OneOfElementDataNodes, OneOfElementPropertyKeys } from '@domain/nodes';
import { IState } from '@domain/state';
import { IMixedCharacterProperties, ITextElementProperties } from '@domain/text';
import { omit } from '@studio/utils/utils';
import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { filter, map, pairwise, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { EditorEventService } from '../services/editor-event/editor-event.service';
import { EditorStateService } from '../services/editor-state.service';

@Injectable()
export class PropertiesService implements OnDestroy {
    dataElementChange$ = new BehaviorSubject<OneOfElementDataNodes | undefined>(undefined);
    selectedStateChange$ = new BehaviorSubject<IState | undefined>(undefined);
    propertyChanging$ = new Subject<{
        property: OneOfElementPropertyKeys;
        value: any;
    }>();

    private unsubscribe$ = new Subject<void>();
    private _inStateView = false;
    get inStateView(): boolean {
        return this._inStateView;
    }

    get stateData(): IState | undefined {
        return this.selectedStateChange$.getValue();
    }
    get selectedElement(): OneOfElementDataNodes | undefined {
        return this.dataElementChange$.getValue();
    }

    constructor(
        private editorEventService: EditorEventService,
        private editorStateService?: EditorStateService
    ) {
        this.editorEventService.elements.change$.pipe(takeUntil(this.unsubscribe$)).subscribe(e => {
            if (isGroupDataNode(e.element)) {
                return;
            }

            this.dataElementChange$.next(e.element);
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    observeDataElementOrStateChange<T extends OneOfElementDataNodes = OneOfElementDataNodes>(
        requireSelectedElement: false
    ): Observable<{ element?: T; state?: IState }>;
    observeDataElementOrStateChange<T extends OneOfElementDataNodes = OneOfElementDataNodes>(
        requireSelectedElement?: true
    ): Observable<{ element: T; state?: IState }>;
    observeDataElementOrStateChange<T extends OneOfElementDataNodes = OneOfElementDataNodes>(
        requireSelectedElement = true
    ): Observable<{ element: T; state?: IState }> {
        return merge(
            this.dataElementChange$.pipe(
                pairwise(),
                tap(elementPair => {
                    if (elementPair[0] !== elementPair[1]) {
                        this._inStateView = false;
                    }
                }),
                map(elementPair => {
                    const state = elementPair[0] !== elementPair[1] ? undefined : this.stateData;
                    return { selectedElement: elementPair[1], state };
                })
            ),
            this.selectedStateChange$.pipe(
                tap(state => (this._inStateView = !!state)),
                withLatestFrom(this.dataElementChange$),
                map(([state, selectedElement]) => ({ state, selectedElement }))
            )
        ).pipe(
            filter(({ selectedElement }) => (requireSelectedElement ? Boolean(selectedElement) : true)),
            map(({ selectedElement, state }) => ({ element: selectedElement as T, state }))
        );
    }

    getPlaceholderValue(property: OneOfElementPropertyKeys): any | undefined {
        if (!this.selectedElement) {
            return {};
        }
        const calculated = this.getCalculatedStateAtCurrentTime();
        const defaultValue = isAdditiveProperty(property) ? 0 : this.selectedElement[property];
        const value =
            calculated && calculated[property] !== undefined ? calculated[property] : defaultValue;
        switch (property) {
            case 'opacity':
            case 'scaleX':
            case 'scaleY':
                return Math.round(value * 100);
            case 'padding':
                return (this.selectedElement as ITextElementDataNode).padding?.top.toString();
            default:
                if (typeof value === 'number') {
                    return value.toString();
                } else {
                    return value;
                }
        }
    }

    // Used in property templates
    stateValueIsUndefined(property: OneOfElementPropertyKeys): boolean {
        if (this._inStateView && this.stateData) {
            if (isTextState(this.stateData, property)) {
                const currentStyle =
                    (this.stateData?.content?.style as Partial<
                        ITextElementProperties & IMixedCharacterProperties
                    >) || {};
                if (!currentStyle[property]) {
                    return true;
                }
            } else {
                if (typeof this.stateData[property] === 'undefined') {
                    return true;
                }
            }
        }
        return false;
    }

    getCalculatedStateAtCurrentTime(): IState | undefined {
        const element = this.selectedElement;
        if (element) {
            const state = this.stateData;

            if (state) {
                // Temporary states wont have id's
                if (state.id) {
                    const keyframe = getKeyframeByStateId(element, state.id);
                    const animation = getAnimationByStateId(element, state.id);

                    // States from actions will not have animation
                    if (animation && keyframe) {
                        // For tests
                        const size = this.editorStateService?.size || { width: 300, height: 250 };
                        return omit(
                            {
                                ...calculateStateFromAnimationAtTime(
                                    element,
                                    animation,
                                    size,
                                    element.time + keyframe.time
                                ),
                                ...state
                            },
                            'id'
                        );
                    }
                }
                return state;
            }
        }
    }
}
