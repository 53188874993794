<div class="target-url-dialog">
    <div
        class="header"
        [class.collapsed]="collapsed">
        <a
            class="target-url"
            [href]="targetUrl"
            target="_blank"
            >{{ targetUrl }}</a
        >
        <ui-svg-icon
            *ngIf="targetUrl.length > 50"
            (click)="collapse()"
            class="icon collapse"
            [icon]="collapsed ? 'arrow-up' : 'arrow-down'"></ui-svg-icon>
    </div>
    <ui-dialog-buttons ui-theme="default">
        <ui-button
            text="Close"
            (click)="close()"></ui-button>
        <ui-button
            text="Open link"
            type="primary"
            (click)="openLink()"></ui-button>
    </ui-dialog-buttons>
</div>
