import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CREATIVE_CONVERTER_FEATURE_KEY, CreativeConverterState } from './creative-converter.reducer';

export const getCreativeConverterState = createFeatureSelector<CreativeConverterState>(
    CREATIVE_CONVERTER_FEATURE_KEY
);

export const getPsdLayers = createSelector(getCreativeConverterState, ({ psdLayers }) => psdLayers);

export const getFontToFix = createSelector(getCreativeConverterState, ({ fontToFix }) => fontToFix);

export const getApplyToAll = createSelector(getCreativeConverterState, ({ applyToAll }) => applyToAll);
