<div class="wrapper">
    @let maxPixels = 2000;

    <ui-button-group
        id="outpaint-mode-switch"
        [options]="[
            { id: OutpaintInputMode.Joint, svgIcon: 'border-joint', value: OutpaintInputMode.Joint },
            {
                id: OutpaintInputMode.Separate,
                svgIcon: 'padding_separate',
                value: OutpaintInputMode.Separate
            }
        ]"
        [value]="selectedOutpaintInputMode()"
        (valueChange)="onOutpaintInputModeChange($event)" />

    @if (selectedOutpaintInputMode() === OutpaintInputMode.Joint) {
        <ui-number-input
            id="outpaint-joint"
            unitLabel="⤡"
            [value]="isOutpaintInputMixed() ? undefined : jointOutpaintValue()"
            [placeholder]="isOutpaintInputMixed() ? 'Mixed' : ''"
            [max]="maxPixels"
            [min]="0"
            [step]="1"
            [disabled]="isRequestingOutpaint()"
            (valueChange)="onJointValueChange($event)"
            uiTooltipPosition="top"
            [uiTooltip]="'Expand all sides'" />
    } @else if (selectedOutpaintInputMode() === OutpaintInputMode.Separate) {
        <div class="directions">
            <ui-number-input
                id="outpaint-left"
                minLable="-"
                unitLabel="←"
                [value]="leftValue()"
                [max]="maxPixels"
                [min]="0"
                [step]="1"
                [disabled]="isRequestingOutpaint()"
                (valueChange)="onLeftValueChange($event)"
                uiTooltipPosition="top"
                [uiTooltip]="'Expand left'" />

            <ui-number-input
                id="outpaint-right"
                minLable="-"
                unitLabel="→"
                [value]="rightValue()"
                [max]="maxPixels"
                [min]="0"
                [step]="1"
                [disabled]="isRequestingOutpaint()"
                (valueChange)="onRightValueChange($event)"
                uiTooltipPosition="top"
                [uiTooltip]="'Expand right'" />

            <ui-number-input
                id="outpaint-up"
                minLable="-"
                unitLabel="↑"
                [value]="upValue()"
                [max]="maxPixels"
                [min]="0"
                [step]="1"
                [disabled]="isRequestingOutpaint()"
                (valueChange)="onUpValueChange($event)"
                uiTooltipPosition="bottom"
                [uiTooltip]="'Expand up'" />

            <ui-number-input
                id="outpaint-down"
                minLable="-"
                unitLabel="↓"
                [value]="downValue()"
                [max]="maxPixels"
                [min]="0"
                [step]="1"
                [disabled]="isRequestingOutpaint()"
                (valueChange)="onDownValueChange($event)"
                uiTooltipPosition="bottom"
                [uiTooltip]="'Expand down'" />
        </div>
    }

    <expandable-prompt
        (promptChange)="onPromptValueChange($event)"
        [canExpand]="false"
        [disabled]="isRequestingOutpaint()"
        [type]="ExpandablePromptInputType.OutpaintPrompt" />
    <expandable-prompt
        [disabled]="isRequestingOutpaint()"
        (promptChange)="onNegativePromptValueChange($event)" />

    <div class="actions">
        <ui-button
            text="Expand image"
            type="primary"
            [disabled]="actionsDisabled()"
            [loading]="isRequestingOutpaint()"
            (click)="expandImage()" />
    </div>
</div>
