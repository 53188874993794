import { CreativeDataNode } from '@creative/nodes/base-data-node';
import { isSelectionVisibleAtTime } from '@creative/nodes/helpers';
import { CreativeMode, ICreativeEnvironment } from '@domain/creative/environment';
import { OneOfElementDataNodes } from '@domain/nodes';
import { __inject, inject } from '@studio/utils/di';
import { EventEmitter } from '@studio/utils/event-emitter';
import { getInAnimationDuration, getOutAnimationDuration } from './animation.utils';
import { IAnimator } from './animator.header';
import { T } from './creative.container';
import { IRenderer } from './renderer.header';

export class Animator extends EventEmitter<AnimatorEvents> implements IAnimator {
    /**
     * Current time in seconds.
     */
    time = 0;

    /**
     * If the creative is playing or not.
     */
    isPlaying = false;

    /**
     * Loop currently playing. First loop has index 1.
     */
    loop = 1;

    private _destroyed = false;

    /**
     * Duration of creative in seconds
     */
    get duration(): number {
        return getCreativeAnimationDuration(this.creative);
    }

    private _customFPSinterval?: number; // Interval in ms for the desired fps from ad tag parameter
    private _lastDrawTime = 0;

    private _startTimestamp = 0;
    private _lastTime = 0;
    private _requestId: number | undefined;
    private _speed = 1;
    private _elementsAnimationState = new Map<string, IElementState>();
    /**
     * Queue of events that has to occur after the current tick is completed.
     * Required by e.g widgets to ensure that seeking happens after next (loop).
     */
    private _eventQueue: { fn: (...args: unknown[]) => unknown; value: unknown[] }[] = [];

    constructor(
        @inject(T.CREATIVE_DATA) public creative: CreativeDataNode,
        @inject(T.RENDERER) private _renderer: IRenderer,
        @inject(T.ENVIRONMENT) private _env: ICreativeEnvironment
    ) {
        super();
        this.time = this._getStartTime();
    }

    /**
     * Play the creative if not already playing
     */
    play(): Animator {
        if (!this.isPlaying && this.creative.elements.length > 0) {
            this._lastTime = this.time * 1000;
            this._requestId = requestAnimationFrame(this._tick);
            this.isPlaying = true;
            this._renderer.setPlaying_m(true);
            this.emit('play', this.time);
        }

        return this;
    }

    /**
     * Toggle between play and pause
     */
    toggle(): Animator {
        this.isPlaying ? this.pause() : this.play();
        return this;
    }

    /**
     * Pause the creative if the creative is playing
     */
    pause(): Animator {
        if (this.isPlaying) {
            this._cancelAnimation();
            this.isPlaying = false;
            this._renderer.setPlaying_m(false);
            this.emit('pause', this.time);
        }
        return this;
    }

    /**
     * Stop the creative. Except from pausing the creative it will also seek to the stopTime.
     * Defaults at duration - 1s
     */
    stop(): void {
        // Don't allow to stop the creative when in VideoGenerator env.
        if (this._env.MODE !== CreativeMode.VideoGenerator) {
            const stopTime = this._getStopTime();
            this.pause();
            this.setTime_m(stopTime);
        }
        this.emit('stop', this.time);
    }

    /**
     * Seek to a certain point in time in seconds.
     */
    seek(time: number): Animator {
        time = Math.max(time, 0) || 0;
        if (this.time !== time) {
            this._lastTime = time * 1000;
            this._startTimestamp = 0;
            this.setTime_m(time);
            this.emit('seek', this.time);
        }
        return this;
    }

    /**
     * Seek to a certain time when the element is visible.
     */
    seekToElement(elementId: string): Animator {
        this.pause();
        const element = this.creative.elements.find(e => e.id === elementId);
        if (element) {
            const elementVisibleTime = element.time + element.duration / 2;
            this.seek(elementVisibleTime);
        } else {
            console.warn(`Tried to seek to element[${elementId}], but element does not exist`);
        }
        return this;
    }

    /**
     * Jump to next loop.
     * Will play from the beginning of the loop
     */
    next(replay = true, offset?: number): Animator {
        const loop = this.loop + (offset || 1);

        let exceededLimit = false;
        if (this._renderer.feedStore) {
            exceededLimit = this._renderer.feedStore.loop < 1;
            if (exceededLimit) {
                this._renderer.feedStore.setFeedLoop(offset || 1);
            }
        }

        this.setLoop(loop, replay, !exceededLimit);

        return this;
    }

    /**
     * Jump to previous loop.
     * Will play from the beginning of the loop
     */
    prev(replay = true, offset?: number, exceedLimit?: boolean): Animator {
        const loop = this.loop - (offset || 1);

        if (this._renderer.feedStore) {
            this._renderer.feedStore.setFeedLoop(
                this._renderer.feedStore.loop - (offset || 1),
                exceedLimit
            );
        }

        this.setLoop(loop, replay, false);

        return this;
    }

    /**
     * Replay current loop from beginning.
     */
    replay(): Animator {
        this.seek(0);
        this.emit('replay', this.loop);
        return this;
    }

    /**
     * Restart creative. Loops and time will be reset.
     */
    restart(): Animator {
        if (this._env.MODE === CreativeMode.VideoGenerator) {
            // Cannot restart Animator when in VideoGenerator env
            return this;
        }
        if (this._renderer.feedStore) {
            this._renderer.feedStore.resetIndexState();
            this._renderer.feedStore.skipNextIndexUpdate();
        }
        this.setLoop(1);
        this.play();
        this.emit('restart', this.loop);
        return this;
    }

    /**
     * Updates values on DOM
     */
    render_m(time?: number, forceUpdate?: boolean): void {
        this._updateElementsState();
        this._renderer.setAllViewElementsValues_m(
            typeof time === 'number' ? time : this.time,
            forceUpdate
        );
    }

    useCreative_m(creative: CreativeDataNode): void {
        this.creative = creative;
    }

    setTime_m(time: number, forceUpdate?: boolean): void {
        this.time = time;
        this.render_m(this.time, forceUpdate);
    }

    setLoop(loop: number, replay = true, setFeedLoop = true): void {
        if (this._renderer.feedStore) {
            if (setFeedLoop) {
                this._renderer.feedStore.setFeedLoop(
                    this._renderer.feedStore.loop < 0 ? this._renderer.feedStore.loop : loop
                );
            }
            this._renderer.feedStore.updateElementsCurrentIndex();
        }

        if (replay) {
            this._lastTime = 0;
            this._startTimestamp = 0;
            this.setTime_m(0, /* forceUpdate */ true);
        } else {
            this.render_m(this.time, /* forceUpdate */ true);
        }

        this.loop = Math.max(1, loop);

        if (this.loop === 1) {
            this.time = this._getStartTime();
        }

        if (this._env.MODE !== CreativeMode.VideoGenerator) {
            this.emit('loop', this.loop);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addToQueue(fn: (...args: any[]) => any, value: unknown[] = []): void {
        if (this.isPlaying) {
            this._eventQueue.push({ fn: fn.bind(this), value });
        } else {
            fn.bind(this)(...value);
        }
    }

    updateCustomFPSInterval(interval: number): void {
        this._customFPSinterval = interval;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addToQueueFromWidget(fn: (...args: any[]) => any, value?: unknown[]): void {
        if (this._env.MODE === CreativeMode.VideoGenerator) {
            return;
        }
        this.addToQueue(fn, value);
    }
    /**
     * Keeps track of the current state of all elements and emits events based on what changes
     * Tracks what kind of animation that latest ran, e.g the "in" or "out" part of the In transition
     */
    private _updateElementsState(): void {
        if (!this.isPlaying) {
            return;
        }
        for (let i = 0; i < this.creative.elements.length; i++) {
            const element = this.creative.elements[i];
            const animations = element.animations;
            if (!this._elementsAnimationState.has(element.id)) {
                this._elementsAnimationState.set(element.id, { visible: false });
            }

            const currentState = this._elementsAnimationState.get(element.id)!;
            let animationState: AnimationStateTypes = 'none';

            const inDuration = getInAnimationDuration(animations);
            const outDuration = getOutAnimationDuration(animations);

            if (inDuration) {
                if (this.time > element.time && this.time < inDuration + element.time) {
                    animationState = 'inTransitionIn';
                } else if (
                    currentState.animationState === 'inTransitionIn' &&
                    (this.time <= element.time || this.time >= inDuration + element.time)
                ) {
                    animationState = 'inTransitionOut';
                }
            }

            if (outDuration) {
                const outDurationStart = element.time + element.duration - outDuration;

                if (this.time > outDurationStart && this.time < element.time + element.duration) {
                    animationState = 'outTransitionIn';
                } else if (
                    currentState.animationState === 'outTransitionIn' &&
                    (this.time < outDurationStart || this.time >= element.duration + element.time)
                ) {
                    animationState = 'outTransitionOut';
                }
            }

            if (isSelectionVisibleAtTime(element, this.time)) {
                this._elementsAnimationState.set(element.id, {
                    visible: true,
                    animationState: animationState
                });
            } else {
                this._elementsAnimationState.set(element.id, {
                    visible: false,
                    animationState: animationState
                });
            }

            const isAtStartOfElement = this.time === 0 && element.time === 0;
            const isAtEndOfElement = this.time === element.duration + element.time;

            const state = this._elementsAnimationState.get(element.id)!;

            if (currentState.visible !== state.visible || isAtStartOfElement || isAtEndOfElement) {
                if (
                    ((state.visible && !isAtEndOfElement) || isAtStartOfElement) &&
                    currentState.animationState !== 'animationStarted'
                ) {
                    state.animationState = 'animationStarted';
                    this.emit('animation_start', element);
                } else if (
                    ((!state.visible && !isAtStartOfElement) || isAtEndOfElement) &&
                    currentState.animationState !== 'animationEnded' &&
                    (currentState.animationState !== 'none' || outDuration === 0)
                ) {
                    state.animationState = 'animationEnded';
                    this.emit('animation_end', element);
                }
            }

            if (
                currentState.animationState !==
                this._elementsAnimationState.get(element.id)!.animationState
            ) {
                switch (this._elementsAnimationState.get(element.id)!.animationState) {
                    case 'inTransitionOut':
                        this.emit('in_transition_end', element);
                        break;
                    case 'outTransitionIn':
                        this.emit('out_transition_start', element);
                        break;
                }
            }
        }
    }

    private _tick = (t: number): void => {
        // throttle to desired fps
        if (this._customFPSinterval && t - this._lastDrawTime < this._customFPSinterval) {
            this._requestId = requestAnimationFrame(this._tick);
            return;
        }

        this._lastDrawTime = t;

        /**
         * We need to force-cancel if isPlaying is false as it seems
         * that if animator is paused from the Widget API then it occurs on a
         * different tick causing everything to go haywire
         */
        if (this._destroyed || !this.isPlaying) {
            this._cancelAnimation();
            return;
        }

        if (!this._startTimestamp) {
            this._startTimestamp = t;
        }

        const stopTime = this._getStopTime();
        const loops = this.creative.loops;
        const timeDiff = (t - this._startTimestamp) * this._speed;
        const timeInSeconds = (this._lastTime + timeDiff) / 1000;

        if (
            !this._infinite() &&
            this.loop >= loops &&
            timeInSeconds >= stopTime &&
            this._env.MODE !== CreativeMode.VideoGenerator
        ) {
            this.stop();
            return;
        }

        if (this._env.MODE === CreativeMode.VideoGenerator && timeInSeconds >= this.duration) {
            this.setTime_m(this.duration);
            this.pause();
            return;
        }

        if (timeInSeconds < this.duration) {
            this.setTime_m(timeInSeconds);
        } else if (timeInSeconds >= this.duration) {
            this.setTime_m(this.duration);
            this.next();
        }

        while (this._eventQueue.length) {
            const event = this._eventQueue.pop()!;
            event.fn(...event.value);
        }

        this.emit('tick', this.time);

        this._requestId = requestAnimationFrame(this._tick);
    };

    private _getStartTime(): number {
        return typeof this.creative.startTime === 'number' ? this.creative.startTime : 0;
    }

    private _getStopTime(): number {
        return typeof this.creative.stopTime === 'number' ? this.creative.stopTime : this.duration - 1;
    }

    private _cancelAnimation(): void {
        this._lastTime = 0;
        this._startTimestamp = 0;
        if (this._requestId) {
            cancelAnimationFrame(this._requestId);
            this._requestId = undefined;
        }
    }

    private _infinite(): boolean {
        if (this._env.MODE === CreativeMode.DesignView) {
            return true;
        }
        return !this.creative.loops;
    }

    destroy(): void {
        this._destroyed = true;
        this.clearEvents();
        this._cancelAnimation();
    }
}

__inject(T.CREATIVE_DATA, {}, Animator, 'creative', 0);
__inject(T.RENDERER, {}, Animator, '_renderer', 1);
__inject(T.ENVIRONMENT, {}, Animator, '_env', 2);

export function getCreativeAnimationDuration(creative: CreativeDataNode): number {
    let latestAnimationEnd = 0;
    for (const element of creative.elements) {
        if (element.time + element.duration > latestAnimationEnd) {
            latestAnimationEnd = element.time + element.duration;
        }
    }
    return latestAnimationEnd;
}

interface IElementState {
    visible: boolean;
    animationState?: AnimationStateTypes;
}

type AnimationStateTypes =
    | 'none'
    | 'animationStarted'
    | 'animationEnded'
    | 'inTransitionIn'
    | 'inTransitionOut'
    | 'outTransitionIn'
    | 'outTransitionOut';

export type AnimatorEvents = {
    play: number;
    pause: number;
    stop: number;
    loop: number;
    restart: number;
    replay: number;
    seek: number;
    tick: number;
    animation_start: OneOfElementDataNodes;
    animation_end: OneOfElementDataNodes;
    in_transition_end: OneOfElementDataNodes;
    out_transition_start: OneOfElementDataNodes;
};
