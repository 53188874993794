<div class="text">
    This action will automatically save all creatives in your Creative Set that use this
    {{ isEffect ? 'effect' : 'widget' }}, including this creative. <br />Do you want to proceed?
</div>

<ui-dialog-buttons>
    <ui-button
        text="Cancel"
        (click)="cancel()">
    </ui-button>
    <ui-button
        id="interaction-update-this-widget-confirm-button"
        type="primary"
        text="Confirm"
        (click)="confirm()">
    </ui-button>
</ui-dialog-buttons>
