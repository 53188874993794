import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FFFeatureFlagsService } from '@bannerflow/feature-flags';
import { Logger } from '@bannerflow/sentinel-logger';
import { firstValueFrom } from 'rxjs';
import { AppComponent } from '../../app.component';
import { AuthService } from '../../core/auth/auth.service';
import { UserService } from '../user/state/user.service';

@Component({
    selector: 'creativeset-component',
    templateUrl: './creativeset.component.html'
})
export class CreativesetComponent implements OnInit, OnDestroy {
    private app = inject(AppComponent);
    private authService = inject(AuthService);
    private ffFeatureFlagService = inject(FFFeatureFlagsService);
    private route = inject(ActivatedRoute);
    private userService = inject(UserService);
    private logger = new Logger('CreativesetComponent');

    constructor() {
        this.app.loaded = true;
    }

    async ngOnInit(): Promise<void> {
        const { brand, creativeset } = this.route.snapshot.data;
        const authUser = await firstValueFrom(this.authService.getAuthUser());
        const isEmployee = await firstValueFrom(this.userService.isEmployee$);

        this.ffFeatureFlagService.updateContext({
            userId: authUser.userId,
            properties: {
                brandId: brand.id,
                creativeSetId: creativeset.id,
                isEmployee: isEmployee.toString()
            }
        });
    }

    ngOnDestroy(): void {
        this.logger.verbose('ngOnDestroy');
    }
}
