import { EnvironmentProviders, importProvidersFrom } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { DisplayCampaignEffects } from './display-campaign.effects';
import { DISPLAY_CAMPAIGN_FEATURE_KEY, reducer } from './display-campaign.reducer';

export function provideDisplayCampaignStore(): EnvironmentProviders {
    return importProvidersFrom(
        StoreModule.forFeature(DISPLAY_CAMPAIGN_FEATURE_KEY, reducer),
        EffectsModule.forFeature(DisplayCampaignEffects)
    );
}
