import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { UIModule } from '@bannerflow/ui';

export enum DialogAction {
    Delete,
    Deactivate,
    DeleteAll,
    DeactivateAll
}

@Component({
    standalone: true,
    imports: [CommonModule, UIModule],
    selector: 'delete-active-size-dialog',
    templateUrl: './delete-active-size-dialog.component.html',
    styleUrls: ['./delete-active-size-dialog.component.scss']
})
export class DeleteActiveSizeDialogComponent {
    private closePromise: Promise<string>;
    private resolveClosePromise: (value: string) => void;
    infoText = '';
    buttonText = '';
    verified = false;

    initiate(type: DialogAction): Promise<string> {
        this.setTexts(type);
        if (!this.closePromise) {
            this.closePromise = new Promise(resolve => (this.resolveClosePromise = resolve));
        }
        return this.closePromise;
    }

    deleteDeactivateSize(): void {
        this.resolveClosePromise('confirm');
    }

    cancel(): void {
        this.resolveClosePromise('cancel');
    }

    setTexts(type: DialogAction): void {
        switch (type) {
            case DialogAction.Delete:
                this.buttonText = 'Delete size';
                this.infoText =
                    'WARNING! The last active size in this creative set is selected for deletion. Deleting it will render all remaining sizes empty';
                break;
            case DialogAction.Deactivate:
                this.buttonText = 'Deactivate size';
                this.infoText =
                    'WARNING! The last active size in this creative set is selected for deactivation. Deactivating it will render all remaining sizes empty';
                break;
            case DialogAction.DeleteAll:
                this.buttonText = 'Delete all sizes';
                this.infoText =
                    'WARNING! Deleting all sizes will render the entire creative set empty.';
                break;
            case DialogAction.DeactivateAll:
                this.buttonText = 'Deactivate all sizes';
                this.infoText =
                    'WARNING! Deactivating all sizes will render the entire creative set empty.';
                break;
            default:
                break;
        }
    }
}
