import { IColor } from '@domain/color';
import { IGifExport, IPreloadImage } from '@domain/creativeset/creative';
import { ISize } from '@domain/dimension';
import { ElementKind } from '@domain/elements';
import {
    CreativeKind,
    IAncestor,
    ICreativeDataNode,
    IGroupElementDataNode,
    INodeWithChildren,
    IPrimitiveDataNode,
    NodeKind,
    NodeKindWithChildren,
    OneOfDataNodes,
    OneOfElementDataNodes
} from '@domain/nodes';
import { ICreativeSocialGuide } from '@domain/social';
import { IGuideline } from '@domain/workspace';
import { uuidv4 } from '@studio/utils/id';
import { fromRGBAstring } from '../color.utils';
import { isGroupDataNode, toFlatElementNodeList } from './helpers';

/** @@remove STUDIO:START */
import { cloneDeep } from '@studio/utils/clone';
/** @@remove STUDIO:END */

/**
 * These base classes should be used for all data node types
 * eventually
 */
export abstract class BaseDataNode<Kind extends NodeKind> implements IPrimitiveDataNode<Kind> {
    id: string;
    kind: Kind;
    name: string;
    locked = false;
    hidden = false;
    __rootNode?: ICreativeDataNode;
    __parentNode?: GroupDataNode;
    cidIndex?: number;
    parentId?: string;
}

export abstract class BaseDataNodeWithChildren<Kind extends NodeKindWithChildren>
    extends BaseDataNode<Kind>
    implements INodeWithChildren<Kind>, IAncestor
{
    private _elements: OneOfElementDataNodes[] = [];
    get elements(): OneOfElementDataNodes[] {
        return Object.freeze(this._elements) as OneOfElementDataNodes[];
    }
    private _nodes: OneOfDataNodes[] = [];
    get nodes(): OneOfDataNodes[] {
        return Object.freeze(this._nodes) as OneOfDataNodes[];
    }
    protected get _duration(): number {
        let latestAnimationEnd = 0;
        for (const element of this.elements) {
            if (isGroupDataNode(element)) {
                continue;
            }

            if (element.time + element.duration > latestAnimationEnd) {
                latestAnimationEnd = element.time + element.duration;
            }
        }
        // Rounding workaround due to floating points
        return Math.round(latestAnimationEnd * 100) / 100;
    }

    constructor(input: IBaseDataNodeWithChildrenInput) {
        super();
        this.id = input.id;

        if (typeof this.id === 'undefined') {
            throw new Error(`No id was set when creating node ${this.name}`);
        }

        this.kind = input.kind as Kind;

        if (input.nodes) {
            this.setNodes_m(input.nodes);
        } else if (input.elements) {
            this.setNodes_m(input.elements);
        }
    }

    setNodes_m(nodes: OneOfDataNodes[]): void {
        this._nodes = nodes.slice();
        this._nodes.forEach(node => this._applyRelationsToNode(node));

        this._setFlatViewElementsList(nodes);
    }

    _setFlatViewElementsList(nodes: OneOfDataNodes[]): void {
        this._elements = toFlatElementNodeList(nodes);

        const closestAncestor = this.__parentNode || this.__rootNode;

        closestAncestor?._setFlatViewElementsList(closestAncestor.nodes);
    }

    nodeIterator_m(
        includeGroups?: false,
        group?: INodeWithChildren
    ): IterableIterator<OneOfElementDataNodes>;
    nodeIterator_m(
        includeGroups?: true,
        group?: INodeWithChildren
    ): IterableIterator<OneOfElementDataNodes | OneOfDataNodes>;
    nodeIterator_m(
        includeGroups?: boolean,
        group?: INodeWithChildren
    ): IterableIterator<OneOfElementDataNodes | OneOfDataNodes>;
    *nodeIterator_m(
        includeGroups = false,
        group?: INodeWithChildren
    ): IterableIterator<OneOfElementDataNodes | OneOfDataNodes> {
        if (!group) {
            group = this;
        }

        const nodes = group.nodes;

        switch (group.kind) {
            case CreativeKind.Creative:
            case ElementKind.Group:
                for (const node of nodes) {
                    switch (node.kind) {
                        case ElementKind.Group:
                            if (includeGroups) {
                                yield node;
                            }
                            for (const n of this.nodeIterator_m(includeGroups, node)) {
                                yield n;
                            }
                            break;
                        default:
                            yield node;
                    }
                }
                break;
            default:
                throw new Error('Unknown group element.');
        }
    }

    private _applyRelationsToNode(node: OneOfDataNodes): void {
        if (isGroupDataNode(this)) {
            node.__rootNode = this.__rootNode;
            node.__parentNode = this;
        } else if (this instanceof CreativeDataNode) {
            node.__rootNode = this;
            node.__parentNode = undefined;
        }
    }

    protected _reverseNodes(): this {
        const nodeWithChildren = this;
        nodeWithChildren.setNodes_m(this.nodes.slice().reverse());

        for (const node of nodeWithChildren.nodes) {
            if (node instanceof BaseDataNodeWithChildren) {
                node.setNodes_m((node as BaseDataNodeWithChildren<Kind>)._reverseNodes().nodes);
            }
        }

        return nodeWithChildren;
    }

    addNode_m(node: OneOfDataNodes, atIndex?: number): void {
        const newNodeList = this.nodes.slice();
        atIndex = atIndex ?? this.nodes.length;

        this._applyRelationsToNode(node);

        newNodeList.splice(atIndex, 0, node);

        this.setNodes_m(newNodeList);
    }

    /** @@remove STUDIO:START */
    /** Moves any node to the node in context at given index */
    moveNode_m?(node: OneOfDataNodes, toIndex?: number): void {
        let nodeToMoveFrom: INodeWithChildren | undefined = this.__rootNode;
        if (!nodeToMoveFrom) {
            if (this.findNodeById_m(node.id)) {
                nodeToMoveFrom = this;
            } else {
                return;
            }
        }

        nodeToMoveFrom.removeNodeById_m(node.id);
        this.addNode_m(node, toIndex);
    }

    remove_m(): void {
        if (isGroupDataNode(this)) {
            const parent = this.__parentNode ?? this.__rootNode;
            const parentNodeIndex = parent?.nodes.findIndex(node => node.id === this.id) ?? 0;

            this.nodes.forEach((node, i) => {
                parent?.addNode_m(node, parentNodeIndex + i);
                this.removeNodeById_m(node.id);
            });
        }
    }
    /** @@remove STUDIO:END */

    /**
     * Recursively find a node in the tree
     */
    findNodeById_m(nodeId: string, includeGroups?: boolean): OneOfDataNodes | undefined {
        for (const node of this.nodeIterator_m(includeGroups)) {
            if (node.id === nodeId) {
                return node;
            }
        }
    }

    removeNodeById_m(nodeId: string): void {
        const newNodeTree: OneOfDataNodes[] = [];
        const nodes = this.nodes;

        for (const node of nodes) {
            if (node.id === nodeId) {
                continue;
            }

            if (isGroupDataNode(node)) {
                node.removeNodeById_m(nodeId);
            }

            newNodeTree.push(node);
        }

        this.setNodes_m(newNodeTree);
    }
}

export interface IBaseDataNodeInput {
    id: string;
    kind: NodeKind;
}

export interface IBaseDataNodeWithChildrenInput extends IBaseDataNodeInput {
    nodes?: OneOfDataNodes[];
    elements?: OneOfElementDataNodes[];
}

export class CreativeDataNode
    extends BaseDataNodeWithChildren<CreativeKind.Creative>
    implements ISize, ICreativeDataNode
{
    /**
     * These properites are obsolete for the
     * creative data node but gets inherited from the base
     * They are deleted in the constructor
     */
    name: never;
    locked: never;
    hidden: never;
    /** ----- */

    fill: IColor;
    loops = 0;
    stopTime?: number;
    startTime?: number;
    width: number;
    height: number;
    guidelines: IGuideline[] = [];
    preloadImage: IPreloadImage;
    gifExport: IGifExport;
    socialGuide?: ICreativeSocialGuide;
    private emptyChildrenPreserved = false;

    get duration(): number {
        return this._duration;
    }

    constructor(creative: ICreativeInput) {
        super({ ...creative, kind: CreativeKind.Creative, nodes: creative.nodes });
        delete this.hidden;
        delete this.locked;
        delete this.name;
        this.width = creative.width;
        this.height = creative.height;
        this.fill = creative.fill || fromRGBAstring('rgba(255, 255, 255, 1)');
        this.loops = creative.loops ?? 0;
        this.stopTime = creative.stopTime;
        this.startTime = creative.startTime;
        this.guidelines = creative.guidelines || [];
        this.gifExport = {
            frames: [],
            show: false,
            ...creative.gifExport
        };
        this.preloadImage = {
            quality: 70,
            format: 'jpg',
            frames: [],
            ...creative.preloadImage
        };
        this.socialGuide = creative.socialGuide;
    }

    /** @@remove STUDIO:START */
    static copy(creative: ICreativeDataNode, keepId = false): ICreativeDataNode {
        const copy = new CreativeDataNode(creative);
        copy.id = keepId ? creative.id : uuidv4();
        copy.setNodes_m(cloneDeep(creative.nodes));
        return copy;
    }

    reversedNodeTree(): CreativeDataNode {
        const creative = new CreativeDataNode(this);
        creative._reverseNodes();
        return creative;
    }

    /** Set to true with caution. Not cleaning up empty groups can lead to memory leaks. */
    preserveEmptyChildren(preserve: boolean): void {
        this.emptyChildrenPreserved = preserve;
    }

    clearEmptyChildren(): void {
        if (this.emptyChildrenPreserved) {
            return;
        }

        for (const node of this.nodeIterator_m(true)) {
            if (isGroupDataNode(node)) {
                const parent = node.__parentNode || node.__rootNode;

                // Remove node if it's becomes empty and is a group node
                if (parent && !node.elements.length) {
                    parent.removeNodeById_m(node.id);
                }
            }
        }
    }
    /** @@remove STUDIO:END */

    getFirstPreloadImageFrame(): number {
        if (this.preloadImage.frames.length > 0) {
            return this.preloadImage.frames[0];
        }
        return 1;
    }

    getStopTime_m(): number {
        if (typeof this.stopTime === 'number') {
            return this.stopTime;
        } else {
            return this.duration - 1;
        }
    }
}

export interface ICreativeInput extends Omit<IBaseDataNodeWithChildrenInput, 'kind'> {
    width: number;
    height: number;
    fill: IColor;
    loops?: number;
    startTime?: number;
    stopTime?: number;
    guidelines?: IGuideline[];
    preloadImage?: IPreloadImage;
    gifExport?: IGifExport;
    socialGuide?: ICreativeSocialGuide;
}

export class GroupDataNode
    extends BaseDataNodeWithChildren<ElementKind.Group>
    implements IGroupElementDataNode
{
    name: string;
    __rootNode?: ICreativeDataNode;
    __parentNode?: GroupDataNode;
    cidIndex?: number;
    parentId?: string;
    get time(): number {
        const times = new Set<number>();
        for (const node of this.elements) {
            times.add(node.time);
        }
        return Math.min(...times);
    }
    get duration(): number {
        return this._duration - this.time;
    }

    constructor(input: IGroupDataNodeInput) {
        super({ ...input, kind: ElementKind.Group });
        this.name = input.name;
        this.locked = input.locked ?? false;
        this.hidden = input.hidden ?? false;
        this.__rootNode = input.__rootNode;
        this.__parentNode = input.__parentNode as GroupDataNode;
        this.cidIndex = input.cidIndex;
        this.parentId = input.parentId;
    }

    /** @@remove STUDIO:START */
    copy(keepId = false): GroupDataNode {
        const copy = new GroupDataNode(this);
        copy.id = keepId ? this.id : uuidv4();
        copy.setNodes_m(cloneDeep(this.nodes));
        return copy;
    }
    /** @@remove STUDIO:END */
}

interface IGroupDataNodeInput extends Omit<IBaseDataNodeWithChildrenInput, 'kind'> {
    name: string;
    locked?: boolean;
    hidden?: boolean;
    __rootNode?: ICreativeDataNode;
    __parentNode?: GroupDataNode | IGroupElementDataNode;
    cidIndex?: number;
    parentId?: string;
}
