import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    HostListener,
    Inject,
    OnDestroy,
    Output,
    ViewChild
} from '@angular/core';
import { getElementKindFromLibraryKind } from '@creative/nodes';
import { LibraryKind } from '@domain/media-library';
import { ActivityLoggerService } from '@studio/monitoring/activity-logger.service';
import { setCursorOnElement } from '@studio/utils/cursor';
import { isElementDescendantOfElementWithClass } from '@studio/utils/dom-utils';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MediaLibraryService } from '../../../shared/media-library/state/media-library.service';
import { DesignViewComponent } from '../design-view.component';
import { BrandLibraryElementEditService } from '../media-library/brandlibrary-element-edit.service';
import { ElementKind } from '@domain/elements';

const CLICK_DELAY = 200;
@Component({
    selector: 'studio-toolbar',
    templateUrl: './studio-toolbar.component.html',
    styleUrls: ['./studio-toolbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudioToolbarComponent implements AfterViewInit, OnDestroy {
    @Output() toolbarIsOpen = new EventEmitter<{ isOpen: boolean; width: number }>();

    @ViewChild('toolbar') toolbar: ElementRef;
    @ViewChild('feedsButton') feedsButton: ElementRef;

    ElementKind = ElementKind;

    // To separate (click) from (dblclick)
    beginDrawingPrevented = false;
    beginDrawingTimeoutId;
    beginDrawingDelay = 200;
    editor: DesignViewComponent;
    mediaLibraryKind?: LibraryKind;
    isMediaLibraryOpen?: boolean;
    isMediaLibraryPinned?: boolean;
    width = 0;
    LibraryKind = LibraryKind;

    private isEditingName = false;
    private clickTimer?: number;
    private preventClick = false;
    private unsubscribe$ = new Subject<void>();

    constructor(
        @Inject(forwardRef(() => DesignViewComponent))
        editor: DesignViewComponent,
        public activityLoggerService: ActivityLoggerService,
        private changeDetector: ChangeDetectorRef,
        public mediaLibraryService: MediaLibraryService,
        private host: ElementRef<HTMLElement>,
        private brandLibraryElementEditService: BrandLibraryElementEditService
    ) {
        this.editor = editor;

        this.brandLibraryElementEditService.isEditingName$$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(isEditingName => {
                this.isEditingName = isEditingName;
            });
    }

    @HostListener('mousedown', ['$event']) onMouseDown(event: MouseEvent): void {
        if (!isElementDescendantOfElementWithClass(event.target, 'feeds-tool')) {
            this.editor.workspace.transform.cancel();
        }
    }

    ngAfterViewInit(): void {
        this.width = this.host.nativeElement.offsetWidth;

        combineLatest([
            this.mediaLibraryService.kind$,
            this.mediaLibraryService.isOpen$,
            this.mediaLibraryService.isPinned$
        ])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(([kind, isOpen, isPinned]) => {
                this.mediaLibraryKind = kind;
                this.isMediaLibraryOpen = isOpen;
                this.isMediaLibraryPinned = isPinned;
                this.detectChanges();
            });
    }

    ngOnDestroy(): void {
        this.clearTimeouts();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    beginElementDrawing(kind: ElementKind): void {
        this.beginDrawingPrevented = false;
        this.beginDrawingTimeoutId = setTimeout(() => {
            if (this.beginDrawingPrevented) {
                return;
            }

            this.editor.workspace.beginCreateNewElement(kind);
            this.setToolbarCursors();
        }, this.beginDrawingDelay);
    }

    selectToolbarItem(libraryKind: LibraryKind, event: MouseEvent): void {
        this.activityLoggerService.log(`Selected ${libraryKind} tool`);

        this.deselectToolbarItem();
        if (this.editor.workspace.selectionToolActive) {
            this.toggleSelectionTool();
        }
        this.editor.workspace.stopCreateNewElement();
        event.stopPropagation();

        if (
            this.isMediaLibraryOpen &&
            this.mediaLibraryKind === libraryKind &&
            !this.isMediaLibraryPinned
        ) {
            this.mediaLibraryService.closeMediaLibrary(this.isEditingName);
            return;
        }

        this.clickTimer = window.setTimeout(async () => {
            if (!this.preventClick || this.mediaLibraryKind === libraryKind) {
                this.deselectToolbarItem();

                const elementKind = getElementKindFromLibraryKind(libraryKind);
                switch (elementKind) {
                    case ElementKind.Button:
                    case ElementKind.Ellipse:
                    case ElementKind.Text:
                    case ElementKind.Rectangle:
                        this.beginElementDrawing(elementKind);
                        break;
                    default:
                        this.mediaLibraryService.openMediaLibrary(libraryKind);
                }
            }

            this.preventClick = false;
            this.detectChanges();
        }, CLICK_DELAY);
    }

    deselectToolbarItem(): void {
        this.editor.workspace.stopCreateNewElement();
        this.setToolbarCursors();
        this.detectChanges();
    }

    setToolbarCursors(): void {
        document.querySelectorAll('.toolbar-item').forEach(e => {
            if (e.classList.contains(`type-${this.editor.workspace.createElementKind}`)) {
                setCursorOnElement(
                    e as HTMLElement,
                    `create-element-${this.editor.workspace.createElementKind}-0`
                );
            } else {
                setCursorOnElement(e as HTMLElement, 'pointer');
            }
        });
    }

    toggleSelectionTool($event?: Event): void {
        $event && $event.stopPropagation();
        this.editor.workspace.toggleSelectionTool();
        this.editor.workspace.stopCreateNewElement();
        this.mediaLibraryService.closeMediaLibrary(this.isEditingName, true);
    }

    createElement(kind: ElementKind): void {
        this.activityLoggerService.log(`Creating ${kind} element from toolbar`);

        this.clearTimeouts();
        this.preventClick = true;
        this.beginDrawingPrevented = true;
        this.editor.workspace.stopCreateNewElement();
        this.editor.workspace.createNewElement(undefined, kind);

        if (!this.isMediaLibraryPinned) {
            this.deselectToolbarItem();
            this.mediaLibraryService.closeMediaLibrary(this.isEditingName);
        }
    }

    private clearTimeouts(): void {
        clearTimeout(this.clickTimer);
        clearTimeout(this.beginDrawingTimeoutId);
    }

    detectChanges(): void {
        if (!this.changeDetector['destroyed']) {
            this.changeDetector.detectChanges();
        }
    }
}
