import { Pipe, PipeTransform } from '@angular/core';
import { ILocalization, IVersion } from '@domain/creativeset/version';
import { IMatInputLabel } from '@studio/domain/components/translation-page';
import { BrandService } from '@studio/stores/brand';
import { take } from 'rxjs';

@Pipe({
    standalone: true,
    name: 'versionToLabel'
})
export class VersionToLabelPipe implements PipeTransform {
    private localizations: ILocalization[];

    constructor(private brandService: BrandService) {
        this.brandService.localizations$.pipe(take(1)).subscribe(localizations => {
            this.localizations = localizations;
        });
    }

    transform(version: IVersion, elementName?: string): IMatInputLabel {
        const icon = this.getLocalizationIcon(version.localization.id);
        const textSuffix = elementName ? ` - ${elementName}` : '';
        const text = `${version.name}${textSuffix}`;
        return { icon, text };
    }

    private getLocalizationIcon(localizationId: string): string {
        const localization = this.localizations.find(({ id }) => id === localizationId);
        if (!localization) {
            return '';
        }
        return this.generateFlagEmoji(localization.cultureCode.toUpperCase());
    }

    private generateFlagEmoji(countryCode: string): string {
        const base = '🇦'.codePointAt(0)! - 'A'.codePointAt(0)!; // Unicode base for capital letters
        let flag = '';
        for (const char of countryCode) {
            const unicodeCode = char.toUpperCase().codePointAt(0)! + base;
            flag += String.fromCodePoint(unicodeCode);
        }
        return flag;
    }
}
