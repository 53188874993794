import { Injectable } from '@angular/core';
import { WorkspaceGizmoDrawer } from './workspace-gizmo-drawer';

@Injectable()
export class GizmoDrawSchedulerService {
    private __timer: any;
    private scheduledGizmoDraws = 0;
    gizmoDrawer: WorkspaceGizmoDrawer;

    scheduleDraw(): void {
        this.scheduledGizmoDraws++;
        this.__timer = setTimeout(() => {
            this.gizmoDrawer.clear();
            this.scheduledGizmoDraws--;
            if (this.scheduledGizmoDraws === 0) {
                this.gizmoDrawer.draw();
            }
        }, 10);
    }

    clear(): void {
        this.gizmoDrawer.clear();

        if (this.__timer) {
            clearTimeout(this.__timer);
        }
    }
}
