import { Pipe, PipeTransform } from '@angular/core';
import {
    IVersionProperty,
    IVersionedElementPropertyValuesByElement,
    IVersionedText
} from '@domain/creativeset/version';
import { IFeedStep } from '@domain/feed';
import { SpanType } from '@domain/text';
import { cloneDeep } from '@studio/utils/clone';

type IVersionedProperties =
    | Pick<IVersionedElementPropertyValuesByElement, 'properties'>
    | { properties: IVersionProperty<IVersionedText>[] };

@Pipe({
    name: 'assignVisibleFeedStep',
    standalone: true
})
export class AssignVisibleFeedStepPipe implements PipeTransform {
    transform(elements: IVersionedProperties[]): (IFeedStep | undefined)[] {
        const clonedArray: IVersionedProperties[] = cloneDeep(elements);

        return this.updateArrayWithMixedValues(clonedArray);
    }

    private updateArrayWithMixedValues(
        versionedProperties: IVersionedProperties[]
    ): (IFeedStep | undefined)[] {
        const propertyNames = ['start', 'size', 'occurrence'];
        const visibleSteps: (IFeedStep | undefined)[] = [];

        const propertyValuesByPosition = {};

        for (const item of versionedProperties) {
            for (const prop of item.properties) {
                for (const style of (prop.value as IVersionedText).styles) {
                    if (style.type !== SpanType.Variable || !style.variable) {
                        continue;
                    }

                    for (const propertyName of propertyNames) {
                        const propertyValue = style.variable.step[propertyName];
                        const propertyKey = `${style.position}_${propertyName}`;

                        if (!propertyValuesByPosition[propertyKey]) {
                            propertyValuesByPosition[propertyKey] = new Set();
                        }

                        propertyValuesByPosition[propertyKey].add(propertyValue);
                    }
                }
            }
        }

        for (const prop of versionedProperties[0].properties) {
            for (const style of (prop.value as IVersionedText).styles) {
                if (style.type !== SpanType.Variable || !style.variable) {
                    visibleSteps.push(undefined);
                    continue;
                }
                const step: IFeedStep = {
                    size: style.variable.step.size,
                    start: style.variable.step.start,
                    occurrence: style.variable.step.occurrence
                };

                for (const propertyName of propertyNames) {
                    const propertyKey = `${style.position}_${propertyName}`;
                    const valuesSet = propertyValuesByPosition[propertyKey];
                    if (valuesSet.size > 1) {
                        step[propertyName] = null;
                    }
                }
                visibleSteps.push(step);
            }
        }

        return visibleSteps;
    }
}
