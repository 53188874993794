import { Injectable } from '@angular/core';
import { TimelineResizeDirection } from '@creative/animation.utils';
import { IAnimationKeyframe } from '@domain/animation';
import { IPosition } from '@domain/dimension';
import { cloneDeep } from '@studio/utils/clone';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, map, pairwise, startWith } from 'rxjs/operators';

export enum KeyframeAction {
    None,
    Move,
    Resize
}

@Injectable()
export class KeyframeService {
    initialValues: Map<string, IAnimationKeyframe> = new Map();
    actionMode = KeyframeAction.None;
    resizeDirection?: TimelineResizeDirection | undefined;
    hoveredKeyframe$ = new BehaviorSubject<IAnimationKeyframe | undefined>(undefined);
    change$ = new Subject<IAnimationKeyframe[]>();
    private _selectedKeyframes$ = new Subject<Set<IAnimationKeyframe>>();
    selectedKeyframes$ = this._selectedKeyframes$.pipe(
        startWith(new Set<IAnimationKeyframe>()),
        pairwise(),
        filter(([prev, curr]) => {
            if (prev.size !== curr.size) {
                return true;
            }
            for (const el of Array.from(prev)) {
                if (!curr.has(el)) {
                    return true;
                }
            }
            return false;
        }),
        map(pair => pair[1])
    );

    nudgeMoveKeyframes$ = new Subject<number>();
    moveKeyframes$ = new Subject<{ deltaTime: number; currentDirectionLeft?: boolean }>();
    changeKeyframeDuration$ = new Subject<IPosition>();
    addKeyframe$ = new Subject<boolean | undefined>();
    deleteKeyframes$ = new Subject<void>();
    copyKeyframes$ = new Subject<void>();
    pasteKeyframes$ = new Subject<void>();
    distributeKeyframes$ = new Subject<void>();

    private _keyframes = new Set<IAnimationKeyframe>();

    get keyframes(): Set<IAnimationKeyframe> {
        const keyframes = new Set<IAnimationKeyframe>();
        for (const kf of this._keyframes) {
            keyframes.add(kf);
        }
        return keyframes;
    }

    get hasKeyframes(): boolean {
        return this._keyframes.size > 0;
    }

    set(...keyframes: IAnimationKeyframe[]): void {
        if (keyframes.length !== this._keyframes.size || keyframes.some(k => !this.isSelected(k))) {
            this._keyframes.clear();
            this.add(...keyframes);
        }
    }

    add(...keyframes: IAnimationKeyframe[]): void {
        keyframes.forEach(k => {
            this._keyframes.add(k);
        });
        this._selectedKeyframes$.next(this.keyframes);
    }

    remove(...keyframes: IAnimationKeyframe[]): void {
        keyframes.forEach(k => {
            this._keyframes.delete(k);
        });
        this._selectedKeyframes$.next(this.keyframes);
    }

    clear(): void {
        if (this._keyframes.size > 0) {
            this._keyframes.clear();
            this._selectedKeyframes$.next(this.keyframes);
        }
    }

    isSelected(keyframe: IAnimationKeyframe | undefined): boolean {
        if (!keyframe) {
            return false;
        }
        return !!Array.from(this._keyframes).find(kf => kf.id === keyframe.id);
    }

    getInitialValue(keyframe: IAnimationKeyframe): IAnimationKeyframe | undefined {
        if (keyframe.id) {
            return this.initialValues.get(keyframe.id);
        }
    }

    setInitialValues(): void {
        this.keyframes.forEach(k => this.initialValues.set(k.id, cloneDeep(k)));
    }
}
