import { Component, EventEmitter, Output } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';

@Component({
    standalone: true,
    imports: [MatTabsModule],
    selector: 'size-add-tabs',
    templateUrl: './size-add-tabs.component.html',
    styleUrls: ['./size-add-tabs.component.scss']
})
export class SizeAddTabsComponent {
    @Output() selectedTabChange = new EventEmitter<number>();

    selectTab(event: any): void {
        this.selectedTabChange.emit(event.index);
    }
}
