import { Injectable } from '@angular/core';
import { Color } from '@creative/color';
import { T, createCreativeContainer } from '@creative/creative.container';
import { CreativeDataNode } from '@creative/nodes/base-data-node';
import { isGroupDataNode, isVideoNode, isWidgetElement } from '@creative/nodes/helpers';
import { Renderer } from '@creative/renderer';
import { deserializeBrandlibraryElementProperties } from '@creative/serialization';
import { visitOneNode } from '@creative/visitor';
import { CreativeMode, ICreativeEnvironment } from '@domain/creative/environment';
import { IElement } from '@domain/creativeset/element';
import { OneOfElementDataNodes } from '@domain/nodes';
import { FontFamiliesService } from '@studio/stores/font-families';
import { Container } from '@studio/utils/di';
import { uuidv4 } from '@studio/utils/id';
import { firstValueFrom } from 'rxjs';
import { EnvironmentService } from '../../../shared/services/environment.service';
import { NodeCreatorService } from '../services/data-node-creator.service';
import { EditorStateService } from '../services/editor-state.service';

/**
 * Service that handles rendering of elements outside of the
 * creative itself, for example in the brand library
 */
@Injectable()
export class ElementRenderingService {
    window: Window;
    renderer: Renderer;
    constructor(
        private appContainer: Container<T>,
        private fontFamiliesService: FontFamiliesService,
        private nodeCreator: NodeCreatorService,
        private environmentService: EnvironmentService,
        private editorStateService: EditorStateService
    ) {
        firstValueFrom(this.fontFamiliesService.fontFamilies$).then(fontFamilies => {
            const creativeFill = new Color();
            const creativeDocument = new CreativeDataNode({
                id: uuidv4(),
                width: 800,
                height: 800,
                fill: creativeFill
            });
            const env = {
                ...this.environmentService.env,
                MODE: CreativeMode.DesignView
            } as ICreativeEnvironment;
            const creativeContainer = createCreativeContainer(env);
            creativeContainer.parent = this.appContainer;
            creativeContainer.register_m(T.FONT_FAMILIES, fontFamilies);
            creativeContainer.register_m(T.ENVIRONMENT, env);
            this.renderer = new Renderer(
                creativeContainer,
                creativeDocument,
                {
                    contentWindow: window,
                    canvasSize: { width: 800, height: 800 }
                },
                env,
                this.editorStateService.renderer.feedStore
            );
            this.window = window;
            this.renderer.rootElement = this.window.document.createElement('div');
        });
    }

    createElement(
        element: IElement,
        excludeAnimations = false,
        dataOverride?: Partial<OneOfElementDataNodes>
    ): OneOfElementDataNodes {
        const dataNode = this.nodeCreator.create(element.type, {
            kind: element.type,
            ...dataOverride
        });

        if (isGroupDataNode(dataNode)) {
            throw new Error('Illegal group node creation.');
        }

        /**
         * BL elements doesn't need a name.
         * Also conflicts with e2e tests
         * */
        dataNode.name = '';

        // ID is lost during setData
        const id = dataNode.id;
        this.setData(element, dataNode, excludeAnimations);
        dataNode.id = id;

        // Applies the scope id to the renderer in order to apply styles
        this.renderer.setScopeId_m(`scope-${dataNode.id}`);

        if (isVideoNode(dataNode)) {
            this.renderer.visitVideo_m(dataNode, true);
        } else {
            visitOneNode(dataNode, this.renderer);
        }

        return dataNode;
    }

    private setData(element: IElement, data: OneOfElementDataNodes, excludeAnimations = false): void {
        deserializeBrandlibraryElementProperties(
            data,
            element.properties.map(p => p)
        );
        if (excludeAnimations) {
            data.states = [];
            data.animations = [];
        }
    }

    getData(element: IElement): OneOfElementDataNodes {
        const data: OneOfElementDataNodes = {
            kind: element.type
        } as OneOfElementDataNodes;
        deserializeBrandlibraryElementProperties(
            data,
            element.properties.map(p => p)
        );
        return data;
    }

    isStyledElement(element: IElement): boolean {
        if (!isWidgetElement(element) && element.properties) {
            return [...element.properties].some(prop => prop.unit && prop.unit.includes('number'));
        }
        return false;
    }
}
