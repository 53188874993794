import {
    IMatContentSpan,
    IMatInputTextSelection,
    PresetsContent
} from '@studio/domain/components/translation-page';

export function convertPresetsToContentSpan(
    presetsContent: PresetsContent[],
    selection: IMatInputTextSelection | undefined
): IMatContentSpan[] {
    if (!selection) {
        return presetsContent.map(({ content }) => ({ content, selected: false }));
    }
    const fullText = presetsContent.map(({ content }) => content).join('');
    const styleContent: IMatContentSpan[] = [];

    if (selection.start > 0) {
        styleContent.push({
            content: fullText.substring(0, selection.start),
            selected: false
        });
    }

    styleContent.push({
        content: fullText.substring(selection.start, selection.end),
        selected: true
    });

    if (selection.end < fullText.length) {
        styleContent.push({
            content: fullText.substring(selection.end),
            selected: false
        });
    }

    return styleContent;
}
