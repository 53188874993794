import { ElementKind } from '@domain/elements';
import { ILayoutElement, ILayoutRule, isLayoutElement } from '../rules';

export class MinMaxRule implements ILayoutRule {
    applyAutoContraintRule(layoutElement: ILayoutElement): void {
        if (isLayoutElement(layoutElement)) {
            const { element, newCanvasSize } = layoutElement;

            // Applies only to buttons
            if (element.kind === ElementKind.Button || element.kind === ElementKind.Text) {
                const rotationZ = element.rotationZ;

                if (
                    newCanvasSize.width < layoutElement.originalCreative.width * 0.6 &&
                    newCanvasSize.width < 150 &&
                    !rotationZ
                ) {
                    layoutElement.constraint.minWidth = {
                        value: 0.8,
                        unit: '%'
                    };
                }
            }
        }
    }
}
