import { ApolloError } from '@apollo/client/errors';
import { IBrandLocalization } from '@domain/brand';
import { ICreative } from '@domain/creativeset/creative/creative';
import { ILocalization, IVersion, IVersionedText, IVersionProperty } from '@domain/creativeset/version';
import { IFeed } from '@domain/feed';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const VersionsActions = createActionGroup({
    source: 'Versions',
    events: {
        init: props<{
            versions: IVersion[];
            selectedVersionsIds: string[];
            defaultVersion: IVersion;
            versionParamProvided: boolean;
            creativesetId: string;
            selectableIds?: string[];
        }>(),
        initSuccess: props<{
            versions: IVersion[];
            selectedVersionsIds: string[];
            defaultVersion: IVersion;
            creativesetId: string;
            selectableIds?: string[];
        }>(),

        resetVersions: props<{
            versions: IVersion[];
        }>(),
        resetVersionsSuccess: props<{
            versions: IVersion[];
        }>(),

        preparePlaceholder: props<{
            localization: IBrandLocalization;
            newVersionName?: string;
        }>(),
        cancelPlaceholder: emptyProps(),

        // From picker
        saveNewVersion: props<{ newVersionName: string; autoTranslate?: boolean }>(),

        // From dialog
        createVersion: props<{
            localization: ILocalization;
            newVersionName: string;
            autoTranslate?: boolean;
        }>(),
        createVersionSuccess: props<{
            versions: IVersion[];
            creatives: ICreative[];
        }>(),
        createVersionFailure: props<{ error: Error | ApolloError }>(),

        deleteVersions: props<{ versions: IVersion[] }>(),
        deleteVersionsSuccess: props<{ versionsIds: string[] }>(),
        deleteVersionsFailure: props<{ error: Error }>(),

        updateVersions: props<{
            versions: IVersion[];
        }>(),
        updateVersionsAndCreatives: props<{
            versions: IVersion[];
            creatives: ICreative[];
        }>(),
        updateVersionsSuccess: props<{
            versions: IVersion[];
        }>(),
        updateVersionsFailure: props<{ error: Error }>(),

        setDefaultVersion: props<{ version: IVersion }>(),
        setDefaultVersionSuccess: props<{ version: IVersion }>(),
        setDefaultVersionFailure: props<{ error: Error }>(),

        updateVersionedText: props<{
            versionId: string;
            versionPropertyId: string;
            value: Partial<IVersionedText>;
        }>(),
        upsertVersionProperty: props<{
            versionIds: string[];
            versionProperty: IVersionProperty;
        }>(),
        addVersionProperty: props<{
            versionIds: string[];
            versionProperty: IVersionProperty;
        }>(),
        addVersionPropertiesToVersions: props<{
            changes: { versionId: string; versionProperty: IVersionProperty }[];
        }>(),

        addVersionProperties: props<{
            versionId: string;
            versionProperties: IVersionProperty[] | IVersionProperty;
        }>(),

        addVersionPropertiesToSelected: props<{
            versionProperties: IVersionProperty[] | IVersionProperty;
        }>(),

        addVersionPropertiesToDefault: props<{
            versionProperties: IVersionProperty[] | IVersionProperty;
        }>(),

        updateVersionPropertyFeed: props<{
            versionId: string;
            versionPropertyId: string;
            feedId: string;
            changes: Partial<IFeed>;
        }>(),

        removeVersionPropertiesFromAllVersions: props<{
            propertyIdsToRemove: string[];
        }>(),
        removeVersionPropertiesFromSelectedVersions: props<{
            propertyIdsToRemove: string[];
        }>(),
        removeVersionPropertiesFromVersions: props<{
            propertyIdsToRemove: string[];
            versionIds: string[];
        }>(),

        updateShouldCheckPristine: props<{
            shouldCheckPristine: boolean;
        }>(),

        copyStyleIdsBetweenDocuments: props<{
            sourceDocumentId: string;
            targetDocumentId: string;
        }>(),

        cleanStyleIds: props<{
            validDocumentIds: string[];
        }>(),

        // TODO: Move this action to creativeset actions
        designUpdated: emptyProps(),

        onUpdatedDesigns: props<{
            versions: IVersion[];
        }>(),
        onUpdatedDesignsSuccess: props<{
            versions: IVersion[];
        }>()
    }
});
