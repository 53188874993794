export enum ErrorMessage {
    ImageRemovedDuringLoad = 'Image was removed while loading',
    ImageLoadingFailed = 'Could not load image',
    ResizeObservableLoopLimit = 'ResizeObserver loop limit exceeded',
    ResizeObservableLoopCompleted = 'ResizeObserver loop completed with undelivered notifications',
    VideoSourceInitFailed = 'Failed to initialize video source',
    MediaErrorAborted = 'media resource fetching was aborted',
    MediaErrorNetwork = 'a network error occurred while fetching the media resource',
    MediaErrorDecode = 'error occurred while decoding the media resource',
    MediaErrorSourceNotSupported = 'media resource is not supported'
}
