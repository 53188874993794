import { IHotkey } from '@domain/hotkeys/hotkeys.types';

export const TranslationPageHotkeys: IHotkey[] = [
    {
        command: 'OpenCreativeInNewTab',
        keyCombination: { win: 'Ctrl + Click', mac: 'Cmd + Click' },
        context: 'TranslationPage',
        category: 'Translation Page',
        meta: {
            description: 'Open Creative in New Tab',
            shortcut: { mac: '⌘ + Click', win: 'Ctrl + Click' }
        }
    },
    {
        command: 'ToggleOtherSizes',
        keyCombination: 'H',
        context: 'TranslationPage',
        category: 'Translation Page',
        meta: { description: 'Hide/show other sizes', shortcut: 'H' }
    },
    {
        command: 'PlayPause',
        keyCombination: 'Spacebar(KeyUp)',
        context: 'TranslationPage',
        category: 'Translation Page',
        meta: { description: 'Play/Pause', shortcut: 'Spacebar' }
    },
    {
        command: 'NextField',
        keyCombination: 'Tab',
        context: 'TranslationPage',
        category: 'Translation Page',
        meta: { description: 'Next field', shortcut: 'Tab' }
    },
    {
        command: 'PreviousField',
        keyCombination: 'Shift + Tab',
        context: 'TranslationPage',
        category: 'Translation Page',
        meta: { description: 'Previous field', shortcut: { mac: '⇧ + Tab', win: 'Shift + Tab' } }
    },
    {
        command: 'SaveFieldValue',
        keyCombination: { mac: 'Cmd + S', win: 'Ctrl + S' },
        context: 'TranslationPage',
        category: 'Translation Page',
        meta: { description: 'Save field value', shortcut: { mac: '⌘ + S', win: 'Ctrl + S' } }
    },
    {
        command: 'UnfocusField',
        keyCombination: 'Escape',
        context: 'TranslationPage',
        category: 'Translation Page',
        meta: { description: 'Unfocus field', shortcut: 'Escape' }
    },
    {
        command: 'OpenContentPanel',
        keyCombination: 'C',
        context: 'TranslationPage',
        category: 'Translation Page',
        meta: { description: 'Open content panel', shortcut: 'C' }
    },
    {
        command: 'OpenStylingPanel',
        keyCombination: 'S',
        context: 'TranslationPage',
        category: 'Translation Page',
        meta: { description: 'Open styling panel', shortcut: 'S' }
    },
    {
        command: 'LeftAlignedPanel',
        keyCombination: { mac: 'Cmd + ArrowLeft', win: 'Ctrl + ArrowLeft' },
        context: 'TranslationPage',
        category: 'Translation Page',
        meta: { description: 'Left aligned panel', shortcut: { mac: '⌘ + ←', win: 'Ctrl + ←' } }
    },
    {
        command: 'RightAlignedPanel',
        keyCombination: { mac: 'Cmd + ArrowRight', win: 'Ctrl + ArrowRight' },
        context: 'TranslationPage',
        category: 'Translation Page',
        meta: { description: 'Right aligned panel', shortcut: { mac: '⌘ + →', win: 'Ctrl + →' } }
    },
    {
        command: 'PreviousElement',
        keyCombination: 'ArrowLeft',
        context: 'TranslationPage',
        category: 'Translation Page',
        meta: { description: 'Previous element', shortcut: '←' }
    },
    {
        command: 'NextElement',
        keyCombination: 'ArrowRight',
        context: 'TranslationPage',
        category: 'Translation Page',
        meta: { description: 'Next element', shortcut: '→' }
    },
    {
        command: 'ToggleAllElements',
        keyCombination: { mac: 'Cmd + H', win: 'Ctrl + H' },
        context: 'TranslationPage',
        category: 'Translation Page',
        meta: { description: 'Show/Hide all elements', shortcut: { mac: '⌘ + H', win: 'Ctrl + H' } }
    },
    {
        command: 'ExitTranslationPage',
        keyCombination: 'Escape',
        context: 'TranslationPage',
        category: 'Translation Page',
        meta: { description: 'Exit Translation Page', shortcut: 'Escape' }
    },
    {
        command: 'Undo',
        keyCombination: { win: 'Ctrl + Z', mac: 'Cmd + Z' },
        context: 'TranslationPage',
        category: 'Translation Page',
        meta: { description: 'Undo', shortcut: { mac: '⌘ + Z', win: 'Ctrl + Z' } }
    },
    {
        command: 'Redo',
        keyCombination: { win: 'Ctrl + Y', mac: 'Cmd + Shift + Z' },
        context: 'TranslationPage',
        category: 'Translation Page',
        meta: { description: 'Redo', shortcut: { mac: '⌘ + ⇧ + Z', win: 'Ctrl + Y' } }
    }
];
