export type Point = {
    width: number;
    x: number;
    y: number;
};

export type BrushHistory = Point[][];

export interface BrushSettings {
    history: BrushHistory;
    size: number;
}
