import { Injectable, ErrorHandler, inject } from '@angular/core';
import { environment } from '../../environments/environment';
import { getPrimaryError } from '@studio/utils/errors/apps-errors';
import { ErrorMessage } from '@studio/utils/errors/error-message.enum';
import { SentinelService } from '@bannerflow/sentinel';
import { Logger } from '@bannerflow/sentinel-logger';
import { ValidationError } from '@creative/serialization/validator.utils';
import { formatValidationError } from '@creative/serialization/document-validator';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    private logger = new Logger('GlobalErrorHandler');
    private sentinelService = inject(SentinelService);

    constructor() {
        // Handle out-of-zone errors.
        const adaptError: OnErrorEventHandler = (
            event: Event | string,
            _source?: string,
            _lineno?: number,
            _colno?: number,
            error?: Error
        ) => {
            this.handleError(error || event);
        };
        if (environment.production) {
            window.onerror = adaptError;
        }
    }

    handleError(error: Error | Event | string): void {
        // Get "real" error object when wrapped
        error = getPrimaryError(error);

        if (error['name'] === 'ObjectUnsubscribedError') {
            // Currently we have some issues with the subscriptions on the UIDropdownComponent
            // This might be because of setTimeout in the component.
            // Nothing is breaking any functionality but should be fixed
            // We do not have a solution for it yet...
            this.logger.warn(error);
            return;
        }

        // Errors like the expression changed error (don't do anything)
        if (error['ngDebugContext']) {
            this.logger.error(error);
            return;
        }

        if (filterErrorAsWarning(error.toString())) {
            this.logger.warn(error);
            return;
        }

        if (error instanceof ValidationError) {
            this.logger.warn(`Invalid document: \n${formatValidationError(error.errors)}`);
        }

        this.sentinelService.error(error);
    }
}

function filterErrorAsWarning(errorMessage: string): errorMessage is ErrorMessage {
    for (const key in ErrorMessage) {
        if (errorMessage.includes(ErrorMessage[key])) {
            return true;
        }
    }

    return false;
}
