import {
    getInAnimationDuration,
    getTimePropertyIsAnimating,
    isElementInMainStateAtTime,
    isTimeBetween,
    keyframesHasEqualStates,
    mergeOverlappingTimeAndDurations,
    MIN_KEYFRAME_DISTANCE,
    toAbsoluteTime
} from '../animation.utils';
import { IAnimation, IAnimationKeyframe, ITimeAndDuration } from '@domain/animation';
import { IExportGifSettings, IGifFrame } from '@domain/creativeset/creative/export-creative';
import { ElementKind } from '@domain/elements';
import { ICreativeDataNode, OneOfElementDataNodes } from '@domain/nodes';
import { roundToNearestMultiple } from '@studio/utils/utils';

const GIF_FRAME_DURATION = MIN_KEYFRAME_DISTANCE;
// Estimated weight per pixel
const BYTES_PER_PIXEL = 0.306;

interface IGifAnimationRange extends ITimeAndDuration {
    frames?: number;
}

export class GifExport {
    constructor(private creative: ICreativeDataNode) {}

    /**
     * Get a set of calculated frames by a desired file size & amount of colors
     * @param sizeKb
     * @param colors
     */
    getFramesByFileSize(sizeKb: number, colors: number): IGifFrame[] {
        const limit = this.getFrameLimitByFileSize(sizeKb, colors);
        return this.getFrames(limit);
    }

    getManualFrames(): IGifFrame[] {
        if (!this.creative.gifExport.frames.length) {
            return [];
        }
        const duration = this.creative.duration;

        // Clone to not affect original durations
        const frames = this.creative.gifExport.frames
            .filter(f => f.time <= duration)
            .map(f => ({ ...f }));

        this.setDurationsByDistance(frames, false);
        return frames;
    }

    /**
     * Get a set of calculated frames based on a set limit of frames
     * @param frameLimit
     */
    getFrames(frameLimit = 1000): IGifFrame[] {
        // Get all main states, at least one frame
        let gifFrames: IGifFrame[] = this.getMainFrames();

        this.rateFrames(gifFrames);

        // Limit is already reached
        if (gifFrames.length > frameLimit) {
            // First remove any duplicated
            gifFrames = this.removeAdjacentFramesWithSameCompositions(gifFrames);

            this.removeFramesByImportance(gifFrames, frameLimit);
        }

        const mainFrameLength = gifFrames.length;

        // Only do frames of the animations if can be a descent amount of them
        if (frameLimit - mainFrameLength > 0) {
            let animationFrames = this.getAnimationFrames(frameLimit - mainFrameLength);

            // Remove any frames already existing as main frames
            animationFrames = animationFrames.filter(
                frame => !gifFrames.some(f => roundTime(f.time) === roundTime(frame.time))
            );
            gifFrames.push(...animationFrames);
            gifFrames.sort(sort);

            const noFrameAtEnd =
                gifFrames[gifFrames.length - 1].time < this.creative.duration - GIF_FRAME_DURATION;
            const noFrameAtBeginning = gifFrames[0].time > GIF_FRAME_DURATION;

            // Add a frame in the beginning of the array if no frames is at the end or beginning of timeline and the add has more animation frames than main ones
            if (
                noFrameAtEnd &&
                noFrameAtBeginning &&
                gifFrames.length < frameLimit &&
                animationFrames.length > mainFrameLength
            ) {
                gifFrames.unshift({ time: 0, duration: 0 });
            }
        }

        this.setDurationsByDistance(gifFrames);
        gifFrames = gifFrames.filter(frame => frame.duration && frame.duration > 0);
        return this.removeAdjacentFramesWithSameCompositions(gifFrames).sort(sort);
    }

    /**
     * Get frames distrubuted over the animated parts of a creative.
     * @param maxAnimationFrames
     */
    getAnimationFrames(maxAnimationFrames?: number): IGifFrame[] {
        const elementRanges: ITimeAndDuration[] = [];
        this.creative.elements.forEach(element => {
            elementRanges.push(...getTimePropertyIsAnimating(element));
        });
        const roundedRanges = elementRanges.map(range => {
            return {
                duration: roundTime(range.duration),
                time: roundTime(range.time)
            };
        });
        const mergedRanges = mergeOverlappingTimeAndDurations(roundedRanges);
        const ranges = this.setFrameCountOnAnimationRanges(mergedRanges, maxAnimationFrames);
        return this.rangesToFrames(ranges);
    }

    getKeyframeImportance(
        element: OneOfElementDataNodes,
        animation: IAnimation,
        keyframe: IAnimationKeyframe
    ): number {
        if (keyframe.time !== 0 && keyframe.time !== element.duration) {
            const time = toAbsoluteTime(element, keyframe.time);
            const type = animation.type;
            const index = animation.keyframes.indexOf(keyframe);
            const isLast = index === animation.keyframes.length - 1;
            const composition = this.getCompositionAt(time);

            // Main state of element is way more important
            if ((type === 'in' || type === 'keyframe') && isLast) {
                return composition.length + 1;
            }
            // Start of out animation
            else if (type === 'out' && index === 0) {
                return -3 + composition.length / 4;
            } else if (type === 'keyframe') {
                return composition.length / 4 + (keyframe.duration > 0 ? 2 : 0);
            }
        }
        return -10000;
    }

    getMainFrames(): IGifFrame[] {
        const coverTolerance = 0.01;
        let frames: IGifFrame[] = [];

        for (const element of this.creative.elements) {
            const inDuration = getInAnimationDuration(element.animations);
            const startTime = toAbsoluteTime(element, inDuration);

            element.animations.forEach(animation => {
                animation.keyframes.forEach((keyframe, index) => {
                    const type = animation.type;
                    const previous = animation.keyframes[index - 1];
                    const isLast = index === animation.keyframes.length - 1;

                    // Only add keyframe if it differes from the previous keyframe
                    if (!previous || !keyframesHasEqualStates(element, previous, keyframe)) {
                        if (type === 'keyframe' || (type === 'in' && isLast)) {
                            const time = toAbsoluteTime(element, keyframe.time);
                            const composition = this.getCompositionAt(time);
                            const importance = this.getKeyframeImportance(element, animation, keyframe);

                            frames.push({ time, composition, importance });
                        }
                    }
                });
            });

            // No frame at start position, add it
            if (!hasFrameBetween(frames, startTime)) {
                const composition = this.getCompositionAt(startTime);
                const importance = composition.length + 1;
                frames.unshift({ time: startTime, composition, importance });
            }

            // Add end frame if element not covering timeline
            if (element.time + element.duration < this.creative.duration - coverTolerance) {
                const end = Math.min(
                    roundTime(element.time + element.duration + 0.001),
                    this.creative.duration
                );
                const composition = this.getCompositionAt(end);
                frames.push({ time: end, composition, importance: -3 + composition.length / 4 });
            }
        }

        // If no frames are added or no element is placed in the beginning => add a frame at 0
        if (!frames.length || !this.creative.elements.some(e => e.time < GIF_FRAME_DURATION)) {
            frames.unshift({
                time: 0,
                duration: 4,
                composition: []
            });
        }

        this.preventZeroDurationOverlaps(frames);
        frames = this.filterDuplicates(frames.sort(sort));
        this.setDurationsByDistance(frames);
        frames = frames.filter(frame => frame.duration && frame.duration > 0);
        return frames;
    }

    private removeAdjacentFramesWithSameCompositions(frames: IGifFrame[]): IGifFrame[] {
        return frames.filter((frame, index) => {
            if (index && this.isSameComposition(frame.composition, frames[index - 1].composition)) {
                return false;
            }
            return true;
        });
    }

    private removeFramesByImportance(frames: IGifFrame[], limit: number): IGifFrame[] {
        frames.sort(sortByImportance);

        frames.splice(limit);

        frames.sort(sort);

        return frames;
    }

    private rateFrames(frames: IGifFrame[]): IGifFrame[] {
        const compositionSizes = frames.map(
            frame => (frame.composition && frame.composition.length) || 0
        );
        const averageComposition = compositionSizes.reduce((total, num) => total + num) / frames.length;
        const { duration } = this.creative;

        // Individual rules
        frames.forEach((frame, index) => {
            const comp = frame.composition || [];
            const containsButton = comp.some(
                layer => this.getKindFromIndex(layer) === ElementKind.Button
            );
            const containsText = comp.some(layer => this.getKindFromIndex(layer) === ElementKind.Text);
            const onlyShapes = comp.every(
                layer =>
                    this.getKindFromIndex(layer) === ElementKind.Text ||
                    this.getKindFromIndex(layer) === ElementKind.Ellipse
            );

            let importance = frame.importance || 0;
            importance += containsButton ? 1 : 0;
            importance += containsText ? 1 : 0;
            importance += frame.time === 0 ? -4 : 0; // Start frame is less important
            importance += frame.time === duration ? -4 : 0; // End frame is less important
            importance += (index / frames.length) * -1; // Prio early elements
            importance += (comp.length / averageComposition) * 1; // Prio large compositions
            importance += ((frame.duration || 0) / duration) * 3; // Prio long durations
            importance += onlyShapes ? -2 : 0;

            // frame.importance += containsButton ? 1 : 0;

            frame.importance = importance;
        });

        // Rate the frame containing preview frame position high
        const preloadFrame = this.creative.preloadImage.frames[0];
        if (preloadFrame !== undefined) {
            for (let i = frames.length - 1; i >= 0; i--) {
                const f = frames[i];
                if (f.time <= preloadFrame && f.importance !== undefined) {
                    f.importance += 2;
                    break;
                }
            }
        }

        // Rate frames that share composition less
        frames.forEach((frame, index) => {
            let maxShared = -1;
            let maxFrame;
            frames.forEach(f => {
                if (f !== frame) {
                    const shared = this.getSharedComposition(f.composition, frame.composition);
                    if (shared.length > maxShared) {
                        maxShared = shared.length;
                        maxFrame = f;
                    }
                }
            });

            // Only set importance once
            if (maxShared > 0 && maxFrame && frames.indexOf(maxFrame) > index) {
                // Get the less important frame
                const secondaryFrame = maxFrame.importance < frame.importance! ? maxFrame : frame;
                secondaryFrame.importance += (maxShared / secondaryFrame.composition.length) * -4;
            }
        });
        return frames;
    }

    private rangeToFrames(range: IGifAnimationRange): IGifFrame[] {
        const frames: IGifFrame[] = [];
        if (range.frames && range.duration) {
            const distance = range.duration / (range.frames + 1);
            for (let i = 1; i <= range.frames; i++) {
                frames.push({
                    time: roundTime(range.time + distance * i),
                    duration: roundTime(distance)
                });
            }
        }
        return frames;
    }

    private rangesToFrames(ranges: IGifAnimationRange[]): IGifFrame[] {
        const frames: IGifFrame[] = [];

        ranges.forEach(range => frames.push(...this.rangeToFrames(range)));
        return frames;
    }

    private setDurationsByDistance(frames: IGifFrame[], overwriteDurations = true): GifExport {
        if (!frames || !frames.length) {
            throw new Error(
                `Can't set durations without frames. Run setFrames() before invoking this method.`
            );
        }

        if (frames.length > 1) {
            for (let i = 1; i < frames.length; i++) {
                const f1 = frames[i - 1];
                const f2 = frames[i];
                if (typeof f1.duration !== 'number' || overwriteDurations) {
                    f1.duration = roundTime(f2.time - f1.time);
                }
            }
            // Last frame duration will be the time to the first frame
            if (typeof frames[frames.length - 1].duration !== 'number' || overwriteDurations) {
                frames[frames.length - 1].duration = roundTime(
                    this.creative.duration - frames[frames.length - 1].time + frames[0].time
                );
            }
        } else if (typeof frames[0].duration !== 'number' || overwriteDurations) {
            frames[0].duration = 4;
        }

        return this;
    }

    private setFrameCountOnAnimationRanges(
        ranges: ITimeAndDuration[],
        maxFrames = 1000
    ): IGifAnimationRange[] {
        ranges = ranges.filter(range => range.duration >= GIF_FRAME_DURATION);

        const totalDuration = ranges.reduce((sum, range) => sum + range.duration, 0);

        const frameCount = Math.min(maxFrames, totalDuration / GIF_FRAME_DURATION);
        const frameDuration = totalDuration / frameCount;

        return ranges
            .map(range => ({
                ...range,
                frames: Math.floor(range.duration / frameDuration)
            }))
            .filter(range => range.frames > 0);
    }

    private filterDuplicates(frames: IGifFrame[]): IGifFrame[] {
        const filtered: IGifFrame[] = [];

        frames.sort(sort).forEach(frame => {
            const last = filtered[filtered.length - 1];

            // Already existing frame in this time slot
            if (
                last &&
                roundToNearestMultiple(last.time, GIF_FRAME_DURATION) ===
                    roundToNearestMultiple(frame.time, GIF_FRAME_DURATION)
            ) {
                // If the new frame is more important, update last one
                if (getImportanceOfFrame(frame) > getImportanceOfFrame(last)) {
                    last.time = frame.time;
                    last.importance = getImportanceOfFrame(frame);
                    last.composition = frame.composition;
                }
            }
            // No existing frame in this time slot
            else {
                filtered.push({ ...frame });
            }
        });

        return filtered;
    }

    /**
     * When an element ends exactly the same time as another start
     * They might be in the same composition causing it to be higher rated.
     */
    private preventZeroDurationOverlaps(frames: IGifFrame[]): void {
        frames.forEach(frame => {
            // Get all elements at this point of time
            const elements = this.getElementsFromComposition(frame.composition);

            // Is one of the element starting to animate in this time
            const isStartFrame = elements.some(element => element.time === frame.time);
            const isEndFrame = elements.some(element => element.time + element.duration === frame.time);

            // Remove any elements ending at this point in time
            if (isStartFrame && isEndFrame && frame.composition) {
                frame.time = Math.min(frame.time + 0.01, this.creative.duration);
                frame.composition = frame.composition.filter(elementIndex => {
                    const el = this.creative.elements[elementIndex];
                    return el.time + el.duration !== frame.time;
                });
            }
        });
    }

    private getFrameLimitByFileSize(sizeKb: number, colors: number): number {
        // sizeKb = 0.306*frames*height*width*(colors/255)/1000

        const { width, height } = this.creative;
        const area = width * height;
        const colorWeight = colors / 255;
        return Math.max(1, Math.floor((sizeKb * 1000) / (area * colorWeight * BYTES_PER_PIXEL)));
    }

    private getElementsFromComposition(composition: number[] = []): OneOfElementDataNodes[] {
        return composition.map(elementIndex => this.creative.elements[elementIndex]);
    }

    private getCompositionAt(time: number): number[] {
        return this.creative.elements
            .map((element, index) => (isElementInMainStateAtTime(element, time) ? index : -1))
            .filter(index => index > -1);
    }

    private isSameComposition(comp1?: number[], comp2?: number[]): boolean {
        if (!comp1 || !comp2 || comp1.length !== comp2.length) {
            return false;
        }
        return comp1.every((layer, index) => comp2[index] === layer);
    }

    private getSharedComposition(comp1?: number[], comp2?: number[]): number[] {
        if (!comp1 || !comp2 || !comp1.length || !comp2.length) {
            return [];
        }
        return comp1.filter(layer => comp2.indexOf(layer) !== -1);
    }

    private getKindFromIndex(index: number): ElementKind {
        return this.creative.elements[index].kind;
    }
}

function getImportanceOfFrame(frame: IGifFrame): number {
    return frame.importance !== undefined ? frame.importance : -10000;
}

function roundTime(time: number): number {
    return roundToNearestMultiple(time, 0.0001);
}

const sort = (p1: IGifFrame, p2: IGifFrame): number => {
    if (p1.time === p2.time) {
        return getImportanceOfFrame(p2) - getImportanceOfFrame(p1);
    }
    return p1.time - p2.time;
};

const sortByImportance = (p1: IGifFrame, p2: IGifFrame): number =>
    (p2.importance || 0) - (p1.importance || 0);

export function hasFrameBetween(frames: IGifFrame[], from: number, to?: number): boolean {
    if (typeof to !== 'number') {
        to = from;
    }

    return getFramesBetween(frames, from, to).length > 0;
}

export function getFramesBetween(frames: IGifFrame[], from: number, to: number): IGifFrame[] {
    return frames.filter(f => isTimeBetween(f.time, from, to, 0.00001));
}

export function getGifFrames(
    creative: ICreativeDataNode,
    colors: number,
    { fileSizeLimit, frameLimit, gifFrameLimitMode }: IExportGifSettings
): IGifFrame[] {
    const gifExport = new GifExport(creative);
    let gifFrames: IGifFrame[] = [];

    switch (gifFrameLimitMode) {
        case 'file-size':
            gifFrames = gifExport.getFramesByFileSize(fileSizeLimit || 300, colors);
            break;
        case 'frame-count':
            gifFrames = gifExport.getFrames(frameLimit);
            break;
        case 'manual':
            gifFrames = gifExport.getManualFrames();
            break;
    }

    // Remove bloated properties
    return gifFrames.map(frame => ({
        time: frame.time,
        duration: typeof frame.duration === 'number' ? frame.duration : 4
    }));
}
