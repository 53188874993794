import { CommonModule } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    ViewChild
} from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { CreativeSize } from '@domain/creativeset';
import { IVersion } from '@domain/creativeset/version';
import { ISelectableSize } from '@studio/domain/components/size-list.types';
import { Observable } from 'rxjs';
import { StudioCreativeComponent } from '../../../../shared/components/creative/studio-creative.component';
import { CreativesetDataService } from '../../../../shared/creativeset/creativeset.data.service';
import { VersionsService } from '../../../../shared/versions/state/versions.service';
import { SizeAddService } from '../size-add.service';
import { SizeAddTooltipComponent } from './size-add-tooltip/size-add-tooltip.component';

@Component({
    standalone: true,
    imports: [CommonModule, UIModule, SizeAddTooltipComponent, StudioCreativeComponent],
    selector: 'size-add-preview',
    templateUrl: './size-add-preview.component.html',
    styleUrls: ['./size-add-preview.component.scss']
})
export class SizeAddPreviewComponent implements AfterViewInit, OnDestroy {
    @Input() sizes: ISelectableSize[];
    @Output() onCancel = new EventEmitter<void>();

    @ViewChild('creativePreviewWrapper', { static: true }) creativePreviewWrapper: ElementRef;

    zoomValue = 100;
    version$: Observable<IVersion>;

    private mutationObserver: MutationObserver;

    constructor(
        private cdr: ChangeDetectorRef,
        private creativesetDataService: CreativesetDataService,
        private sizeAddService: SizeAddService,
        private versionService: VersionsService
    ) {
        // if all version is selected, it will use first
        this.version$ = this.versionService.selectedVersion$;
    }

    ngAfterViewInit(): void {
        this.mutationObserver = new MutationObserver(this.autoZoom);
        this.mutationObserver.observe(this.creativePreviewWrapper.nativeElement, {
            childList: true,
            attributes: true
        });
    }

    ngOnDestroy(): void {
        this.mutationObserver.disconnect();
    }

    cancel(): void {
        this.onCancel.emit();
    }

    onAddSizes = async (): Promise<void> => {
        const sizes: CreativeSize[] = this.sizes.map(size => ({
            id: size.id,
            name: size.name,
            width: size.width,
            height: size.height
        }));
        await this.sizeAddService.addSizes(this.creativesetDataService.creativeset.id, sizes);
    };

    onAddSizesFinished = (): void => {
        this.cancel();
    };

    private autoZoom = (): void => {
        const parent: HTMLElement = this.creativePreviewWrapper.nativeElement;
        let zoom = 1;
        this.zoomValue = 100;
        this.cdr.detectChanges();

        while (parent.scrollHeight > parent.offsetHeight || parent.scrollWidth > parent.offsetWidth) {
            zoom = Math.max(zoom - 0.1, 0.05);
            this.zoomValue = Math.round(zoom * 100);

            this.cdr.detectChanges();

            if (zoom === 0.05) {
                break;
            }
        }
    };
}
