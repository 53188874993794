import { StreamingHttpClient } from './streaming-http-client';
import { ManifestManager } from './manifest/manifest-manager';
import { MediaType } from './manifest/manifest.model';

export class MediaStreamer {
    private readonly _httpClient: StreamingHttpClient;
    private readonly _mediaElement: HTMLVideoElement | HTMLAudioElement;
    private readonly _type: MediaType;
    private _manifestManager: ManifestManager;
    private _mediaSource: MediaSource;
    private _sourceBuffer: SourceBuffer;
    private _mediaSrc: string;

    constructor(
        mediaElement: HTMLVideoElement | HTMLAudioElement,
        type: MediaType,
        manifestManager: ManifestManager,
        httpClient: StreamingHttpClient
    ) {
        this._httpClient = httpClient;
        this._mediaElement = mediaElement;
        this._type = type;
        this._manifestManager = manifestManager;
    }

    /**
     * Initializes a MediaSource and returns the new `src` value.
     * Starts the stream when the source is opened.
     */
    async init(): Promise<string> {
        console.debug(`[MediaStreamer:${this._type}] init`);
        const codecs = this._manifestManager.getCodecs(this._type);
        const MIME_CODEC = `${this._type}/mp4; codecs="${codecs}"`;

        this._mediaSource = new MediaSource();

        this._mediaSource.addEventListener('error', error => {
            console.error('MediaSource error:', error);
        });

        this._mediaSource.addEventListener('sourceopen', async () => {
            this._mediaSource.duration = this._manifestManager.getTotalDuration();
            console.debug(
                `[MediaStreamer:${this._type}] MediaSource open, duration: `,
                this._mediaSource.duration
            );

            this._sourceBuffer = this._mediaSource.addSourceBuffer(MIME_CODEC);
            try {
                await this.startStream();
            } catch (err: unknown) {
                console.error('Error starting media stream', err);
            }
        });

        this._mediaSrc = URL.createObjectURL(this._mediaSource);
        return this._mediaSrc;
    }

    /**
     * Starts the chunk fetching and buffer process.
     * @private
     */
    private async startStream(): Promise<void> {
        const baseUrl = this._manifestManager.getBaseUrl();
        await this._loadInitialChunk(baseUrl);
        await this._loadChunks(baseUrl);
    }

    /**
     * Loads the initialization chunk and appends it to the source buffer.
     * The initialization chunk contains metadata required to initialize the media decoder.
     * @param baseUrl
     * @private
     */
    private async _loadInitialChunk(baseUrl: string): Promise<void> {
        const initializationUrl = this._manifestManager.getInitUrl(baseUrl, this._type);
        console.debug(`[MediaStreamer:${this._type}] loading initial chunk`);
        await this._httpClient.fetchChunkAndAppend(initializationUrl, this._sourceBuffer);
    }

    private async _loadChunks(baseUrl: string): Promise<void> {
        const totalChunks = this._manifestManager.getTotalChunks();
        let chunkIndex = 0;
        console.debug(`[MediaStreamer:${this._type}] loading ${totalChunks} chunk(s)`);

        while (chunkIndex < totalChunks) {
            const chunkUrl = this._manifestManager.getChunkUrl(baseUrl, chunkIndex, this._type);
            console.debug(`[MediaStreamer:${this._type}] Next chunk: ${chunkIndex + 1}/${totalChunks}`);
            try {
                if (this._sourceBuffer.updating) {
                    console.error('sourceBuffer should already been updated.');
                }
                if (this._mediaElement.error) {
                    console.error(this._mediaElement.error);
                }

                await this._httpClient.fetchChunkAndAppend(chunkUrl, this._sourceBuffer);
                // this._manifestManager.verifyCurrentRepresentation(this._httpClient.bps, this._type);
                chunkIndex++;
            } catch (err: unknown) {
                console.error('Error fetching and appending chunk', err);
                break;
            }
        }
        console.debug(`[MediaStreamer: ${this._type}] Fetched all chunks: ${totalChunks}`);
        this._mediaSource.endOfStream();
    }
}
