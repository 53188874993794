import { IColor } from '@domain/color';

export interface IColorPalette {
    name?: string;
    swatch: IColor[];
}

export interface IAuxColorPalette extends IColorPalette {
    selected: boolean;
}
