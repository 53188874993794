import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Logger } from '@bannerflow/sentinel-logger';
import { isImageElement, isVideoElement } from '@creative/nodes/helpers';
import { OneOfLibraryAssets } from '@domain/brand/brand-library';
import { isHeavyVideo, isMP4File } from '@studio/utils/media';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
    AssetUploadService,
    IAssetUploadAfterProgressState,
    IAssetUploadLoadedState,
    IAssetUploadState
} from '../services/asset-upload.service';
import { HeavyVideoService } from '../video';

@Injectable()
export class AssetPickerUploadService {
    assetUploadProcessId: string;
    private _assetUploadComplete$ = new Subject<OneOfLibraryAssets>();
    assetUploadComplete$ = this._assetUploadComplete$.asObservable();

    private logger = new Logger('AssetPickerUploadService');

    constructor(
        private assetUploadService: AssetUploadService,
        private heavyVideoService: HeavyVideoService
    ) {
        this.assetUploadService.uploadProgress$
            .pipe(
                filter(uploadState => this.assetUploadProcessId === uploadState.uploadProcessId),
                takeUntilDestroyed()
            )
            .subscribe(this.onAssetUploadStateChange);
    }

    async uploadAssets(files: File[]): Promise<void> {
        this.logger.verbose(`Uploading assets from asset-picker`);

        const hasHeavyVideo = files.some(file => isMP4File(file) && isHeavyVideo(file.size));

        if (hasHeavyVideo) {
            const allowVideo = await this.heavyVideoService.promptHeavyVideo();
            if (!allowVideo) {
                return;
            }
        }

        const id = await this.assetUploadService.uploadAssets({ files });
        if (id) {
            this.assetUploadProcessId = id;
        }
    }

    private onAssetUploadStateChange = (uploadState: IAssetUploadState): void => {
        switch (uploadState.status) {
            case 'LOAD_ASSET_FILE_STARTED':
                this.uploadStarted(uploadState);
                break;
            case 'AFTER_PROGRESS':
                this.uploadFinished(uploadState);
                break;
            case 'COMPLETE':
                this.logger.verbose(`Asset upload completed`);
                break;
            case 'FAIL':
                this.logger.warn(`Asset upload failed`);
                break;
            default:
                break;
        }
    };

    private uploadStarted(uploadState: IAssetUploadLoadedState): void {
        this.logger.verbose(`Asset[${uploadState.file.name}] upload started`);

        const { asset, element } = uploadState;
        if ('original' in asset && isImageElement(element)) {
            asset.original.url = uploadState.url;
            asset.original.height = uploadState.size.height;
            asset.original.width = uploadState.size.width;
        } else if (isVideoElement(element)) {
            asset.width = uploadState.size.width;
            asset.height = uploadState.size.width;
        }

        uploadState.asset.name = uploadState.file.name;
    }

    private uploadFinished(uploadState: IAssetUploadAfterProgressState): void {
        this.logger.verbose(`Asset[${uploadState.asset.name}] upload finished`);

        const asset = uploadState.newAsset;

        this._assetUploadComplete$.next(asset);
    }
}
