import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UIConfirmDialogService, UIModule, UISubmitResponse } from '@bannerflow/ui';
import { isFeedValue, isVersionedFeed } from '@creative/elements/feed/feeds.utils';
import { isVersionedText } from '@creative/elements/rich-text/utils';
import { isOrphanElement, isTextLikeElement, isWidgetElement } from '@creative/nodes/helpers';
import { IElement } from '@domain/creativeset/element';
import { IVersion, IVersionedElementPropertyValuesByElement } from '@domain/creativeset/version';
import { SpanType } from '@domain/text';
import { concatLatestFrom } from '@ngrx/operators';
import { EventLoggerService, OrphanElementFoundEvent } from '@studio/monitoring/events';
import { BehaviorSubject, Observable, Subject, merge } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { CreativesetDataService } from '../../../shared/creativeset/creativeset.data.service';
import { PermissionsDirective } from '../../../shared/directives/permissions.directive';
import { FiltersService } from '../../../shared/filters/state/filters.service';
import { VersionsService } from '../../../shared/versions/state/versions.service';
import { NavigationGuard } from '../../index';
import { EditCreativeService } from '../services/edit-creative.service';
import { MvFeedPropertiesComponent } from './mv-feed-properties/mv-feed-properties.component';
import { MvTextPropertiesComponent } from './mv-text-properties/mv-text-properties.component';
import { MvWidgetPropertiesComponent } from './mv-widget-properties/mv-widget-properties.component';
import { TranslationPanelService } from './translation-panel.service';

interface SortedElements {
    orphans: IVersionedElementPropertyValuesByElement[];
    texts: IVersionedElementPropertyValuesByElement[];
    widgets: IVersionedElementPropertyValuesByElement[];
    feeds: IVersionedElementPropertyValuesByElement[];
}

@Component({
    standalone: true,
    imports: [
        CommonModule,
        UIModule,
        MvTextPropertiesComponent,
        MvFeedPropertiesComponent,
        MvWidgetPropertiesComponent,
        PermissionsDirective
    ],
    selector: 'translation-panel',
    templateUrl: './translation-panel.component.html',
    styleUrls: ['./translation-panel.component.scss']
})
export class TranslationPanelComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();
    propertiesExists: boolean;
    sortedElements$ = new BehaviorSubject<SortedElements>({
        orphans: [],
        texts: [],
        widgets: [],
        feeds: []
    });
    versionsLoaded$: Observable<boolean>;
    isShowingAllVersions$: Observable<boolean>;

    isDirty = false;

    private defaultVersion: IVersion;

    constructor(
        private translationPanelService: TranslationPanelService,
        private creativesetDataService: CreativesetDataService,
        private editCreativeService: EditCreativeService,
        private uiConfirmDialogService: UIConfirmDialogService,
        private navigationGuard: NavigationGuard,
        private eventLoggerService: EventLoggerService,
        private versionsService: VersionsService,
        private filtersService: FiltersService
    ) {
        this.versionsLoaded$ = this.versionsService.loaded$.pipe(filter(Boolean));
        this.isShowingAllVersions$ = this.filtersService.isShowingAllVersions$;
        this.translationPanelService.pristineCallback = this.checkPristineState;

        merge(
            this.versionsService.versions$,
            this.versionsService.selectedVersion$,
            this.editCreativeService.updateView$
        )
            .pipe(
                takeUntil(this.unsubscribe$),
                concatLatestFrom(() => [
                    this.versionsService.defaultVersion$,
                    this.versionsService.selectableVersions$,
                    this.versionsService.selectedVersion$,
                    this.filtersService.isShowingAllVersions$
                ])
            )
            .subscribe(([_, defaultVersion, versions, selectedVersion, isShowingAllVersions]) => {
                this.defaultVersion = defaultVersion;
                this.translationPanelService.defaultVersion = defaultVersion;
                this.translationPanelService.versions = versions;
                this.translationPanelService.selectedVersion = selectedVersion;
                this.propertiesExists = this.defaultVersion.properties.length > 0;
                if (!this.isDirty && !isShowingAllVersions) {
                    this.initState();
                }
            });

        this.translationPanelService.dirty$.pipe(takeUntil(this.unsubscribe$)).subscribe(dirty => {
            if (this.isDirty !== dirty) {
                this.isDirty = dirty;
            }
        });
    }

    async initState(): Promise<void> {
        const elements = await this.sortElements();
        this.translationPanelService.setStartDirtyState([
            ...elements.orphans,
            ...elements.texts,
            ...elements.widgets,
            ...elements.feeds
        ]);
    }

    ngOnInit(): void {
        this.navigationGuard.addPristineUnloadCheck(this.isPristine);
        this.navigationGuard.addPristineCheck(this.checkPristineState);
        this.versionsService.updateShouldCheckPristine(true);
    }

    ngOnDestroy(): void {
        this.navigationGuard.removePristineUnloadCheck(this.isPristine);
        this.navigationGuard.removePristineCheck(this.checkPristineState);
        this.versionsService.updateShouldCheckPristine(false);
        this.translationPanelService.setNewDirty(false);
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private isPristine = (): boolean => !this.isDirty;

    private async sortElements(): Promise<SortedElements> {
        const sortedElements: SortedElements = {
            orphans: [],
            texts: [],
            widgets: [],
            feeds: []
        };
        const texts: IElement[] = [];
        const widgets: IElement[] = [];
        // Workaround to still have those properties in the request when saving, but not showing them in the panel
        const orphans: IVersionedElementPropertyValuesByElement[] = [];

        const allElements = [...this.creativesetDataService.creativeset.elements];
        const nonOrphanElements: IElement[] = [];

        for (const element of allElements) {
            if (isOrphanElement(element, this.creativesetDataService.creativeset)) {
                this.eventLoggerService.log(
                    new OrphanElementFoundEvent(this.creativesetDataService.creativeset.id)
                );
                const orphanWithProps = this.getVersionedPropertiesForOrphan(element);
                orphans.push(...orphanWithProps);
                continue;
            } else {
                nonOrphanElements.push(element);
            }
            if (isTextLikeElement(element)) {
                texts.push(element);
            }
            if (isWidgetElement(element)) {
                widgets.push(element);
            }
        }

        sortedElements.orphans = orphans;
        sortedElements.texts =
            this.translationPanelService.getElementsWithVersionedPropertyValues(texts);
        sortedElements.feeds = this.sortFeeds(nonOrphanElements);

        if (widgets.length > 0) {
            sortedElements.widgets =
                this.translationPanelService.getElementsWithVersionedPropertyValues(widgets);

            // Filter out widget feed properties as those are sorted as feeds
            sortedElements.widgets = sortedElements.widgets.filter(widget => {
                widget.properties = widget.properties.filter(property => !isVersionedFeed(property));
                if (widget.properties.length > 0) {
                    return true;
                }

                return false;
            });
        }

        this.sortedElements$.next(sortedElements);
        return sortedElements;
    }

    private getVersionedPropertiesForOrphan(
        element: IElement
    ): IVersionedElementPropertyValuesByElement[] {
        return [
            ...this.sortFeeds([element]),
            ...this.translationPanelService.getElementsWithVersionedPropertyValues([element])
        ];
    }

    private checkPristineState = async (): Promise<boolean> => {
        if (this.isDirty) {
            const result = await this.uiConfirmDialogService.confirm({
                headerText: 'Save translation',
                confirmText: 'Save',
                discardText: "Don't save",
                text: 'Do you want to save your translation changes before proceeding?',
                showCancelButton: true,
                onConfirm: () => this.submit()
            });
            if (result === 'discard' || result === 'confirm') {
                this.isDirty = false;
                return true;
            }
            return false;
        }
        return true;
    };

    submit = async (): Promise<UISubmitResponse<string>> => {
        const result = await this.translationPanelService.saveVersionedElementPropertyValues();
        this.isDirty = false;
        return result;
    };

    finishSaving = (error: unknown, versionName: string): void =>
        this.translationPanelService.saveVersionedElementPropertyValuesFinished(error, versionName);

    private sortFeeds(elements: IElement[]): IVersionedElementPropertyValuesByElement[] {
        const sortedElements: IVersionedElementPropertyValuesByElement[] = [];
        for (const element of this.translationPanelService.getElementsWithVersionedPropertyValues(
            elements
        )) {
            const isWidget = isWidgetElement(elements.find(({ id }) => id === element.elementId));
            for (const property of element.properties) {
                let isFeed = false;

                if (isVersionedText(property)) {
                    if (property.value && property.value.styles) {
                        isFeed = !!property.value.styles.find(
                            style => style.type === SpanType.Variable && isFeedValue(style.variable)
                        );
                    }
                } else if (isVersionedFeed(property)) {
                    isFeed = true;
                }

                if (isFeed) {
                    sortedElements.push({
                        ...element,
                        properties: [property],
                        isWidget
                    });
                }
            }
        }

        return sortedElements;
    }

    cancelChanges(elements: SortedElements): void {
        this.translationPanelService.cancelChanges([
            ...elements.texts,
            ...elements.widgets,
            ...elements.feeds
        ]);
        this.initState();
    }
}
