import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommentsModule } from '@bannerflow/comments';
import { UIModule } from '@bannerflow/ui';
import { AiImageGenerationPopoverComponent } from './ai-studio/ai-image-generation-popover/ai-image-generation-popover.component';
import * as C from './components';
import * as D from './directives';
import * as P from './pipes';
import * as S from './services';

@NgModule({
    imports: [
        CommonModule,
        UIModule,
        CommentsModule,

        // standalone
        // Directives
        D.PermissionsDirective,
        D.MediaDirective,
        D.TruncateSpanComponent,
        D.MultilineEllipsisDirective,
        D.ObserveVisibilityDirective,

        C.VersionLanguagePickerComponent,
        C.VersionFlagComponent,
        C.ToolbarButtonComponent,
        C.VersionPickerComponent,
        C.ButtonToggleComponent,
        C.ColorButtonComponent,
        C.FontPickerComponent,
        C.FeedBrowserComponent,
        C.FeatureToggleComponent,
        C.StudioKeymapsComponent,
        C.FeedPickerComponent,
        C.ItemListOverflowComponent,
        C.OverflowItemComponent,
        C.StudioUISectionComponent,
        C.StudioListSectionComponent,
        C.StudioUISliderComponent,
        C.TopbarContextMenuComponent,
        C.ToggleEnvironmentComponent,
        C.CommentsOverviewComponent,
        C.UserIconComponent,
        C.CurrentUsersComponent,
        C.StudioCreativeComponent,
        C.ManageWarningsDropdownComponent,
        C.ColorPaletteComponent,
        C.ColorPickerComponent,
        AiImageGenerationPopoverComponent,

        // Pipes
        P.CapitalizePipe,
        P.DotDotDotPipe,
        P.PickerPipe,
        P.SocialImagePipe,
        P.FractionToPercentagePipe,
        P.ReplaceString,
        P.BrandLibraryElementTooltipPipe,
        P.ReversePipe,
        P.FilterGroupedSizeCreativesPipe,
        P.FilterCreativesPipe,
        P.GroupFeedsPipe,
        P.SortGroupPipe,
        P.GroupWidgetsPipe,
        P.GroupTextsPipe,
        P.GroupHeadlinePipe,
        P.LocalizationForVersionPipe,
        P.SortByGroupWidthPipe,
        P.SortArrayPipe,
        P.VersionFromIdPipe
    ],
    declarations: [
        C.ButtonToggleGroupComponent,
        C.ButtonToggleGroupItemComponent,
        C.CanvasDrawerComponent,
        C.ColorSectionComponent,
        C.ColorHeaderComponent,
        C.FeedPopoverComponent,
        C.FontManagerComponent,
        C.SectionExpandComponent,
        C.TopbarBreadcrumbsComponent,
        C.CreativesetInUseDialogComponent,
        C.QRCodeModalComponent,
        C.ProgressBarComponent
    ],
    providers: [
        // Prefer { providedIn: 'root' } in the @Injectable options of the service
        { provide: 'CopyStore', useFactory: (): S.Store<unknown> => new S.Store() }
    ],
    exports: [
        C.ButtonToggleComponent,
        C.ButtonToggleGroupComponent,
        C.ButtonToggleGroupItemComponent,
        C.CanvasDrawerComponent,
        C.ColorPaletteComponent,
        C.ColorPickerComponent,
        C.ColorButtonComponent,
        C.ColorSectionComponent,
        C.ColorHeaderComponent,
        C.FeedBrowserComponent,
        C.FeedPickerComponent,
        C.FeedPopoverComponent,
        C.FontManagerComponent,
        C.FontPickerComponent,
        C.ItemListOverflowComponent,
        C.OverflowItemComponent,
        C.StudioKeymapsComponent,
        C.StudioUISectionComponent,
        C.StudioListSectionComponent,
        C.SectionExpandComponent,
        C.StudioUISliderComponent,
        C.ToolbarButtonComponent,
        C.TopbarBreadcrumbsComponent,
        C.TopbarContextMenuComponent,
        C.VersionPickerComponent,
        C.VersionFlagComponent,
        C.ToggleEnvironmentComponent,
        C.CommentsOverviewComponent,
        C.UserIconComponent,
        C.CurrentUsersComponent,
        C.CreativesetInUseDialogComponent,
        C.ManageWarningsDropdownComponent,
        C.QRCodeModalComponent,
        C.ProgressBarComponent,
        C.StudioCreativeComponent,
        C.FeatureToggleComponent,
        AiImageGenerationPopoverComponent,

        // Directives
        D.MultilineEllipsisDirective,
        D.PermissionsDirective,
        D.MediaDirective,
        D.ObserveVisibilityDirective,

        // Pipes
        P.CapitalizePipe,
        P.DotDotDotPipe,
        P.PickerPipe,
        P.FractionToPercentagePipe,
        P.SocialImagePipe,
        P.ReplaceString,
        P.BrandLibraryElementTooltipPipe,
        P.ReversePipe,
        P.VersionFromIdPipe,
        P.FilterGroupedSizeCreativesPipe,
        P.FilterCreativesPipe,
        P.GroupFeedsPipe,
        P.SortGroupPipe,
        P.GroupWidgetsPipe,
        P.GroupTextsPipe,
        P.GroupHeadlinePipe,
        P.LocalizationForVersionPipe,
        P.SortByGroupWidthPipe,

        // Propagate module export
        CommonModule,
        CommentsModule,
        UIModule
    ]
})
export class SharedModule {}
