import { NumberAndStringNodeProperties } from '@domain/nodes';
import { Formula } from '@domain/state';
import { clamp, decimal, toFixedDecimal } from '@studio/utils/utils';
import { isFormulaAllowedProperty, isHighPrecisionProperty } from './property-serializer';

export function serializeNumberOrFormula<Value extends number | string | Formula>(
    value: Value,
    property: NumberAndStringNodeProperties
): Value {
    if (typeof value === 'number') {
        switch (property) {
            case 'x':
            case 'y':
                return roundPosition(serializeNumber(value)) as Value;
            default:
                return serializeNumber(value, isHighPrecisionProperty(property)) as Value;
        }
    } else if (typeof value === 'string' && isFormulaAllowedProperty(property)) {
        return serializeFormula(value) as Value;
    }

    throw new Error('Unexpected number or formula value.');
}

export function serializeNumber(value: number, isHighPrecision = false): number {
    if (isHighPrecision) {
        return toFixedDecimal(value, 6);
    }
    return decimal(value);
}

function serializeFormula(value: string | Formula): string | Formula {
    if (value.indexOf('@') > -1) {
        return value;
    }
    throw new Error('Unexpected formula value.');
}

export function roundDimension(value: number): number {
    return Math.round(clamp(value, 1, 9999999));
}

export function roundPosition(value: number): number {
    return Math.round(clamp(value, -9999999, 9999999));
}

export function ensureStringOrDefault(value: unknown, defaultValue = ''): string {
    return typeof value === 'string' ? value : defaultValue;
}

export function ensureNumberOrDefault(value: unknown, defaultValue = 1): number {
    return typeof value === 'number' ? value : defaultValue;
}

export function ensureEnumOrDefault<T>(
    value: unknown,
    posibleValues: T[],
    defaultValue: T = posibleValues[0]
): T {
    if (posibleValues.some(posibleValue => posibleValue === value)) {
        return value as T;
    }
    return defaultValue;
}
