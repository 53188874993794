<ng-container *ngIf="showToggleEnvironment">
    <ui-select
        class="branch"
        [placeholder]="currentBranch">
        <ui-option
            *ngIf="!isOnMain"
            (click)="switchToMain()">
            Switch to Main
        </ui-option>
        <ui-dropdown-divider></ui-dropdown-divider>
        <ui-option
            *ngFor="let prBranchName of pullRequests$ | async"
            (click)="selectPullRequest(prBranchName)">
            {{ prBranchName | dotdotdot: 20 }}
        </ui-option>
    </ui-select>
</ng-container>
