<div class="wrapper">
    @if (!expanded()) {
        <div class="prompt">
            <div
                class="text"
                [ngClass]="{ disabled: disabled() }"
                (click)="expand()">
                <ui-svg-icon icon="cancel-24" />
                @switch (type()) {
                    @case (ExpandablePromptInputType.NegativePrompt) {
                        <span> Add negative prompt</span>
                        <span class="optional-text"> (optional)</span>
                    }
                    @case (ExpandablePromptInputType.SearchPrompt) {
                        <span> Add a search prompt</span>
                        <span class="optional-text"> (optional)</span>
                    }
                    @case (ExpandablePromptInputType.GenerativeFillPrompt) {
                        <span> Add a generative fill prompt</span>
                        <span class="optional-text"> (optional)</span>
                    }
                }
            </div>
            <ui-icon
                class="help-icon"
                icon="help"
                uiTooltipPosition="bottom"
                [uiTooltip]="labelText()" />
        </div>
    } @else {
        <div class="prompt-input">
            <div class="label">
                <span>
                    {{ labelText() }}
                </span>
                @if (optionalInLabel()) {
                    <span class="optional-text"> (optional)</span>
                }
            </div>
            <ui-textarea
                [autofocus]="true"
                [blurOnSubmit]="true"
                [maxCharacters]="2000"
                [placeholder]="placeholderText()"
                [disabled]="disabled()"
                [value]="value()"
                (valueChange)="onPromptValueChange($event)"></ui-textarea>
        </div>
    }
</div>
