<div class="campaigns">
    <div class="warning">
        <ui-svg-icon
            class="warning-icon"
            icon="warning"></ui-svg-icon>
        The weight of this video is over 2MB!
    </div>

    <ng-container *ngIf="allowHeavyVideo; else blockHeavyVideo">
        <div class="text">
            Heavy videos should not be used in hosted display ads because they will significantly
            increase the weight of your creatives, which will in turn lead to ads being blocked by
            Google Chrome or not approved by your chosen ad network.
            <a
                target="_blank"
                rel="noopener"
                href="http://support.bannerflow.com/en/articles/6401112-working-with-heavy-videos"
                >Read more here</a
            >
        </div>

        <div class="confirm-checkbox">
            <ui-checkbox
                [(selected)]="hasConfirmed"
                label="I understand and want to proceed">
            </ui-checkbox>
        </div>

        <ui-dialog-buttons>
            <ui-button
                text="Cancel"
                (click)="cancel()">
            </ui-button>
            <ui-button
                id="interaction-add-heavy-video-button"
                type="primary"
                text="Add heavy video"
                [disabled]="!hasConfirmed"
                (click)="confirm()">
            </ui-button>
        </ui-dialog-buttons>
    </ng-container>
</div>

<ng-template #blockHeavyVideo>
    <div class="text blocked">
        Heavy videos are not supposed to be used in hosted display ads. You can't add this video to this
        creative because it is already assigned to the display ad.
    </div>

    <ui-dialog-buttons>
        <ui-button
            type="primary"
            text="Ok"
            (click)="cancel()">
        </ui-button>
    </ui-dialog-buttons>
</ng-template>
