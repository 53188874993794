type CanvasRadius = { tl: number; tr: number; br: number; bl: number };

export function drawRoundRect(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    width: number,
    height: number,
    radius?: number | Array<number>,
    fill?: boolean,
    stroke?: boolean
): void {
    ctx.save();

    let radiusRect: CanvasRadius = { tl: 0, tr: 0, br: 0, bl: 0 };

    if (typeof stroke === 'undefined') {
        stroke = false;
    }
    if (typeof radius === 'undefined') {
        radius = 5;
    }
    if (Array.isArray(radius)) {
        radiusRect = { tl: radius[0], tr: radius[1], br: radius[2], bl: radius[3] };
    }
    if (typeof radius === 'number') {
        radiusRect = { tl: radius, tr: radius, br: radius, bl: radius };
    }

    ctx.beginPath();
    ctx.moveTo(x + radiusRect.tl, y);
    ctx.lineTo(x + width - radiusRect.tr, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radiusRect.tr);
    ctx.lineTo(x + width, y + height - radiusRect.br);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radiusRect.br, y + height);
    ctx.lineTo(x + radiusRect.bl, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radiusRect.bl);
    ctx.lineTo(x, y + radiusRect.tl);
    ctx.quadraticCurveTo(x, y, x + radiusRect.tl, y);
    ctx.closePath();
    if (fill) {
        ctx.fill();
    }
    if (stroke) {
        ctx.stroke();
    }

    ctx.restore();
}

export function drawRect(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    width: number,
    height: number,
    fill?: any,
    stroke?: any
): void {
    ctx.save();

    if (typeof stroke === 'undefined') {
        stroke = false;
    }

    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x + width, y);
    ctx.lineTo(x + width, y + height);
    ctx.lineTo(x, y + height);
    ctx.lineTo(x, y);
    ctx.closePath();

    if (fill) {
        ctx.fill();
    }
    if (stroke) {
        ctx.stroke();
    }

    ctx.restore();
}

export function drawDiamond(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    width: number,
    height: number,
    fill?: any,
    stroke?: any
): void {
    ctx.save();

    if (typeof stroke === 'undefined') {
        stroke = false;
    }

    const rw = height * 0.65;

    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x + rw, y - rw);
    ctx.lineTo(x + rw + width, y - rw);
    ctx.lineTo(x + rw * 2 + width, y);
    ctx.lineTo(x + rw + width, y + rw);
    ctx.lineTo(x + rw, y + rw);
    ctx.closePath();

    if (fill) {
        ctx.fill();
    }
    if (stroke) {
        ctx.stroke();
    }

    ctx.restore();
}

export function fitCanvasText(ctx: CanvasRenderingContext2D, str: string, maxWidth: number): string {
    if (!str) {
        return '';
    }

    let width = ctx.measureText(str).width;
    const ellipsis = '…';
    const ellipsisWidth = ctx.measureText(ellipsis).width;

    if (width <= maxWidth || width <= ellipsisWidth) {
        return str;
    } else {
        let len = str.length;
        while (width >= maxWidth - ellipsisWidth && len-- > 0) {
            str = str.substring(0, len);
            width = ctx.measureText(str).width;
        }

        return str + ellipsis;
    }
}
