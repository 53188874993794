import { ObjectWithId } from '@domain/utils/types';

export function base64ToArrayBuffer(dataURI: string): ArrayBuffer {
    const binaryString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(binaryString.length);
    const bytes = new Uint8Array(ab);

    for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
}

export function removeItemWithId<T extends ObjectWithId>(array: T[], idToFind: string | string[]): T[] {
    const ids = Array.isArray(idToFind) ? idToFind : [idToFind];
    return array.filter(({ id }) => !ids.includes(id));
}

export function distinctArrayById<T extends ObjectWithId>(arr: T[]): T[] {
    const newArray: T[] = [];
    for (const item of arr) {
        const itemExists = newArray.some(a => a.id === item.id);
        if (!itemExists) {
            newArray.push(item);
        }
    }

    return newArray;
}

export function isUnique<T>(value: T, index: number, self: T[]): boolean {
    return self.indexOf(value) === index;
}
