import { Pipe, PipeTransform } from '@angular/core';
import {
    GroupedElements,
    IVersionedElementPropertyValuesByElement,
    IVersionedText,
    IWidgetText
} from '@domain/creativeset/version';
import { from, Observable } from 'rxjs';
import { groupBy, mergeMap, reduce, toArray } from 'rxjs/operators';

@Pipe({ standalone: true, name: 'groupwidgets' })
export class GroupWidgetsPipe implements PipeTransform {
    transform(
        elements: IVersionedElementPropertyValuesByElement<IVersionedText | IWidgetText>[]
    ): Observable<GroupedElements[]> {
        return from(elements).pipe(
            groupBy(element => {
                const props = element.properties.map(property => ({
                    label: property.label,
                    value: property.value.text
                }));
                return JSON.stringify(props);
            }),
            mergeMap(group =>
                group.pipe(
                    reduce(
                        (acc: GroupedElements, cur) => {
                            acc.elements.push(cur);
                            return acc;
                        },
                        { elements: [], isExpanded: false }
                    )
                )
            ),
            toArray()
        );
    }
}
