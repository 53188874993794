<div
    class="arrow"
    *ngIf="showArrow && arrowPosition"
    [ngClass]="{
        open:
            animationState === 'enter' ||
            animationState.value === 'enter' ||
            animationState.value === ENTER_ALT
    }"
    [style.left]="arrowPosition"></div>

<div
    [@animationState]="animationState"
    (@animationState.start)="onAnimationStart($event)"
    (@animationState.done)="onAnimationDone($event)"
    class="animate"
    [class.no-borders]="!showBorders"
    #animate>
    <div
        #content
        class="content">
        <ng-container *ngIf="showContent || !removeContentWhenCollapsed">
            <ng-content></ng-content>
        </ng-container>
    </div>
</div>

<!-- INNER DROP SHADOW -->
<svg
    width="0"
    height="0"
    style="position: absolute">
    <filter id="inset-shadow">
        <!-- Shadow offset -->
        <feOffset
            dx="0"
            dy="3" />
        <!-- Shadow blur -->
        <feGaussianBlur
            stdDeviation="10"
            result="offset-blur" />
        <!-- Invert drop shadow to make an inset shadow-->
        <feComposite
            operator="out"
            in="SourceGraphic"
            in2="offset-blur"
            result="inverse" />
        <!-- Cut colour inside shadow -->
        <feFlood
            flood-color="black"
            flood-opacity=".1"
            result="color" />
        <feComposite
            operator="in"
            in="color"
            in2="inverse"
            result="shadow" />
        <!-- Placing shadow over element -->
        <feComposite
            operator="over"
            in="shadow"
            in2="SourceGraphic" />
    </filter>
</svg>
