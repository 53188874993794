<div
    *ngIf="creativesetName$ | async as name"
    class="breadcrumb-wrapper">
    <span
        class="name"
        [uiTooltip]="name"
        uiTooltipPosition="bottom"
        truncateSpan
        [spanText]="name"></span>
    <ui-svg-icon
        class="icon"
        icon="arrow-right"></ui-svg-icon>
    <span class="size">{{ sizeName }}</span>
</div>
