import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { filter, pipe } from 'rxjs';
import { DuplicateCreativesState, DUPLICATE_CREATIVES_FEATURE_KEY } from './duplicate-creative.reducer';

export const getDuplicateCreativeState = createFeatureSelector<DuplicateCreativesState>(
    DUPLICATE_CREATIVES_FEATURE_KEY
);

export const getDuplicateCreativeWhenLoaded = pipe(
    select(getDuplicateCreativeState),
    filter(state => state.loaded)
);

export const getDuplicateCreativeLoaded = createSelector(
    getDuplicateCreativeState,
    (state: DuplicateCreativesState) => state.loaded
);

export const getDuplicateCreativeError = createSelector(
    getDuplicateCreativeState,
    (state: DuplicateCreativesState) => state.error
);

export const getShowLoadMore = createSelector(
    getDuplicateCreativeState,
    (state: DuplicateCreativesState) => {
        if (!state.setsAndFolders) {
            return false;
        }
        return (
            (state.setsAndFolders.currentPage + 1) * state.setsAndFolders.currentPageSize <
            state.setsAndFolders.totalItemsCount
        );
    }
);

export const getDuplicateCreativeIsDuplicating = createSelector(
    getDuplicateCreativeState,
    (state: DuplicateCreativesState) => state.isDuplicating
);
