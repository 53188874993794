import { Pipe, PipeTransform } from '@angular/core';
import { concatLatestFrom } from '@ngrx/operators';
import { map, Observable, of } from 'rxjs';
import { VersionsService } from '../versions/state/versions.service';

@Pipe({
    standalone: true,
    name: 'localizationForVersion'
})
export class LocalizationForVersionPipe implements PipeTransform {
    constructor(private versionsService: VersionsService) {}

    // Receives a version ID, returns an Observable with the localization ID
    transform(value: string): Observable<string> {
        return of(value).pipe(
            concatLatestFrom(() => this.versionsService.versions$),
            map(([versionId, versions]) => {
                const version = versions.find(v => v.id === versionId);
                return version?.localization?.id ?? '';
            })
        );
    }
}
