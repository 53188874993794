import { Injectable } from '@angular/core';
import { IAnimation } from '@domain/animation';
import { OneOfElementDataNodes } from '@domain/nodes';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class AnimationService {
    change$ = new Subject<IAnimation | void>();
    hoveredAnimation$ = new BehaviorSubject<IAnimation | undefined>(undefined);
    selectedAnimation$ = new Subject<IAnimation | undefined>();
    deleteAnimations$ = new Subject<void>();
    addAnimation$ = new Subject<void>();
    visiblityToggle$ = new Subject<OneOfElementDataNodes | undefined>();

    seek$ = new Subject<number>();
    pause$ = new Subject<void>();
    skipNextElementSelectionChange = false;
}
