<div class="summary">
    <div
        class="left-panel"
        [ngClass]="{
            hidden: inHiddenNodeTree,
            'keyframes-enabled': elementFeatures.has(Feature.Animations)
        }"
        (click)="setSelections($event)">
        <div
            data-test-id="element-toggle-visibility"
            class="icon eye"
            (click)="toggleVisibility($event)">
            <ui-svg-icon
                [icon]="node.hidden ? 'visibility-hidden' : 'visibility-visible'"></ui-svg-icon>
        </div>
        <div
            class="name"
            [attr.data-test-id]="'te-' + node.name"
            [ngClass]="'indent-level-' + indentLevel"
            #elementName>
            <div
                id="toggle-group"
                data-test-id="toggle-group"
                *ngIf="node | isGroupNode"
                class="icon toggle-group"
                [ngClass]="{ active: !collapsedGroup }"
                (click)="toggleGroup($event)">
                <ui-svg-icon [icon]="collapsedGroup ? 'folder' : 'folder-open'"></ui-svg-icon>
            </div>
            @if (isMask) {
                <div class="icon masking">
                    <svg
                        class="masking-path start"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <use href="#masking-path-start"></use>
                    </svg>
                    <ui-svg-icon icon="mask"></ui-svg-icon>
                </div>
                <ui-svg-icon icon="none"></ui-svg-icon>
            }

            @if (isMasked) {
                <div class="icon masking">
                    <svg
                        class="masking-path line"
                        width="1"
                        height="26"
                        viewBox="0 0 1 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <use href="#masking-path-line"></use>
                    </svg>
                </div>
            }

            @if (isMaskedLast) {
                <div class="icon masking">
                    <svg
                        class="masking-path end"
                        width="14"
                        height="26"
                        viewBox="0 0 14 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <use href="#masking-path-end"></use>
                    </svg>
                </div>
            }
            <ui-input
                #name
                ui-theme="tiny"
                [id]="'name-' + elementIndex"
                [discrete]="true"
                [dynamicWidth]="true"
                [value]="node.name"
                (cancel)="cancelSetName()"
                (mousedown)="startEditName($event)"
                (blur)="setName(name.value)"
                (dynamicWidthUpdated)="onDynamicWidthUpdate()"
                (submit)="submitName()"></ui-input>
            <div
                class="element-features"
                *ngIf="elementFeatures.size > 0">
                <div
                    class="icon feature"
                    *ngIf="elementFeatures.has(Feature.Feeds)">
                    <ui-svg-icon icon="feed"></ui-svg-icon>
                </div>
                <div
                    class="icon feature"
                    *ngIf="elementFeatures.has(Feature.Actions)">
                    <ui-svg-icon icon="de-action"></ui-svg-icon>
                </div>
            </div>
        </div>
        <ng-container *ngIf="node | isElementNode as element">
            <div
                id="toggle-animations-btn"
                data-test-id="toggle-animations-btn"
                class="icon toggle-animations"
                [ngClass]="{ active: expanded, hidden: element.locked || !element.animations.length }"
                (click)="toggleAnimations($event)">
                <ui-svg-icon icon="animation"></ui-svg-icon>
            </div>
        </ng-container>
        <div
            class="icon lock"
            *ngIf="node.locked"
            (click)="toggleLocked()">
            <ui-svg-icon icon="lock-closed"></ui-svg-icon>
        </div>
        <div
            class="icon menu"
            [ngClass]="{ active: selected && timeline.editor.workspace.contextMenuOpen }"
            *ngIf="!node.locked"
            (mousedown)="openContextMenu($event, true)">
            <ui-svg-icon icon="kebab"></ui-svg-icon>
        </div>
    </div>
    <div class="right-canvas">
        <div class="canvas-wrapper">
            <canvas
                #elementCanvas
                class="element-canvas"></canvas>
        </div>
    </div>
</div>

<ng-container *ngIf="node | isElementNode as element">
    <div class="animations-expanded">
        <section-expand
            class="animations"
            #animationSection
            [scrollIntoView]="false"
            [expanded]="expanded"
            arrowPosition="calc(var(--left-panel-width) - 33px)"
            [arrowSize]="6"
            [showBackground]="true"
            [showShadow]="false"
            (animationStateChanged)="sectionStateChange($event)">
            <ng-container *ngFor="let animation of element.animations; trackBy: getAnimationId">
                <timeline-animation
                    #animation
                    [animation]="animation"
                    [element]="element"
                    [indentLevel]="indentLevel">
                </timeline-animation>
            </ng-container>
        </section-expand>
    </div>
</ng-container>
