import { IAd } from '@domain/ad/ad';
import { IAdFile } from '@domain/ad/ad-data-creative';
import { IVideoRenderer } from '@domain/creative/elements/video/video-renderer.header';
import { CreativeMode, ICreativeEnvironment } from '@domain/creative/environment';
import { IFeedStore } from '@domain/creative/feed/feed-store.header';
import { IFontFamily } from '@domain/font-families';
import { ICreativeDataNode, IVideoViewElement } from '@domain/nodes';
import { IEditorState } from '@domain/rich-text';
import { IRichTextEditorService } from '@domain/rich-text/rich-text.editor.header';
import { IRichText } from '@domain/rich-text/rich-text.header';
import { IText } from '@domain/text';
import { Container } from '@studio/utils/di';
import { concatUrl } from '@studio/utils/url';
import { Animator } from './animator';
import { CreativeWrapper } from './creative-wrapper';
import { ICreativeWrapper } from './creative-wrapper.header';
import { IRichTextRenderOption, RichText } from './elements/rich-text/rich-text';
import { RichTextEditorService } from './elements/rich-text/rich-text.editor';
import { RichTextEditorKeyboardBindings } from './elements/rich-text/rich-text.editor.keyboard-bindings';
import { RichTextEditorMouseBindings } from './elements/rich-text/rich-text.editor.mouse-bindings';
import { RichTextEditorStyleResolver } from './elements/rich-text/rich-text.editor.style-resolver';
import { RichTextHtmlResolver } from './elements/rich-text/rich-text.html-resolver';
import { RichTextSelectionService } from './elements/rich-text/rich-text.selection';
import { IWidgetRenderer } from './elements/widget/widget-renderer.header';
import { IRendererOptions, Renderer } from './renderer';
import { IRenderer } from './renderer.header';

/** @@remove STUDIO:START */
// DON'T RE-SORT THE IMPORTS
// WidgetRenderer, AdApi, CreativeApi, TimelineApi, FeedStore, VideoRenderer should be excluded here
import { FeedStore } from './elements/feed/feed-store';
import { VideoRenderer } from './elements/video/video-renderer';
import { AdApi, CreativeApi, TimelineApi, WidgetRenderer } from './elements/widget/widget.module';
/** @@remove STUDIO:END */
const _useless = ''; // Prevents ending strip block comment above from disappearing

export const enum T {
    AD,
    CREATIVE_DATA,
    ENVIRONMENT,

    RENDERER,
    WIDGET_RENDERER,
    ANIMATOR,
    FEED_STORE,
    VIDEO_RENDERER,

    AD_API,
    CREATIVE_API,
    TIMELINE_API,

    RENDERER_FACTORY,
    CREATIVE_FACTORY,
    FEED_STORE_FACTORY,
    VIDEO_RENDERER_FACTORY,

    RICH_TEXT,
    RICH_TEXT_EDITOR,
    RICH_TEXT_EDITOR_KEYBOARD_BINDINGS,
    RICH_TEXT_EDITOR_MOUSE_BINDINGS,
    RICH_TEXT_EDITOR_STYLE_RESOLVER,
    RICH_TEXT_EDITOR_HTML_RESOLVER,
    RICH_TEXT_EDITOR_SELECTION,
    RICH_TEXT_FACTORY,

    HOTKEY_SERVICE,
    ACTIVITY_LOGGER_SERVICE,
    TEST_LOCKER_SERVICE,
    EDITOR_HISTORY_SERVICE,
    EDITOR_STATE,
    TEXT_SELECTION_CHANGE_SUBJECT,
    TEXT_CHANGE_SUBJECT,
    FONT_FAMILIES,
    EDITOR_EVENT
}

export type IRichTextFactory = (
    text: IText,
    rootElement: HTMLDivElement,
    options: IRichTextRenderOption
) => IRichText;
export type IRendererFactory = (options: IRendererOptions) => IRenderer;
export type ICreativeFactory = (
    creative: ICreativeDataNode,
    options: IRendererOptions,
    ad: IAd
) => ICreativeWrapper;
export type IFeedStoreFactory = (brandId: string) => IFeedStore;
export type IVideoRendererFactory = (viewElement: IVideoViewElement) => IVideoRenderer;

// eslint-disable-next-line @typescript-eslint/naming-convention
declare let __webpack_public_path__: string;
export async function registerFeaturesByFiles(
    files: IAdFile[],
    origin: string,
    container: Container<T>
): Promise<void> {
    const registerTasks: Promise<void>[] = [];
    __webpack_public_path__ = origin && concatUrl(origin, '/scripts/');
    for (const file of files) {
        if (!file.id.startsWith('preload')) {
            continue;
        }
        const matches = /^([\w-]+)/.exec(file.url)!;
        switch (matches[1]) {
            case 'widget':
                registerTasks.push(registerWidgetFeature(container));
                break;
            case 'feed':
                registerTasks.push(registerFeedStoreFeature(container));
                break;
            case 'video':
                registerTasks.push(registerVideoFeature(container));
                break;
        }
    }
    await Promise.all(registerTasks);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    __webpack_public_path__ = '';
}

async function registerFeedStoreFeature(container: Container<T>): Promise<void> {
    const { FeedStore: FeedStoreC } = await import(
        /* webpackChunkName: "feed" */ './elements/feed/feed-store'
    );
    container.registerFactory_m<IFeedStoreFactory>(
        T.FEED_STORE_FACTORY,
        factoryContainer =>
            (brandId: string): IFeedStore => {
                const renderer = factoryContainer.resolve<IRenderer>(T.RENDERER);
                const env = factoryContainer.resolve<ICreativeEnvironment>(T.ENVIRONMENT);
                return new FeedStoreC(renderer, env, brandId);
            }
    );
    container.resolve<IFeedStoreFactory>(T.FEED_STORE_FACTORY);
}

async function registerWidgetFeature(container: Container<T>): Promise<void> {
    const {
        WidgetRenderer: WidgetRendererC,
        AdApi: AdApiC,
        CreativeApi: CreativeApiC,
        TimelineApi: TimelineApiC
    } = await import(/* webpackChunkName: "widget" */ './elements/widget/widget.module');
    container.register_m(T.WIDGET_RENDERER, WidgetRendererC);
    container.register_m(T.AD_API, AdApiC);
    container.register_m(T.CREATIVE_API, CreativeApiC);
    container.register_m(T.TIMELINE_API, TimelineApiC);
}

async function registerVideoFeature(container: Container<T>): Promise<void> {
    const { VideoRenderer: VideoRendererC } = await import(
        /* webpackChunkName: "video" */ './elements/video/video.module'
    );
    container.registerFactory_m<IVideoRendererFactory>(
        T.VIDEO_RENDERER_FACTORY,
        factoryContainer =>
            (viewElement: IVideoViewElement): IVideoRenderer => {
                const animator = factoryContainer.resolve<Animator>(T.ANIMATOR);
                const renderer = factoryContainer.resolve<IRenderer>(T.RENDERER);
                const env = factoryContainer.resolve<ICreativeEnvironment>(T.ENVIRONMENT);
                const ad = factoryContainer.resolve<IAd>(T.AD);
                return new VideoRendererC(ad, renderer, animator, env, viewElement);
            }
    );
    container.register_m(T.VIDEO_RENDERER, VideoRendererC);
}

export function createCreativeContainer(environment: ICreativeEnvironment): Container<T> {
    const IN_STUDIO = environmentIsStudio(environment);
    const creativeContainer = new Container<T>();

    creativeContainer.register_m(T.RICH_TEXT, RichText);
    creativeContainer.register_m(T.ANIMATOR, Animator);

    /** @@remove STUDIO:START */
    if (IN_STUDIO) {
        creativeContainer.register_m(T.WIDGET_RENDERER, WidgetRenderer);
        creativeContainer.register_m(T.AD_API, AdApi);
        creativeContainer.register_m(T.CREATIVE_API, CreativeApi);
        creativeContainer.register_m(T.TIMELINE_API, TimelineApi);
    }
    /** @@remove STUDIO:END */

    creativeContainer.registerFactory_m<IRichTextFactory>(
        T.RICH_TEXT_FACTORY,
        container =>
            (text: IText, rootElement: HTMLDivElement, options: IRichTextRenderOption): RichText => {
                /** @@remove STUDIO:START */
                if (IN_STUDIO) {
                    const richTextContainer = new Container();
                    richTextContainer.parent = container;
                    richTextContainer.register_m(T.RICH_TEXT_EDITOR, RichTextEditorService);
                    richTextContainer.register_m(
                        T.RICH_TEXT_EDITOR_KEYBOARD_BINDINGS,
                        RichTextEditorKeyboardBindings
                    );
                    richTextContainer.register_m(
                        T.RICH_TEXT_EDITOR_MOUSE_BINDINGS,
                        RichTextEditorMouseBindings
                    );
                    richTextContainer.register_m(
                        T.RICH_TEXT_EDITOR_STYLE_RESOLVER,
                        RichTextEditorStyleResolver
                    );
                    richTextContainer.register_m(
                        T.RICH_TEXT_EDITOR_SELECTION,
                        RichTextSelectionService
                    );
                    richTextContainer.register_m(
                        T.RICH_TEXT_EDITOR_HTML_RESOLVER,
                        RichTextHtmlResolver
                    );
                    const richTextEditorService = richTextContainer.resolve<IRichTextEditorService>(
                        T.RICH_TEXT_EDITOR
                    );
                    const editorState = container.maybeResolve<IEditorState>(T.EDITOR_STATE);
                    const fontFamilies = container.resolve<IFontFamily[]>(T.FONT_FAMILIES);
                    const richText = new RichText(
                        text,
                        rootElement,
                        options,
                        environment,
                        editorState,
                        richTextEditorService,
                        fontFamilies
                    );
                    richTextEditorService.text = richText;
                    return richText;
                }
                /** @@remove STUDIO:END */
                return new RichText(text, rootElement, options, environment);
            }
    );

    creativeContainer.registerFactory_m<IRendererFactory>(
        T.RENDERER_FACTORY,
        container =>
            (options: IRendererOptions): IRenderer => {
                const document = container.resolve<ICreativeDataNode>(T.CREATIVE_DATA);
                const env = container.resolve<ICreativeEnvironment>(T.ENVIRONMENT);
                const renderer = new Renderer(container, document, options, env);
                const createFeedStore = container.maybeResolve<IFeedStoreFactory>(T.FEED_STORE_FACTORY);
                container.register_m(T.RENDERER, renderer);
                if (createFeedStore) {
                    renderer.feedStore = createFeedStore(options.brandId!);
                }
                const widgetRenderer = container.maybeResolve<IWidgetRenderer>(T.WIDGET_RENDERER);
                renderer.WidgetRenderer = widgetRenderer;
                return renderer;
            }
    );

    creativeContainer.registerFactory_m<ICreativeFactory>(
        T.CREATIVE_FACTORY,
        container =>
            (creativeData: ICreativeDataNode, options: IRendererOptions, ad: IAd): ICreativeWrapper => {
                container.register_m(T.CREATIVE_DATA, creativeData);
                container.register_m(T.AD, ad);
                return new CreativeWrapper(options, container);
            }
    );

    /** Remove modules that are autoresolved inside of studio for the output */
    /** @@remove STUDIO:START */
    creativeContainer.registerFactory_m<IFeedStoreFactory>(
        T.FEED_STORE_FACTORY,
        container =>
            (brandId: string): IFeedStore => {
                const renderer = container.resolve<IRenderer>(T.RENDERER);
                const env = container.resolve<ICreativeEnvironment>(T.ENVIRONMENT);
                return new FeedStore(renderer, env, brandId);
            }
    );

    creativeContainer.registerFactory_m<IVideoRendererFactory>(
        T.VIDEO_RENDERER_FACTORY,
        container =>
            (viewElement: IVideoViewElement): VideoRenderer => {
                const animator = container.resolve<Animator>(T.ANIMATOR);
                const renderer = container.resolve<IRenderer>(T.RENDERER);
                const env = container.resolve<ICreativeEnvironment>(T.ENVIRONMENT);
                const ad = container.resolve<IAd>(T.AD);
                return new VideoRenderer(ad, renderer, animator, env, viewElement);
            }
    );
    /** @@remove STUDIO:END */

    return creativeContainer;
}
function environmentIsStudio(environment: ICreativeEnvironment): boolean {
    return (
        environment.MODE === CreativeMode.ManageView ||
        environment.MODE === CreativeMode.DesignView ||
        environment.MODE === CreativeMode.TranslationPanel
    );
}
