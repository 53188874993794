import { Component } from '@angular/core';
import { AppComponent } from '../../app.component';

@Component({
    selector: 'studio-maintenance',
    templateUrl: 'maintenance-page.component.html',
    styleUrls: ['maintenance-page.component.scss']
})
export class MaintenanceComponent {
    constructor(private app: AppComponent) {
        this.app.loaded = true;
    }
}
