<expandable-prompt
    [disabled]="isRequestingSearchAndReplace()"
    [canExpand]="false"
    [type]="ExpandablePromptInputType.SearchPrompt"
    [value]="searchPrompt()"
    (promptChange)="onSearchPromptChange($event)" />

<expandable-prompt
    [disabled]="isRequestingSearchAndReplace()"
    [canExpand]="false"
    [type]="ExpandablePromptInputType.ReplacePrompt"
    [value]="replacePrompt()"
    (promptChange)="onReplacePromptChange($event)" />

<div class="btn-wrapper">
    <ui-button
        type="primary"
        text="Replace"
        [disabled]="!!actionsDisabled()"
        [loading]="isRequestingSearchAndReplace()"
        (click)="searchAndReplace()"></ui-button>
</div>
