@if (headline) {
    <div
        class="header"
        [ngClass]="{ clickable: clickableHeader }"
        (click)="onHeaderClick($event)">
        <div class="inner">
            @if (headlineIcon) {
                <div
                    class="headline-icon headline-icon-before"
                    [uiTooltip]="headline"
                    [uiTooltipDisabled]="headlineTooltipDisabled">
                    <ui-svg-icon [icon]="headlineIcon" />
                </div>
            }
            <span
                class="headline"
                [class.inactive]="inactive"
                [uiTooltip]="headline"
                [uiTooltipDisabled]="headlineTooltipDisabled">
                {{ headline }}
            </span>

            @if (headlineIconAfter) {
                <div
                    class="headline-icon headline-icon-after"
                    [uiTooltip]="headline"
                    [uiTooltipDisabled]="headlineTooltipDisabled">
                    <ui-svg-icon [icon]="headlineIconAfter" />
                </div>
            }
        </div>
        <span
            [hidden]="!toggle"
            class="toggle-action"
            (click)="toggleProperties()">
            {{ toggleText }}
        </span>
        @if (customAction) {
            <ng-container *ngTemplateOutlet="customAction" />
        }

        <div
            class="action"
            #actionElement>
            <ng-content select="[section-header-action]" />
            @if (dropdownTarget) {
                <ui-svg-icon
                    icon="kebab"
                    [uiDropdownTarget]="dropdownTarget" />
            } @else {
                @for (a of actions; track a.id) {
                    <ui-svg-icon
                        [attr.id]="a.id"
                        [style.display]="a.hidden ? 'none' : 'inline-block'"
                        [icon]="a.icon"
                        (click)="executeAction(a, $event)" />
                }
            }
            @if (collapsable) {
                <ui-svg-icon
                    class="collapse-button"
                    [ngClass]="{ collapsed: collapsed }"
                    (click)="toggleCollapse()"
                    icon="arrow-up" />
            }
        </div>
    </div>
}
<div
    #sectionBody
    class="section-body ui-scrollbar"
    [style.display]="collapsed || empty ? 'none' : 'block'">
    <ng-content />
</div>
