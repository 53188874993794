import { Color } from '@creative/color';
import { CreativeDataNode } from '@creative/nodes';
import { IDesign } from '@domain/creativeset/design';
import { uuidv4 } from './id';
import { ICreative } from '@domain/creativeset/creative/creative';
import { CreativeSize } from '@domain/creativeset/size';

export function createDesign(design: Partial<IDesign> = {}): IDesign {
    return {
        id: '',
        document: new CreativeDataNode(
            design.document || { id: uuidv4(), width: 0, height: 0, fill: Color.parse('#ffffff') }
        ),
        elements: design.elements || [],
        hasHeavyVideo: design.hasHeavyVideo || false,
        name: design.name || ''
    };
}

export function hasDesign(creative: ICreative): boolean {
    return !!creative.design;
}

export function hasActiveDesigns(creatives: ICreative[]): boolean {
    return [...creatives].some(creative => hasDesign(creative));
}

export const sortCreatives: (a: ICreative, b: ICreative) => number = (a, b) =>
    sortBySize(a.size, b.size);

function sortBySize(sizeA: CreativeSize, sizeB: CreativeSize): number {
    const aRatio = sizeA.width / sizeA.height;
    const bRatio = sizeB.width / sizeB.height;
    const nameA = sizeA.name || '';
    const nameB = sizeB.name || '';

    if (aRatio > bRatio) {
        return 1;
    }

    if (aRatio < bRatio) {
        return -1;
    }

    if (sizeA.width > sizeB.width) {
        return 1;
    }

    if (sizeA.width < sizeB.width) {
        return -1;
    }

    if (nameA > nameB) {
        return 1;
    }

    if (nameA < nameB) {
        return -1;
    }

    return 1;
}
