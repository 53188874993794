export interface IImageSettings {
    /**
     * How the image should be rendered in size.
     * Similar to the CSS "background-size" property
     */
    sizeMode: ImageSizeMode;

    /**
     * A decimal value of the image position when "cropping" or "containing" the image.
     * Ranges from 0 to 1. Simliar to the CSS background-position property.
     * 0 = left, 0.5 = center, 1 = right
     */
    x: number;

    /**
     * A decimal value of the image position when "cropping" or "containing" the image.
     * Ranges from 0 to 1. Simliar to the CSS background-position property.
     * 0 = top, 0.5 = left, 1 = bottom
     */
    y: number;

    /**
     * Quality on image from 0 to 100. Undefined means that no optimization should be done
     */
    quality?: number;

    /**
     * Enable retina resolution for devices supporting it.
     * Defaults to false if not provided
     */
    highDpi?: boolean;
}

export const imageSettingsPropertyToUnitMap = {
    sizeMode: 'enum',
    x: 'number',
    y: 'number',
    quality: 'number',
    highDpi: 'boolean'
};

export const imageSettingsProperties = Object.keys(imageSettingsPropertyToUnitMap);
export type ImageSettingsKeys = keyof typeof imageSettingsPropertyToUnitMap;

export enum ImageSizeMode {
    Fit = 'fit',
    Stretch = 'stretch',
    Crop = 'crop'
}

/**
 * Deprecated, use ImageSizeMode
 */
export type ImageFitOption = 'contain' | 'distort' | 'cover';

export const DEFAULT_IMAGE_QUALITY = 85;

export const DEFAULT_IMAGE_SETTINGS: Readonly<IImageSettings> = {
    x: 0.5,
    y: 0.5,
    quality: DEFAULT_IMAGE_QUALITY,
    sizeMode: ImageSizeMode.Fit,
    highDpi: false
};
