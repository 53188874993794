import { IHotkey } from '@domain/hotkeys/hotkeys.types';

export const TextEditModeHotkeys: IHotkey[] = [
    // Moving Caret Shortcuts
    {
        command: 'MoveCaretToPreviousWord',
        keyCombination: 'Alt + ArrowLeft',
        context: 'TextEditMode',
        category: 'Edit text',
        meta: { description: 'Move caret to previous word', shortcut: { mac: '⌥ + ←', win: 'Alt + ←' } }
    },
    {
        command: 'MoveCaretToNextWord',
        keyCombination: 'Alt + ArrowRight',
        context: 'TextEditMode',
        category: 'Edit text',
        meta: { description: 'Move caret to next word', shortcut: { mac: '⌥ + →', win: 'Alt + →' } }
    },
    {
        command: 'MoveCaretToStartOfText',
        keyCombination: { mac: 'Cmd + ArrowUp', win: 'Ctrl + Home' },
        context: 'TextEditMode',
        category: 'Edit text',
        meta: { description: 'Move caret to start of text', shortcut: { mac: '⌘ + ↑', win: 'PageUp' } }
    },
    {
        command: 'MoveCaretToEndOfText',
        keyCombination: { mac: 'Cmd + ArrowDown', win: 'Ctrl + End' },
        context: 'TextEditMode',
        category: 'Edit text',
        meta: { description: 'Move caret to end of text', shortcut: { mac: '⌘ + ↓', win: 'PageDown' } }
    },
    {
        command: 'MoveCaretToStartOfLine',
        keyCombination: { mac: 'Cmd + ArrowLeft', win: 'Home' },
        context: 'TextEditMode',
        category: 'Edit text',
        meta: { description: 'Move caret to start of line', shortcut: { mac: '⌘ + ←', win: 'Home' } }
    },
    {
        command: 'MoveCaretToEndOfLine',
        keyCombination: { mac: 'Cmd + ArrowRight', win: 'End' },
        context: 'TextEditMode',
        category: 'Edit text',
        meta: { description: 'Move caret to end', shortcut: { mac: '⌘ + →', win: 'End' } }
    },
    {
        command: 'MoveCaretToStartOfParagraph',
        keyCombination: { win: 'Alt + ArrowUp', mac: 'Alt + ArrowUp' },
        context: 'TextEditMode',
        category: 'Edit text',
        meta: {
            description: 'Move caret to start of paragraph',
            shortcut: { mac: '⌥ + ↑', win: 'Alt + ↑' }
        }
    },
    {
        command: 'MoveCaretToEndOfParagraph',
        keyCombination: { win: 'Alt + ArrowDown', mac: 'Alt + ArrowDown' },
        context: 'TextEditMode',
        category: 'Edit text',
        meta: {
            description: 'Move caret to end of paragraph',
            shortcut: { mac: '⌥ + ↓', win: 'Alt + ↓' }
        }
    },

    // Selection Shortcuts
    {
        command: 'SelectTextToNextWord',
        keyCombination: 'Alt + Shift + ArrowRight',
        context: 'TextEditMode',
        category: 'Edit text',
        meta: {
            description: 'Select text to next word',
            shortcut: { mac: '⌥ + ⇧ + →', win: 'Alt + ⇧ + →' }
        }
    },
    {
        command: 'SelectTextToPreviousWord',
        keyCombination: 'Alt + Shift + ArrowLeft',
        context: 'TextEditMode',
        category: 'Edit text',
        meta: {
            description: 'Select text to previous word',
            shortcut: { mac: '⌥ + ⇧ + ←', win: 'Alt + ⇧ + ←' }
        }
    },
    {
        command: 'SelectTextToStartOfText',
        keyCombination: { mac: 'Cmd + Shift + ArrowUp', win: 'Ctrl + Shift + Home' },
        context: 'TextEditMode',
        category: 'Edit text',
        meta: {
            description: 'Select text to start of text',
            shortcut: { mac: '⌘ + ⇧ + ↑', win: 'Ctrl + ⇧ + Home' }
        }
    },
    {
        command: 'SelectTextToEndOfText',
        keyCombination: { mac: 'Cmd + Shift + ArrowDown', win: 'Ctrl + Shift + End' },
        context: 'TextEditMode',
        category: 'Edit text',
        meta: {
            description: 'Select text to end of text',
            shortcut: { mac: '⌘ + ⇧ + ↓', win: 'Ctrl + ⇧ + End' }
        }
    },
    {
        command: 'SelectTextToStartOfParagraph',
        keyCombination: { win: 'Alt + Shift + ArrowUp', mac: 'Alt + Shift + ArrowUp' },
        context: 'TextEditMode',
        category: 'Edit text',
        meta: {
            description: 'Select text to start of paragraph',
            shortcut: { mac: '⌥ + ⇧ + ↑', win: 'Alt + ⇧ + ↑' }
        }
    },
    {
        command: 'SelectTextToEndOfParagraph',
        keyCombination: { win: 'Alt + Shift + ArrowDown', mac: 'Alt + Shift + ArrowDown' },
        context: 'TextEditMode',
        category: 'Edit text',
        meta: {
            description: 'Select text to end of paragraph',
            shortcut: { mac: '⌥ + ⇧ + ↓', win: 'Alt + ⇧ + ↓' }
        }
    },
    {
        command: 'SelectTextToStartOfLine',
        keyCombination: { mac: 'Cmd + Shift + ArrowLeft', win: 'Shift + Home' },
        context: 'TextEditMode',
        category: 'Edit text',
        meta: {
            description: 'Select text to start of line',
            shortcut: { mac: '⌘ + ⇧ + ←', win: '⇧ + Home' }
        }
    },
    {
        command: 'SelectTextToEndOfLine',
        keyCombination: { mac: 'Cmd + Shift + ArrowRight', win: 'Shift + End' },
        context: 'TextEditMode',
        category: 'Edit text',
        meta: {
            description: 'Select text to end of line',
            shortcut: { mac: '⌘ + ⇧ + →', win: '⇧ + End' }
        }
    },
    {
        command: 'ToggleBoldTextStyle',
        keyCombination: { win: 'Ctrl + B', mac: 'Cmd + B' },
        context: ['TextElementSelected', 'TextEditMode', 'Workspace'],
        category: 'Edit text',
        meta: { description: 'Toggle bold text', shortcut: { mac: '⌘ + B', win: 'Ctrl + B' } }
    },
    {
        command: 'ToggleItalicTextStyle',
        keyCombination: { win: 'Ctrl + I', mac: 'Cmd + I' },
        context: ['TextElementSelected', 'TextEditMode', 'Workspace'],
        category: 'Edit text',
        meta: { description: 'Toggle italic text', shortcut: { mac: '⌘ + I', win: 'Ctrl + I' } }
    },
    {
        command: 'ToggleUnderlineTextStyle',
        keyCombination: { win: 'Ctrl + U', mac: 'Cmd + U' },
        context: ['TextElementSelected', 'TextEditMode', 'Workspace'],
        category: 'Edit text',
        meta: { description: 'Toggle underline text', shortcut: { mac: '⌘ + U', win: 'Ctrl + U' } }
    },
    {
        command: 'MoveCaretForward',
        keyCombination: 'ArrowRight',
        context: 'TextEditMode',
        meta: { description: 'Move caret forward', shortcut: '→' }
    },
    {
        command: 'MoveCaretBackward',
        keyCombination: 'ArrowLeft',
        context: 'TextEditMode',
        meta: { description: 'Move caret back', shortcut: '←' }
    },
    {
        command: 'MoveCaretUpward',
        keyCombination: 'ArrowUp',
        context: 'TextEditMode',
        meta: { description: 'Move caret up', shortcut: '↑' }
    },
    {
        command: 'MoveCaretDownward',
        keyCombination: 'ArrowDown',
        context: 'TextEditMode',
        meta: { description: 'Move caret down', shortcut: '↓' }
    },
    {
        command: 'SelectTextForward',
        keyCombination: 'Shift + ArrowRight',
        context: 'TextEditMode',
        meta: { description: 'Select text forward', shortcut: { mac: '⇧ + →', win: '⇧ + →' } }
    },
    {
        command: 'SelectTextBackward',
        keyCombination: 'Shift + ArrowLeft',
        context: 'TextEditMode',
        meta: { description: 'Select text back', shortcut: { mac: '⇧ + ←', win: '⇧ + ←' } }
    },
    {
        command: 'SelectTextUpward',
        keyCombination: 'Shift + ArrowUp',
        context: 'TextEditMode',
        meta: { description: 'Select text up', shortcut: { mac: '⇧ + ↑', win: '⇧ + ↑' } }
    },
    {
        command: 'SelectTextDownward',
        keyCombination: 'Shift + ArrowDown',
        context: 'TextEditMode',
        meta: { description: 'Select text down', shortcut: { mac: '⇧ + ↓', win: '⇧ + ↓' } }
    },
    {
        command: 'SelectTextToCurrentClick',
        keyCombination: 'Shift + Click',
        context: 'TextEditMode',
        meta: {
            description: 'Select text to pointer position',
            shortcut: { mac: '⇧ + click', win: '⇧ + click' }
        }
    },
    {
        command: 'SelectAllText',
        keyCombination: { win: 'Ctrl + A', mac: 'Cmd + A' },
        context: 'TextEditMode',
        meta: { description: 'Select all text', shortcut: { mac: '⌘ + A', win: 'Ctrl + A' } }
    },

    {
        command: 'EraseTextForward',
        keyCombination: 'Delete',
        context: 'TextEditMode',
        meta: { description: 'Erase text foward', shortcut: 'Delete' }
    },
    {
        command: 'EraseTextBackward',
        keyCombination: 'Backspace',
        context: 'TextEditMode',
        meta: { description: 'Erase text backward', shortcut: '⌫' }
    },
    {
        command: 'EraseWord',
        keyCombination: { mac: 'Alt + Backspace', win: 'Ctrl + Backspace' },
        context: 'TextEditMode',
        category: 'Edit text',
        meta: { description: 'Erase word', shortcut: { mac: '⌥ + ⌫', win: 'Ctrl + ⌫' } }
    },
    {
        command: 'EraseToStartOfLine',
        keyCombination: { mac: 'Cmd + Backspace', win: 'None' },
        context: 'TextEditMode',
        category: 'Edit text',
        meta: { description: 'Erase to start of line', shortcut: { mac: '⌘ + ⌫' } }
    },
    {
        command: 'UndoTextChange',
        keyCombination: { mac: 'Cmd + Z', win: 'Ctrl + Z' },
        context: 'TextEditMode',
        category: 'Edit text'
    },
    {
        command: 'RedoTextChange',
        keyCombination: { mac: 'Cmd + Shift + Z', win: 'Ctrl + Y' },
        context: 'TextEditMode',
        category: 'Edit text'
    },

    {
        command: 'CopySelectedText',
        keyCombination: { mac: 'Cmd + C', win: 'Ctrl + C' },
        context: 'TextEditMode',
        category: 'Edit text',
        meta: {
            description: 'Copy selected text to clipboard',
            shortcut: { mac: '⌘ + C', win: 'Ctrl + C' }
        }
    },
    {
        command: 'CutSelectedText',
        keyCombination: { mac: 'Cmd + X', win: 'Ctrl + X' },
        context: 'TextEditMode',
        category: 'Edit text',
        meta: {
            description: 'Cut selected text to clipboard',
            shortcut: { mac: '⌘ + X', win: 'Ctrl + X' }
        }
    },
    {
        command: 'PasteSelectedText',
        keyCombination: { mac: 'Cmd + V', win: 'Ctrl + V' },
        context: 'TextEditMode',
        category: 'Edit text',
        meta: {
            description: 'Paste the text from clipboard',
            shortcut: { mac: '⌘ + V', win: 'Ctrl + V' }
        }
    },
    {
        command: 'SaveOnTextEditMode',
        keyCombination: { mac: 'Cmd + S', win: 'Ctrl + S' },
        context: 'TextEditMode',
        category: 'Save'
    },
    {
        command: 'SaveAndExitOnTextEditMode',
        keyCombination: { mac: 'Cmd + Shift + S', win: 'Ctrl + Shift + S' },
        context: 'TextEditMode',
        category: 'Save and Exit'
    }
];
