<studio-list-section
    class="size-section"
    [ngClass]="['is-' + type]"
    [style.bottom.px]="stickyHeight"
    [selected]="selectedState()"
    (toggleAll)="toggleAllSizes($event)"
    [collapsed]="collapsed">
    <div
        sectionTitle
        class="section-title">
        <div class="section-label">{{ label }}</div>
        {{ selectedSizes }}/{{ sizes.length }}
    </div>
    @for (size of sizes; track size.id; let i = $index) {
        <div
            id="{{ type }}-sizes-{{ size.width }}x{{ size.height }}"
            class="size"
            [ngClass]="{
                added: size.selected || size.amount > 0,
                'is-social': !!size.compatibility,
                'add-multiple': addMultipleSizes
            }"
            (click)="toggleSize($event, size)">
            <div class="size__wrapper">
                <div class="thumbnail">
                    @if (size.thumbnail) {
                        <ui-svg-icon
                            class="thumbnail__icon"
                            [icon]="size.thumbnail"></ui-svg-icon>
                    } @else {
                        <size-thumbnail [size]="size"></size-thumbnail>
                    }
                </div>
                <div class="value">
                    <div class="size-value">
                        {{ size.width }} × {{ size.height }}
                        @if (size.compatibility && size.compatibility.length > 0) {
                            <div class="compatibility">
                                @for (icon of size.compatibility; track icon) {
                                    <ui-svg-icon
                                        class="compatibility__icon"
                                        [icon]="icon"></ui-svg-icon>
                                }
                            </div>
                        }
                    </div>
                    @if (size.name) {
                        <div
                            class="size-name"
                            [uiTooltip]="size.name">
                            {{ size.name }}
                        </div>
                    }
                </div>
            </div>
            <div class="add">
                @if (addMultipleSizes) {
                    <div class="add__multiple">
                        <div
                            class="add__multiple__sub"
                            (click)="removeSize($event, size)">
                            <ui-svg-icon icon="minus-small"></ui-svg-icon>
                        </div>
                        <div class="add__multiple__label">{{ size.amount }}</div>
                        <div
                            class="add__multiple__add"
                            (click)="addSize($event, size)">
                            <ui-svg-icon icon="plus-tiny"></ui-svg-icon>
                        </div>
                    </div>
                } @else {
                    <ui-checkbox
                        class="add__checkbox"
                        [selected]="!!size.selected"></ui-checkbox>
                }
            </div>
        </div>
    }
</studio-list-section>
