import { CommonModule } from '@angular/common';
import {
    Component,
    computed,
    effect,
    inject,
    input,
    OnDestroy,
    signal,
    ViewChild
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { UIModule, UIPopoverDirective, UIPopoverTargetDirective } from '@bannerflow/ui';
import { DescriptiveLoaderType } from '@studio/domain/components/descriptive-loader.types';
import { DescriptiveLoaderComponent } from '../../components/descriptive-loader/descriptive-loader.component';
import { GenAIService } from '../state/gen-ai.service';
import { getFileNameFromPrompt } from '../utils';

@Component({
    standalone: true,
    imports: [CommonModule, UIModule, DescriptiveLoaderComponent],
    selector: 'ai-image-generation-popover',
    templateUrl: './ai-image-generation-popover.component.html',
    styleUrls: ['./ai-image-generation-popover.component.scss']
})
export class AiImageGenerationPopoverComponent implements OnDestroy {
    @ViewChild('popover') popover: UIPopoverDirective;

    private genAIService = inject(GenAIService);

    readonly loaderType = DescriptiveLoaderType.AIStudioGen;

    parentFolderId = input<string | undefined>();

    prompt = signal('');

    currentImage?: string;

    isRequestingSaveToBrandLibrary = toSignal(this.genAIService.isRequestingSaveToBrandLibrary$, {
        initialValue: false
    });
    isRequestingOpenInAIStudio = toSignal(this.genAIService.isRequestingOpenInAIStudio$, {
        initialValue: false
    });
    isRequestingGeneration = toSignal(this.genAIService.isRequestingGeneration$, {
        initialValue: false
    });

    disableButtons = computed(
        () =>
            (!this.prompt() && !this.currentImage) ||
            this.isRequestingSaveToBrandLibrary() ||
            this.isRequestingGeneration() ||
            this.isRequestingOpenInAIStudio()
    );

    private saveToBrandLibraryClicked = signal(false);
    private openInAIStudioClicked = signal(false);

    constructor() {
        this.genAIService.currentImage$.pipe(takeUntilDestroyed()).subscribe(currentImage => {
            this.currentImage = currentImage;
        });
        effect(() => {
            const isRequestingSaveToBrandLibrary = this.isRequestingSaveToBrandLibrary();
            const saveToBrandLibraryClicked = this.saveToBrandLibraryClicked();
            if (!saveToBrandLibraryClicked) {
                return;
            }
            if (!isRequestingSaveToBrandLibrary && this.currentImage) {
                this.popover.close();
            }
        });
        effect(() => {
            const isRequestingOpenInAIStudio = this.isRequestingOpenInAIStudio();
            const openInAIStudioClicked = this.openInAIStudioClicked();
            if (!openInAIStudioClicked) {
                return;
            }
            if (!isRequestingOpenInAIStudio && this.currentImage) {
                this.popover.close();
            }
        });
    }

    ngOnDestroy(): void {
        this.genAIService.resetState();
    }

    open(target: UIPopoverTargetDirective): void {
        this.popover.open(target);
    }

    onTextareaChange(newValue: string): void {
        this.prompt.set(newValue);
    }

    generateImage(): void {
        const prompt = this.prompt();
        this.genAIService.generateImage(prompt);
        this.saveToBrandLibraryClicked.set(false);
        this.openInAIStudioClicked.set(false);
    }

    regenerateImage(): void {
        this.generateImage();
    }

    saveToBrandLibrary(): void {
        const prompt = this.prompt();
        if (!prompt) {
            return;
        }
        const fileName = getFileNameFromPrompt(prompt);

        const parentFolderId = this.parentFolderId();

        this.genAIService.saveGeneratedImageToBrandLibrary(fileName, parentFolderId);
        this.saveToBrandLibraryClicked.set(true);
    }

    openInAiStudio(): void {
        const prompt = this.prompt();
        if (!prompt) {
            return;
        }
        const parentFolderId = this.parentFolderId();
        const fileName = getFileNameFromPrompt(prompt);
        this.genAIService.openInAiStudio(fileName, parentFolderId);
        this.openInAIStudioClicked.set(true);
    }
}
