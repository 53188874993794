import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
    IUIListDataNode,
    UIDialogComponent,
    UIListComponent,
    UIListDataSource,
    UIModule
} from '@bannerflow/ui';
import { IBfFeed } from '@domain/feed';
import { FeedService } from '../feed.service';

@Component({
    selector: 'feed-browser',
    templateUrl: 'feed-browser.component.html',
    styleUrls: ['feed-browser.component.scss'],
    standalone: true,
    imports: [UIModule, CommonModule]
})
export class FeedBrowserComponent implements OnInit {
    @ViewChild('list', { static: true }) list: UIListComponent;

    feeds: UIListDataSource<IBfFeed[]>;
    callback: (selectedFeed: IBfFeed) => void;
    selectedFeed?: IBfFeed;
    currentlyUsed?: IBfFeed;

    constructor(
        private uiDialogComponent: UIDialogComponent,
        private feedService: FeedService
    ) {}

    async ngOnInit(): Promise<void> {
        const { feeds, callback, selectedFeed } = this.uiDialogComponent.config.data;
        this.feeds = new UIListDataSource(feeds);
        this.callback = callback;
        this.selectedFeed = selectedFeed;
        this.currentlyUsed = selectedFeed;

        if (this.selectedFeed) {
            setTimeout(() => {
                const findItem = (feedItems: IBfFeed[]): IBfFeed | undefined => {
                    for (const item of feedItems) {
                        if (item.id === this.selectedFeed!.id) {
                            return item;
                        } else if (item.children) {
                            const foundItem = findItem(item.children);
                            if (foundItem) {
                                return foundItem;
                            }
                        }
                    }
                };
                const dataItem = findItem(this.feeds.data as IBfFeed[]);

                if (dataItem && this.list) {
                    this.list.select(dataItem);
                    const selectedItem = this.list.selection?.selected[0];
                    if (selectedItem) {
                        const parent = this.list.getParent(selectedItem);
                        this.list.browseNode(parent);
                    }
                }
            });
        }
    }

    selectionChange(selection: IUIListDataNode[]): void {
        this.selectedFeed =
            selection.length && !selection[0].children ? (selection[0] as IBfFeed) : undefined;
    }

    confirmSelection(event: MouseEvent): void {
        event.stopPropagation();
        if (this.selectedFeed) {
            this.callback(this.selectedFeed);
            this.uiDialogComponent.close();
        }
    }

    openFeed(feed: IBfFeed): void {
        this.feedService.openFeed(feed.id);
    }

    cancel(): void {
        this.uiDialogComponent.close();
    }
}
