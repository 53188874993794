<div
    *ngIf="hasDirtyProperties"
    class="panel-actions__wrapper">
    <ui-button
        id="cancel-button"
        type="default"
        (click)="onCancelButtonClicked()"
        text="Cancel"></ui-button>
    <ui-button
        id="save-button"
        type="primary"
        (click)="onSaveButtonClicked()"
        [loading]="(loaded$ | async) === false"
        text="Save"></ui-button>
</div>
