<div
    class="dropdown-trigger"
    #guidelineMenuTrigger="uiDropdownTarget"
    [uiDropdownTarget]="guidelineMenu"
    [hasBackdrop]="false"
    (dropdownClosed)="contextMenuClosed()"></div>

<element-menu #elementMenuComponent></element-menu>
<canvas-menu #canvasMenuComponent></canvas-menu>
<animation-menu #animationMenuComponent></animation-menu>
<state-menu #stateMenuComponent></state-menu>

<ui-dropdown
    #guidelineMenu
    type="menu">
    <ui-dropdown-item
        class="custom-dropdown"
        (click)="deleteGuideline()">
        <div class="custom-item">
            <div class="custom-column"></div>
            <div class="custom-text">Delete</div>
            <div [class]="isMac ? 'mac custom-action' : 'custom-action'">
                {{ keyboardShortcuts.Editor.DeleteElement }}
            </div>
        </div>
    </ui-dropdown-item>
</ui-dropdown>
