import { IHotkey } from '@domain/hotkeys/hotkeys.types';

export const BrandLibraryHotkeys: IHotkey[] = [
    {
        command: 'OpenBrandLibrary',
        keyCombination: 'L',
        context: 'BrandLibrary',
        category: 'Toolbar',
        meta: { description: 'Open brand library', shortcut: 'L' }
    },
    {
        command: 'OpenImageLibrary',
        keyCombination: 'I',
        context: 'BrandLibrary',
        category: 'Toolbar',
        meta: { description: 'Open image library', shortcut: 'I' }
    },
    {
        command: 'OpenVideoLibrary',
        keyCombination: 'C',
        context: 'BrandLibrary',
        category: 'Toolbar',
        meta: { description: 'Open video library', shortcut: 'C' },
        permission: 'StudioVideoLibrary'
    },
    {
        command: 'OpenWidgetLibrary',
        keyCombination: 'W',
        context: 'BrandLibrary',
        category: 'Toolbar',
        meta: { description: 'Open widget library', shortcut: 'W' }
    },
    {
        command: 'OpenFeedsLibrary',
        keyCombination: 'D',
        context: 'BrandLibrary',
        category: 'Toolbar',
        meta: { description: 'Open feeds library', shortcut: 'D' }
    },
    {
        command: 'OpenEffectsLibrary',
        keyCombination: 'F',
        context: 'BrandLibrary',
        category: 'Toolbar',
        meta: { description: 'Open effects library', shortcut: 'F' }
    }
];
