import { ApprovalStatus } from '@domain/creativeset/creative';
import { createReducer, on } from '@ngrx/store';
import { ISelectableSizeCollections } from '@studio/domain/state';
import { getNewSelectedCollections, getSelectedSizesFromSelectedCollections } from '../filters.utils';
import * as FiltersActions from './filters.actions';

export const FILTERS_FEATURE_KEY = 'filters';

export interface FiltersPartialState {
    readonly [FILTERS_FEATURE_KEY]: FiltersState;
}

export interface FiltersState {
    allSizes: string[]; // contains all size ids, this should be moved to a separate state
    sizes: string[];
    statuses: ApprovalStatus[];
    versions: string[]; // MV supports multiple version selection, DV only should always have one
    previousVersions: string[]; // Versions IDs selected in MV before going to DV
    selectableCollections: ISelectableSizeCollections[];
    selectedCollections: string[];
}

export const initialState: FiltersState = {
    allSizes: [],
    sizes: [],
    statuses: [],
    versions: [],
    previousVersions: [],
    selectableCollections: [],
    selectedCollections: []
};

export const reducer = createReducer<FiltersState>(
    initialState,

    on(FiltersActions.loadFiltersSuccess, (state, { sizes, statuses }) => {
        return {
            ...state,
            sizes,
            statuses
        };
    }),

    on(FiltersActions.init, FiltersActions.setAllSizes, (state, { sizeIds }) => {
        return {
            ...state,
            allSizes: Array.from(new Set(sizeIds))
        };
    }),

    // Sizes
    on(FiltersActions.addSizesToFilter, (state, { sizeIds }) => {
        const newSelectedSizes = Array.from(new Set([...state.sizes, ...sizeIds]));
        return {
            ...state,
            sizes: newSelectedSizes
        };
    }),
    on(FiltersActions.removeSizesFromFilter, (state, { sizeIds }) => {
        const newSelectedSizes = state.sizes.filter(sizeId => !sizeIds.includes(sizeId));
        const newSelectedCollections = getNewSelectedCollections(
            newSelectedSizes,
            state.selectableCollections,
            state.selectedCollections
        );
        return {
            ...state,
            sizes: newSelectedSizes,
            selectedCollections: newSelectedCollections
        };
    }),
    on(FiltersActions.setSizesFilter, (state, { sizeIds }) => {
        const newSelectedSizes = Array.from(new Set(sizeIds));
        return {
            ...state,
            sizes: newSelectedSizes
        };
    }),
    on(FiltersActions.clearAllSizesFilter, (state): FiltersState => {
        return {
            ...state,
            sizes: [],
            selectedCollections: []
        };
    }),
    on(FiltersActions.showAllSizesFilter, (state): FiltersState => {
        return {
            ...state,
            sizes: [...state.allSizes]
        };
    }),

    on(FiltersActions.sizeActions.selectSize, (state, { sizeId }) => {
        const newSelectedSizes = Array.from(new Set([...state.sizes, sizeId]));
        return {
            ...state,
            sizes: newSelectedSizes
        };
    }),

    on(FiltersActions.sizeActions.deselectSize, (state, { sizeId }) => {
        const newSelectedSizes = state.sizes.filter(id => id !== sizeId);
        const newSelectedCollections = getNewSelectedCollections(
            newSelectedSizes,
            state.selectableCollections,
            state.selectedCollections
        );
        return {
            ...state,
            sizes: newSelectedSizes,
            selectedCollections: newSelectedCollections
        };
    }),

    // Statuses
    on(FiltersActions.addStatusesToFilter, (state, { statuses }) => {
        return {
            ...state,
            statuses: Array.from(new Set([...state.statuses, ...statuses]))
        };
    }),

    on(FiltersActions.removeStatusesFromFilter, (state, { statuses }) => {
        return {
            ...state,
            statuses: state.statuses.filter(activeStatus => !statuses.includes(activeStatus))
        };
    }),
    on(FiltersActions.setStatusesFilter, (state, { statuses }) => {
        return {
            ...state,
            statuses: Array.from(new Set(statuses))
        };
    }),
    on(FiltersActions.clearAllStatusesFilter, state => {
        return {
            ...state,
            statuses: []
        };
    }),

    // Versions
    on(FiltersActions.setVersionsFilter, (state, { versionIds, updateOldSelection }) => {
        return {
            ...state,
            versions: versionIds,
            previousVersions: updateOldSelection ? state.versions : state.previousVersions
        };
    }),
    on(FiltersActions.addVersionsToFilter, (state, { versionIds }) => {
        return {
            ...state,
            versions: [...state.versions, ...versionIds]
        };
    }),
    on(FiltersActions.removeVersionsFromFilter, (state, { versionIds }) => {
        return {
            ...state,
            versions: state.versions.filter(id => !versionIds.includes(id))
        };
    }),
    on(FiltersActions.clearVersionsFilter, state => {
        return {
            ...state,
            versions: []
        };
    }),

    on(FiltersActions.selectPreviousVersionSelection, state => {
        if (!state.previousVersions.length) {
            return state;
        }
        return {
            ...state,
            versions: state.previousVersions
        };
    }),
    on(FiltersActions.updatePreviousVersionSelection, state => {
        return {
            ...state,
            previousVersions: state.versions
        };
    }),

    on(FiltersActions.sizeCollectionActions.initialize, (state, { selectableCollections }) => {
        return { ...state, selectableCollections };
    }),

    on(
        FiltersActions.sizeCollectionActions.setSelectedCollections,
        (state, { selectedCollections }) => {
            return { ...state, selectedCollections: selectedCollections.map(({ id }) => id) };
        }
    ),

    on(FiltersActions.sizeCollectionActions.selectCollection, (state, { collectionId }) => {
        const newSelectedCollections = Array.from(
            new Set([...state.selectedCollections, collectionId])
        );

        const selectedSizes = state.selectableCollections.flatMap(selectableCollection => {
            if (!newSelectedCollections.includes(selectableCollection.id)) {
                return [];
            }
            return selectableCollection.sizeIds;
        });

        const newSelectedSizes = Array.from(new Set([...state.sizes, ...selectedSizes]));

        return {
            ...state,
            selectedCollections: newSelectedCollections,
            sizes: newSelectedSizes
        };
    }),

    on(FiltersActions.sizeCollectionActions.deselectCollection, (state, { collectionId }) => {
        const newSelectedCollections = state.selectedCollections.filter(id => id !== collectionId);

        const newSelectedSizes = getSelectedSizesFromSelectedCollections(
            state.selectableCollections,
            newSelectedCollections
        );

        return {
            ...state,
            selectedCollections: newSelectedCollections,
            sizes: newSelectedSizes
        };
    }),

    on(FiltersActions.sizeCollectionActions.selectAllCollections, state => {
        const newSelectedCollections = state.selectableCollections.map(({ id }) => id);

        const newSelectedSizes = [
            ...state.sizes,
            ...getSelectedSizesFromSelectedCollections(
                state.selectableCollections,
                newSelectedCollections
            )
        ];

        return {
            ...state,
            selectedCollections: newSelectedCollections,
            sizes: Array.from(new Set(newSelectedSizes))
        };
    }),

    on(FiltersActions.sizeCollectionActions.deselectAllCollections, state => {
        // filter sizes connected to any collection
        const newSelectedSizes = state.sizes.filter(
            sizeId => !state.selectableCollections.some(({ sizeIds }) => sizeIds.includes(sizeId))
        );

        return {
            ...state,
            selectedCollections: [],
            sizes: newSelectedSizes
        };
    })
);
