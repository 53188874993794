<div class="content-list">
    @if (isShowingAll()) {
        @for (group of groups$ | async; track group.id) {
            <div class="group-wrapper">
                <element-group-wrapper [group]="group">
                    <ng-container
                        elementInputs
                        *ngTemplateOutlet="
                            inputTemplate;
                            context: { selectedElement: undefined, group: group }
                        " />
                </element-group-wrapper>
            </div>
        }
    } @else {
        @let selectedGroup = selectedGroup$ | async;
        @let selectedElement = selectedElement$ | async;
        @if (selectedGroup) {
            <div
                *ngTemplateOutlet="
                    inputTemplate;
                    context: { group: selectedGroup, selectedElement: selectedElement }
                "></div>
        }
    }

    <ng-template
        #inputTemplate
        let-selectedElement="selectedElement"
        let-group="group">
        @let defaultVersion = defaultVersion$ | async;
        @switch (group | versionedGroupType) {
            @case ('widget') {
                <div class="not-supported__message">
                    <span>This element does not support styling</span>
                </div>
            }
            @case ('text') {
                @if (group.elements.length === 1 || selectedElement) {
                    @for (version of sortedSelectedVersions$ | async; track version.id) {
                        <versioned-styling
                            [defaultVersion]="defaultVersion!"
                            [group]="group"
                            [selectedElement]="selectedElement"
                            [version]="version" />
                    }
                } @else {
                    <versioned-styling
                        [defaultVersion]="defaultVersion!"
                        [group]="group"
                        [selectedElement]="selectedElement"
                        [version]="group.version" />
                }
            }
        }
        <style-popover
            [group]="group"
            [version]="group.version" />
    </ng-template>
</div>
