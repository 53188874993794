import { Color } from '@creative/color';
import { ColorType, IColor } from '@domain/color';
import { IPosition } from '@domain/dimension';
import { SimpleCache } from '@studio/utils/simple-cache';
import { clamp, decimal } from '@studio/utils/utils';

export const HEX_COLOR_PATTERN = /^#[0-9a-fA-F]{3}$|^#[0-9a-fA-F]{6}$/;
export const IMPLICIT_HEX_COLOR_PATTERN = /^[0-9a-fA-F]{3}$|^[0-9a-fA-F]{6}$/;
export const RGBA_COLOR_PATTERN =
    /^rgba\((0|255|25[0-4]|2[0-4]\d|1\d\d|0?\d?\d),(0|255|25[0-4]|2[0-4]\d|1\d\d|0?\d?\d),(0|255|25[0-4]|2[0-4]\d|1\d\d|0?\d?\d),(0?(\.\d+)?|1(\.0)?)\)$/;
export const RGB_COLOR_PATTERN =
    /^rgb\(\s*(0|255|25[0-4]|2[0-4]\d|1\d\d|0?\d?\d)\s*,\s*(0|255|25[0-4]|2[0-4]\d|1\d\d|0?\d?\d)\s*,\s*(0|255|25[0-4]|2[0-4]\d|1\d\d|0?\d?\d)\s*\)$/;

export function isColorProperty(key: string): boolean {
    return key === 'fill' || key === 'textColor' || key === 'color';
}

/**
 * Test if string is a valid color or not
 * @param color
 */
export function isColor(color: string): boolean {
    return (
        HEX_COLOR_PATTERN.test(color) ||
        RGBA_COLOR_PATTERN.test(color) ||
        RGB_COLOR_PATTERN.test(color) ||
        IMPLICIT_HEX_COLOR_PATTERN.test(color) ||
        color.indexOf('linear-gradient') === 0
    );
}

/**
 * Return a color if color string is a valid color.
 */
export function parseColorIfValid(color: string): Color | undefined {
    if (isColor(color)) {
        return parseColor(color);
    }
}

/**
 * Parse color string into a color object. If not a recognized pattern
 * return color based on default value
 * @param color
 * @param defaultValue
 */
export function parseColor(color: string | Color, defaultValue = '#FFFFFF'): Color {
    if (color instanceof Color) {
        return new Color(color);
    }

    if (!color) {
        return parseColor(defaultValue);
    }

    const cachedColor = SimpleCache.get<Color>(color);

    if (cachedColor) {
        return cachedColor;
    }

    // Parsers can't handle spaces after commas
    color = typeof color === 'string' ? color.replace(/,\s+/gi, ',') : color;

    let value: Color;

    if (HEX_COLOR_PATTERN.test(color) || IMPLICIT_HEX_COLOR_PATTERN.test(color)) {
        value = fromHEX(color);
    } else if (RGBA_COLOR_PATTERN.test(color)) {
        value = fromRGBAstring(color);
    } else if (RGB_COLOR_PATTERN.test(color)) {
        value = fromRGBstring(color);
    } else if (color.indexOf('linear-gradient') === 0) {
        value = fromLinearGradient(color);
    } else {
        value = parseColor(defaultValue);
    }

    SimpleCache.set<Color>(color, value);

    return value;
}

export function fromHSV(h: number, s: number, v: number): IColor {
    const color = new Color();
    let r!: number;
    let g!: number;
    let b!: number;
    const i = Math.floor(h * 6);
    const f = h * 6 - i;
    const p = v * (1 - s);
    const q = v * (1 - f * s);
    const t = v * (1 - (1 - f) * s);

    switch (i % 6) {
        case 0:
            r = v;
            g = t;
            b = p;
            break;
        case 1:
            r = q;
            g = v;
            b = p;
            break;
        case 2:
            r = p;
            g = v;
            b = t;
            break;
        case 3:
            r = p;
            g = q;
            b = v;
            break;
        case 4:
            r = t;
            g = p;
            b = v;
            break;
        case 5:
            r = v;
            g = p;
            b = q;
            break;
    }

    color.red = Math.round(r * 255);
    color.green = Math.round(g * 255);
    color.blue = Math.round(b * 255);

    const hsl = RGBToHSL(r, g, b);
    color.hue = hsl.h;
    color.saturation = hsl.s;
    color.lightness = hsl.l;

    return color;
}

export function fromRGBA(r: number, g: number, b: number, a = 1): Color {
    const color = new Color();

    if (a > 1 || a < 0) {
        throw new Error('invalid alpha');
    }

    color.red = r;
    color.green = g;
    color.blue = b;
    color.alpha = Math.round(a * 100);

    const hsl = RGBToHSL(r, g, b);
    color.hue = hsl.h;
    color.saturation = hsl.s;
    color.lightness = hsl.l;

    return color;
}

export function fromGrayscale(k: number): Color {
    if (k < 0 || k > 255) {
        throw new Error('Invalid grayscale value');
    }
    const gray = Math.round(k);
    return fromRGBA(gray, gray, gray);
}

export function fromHSL(h: number, s: number, l: number, a = 1): Color {
    const color = new Color();
    color.hue = h;
    color.saturation = s;
    color.lightness = l;
    color.alpha = Math.round(a * 100);

    const rgb = HSLToRGB(h, s, l);
    color.red = rgb.r;
    color.green = rgb.g;
    color.blue = rgb.b;

    return color;
}

export function fromHEX(hex: string): Color {
    if (hex[0] === '#') {
        hex = hex.substring(1, hex.length);
    }
    const color = new Color();
    if (hex.length === 3) {
        color.red = parseInt(hex.substring(0, 1) + hex.substring(0, 1), 16);
        color.green = parseInt(hex.substring(1, 2) + hex.substring(1, 2), 16);
        color.blue = parseInt(hex.substring(2, 3) + hex.substring(2, 3), 16);
    } else if (hex.length === 6) {
        color.red = parseInt(hex.substring(0, 2), 16);
        color.green = parseInt(hex.substring(2, 4), 16);
        color.blue = parseInt(hex.substring(4, 6), 16);
    } else {
        color.red = 255;
        color.green = 255;
        color.blue = 255;
    }
    const hsl = RGBToHSL(color.red, color.green, color.blue);
    color.hue = hsl.h;
    color.saturation = hsl.s;
    color.lightness = hsl.l;

    return color;
}

export function fromRGBstring(rgb: string): Color {
    const color = new Color();

    const matchColors = /rgb\((\d{1,3}),(\d{1,3}),(\d{1,3})\)/;
    const match = matchColors.exec(rgb.replace(/\s/g, ''));

    if (match) {
        color.red = parseInt(match[1], 10);
        color.green = parseInt(match[2], 10);
        color.blue = parseInt(match[3], 10);
    }

    const hsl = RGBToHSL(color.red, color.green, color.blue);
    color.hue = hsl.h;
    color.saturation = hsl.s;
    color.lightness = hsl.l;

    return color;
}

export function fromRGBAstring(rgba: string): Color {
    const color = new Color();
    const matchColors = /^rgba\(([^,]+),([^,]+),([^,]+),([^,]+)\)$/g;
    const match = matchColors.exec(rgba.replace(/\s/g, ''));
    if (match) {
        color.red = parseInt(match[1], 10);
        color.green = parseInt(match[2], 10);
        color.blue = parseInt(match[3], 10);
        color.alpha = Math.round(parseFloat(match[4]) * 100);
    }

    const hsl = RGBToHSL(color.red, color.green, color.blue);
    color.hue = hsl.h;
    color.saturation = hsl.s;
    color.lightness = hsl.l;

    return color;
}

/**
 * Takes 2 colors and returns a color in between. Position 0 will give first color, 0.5 the average and 1 the second color.
 */
export function mixColor(baseColor: Color, ...colors: { color: IColor; amount: number }[]): Color {
    return tintColor(new Color(baseColor), ...colors);
}

/**
 * Takes another color and applies that tinted on the target color instance.
 * amount = 0 will not affect the color, 0.5 the average and 1 the second color completely.
 */
export function tintColor(targetColor: IColor, ...colors: { color: IColor; amount: number }[]): IColor {
    let redOffset = 0;
    let greenOffset = 0;
    let blueOffset = 0;
    let alphaOffset = 0;
    const startPositionOffset = { x: 0, y: 0 };
    const endPositionOffset = { x: 0, y: 0 };
    let startOffset = 0;
    let endOffset = 0;

    colors.forEach(c => {
        const color = c.color;
        const amount = clamp(c.amount, 0, 1);
        const stops = color.type !== 'solid' ? [...color.stops] : [];

        redOffset += (color.red - targetColor.red) * amount;
        greenOffset += (color.green - targetColor.green) * amount;
        blueOffset += (color.blue - targetColor.blue) * amount;
        alphaOffset += (color.alpha - targetColor.alpha) * amount;

        // Gradient color
        if (stops?.length) {
            if (stops.length !== 2) {
                throw new Error('Tinting gradients with more than 2 stops is not supported currently');
            }

            // Turn default state to gradient if it's a solid
            if (!targetColor.stops?.length) {
                targetColor.stops = [];
                stops.forEach((s, index) => {
                    const y = index === 0 ? 0 : 1;
                    targetColor.stops.push({
                        color: new Color(targetColor), // Same color as the solid
                        position: s.position ? { ...s.position } : { x: 0.5, y },
                        offset: s.offset
                    });
                });
                targetColor.type = c.color.type;
            }

            startOffset += (stops[0].offset - targetColor.start.offset) * amount;
            endOffset += (stops[1].offset - targetColor.end.offset) * amount;

            if (
                stops[0].position &&
                targetColor.start.position &&
                stops[1].position &&
                targetColor.end.position
            ) {
                startPositionOffset.x += (stops[0].position.x - targetColor.start.position.x) * amount;
                startPositionOffset.y += (stops[0].position.y - targetColor.start.position.y) * amount;
                endPositionOffset.x += (stops[1].position.x - targetColor.end.position.x) * amount;
                endPositionOffset.y += (stops[1].position.y - targetColor.end.position.y) * amount;
            }
        }
    });

    if (targetColor.stops.length) {
        targetColor.start.offset += startOffset;
        targetColor.end.offset += endOffset;

        targetColor.start.position = {
            x: startPositionOffset.x + targetColor.start.position!.x,
            y: startPositionOffset.y + targetColor.start.position!.y
        };
        targetColor.end.position = {
            x: endPositionOffset.x + targetColor.end.position!.x,
            y: endPositionOffset.y + targetColor.end.position!.y
        };

        const startColors = colors.map(c => {
            let color =
                c.color.type === 'linear-gradient' ? new Color(c.color.start?.color) : undefined;
            if (!color) {
                color = fromRGBA(c.color.red, c.color.green, c.color.blue, c.color.alpha / 100);
            }
            return { color, amount: c.amount };
        });

        const endColors = colors.map(c => {
            let color = c.color.type === 'linear-gradient' ? new Color(c.color.end?.color) : undefined;
            if (!color) {
                color = fromRGBA(c.color.red, c.color.green, c.color.blue, c.color.alpha / 100);
            }
            return { color, amount: c.amount };
        });

        targetColor.start.color = mixColor(targetColor.start.color, ...startColors);
        targetColor.end.color = mixColor(targetColor.end.color, ...endColors);
        targetColor.alpha += alphaOffset;
    } else {
        targetColor.red += redOffset;
        targetColor.green += greenOffset;
        targetColor.blue += blueOffset;
        targetColor.alpha += alphaOffset;
    }

    return targetColor;
}

function extractColorStopPositionFromAngle(offset: number, cos: number, sin: number): IPosition {
    const offsetX = 0.5;
    const offsetY = 1 - offset / 100;
    const x = cos * (offsetX - 0.5) - sin * (offsetY - 0.5) + 0.5;
    const y = cos * (offsetY - 0.5) + sin * (offsetX - 0.5) + 0.5;
    return { x, y };
}

function createGradientFromString(splitStr: string[], angle: number | undefined): Color {
    const color = new Color();
    color.type = ColorType.LinearGradient;
    const rad = (Math.PI * (angle || 0)) / 180;
    const cos = Math.cos(rad);
    const sin = Math.sin(rad);

    for (let i = 0; i < splitStr.length; i++) {
        const data = splitStr[i].split(' ');
        let colorStr: string;
        let offset: number;
        let position: IPosition | undefined;
        // CSS format: linear-gradient(0deg,#000000 0%,#ffffff 100%)
        if (angle !== undefined) {
            colorStr = data[0].trim();
            offset = parseInt(data[1], 10);
            if (i === 0 || i === splitStr.length - 1) {
                position = extractColorStopPositionFromAngle(offset, cos, sin);
            }
        } else {
            colorStr = data[data.length - 1];
            offset = i === 0 ? 0 : 100;

            if (data.length === 3) {
                position = {
                    x: parseFloat(data[0]),
                    y: parseFloat(data[1])
                };
            } else {
                offset = parseFloat(data[0]);
            }
        }
        color.addColorStop(parseColor(colorStr), offset, position);
    }
    return color;
}

export function fromLinearGradient(linearGradient: string): Color {
    const str = linearGradient.substring(
        linearGradient.indexOf('(') + 1,
        linearGradient.lastIndexOf(')')
    );
    const splitStr = str.split(/,(?![^(]*\))(?![^"']*["'](?:[^"']*["'][^"']*["'])*[^"']*$)/);
    let angle: number | undefined;
    if (splitStr[0].indexOf('deg') > -1) {
        angle = parseInt(splitStr.shift() || '0', 10);
    }

    return createGradientFromString(splitStr, angle);
}

/**
 * Checks if a two color instances has the same color value
 */
export function isSameColor(color1?: Color, color2?: Color): boolean {
    if (!color1 && !color2) {
        return true;
    } else if (!color1 || !color2) {
        return false;
    } else {
        return toRGBA(color1) === toRGBA(color2);
    }
}

/**
 * Calculates HSL Color
 * RGB must be normalized
 * Must be executed in a Color object context
 * http://mjijackson.com/2008/02/rgb-to-hsl-and-rgb-to-hsv-color-model-conversion-algorithms-in-javascript
 */
export function RGBToHSL(r: number, g: number, b: number): { h: number; s: number; l: number } {
    r = r / 255;
    g = g / 255;
    b = b / 255;
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h!: number;
    let s!: number;
    const l = (max + min) / 2;
    if (max === min) {
        h = s = 0; // achromatic
    } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0);
                break;
            case g:
                h = (b - r) / d + 2;
                break;
            case b:
                h = (r - g) / d + 4;
                break;
        }
        h /= 6;
    }

    return { h: h, s: s, l: l };
}

/**
 * Calculates RGB color (normalized)
 * HSL must be normalized
 * Must be executed in a Color object context
 * http://mjijackson.com/2008/02/rgb-to-hsl-and-rgb-to-hsv-color-model-conversion-algorithms-in-javascript
 */
export function HSLToRGB(h: number, s: number, l: number): { r: number; g: number; b: number } {
    let r: number;
    let g: number;
    let b: number;
    const hue2rgb = (p: number, q: number, t: number): number => {
        if (t < 0) {
            t += 1;
        }
        if (t > 1) {
            t -= 1;
        }
        if (t < 1 / 6) {
            return p + (q - p) * 6 * t;
        }
        if (t < 1 / 2) {
            return q;
        }
        if (t < 2 / 3) {
            return p + (q - p) * (2 / 3 - t) * 6;
        }
        return p;
    };
    if (s === 0) {
        r = g = b = l;
    } else {
        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;
        r = hue2rgb(p, q, h + 1 / 3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1 / 3);
    }

    return { r: r * 255, g: g * 255, b: b * 255 };
}

/**
 * Round color channel and limit value between 0 and 255
 */
export function roundChannel(channel: number): number {
    return clamp(channel, 0, 255) >>> 0;
}

/**
 * Take a number between 0 and 255 and turn it into
 * a two digit hex string like: "0F", "FF" and "99"
 */
export function channelToHex(channel: number): string {
    channel = roundChannel(channel);
    return (channel < 16 ? '0' : '') + channel.toString(16).toUpperCase();
}

export function isColorTransparent(color: IColor): boolean {
    if (color.type === ColorType.Solid) {
        return color.alpha < 100;
    }
    return color.stops.some(s => isColorTransparent(s.color));
}

export function toHSV(color: IColor): { h: number; s: number; v: number } {
    if (color.type !== 'solid') {
        throw new Error('Cannot convert a none solid color to HSV format.');
    }

    let rr: number;
    let gg: number;
    let bb: number;
    const r = color.red / 255;
    const g = color.green / 255;
    const b = color.blue / 255;
    let h: number;
    let s: number;
    const v = Math.max(r, g, b);
    const diff = v - Math.min(r, g, b);
    const diffc = (c: number): number => (v - c) / 6 / diff + 1 / 2;

    if (diff === 0) {
        h = s = 0;
    } else {
        s = diff / v;
        rr = diffc(r);
        gg = diffc(g);
        bb = diffc(b);

        if (r === v) {
            h = bb - gg;
        } else if (g === v) {
            h = 1 / 3 + rr - bb;
        } else if (b === v) {
            h = 2 / 3 + gg - rr;
        }
        if (h! < 0) {
            h! += 1;
        } else if (h! > 1) {
            h! -= 1;
        }
    }

    return { h: h!, s, v };
}

export function toHEX(color: IColor): string {
    if (color.type !== ColorType.Solid) {
        throw new Error('Cannot convert a none solid color to HEX format.');
    }

    return `#${channelToHex(color.red)}${channelToHex(color.green)}${channelToHex(color.blue)}`;
}

export function toRGB(color: IColor): string {
    if (color.type !== ColorType.Solid) {
        throw new Error('Cannot convert a none solid color to RGB format.');
    }

    return `rgb(${roundChannel(color.red)},${roundChannel(color.green)},${roundChannel(color.blue)})`;
}

export function toRGBA(color: IColor): string {
    if (color.type !== ColorType.Solid) {
        throw new Error('Cannot convert a none solid color to RGBA format.');
    }
    return `rgba(${roundChannel(color.red)},${roundChannel(color.green)},${roundChannel(
        color.blue
    )},${decimal(color.alpha / 100)})`;
}

export function toLinearGradientCSS(color: IColor): string {
    return `linear-gradient(${color.angle}deg, ${color.stops
        .map(stop => {
            const stopColor = parseColor(stop.color);
            stopColor.alpha = Math.round(stopColor.alpha * (color.alpha / 100));
            return `${toRGBA(stopColor)} ${stop.offset}%`;
        })
        .join(',')})`;
}

export function toLinearGradient(color: IColor): string {
    return `linear-gradient(${color.stops
        .map(stop => {
            const stopColor = parseColor(stop.color);
            stopColor.alpha = stopColor.alpha * (color.alpha / 100);

            let positionOrOffset = '';
            if (stop.position) {
                positionOrOffset = `${decimal(stop.position.x)} ${decimal(stop.position.y)}`;
            } else if (stop.offset) {
                positionOrOffset = `${decimal(stop.offset)}`;
            }

            return `${positionOrOffset} ${toRGBA(stopColor)}`;
        })
        .join(',')})`;
}

export function toCSS(color: IColor): string {
    switch (color.type) {
        case ColorType.Solid:
            return toRGBA(color);
        case ColorType.LinearGradient:
            return toLinearGradientCSS(color);
    }
}
