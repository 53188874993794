<studio-ui-section
    headline="Feeds"
    [collapsable]="true">
    <ng-container *ngIf="feeds$ | async as feeds">
        <ng-container *ngFor="let group of elements | groupfeeds: feeds | sortgroup">
            <div *ngIf="group.isExpanded; else groupedTexts">
                <ng-container *ngFor="let element of group.elements; let i = index">
                    <div class="section-wrapper">
                        <div class="section">
                            <div class="title-wrapper">
                                <ui-svg-icon
                                    (click)="group.isExpanded = false"
                                    *ngIf="i === 0"
                                    class="icon"
                                    icon="collapse"></ui-svg-icon>
                                <span
                                    *ngIf="i > 0"
                                    class="title-prefix"
                                    >-</span
                                >
                                <div class="title">
                                    {{ element.name }}
                                </div>
                            </div>
                            <div class="merged-feed-picker">
                                <div
                                    class="merged-feed-picker__data"
                                    #popoverTarget>
                                    <ui-svg-icon
                                        class="merged-feed-picker__icon"
                                        [icon]="'feed'"></ui-svg-icon>
                                    <div class="merged-feed-picker__name">
                                        {{ element.feedName }}
                                    </div>
                                </div>
                                <div class="merged-feed-picker__control">
                                    <feed-picker
                                        (feedSelectionChanged)="useSelectedFeedItem($event, [element])"
                                        [value]="element.feedElement!"
                                        [minimized]="true"></feed-picker>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <ng-template #groupedTexts>
                <div class="section-wrapper">
                    <div class="section">
                        <div class="title-wrapper">
                            <ui-svg-icon
                                (click)="group.isExpanded = true"
                                class="icon"
                                icon="expand"
                                *ngIf="group.elements.length > 1"></ui-svg-icon>
                            <div
                                class="title"
                                [uiTooltip]="group.elements | headline">
                                {{ group.elements | headline }}
                            </div>
                        </div>

                        <div class="merged-feed-picker">
                            <div
                                class="merged-feed-picker__data"
                                #popoverTarget>
                                <ui-svg-icon
                                    class="merged-feed-picker__icon"
                                    [icon]="'feed'"></ui-svg-icon>
                                <div class="merged-feed-picker__name">
                                    {{ group.elements[0].feedName }}
                                </div>
                            </div>
                            <div class="merged-feed-picker__control">
                                <feed-picker
                                    (feedSelectionChanged)="useSelectedFeedItem($event, group.elements)"
                                    [value]="group.elements[0].feedElement!"
                                    [minimized]="true"></feed-picker>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ng-container>
    </ng-container>
</studio-ui-section>
