import { IFontFamily, IFontFamilyStyle } from '@domain/font-families';
import { createAction, props } from '@ngrx/store';
import { LayerItem } from '@studio/domain/components/psd-import/psd';

export const resetState = createAction('[CreativeConverter] Reset State');

export const setLayers = createAction(
    '[CreativeConverter] Set Layers',
    props<{ layers: LayerItem[] }>()
);

export const resetFontToFix = createAction('[CreativeConverter] Reset Font to fix');

export const setFontToFix = createAction(
    '[CreativeConverter] Set Font to Fix',
    props<{ fontToFix: string; applyToAll: boolean }>()
);

export const setApplyToAll = createAction(
    '[CreativeConverter] Set Apply to all',
    props<{ applyToAll: boolean }>()
);

export const useAlternativeFont = createAction(
    '[CreativeConverter] Use alternative font',
    props<{ fontToFix: string; applyToAll: boolean }>()
);

export const toggleCollapse = createAction(
    '[CreativeConverter] Toggle Collapse on layer',
    props<{ layer: LayerItem }>()
);

export const toggleVisibility = createAction(
    '[CreativeConverter] Toggle Visibility on layer',
    props<{ layer: LayerItem }>()
);

export const toggleSelection = createAction(
    '[CreativeConverter] Toggle Selection on layer',
    props<{ layer: LayerItem }>()
);

export const toggleAllSelection = createAction(
    '[CreativeConverter] Toggle All Selection ',
    props<{ newSelectedValue: boolean }>()
);

export const fixFontWith = createAction(
    '[CreativeConverter] Fix font with',
    props<{ fontStyle: IFontFamilyStyle; fontFamily: IFontFamily }>()
);
