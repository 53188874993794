import { ImageElementDto, ImageSettingsDto } from '@domain/api/generated/sapi';
import { IPosition, ISize } from '@domain/dimension';
import { DEFAULT_IMAGE_SETTINGS, IImageSettings, ImageFitOption, ImageSizeMode } from '@domain/image';
import { aspectRatioScale } from '@studio/utils/geom';
import { clamp, toFixedDecimal } from '@studio/utils/utils';

export function deserializeImageSettings(
    element: ImageElementDto,
    originalSize?: Partial<ISize>
): IImageSettings {
    if (isMigratedImageSetting(element.imageSettings)) {
        return {
            ...element.imageSettings!,
            sizeMode: element.imageSettings.sizeMode as ImageSizeMode
        };
    }

    const migratedImageSettings = migrateFitToImageSettings(element, originalSize);

    return {
        ...migratedImageSettings,
        sizeMode: migratedImageSettings.sizeMode as ImageSizeMode
    };
}

function isMigratedImageSetting(imageSettings: ImageSettingsDto | undefined): boolean {
    if (!imageSettings) {
        return false;
    }

    const { x, y } = imageSettings;
    return x >= 0 && x <= 1 && y >= 0 && y <= 1;
}

export function serializeImageSettings(settings: IImageSettings): ImageSettingsDto {
    return { ...settings };
}

function migrateFitToImageSettings(
    element: ImageElementDto,
    originalSize: Partial<ISize> = {}
): ImageSettingsDto {
    // Feeds do not have an image asset
    if (!originalSize.width || !originalSize.height) {
        originalSize.width = element.width;
        originalSize.height = element.height;
    }

    const settings: ImageSettingsDto = {
        ...element.imageSettings,
        sizeMode: fitOptionToSizeMode(element.imageSettings?.sizeMode),
        ...getFitPositionAsFractionAlign(element, originalSize as ISize)
    };

    return settings;
}

/**
 * Translates fitPositionX/Y to an align
 */
export function getFitPositionAsFractionAlign(
    element: ImageElementDto,
    originalSize: ISize
): IPosition {
    let x = 0.5;
    let y = 0.5;

    const imageSettings = element.imageSettings;

    const fitOption = imageSettings?.sizeMode;
    const fitPositionX = Number(imageSettings?.x || 0);
    const fitPositionY = Number(imageSettings?.y || 0);

    // fitPosition only applies to cover. Contain is not supported yet
    if (fitOption === 'crop' && originalSize?.width && originalSize?.height) {
        const renderedImageSize = aspectRatioScale(originalSize, element, 'cover');
        const overflowX = renderedImageSize.width - element.width;
        if (fitPositionX && overflowX > 0) {
            // fitPositionX = -1 means that we move the image 0.5px left
            x = toFixedDecimal(clamp(0.5 - (fitPositionX * 0.5) / overflowX, 0, 1));
        }

        const overflowY = renderedImageSize.height - element.height;
        if (fitPositionY && overflowY > 0) {
            // fitPositionY = -1 means that we move the image 0.5px up
            y = toFixedDecimal(clamp(0.5 - (fitPositionY * 0.5) / overflowY, 0, 1));
        }
    }

    return {
        x,
        y
    };
}

export function fitOptionToSizeMode(
    fitOption?: ImageFitOption | ImageSizeMode | string
): ImageSizeMode {
    switch (fitOption) {
        case 'cover':
        case 'crop':
            return ImageSizeMode.Crop;
        case 'distort':
        case 'stretch':
            return ImageSizeMode.Stretch;
        case 'contain':
        case 'fit':
            return ImageSizeMode.Fit;
        default:
            return DEFAULT_IMAGE_SETTINGS.sizeMode;
    }
}
