import { inject, Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UIInputComponent } from '@bannerflow/ui';
import { IBrandLibraryElement, INewBrandLibraryElement } from '@domain/brand/brand-library';
import { BehaviorSubject } from 'rxjs';
import { BrandLibraryDataService } from '../../../shared/media-library/brand-library.data.service';

@Injectable({ providedIn: 'root' })
export class BrandLibraryElementEditService {
    private brandLibraryDataService = inject(BrandLibraryDataService);

    isEditingName$$ = new BehaviorSubject<boolean>(false);

    validateName({
        generateName,
        inputComponent,
        nameValidation,
        brandLibraryElement
    }: {
        generateName: boolean;
        inputComponent: UIInputComponent;
        nameValidation: UntypedFormControl;
        brandLibraryElement: IBrandLibraryElement | INewBrandLibraryElement | undefined;
    }): undefined | 'invalid' | 'duplicate' {
        let errorType: undefined | 'invalid' | 'duplicate';
        let nameIndex = 0;
        let newName = '';
        let hasDuplicateName = false;
        inputComponent.value = inputComponent.value!.trim();
        const checkName = (): void => {
            let nameExists = false;
            hasDuplicateName = false;
            const type = brandLibraryElement?.type ?? 'element';
            const name2 = nameIndex ? `My ${type} ${nameIndex}` : `My ${type}`;
            for (const element of this.brandLibraryDataService.brandLibrary!.elements) {
                if (brandLibraryElement?.id === element.id) {
                    continue;
                }

                if (inputComponent.value === element.name) {
                    hasDuplicateName = true;
                    break;
                } else if (brandLibraryElement?.id !== element.id && element.name === name2) {
                    nameIndex++;
                    nameExists = true;
                    break;
                }
            }
            newName = name2;
            if (nameExists) {
                hasDuplicateName = true;
                checkName();
            }
        };

        if (!inputComponent.value.length) {
            errorType = errorType || 'invalid';
        } else {
            generateName = false;
        }

        checkName();

        if (!inputComponent.value.length && generateName) {
            inputComponent.value = newName;
            nameValidation.setValue(newName);
            errorType = errorType || 'invalid';
            setTimeout(() => {
                inputComponent.focus(true);
            });
        }

        if (hasDuplicateName && nameValidation.touched) {
            errorType = errorType || 'duplicate';
        }

        if (errorType) {
            nameValidation.markAsTouched();
            nameValidation.setErrors({ errorFlash: true, errorType });
            setTimeout(() => nameValidation.setErrors({ errorType }), 300);
        } else {
            nameValidation.setErrors(null);
        }

        return errorType;
    }
}
