export enum DuplicateCreativeStatus {
    Completed,
    InternalError
}

export interface DuplicateCreativeResult {
    status: DuplicateCreativeStatus;
    creativeSetId: string;
}

export interface ConnectedUser {
    connectionId: string;
    isActive: boolean;
    name: string;
    shortName: string;
    color: string;
}

export interface UserActivity {
    action: string;
    user: ConnectedUser;
    timestamp: string;
}

export interface UsersUpdate {
    users: ConnectedUser[];
}

export type ListHubServerToClientEvent = {
    SizesAndVersionsIntoNewDuplicationCompleted: DuplicateCreativeResult;
};

export type StudioServerToClientEvent = {
    UsersUpdated: UsersUpdate;
    UserActivity: UserActivity;
};
