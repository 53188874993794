import { zodOptional } from '@zod/zod';
import { z } from 'zod';

export const ZWidgetLibraryAsset = z.object({
    id: z.string(),
    thumbnail: zodOptional(z.string()),
    animatedThumbnail: zodOptional(z.string()),
    bannerflowLibraryId: zodOptional(z.string()),
    created: z.string(),
    modified: zodOptional(z.string())
});

export type IWidgetLibraryAsset = z.infer<typeof ZWidgetLibraryAsset>;

export enum LibraryWidgetState {
    Active = 0,
    Inactive = 1
}

export type LibraryWidget = {
    id?: string;
    name: string;
    thumbnailUrl?: string;
    animatedThumbnail?: string;
    comment: string;
    properties: {
        name: string;
        label?: string;
        value?: string;
        unit?: string;
    }[];
    exportable: boolean;
    state?: LibraryWidgetState;
    versionId?: string;
    imported?: boolean;
    created?: string;
    modified?: string;
};
