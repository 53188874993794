<ui-dropdown
    type="menu"
    #dropdown
    (open)="updateIcons()">
    <ui-dropdown-item
        id="clear-font-validation"
        data-test-id="toggle-font-validation"
        class="custom-dropdown"
        (click)="clearFontValidationWarnings()">
        <div class="custom-item">
            <ui-svg-icon
                icon="checkbox-checkmark-default"
                [ngClass]="{ active: showIcon }"></ui-svg-icon>
            <div class="custom-text">Font validation</div>
            <a
                target="_blank"
                rel="noopener"
                href="http://support.bannerflow.com/en/articles/6046094-how-to-solve-issues-with-missing-font-characters"
                >Read more</a
            >
        </div>
    </ui-dropdown-item>
    <ui-dropdown-item
        id="clear-video-validation"
        class="custom-dropdown"
        (click)="clearVideoValidationWarnings()">
        <div class="custom-item">
            <ui-svg-icon
                icon="checkbox-checkmark-default"
                [ngClass]="{ active: showVideoIcon }"></ui-svg-icon>
            <div class="custom-text">Video validation</div>
            <a
                target="_blank"
                rel="noopener"
                href="http://support.bannerflow.com/en/articles/6401112-working-with-heavy-videos"
                >Read more</a
            >
        </div>
    </ui-dropdown-item>
    <ui-dropdown-item
        id="clear-all-validation"
        class="custom-dropdown"
        (click)="clearAllValidationWarnings()">
        <div class="custom-item">
            <div class="custom-text">Clear all</div>
        </div>
    </ui-dropdown-item>
</ui-dropdown>
