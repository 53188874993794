<ng-container *ngIf="!guideline">
    <ng-container *ngIf="selectedElements$ | async as selectedElements">
        <ng-container *ngIf="selectedElements?.length === 1 && !showKeyframePanel && !preview">
            <state-tabs></state-tabs>
        </ng-container>

        <align-bar
            *ngIf="!preview"
            (align)="alignSelection($event)"
            (distribute)="distributeSelection($event)"></align-bar>

        <!-- No node selected -->
        <ng-container *ngIf="!selectedElements.length">
            <div class="settings">
                <creative-properties
                    id="interaction-creative-properties"
                    [animator]="animator"
                    [editor]="editor">
                </creative-properties>
                <ng-container *permissions="'CalculateWeights'">
                    <size-breakdown *ngIf="!preview"></size-breakdown>
                </ng-container>
            </div>
        </ng-container>
        <!-- Node selected -->

        <div
            *ngIf="selectedElements.length"
            [id]="
                'interaction-properties-' +
                (selectedElements.length > 1
                    ? 'mixed'
                    : selectedElements.length === 1
                      ? selectedElements[0].kind
                      : '')
            "
            class="settings">
            <!-- Layout properties -->
            <layout-properties
                [isPropertiesHidden]="isPropertiesHidden"
                [readonly]="preview"
                [elements$]="selectedElements$"></layout-properties>

            <!-- Dynamic content properties -->
            <ng-container *ngIf="selectedElements$ | async as selectedElements">
                @if (isDynamicContentPropertiesVisible(selectedElements)) {
                    <dynamic-content-properties
                        [elements$]="selectedElements$"></dynamic-content-properties>
                }
            </ng-container>

            <!-- Image -->
            <ng-container *ngIf="selectedImageElements$ | async as selectedImageElements">
                <image-properties
                    *ngIf="selectedImageElements.length && !inStateView"
                    [editor]="editor"
                    [images]="selectedImageElements"></image-properties>
            </ng-container>

            <!-- Widget -->
            <ng-container *ngIf="selectedWidgetElements$ | async as selectedWidgetElements">
                <widget-properties
                    *ngIf="selectedWidgetElements.length === 1 && !inStateView"
                    [elements$]="selectedWidgetElements$"
                    [preview]="preview"></widget-properties>
            </ng-container>

            <ng-container *ngIf="!isPropertiesHidden">
                <!-- Text properies -->
                <ng-container *ngIf="selectedTextElements$ | async as selectedTextElements">
                    <text-properties
                        *ngIf="selectedTextElements.length"
                        [elements$]="selectedTextElements$"></text-properties>
                </ng-container>

                <!-- Video properies -->
                <ng-container *ngIf="selectedVideoElements$ | async as selectedVideoElements">
                    <video-properties
                        *ngIf="selectedVideoElements.length && !inStateView"
                        [elements$]="selectedVideoElements$">
                    </video-properties>
                </ng-container>

                <!-- Default properties -->
                <ng-container *ngIf="selectedElements.length >= 1">
                    <default-properties
                        *ngIf="!hideDefaultProperties"
                        [elements$]="selectedElements$"></default-properties>

                    <reserved-state-settings *ngIf="inReservedStateView"></reserved-state-settings>
                    <ng-container *ngIf="!inStateView">
                        <!-- In animation -->
                        <animation-properties
                            *ngIf="animator"
                            type="in"
                            [animator]="animator"
                            [elements$]="selectedElements$"></animation-properties>

                        <!-- Out animation -->
                        <animation-properties
                            *ngIf="animator"
                            type="out"
                            [animator]="animator"
                            [elements$]="selectedElements$"></animation-properties>

                        <action-properties
                            #actions
                            *ngIf="!preview && !isWidgetElement && selectedElements.length === 1"
                            [element]="selectedElements[0]"></action-properties>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
        <keyframe-properties *ngIf="showKeyframePanel"></keyframe-properties>
    </ng-container>
</ng-container>

<!-- Guideline -->
<div
    class="settings"
    *ngIf="guideline">
    <guideline-properties [guideline]="guideline"></guideline-properties>
</div>
