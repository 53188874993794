import { Component, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { SelectedSizes } from '@studio/domain/state';
import { SizeAddStateService } from '../../state/size-add.service';

@Component({
    standalone: true,
    imports: [],
    selector: 'size-add-tooltip',
    templateUrl: './size-add-tooltip.component.html',
    styleUrl: './size-add-tooltip.component.scss'
})
export class SizeAddTooltipComponent {
    private sizeAddStateService = inject(SizeAddStateService);
    private selectedSizes = toSignal(this.sizeAddStateService.selectedSizes$, {
        initialValue: {} as SelectedSizes
    });

    numberOfSelectedSizes = computed(() => this.computeNumberOfSelectedSizes());

    clearSelection(): void {
        this.sizeAddStateService.clearSelection();
    }

    private computeNumberOfSelectedSizes(): number {
        const selectedSizes = this.selectedSizes();

        const count = Object.values(selectedSizes).reduce(
            (acc, { numberOfSizes }) => acc + numberOfSizes,
            0
        );

        return count;
    }
}
