<ui-dropdown
    #dropdown
    type="menu">
    <ng-container *permissions="'Default'">
        <ng-container *permissions="'ManageVersions'">
            <ui-dropdown-item
                id="manage-versions"
                class="menu-option"
                (click)="manageVersions()">
                Manage versions
            </ui-dropdown-item>
        </ng-container>
        <ng-container *permissions="'EditCreative'">
            <ui-dropdown-item
                id="manage-fonts"
                (click)="manageFonts()">
                Manage fonts
            </ui-dropdown-item>
        </ng-container>
        <ui-dropdown-item
            id="exit-to-manage-view"
            (click)="navigateToMV()">
            Exit
        </ui-dropdown-item>
    </ng-container>
</ui-dropdown>
