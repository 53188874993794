<div class="size-label">
    <div
        *ngIf="creative"
        class="creative-size">
        <div
            class="creative-size-fake-input"
            [ngClass]="{ editing: isEditing() && !isCreativeGroup }">
            <div
                *permissions="'Status'"
                class="approval-status-wrapper"
                [ngClass]="{ 'margin-right-s': isCreativeGroup }"
                [uiDropdownTarget]="statusDropdown && statusDropdown.dropdown">
                <ui-svg-icon
                    class="status-icon status-icon_{{ statusClass() }}"
                    icon="status-dot"></ui-svg-icon>
                <div
                    class="approval-status-selector"
                    [id]="'approval-status-selector-' + index">
                    <ui-svg-icon
                        class="status-icon status-icon_{{ statusClass() }}"
                        icon="status-dot"></ui-svg-icon>
                    <div>
                        {{ creative.approvalStatus || 'No status' }}
                    </div>
                    <div class="arrow">
                        <ui-svg-icon icon="arrow-down"></ui-svg-icon>
                    </div>
                </div>
                <status-dropdown #statusDropdown></status-dropdown>
            </div>

            <ng-container
                *ngIf="creative.version.id | localizationForVersion | async as localizationId">
                <version-flag
                    *ngIf="isCreativeGroup"
                    [localizationId]="localizationId"
                    [versionId]="creative.version.id"
                    [showVersionName]="true"></version-flag>
            </ng-container>

            <span
                *ngIf="!isCreativeGroup"
                class="creative-size-name"
                [ngClass]="{
                    empty:
                        (!creative.size.name || !creative.size.name.length) &&
                        !isEditing() &&
                        !isSaving(),
                    editing: isEditing(),
                    saving: isSaving()
                }">
                {{ creative.size.width }} × {{ creative.size.height }}
            </span>
            <span
                *ngIf="creative.size.name && !isEditing() && !isCreativeGroup"
                class="size-name"
                truncateSpan
                [spanText]="creative.size.name"
                (click)="editSizeName()">
            </span>

            <ng-container *permissions="'Default'">
                <input
                    #nameInput
                    *ngIf="isEditing() && !isSaving() && !isCreativeGroup"
                    class="name-input"
                    placeholder="Name"
                    type="text"
                    [maxlength]="250"
                    [ngModel]="creative.size.name"
                    [style.width.px]="
                        (sizeNameCalc && sizeNameCalc.nativeElement.scrollWidth + 15) || 30
                    "
                    (blur)="saveSizeName(nameInput.value)"
                    (click)="$event.stopPropagation()"
                    (clickOutside)="saveSizeName(nameInput.value)"
                    (keyup.enter)="saveSizeName(nameInput.value)"
                    (keyup.esc)="cancelEditSizeName()" />
                <div
                    #sizeNameCalc
                    *ngIf="isEditing() && !isCreativeGroup"
                    class="size-name-calc">
                    {{ creative.size.name }}
                </div>
                <div
                    *ngIf="isSaving() && !isCreativeGroup"
                    class="edit-loader">
                    <ui-loader></ui-loader>
                </div>
                <ui-svg-icon
                    *ngIf="!isSaving() && !isCreativeGroup"
                    class="hover-icon"
                    icon="edit"
                    [uiTooltip]="
                        creative.size.name && creative.size.name.length ? 'Edit name' : 'Add name'
                    "
                    (click)="editSizeName()"></ui-svg-icon>
            </ng-container>
        </div>
    </div>
</div>
