import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as FontFamiliesActions from '@studio/stores/font-families';
import { map } from 'rxjs';
import * as CreativeConverterActions from './creative-converter.actions';

@Injectable()
export class CreativeConverterEffects {
    private actions = inject(Actions);

    fixFont$ = createEffect(() => {
        return this.actions.pipe(
            ofType(CreativeConverterActions.fixFontWith),
            map(() => FontFamiliesActions.resetExternalFonts())
        );
    });
}
