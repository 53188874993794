import { CommonModule } from '@angular/common';
import { Component, computed, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { StudioUISliderComponent } from '@app/shared/components/studio-ui-slider/studio-ui-slider.component';
import { UIModule } from '@bannerflow/ui';
import { ExpandablePromptInputType } from '@studio/domain/components/ai-studio.types';
import { GenAIService } from '../../state/gen-ai.service';
import { ExpandablePromptComponent } from '../expandable-prompt/expandable-prompt.component';

@Component({
    standalone: true,
    imports: [UIModule, StudioUISliderComponent, CommonModule, ExpandablePromptComponent],
    selector: 'generative-fill-options',
    templateUrl: './generative-fill-options.component.html',
    styleUrls: ['./generative-fill-options.component.scss']
})
export class GenerativeFillOptionsComponent {
    private genAiService = inject(GenAIService);

    prompt = signal('');
    negativePrompt = signal('');
    showNegativePrompt = false;
    brushSizeSignal = toSignal(this.genAiService.generativeFillBrushSize$, { initialValue: 40 });
    isRequestingInpaint = toSignal(this.genAiService.isRequestingInpaint$, { initialValue: false });
    private brushHistory = toSignal(this.genAiService.generativeFillBrushHistory$);
    private hasMask = computed(() => !!this.brushHistory()?.length);
    actionsDisabled = computed(() => !this.prompt() || this.isRequestingInpaint() || !this.hasMask());

    ExpandablePromptInputType = ExpandablePromptInputType;

    onPromptChange(newValue: string): void {
        this.prompt.set(newValue);
    }

    onNegativePromptChange(newValue: string): void {
        this.negativePrompt.set(newValue);
    }

    setGenerativeFillBrushSize(brushSize: number): void {
        this.genAiService.setGenerativeFillBrushSize(brushSize);
    }

    inpaint(): void {
        this.genAiService.inpaint(this.prompt(), this.negativePrompt());
    }
}
