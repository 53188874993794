import { Pipe, PipeTransform } from '@angular/core';
import {
    ISocialPlacement,
    MetaPlacement,
    PinterestPlacement,
    SnapchatPlacement,
    TiktokPlacement
} from '@domain/social';
import { handleError } from '@studio/utils/errors';

@Pipe({ standalone: true, name: 'socialImage' })
export class SocialImagePipe implements PipeTransform {
    transform(placement: ISocialPlacement): string {
        const image = this.getImage(placement);
        return `assets/images/social/${image}`;
    }

    private getImage(placement: ISocialPlacement): string {
        switch (placement.type) {
            case MetaPlacement.Instagram:
                return 'ig-cta.svg';
            case MetaPlacement.Facebook:
                return 'fb-cta.svg';
            case MetaPlacement.Messenger:
                return 'story-cta.svg';
            case MetaPlacement.FacebookReels:
                return 'fb-reels.svg';
            case MetaPlacement.InstagramReels:
                return 'insta-reels.svg';
            case TiktokPlacement.Default:
                return 'tiktok-default.svg';
            case TiktokPlacement.After9s:
                return 'tiktok-after-9s.svg';
            case TiktokPlacement.After9sWithCard:
                return 'tiktok-after-9s-with-card.svg';
            case PinterestPlacement.BrowseImage:
                return 'pinterest-browse-image.svg';
            case PinterestPlacement.BrowseVideo:
                return 'pinterest-browse-video.svg';
            case PinterestPlacement.WatchVideo:
                return 'pinterest-watch-video.svg';
            case SnapchatPlacement.Default:
                return 'snapchat.svg';
            default:
                handleError('Unhandled social placement', {
                    level: 'warning',
                    contexts: { placement }
                });
                return '';
        }
    }
}
