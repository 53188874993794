import { IRadius } from '@domain/style';

export function createRoundedShape(w: number, h: number, radius: IRadius): string {
    const { topLeft: tl, topRight: tr, bottomRight: br, bottomLeft: bl } = radius;

    return (
        `M 0 ${tl} A ${tl} ${tl} 0 0 1 ${tl} 0` +
        ` L ${w - tr} 0 A ${tr} ${tr} 0 0 1 ${w} ${tr}` +
        `L ${w} ${h - br} A ${br} ${br} 0 0 1 ${w - br} ${h}` +
        `L ${bl} ${h} A ${bl} ${bl} 0 0 1 0 ${h - bl} Z`
    );
}
