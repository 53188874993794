import { ErrorObject, DefinedError } from 'ajv';

type UniquePropertyError = ErrorObject<'uniqueItemProperties'>;
type ImageAssetExistsError = ErrorObject<'imageAssetExists'>;
type PrecisionError = ErrorObject<'precisionOf'>;
export type ValidError =
    | ErrorObject
    | DefinedError
    | UniquePropertyError
    | ImageAssetExistsError
    | PrecisionError;

export interface IValidationError extends Error {
    readonly errors: Partial<ErrorObject>[];
    readonly ajv: boolean;
    readonly validation: boolean;
}

export class ValidationError extends Error implements IValidationError {
    ajv = true;
    validation = true;

    constructor(public readonly errors: ValidError[]) {
        super('ValidationError');
        this.name = 'ValidationError';
        const messages = errors.map(e => `${e.instancePath} ${e.message}`);
        this.message = messages.join('. ');
    }
}

export function isValidationError(err: Error): err is IValidationError {
    return Object.prototype.hasOwnProperty.call(err, 'validation');
}
