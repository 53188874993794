import { inject, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IHotkeyMeta } from '@domain/hotkeys/hotkeys.types';

@Pipe({
    standalone: true,
    name: 'hotkeySeparateByWord'
})
export class HotkeySeparateByWordPipe implements PipeTransform {
    private sanitizer = inject(DomSanitizer);

    transform(value?: IHotkeyMeta['shortcut']): SafeHtml {
        if (typeof value !== 'string') {
            return this.sanitizer.bypassSecurityTrustHtml('');
        }

        const valueArr = value.split(' ');
        const regex = new RegExp(/(\b(or)\b)|\+|(\(Hold\)|(\(KeyUp\))|(\(DoubleTap\)))/, 'g');
        const regexOr = new RegExp(/(\b(or)\b)/, 'g');
        const regexClick = new RegExp(/(\b(click)\b)/, 'g');
        const regexScroll = new RegExp(/(\b(scroll)\b)/, 'g');
        const regexDrag = new RegExp(/(\b(drag)\b)/, 'g');
        const regexPlus = new RegExp(/(\b(Plus)\b)/, 'g');
        const regexMinus = new RegExp(/(\b(Minus)\b)/, 'g');
        const keymapsCommand = valueArr.reduce((acc: string[], item: string): string[] => {
            if (
                !regex.test(item) &&
                !regexClick.test(item) &&
                !regexScroll.test(item) &&
                !regexDrag.test(item)
            ) {
                let newItem = item;
                if (regexPlus.test(item)) {
                    newItem = item.replace(regexPlus, '+');
                } else if (regexMinus.test(item)) {
                    newItem = item.replace(regexMinus, '-');
                }
                acc.push(`<span class="keymap-value">${newItem}</span>`);
            } else if (regexOr.test(item)) {
                acc.push(`<span class="keymap-value-or">${item}</span>`);
            } else {
                acc.push(item);
            }
            return acc;
        }, []);
        return this.sanitizer.bypassSecurityTrustHtml(keymapsCommand.join(' '));
    }
}
