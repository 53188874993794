import {
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { UIDropdownComponent } from '@bannerflow/ui';
import { ActionTrigger, IAction } from '@domain/action';
import { OneOfElementDataNodes } from '@domain/nodes';
import { isElementDescendantOfElement } from '@studio/utils/dom-utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActionTriggers, ITrigger } from '../action';
import { ActionPropertyComponent } from '../action-property/action-property.component';
import { ActionsService } from '../actions.service';

@Component({
    selector: 'action-properties',
    templateUrl: './action-properties.component.html',
    styleUrls: ['./action-properties.component.scss', '../../common.scss']
})
export class ActionPropertiesComponent implements OnChanges, OnDestroy {
    @Input() element: OneOfElementDataNodes;
    @ViewChild('customDropdown') customDropdown: ElementRef<HTMLDivElement>;
    @ViewChild('dropdown') dropdown: UIDropdownComponent;
    actions: IAction[];
    sortedActions: { [key: string]: IAction[] } = {};
    unsubscribe$ = new Subject<void>();

    ActionTrigger = ActionTrigger;
    actionTriggers = ActionTriggers;
    sortedActionKeys: string[] = [];

    dropdownOffset = { x: 0, y: 0 };
    addActionIconWidth = 20;
    newActionId: string | undefined;

    constructor(private actionsService: ActionsService) {
        this.actionsService.change$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => this.filterActions());
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes) {
            this.sortedActions = {};
            this.filterActions();
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    actionName(actionType: string): string {
        const trigger = this.actionTriggers.find(trigger => trigger.value === actionType);
        if (trigger) {
            return trigger.name;
        }
        return actionType;
    }

    private filterActions(): void {
        this.actions = this.element.actions.filter(action => !action.templateId);
        this.sortActions();
    }

    private sortActions(): void {
        if (this.actions) {
            this.actions.forEach(action => {
                const trigger = ActionTriggers.find(t => t.value === action.triggers[0]);
                if (!(trigger!.value in this.sortedActions)) {
                    this.sortedActions[trigger!.value] = [];
                }
                if (!this.hasAction(action, this.sortedActions[trigger!.value])) {
                    this.sortedActions[trigger!.value].push(action);
                }
            });
            this.sortedActionKeys = Object.keys(this.sortedActions);
        }
    }

    private hasAction(action: IAction, container: IAction[]): boolean {
        const result = container.some((item: IAction) => item.id === action.id);
        return result;
    }

    private deleteFromTriggerGroup(actionId: string, triggerGroup: IAction[]): void {
        const index = triggerGroup.findIndex(action => action.id === actionId);
        if (index !== -1) {
            triggerGroup.splice(index, 1);
        }
    }

    addAction(trigger: ITrigger): void {
        this.newActionId = this.actionsService.addAction(undefined, trigger.value);
    }

    onEditAction($event: ActionPropertyComponent): void {
        for (const trigger in this.sortedActions) {
            this.sortedActions[trigger].forEach(action => {
                if (action.id === $event.actionData.id && action.triggers[0] !== trigger) {
                    this.deleteFromTriggerGroup(action.id, this.sortedActions[trigger]);
                }
            });
        }

        this.sortActions();
    }

    onDeleteAction(actionId: string): void {
        for (const i in this.sortedActions) {
            this.deleteFromTriggerGroup(actionId, this.sortedActions[i]);
        }
    }

    placeDropdown(): { x: number; y: number } {
        return (this.dropdownOffset = { x: this.addActionIconWidth + 5, y: 5 });
    }

    triggerButton(event: MouseEvent): void {
        if (isElementDescendantOfElement(this.customDropdown.nativeElement, event.target)) {
            return;
        }
        this.customDropdown.nativeElement.click();
    }
}
