import { isVersionedFeed } from '@creative/elements/feed/feeds.utils';
import {
    isNewlineLikeCharacter,
    isSpaceLikeCharacter,
    isVersionedText,
    isVersionedWidgetText,
    isWordLikeCharacter
} from '@creative/elements/rich-text/utils';
import { ITextSpan, IVersion, IVersionProperty, IVersionedText } from '@domain/creativeset/version';
import { IFeed } from '@domain/feed';
import { SpanType, VARIABLE_PREFIX } from '@domain/text';
import { VersionValidationFailEvent } from '@studio/monitoring/events';
import { isValidUrl } from '@studio/utils/validation';

// For now, this validation errors will be triggered as warnings.
// Rules:
// * Unique names in versions
// * Valid URLs for targetUrls
// * Valid versionProperties (IFeed and VersionedText)
// * Valid IFeed
// * * Valid step
// * * * Valid occurrence (should be 'loop' or 'none'. Default: 'loop')
// * * * Valid start and size (should be number. Default: 1)
// * * Valid ID and path value (should be string. Default: "")
// * * Valid type (should be 'text')
// * * 'visibleStepFeed' should not be there
// * Valid VersionedText:
// * * Empty text means empty styles array
// * * Text length should be covered by styles
// * * The position of a SpanStyle should be the previous span's position+length
// * * SpanType.Word and SpanType.Variable should contains only Word like characters
// * * SpanType.Variable should be the only one to contain 'variable' object
// * * SpanType.Space should contain only space like characters
// * * SpanType.Newline should contain only newline like characters
// * * SpanType should be Word, Space, Variable or Newline
// * * 'visibleStepFeed' should not be there in variable spans
export function validateVersions(versions: IVersion[]): void {
    validateUniqueNames(versions);
    validateTargetURL(versions);

    for (const version of versions) {
        validateVersionProperties(version);
    }
}

function validateUniqueNames(versions: IVersion[]): void {
    const names = new Set<string>();
    for (const version of versions) {
        if (names.has(version.name)) {
            throw new VersionValidationFailEvent('Duplicated version name', {
                versionId: version.id,
                description: `Version name "${version.name}"`
            });
        }
        names.add(version.name);
    }
}

function validateTargetURL(versions: IVersion[]): void {
    for (const version of versions) {
        if (!isValidUrl(version.targetUrl)) {
            throw new VersionValidationFailEvent('Version has invalid target URL', {
                versionId: version.id,
                description: `TargetUrl was: "${version.targetUrl}"`
            });
        }
    }
}

function validateVersionProperties(version: IVersion): void {
    for (const versionProperty of version.properties) {
        try {
            if (isVersionedWidgetText(versionProperty)) {
                continue;
            }
            if (isVersionedText(versionProperty)) {
                validateVersionedText(versionProperty);
                continue;
            }
            if (isVersionedFeed(versionProperty)) {
                validateVersionedFeed(versionProperty);
                continue;
            }
        } catch (e) {
            if (e instanceof Error) {
                throw new VersionValidationFailEvent(e.message, {
                    versionId: version.id,
                    versionPropertyId: versionProperty.id
                });
            }
        }
        throw new VersionValidationFailEvent('Invalid versionProperty type', {
            versionId: version.id,
            versionPropertyId: versionProperty.id
        });
    }
}

function validateVersionedText({ value: { text, styles } }: IVersionProperty<IVersionedText>): void {
    if (text.length === 0 && styles.length !== 0) {
        throw new Error('Styles array should be empty when text is empty');
    }
    if (text.length !== 0 && styles.length === 0) {
        throw new Error('Styles array should not be empty when text is not empty');
    }
    const lastSpan = styles.at(-1);
    if (!lastSpan) {
        return;
    }
    if (lastSpan.length + lastSpan.position !== text.length) {
        throw new Error('Last span position+length should be equal to text length');
    }

    for (let i = 0; i < styles.length; i++) {
        const span = styles[i];
        if (i !== 0) {
            const prevSpan = styles[i - 1];
            if (span.position !== prevSpan.position + prevSpan.length) {
                throw new Error('Span position should be previous span position+length');
            }
        }
        const spanText = text.substring(span.position, span.length + span.position);
        switch (span.type) {
            case SpanType.Word:
                validateWordSpan(spanText, span);
                break;
            case SpanType.Variable:
                validateVariableSpan(spanText, span);
                break;
            case SpanType.Space:
            case SpanType.Newline:
                validateSpaceNewlineSpan(spanText, span);
                break;
            default:
                throw new Error(`Span type is not valid. It was ${span.type}`);
        }
    }
}

function validateWordSpan(spanText: string, span: ITextSpan): void {
    if (!!span.variable) {
        throw new Error('SpanType Word should not have a "variable" object');
    }
    if (!Array.from(spanText).every(character => isWordLikeCharacter(character.charCodeAt(0)))) {
        throw new Error('Every character in a Word span should not be Space or Newline');
    }
}

function validateVariableSpan(spanText: string, span: ITextSpan): void {
    if (!span.variable) {
        throw new Error('SpanType Variable should have a "variable" object');
    }
    if (spanText.charAt(0) !== VARIABLE_PREFIX) {
        throw new Error(`SpanType Variable text should start with ${VARIABLE_PREFIX}`);
    }

    if (!Array.from(spanText).every(character => isWordLikeCharacter(character.charCodeAt(0)))) {
        throw new Error('Every character in a Variable span should not be Space or Newline');
    }

    if (typeof span.variable.id !== 'string') {
        throw new Error('Feed ID must be a string');
    }
    if (typeof span.variable.path !== 'string') {
        throw new Error('Feed path must be a string');
    }

    if (spanText !== `${VARIABLE_PREFIX}${span.variable.path}`) {
        throw new Error(`SpanType Variable text should be "path" prefixed with "${VARIABLE_PREFIX}"`);
    }

    if (span.variable.type !== 'text') {
        throw new Error('Feed type must be a "text"');
    }
    if ('visibleStepFeed' in span.variable) {
        throw new Error('"visibleStepFeed" should not be part of the feed data');
    }
    validateFeedStep(span.variable.step);
}

function validateSpaceNewlineSpan(spanText: string, span: ITextSpan): void {
    if (!!span.variable) {
        throw new Error('SpanType Spance/Newline should not have a "variable" object');
    }
    if (
        !Array.from(spanText).every(
            character =>
                isSpaceLikeCharacter(character.charCodeAt(0)) ||
                isNewlineLikeCharacter(character.charCodeAt(0))
        )
    ) {
        throw new Error('Every character in a Word span should not be Space or Newline');
    }
}

function validateVersionedFeed({ value }: IVersionProperty<IFeed>): void {
    if (typeof value.id !== 'string') {
        throw new Error('Feed ID must be a string');
    }
    if (typeof value.path !== 'string') {
        throw new Error('Feed path must be a string');
    }
    if (value.type !== 'text') {
        throw new Error('Feed type must be a "text"');
    }
    if ('visibleStepFeed' in value) {
        throw new Error('"visibleStepFeed" should not be part of the feed data');
    }
    validateFeedStep(value.step);
}

function validateFeedStep(feedStep: IFeed['step']): void {
    if (feedStep.occurrence !== 'loop' && feedStep.occurrence !== 'none') {
        throw new Error(
            `FeedStep occurrence should be "loop" or "none". It was ${feedStep.occurrence}`
        );
    }
    if (typeof feedStep.size !== 'number') {
        throw new Error('FeedStep size should be a number');
    }
    if (typeof feedStep.start !== 'number') {
        throw new Error('FeedStep start should be a number');
    }
}
