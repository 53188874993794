<!-- Fill & Opacity -->
<studio-ui-section
    id="creative-property-fill"
    headline="Background fill">
    <div class="setting-value col-3 slider">
        <color-button
            data-test-id="color-button"
            [color]="fill"
            (click)="colorService.toggleColorPicker('creativeFill')"></color-button>

        <studio-ui-slider
            [value]="fill.alpha"
            (valueChange)="setFillAlpha($event, ElementChangeType.Skip)"
            (mouseUp)="updateFill(ElementChangeType.Force)"
            [config]="{ min: 0, max: 100 }"></studio-ui-slider>
        <ui-number-input
            unitLabel="%"
            [value]="fill.alpha"
            [min]="0"
            [max]="100"
            [allowEmpty]="false"
            (undo)="undo()"
            (redo)="redo()"
            (valueChange)="setFillAlpha($event, ElementChangeType.Burst)"
            [disableUndo]="true"></ui-number-input>
    </div>

    <div
        class="color-picker"
        *ngIf="'creativeFill' | picker | async"
        data-test-id="color-picker">
        <section-expand
            arrowPosition="33px"
            [showBackground]="true"
            [removeContentWhenCollapsed]="false"
            [expanded]="true">
            <color-section
                name="creativeFill"
                [preventCloseElements]="['#creative-property-fill']"
                [allowGradient]="true"
                [color]="fill"
                (onColorChange)="setFill($event)"
                (onColorChanged)="updateFill(ElementChangeType.Force)"
                (preview)="previewFill($event)"
                (previewStop)="previewStop($event)"></color-section>
        </section-expand>
    </div>
</studio-ui-section>

<!-- Social Guides -->
<studio-ui-section
    *ngIf="isSocialCreative$ | async"
    id="creative-property-social"
    class="no-padding"
    headline="Social guidelines"
    data-test-id="social-guide-section">
    <div class="body">
        <div
            class="setting"
            *ngIf="selectedNetwork$ | async as selectedNetwork">
            <div class="setting-row auto network">
                <div class="setting-label">Network</div>
                <div class="setting-value">
                    <div class="property-input">
                        <ui-select
                            id="interaction-social-guide-network"
                            data-test-id="social-guide-network-selection"
                            [selected]="selectedNetwork"
                            (selectedChange)="updateNetwork($event)"
                            width="100"
                            [useTargetWidth]="true">
                            <ui-option
                                [value]="network"
                                [svgIcon]="network.icon"
                                *ngFor="let network of socialNetworks$ | async">
                                {{ network.text }}
                            </ui-option>
                        </ui-select>
                    </div>
                    <ui-svg-icon
                        icon="question-mark"
                        uiTooltip="The overlays will only help you to design the story formats and will not be a part of your actual creative."
                        uiTooltipMaxWidth="200px"
                        uiTooltipPosition="bottom">
                    </ui-svg-icon>
                </div>
            </div>
            <div
                class="setting-row auto placement"
                *ngIf="selectedNetwork.placements.length !== 1">
                <div class="setting-label">{{ selectedNetwork.placementText }}</div>
                <div class="setting-value">
                    <div class="property-input">
                        <ui-select
                            *ngIf="selectedPlacement$ | async as selectedPlacement"
                            id="interaction-social-guide-placement"
                            class="social-guide-placement"
                            data-test-id="social-guide-placement-selection"
                            [selected]="selectedPlacement"
                            (selectedChange)="updatePlacement($event)"
                            width="120">
                            <ui-option
                                [value]="placement"
                                [selected]="selectedPlacement === placement"
                                *ngFor="let placement of selectedNetwork.placements"
                                >{{ placement.text }}</ui-option
                            >
                        </ui-select>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="body divider">
        <div class="setting">
            <div class="setting-row auto">
                <div class="setting-label">Show overlays</div>
                <div class="setting-value end">
                    <div class="property-input">
                        <ui-toggle-switch
                            id="interaction-social-guide-show-overlay"
                            data-test-id="social-guide-show-overlay"
                            [selected]="(showOverlay$ | async)!"
                            (selectedChange)="showSocialOverlay($event)"></ui-toggle-switch>
                    </div>
                </div>
            </div>
            <div
                class="setting-row auto"
                *ngIf="userSettingsService.guidelineVisible$ | async">
                <div class="setting-label">Show guidelines</div>
                <div class="setting-value end">
                    <div class="property-input">
                        <ui-toggle-switch
                            id="interaction-social-guide-show-guidelines"
                            data-test-id="social-guide-show-guidelines"
                            [selected]="(showGuidelines$ | async)!"
                            (selectedChange)="showSocialGuidelines($event)"></ui-toggle-switch>
                    </div>
                </div>
            </div>
        </div>
    </div>
</studio-ui-section>

<!-- Timeline -->
<studio-ui-section
    *ngIf="animator"
    id="interaction-timeline-properties"
    headline="Timeline">
    <div class="setting-row auto">
        <div class="setting-label">Duration</div>
        <div class="setting-value">
            <div class="property-input duration">
                <ui-number-input
                    [(value)]="duration"
                    [id]="'duration'"
                    (valueChange)="updateDuration()"
                    [allowEmpty]="false"
                    [step]="0.1"
                    [min]="minTimelineDuration"
                    [max]="maxTimelineDuration"
                    format="1.2-2"
                    (undo)="undo()"
                    (redo)="redo()"
                    [disabled]="creativeDocument.elements.length === 0"
                    [disableUndo]="true"
                    (submit)="updateDuration()"
                    unitLabel="s"></ui-number-input>
            </div>
        </div>
    </div>
    <div class="setting-row auto">
        <div class="setting-label">Loops</div>
        <div class="setting-value">
            <div class="property-input stop">
                <ui-select
                    id="interaction-loops-setting"
                    [(selected)]="loops"
                    (selectedChange)="updateLoops()"
                    width="100"
                    [useTargetWidth]="true">
                    <ui-option [value]="0">Play forever</ui-option>
                    <ui-option [value]="1">1</ui-option>
                    <ui-option [value]="2">2</ui-option>
                    <ui-option [value]="3">3</ui-option>
                    <ui-option [value]="4">4</ui-option>
                    <ui-option [value]="5">5</ui-option>
                </ui-select>
            </div>
        </div>
    </div>
    <div
        class="setting-row auto"
        *ngIf="loops > 0">
        <div class="setting-label">Stop at time</div>
        <div class="setting-value">
            <div class="property-input time">
                <ui-number-input
                    [(value)]="stopTime"
                    [id]="'stop-time'"
                    (valueChange)="updateStopTime(true)"
                    [allowEmpty]="false"
                    [max]="duration"
                    (undo)="undo()"
                    (redo)="redo()"
                    [disableUndo]="true"
                    format="1.2-2"
                    (submit)="updateStopTime()"
                    [step]="0.1"
                    [min]="0"
                    unitLabel="s"></ui-number-input>
            </div>
        </div>
    </div>
    <div class="setting-row auto wide-text">
        <div
            class="setting-label"
            uiTooltip="The time the creative will start from on the first loop">
            1st loop starts at
        </div>
        <div class="setting-value">
            <div class="property-input time">
                <ui-number-input
                    [(value)]="startTime"
                    [id]="'start-time'"
                    (valueChange)="updateStartTime(true)"
                    [allowEmpty]="false"
                    [max]="duration"
                    (undo)="undo()"
                    (redo)="redo()"
                    [disableUndo]="true"
                    format="1.2-2"
                    (submit)="updateStartTime()"
                    [step]="0.1"
                    [min]="0"
                    unitLabel="s"></ui-number-input>
            </div>
        </div>
    </div>
    <div class="setting-row auto">
        <div class="setting-label">Show GIF frames</div>
        <div class="setting-value">
            <div class="property-input stop">
                <ui-toggle-switch
                    id="interaction-gif-frames-setting"
                    [selected]="gifExport.show"
                    (selectedChange)="showManualGifFrames($event)"></ui-toggle-switch>
            </div>
        </div>
    </div>
</studio-ui-section>

<!-- Preload image -->
<studio-ui-section
    *ngIf="preloadImage"
    headline="Preload image">
    <div class="setting-row auto">
        <div class="setting-label">Capture at time</div>
        <div class="setting-value">
            <div class="property-input duration">
                <ui-number-input
                    [(value)]="preloadImage.frames[0]"
                    [id]="'preload-image'"
                    (valueChange)="updatePreloadImage()"
                    [allowEmpty]="false"
                    [step]="0.1"
                    [min]="0"
                    [max]="duration"
                    format="1.2-2"
                    (undo)="undo()"
                    (redo)="redo()"
                    (submit)="updatePreloadImage()"
                    unitLabel="s"></ui-number-input>
            </div>
        </div>
    </div>
</studio-ui-section>
