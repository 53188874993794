type EventType =
    | 'focus'
    | 'blur'
    | 'visibilitychange'
    | 'freeze'
    | 'resume'
    | 'pageshow'
    | 'pagehide'
    | 'beforeunload';

const EVENTS: EventType[] = [
    'focus',
    'blur',
    'visibilitychange',
    'freeze',
    'resume',
    'pageshow',
    'pagehide',
    'beforeunload' // Fallback for safety in case any of the above doesn't fire
];

export type PageVisibilityState = 'active' | 'passive' | 'hidden';

type VisibilityChangeCallback = (event: PageVisibilityState) => void;

class VisibilityChange {
    private _callbacks_m: VisibilityChangeCallback[] = [];
    private _currentState_m: PageVisibilityState;

    constructor() {
        this._registerListeners_m();
    }

    onChange(callback: VisibilityChangeCallback): void {
        this._callbacks_m.push(callback);
    }

    private _registerListeners_m(): void {
        EVENTS.forEach(type => {
            const context = this.getContext(type);
            context.addEventListener(type, this._dispatchToCallbacks_m, { capture: true });
        });
    }

    private getContext(type: EventType): (Window & typeof globalThis) | Document {
        let context: (Window & typeof globalThis) | Document = window;
        // https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilitychange_event#usage_notes
        if (type === 'visibilitychange') {
            context = document;
        }
        return context;
    }

    private _getState_m(event?: Event): PageVisibilityState {
        if (event?.type === 'beforeunload' || document.visibilityState === 'hidden') {
            return 'hidden';
        }
        if (document.hasFocus()) {
            return 'active';
        }
        return 'passive';
    }

    private _dispatchToCallbacks_m = (event?: Event): void => {
        const newState = this._getState_m(event);
        if (this._currentState_m === newState) {
            return;
        }
        for (let i = 0; i < this._callbacks_m.length; i++) {
            this._callbacks_m[i](newState);
        }
    };

    removeListener_m(callback: VisibilityChangeCallback): void {
        for (let i = 0; i < this._callbacks_m.length; i++) {
            if (this._callbacks_m[i] === callback) {
                this._callbacks_m.splice(i, 1);
                break;
            }
        }
    }

    destroy_m(): void {
        this._callbacks_m = [];

        EVENTS.forEach(type => {
            const context = this.getContext(type);
            context.removeEventListener(type, this._dispatchToCallbacks_m, { capture: true });
        });
    }
}

export const visibilityChange = new VisibilityChange();
