<div
    class="field-info"
    #fieldName>
    <span class="name">&#64;{{ item.name }}</span>
</div>
<div
    class="values"
    [class.images]="this.isValidMediaUrl">
    <div
        class="value-wrapper"
        *ngFor="let itemValue of item.values | slice: 0 : maximumItems; let i = index">
        <div
            class="value"
            [class.image]="this.isValidMediaUrl"
            [style.left]="i * 30 + 'px'"
            [style.zIndex]="item.values.length - i">
            <ng-container *ngIf="!this.isValidMediaUrl">{{ itemValue }}</ng-container>
            <ng-container *ngIf="isImage">
                <div class="background"></div>
                <div
                    class="img"
                    [style.backgroundImage]="'url(' + itemValue + ')'"></div>
            </ng-container>
            <ng-container *ngIf="isVideo">
                <ui-loader *ngIf="!videosData.length"></ui-loader>
                <ng-container *ngIf="videosData.length">
                    <div class="background"></div>
                    <ng-container *ngIf="videosData[i]">
                        <div
                            class="img"
                            [style.backgroundImage]="'url(' + videosData[i].thumbnail + ')'"></div>
                        <div class="video-duration">
                            {{ videosData[i].duration * 1000 | date: 'mm:ss' }}
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
        <div
            class="value more"
            *ngIf="i + 1 === maximumItems && item.values.length > maximumItems && isImage && !inDialog"
            [style.left]="i * 30 + 90 + 'px'">
            ...
        </div>
    </div>
</div>
<div
    class="value more"
    *ngIf="item.values.length > maximumItems && !isValidMediaUrl && !inDialog">
    ...
</div>
