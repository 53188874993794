import { IConstraintV2, IDistance } from '@domain/dimension';
import {
    coversHorizontally,
    coversVertically,
    distance2D,
    getCenter,
    intersectionFactor
} from '@studio/utils/geom';
import { isTextNode } from '../../nodes/helpers';
import { GroupOrLayoutElement, ILayoutRule, isLayoutElement } from '../rules';

/**
 * Rule to align ratio locked items more expected.
 * hould be run like on of the last rules to be as productive as possible
 */
export class AlignRatioConstrainedRule implements ILayoutRule {
    applyAutoContraintRule(layoutElement: GroupOrLayoutElement): void {
        // if (isLayoutElement(layoutElement)) {
        const { constraint, rotatedBoundingBox, originalCreative } = layoutElement;
        const { ratio } = constraint;
        const element = isLayoutElement(layoutElement) ? layoutElement.element : undefined;

        // Only applies to elements with a ratio (think ratio lock)
        if (ratio && (ratio.min || ratio.max || ratio.value)) {
            const elementCenter = getCenter(rotatedBoundingBox);
            const canvasCenter = getCenter(originalCreative);
            // How much of the element is on canvas, 1 = 100%
            const canvasIntersectionRatio = intersectionFactor(rotatedBoundingBox, originalCreative);
            const distance = distance2D(canvasCenter, elementCenter);

            const coverH = coversHorizontally(rotatedBoundingBox, originalCreative);
            const coverV = coversVertically(rotatedBoundingBox, originalCreative);

            // Calculate if element should cover or contain  the bounding box
            if (!ratio.fitting) {
                ratio.fitting =
                    coverH || coverV || (canvasIntersectionRatio < 0.95 && !isTextNode(element))
                        ? 'cover'
                        : 'contain';
            }

            // Get an align baset on the constraint
            let alignH = this.constraintToHorizontalAspectRatioAlign(constraint);
            let alignV = this.constraintToVerticalAspectRatioAlign(constraint);

            // Element is a bit out of canvas
            if (canvasIntersectionRatio < 1) {
                // When the complete area is filled by element, make sure all edge constraints are set
                if (coverH && coverV && ratio.fitting === 'cover') {
                    const zeroDistance: IDistance = { unit: 'px', value: 0 };
                    constraint.left = constraint.left || zeroDistance;
                    constraint.right = constraint.right || zeroDistance;
                    constraint.top = constraint.top || zeroDistance;
                    constraint.bottom = constraint.bottom || zeroDistance;
                }

                if (!alignH) {
                    if (Math.abs(distance.x) <= 1) {
                        alignH = 'center';
                    } else if (distance.x > 0) {
                        alignH = 'left';
                    } else {
                        alignH = 'right';
                    }
                }
                if (!alignV) {
                    if (Math.abs(distance.y) <= 1) {
                        alignV = 'center';
                    } else if (distance.y > 0) {
                        alignV = 'top';
                    } else {
                        alignV = 'bottom';
                    }
                }
            }

            ratio.horizontalAlign = alignH || 'center';
            ratio.verticalAlign = alignV || 'center';
        }
    }

    private constraintToHorizontalAspectRatioAlign(
        constraint: IConstraintV2
    ): 'left' | 'center' | 'right' | undefined {
        const contain = (constraint.ratio && constraint.ratio.fitting === 'contain') || true;

        if (constraint.right && !constraint.left) {
            return contain ? 'right' : 'left';
        } else if (constraint.left && !constraint.right) {
            return contain ? 'left' : 'right';
        }
        // return 'center';
    }

    private constraintToVerticalAspectRatioAlign(
        constraint: IConstraintV2
    ): 'top' | 'center' | 'bottom' | undefined {
        const contain = (constraint.ratio && constraint.ratio.fitting === 'contain') || true;

        if (constraint.bottom && !constraint.top) {
            return contain ? 'bottom' : 'top';
        } else if (constraint.top && !constraint.bottom) {
            return contain ? 'top' : 'bottom';
        }
        // return 'center';
    }
}
