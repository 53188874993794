/**
 * A StudioEvent can be anything that needs to be processed for a specific reason - logging, analytics, error reporting...
 * When logging such an event, the combination of properties tells the event logger what to do with it.
 *
 * Console:
 * To log the event's description to the console, provide log=true and the optional log level. Defaults to `log`.
 * {
 *     log = true;
 *     level = ConsoleEventLevel.Log;
 * }
 *
 * Activity:
 * To add the current event description as a user activity, set activity=true
 * {
 *     activity = true;
 * }
 *
 * Capture:
 * To capture the message on the error reporting service, set capture=true and provide the severity.
 * {
 *     capture = true;
 *     severity = CaptureEventSeverity.Error;
 *     data = { originalError: new Error() } // additional data can be provided here
 * }
 *
 * Tracking:
 * For a single tracked event, just set `track` to true. Uses the message as event name.
 * {
 *     track = true;
 * }
 * If the duration also needs to be tracked, set `track` to true and provide the start property. Start=true will define the starting point, start=false defines the end point
 * {
 *     track = true;
 *     start = true; // will tell the tracker to trigger a startTracking event
 * }
 * Additional data can also be appended by setting the data:any property.
 * {
 *     track = true;
 *     data = {
 *         addedSizes = 3
 *     }
 * }
 */
export interface IStudioEvent {
    message: string;
    description: string;
}

export interface StudioConsoleEvent extends IStudioEvent {
    log: boolean;
    level: ConsoleEventLevel;
}

export interface StudioTrackerEvent extends IStudioEvent {
    track: boolean;
    start?: boolean; // if provided, will trigger a start/finishTracking event
    data?: any;
}

export interface StudioCaptureEvent extends IStudioEvent {
    capture: boolean;
    severity: CaptureEventSeverity;
    data?: any;
}

export interface StudioActivityEvent extends IStudioEvent {
    activity: boolean;
    data?: any;
}

export interface StudioUniqueEvent extends IStudioEvent {
    unique: boolean;
    uniqueIdentifier?: () => string; // If not defined, it'll log this types of events only once
}

export type StudioEvent = Partial<StudioConsoleEvent> &
    Partial<StudioTrackerEvent> &
    Partial<StudioCaptureEvent> &
    Partial<StudioActivityEvent> &
    Partial<StudioUniqueEvent>;

export enum ConsoleEventLevel {
    Log = 'log',
    Verbose = 'verbose',
    Debug = 'debug',
    Warning = 'warn',
    Error = 'error'
}

export enum CaptureEventSeverity {
    Fatal = 'fatal',
    Error = 'error',
    Warning = 'warning',
    Log = 'log',
    Info = 'info',
    Debug = 'debug',
    Critical = 'critical'
}
