@if (inputs$ | async; as inputs) {
    @for (inputData of inputs; track inputData.renderId) {
        <div class="wrapper">
            <group-input-header
                (toggleExpand)="onToggleExpand(inputData, $event)"
                [groupLength]="inputData.versionProperties.length"
                [expanded]="false"></group-input-header>
            @if (inputData.expanded) {
                @for (
                    versionProperty of inputData.versionProperties;
                    track versionProperty.id;
                    let index = $index
                ) {
                    <ng-container
                        *ngTemplateOutlet="
                            inputTemplate;
                            context: {
                                label: inputData.labels[index] ?? inputData.labels[0],
                                element: inputData.elements[index],
                                inputData: inputData,
                                index: index
                            }
                        "></ng-container>
                }
            } @else {
                <ng-container
                    *ngTemplateOutlet="
                        inputTemplate;
                        context: {
                            label: inputData.labels.join(', '),
                            inputData: inputData,
                            element: undefined,
                            index: undefined
                        }
                    "></ng-container>
            }
        </div>
    }
}

<ng-template
    #inputTemplate
    let-element="element"
    let-label="label"
    let-inputData="inputData"
    let-index="index">
    <div class="header">
        <ui-svg-icon [icon]="'feed'"></ui-svg-icon>
        <span>
            {{ label }}
        </span>
    </div>
    <div class="input">
        <ng-template #leadingIcon>
            <ui-svg-icon
                leadingIcon
                (click)="onFeedSourceClicked(inputData, index)"
                [icon]="'feed'"></ui-svg-icon>
        </ng-template>
        <ng-template #trailingIcon>
            <ui-svg-icon
                trailingIcon
                (click)="onFeedSourceClicked(inputData, index)"
                [icon]="'edit'"></ui-svg-icon>
        </ng-template>
        <mat-input
            [value]="inputData.feedSource | feedSourceName"
            [type]="'presentational'"
            [label]="inputData.version | versionToLabel: element?.name"
            [trailingIcon]="trailingIcon"
            [leadingIcon]="leadingIcon"
            (inputMouseOver)="focusElement(element?.id, inputData.version.id)"
            (inputMouseOut)="blurElement(element?.id)"
            (inputClick)="onFeedSourceClicked(inputData, index)"></mat-input>
    </div>
</ng-template>
