<mat-chip-set #chipSet>
    @for (pill of pills; track pill.feed.id; let index = $index) {
        <mat-chip
            color="primary"
            [disableRipple]="true"
            [highlighted]="highlightedFeed() === pill.feed"
            (click)="onPillClicked(pill, $event, index)"
            (mouseleave)="onPillUnhovered(pill)"
            (mouseenter)="onPillHovered(pill)">
            {{ '@' + pill.feed.path }}
        </mat-chip>
    }
</mat-chip-set>
