<div class="wrapper">
    <div
        class="undo-wrapper"
        [ngClass]="{ disabled: !canUndo() }"
        (click)="onUndo()">
        <ui-svg-icon icon="undo-arrow" />
        <span>Undo</span>
    </div>
    <div
        class="redo-wrapper"
        [ngClass]="{ disabled: !canRedo() }"
        (click)="onRedo()">
        <ui-svg-icon icon="undo-arrow" />
        <span>Redo</span>
    </div>
</div>
