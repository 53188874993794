import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { creativeSetActions } from '../creativeSet/creativeSet.actions';
import { map } from 'rxjs';
import { init } from './user-settings.actions';

@Injectable()
export class UserSettingsEffects {
    private action$ = inject(Actions);

    init$ = createEffect(() => {
        return this.action$.pipe(
            ofType(creativeSetActions.loaded),
            map(({ creativeSetId }) => {
                return init({ creativeSetId });
            })
        );
    });
}
