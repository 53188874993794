import { gql } from 'apollo-angular';

export const GET_SHOWCASES_OF_CREATIVESET = gql`
    query GetCreativeShowcases($creativesetId: String!) {
        showcasesByCreativesetId(creativesetId: $creativesetId) {
            showcases {
                showcaseKey
                creativesetId
                allowedOperations
                invitations {
                    id
                    kind
                }
                showcaseLink
                sizeIds
                versionIds
                disabled
                isTemporary
            }
            translators {
                id
                email
                name
                localizations {
                    id
                    name
                    cultureCode
                    cultureName
                }
            }
            users {
                id
                email
                lastName
                firstName
            }
        }
    }
`;

export const CREATE_CREATIVESET_SHOWCASE = gql`
    mutation CreateCreativesetShowcase($creativesetShowcase: CreativesetShowcaseInputType!) {
        createCreativesetShowcase(creativesetShowcase: $creativesetShowcase) {
            showcaseKey
            creativesetId
            allowedOperations
            showcaseLink
            sizeIds
            versionIds
            disabled
            isTemporary
            invitationMessage
            invitations {
                id
                kind
                email
            }
        }
    }
`;

export const UPDATE_CREATIVESET_SHOWCASE = gql`
    mutation UpdateCreativesetShowcase($creativesetShowcase: CreativesetShowcaseInputType!) {
        updateCreativesetShowcase(creativesetShowcase: $creativesetShowcase) {
            showcaseKey
            creativesetId
            allowedOperations
            showcaseLink
            sizeIds
            versionIds
            disabled
            invitationMessage
            invitations {
                id
                kind
                email
            }
        }
    }
`;

export const DELETE_CREATIVESET_SHOWCASE = gql`
    mutation DeleteShowcaseMutation($showcaseKey: String!) {
        deleteCreativesetShowcase(showcaseKey: $showcaseKey)
    }
`;

export const COMPLETE_CREATIVESET_SHOWCASE_TRANSLATION = gql`
    mutation UpdateCreativesetShowcaseMutation($creativesetShowcase: CreativesetShowcaseInputType!) {
        updateCreativesetShowcase(creativesetShowcase: $creativesetShowcase) {
            showcaseKey
            creativesetId
            allowedOperations
            sizeIds
            versionIds
            disabled
        }
    }
`;
