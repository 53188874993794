import { GuidelineType, IGuideline } from '@domain/workspace';

export interface IGuidelineTooltipEvent {
    show: boolean;
    type: GuidelineType;
    trigger: GuidelineTrigger;
}

export interface IGuidelineSelection {
    instance?: IGuideline;
    hasMoved: boolean;
    isAdding: boolean;
}

export enum GuidelineTrigger {
    TOP,
    LEFT,
    RIGHT
}

export enum GuidelineColor {
    Black = 'rgba(0, 0, 0, 0.5)',
    Green = 'rgba(0, 128, 0, 0.5)',
    Magenta = 'rgba(229, 0, 125, 0.5)',
    Purple = 'rgba(128, 0, 128, 0.5)',
    White = 'rgba(255, 255, 255, 0.5)'
}
