import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit
} from '@angular/core';
import { EditCreativeService } from '../../services/edit-creative.service';
import { filterCreatives } from '../../utils/filter-creatives.utils';
import { CreativesetDataService } from '../../../../shared/creativeset/creativeset.data.service';
import { FiltersService } from '../../../../shared/filters/state/filters.service';
import { VersionsService } from '../../../../shared/versions/state/versions.service';
import { ApprovalStatus } from '@domain/creativeset/creative';
import { IVersion } from '@domain/creativeset/version';
import { Subject, merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { UIModule } from '@bannerflow/ui';

const initialStatusCounters = {
    [ApprovalStatus.None]: 0,
    [ApprovalStatus.NoStatus]: 0,
    [ApprovalStatus.NotApproved]: 0,
    [ApprovalStatus.Approved]: 0,
    [ApprovalStatus.ForReview]: 0,
    [ApprovalStatus.InProgress]: 0
};

@Component({
    standalone: true,
    imports: [CommonModule, UIModule],
    selector: 'filter-list-status',
    templateUrl: './filter-list-status.component.html',
    styleUrls: ['./filter-list-status.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterListStatusComponent implements OnInit, OnDestroy {
    ApprovalStatus = ApprovalStatus;

    collapsed = false;

    statusCounters = { ...initialStatusCounters };

    private selectedVersions: IVersion[] = [];
    private statusesFilter = new Set<ApprovalStatus>();
    private sizesFilter: string[] = [];
    private unsubscribe$ = new Subject<void>();

    constructor(
        private creativesetDataService: CreativesetDataService,
        private changeDetectorRef: ChangeDetectorRef,
        private editCreativeService: EditCreativeService,
        private filtersService: FiltersService,
        private versionsService: VersionsService
    ) {}

    ngOnInit(): void {
        this.versionsService.selectedVersions$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(selectedVersions => {
                this.selectedVersions = selectedVersions;
            });

        this.filtersService.filtersState$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(({ statuses, sizes }) => {
                this.sizesFilter = sizes;
                this.statusesFilter = new Set(statuses);
                this.updateStatus();
                this.validateFilterState();
            });

        merge(this.editCreativeService.updateView$, this.editCreativeService.statusUpdated$)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.updateStatus();
                this.validateFilterState();
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    updateStatus(): void {
        this.setStatusCounters();
        this.changeDetectorRef.detectChanges();
    }

    collapse(): void {
        this.collapsed = !this.collapsed;
    }

    clearStatus(): void {
        this.filtersService.clearAllStatuses();
    }

    isActive(status: ApprovalStatus): boolean {
        return this.statusesFilter.has(status);
    }

    filterStatus(status: ApprovalStatus): void {
        if (this.statusesFilter.has(status)) {
            const futureActiveStatusFilter = [
                ...Array.from(this.statusesFilter).filter(statusFilter => statusFilter !== status)
            ];

            if (this.canApplyApprovalStatusFilter(futureActiveStatusFilter)) {
                this.filtersService.removeFilteredStatuses([status]);
            }
            return;
        }

        if (this.canApplyApprovalStatusFilter([...this.statusesFilter, status])) {
            this.filtersService.addFilteredStatuses([status]);
        }
    }

    private setStatusCounters(): void {
        this.statusCounters = { ...initialStatusCounters };

        for (const creative of this.creativesetDataService.creativeset.creatives) {
            this.statusCounters[creative.approvalStatus || ApprovalStatus.NoStatus]++;
        }
    }

    private canApplyApprovalStatusFilter(newApprovalStatusFilter: ApprovalStatus[]): boolean {
        const creatives = this.creativesetDataService.getCreativesFromVersionsOrAll(
            this.selectedVersions
        );
        const filteredCreatives = filterCreatives(creatives, this.sizesFilter, newApprovalStatusFilter);
        return !!filteredCreatives.length;
    }

    private validateFilterState(): void {
        let isInvalid = this.statusesFilter.size > 0;
        for (const status of this.statusesFilter) {
            if (this.statusCounters[status] > 0) {
                isInvalid = false;
                break;
            }
        }

        if (isInvalid) {
            this.filtersService.clearAllStatuses();
        }
    }
}
