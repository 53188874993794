import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { isVersionedText, isVersionedWidgetText } from '@creative/elements/rich-text/utils';
import {
    IVersionedElementPropertyValuesByElement,
    IVersionedText,
    IVersionProperty,
    IWidgetText
} from '@domain/creativeset/version';
import { StudioUISectionComponent } from '../../../../shared/components/section/studio-ui-section/studio-ui-section.component';
import { GroupHeadlinePipe } from '../../../../shared/pipes/grouping/group-headline.pipe';
import { GroupWidgetsPipe } from '../../../../shared/pipes/grouping/group-widgets.pipe';
import { SortGroupPipe } from '../../../../shared/pipes/grouping/sort-group.pipe';
import { TranslationPanelService } from '../translation-panel.service';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        UIModule,
        GroupWidgetsPipe,
        SortGroupPipe,
        GroupHeadlinePipe,
        StudioUISectionComponent
    ],
    selector: 'mv-widget-properties',
    templateUrl: './mv-widget-properties.component.html',
    styleUrls: ['./mv-widget-properties.component.scss']
})
export class MvWidgetPropertiesComponent {
    private translationPanelService = inject(TranslationPanelService);

    @Input() elements: IVersionedElementPropertyValuesByElement<IVersionedText | IWidgetText>[];

    isWidgetTextProperty(
        versionProperty: IVersionProperty
    ): versionProperty is IVersionProperty<IVersionedText | IWidgetText> {
        return isVersionedText(versionProperty) || isVersionedWidgetText(versionProperty);
    }

    onTextChange(
        text: string,
        property: IVersionProperty,
        elements: IVersionedElementPropertyValuesByElement[]
    ): void {
        // this is needed due to ui-textarea emits event on ngafterviewinit
        if (this.isWidgetTextProperty(property) && property.value.text !== text) {
            property.value.text = text;

            this.translationPanelService.onWidgetChanged(property, elements);
        }
    }
}
