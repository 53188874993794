import { effect, inject, Injectable, OnDestroy } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { Logger } from '@bannerflow/sentinel-logger';
import { UINotificationService, UINotificationType } from '@bannerflow/ui';
import { tryGetFontByStyleId, tryGetFontStyleById } from '@creative/font-families.utils';
import { isTextDataElement } from '@creative/nodes';
import { CreativeSize } from '@domain/creativeset';
import { ICreative } from '@domain/creativeset/creative/creative';
import { ITextDataNode } from '@domain/nodes';
import { LayerItem, PSDElementType, PSDErrorType } from '@studio/domain/components/psd-import/psd';
import { FontFamiliesService } from '@studio/stores/font-families';
import { BehaviorSubject, map } from 'rxjs';
import { CreativesetDataService } from '../../../../shared/creativeset/creativeset.data.service';
import { EditCreativeService } from '../../services/edit-creative.service';
import { SizeAddService } from '../../size-add-dialog/size-add.service';
import { CreativeConverterUploadService } from '../creative-converter-upload.service';
import { CreativeConverterService } from '../creative-converter.service';
import { CreativeConverterStateService } from '../state/creative-converter.service';
import { isPSDGroupElement } from './psd-reader';

@Injectable()
export class PsdImportService implements OnDestroy {
    private creativeConverterService = inject(CreativeConverterService);
    private creativeConverterStateService = inject(CreativeConverterStateService);
    private creativeConverterUploadService = inject(CreativeConverterUploadService);
    private creativesetDataService = inject(CreativesetDataService);
    private editCreativeService = inject(EditCreativeService);
    private fontFamiliesService = inject(FontFamiliesService);
    private sizeAddService = inject(SizeAddService);
    private uiNotificationService = inject(UINotificationService);

    private _creative$ = new BehaviorSubject<ICreative | undefined>(undefined);
    private _layers$ = new BehaviorSubject<LayerItem[]>([]);
    private _isSaving$ = new BehaviorSubject(false);

    creative$ = this._creative$.asObservable();
    isSaving$ = this._isSaving$.asObservable();
    selectedPsdLayers$ = this.creativeConverterStateService.psdLayers$.pipe(
        map(layers =>
            layers.filter(layer => layer.selected && !layer.error?.isCritical && layer.type !== 'group')
        )
    );

    private logger = new Logger('PsdImportService');

    private psdLayers = toSignal(this.creativeConverterStateService.psdLayers$, { initialValue: [] });
    private creative = toSignal(this.creative$);
    private fontFamilies = toSignal(this.fontFamiliesService.fontFamilies$, { initialValue: [] });

    constructor() {
        // TODO: Move this to an effect
        this._layers$
            .pipe(takeUntilDestroyed())
            .subscribe(layers => this.creativeConverterStateService.setLayers(layers));
        this.creativeConverterService.convertedCreative$
            .pipe(takeUntilDestroyed())
            .subscribe(({ creative, blueprint }) => {
                this._creative$.next(creative);
                this._layers$.next(
                    blueprint.data.flatChildren.map(layer => ({
                        ...layer,
                        selected: !layer.hidden,
                        collapsed: false
                    }))
                );
            });

        this.creativeConverterUploadService.assetUploadComplete$
            .pipe(takeUntilDestroyed())
            .subscribe(design => {
                this.logger.verbose('Asset upload complete');

                if (this.creativeConverterUploadService.imageUploadFailed()) {
                    const failedImages = this.creativeConverterUploadService.getFailedImages();

                    failedImages.forEach(nodeId => {
                        design.elements = design.elements.filter(element => element.id !== nodeId);
                        design.document.removeNodeById_m(nodeId);
                    });
                }

                if (this._creative$.value) {
                    this._creative$.next({ ...this._creative$.value, design });
                }
                this.storeCreative();
            });

        effect(
            () => {
                const layers = this.psdLayers();
                if (!layers.length) {
                    return;
                }
                const creative = this.creative();
                const document = creative?.design?.document;
                if (!document) {
                    return;
                }
                let changed = false;
                for (const layer of layers) {
                    for (const node of document.nodeIterator_m()) {
                        if (node.id === layer.id) {
                            node.hidden = layer.hidden;
                            if (isTextDataElement(node)) {
                                changed ||= this.updateTextProperties(node, layer);
                            }
                            break;
                        }
                    }
                }
                if (changed) {
                    this._creative$.next(creative);
                }
            },
            { allowSignalWrites: true }
        );
    }

    private updateTextProperties(node: ITextDataNode, layer: LayerItem): boolean {
        if (
            layer.type !== PSDElementType.Text ||
            layer.error?.type !== PSDErrorType.OriginalFontNotFound
        ) {
            return false;
        }

        if (node.font?.id !== layer.data.font && layer.data.font) {
            const fontStyleId = layer.data.font;
            const newFontFamily = tryGetFontByStyleId(this.fontFamilies(), fontStyleId);
            const newFontStyle = tryGetFontStyleById(this.fontFamilies(), fontStyleId);
            if (newFontFamily && newFontStyle) {
                node.font = {
                    id: fontStyleId,
                    style: newFontStyle.italic ? 'italic' : 'normal',
                    fontFamilyId: newFontFamily.id,
                    weight: newFontStyle.weight,
                    src: newFontStyle.fontUrl
                };
                return true;
            }
        }
        return false;
    }

    ngOnDestroy(): void {
        this.creativeConverterUploadService.uploadSubscription.unsubscribe();
    }

    getChildLayers(groupLayer: LayerItem): LayerItem[] {
        const children: LayerItem[] = [];

        const traverseChildren = (layer: LayerItem): void => {
            if (isPSDGroupElement(layer) && layer.data.children) {
                layer.data.children.forEach(child => {
                    const childLayer = this._layers$.value.find(l => l.id === child.id);
                    if (childLayer) {
                        children.push(childLayer);
                        traverseChildren(childLayer);
                    }
                });
            }
        };

        traverseChildren(groupLayer);

        return children;
    }

    resetPsd(): void {
        this._layers$.next([]);
        this._creative$.next(undefined);
    }

    async saveCreative(): Promise<void> {
        this._isSaving$.next(true);
        this.cleanDesign();
        const design = this._creative$.value?.design;
        if (!design) {
            return;
        }

        if (this.creativeConverterUploadService.hasAssetsToUpload(design)) {
            // upload all assets and replace base64 with URLs
            await this.creativeConverterUploadService.uploadCreativeAssets(design);
        } else {
            // if nothing needs to be uploaded, save right away
            await this.storeCreative();
        }
    }

    private async storeCreative(): Promise<void> {
        await this.createSize();
        await this.createDesign();
        this.showNotification('Creative saved successfully', 'info');
        this._isSaving$.next(false);
    }

    private async createSize(): Promise<void> {
        try {
            const creative = this._creative$.value;
            if (!creative) {
                return;
            }
            this.logger.verbose('Creating new size');
            const newSizes = await this.addCreativeSize();
            // find the created size again. Should be done via Ids but right now we can only track sizes by unique names
            const size = newSizes.find(newSize => newSize.name === creative.size.name);
            if (!size) {
                throw new Error('Size was not created or found');
            }
            // set the newly created size on the psd creative
            this._creative$.next({ ...creative, size });
        } catch {
            this.showNotification(
                `There was an error when creating the creative size. Please try again. If the problem persists, please contact our support team for assistance. We apologize for any inconvenience.`,
                'error'
            );
        }
    }

    private async createDesign(): Promise<void> {
        const creative = this._creative$.value;
        if (!creative) {
            return;
        }
        try {
            this.logger.verbose('Activating new creative design');
            await this.editCreativeService.saveNewCreative(creative);
        } catch {
            this.showNotification(
                `There was an error when creating the creative design. Please try again. If the problem persists, please contact our support team for assistance. We apologize for any inconvenience.`,
                'error'
            );
        }
    }
    private async addCreativeSize(): Promise<CreativeSize[]> {
        const creative = this._creative$.value;
        if (!creative) {
            return [];
        }

        return this.sizeAddService.addSizes(this.creativesetDataService.creativeset.id, [
            creative.size
        ]);
    }

    private cleanDesign(): void {
        const creative = this._creative$.value;
        if (!creative) {
            return;
        }
        const design = creative.design;
        if (!design) {
            return;
        }
        const document = design.document;

        // remove not selected elements from design
        for (const layer of this._layers$.value) {
            if (!layer.selected) {
                document.removeNodeById_m(layer.id);
                design.elements = design.elements.filter(element => element.id !== layer.id);
            }
        }

        // remove empty groups
        document.clearEmptyChildren();
    }

    private showNotification(text: string, type: UINotificationType): void {
        this.uiNotificationService.open(text, {
            type,
            placement: 'top',
            autoCloseDelay: 5000
        });
    }
}
