import { IBounds } from '@domain/dimension';
import { ElementKind } from '@domain/elements';
import { GroupOrLayoutElement, ILayoutElement, ILayoutRule, isLayoutElement } from '../rules';

/**
 * Only allow ratio to change a certain percent
 */
const CIRCLE_DEFINITION_THICKNESS = 2;

export class PreserveCircleRule implements ILayoutRule {
    applyAutoContraintRule(groupOrLayout: GroupOrLayoutElement): void {
        if (isLayoutElement(groupOrLayout)) {
            const { constraint } = groupOrLayout;

            // Witdth and height might differ even when holding shift due to rounding
            if (this.isCircularEllipse(groupOrLayout) && !constraint.ratio) {
                constraint.ratio = {
                    value: 1
                };
            }
        } else {
            const { rotatedBoundingBox, constraint } = groupOrLayout;

            if (this.isSquare(rotatedBoundingBox) && !constraint.ratio) {
                const hasSquareEllipse = groupOrLayout.layoutElements.some(layoutElement =>
                    this.isCircularEllipse(layoutElement)
                );
                if (hasSquareEllipse) {
                    constraint.ratio = {
                        value: 1
                    };
                }
            }
        }
    }

    private isCircularEllipse(layoutElement: ILayoutElement): boolean {
        return (
            layoutElement.element.kind === ElementKind.Ellipse && this.isSquare(layoutElement.element)
        );
    }

    private isSquare(box: IBounds): boolean {
        return Math.abs(box.width - box.height) < CIRCLE_DEFINITION_THICKNESS;
    }
}
