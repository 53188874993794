import { IStorageService } from './storage.model';

/* Simplified version of
 https://github.com/dscheerens/ngx-webstorage-service
*/

export class WebStorageService implements IStorageService {
    constructor(private storage: Storage) {}

    has(key: string): boolean {
        return this.storage.getItem(key) !== null;
    }
    get(key: string): string | undefined {
        const value = this.storage.getItem(key);

        return value !== null ? value : undefined;
    }
    set(key: string, value: string): void {
        this.storage.setItem(key, value);
    }
    remove(key: string): void {
        this.storage.removeItem(key);
    }
    clear(): void {
        this.storage.clear();
    }
}
