<svg
    class="overlay-section"
    viewBox="0 0 450 801"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle
        cx="414"
        cy="755"
        r="15"
        fill="url(#logo)" />
    <circle
        cx="414"
        cy="389"
        r="20"
        fill="url(#logo)" />
    <g *ngIf="selectedPlacement.type === TiktokPlacement.Default">
        <text
            x="13"
            y="685"
            fill="#fff"
            font-size="19px">
            &#64;{{ brandName$ | async }}
        </text>
    </g>
    <g *ngIf="selectedPlacement.type === TiktokPlacement.After9s">
        <text
            x="13"
            y="645"
            fill="#fff"
            font-size="19px">
            &#64;{{ brandName$ | async }}
        </text>
    </g>
    <g *ngIf="selectedPlacement.type === TiktokPlacement.After9sWithCard">
        <rect
            width="80"
            height="80"
            fill="url(#logo)"
            y="653"
            x="25"></rect>
        <text
            x="120"
            y="675"
            fill="#fff"
            font-size="16px"
            style="font-weight: bold">
            {{ brandName$ | async }}
        </text>
    </g>

    <defs>
        <pattern
            id="logo"
            x="15"
            y="15"
            patternContentUnits="objectBoundingBox"
            height="100%"
            width="100%"
            viewBox="0 0 1 1"
            preserveAspectRatio="xMidYMid slice">
            <image
                height="1"
                width="1"
                preserveAspectRatio="xMidYMid slice"
                [attr.xlink:href]="(brandLogoUrl$ | async) || 'assets/logo.png'"></image>
        </pattern>
    </defs>
</svg>

<img
    class="overlay-section bottom"
    alt="Social Media Overlay"
    src="assets/images/social/tiktok-shared.svg" />

<img
    class="overlay-section bottom"
    alt="Social Media Overlay"
    [src]="selectedPlacement | socialImage" />
