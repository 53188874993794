<svg
    class="overlay-section"
    viewBox="0 0 450 801"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle
        cx="32.0653"
        cy="73.7174"
        r="14.6739"
        fill="url(#logo)" />
    <g *ngIf="selectedPlacement.type === SnapchatPlacement.Default">
        <text
            x="56"
            y="73"
            fill="#fff"
            font-size="17px">
            {{ brandName$ | async }}
        </text>
    </g>

    <defs>
        <pattern
            id="logo"
            x="15"
            y="15"
            patternContentUnits="objectBoundingBox"
            height="100%"
            width="100%"
            viewBox="0 0 1 1"
            preserveAspectRatio="xMidYMid slice">
            <image
                height="1"
                width="1"
                preserveAspectRatio="xMidYMid slice"
                [attr.xlink:href]="(brandLogoUrl$ | async) || 'assets/logo.png'"></image>
        </pattern>
    </defs>
</svg>

<img
    class="overlay-section bottom"
    alt="Social Media Overlay"
    [src]="selectedPlacement | socialImage" />
