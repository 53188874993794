<div class="wrapper">
    <div class="header">
        @if (group.elements.length === 1) {
            <ui-svg-icon
                leadingIcon
                [icon]="(group.elements[0] | iconForElement) ?? ''"></ui-svg-icon>
            <span>
                {{ group.elements[0].name }}
            </span>
        } @else {
            <group-option-number [number]="group.elements.length"></group-option-number>
            <span>Multiple elements</span>
        }
    </div>
    <ng-content select="[elementInputs]"></ng-content>
</div>
