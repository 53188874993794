import { Component, inject } from '@angular/core';
import { Feature, FeatureService } from '../../services/feature/feature.service';
import { UIModule } from '@bannerflow/ui';

@Component({
    selector: 'feature-toggle',
    templateUrl: './feature-toggle.component.html',
    standalone: true,
    styleUrls: ['./feature-toggle.component.scss'],
    imports: [UIModule]
})
export class FeatureToggleComponent {
    private featureService = inject(FeatureService);
    get isEnabled(): boolean {
        return window.localStorage.getItem('feature-toggle') === 'enabled';
    }
    get activatedFeatures(): Feature[] {
        return this.featureService.getActivatedFeatures();
    }

    constructor() {
        window.enableFeatureToggle = this.enableFeatureToggle.bind(this);
    }

    features = this.featureService.features.map(feature => ({
        name: feature,
        active: this.activatedFeatures.includes(feature)
    }));

    toggleFeature(features: Feature[]): void {
        this.featureService.setEnabledFeatures(features);
    }

    enableFeatureToggle(): void {
        window.localStorage.setItem('feature-toggle', 'enabled');
    }
}
