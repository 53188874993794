import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PullRequestsService {
    constructor(private http: HttpClient) {}

    public getPullRequests(): Observable<string[]> {
        return this.http.get<string[]>('https://sandbox-studio.bannerflow.com/deployment-slots.json');
    }
}
