import { Component, inject, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UISVGIconComponent } from '@bannerflow/ui';
import { HotkeyBetterService } from '../../../../shared/services/hotkeys/hotkey.better.service';

@Component({
    standalone: true,
    imports: [UISVGIconComponent],
    selector: 'close-button',
    styleUrl: './close-button.component.scss',
    templateUrl: './close-button.component.html'
})
export class CloseButtonComponent implements OnDestroy {
    private hotkeyBetterService = inject(HotkeyBetterService);
    private activatedRoute = inject(ActivatedRoute);
    private router = inject(Router);

    constructor() {
        this.startHotkeyListeners();
    }

    ngOnDestroy(): void {
        this.stopHotkeyListeners();
    }

    navigateToMV(): void {
        this.router.navigate(['../'], {
            queryParamsHandling: 'merge',
            relativeTo: this.activatedRoute
        });
    }

    // shortcuts support
    private exitTranslationPage = (): void => {
        const stylePopover = document.getElementById('style-popover');
        if (!stylePopover) {
            this.navigateToMV();
        }
    };

    private startHotkeyListeners(): void {
        this.hotkeyBetterService.on('ExitTranslationPage', this.exitTranslationPage);
    }

    private stopHotkeyListeners(): void {
        this.hotkeyBetterService.on('ExitTranslationPage', this.exitTranslationPage);
    }
}
