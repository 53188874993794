import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PointerStatesService {
    workspaceHovered = new Subject<boolean>();
    managePageScrollViewHovered = new Subject<boolean>();

    fitPosition = new Subject<any>();
}
