<ng-container *permissions="'CalculateWeights'">
    @if (weightIsLoading$ | async) {
        <div class="filesize loading">
            <ui-loader></ui-loader>
        </div>
    } @else {
        <div class="filesize">
            @if (creative?.design?.elements?.length) {
                @if (filesize()) {
                    <span [uiTooltip]="filesize()!.tooltip">
                        <span class="initialLoad">{{ filesize()!.initialLoad }}</span>
                        <span class="subLoad">({{ filesize()!.subLoad }})</span>
                    </span>
                } @else {
                    <div class="calculate-button">
                        <div
                            id="mv-calculate-weight"
                            class="button"
                            uiTooltip="Calculate weight"
                            [uiTooltipHideArrow]="true"
                            (click)="calculateWeight($event)">
                            <ui-svg-icon icon="sync"></ui-svg-icon>
                            kB
                        </div>
                    </div>
                }
            }
        </div>
    }
</ng-container>
