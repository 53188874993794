import { IColor } from '../color';

export interface IVideoSettings {
    optimization: IVideoOptimizationSettings;
    streaming: IVideoStreamingSettings;
    autoplay: boolean;
    loop: boolean;
    restartWithCreative: boolean;
    stopWithCreative: boolean;
    playbackRate: PlaybackRate;
    startTime: number;
    /** 0 means that there is no end time */
    endTime: number;
    sizeMode: VideoSizeMode;
    playbackButton: IPlaybackButton;
    volume: number;
}

export interface IVideoRendererSettings extends IVideoSettings {
    url: string;
}

export interface IVideoOptimizationSettings {
    enabled: boolean;
    quality?: number;
}

export interface IVideoStreamingSettings {
    enabled: boolean;
}

export interface IPlaybackButton {
    enabled: boolean;
    /** 0-100% */
    size: number;
    color: IColor;
}

export type PlaybackRate = 0.25 | 0.5 | 0.75 | 1 | 1.25 | 1.5 | 1.75 | 2;

export enum VideoSizeMode {
    Fit = 'fit',
    Crop = 'crop'
}

export type VideoSettingPropertyType = IVideoSettings[keyof IVideoSettings];
