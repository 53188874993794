<div
    id="interaction-toolbar"
    #toolbar
    class="toolbar"
    [ngClass]="{ locked: isMediaLibraryOpen }">
    <!-- Selection tool -->
    <div
        id="interaction-toolbar-selection"
        class="toolbar-item"
        [class.active]="
            (mediaLibraryKind === LibraryKind.Selection && isMediaLibraryOpen) || !isMediaLibraryOpen
        "
        (click)="toggleSelectionTool($event)">
        <div
            class="button"
            id="selection-tool">
            <ui-svg-icon
                class="icon"
                icon="selection-tool"></ui-svg-icon>
        </div>
        <div class="tooltip">Selection tool (V)</div>
    </div>

    <div class="divider hidden"></div>

    <!-- Text tool  -->
    <div
        id="interaction-toolbar-text"
        class="toolbar-item type-{{ LibraryKind.Text }}"
        [ngClass]="{ active: mediaLibraryKind === LibraryKind.Text && isMediaLibraryOpen }"
        (click)="selectToolbarItem(LibraryKind.Text, $event)"
        (dblclick)="createElement(ElementKind.Text)">
        <div
            class="button"
            id="text-tool">
            <ui-svg-icon
                class="icon"
                icon="text"></ui-svg-icon>
        </div>
        <div
            *ngIf="mediaLibraryKind !== LibraryKind.Text"
            class="tooltip">
            Text tool (T)
        </div>
    </div>

    <!-- Button tool -->
    <div
        id="interaction-toolbar-button"
        class="toolbar-item type-{{ LibraryKind.Button }}"
        [ngClass]="{ active: mediaLibraryKind === LibraryKind.Button && isMediaLibraryOpen }"
        (click)="selectToolbarItem(LibraryKind.Button, $event)"
        (dblclick)="createElement(ElementKind.Button)">
        <div
            class="button"
            id="button-tool">
            <ui-svg-icon
                class="icon"
                icon="button"></ui-svg-icon>
        </div>
        <div
            *ngIf="mediaLibraryKind !== LibraryKind.Button"
            class="tooltip">
            Button tool (B)
        </div>
    </div>

    <!-- Rectangle tool -->
    <div
        id="interaction-toolbar-rectangle"
        class="toolbar-item type-{{ LibraryKind.Rectangle }}"
        [ngClass]="{ active: mediaLibraryKind === LibraryKind.Rectangle && isMediaLibraryOpen }"
        (click)="selectToolbarItem(LibraryKind.Rectangle, $event)"
        (dblclick)="createElement(ElementKind.Rectangle)">
        <div
            class="button"
            id="rectangle-tool">
            <ui-svg-icon
                class="icon"
                icon="shape-rectangle"></ui-svg-icon>
        </div>
        <div
            *ngIf="mediaLibraryKind !== LibraryKind.Rectangle"
            class="tooltip">
            Rectangle tool (R)
        </div>
    </div>

    <!-- Ellipse tool -->
    <div
        id="interaction-toolbar-ellipse"
        class="toolbar-item type-{{ LibraryKind.Ellipse }}"
        [ngClass]="{ active: mediaLibraryKind === LibraryKind.Ellipse && isMediaLibraryOpen }"
        (click)="selectToolbarItem(LibraryKind.Ellipse, $event)"
        (dblclick)="createElement(ElementKind.Ellipse)">
        <div
            class="button"
            id="ellipse-tool">
            <ui-svg-icon
                class="icon"
                icon="shape-oval"></ui-svg-icon>
        </div>
        <div
            *ngIf="mediaLibraryKind !== LibraryKind.Ellipse"
            class="tooltip">
            Ellipse tool (E)
        </div>
    </div>

    <!-- Image library -->
    <div
        id="interaction-toolbar-image"
        class="toolbar-item type-{{ LibraryKind.Image }}"
        [ngClass]="{ active: mediaLibraryKind === LibraryKind.Image && isMediaLibraryOpen }"
        (click)="selectToolbarItem(LibraryKind.Image, $event)">
        <div
            class="button"
            id="image-tool">
            <ui-svg-icon
                class="icon"
                icon="image"></ui-svg-icon>
        </div>
        <div
            *ngIf="mediaLibraryKind !== LibraryKind.Image"
            class="tooltip">
            Images (I)
        </div>
    </div>

    <!-- Video library -->
    <div
        *permissions="'VideoLibrary'"
        id="interaction-toolbar-video"
        class="toolbar-item type-{{ LibraryKind.Video }}"
        [ngClass]="{ active: mediaLibraryKind === LibraryKind.Video && isMediaLibraryOpen }"
        (click)="selectToolbarItem(LibraryKind.Video, $event)">
        <div
            class="button"
            id="video-tool">
            <ui-svg-icon
                class="icon"
                icon="video"></ui-svg-icon>
        </div>
        <div
            *ngIf="mediaLibraryKind !== LibraryKind.Video"
            class="tooltip">
            Videos (C)
        </div>
    </div>

    <!-- Widget library -->
    <div
        id="interaction-toolbar-widget"
        class="toolbar-item type-{{ LibraryKind.Widget }}"
        [ngClass]="{ active: mediaLibraryKind === LibraryKind.Widget && isMediaLibraryOpen }"
        (click)="selectToolbarItem(LibraryKind.Widget, $event)">
        <div
            class="button"
            id="widget-tool">
            <ui-svg-icon
                class="icon"
                icon="widget"></ui-svg-icon>
        </div>
        <div
            *ngIf="mediaLibraryKind !== LibraryKind.Widget"
            class="tooltip">
            Widgets (W)
        </div>
    </div>

    <!-- Effects library -->
    <div
        id="interaction-toolbar-effects"
        class="toolbar-item type-effects"
        [ngClass]="{ active: mediaLibraryKind === LibraryKind.Effects && isMediaLibraryOpen }"
        (click)="selectToolbarItem(LibraryKind.Effects, $event)">
        <div
            class="button"
            id="effects-tool">
            <ui-svg-icon
                class="icon"
                icon="social-fx"></ui-svg-icon>
        </div>
        <div
            *ngIf="mediaLibraryKind !== LibraryKind.Effects"
            class="tooltip">
            Effects (F)
        </div>
    </div>

    <div class="divider"></div>

    <!-- Feeds -->
    <div
        id="interaction-toolbar-feeds"
        class="toolbar-item prevent-text-blur"
        [ngClass]="{ active: mediaLibraryKind === LibraryKind.Feeds && isMediaLibraryOpen }"
        (click)="selectToolbarItem(LibraryKind.Feeds, $event)"
        #feedsButton>
        <div
            class="button feeds-tool"
            id="feeds-tool">
            <ui-svg-icon
                class="icon"
                icon="feed"></ui-svg-icon>
        </div>
        <div
            *ngIf="mediaLibraryKind !== LibraryKind.Feeds"
            class="tooltip">
            Dynamic content (D)
        </div>
    </div>

    <!-- Media Library -->
    <div
        id="interaction-toolbar-library"
        class="toolbar-item"
        [class.active]="mediaLibraryKind && mediaLibraryKind === LibraryKind.Any && isMediaLibraryOpen"
        (click)="selectToolbarItem(LibraryKind.Any, $event)">
        <div
            class="button"
            id="library">
            <ui-svg-icon
                class="icon"
                icon="folder-multiple"></ui-svg-icon>
        </div>
        <div
            *ngIf="mediaLibraryKind !== LibraryKind.Any"
            class="tooltip">
            Brand library (L)
        </div>
    </div>

    <div class="divider"></div>
</div>
