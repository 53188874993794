import { IAsyncState } from '@domain/store/async';
import { createReducer, on } from '@ngrx/store';
import { ICLVList, IDuplicationResponse } from '@studio/domain/api/duplicate-creatives.types';
import * as DuplicateCreativeActions from './duplicate-creative.actions';

export const DUPLICATE_CREATIVES_FEATURE_KEY = 'duplicateCreatives';

export interface DuplicateCreativesPartialState {
    readonly [DUPLICATE_CREATIVES_FEATURE_KEY]: DuplicateCreativesState;
}

export interface DuplicateCreativesState extends IAsyncState {
    setsAndFolders?: ICLVList;
    searchTerm?: string;
    pageIndex: number;
    duplicationResult?: IDuplicationResponse;
    isDuplicating: boolean;
}

export const initialState: DuplicateCreativesState = {
    loaded: false,
    pageIndex: 1,
    isDuplicating: false
};

export const reducer = createReducer<DuplicateCreativesState>(
    initialState,

    on(DuplicateCreativeActions.loadDuplicateCreativesPage, state => {
        return {
            ...state,
            loaded: false,
            setsAndFolders: undefined,
            isDuplicating: false
        };
    }),
    on(DuplicateCreativeActions.loadDuplicateCreativesPageSuccess, (state, { data, pageIndex }) => {
        return {
            ...state,
            loaded: true,
            setsAndFolders: data,
            pageIndex
        };
    }),

    on(DuplicateCreativeActions.searchDuplicateCreatives, (state, { searchTerm }) => {
        return {
            ...state,
            loaded: false,
            searchTerm,
            setsAndFolders: undefined,
            isDuplicating: false
        };
    }),
    on(DuplicateCreativeActions.searchDuplicateCreativesSuccess, (state, { searchTerm, data }) => {
        return searchTerm !== state.searchTerm // Ignore responses to old searches
            ? { ...state }
            : {
                  ...state,
                  loaded: true,
                  searchTerm,
                  setsAndFolders: data
              };
    }),

    on(DuplicateCreativeActions.clearSearchDuplicateCreatives, state => {
        return {
            ...state,
            loaded: true,
            searchTerm: undefined,
            setsAndFolders: undefined
        };
    }),

    on(
        DuplicateCreativeActions.createDuplicationToNew,
        DuplicateCreativeActions.createDuplicationToExisting,
        state => {
            return {
                ...state,
                loaded: false,
                duplicationResult: undefined,
                isDuplicating: true
            };
        }
    ),
    on(
        DuplicateCreativeActions.createDuplicationToNewSuccess,
        DuplicateCreativeActions.createDuplicationToExistingSuccess,
        (state, { data: duplicationResult }) => {
            return {
                ...state,
                loaded: true,
                duplicationResult
            };
        }
    ),

    on(
        DuplicateCreativeActions.searchDuplicateCreativesFailure,
        DuplicateCreativeActions.loadDuplicateCreativesPageFailure,
        DuplicateCreativeActions.createDuplicationToNewFailure,
        DuplicateCreativeActions.createDuplicationToExistingFailure,
        (state, { error }) => {
            return {
                ...state,
                error,
                loaded: true,
                setsAndFolders: undefined
            };
        }
    ),

    on(DuplicateCreativeActions.clearDuplicationState, () => {
        return { ...initialState };
    }),

    on(DuplicateCreativeActions.createNewFolderFailure, state => {
        return {
            ...state,
            error: 'Failed to create new folder'
        };
    }),

    on(DuplicateCreativeActions.createNewFolderSuccess, (state, { newFolder }) => {
        if (!state.setsAndFolders) {
            return { ...state };
        }

        return {
            ...state,
            setsAndFolders: {
                ...state.setsAndFolders,
                items: [newFolder, ...state.setsAndFolders?.items]
            }
        };
    })
);
