import { Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Logger } from '@bannerflow/sentinel-logger';
import { UIConfirmDialogService } from '@bannerflow/ui';
import { filter } from 'rxjs';
import { GainsightEvent, GainsightMap, GainsightService } from './gainsight.service';
import { StudioHubService } from './studio-hub.service';
import { CreativesetDataService } from '../creativeset/creativeset.data.service';
import { environment } from '@app/environments/environment';

@Injectable({ providedIn: 'root' })
export class ConcurrencyService {
    private studioHubService = inject(StudioHubService);
    private gainsightService = inject(GainsightService);
    private creativesetDataService = inject(CreativesetDataService);
    private uiConfirmDialogService = inject(UIConfirmDialogService);
    private logger = new Logger(ConcurrencyService.name);

    constructor() {
        this.studioHubService.userActivity$
            .pipe(
                filter(activity => activity.action !== 'validateDesigns'),
                takeUntilDestroyed()
            )
            .subscribe(activity => {
                if (activity.action === 'creativeSetDeleted') {
                    this.openBackToListViewDialog();
                    return;
                }
                this.askUserToReload(activity.user.name, activity.timestamp);
            });
    }

    trackEvent(type: GainsightMap['Concurrency']['type']): void {
        this.gainsightService.sendCustomEvent(GainsightEvent.Concurrency, { type });
    }

    private async openBackToListViewDialog(): Promise<void> {
        const result = await this.uiConfirmDialogService.confirm({
            id: 'creativesetDeletedDialog',
            headerText: 'THIS CREATIVE SET HAS BEEN DELETED',
            confirmText: 'Go back to List View',
            backdropClickClose: false,
            text: `This creative set has been deleted in another tab or by another user.`
        });

        if (result === 'confirm') {
            const brandUrl = `${this.creativesetDataService.getAccountSlug()}/${this.creativesetDataService.getBrandSlug()}`;
            const creativesetId = this.creativesetDataService.creativeset.id;
            const url = `${environment.origins.bfc}/${brandUrl}/creative-sets?redirect=${creativesetId}`;

            window.location.href = url;
        }
    }

    private async askUserToReload(activeUsername: string, lastActivity: string): Promise<void> {
        this.trackEvent('show');

        const result = await this.uiConfirmDialogService.confirm({
            id: 'creativesetUpdateDialog',
            headerText: 'THIS CREATIVE SET HAS BEEN UPDATED',
            confirmText: 'Reload',
            discardText: 'Ignore',
            backdropClickClose: false,
            text: `
                Changes were made to this creative set in another tab or by another user:
                <b>${activeUsername} (Last saved ${lastActivity})</b>
                You can ignore these changes and keep working where you were, or reload to get the latest version of your creative set.
            `
        });

        if (result === 'cancel' || result === 'discard') {
            this.trackEvent('ignore');
            return;
        }

        if (result === 'confirm') {
            this.logger.verbose('User confirmed to reload.');
            this.trackEvent('reload');
            window.location.reload();
        }
    }
}
