import { Callback, EventCallbackStore, IEventEmitter } from '@domain/events';

export class EventEmitter<T extends Record<string, unknown>> implements IEventEmitter<T> {
    private _eventCallbacks: EventCallbackStore<T> = {} as EventCallbackStore<T>;
    protected skipEmission = false;

    on<E extends keyof T, C extends T[E]>(event: E, callback: Callback<C>): this {
        if (!this._eventCallbacks[event]) {
            this._eventCallbacks[event] = [];
        }

        if (this._eventCallbacks[event].indexOf(callback!) === -1) {
            this._eventCallbacks[event].push(callback!);
        }

        return this;
    }

    once<E extends keyof T, C extends T[E]>(event: E, callback: Callback<C>): this {
        const temporary = (arg: C): void => {
            if (typeof callback === 'function') {
                callback(arg);
            }
            this.off(event, temporary);
        };
        this.on(event, temporary);
        return this;
    }

    off<E extends keyof T, C extends T[E]>(event: E, callback?: Callback<C>): this {
        if (typeof event === 'undefined') {
            this.clearEvents();
            return this;
        }
        if (!callback) {
            delete this._eventCallbacks[event];
            return this;
        }
        const callbacks = this._eventCallbacks[event];
        if (callbacks?.length) {
            let i = callbacks.length;
            // Do this way to make sure no index is skipped when reducing size of array
            while (i-- > 0) {
                if (callbacks[i] === callback) {
                    callbacks.splice(i, 1);
                }
            }
        }

        return this;
    }

    emit<E extends keyof T, C extends T[E]>(event: E, arg?: C): this {
        if (this.skipEmission) {
            return this;
        }

        // Clone array to make sure all events are fired even when reducing size of array (see this.once())
        const callbacks = this._eventCallbacks[event]?.slice();
        if (callbacks) {
            for (const callback of callbacks) {
                try {
                    callback(arg);
                } catch (err: unknown) {
                    console.error('CallbackError: ', err);
                }
            }
        }

        return this;
    }

    clearEvents(): void {
        this._eventCallbacks = {} as EventCallbackStore<T>;
    }
}
