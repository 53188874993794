<div class="content-list">
    @if (isShowingAll$ | async) {
        <div class="group-wrapper">
            @for (group of groups$ | async; track group.id) {
                <element-group-wrapper [group]="group">
                    <ng-container
                        elementInputs
                        *ngTemplateOutlet="
                            inputTemplate;
                            context: {
                                group: group,
                                selectedElement: undefined
                            }
                        "></ng-container>
                </element-group-wrapper>
            }
        </div>
    } @else {
        @if (selectedGroup$ | async; as selectedGroup) {
            <div
                *ngTemplateOutlet="
                    inputTemplate;
                    context: { group: selectedGroup, selectedElement: (selectedElement$ | async) }
                "></div>
        }
    }

    <ng-template
        #inputTemplate
        let-selectedElement="selectedElement"
        let-group="group">
        @switch (group | versionedGroupType) {
            @case ('widget') {
                @if (group.elements.length === 1 || selectedElement) {
                    @for (version of sortedSelectedVersions$ | async; track version.id) {
                        <versioned-widget
                            [selectedVersions]="(sortedSelectedVersions$ | async)!"
                            [defaultVersion]="(defaultVersion$ | async)!"
                            [selectedElement]="selectedElement"
                            [version]="version"
                            [group]="group">
                        </versioned-widget>
                    }
                } @else {
                    <versioned-widget
                        [selectedVersions]="(sortedSelectedVersions$ | async)!"
                        [defaultVersion]="(defaultVersion$ | async)!"
                        [selectedElement]="selectedElement"
                        [version]="group.version"
                        [group]="group">
                    </versioned-widget>
                }
            }
            @case ('text') {
                @if (group.elements.length === 1 || selectedElement) {
                    @for (version of sortedSelectedVersions$ | async; track version.id) {
                        <versioned-content
                            [defaultVersion]="(defaultVersion$ | async)!"
                            [group]="group"
                            [selectedElement]="selectedElement"
                            [version]="version"></versioned-content>
                    }
                } @else {
                    <versioned-content
                        [defaultVersion]="(defaultVersion$ | async)!"
                        [group]="group"
                        [selectedElement]="selectedElement"
                        [version]="group.version"></versioned-content>
                }
            }
        }
    </ng-template>

    @if (!(inShowcaseMode$ | async)) {
        <feed-sources
            [defaultVersion]="(defaultVersion$ | async)!"
            [selectedVersions]="(sortedSelectedVersions$ | async)!"></feed-sources>
    }
</div>
