import { Component, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UIModule, UISelectSummaryValueDirective } from '@bannerflow/ui';
import { FilterableSize, FilterableSizeCollection } from '@studio/domain/state';
import { FiltersService } from '../filters';

@Component({
    standalone: true,
    imports: [UIModule, UISelectSummaryValueDirective],
    selector: 'size-picker',
    templateUrl: './size-picker.component.html',
    styleUrl: './size-picker.component.scss'
})
export class SizePickerComponent {
    private filtersService = inject(FiltersService);

    private selectedSizes = toSignal(this.filtersService.selectedSizes$, { initialValue: [] });

    collections = toSignal(this.filtersService.filterableSizeCollections$, { initialValue: [] });
    placeholder = computed(() => this.computePlaceholder());
    sizes = toSignal(this.filtersService.filterableSizes$, { initialValue: [] });
    summaryValue = computed(() => this.computeSummaryValue());
    values = computed(() => this.computeValues());

    toggleCollection(collection: FilterableSizeCollection): void {
        if (collection.selected) {
            this.filtersService.deselectCollection(collection.id);
        } else {
            this.filtersService.selectCollection(collection.id);
        }
    }

    toggleSize(size: FilterableSize): void {
        if (size.selected) {
            this.filtersService.deselectSize(size.id);
        } else {
            this.filtersService.selectSize(size.id);
        }
    }

    onSelectedChanged(event: (FilterableSize | FilterableSizeCollection)[]): void {
        // Selection cleared
        if (event.length === 0) {
            this.filtersService.clearSizesFilter();
        }
    }

    private computePlaceholder(): string {
        const sizes = this.sizes();
        if (sizes.length) {
            return 'All sizes';
        }
        return 'No sizes';
    }
    private computeSummaryValue(): string | undefined {
        const selectedSizes = this.selectedSizes();
        const sizes = this.sizes();
        if (!selectedSizes.length) {
            return;
        }
        return `${selectedSizes.length} sizes selected of ${sizes.length}`;
    }
    private computeValues(): (FilterableSize | FilterableSizeCollection)[] {
        return [...this.collections(), ...this.sizes()].filter(({ selected }) => selected);
    }
}
