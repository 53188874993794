import { ITimeAndDuration } from '@domain/animation';
import { IBoundingBox, IBounds, IConstraintV2, ISize } from '@domain/dimension';
import { ICreativeDataNode, OneOfElementDataNodes } from '@domain/nodes';

export interface ILayoutElement {
    constraint: IConstraintV2;
    element: OneOfElementDataNodes;
    rotatedBoundingBox: IBoundingBox;
    originalCreative: ICreativeDataNode;
    newCanvasSize: ISize;
    group?: IContstraintGroup;
}

export interface IContstraintGroup {
    kind: 'group';
    id: string;
    constraint: IConstraintV2;
    layoutElements: ILayoutElement[];
    timelinePosition: ITimeAndDuration;
    rotatedBoundingBox: IBoundingBox;
    originalCreative: ICreativeDataNode;
    newCanvasSize: ISize;
    newBoundingBox?: IBounds;
    group?: IContstraintGroup;
}

export type GroupOrLayoutElement = IContstraintGroup | ILayoutElement;

export interface ILayoutRule {
    /**
     * Rule that will be applied when trying to calculate auto constraints
     * @param layoutElement
     */
    applyAutoContraintRule?(layoutElement: GroupOrLayoutElement): void;

    /**
     * Rule that will be applied just before an elements properties is updates.
     * Last chance to change stuff.
     * @param layoutElement
     * @param calculatedBounds
     */
    applyRenderRule?(layoutElement: GroupOrLayoutElement, calculatedBounds: IBounds): void;
}

export function isContraintGroup(object: GroupOrLayoutElement): object is IContstraintGroup {
    return 'kind' in object && object.kind === 'group';
}

export function isLayoutElement(object: GroupOrLayoutElement): object is ILayoutElement {
    return !('kind' in object) || object.kind !== 'group';
}
