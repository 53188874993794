<ui-dropdown
    #dropdown
    class="localizationDropdown"
    width="180"
    [maxHeight]="300"
    [offset]="offset"
    theme="default"
    [positions]="positions">
    <div class="header-wrapper">
        <div>Language</div>
        <div
            class="right"
            *permissions="'AutoTranslate'">
            <span [uiTooltip]="'AI-generated Translations may be imperfect'"> AI Translation </span>
            <ui-toggle-switch
                class="toggle-auto-translate"
                id="interaction-toggle-auto-translate"
                (click)="toggleSelectAutoTranslate($event)">
            </ui-toggle-switch>
        </div>
    </div>
    @for (localization of localizations$ | async; track localization.id) {
        <ui-dropdown-item
            [attr.data-test-id]="'add-localization-' + localization.cultureCode"
            (click)="localizationClicked(localization, $event)">
            <version-flag
                [localizationId]="localization.id"
                [showName]="true"></version-flag>
        </ui-dropdown-item>
    }
</ui-dropdown>
