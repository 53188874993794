<div class="overlay-container">
    <h1 class="keymaps-title">Keyboard shortcuts</h1>
    <div class="keymaps-wrapper">
        <div class="column-left">
            @for (context of leftColumn; track context.key) {
                <div class="context">
                    <h1 class="header">{{ context.key }}</h1>
                    @for (hotkey of context.value; track hotkey) {
                        <div class="hotkeys">
                            <div class="info">{{ hotkey.meta?.description }}</div>
                            <div class="combination">
                                <span
                                    class="value"
                                    [innerHTML]="hotkey.meta?.shortcut | hotkeySeparateByWord"></span>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
        <div class="column-right">
            @for (context of rightColumn; track context.key) {
                <div class="context">
                    <h1 class="header">{{ context.key }}</h1>
                    @for (hotkey of context.value; track hotkey) {
                        <div class="hotkeys">
                            <div class="info">{{ hotkey.meta?.description }}</div>
                            <div class="combination">
                                <span
                                    class="value"
                                    [innerHTML]="hotkey.meta?.shortcut | hotkeySeparateByWord"></span>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    </div>
</div>
