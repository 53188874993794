import { EnvironmentProviders, importProvidersFrom } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CreativesEffects } from './creatives.effects';
import { CREATIVES_FEATURE_KEY, reducer } from './creatives.reducer';

export function provideCreativesStore(): EnvironmentProviders {
    return importProvidersFrom(
        StoreModule.forFeature(CREATIVES_FEATURE_KEY, reducer),
        EffectsModule.forFeature(CreativesEffects)
    );
}
