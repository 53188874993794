<studio-ui-section
    headline="State in transition"
    [clickableHeader]="false"
    class="no-padding">
    <div class="setting state-in">
        <div class="setting-row">
            <div class="setting-label">Duration</div>
            <div class="setting-value col-2 wide">
                <div class="property-input slider">
                    <studio-ui-slider
                        [(value)]="inDuration"
                        (valueChange)="onDurationChange('in')"
                        (mouseup)="onDurationChange('in', true)"
                        [config]="{ min: 0, max: 10, accuracy: 100 }"></studio-ui-slider>
                </div>
                <div class="property-input">
                    <ui-number-input
                        [(value)]="inDuration"
                        (valueChange)="onDurationChange('in', true)"
                        [keyboardEmit]="true"
                        [min]="0"
                        [max]="10"
                        [step]="0.1"
                        [allowEmpty]="false"
                        [disableUndo]="true"></ui-number-input>
                </div>
            </div>
        </div>
        <div class="setting-row">
            <div class="setting-label">Easing</div>
            <div class="setting-value select">
                <div class="property-input">
                    <ui-select
                        [(selected)]="inEasing"
                        (selectedChange)="onEasingChange('in')"
                        [useTargetWidth]="true">
                        <ui-option
                            *ngFor="let timingFunction of timingFunctions | keyvalue"
                            [value]="timingFunction.key"
                            >{{ timingFunction.value.name }}
                        </ui-option>
                    </ui-select>
                </div>
            </div>
        </div>
    </div>
</studio-ui-section>
<studio-ui-section
    headline="State out transition"
    [clickableHeader]="false"
    class="no-padding">
    <div class="setting state-out">
        <div class="setting-row">
            <div class="setting-label">Duration</div>
            <div class="setting-value col-2 wide">
                <div class="property-input slider">
                    <studio-ui-slider
                        [(value)]="outDuration"
                        (valueChange)="onDurationChange('out')"
                        (mouseup)="onDurationChange('out', true)"
                        [config]="{ min: 0, max: 10, accuracy: 100 }"></studio-ui-slider>
                </div>
                <div class="property-input">
                    <ui-number-input
                        [(value)]="outDuration"
                        (valueChange)="onDurationChange('out', true)"
                        [keyboardEmit]="true"
                        [min]="0"
                        [max]="10"
                        [step]="0.1"
                        [allowEmpty]="false"
                        [disableUndo]="true"></ui-number-input>
                </div>
            </div>
        </div>
        <div class="setting-row">
            <div class="setting-label">Easing</div>
            <div class="setting-value select">
                <div class="property-input">
                    <ui-select
                        [(selected)]="outEasing"
                        (selectedChange)="onEasingChange('out')"
                        [useTargetWidth]="true">
                        <ui-option
                            *ngFor="let timingFunction of timingFunctions | keyvalue"
                            [value]="timingFunction.key"
                            >{{ timingFunction.value.name }}
                        </ui-option>
                    </ui-select>
                </div>
            </div>
        </div>
    </div>
</studio-ui-section>
