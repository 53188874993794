export default {
    "openapi": "3.0.1",
    "info": {
        "title": "Studio",
        "version": "1.0"
    },
    "paths": "redacted",
    "components": {
        "schemas": {
            "ActionDto": {
                "required": [
                    "disabled",
                    "id",
                    "operations",
                    "triggers"
                ],
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string"
                    },
                    "triggers": {
                        "type": "array",
                        "items": {
                            "enum": [
                                "click",
                                "mousedown",
                                "mouseup",
                                "mouseenter",
                                "mouseleave",
                                "touchstart",
                                "touchend",
                                "touchcancel"
                            ],
                            "type": "string"
                        }
                    },
                    "disabled": {
                        "type": "boolean"
                    },
                    "templateId": {
                        "enum": [
                            "reserved-pressed",
                            "reserved-hover"
                        ],
                        "type": "string"
                    },
                    "operations": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/OperationDto"
                        }
                    },
                    "conditions": {
                        "type": "array",
                        "items": {}
                    },
                    "preventClickThrough": {
                        "type": "boolean"
                    }
                },
                "additionalProperties": false
            },
            "AddElementToBrandLibraryRequest": {
                "required": [
                    "element"
                ],
                "type": "object",
                "properties": {
                    "originalElementId": {
                        "type": "string",
                        "format": "uuid"
                    },
                    "element": {
                        "$ref": "#/components/schemas/ElementDto"
                    }
                },
                "additionalProperties": false
            },
            "AnimationDto": {
                "required": [
                    "hidden",
                    "id",
                    "keyframes",
                    "name",
                    "timingFunction"
                ],
                "type": "object",
                "properties": {
                    "keyframes": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/KeyframeDto"
                        }
                    },
                    "id": {
                        "type": "string"
                    },
                    "name": {
                        "type": "string"
                    },
                    "type": {
                        "enum": [
                            "in",
                            "out",
                            "repeating",
                            "action",
                            "keyframe"
                        ],
                        "type": "string"
                    },
                    "timingFunction": {
                        "enum": [
                            "linear",
                            "easeInExpo",
                            "easeOutExpo",
                            "easeInOutExpo",
                            "easeInQuad",
                            "easeOutQuad",
                            "easeInOutQuad",
                            "easeInCubic",
                            "easeOutCubic",
                            "easeInOutCubic",
                            "easeInQuart",
                            "easeOutQuart",
                            "easeInOutQuart",
                            "easeInQuint",
                            "easeOutQuint",
                            "easeInOutQuint",
                            "easeInElastic",
                            "easeOutElastic",
                            "easeInOutElastic",
                            "easeInBack",
                            "easeOutBack",
                            "easeInOutBack",
                            "easeInBounce",
                            "easeOutBounce",
                            "easeInOutBounce"
                        ],
                        "type": "string"
                    },
                    "templateId": {
                        "enum": [
                            "fade-in",
                            "slide-in",
                            "ascend-in",
                            "scale-in",
                            "flip-in",
                            "blur-in",
                            "fade-out",
                            "slide-out",
                            "descend-out",
                            "scale-out",
                            "flip-out",
                            "blur-out"
                        ],
                        "type": "string"
                    },
                    "settings": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/SettingDto"
                        }
                    },
                    "hidden": {
                        "type": "boolean"
                    }
                },
                "additionalProperties": false
            },
            "AssetDto": {
                "required": [
                    "images",
                    "videos"
                ],
                "type": "object",
                "properties": {
                    "images": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/ImageAssetDataDto"
                        }
                    },
                    "videos": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/VideoAssetDataDto"
                        }
                    }
                },
                "additionalProperties": false
            },
            "AuthorizationResult": {
                "type": "object",
                "properties": {
                    "isAuthorized": {
                        "type": "boolean"
                    }
                },
                "additionalProperties": false
            },
            "BodyDto": {
                "required": [
                    "creative"
                ],
                "type": "object",
                "properties": {
                    "creative": {
                        "$ref": "#/components/schemas/CreativeDto"
                    }
                },
                "additionalProperties": false
            },
            "BorderDto": {
                "required": [
                    "color",
                    "style",
                    "thickness"
                ],
                "type": "object",
                "properties": {
                    "thickness": {
                        "maximum": 5000,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    },
                    "style": {
                        "enum": [
                            "solid",
                            "dotted",
                            "dashed"
                        ],
                        "type": "string"
                    },
                    "color": {
                        "type": "string"
                    }
                },
                "additionalProperties": false
            },
            "BrandDto": {
                "required": [
                    "accountSlug",
                    "id",
                    "slug"
                ],
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string"
                    },
                    "accountSlug": {
                        "type": "string"
                    },
                    "slug": {
                        "type": "string"
                    },
                    "brandLogoUrl": {
                        "type": "string"
                    },
                    "name": {
                        "type": "string"
                    },
                    "imageDefaultNameConvention": {
                        "type": "string"
                    },
                    "videoDefaultNameConvention": {
                        "type": "string"
                    },
                    "localizations": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/LocalizationDto"
                        }
                    },
                    "sizeFormats": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/SizeFormatDto"
                        }
                    },
                    "palettes": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/PaletteDto"
                        }
                    }
                },
                "additionalProperties": false
            },
            "BrandLibraryDto": {
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string",
                        "nullable": true
                    },
                    "brandId": {
                        "type": "string",
                        "nullable": true
                    },
                    "elements": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/ElementDto"
                        },
                        "nullable": true
                    },
                    "folders": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/BrandLibraryFolderDto"
                        },
                        "nullable": true
                    },
                    "images": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/ImageAssetDto"
                        },
                        "nullable": true
                    },
                    "videos": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/VideoAssetDto"
                        },
                        "nullable": true
                    },
                    "widgets": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/WidgetAssetDto"
                        },
                        "nullable": true
                    }
                },
                "additionalProperties": false
            },
            "BrandLibraryFolderDto": {
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string",
                        "nullable": true
                    },
                    "name": {
                        "type": "string",
                        "nullable": true
                    },
                    "parentFolderId": {
                        "type": "string",
                        "nullable": true
                    }
                },
                "additionalProperties": false
            },
            "ButtonElementDto": {
                "required": [
                    "__buttonKind",
                    "actions",
                    "animations",
                    "characterSpacing",
                    "characterStyles",
                    "duration",
                    "filters",
                    "fontSize",
                    "height",
                    "hidden",
                    "horizontalAlignment",
                    "id",
                    "lineHeight",
                    "locked",
                    "maxRows",
                    "mirrorX",
                    "mirrorY",
                    "opacity",
                    "originX",
                    "originY",
                    "padding",
                    "radius",
                    "rotationX",
                    "rotationY",
                    "rotationZ",
                    "scaleX",
                    "scaleY",
                    "states",
                    "strikethrough",
                    "textColor",
                    "textOverflow",
                    "time",
                    "underline",
                    "uppercase",
                    "verticalAlignment",
                    "width",
                    "x",
                    "y"
                ],
                "type": "object",
                "properties": {
                    "__buttonKind": {
                        "type": "boolean",
                        "readOnly": true
                    },
                    "characterStyles": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/CharacterStyleDto"
                        }
                    },
                    "font": {
                        "type": "string"
                    },
                    "textColor": {
                        "type": "string"
                    },
                    "fontSize": {
                        "maximum": 2500,
                        "minimum": 1,
                        "type": "number",
                        "format": "double"
                    },
                    "lineHeight": {
                        "maximum": 9999,
                        "minimum": 0.1,
                        "type": "number",
                        "format": "double"
                    },
                    "characterSpacing": {
                        "maximum": 9999,
                        "minimum": -1,
                        "type": "number",
                        "format": "double"
                    },
                    "uppercase": {
                        "type": "boolean"
                    },
                    "underline": {
                        "type": "boolean"
                    },
                    "strikethrough": {
                        "type": "boolean"
                    },
                    "maxRows": {
                        "maximum": 9999,
                        "minimum": 0,
                        "type": "integer",
                        "format": "int32"
                    },
                    "padding": {
                        "$ref": "#/components/schemas/PaddingDto"
                    },
                    "textOverflow": {
                        "enum": [
                            "shrink",
                            "truncate",
                            "expand",
                            "scroll"
                        ],
                        "type": "string"
                    },
                    "horizontalAlignment": {
                        "enum": [
                            "left",
                            "center",
                            "right",
                            "justify"
                        ],
                        "type": "string"
                    },
                    "verticalAlignment": {
                        "enum": [
                            "top",
                            "middle",
                            "bottom"
                        ],
                        "type": "string"
                    },
                    "textShadows": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/TextShadowDto"
                        }
                    },
                    "states": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/TextStateDto"
                        }
                    },
                    "time": {
                        "maximum": 2000,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    },
                    "duration": {
                        "maximum": 2000,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    },
                    "parentId": {
                        "type": "string"
                    },
                    "parentNodeId": {
                        "type": "string"
                    },
                    "locked": {
                        "type": "boolean"
                    },
                    "hidden": {
                        "type": "boolean"
                    },
                    "ratio": {
                        "type": "number",
                        "format": "double"
                    },
                    "x": {
                        "maximum": 9999999,
                        "minimum": -9999999,
                        "type": "integer",
                        "format": "int32"
                    },
                    "y": {
                        "maximum": 9999999,
                        "minimum": -9999999,
                        "type": "integer",
                        "format": "int32"
                    },
                    "width": {
                        "maximum": 9999999,
                        "minimum": 1,
                        "type": "integer",
                        "format": "int32"
                    },
                    "height": {
                        "maximum": 9999999,
                        "minimum": 1,
                        "type": "integer",
                        "format": "int32"
                    },
                    "fill": {
                        "type": "string"
                    },
                    "originX": {
                        "type": "number",
                        "format": "double"
                    },
                    "originY": {
                        "type": "number",
                        "format": "double"
                    },
                    "rotationX": {
                        "maximum": 9999999,
                        "minimum": -9999999,
                        "type": "number",
                        "format": "double"
                    },
                    "rotationY": {
                        "maximum": 9999999,
                        "minimum": -9999999,
                        "type": "number",
                        "format": "double"
                    },
                    "rotationZ": {
                        "maximum": 9999999,
                        "minimum": -9999999,
                        "type": "number",
                        "format": "double"
                    },
                    "radius": {
                        "$ref": "#/components/schemas/RadiusDto"
                    },
                    "opacity": {
                        "maximum": 1,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    },
                    "mirrorX": {
                        "type": "boolean"
                    },
                    "mirrorY": {
                        "type": "boolean"
                    },
                    "scaleX": {
                        "type": "number",
                        "format": "double"
                    },
                    "scaleY": {
                        "type": "number",
                        "format": "double"
                    },
                    "shadows": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/ShadowDto"
                        }
                    },
                    "border": {
                        "$ref": "#/components/schemas/BorderDto"
                    },
                    "animations": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/AnimationDto"
                        }
                    },
                    "actions": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/ActionDto"
                        }
                    },
                    "filters": {
                        "$ref": "#/components/schemas/FilterDto"
                    },
                    "id": {
                        "type": "string"
                    }
                },
                "additionalProperties": false
            },
            "CaptureSnapshotResponse": {
                "type": "object",
                "properties": {
                    "snapshotId": {
                        "type": "string",
                        "nullable": true
                    }
                },
                "additionalProperties": false
            },
            "CharacterStyleDto": {
                "required": [
                    "id",
                    "value"
                ],
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string"
                    },
                    "value": {
                        "$ref": "#/components/schemas/CharacterStyleValueDto"
                    }
                },
                "additionalProperties": false
            },
            "CharacterStyleValueDto": {
                "type": "object",
                "properties": {
                    "font": {
                        "type": "string"
                    },
                    "textColor": {
                        "type": "string"
                    },
                    "fontSize": {
                        "maximum": 2500,
                        "minimum": 0.01,
                        "type": "number",
                        "format": "double"
                    },
                    "lineHeight": {
                        "maximum": 9999,
                        "minimum": 0.1,
                        "type": "number",
                        "format": "double"
                    },
                    "characterSpacing": {
                        "maximum": 9999,
                        "minimum": -1,
                        "type": "number",
                        "format": "double"
                    },
                    "uppercase": {
                        "type": "boolean"
                    },
                    "underline": {
                        "type": "boolean"
                    },
                    "strikethrough": {
                        "type": "boolean"
                    },
                    "variable": {
                        "$ref": "#/components/schemas/VariableDto"
                    },
                    "textShadows": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/TextShadowDto"
                        }
                    }
                },
                "additionalProperties": false
            },
            "CreateDesignDto": {
                "required": [
                    "document",
                    "sizeId"
                ],
                "type": "object",
                "properties": {
                    "sizeId": {
                        "type": "string"
                    },
                    "document": {
                        "$ref": "#/components/schemas/DocumentDto"
                    }
                },
                "additionalProperties": false
            },
            "CreateDesignsAndVersionsDto": {
                "required": [
                    "creatives",
                    "designs",
                    "elements",
                    "versions"
                ],
                "type": "object",
                "properties": {
                    "elements": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/UpdateElementDto"
                        }
                    },
                    "designs": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/CreateDesignDto"
                        }
                    },
                    "versions": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/UpdateVersionDto"
                        }
                    },
                    "creatives": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/UpdateCreativeDtoV2"
                        }
                    }
                },
                "additionalProperties": false
            },
            "CreateWidgetAssetDto": {
                "type": "object",
                "properties": {
                    "thumbnail": {
                        "type": "string",
                        "description": "Widget thumbnail image URL. Must be a valid URL."
                    },
                    "animatedThumbnail": {
                        "type": "string",
                        "description": "Animated thumbnail image URL (gif). Must be a valid URL."
                    }
                },
                "additionalProperties": false
            },
            "CreateWidgetDto": {
                "required": [
                    "data"
                ],
                "type": "object",
                "properties": {
                    "asset": {
                        "$ref": "#/components/schemas/CreateWidgetAssetDto"
                    },
                    "data": {
                        "$ref": "#/components/schemas/WidgetDataDto"
                    },
                    "bannerflowLibraryId": {
                        "type": "string"
                    }
                },
                "additionalProperties": false
            },
            "Creative": {
                "type": "object",
                "properties": {
                    "id": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "targetUrl": {
                        "type": "string",
                        "nullable": true
                    },
                    "designId": {
                        "type": "integer",
                        "format": "int32",
                        "nullable": true
                    },
                    "preloadImageUrl": {
                        "type": "string",
                        "nullable": true
                    },
                    "sizeId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "versionId": {
                        "type": "integer",
                        "format": "int32"
                    }
                },
                "additionalProperties": false
            },
            "CreativeDto": {
                "required": [
                    "elements",
                    "fill",
                    "gifExport",
                    "guidelines",
                    "height",
                    "id",
                    "loops",
                    "preloadImage",
                    "width"
                ],
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string"
                    },
                    "width": {
                        "maximum": 9999999,
                        "minimum": 1,
                        "type": "integer",
                        "format": "int32"
                    },
                    "height": {
                        "maximum": 9999999,
                        "minimum": 1,
                        "type": "integer",
                        "format": "int32"
                    },
                    "fill": {
                        "type": "string"
                    },
                    "loops": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "guidelines": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/GuidelineDto"
                        }
                    },
                    "socialGuide": {
                        "$ref": "#/components/schemas/CreativeSocialGuideDto"
                    },
                    "gifExport": {
                        "$ref": "#/components/schemas/GifExportDto"
                    },
                    "preloadImage": {
                        "$ref": "#/components/schemas/PreloadImageDto"
                    },
                    "startTime": {
                        "maximum": 2000,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    },
                    "stopTime": {
                        "maximum": 2000,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    },
                    "elements": {
                        "type": "array",
                        "items": {
                            "oneOf": [
                                {
                                    "$ref": "#/components/schemas/GroupNodeDto"
                                },
                                {
                                    "$ref": "#/components/schemas/WidgetElementDto"
                                },
                                {
                                    "$ref": "#/components/schemas/ImageElementDto"
                                },
                                {
                                    "$ref": "#/components/schemas/ButtonElementDto"
                                },
                                {
                                    "$ref": "#/components/schemas/TextElementDto"
                                },
                                {
                                    "$ref": "#/components/schemas/RectangleElementDto"
                                },
                                {
                                    "$ref": "#/components/schemas/EllipseElementDto"
                                },
                                {
                                    "$ref": "#/components/schemas/VideoElementDto"
                                }
                            ]
                        }
                    }
                },
                "additionalProperties": false
            },
            "CreativeDtoV2": {
                "required": [
                    "connectedCampaigns",
                    "id",
                    "size",
                    "version"
                ],
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string"
                    },
                    "design": {
                        "$ref": "#/components/schemas/SlimDesignDtoV2"
                    },
                    "size": {
                        "$ref": "#/components/schemas/SlimSizeDtoV2"
                    },
                    "version": {
                        "$ref": "#/components/schemas/SlimVersionDtoV2"
                    },
                    "targetUrl": {
                        "type": "string"
                    },
                    "checksum": {
                        "type": "string"
                    },
                    "approvalStatus": {
                        "enum": [
                            "In progress",
                            "For review",
                            "Not approved",
                            "Approved"
                        ],
                        "type": "string"
                    },
                    "creativeWeights": {
                        "$ref": "#/components/schemas/CreativeWeightsDto"
                    },
                    "connectedCampaigns": {
                        "type": "array",
                        "items": {
                            "type": "string"
                        }
                    }
                },
                "additionalProperties": false
            },
            "CreativeGenerationDto": {
                "required": [
                    "creativeIds",
                    "creativeSetId"
                ],
                "type": "object",
                "properties": {
                    "creativeSetId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "creativeIds": {
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                },
                "additionalProperties": false
            },
            "CreativeSet": {
                "type": "object",
                "properties": {
                    "creativeSetId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "name": {
                        "type": "string",
                        "nullable": true
                    },
                    "sizes": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/Size"
                        },
                        "nullable": true
                    },
                    "versions": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/Version"
                        },
                        "nullable": true
                    },
                    "designs": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/Design"
                        },
                        "nullable": true
                    },
                    "creatives": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/Creative"
                        },
                        "nullable": true
                    }
                },
                "additionalProperties": false
            },
            "CreativeSetBaseProblemDetails": {
                "required": [
                    "errorType",
                    "title"
                ],
                "type": "object",
                "properties": {
                    "errorType": {
                        "enum": [
                            "creativeSetDeleted",
                            "creativeSetNotFound"
                        ],
                        "type": "string",
                        "readOnly": true
                    },
                    "title": {
                        "minLength": 1,
                        "type": "string",
                        "default": "An error occurred while processing the request",
                        "readOnly": true
                    },
                    "type": {
                        "type": "string",
                        "nullable": true
                    },
                    "status": {
                        "type": "integer",
                        "format": "int32",
                        "nullable": true
                    },
                    "detail": {
                        "type": "string",
                        "nullable": true
                    },
                    "instance": {
                        "type": "string",
                        "nullable": true
                    }
                },
                "additionalProperties": {}
            },
            "CreativeSetDeletedProblemDetails": {
                "required": [
                    "errorType",
                    "status",
                    "title"
                ],
                "type": "object",
                "allOf": [
                    {
                        "$ref": "#/components/schemas/CreativeSetBaseProblemDetails"
                    }
                ],
                "properties": {
                    "errorType": {
                        "enum": [
                            "creativeSetDeleted",
                            "creativeSetNotFound"
                        ],
                        "type": "string",
                        "default": "creativeSetDeleted",
                        "readOnly": true
                    },
                    "status": {
                        "type": "integer",
                        "format": "int32",
                        "default": 404,
                        "readOnly": true
                    },
                    "title": {
                        "minLength": 1,
                        "type": "string",
                        "default": "CreativeSet has been deleted",
                        "readOnly": true
                    }
                },
                "additionalProperties": {}
            },
            "CreativeSetDtoV2": {
                "required": [
                    "brandId",
                    "creatives",
                    "defaultVersion",
                    "designs",
                    "elements",
                    "id",
                    "images",
                    "name",
                    "sizes",
                    "versions",
                    "videos",
                    "widgets"
                ],
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string"
                    },
                    "brandId": {
                        "type": "string"
                    },
                    "name": {
                        "type": "string"
                    },
                    "stateId": {
                        "type": "string"
                    },
                    "images": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/ImageAssetDtoV2"
                        }
                    },
                    "videos": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/VideoAssetDtoV2"
                        }
                    },
                    "widgets": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/WidgetAssetDtoV2"
                        }
                    },
                    "creatives": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/CreativeDtoV2"
                        }
                    },
                    "designs": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/DesignDtoV2"
                        }
                    },
                    "sizes": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/SizeDtoV2"
                        }
                    },
                    "versions": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/VersionDtoV2"
                        }
                    },
                    "elements": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/ElementDtoV2"
                        }
                    },
                    "defaultVersion": {
                        "$ref": "#/components/schemas/VersionDtoV2"
                    }
                },
                "additionalProperties": false
            },
            "CreativeSetForListView": {
                "type": "object",
                "properties": {
                    "name": {
                        "type": "string",
                        "nullable": true
                    },
                    "brandId": {
                        "type": "string",
                        "nullable": true
                    },
                    "userId": {
                        "type": "string",
                        "nullable": true
                    },
                    "id": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "created": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "modified": {
                        "type": "string",
                        "format": "date-time",
                        "nullable": true
                    },
                    "creativeCount": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "thumbnailUri": {
                        "type": "string",
                        "nullable": true
                    }
                },
                "additionalProperties": false
            },
            "CreativeSetNotFoundProblemDetails": {
                "required": [
                    "errorType",
                    "status"
                ],
                "type": "object",
                "allOf": [
                    {
                        "$ref": "#/components/schemas/CreativeSetBaseProblemDetails"
                    }
                ],
                "properties": {
                    "errorType": {
                        "enum": [
                            "creativeSetDeleted",
                            "creativeSetNotFound"
                        ],
                        "type": "string",
                        "default": "creativeSetDeleted",
                        "readOnly": true
                    },
                    "status": {
                        "type": "integer",
                        "format": "int32",
                        "default": 404,
                        "readOnly": true
                    }
                },
                "additionalProperties": {}
            },
            "CreativeSetShowcaseDtoV2": {
                "required": [
                    "allowedOperations",
                    "brand",
                    "creativeSet",
                    "fonts",
                    "showcaseToken"
                ],
                "type": "object",
                "properties": {
                    "creativeSet": {
                        "$ref": "#/components/schemas/CreativeSetDtoV2"
                    },
                    "allowedOperations": {
                        "type": "array",
                        "items": {
                            "enum": [
                                "updateCreativesetShowcase",
                                "makeComments",
                                "fullAccess",
                                "updateVersions",
                                "setApprovalStatusOnCreatives",
                                "styleTranslations"
                            ],
                            "type": "string"
                        }
                    },
                    "showcaseToken": {
                        "type": "string"
                    },
                    "brand": {
                        "$ref": "#/components/schemas/BrandDto"
                    },
                    "fonts": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/FontMetadataDto"
                        }
                    }
                },
                "additionalProperties": false
            },
            "CreativeSetsForListViewRequest": {
                "type": "object",
                "properties": {
                    "creativeSetIds": {
                        "type": "array",
                        "items": {
                            "type": "string"
                        },
                        "nullable": true
                    }
                },
                "additionalProperties": false
            },
            "CreativeSocialGuideDto": {
                "required": [
                    "guidelines",
                    "network",
                    "overlay",
                    "placement"
                ],
                "type": "object",
                "properties": {
                    "placement": {
                        "enum": [
                            "meta-instagram",
                            "meta-facebook",
                            "meta-messenger",
                            "meta-facebookreels",
                            "meta-instagramreels",
                            "tiktok-default",
                            "tiktok-after-9s",
                            "tiktok-after-9s-with-card",
                            "browse-image",
                            "browse-video",
                            "watch-video",
                            "snapchat-default"
                        ],
                        "type": "string"
                    },
                    "network": {
                        "enum": [
                            "meta",
                            "tiktok",
                            "pinterest",
                            "snapchat"
                        ],
                        "type": "string"
                    },
                    "guidelines": {
                        "type": "boolean"
                    },
                    "overlay": {
                        "type": "boolean"
                    }
                },
                "additionalProperties": false
            },
            "CreativeToExport": {
                "type": "object",
                "properties": {
                    "id": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "width": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "height": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "duration": {
                        "type": "number",
                        "format": "double"
                    },
                    "fps": {
                        "type": "integer",
                        "format": "int32"
                    }
                },
                "additionalProperties": false
            },
            "CreativeWeightsDto": {
                "required": [
                    "creativeChecksum",
                    "creativeId",
                    "failed",
                    "id"
                ],
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string"
                    },
                    "creativeId": {
                        "type": "string"
                    },
                    "creativeChecksum": {
                        "type": "string"
                    },
                    "weights": {
                        "$ref": "#/components/schemas/WeightsDto"
                    },
                    "failed": {
                        "type": "boolean"
                    }
                },
                "additionalProperties": false
            },
            "CustomPropertyBooleanDto": {
                "required": [
                    "label",
                    "name",
                    "unit"
                ],
                "type": "object",
                "properties": {
                    "unit": {
                        "enum": [
                            "boolean"
                        ],
                        "type": "string",
                        "default": "boolean",
                        "readOnly": true
                    },
                    "name": {
                        "type": "string"
                    },
                    "label": {
                        "type": "string"
                    },
                    "versionPropertyId": {
                        "type": "string"
                    },
                    "value": {
                        "type": "boolean"
                    }
                },
                "additionalProperties": false
            },
            "CustomPropertyColorDto": {
                "required": [
                    "label",
                    "name",
                    "unit"
                ],
                "type": "object",
                "properties": {
                    "unit": {
                        "enum": [
                            "color"
                        ],
                        "type": "string",
                        "default": "color",
                        "readOnly": true
                    },
                    "name": {
                        "type": "string"
                    },
                    "label": {
                        "type": "string"
                    },
                    "versionPropertyId": {
                        "type": "string"
                    },
                    "value": {
                        "type": "string"
                    }
                },
                "additionalProperties": false
            },
            "CustomPropertyFeedDto": {
                "required": [
                    "label",
                    "name",
                    "unit"
                ],
                "type": "object",
                "properties": {
                    "unit": {
                        "enum": [
                            "feed"
                        ],
                        "type": "string",
                        "default": "feed",
                        "readOnly": true
                    },
                    "name": {
                        "type": "string"
                    },
                    "label": {
                        "type": "string"
                    },
                    "versionPropertyId": {
                        "type": "string"
                    },
                    "value": {
                        "$ref": "#/components/schemas/FeedDto"
                    }
                },
                "additionalProperties": false
            },
            "CustomPropertyFontDto": {
                "required": [
                    "label",
                    "name",
                    "unit"
                ],
                "type": "object",
                "properties": {
                    "unit": {
                        "enum": [
                            "font"
                        ],
                        "type": "string",
                        "default": "font",
                        "readOnly": true
                    },
                    "name": {
                        "type": "string"
                    },
                    "label": {
                        "type": "string"
                    },
                    "versionPropertyId": {
                        "type": "string"
                    },
                    "value": {
                        "$ref": "#/components/schemas/WidgetFontStyleDto"
                    }
                },
                "additionalProperties": false
            },
            "CustomPropertyImageDto": {
                "required": [
                    "label",
                    "name",
                    "unit"
                ],
                "type": "object",
                "properties": {
                    "unit": {
                        "enum": [
                            "image"
                        ],
                        "type": "string",
                        "default": "image",
                        "readOnly": true
                    },
                    "name": {
                        "type": "string"
                    },
                    "label": {
                        "type": "string"
                    },
                    "versionPropertyId": {
                        "type": "string"
                    },
                    "value": {
                        "$ref": "#/components/schemas/WidgetImageDto"
                    }
                },
                "additionalProperties": false
            },
            "CustomPropertyNumberDto": {
                "required": [
                    "label",
                    "name",
                    "unit"
                ],
                "type": "object",
                "properties": {
                    "unit": {
                        "enum": [
                            "number"
                        ],
                        "type": "string",
                        "default": "number",
                        "readOnly": true
                    },
                    "name": {
                        "type": "string"
                    },
                    "label": {
                        "type": "string"
                    },
                    "versionPropertyId": {
                        "type": "string"
                    },
                    "value": {
                        "type": "number",
                        "format": "double"
                    }
                },
                "additionalProperties": false
            },
            "CustomPropertySelectDto": {
                "required": [
                    "label",
                    "name",
                    "unit"
                ],
                "type": "object",
                "properties": {
                    "unit": {
                        "enum": [
                            "select"
                        ],
                        "type": "string",
                        "default": "select",
                        "readOnly": true
                    },
                    "name": {
                        "type": "string"
                    },
                    "label": {
                        "type": "string"
                    },
                    "versionPropertyId": {
                        "type": "string"
                    },
                    "value": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/WidgetSelectionOptionDto"
                        }
                    }
                },
                "additionalProperties": false
            },
            "CustomPropertyTextDto": {
                "required": [
                    "label",
                    "name",
                    "unit"
                ],
                "type": "object",
                "properties": {
                    "unit": {
                        "enum": [
                            "text"
                        ],
                        "type": "string",
                        "default": "text",
                        "readOnly": true
                    },
                    "name": {
                        "type": "string"
                    },
                    "label": {
                        "type": "string"
                    },
                    "versionPropertyId": {
                        "type": "string"
                    },
                    "value": {
                        "type": "string"
                    }
                },
                "additionalProperties": false
            },
            "Design": {
                "type": "object",
                "properties": {
                    "id": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "hasHeavyVideo": {
                        "type": "boolean"
                    }
                },
                "additionalProperties": false
            },
            "DesignDtoV2": {
                "required": [
                    "document",
                    "elements",
                    "hasHeavyVideo",
                    "id",
                    "name"
                ],
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string"
                    },
                    "name": {
                        "type": "string"
                    },
                    "document": {
                        "$ref": "#/components/schemas/DocumentDto"
                    },
                    "elements": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/SlimElementDto"
                        }
                    },
                    "hasHeavyVideo": {
                        "type": "boolean"
                    }
                },
                "additionalProperties": false
            },
            "DocumentDto": {
                "required": [
                    "body",
                    "head"
                ],
                "type": "object",
                "properties": {
                    "head": {
                        "$ref": "#/components/schemas/HeadDto"
                    },
                    "body": {
                        "$ref": "#/components/schemas/BodyDto"
                    }
                },
                "additionalProperties": false
            },
            "DuplicateSizesRequest": {
                "type": "object",
                "properties": {
                    "sizeIds": {
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32"
                        },
                        "nullable": true
                    }
                },
                "additionalProperties": false
            },
            "DuplicateSizesResponse": {
                "type": "object",
                "properties": {
                    "creatives": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/CreativeDtoV2"
                        },
                        "nullable": true
                    },
                    "designs": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/DesignDtoV2"
                        },
                        "nullable": true
                    },
                    "sizes": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/SizeDtoV2"
                        },
                        "nullable": true
                    }
                },
                "additionalProperties": false
            },
            "DuplicateToExistingCreativeSetRequest": {
                "type": "object",
                "properties": {
                    "sourceSizeIds": {
                        "type": "array",
                        "items": {
                            "type": "string"
                        },
                        "nullable": true
                    }
                },
                "additionalProperties": false
            },
            "DuplicateToNewCreativeSetRequest": {
                "type": "object",
                "properties": {
                    "targetFolderId": {
                        "type": "string",
                        "nullable": true
                    },
                    "targetCreativesetName": {
                        "type": "string",
                        "nullable": true
                    },
                    "sourceSizeIds": {
                        "type": "array",
                        "items": {
                            "type": "string"
                        },
                        "nullable": true
                    },
                    "sourceVersionIds": {
                        "type": "array",
                        "items": {
                            "type": "string"
                        },
                        "nullable": true
                    }
                },
                "additionalProperties": false
            },
            "ElementDto": {
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string",
                        "nullable": true
                    },
                    "type": {
                        "enum": [
                            "video",
                            "image",
                            "text",
                            "button",
                            "group",
                            "rectangle",
                            "ellipse",
                            "widget",
                            "bannerflowLibraryWidget",
                            "bannerflowLibraryWidgetInstance"
                        ],
                        "type": "string"
                    },
                    "name": {
                        "type": "string",
                        "nullable": true
                    },
                    "parentId": {
                        "type": "string",
                        "nullable": true
                    },
                    "parentFolderId": {
                        "type": "string",
                        "nullable": true
                    },
                    "created": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "modified": {
                        "type": "string",
                        "format": "date-time",
                        "nullable": true
                    },
                    "properties": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/ElementPropertyDto"
                        },
                        "nullable": true
                    }
                },
                "additionalProperties": false
            },
            "ElementDtoV2": {
                "required": [
                    "id",
                    "name",
                    "properties",
                    "type"
                ],
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string"
                    },
                    "type": {
                        "enum": [
                            "video",
                            "image",
                            "text",
                            "button",
                            "group",
                            "rectangle",
                            "ellipse",
                            "widget",
                            "bannerflowLibraryWidget",
                            "bannerflowLibraryWidgetInstance"
                        ],
                        "type": "string"
                    },
                    "name": {
                        "maxLength": 255,
                        "type": "string"
                    },
                    "properties": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/ElementPropertyDtoV2"
                        }
                    }
                },
                "additionalProperties": false
            },
            "ElementPropertyDto": {
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string",
                        "nullable": true
                    },
                    "name": {
                        "type": "string",
                        "nullable": true
                    },
                    "value": {
                        "type": "string",
                        "nullable": true
                    },
                    "versionPropertyId": {
                        "type": "string",
                        "nullable": true
                    },
                    "unit": {
                        "enum": [
                            "array",
                            "boolean",
                            "color",
                            "feed",
                            "font",
                            "id",
                            "image",
                            "number",
                            "object",
                            "select",
                            "string",
                            "text"
                        ],
                        "type": "string",
                        "nullable": true
                    },
                    "label": {
                        "type": "string",
                        "nullable": true
                    },
                    "clientId": {
                        "type": "string",
                        "format": "uuid"
                    }
                },
                "additionalProperties": false
            },
            "ElementPropertyDtoV2": {
                "required": [
                    "clientId",
                    "id",
                    "name"
                ],
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string"
                    },
                    "name": {
                        "type": "string"
                    },
                    "value": {
                        "type": "string"
                    },
                    "versionPropertyId": {
                        "type": "string"
                    },
                    "unit": {
                        "enum": [
                            "array",
                            "boolean",
                            "color",
                            "feed",
                            "font",
                            "id",
                            "image",
                            "number",
                            "object",
                            "select",
                            "string",
                            "text"
                        ],
                        "type": "string"
                    },
                    "label": {
                        "type": "string"
                    },
                    "clientId": {
                        "type": "string",
                        "format": "uuid"
                    }
                },
                "additionalProperties": false
            },
            "EllipseElementDto": {
                "required": [
                    "__ellipseKind",
                    "actions",
                    "animations",
                    "duration",
                    "filters",
                    "height",
                    "hidden",
                    "id",
                    "locked",
                    "mirrorX",
                    "mirrorY",
                    "opacity",
                    "originX",
                    "originY",
                    "radius",
                    "rotationX",
                    "rotationY",
                    "rotationZ",
                    "scaleX",
                    "scaleY",
                    "states",
                    "time",
                    "width",
                    "x",
                    "y"
                ],
                "type": "object",
                "properties": {
                    "__ellipseKind": {
                        "type": "boolean",
                        "readOnly": true
                    },
                    "states": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/StateDto"
                        }
                    },
                    "masking": {
                        "$ref": "#/components/schemas/MaskDto"
                    },
                    "time": {
                        "maximum": 2000,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    },
                    "duration": {
                        "maximum": 2000,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    },
                    "parentId": {
                        "type": "string"
                    },
                    "parentNodeId": {
                        "type": "string"
                    },
                    "locked": {
                        "type": "boolean"
                    },
                    "hidden": {
                        "type": "boolean"
                    },
                    "ratio": {
                        "type": "number",
                        "format": "double"
                    },
                    "x": {
                        "maximum": 9999999,
                        "minimum": -9999999,
                        "type": "integer",
                        "format": "int32"
                    },
                    "y": {
                        "maximum": 9999999,
                        "minimum": -9999999,
                        "type": "integer",
                        "format": "int32"
                    },
                    "width": {
                        "maximum": 9999999,
                        "minimum": 1,
                        "type": "integer",
                        "format": "int32"
                    },
                    "height": {
                        "maximum": 9999999,
                        "minimum": 1,
                        "type": "integer",
                        "format": "int32"
                    },
                    "fill": {
                        "type": "string"
                    },
                    "originX": {
                        "type": "number",
                        "format": "double"
                    },
                    "originY": {
                        "type": "number",
                        "format": "double"
                    },
                    "rotationX": {
                        "maximum": 9999999,
                        "minimum": -9999999,
                        "type": "number",
                        "format": "double"
                    },
                    "rotationY": {
                        "maximum": 9999999,
                        "minimum": -9999999,
                        "type": "number",
                        "format": "double"
                    },
                    "rotationZ": {
                        "maximum": 9999999,
                        "minimum": -9999999,
                        "type": "number",
                        "format": "double"
                    },
                    "radius": {
                        "$ref": "#/components/schemas/RadiusDto"
                    },
                    "opacity": {
                        "maximum": 1,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    },
                    "mirrorX": {
                        "type": "boolean"
                    },
                    "mirrorY": {
                        "type": "boolean"
                    },
                    "scaleX": {
                        "type": "number",
                        "format": "double"
                    },
                    "scaleY": {
                        "type": "number",
                        "format": "double"
                    },
                    "shadows": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/ShadowDto"
                        }
                    },
                    "border": {
                        "$ref": "#/components/schemas/BorderDto"
                    },
                    "animations": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/AnimationDto"
                        }
                    },
                    "actions": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/ActionDto"
                        }
                    },
                    "filters": {
                        "$ref": "#/components/schemas/FilterDto"
                    },
                    "id": {
                        "type": "string"
                    }
                },
                "additionalProperties": false
            },
            "ExportVideoRequest": {
                "type": "object",
                "properties": {
                    "creativeSetId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "creatives": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/CreativeToExport"
                        },
                        "nullable": true
                    }
                },
                "additionalProperties": false
            },
            "FeedDto": {
                "required": [
                    "fallback",
                    "id",
                    "path",
                    "step",
                    "type"
                ],
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string"
                    },
                    "path": {
                        "type": "string"
                    },
                    "step": {
                        "$ref": "#/components/schemas/FeedStepDto"
                    },
                    "fallback": {
                        "type": "string"
                    },
                    "type": {
                        "enum": [
                            "text",
                            "number",
                            "image",
                            "video"
                        ],
                        "type": "string"
                    }
                },
                "additionalProperties": false
            },
            "FeedOrFolderDto": {
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string",
                        "nullable": true
                    },
                    "name": {
                        "type": "string",
                        "nullable": true
                    },
                    "isBroken": {
                        "type": "boolean",
                        "nullable": true
                    },
                    "children": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/FeedOrFolderDto"
                        },
                        "nullable": true
                    }
                },
                "additionalProperties": false
            },
            "FeedStepDto": {
                "required": [
                    "occurrence",
                    "size",
                    "start"
                ],
                "type": "object",
                "properties": {
                    "occurrence": {
                        "enum": [
                            "none",
                            "loop"
                        ],
                        "type": "string"
                    },
                    "size": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "start": {
                        "type": "integer",
                        "format": "int32"
                    }
                },
                "additionalProperties": false
            },
            "FilterDto": {
                "type": "object",
                "properties": {
                    "blur": {
                        "$ref": "#/components/schemas/FilterValueDtoMax100"
                    },
                    "contrast": {
                        "$ref": "#/components/schemas/FilterValueDtoMax200"
                    },
                    "invert": {
                        "$ref": "#/components/schemas/FilterValueDtoMax100"
                    },
                    "saturate": {
                        "$ref": "#/components/schemas/FilterValueDtoMax200"
                    },
                    "grayscale": {
                        "$ref": "#/components/schemas/FilterValueDtoMax100"
                    },
                    "sepia": {
                        "$ref": "#/components/schemas/FilterValueDtoMax100"
                    }
                },
                "additionalProperties": false
            },
            "FilterValueDtoMax100": {
                "type": "object",
                "properties": {
                    "value": {
                        "maximum": 100,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    }
                },
                "additionalProperties": false
            },
            "FilterValueDtoMax200": {
                "type": "object",
                "properties": {
                    "value": {
                        "maximum": 200,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    }
                },
                "additionalProperties": false
            },
            "FontMetadataDto": {
                "required": [
                    "brandIds",
                    "createdAt",
                    "fontStyles",
                    "id",
                    "name"
                ],
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string"
                    },
                    "name": {
                        "type": "string"
                    },
                    "createdAt": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "deletedAt": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "fontStyles": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/FontStyleDto"
                        }
                    },
                    "brandIds": {
                        "type": "array",
                        "items": {
                            "type": "string"
                        }
                    }
                },
                "additionalProperties": false
            },
            "FontStyleDto": {
                "required": [
                    "createdAt",
                    "fontUrl",
                    "id",
                    "italic",
                    "name",
                    "unicodeGlyphs",
                    "weight"
                ],
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string"
                    },
                    "name": {
                        "type": "string"
                    },
                    "fontUrl": {
                        "type": "string"
                    },
                    "uploadedFontName": {
                        "type": "string"
                    },
                    "fontFamilyId": {
                        "type": "string"
                    },
                    "createdAt": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "deletedAt": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "italic": {
                        "type": "boolean"
                    },
                    "weight": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "unicodeGlyphs": {
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                },
                "additionalProperties": false
            },
            "FrameDto": {
                "required": [
                    "time"
                ],
                "type": "object",
                "properties": {
                    "time": {
                        "maximum": 2000,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    },
                    "duration": {
                        "maximum": 2000,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    }
                },
                "additionalProperties": false
            },
            "GetCreativeSetAndCreativeCountByBrandIdQueryResponse": {
                "type": "object",
                "properties": {
                    "brandId": {
                        "type": "string",
                        "nullable": true
                    },
                    "creativeSetCount": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "creativeCount": {
                        "type": "integer",
                        "format": "int32"
                    }
                },
                "additionalProperties": false
            },
            "GetCreativeSetSizesByBrandIdResponse": {
                "type": "object",
                "properties": {
                    "sizes": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/ResponseSize"
                        },
                        "nullable": true
                    }
                },
                "additionalProperties": false
            },
            "GetCreativeSetsByBrandIdAndNameQueryResponse": {
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string",
                        "nullable": true
                    },
                    "name": {
                        "type": "string",
                        "nullable": true
                    }
                },
                "additionalProperties": false
            },
            "GetCreativeSetsByIdsQueryV2": {
                "type": "object",
                "properties": {
                    "brandId": {
                        "type": "string",
                        "nullable": true
                    },
                    "ids": {
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32"
                        },
                        "nullable": true
                    }
                },
                "additionalProperties": false
            },
            "GetCreativeSetsByIdsResponse": {
                "type": "object",
                "properties": {
                    "correlationId": {
                        "type": "string",
                        "format": "uuid"
                    },
                    "creativeSets": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/CreativeSet"
                        },
                        "nullable": true
                    }
                },
                "additionalProperties": false
            },
            "GetCreativeSetsMetadataQuery": {
                "type": "object",
                "properties": {
                    "ids": {
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32"
                        },
                        "nullable": true
                    }
                },
                "additionalProperties": false
            },
            "GetCreativesMetadataQuery": {
                "type": "object",
                "properties": {
                    "ids": {
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32"
                        },
                        "nullable": true
                    }
                },
                "additionalProperties": false
            },
            "GetLocalizationIdsByBrandIdQueryResponse": {
                "type": "object",
                "properties": {
                    "localizationIds": {
                        "type": "array",
                        "items": {
                            "type": "string"
                        },
                        "nullable": true
                    }
                },
                "additionalProperties": false
            },
            "GifExportDto": {
                "required": [
                    "frames",
                    "show"
                ],
                "type": "object",
                "properties": {
                    "frames": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/FrameDto"
                        }
                    },
                    "show": {
                        "type": "boolean"
                    }
                },
                "additionalProperties": false
            },
            "GraphQlQuery": {
                "type": "object",
                "properties": {
                    "operationName": {
                        "type": "string",
                        "nullable": true
                    },
                    "query": {
                        "type": "string",
                        "nullable": true
                    },
                    "serializedVariables": {
                        "type": "string",
                        "nullable": true,
                        "readOnly": true
                    },
                    "variables": {
                        "type": "object",
                        "additionalProperties": {
                            "nullable": true
                        },
                        "nullable": true
                    }
                },
                "additionalProperties": false
            },
            "GroupNodeDto": {
                "required": [
                    "__groupKind",
                    "hidden",
                    "id",
                    "locked"
                ],
                "type": "object",
                "properties": {
                    "__groupKind": {
                        "type": "boolean",
                        "readOnly": true
                    },
                    "parentNodeId": {
                        "type": "string"
                    },
                    "locked": {
                        "type": "boolean"
                    },
                    "hidden": {
                        "type": "boolean"
                    },
                    "id": {
                        "type": "string"
                    }
                },
                "additionalProperties": false
            },
            "GuidelineDto": {
                "required": [
                    "id",
                    "position",
                    "preview",
                    "type"
                ],
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string"
                    },
                    "position": {
                        "$ref": "#/components/schemas/PositionDto"
                    },
                    "type": {
                        "enum": [
                            "vertical",
                            "horizontal"
                        ],
                        "type": "string"
                    },
                    "preview": {
                        "type": "boolean"
                    }
                },
                "additionalProperties": false
            },
            "HeadDto": {
                "required": [
                    "asset",
                    "elements"
                ],
                "type": "object",
                "properties": {
                    "asset": {
                        "$ref": "#/components/schemas/AssetDto"
                    },
                    "elements": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/HeadElementDto"
                        }
                    }
                },
                "additionalProperties": false
            },
            "HeadElementDto": {
                "required": [
                    "id",
                    "type"
                ],
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string"
                    },
                    "type": {
                        "enum": [
                            "creative",
                            "rectangle",
                            "text",
                            "image",
                            "video",
                            "group",
                            "selection",
                            "any",
                            "ellipse",
                            "button",
                            "widget",
                            "bannerflowLibraryWidget",
                            "bannerflowLibraryWidgetInstance"
                        ],
                        "type": "string"
                    }
                },
                "additionalProperties": false
            },
            "ImageAssetDataDto": {
                "required": [
                    "height",
                    "id",
                    "src",
                    "width"
                ],
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string"
                    },
                    "name": {
                        "type": "string"
                    },
                    "src": {
                        "type": "string"
                    },
                    "width": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "height": {
                        "type": "integer",
                        "format": "int32"
                    }
                },
                "additionalProperties": false
            },
            "ImageAssetDto": {
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string",
                        "nullable": true
                    },
                    "name": {
                        "type": "string",
                        "nullable": true
                    },
                    "original": {
                        "$ref": "#/components/schemas/ImageDto"
                    },
                    "thumbnail": {
                        "$ref": "#/components/schemas/ImageDto"
                    },
                    "animatedThumbnail": {
                        "$ref": "#/components/schemas/ImageDto"
                    },
                    "state": {
                        "enum": [
                            "Unknown",
                            "Created"
                        ],
                        "type": "string"
                    },
                    "fileSize": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "created": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "modified": {
                        "type": "string",
                        "format": "date-time",
                        "nullable": true
                    },
                    "isGenAi": {
                        "type": "boolean"
                    }
                },
                "additionalProperties": false
            },
            "ImageAssetDtoV2": {
                "required": [
                    "created",
                    "fileSize",
                    "id",
                    "name",
                    "original",
                    "thumbnail"
                ],
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string"
                    },
                    "name": {
                        "type": "string"
                    },
                    "original": {
                        "$ref": "#/components/schemas/ImageDto"
                    },
                    "thumbnail": {
                        "$ref": "#/components/schemas/ImageDto"
                    },
                    "animatedThumbnail": {
                        "$ref": "#/components/schemas/ImageDto"
                    },
                    "fileSize": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "created": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "modified": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "isGenAi": {
                        "type": "boolean"
                    }
                },
                "additionalProperties": false
            },
            "ImageDto": {
                "required": [
                    "height",
                    "url",
                    "width"
                ],
                "type": "object",
                "properties": {
                    "url": {
                        "maxLength": 2083,
                        "minLength": 1,
                        "type": "string"
                    },
                    "width": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "height": {
                        "type": "integer",
                        "format": "int32"
                    }
                },
                "additionalProperties": false
            },
            "ImageElementDto": {
                "required": [
                    "__imageKind",
                    "actions",
                    "animations",
                    "duration",
                    "filters",
                    "height",
                    "hidden",
                    "id",
                    "imageSettings",
                    "locked",
                    "mirrorX",
                    "mirrorY",
                    "opacity",
                    "originX",
                    "originY",
                    "radius",
                    "rotationX",
                    "rotationY",
                    "rotationZ",
                    "scaleX",
                    "scaleY",
                    "states",
                    "time",
                    "width",
                    "x",
                    "y"
                ],
                "type": "object",
                "properties": {
                    "__imageKind": {
                        "type": "boolean",
                        "readOnly": true
                    },
                    "imageAssetId": {
                        "type": "string"
                    },
                    "imageSettings": {
                        "$ref": "#/components/schemas/ImageSettingsDto"
                    },
                    "feed": {
                        "$ref": "#/components/schemas/FeedDto"
                    },
                    "states": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/StateDto"
                        }
                    },
                    "masking": {
                        "$ref": "#/components/schemas/MaskDto"
                    },
                    "time": {
                        "maximum": 2000,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    },
                    "duration": {
                        "maximum": 2000,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    },
                    "parentId": {
                        "type": "string"
                    },
                    "parentNodeId": {
                        "type": "string"
                    },
                    "locked": {
                        "type": "boolean"
                    },
                    "hidden": {
                        "type": "boolean"
                    },
                    "ratio": {
                        "type": "number",
                        "format": "double"
                    },
                    "x": {
                        "maximum": 9999999,
                        "minimum": -9999999,
                        "type": "integer",
                        "format": "int32"
                    },
                    "y": {
                        "maximum": 9999999,
                        "minimum": -9999999,
                        "type": "integer",
                        "format": "int32"
                    },
                    "width": {
                        "maximum": 9999999,
                        "minimum": 1,
                        "type": "integer",
                        "format": "int32"
                    },
                    "height": {
                        "maximum": 9999999,
                        "minimum": 1,
                        "type": "integer",
                        "format": "int32"
                    },
                    "fill": {
                        "type": "string"
                    },
                    "originX": {
                        "type": "number",
                        "format": "double"
                    },
                    "originY": {
                        "type": "number",
                        "format": "double"
                    },
                    "rotationX": {
                        "maximum": 9999999,
                        "minimum": -9999999,
                        "type": "number",
                        "format": "double"
                    },
                    "rotationY": {
                        "maximum": 9999999,
                        "minimum": -9999999,
                        "type": "number",
                        "format": "double"
                    },
                    "rotationZ": {
                        "maximum": 9999999,
                        "minimum": -9999999,
                        "type": "number",
                        "format": "double"
                    },
                    "radius": {
                        "$ref": "#/components/schemas/RadiusDto"
                    },
                    "opacity": {
                        "maximum": 1,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    },
                    "mirrorX": {
                        "type": "boolean"
                    },
                    "mirrorY": {
                        "type": "boolean"
                    },
                    "scaleX": {
                        "type": "number",
                        "format": "double"
                    },
                    "scaleY": {
                        "type": "number",
                        "format": "double"
                    },
                    "shadows": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/ShadowDto"
                        }
                    },
                    "border": {
                        "$ref": "#/components/schemas/BorderDto"
                    },
                    "animations": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/AnimationDto"
                        }
                    },
                    "actions": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/ActionDto"
                        }
                    },
                    "filters": {
                        "$ref": "#/components/schemas/FilterDto"
                    },
                    "id": {
                        "type": "string"
                    }
                },
                "additionalProperties": false
            },
            "ImageSettingsDto": {
                "required": [
                    "sizeMode",
                    "x",
                    "y"
                ],
                "type": "object",
                "properties": {
                    "x": {
                        "maximum": 1,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    },
                    "y": {
                        "maximum": 1,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    },
                    "quality": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "sizeMode": {
                        "enum": [
                            "fit",
                            "stretch",
                            "crop"
                        ],
                        "type": "string"
                    },
                    "highDpi": {
                        "type": "boolean"
                    }
                },
                "additionalProperties": false
            },
            "KeyframeDto": {
                "required": [
                    "duration",
                    "id",
                    "time"
                ],
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string"
                    },
                    "stateId": {
                        "type": "string"
                    },
                    "time": {
                        "maximum": 2000,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    },
                    "duration": {
                        "maximum": 2000,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    },
                    "timingFunction": {
                        "type": "string"
                    }
                },
                "additionalProperties": false
            },
            "LoadAssetDto": {
                "required": [
                    "name",
                    "parentUrl",
                    "type",
                    "url",
                    "weight"
                ],
                "type": "object",
                "properties": {
                    "weight": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "name": {
                        "type": "string"
                    },
                    "url": {
                        "type": "string"
                    },
                    "parentUrl": {
                        "type": "string"
                    },
                    "type": {
                        "enum": [
                            "Application",
                            "Audio",
                            "Font",
                            "Image",
                            "Text",
                            "Unknown",
                            "Video"
                        ],
                        "type": "string"
                    }
                },
                "additionalProperties": false
            },
            "LoadDto": {
                "required": [
                    "assets",
                    "totalWeight"
                ],
                "type": "object",
                "properties": {
                    "totalWeight": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "assets": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/LoadAssetDto"
                        }
                    }
                },
                "additionalProperties": false
            },
            "LocalizationDto": {
                "required": [
                    "cultureCode",
                    "cultureCountry",
                    "cultureLanguage",
                    "cultureName",
                    "id",
                    "isDefault",
                    "name"
                ],
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string"
                    },
                    "name": {
                        "type": "string"
                    },
                    "cultureCode": {
                        "type": "string"
                    },
                    "cultureName": {
                        "type": "string"
                    },
                    "cultureLanguage": {
                        "type": "string"
                    },
                    "cultureCountry": {
                        "type": "string"
                    },
                    "targetUrl": {
                        "type": "string"
                    },
                    "isDefault": {
                        "type": "boolean"
                    }
                },
                "additionalProperties": false
            },
            "MaskDto": {
                "required": [
                    "isMask"
                ],
                "type": "object",
                "properties": {
                    "isMask": {
                        "type": "boolean"
                    },
                    "elementId": {
                        "type": "string"
                    }
                },
                "additionalProperties": false
            },
            "OperationAnimationDto": {
                "required": [
                    "duration",
                    "timingFunction"
                ],
                "type": "object",
                "properties": {
                    "timingFunction": {
                        "enum": [
                            "linear",
                            "easeInExpo",
                            "easeOutExpo",
                            "easeInOutExpo",
                            "easeInQuad",
                            "easeOutQuad",
                            "easeInOutQuad",
                            "easeInCubic",
                            "easeOutCubic",
                            "easeInOutCubic",
                            "easeInQuart",
                            "easeOutQuart",
                            "easeInOutQuart",
                            "easeInQuint",
                            "easeOutQuint",
                            "easeInOutQuint",
                            "easeInElastic",
                            "easeOutElastic",
                            "easeInOutElastic",
                            "easeInBack",
                            "easeOutBack",
                            "easeInOutBack",
                            "easeInBounce",
                            "easeOutBounce",
                            "easeInOutBounce"
                        ],
                        "type": "string"
                    },
                    "duration": {
                        "type": "number",
                        "format": "double"
                    }
                },
                "additionalProperties": false
            },
            "OperationDto": {
                "required": [
                    "method"
                ],
                "type": "object",
                "properties": {
                    "method": {
                        "enum": [
                            "SET_STATE",
                            "REMOVE_STATE",
                            "CLEAR_STATES",
                            "OPEN_URL"
                        ],
                        "type": "string"
                    },
                    "value": {
                        "type": "string"
                    },
                    "target": {
                        "type": "string"
                    },
                    "animation": {
                        "$ref": "#/components/schemas/OperationAnimationDto"
                    }
                },
                "additionalProperties": false
            },
            "OptimizationDto": {
                "required": [
                    "enabled"
                ],
                "type": "object",
                "properties": {
                    "enabled": {
                        "type": "boolean"
                    },
                    "quality": {
                        "type": "integer",
                        "format": "int32"
                    }
                },
                "additionalProperties": false
            },
            "PaddingDto": {
                "required": [
                    "bottom",
                    "left",
                    "right",
                    "top"
                ],
                "type": "object",
                "properties": {
                    "top": {
                        "maximum": 5000,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    },
                    "left": {
                        "maximum": 5000,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    },
                    "right": {
                        "maximum": 5000,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    },
                    "bottom": {
                        "maximum": 5000,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    }
                },
                "additionalProperties": false
            },
            "PaletteDto": {
                "required": [
                    "swatch"
                ],
                "type": "object",
                "properties": {
                    "name": {
                        "type": "string"
                    },
                    "swatch": {
                        "type": "array",
                        "items": {
                            "type": "string"
                        }
                    }
                },
                "additionalProperties": false
            },
            "PlaybackButtonDto": {
                "required": [
                    "color",
                    "enabled",
                    "size"
                ],
                "type": "object",
                "properties": {
                    "enabled": {
                        "type": "boolean"
                    },
                    "size": {
                        "maximum": 100,
                        "minimum": 0,
                        "type": "integer",
                        "format": "int32"
                    },
                    "color": {
                        "type": "string"
                    }
                },
                "additionalProperties": false
            },
            "PositionDto": {
                "required": [
                    "x",
                    "y"
                ],
                "type": "object",
                "properties": {
                    "x": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "y": {
                        "type": "integer",
                        "format": "int32"
                    }
                },
                "additionalProperties": false
            },
            "PreloadImageDto": {
                "required": [
                    "format",
                    "frames",
                    "quality"
                ],
                "type": "object",
                "properties": {
                    "quality": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "format": {
                        "enum": [
                            "jpg",
                            "gif",
                            "png"
                        ],
                        "type": "string"
                    },
                    "frames": {
                        "type": "array",
                        "items": {
                            "type": "number",
                            "format": "double"
                        }
                    }
                },
                "additionalProperties": false
            },
            "ProblemDetails": {
                "type": "object",
                "properties": {
                    "type": {
                        "type": "string",
                        "nullable": true
                    },
                    "title": {
                        "type": "string",
                        "nullable": true
                    },
                    "status": {
                        "type": "integer",
                        "format": "int32",
                        "nullable": true
                    },
                    "detail": {
                        "type": "string",
                        "nullable": true
                    },
                    "instance": {
                        "type": "string",
                        "nullable": true
                    }
                },
                "additionalProperties": {}
            },
            "RadiusDto": {
                "required": [
                    "bottomLeft",
                    "bottomRight",
                    "topLeft",
                    "topRight",
                    "type"
                ],
                "type": "object",
                "properties": {
                    "type": {
                        "enum": [
                            "joint",
                            "separate"
                        ],
                        "type": "string"
                    },
                    "topLeft": {
                        "maximum": 9999999,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    },
                    "topRight": {
                        "maximum": 9999999,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    },
                    "bottomRight": {
                        "maximum": 9999999,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    },
                    "bottomLeft": {
                        "maximum": 9999999,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    }
                },
                "additionalProperties": false
            },
            "RectangleElementDto": {
                "required": [
                    "__rectangleKind",
                    "actions",
                    "animations",
                    "duration",
                    "filters",
                    "height",
                    "hidden",
                    "id",
                    "locked",
                    "mirrorX",
                    "mirrorY",
                    "opacity",
                    "originX",
                    "originY",
                    "radius",
                    "rotationX",
                    "rotationY",
                    "rotationZ",
                    "scaleX",
                    "scaleY",
                    "states",
                    "time",
                    "width",
                    "x",
                    "y"
                ],
                "type": "object",
                "properties": {
                    "__rectangleKind": {
                        "type": "boolean",
                        "readOnly": true
                    },
                    "states": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/StateDto"
                        }
                    },
                    "masking": {
                        "$ref": "#/components/schemas/MaskDto"
                    },
                    "time": {
                        "maximum": 2000,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    },
                    "duration": {
                        "maximum": 2000,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    },
                    "parentId": {
                        "type": "string"
                    },
                    "parentNodeId": {
                        "type": "string"
                    },
                    "locked": {
                        "type": "boolean"
                    },
                    "hidden": {
                        "type": "boolean"
                    },
                    "ratio": {
                        "type": "number",
                        "format": "double"
                    },
                    "x": {
                        "maximum": 9999999,
                        "minimum": -9999999,
                        "type": "integer",
                        "format": "int32"
                    },
                    "y": {
                        "maximum": 9999999,
                        "minimum": -9999999,
                        "type": "integer",
                        "format": "int32"
                    },
                    "width": {
                        "maximum": 9999999,
                        "minimum": 1,
                        "type": "integer",
                        "format": "int32"
                    },
                    "height": {
                        "maximum": 9999999,
                        "minimum": 1,
                        "type": "integer",
                        "format": "int32"
                    },
                    "fill": {
                        "type": "string"
                    },
                    "originX": {
                        "type": "number",
                        "format": "double"
                    },
                    "originY": {
                        "type": "number",
                        "format": "double"
                    },
                    "rotationX": {
                        "maximum": 9999999,
                        "minimum": -9999999,
                        "type": "number",
                        "format": "double"
                    },
                    "rotationY": {
                        "maximum": 9999999,
                        "minimum": -9999999,
                        "type": "number",
                        "format": "double"
                    },
                    "rotationZ": {
                        "maximum": 9999999,
                        "minimum": -9999999,
                        "type": "number",
                        "format": "double"
                    },
                    "radius": {
                        "$ref": "#/components/schemas/RadiusDto"
                    },
                    "opacity": {
                        "maximum": 1,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    },
                    "mirrorX": {
                        "type": "boolean"
                    },
                    "mirrorY": {
                        "type": "boolean"
                    },
                    "scaleX": {
                        "type": "number",
                        "format": "double"
                    },
                    "scaleY": {
                        "type": "number",
                        "format": "double"
                    },
                    "shadows": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/ShadowDto"
                        }
                    },
                    "border": {
                        "$ref": "#/components/schemas/BorderDto"
                    },
                    "animations": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/AnimationDto"
                        }
                    },
                    "actions": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/ActionDto"
                        }
                    },
                    "filters": {
                        "$ref": "#/components/schemas/FilterDto"
                    },
                    "id": {
                        "type": "string"
                    }
                },
                "additionalProperties": false
            },
            "ResponseSize": {
                "type": "object",
                "properties": {
                    "id": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "width": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "height": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "name": {
                        "type": "string",
                        "nullable": true
                    }
                },
                "additionalProperties": false
            },
            "SettingDto": {
                "required": [
                    "key",
                    "value"
                ],
                "type": "object",
                "properties": {
                    "key": {
                        "type": "string"
                    },
                    "name": {
                        "type": "string"
                    },
                    "value": {
                        "maximum": 9999999,
                        "minimum": -9999999,
                        "type": "number",
                        "format": "double"
                    }
                },
                "additionalProperties": false
            },
            "ShadowDto": {
                "required": [
                    "blur",
                    "color",
                    "offsetX",
                    "offsetY",
                    "spread"
                ],
                "type": "object",
                "properties": {
                    "offsetX": {
                        "maximum": 30000,
                        "minimum": -30000,
                        "type": "number",
                        "format": "double"
                    },
                    "offsetY": {
                        "maximum": 30000,
                        "minimum": -30000,
                        "type": "number",
                        "format": "double"
                    },
                    "color": {
                        "type": "string"
                    },
                    "blur": {
                        "maximum": 250,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    },
                    "spread": {
                        "type": "number",
                        "format": "double"
                    }
                },
                "additionalProperties": false
            },
            "ShowcaseBaseProblemDetails": {
                "required": [
                    "errorType"
                ],
                "type": "object",
                "properties": {
                    "errorType": {
                        "enum": [
                            "showcaseDisabled",
                            "showcaseExpired",
                            "showcaseNotFound"
                        ],
                        "type": "string",
                        "readOnly": true
                    },
                    "type": {
                        "type": "string",
                        "nullable": true
                    },
                    "title": {
                        "type": "string",
                        "nullable": true
                    },
                    "status": {
                        "type": "integer",
                        "format": "int32",
                        "nullable": true
                    },
                    "detail": {
                        "type": "string",
                        "nullable": true
                    },
                    "instance": {
                        "type": "string",
                        "nullable": true
                    }
                },
                "additionalProperties": {}
            },
            "ShowcaseDisabledProblemDetails": {
                "required": [
                    "brandId",
                    "creativeSetId",
                    "errorType",
                    "status"
                ],
                "type": "object",
                "allOf": [
                    {
                        "$ref": "#/components/schemas/ShowcaseBaseProblemDetails"
                    }
                ],
                "properties": {
                    "creativeSetId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "brandId": {
                        "minLength": 1,
                        "type": "string"
                    },
                    "errorType": {
                        "enum": [
                            "showcaseDisabled"
                        ],
                        "type": "string",
                        "default": "showcaseDisabled",
                        "readOnly": true
                    },
                    "status": {
                        "enum": [
                            404
                        ],
                        "type": "integer",
                        "format": "int32",
                        "default": 404,
                        "readOnly": true
                    }
                },
                "additionalProperties": {}
            },
            "ShowcaseExpiredProblemDetails": {
                "required": [
                    "brandId",
                    "creativeSetId",
                    "errorType",
                    "status"
                ],
                "type": "object",
                "allOf": [
                    {
                        "$ref": "#/components/schemas/ShowcaseBaseProblemDetails"
                    }
                ],
                "properties": {
                    "creativeSetId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "brandId": {
                        "minLength": 1,
                        "type": "string"
                    },
                    "errorType": {
                        "enum": [
                            "showcaseExpired"
                        ],
                        "type": "string",
                        "default": "showcaseExpired",
                        "readOnly": true
                    },
                    "status": {
                        "enum": [
                            404
                        ],
                        "type": "integer",
                        "format": "int32",
                        "default": 404,
                        "readOnly": true
                    }
                },
                "additionalProperties": {}
            },
            "ShowcaseInvalidKeyProblemDetails": {
                "required": [
                    "errorType",
                    "status"
                ],
                "type": "object",
                "allOf": [
                    {
                        "$ref": "#/components/schemas/ShowcaseValidationBaseProblemDetails"
                    }
                ],
                "properties": {
                    "errorType": {
                        "enum": [
                            "showcaseInvalidKey"
                        ],
                        "type": "string",
                        "default": "showcaseInvalidKey",
                        "readOnly": true
                    },
                    "status": {
                        "enum": [
                            400
                        ],
                        "type": "integer",
                        "format": "int32",
                        "default": 400,
                        "readOnly": true
                    }
                },
                "additionalProperties": {}
            },
            "ShowcaseNotFoundProblemDetails": {
                "required": [
                    "errorType",
                    "status"
                ],
                "type": "object",
                "allOf": [
                    {
                        "$ref": "#/components/schemas/ShowcaseBaseProblemDetails"
                    }
                ],
                "properties": {
                    "errorType": {
                        "enum": [
                            "showcaseNotFound"
                        ],
                        "type": "string",
                        "default": "showcaseNotFound",
                        "readOnly": true
                    },
                    "status": {
                        "enum": [
                            404
                        ],
                        "type": "integer",
                        "format": "int32",
                        "default": 404,
                        "readOnly": true
                    }
                },
                "additionalProperties": {}
            },
            "ShowcaseValidationBaseProblemDetails": {
                "required": [
                    "errorType"
                ],
                "type": "object",
                "properties": {
                    "errorType": {
                        "enum": [
                            "showcaseInvalidKey"
                        ],
                        "type": "string",
                        "readOnly": true
                    },
                    "errors": {
                        "type": "object",
                        "additionalProperties": {
                            "type": "array",
                            "items": {
                                "type": "string"
                            }
                        },
                        "nullable": true
                    },
                    "type": {
                        "type": "string",
                        "nullable": true
                    },
                    "title": {
                        "type": "string",
                        "nullable": true
                    },
                    "status": {
                        "type": "integer",
                        "format": "int32",
                        "nullable": true
                    },
                    "detail": {
                        "type": "string",
                        "nullable": true
                    },
                    "instance": {
                        "type": "string",
                        "nullable": true
                    }
                },
                "additionalProperties": {}
            },
            "Size": {
                "type": "object",
                "properties": {
                    "id": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "width": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "height": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "name": {
                        "type": "string",
                        "nullable": true
                    }
                },
                "additionalProperties": false
            },
            "SizeDtoV2": {
                "required": [
                    "height",
                    "id",
                    "width"
                ],
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string"
                    },
                    "width": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "height": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "name": {
                        "type": "string"
                    }
                },
                "additionalProperties": false
            },
            "SizeFormatDto": {
                "required": [
                    "height",
                    "id",
                    "width"
                ],
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string"
                    },
                    "width": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "height": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "name": {
                        "type": "string"
                    }
                },
                "additionalProperties": false
            },
            "SlimDesignDtoV2": {
                "required": [
                    "id"
                ],
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string"
                    }
                },
                "additionalProperties": false
            },
            "SlimElementDto": {
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string",
                        "nullable": true
                    }
                },
                "additionalProperties": false
            },
            "SlimLocalizationDto": {
                "required": [
                    "id"
                ],
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string"
                    }
                },
                "additionalProperties": false
            },
            "SlimSizeDtoV2": {
                "required": [
                    "id"
                ],
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string"
                    }
                },
                "additionalProperties": false
            },
            "SlimVersionDtoV2": {
                "required": [
                    "id"
                ],
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string"
                    }
                },
                "additionalProperties": false
            },
            "StateDto": {
                "required": [
                    "id"
                ],
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string"
                    },
                    "name": {
                        "type": "string"
                    },
                    "ratio": {
                        "type": "number",
                        "format": "double"
                    },
                    "x": {
                        "oneOf": [
                            {
                                "type": "integer",
                                "format": "int32"
                            },
                            {
                                "type": "string"
                            }
                        ]
                    },
                    "y": {
                        "oneOf": [
                            {
                                "type": "integer",
                                "format": "int32"
                            },
                            {
                                "type": "string"
                            }
                        ]
                    },
                    "width": {
                        "maximum": 9999999,
                        "minimum": 1,
                        "type": "integer",
                        "format": "int32"
                    },
                    "height": {
                        "maximum": 9999999,
                        "minimum": 1,
                        "type": "integer",
                        "format": "int32"
                    },
                    "fill": {
                        "type": "string"
                    },
                    "originX": {
                        "type": "number",
                        "format": "double"
                    },
                    "originY": {
                        "type": "number",
                        "format": "double"
                    },
                    "rotationX": {
                        "oneOf": [
                            {
                                "type": "number",
                                "format": "double"
                            },
                            {
                                "type": "string"
                            }
                        ]
                    },
                    "rotationY": {
                        "oneOf": [
                            {
                                "type": "number",
                                "format": "double"
                            },
                            {
                                "type": "string"
                            }
                        ]
                    },
                    "rotationZ": {
                        "oneOf": [
                            {
                                "type": "number",
                                "format": "double"
                            },
                            {
                                "type": "string"
                            }
                        ]
                    },
                    "radius": {
                        "$ref": "#/components/schemas/RadiusDto"
                    },
                    "opacity": {
                        "maximum": 1,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    },
                    "mirrorX": {
                        "type": "boolean"
                    },
                    "mirrorY": {
                        "type": "boolean"
                    },
                    "scaleX": {
                        "maximum": 9999999,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    },
                    "scaleY": {
                        "maximum": 9999999,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    },
                    "shadows": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/ShadowDto"
                        }
                    },
                    "border": {
                        "$ref": "#/components/schemas/BorderDto"
                    },
                    "filters": {
                        "$ref": "#/components/schemas/FilterDto"
                    }
                },
                "additionalProperties": false
            },
            "StreamingDto": {
                "required": [
                    "enabled"
                ],
                "type": "object",
                "properties": {
                    "enabled": {
                        "type": "boolean"
                    }
                },
                "additionalProperties": false
            },
            "TextElementDto": {
                "required": [
                    "__textKind",
                    "actions",
                    "animations",
                    "characterSpacing",
                    "characterStyles",
                    "duration",
                    "filters",
                    "fontSize",
                    "height",
                    "hidden",
                    "horizontalAlignment",
                    "id",
                    "lineHeight",
                    "locked",
                    "maxRows",
                    "mirrorX",
                    "mirrorY",
                    "opacity",
                    "originX",
                    "originY",
                    "padding",
                    "radius",
                    "rotationX",
                    "rotationY",
                    "rotationZ",
                    "scaleX",
                    "scaleY",
                    "states",
                    "strikethrough",
                    "textColor",
                    "textOverflow",
                    "time",
                    "underline",
                    "uppercase",
                    "verticalAlignment",
                    "width",
                    "x",
                    "y"
                ],
                "type": "object",
                "properties": {
                    "__textKind": {
                        "type": "boolean",
                        "readOnly": true
                    },
                    "characterStyles": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/CharacterStyleDto"
                        }
                    },
                    "font": {
                        "type": "string"
                    },
                    "textColor": {
                        "type": "string"
                    },
                    "fontSize": {
                        "maximum": 2500,
                        "minimum": 1,
                        "type": "number",
                        "format": "double"
                    },
                    "lineHeight": {
                        "maximum": 9999,
                        "minimum": 0.1,
                        "type": "number",
                        "format": "double"
                    },
                    "characterSpacing": {
                        "maximum": 9999,
                        "minimum": -1,
                        "type": "number",
                        "format": "double"
                    },
                    "uppercase": {
                        "type": "boolean"
                    },
                    "underline": {
                        "type": "boolean"
                    },
                    "strikethrough": {
                        "type": "boolean"
                    },
                    "maxRows": {
                        "maximum": 9999,
                        "minimum": 0,
                        "type": "integer",
                        "format": "int32"
                    },
                    "padding": {
                        "$ref": "#/components/schemas/PaddingDto"
                    },
                    "textOverflow": {
                        "enum": [
                            "shrink",
                            "truncate",
                            "expand",
                            "scroll"
                        ],
                        "type": "string"
                    },
                    "horizontalAlignment": {
                        "enum": [
                            "left",
                            "center",
                            "right",
                            "justify"
                        ],
                        "type": "string"
                    },
                    "verticalAlignment": {
                        "enum": [
                            "top",
                            "middle",
                            "bottom"
                        ],
                        "type": "string"
                    },
                    "textShadows": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/TextShadowDto"
                        }
                    },
                    "states": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/TextStateDto"
                        }
                    },
                    "time": {
                        "maximum": 2000,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    },
                    "duration": {
                        "maximum": 2000,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    },
                    "parentId": {
                        "type": "string"
                    },
                    "parentNodeId": {
                        "type": "string"
                    },
                    "locked": {
                        "type": "boolean"
                    },
                    "hidden": {
                        "type": "boolean"
                    },
                    "ratio": {
                        "type": "number",
                        "format": "double"
                    },
                    "x": {
                        "maximum": 9999999,
                        "minimum": -9999999,
                        "type": "integer",
                        "format": "int32"
                    },
                    "y": {
                        "maximum": 9999999,
                        "minimum": -9999999,
                        "type": "integer",
                        "format": "int32"
                    },
                    "width": {
                        "maximum": 9999999,
                        "minimum": 1,
                        "type": "integer",
                        "format": "int32"
                    },
                    "height": {
                        "maximum": 9999999,
                        "minimum": 1,
                        "type": "integer",
                        "format": "int32"
                    },
                    "fill": {
                        "type": "string"
                    },
                    "originX": {
                        "type": "number",
                        "format": "double"
                    },
                    "originY": {
                        "type": "number",
                        "format": "double"
                    },
                    "rotationX": {
                        "maximum": 9999999,
                        "minimum": -9999999,
                        "type": "number",
                        "format": "double"
                    },
                    "rotationY": {
                        "maximum": 9999999,
                        "minimum": -9999999,
                        "type": "number",
                        "format": "double"
                    },
                    "rotationZ": {
                        "maximum": 9999999,
                        "minimum": -9999999,
                        "type": "number",
                        "format": "double"
                    },
                    "radius": {
                        "$ref": "#/components/schemas/RadiusDto"
                    },
                    "opacity": {
                        "maximum": 1,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    },
                    "mirrorX": {
                        "type": "boolean"
                    },
                    "mirrorY": {
                        "type": "boolean"
                    },
                    "scaleX": {
                        "type": "number",
                        "format": "double"
                    },
                    "scaleY": {
                        "type": "number",
                        "format": "double"
                    },
                    "shadows": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/ShadowDto"
                        }
                    },
                    "border": {
                        "$ref": "#/components/schemas/BorderDto"
                    },
                    "animations": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/AnimationDto"
                        }
                    },
                    "actions": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/ActionDto"
                        }
                    },
                    "filters": {
                        "$ref": "#/components/schemas/FilterDto"
                    },
                    "id": {
                        "type": "string"
                    }
                },
                "additionalProperties": false
            },
            "TextShadowDto": {
                "required": [
                    "blur",
                    "color",
                    "offsetX",
                    "offsetY"
                ],
                "type": "object",
                "properties": {
                    "offsetX": {
                        "maximum": 30000,
                        "minimum": -30000,
                        "type": "number",
                        "format": "double"
                    },
                    "offsetY": {
                        "maximum": 30000,
                        "minimum": -30000,
                        "type": "number",
                        "format": "double"
                    },
                    "blur": {
                        "maximum": 250,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    },
                    "color": {
                        "type": "string"
                    }
                },
                "additionalProperties": false
            },
            "TextStateDto": {
                "required": [
                    "id"
                ],
                "type": "object",
                "properties": {
                    "textColor": {
                        "type": "string"
                    },
                    "id": {
                        "type": "string"
                    },
                    "name": {
                        "type": "string"
                    },
                    "ratio": {
                        "type": "number",
                        "format": "double"
                    },
                    "x": {
                        "oneOf": [
                            {
                                "type": "integer",
                                "format": "int32"
                            },
                            {
                                "type": "string"
                            }
                        ]
                    },
                    "y": {
                        "oneOf": [
                            {
                                "type": "integer",
                                "format": "int32"
                            },
                            {
                                "type": "string"
                            }
                        ]
                    },
                    "width": {
                        "maximum": 9999999,
                        "minimum": 1,
                        "type": "integer",
                        "format": "int32"
                    },
                    "height": {
                        "maximum": 9999999,
                        "minimum": 1,
                        "type": "integer",
                        "format": "int32"
                    },
                    "fill": {
                        "type": "string"
                    },
                    "originX": {
                        "type": "number",
                        "format": "double"
                    },
                    "originY": {
                        "type": "number",
                        "format": "double"
                    },
                    "rotationX": {
                        "oneOf": [
                            {
                                "type": "number",
                                "format": "double"
                            },
                            {
                                "type": "string"
                            }
                        ]
                    },
                    "rotationY": {
                        "oneOf": [
                            {
                                "type": "number",
                                "format": "double"
                            },
                            {
                                "type": "string"
                            }
                        ]
                    },
                    "rotationZ": {
                        "oneOf": [
                            {
                                "type": "number",
                                "format": "double"
                            },
                            {
                                "type": "string"
                            }
                        ]
                    },
                    "radius": {
                        "$ref": "#/components/schemas/RadiusDto"
                    },
                    "opacity": {
                        "maximum": 1,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    },
                    "mirrorX": {
                        "type": "boolean"
                    },
                    "mirrorY": {
                        "type": "boolean"
                    },
                    "scaleX": {
                        "maximum": 9999999,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    },
                    "scaleY": {
                        "maximum": 9999999,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    },
                    "shadows": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/ShadowDto"
                        }
                    },
                    "border": {
                        "$ref": "#/components/schemas/BorderDto"
                    },
                    "filters": {
                        "$ref": "#/components/schemas/FilterDto"
                    }
                },
                "additionalProperties": false
            },
            "Thumbnail": {
                "type": "object",
                "properties": {
                    "url": {
                        "type": "string",
                        "nullable": true
                    },
                    "width": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "height": {
                        "type": "integer",
                        "format": "int32"
                    }
                },
                "additionalProperties": false
            },
            "UpdateCreativeDto": {
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string",
                        "nullable": true
                    },
                    "targetUrl": {
                        "type": "string",
                        "nullable": true
                    },
                    "checksum": {
                        "type": "string",
                        "nullable": true
                    },
                    "approvalStatus": {
                        "type": "string",
                        "nullable": true
                    }
                },
                "additionalProperties": false
            },
            "UpdateCreativeDtoV2": {
                "required": [
                    "checksum",
                    "id"
                ],
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string"
                    },
                    "checksum": {
                        "type": "string"
                    }
                },
                "additionalProperties": false
            },
            "UpdateCreativeSetStateIdDto": {
                "required": [
                    "creativeSetId",
                    "creativesToUpdate"
                ],
                "type": "object",
                "properties": {
                    "creativeSetId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "stateId": {
                        "type": "string"
                    },
                    "creativesToUpdate": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/UpdateCreativeDto"
                        }
                    }
                },
                "additionalProperties": false
            },
            "UpdateDesignDto": {
                "required": [
                    "document",
                    "id"
                ],
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string"
                    },
                    "document": {
                        "$ref": "#/components/schemas/DocumentDto"
                    }
                },
                "additionalProperties": false
            },
            "UpdateDesignsAndVersionsDto": {
                "required": [
                    "creatives",
                    "designs",
                    "elements",
                    "versions"
                ],
                "type": "object",
                "properties": {
                    "elements": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/UpdateElementDto"
                        }
                    },
                    "designs": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/UpdateDesignDto"
                        }
                    },
                    "versions": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/UpdateVersionDto"
                        }
                    },
                    "creatives": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/UpdateCreativeDtoV2"
                        }
                    }
                },
                "additionalProperties": false
            },
            "UpdateElementDto": {
                "required": [
                    "id",
                    "name",
                    "properties",
                    "type"
                ],
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string"
                    },
                    "type": {
                        "enum": [
                            "video",
                            "image",
                            "text",
                            "button",
                            "group",
                            "rectangle",
                            "ellipse",
                            "widget",
                            "bannerflowLibraryWidget",
                            "bannerflowLibraryWidgetInstance"
                        ],
                        "type": "string"
                    },
                    "name": {
                        "maxLength": 250,
                        "type": "string"
                    },
                    "properties": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/UpdateElementPropertyDto"
                        }
                    }
                },
                "additionalProperties": false
            },
            "UpdateElementPropertyDto": {
                "required": [
                    "clientId",
                    "name"
                ],
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string"
                    },
                    "name": {
                        "type": "string"
                    },
                    "value": {
                        "type": "string"
                    },
                    "versionPropertyId": {
                        "type": "string"
                    },
                    "unit": {
                        "enum": [
                            "array",
                            "boolean",
                            "color",
                            "feed",
                            "font",
                            "id",
                            "image",
                            "number",
                            "object",
                            "select",
                            "string",
                            "text"
                        ],
                        "type": "string"
                    },
                    "label": {
                        "type": "string"
                    },
                    "clientId": {
                        "type": "string",
                        "format": "uuid"
                    }
                },
                "additionalProperties": false
            },
            "UpdateVersionDto": {
                "required": [
                    "id",
                    "properties"
                ],
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string"
                    },
                    "properties": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/VersionPropertyDto"
                        }
                    }
                },
                "additionalProperties": false
            },
            "UpdateWidgetAssetDto": {
                "required": [
                    "id"
                ],
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string"
                    },
                    "thumbnail": {
                        "type": "string"
                    },
                    "animatedThumbnail": {
                        "type": "string"
                    }
                },
                "additionalProperties": false
            },
            "UpdateWidgetDto": {
                "required": [
                    "data"
                ],
                "type": "object",
                "properties": {
                    "asset": {
                        "$ref": "#/components/schemas/UpdateWidgetAssetDto"
                    },
                    "data": {
                        "$ref": "#/components/schemas/WidgetDataDto"
                    },
                    "accountSlug": {
                        "type": "string"
                    },
                    "bannerflowLibraryId": {
                        "type": "string"
                    }
                },
                "additionalProperties": false
            },
            "UploadVideoResponse": {
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string",
                        "nullable": true
                    },
                    "name": {
                        "type": "string",
                        "nullable": true
                    },
                    "url": {
                        "type": "string",
                        "nullable": true
                    },
                    "fileSize": {
                        "type": "integer",
                        "format": "int64"
                    },
                    "width": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "height": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "durationInMilliseconds": {
                        "type": "number",
                        "format": "double"
                    },
                    "thumbnail": {
                        "$ref": "#/components/schemas/Thumbnail"
                    },
                    "elementId": {
                        "type": "string",
                        "nullable": true
                    }
                },
                "additionalProperties": false
            },
            "UpsertWidgetResultDto": {
                "type": "object",
                "properties": {
                    "asset": {
                        "$ref": "#/components/schemas/WidgetAssetDto"
                    },
                    "blobUrl": {
                        "type": "string",
                        "nullable": true
                    }
                },
                "additionalProperties": false
            },
            "VariableDto": {
                "required": [
                    "fallback",
                    "id",
                    "path",
                    "step",
                    "type"
                ],
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string"
                    },
                    "path": {
                        "type": "string"
                    },
                    "step": {
                        "$ref": "#/components/schemas/FeedStepDto"
                    },
                    "fallback": {
                        "type": "string"
                    },
                    "type": {
                        "type": "string"
                    },
                    "spanId": {
                        "type": "string"
                    }
                },
                "additionalProperties": false
            },
            "Version": {
                "type": "object",
                "properties": {
                    "id": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "name": {
                        "type": "string",
                        "nullable": true
                    },
                    "targetUrl": {
                        "type": "string",
                        "nullable": true
                    },
                    "localizationId": {
                        "type": "string",
                        "nullable": true
                    }
                },
                "additionalProperties": false
            },
            "VersionDtoV2": {
                "required": [
                    "id",
                    "localization",
                    "name",
                    "properties",
                    "targetUrl"
                ],
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string"
                    },
                    "name": {
                        "type": "string"
                    },
                    "localization": {
                        "$ref": "#/components/schemas/SlimLocalizationDto"
                    },
                    "targetUrl": {
                        "type": "string"
                    },
                    "properties": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/VersionPropertyDto"
                        }
                    }
                },
                "additionalProperties": false
            },
            "VersionPropertyDto": {
                "required": [
                    "id",
                    "name"
                ],
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string"
                    },
                    "name": {
                        "type": "string"
                    },
                    "value": {
                        "type": "string"
                    }
                },
                "additionalProperties": false
            },
            "VideoAssetDataDto": {
                "required": [
                    "fileSize",
                    "height",
                    "id",
                    "name",
                    "url",
                    "width"
                ],
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string"
                    },
                    "name": {
                        "type": "string"
                    },
                    "url": {
                        "type": "string"
                    },
                    "width": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "height": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "fileSize": {
                        "type": "integer",
                        "format": "int32"
                    }
                },
                "additionalProperties": false
            },
            "VideoAssetDto": {
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string",
                        "nullable": true
                    },
                    "name": {
                        "type": "string",
                        "nullable": true
                    },
                    "url": {
                        "type": "string",
                        "nullable": true
                    },
                    "fileSize": {
                        "type": "integer",
                        "format": "int64"
                    },
                    "width": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "height": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "durationInMilliseconds": {
                        "type": "number",
                        "format": "double"
                    },
                    "thumbnail": {
                        "$ref": "#/components/schemas/ImageDto"
                    },
                    "state": {
                        "enum": [
                            "Unknown",
                            "Created"
                        ],
                        "type": "string"
                    },
                    "created": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "modified": {
                        "type": "string",
                        "format": "date-time",
                        "nullable": true
                    }
                },
                "additionalProperties": false
            },
            "VideoAssetDtoV2": {
                "required": [
                    "created",
                    "durationInMilliseconds",
                    "fileSize",
                    "height",
                    "id",
                    "name",
                    "thumbnail",
                    "url",
                    "width"
                ],
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string"
                    },
                    "name": {
                        "type": "string"
                    },
                    "url": {
                        "maxLength": 2083,
                        "minLength": 1,
                        "type": "string"
                    },
                    "fileSize": {
                        "type": "integer",
                        "format": "int64"
                    },
                    "width": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "height": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "durationInMilliseconds": {
                        "type": "number",
                        "format": "double"
                    },
                    "thumbnail": {
                        "$ref": "#/components/schemas/ImageDto"
                    },
                    "created": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "modified": {
                        "type": "string",
                        "format": "date-time"
                    }
                },
                "additionalProperties": false
            },
            "VideoElementDto": {
                "required": [
                    "__videoKind",
                    "actions",
                    "animations",
                    "duration",
                    "filters",
                    "height",
                    "hidden",
                    "id",
                    "locked",
                    "mirrorX",
                    "mirrorY",
                    "opacity",
                    "originX",
                    "originY",
                    "radius",
                    "rotationX",
                    "rotationY",
                    "rotationZ",
                    "scaleX",
                    "scaleY",
                    "states",
                    "time",
                    "videoSettings",
                    "width",
                    "x",
                    "y"
                ],
                "type": "object",
                "properties": {
                    "__videoKind": {
                        "type": "boolean",
                        "readOnly": true
                    },
                    "feed": {
                        "$ref": "#/components/schemas/FeedDto"
                    },
                    "states": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/StateDto"
                        }
                    },
                    "videoAssetId": {
                        "type": "string"
                    },
                    "videoSettings": {
                        "$ref": "#/components/schemas/VideoSettingsDto"
                    },
                    "masking": {
                        "$ref": "#/components/schemas/MaskDto"
                    },
                    "checksum": {
                        "type": "string"
                    },
                    "time": {
                        "maximum": 2000,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    },
                    "duration": {
                        "maximum": 2000,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    },
                    "parentId": {
                        "type": "string"
                    },
                    "parentNodeId": {
                        "type": "string"
                    },
                    "locked": {
                        "type": "boolean"
                    },
                    "hidden": {
                        "type": "boolean"
                    },
                    "ratio": {
                        "type": "number",
                        "format": "double"
                    },
                    "x": {
                        "maximum": 9999999,
                        "minimum": -9999999,
                        "type": "integer",
                        "format": "int32"
                    },
                    "y": {
                        "maximum": 9999999,
                        "minimum": -9999999,
                        "type": "integer",
                        "format": "int32"
                    },
                    "width": {
                        "maximum": 9999999,
                        "minimum": 1,
                        "type": "integer",
                        "format": "int32"
                    },
                    "height": {
                        "maximum": 9999999,
                        "minimum": 1,
                        "type": "integer",
                        "format": "int32"
                    },
                    "fill": {
                        "type": "string"
                    },
                    "originX": {
                        "type": "number",
                        "format": "double"
                    },
                    "originY": {
                        "type": "number",
                        "format": "double"
                    },
                    "rotationX": {
                        "maximum": 9999999,
                        "minimum": -9999999,
                        "type": "number",
                        "format": "double"
                    },
                    "rotationY": {
                        "maximum": 9999999,
                        "minimum": -9999999,
                        "type": "number",
                        "format": "double"
                    },
                    "rotationZ": {
                        "maximum": 9999999,
                        "minimum": -9999999,
                        "type": "number",
                        "format": "double"
                    },
                    "radius": {
                        "$ref": "#/components/schemas/RadiusDto"
                    },
                    "opacity": {
                        "maximum": 1,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    },
                    "mirrorX": {
                        "type": "boolean"
                    },
                    "mirrorY": {
                        "type": "boolean"
                    },
                    "scaleX": {
                        "type": "number",
                        "format": "double"
                    },
                    "scaleY": {
                        "type": "number",
                        "format": "double"
                    },
                    "shadows": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/ShadowDto"
                        }
                    },
                    "border": {
                        "$ref": "#/components/schemas/BorderDto"
                    },
                    "animations": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/AnimationDto"
                        }
                    },
                    "actions": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/ActionDto"
                        }
                    },
                    "filters": {
                        "$ref": "#/components/schemas/FilterDto"
                    },
                    "id": {
                        "type": "string"
                    }
                },
                "additionalProperties": false
            },
            "VideoSettingsDto": {
                "required": [
                    "autoplay",
                    "endTime",
                    "loop",
                    "playbackButton",
                    "playbackRate",
                    "restartWithCreative",
                    "sizeMode",
                    "startTime",
                    "stopWithCreative",
                    "volume"
                ],
                "type": "object",
                "properties": {
                    "autoplay": {
                        "type": "boolean"
                    },
                    "loop": {
                        "type": "boolean"
                    },
                    "restartWithCreative": {
                        "type": "boolean"
                    },
                    "stopWithCreative": {
                        "type": "boolean"
                    },
                    "startTime": {
                        "maximum": 9999999,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    },
                    "endTime": {
                        "maximum": 9999999,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    },
                    "playbackRate": {
                        "maximum": 2,
                        "minimum": 0.25,
                        "type": "number",
                        "format": "double"
                    },
                    "sizeMode": {
                        "enum": [
                            "fit",
                            "crop"
                        ],
                        "type": "string"
                    },
                    "playbackButton": {
                        "$ref": "#/components/schemas/PlaybackButtonDto"
                    },
                    "volume": {
                        "maximum": 1,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    },
                    "streaming": {
                        "$ref": "#/components/schemas/StreamingDto"
                    },
                    "optimization": {
                        "$ref": "#/components/schemas/OptimizationDto"
                    }
                },
                "additionalProperties": false
            },
            "WeightsDto": {
                "required": [
                    "totalWeight"
                ],
                "type": "object",
                "properties": {
                    "url": {
                        "type": "string"
                    },
                    "totalWeight": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "frameworkOverhead": {
                        "$ref": "#/components/schemas/LoadDto"
                    },
                    "initialLoad": {
                        "$ref": "#/components/schemas/LoadDto"
                    },
                    "subLoad": {
                        "$ref": "#/components/schemas/LoadDto"
                    }
                },
                "additionalProperties": false
            },
            "WidgetAssetDto": {
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string",
                        "nullable": true
                    },
                    "thumbnail": {
                        "type": "string",
                        "nullable": true
                    },
                    "animatedThumbnail": {
                        "type": "string",
                        "nullable": true
                    },
                    "bannerflowLibraryId": {
                        "type": "string",
                        "nullable": true
                    },
                    "bannerflowLibraryVersionId": {
                        "type": "string",
                        "nullable": true
                    },
                    "exportable": {
                        "type": "boolean"
                    },
                    "modified": {
                        "type": "string",
                        "format": "date-time",
                        "nullable": true
                    },
                    "created": {
                        "type": "string",
                        "format": "date-time"
                    }
                },
                "additionalProperties": false
            },
            "WidgetAssetDtoV2": {
                "required": [
                    "created",
                    "id"
                ],
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string"
                    },
                    "thumbnail": {
                        "type": "string"
                    },
                    "animatedThumbnail": {
                        "type": "string"
                    },
                    "bannerflowLibraryId": {
                        "type": "string"
                    },
                    "bannerflowLibraryVersionId": {
                        "type": "string"
                    },
                    "exportable": {
                        "type": "boolean"
                    },
                    "modified": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "created": {
                        "type": "string",
                        "format": "date-time"
                    }
                },
                "additionalProperties": false
            },
            "WidgetDataDto": {
                "type": "object",
                "properties": {
                    "html": {
                        "type": "string",
                        "description": "Base 64 encoded HTML content."
                    },
                    "css": {
                        "type": "string",
                        "description": "Base 64 encoded CSS content."
                    },
                    "js": {
                        "type": "string",
                        "description": "Base 64 encoded JS content."
                    },
                    "ts": {
                        "type": "string",
                        "description": "Base 64 encoded TS content."
                    }
                },
                "additionalProperties": false
            },
            "WidgetElementDto": {
                "required": [
                    "__widgetKind",
                    "actions",
                    "animations",
                    "customProperties",
                    "duration",
                    "filters",
                    "height",
                    "hidden",
                    "id",
                    "locked",
                    "mirrorX",
                    "mirrorY",
                    "opacity",
                    "originX",
                    "originY",
                    "radius",
                    "rotationX",
                    "rotationY",
                    "rotationZ",
                    "scaleX",
                    "scaleY",
                    "states",
                    "time",
                    "width",
                    "x",
                    "y"
                ],
                "type": "object",
                "properties": {
                    "__widgetKind": {
                        "type": "boolean",
                        "readOnly": true
                    },
                    "customProperties": {
                        "type": "array",
                        "items": {
                            "oneOf": [
                                {
                                    "$ref": "#/components/schemas/CustomPropertyBooleanDto"
                                },
                                {
                                    "$ref": "#/components/schemas/CustomPropertyTextDto"
                                },
                                {
                                    "$ref": "#/components/schemas/CustomPropertyColorDto"
                                },
                                {
                                    "$ref": "#/components/schemas/CustomPropertyNumberDto"
                                },
                                {
                                    "$ref": "#/components/schemas/CustomPropertyFeedDto"
                                },
                                {
                                    "$ref": "#/components/schemas/CustomPropertyImageDto"
                                },
                                {
                                    "$ref": "#/components/schemas/CustomPropertyFontDto"
                                },
                                {
                                    "$ref": "#/components/schemas/CustomPropertySelectDto"
                                }
                            ]
                        }
                    },
                    "states": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/StateDto"
                        }
                    },
                    "time": {
                        "maximum": 2000,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    },
                    "duration": {
                        "maximum": 2000,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    },
                    "parentId": {
                        "type": "string"
                    },
                    "parentNodeId": {
                        "type": "string"
                    },
                    "locked": {
                        "type": "boolean"
                    },
                    "hidden": {
                        "type": "boolean"
                    },
                    "ratio": {
                        "type": "number",
                        "format": "double"
                    },
                    "x": {
                        "maximum": 9999999,
                        "minimum": -9999999,
                        "type": "integer",
                        "format": "int32"
                    },
                    "y": {
                        "maximum": 9999999,
                        "minimum": -9999999,
                        "type": "integer",
                        "format": "int32"
                    },
                    "width": {
                        "maximum": 9999999,
                        "minimum": 1,
                        "type": "integer",
                        "format": "int32"
                    },
                    "height": {
                        "maximum": 9999999,
                        "minimum": 1,
                        "type": "integer",
                        "format": "int32"
                    },
                    "fill": {
                        "type": "string"
                    },
                    "originX": {
                        "type": "number",
                        "format": "double"
                    },
                    "originY": {
                        "type": "number",
                        "format": "double"
                    },
                    "rotationX": {
                        "maximum": 9999999,
                        "minimum": -9999999,
                        "type": "number",
                        "format": "double"
                    },
                    "rotationY": {
                        "maximum": 9999999,
                        "minimum": -9999999,
                        "type": "number",
                        "format": "double"
                    },
                    "rotationZ": {
                        "maximum": 9999999,
                        "minimum": -9999999,
                        "type": "number",
                        "format": "double"
                    },
                    "radius": {
                        "$ref": "#/components/schemas/RadiusDto"
                    },
                    "opacity": {
                        "maximum": 1,
                        "minimum": 0,
                        "type": "number",
                        "format": "double"
                    },
                    "mirrorX": {
                        "type": "boolean"
                    },
                    "mirrorY": {
                        "type": "boolean"
                    },
                    "scaleX": {
                        "type": "number",
                        "format": "double"
                    },
                    "scaleY": {
                        "type": "number",
                        "format": "double"
                    },
                    "shadows": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/ShadowDto"
                        }
                    },
                    "border": {
                        "$ref": "#/components/schemas/BorderDto"
                    },
                    "animations": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/AnimationDto"
                        }
                    },
                    "actions": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/ActionDto"
                        }
                    },
                    "filters": {
                        "$ref": "#/components/schemas/FilterDto"
                    },
                    "id": {
                        "type": "string"
                    }
                },
                "additionalProperties": false
            },
            "WidgetFontStyleDto": {
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string",
                        "nullable": true
                    },
                    "weight": {
                        "type": "number",
                        "format": "double"
                    },
                    "style": {
                        "enum": [
                            "normal",
                            "italic"
                        ],
                        "type": "string"
                    },
                    "src": {
                        "type": "string",
                        "nullable": true
                    },
                    "fontFamilyId": {
                        "type": "string",
                        "nullable": true
                    }
                },
                "additionalProperties": false
            },
            "WidgetImageDto": {
                "required": [
                    "id",
                    "src"
                ],
                "type": "object",
                "properties": {
                    "id": {
                        "type": "string"
                    },
                    "src": {
                        "type": "string"
                    }
                },
                "additionalProperties": false
            },
            "WidgetSelectionOptionDto": {
                "required": [
                    "selected",
                    "value"
                ],
                "type": "object",
                "properties": {
                    "value": {
                        "type": "string"
                    },
                    "selected": {
                        "type": "boolean"
                    }
                },
                "additionalProperties": false
            }
        },
        "securitySchemes": {
            "auth0": {
                "type": "apiKey",
                "description": "Please insert JWT token here prepended by 'auth0'. auth0 [token]",
                "name": "Authorization",
                "in": "header"
            }
        }
    },
    "security": "redacted"
}