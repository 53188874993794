import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UIDialogComponent, UIModule } from '@bannerflow/ui';

@Component({
    standalone: true,
    imports: [CommonModule, UIModule],
    selector: 'target-url-dialog',
    templateUrl: './target-url-dialog.component.html',
    styleUrls: ['./target-url-dialog.component.scss']
})
export class TargetUrlDialogComponent implements OnInit {
    targetUrl = '';
    collapsed = false;

    constructor(private dialog: UIDialogComponent) {}

    ngOnInit(): void {
        this.targetUrl = this.dialog.dialogRef.config.data.targetUrl;
    }

    collapse(): void {
        this.collapsed = !this.collapsed;
    }

    close(): void {
        this.dialog.dialogRef.close();
    }

    openLink(): void {
        window.open(this.targetUrl, '_blank');
    }
}
