import {
    createEmptyState,
    getStateById,
    isEmptyState,
    isEqualStates
} from '@creative/rendering/states.utils';
import {
    AnimationType,
    IAnimation,
    IAnimationKeyframe,
    IAnimationSettings,
    IAnimationTemplate,
    ITime,
    ITimeAndDuration,
    keyframeProperties
} from '@domain/animation';
import { AnimationDto } from '@domain/api/generated/sapi';
import { OneOfDataNodes, OneOfElementDataNodes } from '@domain/nodes';
import { OneOfElementsDto } from '@domain/serialization';
import { IState, OneOfStatePropertyKeys } from '@domain/state';
import { TimingFunctionKey } from '@domain/timing-functions';
import { animatableProperties } from '@domain/transition';
import { handleError } from '@studio/utils/errors';
import { uuidv4 } from '@studio/utils/id';
import { capitalize, getUniqueName } from '@studio/utils/string';
import { clamp, deepEqual, omit } from '@studio/utils/utils';
import { isHidden } from './nodes/helpers';
import { DEFAULT_TIMING_FUNCTION } from './timing-functions';
import { isStateActionMethod } from '@creative/actions/actions.utils';

/**
 * Time in second between large.
 */
export const IN_OUT_ANIMATION_GAP = 0.02;
export const MIN_ELEMENT_DURATION = 0.1;
export const MIN_ANIMATION_DURATION = 0.1;
export const MIN_KEYFRAME_DISTANCE = 0.08;
export const KEYFRAME_SNAP_DISTANCE = 0.02;

export const MIN_ANIMATION_DIRECTION = -9999999;
export const MAX_ANIMATION_DIRECTION = 9999999;
export const MIN_ANIMATION_DISTANCE = -9999999;
export const MAX_ANIMATION_DISTANCE = 9999999;

export type TimelineResizeDirection = 'left' | 'right' | 'both';

export type KeyframeTemplate = ITime & Partial<IState & IAnimationKeyframe>;
type KeyframeAndState = { keyframe: IAnimationKeyframe; state: IState };
type KeyframesAndStates = { keyframes: IAnimationKeyframe[]; states: IState[] };

interface IAnimationInputSettings {
    name?: string;
    type?: AnimationType;
    timingFunction?: TimingFunctionKey;
}

export function addAnimationToElement(
    element: OneOfElementDataNodes,
    settings: IAnimationInputSettings = {},
    keyframesTemplates: KeyframeTemplate[] = []
): IAnimation {
    const animation = createAnimation(element, settings);
    const { keyframes, states } = createKeyframesAndStatesFromTemplates(...keyframesTemplates);

    animation.keyframes.push(...keyframes);
    element.animations.push(animation);
    element.states.push(...states);

    element.animations.sort(sortAnimations);

    return animation;
}

export function createAnimation(
    element: OneOfElementDataNodes,
    settings: IAnimationInputSettings = {}
): IAnimation {
    let name = getUniqueName(
        settings.name || 'Animation',
        element.animations.map(animation => animation.name)
    );

    if (isTransitionType(settings.type)) {
        name += ` ${settings.type}`;
    }

    const animation: IAnimation = {
        id: uuidv4(),
        keyframes: [],
        name,
        timingFunction: settings.timingFunction || DEFAULT_TIMING_FUNCTION,
        type: settings.type || 'keyframe',
        hidden: false
    };
    return animation;
}

export function createKeyframesAndStatesFromTemplates(
    ...templates: KeyframeTemplate[]
): KeyframesAndStates {
    const keyframeTemplates = templates.filter(
        t => Object.keys(filterKeyframePropertiesFromKeyframeTemplate(t)).length
    );
    const defaultTemplates = templates.filter(t => keyframeTemplates.indexOf(t) === -1);

    // Create all keyframes that have a state
    const { keyframes, states } = createKeyframesWithStates(...keyframeTemplates);

    // Apply all default state keyframes
    defaultTemplates.forEach(t => keyframes.push(createKeyframe(t)));

    keyframes.sort(sortByTime);

    return { keyframes, states };
}

export function createKeyframesWithStates(
    ...keyframeTemplates: KeyframeTemplate[]
): KeyframesAndStates {
    const states: IState[] = [];
    const keyframes: IAnimationKeyframe[] = [];

    for (let i = 0; i < keyframeTemplates.length; i++) {
        const k = keyframeTemplates[i];
        const stateProps = filterKeyframePropertiesFromKeyframeTemplate(k);
        const newState = createEmptyState();
        const state = {
            ...newState,
            ...stateProps,
            id: newState.id
        };

        states.push(state);

        k.stateId = newState.id;

        keyframes.push(createKeyframe(k));
    }

    keyframes.sort(sortByTime);

    return { keyframes, states };
}

/**
 * Get duration of an animation
 * @param animation
 */
export function getAnimationDuration(animation: IAnimation | IAnimationTemplate): number {
    return getDuration(...animation.keyframes);
}

/**
 * Get time when animation starts.
 * Note that time is relative to element.time
 * @param animation
 */
export function getAnimationTime(animation: IAnimation): number {
    return getTime(...animation.keyframes);
}

// export function getDurationOfAnimations(animations: IAnimation[]): number {
//     let from = Number.MAX_VALUE;
//     let to = 0;

//     if (animations && animations.length) {
//         animations.forEach(animation => {
//             const start = getAnimationTime(animation);
//             const end = start + getAnimationDuration(animation);
//             from = Math.min(from, start);
//             to = Math.min(to, end);
//         });
//         return to - from;
//     }
//     return 0;
// }

/**
 * Change duration of a single animation.
 */
export function setAnimationDuration(animation: IAnimation, duration: number): void {
    const { keyframes, type } = animation;
    if (!keyframes?.length) {
        throw new Error(`Can't set duration on an animation without keyframes`);
    }

    duration = Math.max(getMinAnimationDuration(animation), duration);

    validateDuration(duration);

    const currentDuration = getAnimationDuration(animation);

    // Single keyframes have 0 duration which can't scale...
    if (currentDuration) {
        const scale = duration / currentDuration;

        // The keyframe that should stay in place (0 for everything fluid)
        const reference = isTransitionType(type)
            ? (type === 'out' ? keyframes[keyframes.length - 1] : keyframes[0]).time
            : 0;

        keyframes.forEach(keyframe => {
            const dt = keyframe.time - reference;
            keyframe.time = reference + dt * scale;
            keyframe.duration = (keyframe.duration || 0) * scale;
        });
    }
}

export function fixTransitionKeyframesAlign(
    element: OneOfElementDataNodes,
    animation: IAnimation
): void {
    const keyframes = animation.keyframes;
    if (keyframes.length > 1) {
        if (animation.type === 'in') {
            keyframes[0].time = 0;
        } else if (animation.type === 'out') {
            keyframes[keyframes.length - 1].time =
                element.duration - keyframes[keyframes.length - 1].duration;
        }
    }
}

/**
 * Remove all animations of a ceratin type
 */
export function removeAnimationsOfType(
    element: OneOfElementDataNodes,
    type: AnimationType
): IAnimation[] {
    return removeAnimations(
        element,
        ...element.animations.filter((animation: IAnimation) => animation.type === type)
    );
}

/**
 * Remove a selection of animations from the array of animations
 * @param animations
 * @param animationsToRemove
 */
export function removeAnimations(
    element: OneOfElementDataNodes,
    ...animationsToRemove: IAnimation[]
): IAnimation[] {
    const removedAnimations: IAnimation[] = [];
    const animations = element.animations;
    for (let i = animations.length - 1; i >= 0; i--) {
        const removeIndex = animationsToRemove.findIndex(remove => animations[i].id === remove.id);
        if (removeIndex > -1) {
            animationsToRemove.splice(removeIndex, 1);
            removedAnimations.push(animations.splice(i, 1)[0]);
        }
    }
    removeAnimationStates(element, removedAnimations);
    return removedAnimations;
}

/**
 * Remove states not used by a set of animations
 * @param element
 * @param animationsToRemove
 */
export function removeAnimationStates(
    element: OneOfElementDataNodes,
    animationsToRemove: IAnimation[]
): void {
    if (animationsToRemove.length) {
        const persistentAnimations = element.animations.filter(
            animation => !animationsToRemove.some(a => a === animation)
        );
        const persistentKeyframes = getAllKeyframes(persistentAnimations);
        const keyframesToBeRemoved = getAllKeyframes(animationsToRemove);

        const idsToRemove = keyframesToBeRemoved
            .map(keyframe => keyframe.stateId)
            .filter(id => id && !persistentKeyframes.some(keyframe => id === keyframe.stateId));

        element.states = element.states.filter(state => !idsToRemove.some(id => state.id === id));
    }
}

export function removeKeyframes(element: OneOfElementDataNodes, keyframes: IAnimationKeyframe[]): void {
    const statesToRemove = new Set<string>();

    element.animations.forEach(animation => {
        keyframes.forEach(keyframe => {
            const keyframeToRemove = animation.keyframes.find(kf => kf.id === keyframe.id);

            if (keyframeToRemove) {
                statesToRemove.add(keyframeToRemove.stateId!);

                animation.keyframes = animation.keyframes.filter(kf => kf.id !== keyframe.id);
                animation.keyframes.sort(sortByTime);
            }
        });
    });

    element.states = element.states.filter(state => !statesToRemove.has(state.id!));
}

/**
 * Moive animation without changing the duration.
 * Remember that keyframe.time is relative to element.time.
 * @param animation
 * @param relativeTime
 */
export function moveAnimationTo(animation: IAnimation, relativeTime: number): number {
    const dt = relativeTime - getAnimationTime(animation);
    animation.keyframes.forEach(keyframe => (keyframe.time += dt));
    return relativeTime;
}

/**
 * Align an animation to end or start of an element.
 * @param animation
 * @param elementDuration
 * @param alignTo
 */
export function alignAnimation(
    animation: IAnimation,
    elementDuration: number,
    alignTo: TimelineResizeDirection
): number {
    const duration = getAnimationDuration(animation);
    let origin = alignTo === 'both' ? 0.5 : 0;
    if (alignTo === 'right') {
        origin = 1;
    }
    const moveTo = origin * (elementDuration - duration);
    return moveAnimationTo(animation, moveTo);
}

/**
 * Get duration of all in animations
 */
export function getInAnimationDuration(animations: IAnimation[]): number {
    return getAnimationDurationOfType(animations, 'in');
}

/**
 * Get duration of all out animations
 */
export function getOutAnimationDuration(animations: IAnimation[]): number {
    return getAnimationDurationOfType(animations, 'out');
}

/**
 * Get duration of all out animations
 */
export function getAnimationDurationOfType(animations: IAnimation[], type: AnimationType): number {
    return getDurationOfAnimations(
        animations.filter((animation: IAnimation) => animation.type === type)
    );
}

/**
 * Set the duration of all in animations
 * @param animations
 * @param duration
 */
export function setInDuration(element: OneOfElementDataNodes, duration: number): void {
    setDurationOfType(element, duration, 'in');
}

/**
 * Set the duration of all out animations
 * @param animations
 * @param duration
 */
export function setOutDuration(element: OneOfElementDataNodes, duration: number): void {
    setDurationOfType(element, duration, 'out');
}

/**
 * Helper to change duration from other anchorpoints than element.time
 */
export function getDirectionalDurationChange(
    current: ITimeAndDuration,
    newDuration: number,
    direction: TimelineResizeDirection = 'right',
    limit: { left?: number; right?: number } = {}
): ITimeAndDuration {
    const leftLimit = limit.left || 0;
    const rightLimit = limit.right;

    const { duration } = current;
    let origin = direction === 'both' ? 0.5 : 0;
    if (direction === 'left') {
        origin = 1;
    }
    const durationDifference = newDuration - duration;
    let newTime = current.time - durationDifference * origin;

    if (newTime < leftLimit) {
        newDuration += origin ? (newTime - leftLimit) / origin : 0;
        newTime = leftLimit;
    }
    if (typeof rightLimit === 'number') {
        const overflow = newTime + newDuration - rightLimit;
        if (overflow > 0) {
            newDuration -= overflow - overflow * origin;
            newTime -= overflow * origin;
        }
    }

    return {
        time: newTime,
        duration: newDuration
    };
}

export function setDurationOfElements(
    elements: OneOfElementDataNodes[],
    newDuration: number,
    direction: TimelineResizeDirection = 'right'
): void {
    newDuration = Math.max(newDuration, getMinDurationOfElements(...elements));

    const current = getTimeAndDuration(...elements);
    const directionalChange = getDirectionalDurationChange(current, newDuration, direction);
    const timeDiff = directionalChange.time - current.time;
    const durationDiff = directionalChange.duration - current.duration;

    elements.forEach(element => {
        setElementTimeAndDuration(element, element.time + timeDiff, element.duration + durationDiff);
    });
}

export function setElementDuration(
    element: OneOfElementDataNodes,
    newDuration: number,
    direction: TimelineResizeDirection = 'right'
): void {
    if (element.duration !== newDuration) {
        newDuration = Math.max(getMinElementDuration(element), newDuration);
        const { time, duration } = getDirectionalDurationChange(element, newDuration, direction);
        const outAnimations = getAnimationsOfType(element, 'out');

        outAnimations.forEach(animation => {
            // Distance in seconds from "end"
            const diff = element.duration - getAnimationTime(animation);
            moveAnimationTo(animation, duration - diff);
        });

        const keyframeAnimations = getAnimationsOfType(element, 'keyframe');
        // const keyframesDuration = getA
        keyframeAnimations.forEach(animation => {
            scaleAnimationToElementDuration(animation, element.duration, duration);
        });
        element.duration = duration;
        element.time = time;
        validateAnimations(element);
    }
}

export function setElementTimeAndDuration(
    element: OneOfElementDataNodes,
    time: number,
    duration: number
): void {
    setElementDuration(element, duration);
    element.time = time;
}

export function setDurationOfType(
    element: OneOfElementDataNodes,
    duration: number,
    type: AnimationType
): void {
    const animations = element.animations.filter((animation: IAnimation) => animation.type === type);
    setDurationOfAnimations(element, animations, duration);
}

export function moveElementToPlayhead(
    element: OneOfElementDataNodes,
    elements: ITimeAndDuration[],
    playheadTime: number
): void {
    // Exclude itself
    elements = elements.filter(e => e !== element);

    const timeAndDuration = getTimeAndDuration(...elements);
    const creativeDuration = timeAndDuration.time + timeAndDuration.duration;

    let newTime = Math.max(0, playheadTime - getInAnimationDuration(element.animations));

    // With no elemets duration is 0
    if (creativeDuration) {
        newTime = clamp(newTime, 0, Math.max(0, creativeDuration - element.duration));
    } else {
        newTime = 0;
    }

    element.time = newTime;
}

/**
 * Scale a bunch of animations so they "cover" the given duration
 * @param animations
 * @param duration
 */
export function setDurationOfAnimations(
    element: OneOfElementDataNodes,
    animations: IAnimation[],
    duration: number
): void {
    if (animations?.length) {
        const { type } = animations[0];

        // When scaling in and out animations make sure they don't overlap the opposite type animations
        if (isTransitionType(type) && animations.every(a => a.type === type)) {
            duration = Math.min(duration, getMaxAnimationDuration(element, animations[0]));
        }

        duration = Math.max(MIN_ANIMATION_DURATION, duration);

        validateDuration(duration);

        const currentDuration = getDurationOfAnimations(animations);
        const scale = duration / currentDuration;

        animations.forEach(animation => {
            const animationDuration = getAnimationDuration(animation);

            // Set to full duration if animation has no duration
            const newDuration = (animationDuration || currentDuration) * scale;

            if (!isNaN(scale)) {
                if (animation.keyframes.length > 1) {
                    setAnimationDuration(animation, newDuration);
                } else {
                    scaleKeyframeAnimation(animation, scale);
                }
            }
        });
    }
}

export function scaleAnimationToElementDuration(
    animation: IAnimation,
    oldDuration: number,
    newDuration: number
): void {
    const animationDuration = getAnimationDuration(animation);
    const scale = newDuration / oldDuration;

    // Only animations that have durations can be scaled
    if (animationDuration) {
        setAnimationDuration(animation, animationDuration * scale);
    }
    // Typically for animations with only one keyframe
    else {
        scaleKeyframeAnimation(animation, scale);
    }
}

/**
 * Move all keyframes in an animations by a scale.
 * Note: setAnimationDuration should be the prefered method for this
 * since it can handle in/out animations.
 * @param animation
 * @param scale
 */
export function scaleKeyframeAnimation(animation: IAnimation, scale: number): void {
    if (animation.type !== 'keyframe') {
        throw new Error('Can only scale keyframe animations');
    }
    if (!scale) {
        throw new Error('A positive scale must be provided');
    }
    animation.keyframes.forEach(k => {
        k.time *= scale;
        k.duration = k.duration * scale;
    });
}

/**
 * Get the total duration of a group of animations.
 * Note: This is not returning just the longest duration.
 * So the duration of the in and out animation will be element.duration.
 */
export function getDurationOfAnimations(animations: IAnimation[]): number {
    return getDuration(...getAllKeyframes(animations));
}

export function getTimeOfAnimations(animations: IAnimation[]): number {
    return getTime(...getAllKeyframes(animations));
}

export function getTimeAndDurationOfAnimations(animations: IAnimation[]): ITimeAndDuration {
    return getTimeAndDuration(...getAllKeyframes(animations));
}

export function getAnimationKeyframesOfType(
    element: OneOfElementDataNodes,
    type: AnimationType
): IAnimationKeyframe[] {
    const animations = getAnimationsOfType(element, type);
    return animations.reduce<IAnimationKeyframe[]>((acc, curr) => [...acc, ...curr.keyframes], []);
}

/**
 * TODO: SUPPORT MORE CASES THAN IN/OUT
 */
export function isElementInMainStateAtTime(element: OneOfElementDataNodes, time: number): boolean {
    if (!isElementVisibleAtTime(element, time)) {
        return false;
    }
    const inDuration = getInAnimationDuration(element.animations) || 0;
    const outDuration = getOutAnimationDuration(element.animations) || 0;
    const inTime = element.time + inDuration;
    const outTime = element.time + element.duration - outDuration;
    const tolerance = 0.000001;

    // Sometimes javascript gives you number like 7.6000000000000005 despite rounding.
    if (Math.abs(inTime - time) < tolerance || Math.abs(outTime - time) < tolerance) {
        return true;
    }
    // normal compare
    else if (time < inTime || time > outTime) {
        return false;
    }

    return true;
}

export function validateKeyframeToStatesMapping(element: OneOfElementDataNodes): boolean {
    const keyframes = getAllKeyframes(element);
    for (const keyframe of keyframes) {
        if (keyframe.stateId) {
            if (!element.states?.some(state => state.id === keyframe.stateId)) {
                throw new Error('Keyframe is missing corresponding state');
            }
        }
    }

    const actionStates = element.actions.flatMap(action =>
        action.operations.flatMap(operation => {
            if (isStateActionMethod(operation.method) && operation.value) {
                return operation.value;
            }
            return undefined;
        })
    );

    if (element.states) {
        for (const state of element.states) {
            if (state.name) {
                continue;
            }

            /**
             * When creating animation keyframes, the state
             * is initially always empty without references
             */
            const stateProps = Object.keys(state);
            let emptyValidState = false;
            if (stateProps.length === 1 && stateProps[0] === 'id') {
                emptyValidState = true;
            }

            const referencesKeyframe = keyframes.some(keyframe => state.id === keyframe.stateId);
            const referencesAction = actionStates.some(actionState => state.id === actionState);

            if (!state.id || (!emptyValidState && !referencesKeyframe && !referencesAction)) {
                throw new Error('State without any references detected');
            }
        }
    }
    return true;
}

export function getAnimationsOfType(element: OneOfElementDataNodes, type: AnimationType): IAnimation[] {
    return (element.animations || []).filter(a => a.type === type);
}

export function hasAnimationsOfType(element: OneOfElementDataNodes, type: AnimationType): boolean {
    return getAnimationsOfType(element, type).length > 0;
}

export function isElementVisibleAtTime(element: OneOfDataNodes, time: number): boolean {
    return !isHidden(element) && isTimeAt(time, element);
}

export function getAnimationTypeAtTime(
    element: OneOfElementDataNodes,
    time: number
): 'in' | 'out' | 'keyframe' | undefined {
    const animations = getAnimationsAtTime(element, time);
    if (animations.length) {
        const transition = animations.find(a => isTransitionType(a.type));
        if (transition) {
            return transition.type as 'in' | 'out';
        }
        return animations[0].type as 'keyframe';
    }

    return undefined;
}

export function getAnimationsAtTime(
    element: OneOfElementDataNodes,
    absoluteTime: number
): IAnimation[] {
    const relativeTime = toRelativeTime(element, absoluteTime);

    return element.animations.filter(animation =>
        isTimeAt(relativeTime, getTimeAndDuration(...animation.keyframes))
    );
}

export function getTimelineOverlap(
    position1: ITimeAndDuration,
    position2: ITimeAndDuration
): ITimeAndDuration | undefined {
    const from = Math.max(position1.time, position2.time);
    const to = Math.min(position1.time + position1.duration, position2.time + position2.duration);
    const duration = to - from;
    if (duration > 0) {
        return {
            time: from,
            duration
        };
    }
}

export function getMainStateOverlap(
    element1: OneOfElementDataNodes,
    element2: OneOfElementDataNodes
): ITimeAndDuration | undefined {
    return getTimelineOverlap(
        getMainStateTimelinePosition(element1),
        getMainStateTimelinePosition(element2)
    );
}

export function isTimelineOverlap(position1: ITimeAndDuration, position2: ITimeAndDuration): boolean {
    return getTimelineOverlap(position1, position2) !== undefined;
}

export function isMainStateOverlap(
    element1: OneOfElementDataNodes,
    element2: OneOfElementDataNodes
): boolean {
    return isTimelineOverlap(
        getMainStateTimelinePosition(element1),
        getMainStateTimelinePosition(element2)
    );
}

export function getMainStateTimelinePosition(element: OneOfElementDataNodes): ITimeAndDuration {
    const inDuration = getInAnimationDuration(element.animations);
    const outDuration = getOutAnimationDuration(element.animations);
    return {
        time: element.time + inDuration,
        duration: element.duration - (inDuration + outDuration)
    };
}

export function getAnimationByKeyframe(
    element: OneOfElementDataNodes,
    keyframe: IAnimationKeyframe
): IAnimation | undefined {
    return element.animations.find(animation => animation.keyframes.some(k => k === keyframe));
}

export function getAllKeyframes(
    elementOrAnimations: OneOfElementDataNodes | OneOfElementsDto | IAnimation[] | AnimationDto[]
): IAnimationKeyframe[] {
    const animations = Array.isArray(elementOrAnimations)
        ? elementOrAnimations
        : elementOrAnimations.animations;
    const keyframes: IAnimationKeyframe[] = [];
    animations?.forEach(animation => keyframes.push(...animation.keyframes));
    return keyframes;
}

export function getElementOfAnimation(
    elements: ReadonlyArray<OneOfElementDataNodes>,
    animation: IAnimation
): OneOfElementDataNodes | undefined {
    return elements.find(el => el.animations.find(ani => ani === animation));
}

export function getElementAndAnimationOfKeyframe(
    elements: ReadonlyArray<OneOfElementDataNodes>,
    keyframe: IAnimationKeyframe
): { element: OneOfElementDataNodes; animation: IAnimation } | undefined {
    for (const element of elements) {
        const animation = getAnimationOfKeyframe(element, keyframe);
        if (animation) {
            return { element, animation };
        }
    }
}

export function getAnimationOfKeyframe(
    elementOrAnimations: OneOfElementDataNodes | IAnimation[],
    keyframe: IAnimationKeyframe
): IAnimation | undefined {
    const animations = Array.isArray(elementOrAnimations)
        ? elementOrAnimations
        : elementOrAnimations.animations;
    return animations.find(animation => animation.keyframes.some(k => k === keyframe));
}

export function createKeyframe(options: ITime & Partial<IAnimationKeyframe>): IAnimationKeyframe {
    const keyframe: IAnimationKeyframe = {
        id: uuidv4(),
        time: options.time,
        stateId: options.stateId,
        timingFunction: options.timingFunction || DEFAULT_TIMING_FUNCTION,
        duration: options.duration || 0
    };

    return keyframe;
}

export function addKeyframe(
    element: OneOfElementDataNodes,
    animation: IAnimation,
    options: ITime & Partial<IAnimationKeyframe>
): IAnimationKeyframe | undefined {
    const keyframe = createKeyframe({ ...options, time: -1 });

    animation.keyframes.unshift(keyframe);

    const time = moveKeyframeTo(element, animation, keyframe, options.time);

    // Could not place the element
    if (time === undefined) {
        handleError('Could not place keyframe', {
            level: 'warning'
        });

        animation.keyframes.shift();
    }

    return time === undefined ? undefined : keyframe;
}

export function addKeyframeWithState(
    element: OneOfElementDataNodes,
    animation: IAnimation,
    properties: ITime & Partial<IState & IAnimationKeyframe>
): KeyframeAndState | undefined {
    const previousKeyframe = getPreviousTime(animation.keyframes, properties.time);
    const { keyframes, states } = createKeyframesWithStates({
        // If there is a previous timing function, duplicate that for the new keyframe
        timingFunction: previousKeyframe?.timingFunction,
        ...properties
    });
    const keyframe = addKeyframe(element, animation, keyframes[0]);

    if (!keyframe) {
        console.warn('Cant add new keyframe');
        return;
    }

    const state = states[0];
    element.states.push(state);

    return { state, keyframe };
}

export function getClosestGap(
    time: ITime,
    elements: ITime[],
    totalDuration: number,
    minDistance = 0
): number | undefined {
    // Fastest way, no obstacles in the way from start
    if (!elements.some(element => getTimeBetween(element, time) < minDistance)) {
        return time.time;
    }
    // Do a more sophisticated calculation of the closest available position
    else if (elements.length) {
        const first = elements[0];
        const last = elements[elements.length - 1];

        // Get all gaps between keyframes that the keyframe to move can fit between
        const availableGaps = elements
            .slice(1)
            .map((k, i) => ({
                time: elements[i].time + (elements[i].duration || 0) + minDistance,
                duration: getTimeBetween(elements[i], k) - minDistance * 2
            }))
            .filter(k => k.duration >= (time.duration || 0));

        // Add available gap at start of element
        if (first.time >= (time.duration || 0) + minDistance) {
            availableGaps.unshift({
                time: 0,
                duration: first.time - minDistance
            });
        }
        // Add available gap of the end of element
        if (last.time + minDistance + (time.duration || 0) <= totalDuration) {
            availableGaps.push({
                time: last.time + (last.duration || 0) + minDistance,
                duration: time.duration || 0
            });
        }

        if (availableGaps.length) {
            return (
                availableGaps
                    // Convert to numeric time
                    .map(g => clamp(time.time, g.time, g.time + g.duration - (time.duration || 0)))
                    // Find position closest to relativeTime
                    .reduce((prev, curr) =>
                        Math.abs(curr - time.time) < Math.abs(prev - time.time) ? curr : prev
                    )
            );
        }
    }
}

export function moveKeyframeTo(
    element: OneOfElementDataNodes,
    animation: IAnimation,
    keyframe: IAnimationKeyframe,
    relativeTime: number
): number | undefined {
    const duration = keyframe.duration || 0;
    const type = animation.type;
    let keyframes = animation.keyframes;
    const index = keyframes.indexOf(keyframe);
    relativeTime = clamp(relativeTime, 0, element.duration - duration);

    // Snap first keyframe to start
    if (index === 0) {
        relativeTime = relativeTime <= KEYFRAME_SNAP_DISTANCE ? 0 : relativeTime;
    }
    // Snap last keyframe to end
    else if (index === keyframes.length - 1 && index !== -1) {
        relativeTime =
            relativeTime >= element.duration - KEYFRAME_SNAP_DISTANCE ? element.duration : relativeTime;
    }

    // Make sure we're not allowing in/out animations to overlap
    if (isTransitionType(type)) {
        const minDuration = getMinAnimationDuration(animation);
        const maxDuration = getMaxAnimationDuration(element, animation);
        const reference = type === 'in' ? keyframes[0] : keyframes[keyframes.length - 1];
        if (reference !== keyframe) {
            const min =
                type === 'in'
                    ? minDuration - duration
                    : reference.time + reference.duration - maxDuration;
            const max =
                type === 'in'
                    ? reference.time + maxDuration
                    : reference.time + reference.duration - minDuration;
            relativeTime = clamp(relativeTime, min, max);
        }
    }

    // Filter out the keyframe we want to move from the calculations
    keyframes = keyframes.filter(k => k !== keyframe);

    const newTime = getClosestGap(
        { time: relativeTime, duration },
        keyframes,
        element.duration,
        MIN_KEYFRAME_DISTANCE
    );

    if (typeof newTime === 'number') {
        keyframe.time = newTime;
        animation.keyframes.sort(sortByTime);
    }
    return newTime;
}

export function setKeyframeDuration(
    element: OneOfElementDataNodes,
    animation: IAnimation,
    keyframe: IAnimationKeyframe,
    newDuration: number,
    direction: TimelineResizeDirection = 'right'
): number {
    if (keyframe.duration !== newDuration) {
        newDuration = Math.min(newDuration, getMaxKeyframeDuration(element, animation, keyframe));
        const keyframes = animation.keyframes.filter(k => k !== keyframe);
        const next = getNextTime(keyframes, keyframe.time);
        const prev = getPreviousTime(keyframes, keyframe.time);
        const limit = {
            left: prev ? prev.time + prev.duration + MIN_KEYFRAME_DISTANCE : 0,
            right: next ? next.time - MIN_KEYFRAME_DISTANCE : element.duration
        };
        const { time, duration } = getDirectionalDurationChange(
            keyframe,
            newDuration,
            direction,
            limit
        );

        keyframe.duration = duration;
        keyframe.time = time;
    }
    return keyframe.duration;
}

export function isEmptyKeyframe(element: OneOfElementDataNodes, keyframe: IAnimationKeyframe): boolean {
    const state = getStateById(element, keyframe.stateId);
    return !state || isEmptyState(state);
}

export function keyframesHasEqualStates(
    element: OneOfElementDataNodes,
    ...keyframes: (IAnimationKeyframe | undefined)[]
): boolean {
    keyframes = keyframes.filter(k => k !== undefined) as IAnimationKeyframe[];

    if (keyframes.length > 1) {
        // At least one keyframe with a state, do a compare.
        if (keyframes.some(k => k?.stateId)) {
            const states = keyframes.map(k => getStateById(element, k!.stateId));
            return isEqualStates(...states);
        }
        // This means all frames are default keyframes
        return true;
    }
    return false;
}

export function getKeyframesAtTime(
    time: number,
    element: OneOfElementDataNodes,
    animations?: IAnimation[],
    type?: AnimationType,
    tolerance?: number
): IAnimationKeyframe[] {
    animations = animations || element.animations;

    if (type) {
        animations = animations.filter(a => a.type === type);
    }

    const keyframes: IAnimationKeyframe[] = [];

    animations.forEach(animation => {
        keyframes.push(...getKeyframesOfAnimationAtTime(time, element, animation, tolerance));
    });

    return keyframes;
}

export function getCustomKeyframes(element: OneOfElementDataNodes): IAnimationKeyframe[] {
    const keyframes = getAllKeyframes(getAnimationsOfType(element, 'keyframe')).sort(sortByTime);

    return keyframes;
}

export function getPreviousTime<T extends ITime>(
    times: T[],
    time: ITime | number,
    tolerance = 0
): T | undefined {
    const t = numberToTime(time);
    return times
        .slice(0)
        .sort(sortByTimeReversed)
        .find(k => t !== k && k.time - tolerance <= t.time);
}

export function getNextTime<T extends ITime>(
    times: T[],
    time: ITime | number,
    tolerance = 0
): T | undefined {
    const t = numberToTime(time);
    return times
        .slice(0)
        .sort(sortByTime)
        .find(k => t !== k && t.time <= k.time + tolerance);
}

export function getSurroundingTimes(
    keyframes: IAnimationKeyframe[],
    absoluteTime: number,
    elementTime = 0,
    tolerance = 0
): ISurroundingKeyframes {
    const relativeTime = absoluteTime - elementTime;

    return {
        before: getPreviousTime(keyframes, relativeTime, tolerance),
        after: getNextTime(keyframes, relativeTime, tolerance)
    };
}

export function getKeyframesOfAnimationAtTime(
    absoluteTime: number,
    element: OneOfElementDataNodes,
    animation: IAnimation,
    tolerance = 0.0001
): IAnimationKeyframe[] {
    const relativeTime = toRelativeTime(element, absoluteTime);
    const keyframes = animation.keyframes.filter(keyframe =>
        isTimeAt(relativeTime, keyframe, tolerance)
    );

    return keyframes;
}

export function getKeyframeById(
    element: OneOfElementDataNodes,
    id: string
): IAnimationKeyframe | undefined {
    return getAllKeyframes(element).find(keyframe => keyframe.id === id);
}

export function getKeyframeByStateId(
    element: OneOfElementDataNodes,
    stateId: string
): IAnimationKeyframe | undefined {
    return getAllKeyframes(element).find(keyframe => keyframe.stateId && keyframe.stateId === stateId);
}

export function getAnimationByStateId(
    element: OneOfElementDataNodes,
    stateId: string
): IAnimation | undefined {
    for (const animation of element.animations) {
        for (const keyframe of animation.keyframes) {
            if (keyframe.stateId === stateId) {
                return animation;
            }
        }
    }
}

export function isMainStateKeyframe(keyframe: IAnimationKeyframe): boolean {
    return keyframe.stateId === undefined;
}

export function getSettingsOfAnimations(animations: IAnimation[]): IAnimationSettings {
    const settings: IAnimationSettings = {} as IAnimationSettings;
    animations.forEach(a => {
        if (a.settings) {
            for (const key in a.settings) {
                // TODO: duplicate or keep reference?
                settings[key] = a.settings[key];
            }
        }
    });
    return settings;
}

export function getNameOfAnimations(animations: IAnimation[]): string {
    if (animations && animations.length) {
        const names = animations.map(a => a.name).filter(name => name);
        if (names.length) {
            return names.join(', ');
        }
        const type = capitalize(animations[0].type || '');
        return animations.length === 1 ? `${type} animation` : `${type} animations`;
    }
    return 'No animation';
}

export function sortByTime(a: ITime, b: ITime): number {
    return a.time - b.time || (a.duration || 0) - (b.duration || 0);
}

export function sortByTimeReversed(a: ITime, b: ITime): number {
    return b.time - a.time || (b.duration || 0) - (a.duration || 0);
}

export function sortAnimations(a: IAnimation, b: IAnimation): number {
    return getAnimationSortPrio(b) - getAnimationSortPrio(a);
}

function getAnimationSortPrio(animation: IAnimation): number {
    if (animation.type === 'in') {
        return 1;
    }
    if (animation.type === 'out') {
        return 0;
    }
    return -1;
}

export function validateAnimations(element: OneOfElementDataNodes): void {
    let inDuration = 0;
    let outDuration = 0;

    element.animations.forEach(a => {
        const duration = getAnimationDuration(a);
        const first = a.keyframes[0];
        const last = a.keyframes[a.keyframes.length - 1];
        const tolerance = 0.00001;

        a.keyframes.forEach(k => {
            if (!k.id) {
                throw new Error('Keyframe is missing id');
            }

            if (k.time + k.duration > element.duration + 0.01 || k.time < 0) {
                throw new Error('Invalid keyframe time');
            }
        });

        if (a.type === 'out') {
            if (
                Math.abs(first.time - (element.duration - duration)) > tolerance ||
                Math.abs(element.duration - (last.time + last.duration)) > tolerance
            ) {
                handleError('Invalid out animation', {
                    level: 'warning',
                    contexts: {
                        animation: a
                    }
                });
                fixTransitionKeyframesAlign(element, a);
            }
            outDuration = Math.max(outDuration, duration);
        } else if (a.type === 'in') {
            if (first.time !== 0) {
                handleError('Invalid in animation', {
                    level: 'warning',
                    contexts: {
                        animation: a
                    }
                });
                fixTransitionKeyframesAlign(element, a);
            }
            inDuration = Math.max(inDuration, duration);
        }
    });

    if (inDuration + outDuration > element.duration) {
        throw new Error('Overlapping durations');
    }
}

export function validateDuration(duration: number): boolean {
    if (typeof duration !== 'number' || !isFinite(duration) || duration < 0) {
        throw new Error(`Invalid duration "${duration}"`);
    }
    return true;
}

/**
 * Get an absolute time, a value releative to time 0 of timeline.
 * Expects a time relative to an element to be passed as argument.
 * Like keyframe.time
 * @param element
 * @param relativeTime
 */
export function toAbsoluteTime(element: OneOfElementDataNodes, relativeTime: number): number {
    return element.time + relativeTime;
}

/**
 * Get a relative time, a value releative to time of an element.
 * Expect a time relative to the start of timeline as argument.
 * Like element.time + keyframe.time
 * @param element
 * @param relativeTime
 */
export function toRelativeTime(element: OneOfElementDataNodes, absoluteTime: number): number {
    return absoluteTime - element.time;
}

/**
 * Get "leftmost" point in time of multiple objects
 * @param times
 */
export function getTime(...times: ITime[]): number {
    if (!times?.length) {
        return 0;
    }
    return Math.min(...times.map(t => t.time));
}

/**
 * Get total duration from multiple objects. IE: "rightmost" - "leftmost")
 * @param times
 */
export function getDuration(...times: ITime[]): number {
    if (!times?.length) {
        return 0;
    }
    const from = getTime(...times);
    const to = Math.max(...times.map(t => t.time + (t.duration || 0)));
    return to - from;
}

export function isTimeAt(time: ITime | number, target: ITime | number, tolerance = 0): boolean {
    return isTimeBetween(time, target, target, tolerance);
}

export function getEndTime(time: ITime): number {
    return time.time + (time.duration || 0);
}

export function isTimeBetween(
    time: ITime | number,
    t1: ITime | number,
    t2: ITime | number,
    tolerance = 0
): boolean {
    const t = toTimeAndDuration(time);
    const from = toTimeAndDuration(t1);
    const to = t1 === t2 ? from : toTimeAndDuration(t2);
    return from.time - tolerance <= t.time && t.time + t.duration <= to.time + to.duration + tolerance;
}

export function isExceedingElementDuration(
    keyframe: IAnimationKeyframe,
    element: OneOfElementDataNodes
): boolean {
    return keyframe.time + keyframe.duration > element.duration;
}

export function mergeOverlappingTimeAndDurations(times: ITime[], tolerance = 0): ITimeAndDuration[] {
    return times
        .sort(sortByTime)
        .reduce((r: ITimeAndDuration[], timeSpan) => {
            const last = r[r.length - 1];
            const lastEnd = last && getEndTime(last);

            // if (last && last.time <= timeSpan.time && timeSpan.time <= lastEnd) {
            if (last && isTimeBetween(timeSpan.time, last.time, lastEnd, tolerance)) {
                const end = getEndTime(timeSpan);
                if (lastEnd < end) {
                    last.duration = getDuration(last, timeSpan);
                }
                return r;
            }
            return r.concat(toTimeAndDuration(timeSpan));
        }, [])
        .sort(sortByTime);
}

export function getTimeBetween(k1: ITime, k2: ITime): number {
    const from = k1.time < k2.time ? k1 : k2;
    const to = k1.time < k2.time ? k2 : k1;
    return Math.max(0, to.time - (from.time + (from.duration || 0)));
}

export function getAbsoluteTimeBetween(k1: ITime, k2: ITime): number {
    const from = k1.time < k2.time ? k1 : k2;
    const to = k1.time < k2.time ? k2 : k1;
    return Math.max(0, to.time - from.time);
}

function numberToTime(time: ITime | number): ITime {
    return typeof time === 'number' ? { time } : time;
}

function toTimeAndDuration(time: ITime | number): ITimeAndDuration {
    if (typeof time === 'number') {
        return { time, duration: 0 };
    }
    if (time.duration === undefined) {
        time.duration = 0;
    }
    return time as ITimeAndDuration;
}

export function getMinDurationOfElements(...elements: OneOfElementDataNodes[]): number {
    const totalDuration = getDuration(...elements);
    const diff = Math.max(
        ...elements.map(element => getMinElementDuration(element) - element.duration),
        -totalDuration
    );

    return totalDuration + diff;
}

export function getMinElementDuration(element: OneOfElementDataNodes): number {
    const { animations } = element;

    const keyframeAnimations = getAnimationsOfType(element, 'keyframe');
    const inDuration = getInAnimationDuration(animations);
    const outDuration = getOutAnimationDuration(animations);

    const minInDuration = inDuration > 0 ? Math.max(MIN_ANIMATION_DURATION, inDuration) : 0;
    const minOutDuration = outDuration > 0 ? Math.max(MIN_ANIMATION_DURATION, outDuration) : 0;
    const minInOutDuration =
        minInDuration + minOutDuration > 0 ? minInDuration + IN_OUT_ANIMATION_GAP + minOutDuration : 0;

    let minKeyframeDuration = 0;

    if (keyframeAnimations.length) {
        const scales = keyframeAnimations
            .map(a => getMinAnimationDuration(a) / getAnimationDuration(a))
            .filter(s => s > 0);

        if (scales.length) {
            minKeyframeDuration = element.duration * (Math.max(...scales) || 0);
        }
    }
    return Math.max(minKeyframeDuration, minInOutDuration, MIN_ELEMENT_DURATION);
}

export function getMinAnimationDuration(animation: IAnimation): number {
    const { type, keyframes } = animation;
    const duration = getAnimationDuration(animation);
    let min = isTransitionType(type) ? MIN_ANIMATION_DURATION : 0;

    // Respect distancs between keyframes
    if (keyframes.length > 1) {
        const distances = keyframes.slice(1).map((k, i) => getTimeBetween(keyframes[i], k));

        const minDistance = Math.min(...distances);
        if (minDistance) {
            const scale = MIN_KEYFRAME_DISTANCE / minDistance;
            min = Math.max(min, scale * duration);
        }
    }
    return min;
}

export function getMaxAnimationDuration(element: OneOfElementDataNodes, animation: IAnimation): number {
    const { animations, duration } = element;
    const { type } = animation;

    if (isTransitionType(type)) {
        return (
            duration -
            (IN_OUT_ANIMATION_GAP + getAnimationDurationOfType(animations, invertInOutType(type)))
        );
    }
    return duration;
}

/**
 * Get the available duration a certain keyframe might expand within.
 * Pass fromCurrentTime = true if you want to keep current keyframe.time
 */
export function getMaxKeyframeDuration(
    element: OneOfElementDataNodes,
    animation: IAnimation,
    keyframe: IAnimationKeyframe,
    fromCurrentTime?: boolean
): number {
    const keyframes = animation.keyframes.filter(k => k !== keyframe);
    const time = keyframe.time;
    const next = getNextTime(keyframes, time);
    const prev = getPreviousTime(keyframes, time);
    const to = next ? next.time - MIN_KEYFRAME_DISTANCE : element.duration;
    let from = prev ? prev.time + prev.duration + MIN_KEYFRAME_DISTANCE : 0;

    if (fromCurrentTime) {
        from = keyframe.time;
    }

    return Math.max(0, to - from);
}

/**
 * Get timespans in which a property is animating
 * @param element
 * @param properties
 * @returns
 */
export function getTimePropertyIsAnimating(
    element: OneOfElementDataNodes,
    ...properties: OneOfStatePropertyKeys[]
): ITimeAndDuration[] {
    const times: ITimeAndDuration[] = [];

    // No properties provided, => use all properties instead
    if (!properties?.length) {
        properties = [...animatableProperties];
    }

    element.animations.forEach(animation => {
        const keyframes = animation.keyframes;
        keyframes.forEach((keyframe, index) => {
            const next = keyframes[index + 1];
            if (next) {
                const state = getStateById(element, keyframe.stateId) || {};
                const nextState = getStateById(element, next.stateId) || {};
                const change = properties.some(p => !deepEqual(state[p], nextState[p]));

                if (change) {
                    const duration = getTimeBetween(keyframe, next);
                    times.push({ time: toAbsoluteTime(element, getEndTime(keyframe)), duration });
                }
            }
        });
    });

    return mergeOverlappingTimeAndDurations(times);
}

/**
 * Get total time and duration from multiple objects.
 * Basically the bounding box
 * @param times
 */
export function getTimeAndDuration(...times: ITime[]): ITimeAndDuration {
    return {
        time: getTime(...times),
        duration: getDuration(...times)
    };
}

export const inAnimationFilter = (animation: IAnimation): boolean => animation.type === 'in';
export const outAnimationFilter = (animation: IAnimation): boolean => animation.type === 'out';
export const transitionAnimationFilter = (animation: IAnimation): boolean =>
    animation.type === 'out' || animation.type === 'in';

export function isTransitionAnimation(animation: IAnimation): boolean {
    return isTransitionType(animation.type);
}

export function isTransitionType(type?: AnimationType): type is 'in' | 'out' {
    return type === 'in' || type === 'out';
}

export function isTransitionEdgeKeyframe(animation: IAnimation, keyframe: IAnimationKeyframe): boolean {
    const { type, keyframes } = animation;
    if (isTransitionType(type)) {
        const index = keyframes.indexOf(keyframe);
        const edgeIndex = type === 'in' ? 0 : keyframes.length - 1;
        return index === edgeIndex;
    }

    return false;
}

export function isAnimation(
    animationOrKeyframe: IAnimation | IAnimationKeyframe | IAnimationKeyframe[]
): animationOrKeyframe is IAnimation {
    return 'keyframes' in animationOrKeyframe;
}

export function getCustomFPSInterval(fps: string | undefined): number | undefined {
    if (!fps) {
        return;
    }

    const customFPS = parseInt(fps, 10);

    if (isValidFPS(customFPS)) {
        return 1000 / customFPS;
    }
}

function isValidFPS(fps: number): boolean {
    return Number.isInteger(fps) && Number.isFinite(fps) && fps > 0;
}

function invertInOutType(type: 'in' | 'out'): 'in' | 'out' {
    if (type !== 'in' && type !== 'out') {
        throw new Error('Can only invert in/out types');
    }
    return type === 'in' ? 'out' : 'in';
}

function filterKeyframePropertiesFromKeyframeTemplate(template: KeyframeTemplate): IState {
    return omit(template, ...keyframeProperties);
}

export function distributeKeyframes(
    element: OneOfElementDataNodes,
    animation: IAnimation,
    keyframes: IAnimationKeyframe[]
): IAnimationKeyframe[] {
    const sortedKeyframes = [...keyframes].sort(sortByTime);

    const firstKeyframe = sortedKeyframes[0];
    const lastKeyframe = sortedKeyframes[sortedKeyframes.length - 1];

    const middleKeyframes = sortedKeyframes.slice(1, sortedKeyframes.length - 1);
    const delta = (lastKeyframe.time - firstKeyframe.time) / (middleKeyframes.length + 1);

    middleKeyframes.forEach((keyframe, index) => {
        moveKeyframeTo(element, animation, keyframe, firstKeyframe.time + delta * (index + 1));
    });

    return middleKeyframes;
}

interface ISurroundingKeyframes {
    after?: IAnimationKeyframe;
    before?: IAnimationKeyframe;
}
