<studio-ui-section
    headline="Weight breakdown"
    [collapsable]="true">
    <div
        id="interaction-size-breakdown"
        class="breakdown-wrapper">
        <div
            [class.hidden]="!hasWeights"
            class="circle-wrapper">
            <canvas id="size-breakdown-canvas"></canvas>
            <div class="circle-text">
                <span [uiTooltip]="'* This is an estimate. Final weight may vary'"> Total * </span>
                <span class="totalSize">
                    {{ graphWeight | uiFormatBytes: 0 }}
                </span>
                <span> Initial load: {{ initialLoadWeight | uiFormatBytes: 0 }} </span>
            </div>
        </div>
        <div
            *ngIf="hasWeights"
            class="breakdown-container">
            <div>
                <div class="breakdown-text">
                    <div>
                        <span>Initial load</span>
                        <ui-svg-icon
                            [icon]="showInitialList ? 'collapse' : 'expand'"
                            class="toggleIcon"
                            (click)="showInitialList = !showInitialList">
                        </ui-svg-icon>
                    </div>
                    <div>
                        <span class="dot yellow"></span>
                        <span>{{ initialLoadWeight | uiFormatBytes: 0 }}</span>
                    </div>
                </div>
                <div
                    *ngIf="showInitialList"
                    class="breakdown-list">
                    <div *ngFor="let asset of initialAssets">
                        <span [uiTooltip]="asset.name">{{ asset.name }}</span>
                        <span>{{ asset.weight | uiFormatBytes: 0 }}</span>
                    </div>
                </div>
            </div>
            <div class="breakdown-text">
                <span>HTML, Script, Widgets</span>
                <div>
                    <span class="dot red"></span>
                    <span>{{ textWeight | uiFormatBytes: 0 }}</span>
                </div>
            </div>
            <div>
                <div class="breakdown-text">
                    <div>
                        <span>Images</span>
                        <ui-svg-icon
                            *ngIf="showImageToggle"
                            [icon]="showImageList ? 'collapse' : 'expand'"
                            class="toggleIcon"
                            (click)="showImageList = !showImageList">
                        </ui-svg-icon>
                    </div>
                    <div>
                        <span class="dot green"></span>
                        <span>{{ imageWeight | uiFormatBytes: 0 }}</span>
                    </div>
                </div>
                <div
                    *ngIf="showImageList"
                    class="breakdown-list">
                    <div *ngFor="let asset of imageAssets">
                        <span [uiTooltip]="asset.name">{{ asset.name }}</span>
                        <span>{{ asset.weight | uiFormatBytes: 0 }}</span>
                    </div>
                </div>
            </div>
            <div>
                <div class="breakdown-text">
                    <div>
                        <span>Fonts</span>
                        <ui-svg-icon
                            *ngIf="showFontToggle"
                            [icon]="showFontList ? 'collapse' : 'expand'"
                            class="toggleIcon"
                            (click)="showFontList = !showFontList">
                        </ui-svg-icon>
                    </div>
                    <div>
                        <span class="dot blue"></span>
                        <span>{{ fontWeight | uiFormatBytes: 0 }}</span>
                    </div>
                </div>
                <div
                    *ngIf="showFontList"
                    class="breakdown-list">
                    <div *ngFor="let asset of fontAssets">
                        <span [uiTooltip]="asset.name">{{ asset.name }}</span>
                        <span>{{ asset.weight | uiFormatBytes: 0 }}</span>
                    </div>
                </div>
            </div>
            <div *ngIf="showVideo">
                <div class="breakdown-text">
                    <div>
                        <span>Video</span>
                        <ui-svg-icon
                            *ngIf="showVideoToggle"
                            [icon]="showVideoList ? 'collapse' : 'expand'"
                            class="toggleIcon"
                            (click)="showVideoList = !showVideoList">
                        </ui-svg-icon>
                    </div>
                    <div>
                        <ui-svg-icon
                            icon="question-mark"
                            [uiTooltipPosition]="'bottom'"
                            [uiTooltipHideArrow]="true"
                            [uiTooltipWidth]="290"
                            [uiTooltip]="
                                'Videos are streamed to the end-users to ensure fast and efficient delivery.
                        This weight indication shows the non-streamed, total file-size of the video, and is not included in the diagram.'
                            "></ui-svg-icon>
                        <span>{{ videoWeight | uiFormatBytes: 0 }}</span>
                    </div>
                </div>
                <div
                    *ngIf="showVideoList"
                    class="breakdown-list">
                    <div *ngFor="let asset of videoAssets">
                        <span [uiTooltip]="asset.name">{{ asset.name }}</span>
                        <span>{{ asset.weight | uiFormatBytes: 0 }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div>
                <ui-button
                    *ngIf="showCalcButton"
                    type="discrete"
                    [text]="calcButtonDisplayText"
                    [icon]="buttonIcon"
                    id="interaction-calc-weight-btn"
                    (click)="calculateButtonClick()"
                    [loading]="loading">
                </ui-button>
            </div>
            <div *ngIf="showInfoSaveText">
                <div
                    class="saveText"
                    *ngIf="showUpdateText">
                    Save your creative to be able to update weight calculation
                </div>
                <div
                    class="saveText"
                    *ngIf="showSaveText">
                    Save your creative to enable weight calculation
                </div>
            </div>
        </div>
    </div>
</studio-ui-section>
