<!-- Fill -->
<studio-ui-section
    headline="Fill"
    id="default-property-fill"
    [clickableHeader]="fill.value === undefined"
    [empty]="fill.value === undefined && !fill.isMixed"
    (headerClick)="addFill()"
    [actions]="[
        { id: 'add-fill-button', icon: 'plus-small', action: addFill, hidden: fill.value !== undefined }
    ]">
    <div
        *ngIf="shouldShowFillProperty && isNumberOfFillsEqual; else propertyPlaceholder"
        class="setting-value col-4 slider">
        <color-button
            [color]="fill.value ?? colorPlaceholder"
            [colorMixed]="fill.isMixed"
            (click)="colorService.toggleColorPicker('defaultFill')"
            data-test-id="color-button">
        </color-button>

        <div></div>

        <studio-ui-slider
            [value]="fill.value ? fill.value.alpha : 0"
            [config]="{ min: inputValidation.alpha.min, max: inputValidation.alpha.max }"
            (mouseUp)="updateFill(fill.value, ElementChangeType.Force)"
            (valueChange)="setFillAlpha($event, ElementChangeType.Skip)"></studio-ui-slider>
        <div></div>
        <ui-number-input
            unitLabel="%"
            [value]="fill.value ? fill.value.alpha : undefined"
            [keyboardEmit]="true"
            [allowEmpty]="elements.length > 1"
            [min]="inputValidation.alpha.min"
            [max]="inputValidation.alpha.max"
            [btnUpDisabled]="fill.value?.alpha === inputValidation.alpha.max"
            [btnUpDisabled]="fill.value?.alpha === inputValidation.alpha.min"
            placeholder="-"
            (undo)="emitUndo()"
            (redo)="emitRedo()"
            (valueChange)="setFillAlpha($event)"
            [disableUndo]="true"></ui-number-input>
        <div></div>
        <ui-svg-icon
            id="remove-fill-button"
            class="remove-button"
            icon="delete"
            (mousedown)="clearFill()"></ui-svg-icon>
        <ng-template #empty>
            <div></div>
        </ng-template>
    </div>

    <div
        class="color-picker"
        *ngIf="shouldShowFillProperty"
        data-test-id="color-picker">
        <section-expand
            *ngIf="'defaultFill' | picker | async"
            arrowPosition="33px"
            [showBackground]="true"
            [removeContentWhenCollapsed]="false"
            [expanded]="true">
            <color-section
                name="defaultFill"
                [allowGradient]="true"
                [preventCloseElements]="['#default-property-fill']"
                [color]="fill.value ?? colorPlaceholder"
                [colorMixed]="fill.isMixed"
                (onColorChange)="setFill($event, ElementChangeType.Skip)"
                (onColorChanged)="setFill($event, ElementChangeType.Force)"
                (preview)="previewFill($event)"
                (previewStop)="previewFillStop()">
            </color-section>
        </section-expand>
    </div>
</studio-ui-section>

<!-- Border -->
<studio-ui-section
    id="default-property-border"
    headline="Border"
    [clickableHeader]="border === undefined"
    (headerClick)="addBorder()"
    [empty]="!border && !bordersMixed"
    [actions]="[
        { id: 'add-border-button', icon: 'plus-small', action: addBorder, hidden: border !== undefined }
    ]">
    <div
        class="setting-value col-4 input"
        *ngIf="border; else propertyPlaceholder">
        <color-button
            [color]="border.color"
            [colorMixed]="borderColorMixed"
            (click)="colorService.toggleColorPicker('defaultBorder')"
            data-test-id="color-button"></color-button>

        <div></div>
        <ui-select
            theme="tiny"
            id="border-type"
            [useTargetWidth]="true"
            [selected]="border!.style === undefined ? 'Mixed' : border!.style"
            (selectedChange)="updateBorderStyle($event)">
            <ng-container *ngIf="border!.style === undefined">
                <ui-option
                    [value]="'Mixed'"
                    [selected]="true">
                    Mixed
                </ui-option>
                <div class="option-divider"></div>
            </ng-container>
            <ui-option
                class="border-type-option"
                *ngFor="let style of ['solid', 'dashed', 'dotted']"
                [value]="style"
                >{{ style.charAt(0).toUpperCase() + style.slice(1) }}</ui-option
            >
        </ui-select>
        <div></div>
        <ui-number-input
            id="borderThickness"
            [(value)]="border!.thickness"
            [min]="inputValidation.borderThickness.min"
            [max]="inputValidation.borderThickness.max"
            [btnUpDisabled]="border!.thickness === inputValidation.borderThickness.max"
            [btnDownDisabled]="border!.thickness === inputValidation.borderThickness.min"
            [allowEmpty]="true"
            (valueChange)="updateBorderThickness($event)"
            [keyboardEmit]="true"
            [placeholder]="'-'"
            (undo)="emitUndo()"
            (redo)="emitRedo()"
            [disableUndo]="true"></ui-number-input>
        <div></div>
        <ui-svg-icon
            id="remove-border-button"
            class="remove-button"
            icon="delete"
            (click)="removeBorder()"></ui-svg-icon>
    </div>
    <div
        *ngIf="border"
        class="color-picker"
        data-test-id="color-picker">
        <section-expand
            *ngIf="'defaultBorder' | picker | async"
            arrowPosition="33px"
            [showBackground]="true"
            [removeContentWhenCollapsed]="false"
            [expanded]="true">
            <color-section
                name="defaultBorder"
                [preventCloseElements]="['#default-property-border', '.border-type-option']"
                [color]="border.color"
                [colorMixed]="borderColorMixed"
                (onColorChanged)="setBorderColor($event)"
                (preview)="previewBorder($event)"
                (previewStop)="updateBorderColor($event, ElementChangeType.Skip)">
            </color-section>
        </section-expand>
    </div>
</studio-ui-section>

<!-- Shadow -->
<studio-ui-section
    id="default-property-shadow"
    headline="Box Shadow"
    *ngIf="showFill"
    [clickableHeader]="true"
    [empty]="!shadows?.length && isNumberOfBoxShadowsEqual"
    (headerClick)="addShadow()"
    [actions]="[{ id: 'add-shadow-button', icon: 'plus-small', action: addShadow, hidden: false }]">
    <ng-container *ngIf="isNumberOfBoxShadowsEqual; else propertyPlaceholder">
        <ng-container
            *ngFor="let shadowItem of shadows; let i = index; let last = last; trackBy: trackByShadow">
            <div
                class="setting-value col-3 shadow margin-bottom"
                [ngClass]="{ last: last }">
                <color-button
                    [color]="shadowItem.color ?? colorPlaceholder"
                    [id]="'color-btn-' + i"
                    [colorMixed]="shadowItem.isColorMixed"
                    (click)="colorService.toggleColorPicker('defaultShadow' + i)"
                    data-test-id="color-button"></color-button>
                <div></div>
                <div
                    class="input-group setting-value col-4 shadow"
                    [class.hide-spread]="!spreadSupport">
                    <ui-number-input
                        [(value)]="shadowItem.offsetX"
                        (valueChange)="updateShadow(ElementChangeType.Burst)"
                        [keyboardEmit]="true"
                        [id]="'shadow-offsetX-' + i"
                        [min]="inputValidation.shadowOffset.min"
                        [max]="inputValidation.shadowOffset.max"
                        [btnUpDisabled]="shadowItem.offsetX === inputValidation.shadowOffset.max"
                        [btnDownDisabled]="shadowItem.offsetX === inputValidation.shadowOffset.min"
                        data-test-id="shadow-offsetX"
                        (undo)="emitUndo()"
                        (redo)="emitRedo()"
                        [disableUndo]="true"
                        [placeholder]="'-'"
                        [allowEmpty]="elements.length > 1"></ui-number-input>
                    <ui-number-input
                        class="ui-number-input-no-radius"
                        [(value)]="shadowItem.offsetY"
                        (valueChange)="updateShadow(ElementChangeType.Burst)"
                        [keyboardEmit]="true"
                        [min]="inputValidation.shadowOffset.min"
                        [max]="inputValidation.shadowOffset.max"
                        [btnUpDisabled]="shadowItem.offsetY === inputValidation.shadowOffset.max"
                        [btnDownDisabled]="shadowItem.offsetY === inputValidation.shadowOffset.min"
                        data-test-id="shadow-offsetY"
                        [id]="'shadow-offsetY-' + i"
                        (undo)="emitUndo()"
                        (redo)="emitRedo()"
                        [disableUndo]="true"
                        [placeholder]="'-'"
                        [allowEmpty]="elements.length > 1"></ui-number-input>
                    <ui-number-input
                        [ngClass]="{
                            'ui-number-input-last': !spreadSupport,
                            'ui-number-input-no-radius': spreadSupport
                        }"
                        [(value)]="shadowItem.blur"
                        (valueChange)="updateShadow(ElementChangeType.Burst)"
                        [keyboardEmit]="true"
                        [id]="'shadow-blur-' + i"
                        data-test-id="shadow-blur"
                        [min]="inputValidation.shadowBlur.min"
                        [max]="inputValidation.shadowBlur.max"
                        [btnUpDisabled]="shadowItem.blur === inputValidation.shadowBlur.max"
                        [btnDownDisabled]="shadowItem.blur === inputValidation.shadowBlur.min"
                        (undo)="emitUndo()"
                        (redo)="emitRedo()"
                        [disableUndo]="true"
                        [placeholder]="'-'"
                        [allowEmpty]="elements.length > 1"></ui-number-input>
                    <ui-number-input
                        class="ui-number-input-last"
                        [style.visibility]="spreadSupport ? 'visible' : 'hidden'"
                        [(value)]="shadowItem.spread"
                        (valueChange)="updateShadow(ElementChangeType.Burst)"
                        [keyboardEmit]="true"
                        data-test-id="shadow-spread"
                        [id]="'shadow-spread-' + i"
                        [min]="0"
                        [max]="100"
                        (undo)="emitUndo()"
                        (redo)="emitRedo()"
                        [disableUndo]="true"
                        [placeholder]="'-'"
                        [allowEmpty]="elements.length > 1"></ui-number-input>
                </div>
                <div></div>
                <ui-svg-icon
                    id="remove-shadow-button"
                    class="remove-button"
                    icon="delete"
                    (mousedown)="clearShadow(shadowItem)"></ui-svg-icon>
            </div>
            <div
                class="setting-value col-3 shadow"
                *ngIf="shadows && shadows.length > 0 && last">
                <div></div>
                <div></div>
                <div
                    class="input-group-text setting-value col-4 shadow"
                    [class.hide-spread]="!spreadSupport">
                    <div>X</div>
                    <div>Y</div>
                    <div>B</div>
                    <div [style.visibility]="spreadSupport ? 'visible' : 'hidden'">S</div>
                </div>
                <div></div>
                <div></div>
            </div>
            <div
                class="color-picker shadow"
                *ngIf="'defaultShadow' + i | picker | async"
                data-test-id="color-picker">
                <section-expand
                    arrowPosition="33px"
                    [showBackground]="true"
                    [removeContentWhenCollapsed]="false"
                    [expanded]="true">
                    <color-section
                        [name]="'defaultShadow' + i"
                        [preventCloseElements]="['#default-property-shadow']"
                        [color]="shadowItem.color ?? colorPlaceholder"
                        [colorMixed]="shadowItem.isColorMixed"
                        (onColorChange)="setShadow($event, i)"
                        (onColorChanged)="updateShadow()"
                        (preview)="previewShadow(i, $event)"
                        (previewStop)="updateShadow(ElementChangeType.Skip)">
                    </color-section>
                </section-expand>
            </div>
        </ng-container>
    </ng-container>
</studio-ui-section>

<!-- Filters -->
<studio-ui-section
    id="default-property-filter"
    headline="Filters"
    [clickableHeader]="true"
    (headerClick)="triggerButton($event)"
    [customAction]="addFilterTemplate">
    <ng-container *ngIf="isFiltersEqual; else propertyPlaceholder">
        @for (filter of filters; track filter.id; let i = $index) {
            <div
                *ngIf="filter.selected"
                [id]="'filter-setting-row-' + i"
                class="setting-row with-icon"
                [uiTooltip]="filter.name">
                <ui-svg-icon [icon]="filter.icon"></ui-svg-icon>
                <div class="setting-value col-3 filter wide">
                    <div class="property-input slider">
                        <studio-ui-slider
                            [value]="
                                elements.length > 1 || filter.value !== undefined
                                    ? filter.value!
                                    : filter.default || 0
                            "
                            (valueChange)="setFilter(filter.id, $event)"
                            (mouseUp)="updateFilters()"
                            [config]="{
                                min: filter.min,
                                max: filter.max,
                                accuracy: 1
                            }"></studio-ui-slider>
                    </div>
                    <div class="property-input">
                        <ui-number-input
                            id="filter-input"
                            [value]="
                                filter.isMixed || filter.value !== undefined
                                    ? filter.value
                                    : filter.default || 0
                            "
                            [btnUpDisabled]="filter.value === filter.max"
                            [btnDownDisabled]="filter.value === filter.min"
                            (valueChange)="setFilter(filter.id, $event, ElementChangeType.Burst)"
                            [keyboardEmit]="true"
                            [min]="filter.min"
                            [max]="filter.max"
                            [disableUndo]="true"
                            [placeholder]="
                                elements.length > 1 && !filter.value ? '-' : '' + (filter.default || 0)
                            "
                            [unitLabel]="filter.unit"></ui-number-input>
                    </div>
                    <ui-svg-icon
                        id="remove-filter-button"
                        class="remove-button"
                        icon="delete"
                        (mousedown)="clearFilter(filter.id)"></ui-svg-icon>
                </div>
            </div>
        }
    </ng-container>
</studio-ui-section>

<ui-dropdown
    #dropdown
    [offset]="{ x: 20, y: 0 }"
    [positions]="[
        { originX: 'start', originY: 'bottom', overlayX: 'end', overlayY: 'top' },
        { originX: 'start', originY: 'top', overlayX: 'end', overlayY: 'bottom' }
    ]"
    type="menu">
    <ui-dropdown-item
        *ngFor="let filter of filters"
        [id]="'interaction-filters-' + filter.id"
        (click)="addFilter(filter.id)"
        [svgIcon]="filter.icon"
        [disabled]="filter.selected">
        {{ filter.name }}
    </ui-dropdown-item>
</ui-dropdown>

<ng-template #addFilterTemplate>
    <ng-container>
        <div
            #customDropdown
            class="add-filter">
            <ui-svg-icon
                [uiDropdownTarget]="dropdown"
                (click)="dropDownButtonClicked($event)"
                [style.width]="'20px'"
                class="icon"
                icon="plus-small"></ui-svg-icon>
        </div>
    </ng-container>
</ng-template>
<ng-template #propertyPlaceholder>
    <p class="placeholder-text">Click + to replace mixed properties</p>
</ng-template>
