import { isMaskedNode } from '@creative/nodes/helpers';
import { OneOfMaskableElementDataNodes } from '@domain/mask';
import { cloneDeep } from '@studio/utils/clone';

type MaskingRenderMapValue = {
    /** All elements in the same mask */
    elements: Set<string>;
    /** Whether the mask was changed on the previous render pass */
    changedOnPreviousPass: boolean;
};

export class MaskingRenderMap {
    /** Map<"Mask element id", MaskingRenderMapValue> */
    private _renderMap = new Map<string, MaskingRenderMapValue>();

    set_m(
        element: OneOfMaskableElementDataNodes,
        changedOnPreviousPass: boolean
    ): MaskingRenderMapValue {
        const key = this._getKey(element);
        const mappedMask: MaskingRenderMapValue = this._renderMap.get(key) ?? {
            elements: new Set<string>(),
            changedOnPreviousPass
        };

        this._renderMap.set(key, {
            elements: mappedMask.elements.add(key),
            changedOnPreviousPass
        });

        return mappedMask;
    }

    get_m(element: OneOfMaskableElementDataNodes): MaskingRenderMapValue | undefined {
        const key = this._getKey(element);
        const value = this._renderMap.get(key);

        return cloneDeep(value);
    }

    delete_m(element: OneOfMaskableElementDataNodes): void {
        const key = this._getKey(element);
        this._renderMap.delete(key);
        this._renderMap.get(key)?.elements.delete(key);
    }

    private _getKey(element: OneOfMaskableElementDataNodes): string {
        return isMaskedNode(element) ? (element.masking.elementId ?? '') : element.id;
    }

    clear_m(): void {
        this._renderMap.clear();
    }
}
