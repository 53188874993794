import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { UIDialogService, UIModule } from '@bannerflow/ui';
import { IBfFeed, IFeed } from '@domain/feed';
import { FeedService } from '../feed.service';
import { FeedBrowserComponent } from './feed-browser.component';

@Component({
    standalone: true,
    imports: [CommonModule, UIModule],
    selector: 'feed-picker',
    templateUrl: 'feed-picker.component.html',
    styleUrls: ['feed-picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedPickerComponent implements OnInit, OnChanges {
    @Output() feedSelectionChanged: EventEmitter<IBfFeed> = new EventEmitter<IBfFeed>();
    @Input() value: IFeed;
    @Input() minimized = false;
    @Input() mixed = false;
    @Input() disablePopoverInputs = false;

    feeds: IBfFeed[];
    selectedFeed?: IBfFeed;
    name: string;

    constructor(
        private feedService: FeedService,
        private uiDialogService: UIDialogService,
        private changeDetector: ChangeDetectorRef
    ) {}

    async ngOnInit(): Promise<void> {
        const feeds = await this.feedService.getFeeds();
        this.feeds = feeds;
        if (!this.mixed && this.value?.id) {
            this.findSelectedFeed(this.value.id);
        }
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes?.value?.currentValue) {
            await this.findSelectedFeed(changes.value.currentValue.id);
        }
    }

    async findSelectedFeed(id: string): Promise<void> {
        this.selectedFeed = (await this.feedService.getFeeds()).find(feed => feed.id === id);
        if (!this.selectedFeed) {
            this.selectedFeed = await this.feedService.getNestedFeed(id);
        }

        this.name = this?.selectedFeed?.name || '';

        if (this.mixed) {
            this.name = 'Mixed';
        }

        this.changeDetector.markForCheck();
    }

    onSelectionCallback = (selectedFeed: IBfFeed): void => {
        if (selectedFeed) {
            this.feedSelectionChanged.emit(selectedFeed);
            this.findSelectedFeed(this.value.id);
        }
    };

    async openDialog(): Promise<void> {
        if (!this.feeds) {
            this.feeds = await this.feedService.getFeeds();
        }
        this.uiDialogService.openComponent(FeedBrowserComponent, {
            headerText: 'Select feed',
            panelClass: 'fullscreen',
            theme: 'default',
            data: {
                callback: this.onSelectionCallback,
                feeds: this.feeds,
                selectedFeed: this.mixed ? undefined : this.selectedFeed
            }
        });
    }
}
