<div class="select">
    @if (labels) {
        <div class="select-label">Font</div>
    }
    <ui-select
        #fontFamilySelect
        id="font-family"
        [searchable]="searchable()"
        [useTargetWidth]="true"
        [uiTooltip]="selectedFontFamily?.name || ''"
        [uiTooltipDisabled]="!selectedFontFamily?.name"
        [selected]="selectedFontFamilyId === '$mixed' ? 'Mixed' : selectedFontFamily"
        (selectedChange)="onFontFamilyChanged($event)"
        (previewChange)="onPreviewFontFamily($event)"
        (previewStop)="stopCurrentPreview()"
        [placeholder]="fontFamilyPlaceholder"
        [backdropCss]="'prevent-text-blur'"
        [disabled]="disabled || filteredFontFamilies.length === 0"
        (onOpen)="selectOpen()">
        <div (mouseleave)="stopCurrentPreview()">
            @if (selectedFontFamilyId === '$mixed') {
                <ui-option
                    [value]="'Mixed'"
                    [selected]="true">
                    Mixed
                </ui-option>
                <div class="option-divider"></div>
            }
            @for (fontFamily of filteredFontFamilies || []; track fontFamily.id) {
                <ui-option
                    (mousedown)="mousedown.emit($event)"
                    [value]="fontFamily">
                    {{ fontFamily.name }}
                </ui-option>
            }
            <div class="buttons">
                <toolbar-button
                    icon="settings"
                    width="100%"
                    [iconSize]="14"
                    (click)="openFontManager()" />
            </div>
        </div>
    </ui-select>
</div>
<div class="select">
    @if (labels) {
        <div class="select-label">Style</div>
    }
    <ui-select
        #fontStyleSelect
        id="font-style"
        [useTargetWidth]="true"
        [searchable]="searchable()"
        [uiTooltip]="selectedFontStyle?.name || ''"
        [uiTooltipDisabled]="!selectedFontStyle?.name"
        [placeholder]="fontStylePlaceholder"
        [selected]="selectedFontStyleId === '$mixed' ? 'Mixed' : selectedFontStyle"
        [disabled]="disabled || !filteredFontFamilies"
        [backdropCss]="'prevent-text-blur'"
        (selectedChange)="onFontStyleChanged($event)"
        (previewChange)="onPreviewFontStyle($event)"
        (previewStop)="stopCurrentPreview()"
        (onOpen)="selectOpen()">
        <div (mouseleave)="stopCurrentPreview()">
            @if (selectedFontStyleId === '$mixed') {
                <ui-option
                    [value]="'Mixed'"
                    [selected]="true">
                    Mixed
                </ui-option>
                <div class="option-divider"></div>
            }
            @if (selectedFontFamily) {
                @for (
                    fontStyle of selectedFontFamily.fontStyles || [] | sortArray: { weight: 'asc' };
                    track fontStyle.id
                ) {
                    <ui-option
                        (mousedown)="mousedown.emit($event)"
                        [value]="fontStyle">
                        {{ fontStyle.name }}
                    </ui-option>
                }
            }
            <div class="buttons">
                <toolbar-button
                    icon="settings"
                    width="100%"
                    [iconSize]="14"
                    (click)="openFontManager()" />
            </div>
        </div>
    </ui-select>
</div>
