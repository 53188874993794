import { Injectable, OnDestroy, ViewContainerRef } from '@angular/core';
import { Color } from '@creative/color';
import { applyWidgetCodeOnWidgetNodes } from '@creative/elements/widget/utils';
import { Layouter } from '@creative/layout/layout';
import { CreativeDataNode, isImageNode, isVideoNode } from '@creative/nodes';
import { IRenderer } from '@creative/renderer.header';
import { cloneCreativeDocument } from '@creative/serialization';
import { CreativeSize, IDesign, IElement } from '@domain/creativeset';
import { ICreative } from '@domain/creativeset/creative';
import {
    IVersion,
    IVersionProperty,
    IVersionedText,
    OneOfVersionableProperties,
    VersionableElementProperty,
    VersionedElementProperty,
    VersionedPropertyUnit
} from '@domain/creativeset/version';
import { ISize } from '@domain/dimension';
import { ICreativeDataNode } from '@domain/nodes';
import { IEditorState } from '@domain/rich-text';
import { cloneDeep } from '@studio/utils/clone';
import { createDesign } from '@studio/utils/design.utils';
import { NotFoundError } from '@studio/utils/errors/apps-errors';
import { uuidv4 } from '@studio/utils/id';
import { hasMediaReference } from '@studio/utils/media';
import { ReplaySubject, Subject, firstValueFrom } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { CreativesetDataService } from '../../../shared/creativeset/creativeset.data.service';
import { VersionsService } from '../../../shared/versions/state/versions.service';
import { StudioTimelineComponent } from '../timeline';
import { MutatorService } from './mutator.service';

@Injectable()
export class EditorStateService implements OnDestroy, IEditorState {
    private _renderer$ = new ReplaySubject<IRenderer>(1);
    renderer$ = this._renderer$.asObservable().pipe(filter(v => typeof v !== 'undefined'));

    get elements(): IElement[] {
        return Object.freeze([...this._elements]) as IElement[];
    }
    private _elements: IElement[] = [];
    size: CreativeSize;
    designs: IDesign[] = [];
    versions: IVersion[];

    private selectedVersion: IVersion;

    private defaultVersion: IVersion;
    private _defaultVersionProperties: IVersionProperty[];
    get defaultVersionProperties(): IVersionProperty[] {
        this._defaultVersionProperties ??= [...this.defaultVersion.properties];
        return this._defaultVersionProperties;
    }

    private _versionProperties: IVersionProperty[];
    get versionProperties(): IVersionProperty[] {
        return this._versionProperties;
    }

    document: ICreativeDataNode;

    zoom = 1;
    sizeIsActive: boolean;
    designFork: IDesign;
    private _canvasSize: ISize;
    get canvasSize(): Readonly<ISize> {
        return this._canvasSize;
    }

    private _renderer: IRenderer;
    get renderer(): IRenderer {
        return this._renderer;
    }
    mutatorService: MutatorService;

    creative: ICreative;
    timeline: StudioTimelineComponent;
    __appComponentViewRef: ViewContainerRef;

    // move the currentVersion deps to rich text
    get currentVersion(): IVersion {
        if (!this.selectedVersion) {
            throw new Error('No version selected!');
        }
        return this.selectedVersion;
    }

    private _creativeChanged$ = new Subject<ICreative>();
    creativeChanged$ = this._creativeChanged$.asObservable();

    private unsubscribe$ = new Subject<void>();

    constructor(
        private creativesetDataService: CreativesetDataService,
        private versionsService: VersionsService
    ) {
        this.versionsService.selectedVersion$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(selectedVersion => {
                this.selectedVersion = selectedVersion;
            });

        this.versionsService.selectedVersionProperties$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(properties => {
                this._versionProperties = properties;
            });

        // this convenient mapping should not be needed
        this.versionsService.versions$.pipe(takeUntil(this.unsubscribe$)).subscribe(versions => {
            this.versions = versions;
        });

        this.versionsService.defaultVersion$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(defaultVersion => {
                this.defaultVersion = defaultVersion;
                this._defaultVersionProperties = [...defaultVersion.properties];
            });

        this.versionsService.defaultVersionProperties$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(properties => {
                this._defaultVersionProperties = [...properties];
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    async initCreative(creativeId: string): Promise<void> {
        // If design id is specified find the corresponding design in
        // the creative set, else create a new blank design

        const creatives = this.creativesetDataService.creativeset.creatives;
        const designs = this.creativesetDataService.creativeset.designs.filter(design => !!design);
        const creative = creatives.find(({ id }) => id === creativeId);

        if (!creative) {
            const error = new NotFoundError(`Could not find creative`);
            error.id = creativeId;
            throw error;
        }

        const size = creative.size;

        let design = creative.design;
        let originalDocumentId: string | undefined;

        if (design) {
            originalDocumentId = design.document.id;
            this.sizeIsActive = true;
        } else {
            const bestDesignToClone = Layouter.getBestDesignBasedOnSize(
                size,
                this.creativesetDataService.creativeset
            );
            const clonedElements = bestDesignToClone ? cloneDeep(bestDesignToClone.elements) : [];
            if (bestDesignToClone) {
                const layouter = new Layouter(bestDesignToClone.document, size);
                const copyDocument = CreativeDataNode.copy(layouter.newCreative);
                copyDocument.guidelines = [];
                design = createDesign({
                    name: bestDesignToClone.name,
                    document: copyDocument,
                    elements: clonedElements
                });
                originalDocumentId = bestDesignToClone.document.id;
            } else {
                const document = new CreativeDataNode({
                    width: size.width,
                    height: size.height,
                    id: uuidv4(),
                    fill: Color.parse('#ffffff')
                });
                design = createDesign({
                    document: document,
                    elements: clonedElements
                });
            }

            // Assign a new document id.
            this.sizeIsActive = false;
        }

        await this.setDesignFork(design);

        this.size = size;

        this._canvasSize = { width: this.size.width, height: this.size.height };

        if (!this.designs.length) {
            this.designs = cloneDeep(designs);
        }

        if (!this.versions) {
            this.versions = await firstValueFrom(this.versionsService.versions$);
        }

        if (originalDocumentId) {
            this.versionsService.copyStyleIdsBetweenDocuments({
                targetDocumentId: this.document.id,
                sourceDocumentId: originalDocumentId
            });
            this.versions = await firstValueFrom(this.versionsService.versions$);
        }

        const creativeChanged = this.creative && this.creative.id !== creative.id;
        this.creative = creative;

        if (creativeChanged) {
            this._creativeChanged$.next(creative);
        }
    }

    reset(): void {
        this.zoom = 1;
    }

    async setDesignFork(design: IDesign): Promise<void> {
        this.designFork = {
            ...cloneDeep(design),
            document: cloneCreativeDocument(design.document)
        };
        await applyWidgetCodeOnWidgetNodes(this.designFork);
        this.document = this.designFork.document;
        this.updateElements(this.designFork.elements);
    }

    updateNewElementAsset(
        oldAssetId: string,
        newAssetId: string,
        newURL: string,
        newSize: number
    ): void {
        this.document.elements.forEach(element => {
            if (isImageNode(element) && element.imageAsset?.id === oldAssetId) {
                element.imageAsset.url = newURL;
                element.imageAsset.id = newAssetId;
                element.imageAsset.__loading = false;
            } else if (isVideoNode(element) && element.videoAsset?.id === oldAssetId) {
                element.videoAsset.url = newURL;
                element.videoAsset.id = newAssetId;
                element.videoAsset.__loading = false;
                element.videoAsset.fileSize = newSize;
            }
        });

        this._elements.forEach(element => {
            element.properties.forEach(property => {
                if (hasMediaReference(property) && property.value === oldAssetId) {
                    property.value = newAssetId;
                }
            });
        });
    }

    setRenderer(renderer: IRenderer): void {
        this._renderer = renderer;
        this._renderer$.next(renderer);
    }

    // used by rich text editor
    updateVersionedText(
        versionId: string,
        versionPropertyId: string,
        value: Partial<IVersionedText>
    ): void {
        this.versionsService.updateVersionedText(versionId, versionPropertyId, value);
    }

    propertyAsVersionableProperty(
        property: VersionableElementProperty,
        unit: VersionedPropertyUnit
    ): VersionedElementProperty {
        const versionPropertyId = uuidv4();
        const epv: IVersionProperty = {
            id: versionPropertyId,
            name: unit,
            value: property.value as OneOfVersionableProperties
        };
        this.versionsService.addVersionProperty(this.defaultVersion.id, epv);
        property.value = '';
        property.versionPropertyId = versionPropertyId;
        return property as VersionedElementProperty;
    }

    updateElements(elements: IElement[]): void {
        this._elements = elements;
        this.designFork.elements = elements;
    }

    addElement(element: IElement): void {
        this._elements.push(element);
    }

    upsertDefaultVersionProperty(versionProperty: IVersionProperty): void {
        this._defaultVersionProperties = [
            ...this._defaultVersionProperties.filter(({ id }) => id !== versionProperty.id),
            versionProperty
        ];
    }

    updateVersionProperty(
        versionId: string,
        versionProperty: IVersionProperty<OneOfVersionableProperties>
    ): void {
        this.versionsService.upsertVersionProperty(versionId, versionProperty);
    }

    updateCanvasSize(size: ISize): void {
        this._canvasSize = size;
    }

    getElementById(elementId: string): IElement {
        const element = this.elements.find(({ id }) => id === elementId);

        if (!element) {
            throw new Error(`No element exists with ID '${elementId}' in the design element list.`);
        }

        return element;
    }
}
