import {
    ICreateVersionsResponse,
    ICreateVersionsVariables,
    IDeleteVersionsResponse,
    ISetDefaultVersionResponse,
    IUpdateVersionsResponse
} from '@domain/creativeset/version';
import { VERSION_FRAGMENT } from './creativeset.queries';
import { gql } from './gql.types';

export const CREATE_VERSION_MUTATION = gql<ICreateVersionsResponse, ICreateVersionsVariables>`
    mutation CreateVersionsMutation($versions: [CreateVersionInputType], $creativesetId: String!) {
        createVersions(versions: $versions, creativesetId: $creativesetId) {
            creatives {
                id
                design {
                    id
                }
                size {
                    id
                }
                version {
                    id
                }
                checksum
                creativeWeights {
                    weights {
                        url
                    }
                }
            }
            versions {
                ...VersionFragment
            }
        }
    }
    ${VERSION_FRAGMENT}
`;

export const UPDATE_VERSIONS_MUTATION = gql<IUpdateVersionsResponse, any>`
    mutation UpdateVersionsMutation(
        $versions: [UpdateVersionInputType]!
        $creatives: [UpdateCreativeInputType]!
        $creativesetId: String!
    ) {
        updateVersions(versions: $versions, creativesetId: $creativesetId, creatives: $creatives) {
            versions {
                ...VersionFragment
            }
        }
    }
    ${VERSION_FRAGMENT}
`;

export const DELETE_VERSIONS_MUTATION = gql<
    IDeleteVersionsResponse,
    { ids: string[]; creativesetId: string }
>`
    mutation DeleteVersionsMutation($ids: [String]!, $creativesetId: String!) {
        deleteVersions(ids: $ids, creativesetId: $creativesetId) {
            ids
        }
    }
`;

export const SET_DEFAULT_VERSION_MUTATION = gql<
    ISetDefaultVersionResponse,
    { versionId: string; creativesetId: string }
>`
    mutation SetDefaultVersionMutation($versionId: String!, $creativesetId: String!) {
        setDefaultVersion: setOriginalVersion(id: $versionId, creativesetId: $creativesetId) {
            ...VersionFragment
        }
    }
    ${VERSION_FRAGMENT}
`;

export const VERSION_DEFINITION = `{
    id
    name
    targetUrl
    properties {
        id
        value
        name
        __typename
    }
    localization {
        id
        __typename
    }
}
`;
