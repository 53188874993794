import { FiltersState } from '../../../shared/filters/state/filters.reducer';
import { ICreative, ApprovalStatus } from '@domain/creativeset/creative';

export function filterCreatives(
    creatives: ICreative[],
    sizes: FiltersState['sizes'],
    statuses: FiltersState['statuses']
): ICreative[] {
    return creatives.filter(creative => {
        if (!sizes.length && !statuses.length) {
            // Not filtering
            return true;
        }
        const {
            size: { id: sizeId }
        } = creative;
        if (sizes.length && !sizes.includes(sizeId)) {
            return false;
        }

        const approvalStatus = creative.approvalStatus || ApprovalStatus.NoStatus;
        if (statuses.length && !statuses.includes(approvalStatus)) {
            return false;
        }
        return true;
    });
}
