import { Injectable } from '@angular/core';
import { CreativesetDataService } from '../../creativeset/creativeset.data.service';
import { Logger } from '@bannerflow/sentinel-logger';
import { ICreative } from '@domain/creativeset/creative';
import { ICreativeWeight } from '@domain/creativeset/creative/weight';
import { BehaviorSubject, Subject, firstValueFrom, switchMap } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { WeightDataService } from '../weight.data.service';

@Injectable({ providedIn: 'root' })
export class WeightService {
    private _creativeWeights$ = new Subject<ICreativeWeight[]>();
    creativeWeights$ = this._creativeWeights$.asObservable();

    private _loadingCreativeWeights$ = new BehaviorSubject<string[]>([]);
    loadingCreativeWeights$ = this._loadingCreativeWeights$.asObservable();

    // all creative ids that are currently being calculated, if multi-calculation was started
    private currentCalculations: ICreative[] = [];
    private _checkWeights$ = new Subject<ICreative[]>();
    private logger = new Logger('WeightService');

    constructor(
        private creativesetDataService: CreativesetDataService,
        private weightDataService: WeightDataService
    ) {
        this._checkWeights$
            .pipe(
                map(creatives => {
                    this.currentCalculations.push(...creatives);
                    return this.currentCalculations;
                }),
                tap(creatives => {
                    const creativeIds = creatives.map(c => c.id);
                    this.logger.log(`Checking for calculated weights ${creativeIds.join(', ')}`);
                    this._loadingCreativeWeights$.next(creativeIds);
                }),
                switchMap(creatives => {
                    return this.weightDataService.checkForCalculatedWeights(
                        creatives.map(c => c.checksum!)
                    );
                })
            )
            .subscribe(creativeWeights => {
                if (creativeWeights) {
                    const creativeIds = creativeWeights.map(cw => cw.creativeId);
                    this.clearWeightLoading(creativeIds);
                    this._creativeWeights$.next(creativeWeights);
                }
            });
    }

    async beginWeightCalculation(creatives: ICreative[]): Promise<void> {
        const weightableCreatives = this.getWeightableCreatives(creatives);
        const creativeIds = weightableCreatives
            .filter(creative => !!creative.design)
            .map(creative => creative.id);
        this.logger.log(`Beginning weight calculation for creatives: ${creativeIds.join(', ')}`);

        const creativesetId = this.creativesetDataService.creativeset.id;
        await firstValueFrom(this.weightDataService.beginWeightCalculation(creativesetId, creativeIds));

        this.initWeightCalculationChecks(creatives);
    }

    initWeightCalculationChecks(creatives: ICreative[]): void {
        this._checkWeights$.next(creatives);
    }

    getWeightableCreatives(creatives: ICreative[]): ICreative[] {
        return creatives.filter(creative => {
            const weightChanged =
                !creative.creativeWeights ||
                !creative.creativeWeights.weights ||
                creative.creativeWeights.creativeChecksum !== creative.checksum;
            return weightChanged && creative.design && !this.isCreativeWeightLoading(creative.id);
        });
    }

    private isCreativeWeightLoading(creativeId: string): boolean {
        return this.currentCalculations.some(c => c.id === creativeId);
    }

    private clearWeightLoading(creativeIds: string[]): void {
        this.currentCalculations = this.currentCalculations.filter(c => !creativeIds.includes(c.id));
        this._loadingCreativeWeights$.next(this.currentCalculations.map(c => c.id));
    }
}
