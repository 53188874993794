<canvas-layer
    id="canvas"
    #canvas
    [ngClass]="{ hidden: !loaded }"
    [renderer]="mutatorService.renderer"
    [transparent]="transparent">
</canvas-layer>
<!-- This element is used to feature detect CSS image-set used in our custom cursors. DON'T TOUCH !!! -->
<div
    id="detect-image-set"
    style="
        background-image: -webkit-image-set(url(//) 1x, url(//) 2x);
        background-image: image-set(url(//) 1x, url(//) 2x);
    "></div>

<context-menu #contextMenu></context-menu>

<!-- Guideline Top -->
<guideline-wrapper
    *ngIf="!mutatorService.preview"
    [workspace]="this"></guideline-wrapper>
