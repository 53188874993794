import { Component } from '@angular/core';
import { isReservedAction } from '@creative/actions/actions.utils';
import { timingFunctions } from '@creative/timing-functions';
import { ActionOperationMethod, IAction } from '@domain/action';
import { OneOfElementDataNodes } from '@domain/nodes';
import { TimingFunctionKey } from '@domain/timing-functions';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EditorStateService } from '../../services/editor-state.service';
import { PropertiesService } from '../properties.service';

@Component({
    selector: 'reserved-state-settings',
    templateUrl: './reserved-state-settings.component.html',
    styleUrls: ['../common.scss']
})
export class ReservedStateSettingsComponent {
    inDuration: number;
    inEasing: TimingFunctionKey;
    outDuration: number;
    outEasing: TimingFunctionKey;
    timingFunctions = { ...timingFunctions };
    unsubscribe$ = new Subject<void>();
    inAction: IAction;
    outAction: IAction;

    el: OneOfElementDataNodes | undefined;
    reservedTemplateId: 'reserved-pressed' | 'reserved-hover' | undefined;
    stateId: string | undefined;

    constructor(
        private propertiesService: PropertiesService,
        private editorStateService: EditorStateService
    ) {
        this.propertiesService.selectedStateChange$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(state => {
                if (state && this.propertiesService.selectedElement) {
                    const element = this.propertiesService.selectedElement;
                    this.el = element;
                    this.stateId = state.id;
                    this.inAction = this.findInAction();

                    if (this.inAction) {
                        this.reservedTemplateId = this.inAction.templateId;

                        this.inDuration = this.inAction.operations[0].animation!.duration;
                        this.inEasing = this.inAction.operations[0].animation!.timingFunction;

                        this.outAction = this.findOutAction();
                        this.outDuration = this.outAction.operations[0].animation!.duration;
                        this.outEasing = this.outAction.operations[0].animation!.timingFunction;
                    }
                }
            });
    }

    private findInAction(): IAction {
        return this.propertiesService.selectedElement!.actions.find(
            action =>
                isReservedAction(action) && action.operations.find(ops => ops.value === this.stateId)
        )!;
    }

    private findOutAction(): IAction {
        return this.propertiesService.selectedElement!.actions.find(action => {
            if (action.templateId !== this.inAction.templateId) {
                return false;
            }
            return action.operations.find(
                ops =>
                    ops.method === ActionOperationMethod.RemoveState ||
                    // Fallback for already created reserved states
                    (ops.method === ActionOperationMethod.SetState && ops.value === undefined)
            );
        })!;
    }

    onDurationChange(type: 'in' | 'out', applyChange?: boolean): void {
        if (applyChange) {
            if (type === 'in') {
                this.findInAction().operations[0].animation!.duration = this.inDuration;
            } else {
                this.findOutAction().operations[0].animation!.duration = this.outDuration;
            }
            const element = this.propertiesService.selectedElement!;
            this.editorStateService.mutatorService.setElementPropertyValue(
                element,
                'actions',
                element.actions
            );
        }
    }

    onEasingChange(type: 'in' | 'out'): void {
        if (type === 'in') {
            this.findInAction().operations[0].animation!.timingFunction = this.inEasing;
        } else {
            this.findOutAction().operations[0].animation!.timingFunction = this.outEasing;
        }
        const element = this.propertiesService.selectedElement!;
        this.editorStateService.mutatorService.setElementPropertyValue(
            element,
            'actions',
            element.actions
        );
    }
}
