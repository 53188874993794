import { Component, effect, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { NS_CONFIG_TOKEN } from '@bannerflow/notification';
import { UIAdBlockerDetectionService } from '@bannerflow/ui';
import { T } from '@creative/creative.container';
import { initValidator } from '@creative/serialization/document-validator';
import { ActivityLoggerService } from '@studio/monitoring/activity-logger.service';
import { BrandService } from '@studio/stores/brand';
import { FontFamiliesService } from '@studio/stores/font-families';
import { Container } from '@studio/utils/di';
import { getPrimaryError } from '@studio/utils/errors/apps-errors';
import { map, merge, skipWhile } from 'rxjs';
import { environment } from '../environments/environment';
import { EnvironmentService } from './shared/services/environment.service';
import { HotkeyBetterService } from './shared/services/hotkeys/hotkey.better.service';
import { UserService } from './shared/user/state/user.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    private authService = inject(AuthService);
    private libConfig = inject(NS_CONFIG_TOKEN);
    private uiAdBlockerDetectionService = inject(UIAdBlockerDetectionService);

    private user = toSignal(this.authService.user$);

    loaded = false;

    constructor(
        private hotkeyService: HotkeyBetterService,
        private activityLoggerService: ActivityLoggerService,
        private container: Container<T>,
        private brandService: BrandService,
        private fontFamiliesService: FontFamiliesService,
        private userService: UserService,
        private activatedRoute: ActivatedRoute,
        private environmentService: EnvironmentService,
        protected router: Router
    ) {
        this.container.register_m(T.HOTKEY_SERVICE, this.hotkeyService);
        this.container.register_m(T.ACTIVITY_LOGGER_SERVICE, this.activityLoggerService);
        merge(this.brandService.error$, this.fontFamiliesService.error$, this.userService.error$)
            .pipe(
                skipWhile(error => !error),
                map(getPrimaryError),
                takeUntilDestroyed()
            )
            .subscribe(error => {
                throw error;
            });

        initValidator();

        // Pass acess token to lib, needed for SignalR
        effect(
            () => {
                this.provideAccessTokenToNS();
            },
            {
                // needs to write a signal inside lib
                allowSignalWrites: true
            }
        );
        this.checkAdBlocker();
    }

    ngOnInit(): void {
        this.environmentService.setGlobals();
    }

    private checkAdBlocker(): void {
        if (environment.stage !== 'test') {
            this.uiAdBlockerDetectionService.checkAndNotifiy().subscribe();
        }
    }

    private provideAccessTokenToNS(): void {
        const user = this.user();

        if (user) {
            this.authService
                .getAccessTokenSilently({
                    cacheMode: 'on'
                })
                .subscribe(accessToken => {
                    this.libConfig.accessToken$.next(accessToken);
                });
        }
    }
}
