import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PermissionsDirective } from '../../../shared/directives/permissions.directive';
import { WeightService } from '../../../shared/weight-calculation/state/weight.service';
import { Logger } from '@bannerflow/sentinel-logger';
import { UIFormatBytesPipe, UIModule } from '@bannerflow/ui';
import { ICreative } from '@domain/creativeset/creative';
import { ICreativeWeight } from '@domain/creativeset/creative/weight';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

interface IFormattedWeight {
    subLoad: string;
    initialLoad: string;
    totalLoad: string;
    tooltip: string;
}

@Component({
    standalone: true,
    imports: [CommonModule, UIModule, PermissionsDirective],
    selector: 'creative-list-item-weight',
    templateUrl: './creative-list-item-weight.component.html',
    styleUrls: ['./creative-list-item-weight.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreativeListItemWeightComponent implements OnInit {
    @Input() creative: ICreative | undefined;
    weightIsLoading$: Observable<boolean>;
    filesize = signal<IFormattedWeight | undefined>(undefined, { equal: this.compareWeight });

    private logger = new Logger('CreativeListItemWeightComponent');

    constructor(
        private weightService: WeightService,
        private formatBytes: UIFormatBytesPipe
    ) {
        this.weightIsLoading$ = this.weightService.loadingCreativeWeights$.pipe(
            map(creativeIds => creativeIds.some(id => id === this.creative?.id))
        );

        this.weightService.creativeWeights$
            .pipe(filter(Boolean), takeUntilDestroyed())
            .subscribe(creativeWeights => {
                const weight = creativeWeights.find(
                    creativeWeight => creativeWeight.creativeId === this.creative?.id
                );
                if (weight) {
                    this.handleCreativeWeightResult(weight);
                }
            });
    }

    ngOnInit(): void {
        this.setFileSize();
    }

    async calculateWeight(event: MouseEvent): Promise<void> {
        event.stopPropagation();
        if (!this.creative) {
            return;
        }

        // Only begin calculations if the checksum is new
        const newChecksum = this.creative.checksum !== this.creative.creativeWeights?.creativeChecksum;
        if (newChecksum) {
            await this.weightService.beginWeightCalculation([this.creative]);
        } else {
            this.logger.verbose('Skipping weight calculation, checksum is the same');
            // check if the weight has been calculated
            this.weightService.initWeightCalculationChecks([this.creative]);
        }
    }

    private setFileSize(): void {
        // Don't set filesize if the creative has been changed since calc
        if (this.creative?.creativeWeights?.creativeChecksum !== this.creative?.checksum) {
            return;
        }

        let newFileSize: IFormattedWeight | undefined;
        if (this.creative?.creativeWeights?.weights) {
            const { initialLoad, subLoad } = this.creative.creativeWeights.weights;
            newFileSize = this.formatFileSizes({
                initialLoad: initialLoad?.totalWeight || 0,
                subLoad: subLoad?.totalWeight || 0
            });
        }

        this.filesize.set(newFileSize);
    }

    private handleCreativeWeightResult(creativeWeight: ICreativeWeight): void {
        if (!creativeWeight || !this.creative) {
            return;
        }
        if (creativeWeight.weights) {
            this.creative.creativeWeights = creativeWeight;
            const { initialLoad, subLoad } = creativeWeight.weights;

            this.filesize.set(
                this.formatFileSizes({
                    initialLoad: initialLoad?.totalWeight || 0,
                    subLoad: subLoad?.totalWeight || 0
                })
            );
        } else if (creativeWeight.failed) {
            this.filesize.set(undefined);
        }
    }

    private formatFileSizes(weight: { initialLoad: number; subLoad: number }): IFormattedWeight {
        const initialLoad = this.formatBytes.transform(weight.initialLoad, 0);
        const subLoad = this.formatBytes.transform(weight.subLoad, 0);
        const totalLoad = this.formatBytes.transform(weight.subLoad + weight.initialLoad, 0);
        return {
            initialLoad,
            subLoad,
            totalLoad,
            tooltip: `Preload (initial): ${initialLoad}<br>Subload: ${subLoad}<br>Total: ${totalLoad}`
        };
    }

    private compareWeight(a: IFormattedWeight | undefined, b: IFormattedWeight | undefined): boolean {
        return (
            a?.initialLoad === b?.initialLoad &&
            a?.subLoad === b?.subLoad &&
            a?.totalLoad === b?.totalLoad
        );
    }
}
