import { IText } from '@domain/text';
import { Subject } from 'rxjs';

/**
 * Accessible through editor-events
 */
export class TextEvents {
    change$ = new Subject<IText>();
    textSelectionChange$ = new Subject<void>();
    textMouseDown$ = new Subject<void>();
    textMouseUp$ = new Subject<void>();
}
