<studio-ui-section
    id="add-action-header"
    headline="Actions"
    [clickableHeader]="true"
    (headerClick)="triggerButton($event)"
    [empty]="!actions.length"
    class="no-padding"
    [customAction]="addActionTemplate">
    <div class="actions">
        <div *ngFor="let actionType of sortedActionKeys">
            <ng-container *ngIf="sortedActions[actionType] && sortedActions[actionType]!.length > 0">
                <div class="setting">On {{ actionName(actionType) | lowercase }}</div>
                <action-property
                    #actionElement
                    *ngFor="let elementAction of sortedActions[actionType]; let i = index"
                    [actionId]="elementAction.id"
                    [actionSettingsOpened]="newActionId === elementAction.id"
                    (editAction)="onEditAction($event)"
                    (actionDeleted)="onDeleteAction($event)"></action-property>
            </ng-container>
        </div>
    </div>
</studio-ui-section>
<ui-dropdown
    #dropdown
    id="action-add-dropdown"
    [offset]="placeDropdown()"
    [positions]="[
        { originX: 'start', originY: 'bottom', overlayX: 'end', overlayY: 'top' },
        { originX: 'start', originY: 'top', overlayX: 'end', overlayY: 'bottom' }
    ]"
    type="menu">
    <ui-dropdown-item
        *ngFor="let trigger of actionTriggers"
        [id]="'interaction-action-' + trigger.value"
        (click)="addAction(trigger)">
        On {{ trigger.name }}
    </ui-dropdown-item>
</ui-dropdown>

<ng-template #addActionTemplate>
    <ng-container>
        <div
            #customDropdown
            class="action"
            [uiDropdownTarget]="dropdown">
            <ui-svg-icon
                [style.width]="addActionIconWidth + 'px'"
                class="icon"
                icon="plus-small">
            </ui-svg-icon>
        </div>
    </ng-container>
</ng-template>
