<div class="campaigns">
    <div class="text">
        Publish latest changes in selected
        {{ creatives.length > 1 ? 'creatives (' + creatives.length + ')' : 'creative' }} to the
        following {{ campaigns.length > 1 ? 'campaigns' : 'campaign' }}?
    </div>

    @for (campaign of campaigns; track campaign.id) {
        <div class="campaign">
            <div
                class="link"
                (click)="openCampaign(campaign.id)"
                [uiTooltip]="'Open campaign in a new tab'">
                <ui-svg-icon icon="campaigns-s"></ui-svg-icon> {{ campaign.name }}
            </div>
        </div>
    }

    <div class="text bottom">Note! This action will affect live ads instantly.</div>
</div>

<ui-dialog-buttons>
    <ui-button
        text="Cancel"
        (click)="cancel()"></ui-button>

    <ui-button
        id="push-changes-dialog-confirm"
        type="primary"
        [text]="'Push Changes (' + creatives.length + ')'"
        (click)="publishChanges()"></ui-button>
</ui-dialog-buttons>
