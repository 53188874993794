<div class="sizes">
    <size-list
        data-test-id="size-list"
        label="Select sizes"
        type="duplicate"
        [sizes]="sizeList"
        (sizesChanged)="sizesChanged($event)"></size-list>
</div>

@if (duplicateToNew) {
    <div class="versions">
        <version-list
            data-test-id="version-list"
            [versions]="versionList"
            (versionsChanged)="versionsChanged($event)"></version-list>
    </div>
}

<div
    class="target-list"
    [class.duplicate-to-new]="duplicateToNew">
    <creativesets-list
        [duplicateToNew]="duplicateToNew"
        [disableDuplication]="disableDuplication"
        [selectedSizes]="selectedSizes"
        [selectedVersions]="selectedVersions"
        (onCancel)="closeDialog()"></creativesets-list>
</div>
